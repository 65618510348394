import styles from './VisitationReportsPreview.module.scss';

import { Fullscreen } from '@mui/icons-material';
import ShareIcon from '@mui/icons-material/Share';
import { IconButton, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import i18next from 'i18next';
import {
  MouseEventHandler,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EditIcon } from '@work4all/assets/icons/edit.svg';

import {
  useDataMutation,
  useInaccessibleFields,
  useUser,
} from '@work4all/data';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { TalkingPoint } from '@work4all/models/lib/Classes/TalkingPoint.entity';
import { VisitationReport } from '@work4all/models/lib/Classes/VisitationReport.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import {
  DateFormatPreset,
  formatDateString,
} from '@work4all/utils/lib/date-utils/formatDateString';

import {
  BasicTable,
  IBasicTableProps,
  TableStateBagProvider,
} from '../../../dataDisplay/basic-table';
import { DatePicker } from '../../../input/date-picker';
import { MIME_TYPES, Preview } from '../../../preview/Preview';
import { useMobiscrollLanguage } from '../../../utils/use-mobiscroll-language/use-mobiscroll-language';
import { EntityPickerPopover } from '../../entity-picker/components';
import { CustomerPicker } from '../../entity-picker/customer-picker/CustomerPicker';
import { ProjectPicker } from '../../entity-picker/project-picker/ProjectPicker';
import { UserPicker } from '../../entity-picker/user-picker/UserPicker';
import { useEntityTemplate } from '../../entity-template-provider/EntityTemplateProvider';
import { IUserIconRegisterContext } from '../../user-icon/types';
import { HookedUserIcon } from '../../user-icon/useUserIconRegister';
import {
  InfoCard,
  InfoCards,
  OpenFileIconButton,
  PreviewEditMessage,
  PreviewTitle,
  PreviewWrapper,
} from '../components';
import { useEntityPreview } from '../hooks/use-entity-preview';

import { visitationReportPreviewFields } from './VisitationReportPreviewContainer';

export type IVisitationReportPreviewProps = {
  userIconContext: IUserIconRegisterContext;
  noPreviewUrl?: string;
  visitationReports: VisitationReport[];
  onCloseClicked?: MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onShareClicked?: () => void;
};

enum previewFields {
  DATE = 'date',
  CUSTOMER = 'customer',
  PARTICIPANT = 'participant',
  CREATOR = 'creator',
  PREVIEW_TITLE = 'previewTitle',
  PROJECT = 'project',
}

export const VisitationReportPreview = (
  props: IVisitationReportPreviewProps
) => {
  const {
    visitationReports,
    onCloseClicked,
    onEditClicked,
    noPreviewUrl,
    onShareClicked,
  } = props;
  const { t } = useTranslation();
  const language = useMobiscrollLanguage();

  const user = useUser();
  const { isInaccessible } = useInaccessibleFields();

  const titleIsAccessible = !isInaccessible(Entities.visitationReport, 'title');
  const fileInfosIsAccessible = !isInaccessible(
    Entities.visitationReport,
    'fileInfos'
  );
  const userIsAccessible = !isInaccessible(Entities.visitationReport, 'user');
  const creationDateIsAccessible = !isInaccessible(
    Entities.visitationReport,
    'creationDate'
  );
  const businessPartnerIsAccessible = !isInaccessible(
    Entities.visitationReport,
    'businessPartner'
  );
  const projectIsAccessible = !isInaccessible(
    Entities.visitationReport,
    'project'
  );
  const attendeeListIsAccessible = !isInaccessible(
    Entities.visitationReport,
    'attendeeList'
  );
  const talkingPointListIsAccessible = !isInaccessible(
    Entities.visitationReport,
    'talkingPointList'
  );

  const [detailInFullsceen, setFullscreen] = useState(false);

  const prepareRowDisplayModifiers = (value) => {
    return { isBold: (value as TalkingPoint).kind === 1 };
  };

  const columns = useMemo(() => {
    const columns: IBasicTableProps['columns'] = [
      {
        Header: t('COMMON.NUMBER_SHORT'),
        accessor: 'pozNr',
        width: 60,
      },
      {
        Header: t('COMMON.TOPIC'),
        accessor: 'thema',
        width: 220,
      },
      {
        Header: t('COMMON.CONTENTS'),
        accessor: 'erledigung',
        width: 260,
      },
    ];

    return columns;
  }, [t]);

  const dateRef = useRef<EntityPickerPopover>(null);
  const customerRef = useRef<EntityPickerPopover>(null);
  const projectRef = useRef<EntityPickerPopover>(null);
  const participantRef = useRef<EntityPickerPopover>(null);
  const creatorRef = useRef<EntityPickerPopover>(null);
  const titleRef = useRef<PreviewTitle>(null);

  const openPicker = useCallback((picker: previewFields) => {
    switch (picker) {
      case previewFields.DATE: {
        dateRef.current.open();
        break;
      }

      case previewFields.CUSTOMER: {
        customerRef.current.open();
        break;
      }

      case previewFields.PROJECT: {
        projectRef.current.open();
        break;
      }

      case previewFields.PARTICIPANT: {
        participantRef.current.open();
        break;
      }

      case previewFields.CREATOR: {
        creatorRef.current.open();
        break;
      }

      case previewFields.PREVIEW_TITLE: {
        titleRef.current.open();
        break;
      }

      default:
        return;
    }
  }, []);

  const [mutate] = useDataMutation<VisitationReport, EMode.upsert>({
    entity: Entities.visitationReport,
    mutationType: EMode.upsert,
    responseData: visitationReportPreviewFields as unknown as VisitationReport,
  });

  const {
    loading,
    isMultiselect,
    commonFields,
    activePicker,
    optimisticTitle,
    handleLock,
    onPopoverClose,
    setOptimisticTitle,
    onEdit,
    canEditAllSelected,
  } = useEntityPreview({
    user,
    subEntityType: Entities.visitationReport,
    entries: visitationReports,
    openPicker,
    mutate,
  });

  const dateIsCommon = commonFields.includes('creationDate');
  const customerIsCommon = commonFields.includes('businessPartner');
  const projectIsCommon = commonFields.includes('project');
  const creatorIsCommon = commonFields.includes('user');

  const visitationReport = visitationReports[0];
  const sortedList = useMemo(() => {
    return [...visitationReport.talkingPointList].sort(
      (a, b) => a.nummer - b.nummer
    );
  }, [visitationReport.talkingPointList]);

  const entityTemplate = useEntityTemplate();

  return (
    <PreviewWrapper>
      {visitationReport.fileInfos.fileServiceProviderInfos ? (
        <>
          <PreviewTitle
            disabled={true}
            showEdit={!isMultiselect && canEditAllSelected}
            ref={titleRef}
            label={visitationReport.topic}
            onClick={() =>
              !isMultiselect &&
              titleIsAccessible &&
              handleLock(previewFields.PREVIEW_TITLE)
            }
            onClose={(e) => {
              if (e.target.value !== visitationReport.topic) {
                setOptimisticTitle(e.target.value);
                onEdit({ title: e.target.value });
              }
              onPopoverClose();
            }}
            onCloseClicked={onCloseClicked}
            onEditClicked={onEditClicked}
            onShareClicked={onShareClicked}
            actions={
              loading && activePicker === previewFields.PREVIEW_TITLE ? (
                <CircularProgress size="1rem" color="secondary" />
              ) : (
                <>
                  <IconButton
                    color="primary"
                    onClick={() => setFullscreen(true)}
                  >
                    <Fullscreen />
                  </IconButton>

                  {onShareClicked && (
                    <IconButton
                      size="large"
                      color="primary"
                      onClick={onShareClicked}
                    >
                      <ShareIcon />
                    </IconButton>
                  )}

                  {onEditClicked ? (
                    <IconButton
                      onClick={onEditClicked}
                      className={styles['fileheader-icon']}
                      size="large"
                    >
                      <EditIcon />
                    </IconButton>
                  ) : null}
                </>
              )
            }
          >
            {isMultiselect
              ? `${t('COMMON.SELECTION')}: ${visitationReports.length} ${t(
                  'COMMON.ELEMENTS'
                )}`
              : titleIsAccessible
              ? optimisticTitle ?? visitationReport.topic
              : ''}
          </PreviewTitle>
          {isMultiselect && (
            <PreviewEditMessage entityId={String(visitationReport.id)} />
          )}

          {!isMultiselect && fileInfosIsAccessible ? (
            <Preview
              onFullscreenClose={() => setFullscreen(false)}
              openInFullscreen={detailInFullsceen}
              url={`${visitationReport.fileInfos?.previewUrl}`}
              mimeType={
                visitationReport.fileInfos?.previewMimeType as MIME_TYPES
              }
              fileName={`${visitationReport.fileInfos?.fileServiceProviderInfos?.fileName}`}
              noPreview={i18next.t('MASK.NO_PREVIEW_FOR', {
                type: MIME_TYPES,
              })}
              noPreviewUrl={noPreviewUrl}
            />
          ) : null}
        </>
      ) : (
        <>
          <PreviewTitle
            disabled={true}
            showEdit={!isMultiselect && canEditAllSelected}
            ref={titleRef}
            label={visitationReport.topic}
            onClick={() =>
              !isMultiselect &&
              titleIsAccessible &&
              handleLock(previewFields.PREVIEW_TITLE)
            }
            onClose={(e) => {
              if (e.target.value !== visitationReport.topic) {
                setOptimisticTitle(e.target.value);
                onEdit({ topic: e.target.value });
              }
              onPopoverClose();
            }}
            onCloseClicked={onCloseClicked}
            onEditClicked={onEditClicked}
            onShareClicked={onShareClicked}
            actions={
              loading && activePicker === previewFields.PREVIEW_TITLE ? (
                <CircularProgress size="1rem" color="secondary" />
              ) : null
            }
          >
            {isMultiselect
              ? `${t('COMMON.SELECTION')}: ${visitationReports.length} ${t(
                  'COMMON.ELEMENTS'
                )}`
              : titleIsAccessible
              ? optimisticTitle ?? visitationReport.topic
              : ''}
          </PreviewTitle>
          {isMultiselect && (
            <PreviewEditMessage entityId={String(visitationReport.id)} />
          )}
          <InfoCards columns={2}>
            {userIsAccessible && (
              <EntityPickerPopover
                ref={creatorRef}
                disabled={true}
                onTargetClick={() => handleLock(previewFields.CREATOR)}
                onClose={onPopoverClose}
                picker={
                  <UserPicker
                    multiple={false}
                    value={creatorIsCommon ? visitationReport.user : null}
                    onChange={(value) => {
                      onEdit({
                        userId: value.id,
                      });
                    }}
                  />
                }
              >
                <InfoCard
                  truncate
                  disabled={false}
                  label={t('COMMON.EMPLOYEE')}
                  decorator={
                    loading && activePicker === previewFields.CREATOR ? (
                      <CircularProgress size="1rem" color="secondary" />
                    ) : null
                  }
                  decoratorPos="right"
                >
                  {creatorIsCommon ? (
                    <div className={styles['user-icon-wrapper']}>
                      <HookedUserIcon
                        userId={visitationReport.userId}
                        size="l"
                      />
                      {visitationReport.user.firstName}{' '}
                      {visitationReport.user.lastName}
                    </div>
                  ) : (
                    `(${t('COMMON.MULTIPLE')})`
                  )}
                </InfoCard>
              </EntityPickerPopover>
            )}

            {creationDateIsAccessible && (
              <EntityPickerPopover
                ref={dateRef}
                disabled={true}
                onTargetClick={() => handleLock(previewFields.DATE)}
                onClose={onPopoverClose}
                autoclose={true}
                picker={
                  <DatePicker
                    locale={language}
                    controls={['calendar']}
                    defaultValue={formatDateString(
                      visitationReport?.creationDate,
                      DateFormatPreset.DATE_SIMPLE_4YEAR
                    )}
                    onChange={(val) => {
                      onEdit({
                        creationDate: new Date(val.value).toISOString(),
                      });
                    }}
                  />
                }
              >
                <InfoCard
                  label={t('COMMON.DATE')}
                  decorator={
                    loading && activePicker === previewFields.DATE ? (
                      <CircularProgress size="1rem" color="secondary" />
                    ) : null
                  }
                  decoratorPos="right"
                >
                  {dateIsCommon
                    ? formatDateString(
                        visitationReport?.creationDate,
                        DateFormatPreset.DATE_SIMPLE_4YEAR
                      )
                    : `(${t('COMMON.MULTIPLE')})`}
                </InfoCard>
              </EntityPickerPopover>
            )}

            {businessPartnerIsAccessible && (
              <EntityPickerPopover
                ref={customerRef}
                disabled={true}
                onTargetClick={() => handleLock(previewFields.CUSTOMER)}
                onClose={onPopoverClose}
                footer={entityTemplate.renderPickerFooter({
                  entity: Entities.customer,
                  popoverRef: customerRef,
                  data: visitationReport.customer,
                  onChange: (value) => {
                    onEdit({
                      businessPartner:
                        value && !Array.isArray(value)
                          ? {
                              data: value,
                              businessPartnerType: SdObjType.KUNDE,
                              id: value?.id,
                            }
                          : null,
                      businessPartnerId:
                        value && !Array.isArray(value) ? value?.id : 0,
                      businessPartnerType: SdObjType.KUNDE,
                    });
                  },
                })}
                picker={
                  <CustomerPicker
                    onChange={(value) => {
                      onEdit({
                        businessPartner: value
                          ? {
                              data: value,
                              businessPartnerType: SdObjType.KUNDE,
                              id: value?.id,
                            }
                          : null,
                        businessPartnerId: value ? value?.id : 0,
                        businessPartnerType: SdObjType.KUNDE,
                      });
                    }}
                    value={visitationReport.businessPartner?.data as Customer}
                    multiple={false}
                  />
                }
              >
                <InfoCard
                  truncate
                  label={t('COMMON.BUSINESS_PARTNER', {
                    context:
                      visitationReport?.businessPartner?.businessPartnerType,
                  })}
                  decorator={
                    loading && activePicker === previewFields.CUSTOMER ? (
                      <CircularProgress size="1rem" color="secondary" />
                    ) : null
                  }
                  decoratorPos="right"
                  beforeContentElement={
                    <OpenFileIconButton
                      data={visitationReport?.businessPartner?.data}
                    />
                  }
                >
                  {customerIsCommon
                    ? visitationReport?.businessPartner?.data?.name
                    : `(${t('COMMON.MULTIPLE')})`}
                </InfoCard>
              </EntityPickerPopover>
            )}

            {projectIsAccessible && (
              <EntityPickerPopover
                ref={projectRef}
                disabled={true}
                onTargetClick={() => handleLock(previewFields.PROJECT)}
                onClose={onPopoverClose}
                footer={entityTemplate.renderPickerFooter({
                  entity: Entities.project,
                  popoverRef: projectRef,
                  data: visitationReport.project,
                  onChange: (value) => {
                    onEdit({
                      project: value,
                      projectId: value && !Array.isArray(value) ? value.id : 0,
                    });
                  },
                })}
                picker={
                  <ProjectPicker
                    onChange={(value) => {
                      onEdit({
                        project: value,
                        projectId: value ? value.id : 0,
                      });
                    }}
                    value={visitationReport.project}
                    multiple={false}
                  />
                }
              >
                <InfoCard
                  truncate
                  label={t('COMMON.PROJECT')}
                  decorator={
                    loading && activePicker === previewFields.PROJECT ? (
                      <CircularProgress size="1rem" color="secondary" />
                    ) : null
                  }
                  decoratorPos="right"
                  beforeContentElement={
                    <OpenFileIconButton data={visitationReport?.project} />
                  }
                >
                  {projectIsCommon
                    ? visitationReport?.project?.name
                    : `(${t('COMMON.MULTIPLE')})`}
                </InfoCard>
              </EntityPickerPopover>
            )}
          </InfoCards>

          {attendeeListIsAccessible && (
            <InfoCards columns={1}>
              <InfoCard
                staticField={true}
                truncate
                label={t('COMMON.PARTICIPANT')}
              >
                {visitationReport.attendeeList.map((attendee) => {
                  return (
                    <div>
                      <Typography component="span">
                        {attendee.name}

                        {attendee.businessPartnerId !== 0 ? (
                          <Typography component="span" color="secondary">
                            {' '}
                            | {attendee.contact?.businessPartner?.data?.name}
                          </Typography>
                        ) : null}
                      </Typography>
                    </div>
                  );
                })}
              </InfoCard>
            </InfoCards>
          )}

          {!isMultiselect && talkingPointListIsAccessible ? (
            <div style={{ display: 'grid', height: '100%' }}>
              {/* Add new empty context so that this table does not overwrite the context in ListPage. */}
              <TableStateBagProvider tableInstance={null}>
                <BasicTable
                  className={styles.transparentTable}
                  mode="client"
                  prepareRowDisplayModifiers={prepareRowDisplayModifiers}
                  reordableColumns={true}
                  resizableColumns={true}
                  data={sortedList}
                  columns={columns}
                  allItemsCount={visitationReport.talkingPointList.length || 0}
                  cardsView={false}
                  selectableMultiple={false}
                />
              </TableStateBagProvider>
            </div>
          ) : null}
        </>
      )}
    </PreviewWrapper>
  );
};
