import styles from './Attachments.module.scss';

import AttachmentIcon from '@mui/icons-material/AttachFile';
import { Box, CircularProgress } from '@mui/material';
import { ChangeEvent, useCallback, useContext } from 'react';

import { TempFileManagerContext } from '@work4all/data/lib/hooks/data-provider/useTempFileManager';

import { useLock } from '../../hooks/object-lock';
import { LABEL_VISIBILITY, MaskActionButton } from '../MaskActionButton';

interface AttachmentsUploadButtonProps {
  disabled?: boolean;
  disabledReason?: string;
}

export const AttachmentsUploadButton: React.FC<AttachmentsUploadButtonProps> = (
  props
) => {
  const { disabled = false } = props;

  const attachmentsContext = useContext(TempFileManagerContext);
  const { uploadFiles: uploadAttachments, currentUploadingFiles } =
    attachmentsContext;

  const lock = useLock();

  const onUploadAttachment = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      uploadAttachments(Array.from(e.target.files));
    },
    [uploadAttachments]
  );

  const isUploadActionAllowed =
    (!disabled && !lock.locked) || currentUploadingFiles > 0;

  return (
    <Box className={styles.fileUploadInputWrap}>
      <MaskActionButton
        label={''}
        labelVisibility={LABEL_VISIBILITY.HIDE}
        color="primary"
        icon={
          currentUploadingFiles !== 0 ? (
            <CircularProgress size="1.25rem" />
          ) : (
            <div className={styles.attachmentIconWrap}>
              <AttachmentIcon />
            </div>
          )
        }
        disabled={!isUploadActionAllowed}
        {...props}
      />
      <input
        className={styles.fileUploadInput}
        type="file"
        name="upload"
        disabled={props.disabled}
        multiple={true}
        onChange={onUploadAttachment}
      />
    </Box>
  );
};
