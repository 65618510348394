import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IconButton, Stack, Typography } from '@mui/material';

export interface FileListGalleryPaginationProps {
  count: number;
  page: number;
  onChange: (value: number) => void;
}

export function FileListGalleryPagination(
  props: FileListGalleryPaginationProps
) {
  const { count, page, onChange } = props;

  const displayedPage = page + 1;

  function handleChange(newPage: number): void {
    if (newPage < 0) {
      onChange(count - 1);
    } else if (newPage >= count) {
      onChange(0);
    } else {
      onChange(newPage);
    }
  }

  return (
    <Stack direction="row" alignItems="center">
      <IconButton color="primary" onClick={() => handleChange(page - 1)}>
        <ChevronLeftIcon />
      </IconButton>

      <Typography variant="body1">
        {displayedPage}/{count}
      </Typography>

      <IconButton color="primary" onClick={() => handleChange(page + 1)}>
        <ChevronRightIcon />
      </IconButton>
    </Stack>
  );
}
