import styles from './CategoryClassPicker.module.scss';

import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import Typography from '@mui/material/Typography';
import { useObservableState } from 'observable-hooks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { Category } from '@work4all/models/lib/Classes/Category.entity';
import { CategoryClass } from '@work4all/models/lib/Classes/CategoryClass.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { translateFilter } from '../../../dataDisplay/basic-table/hooks/query-table-data/hooks/translate-utils';
import { Chip } from '../../../dataDisplay/chip/Chip';
import { ChipList } from '../../../dataDisplay/chip/ChipList';
import {
  SelectableTree,
  TreeNode,
} from '../../../dataDisplay/tree/SelectableTree';
import { FilterTextInput } from '../components';
import { IPickerProps } from '../types';

import { Selection } from './../utils/selection-model';

export type ICategoryClassPickerProps<TMultiple extends boolean> = Omit<
  IPickerProps<Category, TMultiple>,
  'data'
> & {
  filterToContactCategories?: boolean; // the logic for kategories that apply to suppliers or contacts of them is modeled in the backend see JIRA WW-1210
  filterToCompanyCategories?: boolean; // the logic for kategories that apply to suppliers or contacts of them is modeled in the backend see JIRA WW-1210
};

type CategoryCategoryClassUnion = Pick<
  CategoryClass & Category,
  'id' | 'name' | 'categoryList'
>;

export function CategoryClassPicker<TMultiple extends boolean>(
  props: ICategoryClassPickerProps<TMultiple>
) {
  const options = useMemo<QueryHookOptions>(() => {
    return {
      variables: {
        querySize: 9999,
        companyCategories:
          props.filterToCompanyCategories === true ? true : undefined,
        contactCategories:
          props.filterToContactCategories === true ? true : undefined,
        filter: JSON.stringify(
          translateFilter(props.prefilter, Entities.categoryClass)
        ),
      },
    };
  }, [
    props.filterToCompanyCategories,
    props.filterToContactCategories,
    props.prefilter,
  ]);

  const query = useQuery(GET_CATEGORYCLASSES, options);

  const responseData = query.data?.getKategorieKlassen;

  const filteredResponseData = useMemo<CategoryClass[]>(() => {
    const hiddenSubCatsRemoved = responseData?.data?.reduce((agg, curr) => {
      const withoutHidden = curr.categoryList.filter((el) => el.hide !== true);
      agg.push({ ...curr, categoryList: withoutHidden });
      return agg;
    }, []);

    return (
      hiddenSubCatsRemoved?.filter(
        (category) => category.categoryList.length > 0
      ) ?? []
    );
  }, [responseData?.data]);

  const categoryList = useMemo<Category[]>(() => {
    return (
      filteredResponseData?.reduce(
        (curr, x) => (curr = curr?.concat(x.categoryList)),
        []
      ) || []
    );
  }, [filteredResponseData]);

  const getCategoriesById = useCallback(
    (selectedIds: string[]): Selection<Category, TMultiple> => {
      const foundElements = categoryList[props.multiple ? 'filter' : 'find'](
        (category) => selectedIds.includes(category.id.toString())
      ) as Selection<Category, TMultiple>;
      return foundElements || null;
    },
    [props.multiple, categoryList]
  );

  const [searchString, setSearchString] = useObservableState(
    (input$) => input$.pipe(distinctUntilChanged(), debounceTime(200)),
    ''
  );

  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    if (props.multiple) {
      setSelectedIds(
        (props?.value as Selection<Category, true>)?.map((x) => x.id.toString())
      );
    } else {
      setSelectedIds([
        (props?.value as Selection<Category, false>)?.id?.toString(),
      ]);
    }
  }, [props.multiple, props?.value]);

  const nodeMap = useMemo(() => {
    const map = new Map<
      string,
      CategoryCategoryClassUnion & { parent: CategoryCategoryClassUnion }
    >();
    const arrayToMap = (
      elements: CategoryCategoryClassUnion[],
      map: Map<
        string,
        CategoryCategoryClassUnion & { parent: CategoryCategoryClassUnion }
      >,
      parent?: CategoryCategoryClassUnion
    ) => {
      elements.forEach((el) => {
        map.set(el.id.toString(), { ...el, parent });
        if (el.categoryList?.length > 0) {
          arrayToMap(el.categoryList, map, el);
        }
      });
    };
    arrayToMap(filteredResponseData, map);
    return map;
  }, [filteredResponseData]);

  const treeData = useMemo<TreeNode[]>(() => {
    const toTree = (data: CategoryCategoryClassUnion[]) => {
      return data.map((el) => {
        const result: TreeNode = {
          id: el.id.toString(),
          label: el.name,
          children: el.categoryList
            ? toTree(
                [...el.categoryList].sort((a, b) =>
                  a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                )
              )
            : undefined,
        };
        return result;
      }, []);
    };

    //return tree
    if (searchString.trim() === '') return toTree(filteredResponseData || []);

    //return flat list
    return (filteredResponseData || [])
      .map((el) => ({
        parent: el,
        children:
          el.categoryList?.filter((el) =>
            el.name.toLowerCase().includes(searchString.toLowerCase())
          ) ?? [],
      }))
      .filter((item) => item.children.length > 0)
      .flatMap(({ parent, children }) => {
        return children.map((el) => ({
          id: el.id.toString(),
          label: (
            <Typography component="div">
              <span>{el.name}</span>
              <span
                className={styles.secondaryItemLabel}
              >{` | ${parent.name}`}</span>
            </Typography>
          ),
        }));
      });
  }, [filteredResponseData, searchString]);

  const selectableTreeRef = useRef<HTMLDivElement>(null);

  const handleKeyNavigation = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.code === 'ArrowDown') {
        setTimeout(() => {
          if (e?.target) {
            const htmlTarget = e.target as HTMLElement;
            htmlTarget.blur();
          }
          if (selectableTreeRef?.current?.firstChild) {
            const htmlTarget = selectableTreeRef.current
              .firstChild as HTMLElement;
            htmlTarget.focus();
          }
        });
      }
    },
    []
  );

  return (
    <div>
      {props.multiple && selectedIds?.length > 0 && (
        <div className={styles.wrapper}>
          <ChipList>
            {selectedIds?.map((id) => {
              const data = nodeMap.get(id.toString());
              return (
                <Chip
                  key={id}
                  maxWidth={18}
                  label={`${(data as Category)?.categoryClass?.name} / ${
                    data?.name
                  }`}
                  handleDelete={() => {
                    const selected = selectedIds.filter(
                      (x) => x !== id.toString()
                    );
                    setSelectedIds(selected);
                    if (props.onChange) {
                      props.onChange(getCategoriesById(selected));
                    }
                  }}
                />
              );
            })}
          </ChipList>
        </div>
      )}

      <div className={styles.wrapper}>
        <FilterTextInput
          placeholder="Suchen"
          onChange={(val) => {
            setSearchString(val);
          }}
          onKeyDown={handleKeyNavigation}
        />
      </div>
      <div className={styles.treeWrapper} ref={selectableTreeRef}>
        <SelectableTree
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          multiple={props.multiple as any}
          selectable="leaf"
          data={treeData}
          selected={selectedIds}
          onChange={(ids: string | string[]) => {
            const selected = Array.isArray(ids) ? ids : [ids];
            setSelectedIds(selected);
            if (props.onChange) {
              props.onChange(getCategoriesById(selected));
            }
          }}
        />
      </div>
    </div>
  );
}

const GET_CATEGORYCLASSES = gql`
  query GetCategoryClasses(
    $querySize: Int
    $filter: String
    $companyCategories: Boolean
    $contactCategories: Boolean
  ) {
    getKategorieKlassen(querySize: $querySize, filter: $filter) {
      data {
        id: code
        name: name
        isPersonCategory: personenverteiler
        isSupplierCategory: lieferantenverteiler
        isCustomerCategory: kundenverteiler
        isFirmCategory: firmenverteiler
        hide: hide
        categoryList: verteiler(
          companyCategories: $companyCategories
          contactCategories: $contactCategories
        ) {
          id: code
          name: name
          isPersonCategory: personenverteiler
          isFirmCategory: firmenverteiler
          isArticleDistributor: artikelverteiler
          isStandardCustomer: standardKunde
          isContractDistributor: auftragsverteiler
          isStandardSupplier: standardLieferant
          hide: hide
          categoryClass: kategorieKlasse {
            name: name
            isPersonCategory: personenverteiler
            isCustomerCategory: kundenverteiler
            isSupplierCategory: lieferantenverteiler
            isFirmCategory: firmenverteiler
            isArticleDistributor: artikelverteiler
            mandatoryCustomers: pflichtKunden
            mandatorySuppliers: pflichtLieferanten
            hide: hide
          }
        }
      }
    }
  }
`;
