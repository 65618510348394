import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { StatisticCompanyReportKeyFigures } from './StatisticCompanyReportKeyFigures.entity';

export class StatisticCompanyReportColumn<T extends EMode = EMode.entity> {
  /** Alias: month */
  month?: string;
  /** Alias: rowCount */
  rowCount?: number;
  /** Alias: keyFigures */
  keyFigures?: StatisticCompanyReportKeyFigures<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<StatisticCompanyReportColumn> = {
  month: {
    alias: 'month',
  },
  rowCount: {
    alias: 'rowCount',
  },
  keyFigures: {
    alias: 'keyFigures',
    entity: Entities.statisticCompanyReportKeyFigures,
  },
  __typename: {
    alias: '__typename',
  },
};

export const statisticCompanyReportColumnEntityDefinition: EntitiyDefinition<StatisticCompanyReportColumn> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'StatisticCompanyReportColumn',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
