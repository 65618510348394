import styles from './UserPicker.module.scss';

import { Typography } from '@mui/material';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { useMemo } from 'react';

import { User } from '@work4all/models/lib/Classes/User.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { UserClass } from '@work4all/models/lib/Enums/UserClass.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { HookedUserIcon } from '../../user-icon/useUserIconRegister';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type IUserPickerProps<TMultiple extends boolean> = IPickerProps<
  User,
  TMultiple
> & {
  userClasses?: UserClass[];
};

export function UserPicker<TMultiple extends boolean>(
  props: IUserPickerProps<TMultiple>
) {
  const {
    data,
    userClasses = [UserClass.BENUTZER, UserClass.MITARBEITER],
    ...rest
  } = props;

  const allFields = useMemo(() => {
    return { ...USER_FIELDS, ...data };
  }, [data]);

  const renderUser = (user: User) => {
    if (props) return <UserRow user={user} />;
  };

  return (
    <ListEntityPicker
      entity={Entities.user}
      data={allFields}
      prefilter={[
        {
          hasLeft: { $eq: false },
        },
        {
          $or: userClasses.map((userClass) => ({
            userKind: { $eq: userClass },
          })),
        },
      ]}
      filterBy="displayName"
      sortBy="displayName"
      {...rest}
      renderItemContent={renderUser}
    />
  );
}
const USER_FIELDS: User = {
  id: null,
  displayName: null,
  shortName: null,
  firstName: null,
  lastName: null,
  hasLeft: null,
};

export const UserRow = ({ user }: { user: User }) => {
  return (
    <div className={styles.listItem}>
      <ListItemAvatar>
        <HookedUserIcon userId={user.id} size="l" />
      </ListItemAvatar>

      <Tooltip title={user.displayName}>
        <Typography noWrap>{user.displayName}</Typography>
      </Tooltip>
    </div>
  );
};
