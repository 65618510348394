import { useTranslation } from 'react-i18next';

export type ITranslatedCellProps = {
  value: string;
  column: {
    cellParams: {
      keyPrefix: string;
    };
  };
};

export function TranslatedCell(props: ITranslatedCellProps) {
  const {
    value,
    column: { cellParams },
  } = props;

  const { t } = useTranslation();

  const keyPrefix = cellParams?.keyPrefix;
  if (!value) return null;

  if (!keyPrefix) {
    return t(value);
  }

  const key = `${cellParams.keyPrefix}.${value}`;
  return t(key);
}
