import { CellProps } from 'react-table';

import { ChronoFileItem } from '@work4all/models/lib/Classes/ChronoFileItem.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';

export const ProjectCell = (props: CellProps<ChronoFileItem>) => {
  const fileItem = props.cell.row.original.fileItem;

  const project = getProject(fileItem);
  if (project) {
    return <div>{`${project?.name}`}</div>;
  }
  return null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getProject(fileItem: any): Project {
  return fileItem?.project;
}
