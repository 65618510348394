import styles from './EntityPickerActions.module.scss';

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ArticlePicker,
  IArticlePickerProps,
} from '@work4all/components/lib/components/entity-picker/article-picker/ArticlePicker';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { reactRefSetter } from '@work4all/utils';

import { LabeledInput } from '../../containers/mask-overlays/locked-inputs';

interface ArticlePickerFieldProps
  extends Omit<IArticlePickerProps<false>, 'multiple'> {
  error?: string;
  name?: string;
  label?: string;
  onChange: (value: Article | ErpPositionsKind) => void;
  showFunctions?: boolean;
}

export const ArticlePickerField = React.forwardRef<
  HTMLDivElement,
  ArticlePickerFieldProps
>(function ArticlePickerField(props, ref) {
  const {
    error,
    placeholder,
    label,
    showFunctions = false,
    favorites = true,
    ...pickerProps
  } = props;
  const { value, onChange } = pickerProps;

  const { t } = useTranslation();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<EntityPickerPopover>(null);
  const placeholderText = placeholder ?? t('COMMON.ARTICLE');

  return (
    <div ref={reactRefSetter(wrapperRef, ref)}>
      <EntityPickerPopover
        ref={popoverRef}
        anchorEl={wrapperRef}
        picker={
          <ArticlePicker
            favorites={favorites}
            {...pickerProps}
            multiple={false}
            showFunctions={showFunctions}
          />
        }
      >
        <LabeledInput
          name={props.name}
          value={value ? value?.name : ''}
          onKeyDown={() => {
            popoverRef.current.open();
          }}
          label={
            label || (
              <>
                {!value && placeholderText}
                {value && (
                  <>
                    <span className={styles.primaryInfo}>
                      {placeholderText} &nbsp;
                    </span>
                    <span className={styles.secondaryInfo}>
                      {value?.number}
                    </span>
                  </>
                )}
              </>
            )
          }
          error={error}
          onClear={() => {
            onChange(null);
          }}
        />
      </EntityPickerPopover>
    </div>
  );
});
