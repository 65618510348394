import { isEqual } from 'lodash';
import { useEffect, useRef } from 'react';

import {
  AppointmentCalendarLastParams,
  AppointmentCalendarState,
} from '../types';

export function useUpdateLastCalendarParams({
  state,
  setLastParams,
}: {
  state: AppointmentCalendarState;
  setLastParams: (params: AppointmentCalendarLastParams) => void;
}) {
  // Keep track of the last saved params to avoid saving the same value if only
  // the selected data changed.
  const lastSavedParams = useRef<AppointmentCalendarLastParams>(null);

  // Update the saved last calendar params when the calendar state changes.
  useEffect(() => {
    if (state === null) return;

    const { users, focusedUserIds, appointmentStates, range, groupMode } =
      state;

    const params: AppointmentCalendarLastParams = {
      userIds: users.map((user) => user.id).sort((a, b) => a - b),
      focusedUserIds: focusedUserIds?.slice().sort((a, b) => a - b),
      appointmentStates: appointmentStates?.slice().sort((a, b) => a - b),
      range,
      groupMode,
    };

    // Only update the setting value if there are changes.
    if (
      lastSavedParams.current === null ||
      !isEqual(lastSavedParams.current, params)
    ) {
      setLastParams(params);
    }

    lastSavedParams.current = params;
  }, [state, setLastParams]);
}
