import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputDokument<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: number;
  /** Alias: ansprechpartnerCode */
  ansprechpartnerCode?: number;
  /** Alias: art */
  art?: number;
  /** Alias: benutzerCode */
  benutzerCode?: number;
  /** Alias: blobFileType */
  blobFileType?: string;
  /** Alias: binaryData */
  binaryData?: string;
  /** Alias: dateiPfad */
  dateiPfad?: string;
  /** Alias: datum */
  datum?: string;
  /** Alias: infofensterName */
  infofensterName?: string;
  /** Alias: lastModificationDate */
  lastModificationDate?: string;
  /** Alias: themaCode */
  themaCode?: number;
  /** Alias: notiz */
  notiz?: string;
  /** Alias: objGr */
  objGr?: number;
  /** Alias: objGrCode */
  objGrCode?: number;
  /** Alias: projektCode */
  projektCode?: number;
  /** Alias: sdObjMemberCode */
  sdObjMemberCode?: number;
  /** Alias: sdObjType */
  sdObjType?: SdObjType;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];
  /** Alias: themen */
  themen?: number[];
  /** Alias: createdByUserCode */
  createdByUserCode?: number;
  /** Alias: documentParentCode */
  documentParentCode?: number;
  /** Alias: name */
  name?: string;
  /** Alias: notizHtml */
  notizHtml?: string;

  __typename?: string;
}

const fields: FieldDefinitions<InputDokument> = {
  code: {
    alias: 'code',
  },
  ansprechpartnerCode: {
    alias: 'ansprechpartnerCode',
  },
  art: {
    alias: 'art',
  },
  benutzerCode: {
    alias: 'benutzerCode',
  },
  blobFileType: {
    alias: 'blobFileType',
  },
  binaryData: {
    alias: 'binaryData',
  },
  dateiPfad: {
    alias: 'dateiPfad',
  },
  datum: {
    alias: 'datum',
  },
  infofensterName: {
    alias: 'infofensterName',
  },
  lastModificationDate: {
    alias: 'lastModificationDate',
  },
  themaCode: {
    alias: 'themaCode',
  },
  notiz: {
    alias: 'notiz',
  },
  objGr: {
    alias: 'objGr',
  },
  objGrCode: {
    alias: 'objGrCode',
  },
  projektCode: {
    alias: 'projektCode',
  },
  sdObjMemberCode: {
    alias: 'sdObjMemberCode',
  },
  sdObjType: {
    alias: 'sdObjType',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customFields: {
    alias: 'customFields',
  },
  themen: {
    alias: 'themen',
  },
  createdByUserCode: {
    alias: 'createdByUserCode',
  },
  documentParentCode: {
    alias: 'documentParentCode',
  },
  name: {
    alias: 'name',
  },
  notizHtml: {
    alias: 'notizHtml',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputDokumentEntityDefinition: EntitiyDefinition<InputDokument> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'InputDokument',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
