import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { DuplicateErrorType } from '../Enums/DuplicateErrorType.enum';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Appointment } from './Appointment.entity';
import { CallMemo } from './CallMemo.entity';
import { DuplicatedObjectUnionType } from './DuplicatedObjectUnionType.entity';
import { Note } from './Note.entity';
import { SalesOpportunities } from './SalesOpportunities.entity';
import { Task } from './Task.entity';

export class DuplicateResponse<T extends EMode = EMode.entity> {
  /** Alias: duplicatedObjectCode */
  duplicatedObjectCode?: number;
  /** Alias: duplicatedObjectId */
  duplicatedObjectId?: string;
  /** Alias: generatedObject */
  generatedObject?: T extends EMode.query
    ? DuplicatedObjectUnionType<T>
    : Task<T> | CallMemo<T> | Note<T> | Appointment<T> | SalesOpportunities<T>;
  /** Alias: errorType */
  errorType?: DuplicateErrorType;
  /** Alias: errorMessage */
  errorMessage?: string;

  __typename?: string;
}

const fields: FieldDefinitions<DuplicateResponse> = {
  duplicatedObjectCode: {
    alias: 'duplicatedObjectCode',
  },
  duplicatedObjectId: {
    alias: 'duplicatedObjectId',
  },
  generatedObject: {
    alias: 'generatedObject',
    entity: [
      Entities.task,
      Entities.callMemo,
      Entities.note,
      Entities.appointment,
      Entities.salesOpportunities,
    ],
  },
  errorType: {
    alias: 'errorType',
  },
  errorMessage: {
    alias: 'errorMessage',
  },
  __typename: {
    alias: '__typename',
  },
};

export const duplicateResponseEntityDefinition: EntitiyDefinition<DuplicateResponse> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'DuplicateResponse',
      withPaginationWrapper: false,
      mutations: {
        upsert: {
          mutationName: 'duplicateEntity',
          args: [{ name: 'request', type: 'DuplicateRequest!' }],
        },
      },
    },
    fieldDefinitions: fields,
  };
