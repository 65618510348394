import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IFilterPickerConfig } from '../../../../components/entity-picker/components/list-entity-picker';
import { MailAssignStatusPicker } from '../../../../components/entity-picker/MailAssignStatusPicker/MailAssignStatusPicker';
import { FilterCard } from '../filter-card/FilterCard';

export type MailAssignStatusFilterCardProps = {
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any[] | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (value: any[]) => void;
  expanded: boolean;
  onExpandedChange: (val: boolean) => void;
  filterConfig?: IFilterPickerConfig;
};

export function MailAssignStatusFilterCard(
  props: MailAssignStatusFilterCardProps
) {
  const { title, value, onChange, expanded, onExpandedChange } = props;

  const { t } = useTranslation();

  const filterValueText: string = useMemo(() => {
    if (!value || value.length === 0) return null;

    return value.length + ' ' + t('COMMON.CHOSEN');
  }, [value, t]);

  return (
    <FilterCard
      title={title}
      filterValue={filterValueText}
      filterValueSet={!!filterValueText}
      expanded={expanded}
      onExpandedChange={onExpandedChange}
      onValueDelete={() => {
        onChange([]);
      }}
    >
      <MailAssignStatusPicker
        value={value}
        onChange={(newValue) => {
          onChange(newValue);
        }}
      />
    </FilterCard>
  );
}
