import React from 'react';
import { useTranslation } from 'react-i18next';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { BusinessPartnerPickerField } from './BusinessPartnerPickerField';
import { CustomerPickerField } from './CustomerPickerField';
import { SupplierPickerField } from './SupplierPickerField';

interface PartnerPickerByEntityProps {
  entity: Entities;
  onChange: (selected: (Supplier | Customer)[]) => void;
  businessPartner: Supplier | Customer;
}

export function PartnerPickerByEntity(props: PartnerPickerByEntityProps) {
  const { entity, onChange, businessPartner } = props;
  const { t } = useTranslation();

  if (entity === Entities.customer)
    return (
      <CustomerPickerField
        label=""
        placeholder={t('COMMON.PICK_FROM', {
          from: t('COMMON.BUSINESSPARTNER'),
        })}
        onChange={(bp) => onChange([bp])}
        value={businessPartner as Customer}
      />
    );
  else if (entity === Entities.supplier)
    return (
      <SupplierPickerField
        label=""
        placeholder={t('COMMON.PICK_FROM', {
          from: t('COMMON.BUSINESSPARTNER'),
        })}
        onChange={(bp) => onChange([bp])}
        value={businessPartner as Supplier}
      />
    );
  return (
    <BusinessPartnerPickerField
      onChange={(bp) => onChange([bp.data])}
      value={businessPartner}
    />
  );
}
