import styles from './Relations.module.scss';

import React, { useState } from 'react';
import { TableInstance } from 'react-table';

import { TableStateBagProvider } from '@work4all/components';

import { PrintBagProvider } from '@work4all/data/lib/hooks/useHandlePrint';

import { Panel } from '../../../../../components/panel/Panel';
import { UpdateEntity } from '../../../../../components/update-entity/UpdateEntity';
import { useMaskContext } from '../../../../../hooks/mask-context';
import { RelationsTable } from '../../../../contacts/components/tab-panels/pr/components/relations-section/RelationsTable';
import { BusinessPartners } from '../../../BusinessPartnerOverlayController';

export const RelationsTabPanel: React.FC = () => {
  const { data: entityData } = useMaskContext<BusinessPartners>();
  const [tableInstance, setTableInstance] = useState<TableInstance>(null);

  return (
    <Panel className={styles.panel}>
      {!entityData.id && <UpdateEntity />}
      {entityData?.id && (
        <PrintBagProvider>
          <TableStateBagProvider tableInstance={tableInstance}>
            <RelationsTable ref={setTableInstance} />
          </TableStateBagProvider>
        </PrintBagProvider>
      )}
    </Panel>
  );
};
