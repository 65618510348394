import Typography from '@mui/material/Typography';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDataProvider } from '@work4all/data/lib/hooks/data-provider';

import { VacationKind } from '@work4all/models/lib/Classes/VacationKind.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type IVacationKindPickerProps<TMultiple extends boolean> = IPickerProps<
  VacationKind,
  TMultiple
>;

export function VacationKindPicker<TMultiple extends boolean>(
  props: IVacationKindPickerProps<TMultiple>
) {
  const { data, ...rest } = props;

  const { t } = useTranslation();
  const allFields = useMemo(() => {
    return { ...VACATION_KIND_FIELDS, ...data };
  }, [data]);

  const requestData = useMemo<DataRequest>(() => {
    return {
      entity: Entities.vacationKind,
      data: allFields,
      completeDataResponse: true,
    };
  }, [allFields]);

  const response = useDataProvider<VacationKind>(requestData);

  const dataset = useMemo(() => {
    const data = [
      {
        id: 0,
        name: t('COMMON.VACATION_KIND_NULL_LABEL'),
      },
      ...response.data,
    ];

    return {
      total: data.length,
      loading: false,
      fetchMore: () => Promise.resolve(),
      refetch: () => Promise.resolve(null),
      data: data,
    };
  }, [response.data, t]);

  return (
    <ListEntityPicker
      entity={Entities.vacationKind}
      data={allFields}
      fixedDataSet={dataset}
      filterBy="name"
      sortBy="name"
      {...rest}
      renderItemContent={(vacationKind) => (
        <Tooltip title={vacationKind.name}>
          <Typography variant="body2" noWrap>
            {vacationKind.name}
          </Typography>
        </Tooltip>
      )}
    />
  );
}

const VACATION_KIND_FIELDS: VacationKind = {
  id: null,
  name: null,
};
