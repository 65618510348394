import styles from './FieldsSection.module.scss';

import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { BasicTableColumn, Table } from '@work4all/components';
import { Collapse } from '@work4all/components/lib/components/collapse';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';

import { useUser } from '@work4all/data';
import {
  useSetting,
  UseSettingReturn,
} from '@work4all/data/lib/settings/use-setting';

import { settings } from '../../../../../../../../settings';

export function FieldsSection() {
  const { t } = useTranslation();

  const projectTimeTrackingCustomerMandatory = useSetting(
    settings.projectTimeTrackingCustomerMandatory()
  );
  const projectTimeTrackingTicketMandatory = useSetting(
    settings.projectTimeTrackingTicketMandatory()
  );
  const projectTimeTrackingProjectMandatory = useSetting(
    settings.projectTimeTrackingProjectMandatory()
  );
  const projectTimeTrackingActivityMandatory = useSetting(
    settings.projectTimeTrackingActivityMandatory()
  );
  const projectTimeTrackingContractMandatory = useSetting(
    settings.projectTimeTrackingContractMandatory()
  );
  const projectTimeTrackingPositionMandatory = useSetting(
    settings.projectTimeTrackingPositionMandatory()
  );
  const projectTimeTrackingProjectProcessMandatory = useSetting(
    settings.projectTimeTrackingProjectProcessMandatory()
  );

  const projectTimeTrackingShowActivity = useSetting(
    settings.projectTimeTrackingShowActivity()
  );
  const projectTimeTrackingShowContract = useSetting(
    settings.projectTimeTrackingShowContract()
  );

  const projectTimeTrackingShowCustomer = useSetting(
    settings.projectTimeTrackingShowCustomer()
  );
  const projectTimeTrackingShowPosition = useSetting(
    settings.projectTimeTrackingShowPosition()
  );
  const projectTimeTrackingShowProject = useSetting(
    settings.projectTimeTrackingShowProject()
  );
  const projectTimeTrackingShowProjectProcess = useSetting(
    settings.projectTimeTrackingShowProjectProcess()
  );
  const projectTimeTrackingShowTicket = useSetting(
    settings.projectTimeTrackingShowTicket()
  );

  const apiRef = useRef<TableInstance>(null);

  const user = useUser();

  const fieldsData = useMemo<
    {
      name: string;
      mandatory: UseSettingReturn;
      display: UseSettingReturn;
      onDisplayToggle?: (display: boolean) => void;
      onMandatoryToggle?: (mandatory: boolean) => void;
    }[]
  >(() => {
    return [
      {
        name: t('COMMON.CUSTOMER'),
        display: projectTimeTrackingShowCustomer,
        mandatory: projectTimeTrackingCustomerMandatory,
        onDisplayToggle: (display) => {
          if (!display) {
            projectTimeTrackingCustomerMandatory.set(false);
          }
        },
        onMandatoryToggle: (mandatory) => {
          if (mandatory) {
            projectTimeTrackingShowCustomer.set(true);
          }
        },
      },
      {
        name: t('COMMON.PROJECT'),
        display: projectTimeTrackingShowProject,
        mandatory: projectTimeTrackingProjectMandatory,
        onDisplayToggle: (display) => {
          if (!display) {
            projectTimeTrackingProjectMandatory.set(false);
            projectTimeTrackingShowProjectProcess.set(false);
            projectTimeTrackingProjectProcessMandatory.set(false);
          }
        },
        onMandatoryToggle: (mandatory) => {
          if (mandatory) {
            projectTimeTrackingShowProject.set(true);
          }
        },
      },
      {
        name: t('COMMON.PROJECT_PROCESS'),
        display: projectTimeTrackingShowProjectProcess,
        mandatory: projectTimeTrackingProjectProcessMandatory,
        onDisplayToggle: (display) => {
          if (!display) {
            projectTimeTrackingProjectProcessMandatory.set(false);
          } else {
            projectTimeTrackingShowProject.set(true);
          }
        },
        onMandatoryToggle: (mandatory) => {
          if (mandatory) {
            projectTimeTrackingShowProject.set(true);
            projectTimeTrackingShowProjectProcess.set(true);
          }
        },
      },
      {
        name: t('COMMON.CONTRACT'),
        display: projectTimeTrackingShowContract,
        mandatory: projectTimeTrackingContractMandatory,
        onDisplayToggle: (display) => {
          if (!display) {
            projectTimeTrackingContractMandatory.set(false);
            projectTimeTrackingShowPosition.set(false);
            projectTimeTrackingPositionMandatory.set(false);
          }
        },
        onMandatoryToggle: (mandatory) => {
          if (mandatory) {
            projectTimeTrackingShowContract.set(true);
          } else {
            projectTimeTrackingPositionMandatory.set(false);
          }
        },
      },
      {
        name: t('COMMON.POSITION'),
        display: projectTimeTrackingShowPosition,
        mandatory: projectTimeTrackingPositionMandatory,
        onDisplayToggle: (display) => {
          if (!display) {
            projectTimeTrackingPositionMandatory.set(false);
          } else {
            projectTimeTrackingShowContract.set(true);
          }
        },
        onMandatoryToggle: (mandatory) => {
          if (mandatory) {
            projectTimeTrackingShowPosition.set(true);
            projectTimeTrackingShowContract.set(true);
            projectTimeTrackingContractMandatory.set(true);
          }
        },
      },
      {
        name: t('COMMON.ACTIVITY'),
        display: projectTimeTrackingShowActivity,
        mandatory: projectTimeTrackingActivityMandatory,
        onDisplayToggle: (display) => {
          if (!display) {
            projectTimeTrackingActivityMandatory.set(false);
          }
        },
        onMandatoryToggle: (mandatory) => {
          if (mandatory) {
            projectTimeTrackingShowActivity.set(true);
          }
        },
      },
      {
        name: t('COMMON.TICKET'),
        display: projectTimeTrackingShowTicket,
        mandatory: projectTimeTrackingTicketMandatory,
        onDisplayToggle: (display) => {
          if (!display) {
            projectTimeTrackingTicketMandatory.set(false);
          }
        },
        onMandatoryToggle: (mandatory) => {
          if (mandatory) {
            projectTimeTrackingShowTicket.set(true);
          }
        },
      },
    ];
  }, [
    projectTimeTrackingActivityMandatory,
    projectTimeTrackingContractMandatory,
    projectTimeTrackingCustomerMandatory,
    projectTimeTrackingPositionMandatory,
    projectTimeTrackingProjectMandatory,
    projectTimeTrackingProjectProcessMandatory,
    projectTimeTrackingShowActivity,
    projectTimeTrackingShowContract,
    projectTimeTrackingShowCustomer,
    projectTimeTrackingShowPosition,
    projectTimeTrackingShowProject,
    projectTimeTrackingShowProjectProcess,
    projectTimeTrackingShowTicket,
    projectTimeTrackingTicketMandatory,
    t,
  ]);

  const filteredFieldsData = useMemo(() => {
    let newFieldsData = fieldsData;

    if (projectTimeTrackingShowContract.value === false) {
      newFieldsData = fieldsData.filter(
        (field) => field.name !== t('COMMON.POSITION')
      );
    }

    return newFieldsData;
  }, [fieldsData, projectTimeTrackingShowContract.value, t]);

  const fieldsColumns = useMemo<BasicTableColumn[]>(() => {
    const result: BasicTableColumn[] = [
      {
        Header: t('Name'),
        accessor: 'name',
        width: 150,
      },
      {
        Header: t('COMMON.DISPLAY'),
        accessor: 'display',
        alignment: 'center',
        width: 100,
        Cell: ({ value, row }: { value: UseSettingReturn<boolean>; row }) => {
          return (
            <div className={styles.checkBoxWrap}>
              <CheckboxRadioItem
                disabled={
                  !user.isMaster || row.original.name === t('COMMON.PROJECT')
                }
                className={styles.checkBox}
                checked={value.value}
                onChange={(e) => {
                  value.set(e.target.checked);
                  if (row.original.onDisplayToggle) {
                    row.original.onDisplayToggle(e.target.checked);
                  }
                }}
                label=" "
              />
            </div>
          );
        },
      },

      {
        Header: t('COMMON.MANDATORY_FIELD'),
        accessor: 'mandatory',
        alignment: 'center',
        width: 100,
        Cell: ({ value, row }: { value: UseSettingReturn<boolean>; row }) => {
          return (
            <div className={styles.checkBoxWrap}>
              <CheckboxRadioItem
                disabled={
                  !user.isMaster || row.original.name === t('COMMON.PROJECT')
                }
                className={styles.checkBox}
                checked={value.value}
                onChange={(e) => {
                  value.set(e.target.checked);
                  if (row.original.onMandatoryToggle) {
                    row.original.onMandatoryToggle(e.target.checked);
                  }
                }}
                label=" "
              />
            </div>
          );
        },
      },
    ];

    return result;
    // ToDo: RK check if this is correct, just disbaled to please linting
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  return (
    <Collapse title={t('SETTINGS.PROJECT_TIME.FIELDS')} defaultOpen>
      <div className={styles.tableWrap}>
        <Table
          mode="client"
          ref={apiRef}
          data={filteredFieldsData}
          columns={fieldsColumns}
          allItemsCount={filteredFieldsData.length}
          selectableRows={false}
        />
      </div>
    </Collapse>
  );
}
