import { Capacitor } from '@capacitor/core';

import { APP_VERSION } from '../../../config';

// API response definition
interface SoftwareInformation {
  version: string;
  versionStatus: VersionUpdateStatus;
}

const enum VersionUpdateStatus {
  /** Unable to read assigned version. Error in the lic data. */
  InvalidVersion = -2,

  /** Unable to read current executing/calling application version state. */
  InvalidCurrentVersion = -1,

  /** Version matches. */
  VersionOk = 0,

  /** The current executing/calling application version is higher then the assigned version. */
  VersionIsHigherThenAssigned = 1,

  /** There is a update available, just follow the white 🐰. */
  UpdateAvailable = 2,
}

const ApplicationType: Record<string, string> = {
  web: 'BrowserApp',
  android: 'AndroidApp',
  ios: 'IosApp',
};

export interface CheckForUpdatesResult {
  isUpdateAvailable: boolean;
}

export async function checkForUpdates({
  baseUrl,
  token,
}: {
  baseUrl: string;
  token: string;
}) {
  const platform = Capacitor.getPlatform();

  const applicationType = ApplicationType[platform];

  if (!applicationType) {
    throw new Error(`Unknown platform ${platform}`);
  }

  // The backend server uses version format "X.X.X", but our app uses "X.X.X".
  // Append another 0 at the end so the versions are matched correctly.
  const currentVersionFormatted = `${APP_VERSION}.0`;

  return fetch(
    `${baseUrl}/api/status/software?applicationType=${applicationType}&currentVersion=${currentVersionFormatted}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${token}`,
        accept: 'application/json',
        'x-work4all-apiurl': baseUrl,
      },
    }
  )
    .then<SoftwareInformation>((response) => {
      if (!response.ok) {
        throw new Error('Could not fetch app version info');
      }

      return response.json();
    })
    .then<CheckForUpdatesResult>((softwareInformation) => {
      // Use optional chaining here just in case API response changes later to
      // avoid throwing.
      return {
        isUpdateAvailable:
          softwareInformation?.versionStatus ===
          VersionUpdateStatus.UpdateAvailable,
      };
    });
}
