import { useCallback } from 'react';

import { useDataMutation } from '@work4all/data';

import { BzObjectConversionResult } from '@work4all/models/lib/Classes/BzObjectConversionResult.entity';
import { ErpObjectConversionRequest } from '@work4all/models/lib/Classes/ErpObjectConversionRequest.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { convertEntityToBzObjType } from '../../../../containers/mask-overlays/mask-overlay/views/erp/hooks/use-bz-shadow-object-api/use-shadow-bz-object-api';

interface UseConvertBzObjectMutationOptions {
  targetEntity: Entities;
}

interface ConvertBzObjectMutationArgs {
  sourceEntity: Entities;
  sourceIds: number[];
  targetEntity?: Entities;
}

export const useConvertBzObjectMutation = (
  opt?: UseConvertBzObjectMutationOptions
) => {
  const { targetEntity } = opt ?? {};
  const [mutate] = useDataMutation<BzObjectConversionResult, EMode.upsert>({
    entity: Entities.bzObjectConversionResult,
    mutationType: EMode.upsert,
    // TODO: workaround - there is no difference between Input & Output types in hook ?
    wrapInput: false,
    responseData: {
      bzObject: targetEntity
        ? {
            [targetEntity]: {
              id: null,
            },
          }
        : {},
    },
  });

  const callback = useCallback(
    async (args: ConvertBzObjectMutationArgs) => {
      const request: ErpObjectConversionRequest = {
        sourceBzObjectType: convertEntityToBzObjType(args.sourceEntity),
        sourceBzObjectCodes: args.sourceIds,
        targetBzObjectType: convertEntityToBzObjType(args.targetEntity),
      };
      // TODO: workaround - same issue as above if flag wrapInput is false it's not wrapped and not translated
      await mutate({ request });
    },
    [mutate]
  );
  return {
    mutate: callback,
  };
};
