import {
  IFavoriteLink,
  IFavoriteLinkOrFolder,
  IFavoriteLinksFolder,
} from './types';

export function isFavoriteLink(
  linkOrFolder: IFavoriteLinkOrFolder
): linkOrFolder is IFavoriteLink {
  return linkOrFolder.kind === 'link';
}

export function isFavoriteLinksFolder(
  linkOrFolder: IFavoriteLinkOrFolder
): linkOrFolder is IFavoriteLinksFolder {
  return linkOrFolder.kind === 'folder';
}

export function generateNewFolderName(links: IFavoriteLinkOrFolder[]): string {
  const folders = links.filter(isFavoriteLinksFolder);

  const newFolderNameRegex = /^Neuer Ordner (?<order>\d+)$/;

  const maxFolderOrder = folders
    .map((folder) => folder.name)
    .reduce((max, name) => {
      const result = newFolderNameRegex.exec(name);

      if (result === null) {
        return max;
      } else {
        return Math.max(max, Number(result.groups.order));
      }
    }, 0);

  const folderName = `Neuer Ordner ${maxFolderOrder + 1}`;

  return folderName;
}
