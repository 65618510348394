import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { BusinessPartner } from './BusinessPartner.entity';
import { Contact } from './Contact.entity';

export class BusinessPartnerContactCombined<T extends EMode = EMode.entity> {
  /** Alias: ansprechpartner */
  contact?: Contact<T>;
  /** Alias: geschaeftspartner */
  businessPartner?: BusinessPartner<T>;

  __typename?: string;
}

const fields: FieldDefinitions<BusinessPartnerContactCombined> = {
  contact: {
    alias: 'ansprechpartner',
    entity: Entities.contact,
  },
  businessPartner: {
    alias: 'geschaeftspartner',
    entity: Entities.businessPartner,
  },
  __typename: {
    alias: '__typename',
  },
};

export const businessPartnerContactCombinedEntityDefinition: EntitiyDefinition<BusinessPartnerContactCombined> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'Nebenadresse',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
