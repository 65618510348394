export interface IndexRange {
  /** The start index of the range, inclusive. */
  startIndex: number;
  /** The stop index of the range, inclusive. */
  stopIndex: number;
}

export interface Pagination {
  page: number;
  size: number;
}

/**
 * Convert an index range received from InfiniteLoader to a DataProvider-style
 * pagination.
 */
export function indexRangeToPagination(range: IndexRange): Pagination {
  const { startIndex, stopIndex } = range;

  const offset = startIndex;
  const limit = stopIndex - startIndex + 1;

  for (let size = limit; size <= offset + limit; size++) {
    for (let leftShift = 0; leftShift <= size - limit; leftShift++) {
      if ((offset - leftShift) % size === 0) {
        const page = (offset - leftShift) / size;

        return { page, size };
      }
    }
  }
}
