import { MouseEventHandler } from 'react';

import { VisitationReport } from '@work4all/models/lib/Classes/VisitationReport.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';

import { LockProvider } from '../../../hooks';
import { IUserIconRegisterContext } from '../../user-icon/types';

import { useVisitationReportPreviewData } from './use-visitation-report-preview-data';
import { VisitationReportPreview } from './VisitationReportPreview';

export type IVisitationReportPreviewContainerProps = {
  visitationReportIds: number[];

  userIconContext: IUserIconRegisterContext;
  onCloseClicked?: MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onShareClicked?: () => void;
};

export const visitationReportPreviewFields: VisitationReport<EMode.query> = {
  id: null,
  kind: null,
  creationDate: null,
  startTime: null,
  userId: null,
  attendeeList: [
    {
      id: null,
      customerNumberVisitationId: null,
      name: null,
      collegueId: null,
      sDObjType: null,
      businessPartnerId: null,
      contactId: null,
      contactType: null,
      contact: {
        id: null,
        businessPartner: {
          id: null,
          data: {
            customer: {
              id: null,
              name: null,
              website: null,
            },
            supplier: {
              id: null,
              name: null,
              website: null,
            },
          },
        },
      },
      collegue: {
        id: null,
        firstName: null,
        lastName: null,
        displayName: null,
      },
    },
  ],
  talkingPointList: [
    {
      id: null,
      thema: null,
      wer: null,
      bisWann: null,
      whattodoCode: null,
      customerNumberVisitationId: null,
      erledigung: null,
      erledigt: null,
      oK: null,
      kind: null,
      vereinbarung: null,
      pozNr: null,
    },
  ],
  user: {
    id: null,
    firstName: null,
    lastName: null,
    shortName: null,
    displayName: null,
    userKind: null,
  },
  businessPartner: {
    id: null,
    businessPartnerType: null,
    data: {
      customer: {
        id: null,
        name: null,
        website: null,
      },
      supplier: {
        id: null,
        name: null,
        website: null,
      },
    },
  },
  contact: {
    id: null,
    name: null,
    firstName: null,
    displayName: null,
  },
  project: {
    id: null,
    name: null,
  },
  topic: null,
  fileInfos: {
    fileEntityFilename: null,
    downloadUrl: null,
    previewUrl: null,
    fileRequestType: null,
    downloadMimeType: null,
    previewMimeType: null,
    fileSize: null,
    fileServiceProviderInfos: {
      id: null,
      fileName: null,
      mimeType: null,
      fspUrl: null,
    },
  },
};

export const VisitationReportPreviewContainer = (
  props: IVisitationReportPreviewContainerProps
) => {
  const {
    visitationReportIds,
    userIconContext,
    onCloseClicked,
    onEditClicked,
    onShareClicked,
  } = props;

  const visitationReports = useVisitationReportPreviewData(
    visitationReportIds,
    visitationReportPreviewFields
  );

  if (!visitationReports) {
    return null;
  }

  return (
    <LockProvider>
      <VisitationReportPreview
        visitationReports={visitationReports}
        userIconContext={userIconContext}
        onCloseClicked={onCloseClicked}
        onEditClicked={onEditClicked}
        onShareClicked={onShareClicked}
      />
    </LockProvider>
  );
};
