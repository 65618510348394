import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { AppointmentState } from '@work4all/models/lib/Classes/AppointmentState.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useCalendarSelection } from '../../../hooks/useCalendarSelection';
import { DEFAULT_APPOINTMENT_STATE } from '../constants';

export function useAppointmentStatesHelper({
  selectedAppointmentStates,
  onSelectedAppointmentStatesChange,
}: {
  selectedAppointmentStates: number[];
  onSelectedAppointmentStatesChange: (states: number[]) => void;
}) {
  const dataRequest = useMemo<DataRequest>(() => {
    return {
      operationName: 'GetAppointmentStates',
      entity: Entities.appointmentState,
      data: {
        id: null,
        name: null,
        hexColorForeground: null,
      } as AppointmentState,
      completeDataResponse: true,
    };
  }, []);

  const request = useDataProvider<AppointmentState>(dataRequest);

  const appointmentStates = useMemo(() => {
    return [
      DEFAULT_APPOINTMENT_STATE,
      ...request.data.slice().sort((a, b) => a.name.localeCompare(b.name)),
    ];
  }, [request.data]);

  const { isSelected, isEverythingSelected, toggle, toggleAll } =
    useCalendarSelection({
      items: appointmentStates,
      selectedItem: selectedAppointmentStates,
      onChange: onSelectedAppointmentStatesChange,
    });

  return {
    appointmentStates,
    isSelected,
    isEverythingSelected,
    toggle,
    toggleAll,
  };
}
