import {
  LazyQueryHookOptions,
  QueryHookOptions,
  useLazyQuery as useLazyApolloQuery,
  useQuery as useApolloQuery,
} from '@apollo/client';

import { IGenerateParams } from '@work4all/models/lib/DataProvider';

import { generateGraphQLQuery } from './generateGraphQLQuery';

export const generateUseGraphQLQuery = (params: IGenerateParams) => {
  const {
    query: QUERY,
    queryString,
    queryInner,
  } = generateGraphQLQuery(params);

  const useQuery = <Data, DataVariables>(
    options?: QueryHookOptions<Data, DataVariables>
  ) => {
    return useApolloQuery<Data, DataVariables>(QUERY, options);
  };

  const useLazyQuery = <Data, DataVariables>(
    options?: LazyQueryHookOptions<Data, DataVariables>
  ) => {
    return useLazyApolloQuery<Data, DataVariables>(QUERY, options);
  };

  return { useQuery, useLazyQuery, query: QUERY, queryString, queryInner };
};
