import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { Sickness } from '@work4all/models/lib/Classes/Sickness.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { Vacation } from '@work4all/models/lib/Classes/Vacation.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export interface UseAbsencesOptions {
  from: Date;
  to: Date;
  userIds?: number[];
  skip?: boolean;
}

const PAGE_SIZE = 10_000;

export function useAbsences(options: UseAbsencesOptions) {
  const { from, to, skip = false, userIds = [] } = options;

  const vars = skip
    ? { from: '', to: '' }
    : {
        from: DateTime.fromJSDate(from).toISODate(),
        to: DateTime.fromJSDate(to).toISODate(),
      };

  const fullUserRequest = useMemo(() => {
    const fields: User = {
      id: null,
      displayName: null,
      hasLeft: null,
      vacationApprover: {
        id: null,
        displayName: null,
      },
    };

    const request: DataRequest = {
      entity: Entities.user,
      data: fields,
      operationName: 'GetUsers',
      filter: [
        {
          id: { $in: userIds },
        },
      ],
    };

    return request;
  }, [userIds]);

  const vacationrequest = useMemo(() => {
    const fields: Vacation = {
      id: null,
      user: {
        id: null,
        displayName: null,
        hasLeft: null,
        vacationApprover: {
          id: null,
          displayName: null,
        },
      },
      date: null,
      applicationDate: null,
      approveDate: null,
      amount: null,
      note: null,
      vacationDayPosition: null,
      vacationApprover: {
        id: null,
        displayName: null,
        shortName: null,
      },
      vacationKind: {
        id: null,
        name: null,
      },
      simpleVacationKind: {
        id: null,
        name: null,
        color: null,
      },
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filter: any = [
      { date: { $gte: vars.from } },
      { date: { $lt: vars.to } },
      { 'user.id': { $ne: null } },
      { 'user.hasLeft': { $eq: false } },
    ];
    if (userIds.length > 0) {
      filter.push({
        userId: { $in: userIds },
      });
    }

    const request: DataRequest = {
      entity: Entities.vacation,
      data: fields,
      operationName: 'GetVacations',
      filter,
    };

    return request;
  }, [userIds, vars.from, vars.to]);

  const sicknessRequest = useMemo(() => {
    const fields: Sickness = {
      id: null,
      date: null,
      user: {
        id: null,
        displayName: null,
      },
      applicationDate: null,
      approveDate: null,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filter: any = [
      { date: { $gte: vars.from } },
      { date: { $lt: vars.to } },
      { 'user.id': { $ne: null } },
    ];
    if (userIds.length > 0) {
      filter.push({
        userId: { $in: userIds },
      });
    }

    const request: DataRequest = {
      entity: Entities.sickness,
      data: fields,
      operationName: 'GetSicknesses',
      filter,
    };

    return request;
  }, [userIds, vars.from, vars.to]);

  // Use a large page size since we can't use pagination here.
  const { data: vacations, total: vacationTotal } = useDataProvider<Vacation>(
    vacationrequest,
    skip,
    PAGE_SIZE
  );
  const { data: sicknesses, total: sicknessTotal } = useDataProvider<Vacation>(
    sicknessRequest,
    skip,
    PAGE_SIZE
  );
  const { data: usersData } = useDataProvider<User>(
    fullUserRequest,
    userIds.length === 0,
    userIds.length
  );

  useEffect(() => {
    if (vacationTotal > PAGE_SIZE) {
      console.warn(
        [
          `Loaded ${vacationTotal} out of ${PAGE_SIZE} vacations because of page size limit.`,
          `Some of the vacations might be missing.`,
        ].join(' ')
      );
    }
  }, [vacationTotal]);

  const result = useMemo(() => {
    const users = new Map<number, User>();

    //if explicitly requested for a set of users return them
    if (userIds.length > 0) {
      usersData.forEach((user) => {
        users.set(user.id, user);
      });
    } else {
      //elsewise return the users that are having vacations in this timespan
      for (const vacation of vacations) {
        const { user } = vacation;
        users.set(user.id, user);
      }
    }

    return {
      users: [...users.values()],
      vacations: vacations,
      sicknesses,
    };
  }, [vacations, userIds.length, usersData, sicknesses]);

  return result;
}
