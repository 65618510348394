import styles from './../../../../../MaskOverlay.module.scss';

import { Panel } from '../../../../../components/panel/Panel';
import { DocumentView } from '../../../DocumentView';

export const GeneralTabPanel = () => {
  return (
    <Panel className={styles.tabsWrapper}>
      <DocumentView />
    </Panel>
  );
};
