import { entityDefinition } from '@work4all/models/lib/Classes/entityDefinitions';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { invariant } from '@work4all/utils';

function getEntityDefinition(entityType: Entities) {
  const definition = entityDefinition[entityType];
  invariant(
    definition,
    `Could not find definition for entity type ${entityType}`
  );

  return definition;
}

export function entityHasProperty(
  entityType: Entities,
  propertyName: string
): boolean {
  const definition = getEntityDefinition(entityType);
  return propertyName in definition.fieldDefinitions;
}
