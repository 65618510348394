import styles from '../EntityPicker.module.scss';

import { Tooltip, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Contract } from '@work4all/models/lib/Classes/Contract.entity';
import { ServiceContract } from '@work4all/models/lib/Classes/ServiceContract.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ListEntityPicker } from '../components/list-entity-picker';
import { IPickerWithTabsProps } from '../types';

export type IContractServiceContractPickerProps<
  TMultiple extends boolean = boolean
> = IPickerWithTabsProps<Contract | ServiceContract, TMultiple>;
export function ContractServiceContractPicker<
  TMultiple extends boolean = boolean
>(props: IContractServiceContractPickerProps<TMultiple>) {
  const { data, prefilter, onChange, onTabChange, ...rest } = props;

  const { t } = useTranslation();

  const allFields = useMemo(() => {
    return { ...COMMON_FIELDS, ...data };
  }, [data]);

  const renderItem = useCallback((contract: Contract | ServiceContract) => {
    const numberAccessor =
      contract.__typename === 'Auftrag'
        ? 'contractNumber'
        : 'serviceContractNumber';

    return (
      <Tooltip title={`${contract[numberAccessor]} ${contract.note}`}>
        <Typography variant="body2" noWrap>
          <span className={styles.secondaryInfo}>
            {contract[numberAccessor]} |{' '}
          </span>
          {contract.note}
        </Typography>
      </Tooltip>
    );
  }, []);

  const tabs = useMemo(() => {
    return [
      {
        entity: Entities.contract,
        data: { ...(allFields as Contract), ...CONTRACT_FIELDS },
        label: t('COMMON.CONTRACT'),
        filterBy: ['note', 'contractNumber', 'number'],
        sortBy: 'contractDate',
        sortByDirection: SortDirection.DESCENDING,
        prefilter,
        renderItemContent: renderItem,
      },
      {
        entity: Entities.serviceContract,
        data: { ...(allFields as ServiceContract), ...SERVICE_CONTRACT },
        label: t('COMMON.SERVICECONTRACT'),
        filterBy: ['note', 'serviceContractNumber', 'number'],
        sortBy: 'creationDate',
        sortByDirection: SortDirection.DESCENDING,
        prefilter,
        renderItemContent: renderItem,
      },
    ];
  }, [allFields, prefilter, renderItem, t]);

  return (
    <ListEntityPicker
      {...rest}
      onChange={onChange}
      getTabIndex={getTabIndex}
      onTabChange={onTabChange}
      tabs={tabs}
    />
  );
}

const COMMON_FIELDS: Contract | ServiceContract = {
  id: null,
  number: null,
  note: null,
};

const CONTRACT_FIELDS: Contract = {
  contractNumber: null,
  positionList: [
    {
      id: null,
      positionKind: null,
      number: null,
      longtext: null,
      amount: null,
      unit: null,
    },
  ],
  contractDate: null,
};

const SERVICE_CONTRACT: ServiceContract = {
  serviceContractNumber: null,
  creationDate: null,
};

function getTabIndex(value: Contract | ServiceContract): 0 | 1 {
  return value.__typename === 'Auftrag' ? 0 : 1;
}
