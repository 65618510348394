export const ARTICLE_POSITIONS_DATA = {
  articleKind: null,
  slModes: [
    {
      id: null,
      itemId: null,
      slMode: null,
      bzObjType: null,
    },
  ],
};
