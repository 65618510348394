import { useEventCallback } from '@mui/material/utils';
import throttle from 'lodash.throttle';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';
import { useEntityChanges } from '@work4all/data/lib/hooks/use-entity-changed';

import { DataRequest } from '@work4all/models/lib/DataProvider';
import { ChangeType } from '@work4all/models/lib/Enums/ChangeType.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

const CHANGE_TYPE = [
  ChangeType.ITEM_INSERTED,
  ChangeType.ITEM_UPDATED,
  ChangeType.ITEM_DELETED,
];

interface UseWidgetEntryDataProps {
  entity: Entities;
  filter: DataRequest['filter'];
  data?: DataRequest['data'];
  skip?: boolean;
  size?: number;
}

const CONST_OBJECT = {};
export const useWidgetEntryData = <T>(props: UseWidgetEntryDataProps) => {
  const { entity, filter, skip, size, data = CONST_OBJECT } = props;

  const dataRequest: DataRequest = useMemo(() => {
    return {
      entity,
      data: data,
      filter,
    };
  }, [entity, filter, data]);

  const response = useDataProvider<T>(dataRequest, skip, size);

  useRefetchOnSubscription({ entity, refetch: response.refetch });
  return response;
};

export const useRefetchOnSubscription = (
  props: Pick<UseWidgetEntryDataProps, 'entity'> & { refetch: () => void }
) => {
  const { entity, refetch } = props;
  const refetchCallback = useEventCallback(refetch);
  const onEntityChanged = useMemo(
    () => throttle(refetchCallback, 500),
    [refetchCallback]
  );
  useEntityChanges({
    entity,
    changeType: CHANGE_TYPE,
    onEntityChanged,
  });
};
