import { useState } from 'react';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { BottomViewProps } from '../interfaces';
import { Tab } from '../types';

import { BottomViewHeader } from './BottomViewHeader';
import { BottomViewTabContent } from './BottomViewTabContent';

export const BottomView = ({ selectedEntityId }: BottomViewProps) => {
  const [tab, setTab] = useState<Tab>(Entities.rELedgerAccountSplit);
  const [isCollapsed, setIsCollapsed] = useState(false);

  if (isCollapsed) {
    return (
      <BottomViewHeader
        tab={tab}
        onTabChange={(value) => setTab(value)}
        isCollapsed={isCollapsed}
        onCollapsedChange={setIsCollapsed}
      />
    );
  }

  return (
    <div style={{ height: '300px' }}>
      <BottomViewHeader
        tab={tab}
        onTabChange={(value) => setTab(value)}
        isCollapsed={isCollapsed}
        onCollapsedChange={setIsCollapsed}
      />

      <BottomViewTabContent tab={tab} selectedEntityId={selectedEntityId} />
    </div>
  );
};
