import { ColumnInstance } from '@work4all/components';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { settings, useSetting } from '../../../settings';
import { TableQuickFiltersSection } from '../table-quick-filters';

export interface ClosedStatusQuickFilterProps {
  entityType: Entities;
  column: ColumnInstance | null;
  label: string;
}

export function ClosedStatusQuickFilter(props: ClosedStatusQuickFilterProps) {
  const { entityType, column, label } = props;

  const closed = column?.filterValue?.value ?? null;

  const { set: setHideClosedEntitiesSetting } = useSetting(
    settings.hideClosedEntities({ entityType })
  );

  const onClosedChange = (closed: boolean) => {
    if (!column) {
      return;
    }

    column.setFilter({
      value: closed,
      filterType: column.filterType,
    });

    setHideClosedEntitiesSetting(closed);
  };

  return (
    <TableQuickFiltersSection>
      <Divider title={label} horizontalPadding />
      <CheckboxRadioItem
        label={label}
        control="checkbox"
        checked={closed === true}
        // @ts-expect-error The type for this component's props is wrong
        onChange={(_event: unknown, value: boolean) => {
          onClosedChange(value);
        }}
      />
    </TableQuickFiltersSection>
  );
}
