import styles from './FileNavigation.module.scss';

import { Theme, useMediaQuery } from '@mui/material';
import React from 'react';

import { Card } from '@work4all/components/lib/dataDisplay/card';
import { SearchBarWithDropDown } from '@work4all/components/lib/input/search-bar-with-dropdown/SearchBarWithDropdown';
import {
  ISearchBarWidthFullScreenProps,
  SearchBarWithFullScreen,
} from '@work4all/components/lib/input/search-bar-with-fullscreen/SearchBarWithFullScreen';
import {
  MobileNavigationItemBase,
  MobileNavigationItemProps,
} from '@work4all/components/lib/navigation/mobile-navigation/MobileNavigationItem';
import { useMobileNavigation } from '@work4all/components/lib/navigation/mobile-navigation/useMobileNavigation';

type Props = ISearchBarWidthFullScreenProps;

export const FileNavigationItem: React.FC<MobileNavigationItemProps> = (
  props: MobileNavigationItemProps
) => {
  return (
    <MobileNavigationItemBase
      {...props}
      className={styles['navigation__item']}
      activeClassName={styles['navigation__item--active']}
    />
  );
};

export const FileNavigation: React.FC<Props> = (props: Props) => {
  const { files } = useMobileNavigation();
  const desktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));

  if (desktop) return <SearchBarWithDropDown {...props} />;

  const components = files.map((navigation) => (
    <FileNavigationItem key={navigation.title} navigation={navigation} />
  ));

  return (
    <Card
      className={
        props.active ? styles['navigation--fullscreen'] : styles['navigation']
      }
    >
      <SearchBarWithFullScreen
        {...props}
        classes={{
          root: styles['navigation__search'],
          searchBar: styles['navigation__search-input'],
        }}
      />
      {!props.active && (
        <div className={styles['navigation__content']}>{components}</div>
      )}
    </Card>
  );
};
