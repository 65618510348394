import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputErpAnhangAttachementsRelation } from './InputErpAnhangAttachementsRelation.entity';

export class InputEingangsrechnungRelation<T extends EMode = EMode.entity> {
  /** Alias: attachements */
  attachements?: InputErpAnhangAttachementsRelation<T>;
  /** Alias: assignedOrders */
  assignedOrders?: number[];
  /** Alias: assignedDeliveryNotes */
  assignedDeliveryNotes?: number[];

  __typename?: string;
}

const fields: FieldDefinitions<InputEingangsrechnungRelation> = {
  attachements: {
    alias: 'attachements',
  },
  assignedOrders: {
    alias: 'assignedOrders',
  },
  assignedDeliveryNotes: {
    alias: 'assignedDeliveryNotes',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputEingangsrechnungRelationEntityDefinition: EntitiyDefinition<InputEingangsrechnungRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputEingangsrechnungRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
