import styles from './RequiredTimeRangePicker.module.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { LabeledInput } from '../../../../input/labeled-input';
import { MultiStepControls, Step } from '../../../../input/multi-step-controls';

interface IRequiredTimeRangePickerProps {
  from?: number;
  to?: number;
  onChange: (from: number, to: number) => void;
  convertTo?: 'hours' | 'days';
}

export const RequiredTimeRangePicker: React.FC<
  IRequiredTimeRangePickerProps
> = (props) => {
  const { t } = useTranslation();
  const { from, to, onChange, convertTo } = props;

  const handleFromChange = (from: string) => {
    onChange(parseValue(from, convertTo), to);
  };

  const handleToChange = (to: string) => {
    onChange(from, parseValue(to, convertTo));
  };

  return (
    <div className={styles.pickerWrap}>
      <MultiStepControls>
        <Step active={true} index={0}>
          <LabeledInput
            value={formatValue(from, convertTo)}
            label={t('COMMON.FROM')}
            type="number"
            onChange={(e) => {
              handleFromChange(e.target.value);
            }}
            onClear={() => {
              handleFromChange(null);
            }}
          />
        </Step>
        <Step active={true} index={1}>
          <LabeledInput
            value={formatValue(to, convertTo)}
            label={t('COMMON.TO')}
            type="number"
            onChange={(e) => {
              handleToChange(e.target.value);
            }}
            onClear={() => {
              handleToChange(null);
            }}
          />
        </Step>
      </MultiStepControls>
    </div>
  );
};

function formatValue(value: number | null, format: 'hours' | 'days'): string {
  if (value == null || Number.isNaN(value)) {
    return '';
  }

  switch (format) {
    case 'hours':
      return (value * 8).toString();
    default:
      return value.toString();
  }
}

function parseValue(value: string, format: 'hours' | 'days'): number | null {
  if (value == null || value === '') {
    return null;
  }

  const parsed: number = parseFloat(value);

  if (Number.isNaN(parsed)) {
    return null;
  }

  switch (format) {
    case 'hours':
      return parsed / 8;
    default:
      return parsed;
  }
}
