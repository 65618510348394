import { createContext } from 'react';

import { UsersListProps } from '../UsersList';
import { ViewsListProps } from '../ViewsList';

export interface CalendarUsersDialogContextValue
  extends UsersListProps,
    ViewsListProps {
  onUsersClear: () => void;
  onCreateView: () => void;
}

export const CalendarUsersDialogContext =
  createContext<CalendarUsersDialogContextValue>(null);
