import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { AggregationType } from '../Enums/AggregationType.enum';
import { EMode } from '../Enums/EMode.enum';

export class GroupQueryResultItem<T extends EMode = EMode.entity> {
  /** Alias: fieldname */
  fieldname?: string;
  /** Alias: alias */
  alias?: string;
  /** Alias: value */
  value?: string;
  /** Alias: aggregationType */
  aggregationType?: AggregationType;

  __typename?: string;
}

const fields: FieldDefinitions<GroupQueryResultItem> = {
  fieldname: {
    alias: 'fieldname',
  },
  alias: {
    alias: 'alias',
  },
  value: {
    alias: 'value',
  },
  aggregationType: {
    alias: 'aggregationType',
  },
  __typename: {
    alias: '__typename',
  },
};

export const groupQueryResultItemEntityDefinition: EntitiyDefinition<GroupQueryResultItem> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'GroupQueryResultItem',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
