import { noop } from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';

import { useAppState } from '@work4all/components';
import { useTableStateBag } from '@work4all/components/lib/dataDisplay/basic-table';

import { useCloneEntityMutation } from '@work4all/data';
import { usePermissions } from '@work4all/data/lib/hooks/use-permissions';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import {
  MaskTemplateEntity,
  OnOpenMask,
} from '../../containers/mask-overlays/mask-overlay/types';

export function useTableMaskHandlers(
  entity: Entities,
  onOpenMask: OnOpenMask,
  template: MaskTemplateEntity | null = null
) {
  const tableStateBag = useTableStateBag();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { untypedPermissions } = usePermissions();
  const { forcedShareBaseUrl } = useAppState();
  const cloneEntity = useCloneEntityMutation();

  const canCreate = untypedPermissions(entity).canAdd();

  const selectedFlatRows = tableStateBag.tableInstance?.selectedFlatRows.filter(
    (x) => !x.isGrouped
  ) as unknown as Row<{ id: string }>[];

  const canEdit =
    selectedFlatRows?.length === 1 &&
    untypedPermissions(entity).canEdit(selectedFlatRows[0].original);

  const create = useMemo(() => {
    if (!canCreate)
      return {
        handler: () => {
          //nop
        },
        disabled: true,
      };
    const mask = onOpenMask();

    const getHref = mask.getHref
      ? () => {
          return mask.getHref({
            entity,
            template,
          });
        }
      : undefined;

    return {
      getHref,
      handler: () =>
        mask.handler({
          entity,
          id: null,
          template,
        }),
    };
  }, [canCreate, entity, onOpenMask, template]);

  const edit = useMemo(() => {
    if (!canEdit) {
      return {
        handler: noop,
        disabled: true,
      };
    }
    const mask = onOpenMask();
    const selectedIds = selectedFlatRows
      ? getSelectedRowIds(selectedFlatRows)
      : [];

    const getHref = mask.getHref
      ? (input: string) => {
          return mask.getHref({ entity, id: input || selectedIds[0] });
        }
      : undefined;

    return {
      getHref,
      handler: () => {
        if (selectedIds.length === 1) {
          mask.handler({ entity, id: selectedIds[0] });
        }
      },
    };
  }, [canEdit, entity, onOpenMask, selectedFlatRows]);

  const convert = useCallback(
    (convertTo: Entities) => {
      const { handler } = onOpenMask();

      let template: { entity: Entities; id: string } | null = null;

      const selectedIds = getSelectedRowIds(selectedFlatRows);
      if (selectedIds.length === 1) {
        template = { entity, id: selectedIds[0] };
      }

      handler({
        entity: convertTo,
        id: null,
        template,
      });
    },
    [entity, onOpenMask, selectedFlatRows]
  );

  const share = useCallback(() => {
    const mask = onOpenMask();
    const { origin, pathname } = window.location;
    const selectedId = getSelectedRowIds(selectedFlatRows)[0];
    const baseUrl = forcedShareBaseUrl || origin;
    const link = `${baseUrl}${pathname}/${mask.getHref({
      entity,
      id: selectedId,
    })}?fullscreen=true`;

    navigator.clipboard.writeText(link);
    enqueueSnackbar(t('COMMON.LINK_COPIED_TO_CLIPBOARD'), {
      variant: 'success',
      autoHideDuration: 3000,
    });
  }, [
    enqueueSnackbar,
    entity,
    forcedShareBaseUrl,
    onOpenMask,
    selectedFlatRows,
    t,
  ]);

  const clone = useMemo(() => {
    if (!canCreate) {
      return {
        handler: () => {
          //nothing
        },
        disabled: true,
      };
    }

    const selectedId = getSelectedRowIds(selectedFlatRows ?? [])[0];

    return {
      disabled: false,
      handler: () => {
        cloneEntity({
          id: Number(selectedId),
          entity,
        });
      },
    };
  }, [canCreate, selectedFlatRows, entity]);

  const openMaskTab = useCallback(
    (openTab: string) => {
      const mask = onOpenMask();

      if (!selectedFlatRows) return;
      const selectedId = getSelectedRowIds(selectedFlatRows)[0];

      mask.handler({ entity, id: selectedId, openTab });
    },
    [entity, onOpenMask, selectedFlatRows]
  );

  return { create, edit, convert, share, clone, openMaskTab };
}

function getSelectedRowIds(rows: Row<{ id: string }>[]): string[] {
  return rows.filter((row) => !row.isGrouped).map((row) => row.original.id);
}
