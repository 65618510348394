import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FixedDataPicker } from './fixed-data-picker/FixedDataPicker';
import { IPickerProps } from './types';

export enum TicketPriority {
  HIGH = 1,
  NORMAL = 0,
  LOW = -1,
}

export const usePriority = (): { id: number; name: string }[] => {
  const { t } = useTranslation();
  const dataSet = useMemo(() => {
    return [
      {
        id: TicketPriority.LOW,
        name: t(`COMMON.LOW`),
      },
      {
        id: TicketPriority.NORMAL,
        name: t(`COMMON.NORMAL`),
      },
      {
        id: TicketPriority.HIGH,
        name: t(`COMMON.HIGH`),
      },
    ];
  }, [t]);

  return dataSet;
};

export type IPriorityPickerProps<TMultiple extends boolean> = IPickerProps<
  { id: number; name: string },
  TMultiple
>;

export function PriorityPicker<TMultiple extends boolean>(
  props: IPriorityPickerProps<TMultiple>
) {
  const { ...rest } = props;

  const dataSet: { id: number; name: string }[] = usePriority();

  return <FixedDataPicker dataSet={dataSet} {...rest} />;
}
