export enum TypeOfCrm {
  TERMIN = 'TERMIN',
  TELEFONAT = 'TELEFONAT',
  RECHNUNG = 'RECHNUNG',
  LIEFERSCHEIN = 'LIEFERSCHEIN',
  WHATTODO = 'WHATTODO',
  EINGANGSLIEFERSCHEIN = 'EINGANGSLIEFERSCHEIN',
  NOTIZ = 'NOTIZ',
  BRIEF = 'BRIEF',
  DOKUMENT = 'DOKUMENT',
  ANGEBOT = 'ANGEBOT',
  KONTAKT_BERICHT = 'KONTAKT_BERICHT',
  VERKAUFSCHANCE = 'VERKAUFSCHANCE',
  PROJEKT = 'PROJEKT',
  SUPPORT = 'SUPPORT',
  PRUEFAUFTRAG = 'PRUEFAUFTRAG',
  QM_DOKUMENT = 'QM_DOKUMENT',
  PRODUKTIONSAUFTRAG = 'PRODUKTIONSAUFTRAG',
  REISEKOSTENABRECHNUNG = 'REISEKOSTENABRECHNUNG',
  PRODUKTIONSPROTOKOLL = 'PRODUKTIONSPROTOKOLL',
  FREIGABE_EINGANGSRECHNUNG = 'FREIGABE_EINGANGSRECHNUNG',
  LIEFERANTEN_BEWERTUNG = 'LIEFERANTEN_BEWERTUNG',
  CHECK_LISTE = 'CHECK_LISTE',
  EMAIL = 'EMAIL',
  RECHNUNGSEINGAENGE = 'RECHNUNGSEINGAENGE',
}
