import styles from './styles.module.scss';

import { Popover, PopoverProps } from '@mui/material';
import React from 'react';

type ILinksPopoverOwnProps = {
  popoverRef?: React.ForwardedRef<HTMLDivElement>;
};

type ILinksPropoverForwardedProps = Omit<
  React.PropsWithoutRef<PopoverProps>,
  'anchorOrigin' | 'transformOrigin' | 'classes' | 'onContextMenu'
>;

export type ILinksPopoverProps = ILinksPopoverOwnProps &
  ILinksPropoverForwardedProps;

function stopPropagation(event: React.SyntheticEvent): void {
  event.stopPropagation();
}

export function LinksPopover({
  popoverRef,
  children,
  ...otherProps
}: ILinksPopoverProps) {
  return (
    <Popover
      ref={popoverRef}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      classes={{ paper: styles.popoverRoot }}
      onContextMenu={stopPropagation}
      {...otherProps}
    >
      {children}
    </Popover>
  );
}
