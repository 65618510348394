import { setContext } from '@apollo/client/link/context';

export const createAuthLink = (
  accessToken: string,
  tenant: number,
  baseUrl = ''
) => {
  return setContext((request, previousContext) => {
    return {
      ...previousContext,
      headers: {
        ...previousContext.headers,
        authorization: `bearer ${accessToken}`,
        'x-work4all-mandant': tenant,
        'x-work4all-apiurl': baseUrl,
      },
    };
  });
};
