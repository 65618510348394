import { Typography } from '@mui/material';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { TicketKind } from '@work4all/models/lib/Classes/TicketKind.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type ITicketKindPickerProps<TMultiple extends boolean> = IPickerProps<
  TicketKind,
  TMultiple
>;

export function TicketKindPicker<TMultiple extends boolean>(
  props: ITicketKindPickerProps<TMultiple>
) {
  const { data, prefilter, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...TICKET_KIND_FIELDS, ...data };
  }, [data]);

  const request = useMemo<DataRequest>(() => {
    return {
      data: allFields,
      entity: Entities.ticketKind,
      completeDataResponse: true,
      operationName: 'GetTicketKindList',
      filter: prefilter,
    };
  }, [allFields, prefilter]);

  const response = useDataProvider<TicketKind>(request);

  const dataset = useMemo(() => {
    return {
      loading: response.loading,
      data: response.data,
      total: response.data.length,
      fetchMore: () => Promise.resolve(),
      refetch: () => Promise.resolve(null),
    };
  }, [response]);

  const renderItem = (ticketKind: TicketKind) => {
    const label = ticketKind.name;

    return (
      <Tooltip title={label}>
        <Typography variant="body2" noWrap>
          {label}
        </Typography>
      </Tooltip>
    );
  };

  return (
    <ListEntityPicker
      entity={Entities.ticketKind}
      data={allFields}
      filterBy="name"
      sortBy="name"
      fixedDataSet={dataset}
      renderItemContent={renderItem}
      {...rest}
    />
  );
}

const TICKET_KIND_FIELDS: TicketKind = {
  id: null,
  name: null,
};
