// import { Theme, useMediaQuery } from '@mui/material';
import { Theme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { MaskTab, MaskTabs } from '../../../../../mask-tabs';
import { INDIVIDUAL_TAB_ID } from '../../../../components/custom-fields/contants';

interface ErpTabsProps {
  isCreateMode: boolean;
  renderIndividualTab: boolean;
  isLoading?: boolean;
}

export const ErpTabs = ({
  isCreateMode,
  renderIndividualTab,
  isLoading,
}: ErpTabsProps) => {
  const { t } = useTranslation();

  const isTablet = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));

  return (
    <MaskTabs>
      {isTablet && <MaskTab value="general" label={t('ERP.SIMPLE_MODE')} />}
      <MaskTab
        value="miscellaneous"
        label={t('ERP.BASE_DATA')}
        disabled={isLoading}
      />
      <MaskTab value="text" label={t('MASK.TEXT')} disabled={isLoading} />
      <MaskTab
        value="positions"
        label={t('MASK.POSITIONS')}
        disabled={isLoading}
      />
      <MaskTab
        value="attachments"
        label={t('MASK.ATTACHMENTS')}
        disabled={isLoading}
      />
      <MaskTab
        value="history"
        disabled={isCreateMode || isLoading}
        label={t('MASK.HISTORY')}
      />
      {renderIndividualTab && (
        <MaskTab
          value={INDIVIDUAL_TAB_ID}
          label={t('MASK.INDIVIDUAL')}
          disabled={isLoading}
        />
      )}
    </MaskTabs>
  );
};
