import styles from './Tab.module.scss';

import { Badge, Tab as MUITab } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { ITabProps } from '../../types';

export const Tab: React.FC<ITabProps> = (props) => {
  return (
    <Badge
      color="error"
      badgeContent={props.error?.count}
      invisible={!props.error}
      classes={{
        badge: styles.badge,
      }}
    >
      <MUITab
        title={props.error?.message}
        {...props}
        classes={{
          root: clsx(styles.root, {
            [styles.error]: props.error,
          }),
          selected: styles.selected,
        }}
      />
    </Badge>
  );
};
