import { Panel } from '../panel/Panel';

import { CustomFields } from './CustomFields';

export function IndividualTabPanel() {
  return (
    <Panel>
      <CustomFields />
    </Panel>
  );
}
