import { createContext, useContext, useMemo } from 'react';

export interface TableFooterContextValue {
  data: unknown;
}

const Context = createContext<TableFooterContextValue>({
  data: null,
});

export function TableFooterContext(
  props: React.PropsWithChildren<TableFooterContextValue>
) {
  const { data } = props;

  const context = useMemo(() => {
    return { data };
  }, [data]);

  return <Context.Provider value={context}>{props.children}</Context.Provider>;
}

export function useTableFooterContext() {
  return useContext(Context);
}
