import { WithDeleted } from 'rxdb';

import { EntitySchema } from '@work4all/models/lib/Classes/EntitySchema.entity';
import { DbEntities } from '@work4all/models/lib/DB';

import { generateGraphQLQuery } from '@work4all/utils/lib/graphql-query-generation/generateGraphQLQuery';

import { createReplicateTenantCollection } from './create-replicate-tenant-collection';

const { queryString } = generateGraphQLQuery({
  operationName: 'ReplicateSchemas',
  rootField: {
    name: 'getSchema',
    params: [{ name: 'since', type: 'DateTime' }],
  },
  fields: [
    { name: 'objectType' },
    { name: 'entityName' },
    { name: 'updateTime' },
    { name: 'jsonSchema' },
    { name: 'mandantenCode', alias: 'tenantId' },
    { name: 'databaseType' },
  ],
});

interface EntitySchemaPullQueryCheckpoint {
  updatedAt: string;
}

// Sync tenant-specific json schemas
export const replicateEntitySchemas = createReplicateTenantCollection(
  DbEntities.JsonSchema
)<EntitySchemaPullQueryCheckpoint, EntitySchema>(({ tenant }) => {
  return {
    queryBuilder(checkpoint) {
      return {
        query: queryString,
        variables: {
          since: checkpoint?.updatedAt,
        },
      };
    },
    responseModifier(plainResponse: EntitySchema[], origin, requestCheckpoint) {
      // TODO Remove this when backend is updated to filter by tenant id
      const docs = plainResponse.filter((doc) => {
        /**
         * the doc.objectType fix is really only needed if different installations have different apis to work with
         * the object type can't be null in our case though as we use it as primary key in the schema config of entitySchemasCollection
         **/
        return doc.objectType !== null && doc.tenantId === tenant;
      });

      // Add `_deleted` property to all documents. This property is
      // required by RxDB, but our API does not provide it. As it is right
      // now, it is impossible to delete documents from local collections
      // after they have been replicated. We can only add new documents or
      // update existing ones, but not delete them.
      //
      // TODO Update the replication query to return `_deleted` property.
      function withDeleted(doc: EntitySchema): WithDeleted<EntitySchema> {
        return { ...doc, _deleted: false };
      }

      function createCheckpoint(
        doc: EntitySchema
      ): EntitySchemaPullQueryCheckpoint {
        return {
          updatedAt: doc.updateTime,
        };
      }

      return {
        documents: docs.map(withDeleted),
        checkpoint:
          docs.length === 0
            ? requestCheckpoint
            : createCheckpoint(docs[docs.length - 1]),
      };
    },
  };
});
