import { isArray, merge as lodashMerge, mergeWith } from 'lodash';

function customizer<D, S>(objValue: D, srcValue: S) {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
}

export const merge: typeof lodashMerge = <D, S>(dest: D, src: S) => {
  return mergeWith(dest, src, customizer);
};
