import styles from './WordDocumentTemplatePicker.module.scss';

import Typography from '@mui/material/Typography';
import { useObservableState } from 'observable-hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { useDataProvider } from '@work4all/data';

import { DocumentTemplateGroup } from '@work4all/models/lib/Classes/DocumentTemplateGroup.entity';
import { WordDocumentTemplate } from '@work4all/models/lib/Classes/WordDocumentTemplate.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Chip } from '../../../dataDisplay/chip/Chip';
import { ChipList } from '../../../dataDisplay/chip/ChipList';
import {
  SelectableTree,
  TreeNode,
} from '../../../dataDisplay/tree/SelectableTree';
import { FilterTextInput } from '../components';
import { IPickerProps } from '../types';

const expandedNodesLocalStorageyKey =
  'w4aWordDocumentTemplatePickerExpandedNodes';

const saveExpandedNodes = (expandedNodes: string[]) => {
  localStorage.setItem(
    expandedNodesLocalStorageyKey,
    JSON.stringify(expandedNodes)
  );
};

const loadExpandedNodes = (): string[] => {
  const loadedNodes = localStorage.getItem(expandedNodesLocalStorageyKey);
  return loadedNodes ? JSON.parse(loadedNodes) : [];
};

const sortList = (groups: DocumentTemplateGroup[]): DocumentTemplateGroup[] => {
  return [...groups]
    .map((group) => {
      return {
        ...group,
        templates: [...group.templates].sort((a, b) => {
          return a.body > b.body ? 1 : b.body > a.body ? -1 : 0;
        }),
      };
    })
    .sort((a, b) => {
      return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
    });
};

export type IWordDocumentTemplatePickerProps<TMultiple extends boolean> =
  IPickerProps<WordDocumentTemplate, TMultiple> & {
    onChange: (value: WordDocumentTemplate | WordDocumentTemplate[]) => void;
  };

type WordDocumentTemplateGroupUnion = Pick<
  DocumentTemplateGroup & WordDocumentTemplate,
  'id' | 'name' | 'templates' | 'body'
>;

export function WordDocumentTemplatePicker<TMultiple extends boolean>(
  props: IWordDocumentTemplatePickerProps<TMultiple>
) {
  const { data, ...rest } = props;

  const allFields = useMemo(() => {
    return {
      ...DOCUMENT_TEMPLATE_GROUP_FIELDS,
      ...{ templates: [{ ...DOCUMENT_TEMPLATES_FIELDS, ...data }] },
    };
  }, [data]);

  const requestData: DataRequest = useMemo(() => {
    return {
      entity: Entities.documentTemplateGroup,
      data: allFields,
      completeDataResponse: true,
      filter: props.prefilter,
    };
  }, [allFields, props.prefilter]);

  const response = useDataProvider<DocumentTemplateGroup>(requestData);

  const responseData = response?.data;

  const [searchString, setSearchString] = useObservableState(
    (input$) => input$.pipe(distinctUntilChanged(), debounceTime(200)),
    ''
  );

  const [selectedIds, setSelectedIds] = useState([]);

  const nodeMap = useMemo(() => {
    const map = new Map<
      string,
      WordDocumentTemplateGroupUnion & {
        parent: WordDocumentTemplateGroupUnion;
      }
    >();
    const arrayToMap = (
      elements: WordDocumentTemplateGroupUnion[],
      map: Map<
        string,
        WordDocumentTemplateGroupUnion & {
          parent: WordDocumentTemplateGroupUnion;
        }
      >,
      parent?: WordDocumentTemplateGroupUnion
    ) => {
      elements?.forEach((el) => {
        map.set(el.id.toString(), { ...el, parent });
        if (el.templates?.length > 0) {
          arrayToMap(el.templates, map, el);
        }
      });
    };

    arrayToMap(responseData, map);
    return map;
  }, [responseData]);

  const treeData = useMemo<TreeNode[]>(() => {
    const cleanedList = sortList(responseData);
    const toTree = (data: WordDocumentTemplateGroupUnion[]) => {
      return data.map((el) => {
        const result: TreeNode = {
          id: el.id.toString(),
          label: el.body || el.name,
          children: el.templates ? toTree(el.templates) : undefined,
        };

        return result;
      }, []);
    };
    //return tree
    if (searchString.trim() === '') return toTree(cleanedList || []);

    //return flat list
    return (cleanedList || [])
      .map((el) => ({
        parent: el,
        children:
          el.templates?.filter((el) =>
            el.body.toLowerCase().includes(searchString.toLowerCase())
          ) ?? [],
      }))
      .filter((item) => item.children.length > 0)
      .flatMap(({ parent, children }) => {
        return children.map((el) => ({ el, parent, children }));
      })
      .sort((a, b) => {
        return a.el.body.toLocaleLowerCase() > b.el.body.toLocaleLowerCase()
          ? 1
          : a.el.body.toLocaleLowerCase() < b.el.body.toLocaleLowerCase()
          ? -1
          : 0;
      })
      .map((x) => {
        return {
          id: x.el.id.toString(),
          label: (
            <Typography component="div">
              <span>{x.el.body}</span>
              <span
                className={styles.secondaryItemLabel}
              >{` | ${x.parent.name}`}</span>
            </Typography>
          ),
        };
      });
  }, [responseData, searchString]);

  const getWordDocumentTemplateById = useCallback(
    (id: string) => {
      const templates = [];
      responseData.forEach((x) => templates.push(...x.templates));
      return templates.find((template) => template.id === parseInt(id));
    },
    [responseData]
  );

  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);

  useEffect(() => {
    setExpandedNodes(loadExpandedNodes());
  }, []);

  return (
    <div>
      {props.multiple && selectedIds?.length > 0 && (
        <div className={styles.wrapper}>
          <ChipList>
            {selectedIds?.map((id) => {
              const data = nodeMap.get(id.toString());
              return <Chip key={id} maxWidth={18} label={data.name} />;
            })}
          </ChipList>
        </div>
      )}

      <div className={styles.wrapper}>
        <FilterTextInput
          placeholder="Suchen"
          onChange={(val) => {
            setSearchString(val);
          }}
        />
      </div>
      <div className={styles.treeWrapper}>
        <SelectableTree
          expanded={expandedNodes}
          onNodeToggle={(e, nodeIds) => {
            setExpandedNodes(nodeIds);
            saveExpandedNodes(nodeIds);
          }}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          multiple={props.multiple as any}
          selectable="leaf"
          data={treeData}
          selected={selectedIds}
          onChange={(ids: string | string[]) => {
            setSelectedIds(Array.isArray(ids) ? ids : [ids]);

            if (Array.isArray(ids)) {
              props.onChange(ids.map((id) => getWordDocumentTemplateById(id)));
            } else {
              props.onChange(getWordDocumentTemplateById(ids));
            }
          }}
        />
      </div>
    </div>
  );
}

const DOCUMENT_TEMPLATES_FIELDS: WordDocumentTemplate = {
  id: null,
  body: null,
};

const DOCUMENT_TEMPLATE_GROUP_FIELDS: DocumentTemplateGroup = {
  id: null,
  name: null,
  templates: [DOCUMENT_TEMPLATES_FIELDS],
};
