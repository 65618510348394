import styles from './Left.module.scss';

import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { Card } from '@work4all/components/lib/dataDisplay/card';

import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { LabeledInput } from '../../../../../../../../locked-inputs';
import { Panel } from '../../../../../../../../mask-overlay/components/panel/Panel';
import { ControlWrapper } from '../../../../../../../components/control-wrapper';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { CrmMaskFormValue } from '../../../../../types';

const collapseClasses = {
  root: styles.collapseContainer,
  wrapperInner: styles.collapseWrapperInner,
};

export const Left = () => {
  const { t } = useTranslation();

  const mask = useMaskContext();

  const { register } = useFormContextPlus<CrmMaskFormValue>();

  return (
    <Panel className={styles.common}>
      <Collapse
        classes={collapseClasses}
        title={t(`COMMON.${mask.entity.toUpperCase()}`)}
        className={styles.collapse}
        defaultOpen={true}
        bottomIndent="lg"
      >
        <Card sx={{ display: 'grid', gridAutoRows: 'auto 1fr' }}>
          <ControlWrapper>
            <LabeledInput
              {...register('title')}
              label={t('INPUTS.DESCRIPTION')}
            />
          </ControlWrapper>
          <ControlWrapper className={styles.controlWrapper}>
            <LabeledInput
              {...register('note')}
              label={t('INPUTS.NOTE')}
              multiline={true}
            />
          </ControlWrapper>
        </Card>
      </Collapse>
    </Panel>
  );
};
