import styles from './TextInputCell.module.scss';

import clsx from 'clsx';
import { useEffect, useState } from 'react';

export type TextInputCellProps = {
  className?: string;
  disabled?: boolean;
  value: string;
  onChange: (value: string) => void;
  forbiddenPattern?: RegExp;
  pattern?: RegExp;
};

export const TextInputCell = (props: TextInputCellProps) => {
  const {
    className,
    value,
    onChange,
    disabled = false,
    forbiddenPattern,
  } = props;

  const [state, setState] = useState('');
  const [isFocused, setFocused] = useState(false);

  useEffect(() => {
    setState(value);
  }, [value, isFocused]);

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      onChange(state);
    }
  }

  function handleFocus(): void {
    setFocused(true);
  }

  function handleBlur(): void {
    onChange(state);
    setFocused(false);
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const value = event.currentTarget.value;
    const validated = forbiddenPattern
      ? value.replace(forbiddenPattern, '')
      : value;

    setState(validated);
  }

  return (
    <input
      className={clsx(styles.textInput, className)}
      disabled={disabled}
      value={state}
      onClick={(e) => {
        const target = e.target as HTMLInputElement;
        if (target.select) target.select();
      }}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  );
};
