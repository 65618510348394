import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputbankAccountRelationAddModify } from './InputbankAccountRelationAddModify.entity';

export class InputBankAccountRelation<T extends EMode = EMode.entity> {
  /** Alias: addModify */
  addModify?: InputbankAccountRelationAddModify<T>[];
  /** Alias: remove */
  remove?: number[];

  __typename?: string;
}

const fields: FieldDefinitions<InputBankAccountRelation> = {
  addModify: {
    alias: 'addModify',
  },
  remove: {
    alias: 'remove',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputBankAccountRelationEntityDefinition: EntitiyDefinition<InputBankAccountRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputBankAccountRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
