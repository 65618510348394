import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { ObjectType } from '../Enums/ObjectType.enum';

import { FileEntity } from './FileEntity.entity';

export class Thumbnail<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: objectType */
  objectType?: ObjectType;
  /** Alias: objectCode */
  objectCode?: number;
  /** Alias: objectId */
  objectId?: any;
  /** Alias: filename */
  filename?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: fileInfos */
  fileInfos?: FileEntity<T>;

  __typename?: string;
}

const fields: FieldDefinitions<Thumbnail> = {
  id: {
    alias: 'id',
  },
  objectType: {
    alias: 'objectType',
  },
  objectCode: {
    alias: 'objectCode',
  },
  objectId: {
    alias: 'objectId',
  },
  filename: {
    alias: 'filename',
  },
  insertTime: {
    alias: 'insertTime',
  },
  updateTime: {
    alias: 'updateTime',
  },
  fileInfos: {
    alias: 'fileInfos',
    entity: Entities.fileEntity,
  },
  __typename: {
    alias: '__typename',
  },
};

export const thumbnailEntityDefinition: EntitiyDefinition<Thumbnail> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'Thumbnail',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
