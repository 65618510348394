import { useParams } from 'react-router-dom';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PdfEditorOverlayController } from './PdfEditorOverlayController';

export const PdfEditorPage = () => {
  const { entityId, docId, entityType } = useParams<{
    entityType: Entities;
    entityId: string;
    docId: string;
  }>();

  return (
    <PdfEditorOverlayController
      docId={docId}
      entityId={entityId}
      entityType={entityType as Entities}
      onClose={() => {
        return;
      }}
    />
  );
};
