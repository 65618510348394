import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';
import { EmailTemplatesPopover } from '@work4all/components/lib/components/email-templates-popover/EmailTemplatesPopover';
import { TICKETACTIONS } from '@work4all/components/lib/components/entity-preview/ticket-preview/TicketPreview';
import { TicketPreviewContainer } from '@work4all/components/lib/components/entity-preview/ticket-preview/TicketPreviewContainer';
import { useEmailTemplates } from '@work4all/components/lib/hooks';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { TicketStatus } from '@work4all/models/lib/Enums/TicketStatus.enum';

import { Table } from '../../components/data-tables/table/Table';
import { MaskControllerProps } from '../../containers/mask-overlays/mask-overlay/types';
import { EmailOverlayController } from '../../containers/mask-overlays/mask-overlay/views/email/EmailOverlayController';
import { ContactsPopover } from '../../containers/mask-overlays/mask-overlay/views/ticket/components/contacts-popover/ContactsPopover';
import { TicketTabKeys } from '../../containers/mask-overlays/mask-overlay/views/ticket/TicketOverlayController';
import { useTicketContactsPopoverData } from '../../hooks/useTicketContacts';
import { settings, useSetting } from '../../settings';

import { EntityTable } from './EntityTable';
import schema from './schemata/ticket-table-schema.json';
import { TableLayoutContext, useTableLayoutState } from './table-layout';
import { TableNoRowsPlaceholder } from './TableNoRowsPlaceholder';
import { TicketPriorityCell } from './TicketPriorityCell';
import { TicketsTableQuickFilters } from './tickets-table-components/tickets-table-filters/TicketsTableQuickFilters';
import { TicketStatusCell } from './TicketStatusCell';
import { useDataTable } from './use-data-table';
import { useDeleteEntitiesToolbarConfig } from './use-delete-entities-toolbar-config';
import { useTableMaskHandlers } from './use-table-mask-handlers';
import { useUserColumnConfigs } from './use-user-column-configs';

type Props = EntityTable;

const defaultSort = [
  { field: 'creationDate', direction: SortDirection.DESCENDING },
];
const manualGroupBy = true;

export const TicketsTable = React.forwardRef<TableInstance, Props>(
  function TicketsTable(props, ref) {
    const { prefilter, onOpenMask, ...rest } = props;
    const { entity: entityType } = schema as never;

    const tableStateBag = useTableStateBag();

    const layoutState = useTableLayoutState();
    const [layout] = layoutState;

    const { t } = useTranslation();

    const {
      columnConfigs,
      cardConfig,
      prepareRowDisplayModifiers,
      data,
      fetchMore,
      total,
      selectedEntities,
      initialSortBy,
      pending,
    } = useDataTable<Ticket, never>({
      schema: schema as never,
      cells: {
        TicketStatus: TicketStatusCell,
        TicketPriority: TicketPriorityCell,
      },
      layout,
      tableStateBag,
      prefilter,
      defaultSort,
    });

    const maskHandlers = useTableMaskHandlers(entityType, onOpenMask);

    const deleteConfig = useDeleteEntitiesToolbarConfig({
      entityType,
    });

    const [userConfig, userConfigMethods] = useUserColumnConfigs({
      layout,
      entityType,
      columnConfigs,
    });

    const quickFilters = useMemo(() => <TicketsTableQuickFilters />, []);

    const templates = useEmailTemplates(EMailTemplateKind.TICKET);
    const emailTemplatesRef = useRef();
    const contactsPopoverRef = useRef();

    const [emailProps, setEmailProps] = useState<MaskControllerProps>();
    const [isEmailTemplatesPopoverOpen, setIsEmailTemplatesPopoverOpen] =
      useState(false);

    const [isContactsPopoverOpend, setIsContactsPopoverOpend] = useState(false);

    const contactsPopoverData = useTicketContactsPopoverData(
      selectedEntities?.[0]?.id
    );
    const defaultTicketSenderAddress = useSetting(
      settings.defaultTicketSenderAddress()
    );

    const openEmailMask = useCallback(
      (template: EMailTemplate) => {
        const ticket: Ticket =
          tableStateBag.tableInstance?.selectedFlatRows?.[0]?.original;

        const businessPartner = ticket.businessPartner?.data;
        const contact = ticket.contact;

        const entityTemplate = businessPartner
          ? {
              entity: contact ? Entities.contact : Entities.customer,
              id: contact
                ? `${contact.id}:customer:${businessPartner.id}`
                : businessPartner.id,
            }
          : undefined;

        setEmailProps({
          entity: Entities.eMail,
          id: null,
          template: entityTemplate,
          params: {
            basedon: 'EmailTemplate',
            emailTemplate: JSON.stringify(template),
            ticketTemplateContext: JSON.stringify(ticket),
            senderAddress: defaultTicketSenderAddress.value,
          },
          onAfterSave: () => {
            setEmailProps(undefined);
          },
        });
        setIsEmailTemplatesPopoverOpen(false);
      },
      [
        defaultTicketSenderAddress.value,
        tableStateBag.tableInstance?.selectedFlatRows,
      ]
    );

    const rowModifiers = useCallback(
      (value) => {
        const modifiers = prepareRowDisplayModifiers(value);
        return {
          ...modifiers,
          isFaded: value.status1 === TicketStatus.ERLEDIGT,
        };
      },
      [prepareRowDisplayModifiers]
    );

    if (!userConfig) return null;

    return (
      <>
        {emailProps && (
          <NavigationOverlay
            open={true}
            initialView={{
              view: <EmailOverlayController {...emailProps} />,
            }}
            close={() => {
              setEmailProps(undefined);
            }}
          />
        )}

        {isEmailTemplatesPopoverOpen && (
          <EmailTemplatesPopover
            templates={templates}
            anchorEl={emailTemplatesRef.current}
            onClose={() => setIsEmailTemplatesPopoverOpen(false)}
            onTemplateClick={(template) => {
              setIsEmailTemplatesPopoverOpen(false);
              openEmailMask(template);
            }}
          />
        )}

        <ContactsPopover
          popoverConfig={{
            open: isContactsPopoverOpend,
            anchorEl: contactsPopoverRef.current,
            onClose: () => setIsContactsPopoverOpend(false),
          }}
          {...contactsPopoverData}
        />

        <TableLayoutContext value={layoutState}>
          <Table
            pending={pending}
            noRowsRenderer={() => <TableNoRowsPlaceholder />}
            ref={ref}
            layout={layout}
            actions={{
              add: maskHandlers.create,
              edit: maskHandlers.edit,
              remove: deleteConfig,
              resetColumns: userConfigMethods.remove,
              createWidget: true,
            }}
            areas={{
              left: {
                content: quickFilters,
                resizable: true,
                collapseConfig: {
                  title: t('COMMON.FILTER'),
                },
              },
              right: selectedEntities &&
                selectedEntities.length > 0 && {
                  content: (
                    <TicketPreviewContainer
                      onCloseClicked={() =>
                        tableStateBag.tableInstance.toggleAllRowsSelected(false)
                      }
                      onEditClicked={maskHandlers.edit.handler}
                      onShareClicked={maskHandlers.share}
                      onActionElClicked={(e, action) => {
                        if (
                          action === TICKETACTIONS.addAttachments &&
                          selectedEntities.length === 1
                        ) {
                          onOpenMask?.().handler({
                            entity: entityType,
                            id: selectedEntities[0].id,
                            openTab: TicketTabKeys.attachments,
                          });
                        }
                      }}
                      onEmailClicked={() => {
                        if (templates.length > 0) {
                          setIsEmailTemplatesPopoverOpen(true);
                        } else {
                          openEmailMask(templates[0]);
                        }
                      }}
                      openMaskTab={maskHandlers.openMaskTab}
                      // TODO: this is not used
                      onEmailRef={emailTemplatesRef}
                      ticketIds={selectedEntities.map((e) => e.id)}
                      onPhoneClick={() => setIsContactsPopoverOpend(true)}
                      phoneIconRef={contactsPopoverRef}
                    />
                  ),
                },
            }}
            columnConfigs={userConfig}
            cardConfig={cardConfig}
            manualGroupBy={manualGroupBy}
            initialSortBy={initialSortBy}
            loadMoreItems={fetchMore}
            prepareRowDisplayModifiers={rowModifiers}
            data={data}
            total={total}
            {...rest}
          />
        </TableLayoutContext>
      </>
    );
  }
);
