import { EncodedCustomFieldName } from './types';

// Custom fields have numeric ids. We can't use these numeric ids in forms
// because then they are treated as part of an array, which creates multiple
// issues with behavior and performance. So we prefix all custom field names
// with a string to avoid the object being converted to an array.

export function encodeCustomFieldName(id: number): EncodedCustomFieldName {
  return `f${id}`;
}

export function decodeCustomFieldName(name: EncodedCustomFieldName): number {
  return Number(name.slice(1));
}
