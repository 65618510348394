import { CategoryPicker, CategoryPickerProps } from './CategoryPicker';

const filter = [
  {
    $and: [
      { isCustomerCategory: { $eq: true } },
      { isPersonCategory: { $eq: false } },
    ],
  },
];

type CustomerCategoryPickerProps = Omit<CategoryPickerProps, 'filter'>;

export function CustomerCategoryPicker(props: CustomerCategoryPickerProps) {
  return <CategoryPicker {...props} filter={filter} />;
}
