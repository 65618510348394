import { useMemo } from 'react';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { Calculation } from '@work4all/models/lib/Classes/Calculation.entity';
import { CallMemo } from '@work4all/models/lib/Classes/CallMemo.entity';
import { CheckList } from '@work4all/models/lib/Classes/CheckList.entity';
import { Contract } from '@work4all/models/lib/Classes/Contract.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { DeliveryNote } from '@work4all/models/lib/Classes/DeliveryNote.entity';
import { Demand } from '@work4all/models/lib/Classes/Demand.entity';
import { Document } from '@work4all/models/lib/Classes/Document.entity';
import { EMail } from '@work4all/models/lib/Classes/EMail.entity';
import { EMailSignature } from '@work4all/models/lib/Classes/EMailSignature.entity';
import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { InboundDeliveryNote } from '@work4all/models/lib/Classes/InboundDeliveryNote.entity';
import { InboundInvoice } from '@work4all/models/lib/Classes/InboundInvoice.entity';
import { Invoice } from '@work4all/models/lib/Classes/Invoice.entity';
import { Letter } from '@work4all/models/lib/Classes/Letter.entity';
import { Note } from '@work4all/models/lib/Classes/Note.entity';
import { Offer } from '@work4all/models/lib/Classes/Offer.entity';
import { Order } from '@work4all/models/lib/Classes/Order.entity';
import { ProductionContract } from '@work4all/models/lib/Classes/ProductionContract.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { SalesOpportunities } from '@work4all/models/lib/Classes/SalesOpportunities.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Task } from '@work4all/models/lib/Classes/Task.entity';
import { TextBuildingBlock } from '@work4all/models/lib/Classes/TextBuildingBlock.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { TravelExpenses } from '@work4all/models/lib/Classes/TravelExpenses.entity';
import { TravelReceipts } from '@work4all/models/lib/Classes/TravelReceipts.entity';
import { VisitationReport } from '@work4all/models/lib/Classes/VisitationReport.entity';
import { WordDocumentTemplate } from '@work4all/models/lib/Classes/WordDocumentTemplate.entity';
import { WordLetterTemplate } from '@work4all/models/lib/Classes/WordLetterTemplate.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ModuleAccessRightType } from '@work4all/models/lib/Enums/ModuleAccessRightType.enum';

import {
  canAddAarticle,
  canAddAppointment,
  canAddCallMemo,
  canAddChecklist,
  canAddContract,
  canAddCustomer,
  canAddDeliveryNote,
  canAddDemand,
  canAddDocument,
  canAddEmail,
  canAddEmailSignature,
  canAddEmailTemplate,
  canAddInboundDeliveryNote,
  canAddInboundInvoice,
  canAddInvoice,
  canAddLetter,
  canAddNote,
  canAddOffer,
  canAddOrder,
  canAddProductionContract,
  canAddProject,
  canAddSalesOpportunities,
  canAddSupplier,
  canAddTask,
  canAddTextBuildingBlock,
  canAddTicket,
  canAddTravelExpenses,
  canAddVisitationReport,
  canAddWordDocumentTemplate,
  canAddWordLetterTemplate,
  canDeleteAppointment,
  canDeleteArticle,
  canDeleteCallMemo,
  canDeleteChecklist,
  canDeleteContract,
  canDeleteCustomer,
  canDeleteDeliveryNote,
  canDeleteDemand,
  canDeleteDocument,
  canDeleteEmail,
  canDeleteEmailSignature,
  canDeleteEmailTemplate,
  canDeleteInboundDeliveryNote,
  canDeleteInboundInvoice,
  canDeleteInvoice,
  canDeleteLetter,
  canDeleteNote,
  canDeleteOffer,
  canDeleteOrder,
  canDeleteProductionContract,
  canDeleteProject,
  canDeleteSalesOpportunities,
  canDeleteSupplier,
  canDeleteTask,
  canDeleteTextBuildingBlock,
  canDeleteTicket,
  canDeleteTravelExpenses,
  canDeleteVisitationReport,
  canDeleteWordDocumentTemplate,
  canDeleteWordLetterTemplate,
  canEditAarticle,
  canEditAppointment,
  canEditCallMemo,
  canEditChecklist,
  canEditContract,
  canEditCustomer,
  canEditDeliveryNote,
  canEditDemand,
  canEditDocument,
  canEditEmail,
  canEditEmailSignature,
  canEditEmailTemplate,
  canEditInboundDeliveryNote,
  canEditInboundInvoice,
  canEditInvoice,
  canEditLetter,
  canEditNote,
  canEditOffer,
  canEditOrder,
  canEditProductionContract,
  canEditProject,
  canEditSalesOpportunities,
  canEditSupplier,
  canEditTask,
  canEditTextBuildingBlock,
  canEditTicket,
  canEditTravelExpenses,
  canEditVisitationReport,
  canEditWordLDocumentTemplate,
  canEditWordLetterTemplate,
  CanPerformEntityOperationFunction,
  UserLike,
} from '@work4all/utils/lib/permissions';

import { useModuleRights } from './use-module-rights';
import { checkModuleRight } from './useCanView';
import { useUser } from './useUser';

export const usePermissions = () => {
  const user = useUser();
  const { rights } = useModuleRights();
  const isFullLicenceUser = checkModuleRight(
    rights,
    ModuleAccessRightType.FEATURE_WORK_4_ALL_WEB_FULL
  );

  const isErpLightUser =
    checkModuleRight(rights, ModuleAccessRightType.FEATURE_ERP_LIGHT) &&
    !isFullLicenceUser;

  const canAccessERPData =
    isFullLicenceUser ||
    checkModuleRight(rights, ModuleAccessRightType.FEATURE_ERP_LIGHT);

  const isProjectAccessUser =
    isFullLicenceUser ||
    checkModuleRight(rights, ModuleAccessRightType.FEATURE_PROJECT_LIGHT);

  const isCRMAccessUser =
    isFullLicenceUser ||
    checkModuleRight(rights, ModuleAccessRightType.FEATURE_CRM_LIGHT);

  const isTicketEnabledUser =
    isFullLicenceUser ||
    checkModuleRight(rights, ModuleAccessRightType.FEATURE_TICKETS_LIGHT);

  const makeEntityFunctions = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      <T = any>(
        addFn: (user: UserLike) => boolean,
        deleteFn: CanPerformEntityOperationFunction<T>,
        editFn: CanPerformEntityOperationFunction<T>,
        alwaysFail = false
      ) => {
        const userLike = {
          benutzerRechte: user.benutzerRechte,
          benutzerCode: user.benutzerCode,
        };
        return {
          canAdd: () => !alwaysFail && addFn(userLike),
          /**
           * @param data if passed will check if user can delete this entity, if not passed checks if user can edit any possible entity of this type
           * @returns
           */
          canDelete: (data: T | null) =>
            !alwaysFail && deleteFn(userLike, data),
          /**
           * @param data if passed will check if user can edit this entity, if not passed checks if user can edit any possible entity of this type
           * @returns
           */
          canEdit: (data: T | null) => !alwaysFail && editFn(userLike, data),
        };
      },
    [user.benutzerRechte, user.benutzerCode]
  );

  return useMemo(() => {
    const permMap = {
      //ERP
      [`${Entities.calculation}`]: makeEntityFunctions<Calculation>(
        canAddNote,
        canDeleteNote,
        canEditNote,
        isErpLightUser || !canAccessERPData
      ),
      [`${Entities.offer}`]: makeEntityFunctions<Offer>(
        canAddOffer,
        canDeleteOffer,
        canEditOffer,
        isErpLightUser || !canAccessERPData
      ),
      [`${Entities.contract}`]: makeEntityFunctions<Contract>(
        canAddContract,
        canDeleteContract,
        canEditContract,
        isErpLightUser || !canAccessERPData
      ),
      [`${Entities.invoice}`]: makeEntityFunctions<Invoice>(
        canAddInvoice,
        canDeleteInvoice,
        canEditInvoice,
        isErpLightUser || !canAccessERPData
      ),
      [`${Entities.deliveryNote}`]: makeEntityFunctions<DeliveryNote>(
        canAddDeliveryNote,
        canDeleteDeliveryNote,
        canEditDeliveryNote,
        !canAccessERPData //, || isErpLighUser does not apply here, even lightusers can do everything with delivery notes, this is a special behaviour stemming from the lightusers impl  https://work4all.atlassian.net/browse/WW-2625
      ),
      [`${Entities.demand}`]: makeEntityFunctions<Demand>(
        canAddDemand,
        canDeleteDemand,
        canEditDemand,
        isErpLightUser || !canAccessERPData
      ),
      [`${Entities.order}`]: makeEntityFunctions<Order>(
        canAddOrder,
        canDeleteOrder,
        canEditOrder,
        isErpLightUser || !canAccessERPData
      ),
      [`${Entities.inboundDeliveryNote}`]:
        makeEntityFunctions<InboundDeliveryNote>(
          canAddInboundDeliveryNote,
          canDeleteInboundDeliveryNote,
          canEditInboundDeliveryNote,
          isErpLightUser || !canAccessERPData
        ),
      [`${Entities.inboundInvoice}`]: makeEntityFunctions<InboundInvoice>(
        canAddInboundInvoice,
        canDeleteInboundInvoice,
        canEditInboundInvoice,
        isErpLightUser || !canAccessERPData
      ),
      //REST
      [`${Entities.customer}`]: makeEntityFunctions<Customer>(
        canAddCustomer,
        canDeleteCustomer,
        canEditCustomer,
        !isCRMAccessUser
      ),
      [`${Entities.supplier}`]: makeEntityFunctions<Supplier>(
        canAddSupplier,
        canDeleteSupplier,
        canEditSupplier,
        !isCRMAccessUser
      ),
      [`${Entities.project}`]: makeEntityFunctions<Project>(
        canAddProject,
        canDeleteProject,
        canEditProject,
        !isProjectAccessUser
      ),
      [`${Entities.task}`]: makeEntityFunctions<Task>(
        canAddTask,
        canDeleteTask,
        canEditTask
      ),
      [`${Entities.checkList}`]: makeEntityFunctions<CheckList>(
        canAddChecklist,
        canDeleteChecklist,
        canEditChecklist
      ),
      [`${Entities.callMemo}`]: makeEntityFunctions<CallMemo>(
        canAddCallMemo,
        canDeleteCallMemo,
        canEditCallMemo
      ),
      [`${Entities.ticket}`]: makeEntityFunctions<Ticket>(
        canAddTicket,
        canDeleteTicket,
        canEditTicket,
        !isTicketEnabledUser
      ),
      [`${Entities.visitationReport}`]: makeEntityFunctions<VisitationReport>(
        canAddVisitationReport,
        canDeleteVisitationReport,
        canEditVisitationReport
      ),
      [`${Entities.letter}`]: makeEntityFunctions<Letter>(
        canAddLetter,
        canDeleteLetter,
        canEditLetter
      ),
      [`${Entities.eMail}`]: makeEntityFunctions<EMail>(
        canAddEmail,
        canDeleteEmail,
        canEditEmail
      ),
      [`${Entities.document}`]: makeEntityFunctions<Document>(
        canAddDocument,
        canDeleteDocument,
        canEditDocument
      ),
      [`${Entities.salesOpportunities}`]:
        makeEntityFunctions<SalesOpportunities>(
          canAddSalesOpportunities,
          canDeleteSalesOpportunities,
          canEditSalesOpportunities
        ),
      [`${Entities.appointment}`]: makeEntityFunctions<Appointment>(
        canAddAppointment,
        canDeleteAppointment,
        canEditAppointment
      ),
      [`${Entities.article}`]: makeEntityFunctions<Article>(
        canAddAarticle,
        canDeleteArticle,
        canEditAarticle
      ),
      [`${Entities.note}`]: makeEntityFunctions<Note>(
        canAddNote,
        canDeleteNote,
        canEditNote
      ),
      [`${Entities.eMailSignature}`]: makeEntityFunctions<EMailSignature>(
        canAddEmailSignature,
        canDeleteEmailSignature,
        canEditEmailSignature
      ),
      [`${Entities.eMailTemplate}`]: makeEntityFunctions<EMailTemplate>(
        canAddEmailTemplate,
        canDeleteEmailTemplate,
        canEditEmailTemplate
      ),
      [`${Entities.productionContract}`]:
        makeEntityFunctions<ProductionContract>(
          canAddProductionContract,
          canDeleteProductionContract,
          canEditProductionContract
        ),
      [`${Entities.textBuildingBlock}`]: makeEntityFunctions<TextBuildingBlock>(
        canAddTextBuildingBlock,
        canDeleteTextBuildingBlock,
        canEditTextBuildingBlock
      ),
      [`${Entities.travelExpenses}`]: makeEntityFunctions<TravelExpenses>(
        canAddTravelExpenses,
        canDeleteTravelExpenses,
        canEditTravelExpenses
      ),
      [`${Entities.travelReceipts}`]:
        checkModuleRight(
          rights,
          ModuleAccessRightType.FEATURE_RECEIPTS_TRAVEL
        ) &&
        makeEntityFunctions<TravelReceipts>(
          canAddTravelExpenses,
          canDeleteTravelExpenses,
          canEditTravelExpenses
        ),
      [`${Entities.wordDocumentTemplate}`]:
        makeEntityFunctions<WordDocumentTemplate>(
          canAddWordDocumentTemplate,
          canDeleteWordDocumentTemplate,
          canEditWordLDocumentTemplate
        ),
      [`${Entities.wordLetterTemplate}`]:
        makeEntityFunctions<WordLetterTemplate>(
          canAddWordLetterTemplate,
          canDeleteWordLetterTemplate,
          canEditWordLetterTemplate
        ),
    };

    return {
      permissions: permMap,
      untypedPermissions: (
        entity: Entities
      ): ReturnType<typeof makeEntityFunctions> => {
        if (!permMap[entity]) {
          console.warn('checking permissions for a undefined entity');
          return {
            canAdd: () => true,
            canEdit: () => true,
            canDelete: () => true,
          };
        }
        return permMap[entity];
      },
    };
  }, [isErpLightUser, makeEntityFunctions]);
};
