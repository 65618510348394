import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class StatisticSalesVolumeYearItem<T extends EMode = EMode.entity> {
  /** Alias: month */
  month?: string;
  /** Alias: amount */
  amount?: number;

  __typename?: string;
}

const fields: FieldDefinitions<StatisticSalesVolumeYearItem> = {
  month: {
    alias: 'month',
  },
  amount: {
    alias: 'amount',
  },
  __typename: {
    alias: '__typename',
  },
};

export const statisticSalesVolumeYearItemEntityDefinition: EntitiyDefinition<StatisticSalesVolumeYearItem> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'StatisticSalesVoumeYearItem',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
