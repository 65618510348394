import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { BzObjType } from '../Enums/BzObjType.enum';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { Calculation } from './Calculation.entity';
import { Contract } from './Contract.entity';
import { DeliveryNote } from './DeliveryNote.entity';
import { Demand } from './Demand.entity';
import { InboundDeliveryNote } from './InboundDeliveryNote.entity';
import { Invoice } from './Invoice.entity';
import { Offer } from './Offer.entity';
import { Order } from './Order.entity';
import { ShadowBzObject } from './ShadowBzObject.entity';

export class ModifyShadowBzObjectResult<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: name */
  name?: string;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: bzObjType */
  bzObjType?: BzObjType;
  /** Alias: bzObjMemberCode */
  bzObjMemberCode?: number;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: sdObjMemberCode */
  sdObjMemberCode?: number;
  /** Alias: sdObjType */
  sdObjType?: SdObjType;
  /** Alias: projektCode */
  projectId?: number;
  /** Alias: data */
  data?: T extends EMode.query
    ? ShadowBzObject<T>
    :
        | Calculation<T>
        | Offer<T>
        | Contract<T>
        | Invoice<T>
        | DeliveryNote<T>
        | Demand<T>
        | Order<T>
        | InboundDeliveryNote<T>;
  /** Alias: createdCodes */
  createdCodes?: number[];

  __typename?: string;
}

const fields: FieldDefinitions<ModifyShadowBzObjectResult> = {
  id: {
    alias: 'id',
  },
  name: {
    alias: 'name',
  },
  userId: {
    alias: 'benutzerCode',
  },
  bzObjType: {
    alias: 'bzObjType',
  },
  bzObjMemberCode: {
    alias: 'bzObjMemberCode',
  },
  insertTime: {
    alias: 'insertTime',
  },
  updateTime: {
    alias: 'updateTime',
  },
  sdObjMemberCode: {
    alias: 'sdObjMemberCode',
  },
  sdObjType: {
    alias: 'sdObjType',
  },
  projectId: {
    alias: 'projektCode',
  },
  data: {
    alias: 'data',
    entity: [
      Entities.calculation,
      Entities.offer,
      Entities.contract,
      Entities.invoice,
      Entities.deliveryNote,
      Entities.demand,
      Entities.order,
      Entities.inboundDeliveryNote,
    ],
  },
  createdCodes: {
    alias: 'createdCodes',
  },
  __typename: {
    alias: '__typename',
  },
};

export const modifyShadowBzObjectResultEntityDefinition: EntitiyDefinition<ModifyShadowBzObjectResult> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'ModifyShadowBzObjectResult',
      withPaginationWrapper: false,
      mutations: {
        upsert: {
          mutationName: 'createShadowBzObject',
          args: [
            { name: 'type', type: 'BzObjType!' },
            { name: 'sdObjType', type: 'SdObjType' },
            { name: 'sdObjMemberCode', type: 'Int' },
            { name: 'apCode', type: 'Int' },
            { name: 'bzObjMemberCode', type: 'Int' },
            { name: 'name', type: 'String' },
          ],
        },
      },
    },
    fieldDefinitions: fields,
  };
