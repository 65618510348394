import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { FileEntity } from './FileEntity.entity';
import { User } from './User.entity';

export class TicketAttachment<T extends EMode = EMode.entity> {
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: iD */
  id?: string;
  /** Alias: lastModificationDate */
  lastModificationDate?: string;
  /** Alias: name */
  fileName?: string;
  /** Alias: notiz */
  note?: string;
  /** Alias: subDirectory */
  subDirectory?: string;
  /** Alias: supportID */
  ticketId?: string;
  /** Alias: fileInfos */
  fileInfos?: FileEntity<T>;
  /** Alias: anzeigename */
  displayName?: string;
  /** Alias: benutzer */
  user?: User<T>;

  __typename?: string;
}

const fields: FieldDefinitions<TicketAttachment> = {
  userId: {
    alias: 'benutzerCode',
  },
  id: {
    alias: 'iD',
  },
  lastModificationDate: {
    alias: 'lastModificationDate',
  },
  fileName: {
    alias: 'name',
  },
  note: {
    alias: 'notiz',
  },
  subDirectory: {
    alias: 'subDirectory',
  },
  ticketId: {
    alias: 'supportID',
  },
  fileInfos: {
    alias: 'fileInfos',
    entity: Entities.fileEntity,
  },
  displayName: {
    alias: 'anzeigename',
  },
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  __typename: {
    alias: '__typename',
  },
};

export const ticketAttachmentEntityDefinition: EntitiyDefinition<TicketAttachment> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'TicketAnhang',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
