export enum ArticleKind {
  NORMAL = 'NORMAL',
  TAETIGKEIT_FUER_ZEITERFASSUNG = 'TAETIGKEIT_FUER_ZEITERFASSUNG',
  WARTUNGSARTIKEL = 'WARTUNGSARTIKEL',
  STAFFELPREISE = 'STAFFELPREISE',
  KALKULATORISCHER_ARTIKEL = 'KALKULATORISCHER_ARTIKEL',
  STAFFELRABATTE = 'STAFFELRABATTE',
  EXCEL_ARTIKEL = 'EXCEL_ARTIKEL',
  STAFFELPREISSTUECKLISTE = 'STAFFELPREISSTUECKLISTE',
  ANZAHLUNGSARTIKEL = 'ANZAHLUNGSARTIKEL',
  BOX_ARTIKEL = 'BOX_ARTIKEL',
  STILLGELEGTE_STUECKLISTE = 'STILLGELEGTE_STUECKLISTE',
  STUECKLISTE = 'STUECKLISTE',
  STILLGELEGT_WORK_9 = 'STILLGELEGT_WORK_9',
}
