import {
  GridView,
  ViewModuleOutlined,
  ViewQuiltOutlined,
} from '@mui/icons-material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FileViewMode } from '@work4all/data/lib/hooks/useWidgetsBag';

import { SelectButton } from '../../select-button/SelectButton';

export interface FileHorizontalSelectProps {
  viewMode: FileViewMode;
  setViewMode: (val: FileViewMode) => void;
  layout?: 'toggle' | 'button';
}

export function FileHorizontalSelect(props: FileHorizontalSelectProps) {
  const { viewMode, setViewMode, layout = 'toggle' } = props;
  const { t } = useTranslation();

  return layout === 'toggle' ? (
    <ToggleButtonGroup
      value={viewMode}
      exclusive
      onChange={(e, value) => {
        setViewMode(value);
      }}
      size="small"
    >
      <ToggleButton value="list" disabled={viewMode === 'list'}>
        <FormatListBulletedIcon fontSize={'small'} />
        &nbsp;
        <span>{t('FILEPAGE.LAYOUT.CHRONOLOGICAL')}</span>
      </ToggleButton>
      <ToggleButton value="tiles" disabled={viewMode === 'tiles'}>
        <GridView fontSize={'small'} />
        &nbsp;<span>{t('FILEPAGE.LAYOUT.WIDGET')}</span>
      </ToggleButton>
    </ToggleButtonGroup>
  ) : (
    <SelectButton
      label={t('SETTINGS.TABLES.LAYOUT')}
      onChange={(value) => setViewMode(value.id as FileViewMode)}
      value={{
        id: viewMode,
        name: {
          list: t('FILEPAGE.LAYOUT.CHRONOLOGICAL'),
          tiles: t('FILEPAGE.LAYOUT.WIDGET'),
          individual: t('FILEPAGE.LAYOUT.INDIVIDUAL'),
        }[viewMode],
        icon: {
          list: <FormatListBulletedIcon fontSize={'small'} />,
          tiles: <ViewModuleOutlined fontSize={'small'} />,
          individual: <ViewQuiltOutlined fontSize={'small'} />,
        }[viewMode],
      }}
      options={[
        {
          id: 'list',
          icon: <FormatListBulletedIcon fontSize={'small'} />,
          name: t('FILEPAGE.LAYOUT.CHRONOLOGICAL'),
        },
        {
          id: 'tiles',
          icon: <ViewModuleOutlined fontSize={'small'} />,
          name: t('FILEPAGE.LAYOUT.WIDGET'),
        },
        {
          id: 'individual',
          icon: <ViewQuiltOutlined fontSize={'small'} />,
          name: t('FILEPAGE.LAYOUT.INDIVIDUAL'),
        },
      ]}
    />
  );
}
