import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { InboundDeliveryNoteKind } from '@work4all/models/lib/Enums/InboundDeliveryNoteKind.enum';

import { FixedDataPicker } from '../fixed-data-picker/FixedDataPicker';
import { IPickerProps } from '../types';

export type InboundDeliveryNoteKindPickerProps<TMultiple extends boolean> =
  IPickerProps<{ id: InboundDeliveryNoteKind; name: string }, TMultiple>;

export const useInboundDeliveryNoteKind = () => {
  const { t } = useTranslation();
  return useMemo(() => {
    return [
      {
        id: InboundDeliveryNoteKind.RUECKNAHME_MIETARTIKEL,
        name: t('MORE.WITHDRAWAL_OF_RENTAL_ITEMS'),
      },
      {
        id: InboundDeliveryNoteKind.WARENEINGANG,
        name: t('MORE.INCOMINGGOODS'),
      },
    ];
  }, [t]);
};

export function InboundDeliveryNoteKindPicker<TMultiple extends boolean>(
  props: InboundDeliveryNoteKindPickerProps<TMultiple>
) {
  const { ...rest } = props;
  const dataSet = useInboundDeliveryNoteKind();
  return <FixedDataPicker dataSet={dataSet} {...rest} />;
}
