import { Typography } from '@mui/material';
import { useMemo } from 'react';

import { SalesOpportunities } from '@work4all/models/lib/Classes/SalesOpportunities.entity';
import { SalesOpportunityGroup } from '@work4all/models/lib/Classes/SalesOpportunityGroup.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

import { useSalesOpportunityGroups } from './useSalesOpportunityGroups';

export type SalesOpportunitiesGroupPickerProps<TMultiple extends boolean> =
  IPickerProps<SalesOpportunities, TMultiple>;

export function SalesOpportunitiesGroupPicker<TMultiple extends boolean>(
  props: SalesOpportunitiesGroupPickerProps<TMultiple>
) {
  const response = useSalesOpportunityGroups();

  const dataset = useMemo(() => {
    return {
      loading: response.loading,
      data: response.data,
      total: response.data.length,
      fetchMore: () => Promise.resolve(),
      refetch: () => Promise.resolve(null),
    };
  }, [response]);

  const renderItem = (SalesOpportunities: SalesOpportunityGroup) => {
    const label = SalesOpportunities.name;

    return (
      <Tooltip title={label}>
        <Typography variant="body2" noWrap>
          {label}
        </Typography>
      </Tooltip>
    );
  };

  return (
    <ListEntityPicker
      entity={Entities.salesOpportunityGroup}
      data={FIELDS}
      filterBy="name"
      sortBy="name"
      fixedDataSet={dataset}
      renderItemContent={renderItem}
      {...props}
    />
  );
}

const FIELDS = {
  id: null,
  name: null,
};
