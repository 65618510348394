import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  EventType,
  sendAmplitudeData,
} from '@work4all/components/lib/utils/amplitude/amplitude';

import { NavigationOverlayHeader } from '../../../../../../components/navigation-overlay-header/NavigationOverlayHeader';
import {
  MaskTab,
  MaskTabContext,
  MaskTabPanel,
  MaskTabs,
} from '../../../../mask-tabs';
import { MaskContent } from '../../../components/MaskContent/MaskContent';

import { CardsFieldsSection } from './components/cards-fields-section/CardsFieldsSection';
import { ColorsSection } from './components/colors-section/ColorsSection';
import { DragAndDropCheckbox } from './components/drag-and-drop-checkbox/DragAndDropCheckbox';
import { TimelineSection } from './components/timeline-section/TimelineSection';
import { TooltipFieldsSection } from './components/tooltip-fields-section/TooltipFieldsSection';
import { UserAppearanceSection } from './components/user-appearance-section/UserAppearanceSection';

type CalendarSettingsProps = {
  amplitudeEntryPoint: string;
};

export function CalendarSettings(props: CalendarSettingsProps) {
  const { amplitudeEntryPoint } = props;

  const { t } = useTranslation();

  useEffect(() => {
    sendAmplitudeData(EventType.OpenCalendarSettings, {
      entryPoint: amplitudeEntryPoint,
    });
  }, [amplitudeEntryPoint]);

  return (
    <MaskTabContext defaultValue="personal">
      <NavigationOverlayHeader
        title={t('SETTINGS.SETTINGS')}
        subTitle={t('SETTINGS.CALENDAR.HEADER_SUB_TITLE')}
        breadcrumbsChildren={<div></div>}
        tabs={<SettingsTabs />}
      />
      <Content />
    </MaskTabContext>
  );
}

const SettingsTabs = memo(function Tabs() {
  const { t } = useTranslation();

  return (
    <MaskTabs>
      <MaskTab value="personal" label={t('COMMON.PERSONAL')}></MaskTab>
      <MaskTab value="general" label={t('COMMON.GENERAL')}></MaskTab>
    </MaskTabs>
  );
});

const Content = memo(function TabPanels() {
  return (
    <MaskContent>
      <MaskTabPanel value="personal">
        <CardsFieldsSection />
        <TooltipFieldsSection />
        <TimelineSection />
        <ColorsSection />
        <UserAppearanceSection />
      </MaskTabPanel>

      <MaskTabPanel value="general">
        <DragAndDropCheckbox />
      </MaskTabPanel>
    </MaskContent>
  );
});
