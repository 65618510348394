import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { REBelegart } from '../Enums/REBelegart.enum';

import { BankDetails } from './BankDetails.entity';
import { Currency } from './Currency.entity';
import { File } from './File.entity';
import { InboundDeliveryNote } from './InboundDeliveryNote.entity';
import { InboundInvoicePayment } from './InboundInvoicePayment.entity';
import { Order } from './Order.entity';
import { PaymentKind } from './PaymentKind.entity';
import { RELedgerAccountSplit } from './RELedgerAccountSplit.entity';
import { SumValues } from './SumValues.entity';
import { Supplier } from './Supplier.entity';
import { User } from './User.entity';

export class InboundInvoice<T extends EMode = EMode.entity> {
  /** Alias: bankkontoCode */
  bankAccountId?: number;
  /** Alias: bankverbindungCode */
  bankDetailsId?: number;
  /** Alias: belegart */
  receiptKind?: REBelegart;
  /** Alias: benutzer */
  user?: User<T>;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: bezahltSumme */
  payedSum?: number;
  /** Alias: buchungen */
  buchungen?: RELedgerAccountSplit<T>[];
  /** Alias: buchungsDatum */
  bookingDate?: string;
  /** Alias: code */
  id?: number;
  /** Alias: creationDate */
  creationDate?: string;
  /** Alias: dMSDokumentenID */
  dMSDocumentId?: string;
  /** Alias: dTANr */
  dTANr?: number;
  /** Alias: datev */
  datevDate?: string;
  /** Alias: datum */
  date?: string;
  /** Alias: editBenutzerCode */
  editUserId?: number;
  /** Alias: editDate */
  editDate?: string;
  /** Alias: eingangsDatum */
  receivedDate?: string;
  /** Alias: eingangslieferscheinCode */
  inboundDeliveryNoteId?: number;
  /** Alias: faelligDatum */
  dueDate?: string;
  /** Alias: frei1 */
  free1?: number;
  /** Alias: freigabeBCode */
  releaseUserId?: number;
  /** Alias: freigabeDatum */
  releaseDate?: string;
  /** Alias: freigabeNotiz */
  releaseNote?: string;
  /** Alias: genehmigungDurchBCode */
  approvedByUserId?: number;
  /** Alias: kostenstelle */
  costCenterId?: number;
  /** Alias: kurs */
  course?: number;
  /** Alias: lieferant */
  supplier?: Supplier<T>;
  /** Alias: lieferdatum */
  deliveryDate?: string;
  /** Alias: lieferdatumGeplant */
  plannedDeliveryDate?: string;
  /** Alias: mahnstuffe */
  warningLevel?: number;
  /** Alias: notiz */
  note?: string;
  /** Alias: objGrCode */
  objGrId?: number;
  /** Alias: positionstext */
  positionText?: string;
  /** Alias: projektCode */
  projectId?: number;
  /** Alias: rBetrag */
  rAmount?: number;
  /** Alias: rBetragErstellt */
  rAmountCreated?: number;
  /** Alias: rBetragFremd */
  rAmountForeign?: number;
  /** Alias: rESachkGesamt */
  rLedgerAccountTotal?: number;
  /** Alias: rMwst */
  rVat?: number;
  /** Alias: rMwstErstellt */
  rVatCreated?: number;
  /** Alias: rNettoErstellt */
  rNetCreated?: number;
  /** Alias: rNummer */
  rNumber?: number;
  /** Alias: rNummerbeiLieferant */
  rNumberAtSupplier?: string;
  /** Alias: referenz */
  reference?: string;
  /** Alias: reisekostenabrechnung */
  travelExpensesId?: number;
  /** Alias: sDObjMemberCode */
  businessPartnerId?: number;
  /** Alias: skonto */
  skonto?: number;
  /** Alias: skonto2 */
  skonto2?: number;
  /** Alias: skonto2Datum */
  skonto2Date?: string;
  /** Alias: skonto2Tg */
  skonto2DurationDays?: number;
  /** Alias: skontoDatum */
  skontoDate?: string;
  /** Alias: skontoProzent */
  skontoPercent?: number;
  /** Alias: skontoTg */
  skontoDurationDays?: number;
  /** Alias: sperrungBcode */
  lockUserCode?: number;
  /** Alias: statusCode */
  statusId?: number;
  /** Alias: stornSumme */
  cancellationSum?: number;
  /** Alias: summe */
  sum?: number;
  /** Alias: templatename */
  templateName?: string;
  /** Alias: tmpSkonto */
  tmpSkonto?: number;
  /** Alias: ursprungBezSumme */
  originRelationSum?: number;
  /** Alias: ursprungMwst */
  originVat?: number;
  /** Alias: ursprungRBetrag */
  originRAmount?: number;
  /** Alias: verrechnetCode */
  calculatedCode?: number;
  /** Alias: waehrungCode */
  currencyId?: number;
  /** Alias: wiederkehrend */
  reoccuring?: number;
  /** Alias: zahlungsCode */
  paymentId?: number;
  /** Alias: zahlungsart */
  paymentKind?: PaymentKind<T>;
  /** Alias: genehmigungDurchBenutzer */
  approvalByUser?: User<T>;
  /** Alias: waehrung */
  currency?: Currency<T>;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: belege */
  belegList?: File<T>[];
  /** Alias: bankverbindung */
  bankDetails?: BankDetails<T>;
  /** Alias: freigabeDurchBenutzer */
  releaseUser?: User<T>;
  /** Alias: eingangslieferscheine */
  inboundDeliveryNote?: InboundDeliveryNote<T>[];
  /** Alias: bestellungen */
  order?: Order<T>[];
  /** Alias: blockedByUser */
  blockedByUser?: User<T>;
  /** Alias: sumValues */
  sumValues?: SumValues<T>;
  /** Alias: paymentTermDays */
  paymentTermDays?: number;
  /** Alias: createdByUserCode */
  createdByUserCode?: number;
  /** Alias: createdByUser */
  createdByUser?: User<T>;
  /** Alias: paid */
  paid?: boolean;
  /** Alias: payments */
  payments?: InboundInvoicePayment<T>[];
  /** Alias: remainingAmount */
  remainingAmount?: number;
  /** Alias: grossAmount */
  grossAmount?: number;

  __typename?: string;
}

const fields: FieldDefinitions<InboundInvoice> = {
  bankAccountId: {
    alias: 'bankkontoCode',
  },
  bankDetailsId: {
    alias: 'bankverbindungCode',
  },
  receiptKind: {
    alias: 'belegart',
  },
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  userId: {
    alias: 'benutzerCode',
  },
  payedSum: {
    alias: 'bezahltSumme',
  },
  buchungen: {
    alias: 'buchungen',
    entity: Entities.rELedgerAccountSplit,
  },
  bookingDate: {
    alias: 'buchungsDatum',
  },
  id: {
    alias: 'code',
  },
  creationDate: {
    alias: 'creationDate',
  },
  dMSDocumentId: {
    alias: 'dMSDokumentenID',
  },
  dTANr: {
    alias: 'dTANr',
  },
  datevDate: {
    alias: 'datev',
  },
  date: {
    alias: 'datum',
  },
  editUserId: {
    alias: 'editBenutzerCode',
  },
  editDate: {
    alias: 'editDate',
  },
  receivedDate: {
    alias: 'eingangsDatum',
  },
  inboundDeliveryNoteId: {
    alias: 'eingangslieferscheinCode',
  },
  dueDate: {
    alias: 'faelligDatum',
  },
  free1: {
    alias: 'frei1',
  },
  releaseUserId: {
    alias: 'freigabeBCode',
  },
  releaseDate: {
    alias: 'freigabeDatum',
  },
  releaseNote: {
    alias: 'freigabeNotiz',
  },
  approvedByUserId: {
    alias: 'genehmigungDurchBCode',
  },
  costCenterId: {
    alias: 'kostenstelle',
  },
  course: {
    alias: 'kurs',
  },
  supplier: {
    alias: 'lieferant',
    entity: Entities.supplier,
  },
  deliveryDate: {
    alias: 'lieferdatum',
  },
  plannedDeliveryDate: {
    alias: 'lieferdatumGeplant',
  },
  warningLevel: {
    alias: 'mahnstuffe',
  },
  note: {
    alias: 'notiz',
  },
  objGrId: {
    alias: 'objGrCode',
  },
  positionText: {
    alias: 'positionstext',
  },
  projectId: {
    alias: 'projektCode',
  },
  rAmount: {
    alias: 'rBetrag',
  },
  rAmountCreated: {
    alias: 'rBetragErstellt',
  },
  rAmountForeign: {
    alias: 'rBetragFremd',
  },
  rLedgerAccountTotal: {
    alias: 'rESachkGesamt',
  },
  rVat: {
    alias: 'rMwst',
  },
  rVatCreated: {
    alias: 'rMwstErstellt',
  },
  rNetCreated: {
    alias: 'rNettoErstellt',
  },
  rNumber: {
    alias: 'rNummer',
  },
  rNumberAtSupplier: {
    alias: 'rNummerbeiLieferant',
  },
  reference: {
    alias: 'referenz',
  },
  travelExpensesId: {
    alias: 'reisekostenabrechnung',
  },
  businessPartnerId: {
    alias: 'sDObjMemberCode',
  },
  skonto: {
    alias: 'skonto',
  },
  skonto2: {
    alias: 'skonto2',
  },
  skonto2Date: {
    alias: 'skonto2Datum',
  },
  skonto2DurationDays: {
    alias: 'skonto2Tg',
  },
  skontoDate: {
    alias: 'skontoDatum',
  },
  skontoPercent: {
    alias: 'skontoProzent',
  },
  skontoDurationDays: {
    alias: 'skontoTg',
  },
  lockUserCode: {
    alias: 'sperrungBcode',
  },
  statusId: {
    alias: 'statusCode',
  },
  cancellationSum: {
    alias: 'stornSumme',
  },
  sum: {
    alias: 'summe',
  },
  templateName: {
    alias: 'templatename',
  },
  tmpSkonto: {
    alias: 'tmpSkonto',
  },
  originRelationSum: {
    alias: 'ursprungBezSumme',
  },
  originVat: {
    alias: 'ursprungMwst',
  },
  originRAmount: {
    alias: 'ursprungRBetrag',
  },
  calculatedCode: {
    alias: 'verrechnetCode',
  },
  currencyId: {
    alias: 'waehrungCode',
  },
  reoccuring: {
    alias: 'wiederkehrend',
  },
  paymentId: {
    alias: 'zahlungsCode',
  },
  paymentKind: {
    alias: 'zahlungsart',
    entity: Entities.paymentKind,
  },
  approvalByUser: {
    alias: 'genehmigungDurchBenutzer',
    entity: Entities.user,
  },
  currency: {
    alias: 'waehrung',
    entity: Entities.currency,
  },
  insertTime: {
    alias: 'insertTime',
  },
  updateTime: {
    alias: 'updateTime',
  },
  belegList: {
    alias: 'belege',
    entity: Entities.file,
  },
  bankDetails: {
    alias: 'bankverbindung',
    entity: Entities.bankDetails,
  },
  releaseUser: {
    alias: 'freigabeDurchBenutzer',
    entity: Entities.user,
  },
  inboundDeliveryNote: {
    alias: 'eingangslieferscheine',
    entity: Entities.inboundDeliveryNote,
  },
  order: {
    alias: 'bestellungen',
    entity: Entities.order,
  },
  blockedByUser: {
    alias: 'blockedByUser',
    entity: Entities.user,
  },
  sumValues: {
    alias: 'sumValues',
    entity: Entities.sumValues,
  },
  paymentTermDays: {
    alias: 'paymentTermDays',
  },
  createdByUserCode: {
    alias: 'createdByUserCode',
  },
  createdByUser: {
    alias: 'createdByUser',
    entity: Entities.user,
  },
  paid: {
    alias: 'paid',
  },
  payments: {
    alias: 'payments',
    entity: Entities.inboundInvoicePayment,
  },
  remainingAmount: {
    alias: 'remainingAmount',
  },
  grossAmount: {
    alias: 'grossAmount',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inboundInvoiceEntityDefinition: EntitiyDefinition<InboundInvoice> =
  {
    local: {},
    remote: {
      queryName: 'getEingangsrechnung',
      fragmentName: 'Eingangsrechnung',
      withPaginationWrapper: true,
      mutations: {
        upsert: {
          mutationName: 'upsertIncomingInvoiceLedger',
          args: [
            { name: 'input', type: 'InputEingangsrechnung!' },
            { name: 'relations', type: 'InputReRelation' },
            { name: 'drySave', type: 'Boolean' },
          ],
        },
      },
      params: [
        { name: 'querySize', type: 'Int' },
        { name: 'queryPage', type: 'Int' },
        { name: 'querySortOrder', type: 'SortOrderStyle' },
        { name: 'querySortBy', type: 'String' },
        { name: 'filter', type: 'String' },
      ],
    },
    fieldDefinitions: fields,
  };
