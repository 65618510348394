import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { IResponse, useDataProvider } from '@work4all/data';
import { nameof } from '@work4all/data/lib/helper/nameof';

import { Notification } from '@work4all/models/lib/Classes/Notification.entity';
import { DataRequest, SortDirection } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

const data: Notification<EMode.query> = {
  id: null,
  fromUser: {
    id: null,
    displayName: null,
  },
  parentObjectCode: null,
  parentObjectId: null,
  parentObjectType: null,
  insertTime: null,
  updateTime: null,
  isRead: null,
  notificationType: null,
  object: {
    mention: {
      id: null,
      parentObjectType: null,
      parentObjectCode: null,
      parentObjectId: null,
      parentObject: {
        ticket: {
          id: null,
          title: null,
          number: null,
        },
        note: {
          id: null,
          title: null,
        },
        task: {
          id: null,
          title: null,
        },
        appointment: {
          id: null,
          title: null,
        },
        checkList: {
          id: null,
          title: null,
        },
        salesOpportunities: {
          id: null,
          name: null,
        },
        callMemo: {
          id: null,
          title: null,
        },
        visitationReport: {
          id: null,
          note: null,
        },
        letter: {
          id: null,
          note: null,
        },
        document: {
          id: null,
          note: null,
        },
        eMail: {
          id: null,
          subject: null,
        },
        deliveryNote: {
          id: null,
          note: null,
        },
        inboundDeliveryNote: {
          id: null,
          note: null,
        },
        calculation: {
          id: null,
          note: null,
        },
        invoice: {
          id: null,
          note: null,
        },
        contract: {
          id: null,
          note: null,
        },
        offer: {
          id: null,
          note: null,
        },
        order: {
          id: null,
          note: null,
        },
        demand: {
          id: null,
          note: null,
        },
        productionContract: {
          id: null,
          name: null,
        },
        inboundInvoice: {
          id: null,
          note: null,
        },
        customer: {
          id: null,
          name: null,
        },
        supplier: {
          id: null,
          name: null,
        },
        project: {
          id: null,
          name: null,
        },
        article: {
          id: null,
          name: null,
        },
      },
    },
    saveSendMailJob: {
      id: null,
      senderMailAddress: null,
      createdMail: {
        id: null,
        subject: null,
      },
    },
  },
};

const request: DataRequest = {
  operationName: 'GetLatestNotifications',
  entity: Entities.notification,
  data,
  sort: [
    {
      field: nameof<Notification>('insertTime'),
      direction: SortDirection.DESCENDING,
    },
  ],
};

export function useLatestNotifications({ pageSize }: { pageSize: number }) {
  const query = useDataProvider<Notification>(request, false, pageSize);

  const data = useMemo(() => {
    const data: Notification[] = query.data.map((notification) => {
      return {
        ...notification,
        insertTime: formatDateTimeAsUTC(notification.insertTime),
        updateTime: formatDateTimeAsUTC(notification.updateTime),
      };
    });

    return data;
  }, [query.data]);

  return useMemo<IResponse<Notification>>(() => {
    return { ...query, data };
  }, [query, data]);
}

function formatDateTimeAsUTC(value: string): string {
  const dt = DateTime.fromISO(value, { zone: 'utc' });

  if (dt.isValid) {
    return dt.toISO();
  }

  return value;
}
