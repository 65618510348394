import { ReplicationEventType } from '@work4all/data/lib/entities/utils/replication.types';

import { assertNever } from '@work4all/utils';

import { ReplicationState, ReplicationStatus } from './types';

export function expandReplicationStatus(
  status: ReplicationStatus
): ReplicationState {
  return {
    status,
    isLoading: status === 'loading',
    isReady: status === 'ready',
  };
}

export function resolveNextReplicationStatus({
  event,
}: {
  event: ReplicationEventType;
}): ReplicationStatus {
  switch (event) {
    case 'reset':
      return 'initial';

    case 'start':
      return 'loading';

    case 'done':
      return 'ready';

    default:
      assertNever(event, `Unknown event type ${event}`);
  }
}
