import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import * as React from 'react';

import { SalesOpportunityTedency } from '@work4all/models/lib/Enums/SalesOpportunityTedency.enum';

type Props = {
  value: SalesOpportunityTedency;
};

export const TendencyCell = (props: Props) => {
  const { value } = props;
  return <Tendency value={value} />;
};

interface TendencyProps {
  value: SalesOpportunityTedency;
}

export const Tendency = (props: TendencyProps) => {
  const { value } = props;
  const lookUp = {
    [SalesOpportunityTedency.DEFAULT]: null,
    [SalesOpportunityTedency.DOWN]: (
      <ArrowDropUpIcon style={{ color: '#E14E2E' }} />
    ),
    [SalesOpportunityTedency.UP]: (
      <ArrowDropDownIcon style={{ color: '#67B443' }} />
    ),
  };
  return lookUp[value] ?? null;
};
