import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Contact } from './Contact.entity';
import { Customer } from './Customer.entity';
import { Supplier } from './Supplier.entity';
import { User } from './User.entity';

export type ContactUnion<T extends EMode = EMode.entity> =
  T extends EMode.entity
    ? User<T> | Customer<T> | Supplier<T> | Contact<T>
    : {
        /** Alias: Benutzer */
        user?: User<T>;
        /** Alias: Kunde */
        customer?: Customer<T>;
        /** Alias: Lieferant */
        supplier?: Supplier<T>;
        /** Alias: Ansprechpartner */
        contact?: Contact<T>;
      };

const fields: FieldDefinitions<ContactUnion<EMode.query>> = {
  user: {
    alias: 'user',
    entity: Entities.user,
  },
  customer: {
    alias: 'customer',
    entity: Entities.customer,
  },
  supplier: {
    alias: 'supplier',
    entity: Entities.supplier,
  },
  contact: {
    alias: 'contact',
    entity: Entities.contact,
  },
};

export const contactUnionEntityDefinition: EntitiyDefinition<
  ContactUnion<EMode.query>
> = {
  kind: 'union',
  local: {},
  remote: {
    queryName: 'getUpcommingBirthdays',
    fragmentName: 'ContactUnion',
    withPaginationWrapper: false,
    mutations: {
      upsert: {
        mutationName: 'getUpcommingBirthdays',
        args: [
          { name: 'startDate', type: 'DateTimeEx!' },
          { name: 'lookaheadDays', type: 'Int' },
          { name: 'contactTypes', type: '[ContactType]' },
        ],
      },
    },
    params: [
      { name: 'startDate', type: 'DateTimeEx!' },
      { name: 'lookaheadDays', type: 'Int' },
      { name: 'contactTypes', type: '[ContactType]' },
    ],
  },
  fieldDefinitions: fields,
};
