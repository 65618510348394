import styles from './FileListGalleryItemThumbnailFallback.module.scss';

import { parseNameAndExtension } from '@work4all/data/lib/utils';

import { FileEntity } from '@work4all/models/lib/Classes/FileEntity.entity';

import { FileIcon } from '../../file-icon/FileIcon';

export interface FileListGalleryItemThumbnailFallbackProps {
  file: FileEntity;
}

export function FileListGalleryItemThumbnailFallback(
  props: FileListGalleryItemThumbnailFallbackProps
) {
  const { file } = props;

  const { extension } = parseNameAndExtension(file.fileEntityFilename);

  return (
    <div className={styles.root}>
      <FileIcon fileType={extension} />
    </div>
  );
}
