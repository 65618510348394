import { useEffect, useState } from 'react';

import { useForceUpdate } from '@work4all/utils/lib/hooks/use-force-update';

import {
  SelectionModel,
  SelectionModelOptions,
} from '../utils/selection-model';

export type UseSelectionModelResult<
  TValue,
  TMultiple extends boolean
> = SelectionModel<TValue, TMultiple>;

export function useSelectionModel<TValue, TMultiple extends boolean>(
  options: SelectionModelOptions<TValue, TMultiple>
): UseSelectionModelResult<TValue, TMultiple> {
  const [selectionModel] = useState(() => new SelectionModel(options));

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const subscription = selectionModel.changes.subscribe(forceUpdate);

    return () => {
      subscription.unsubscribe();
    };
  }, [selectionModel, forceUpdate]);

  return selectionModel;
}
