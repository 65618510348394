import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class CostCenter<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: deaktiviert */
  disabled?: boolean;
  /** Alias: ersteNummer */
  firstNumber?: number;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: letzteNummer */
  lastNumber?: number;
  /** Alias: mandantennummer */
  tenantNumber?: number;
  /** Alias: name */
  name?: string;
  /** Alias: nummer */
  number?: number;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: zusatz */
  additive?: string;

  __typename?: string;
}

const fields: FieldDefinitions<CostCenter> = {
  id: {
    alias: 'code',
  },
  disabled: {
    alias: 'deaktiviert',
  },
  firstNumber: {
    alias: 'ersteNummer',
  },
  insertTime: {
    alias: 'insertTime',
  },
  lastNumber: {
    alias: 'letzteNummer',
  },
  tenantNumber: {
    alias: 'mandantennummer',
  },
  name: {
    alias: 'name',
  },
  number: {
    alias: 'nummer',
  },
  updateTime: {
    alias: 'updateTime',
  },
  additive: {
    alias: 'zusatz',
  },
  __typename: {
    alias: '__typename',
  },
};

export const costCenterEntityDefinition: EntitiyDefinition<CostCenter> = {
  local: {},
  remote: {
    queryName: 'getKostenstellen',
    fragmentName: 'Kostenstelle',
    withPaginationWrapper: true,
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'code', type: 'Int' },
      { name: 'nummer', type: 'Int' },
      { name: 'name', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
