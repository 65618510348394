import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class KpisSupplierSalesValueYear<T extends EMode = EMode.entity> {
  /** Alias: fromDate */
  fromDate?: string;
  /** Alias: toDate */
  toDate?: string;
  /** Alias: value */
  value?: number;

  __typename?: string;
}

const fields: FieldDefinitions<KpisSupplierSalesValueYear> = {
  fromDate: {
    alias: 'fromDate',
  },
  toDate: {
    alias: 'toDate',
  },
  value: {
    alias: 'value',
  },
  __typename: {
    alias: '__typename',
  },
};

export const kpisSupplierSalesValueYearEntityDefinition: EntitiyDefinition<KpisSupplierSalesValueYear> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'KpisSupplierSalesValueYear',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
