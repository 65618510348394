/* eslint-disable @typescript-eslint/no-explicit-any */
import { combineEpics } from 'redux-observable';
import { BehaviorSubject, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import {
  allUserEffects,
  UserEpicsDependencies,
} from '@work4all/data/lib/epics/user-effects';

export const boostrapRootEpic = () => {
  const combinedEpics = [...allUserEffects];

  const epic$ = new BehaviorSubject<any>(combineEpics(...combinedEpics));
  const rootEpic = (
    action$: Observable<any>,
    state$: any,
    dependencies: UserEpicsDependencies
  ): Observable<any> =>
    epic$.pipe(mergeMap((epic: any) => epic(action$, state$, dependencies)));

  return { rootEpic, epic$ };
};
