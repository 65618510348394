import produce from 'immer';
import { useCallback } from 'react';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { CRMTypes } from '@work4all/models/lib/Classes/CRMTypes.entity';
import { Document } from '@work4all/models/lib/Classes/Document.entity';
import { EMail } from '@work4all/models/lib/Classes/EMail.entity';
import { Letter } from '@work4all/models/lib/Classes/Letter.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';
import { FileData } from '@work4all/models/lib/File';

import { settings, useSetting } from '../settings';
import { SearchHistoryResults } from '../settings/types';

export const MAX_LAST_ITEMS_TO_SAVE = 10;

/**
 * List of files recently opened from the global search or one of file search
 * pages. The results are grouped by file type and sorted by date, with most
 * recently used items first.
 */
export function useSearchHistory() {
  const searchHistory = useSetting(settings.searchHistory());

  const { set, value: lastSearchItems } = searchHistory;

  const saveSearchItem = useCallback(
    (type: Entities, data: Omit<FileData, 'lastUsed'>) => {
      // Add the new item to the start of list and remove old items with the
      // same id (if they exist).
      const newValue: SearchHistoryResults = produce(
        {
          customer: [],
          supplier: [],
          project: [],
          article: [],
          ...lastSearchItems,
        },
        (draft) => {
          draft[type] = [
            { ...data, lastUsed: Date.now() },
            ...draft[type].filter((old) => old.id !== data.id),
          ].slice(0, MAX_LAST_ITEMS_TO_SAVE);
        }
      );

      set(newValue);
    },
    [lastSearchItems, set]
  );

  const saveSearchItemFromEnityData = (
    data: Ticket | CRMTypes | Appointment | Document | Letter | EMail
  ) => {
    if (data.businessPartner) {
      saveSearchItem(
        data.businessPartnerType === SdObjType.KUNDE
          ? Entities.customer
          : Entities.supplier,
        {
          id: data.businessPartner.data.id.toString(),
          number: data.businessPartner.data.number.toString(),
          name: data.businessPartner.data.name,
        }
      );
    }

    if (data.project) {
      saveSearchItem(Entities.project, {
        id: data.project.id.toString(),
        number: data.project.number,
        name: data.project.name,
      });
    }
  };

  return { lastSearchItems, saveSearchItem, saveSearchItemFromEnityData };
}
