interface TextHeightCalculationProps {
  text: string;
  containerStyle?: Partial<CSSStyleDeclaration>;
}

const DEFAULT_CONTAINER_STYLES: Partial<CSSStyleDeclaration> = {
  visibility: 'hidden',
  fontSize: '1rem',
  width: '340px',
  padding: '6px 8px',
  whiteSpace: 'pre-line',
  lineHeight: '1.25rem',
  position: 'fixed',
  fontWeight: '400',
  top: '0',
  zIndex: '9999',
  fontFamily: '"Roboto", sans-serif',
};

export function getTextHeight(props: TextHeightCalculationProps): number {
  const { text, containerStyle = {} } = props;
  const finalContainerStyles = {
    ...DEFAULT_CONTAINER_STYLES,
    ...containerStyle,
  };
  const elem = document.createElement('div');

  for (const key in finalContainerStyles) {
    elem.style[key] = finalContainerStyles[key];
  }

  document.body.appendChild(elem);
  elem.appendChild(document.createTextNode(text));
  const height = elem.clientHeight;
  elem.parentNode.removeChild(elem);
  return height;
}
