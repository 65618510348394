import styles from './AppointmentView.module.scss';

import clsx from 'clsx';
import React from 'react';

import { Panel } from '../../../../../../../components/panel/Panel';
import { TabPanelSectionProps } from '../../../../../../../types';
import { Assignment } from '../assignment/Assignment';
import { Description } from '../description/Description';
import { Participant } from '../participant/Participant';
import { Period } from '../period/Period';

export const AppointmentView: React.FC<TabPanelSectionProps> = () => {
  return (
    <Panel className={clsx(styles.common)}>
      <Period></Period>
      <Description></Description>
      <Participant></Participant>
      <Assignment></Assignment>
    </Panel>
  );
};
