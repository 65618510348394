import styles from './CheckboxRadio.module.scss';

import { Checkbox as MuiCheckbox, Radio as MuiRadio } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

const createCheckboxRadio = <Props extends { className?: string }>(
  Component: React.FC<Props>
) => {
  return React.forwardRef<HTMLInputElement, Props>((props, ref) => {
    return (
      <Component
        inputRef={ref}
        color="primary"
        {...props}
        className={clsx(styles.checkboxradio, props.className)}
      />
    );
  });
};

export const Checkbox = createCheckboxRadio(MuiCheckbox);
export const Radio = createCheckboxRadio(MuiRadio);
