import i18next from 'i18next';

import { DebugInfoPart } from './getDebugInfoParts';

export function formatErrorDetails(
  eventId: string,
  debugInfoData: DebugInfoPart[]
) {
  return [{ key: 'Error ID', value: eventId }, ...debugInfoData]
    .map(({ key, value }) => {
      const translated = i18next.t(`DEBUG_INFO.KEYS.${key}`, {
        defaultValue: key,
      });
      return `${translated}: ${value}`;
    })
    .join('\n');
}
