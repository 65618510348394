import { UserIconCell } from '../cell-renderer/userIconCell';
import { DataTableColumnConfig } from '../table/DataTableColumnConfig';

export const OBJECT_LOCK_COLUMN_ID = 'objectLock';
export const ERP_EXPAND_COLUMN_ID = 'expand';

export const genericColumns: DataTableColumnConfig[] = [
  {
    id: OBJECT_LOCK_COLUMN_ID,
    title: '',
    accessor: 'objectLock',
    defaultHidden: false,
    Cell: UserIconCell,
    cellParams: {
      locked: true,
    },
    width: 43,
    disableSortBy: false,
    disableGroupBy: false,
    // dataType: 'date',
    // filterType: 'date',
    quickSearchable: false,
    isPrimaryFilter: false,
    filterParams: {
      entity: '',
      entityDE: '',
      multiple: true,
    },
    columnSubGroupPath: [],
    sticky: 'right',
  },
];
