import styles from './MoreTabPanel.module.scss';

import clsx from 'clsx';

import { Panel } from '../../../../../components';

import { Delivery, Payment } from './components';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function MoreTabPanel(props: any) {
  return (
    <Panel className={clsx(styles.panel)}>
      <Payment />
      <Delivery />
    </Panel>
  );
}
