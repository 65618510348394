import { Box, Typography } from '@mui/material';
import { concat } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BusinessPartner } from '@work4all/models/lib/Classes/BusinessPartner.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { WebsiteFavicon } from '../../website-favicon/WebsiteFavicon';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerWithTabsProps } from '../types';
import { Selection } from '../utils/selection-model';

export type IBusinessPartnerPickerProps<TMultiple extends boolean = boolean> =
  IPickerWithTabsProps<Customer | Supplier, TMultiple> & {
    onTabSelected?: (entity: Entities) => void;
    favorites?: boolean;
  };

export function BusinessPartnerPicker<TMultiple extends boolean = boolean>(
  props: IBusinessPartnerPickerProps<TMultiple>
) {
  const {
    data,
    prefilter,
    onChange,
    filterConfig,
    onTabSelected,
    favorites = false,
    ...rest
  } = props;

  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(Entities.customer);

  useEffect(() => {
    onTabSelected?.(selectedTab);
  }, [selectedTab, onTabSelected]);

  const selectedTabType =
    selectedTab === Entities.customer ? 'Kunde' : 'Lieferant';

  const extendedFilterConfig = useMemo(() => {
    if (!filterConfig) return filterConfig;

    const businessPartnerTypes = {
      [Entities.customer]: SdObjType.KUNDE,
      [Entities.supplier]: SdObjType.LIEFERANT,
    };

    const typeFilter = {
      $or: [
        { businessPartnerType: { $eq: businessPartnerTypes[selectedTab] } },
      ],
    };

    const filter = concat(...[filterConfig.filter, typeFilter].filter(Boolean));

    return { ...filterConfig, filter };
  }, [filterConfig, selectedTab]);

  const allFields = useMemo(() => {
    return { ...FIELDS, ...data };
  }, [data]);

  const normalizeValue = useCallback(
    (
      item: Customer | Supplier | BusinessPartner,
      __typename: string
    ): Customer | Supplier => {
      if (!props.filterConfig) return item as Customer | Supplier;

      return 'data' in item
        ? ({ ...item.data, __typename } as Customer | Supplier)
        : ({ ...item, __typename: item.__typename || __typename } as
            | Customer
            | Supplier);
    },
    [props.filterConfig]
  );

  const renderItem = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (item: any) => {
      const name = normalizeValue(item, selectedTabType).name;
      return (
        <Tooltip title={name}>
          <Typography variant="body2" noWrap>
            <Box display="flex" gap="0.5rem" alignItems="center">
              {item && <WebsiteFavicon website={item?.website} />}{' '}
              {name || t('COMMON.NONE')}
            </Box>
          </Typography>
        </Tooltip>
      );
    },
    [normalizeValue, selectedTabType, t]
  );

  const mappedChange = (value) => {
    if (!value) return onChange(value);

    const normalizedValue = Array.isArray(value)
      ? value.map((x) => normalizeValue(x, selectedTabType))
      : normalizeValue(value, selectedTabType);

    onChange(normalizedValue as Selection<Customer | Supplier, TMultiple>);
  };

  const tabs = useMemo(() => {
    return [
      {
        entity: Entities.customer,
        data: allFields,
        label: t('COMMON.CUSTOMER'),
        placeholder: t('CUSTOMER.SEARCH_PLACEHOLDER'),
        filterBy: ['name', 'number'],
        sortBy: 'name',
        useSearchHistory: true,
        prefilter,
        renderItemContent: renderItem,
        favorites,
      },
      {
        entity: Entities.supplier,
        data: allFields,
        label: t('COMMON.SUPPLIER'),
        placeholder: t('SUPPLIER.SEARCH_PLACEHOLDER'),
        filterBy: ['name', 'number'],
        sortBy: 'name',
        useSearchHistory: true,
        prefilter,
        renderItemContent: renderItem,
        favorites,
      },
    ];
  }, [allFields, favorites, prefilter, renderItem, t]);

  useEffect(() => {
    props.onTabChange?.(selectedTab);
  }, [props, selectedTab]);

  return (
    <ListEntityPicker
      {...rest}
      onChange={mappedChange}
      getTabIndex={getTabIndex}
      onTabChange={setSelectedTab}
      tabs={tabs}
      filterConfig={extendedFilterConfig}
    />
  );
}

const FIELDS: Customer | Supplier = {
  id: null,
  name: null,
  note: null,
  number: null,
  website: null,
  mainContact: {
    id: null,
    name: null,
    firstName: null,
    displayName: null,
    eMail: null,
    phoneNumber: null,
    phoneNumber2: null,
    phoneNumber3: null,
    mobileNumber: null,
    mobileNumberPrivate: null,
    faxNumber: null,
    phoneNumberPrivate: null,
    businessPartnerId: null,
    businessPartnerType: null,
  },
};

function getTabIndex(value: ICustomerOrSupplierWithTypename): 0 | 1 {
  return value.__typename === 'Lieferant' ? 1 : 0;
}

type ICustomerOrSupplierWithTypename =
  | (Customer & { __typename: 'Kunde' })
  | (Supplier & { __typename: 'Lieferant' });
