import {
  MailboxContentMutations,
  useMailboxContentMutations,
} from './use-mailbox-content-mutations';
import {
  MailboxContentStateHelpers,
  useMailboxContentStateHelpers,
} from './use-mailbox-content-state-helpers';
import {
  MailboxContentStateManager,
  useMailboxContentStateManager,
  UseMailboxContentStateManagerOptions,
} from './use-mailbox-content-state-manager';
import {
  MailboxContentStatuses,
  useMailboxContentStatuses,
} from './use-mailbox-content-statuses';

export interface MailboxContentManager
  extends MailboxContentStateManager,
    MailboxContentStateHelpers,
    MailboxContentStatuses,
    MailboxContentMutations {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseMailboxContentManagerOptions
  extends UseMailboxContentStateManagerOptions {}

export function useMailboxContentManager(
  options: UseMailboxContentManagerOptions
): MailboxContentManager {
  const { state, onContactChange, onProjectChange } =
    useMailboxContentStateManager(options);

  const helpers = useMailboxContentStateHelpers(state);

  const statuses = useMailboxContentStatuses(state);

  const mutations = useMailboxContentMutations(state);

  return {
    state,
    onContactChange,
    onProjectChange,
    ...helpers,
    ...statuses,
    ...mutations,
  };
}
