import { useMemo } from 'react';

import { DataRequest } from '@work4all/models/lib/DataProvider';

import { useCustomFieldsConfigContext } from '../../../custom-fields';
import { buildQuery } from '../utils/buildQuery';

export const useBuildQuery = (
  requestData: DataRequest,
  pageSize: number,
  skip?: boolean
): Partial<ReturnType<typeof buildQuery>> => {
  const { customFields } = useCustomFieldsConfigContext();

  return useMemo(() => {
    // todo: check mode (only server mode now)

    if (requestData.skip || skip) {
      return {};
    }

    return buildQuery(requestData, pageSize, customFields);
  }, [pageSize, requestData, skip, customFields]);
};
