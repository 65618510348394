import styles from './styles.module.scss';

import { MenuItem, MenuList } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ContextMenu,
  DISMISS_POPOVER,
  IContextMenuProps,
} from '../../../context-menu/ContextMenu';

export type IFavoriteLinksFolderContextMenuProps = {
  children: IContextMenuProps['children'];
  onRename: () => void;
  onRemove: () => void;
};

export function FavoriteLinksFolderContextMenu({
  children,
  onRename,
  onRemove,
}: IFavoriteLinksFolderContextMenuProps) {
  const { t } = useTranslation();

  return (
    <ContextMenu
      content={
        <MenuList className={styles.menuList}>
          <MenuItem
            className={clsx(styles.menuItem, DISMISS_POPOVER)}
            onClick={onRename}
          >
            {t('FAV_LINKS.RENAME')}
          </MenuItem>
          <MenuItem
            className={clsx(styles.menuItem, DISMISS_POPOVER)}
            onClick={onRemove}
          >
            {t('FAV_LINKS.REMOVE')}
          </MenuItem>
        </MenuList>
      }
    >
      {children}
    </ContextMenu>
  );
}
