import { MAX_HOURS_PRESENT } from '../../calendar/constants';
import { TimeTracker } from '../use-time-tracker';

export const getDefaultTime = ({ result }: TimeTracker, workDayEnd: string) => {
  const isRunningForMoreThanMaxHours =
    result.totalPresent.as('hours') > MAX_HOURS_PRESENT;
  const defTime = new Date();

  const workDayEndTime = workDayEnd.split(':');

  if (
    isRunningForMoreThanMaxHours &&
    result.presentSince.hour < Number(workDayEndTime[0])
  ) {
    defTime.setHours(Number(workDayEndTime[0]), Number(workDayEndTime[1]), 0);
    defTime.setDate(result.presentSince.day);
  } else if (
    result.presentSince.hour >= Number(workDayEndTime[0]) &&
    result.isPresent
  ) {
    defTime.setDate(result.presentSince.day);
    defTime.setHours(result.presentSince.hour);
    defTime.setMinutes(result.presentSince.minute + 1);
  } else return undefined;
  return defTime;
};
