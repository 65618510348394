import styles from './TextBuildingBlockOverlayController.module.scss';

import clsx from 'clsx';
import { pick } from 'lodash';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LabeledInput } from '@work4all/components/lib/input/labeled-input';
import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';
import {
  EventType,
  sendAmplitudeData,
} from '@work4all/components/lib/utils/amplitude/amplitude';

import { useDataMutation, useDataProvider, useUser } from '@work4all/data';
import { useEntityJsonSchema } from '@work4all/data/lib/json-schema/EntityJsonSchemasContext';

import { TextBuildingBlock } from '@work4all/models/lib/Classes/TextBuildingBlock.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useJSONSchemaResolver } from '@work4all/utils';
import { PathsOf } from '@work4all/utils/lib/paths-of/paths-of';
import {
  canAddTextBuildingBlock,
  canDeleteTextBuildingBlock,
  canEditTextBuildingBlock,
} from '@work4all/utils/lib/permissions';

import { NavigationOverlayHeader } from '../../../../../components/navigation-overlay-header/NavigationOverlayHeader';
import { useFormContextPlus } from '../../../form-plus/use-form-context-plus';
import { MenuItem } from '../../../locked-inputs';
import { Collapse, ControlWrapper, Form } from '../../components';
import { MaskActions } from '../../components/MaskActions';
import { MaskOverlayMenuWrapper } from '../../components/MaskOverlayMenuWrapper';
import { MaskOverlaySubmitButton } from '../../components/MaskOverlaySubmitButton';
import {
  MaskContextProvider,
  useMaskConfig,
  useMaskContextValue,
} from '../../hooks/mask-context';
import { useConfirmBeforeCloseMask } from '../../hooks/use-confrm-before-close-mask';
import { useStandardDeleteEntityHandler } from '../../hooks/use-standard-delete-entity-handler';
import { normalizeFormValue } from '../../hooks/useExtendedFormContext';
import { MaskControllerProps } from '../../types';

type TextBuildingBlockFormValue = PathsOf<TextBuildingBlock, 2>;

type TextBuildingBlockOverlayControllerProps = MaskControllerProps & {
  amplitudeEntryPoint: string;
};

export function TextBuildingBlockOverlayController(
  props: TextBuildingBlockOverlayControllerProps
) {
  const { amplitudeEntryPoint } = props;
  const { t } = useTranslation();

  const { goBack, close, currentStackIndex, setObjectionListener } =
    useHistoryStack();
  const [mutate] = useDataMutation<
    TextBuildingBlock,
    EMode.upsert,
    {
      attachements;
    }
  >({
    entity: Entities.textBuildingBlock,
    mutationType: EMode.upsert,
    responseData: {
      id: null,
    },
  });

  const mask = useMaskConfig(props);

  useEffect(() => {
    if (mask.isCreateMode) {
      sendAmplitudeData(EventType.AddTextBuildingBlock, {
        entryPoint: amplitudeEntryPoint,
      });
    } else {
      sendAmplitudeData(EventType.EditTextBuildingBlock, {
        entryPoint: amplitudeEntryPoint,
      });
    }
  }, [amplitudeEntryPoint, mask, mask.isCreateMode, props.id]);

  const requestTextBuildingBlockData = useMemo<DataRequest>(() => {
    const TextBuildingBlockRequestData: TextBuildingBlock<EMode.query> = {
      id: null,
      name: null,
      text: null,
    };

    const data = TextBuildingBlockRequestData;
    return {
      entity: Entities.textBuildingBlock,
      data,
      completeDataResponse: false,
      vars: {
        codes: [parseInt(mask.id as string)],
      },
    };
  }, [mask.id]);

  const currentTextBuildingBlock = useDataProvider<TextBuildingBlock>(
    requestTextBuildingBlockData,
    mask.isCreateMode
  );

  const dataRaw = useMemo(() => {
    const defaultValues = {};

    const result =
      (mask.isCreateMode
        ? defaultValues
        : currentTextBuildingBlock?.data?.[0]) ?? defaultValues;
    return result;
  }, [currentTextBuildingBlock, mask.isCreateMode]);

  const data = useMemo<PathsOf<TextBuildingBlock, 2>>(() => {
    const result = normalizeFormValue(dataRaw);
    return result;
  }, [dataRaw]);

  const schema = useEntityJsonSchema(Entities.textBuildingBlock);
  const resolver = useJSONSchemaResolver(schema);

  const form = useForm<TextBuildingBlockFormValue>({
    resolver,
    defaultValues: data,
    mode: 'onChange',
    shouldFocusError: false,
  });
  const {
    formState: { dirtyFields, isDirty, isValid, isSubmitting },
    handleSubmit,
    reset,
  } = form;

  useConfirmBeforeCloseMask(isDirty);

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  const deleteHandler = useStandardDeleteEntityHandler({
    entity: Entities.textBuildingBlock,
    id: mask.id,
  });

  const onSubmit = async (values: TextBuildingBlockFormValue) => {
    const update = mask.isCreateMode
      ? values
      : pick(values, ['id', ...Object.keys(dirtyFields)]);
    await mutate(update);

    setObjectionListener(null);
    sendAmplitudeData(EventType.SaveTextBuildingBlock, {
      entryPoint: amplitudeEntryPoint,
    });
    if (currentStackIndex !== 0) {
      goBack();
    } else {
      close();
    }
  };

  const maskContext = useMaskContextValue({ ...mask, data });
  const user = useUser();

  const entityRights = useMemo(
    () => ({
      create: canAddTextBuildingBlock(user),
      read: false, //ToDo there is no real read right, rather a visibility right
      update: canEditTextBuildingBlock(user),
      delete: canDeleteTextBuildingBlock(user),
    }),
    [user]
  );

  const noRights = mask.isEditMode
    ? !entityRights.update
    : !entityRights.create;

  const submitDisabled =
    noRights || (mask.isEditMode && (!isValid || !isDirty));

  return (
    <MaskContextProvider value={maskContext}>
      <FormProvider {...form}>
        <div className={clsx(styles.root, 'custom-scrollbar')}>
          <div>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <NavigationOverlayHeader
                title={t('COMMON.TEXTBUILDINGBLOCK')}
                breadcrumbsChildren={
                  <MaskActions>
                    <MaskOverlaySubmitButton
                      disabled={submitDisabled}
                      disableReason={noRights ? t('RIGHTS.MISSING') : undefined}
                      loading={isSubmitting}
                    />
                    {mask.id && (
                      <MaskOverlayMenuWrapper>
                        <MenuItem onClick={deleteHandler}>
                          {t('MASK.REMOVE')}
                        </MenuItem>
                      </MaskOverlayMenuWrapper>
                    )}
                  </MaskActions>
                }
              />
              <TextBuildingBlockMaskContent />
            </Form>
          </div>
        </div>
      </FormProvider>
    </MaskContextProvider>
  );
}

function TextBuildingBlockMaskContent() {
  const { t } = useTranslation();

  const { register } = useFormContextPlus<TextBuildingBlockFormValue>();

  return (
    <>
      <Collapse title={t('COMMON.DESCRIPTION')} defaultOpen>
        <ControlWrapper>
          <LabeledInput label={t('COMMON.NAME')} {...register('name')} />
        </ControlWrapper>
      </Collapse>

      <Collapse defaultOpen>
        <LabeledInput
          {...register('text')}
          label={t('INPUTS.TEXT')}
          multiline={true}
          style={{
            height: '20rem',
          }}
        />
      </Collapse>
    </>
  );
}
