import styles from './DocumentView.module.scss';

import { useContext } from 'react';

import { Info } from './components/info';
import { Preview } from './components/preview';
import { DocumentMaskContext } from './context';
import { Template } from './Template';

export const DocumentView = () => {
  const maskExtras = useContext(DocumentMaskContext);

  return (
    <div className={styles.documentView}>
      <Info />
      <div className={styles.wrapperRight}>
        {maskExtras.type === 'template' && <Template />}
        <Preview />
      </div>
    </div>
  );
};
