import styles from './Preview.module.scss';

import { Delete, Download, Edit, Fullscreen } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { MouseEventHandler, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LinkIcon } from '@work4all/assets/icons/link.svg';

import { useAuthHeaders } from '@work4all/data/lib/auth/use-auth-headers';
import { downloadAuthed } from '@work4all/data/lib/utils';

import { PreviewTitle } from '../components/entity-preview/components';
import { BaseActionButton } from '../input/base-action-button/BaseActionButton';
import { StackProvider } from '../navigation/history-stack';
import { Content } from '../navigation/navigation-overlay/components/content/Content';

import { ImagePreview } from './image-preview/ImagePreview';
import { MsgPreview } from './msg-preview/MsgPreview';
import { NoPreview } from './NoPreview';
import { PDFPreview } from './pdf-preview';
import { PDFTextmarkConf } from './pdf-textmarks/PDFTextmarkContainer';
import { UrlPreview } from './url-preview/UrlPreview';
import { IUsePreview } from './use-preview';

export enum MIME_TYPES {
  pdf = 'application/pdf',
  jpeg = 'image/jpeg',
  png = 'image/png',
  svg = 'image/svg+x',
  webp = 'image/webp',
  urllist = 'text/uri-list',
  msg = 'application/json; type=EmailPreview',
}

export type IPreviewProps = {
  url: string;
  fileName?: string;
  filePath?: string;
  mimeType: MIME_TYPES;
  fspUrl?: string;
  exists?: boolean;
  downloadProps?: {
    url: string;
    filePath: string;
  };
  iconProps?: IconsProps;
  /** Text that will be displayed if no preview is available. */
  noPreview: string;
  noPreviewUrl?: string;
  openInFullscreen?: boolean;
  onFullscreenClose?: () => void;
  onCloseClicked?: MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onDeleteClicked?: MouseEventHandler<HTMLButtonElement>;
  isLinkable?: boolean;
  pdfTextmarkConf?: PDFTextmarkConf;
} & Partial<IUsePreview['props']>;

interface IconsProps {
  showFullscreenIcon?: boolean;
  showDownloadIcon?: boolean;
  showPreviewExternallyIcon?: boolean;
  onEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onDeleteClicked?: MouseEventHandler<HTMLButtonElement>;
}

export function Preview(props: IPreviewProps) {
  const {
    mimeType,
    downloadProps,
    iconProps,
    onFullscreenClose,
    onEditClicked,
    onDeleteClicked,
    filePath,
    fileName,
    openInFullscreen,
    fspUrl,
    isLinkable = false,
  } = props;
  const [showPopover, setShowPopover] = useState(openInFullscreen);

  useEffect(() => {
    if (openInFullscreen !== undefined) {
      setShowPopover(openInFullscreen);
    }
  }, [openInFullscreen]);

  const PreviewComp = <PreviewComponent {...props} />;
  const mimeTypeValues = Object.values(MIME_TYPES);

  const canPreviewFullscreen =
    iconProps?.showFullscreenIcon !== false &&
    mimeTypeValues.includes(mimeType);
  const canDownload = iconProps?.showDownloadIcon !== false && !!downloadProps;
  const canPreviewExternally =
    iconProps?.showPreviewExternallyIcon !== false &&
    !!((filePath && isLinkable) || fspUrl);

  const closeOverlay = () => {
    if (openInFullscreen === undefined) {
      setShowPopover(false);
    } else {
      onFullscreenClose?.();
    }
  };

  const httpHeaders = useAuthHeaders();
  const { t } = useTranslation();

  const Icons = ({
    showDownloadIcon = false,
    showFullscreenIcon = false,
    showPreviewExternallyIcon = false,
    onEditClicked,
    onDeleteClicked,
  }: IconsProps) => {
    return (
      <>
        {onEditClicked && (
          <IconButton color="primary" onClick={onEditClicked}>
            <Edit />
          </IconButton>
        )}
        {onDeleteClicked && (
          <IconButton color="primary" onClick={onDeleteClicked}>
            <Delete />
          </IconButton>
        )}
        {showFullscreenIcon && (
          <IconButton color="primary" onClick={() => setShowPopover(true)}>
            <Fullscreen />
          </IconButton>
        )}

        {showDownloadIcon && (
          <IconButton
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              downloadAuthed(downloadProps.url, fileName, httpHeaders);
            }}
          >
            <Download />
          </IconButton>
        )}

        {showPreviewExternallyIcon && (
          <IconButton
            color="primary"
            href={filePath && isLinkable ? filePath : fspUrl}
            target="_blank"
          >
            <LinkIcon />
          </IconButton>
        )}
      </>
    );
  };

  return (
    <>
      <StackProvider
        onClose={closeOverlay}
        initialView={{
          title: fileName || t('COMMON.PREVIEW'),
          view: (
            <Box height={'100%'} display="flex" flexDirection="column">
              <PreviewTitle
                onCloseClicked={closeOverlay}
                onEditClicked={onEditClicked}
                onDeleteClicked={onDeleteClicked}
                actions={
                  <Icons
                    showDownloadIcon={canDownload}
                    showPreviewExternallyIcon={canPreviewExternally}
                    onEditClicked={onEditClicked}
                    onDeleteClicked={onDeleteClicked}
                  />
                }
              >
                {fileName}
              </PreviewTitle>
              <Box flex="1">{PreviewComp}</Box>
            </Box>
          ),
        }}
      >
        <Content
          open={showPopover}
          close={closeOverlay}
          onExited={closeOverlay}
        />
      </StackProvider>
      <div className={styles.previewWrapper}>
        <div className={styles.actionBar}>
          {openInFullscreen === undefined && (
            <BaseActionButton
              title={''}
              icon={
                <Icons
                  showFullscreenIcon={canPreviewFullscreen}
                  showDownloadIcon={canDownload}
                  showPreviewExternallyIcon={canPreviewExternally}
                />
              }
              component="body2"
              color="primary"
            />
          )}
        </div>
        <div className={styles.previewContainer}>{PreviewComp}</div>
      </div>
    </>
  );
}

export type IPreviewComponentProps = {
  mimeType?: MIME_TYPES;
  noPreview?: string;
  noPreviewUrl?: string;
  url: string;
  initialScale?: number;
  initalTranslate?: string;
  pdfTextmarkConf?: PDFTextmarkConf;
} & Partial<IUsePreview['props']>;
export const PreviewComponent = (props) => {
  const {
    url,
    register,
    scale,
    onScaleChange,
    mimeType,
    noPreviewUrl,
    noPreview,
    initialScale,
    initalTranslate,
    pdfTextmarkConf,
  } = props;
  const PreviewComp = useMemo(() => {
    const previewProps = {
      url,
      register,
      scale,
      onScaleChange,
      initialScale,
      initalTranslate,
    };
    switch (mimeType) {
      case MIME_TYPES.pdf:
        return (
          <PDFPreview {...previewProps} pdfTextmarkConf={pdfTextmarkConf} />
        );
      case MIME_TYPES.msg:
        return <MsgPreview {...previewProps} />;
      case MIME_TYPES.jpeg:
      case MIME_TYPES.png:
      case MIME_TYPES.svg:
      case MIME_TYPES.webp:
        return <ImagePreview {...previewProps} />;
      case MIME_TYPES.urllist:
        return <UrlPreview url={previewProps.url} />;
      default:
        return (
          <NoPreview
            {...previewProps}
            text={noPreview}
            url={noPreviewUrl || url}
          />
        );
    }
  }, [
    url,
    register,
    scale,
    onScaleChange,
    initialScale,
    initalTranslate,
    mimeType,
    pdfTextmarkConf,
    noPreview,
    noPreviewUrl,
  ]);
  return PreviewComp;
};
