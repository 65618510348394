import { DateTime } from 'luxon';

export const generateYearList = (startDate: Date, endDate: Date): number[] => {
  const result = [];

  if (!startDate || !endDate) {
    return [];
  }
  const startYear: number = parseInt(
    DateTime.fromJSDate(new Date(startDate)).toFormat('yyyy')
  );
  const endYear: number = parseInt(
    DateTime.fromJSDate(new Date(endDate)).toFormat('yyyy')
  );

  for (let i = endYear; i >= startYear; i--) {
    result.push(i);
  }

  return result;
};
