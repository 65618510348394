import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputSalesOpportunityRatingTemplate<
  T extends EMode = EMode.entity
> {
  /** Alias: id */
  id?: string | number;
  /** Alias: name */
  name?: string;
  /** Alias: ratingValue */
  ratingValue?: any;
  /** Alias: createdByUserCode */
  createdByUserCode?: number;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];
  /** Alias: comment */
  comment?: string;

  __typename?: string;
}

const fields: FieldDefinitions<InputSalesOpportunityRatingTemplate> = {
  id: {
    alias: 'id',
  },
  name: {
    alias: 'name',
  },
  ratingValue: {
    alias: 'ratingValue',
  },
  createdByUserCode: {
    alias: 'createdByUserCode',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customFields: {
    alias: 'customFields',
  },
  comment: {
    alias: 'comment',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputSalesOpportunityRatingTemplateEntityDefinition: EntitiyDefinition<InputSalesOpportunityRatingTemplate> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputSalesOpportunityRatingTemplate',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
