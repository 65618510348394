import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MaskControllerProps } from '../../../../mask-overlays/mask-overlay';
import { MaskOverlayHeader } from '../../../../mask-overlays/mask-overlay/components';
import { MaskContent } from '../../../../mask-overlays/mask-overlay/components/MaskContent/MaskContent';
import {
  MaskContextProvider,
  useMaskConfig,
  useMaskContextValue,
} from '../../../../mask-overlays/mask-overlay/hooks/mask-context';
import { normalizeFormValue } from '../../../../mask-overlays/mask-overlay/hooks/useExtendedFormContext';
import {
  MaskTab,
  MaskTabContext,
  MaskTabPanel,
  MaskTabs,
} from '../../../../mask-overlays/mask-tabs';

import { PersonalTabPanel } from './PersonalTabPanel';

export const FileSettingsMaskController = (props: MaskControllerProps) => {
  const { t } = useTranslation();

  const mask = useMaskConfig(props);

  const data = useMemo(() => {
    return normalizeFormValue({
      title: t(`MASK.${mask.id.toString().toUpperCase()}_MASTER_DATA`),
    });
  }, [mask.id, t]);

  const maskContext = useMaskContextValue({ ...mask, data });

  return (
    <MaskContextProvider value={maskContext}>
      <MaskTabContext defaultValue="personal">
        <MaskOverlayHeader
          title={t(`COMMON.${mask.entity.toUpperCase()}`)}
          subTitle={data?.title}
          tabs={<Tabs />}
        />
        <Content />
      </MaskTabContext>
    </MaskContextProvider>
  );
};

const Tabs = memo(function Tabs() {
  const { t } = useTranslation();

  return (
    <MaskTabs>
      <MaskTab value="personal" label={t('MASK.PERSONAL')}></MaskTab>
      <MaskTab disabled value="general" label={t('MASK.GENERAL')}></MaskTab>
    </MaskTabs>
  );
});

const Content = memo(function Content() {
  return (
    <MaskContent>
      <MaskTabPanel value="personal">
        <PersonalTabPanel />
      </MaskTabPanel>
      <MaskTabPanel value="general" />
    </MaskContent>
  );
});
