const prefix = 'InvariantError';

export function invariant(
  condition: unknown,
  messageConstructor?: string | (() => string) | null | undefined
): asserts condition {
  if (condition) return;

  const message =
    typeof messageConstructor === 'function'
      ? messageConstructor()
      : messageConstructor;

  const error = Error(message == null ? prefix : `${prefix}: ${message}`);
  Error?.captureStackTrace(error, invariant);
  throw error;
}
