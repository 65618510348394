import Typography from '@mui/material/Typography';

import { IResponse } from '@work4all/data/lib/hooks/data-provider/useDataProvider';

import { SalesOpportunityRatingStatus } from '@work4all/models/lib/Classes/SalesOpportunityRatingStatus.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

import { useSalesOpportunityRatingStatus } from './components/use-sales-opportunity-rating-status';

const defaultRatingStatusArray: SalesOpportunityRatingStatus[] = Array.from(
  Array(10).keys()
).map((x) => {
  const so: SalesOpportunityRatingStatus = {
    id: `-${x}`,
    ratingValue: (x + 1) * 10,
  };
  return so;
});

export type SalesOpportunityRatingStatusPickerProps<TMultiple extends boolean> =
  IPickerProps<SalesOpportunityRatingStatus, TMultiple>;

export function SalesOpportunityRatingStatusPicker<TMultiple extends boolean>(
  props: SalesOpportunityRatingStatusPickerProps<TMultiple>
) {
  const { ...rest } = props;

  const { data, loading } = useSalesOpportunityRatingStatus(
    defaultRatingStatusArray
  );

  const fixedDataSet: IResponse<SalesOpportunityRatingStatus> = {
    data: data,
    loading,
    pending: loading,
    total: data.length,
    fetchMore: () => Promise.resolve(),
    refetch: () => Promise.resolve(null),
  };
  return (
    <ListEntityPicker
      entity={Entities.salesOpportunityRatingStatus}
      fixedDataSet={fixedDataSet}
      data={FIELDS}
      filterBy={['name']}
      sortBy="name"
      {...rest}
      renderItemContent={(field) => (
        <Tooltip title={field.name}>
          <Typography variant="body2" noWrap>
            {`${field.ratingValue} %${field.name ? ` - ${field.name}` : ''}`}
          </Typography>
        </Tooltip>
      )}
    />
  );
}

const FIELDS: SalesOpportunityRatingStatus = {
  id: null,
  name: null,
  comment: null,
  ratingValue: null,
};
