import { useMemo } from 'react';

import { Group } from '@work4all/models/lib/Classes/Group.entity';

import {
  SelectableTree,
  TreeNode,
} from '../../dataDisplay/tree/SelectableTree';

interface GroupPickerProps {
  groups: Group[] | null;
  value: Group[] | null;
  onChange: (value: Group[]) => void;
  multiple?: boolean;
}

function toNodeId(id: number) {
  return id === 0 ? ROOT_ID : id?.toString();
}

const ROOT_ID = '<root>';

export function GroupPicker(props: GroupPickerProps) {
  const { value, onChange, groups, multiple = true } = props;

  const { treeData, groupById, groupsByParentId } = useMemo(() => {
    const groupById = new Map<string, Group>();
    const groupsByParentId = new Map<string | null, string[]>();

    if (!groups) {
      return { treeData: [], groupById, groupsByParentId };
    }

    for (const group of groups) {
      const id = toNodeId(group.id);
      const parentId = toNodeId(group.parentId);

      groupById.set(id, group);

      if (!groupsByParentId.has(parentId)) {
        groupsByParentId.set(parentId, []);
      }

      groupsByParentId.get(parentId).push(id);
    }

    const compareGroupsByNameOrIndex = (a: Group, b: Group) => {
      if (a.index !== undefined && b.index !== undefined) {
        return a.index - b.index;
      }
      return a.name.localeCompare(b.name);
    };

    function mapItem(group: Group): TreeNode {
      const id = toNodeId(group.id);

      const treeItem: TreeNode = {
        id,
        label: group.name,
        children: groupsByParentId
          .get(id)
          ?.map((id) => groupById.get(id))
          .sort(compareGroupsByNameOrIndex)
          .map((group) => mapItem(group)),
      };

      return treeItem;
    }

    const rootGroups = groupsByParentId.get(ROOT_ID) ?? [];

    const treeData = rootGroups
      .map((id) => groupById.get(id))
      .sort(compareGroupsByNameOrIndex)
      .map((group) => mapItem(group));

    return { treeData, groupById, groupsByParentId };
  }, [groups]);

  const selected = useMemo<string[]>(() => {
    if (!value) {
      return [];
    }

    // TODO We need to manually add the ids of fully selected group nodes here,
    // so that MUI considers them selected for the purposes of selection state
    // management. Otherwise there will be no way to deselect them.
    //
    // This should be removed after reworking SelectableTree to handle all
    // selection logic by itself.
    //
    // Note: Right now this is not required, because the filter value cannot be
    // set from any other source. And after the SelectableTree component is
    // reworked, this will not be necessary anymore.

    return value.map((item) => toNodeId(item?.id));
  }, [value]);

  // When the selection changes, we need to update the selected groups:
  // 1. Deselect items that are no longer selected. For groups, recursively
  //    deselect all children.
  // 2. Select the newly selected items. For groups, recursively select all
  //    children.
  // 3. If a group is selected, but some of its children are not, remove it from
  //    selection.
  // 4. For every node that is selected, check if its parent should now also be
  //    added to the selection.
  // 5. Remove duplicates.
  //
  // Steps 1, 2 and 5 should work the same for all types of trees. Steps 3 and 4
  // are unique to this Group tree, because we need to manually add group nodes
  // to the selection if all their children leaf nodes are selected.
  //
  // After the requirements are clarified and this version is reviewed, we can
  // move some of this duplicated code to the SelectableTree component and have
  // it manage all group/leaf node interactions.
  //
  // Here we would only have to manually select/deselect group nodes when its
  // child leaf nodes are selected/deselected.
  //
  // TODO Refactor selection state management for SelectableTree.

  const handleChange = (newSelected: string[]) => {
    const removed = selected.filter((id) => !newSelected.includes(id));
    const added = newSelected.filter((id) => !selected.includes(id));

    function getGroupWithChildren(id: string): Group[] {
      const group = groupById.get(id);

      if (!group) {
        return [];
      }

      const children = groupsByParentId.get(id) ?? [];

      return [group, ...children.flatMap((id) => getGroupWithChildren(id))];
    }

    const removedGroups = removed.flatMap((id) => getGroupWithChildren(id));
    const addedGroups = added.flatMap((id) => getGroupWithChildren(id));

    let preDraft = [...addedGroups];

    if (value) {
      preDraft = preDraft.concat(value);
    }

    for (const group of removedGroups) {
      preDraft = preDraft.filter((g) => g.id !== group.id);
    }

    const draft = new Set(preDraft);

    // TODO Add some memoization here to avoid checking the same group multiple
    // times.

    // Remove groups that have children when not all children are selected.

    function isRecursivelySelected(group: Group) {
      const id = toNodeId(group?.id);

      const children = groupsByParentId.get(id);

      if (!children) {
        return draft.has(group);
      }

      return children.every((id) => isRecursivelySelected(groupById.get(id)));
    }

    for (const group of draft) {
      if (!isRecursivelySelected(group)) {
        draft.delete(group);
      }
    }

    // Add groups where all children are selected.

    function shouldParentBeAdded(group: Group) {
      const parentId = toNodeId(group?.parentId);

      if (parentId === ROOT_ID) {
        return false;
      }

      const parent = groupById.get(parentId);

      if (!parent) {
        return false;
      }

      return isRecursivelySelected(parent);
    }

    for (const group of draft) {
      if (shouldParentBeAdded(group)) {
        const parent = groupById.get(toNodeId(group.parentId));

        draft.add(parent);
      }
    }

    const asArray = [...draft];

    onChange(asArray);
  };

  return (
    <SelectableTree
      multiple={multiple}
      selectable={multiple ? 'all' : 'leaf'}
      data={treeData}
      selected={selected}
      onChange={(value) => {
        Array.isArray(value) ? handleChange(value) : handleChange([value]);
      }}
    />
  );
}
