import { TaskStatus } from '@work4all/models/lib/Enums/TaskStatus.enum';

import {
  IStatusIndicatorVariant,
  StatusIndicator,
} from '../status-indicator/StatusIndicator';

export type ITaskStatusIndicatorProps = {
  taskStatus: TaskStatus;
};

export function TaskStatusIndicator(props: ITaskStatusIndicatorProps) {
  const { taskStatus } = props;

  const variant = mapStatusToVariant(taskStatus);

  return <StatusIndicator variant={variant} />;
}

function mapStatusToVariant(taskStatus: TaskStatus): IStatusIndicatorVariant {
  switch (taskStatus) {
    case TaskStatus.ZURUECK_GESTELLT:
    case TaskStatus.WARTET:
    case TaskStatus.IN_BEARBEITUNG:
      return 'alert';
    case TaskStatus.OFFEN:
      return 'default';
    case TaskStatus.ERLEDIGT:
      return 'success';
  }
}
