import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { BulkPriceTierItem } from './BulkPriceTierItem.entity';

export class BulkPriceTier<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: name */
  name?: string;
  /** Alias: kalkulatorisch */
  isCalculatory?: boolean;
  /** Alias: items */
  items?: BulkPriceTierItem<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<BulkPriceTier> = {
  id: {
    alias: 'code',
  },
  name: {
    alias: 'name',
  },
  isCalculatory: {
    alias: 'kalkulatorisch',
  },
  items: {
    alias: 'items',
    entity: Entities.bulkPriceTierItem,
  },
  __typename: {
    alias: '__typename',
  },
};

export const bulkPriceTierEntityDefinition: EntitiyDefinition<BulkPriceTier> = {
  local: {},
  remote: {
    queryName: 'getPreisstaffeln',
    fragmentName: 'Preisstaffel',
    withPaginationWrapper: false,
    params: [{ name: 'codes', type: '[Int]' }],
  },
  fieldDefinitions: fields,
};
