import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  IWordLetterTemplatePickerProps,
  WordLetterTemplatePicker,
} from '@work4all/components/lib/components/entity-picker/word-letter-template-picker/WordLetterTemplatePicker';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { reactRefSetter } from '@work4all/utils';

import { LabeledInput } from '../../containers/mask-overlays/locked-inputs';

import { EntityPickerActions } from './EntityPickerActions';

interface WordLetterTemplatePickerFieldProps
  extends Omit<IWordLetterTemplatePickerProps<false>, 'multiple'> {
  error?: string;
}

export const WordLetterTemplatePickerField = React.forwardRef<
  HTMLDivElement,
  WordLetterTemplatePickerFieldProps
>(function WordLetterTemplatePickerField(props, ref) {
  const { error, ...pickerProps } = props;
  const { value, onChange } = pickerProps;

  const { t } = useTranslation();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<EntityPickerPopover>(null);

  return (
    <div ref={reactRefSetter(wrapperRef, ref)}>
      <EntityPickerPopover
        ref={popoverRef}
        anchorEl={wrapperRef}
        picker={<WordLetterTemplatePicker {...pickerProps} multiple={false} />}
        footer={
          <EntityPickerActions
            entity={Entities.wordLetterTemplate}
            multiple={false}
            data={props.data}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(value: any) => {
              props.onChange(value);
              popoverRef.current?.close();
            }}
          />
        }
      >
        <LabeledInput
          value={value?.body ?? ''}
          label={t(`COMMON.WORDLETTERTEMPLATE`)}
          error={error}
          onClear={() => {
            onChange(null);
          }}
        />
      </EntityPickerPopover>
    </div>
  );
});
