import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Sickness } from './Sickness.entity';
import { Vacation } from './Vacation.entity';

export class AbsenceOverview<T extends EMode = EMode.entity> {
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: jahr */
  jahr?: number;
  /** Alias: krankheitItems */
  sicknessList?: Sickness<T>[];
  /** Alias: uebertragVorjahr */
  transferPreviousYear?: number;
  /** Alias: urlaubGenommen */
  vacationDaysTaken?: number;
  /** Alias: urlaubItems */
  vacationList?: Vacation<T>[];
  /** Alias: urlaubRestJahresende */
  vacationRestEndOfYear?: number;
  /** Alias: urlaubsAnspruch */
  vacationClaim?: number;

  __typename?: string;
}

const fields: FieldDefinitions<AbsenceOverview> = {
  userId: {
    alias: 'benutzerCode',
  },
  jahr: {
    alias: 'jahr',
  },
  sicknessList: {
    alias: 'krankheitItems',
    entity: Entities.sickness,
  },
  transferPreviousYear: {
    alias: 'uebertragVorjahr',
  },
  vacationDaysTaken: {
    alias: 'urlaubGenommen',
  },
  vacationList: {
    alias: 'urlaubItems',
    entity: Entities.vacation,
  },
  vacationRestEndOfYear: {
    alias: 'urlaubRestJahresende',
  },
  vacationClaim: {
    alias: 'urlaubsAnspruch',
  },
  __typename: {
    alias: '__typename',
  },
};

export const absenceOverviewEntityDefinition: EntitiyDefinition<AbsenceOverview> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'AbwesenheitUebersicht',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
