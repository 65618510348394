import { Document } from '@work4all/models/lib/Classes/Document.entity';

export const PARENT_DOCUMENT_ID_PROPERTY: keyof Document = 'documentParentCode';

export const DOCUMENT_FILTER_EXCLUDE_CHILD_DOCUMENTS = {
  $or: [
    { [PARENT_DOCUMENT_ID_PROPERTY]: { $eq: 0 } },
    { [PARENT_DOCUMENT_ID_PROPERTY]: { $eq: null } },
  ],
};
