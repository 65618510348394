import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class AppearanceBackgroundImage<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: url */
  url?: string;
  /** Alias: urlThumbnail */
  urlThumbnail?: string;
  /** Alias: filename */
  filename?: string;

  __typename?: string;
}

const fields: FieldDefinitions<AppearanceBackgroundImage> = {
  id: {
    alias: 'id',
  },
  url: {
    alias: 'url',
  },
  urlThumbnail: {
    alias: 'urlThumbnail',
  },
  filename: {
    alias: 'filename',
  },
  __typename: {
    alias: '__typename',
  },
};

export const appearanceBackgroundImageEntityDefinition: EntitiyDefinition<AppearanceBackgroundImage> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'AppearanceBackgroundImage',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
