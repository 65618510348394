import { noop } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { BasicTableColumn, DataType, Table } from '@work4all/components';
import { Collapse } from '@work4all/components/lib/components/collapse';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';

import { ActionsBar } from '../../../../../../../../data-display/actions-bar/ActionsBar';
import { MaskPopover } from '../../../../../../../components/mask-popover/MaskPopover';
import { UpdateEntity } from '../../../../../../../components/update-entity/UpdateEntity';
import { useMaskContext } from '../../../../../../../hooks/mask-context';

import { PurchasePricePopover } from './components/purchase-price-popover/PurchasePricePopover';

export const PurchasePrices = React.forwardRef<TableInstance>((_props, ref) => {
  const { t } = useTranslation();

  const { data } = useMaskContext<Supplier>();

  const columns = useMemo<BasicTableColumn[]>(() => {
    return [
      {
        Header: t('MASK.ARTICLE'),
        accessor: 'articleNumber',
        minWidth: 100,
      },
      {
        Header: t('MASK.VALID_FROM'),
        accessor: 'validFromDate',
        minWidth: 100,
        dataType: DataType.Date,
      },
      {
        Header: t('MASK.LIST_PRICE'),
        accessor: 'listPrice',
        minWidth: 100,
        alignment: 'right',
        Cell: (data) => {
          const value = data.row.original['listPrice'];
          return value.toLocaleString();
        },
      },
      {
        Header: t('MASK.DISCOUNT'),
        accessor: 'discount',
        minWidth: 100,
        alignment: 'right',
        Cell: (data) => {
          const value = data.row.original['discount'];
          return value ? value + ' %' : '';
        },
      },
      {
        Header: t('MASK.PURCHASE_PRICE_SHORT'),
        accessor: 'purchasePrice',
        minWidth: 100,
        alignment: 'right',
        Cell: (data) => {
          const value = data.row.original['purchasePrice'];
          return value.toLocaleString();
        },
      },
    ];
  }, [t]);

  const popoverState = usePopoverState();

  return (
    <Collapse
      headerBar={<ActionsBar hideMoreButton />}
      grid={true}
      defaultOpen={true}
      title={t('MASK.PURCHASE_PRICES')}
    >
      {!data?.id && <UpdateEntity />}
      {data?.id && (
        <>
          <MaskPopover
            title={t('MASK.PURCHASE_PRICE')}
            submit={noop}
            cancel={popoverState.onClose}
            anchorEl={popoverState.anchorEl}
            open={popoverState.open}
          >
            <PurchasePricePopover />
          </MaskPopover>
          <Table
            ref={ref}
            cardsView={false}
            columns={columns}
            data={data?.purchasePriceList}
            mode="client"
            allItemsCount={data?.purchasePriceList?.length}
          />
        </>
      )}
    </Collapse>
  );
});
