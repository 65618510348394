import { Contact } from '@work4all/models/lib/Classes/Contact.entity';

export const contactPhoneNumber = (contact: Contact) => {
  return (
    contact?.phoneNumber ||
    contact?.phoneNumber2 ||
    contact?.phoneNumber3 ||
    contact?.phoneNumberPrivate ||
    contact?.mobileNumber ||
    contact?.mobileNumberPrivate
  );
};
