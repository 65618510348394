import { Theme, useMediaQuery } from '@mui/material';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  ITravelExpenseReportPaymentMethodPickerProps,
  TravelExpenseReportPaymentMethodPicker,
} from '@work4all/components/lib/components/entity-picker/travel-expanse-report-payment-method-picker/TravelExpenseReportPaymentMethodPicker';

import { reactRefSetter } from '@work4all/utils';

import { LabeledInput } from '../../containers/mask-overlays/locked-inputs';

interface TravelExpenseReportPaymentMethodPickerFieldProps
  extends Omit<
    ITravelExpenseReportPaymentMethodPickerProps<false>,
    'multiple'
  > {
  error?: string;
  name?: string;
  label?: string;
  disabled?: boolean;
}

export const TravelExpenseReportPaymentMethodPickerField = React.forwardRef<
  HTMLDivElement,
  TravelExpenseReportPaymentMethodPickerFieldProps
>(function VatPickerField(props, ref) {
  const { error, label, disabled, ...pickerProps } = props;

  const { t } = useTranslation();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<EntityPickerPopover>(null);
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );

  return (
    <div ref={reactRefSetter(wrapperRef, ref)}>
      <EntityPickerPopover
        fullscreen={isMobile}
        ref={popoverRef}
        anchorEl={wrapperRef}
        disabled={disabled}
        picker={
          <TravelExpenseReportPaymentMethodPicker
            {...pickerProps}
            clearable={false}
            multiple={false}
          />
        }
      >
        <LabeledInput
          value={props.value?.name}
          name={props.name}
          disabled={disabled}
          label={label ?? t('COMMON.PAYMENT_KIND')}
          error={error}
        />
      </EntityPickerPopover>
    </div>
  );
});
