import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { WidgetData } from './WidgetData.entity';

export class LayoutData<T extends EMode = EMode.entity> {
  /** Alias: data */
  data?: WidgetData<T>[];
  /** Alias: layoutId */
  layoutId?: string;

  __typename?: string;
}

const fields: FieldDefinitions<LayoutData> = {
  data: {
    alias: 'data',
    entity: Entities.widgetData,
  },
  layoutId: {
    alias: 'layoutId',
  },
  __typename: {
    alias: '__typename',
  },
};

export const layoutDataEntityDefinition: EntitiyDefinition<LayoutData> = {
  local: {},
  remote: {
    queryName: 'getLayoutData',
    fragmentName: 'LayoutData',
    withPaginationWrapper: false,
    params: [
      { name: 'sdObjMemberCode', type: 'Int!' },
      { name: 'type', type: 'LayoutType!' },
      { name: 'maxWidgetItems', type: 'Int' },
      { name: 'apCode', type: 'Int' },
      { name: 'onlySelfAssigned', type: 'Boolean' },
      { name: 'timeFrom', type: 'DateTime' },
      { name: 'timeTo', type: 'DateTime' },
    ],
  },
  fieldDefinitions: fields,
};
