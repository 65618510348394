import baseStyles from '../alert/Alert.module.scss';
import styles from './InstructionsAlert.module.scss';

import { Divider, ModalProps } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CameraIcon } from '@work4all/assets/icons/camera.svg';
import { ReactComponent as CodeIcon } from '@work4all/assets/icons/code.svg';
import { ReactComponent as ContentPaste } from '@work4all/assets/icons/content-paste.svg';
import { ReactComponent as LocationIcon } from '@work4all/assets/icons/location.svg';
import { ReactComponent as MicrophoneIcon } from '@work4all/assets/icons/microphone.svg';
import { ReactComponent as NotificationsIcon } from '@work4all/assets/icons/notifications.svg';

import { ActionButton } from '../../input/action-button/ActionButton';
import { Body1 } from '../../typography/body1/Body1';
import { Caption } from '../../typography/caption/Caption';
import { Alert } from '../alert/Alert';
import { OkAlert } from '../alert/OkAlert';

export type AlertState =
  | 'PROMPT'
  | 'DENIED'
  | 'FAILED'
  | 'RESOURCE_UNAUTHORIZED'
  | 'NONE';

export interface IInstructionsAlertProps extends Omit<ModalProps, 'children'> {
  type:
    | 'location'
    | 'camera'
    | 'microphone'
    | 'notifications'
    | 'javascript'
    | 'clipboardCustomer'
    | 'clipboardSupplier';
  cancel: () => void;
  accept: () => void;
  state?: AlertState;
}

const instructionDataByType: Record<
  IInstructionsAlertProps['type'],
  {
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    titleKey: string;
    captionKey;
  }
> = {
  location: {
    icon: LocationIcon,
    titleKey: 'ALERTS.LOCATION',
    captionKey: 'ALERTS.LOCATION_GIVE_PERMISSION',
  },
  camera: {
    icon: CameraIcon,
    titleKey: 'ALERTS.CAMERA',
    captionKey: 'ALERTS.CAMERA_GIVE_PERMISSION',
  },
  microphone: {
    icon: MicrophoneIcon,
    titleKey: 'ALERTS.MICROPHONE',
    captionKey: 'ALERTS.MICROPHONE_GIVE_PERMISSION',
  },
  notifications: {
    icon: NotificationsIcon,
    titleKey: 'ALERTS.NOTIFICATIONS',
    captionKey: 'ALERTS.NOTIFICATIONS_GIVE_PERMISSION',
  },
  javascript: {
    icon: CodeIcon,
    titleKey: 'ALERTS.JAVASCRIPT',
    captionKey: 'ALERTS.JAVASCRIPT_GIVE_PERMISSION',
  },
  clipboardCustomer: {
    icon: ContentPaste,
    titleKey: 'ALERTS.CLIPBOARD',
    captionKey: 'ALERTS.CLIPBOARD_CUSTOMER_GIVE_PERMISSON',
  },
  clipboardSupplier: {
    icon: ContentPaste,
    titleKey: 'ALERTS.CLIPBOARD',
    captionKey: 'ALERTS.CLIPBOARD_SUPPLIER_GIVE_PERMISSON',
  },
};

const instructionDataByState = {
  prompt: {
    headerKey: 'ALERTS.PERMISSION_REQUIRED',
  },
  denied: {
    headerKey: 'ALERTS.PERMISSION_REQUIRED',
  },
  failed: {
    headerKey: 'ALERTS.PERMISSION_READ_FAILED',
  },
};

export const InstructionsAlert: React.FC<IInstructionsAlertProps> = (props) => {
  const { t } = useTranslation();
  const { state = 'PROMPT', type, ...modalProps } = props;

  const data = instructionDataByState[state.toLocaleLowerCase()];
  if (state === 'RESOURCE_UNAUTHORIZED')
    return (
      <OkAlert
        {...props}
        header={t('ALERTS.PERMISSION_READ_FAILED')}
        body={
          type === 'clipboardCustomer'
            ? t('ALERTS.NOT_AUTHORIZED_TO_CREATE_CUSTOMER')
            : t('ALERTS.NOT_AUTHORIZED_TO_CREATE_SUPPLIER')
        }
      />
    );

  return (
    <Alert
      {...modalProps}
      header={data ? t(data.headerKey) : ''}
      body={<InstructionsAlertBody {...props} />}
      footer={<InstructionsAlertFooter {...props} />}
    />
  );
};

export const InstructionsAlertBody = (props: IInstructionsAlertProps) => {
  const { t } = useTranslation();
  const { state, type } = props;
  const { icon: Icon, titleKey, captionKey } = instructionDataByType[type];

  const baseBody = (
    <div className={styles.body}>
      <Icon />
      <Body1 className={baseStyles.title}>{t(titleKey)}</Body1>
      <Caption className={baseStyles.caption}>{t(captionKey)}</Caption>
    </div>
  );

  if (state === 'PROMPT') return baseBody;

  return (
    <div>
      <Body1 className={styles.text}>{t('ALERTS.PERMISSION_DENIED')}</Body1>
      <Divider className={styles.divider} />
      <div className={styles.body}>{baseBody}</div>
    </div>
  );
};

export const InstructionsAlertFooter = (props: IInstructionsAlertProps) => {
  const { t } = useTranslation();
  const { state, cancel, accept } = props;

  switch (state) {
    case 'PROMPT':
      return (
        <>
          <ActionButton
            title={t('ALERTS.REJECT')}
            className={clsx(baseStyles.actionButton, baseStyles.reject)}
            onClick={cancel}
          />
          <ActionButton
            title={t('ALERTS.GRANT')}
            className={clsx(baseStyles.actionButton, baseStyles.grant)}
            onClick={accept}
          />
        </>
      );
    case 'DENIED':
      return (
        <ActionButton
          title={t('ALERTS.UNDERSTOOD')}
          className={clsx(baseStyles.actionButton, baseStyles.grant)}
          onClick={cancel}
        />
      );
    case 'FAILED':
    default:
      return (
        <ActionButton
          title={t('ALERTS.OK')}
          className={clsx(baseStyles.actionButton, baseStyles.grant)}
          onClick={cancel}
        />
      );
  }
};
