import { forwardRef, useContext } from 'react';

import { useLock } from '@work4all/components/lib/hooks/object-lock';

import { useInaccessibleFields } from '@work4all/data';

import { MaskContext } from '../hooks/mask-context';
import { EntityRightsContext } from '../hooks/use-entity-rights';
/**
 * wraps field with the ability to react to a lock context e.g. if the entity is edited by someone else
 * or the active user is missing rights
 * @param Component
 * @returns
 */
export const withAutoDisable = <
  Props extends { disabled?: boolean; name?: string }
>(
  Component: React.FC<Props>
): React.FC<Props> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return forwardRef<any, Props>(function WithAutoDisable(props, ref) {
    const { locked } = useLock();
    const { update, create } = useContext(EntityRightsContext);
    const mask = useContext(MaskContext);
    const { isInaccessible } = useInaccessibleFields();
    let disabled = props.disabled;
    if (disabled === undefined) {
      //if explicitly set on the item, then dont override it
      disabled =
        locked ||
        (!update && mask.mode === 'edit') ||
        (!create && mask.mode === 'create') ||
        props.disabled;
      if (mask !== null && props.name) {
        //if the field is registered with a name
        //additionally for some users fields are not editable by rights definition, those fields are auto locked as well
        disabled = disabled || isInaccessible(mask.entity, props.name);
      }
    }

    return <Component ref={ref} {...props} disabled={disabled} />;
  }) as React.FC<Props>;
};
