import styles from './styles.module.scss';

import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Typography } from '@mui/material';
import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { ProjectCategoryClassPicker } from '@work4all/components/lib/components/entity-picker/project-category-class-picker/ProjectCategoryClassPicker';

import { useDataMutation } from '@work4all/data';

import { CategoryAssignment } from '@work4all/models/lib/Classes/CategoryAssignment.entity';
import { InputProjektRelation } from '@work4all/models/lib/Classes/InputProjektRelation.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { ProjectCategory } from '@work4all/models/lib/Classes/ProjectCategory.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

type IProjectCategoryCardProps = {
  categories: Array<CategoryAssignment>;
  id: number;
};

export const ProjectCategoryCard: FC<IProjectCategoryCardProps> = ({
  categories,
  id,
}) => {
  const { t } = useTranslation();
  const sortedCategories = (a: CategoryAssignment, b: CategoryAssignment) =>
    a.categoryName?.localeCompare(b.categoryName);

  const [showAll, setShowAll] = useState(false);
  const maxRows = 5;
  const categoryFieldRef = useRef(null);

  const [mutate] = useDataMutation<Project, EMode.upsert>({
    entity: Entities.project,
    mutationType: EMode.upsert,
    responseData: {
      id: null,
      categoryAssignmentList: [
        {
          categoryId: null,
          categoryName: null,
        },
      ],
    },
  });

  const updateCategories = (props: { newCategories: ProjectCategory[] }) => {
    const relations: InputProjektRelation = {
      projectCategories: {
        add: props.newCategories
          ?.filter(
            (currentCategories) =>
              !categories?.find(
                (prevCategories) =>
                  currentCategories.id === prevCategories.categoryId
              )
          )
          ?.map((categories) => ({
            categoryCode: categories.id,
          })),
        remove: categories
          ?.filter(
            (prevCategories) =>
              !props.newCategories?.find(
                (currentCategories) =>
                  currentCategories.id === prevCategories.categoryId
              )
          )
          ?.map((categories) => categories.categoryId),
      },
    };

    const keysToMutate = { id: id };

    mutate(keysToMutate, { relations });
  };

  const createExtendedCategoryItems = () => {
    if (!categories || categories.length === 0) return '-';
    return (
      <>
        {[...categories]
          .sort((a, b) => sortedCategories(a, b))
          .map((item, i) =>
            i < maxRows || showAll ? (
              <div key={i} className={styles.category}>
                <div className={styles.secondary}>{item.categoryKindName}</div>
                <div className={styles.primary}>{item.categoryName}</div>
              </div>
            ) : null
          ) ?? '-'}
        {categories.length > maxRows && !showAll && (
          <span className={styles.blueAction} onClick={() => setShowAll(true)}>
            {'+' + (categories.length - maxRows) + ' ' + t('FAV_LINKS.MORE')}
          </span>
        )}
      </>
    );
  };

  const createCategoriesLayout = () => {
    return (
      <div className={styles['category-layout']}>
        {createExtendedCategoryItems()}
      </div>
    );
  };

  return (
    <div>
      <div className={styles.categoryHeadWrapper}>
        <Typography
          alignItems="center"
          display="flex"
          variant="body2"
          color="text.tertiary"
        >
          {t('COMMON.CATEGORY_plural')}
        </Typography>

        <EntityPickerPopover
          anchorEl={categoryFieldRef.current}
          picker={
            <ProjectCategoryClassPicker
              multiple
              value={
                !Array.isArray(categories)
                  ? []
                  : categories.map((categoryAssignment) => ({
                      id: categoryAssignment?.categoryId,
                      name: categoryAssignment?.categoryName,
                    }))
              }
              onChange={(value) => {
                const newValue: ProjectCategory[] = value?.map((category) => {
                  return {
                    id: category.id,
                    name: category.name,
                  };
                });
                updateCategories({ newCategories: newValue });
              }}
            />
          }
        >
          <IconButton size="large" color="primary" onClick={() => ''}>
            <EditIcon />
          </IconButton>
        </EntityPickerPopover>
      </div>
      <div className={styles.categoryContentWrapper}>
        {createCategoriesLayout()}
      </div>
    </div>
  );
};
