import { Theme, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';

export function useERPOverlayControllerTabState(defaultValue: string | number) {
  const isTablet = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));

  const [tab, setTab] = useState<string | number>(defaultValue);

  // We do not show the general tab on mobile
  const realTab = tab === 'general' && !isTablet ? 'miscellaneous' : tab;

  useEffect(() => {
    if (tab !== realTab) {
      setTab(realTab);
    }
  }, [tab, realTab]);

  return [realTab, setTab] as const;
}
