import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { FileEntity } from './FileEntity.entity';
import { User } from './User.entity';

export class SalesOpportunityAttachement<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: salesOpportunityCode */
  salesOpportunityCode?: number;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: fileKey */
  fileKey?: string;
  /** Alias: fileDisplayname */
  fileDisplayname?: string;
  /** Alias: userCode */
  userId?: number;
  /** Alias: fileInfos */
  fileInfos?: FileEntity<T>;
  /** Alias: user */
  user?: User<T>;

  __typename?: string;
}

const fields: FieldDefinitions<SalesOpportunityAttachement> = {
  id: {
    alias: 'id',
  },
  salesOpportunityCode: {
    alias: 'salesOpportunityCode',
  },
  insertTime: {
    alias: 'insertTime',
  },
  updateTime: {
    alias: 'updateTime',
  },
  fileKey: {
    alias: 'fileKey',
  },
  fileDisplayname: {
    alias: 'fileDisplayname',
  },
  userId: {
    alias: 'userCode',
  },
  fileInfos: {
    alias: 'fileInfos',
    entity: Entities.fileEntity,
  },
  user: {
    alias: 'user',
    entity: Entities.user,
  },
  __typename: {
    alias: '__typename',
  },
};

export const salesOpportunityAttachementEntityDefinition: EntitiyDefinition<SalesOpportunityAttachement> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'SalesOpportunityAttachement',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
