import { matchSorter } from 'match-sorter';
import { Row } from 'react-table';

import { GlobalFilter } from '../../types';
import { getDefaultFilter } from '../updateFilter';

export const fuzzyText = (
  rows: Row[],
  id: string[],
  filterValue: GlobalFilter
) => {
  const fValue = getDefaultFilter<string>(filterValue);
  if (!fValue) {
    return rows;
  }

  return matchSorter(rows, fValue, {
    keys: [(row) => row.values[id[0]]],
  });
};
