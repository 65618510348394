import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ClipboardAlert,
  ClipboardAlertState,
} from '@work4all/components/lib/dataDisplay/clipboard-alert/ClipboarAlert';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { LegalNoticeParseResult } from '@work4all/models/lib/Classes/LegalNoticeParseResult.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useLegalNotice } from '../../hooks/useLegalNotice';

interface IInsertFromClipboard {
  entity: Entities;
  onSuccess?: (value: Customer | Supplier) => void;
  onClose?: () => void;
}
export const InsertFromClipboard: React.FC<IInsertFromClipboard> = (props) => {
  const { onSuccess, onClose, entity } = props;
  const { t } = useTranslation();
  const [imprintValue, setImprintValue] = useState<string>('');
  const [skip, setSkip] = useState<boolean>(true);
  const [initialChecked, setInitialChecked] = useState<boolean>(false);
  const [instructionsDialogState, setInstructionsDialogState] =
    useState<ClipboardAlertState>('NONE');
  const { data: resultData } = useLegalNotice(imprintValue, skip);
  const requestCount = useRef(0);

  useEffect(() => {
    requestCount.current++;

    if (requestCount.current < 1 && !resultData) {
      return;
    }

    if (resultData && !Array.isArray(resultData)) {
      const data = resultData as LegalNoticeParseResult;
      onSuccess && onSuccess({ ...data, __typename: null });
    } else if (resultData === null) {
      setInitialChecked(true);
    }
    requestCount.current = 0;
    setSkip(true);
  }, [resultData, onSuccess, t]);

  async function getClipboardContent() {
    try {
      const clipboardContent = await navigator.clipboard.readText();
      setImprintValue(clipboardContent);
      if (clipboardContent && clipboardContent.length > 0) {
        setSkip(false);
      } else {
        setInstructionsDialogState('EMPTY');
        setInitialChecked(true);
      }
    } catch (err) {
      console.error('Failed to read clipboard contents: ', err);
    }
  }

  const onUserAgreed = () => {
    getClipboardContent();
    setInstructionsDialogState('NONE');
  };

  useEffect(() => {
    const init = async () => {
      const clipboardPermissonName = 'clipboard-read' as PermissionName;
      await navigator.permissions
        .query({
          name: clipboardPermissonName,
        })
        .then((res) => {
          if (res.state === 'prompt') {
            setInstructionsDialogState('PROMPT');
            setInitialChecked(true);
            return;
          }
          if (res.state === 'granted') {
            getClipboardContent();
            return;
          }
          if (res.state === 'denied') {
            setInstructionsDialogState('DENIED');
            setInitialChecked(true);
            return;
          }
        });
    };
    init();
  }, []);

  const showInstructionDialog = instructionsDialogState !== 'NONE';
  if (!initialChecked || !showInstructionDialog) return null;

  return (
    <ClipboardAlert
      type={
        entity === Entities.customer ? 'clipboardCustomer' : 'clipboardSupplier'
      }
      open={showInstructionDialog}
      state={instructionsDialogState}
      onClose={() => {
        setInstructionsDialogState('NONE');
        onClose();
      }}
      cancel={() => {
        setInstructionsDialogState('NONE');
        onClose();
      }}
      accept={() => {
        onUserAgreed();
        setInstructionsDialogState('NONE');
      }}
    />
  );
};
