import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EditIcon } from '@work4all/assets/icons/edit.svg';

import { IconButtonWithTooltip } from '../IconButtonWithTooltip';
import { BaseActionProps } from '../types';

export const Edit: React.FC<BaseActionProps> = (props) => {
  const { t } = useTranslation();
  return (
    <IconButtonWithTooltip
      tooltip={props.disabled ? t('RIGHTS.MISSING') : t('MASK.EDIT')}
      icon={<EditIcon />}
      {...props}
    />
  );
};
