import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { IMaskUserConfig, SearchHistoryResults } from './types';
import { UseSettingOptions } from './use-setting';

// Configure shared settings here.

const DEFAULT_MASK_STATE: IMaskUserConfig = {};

/** Mask state. */
export function mask(options: {
  entityType: string;
}): UseSettingOptions<IMaskUserConfig> {
  const { entityType } = options;

  return {
    name: `mask.${entityType}`,
    scope: 'user',
    defaultValue: DEFAULT_MASK_STATE,
  };
}

const SEARCH_HISTORY_DEFAULT_VALUE: SearchHistoryResults = {
  [Entities.customer]: [],
  [Entities.supplier]: [],
  [Entities.project]: [],
  [Entities.article]: [],
};

/**
 * Search results for the global search component and file pages. The search
 * items are grouped by entity type.
 * */
export function searchHistory(): UseSettingOptions<SearchHistoryResults> {
  return {
    name: `searchHistory`,
    scope: 'tenant',
    defaultValue: SEARCH_HISTORY_DEFAULT_VALUE,
  };
}
