import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { KpisCustomerSalesValueYear } from './KpisCustomerSalesValueYear.entity';

export class KpisCustomer<T extends EMode = EMode.entity> {
  /** Alias: salesValues */
  salesValues?: KpisCustomerSalesValueYear<T>[];
  /** Alias: vacantPositions */
  vacantPositions?: number;
  /** Alias: dueOpenValue */
  dueOpenValue?: number;

  __typename?: string;
}

const fields: FieldDefinitions<KpisCustomer> = {
  salesValues: {
    alias: 'salesValues',
    entity: Entities.kpisCustomerSalesValueYear,
  },
  vacantPositions: {
    alias: 'vacantPositions',
  },
  dueOpenValue: {
    alias: 'dueOpenValue',
  },
  __typename: {
    alias: '__typename',
  },
};

export const kpisCustomerEntityDefinition: EntitiyDefinition<KpisCustomer> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'KpisCustomer',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
