import { CategoryPicker, CategoryPickerProps } from './CategoryPicker';

const filter = [
  {
    $and: [
      { isSupplierCategory: { $eq: true } },
      { isPersonCategory: { $eq: false } },
    ],
  },
];

type SupplierCategoryPickerProps = Omit<CategoryPickerProps, 'filter'>;

export function SupplierCategoryPicker(props: SupplierCategoryPickerProps) {
  return <CategoryPicker {...props} filter={filter} />;
}
