import { ITableColumnConfig } from '@work4all/models/lib/table-schema/table-schema';

import { DataTableColumnConfig } from './table/DataTableColumnConfig';

type ColumnConfigLike =
  | ITableColumnConfig<unknown>
  | DataTableColumnConfig<unknown>;

export type ColumnConfigWithId = Pick<ColumnConfigLike, 'id' | 'accessor'>;

export function getColumnId(column: ColumnConfigWithId): string {
  if (typeof column.id === 'string') return column.id;
  if (typeof column.accessor === 'string') return column.accessor;

  throw new Error('Column does not have a valid id.');
}
