import styles from './Left.module.scss';

import clsx from 'clsx';
import React from 'react';

import { TabPanelSectionProps } from '../../../../../../..';
import { Panel } from '../../../../../../../components/panel/Panel';
import { Communication } from '../communication/Communication';
import { Organization } from '../organization/Organization';
import { PersonalData } from '../personal-data/PersonalData';

export const Left: React.FC<TabPanelSectionProps> = (props) => {
  return (
    <Panel
      className={clsx(styles.left, {
        [styles.desktop]: !props.mobile,
        [styles.mobile]: props.mobile,
      })}
    >
      <PersonalData />
      <Communication />
      <Organization />
    </Panel>
  );
};
