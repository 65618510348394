import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputSalesOpportunityRatingSet } from './InputSalesOpportunityRatingSet.entity';

export class InputSalesOpportunityRatingRelation<
  T extends EMode = EMode.entity
> {
  /** Alias: set */
  set?: InputSalesOpportunityRatingSet<T>[];
  /** Alias: delete */
  delete?: number[];

  __typename?: string;
}

const fields: FieldDefinitions<InputSalesOpportunityRatingRelation> = {
  set: {
    alias: 'set',
  },
  delete: {
    alias: 'delete',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputSalesOpportunityRatingRelationEntityDefinition: EntitiyDefinition<InputSalesOpportunityRatingRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputSalesOpportunityRatingRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
