import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { AddressConnection } from '@work4all/models/lib/Classes/AddressConnection.entity';
import { BusinessPartner } from '@work4all/models/lib/Classes/BusinessPartner.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import {
  ADDRESS_CONNECTION_FIELDS,
  BUSINESS_PARTNER_DATA_FIELD,
} from '../constants';

interface Props {
  businessPartnerId: number;
  businessPartnerType: SdObjType;
  relationId: number | undefined;
  isCreateMode: boolean;
}

export const useRelationData = ({
  businessPartnerId,
  businessPartnerType,
  relationId,
  isCreateMode,
}: Props) => {
  // Query the business partner if `isCreateMode` is `true`.
  const businessPartnerRequestData = useMemo<DataRequest>(() => {
    return {
      entity:
        businessPartnerType === SdObjType.KUNDE
          ? Entities.customer
          : Entities.supplier,
      data: BUSINESS_PARTNER_DATA_FIELD,
      filter: [{ id: { $eq: businessPartnerId } }],
    };
  }, [businessPartnerId, businessPartnerType]);

  const businessPartnerRequest = useDataProvider<Customer>(
    businessPartnerRequestData,
    !isCreateMode
  );
  const businessPartner: BusinessPartner = useMemo(
    () =>
      businessPartnerRequest.data[0]
        ? {
            id: businessPartnerRequest.data[0]?.id,
            data: businessPartnerRequest.data[0],
          }
        : undefined,
    [businessPartnerRequest.data]
  );

  // Query the normal relation if `isCreateMode` is `false`.
  const relationRequestData = useMemo<DataRequest>(() => {
    return {
      entity: Entities.addressConnection,
      data: ADDRESS_CONNECTION_FIELDS,
      filter: [{ id: { $eq: relationId } }],
    };
  }, [relationId]);

  const relationRequest = useDataProvider<AddressConnection>(
    relationRequestData,
    isCreateMode
  );
  const relation = relationRequest.data[0];

  // Query the reverse relation if `isCreateMode` is `false` and `relation` not nullable.
  const reverseRelationRequestData = useMemo<DataRequest>(() => {
    return {
      entity: Entities.addressConnection,
      data: ADDRESS_CONNECTION_FIELDS,
      filter: [
        { sdObjTypeB: { $eq: relation?.sdObjTypeA } },
        { sdObjMemberIdB: { $eq: relation?.sdObjMemberIdA } },
        { sdObjTypeA: { $eq: relation?.sdObjTypeB } },
        { sdObjMemberIdA: { $eq: relation?.sdObjMemberIdB } },
      ],
    };
  }, [relation]);

  const reverseRelationRequest = useDataProvider<AddressConnection>(
    reverseRelationRequestData,
    isCreateMode || !relation
  );
  const reverseRelation = reverseRelationRequest.data[0];

  return useMemo(() => {
    if (
      businessPartnerRequest.loading ||
      businessPartnerRequest.pending ||
      relationRequest.loading ||
      relationRequest.pending ||
      reverseRelationRequest.loading ||
      reverseRelationRequest.pending
    ) {
      return {};
    }

    return {
      businessPartnerA: businessPartner ?? relation?.businessPartnerA,
      businessPartnerB: relation?.businessPartnerB,
      relation,
      reverseRelation,
    };
  }, [
    businessPartnerRequest.loading,
    businessPartnerRequest.pending,
    relationRequest.loading,
    relationRequest.pending,
    reverseRelationRequest.loading,
    reverseRelationRequest.pending,
    businessPartner,
    relation,
    reverseRelation,
  ]);
};
