import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { CostCenter } from './CostCenter.entity';
import { LedgerAccount } from './LedgerAccount.entity';
import { LookUp } from './LookUp.entity';
import { Project } from './Project.entity';

export class RELedgerAccountSplit<T extends EMode = EMode.entity> {
  /** Alias: anteilDM */
  proportionDM?: number;
  /** Alias: anteilProzent */
  proportionPercent?: number;
  /** Alias: aufmass */
  measurement?: string;
  /** Alias: berichtigungsschluessel */
  correctionKey?: number;
  /** Alias: code */
  id?: number;
  /** Alias: datev */
  datevDate?: string;
  /** Alias: gegenkonto */
  contraAccount?: number;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: kostengruppe */
  costGroup?: LookUp<T>;
  /** Alias: mwst */
  vat?: number;
  /** Alias: mwstBetrag */
  vatAmount?: number;
  /** Alias: notiz */
  note?: string;
  /** Alias: projektCode */
  projectId?: number;
  /** Alias: rECode */
  rEId?: number;
  /** Alias: rechnungCode */
  invoiceId?: number;
  /** Alias: reisekostenabrechnungBelegCode */
  travelExpensesreceiptId?: number;
  /** Alias: steuerschluessel */
  taxKey?: number;
  /** Alias: umbuchung */
  rebooking?: number;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: vorlauf */
  foreRunDate?: string;
  /** Alias: projekt */
  project?: Project<T>;
  /** Alias: bestellungCode */
  orderId?: number;
  /** Alias: kostengruppeCode */
  costGroupId?: number;
  /** Alias: kostenstelleCode */
  costCenterId?: number;
  /** Alias: kostenstelleNummer */
  costCenter1Number?: number;
  /** Alias: sachkontoCode */
  ledgerAccountId?: number;
  /** Alias: sachkontoNummer */
  ledgerAccountNumber?: number;
  /** Alias: kostenstelle */
  costCenter?: CostCenter<T>;
  /** Alias: konto */
  konto?: LedgerAccount<T>;
  /** Alias: valueNet */
  valueNet?: number;

  __typename?: string;
}

const fields: FieldDefinitions<RELedgerAccountSplit> = {
  proportionDM: {
    alias: 'anteilDM',
  },
  proportionPercent: {
    alias: 'anteilProzent',
  },
  measurement: {
    alias: 'aufmass',
  },
  correctionKey: {
    alias: 'berichtigungsschluessel',
  },
  id: {
    alias: 'code',
  },
  datevDate: {
    alias: 'datev',
  },
  contraAccount: {
    alias: 'gegenkonto',
  },
  insertTime: {
    alias: 'insertTime',
  },
  costGroup: {
    alias: 'kostengruppe',
    entity: Entities.lookUp,
  },
  vat: {
    alias: 'mwst',
  },
  vatAmount: {
    alias: 'mwstBetrag',
  },
  note: {
    alias: 'notiz',
  },
  projectId: {
    alias: 'projektCode',
  },
  rEId: {
    alias: 'rECode',
  },
  invoiceId: {
    alias: 'rechnungCode',
  },
  travelExpensesreceiptId: {
    alias: 'reisekostenabrechnungBelegCode',
  },
  taxKey: {
    alias: 'steuerschluessel',
  },
  rebooking: {
    alias: 'umbuchung',
  },
  updateTime: {
    alias: 'updateTime',
  },
  foreRunDate: {
    alias: 'vorlauf',
  },
  project: {
    alias: 'projekt',
    entity: Entities.project,
  },
  orderId: {
    alias: 'bestellungCode',
  },
  costGroupId: {
    alias: 'kostengruppeCode',
  },
  costCenterId: {
    alias: 'kostenstelleCode',
  },
  costCenter1Number: {
    alias: 'kostenstelleNummer',
  },
  ledgerAccountId: {
    alias: 'sachkontoCode',
  },
  ledgerAccountNumber: {
    alias: 'sachkontoNummer',
  },
  costCenter: {
    alias: 'kostenstelle',
    entity: Entities.costCenter,
  },
  konto: {
    alias: 'konto',
    entity: Entities.ledgerAccount,
  },
  valueNet: {
    alias: 'valueNet',
  },
  __typename: {
    alias: '__typename',
  },
};

export const rELedgerAccountSplitEntityDefinition: EntitiyDefinition<RELedgerAccountSplit> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'RESachkontenSplit',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
