import { CellProps } from 'react-table';

import { ChronoFileItem } from '@work4all/models/lib/Classes/ChronoFileItem.entity';

export const DescriptionCell = (props: CellProps<ChronoFileItem>) => {
  const fileItem = props.cell.row.original.fileItem;
  const note = getDescription(fileItem);
  if (note) {
    return <div>{`${note}`}</div>;
  }

  return null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getDescription(fileItem: any): string {
  return (
    fileItem?.note || fileItem?.title || fileItem?.subject || fileItem?.name
  );
}
