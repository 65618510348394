import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Article } from './Article.entity';

export class StatisticsSalesVolumeByItemRow<T extends EMode = EMode.entity> {
  /** Alias: date */
  date?: string;
  /** Alias: objCode */
  objId?: number;
  /** Alias: amount */
  amount?: number;
  /** Alias: artikel */
  artikel?: Article<T>;

  __typename?: string;
}

const fields: FieldDefinitions<StatisticsSalesVolumeByItemRow> = {
  date: {
    alias: 'date',
  },
  objId: {
    alias: 'objCode',
  },
  amount: {
    alias: 'amount',
  },
  artikel: {
    alias: 'artikel',
    entity: Entities.article,
  },
  __typename: {
    alias: '__typename',
  },
};

export const statisticsSalesVolumeByItemRowEntityDefinition: EntitiyDefinition<StatisticsSalesVolumeByItemRow> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'StatisticsSalesVolumeByItemRow',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
