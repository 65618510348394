import { useMemo } from 'react';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import {
  OFFER_CONVERT_TYPES,
  OFFER_CONVERT_TYPES_BLOCKED,
} from '../../../../../../../components/data-tables/offers-table/hooks/use-clone-convert-erp';
import { useConvertBzObjectMutation } from '../../../../../../../components/data-tables/offers-table/hooks/use-convert-bz-object-mutation';

// For now this only support Entities.offer
export const useCardDnd = (entity: Entities) => {
  const { mutate } = useConvertBzObjectMutation({ targetEntity: entity });

  const value = useMemo(() => {
    const canDrag = () => entity === Entities.offer;
    const canDrop = () => {
      return OFFER_CONVERT_TYPES.includes(entity);
    };
    const canSkip = () => {
      return ![...OFFER_CONVERT_TYPES_BLOCKED, ...OFFER_CONVERT_TYPES].includes(
        entity
      );
    };

    // eslint-disable-next-line @typescript-eslint/ban-types
    const onDrop = (item: Object) => {
      if (!('id' in item && typeof item.id === 'number'))
        throw new Error('Wrong dropped item in useCardDnd');

      mutate({
        sourceEntity: Entities.offer,
        sourceIds: [item.id],
        targetEntity: entity,
      });
    };

    return { canDrag, canDrop, canSkip, onDrop };
  }, [entity, mutate]);

  return value;
};
