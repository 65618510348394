import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { ObjectType } from '../Enums/ObjectType.enum';

import { ChangeEventChangeInfo } from './ChangeEventChangeInfo.entity';
import { User } from './User.entity';

export class ChangeEvent<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: creationDate */
  creationDate?: string;
  /** Alias: objectType */
  objectType?: ObjectType;
  /** Alias: objectId */
  objectId?: string;
  /** Alias: userCode */
  userId?: number;
  /** Alias: user */
  user?: User<T>;
  /** Alias: changes */
  changes?: ChangeEventChangeInfo<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<ChangeEvent> = {
  id: {
    alias: 'id',
  },
  creationDate: {
    alias: 'creationDate',
  },
  objectType: {
    alias: 'objectType',
  },
  objectId: {
    alias: 'objectId',
  },
  userId: {
    alias: 'userCode',
  },
  user: {
    alias: 'user',
    entity: Entities.user,
  },
  changes: {
    alias: 'changes',
    entity: Entities.changeEventChangeInfo,
  },
  __typename: {
    alias: '__typename',
  },
};

export const changeEventEntityDefinition: EntitiyDefinition<ChangeEvent> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'ChangeEvent',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
