import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';

import { checkModuleRight, useModuleRights, useUser } from '@work4all/data';

import {
  checkUserRight,
  UserRights,
} from '@work4all/models/lib/additionalEnums/UserRights';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ModuleAccessRightType } from '@work4all/models/lib/Enums/ModuleAccessRightType.enum';

import { settings, useSetting } from '../../../../../settings';
import { CheckboxRadioItem } from '../../../../mask-overlays/locked-inputs';
import {
  Collapse,
  Panel,
} from '../../../../mask-overlays/mask-overlay/components';
import { IFileInfoPanelItems } from '../file-info-panel/types';

import { useFileSettingsGroups } from './useFileSettingsGroups';

type IFileSettings = Partial<Record<IFileInfoPanelItems, boolean>>;

export const PersonalTabPanel = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const array = location.pathname.split('/');
  const entity = array[array.length - 1] as Entities;
  const groups = useFileSettingsGroups(entity);
  const setting = useSetting<IFileSettings>(settings.fileSettings(entity));
  const fileHideLeftContacts = useSetting(settings.fileHideLeftContacts());

  const { rights } = useModuleRights();
  const user = useUser();

  const fieldsList = groups.map((group, idx) => (
    <div key={idx}>
      {!!idx && <Divider style={{ margin: '0.5rem' }} />}
      {group.map((singleSetting) => {
        if (singleSetting.id === IFileInfoPanelItems.Opportunities) {
          if (
            !checkUserRight(
              UserRights.VerkaufschancenNutzen,
              user.benutzerRechte
            )
          ) {
            return null;
          }
        }

        if (singleSetting.id === IFileInfoPanelItems.OpenDuePositions) {
          if (
            !checkModuleRight(
              rights,
              ModuleAccessRightType.FEATURE_ORDER_MANAGEMENT_MODULE
            ) ||
            !checkUserRight(
              entity === Entities.customer
                ? UserRights.OffenePostenKundenEinsehen
                : UserRights.OffenePostenLieferantenEinsehen,
              user.benutzerRechte
            )
          ) {
            return null;
          }
        }
        if (singleSetting.id === IFileInfoPanelItems.SubscriptionContract) {
          if (
            !checkModuleRight(
              rights,
              ModuleAccessRightType.FEATURE_MAINTENANCE_CONTRACT_MODULE
            ) ||
            !checkUserRight(UserRights.WartungsmodulNutzen, user.benutzerRechte)
          ) {
            return null;
          }
        }

        return (
          <CheckboxRadioItem
            key={singleSetting.label}
            label={singleSetting.label}
            defaultChecked={setting.value[singleSetting.id]}
            onChange={() => {
              setting.set({
                ...setting.value,
                [singleSetting.id]: !setting.value[singleSetting.id],
              });
            }}
            disabled={singleSetting.disabled}
          />
        );
      })}
    </div>
  ));

  return (
    <Panel>
      <Collapse title={t('FILE_CONTACTS.CONTACTS')} defaultOpen={true}>
        <CheckboxRadioItem
          label={t('FILE_CONTACTS.HIDE_RETIRED_CONTACTS')}
          defaultChecked={fileHideLeftContacts.value}
          onChange={(e) => {
            fileHideLeftContacts.set(e.target.checked);
          }}
        />
      </Collapse>
      <Collapse title={t('MASK.FIELDS')} defaultOpen={true}>
        {fieldsList}
      </Collapse>
    </Panel>
  );
};
