export enum ErpPositionsKind {
  STANDARD = 'STANDARD',
  BEZUGSKOSTEN = 'BEZUGSKOSTEN',
  STUECKLISTE_OHNE_BERECHNUNG = 'STUECKLISTE_OHNE_BERECHNUNG',
  RABATTPOSITION = 'RABATTPOSITION',
  INTERNE_STUECKLISTE = 'INTERNE_STUECKLISTE',
  BELEGZUSCHLAG = 'BELEGZUSCHLAG',
  BELEGRABATT = 'BELEGRABATT',
  AUTO_EK_PROZENT = 'AUTO_EK_PROZENT',
  TITEL_INTERN = 'TITEL_INTERN',
  INTERNE_POSITION = 'INTERNE_POSITION',
  TEILRECHNUNGSPOSITION = 'TEILRECHNUNGSPOSITION',
  TITEL_OHNE_NUMMER = 'TITEL_OHNE_NUMMER',
  ZWISCHENSUMME = 'ZWISCHENSUMME',
  TEXTZEILE = 'TEXTZEILE',
  STUECKLISTE = 'STUECKLISTE',
  UMWANDLUNGSHISTORIE = 'UMWANDLUNGSHISTORIE',
  EXCEL_ARTIKEL = 'EXCEL_ARTIKEL',
  SEITENUMBRUCH = 'SEITENUMBRUCH',
  TITEL = 'TITEL',
  TITELSUMME = 'TITELSUMME',
}
