import styles from './StatusIndicator.module.scss';

import { CheckCircle, CircleTwoTone } from '@mui/icons-material';

export type IStatusIndicatorVariant = 'default' | 'success' | 'alert' | 'error';

export type IStatusIndicatorProps = {
  variant?: IStatusIndicatorVariant;
};

export function StatusIndicator(props: IStatusIndicatorProps) {
  const { variant = 'default' } = props;

  const Icon = variant === 'success' ? CheckCircle : CircleTwoTone;

  return <Icon className={styles[variant]} />;
}
