import { Path } from '../../../types';
import { GroupedItem } from '../types';

export const generateRow = (
  path: Path,
  skeleton?: boolean,
  item?: GroupedItem
): GroupedItem => {
  return {
    ...item,
    skeleton,
    meta: { path },
  };
};
