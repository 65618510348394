import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { IndividualFieldControlType } from '@work4all/models/lib/Enums/IndividualFieldControlType.enum';
import { IColumnFilterConfig } from '@work4all/models/lib/table-schema/table-schema';

import { AllowedCustomField } from './types';

// There are multiple field types used for visual formatting, like "new line",
// "title", etc. Showing these in the table doesn't make sense. So we filter the
// field list to these allowed field types.
export const ALLOWED_CUSTOM_FIELD_TYPES = [
  IndividualFieldControlType.TEXT,
  IndividualFieldControlType.NUMERIC,
  IndividualFieldControlType.DATE_TYPE,
  IndividualFieldControlType.CHECK,
  IndividualFieldControlType.RADIO,
  IndividualFieldControlType.COMBO,
];

export const CUSTOM_FIELD_FILTER_CONFIG: {
  [key in AllowedCustomField]: IColumnFilterConfig | false;
} = {
  [IndividualFieldControlType.TEXT]: { type: 'Search' },
  [IndividualFieldControlType.NUMERIC]: { type: 'Number' },
  [IndividualFieldControlType.DATE_TYPE]: { type: 'Date' },
  [IndividualFieldControlType.CHECK]: { type: 'Check' },
  [IndividualFieldControlType.RADIO]: { type: 'Search' },
  [IndividualFieldControlType.COMBO]: {
    type: 'Picker',
    params: { entity: Entities.customField, multiple: true },
  },
};
