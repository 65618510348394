import { Project } from '@work4all/models/lib/Classes/Project.entity';

export const PROJECT_DATA: Project = {
  customer: {
    id: null,
    name: null,
    number: null,
    website: null,
    mainContact: {
      id: null,
      displayName: null,
      firstName: null,
      name: null,
      role: null,
      // TODO contact salutation is too deep - Backend will not return it
      salutation: {
        id: null,
        isFemale: null,
        isMale: null,
      },
      businessPartnerId: null,
      businessPartnerType: null,
      eMail: null,
      phoneNumber: null,
      phoneNumber2: null,
      phoneNumber3: null,
      mobileNumber: null,
      mobileNumberPrivate: null,
      faxNumber: null,
      phoneNumberPrivate: null,
    },
  },
  supplier: {
    id: null,
    name: null,
    number: null,
    website: null,
    mainContact: {
      id: null,
      displayName: null,
      firstName: null,
      name: null,
      role: null,
      // TODO contact salutation is too deep - Backend will not return it
      salutation: {
        id: null,
        isFemale: null,
        isMale: null,
      },
      businessPartnerId: null,
      businessPartnerType: null,
      eMail: null,
      phoneNumber: null,
      phoneNumber2: null,
      phoneNumber3: null,
      mobileNumber: null,
      mobileNumberPrivate: null,
      faxNumber: null,
      phoneNumberPrivate: null,
    },
  },
};
