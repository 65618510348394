import { Work4AllEntity } from '@work4all/models/lib/additionalEnums/Work4AllEntity.entity';
import { ITableColumnConfig } from '@work4all/models/lib/table-schema/table-schema';

import { CUSTOM_FIELD_FILTER_CONFIG } from '../constants';
import { ParsedCustomFieldConfig } from '../types';

import { createCustomFieldId } from './createCustomFieldId';

export function createCustomColumn(
  customField: ParsedCustomFieldConfig
): ITableColumnConfigCustom {
  const column: ITableColumnConfigCustom = {
    id: createCustomFieldId(customField),
    title: customField.name,
    accessor: createCustomFieldAccessor(customField),
    defaultHidden: true,
    cell: {
      type: 'Custom',
      params: {
        fieldType: customField.fieldType,
        fieldOptions: customField.options,
      },
    },
    width: 120,
    sortable: true,
    filterable: CUSTOM_FIELD_FILTER_CONFIG[customField.fieldType],
    groupable: true,
    quickSearchable: false,
    path: ['MASK.INDIVIDUAL'],
  };

  return column;
}

function createCustomFieldAccessor(
  customField: ParsedCustomFieldConfig
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): (record: Work4AllEntity) => any {
  return (row: Work4AllEntity) => {
    const fields = 'customFieldList' in row ? row.customFieldList : null;
    const field = fields?.find((field) => field.key === customField.id);
    const value = field?.value;

    if (value == null) return value;

    try {
      return JSON.parse(value);
    } catch {
      return value;
    }
  };
}

type ITableColumnConfigCustom = Omit<ITableColumnConfig, 'accessor'> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accessor: string | ((obj: any) => any);
};
