import Typography from '@mui/material/Typography';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateEvent } from '@work4all/models/lib/Classes/CreateEvent.entity';

import { EntityHistoryContext } from '../entity-history-context';

import { EventLayout } from './EventLayout';

export interface CreateEventViewProps {
  event: CreateEvent;
}

export function CreateEventView(props: CreateEventViewProps) {
  const { event } = props;

  const { t } = useTranslation();

  const { entity } = useContext(EntityHistoryContext);

  return (
    <EventLayout event={event}>
      <Typography variant="body2" color="text.primary">
        {t('COMMON.CREATED', {
          name: t(`COMMON.${entity.toUpperCase()}`),
        })}
      </Typography>
    </EventLayout>
  );
}
