import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { BusinessPartnerContactCombined } from './BusinessPartnerContactCombined.entity';
import { CategoryAssignment } from './CategoryAssignment.entity';
import { Contact } from './Contact.entity';
import { CostCenter } from './CostCenter.entity';
import { Customer } from './Customer.entity';
import { CustomField } from './CustomField.entity';
import { LookUp } from './LookUp.entity';
import { PaginationListAppointment } from './PaginationListAppointment.entity';
import { PaginationListCalculation } from './PaginationListCalculation.entity';
import { PaginationListCallMemo } from './PaginationListCallMemo.entity';
import { PaginationListCheckList } from './PaginationListCheckList.entity';
import { PaginationListContract } from './PaginationListContract.entity';
import { PaginationListDeliveryNote } from './PaginationListDeliveryNote.entity';
import { PaginationListDemand } from './PaginationListDemand.entity';
import { PaginationListDocument } from './PaginationListDocument.entity';
import { PaginationListEMail } from './PaginationListEMail.entity';
import { PaginationListInboundDeliveryNote } from './PaginationListInboundDeliveryNote.entity';
import { PaginationListInvoice } from './PaginationListInvoice.entity';
import { PaginationListLetter } from './PaginationListLetter.entity';
import { PaginationListNote } from './PaginationListNote.entity';
import { PaginationListOffer } from './PaginationListOffer.entity';
import { PaginationListOrder } from './PaginationListOrder.entity';
import { PaginationListProductionContract } from './PaginationListProductionContract.entity';
import { PaginationListProjectProcess } from './PaginationListProjectProcess.entity';
import { PaginationListTask } from './PaginationListTask.entity';
import { PaginationListTicket } from './PaginationListTicket.entity';
import { PaginationListTimeTracking } from './PaginationListTimeTracking.entity';
import { PaginationListTravelExpenses } from './PaginationListTravelExpenses.entity';
import { PaginationListVisitationReport } from './PaginationListVisitationReport.entity';
import { PostIt } from './PostIt.entity';
import { ProjectAccessGroup } from './ProjectAccessGroup.entity';
import { ProjectDistributorEntry } from './ProjectDistributorEntry.entity';
import { ProjectGroup } from './ProjectGroup.entity';
import { ProjectInvolveds } from './ProjectInvolveds.entity';
import { SubDirectoryTemplateItem } from './SubDirectoryTemplateItem.entity';
import { Supplier } from './Supplier.entity';
import { User } from './User.entity';

export class Project<T extends EMode = EMode.entity> {
  /** Alias: anfangDatum */
  startDateInner?: string;
  /** Alias: PaginationListangebote */
  offerList?: PaginationListOffer<T>;
  /** Alias: PaginationListaufgaben */
  taskList?: PaginationListTask<T>;
  /** Alias: PaginationListauftraege */
  contractList?: PaginationListContract<T>;
  /** Alias: bCode */
  creatorUserId?: number;
  /** Alias: PaginationListbedarfsanforderungen */
  demandList?: PaginationListDemand<T>;
  /** Alias: PaginationListbestellungen */
  orderList?: PaginationListOrder<T>;
  /** Alias: PaginationListbesuchsberichte */
  visitationReportList?: PaginationListVisitationReport<T>;
  /** Alias: bewertung */
  valuation?: number;
  /** Alias: bisDatum */
  endDateOuter?: string;
  /** Alias: PaginationListbriefe */
  letterList?: PaginationListLetter<T>;
  /** Alias: PaginationListchecklisten */
  checkListList?: PaginationListCheckList<T>;
  /** Alias: code */
  id?: number;
  /** Alias: customFields */
  customFieldList?: CustomField<T>[];
  /** Alias: PaginationListdokumente */
  documentList?: PaginationListDocument<T>;
  /** Alias: dokumentverzeichnis */
  documentDirectory?: string;
  /** Alias: PaginationListeMails */
  eMailList?: PaginationListEMail<T>;
  /** Alias: PaginationListeingangslieferscheine */
  inboundDeliveryNoteList?: PaginationListInboundDeliveryNote<T>;
  /** Alias: endeDatum */
  endDateInner?: string;
  /** Alias: ersteller */
  creator?: User<T>;
  /** Alias: etat */
  budget?: number;
  /** Alias: grCode */
  groupId?: number;
  /** Alias: gruppe */
  group?: ProjectGroup<T>;
  /** Alias: hasObjektschutz */
  hasObjectProtection?: boolean;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: PaginationListkalkulationen */
  calculationList?: PaginationListCalculation<T>;
  /** Alias: kostenStellenCode */
  costCenterId?: number;
  /** Alias: kostenstelle */
  costCenter?: CostCenter<T>;
  /** Alias: kunde */
  customer?: Customer<T>;
  /** Alias: kundenAnsprechpartner */
  customerContact?: Contact<T>;
  /** Alias: kundenApCode */
  customerContactId?: number;
  /** Alias: kundenCode */
  customerId?: number;
  /** Alias: letzteAenderung */
  lastEdit?: string;
  /** Alias: lieferant */
  supplier?: Supplier<T>;
  /** Alias: lieferantenAnsprechpartner */
  supplierContact?: Contact<T>;
  /** Alias: lieferantenApCode */
  supplierContactId?: number;
  /** Alias: lieferantenCode */
  supplierId?: number;
  /** Alias: PaginationListlieferscheine */
  deliveryNoteList?: PaginationListDeliveryNote<T>;
  /** Alias: name */
  name?: string;
  /** Alias: nebenadresse1 */
  additionalAddress1?: BusinessPartnerContactCombined<T>;
  /** Alias: nebenadresse2 */
  additionalAddress2?: BusinessPartnerContactCombined<T>;
  /** Alias: nebenadresse2Code */
  additionalAddress2Id?: number;
  /** Alias: nebenadresse2Type */
  additionalAddress2Type?: number;
  /** Alias: nebenadresseCode */
  additionalAddressId?: number;
  /** Alias: nebenadresseType */
  additionalAddressType?: number;
  /** Alias: notiz */
  note?: string;
  /** Alias: PaginationListnotizen */
  noteList?: PaginationListNote<T>;
  /** Alias: nummer */
  number?: string;
  /** Alias: parentProjekt */
  parentProject?: Project<T>;
  /** Alias: parentProjektCode */
  parentProjectId?: number;
  /** Alias: PaginationListproduktionsauftraege */
  productionContractList?: PaginationListProductionContract<T>;
  /** Alias: projektArt */
  projectKind?: LookUp<T>;
  /** Alias: projektArtCode */
  projectKindId?: number;
  /** Alias: projektBeteiligte */
  projectInvolveds?: ProjectInvolveds<T>;
  /** Alias: projektLeiterInternCode */
  internalProjectManagerId?: number;
  /** Alias: projektLink0 */
  projectLink0?: string;
  /** Alias: projektLink1 */
  projectLink1?: string;
  /** Alias: projektLink2 */
  projectLink2?: string;
  /** Alias: projektStatus */
  projectStatus?: LookUp<T>;
  /** Alias: projektStatusCode */
  projectStatusId?: number;
  /** Alias: projektVerteiler */
  projectDistributor?: ProjectDistributorEntry<T>[];
  /** Alias: projektleiterExtern */
  externalProjectManager?: string;
  /** Alias: projektleiterIntern */
  internalProjectManager?: User<T>;
  /** Alias: PaginationListrechnungen */
  invoiceList?: PaginationListInvoice<T>;
  /** Alias: PaginationListreisekostenabrechnungen */
  travelExpensesList?: PaginationListTravelExpenses<T>;
  /** Alias: shopFormularDaten */
  shopFormData?: string;
  /** Alias: sperrungEigenleistung */
  lockInhouseServices?: number;
  /** Alias: sperrungFremdleistung */
  lockExternalServices?: number;
  /** Alias: PaginationListtelefonate */
  phoneCallList?: PaginationListCallMemo<T>;
  /** Alias: PaginationListtermine */
  appointmentList?: PaginationListAppointment<T>;
  /** Alias: PaginationListtickets */
  ticketList?: PaginationListTicket<T>;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: verzeichnisTemplateTitelCode */
  directoryTemplateTitleId?: number;
  /** Alias: vonDatum */
  startDateOuter?: string;
  /** Alias: PaginationListvorgaenge */
  projectProcessList?: PaginationListProjectProcess<T>;
  /** Alias: PaginationListzeiterfassung */
  timeTrackingList?: PaginationListTimeTracking<T>;
  /** Alias: projectSubDirectories */
  projectSubDirectories?: SubDirectoryTemplateItem<T>[];
  /** Alias: categoriesAssigned */
  categoryAssignmentList?: CategoryAssignment<T>[];
  /** Alias: postIt */
  postIt?: PostIt<T>;
  /** Alias: projectAccessGroups */
  projectAccessGroups?: ProjectAccessGroup<T>[];
  /** Alias: childProjects */
  childProjects?: Project<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<Project> = {
  startDateInner: {
    alias: 'anfangDatum',
  },
  offerList: {
    alias: 'angebote',
    entity: Entities.offer,
  },
  taskList: {
    alias: 'aufgaben',
    entity: Entities.task,
  },
  contractList: {
    alias: 'auftraege',
    entity: Entities.contract,
  },
  creatorUserId: {
    alias: 'bCode',
  },
  demandList: {
    alias: 'bedarfsanforderungen',
    entity: Entities.demand,
  },
  orderList: {
    alias: 'bestellungen',
    entity: Entities.order,
  },
  visitationReportList: {
    alias: 'besuchsberichte',
    entity: Entities.visitationReport,
  },
  valuation: {
    alias: 'bewertung',
  },
  endDateOuter: {
    alias: 'bisDatum',
  },
  letterList: {
    alias: 'briefe',
    entity: Entities.letter,
  },
  checkListList: {
    alias: 'checklisten',
    entity: Entities.checkList,
  },
  id: {
    alias: 'code',
  },
  customFieldList: {
    alias: 'customFields',
    entity: Entities.customField,
  },
  documentList: {
    alias: 'dokumente',
    entity: Entities.document,
  },
  documentDirectory: {
    alias: 'dokumentverzeichnis',
  },
  eMailList: {
    alias: 'eMails',
    entity: Entities.eMail,
  },
  inboundDeliveryNoteList: {
    alias: 'eingangslieferscheine',
    entity: Entities.inboundDeliveryNote,
  },
  endDateInner: {
    alias: 'endeDatum',
  },
  creator: {
    alias: 'ersteller',
    entity: Entities.user,
  },
  budget: {
    alias: 'etat',
  },
  groupId: {
    alias: 'grCode',
  },
  group: {
    alias: 'gruppe',
    entity: Entities.projectGroup,
  },
  hasObjectProtection: {
    alias: 'hasObjektschutz',
  },
  insertTime: {
    alias: 'insertTime',
  },
  calculationList: {
    alias: 'kalkulationen',
    entity: Entities.calculation,
  },
  costCenterId: {
    alias: 'kostenStellenCode',
  },
  costCenter: {
    alias: 'kostenstelle',
    entity: Entities.costCenter,
  },
  customer: {
    alias: 'kunde',
    entity: Entities.customer,
  },
  customerContact: {
    alias: 'kundenAnsprechpartner',
    entity: Entities.contact,
  },
  customerContactId: {
    alias: 'kundenApCode',
  },
  customerId: {
    alias: 'kundenCode',
  },
  lastEdit: {
    alias: 'letzteAenderung',
  },
  supplier: {
    alias: 'lieferant',
    entity: Entities.supplier,
  },
  supplierContact: {
    alias: 'lieferantenAnsprechpartner',
    entity: Entities.contact,
  },
  supplierContactId: {
    alias: 'lieferantenApCode',
  },
  supplierId: {
    alias: 'lieferantenCode',
  },
  deliveryNoteList: {
    alias: 'lieferscheine',
    entity: Entities.deliveryNote,
  },
  name: {
    alias: 'name',
  },
  additionalAddress1: {
    alias: 'nebenadresse1',
    entity: Entities.businessPartnerContactCombined,
  },
  additionalAddress2: {
    alias: 'nebenadresse2',
    entity: Entities.businessPartnerContactCombined,
  },
  additionalAddress2Id: {
    alias: 'nebenadresse2Code',
  },
  additionalAddress2Type: {
    alias: 'nebenadresse2Type',
  },
  additionalAddressId: {
    alias: 'nebenadresseCode',
  },
  additionalAddressType: {
    alias: 'nebenadresseType',
  },
  note: {
    alias: 'notiz',
  },
  noteList: {
    alias: 'notizen',
    entity: Entities.note,
  },
  number: {
    alias: 'nummer',
  },
  parentProject: {
    alias: 'parentProjekt',
    entity: Entities.project,
  },
  parentProjectId: {
    alias: 'parentProjektCode',
  },
  productionContractList: {
    alias: 'produktionsauftraege',
    entity: Entities.productionContract,
  },
  projectKind: {
    alias: 'projektArt',
    entity: Entities.lookUp,
  },
  projectKindId: {
    alias: 'projektArtCode',
  },
  projectInvolveds: {
    alias: 'projektBeteiligte',
    entity: Entities.projectInvolveds,
  },
  internalProjectManagerId: {
    alias: 'projektLeiterInternCode',
  },
  projectLink0: {
    alias: 'projektLink0',
  },
  projectLink1: {
    alias: 'projektLink1',
  },
  projectLink2: {
    alias: 'projektLink2',
  },
  projectStatus: {
    alias: 'projektStatus',
    entity: Entities.lookUp,
  },
  projectStatusId: {
    alias: 'projektStatusCode',
  },
  projectDistributor: {
    alias: 'projektVerteiler',
    entity: Entities.projectDistributorEntry,
  },
  externalProjectManager: {
    alias: 'projektleiterExtern',
  },
  internalProjectManager: {
    alias: 'projektleiterIntern',
    entity: Entities.user,
  },
  invoiceList: {
    alias: 'rechnungen',
    entity: Entities.invoice,
  },
  travelExpensesList: {
    alias: 'reisekostenabrechnungen',
    entity: Entities.travelExpenses,
  },
  shopFormData: {
    alias: 'shopFormularDaten',
  },
  lockInhouseServices: {
    alias: 'sperrungEigenleistung',
  },
  lockExternalServices: {
    alias: 'sperrungFremdleistung',
  },
  phoneCallList: {
    alias: 'telefonate',
    entity: Entities.callMemo,
  },
  appointmentList: {
    alias: 'termine',
    entity: Entities.appointment,
  },
  ticketList: {
    alias: 'tickets',
    entity: Entities.ticket,
  },
  updateTime: {
    alias: 'updateTime',
  },
  directoryTemplateTitleId: {
    alias: 'verzeichnisTemplateTitelCode',
  },
  startDateOuter: {
    alias: 'vonDatum',
  },
  projectProcessList: {
    alias: 'vorgaenge',
    entity: Entities.projectProcess,
  },
  timeTrackingList: {
    alias: 'zeiterfassung',
    entity: Entities.timeTracking,
  },
  projectSubDirectories: {
    alias: 'projectSubDirectories',
    entity: Entities.subDirectoryTemplateItem,
  },
  categoryAssignmentList: {
    alias: 'categoriesAssigned',
    entity: Entities.categoryAssignment,
  },
  postIt: {
    alias: 'postIt',
    entity: Entities.postIt,
  },
  projectAccessGroups: {
    alias: 'projectAccessGroups',
    entity: Entities.projectAccessGroup,
  },
  childProjects: {
    alias: 'childProjects',
    entity: Entities.project,
  },
  __typename: {
    alias: '__typename',
  },
};

export const projectEntityDefinition: EntitiyDefinition<Project> = {
  local: {},
  remote: {
    queryName: 'getProjekte',
    fragmentName: 'Projekt',
    withPaginationWrapper: true,
    mutations: {
      upsert: {
        mutationName: 'upsertProjekt',
        args: [
          { name: 'input', type: 'InputProjekt!' },
          { name: 'relations', type: 'InputProjektRelation' },
          { name: 'drySave', type: 'Boolean' },
        ],
      },
    },
    params: [
      { name: 'categoryCodes', type: '[Int]' },
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
