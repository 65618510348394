import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { LookUp } from './LookUp.entity';
import { User } from './User.entity';

export class Sickness<T extends EMode = EMode.entity> {
  /** Alias: bCodeGenehmigung */
  approverUserId?: number;
  /** Alias: benutzer */
  user?: User<T>;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: code */
  id?: number;
  /** Alias: datum */
  date?: string;
  /** Alias: datumAntrag */
  applicationDate?: string;
  /** Alias: datumGenehmigung */
  approveDate?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: menge */
  amount?: number;
  /** Alias: notiz */
  note?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: krankheitsArt */
  sicknessType?: LookUp<T>;
  /** Alias: krankheitsArtCode */
  sicknessTypeId?: number;

  __typename?: string;
}

const fields: FieldDefinitions<Sickness> = {
  approverUserId: {
    alias: 'bCodeGenehmigung',
  },
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  userId: {
    alias: 'benutzerCode',
  },
  id: {
    alias: 'code',
  },
  date: {
    alias: 'datum',
  },
  applicationDate: {
    alias: 'datumAntrag',
  },
  approveDate: {
    alias: 'datumGenehmigung',
  },
  insertTime: {
    alias: 'insertTime',
  },
  amount: {
    alias: 'menge',
  },
  note: {
    alias: 'notiz',
  },
  updateTime: {
    alias: 'updateTime',
  },
  sicknessType: {
    alias: 'krankheitsArt',
    entity: Entities.lookUp,
  },
  sicknessTypeId: {
    alias: 'krankheitsArtCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const sicknessEntityDefinition: EntitiyDefinition<Sickness> = {
  local: {},
  remote: {
    queryName: 'getKrankeitstage',
    fragmentName: 'Krankheit',
    withPaginationWrapper: true,
    mutations: {
      upsert: {
        mutationName: 'upsertKrankheit',
        args: [
          { name: 'input', type: 'InputKrankheit!' },
          { name: 'drySave', type: 'Boolean' },
        ],
      },
    },
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
