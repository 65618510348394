import { RxJsonSchema } from 'rxdb';

import { DbEntities, ILayout } from '@work4all/models';

import { EntityDescriptor } from '.';

const schema: RxJsonSchema<ILayout> = {
  title: DbEntities.Layout,
  version: 0,
  description: 'a document representing a layout object',
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 100, // the primary key must have set maxLength
    },
    tenant: {
      type: 'number',
    },
    layoutType: {
      type: 'string',
      enum: ['LIEFERANT', 'KUNDE', 'PROJEKT', 'PERSONAL', 'NOTYPE'],
    },
    name: {
      type: 'string',
    },
    defaultLayout: {
      type: 'boolean',
    },
    definition: {
      type: 'string',
    },
    insertTime: {
      type: 'string',
    },
    updateTime: {
      type: 'string',
    },
  },
  required: [
    'id',
    'tenant',
    'layoutType',
    'name',
    'defaultLayout',
    'definition',
    'insertTime',
    'updateTime',
  ],
};

export const layoutEntity: EntityDescriptor = {
  name: DbEntities.Layout,
  schema,
};
