import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';

const GET_SALES_OPPORTUNITY_GROUPS = gql`
  query GetSalesOpportunityGroups {
    getSalesOpportunityGroups {
      parentCode
      name
      index
      code
    }
  }
`;

export interface SalesOpportunityGroupResponse {
  getSalesOpportunityGroups: {
    prentCode: number;
    name: string;
    index;
    number;
    code: number;
  }[];
}

export const useSalesOpportunityGroups = () => {
  const response = useQuery<SalesOpportunityGroupResponse>(
    GET_SALES_OPPORTUNITY_GROUPS
  );

  return useMemo(() => {
    return {
      ...response,
      data:
        response.data?.getSalesOpportunityGroups?.map((x) => ({
          ...x,
          id: x.code,
          groupId: x.code,
        })) ?? [],
    };
  }, [response]);
};
