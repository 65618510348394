import styles from './Panel.module.scss';

import clsx from 'clsx';
import React from 'react';

export const Panel: React.FC<React.HTMLAttributes<HTMLDivElement>> = (
  props
) => {
  const { children, className, ...rest } = props;

  return (
    <div className={clsx(styles.panel, className)} {...rest}>
      {children}
    </div>
  );
};
