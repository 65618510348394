import { AvatarGroup, Theme, useMediaQuery } from '@mui/material';
import React from 'react';
import { Row } from 'react-table';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';

import { UserIcon } from '../../../../components/user-icon/UserIcon';
import { HookedUserIcon } from '../../../../components/user-icon/useUserIconRegister';

export type IAvatarsCellProps = {
  row: Row<Appointment>;
};

export const AvatarsCell: React.FC<IAvatarsCellProps> = ({
  row: { original: rowData },
}) => {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'));

  const avatarsCount = isDesktop ? 3 : 1;
  const groupLimit = avatarsCount + 1;

  const internalUsers = rowData.appointmentAttendeeList.filter((a) => a.user);
  const externalUsers = rowData.appointmentAttendeeList.filter((a) => !a.user);

  const usersToShow =
    internalUsers.length > avatarsCount
      ? internalUsers.slice(0, avatarsCount)
      : internalUsers;
  const counterValue =
    internalUsers.length > avatarsCount
      ? internalUsers.length - avatarsCount + externalUsers.length
      : externalUsers.length;

  const internalNames = internalUsers
    .map((el) => el.user?.displayName)
    .filter((el) => Boolean(el))
    .join(', ');
  const externalNames = externalUsers
    .map((el) => el.contact?.displayName)
    .filter((el) => Boolean(el))
    .join(', ');

  return (
    <AvatarGroup
      sx={{
        width: 'max-content',
        marginTop: '-0.4rem',
      }}
      max={groupLimit}
      title={[internalNames, externalNames]
        .filter((el) => Boolean(el))
        .join(', ')}
    >
      {usersToShow.map((u) => (
        <HookedUserIcon key={u.id} userId={u.user.id} size="l-bold" />
      ))}
      {counterValue ? (
        <UserIcon
          size="l-bold"
          shortName={`+${counterValue}`}
          bgColor="var(--ui04)"
          fontColor="var(--text03)"
        />
      ) : null}
    </AvatarGroup>
  );
};
