import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { CostCenter } from './CostCenter.entity';

export class StatisticsSalesVolumeByCostCenterRow<
  T extends EMode = EMode.entity
> {
  /** Alias: date */
  date?: string;
  /** Alias: objCode */
  objId?: number;
  /** Alias: amount */
  amount?: number;
  /** Alias: costCenter */
  costCenter?: CostCenter<T>;

  __typename?: string;
}

const fields: FieldDefinitions<StatisticsSalesVolumeByCostCenterRow> = {
  date: {
    alias: 'date',
  },
  objId: {
    alias: 'objCode',
  },
  amount: {
    alias: 'amount',
  },
  costCenter: {
    alias: 'costCenter',
    entity: Entities.costCenter,
  },
  __typename: {
    alias: '__typename',
  },
};

export const statisticsSalesVolumeByCostCenterRowEntityDefinition: EntitiyDefinition<StatisticsSalesVolumeByCostCenterRow> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'StatisticsSalesVolumeByCostCenterRow',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
