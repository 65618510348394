import { useEffect, useState } from 'react';

import { HttpClientResponse, useHttpClient } from '@work4all/data';

import { NavigationCategory } from '../types';

// Save the last loaded schema to avoid loading states on subsequent navigation.
// Reload the up-to-date schema in the background while falling back to the
// previous one and replace it when the new one is loaded.
let cachedSchema: NavigationCategory[] = null;

interface ColumnConfigParams {
  types: 'work4allControlCenter';
}

export const useControlCenterSchema = () => {
  const httpClient = useHttpClient();
  const [schema, setSchema] = useState<NavigationCategory[]>(cachedSchema);

  useEffect(() => {
    async function loadSchema(): Promise<
      HttpClientResponse<ColumnConfigResponse>
    > {
      try {
        const data = await httpClient.get<
          ColumnConfigResponse,
          ColumnConfigParams
        >({
          url: 'https://ws.work4all.de/api/service/columnconfig',
          params: {
            types: 'work4allControlCenter',
          },
        });

        return data;
      } catch (err) {
        console.error(err);
      }
      return null;
    }

    loadSchema().then((result) => {
      if (result?.data) setSchema(JSON.parse(result?.data?.[0]?.data));
    });
  }, [httpClient]);

  useEffect(() => {
    cachedSchema = schema;
  }, [schema]);

  return { $schema: './navigation-links.schema.json', data: schema };
};

interface ColumnConfigResponse {
  id: string;
  configType: string;
  revision: number;
  data: string;
  creationDate: Date;
  creationUserCode: number;
}
