import { useMemo } from 'react';

import { IUser, useDataProvider } from '@work4all/data';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

const useSuggestions = (from: Date, to: Date, targetUser: User | IUser) => {
  const suggestionsRequest = useMemo(() => {
    return {
      entity: Entities.appointment,
      data: {
        id: null,
        title: null,
        startDate: null,
        endDate: null,
        isWholeDay: null,
        note: null,
        businessPartner: {
          id: null,
          businessPartnerType: null,
          data: {
            customer: {
              id: null,
              name: null,
              number: null,
              website: null,
            },
          },
        },
        project: {
          id: null,
          name: null,
          number: null,
        },
        projectProcess: {
          id: null,
          process: null,
        },
        appointmentAttendeeList: [
          {
            id: null,
            user: { id: null, displayName: null },
          },
        ],
      },
      filter: [
        {
          'user.id': {
            $eq: (targetUser as User).id || (targetUser as IUser).benutzerCode,
          },
        },
        {
          $and: [{ startDate: { $lte: to } }, { endDate: { $gte: from } }],
        },
      ],
    };
  }, [from, to, targetUser]);

  const { data: suggestions } =
    useDataProvider<Appointment>(suggestionsRequest);

  return suggestions;
};

export default useSuggestions;
