import { useLocation } from '@work4all/data';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

type GetLinkType = {
  entity: Entities | null;
  id: string;
};

export const useCtrlMouseClickLink = () => {
  const { pathname } = useLocation();
  const { activeTenant } = useTenant();

  const getLink = (props: GetLinkType) => {
    let url;

    if (props.entity) {
      url = `/more/entity/${props.entity}/details/${props.id}`;
    } else {
      const currentPath = `/${pathname.split('/')[1]}`;
      url = `${currentPath}/${props.id}`;
    }

    return `t-${activeTenant}${url}`;
  };

  return { getLink };
};
