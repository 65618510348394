import styles from './FullAddressPicker.module.scss';

import ExpandLess from '@mui/icons-material/ExpandLess';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useContactActions } from '@work4all/components/lib/hooks/use-contact-actions';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { BusinessPartnerPickerPopover } from '../../../../../../../components/entity-picker/BusinessPartnerPickerPopover';
import { ContactPickerField } from '../../../../../../../components/entity-picker/ContactPickerField';
import { LabeledInput } from '../../../../../locked-inputs';
import { EditWrap } from '../edit-wrap/EditWrap';

const BUSINESS_PARTNER_PICKER_DATA: Customer | Supplier = {
  mainContactId: null,
  contactList: [
    {
      id: null,
      displayName: null,
      role: null,
      layedOff: null,
      salutation: {
        id: null,
        isMale: null,
        isFemale: null,
      },
    },
  ],
};

export interface FullAddressPickerProps {
  businessPartner: Customer | Supplier;
  businessPartnerType: SdObjType;
  contact?: Contact;
  addressString?: string;
  compareBusinessPartnerId?: number;
  compareContactId?: number;
  label?: string;
  layoutCollapsed?: 'input' | 'address';
  onChange?: (value: {
    businessPartner?: Customer | Supplier;
    contact?: Contact;
    addressString?: string;
  }) => void;
  onExpandChange?: (expanded: boolean) => void;
  expanded?: boolean;
  disableEditToggle?: boolean;
}

export const FullAddressPicker = (props: FullAddressPickerProps) => {
  const {
    businessPartnerType,
    businessPartner,
    label,
    contact,
    onChange,
    addressString,
    compareBusinessPartnerId,
    compareContactId,
    layoutCollapsed = 'address',
    expanded = false,
    onExpandChange,
    disableEditToggle,
  } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();
  const businessPartnerWrapperRef = useRef(null);

  const { actions } = useContactActions({
    businessPartnerType,
    businessPartnerId: businessPartner?.id,
    eMail: contact?.eMail || businessPartner?.eMail,
    id: contact?.id,
  });

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  const finalLabel = useMemo(() => {
    return (
      (layoutCollapsed === 'address' && !isExpanded && label) ||
      (businessPartnerType === SdObjType.KUNDE
        ? t('COMMON.CUSTOMER')
        : t('COMMON.SUPPLIER')) +
        (businessPartner?.number ? ' #' + businessPartner?.number : '')
    );
  }, [
    layoutCollapsed,
    isExpanded,
    label,
    businessPartnerType,
    t,
    businessPartner?.number,
  ]);

  const [editedAddressString, setEditedAddressString] = useState('');

  const CollapsedView = useMemo(() => {
    const isIdentical =
      businessPartner?.id === compareBusinessPartnerId &&
      contact?.id === compareContactId;

    return layoutCollapsed === 'address' ? (
      <>
        <Box position="absolute" width="max-content">
          <Typography className={styles.label}>{finalLabel}</Typography>
        </Box>
        <br />
        <Typography whiteSpace="pre">
          {isIdentical ? t<string>('COMMON.IDENTICAL') : editedAddressString}
        </Typography>
      </>
    ) : (
      <LabeledInput
        style={{ cursor: 'pointer' }}
        onClick={() => setIsExpanded(true)}
        label={finalLabel}
        value={
          isIdentical
            ? t<string>('COMMON.IDENTICAL')
            : businessPartner?.name +
              (contact ? ' | ' + contact?.firstName + ' ' + contact?.name : '')
        }
        iconRight={actions}
      />
    );
  }, [
    actions,
    businessPartner?.id,
    businessPartner?.name,
    compareBusinessPartnerId,
    compareContactId,
    contact,
    editedAddressString,
    finalLabel,
    layoutCollapsed,
    t,
  ]);

  useEffect(() => {
    setEditedAddressString(addressString);
  }, [setEditedAddressString, addressString]);

  const ExpandedView = useMemo(() => {
    return (
      <div ref={businessPartnerWrapperRef} className={styles.wrapper}>
        <IconButton
          className={styles.expandLess}
          onClick={() => {
            setIsExpanded(false);
            onExpandChange?.(false);
          }}
        >
          <ExpandLess className={styles.icon} />
        </IconButton>
        <Stack gap="0.5rem">
          <Box>
            <BusinessPartnerPickerPopover
              anchorEl={businessPartnerWrapperRef}
              multiple={false}
              withActionBar
              data={BUSINESS_PARTNER_PICKER_DATA}
              value={businessPartner}
              onChange={(selectedBusinessPartner) => {
                onChange &&
                  onChange({
                    businessPartner: selectedBusinessPartner as
                      | Customer
                      | Supplier,
                  });
              }}
            >
              <LabeledInput
                value={businessPartner?.name || ''}
                label={finalLabel}
              />
            </BusinessPartnerPickerPopover>
          </Box>
          <Box>
            {businessPartner && (
              <ContactPickerField
                value={contact}
                businessPartnerId={businessPartner?.id}
                businessPartnerType={businessPartnerType}
                clearable={true}
                onChange={(selectedContact) => {
                  onChange &&
                    onChange({
                      contact: selectedContact,
                    });
                }}
                showContactActions={false}
              />
            )}
          </Box>
          <Box>
            <LabeledInput
              label=""
              type="textarea"
              multiline={true}
              minRows={4}
              value={editedAddressString}
              onChange={(e) => {
                setEditedAddressString(e.target.value);
              }}
              onBlur={() => {
                onChange &&
                  onChange({
                    addressString: editedAddressString,
                  });
              }}
            />
          </Box>
        </Stack>
      </div>
    );
  }, [
    businessPartner,
    businessPartnerType,
    contact,
    editedAddressString,
    finalLabel,
    onChange,
    onExpandChange,
  ]);

  return (
    <EditWrap
      onEditModeToggle={() => {
        setIsExpanded(!isExpanded);
        onExpandChange?.(!isExpanded);
      }}
      editMode={isExpanded}
      disabled={disableEditToggle}
    >
      {isExpanded ? ExpandedView : CollapsedView}
    </EditWrap>
  );
};
