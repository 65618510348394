import styles from './EditTable.module.scss';

import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InsertPageBreakIcon from '@mui/icons-material/InsertPageBreak';
import { Box, IconButton, Stack, Theme, useMediaQuery } from '@mui/material';
import { Tooltip } from '@mui/material';
import { useEventCallback } from '@mui/material/utils';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import {
  CellProps,
  Column,
  ColumnInstance,
  Renderer,
  Row,
  TableInstance,
  useBlockLayout,
  useColumnOrder,
  useResizeColumns,
  useRowSelect,
  useTable,
} from 'react-table';
import { AutoSizer } from 'react-virtualized';
import { ListChildComponentProps, VariableSizeList } from 'react-window';

import { ResizableArea } from '@work4all/components/lib/components/ResizableArea';
import { HeaderCheckbox } from '@work4all/components/lib/dataDisplay/basic-table/components/header/components/header-checkbox/HeaderCheckbox';
import { EditableCell } from '@work4all/components/lib/dataDisplay/basic-table/components/row-render/components/editable-cell/EditableCell';
import { TABLE_ROW_DRAG_DROP_KEY } from '@work4all/components/lib/dataDisplay/basic-table/components/row-render/components/row/Row';
import { HeaderCell } from '@work4all/components/lib/dataDisplay/basic-table/components/table-header/components/header-cell/HeaderCell';
import {
  CellEditHandler,
  EditModeCell,
  EditModeTableInstance,
  useEditMode,
} from '@work4all/components/lib/dataDisplay/basic-table/plugins/useEditMode';
import { Checkbox } from '@work4all/components/lib/input/checkbox-radio/CheckboxRadio';
import { ExpandButton } from '@work4all/components/lib/input/expand-button/ExpandButton';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { reactRefSetter } from '@work4all/utils/lib/reactRefSetter';

import { ERP_EXPAND_COLUMN_ID } from '../../../../../../../../../../components/data-tables/generic-column/GenericColumns';
import { settings, useSetting } from '../../../../../../../../../../settings';
import { ArticleGroupTree } from '../position-group-tree/PositionGroupsTree';

import { TableCell, TableHeaderRow, TableRow } from './components';
import {
  CellDefinition,
  EditTableEntry,
  EditTableMask,
  IdType,
  OnEditPosition,
} from './types';

export const ForbiddenPriceCell = () => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('ALERTS.NOT_AUTHORIZED_TO_SEE_PRICES')}>
      <Box textAlign="center">-</Box>
    </Tooltip>
  );
};

export interface EditTableProps<T extends EditTableEntry> {
  columns: Column<T>[];
  items: T[];
  tableInstanceRef: React.RefObject<TableInstance<T>>;
  onEditPosition: (result: OnEditPosition<T>) => void;
  mask?: React.ComponentType<EditTableMask<T>>;
  renderMobileItem?: (item: T, select: (item: T) => void) => JSX.Element;
  onCollapsePosition?: (result: T) => void;

  editableRows?: Partial<Record<ErpPositionsKind, string[]>>;
  classes?: {
    tbody?: string;
    mobile?: string;
    table?: string;
  };
  refs?: {
    body?: React.MutableRefObject<HTMLDivElement | null>;
  };
  cellDefinition?: Partial<Record<keyof T, CellDefinition<T>>>;

  hideMask?: boolean;
  disabled?: boolean;
  onSelectedItemIdsChange?: (selectedPositionIds: number[]) => void;
  onMove?: (id: number, index: number, finish?: boolean) => void;
  onPick?: (article?: Article) => void;
  onRemove?: (positionId: number[]) => void;
  loadingRows?: IdType[];
  itemSize?: (row: T) => number;
  decorators?: {
    getRelation: (pos: T) => 'parent' | 'child' | 'normal';
    isEditableCell: (cell: string) => boolean;
  };
  pending?: boolean;
  bordersHorizontal?: boolean;
  showSelectionColumn?: boolean;
}

const CONST_OBJECT = {};
function EditTableInternal<T extends EditTableEntry>(props: EditTableProps<T>) {
  const {
    disabled = false,
    items,
    onSelectedItemIdsChange,
    onMove,
    onCollapsePosition,
    onEditPosition,
    tableInstanceRef,
    onRemove,
    columns,
    mask: Mask,
    editableRows = CONST_OBJECT,
    renderMobileItem,
    classes,
    cellDefinition,
    loadingRows = [],
    itemSize,
    hideMask,
    decorators,
    bordersHorizontal = true,
    showSelectionColumn = true,
  } = props;

  const allCells = useMemo(
    () =>
      Object.entries(cellDefinition)
        .filter((x) => x[1].editable !== false)
        .map((x) => x[0]),
    [cellDefinition]
  );

  const defaultColumn: Partial<Column<T>> & {
    EditableCell: Renderer<CellProps<T>>;
  } = useMemo(
    () => ({
      EditableCell,
    }),
    []
  );

  const { t } = useTranslation();

  const data = useRef<T[]>([]);
  const listRef = useRef<VariableSizeList>();

  const lastAdded = useRef<T>();
  const finalData = useMemo(() => {
    const positions = data.current;
    const lastCurrent = items[items.length - 1];
    const lastIncoming = positions[positions.length - 1];

    // Scroll to item only if it's newly added
    if (
      lastCurrent &&
      lastIncoming &&
      lastCurrent.id !== lastIncoming.id &&
      positions.length
    ) {
      lastAdded.current = lastIncoming;
      setTimeout(() => {
        listRef.current?.scrollToItem(items.length);
      }, 0);
    }
    data.current = items;
    return data.current;
  }, [items]);
  useEffect(() => {
    listRef.current?.resetAfterIndex(0);
  }, [items]);

  const moveRow = (from: number, to: number) => {
    const pos = data.current[from];
    const id = typeof pos.id === 'number' ? pos.id : parseInt(pos.id);
    onMove(id, to, false);
  };

  function getRowId(position: T) {
    return String(position.id);
  }

  // aggregate this events if it's same row
  const rowInEditModeValues = useRef<T>(null);
  const onCellEdit: CellEditHandler<T> = useCallback((result) => {
    const { cell, value } = result;
    rowInEditModeValues.current = {
      ...rowInEditModeValues.current,
      ...{
        id: cell.row.original.id,
        [cell.column.id]: value,
      },
    };
  }, []);

  const onRowEdit = useCallback(() => {
    if (!rowInEditModeValues.current) {
      console.error('There is no position');
      return;
    }
    const onEditPositionArgs = {
      position: rowInEditModeValues.current,
    };
    onEditPosition(onEditPositionArgs);
    rowInEditModeValues.current = null;
  }, [onEditPosition]);

  const tableInstance = useTable<T>(
    {
      columns,
      defaultColumn,
      data: finalData,
      getRowId,
      onCellEdit,
    },
    useBlockLayout,
    useColumnOrder,
    useRowSelect,
    useResizeColumns,
    useEditMode,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        const newColumns = [];
        if (onSelectedItemIdsChange && showSelectionColumn) {
          newColumns.push({
            id: 'selection',
            disableResizing: true,
            minWidth: 40,
            width: 40,
            maxWidth: 40,
            sticky: 'left',
            Header: ({ getToggleAllRowsSelectedProps }) => {
              const { checked, indeterminate, onChange, role } =
                getToggleAllRowsSelectedProps();

              return (
                <HeaderCheckbox
                  checked={checked}
                  indeterminate={indeterminate}
                  onChange={onChange}
                  role={role}
                />
              );
            },
            Cell: ({ row }) => {
              const { checked, onChange, indeterminate, role } =
                row.getToggleRowSelectedProps();
              const relation = decorators
                ? decorators.getRelation(row.original)
                : 'normal';
              const isNormal = relation === 'normal';
              if (!isNormal) return <Box />;

              return (
                <Checkbox
                  className={clsx(styles['checkbox'], {
                    [styles['checked']]: checked,
                  })}
                  checked={checked}
                  indeterminate={indeterminate}
                  onChange={onChange}
                  role={role}
                />
              );
            },
          });
        }
        if (decorators?.getRelation) {
          newColumns.push({
            id: ERP_EXPAND_COLUMN_ID,
            disableResizing: true,
            minWidth: 24,
            width: 24,
            maxWidth: 24,
            Header: () => (
              <Box
                top="-2px"
                bottom="-2px"
                bgcolor="var(--ui01)"
                right={0}
                left={0}
                position="absolute"
              />
            ),
            Cell: ({ row }) => {
              const positions = data.current;
              const relation = decorators
                ? decorators.getRelation(row.original)
                : 'normal';
              if (relation === 'normal') return <Box />;
              if (relation === 'child') {
                return <Box className={styles.line} />;
              }

              const PARRENT_PADDING = 37;
              let index = positions.findIndex((x) => x.id === row.original.id);
              let height = itemSize(positions[index]) - PARRENT_PADDING;
              let lastHeight = 0;
              let potentialChild = positions[++index];
              let nextRelation = potentialChild
                ? decorators.getRelation(potentialChild)
                : 'normal';
              while (nextRelation === 'child' && potentialChild) {
                lastHeight = itemSize(potentialChild);
                height += lastHeight;
                potentialChild = positions[++index];
                nextRelation = potentialChild
                  ? decorators.getRelation(potentialChild)
                  : 'normal';
              }

              height -= lastHeight / 2;

              return (
                <Box height="100%">
                  <Box
                    className={clsx(styles.longLineWrapper)}
                    display={row.original.collapsed ? 'none' : 'block'}
                  >
                    <Box height={height} className={clsx(styles.longLine)} />
                  </Box>
                  <IconButton
                    className={clsx(styles.icon)}
                    onClick={() => onCollapsePosition?.(row.original)}
                  >
                    <ExpandMoreIcon
                      className={clsx({
                        [styles.iconCollapsed]: row.original.collapsed,
                      })}
                    />
                  </IconButton>
                </Box>
              );
            },
          });
        }

        return [...newColumns, ...columns];
      });
    }
  ) as TableInstance<T> & EditModeTableInstance;

  const {
    headerGroups,
    getTableBodyProps,
    getTableProps,
    prepareRow,
    rows,
    setEditModeConfig,
    state: { selectedRowIds },
  } = tableInstance;

  useEffect(() => {
    reactRefSetter(tableInstanceRef)(tableInstance);

    return () => {
      reactRefSetter(tableInstanceRef)(null);
    };
  }, [tableInstanceRef, tableInstance]);

  const lastEmittedSelectedItemIds = useRef<number[] | null>(null);

  const [maskOpen, setMaskOpen] = useState(false);

  useEffect(() => {
    const selectedIds = Object.entries(selectedRowIds)
      .filter(([, selected]) => selected)
      .map(([id]) => Number(id));

    if (!isEqual(selectedIds, lastEmittedSelectedItemIds.current)) {
      lastEmittedSelectedItemIds.current = selectedIds;
      onSelectedItemIdsChange?.(selectedIds);
    }
  }, [lastEmittedSelectedItemIds, onSelectedItemIdsChange, selectedRowIds]);

  useEffect(() => {
    // When last item is detected as different (look prev hook which set lastAdded) && has no id
    if (lastAdded.current && !lastAdded.current?.id) {
      // then set it as editable (this shows that this is newly added row)
      setEditModeConfig({
        row: String(items[items.length - 1].id),
        columns: allCells,
        autoFocus: 'amount',
      });
      // do it only once so reset ref to null
      lastAdded.current = null;
    }
  }, [setEditModeConfig, items, allCells]);

  const [editedItem, setEditedItem] = useState<T>(null);
  useEffect(() => {
    if (editedItem) {
      setEditedItem(items.find((p) => p.id === editedItem.id) || null);
    }
  }, [items, editedItem]);

  const onClickOutSide = useEventCallback((id: number) => {
    if (rowInEditModeValues.current && rowInEditModeValues.current.id === id) {
      onRowEdit();
      setEditModeConfig({
        columns: [],
        row: '',
      });
    }
  });

  const skeletonFirstRowAffectedIndex = items.findIndex(
    (x) => x.id === loadingRows[0]
  );

  const renderRow = (props: ListChildComponentProps<Row<T>[]>) => {
    const { index, data, style } = props;
    const row = data[index];

    prepareRow(row);
    const relation = decorators
      ? decorators.getRelation(row.original)
      : 'normal';
    const isParent = relation === 'parent';
    const isDraggable = relation === 'normal';

    switch (row.original.positionKind) {
      case ErpPositionsKind.SEITENUMBRUCH:
        return (
          <TableRow
            id={row.original.id}
            disabled={disabled}
            index={index}
            moveRow={moveRow}
            moveRowDone={onMove}
            kind={row.original.positionKind}
            className={clsx({ [styles.selectedRow]: row.isSelected })}
            {...row.getRowProps({ className: styles['tr-border'], style })}
          >
            <TableCell
              {...row.cells[0].getCellProps()}
              onClick={() => {
                if (
                  !tableInstance.selectedFlatRows.find((x) => x.id === row.id)
                ) {
                  tableInstance.toggleAllRowsSelected(false);
                  tableInstance.toggleRowSelected(row.id);
                }
              }}
              className={styles['table-cell']}
            >
              {row.cells[0].render('Cell')}
            </TableCell>
            <div className={styles['page-break']}>
              <InsertPageBreakIcon />
              <div>{t('COMMON.PAGE_BREAK')}</div>
            </div>
          </TableRow>
        );

      default:
        return (
          <TableRow
            id={row.original.id}
            className={clsx({
              [styles.skeleton]: !row.original.id,
              [styles.selectedRow]: row.isSelected,
            })}
            disabled={disabled}
            index={index}
            moveRow={moveRow}
            moveRowDone={onMove}
            kind={row.original.positionKind}
            onClickOutside={onClickOutSide}
            isDraggable={isDraggable}
            hasBorder={bordersHorizontal && (isDraggable || isParent)}
            {...row.getRowProps({ style })}
          >
            {row.cells.map((cell) => {
              const definition: CellDefinition<T> = cellDefinition[
                cell.column.id as string
              ] ?? { type: 'number' };
              const render = definition.render;
              const cellClassStyle = definition.style;
              const { style, ...otherCellProps } = cell.getCellProps();

              const isEditableCellFilter = isParent
                ? decorators.isEditableCell
                : () => true;

              const editableCols =
                editableRows[row.original.positionKind] ||
                allCells.filter(isEditableCellFilter);
              const isEditMode = (cell as unknown as EditModeCell).isEditMode;

              const cellType = isEditMode ? 'EditableCell' : 'Cell';

              const isEditableCell = editableCols.includes(cell.column.id);
              const onClick = editableCols.includes(cell.column.id)
                ? () => {
                    if (
                      !tableInstance.selectedFlatRows.find(
                        (x) => x.id === row.id
                      )
                    ) {
                      tableInstance.toggleAllRowsSelected(false);
                      tableInstance.toggleRowSelected(row.id);
                    }
                    if (disabled) {
                      return;
                    }
                    setEditedItem(row.original);

                    if (
                      cell.row.id ===
                      rowInEditModeValues.current?.id?.toString()
                    )
                      return;
                    if (rowInEditModeValues.current) onRowEdit();
                    rowInEditModeValues.current = { id: row.original.id } as T;
                    setEditModeConfig({
                      row: cell.row.id,
                      columns: editableCols,
                      autoFocus: cell.column.id,
                    });
                  }
                : undefined;
              const stopEdit = () => {
                setEditedItem(null);
                onRowEdit();
                rowInEditModeValues.current = null;
                return setEditModeConfig({
                  row: cell.row.id,
                  columns: [],
                  autoFocus: cell.column.id,
                });
              };

              const showSkeleton =
                (loadingRows.includes(row.original.id) ||
                  (loadingRows.length &&
                    skeletonFirstRowAffectedIndex < index &&
                    [
                      ErpPositionsKind.ZWISCHENSUMME,
                      ErpPositionsKind.TITELSUMME,
                    ].includes(row.original.positionKind))) &&
                definition.skeleton;

              return (
                <TableCell
                  {...otherCellProps}
                  onClick={onClick}
                  className={clsx(styles['table-cell'], {
                    [styles.skeleton]: showSkeleton,
                    [styles.parent]: !isEditableCell,
                  })}
                  style={{
                    ...style,
                    ...cellClassStyle,
                  }}
                  id={cell.column.id}
                  kind={row.original.positionKind}
                  shouldWrap={definition.type !== 'number'}
                >
                  {render
                    ? render(cell, { isEditMode })
                    : cell.render(cellType, {
                        onEdit: onClick,
                        ...definition,
                        textarea: definition.type === 'text',
                        onSubmit: stopEdit,
                        onExit: stopEdit,
                        onNext: () => {
                          const idx = editableCols.findIndex(
                            (x) => cell.column.id === x
                          );
                          const next = editableCols[idx + 1];
                          if (!next) stopEdit();
                        },
                        className: 'inside-row',
                      })}
                </TableCell>
              );
            })}
          </TableRow>
        );
    }
  };

  const positionMaskAreaSetting = useSetting(settings.positionMaskAreaSize());

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  const [{ canDrop }, drop] = useDrop(() => ({
    accept: TABLE_ROW_DRAG_DROP_KEY,
    drop: (draggedItem: Article | ArticleGroupTree) => {
      if (
        // eslint-disable-next-line no-prototype-builtins
        draggedItem.hasOwnProperty('__typename') &&
        draggedItem['__typename'] === 'Artikel'
      )
        props?.onPick(draggedItem);
      else {
        props?.onPick(draggedItem.name);
      }
    },
    collect: (monitor: DropTargetMonitor) => {
      return {
        canDrop: monitor.canDrop(),
      };
    },
  }));

  return isMobile && renderMobileItem ? (
    <React.Fragment>
      <table className={classes?.mobile}>
        <tbody>
          {items.map((x) => {
            return renderMobileItem(x, setEditedItem);
          })}
        </tbody>
      </table>
      {Mask && (
        <NavigationOverlay
          initialView={{
            view: (
              <Mask
                item={editedItem}
                onCloseClicked={() => setEditedItem(null)}
                onEdit={onEditPosition}
                onRemove={onRemove}
              />
            ),
          }}
          close={() => setEditedItem(null)}
          open={editedItem !== null}
        />
      )}
    </React.Fragment>
  ) : (
    <div
      ref={drop}
      className={clsx(styles.tableWrapper, {
        [styles.disabled]: disabled,
        [styles.canDrop]: canDrop,
      })}
    >
      <div className={clsx(styles.outerWrap)}>
        <div className={clsx(styles.tableWrapper, disabled && styles.disabled)}>
          <div
            className={clsx(styles.table, classes.table)}
            {...getTableProps()}
          >
            <div className={styles.thead}>
              {headerGroups.map((headerGroup) => (
                <TableHeaderRow
                  disabled={disabled || !onMove}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <HeaderCell
                      canResizeCurrent={true}
                      col={column as unknown as ColumnInstance}
                      canResizeLast={true}
                      noSeperator={false}
                      resizableColumns={true}
                    />
                  ))}
                </TableHeaderRow>
              ))}
            </div>
            <div
              ref={props.refs?.body}
              className={clsx(styles.tbody, classes.tbody)}
              {...getTableBodyProps()}
            >
              <AutoSizer>
                {({ width, height }) => {
                  return (
                    <VariableSizeList
                      ref={listRef}
                      width={width}
                      height={height}
                      itemSize={(index: number) =>
                        itemSize
                          ? itemSize(rows[index]?.original)
                          : remToPx(3.5)
                      }
                      estimatedItemSize={remToPx(3.5)}
                      itemCount={rows.length}
                      overscanCount={8}
                      itemData={rows}
                    >
                      {renderRow}
                    </VariableSizeList>
                  );
                }}
              </AutoSizer>
            </div>

            {columns.some((x) => x.Footer) ? (
              <div className={styles.tfoot}>
                {headerGroups.map((headerGroup) => (
                  <TableHeaderRow
                    disabled={disabled}
                    {...headerGroup.getFooterGroupProps()}
                  >
                    {headerGroup.headers.map((column) => {
                      const definition: CellDefinition<T> = cellDefinition[
                        column.id as string
                      ] ?? { type: 'number' };

                      return (
                        <TableCell
                          {...column.getFooterProps()}
                          className={clsx(styles['table-cell'], {
                            [styles.skeleton]:
                              loadingRows.length && definition.skeleton,
                          })}
                        >
                          {column.render('Footer')}
                        </TableCell>
                      );
                    })}
                  </TableHeaderRow>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {!hideMask && editedItem && Mask && (
        <Box
          sx={{
            height: '100%',
            background: 'var(--ui01)',
          }}
        >
          {!maskOpen && (
            <div className={styles.positionMaskToggle}>
              <ExpandButton
                icon={maskOpen ? <ChevronRight /> : <ChevronLeft />}
                textStart="top"
                title={t('COMMON.POSITION')}
                color="text03"
                onClick={() => setMaskOpen(!maskOpen)}
              />
            </div>
          )}
          {maskOpen && (
            <AutoSizer disableWidth>
              {({ height }) => {
                return (
                  <ResizableArea
                    minWidth={250}
                    maxWidth={1200}
                    size={{ width: positionMaskAreaSetting.value.width }}
                    onResize={(size) => positionMaskAreaSetting.set(size)}
                    handles="left"
                  >
                    <Stack direction="row" height={height}>
                      <Mask
                        item={editedItem}
                        onCloseClicked={() => setMaskOpen(false)}
                        onEdit={onEditPosition}
                        onRemove={onRemove}
                      />
                    </Stack>
                  </ResizableArea>
                );
              }}
            </AutoSizer>
          )}
        </Box>
      )}
    </div>
  );
}

const genericMemo: <T>(component: T) => T = memo;
export const EditTable = genericMemo(EditTableInternal);
