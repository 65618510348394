import styles from './VacationDaysInfoList.module.scss';

import clsx from 'clsx';

export interface VacationDaysInfoListProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export function VacationDaysInfoList(props: VacationDaysInfoListProps) {
  const { className, ...others } = props;

  return <div className={clsx(styles.root, className)} {...others} />;
}
