import styles from './AttachmentCell.module.scss';

import { ReactComponent as AttachmentIcon } from '@work4all/assets/icons/email-actions/table/attachment.svg';

export type AttachmentCellProps = {
  row: { original: { attachmentList: { id: string }[]; file: [] } };
};

export const AttachmentCell = (props: AttachmentCellProps) => {
  const attachmentList = props.row.original.attachmentList?.length;
  const file = props.row.original.file?.length;

  const array = [attachmentList, file];
  const attachements = Boolean(attachmentList) || Boolean(file);
  return (
    <div
      className={styles.attachmentCellRoot}
      title={`${array.filter((x) => x)[0] || 0}`}
    >
      {attachements && <AttachmentIcon />}
    </div>
  );
};
