import styles from './CustomerSupplierImageGallery.module.scss';

import React, { useState } from 'react';

import {
  IImageItem,
  ImageGallery,
} from '@work4all/components/lib/components/image-gallery/ImageGallery';

import { Header } from './components/header/Header';

interface ICustomerSupplierImageGalleryProps {
  items: IImageItem[];
}

export const CustomerSupplierImageGallery: React.FC<
  ICustomerSupplierImageGalleryProps
> = (props) => {
  const [items, setItems] = useState(props.items);

  const getFileSizeFromDataUri = (dataUri: string) => {
    const base64str = dataUri.substr(22);
    const decoded = atob(base64str);
    return decoded.length;
  };

  const setPicture = (
    dataUri: string,
    size?: number,
    type?: string,
    name?: string
  ) => {
    size = !size ? getFileSizeFromDataUri(dataUri) : size;

    setItems([
      {
        fileSize: size ? size : 0,
        fileName: name ? name : 'Image 1',
        id: 'xxx',
        imgUrl: dataUri,
        imgThumbUrl: dataUri,
      },
    ]);
  };

  return (
    <div className={styles.root}>
      <Header onPictureTaken={setPicture} onImageChoosen={setPicture} />
      <ImageGallery items={items} height={'100%'} />
    </div>
  );
};
