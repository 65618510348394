import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item/CheckboxRadioItem';

import { useUser } from '@work4all/data';

import { canEditTimeTrackingForOthers } from '@work4all/utils/lib/permissions';

import { settings, useSetting } from '../../../../../../../../settings';

export const BehaviourSection = () => {
  const { t } = useTranslation();
  const currentUser = useUser();
  const hasRight = canEditTimeTrackingForOthers(currentUser, undefined);
  const { value, set } = useSetting(settings.createTimeTrackingForOthers());

  return (
    <Collapse title={t('COMMON.BEHAVIOUR')} defaultOpen={true}>
      <CheckboxRadioItem
        label={t('SETTINGS.PROJECT_TIME.CREATE_TIME_TRACKING_FOR_OTHERS')}
        checked={hasRight ? value : false}
        onChange={() => {
          set(value ? false : true);
        }}
        disabled={!hasRight}
      />
    </Collapse>
  );
};
