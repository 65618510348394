import styles from './TableRow.module.scss';

import clsx from 'clsx';
import produce from 'immer';
import { ReactNode } from 'react';
import { TableCommonProps } from 'react-table';

import { TableCell } from '../TableCell/TableCell';

import { DRAG_HANDLER_COLUMN_WIDTH } from './constants';

export type ITableHeaderRowProps = TableCommonProps & {
  disabled?: boolean;
  children?: ReactNode;
};

export const TableHeaderRow = (props: ITableHeaderRowProps) => {
  const { className, style, disabled, children, ...rest } = props;

  const modifiedStyle = produce(style, (draft) => {
    draft.width =
      (typeof draft.width === 'number' ? draft.width : parseInt(draft.width)) +
      DRAG_HANDLER_COLUMN_WIDTH;
  });

  return (
    <div
      className={clsx(styles['table-row'], className)}
      style={modifiedStyle}
      {...rest}
    >
      {disabled ? null : (
        <TableCell role="cell" className={styles['drag-handler']} />
      )}
      {children}
    </div>
  );
};
