import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { ObjectType } from '../Enums/ObjectType.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputSavedListFilter<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string | number;
  /** Alias: name */
  name?: string;
  /** Alias: userCode */
  userCode?: number;
  /** Alias: global */
  global?: boolean;
  /** Alias: filter */
  filter?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];
  /** Alias: objectType */
  objectType?: ObjectType;
  /** Alias: version */
  version?: string;

  __typename?: string;
}

const fields: FieldDefinitions<InputSavedListFilter> = {
  id: {
    alias: 'id',
  },
  name: {
    alias: 'name',
  },
  userCode: {
    alias: 'userCode',
  },
  global: {
    alias: 'global',
  },
  filter: {
    alias: 'filter',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customFields: {
    alias: 'customFields',
  },
  objectType: {
    alias: 'objectType',
  },
  version: {
    alias: 'version',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputSavedListFilterEntityDefinition: EntitiyDefinition<InputSavedListFilter> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputSavedListFilter',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
