import { useCallback, useMemo, useState } from 'react';

import {
  ApolloExtrasContextProvider,
  ApolloExtrasContextValue,
} from './apollo-extras-context';

export interface ApolloExtrasProps {
  children?: React.ReactChild;
}

export function ApolloExtras(props: ApolloExtrasProps) {
  const { children } = props;

  const [disableCallsCount, setDisableCallsCount] = useState(0);

  const disableAutoInvalidation = useCallback(() => {
    setDisableCallsCount((count) => count + 1);

    return () => {
      setDisableCallsCount((count) => count - 1);
    };
  }, []);

  const isAutoInvalidationDisabled = disableCallsCount > 0;

  const value = useMemo<ApolloExtrasContextValue>(() => {
    return { isAutoInvalidationDisabled, disableAutoInvalidation };
  }, [isAutoInvalidationDisabled, disableAutoInvalidation]);

  return (
    <ApolloExtrasContextProvider value={value}>
      {children}
    </ApolloExtrasContextProvider>
  );
}
