import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useRxDB } from 'rxdb-hooks';
import { filter, firstValueFrom } from 'rxjs';

import { IUser } from '@work4all/data';
import { refreshTokenAction } from '@work4all/data/lib/actions/user-actions';
import { USER_META_ID } from '@work4all/data/lib/db';

/**
 * Returns a function that can be used to refresh the access token. This
 * function will return a promise that will resolve with the value of the new
 * token after the token has been refreshed.
 *
 * If the token can't be refreshed for some reason, the promise will reject.
 * This can happen, for example, if the refresh token is expired/invalid, if the
 * user is not logged in when the function is called, or if the API request
 * fails.
 */
export function useTokenRefresher(): () => Promise<string> {
  const db = useRxDB();
  const dispatch = useDispatch();

  return useCallback(async () => {
    const doc = await db.getLocal<{ user: IUser }>(USER_META_ID);
    const user = doc._data.data.user;
    const currentToken = user.token;
    dispatch(refreshTokenAction());

    const nextDoc = await firstValueFrom(
      db.getLocal$<{ user: IUser }>(USER_META_ID).pipe(
        filter((nextDoc) => {
          // we wait for the update with the new token and skip all other updates, that may occur in the meantime
          return nextDoc._data.data.user.token !== currentToken;
        })
      )
    );

    return nextDoc._data.data.user.token;
  }, [db, dispatch]);
}
