import styles from './TableSection.module.scss';

import { Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  IBasicTableProps,
  Table,
  TableStateBagProvider,
} from '@work4all/components';
import { HookedUserIcon } from '@work4all/components/lib/components/user-icon/useUserIconRegister';

import { InboundInvoicePayment } from '@work4all/models/lib/Classes/ InboundInvoicePayment.entity';

import {
  DateFormatPreset,
  formatDateString,
} from '@work4all/utils/lib/date-utils/formatDateString';

import { NumberInputCell } from '../../components/table-cells/NumberInputCell';
import { TextInputCell } from '../../components/table-cells/TextInputCell';

export const TableSection = () => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();

  const watchPayments = watch('payments');
  const payments: InboundInvoicePayment[] = useMemo(
    () => watchPayments ?? [],
    [watchPayments]
  );
  const totalAccount = useMemo(() => {
    return payments.reduce(
      (accumulator, payment) =>
        (payment?.account ? Number(payment.account) : 0) + accumulator,
      0
    );
  }, [payments]);

  const handleEdit = useCallback(
    (
      oldPaymentValue: InboundInvoicePayment,
      newPaymentValue: InboundInvoicePayment
    ) => {
      const copyPayments = [...payments];
      const payment = copyPayments.find(
        (item) => item.code === oldPaymentValue.code
      );
      Object.keys(newPaymentValue).forEach((key) => {
        payment[key] = newPaymentValue[key];
      });

      setValue('payments', copyPayments, { shouldDirty: true });
    },
    [payments, setValue]
  );

  const columns = useMemo<IBasicTableProps['columns']>(() => {
    return [
      {
        Header: t('PAYMENTS_MASK.TABLE.COLUMNS.BOOKING_DATE'),
        accessor: 'bookingDate',
        width: 150,
        Cell: (cell) =>
          formatDateString(cell?.value, DateFormatPreset.DATE_SIMPLE_2YEAR),
      },
      {
        Header: t('PAYMENTS_MASK.TABLE.COLUMNS.FINANCIAL_ACCOUNT_TRANSFER'),
        accessor: 'datevDate',
        width: 150,
      },
      {
        Header: t('PAYMENTS_MASK.TABLE.COLUMNS.ACCOUNT'),
        accessor: 'account',
        width: 150,
        Footer: <Typography>{totalAccount}</Typography>,
        Cell: (cell) => {
          return (
            <NumberInputCell
              disabled
              value={cell.value}
              onChange={(value) => {
                const account = value.toString();

                if (account !== cell.value) {
                  handleEdit(cell.row.original, { account });
                }
              }}
            />
          );
        },
      },
      {
        Header: t('PAYMENTS_MASK.TABLE.COLUMNS.LEDGER_ACCOUNT_NUMBER'),
        accessor: 'ledgerAccountNumber',
        width: 150,
        Cell: (cell) => {
          return (
            <NumberInputCell
              disabled
              value={cell.value}
              onChange={(ledgerAccountNumber) => {
                if (ledgerAccountNumber !== cell.value) {
                  handleEdit(cell.row.original, { ledgerAccountNumber });
                }
              }}
            />
          );
        },
      },
      {
        Header: t('PAYMENTS_MASK.TABLE.COLUMNS.NOTE'),
        accessor: 'text',
        width: 150,
        Cell: (cell) => {
          return (
            <TextInputCell
              disabled
              value={cell.value}
              onChange={(text) => {
                if (text !== cell.value) {
                  handleEdit(cell.row.original, { text });
                }
              }}
            />
          );
        },
      },
      {
        Header: t('PAYMENTS_MASK.TABLE.COLUMNS.USER'),
        accessor: 'user',
        width: 250,
        Cell: (cell) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <HookedUserIcon userId={cell?.value?.id} />
              <Typography style={{ marginLeft: '12px' }}>
                {cell?.value?.displayName}
              </Typography>
            </div>
          );
        },
      },
    ];
  }, [totalAccount, handleEdit, t]);

  return (
    <div className={styles.wrapper}>
      <div>
        <Typography variant="h4">Payments</Typography>
      </div>

      <TableStateBagProvider tableInstance={null}>
        <Table
          mode="client"
          columns={columns}
          data={payments}
          allItemsCount={payments.length}
          className={styles.table}
          displayFooter
          resizableColumns
        />
      </TableStateBagProvider>
    </div>
  );
};
