import { IFieldData } from '@work4all/models/lib/DataProvider';

import { DATA_PREFIX } from '..//variables';

export const withPrefixedFields = (
  fields: IFieldData[],
  customPrefix?: string
) => {
  return fields.map((field) => {
    if (customPrefix) {
      return { ...field, name: `${DATA_PREFIX}.${customPrefix}.${field.name}` };
    }

    return { ...field, name: `${DATA_PREFIX}.${field.name}` };
  });
};
