import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { MailboxEncryption } from './types';

export interface MailboxEncryptionOptionProps {
  value: MailboxEncryption;
}

export function MailboxEncryptionOption(props: MailboxEncryptionOptionProps) {
  const { value } = props;

  const { t } = useTranslation();

  if (!value) return null;

  return (
    <Typography component="div" variant="body2" noWrap>
      {t(`LINK_MAILBOX.MAILBOX_ENCRYPTION.${value}`)}
    </Typography>
  );
}
