import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDataProvider } from '@work4all/data';

import { PaymentKind } from '@work4all/models/lib/Classes/PaymentKind.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type IPaymentKindPickerProps<TMultiple extends boolean> = IPickerProps<
  PaymentKind,
  TMultiple
>;

export const DEFAULT_PAYMENT_KIND: PaymentKind & { translationKey: string } = {
  id: 0,
  translationKey: 'PAYMENT_KIND.TERMS_OF_PAYMENT',
};

export function PaymentKindPicker<TMultiple extends boolean>(
  props: IPaymentKindPickerProps<TMultiple>
) {
  const { data, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...PAYMENT_KIND_FIELDS, ...data };
  }, [data]);

  const request = useMemo<DataRequest>(() => {
    return {
      data: allFields,
      entity: Entities.paymentKind,
      completeDataResponse: true,
      operationName: 'GetPaymentKinds',
    };
  }, [allFields]);

  const response = useDataProvider(request);

  const { t } = useTranslation();

  // The data provider does not set the `total` property when
  // `completeDataResponse` is `true`.   So we have to set it manually.
  // Otherwise, the <ListEntityPicker /> will not be able to render the list.
  const patchedResponse = useMemo(() => {
    return {
      ...response,
      data: [
        {
          ...DEFAULT_PAYMENT_KIND,
          note: t(DEFAULT_PAYMENT_KIND.translationKey),
        },
        ...response.data,
      ],
      total: response.data.length,
    };
  }, [response, t]);

  const renderItem = (paymentKind: PaymentKind) => {
    const label = paymentKind.note;

    return (
      <Tooltip title={label}>
        <Typography variant="body2" noWrap>
          {label}
        </Typography>
      </Tooltip>
    );
  };

  return (
    <ListEntityPicker
      entity={Entities.user}
      data={allFields}
      filterBy={'note'}
      sortBy="note"
      {...rest}
      fixedDataSet={patchedResponse}
      renderItemContent={renderItem}
    />
  );
}

const PAYMENT_KIND_FIELDS: PaymentKind = {
  id: null,
  disentangleAutomatically: null,
  disentangleAutomaticallyAccount: null,
  fibuReferencenumber: null,
  longtext: null,
  note: null,
  ledgerAccountId: null,
  skonto2Percent: null,
  skonto2DurationDays: null,
  skonto1Percent: null,
  skonto1DurationDays: null,
  paymentTarget: null,
};
