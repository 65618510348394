import React from 'react';
import { useTranslation } from 'react-i18next';

import { BanderoleInfo } from '@work4all/components/lib/components/banderole/BanderoleInfo';

import { settings, useSetting } from '../../../../settings';

export const ProjectBanderoleInfo = () => {
  const { t } = useTranslation();

  const { value, set } = useSetting(
    settings.showProjectAccessGroupRightQuestion()
  );

  if (!value) return null;

  return (
    <BanderoleInfo
      visible={value}
      onClose={() => set(false)}
      text={t('COMMON.RIGHTS_GROUP.BANDEROLE')}
    />
  );
};
