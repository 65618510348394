import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { OcrInvoiceSupplierGuessing } from './OcrInvoiceSupplierGuessing.entity';

export class OcrInvoiceData<T extends EMode = EMode.entity> {
  /** Alias: dataProcessed */
  dataProcessed?: boolean;
  /** Alias: rawText */
  rawText?: string;
  /** Alias: ibanMatches */
  ibanMatches?: string[];
  /** Alias: sumMatches */
  sumMatches?: string[];
  /** Alias: supplierGuessings */
  supplierGuessings?: OcrInvoiceSupplierGuessing<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<OcrInvoiceData> = {
  dataProcessed: {
    alias: 'dataProcessed',
  },
  rawText: {
    alias: 'rawText',
  },
  ibanMatches: {
    alias: 'ibanMatches',
  },
  sumMatches: {
    alias: 'sumMatches',
  },
  supplierGuessings: {
    alias: 'supplierGuessings',
    entity: Entities.ocrInvoiceSupplierGuessing,
  },
  __typename: {
    alias: '__typename',
  },
};

export const ocrInvoiceDataEntityDefinition: EntitiyDefinition<OcrInvoiceData> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'OcrInvoiceData',
      withPaginationWrapper: false,
      mutations: {
        upsert: {
          mutationName: 'interpretTempFileAsIncommingInvoice',
          args: [{ name: 'tempfileId', type: 'ID!' }],
        },
      },
    },
    fieldDefinitions: fields,
  };
