import { useContext } from 'react';

import { IHistoryStackBag, StackContext } from '../components/stack-context';

import { useHistoryStackIndex } from './useHistoryStackIndex';

export const useHistoryStack = (): IHistoryStackBag & {
  viewIndex?: number;
} => {
  const bag = useContext(StackContext);
  if (!bag) {
    throw new Error(
      'History Stack Bag is undefined. Make sure that you wrapped you component with <StackProvider>'
    );
  }

  /**
   * Every view has its own index.
   * It possible that user wants to show transition effect when "current" view changes.
   * In this case "View" component cannot use "current" object from bag
   * because it is always last view from stack
   * To make history stack work correctly with transition effect we need to bind index to every view
   * This is done via "history stack index" context.
   * Use "viewStackItem" instead of current in order to avoid displaying wrong data when
   * transition effect happens.
   */
  const viewIndex = useHistoryStackIndex();
  const viewStackItem =
    typeof viewIndex === 'number' ? bag.stack[viewIndex] : bag.current;

  return { ...bag, viewStackItem, viewIndex };
};
