import styles from './SignatureDialog.module.scss';

import { Box, Stack } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SignatureIcon } from '@work4all/assets/icons/signature.svg';

import { useLock } from '@work4all/components/lib/hooks';
import { IconButtonWithTooltip } from '@work4all/components/lib/input/actions/IconButtonWithTooltip';
import { Caption } from '@work4all/components/lib/typography/caption/Caption';

import { EMPTY_UID } from '@work4all/data/lib/utils/empty-uid';

import { DeliveryNote } from '@work4all/models/lib/Classes/DeliveryNote.entity';
import { File as FileEntity } from '@work4all/models/lib/Classes/File.entity';

import { PathsOf } from '@work4all/utils/lib/paths-of/paths-of';

import { useFormContextPlus } from '../../../../../form-plus/use-form-context-plus';
import { useShadowBzObjectApiContext } from '../../hooks/use-bz-shadow-object-api/use-shadow-bz-object-api-context';

import { SignatureContext } from './signature-provider';
import { SignatureDialogConfirmDialog } from './SignatureDialog';
import { useSignatureCanvas } from './use-signature-canvas';

interface SignatureInternalProps extends SignatureProps {
  signature: FileEntity;
  showSignature?: boolean;
}

const SignatureInternal = (props: SignatureInternalProps) => {
  const { t } = useTranslation();
  const { showSignature } = props;

  const { locked } = useLock();

  const shadowBzObjectApi = useShadowBzObjectApiContext();

  const { uploadFiles, fileList, markFilesToDelete } =
    useContext(SignatureContext);

  const signature = useMemo(() => {
    return fileList[fileList.length - 1];
  }, [fileList]);

  const { canvas } = useSignatureCanvas(signature);

  const signatureExist = !!signature;
  const [openSignature, setOpenSignature] = useState(false);

  const setSignature = shadowBzObjectApi.setSignature;
  useEffect(() => {
    const lastId = signature?.id;
    if (lastId && typeof lastId === 'string') {
      setSignature(lastId);
      setOpenSignature(false);
    }
  }, [setSignature, setOpenSignature, signature]);

  return (
    <React.Fragment>
      {showSignature ? (
        <Stack
          position="relative"
          onClick={() => !(locked || props.disabled) && setOpenSignature(true)}
        >
          <Caption color="text03">{t('COMMON.SIGNATURE')}</Caption>
          <div className={styles['signature']}>{canvas}</div>
          <Box position="absolute" top={0} bottom={0} right={0} left={0}></Box>
        </Stack>
      ) : (
        <IconButtonWithTooltip
          color={signatureExist ? 'success' : 'primary'}
          style={{ padding: '0.75rem' }}
          tooltip={t('MASK.CREATE_SIGNATURE')}
          icon={<SignatureIcon />}
          disabled={locked || props.disabled}
          onClick={() => setOpenSignature(true)}
        />
      )}
      <SignatureDialogConfirmDialog
        open={openSignature}
        signature={signature}
        onConfirm={(file: File) => {
          uploadFiles([file]);
          setOpenSignature(false);
        }}
        onDelete={(id: string | number) => {
          markFilesToDelete([id.toString()]);
          if (props.signature) {
            setSignature(EMPTY_UID);
          }
          setOpenSignature(false);
        }}
        onClose={() => setOpenSignature(false)}
      />
    </React.Fragment>
  );
};

interface SignatureProps {
  disabled?: boolean;
  showSignature?: boolean;
}

export const Signature = (props: SignatureProps) => {
  const { watch } = useFormContextPlus<PathsOf<DeliveryNote, 2>>();
  const signature = watch('signature');

  return <SignatureInternal signature={signature} {...props} />;
};
