import styles from './Info.module.scss';

import { useContext } from 'react';

import { DocumentMaskContext } from '../../context';

import { Assignment } from './components/assignment';
import { Content } from './components/content';
import { ExternalSource } from './components/ExternalSource';
import { Target } from './components/target';

export function Info() {
  const maskExtras = useContext(DocumentMaskContext);

  return (
    <div className={styles.info}>
      <Assignment />
      {(maskExtras.type === 'file' || maskExtras.type === 'template') && (
        <Target />
      )}
      {maskExtras.type === 'link' && <ExternalSource />}
      <Content />
    </div>
  );
}
