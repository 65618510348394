import styles from './Calendar.module.scss';

import { LinearProgress, Theme, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import { AutoSizer } from 'react-virtualized';

import { useUser } from '@work4all/data';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { UserClass } from '@work4all/models/lib/Enums/UserClass.enum';

import { settings, useSetting } from '../../settings';

import { CreateViewDialog } from './components/CreateViewDialog';
import { Footer } from './components/Footer';
import { Main } from './components/Main';
import { MobileActionBar } from './components/MobileActionBar';
import { Sidebar } from './components/Sidebar';
import { Toolbar } from './components/Toolbar';
import { AgendaSize, AppointmentCalendarView, CalendarProps } from './types';
import { mapUsersToAppointmentAttendees } from './utils/map-users-to-appointment-attendees';

export function Calendar(props: CalendarProps) {
  const {
    loading,
    onUsersChange,
    focusedUserIds,
    onFocusedUserIdsChange,
    date,
    onDateChange,
    appointmentStates,
    onAppointmentStatesChange,
    onRangeChange,
    onGroupModeChange,
    onOpenMask,
    views,
    onSelectView,
    onCreateView,
    onDeleteView,
    forceMobileView = false,
    groupMode,
    range,
    hideMobileAddButton,
    hideMobileUserSelection,
  } = props;

  const { value: calendarCardColorBy } = useSetting(
    settings.calendarAppointmentCardColorBy()
  );

  const users = useMemo(() => {
    const resolveDisplayName = (userOrResource: User): string => {
      if (userOrResource.userKind === UserClass.RESSOURCE) {
        return userOrResource.shortName;
      }

      return userOrResource.displayName;
    };

    return props.users
      .slice()
      .sort((a, b) =>
        resolveDisplayName(a).localeCompare(resolveDisplayName(b))
      );
  }, [props.users]);

  // In focus mode only show the focused users.
  const visibleUsers = useMemo(() => {
    if (focusedUserIds.length === 0) return users;
    return users.filter((user) => focusedUserIds.includes(user.id));
  }, [users, focusedUserIds]);

  const activeView: AppointmentCalendarView | undefined = useMemo(() => {
    return views.find((view) => {
      let result = true;
      view.params.userIds.some((userId) => {
        if (!users.find((user) => user.id === userId)) {
          result = false;
          return true;
        }
      });
      if (view.params.userIds.length !== users.length) result = false;
      return result;
    });
  }, [users, views]);

  const allViewNames = useMemo(() => {
    return views.map((view) => view.name);
  }, [views]);

  const isMdUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));
  const isLgUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'));
  const currentUser = useUser();

  // Clicking "Clear selection" always selects just the current user.
  const handleUsersClear = useCallback(() => {
    // If the current user is already the only one selected, do nothing.
    if (users.length === 1 && users[0].id === currentUser.benutzerCode) {
      return;
    }

    onUsersChange([
      {
        id: currentUser.benutzerCode,
        displayName: currentUser.displayName,
      },
    ]);
  }, [onUsersChange, users, currentUser]);

  const [createViewDialogOpen, setCreateViewDialogOpen] = useState(false);

  const handleCreateViewClick = () => {
    setCreateViewDialogOpen(true);
  };

  const [agendaSize, setAgendaSize] = useState<AgendaSize>('shrinked');

  return (
    <div className={styles.root}>
      <AutoSizer>
        {({ width, height }) => (
          <div
            style={{
              width,
              height: height - 1,
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            {loading && (
              <LinearProgress
                sx={{ position: 'absolute', inset: '0 0 auto 0' }}
              />
            )}

            <div
              className={clsx(styles.grid, {
                [styles.forceMobileView]: forceMobileView,
                [styles.showSidebar]: range === 'agenda' && isMdUp && !isLgUp,
              })}
            >
              {!forceMobileView &&
                isMdUp &&
                ((range === 'agenda' && isMdUp) || isMdUp) && (
                  <Sidebar
                    users={users}
                    onUsersChange={onUsersChange}
                    onUsersClear={handleUsersClear}
                    focusedUserIds={focusedUserIds}
                    onFocusedUserIdsChange={onFocusedUserIdsChange}
                    views={views}
                    activeView={activeView}
                    onSelectView={onSelectView}
                    onCreateView={handleCreateViewClick}
                    onDeleteView={onDeleteView}
                    calendarCardColorBy={calendarCardColorBy}
                  />
                )}
              {!forceMobileView && (
                <Toolbar
                  date={date}
                  onDateChange={onDateChange}
                  range={range}
                  onRangeChange={onRangeChange}
                  groupMode={groupMode}
                  onGroupModeChange={onGroupModeChange}
                  agendaSize={agendaSize}
                />
              )}

              <Main
                forceMobileView={forceMobileView}
                users={visibleUsers}
                range={range}
                groupMode={groupMode}
                appointmentStates={appointmentStates}
                date={date}
                onDateChange={onDateChange}
                onOpenAppointmentMask={onOpenMask}
                calendarCardColorBy={calendarCardColorBy}
                agendaSize={agendaSize}
                onAgendaSizeChange={(size) => {
                  setAgendaSize(size);
                }}
              />

              {!forceMobileView && isMdUp && (
                <Footer
                  selectedAppointmentStates={appointmentStates}
                  onSelectedAppointmentStatesChange={onAppointmentStatesChange}
                  calendarCardColorBy={calendarCardColorBy}
                />
              )}
            </div>
          </div>
        )}
      </AutoSizer>

      {(forceMobileView || !isMdUp) && (
        <MobileActionBar
          users={users}
          onUsersChange={onUsersChange}
          onUsersClear={handleUsersClear}
          focusedUserIds={focusedUserIds}
          onFocusedUserIdsChange={onFocusedUserIdsChange}
          appointmentStates={appointmentStates}
          onAppointmentStatesChange={onAppointmentStatesChange}
          onCreateClick={() => {
            const template: Appointment = {
              appointmentAttendeeList: mapUsersToAppointmentAttendees(users),
            };
            onOpenMask(null, JSON.stringify(template));
          }}
          views={views}
          activeView={activeView}
          onSelectView={onSelectView}
          onCreateView={handleCreateViewClick}
          onDeleteView={onDeleteView}
          calendarCardColorBy={calendarCardColorBy}
          hideAddButton={hideMobileAddButton}
          hideUserSelection={hideMobileUserSelection}
        />
      )}

      <CreateViewDialog
        open={createViewDialogOpen}
        onClose={() => setCreateViewDialogOpen(false)}
        onConfirm={(name) => {
          setCreateViewDialogOpen(false);
          onCreateView(name);
        }}
        options={allViewNames}
      />
    </div>
  );
}
