import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { ServiceContractKind } from '../Enums/ServiceContractKind.enum';

export class ServiceContractPosition<T extends EMode = EMode.entity> {
  /** Alias: anzahl */
  amount?: number;
  /** Alias: anzahlDecimal */
  amountDecimal?: number;
  /** Alias: anzahlLieferant */
  amountSupplier?: number;
  /** Alias: art */
  serviceContractKind?: ServiceContractKind;
  /** Alias: artikelCode */
  articleId?: number;
  /** Alias: artikelNummer */
  articleNumber?: string;
  /** Alias: bemerkung */
  comment?: string;
  /** Alias: code */
  id?: number;
  /** Alias: datum */
  date?: string;
  /** Alias: ekPreis */
  purchasePrice?: number;
  /** Alias: ekPreis2 */
  ekPreis2?: number;
  /** Alias: frei1 */
  free1?: string;
  /** Alias: frei2 */
  free2?: string;
  /** Alias: frei3 */
  free3?: string;
  /** Alias: frei4 */
  free4?: string;
  /** Alias: frei5 */
  free5?: string;
  /** Alias: gueltigAb */
  validFromDate?: string;
  /** Alias: gueltigBis */
  validUntilDate?: string;
  /** Alias: herstellernummer */
  manufacturerNumber?: string;
  /** Alias: index */
  index?: number;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: kostenstelle */
  costCenterId?: number;
  /** Alias: kurztext */
  shortText?: string;
  /** Alias: langtext */
  longtext?: string;
  /** Alias: lieferantenCode */
  supplierId?: number;
  /** Alias: lieferantenText */
  supplierText?: string;
  /** Alias: positionsNummer */
  positionNumber?: string;
  /** Alias: preis */
  price?: number;
  /** Alias: preis2 */
  price2?: number;
  /** Alias: preis2AbDatum */
  price2StartDate?: string;
  /** Alias: prozent */
  percent?: number;
  /** Alias: rabatt */
  discount?: number;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: wartungspreisJahr */
  servicePricePerYear?: number;
  /** Alias: wartungsvertragCode */
  serviceContractId?: number;

  __typename?: string;
}

const fields: FieldDefinitions<ServiceContractPosition> = {
  amount: {
    alias: 'anzahl',
  },
  amountDecimal: {
    alias: 'anzahlDecimal',
  },
  amountSupplier: {
    alias: 'anzahlLieferant',
  },
  serviceContractKind: {
    alias: 'art',
  },
  articleId: {
    alias: 'artikelCode',
  },
  articleNumber: {
    alias: 'artikelNummer',
  },
  comment: {
    alias: 'bemerkung',
  },
  id: {
    alias: 'code',
  },
  date: {
    alias: 'datum',
  },
  purchasePrice: {
    alias: 'ekPreis',
  },
  ekPreis2: {
    alias: 'ekPreis2',
  },
  free1: {
    alias: 'frei1',
  },
  free2: {
    alias: 'frei2',
  },
  free3: {
    alias: 'frei3',
  },
  free4: {
    alias: 'frei4',
  },
  free5: {
    alias: 'frei5',
  },
  validFromDate: {
    alias: 'gueltigAb',
  },
  validUntilDate: {
    alias: 'gueltigBis',
  },
  manufacturerNumber: {
    alias: 'herstellernummer',
  },
  index: {
    alias: 'index',
  },
  insertTime: {
    alias: 'insertTime',
  },
  costCenterId: {
    alias: 'kostenstelle',
  },
  shortText: {
    alias: 'kurztext',
  },
  longtext: {
    alias: 'langtext',
  },
  supplierId: {
    alias: 'lieferantenCode',
  },
  supplierText: {
    alias: 'lieferantenText',
  },
  positionNumber: {
    alias: 'positionsNummer',
  },
  price: {
    alias: 'preis',
  },
  price2: {
    alias: 'preis2',
  },
  price2StartDate: {
    alias: 'preis2AbDatum',
  },
  percent: {
    alias: 'prozent',
  },
  discount: {
    alias: 'rabatt',
  },
  updateTime: {
    alias: 'updateTime',
  },
  servicePricePerYear: {
    alias: 'wartungspreisJahr',
  },
  serviceContractId: {
    alias: 'wartungsvertragCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const serviceContractPositionEntityDefinition: EntitiyDefinition<ServiceContractPosition> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'WartungsvertragPosition',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
