import styles from './InfoCardLabel.module.scss';

import { Typography } from '@mui/material';

export type IInfoCardLabelProps = {
  children?: React.ReactNode;
};

export function InfoCardLabel(props: IInfoCardLabelProps) {
  const { children } = props;

  return (
    <Typography className={styles['info-card-label']}>{children}</Typography>
  );
}
