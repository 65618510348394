import './DurationField.scss';
import styles from './DurationField.module.scss';

import { Duration } from 'luxon';
import { FocusEventHandler, useEffect, useRef, useState } from 'react';

import { LabeledInput } from '../../mask-overlays/locked-inputs';

export interface DurationFieldProps {
  label: string;
  value: string;
  error?: string;
  onChange: (value: string) => void;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
}

const DurationField = ({
  label,
  value,
  error,
  onChange,
  onBlur,
  onFocus,
}: DurationFieldProps) => {
  const ref = useRef(null);
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [renderValue, setRenderValue] = useState('00:00');
  const isFirstKeyStrokeAfterUnitChange = useRef(false);

  useEffect(() => {
    const duration = value.split(':');
    //make sure the hours and minutes are not prefixed with "0"s
    const hours = parseInt(duration[0], 10).toString();
    const minutes = parseInt(duration[1], 10).toString();
    setHours(hours);
    setMinutes(minutes);
  }, [value]);

  useEffect(() => {
    const duration = Duration.fromObject({
      hours: +hours,
      minutes: +minutes,
    }).toFormat('hh:mm');
    setRenderValue(duration);
    onChange(duration);
  }, [hours, minutes, onChange]);

  const keyHandler = (e) => {
    const key = e.key;
    const position = ref.current.selectionStart;
    const hoursFocused = position < 3;
    if (e.key === 'Tab') {
      if (hoursFocused) {
        selectMinutes(true);
        e.preventDefault();
      }
      return;
    }

    e.preventDefault();

    // preventing holding a key down, because it's causing a problem
    if (e.repeat) {
      hoursFocused ? selectHours() : selectMinutes();
      return;
    }

    const hoursHandler = (key: string) => {
      let hoursValue = hours;

      if (key === 'Backspace') {
        hoursValue = hours.slice(0, -1);
      } else if (key === 'Delete') {
        hoursValue = '00';
      } else {
        if (isFirstKeyStrokeAfterUnitChange.current) {
          isFirstKeyStrokeAfterUnitChange.current = false;
          hoursValue = key;
        } else {
          const hoursLength = (+hours).toString().length;
          hoursValue = hoursLength === 2 ? key : `${hours}${key}`;
        }
      }

      setHours(hoursValue);

      return hoursValue;
    };

    const hoursArrowsHandler = (value: number) => {
      const totalValue = +hours + value;

      if (totalValue >= 0 && totalValue <= 99) {
        setHours(totalValue.toString());
      }
    };

    const minutesHandler = (value: string) => {
      let minutesValue = minutes;

      if (value === 'Backspace') {
        minutesValue = minutes.slice(0, -1);
      } else if (key === 'Delete') {
        minutesValue = '00';
      } else {
        if (isFirstKeyStrokeAfterUnitChange.current) {
          isFirstKeyStrokeAfterUnitChange.current = false;
          minutesValue = key;
        } else {
          const totalValue: number = +(minutes + value);
          minutesValue = totalValue <= 59 ? totalValue.toString() : value;
        }
      }

      setMinutes(minutesValue);
    };

    const minutesArrowsHandler = (value: number) => {
      const totalValue = +minutes + value;

      if (totalValue >= 0 && totalValue <= 59) {
        setMinutes(totalValue.toString());
      }
    };

    switch (key) {
      case 'ArrowUp': {
        if (hoursFocused) {
          hoursArrowsHandler(1);
          selectHours();
        } else {
          minutesArrowsHandler(1);
          selectMinutes();
        }
        break;
      }
      case 'ArrowDown': {
        if (hoursFocused) {
          hoursArrowsHandler(-1);
          selectHours();
        } else {
          minutesArrowsHandler(-1);
          selectMinutes();
        }
        break;
      }
      case 'Delete':
      case 'Backspace': {
        hoursFocused ? hoursHandler(key) : minutesHandler(key);
        hoursFocused
          ? selectHours(key === 'Delete')
          : selectMinutes(key === 'Delete');
        break;
      }

      case 'ArrowLeft':
      case 'ArrowRight': {
        hoursFocused ? selectMinutes(true) : selectHours(true);
        break;
      }

      default:
        break;
    }

    if (+key >= 0 && +key <= 9) {
      if (hoursFocused) {
        const newHoursvalue = hoursHandler(key);
        if (newHoursvalue.length >= 2) {
          selectMinutes(true);
        } else {
          selectHours();
        }
      } else {
        minutesHandler(key);
        selectMinutes();
      }
    }
  };

  const selectHours = (reset = false) => {
    isFirstKeyStrokeAfterUnitChange.current = reset;
    //next rederframe as the input value will rerender
    setTimeout(() => ref.current.setSelectionRange(0, 2), 0);
  };

  const selectMinutes = (reset = false) => {
    isFirstKeyStrokeAfterUnitChange.current = reset;
    //next rederframe as the input value will rerender
    setTimeout(() => ref.current.setSelectionRange(3, 5), 0);
  };

  const focusInput = () => {
    const position = ref.current.selectionStart;
    const hoursFocused = position < 3;
    if (hoursFocused) {
      selectHours(true);
    } else {
      selectMinutes(true);
    }
  };

  return (
    <LabeledInput
      ref={ref}
      label={label}
      className={styles.container}
      onKeyDown={keyHandler}
      onClick={focusInput}
      error={error}
      value={renderValue}
      onBlur={onBlur}
      onFocus={(e) => {
        selectHours(true);
        onFocus?.(e);
      }}
      inputProps={{
        inputMode: 'numeric',
      }}
    />
  );
};

export default DurationField;
