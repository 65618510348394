import { ApolloLink } from '@apollo/client';
import { createContext, Provider, useContext } from 'react';

export interface ApiVersionLinkContextValue {
  link: ApolloLink;
}

const Context = createContext<ApiVersionLinkContextValue | null>(null);

export const ApiVersionLinkContextProvider =
  Context.Provider as Provider<ApiVersionLinkContextValue>;

export function useApiVersionLinkContext(): ApiVersionLinkContextValue {
  const context = useContext(Context);

  if (context === null) {
    throw new Error(
      `useApiVersionLinkContext must be used within a <ApiVersionLinkContextProvider>`
    );
  }

  return context;
}
