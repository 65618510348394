import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class LegalNoticeParseResult<T extends EMode = EMode.entity> {
  /** Alias: name */
  name?: string;
  /** Alias: firma1 */
  firm1?: string;
  /** Alias: firma2 */
  firm2?: string;
  /** Alias: firma3 */
  firm3?: string;
  /** Alias: strasse */
  street?: string;
  /** Alias: staat */
  country?: string;
  /** Alias: ort */
  city?: string;
  /** Alias: uSTIDNR */
  vatId?: string;
  /** Alias: eMail */
  eMail?: string;
  /** Alias: interNet */
  website?: string;
  /** Alias: plz */
  postalCode?: string;
  /** Alias: telefon */
  phoneNumber?: string;
  /** Alias: telefax */
  faxNumber?: string;

  __typename?: string;
}

const fields: FieldDefinitions<LegalNoticeParseResult> = {
  name: {
    alias: 'name',
  },
  firm1: {
    alias: 'firma1',
  },
  firm2: {
    alias: 'firma2',
  },
  firm3: {
    alias: 'firma3',
  },
  street: {
    alias: 'strasse',
  },
  country: {
    alias: 'staat',
  },
  city: {
    alias: 'ort',
  },
  vatId: {
    alias: 'uSTIDNR',
  },
  eMail: {
    alias: 'eMail',
  },
  website: {
    alias: 'interNet',
  },
  postalCode: {
    alias: 'plz',
  },
  phoneNumber: {
    alias: 'telefon',
  },
  faxNumber: {
    alias: 'telefax',
  },
  __typename: {
    alias: '__typename',
  },
};

export const legalNoticeParseResultEntityDefinition: EntitiyDefinition<LegalNoticeParseResult> =
  {
    local: {},
    remote: {
      queryName: 'getBusinesspartnerFromLegalNotice',
      fragmentName: 'LegalNoticeParseResult',
      withPaginationWrapper: false,
      params: [{ name: 'legalNotice', type: 'String!' }],
    },
    fieldDefinitions: fields,
  };
