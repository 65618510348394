import React from 'react';

import { StackIndexContext } from '../stack-index-context/StackIndexContext';

export const StackIndexProvider: React.FC<{ index: number }> = (props) => {
  return (
    <StackIndexContext.Provider value={props.index}>
      {props.children}
    </StackIndexContext.Provider>
  );
};
