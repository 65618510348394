import styles from './GeneralTabPanel.module.scss';

import clsx from 'clsx';

import { Panel } from '../../../../../components/panel/Panel';

import { AppointmentView } from './components/appointmentview/AppointmentView';

export const GeneralTabPanel = () => {
  return (
    <Panel className={clsx(styles.panel)}>
      <AppointmentView />
    </Panel>
  );
};
