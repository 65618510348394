import { AddressConnection } from '@work4all/models/lib/Classes/AddressConnection.entity';
import { BusinessPartner } from '@work4all/models/lib/Classes/BusinessPartner.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';

export const BUSINESS_PARTNER_DATA_FIELD:
  | Customer<EMode.query>
  | Supplier<EMode.query> = {
  id: null,
  name: null,
  website: null,
};

export const BUSINESS_PARTNER_FIELDS: BusinessPartner<EMode.query> = {
  id: null,
  data: {
    customer: BUSINESS_PARTNER_DATA_FIELD,
    supplier: BUSINESS_PARTNER_DATA_FIELD,
  },
};

export const ADDRESS_CONNECTION_FIELDS: AddressConnection<EMode.query> = {
  id: null,
  sdObjMemberIdA: null,
  sdObjMemberIdB: null,
  sdObjTypeA: null,
  sdObjTypeB: null,
  businessPartnerA: BUSINESS_PARTNER_FIELDS,
  businessPartnerB: BUSINESS_PARTNER_FIELDS,
  connection: {
    id: null,
    name: null,
  },
};
