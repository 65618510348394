import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { DefaultErpTextType } from '../Enums/DefaultErpTextType.enum';
import { EMode } from '../Enums/EMode.enum';

export class InputDefaultErpTextAddModify<T extends EMode = EMode.entity> {
  /** Alias: text */
  text?: string;
  /** Alias: textType */
  textType?: DefaultErpTextType;

  __typename?: string;
}

const fields: FieldDefinitions<InputDefaultErpTextAddModify> = {
  text: {
    alias: 'text',
  },
  textType: {
    alias: 'textType',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputDefaultErpTextAddModifyEntityDefinition: EntitiyDefinition<InputDefaultErpTextAddModify> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputDefaultErpTextAddModify',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
