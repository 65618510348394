import React from 'react';

import { IStepProps } from '../../types';
import { TurnOffUserStyles } from '../turn-off-user-styles/TurnOffUserStyles';

export const Step = React.memo(
  React.forwardRef<HTMLDivElement, IStepProps>(function Step(props, ref) {
    if (!props.active || !props.children) {
      return null;
    }

    return (
      <TurnOffUserStyles
        ref={ref}
        className={props.className}
        {...props.noUserStyles}
      >
        {props.children}
      </TurnOffUserStyles>
    );
  })
);
