import { useEffect, useState } from 'react';

import { useResizeObserver } from '@work4all/components/lib/hooks/use-resize-observer';

export function useElementSize(element: HTMLElement): {
  width: number;
  height: number;
} | null {
  const [size, setSize] = useState<{
    width: number;
    height: number;
  } | null>(null);

  function measureElement() {
    if (!element) {
      setSize(null);
      return;
    }

    const width = element.clientWidth;
    const height = element.clientHeight;

    setSize({ width, height });
  }

  useResizeObserver(element, measureElement);
  useEffect(measureElement, [element]);

  return size;
}
