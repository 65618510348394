import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class VaultSettingItem<T extends EMode = EMode.entity> {
  /** Alias: name */
  name?: string;
  /** Alias: user */
  user?: string;
  /** Alias: value */
  value?: string;

  __typename?: string;
}

const fields: FieldDefinitions<VaultSettingItem> = {
  name: {
    alias: 'name',
  },
  user: {
    alias: 'user',
  },
  value: {
    alias: 'value',
  },
  __typename: {
    alias: '__typename',
  },
};

export const vaultSettingItemEntityDefinition: EntitiyDefinition<VaultSettingItem> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'VaultSettingItem',
      withPaginationWrapper: false,
      mutations: {
        upsert: {
          mutationName: 'setSetting',
          args: [{ name: 'input', type: 'InputSetting!' }],
        },
      },
    },
    fieldDefinitions: fields,
  };
