import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as OptionsIcon } from '@work4all/assets/icons/burger.svg';

import { Base } from '../base/Base';
import { BaseActionProps } from '../types';

export const Options: React.FC<BaseActionProps> = (props) => {
  const { t } = useTranslation();

  return <Base title={t('MASK.OPTIONS')} icon={<OptionsIcon />} {...props} />;
};
