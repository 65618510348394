import styles from './EntityHistory.module.scss';

import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  Divider,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDataProvider } from '@work4all/data';
import { usePermissions } from '@work4all/data/lib/hooks/use-permissions';
import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { ObjectTypeByEntity } from '@work4all/models';
import { Event } from '@work4all/models/lib/Classes/Event.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { inlineMentions } from '../../../input/format-text/TextEditor/plugins/mention/utils';

import { CommentEditorWithAddButton } from './components/CommentEditorWithAddButton';
import { EventView } from './components/EventView';
import { NewCommentModal } from './components/NewCommentModal';
import {
  EntityHistoryContext,
  EntityHistoryContextValue,
} from './entity-history-context';
import { groupEventsByDate } from './group-events-by-date';
import { useAddCommentMutation } from './use-add-comment-mutation';

export interface EntityHistoryProps {
  entity: Entities;
  id: string | number;
  fitContainer?: boolean;
  className?: string;
  breadcrumbTitle?: string;
  fullWidth?: boolean;
  fullCommentEditor?: boolean;
  withTabs?: boolean;
  disableAddingComment?: boolean;
  children?: React.ReactElement;
  newComment?: string;
  onNewCommentChange?: (value: string) => void;
}
type tab = 'all' | 'comments' | 'events';

export function EntityHistory(props: EntityHistoryProps) {
  const {
    entity,
    id,
    fitContainer = true,
    breadcrumbTitle,
    fullWidth = false,
    fullCommentEditor = false,
    withTabs = false,
    disableAddingComment = false,
    children,
    newComment,
    onNewCommentChange,
  } = props;

  const { t } = useTranslation();
  const { untypedPermissions } = usePermissions();
  const canEditEntity = untypedPermissions(entity).canEdit(null);

  const requestData: DataRequest = useMemo(() => {
    const data: Event<EMode.query> = {
      createEvent: {
        id: null,
        creationDate: null,
        user: { id: null, displayName: null },
      },
      changeEvent: {
        id: null,
        creationDate: null,
        changes: [{ propertyName: null, newValue: null }],
        user: { id: null, displayName: null },
      },
      commentEvent: {
        id: null,
        creationDate: null,
        text: null,
        user: { id: null, displayName: null },
      },
    };

    return {
      entity: Entities.event,
      data,
      vars: {
        objectType: ObjectTypeByEntity[entity],
        objectId: String(id),
      },
    };
  }, [entity, id]);

  const skipIfNoId = !id;
  const events = useDataProvider<Event>(requestData, skipIfNoId);

  const [selectedTab, setSelectedTab] = useState<tab>('all');
  const { filteredEvents, numberOfEvents, numberOfComments } = useMemo(() => {
    // TODO: ask about that ChildItemCreated event WW-696
    const allEvents = events.data.filter(
      (event) => event.__typename !== 'ChildItemCreated'
    );
    const eventsList = allEvents.filter(
      (event) =>
        event.__typename === 'CreateEvent' || event.__typename === 'ChangeEvent'
    );
    const commentsList = allEvents.filter(
      (event) => event.__typename === 'CommentEvent'
    );

    const numberOfEvents = eventsList.length;
    const numberOfComments = commentsList.length;

    let filteredEvents;
    switch (selectedTab) {
      case 'all':
        filteredEvents = allEvents;
        break;
      case 'events':
        filteredEvents = eventsList;
        break;
      case 'comments':
        filteredEvents = commentsList;
        break;
    }

    return { filteredEvents, numberOfEvents, numberOfComments };
  }, [events.data, selectedTab]);

  const eventsGroupedByDate = useMemo(
    () => groupEventsByDate(filteredEvents, t),
    [t, filteredEvents]
  );

  const context = useMemo<EntityHistoryContextValue>(
    () => ({ entity }),
    [entity]
  );

  const [addComment] = useAddCommentMutation({
    onCompleted() {
      events.refetch();
      onNewCommentChange('');
    },
  });

  const handleAddComment = (comment: string) => {
    addComment({
      variables: {
        objectType: ObjectTypeByEntity[entity],
        codeOrId: String(id),
        comment: inlineMentions(comment),
      },
    });
  };

  const [newCommentModalOpen, setNewCommentModalOpen] = useState(false);

  const isDownMd = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  const canAddOrInsertImage = entity === Entities.ticket;

  return (
    <EntityHistoryContext.Provider value={context}>
      <NewCommentModal
        breadcrumbTitle={breadcrumbTitle}
        open={newCommentModalOpen}
        onSubmit={(comment) => {
          handleAddComment(comment);
          setNewCommentModalOpen(false);
        }}
        onClose={() => {
          setNewCommentModalOpen(false);
        }}
        canAddOrInsertImage={canAddOrInsertImage}
        newComment={newComment}
        onNewCommentChange={onNewCommentChange}
      />

      {fullCommentEditor && (
        <div className={styles.editor}>
          <CommentEditorWithAddButton
            onSubmit={() => {
              handleAddComment(newComment);
            }}
            disabled={disableAddingComment || !canEditEntity}
            value={newComment}
            setValue={onNewCommentChange}
            canAddOrInsertImage={canAddOrInsertImage}
            editorConf={{
              height: remToPx(isDownMd ? 1.5 : 8),
              heightMin: remToPx(isDownMd ? 1.5 : 8),
              heightMax: remToPx(isDownMd ? 8 : 16),
            }}
            hideToolbar={isDownMd}
          />
        </div>
      )}

      {withTabs && (
        <Tabs value={selectedTab} onChange={(e, v) => setSelectedTab(v)}>
          <Tab value="all" label={t('COMMON.ALL')} />
          <Tab
            value="comments"
            label={`${t('COMMON.COMMENTS')} (${numberOfComments})`}
          />
          <Tab
            value="events"
            label={`${t('COMMON.EVENTS')} (${numberOfEvents})`}
          />
        </Tabs>
      )}

      <div
        className={clsx(styles.root, props.className, {
          [styles.fullWidth]: fullWidth,
        })}
      >
        <div
          className={clsx(styles.container, {
            [styles.fitContainer]: fitContainer,
          })}
        >
          {children}
          {events.loading ? (
            <div className={styles.loading}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className={styles.header}>
                <Typography variant="h4" sx={{ py: '1rem' }}>
                  {t('MASK.PROGRESS')}
                </Typography>

                {!fullCommentEditor && (
                  <Button
                    size="large"
                    color="primary"
                    disabled={!canEditEntity}
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setNewCommentModalOpen(true);
                    }}
                  >
                    {t('MASK.ADD_COMMENT')}
                  </Button>
                )}
              </div>

              <div className={styles.events}>
                {events.data.length > 0 ? (
                  eventsGroupedByDate.map(({ label, events }, i) => (
                    <React.Fragment key={`${label}-${i}`}>
                      <Divider>{label}</Divider>
                      {events.map((event) => (
                        <EventView key={event.id} event={event} />
                      ))}
                    </React.Fragment>
                  ))
                ) : (
                  <Typography variant="caption">
                    {t('COMMON.NO_DATA')}
                  </Typography>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </EntityHistoryContext.Provider>
  );
}
