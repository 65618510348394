import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class SumValues<T extends EMode = EMode.entity> {
  /** Alias: valueGross */
  valueGross?: number;
  /** Alias: valueNet */
  valueNet?: number;
  /** Alias: valueVat */
  valueVat?: number;

  __typename?: string;
}

const fields: FieldDefinitions<SumValues> = {
  valueGross: {
    alias: 'valueGross',
  },
  valueNet: {
    alias: 'valueNet',
  },
  valueVat: {
    alias: 'valueVat',
  },
  __typename: {
    alias: '__typename',
  },
};

export const sumValuesEntityDefinition: EntitiyDefinition<SumValues> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'SumValues',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
