import styles from './MailboxSettingsSidebar.module.scss';

import CloseIcon from '@mui/icons-material/Close';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Box,
  Checkbox,
  Collapse as MuiCollapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { createContext, Fragment, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import {
  IStackItem,
  useHistoryStack,
} from '@work4all/components/lib/navigation/history-stack';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { settings, useSetting } from '../../../settings';

import { listStyleOverrides } from './list-style-overrides';
import {
  MailboxFolderTreeItem,
  makeFolderTree,
  ROOT_PARENT_ID,
} from './make-folder-tree';
import { ExtendedMailbox } from './types';

export interface MailboxSettingsSidebarProps {
  mailbox: ExtendedMailbox;
  open: boolean;
  onClose: () => void;
}

export function MailboxSettingsSidebar(props: MailboxSettingsSidebarProps) {
  const { open, onClose, mailbox } = props;

  const context = useMemo(() => {
    return { mailbox };
  }, [mailbox]);

  return (
    <Context.Provider value={context}>
      <NavigationOverlay
        classes={{ wrapper: styles.wrapper }}
        open={open}
        close={onClose}
        initialView={VIEW}
        withBreadcrumbs={false}
      />
    </Context.Provider>
  );
}

const VIEW: IStackItem = { view: <Content /> };

const Context = createContext<{ mailbox: ExtendedMailbox }>(null);

function Content() {
  const props = useContext(Context);

  const { t } = useTranslation();

  const { mailbox } = props;

  const rootFolders = useMemo(() => {
    return makeFolderTree(mailbox?.folder ?? []);
  }, [mailbox]);

  const stack = useHistoryStack();

  const [collapsedFolders, setCollapsedFolders] = useState<
    Record<string, boolean>
  >({});

  const setting = useSetting(settings.mailboxFolderVisibility());

  const visibility = setting.value[mailbox.id];

  const setVisibility = (visibility: Record<string, boolean>) => {
    setting.set({ ...setting.value, [mailbox.id]: visibility });
  };

  const onFolderVisibilityChange = (folder: MailboxFolderTreeItem) => {
    const isVisible = folder.folder.isVisible;

    const newVisibility = {
      ...visibility,
      [folder.folder.id]: !isVisible,
    };

    // Clear visibility state of all subfolders when the parent is toggled.
    const subfolders = collectSubfolders(folder);

    for (const subfolder of subfolders) {
      delete newVisibility[subfolder.folder.id];
    }

    setVisibility(newVisibility);
  };

  const renderFolders = (
    folders: MailboxFolderTreeItem[],
    parentId: string,
    forceHidden = false
  ) => {
    const isOpen = !collapsedFolders[parentId];

    return (
      <MuiCollapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{ pl: '1rem' }}>
          {folders.map((folder) => {
            const folderId = folder.folder.id;

            const isVisible = folder.folder.isVisible && !forceHidden;

            const isOpen = !collapsedFolders[folderId];
            const hasChildren = folder.children != null;

            const onToggleOpen = () => {
              setCollapsedFolders((collapsed) => ({
                ...collapsed,
                [folder.folder.id]: !collapsed[folder.folder.id],
              }));
            };

            const handleToggleVisibility = () => {
              onFolderVisibilityChange(folder);
            };

            return (
              <Fragment key={folderId}>
                <ListItem
                  secondaryAction={
                    hasChildren ? (
                      <IconButton size="small" onClick={onToggleOpen}>
                        <ExpandMore
                          sx={{
                            transition: 'transform 0.1s',
                            transform: isOpen ? null : 'rotate(-90deg)',
                          }}
                        />
                      </IconButton>
                    ) : null
                  }
                  disablePadding
                  sx={listStyleOverrides.listItem}
                >
                  <ListItemButton
                    disabled={forceHidden}
                    role={undefined}
                    onClick={handleToggleVisibility}
                    sx={listStyleOverrides.listItemButton}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        disabled={forceHidden}
                        checked={isVisible}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText primary={folder.folder.name} />
                  </ListItemButton>
                </ListItem>

                {folder.children &&
                  renderFolders(
                    folder.children,
                    folderId,
                    forceHidden || !isVisible
                  )}
              </Fragment>
            );
          })}
        </List>
      </MuiCollapse>
    );
  };

  return (
    <Stack direction="column">
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <IconButton onClick={() => stack.close()}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h4" sx={{ my: '1rem' }}>
          {t('COMMON.MAILBOX')} | {mailbox.mailboxPrimaryAddress}
        </Typography>
      </Box>

      <Divider />

      <Box sx={{ flex: 1, overflow: 'auto' }}>
        <Collapse
          title={t('ASSIGN_INCOMING_EMAILS.SHOW_FOLDERS')}
          defaultOpen={true}
        >
          {renderFolders(rootFolders, ROOT_PARENT_ID)}
        </Collapse>
      </Box>
    </Stack>
  );
}

function collectSubfolders(
  folder: MailboxFolderTreeItem
): MailboxFolderTreeItem[] {
  const subfolders: MailboxFolderTreeItem[] = [];

  collectSubfoldersRecursive(folder);

  return subfolders;

  function collectSubfoldersRecursive(folder: MailboxFolderTreeItem): void {
    if (folder.children) {
      for (const child of folder.children) {
        subfolders.push(child);
        collectSubfoldersRecursive(child);
      }
    }
  }
}
