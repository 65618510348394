import { Mailbox } from '@work4all/models/lib/Classes/Mailbox.entity';
import { MailboxFolder } from '@work4all/models/lib/Classes/MailboxFolder.entity';
import { MailboxFolderType } from '@work4all/models/lib/Enums/MailboxFolderType.enum';

import type { MailboxFolderVisibility } from '../../../settings/settings';

import { ExtendedMailbox, ExtendedMailboxFolder } from './types';

/**
 * Extends the given mailboxes based on the provided visibility settings.
 *
 * Adds `isVisible` property to all folders in every mailbox.
 *
 * @param mailboxes the list of mailboxes to extend.
 * @param visibility the visibility settings for the mailboxes.
 * @return the extended list of mailboxes with updated folders.
 */
export function extendMailboxFolders(
  mailboxes: Mailbox[],
  visibility: MailboxFolderVisibility
): ExtendedMailbox[] {
  return mailboxes.map<ExtendedMailbox>((mailbox) => {
    if (mailbox.folder == null) {
      return mailbox as ExtendedMailbox;
    }

    return {
      ...mailbox,
      folder: mailbox.folder.map(extendMailboxFolder).filter(filterFolderTypes),
    };

    // Add `isVisible` property to the folder based on the visibility settings
    // and default visibility rules.
    function extendMailboxFolder(folder: MailboxFolder): ExtendedMailboxFolder {
      const settingValue = visibility[mailbox.id]?.[folder.id];
      const defaultValue = inferDefaultVisibilityValue(folder);

      return {
        ...folder,
        isVisible: settingValue ?? defaultValue,
      };
    }

    // Remove Drafts and Outbox folder types from the list.
    function filterFolderTypes(folder: ExtendedMailboxFolder): boolean {
      switch (folder.folderType) {
        case MailboxFolderType.DRAFTS:
        case MailboxFolderType.OUTBOX:
          return false;
        default:
          return true;
      }
    }
  });
}
function inferDefaultVisibilityValue(folder: MailboxFolder): boolean {
  // Only show Inbox and Sent Items by default.
  switch (folder.folderType) {
    case MailboxFolderType.INBOX:
    case MailboxFolderType.SENT_ITEMS:
      return true;
    default:
      return false;
  }
}
