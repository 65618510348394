import styles from './styles.module.scss';

import { Link } from '@mui/material';
import Linkify from 'linkify-react';
import { FC, MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { Body1 } from '@work4all/components/lib/typography/body1/Body1';

type INoteCardProps = {
  note: string;
  maxCharCount?: number;
  isExtended?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

const renderLink = ({ attributes, content }) => {
  const { href, ...props } = attributes;
  return (
    <a target="blank" href={href} {...props}>
      {content}
    </a>
  );
};

export const NoteCard: FC<INoteCardProps> = ({
  note,
  maxCharCount = note?.length,
  isExtended,
  onClick,
}) => {
  const { t } = useTranslation();
  const [extended, setExtended] = useState<boolean>(isExtended ?? false);

  return (
    <>
      <Divider title={t('COMMON.NOTE')} size="body" />
      <div onClick={onClick}>
        <Body1 className={styles.preLine}>
          <Linkify
            as="div"
            options={{
              render: renderLink,
            }}
          >
            {extended
              ? note?.trim()?.length > 0
                ? note
                : '-'
              : `${note?.substring(0, maxCharCount)}${
                  note?.length > maxCharCount ? '...' : ''
                }`}
          </Linkify>
        </Body1>
        {note?.length > maxCharCount && (
          <Link
            className={styles['link']}
            onClick={() => setExtended((prev) => !prev)}
            underline="none"
          >
            {t(extended ? 'COMMON.SHOW_LESS' : 'COMMON.SHOW_MORE')}
          </Link>
        )}
      </div>
    </>
  );
};
