import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import {
  isBusinessPartner,
  isContact,
  isCustomer,
} from './cells/contact-or-business-partner';
import { MailboxContentItem } from './mailbox-content-manager/types';

export function createTicketFromMail(mail: MailboxContentItem): Ticket {
  const ticket: Ticket = {};

  ticket.title = mail.data.title ?? '';
  ticket.problemDescription = mail.data.body ?? '';
  ticket.followUpDate = mail.data.date ?? null;

  const contact = mail.contact;
  const project = mail.project;

  if (isBusinessPartner(contact)) {
    assignBusinessPartner(ticket, contact);
  } else if (isContact(contact)) {
    assignBusinessPartner(ticket, contact.businessPartner?.data);
    assignContact(ticket, contact);
  }

  assignProject(ticket, project);

  return ticket;
}

function assignBusinessPartner(
  ticket: Ticket,
  businessPartner: Customer | Supplier
) {
  if (!businessPartner) {
    return;
  }

  const businessPartnerId = businessPartner.id;
  const businessPartnerType = isCustomer(businessPartner)
    ? SdObjType.KUNDE
    : SdObjType.LIEFERANT;

  ticket.businessPartner = {
    data: businessPartner,
    id: businessPartnerId,
    businessPartnerType: businessPartnerType,
  };
  ticket.businessPartnerId = businessPartnerId;
  ticket.businessPartnerType = businessPartnerType;
}

function assignContact(ticket: Ticket, contact: Contact) {
  if (!contact) {
    return;
  }

  ticket.contact = contact;
  ticket.contactId = contact.id;
}

function assignProject(ticket: Ticket, project: Project) {
  if (!project) {
    return;
  }

  ticket.project = project;
  ticket.projectId = project.id;
}
