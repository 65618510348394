import styles from './styles.module.scss';

export type IDialogContentProps = {
  children: React.ReactNode;
};

export function DialogContent(props: IDialogContentProps) {
  const { children } = props;

  return <div className={styles['dialog-content']}>{children}</div>;
}
