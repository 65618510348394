import styles from './NumberRangePicker.module.scss';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LabeledInput } from '../../../../input/labeled-input';
import { MultiStepControls, Step } from '../../../../input/multi-step-controls';

interface INumberRangePickerProps {
  from?: number;
  to?: number;
  onChange: (from: number, to: number) => void;
}

export const NumberRangePicker: React.FC<INumberRangePickerProps> = (props) => {
  const { t } = useTranslation();
  const { from = null, to = null, onChange } = props;

  const [fromValue, setFromValue] = useState<number>(null);
  const [toValue, setToValue] = useState<number>(null);

  useEffect(() => {
    setFromValue(from);
    setToValue(to);
  }, [from, to]);

  return (
    <div className={styles.pickerWrap}>
      <MultiStepControls>
        <Step active={true} index={0}>
          <LabeledInput
            value={fromValue}
            label={t('COMMON.FROM')}
            type="number"
            onChange={(e) => {
              setFromValue(parseFloat(e.target.value));
              onChange(parseFloat(e.target.value), toValue);
            }}
            onClear={() => {
              setFromValue(null);
              onChange(null, toValue);
            }}
          />
        </Step>
        <Step active={true} index={1}>
          <LabeledInput
            value={toValue}
            label={t('COMMON.TO')}
            type="number"
            onChange={(e) => {
              setToValue(parseFloat(e.target.value));
              onChange(fromValue, parseFloat(e.target.value));
            }}
            onClear={() => {
              setToValue(null);
              onChange(fromValue, null);
            }}
          />
        </Step>
      </MultiStepControls>
    </div>
  );
};
