import { without } from 'lodash';
import { useCallback } from 'react';

import { categoryType } from '../containers/time-tracker/TimeTemplatesWidget';
import {
  settings,
  TimeTrackSetting,
  TimeTrackTemplate,
  useSetting,
} from '../settings';

/**
 * List of entities recently used as time tracking templates
 * with corresponding category settings.
 */
export function useTrackHistory() {
  const { set: setTemplates, value: lastTimeTemplates } = useSetting(
    settings.timeTrackHistory()
  );
  const { set: setCategoriesSettings, value: categoriesSettings } = useSetting(
    settings.timeTrackSettings()
  );

  const saveTimeTemplate = useCallback(
    (data: TimeTrackTemplate) => {
      //check if this template is already known to the last history, if so just get it to the top most pos
      const prevTemplate = lastTimeTemplates.find((el) => {
        return (
          el.subtitle1 === data.subtitle1 &&
          el.subtitle2 === data.subtitle2 &&
          el.title === data.title &&
          JSON.stringify(el.toApply) === JSON.stringify(data.toApply)
        );
      });
      let cleanedLastTime = lastTimeTemplates;
      if (prevTemplate) {
        cleanedLastTime = without(lastTimeTemplates, prevTemplate);
      }

      //never save more last used than the max amount to show
      setTemplates(
        [data, ...cleanedLastTime].slice(0, categoriesSettings.last.count)
      );
    },
    [categoriesSettings.last.count, lastTimeTemplates, setTemplates]
  );

  const saveCategorySettings = useCallback(
    (type: categoryType, data: TimeTrackSetting) => {
      setCategoriesSettings({
        ...categoriesSettings,
        [type]: data,
      });
    },
    [categoriesSettings, setCategoriesSettings]
  );

  return {
    lastTimeTemplates,
    saveTimeTemplate,
    categoriesSettings,
    saveCategorySettings,
  };
}
