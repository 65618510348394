import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CropSquareIcon } from '@work4all/assets/icons/detach.svg';
import { ReactComponent as MailIcon } from '@work4all/assets/icons/mail.svg';
import { ReactComponent as PhoneIcon } from '@work4all/assets/icons/phone2.svg';

import { Collapse } from '@work4all/components/lib/components/collapse';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';

import { useValidateEmail } from '../../../../../../../../../../hooks';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { LabeledInput } from '../../../../../../../../locked-inputs';
import { ControlWrapper } from '../../../../../../../components/control-wrapper';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { BPMaskFormValue } from '../../../../../types';

export const Communication: React.FC = () => {
  const { t } = useTranslation();
  const { register, watch } = useFormContextPlus<BPMaskFormValue>();
  const validateEmail = useValidateEmail();

  const mask = useMaskContext<Supplier | Customer>();
  const isPrivateCustomer = watch('isPrivateCustomer');

  const data = mask.data;
  if (!data) return <div></div>;

  return (
    <Collapse defaultOpen={true} title={t('MASK.COMMUNICATION')}>
      <ControlWrapper>
        <LabeledInput
          label={t('INPUTS.EMAIL')}
          iconRight={
            <a href={'mailto:' + data.eMail}>
              <MailIcon />
            </a>
          }
          {...register('eMail')}
          onBlur={(e) => {
            if (e.target.value.length > 0) validateEmail(e.target.value);
          }}
        />
        <LabeledInput
          label={t('INPUTS.WEB')}
          iconRight={
            <a
              href={'https://' + data?.website}
              target="_blank"
              rel="noreferrer"
            >
              <CropSquareIcon />
            </a>
          }
          {...register('website')}
        />
        <LabeledInput
          label={t('INPUTS.PHONE')}
          iconRight={
            <a href={'tel:' + data?.phoneNumber}>
              <PhoneIcon />
            </a>
          }
          {...register('phoneNumber')}
        />
        <LabeledInput label={t('INPUTS.FAX')} {...register('faxNumber')} />
      </ControlWrapper>
      {isPrivateCustomer && (
        <ControlWrapper columns={4}>
          <LabeledInput
            label={t('INPUTS.FURTHER_EMAILS')}
            iconRight={
              <a href={'mailto:' + data?.eMail2}>
                <MailIcon />
              </a>
            }
            {...register('eMail2')}
          />
          <LabeledInput
            label={t('INPUTS.MOBILE')}
            iconRight={
              <a href={'tel:' + data?.mobileNumber}>
                <PhoneIcon />
              </a>
            }
            {...register('mobileNumber')}
          />
          <LabeledInput
            label={t('INPUTS.FURTHER_PHONE')}
            iconRight={
              <a href={'tel:' + data?.phoneNumber2}>
                <PhoneIcon />
              </a>
            }
            {...register('phoneNumber2')}
          />
        </ControlWrapper>
      )}
    </Collapse>
  );
};
