import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ConvertIcon } from '../../../../../../assets/icons/convert.svg';
import { IconButtonWithTooltip } from '../IconButtonWithTooltip';
import { BaseActionProps } from '../types';

export const Convert: React.FC<BaseActionProps> = (props) => {
  const { t } = useTranslation();
  return (
    <IconButtonWithTooltip
      tooltip={t('MASK.CONVERT')}
      icon={<ConvertIcon />}
      {...props}
    />
  );
};
