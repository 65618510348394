import styles from './ProjectTimeSettings.module.scss';

import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  EventType,
  sendAmplitudeData,
} from '@work4all/components/lib/utils/amplitude/amplitude';

import { useUser } from '@work4all/data';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { NavigationOverlayHeader } from '../../../../../../components/navigation-overlay-header/NavigationOverlayHeader';
import { NoAdminRightsBanderole } from '../../../../components/banderoles/NoAdminRightsBanderole';
import {
  MaskTab,
  MaskTabContext,
  MaskTabPanel,
  MaskTabs,
} from '../../../../mask-tabs';
import { MaskContent } from '../../../components/MaskContent/MaskContent';
import {
  MaskContextProvider,
  useMaskContextValue,
} from '../../../hooks/mask-context';

import { BehaviourSection } from './components/behaviour-section/BehaviourSection';
import { CostsSection } from './components/costs-section/CostsSection';
import { DeductionSection } from './components/deduction-section/DeductionSection';
import { FieldsSection } from './components/fields-section/FieldsSection';

type ProjectTimeSettingsProps = {
  amplitudeEntryPoint: string;
};

export function ProjectTimeSettings(props: ProjectTimeSettingsProps) {
  const { amplitudeEntryPoint } = props;

  const { t } = useTranslation();
  const user = useUser();

  useEffect(() => {
    sendAmplitudeData(EventType.OpenProjectTimeSettings, {
      entryPoint: amplitudeEntryPoint,
    });
  }, [amplitudeEntryPoint]);

  // It make not much sense in case it's not entity, but <MaskContent/> requires to have MaskContext
  // This is settings of timeTracking
  const maskContext = useMaskContextValue({
    wip: false,
    entity: Entities.timeTracking,
    mode: 'edit',
    isCreateMode: false,
    isEditMode: true,
    params: {},
  });

  return (
    <MaskContextProvider value={maskContext}>
      <MaskTabContext defaultValue="general">
        <NavigationOverlayHeader
          title={t('SETTINGS.SETTINGS')}
          subTitle={t('SETTINGS.PROJECT_TIME')}
          breadcrumbsChildren={<div></div>}
          tabs={<SettingsTabs />}
        />
        {!user.isMaster && <NoAdminRightsBanderole />}
        <Content />
      </MaskTabContext>
    </MaskContextProvider>
  );
}

const SettingsTabs = memo(function Tabs() {
  const { t } = useTranslation();

  return (
    <MaskTabs>
      <MaskTab value="personal" label={t('COMMON.PERSONAL')}></MaskTab>
      <MaskTab value="general" label={t('COMMON.GENERAL')} />
    </MaskTabs>
  );
});

const Content = memo(function TabPanels() {
  return (
    <div className={styles.wrap}>
      <MaskContent>
        <MaskTabPanel value="personal">
          <BehaviourSection />
        </MaskTabPanel>
        <MaskTabPanel value="general">
          <FieldsSection />
          <DeductionSection />
          <CostsSection />
        </MaskTabPanel>
      </MaskContent>
    </div>
  );
});
