export type IPageTitlePart = string | false | null | undefined;

/**
 * A utility function to make it easier to compose page titles that depend
 * on some data. It will ignore all `null`, `undefined` and `false`
 * values, and join all the remaining parts with " - ".
 *
 * @example
 * formatPageTitle(["Customer", "One"]); // "Customer - One"
 * formatPageTitle(["Customer", false && "One"]); // "Customer"
 * formatPageTitle(["Customer", null]); // "Customer"
 * formatPageTitle(["Customer", null]); // "Customer"
 * formatPageTitle([]); // ""
 */
export function formatPageTitle(parts: IPageTitlePart[]): string {
  const result = parts
    .filter((part) => part !== false && part !== null && part !== undefined)
    .join(' - ');

  return result;
}
