import styles from './Body1.module.scss';

import { Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { ITypographyProps } from '..';

export interface IBody1Props extends ITypographyProps {
  textLink?: boolean;
}

export const Body1: React.FC<IBody1Props> = (props) => {
  return (
    <Typography
      className={clsx(
        props.textLink ? styles['body1-text-link'] : styles['body1'],
        props.className
      )}
      variant="body1"
      {...props}
    >
      {props.children}
    </Typography>
  );
};
