import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { LicenseNotAssignedReason } from '../Enums/LicenseNotAssignedReason.enum';
import { ModulName } from '../Enums/ModulName.enum';

export class UserLicenseInformationItem<T extends EMode = EMode.entity> {
  /** Alias: modulName */
  modulName?: ModulName;
  /** Alias: assigned */
  assigned?: boolean;
  /** Alias: denyReason */
  denyReason?: LicenseNotAssignedReason;

  __typename?: string;
}

const fields: FieldDefinitions<UserLicenseInformationItem> = {
  modulName: {
    alias: 'modulName',
  },
  assigned: {
    alias: 'assigned',
  },
  denyReason: {
    alias: 'denyReason',
  },
  __typename: {
    alias: '__typename',
  },
};

export const userLicenseInformationItemEntityDefinition: EntitiyDefinition<UserLicenseInformationItem> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'UserLicenseInformationItem',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
