import { MouseEventHandler, useMemo } from 'react';

import { useUser } from '@work4all/data';

import { Document } from '@work4all/models/lib/Classes/Document.entity';
import { FileEntity } from '@work4all/models/lib/Classes/FileEntity.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { canEditDocument } from '@work4all/utils/lib/permissions';

import { PDFTextmarkConf } from '../../../preview/pdf-textmarks/PDFTextmarkContainer';
import { MIME_TYPES } from '../../../preview/Preview';
import {
  FileAndThumbnail,
  FileListPreview,
  getDocumentFileList,
} from '../FileListPreview';
import { useEntityPreviewData } from '../hooks/use-entity-preview-data';

import { DocumentPreview } from './DocumentPreview';

export interface IDocumentPreviewContainerProps
  extends Omit<DocumentPreviewContainerInnerProps, 'document'> {
  documentId: number;
}

const FileInfoFragment: FileEntity<EMode.query> = {
  previewUrl: null,
  downloadUrl: null,
  fileRequestType: null,
  previewMimeType: null,
  downloadMimeType: null,
  fileServiceProviderInfos: {
    id: null,
    exists: null,
    fileName: null,
    mimeType: null,
    fspUrl: null,
  },

  // As the FileInfo type doesn't have an ID field, we need to also request
  // properties that are likely to be fetched by other components (the table)
  // so the Apollo cache is not invalidated.
  fileEntityFilename: null,
};

const DocumentFileFragment: Document<EMode.query> = {
  thumbnail: {
    id: null,
    filename: null,
    fileInfos: {
      ...FileInfoFragment,
    },
  },
  fileInfos: {
    ...FileInfoFragment,
  },
};

const requestedDocumentData: Document<EMode.query> = {
  id: null,
  filePath: null,
  note: null,
  userId: null,
  ...DocumentFileFragment,
  childDocuments: [
    {
      id: null,
      ...DocumentFileFragment,
    },
  ],
};

export function DocumentPreviewContainer(
  props: IDocumentPreviewContainerProps
) {
  const {
    documentId,
    onCloseClicked,
    onEditClicked,
    onShareClicked,
    onEditPdfClicked,
  } = props;

  const documents = useEntityPreviewData<Document>(
    [documentId],
    Entities.document,
    requestedDocumentData
  );

  const doc = documents?.[0];

  if (!doc) return null;

  return (
    <DocumentPreviewContainerInner
      document={doc}
      onCloseClicked={onCloseClicked}
      onEditClicked={onEditClicked}
      onShareClicked={onShareClicked}
      onEditPdfClicked={onEditPdfClicked}
    />
  );
}

interface DocumentPreviewContainerInnerProps {
  document: Document;
  onCloseClicked?: MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onEditPdfClicked?: MouseEventHandler<HTMLButtonElement>;
  onShareClicked?: () => void;
  pdfTextmarkConf?: PDFTextmarkConf;
}

function DocumentPreviewContainerInner(
  props: DocumentPreviewContainerInnerProps
) {
  const {
    document: doc,
    onCloseClicked,
    onEditClicked,
    onEditPdfClicked,
    onShareClicked,
    pdfTextmarkConf,
  } = props;

  const user = useUser();

  const files = useMemo<FileAndThumbnail[]>(() => {
    return getDocumentFileList(doc);
  }, [doc]);

  const isLinkable =
    typeof doc.filePath === 'string' &&
    ['www', 'http', 'https'].some((prefix) => doc.filePath.startsWith(prefix));

  // If a document is created from a link, use the old preview. Otherwise use
  // the new preview component that only works file lists of files.
  if (isLinkable) {
    return (
      <DocumentPreview
        title={doc.note}
        exists={doc.fileInfos?.fileServiceProviderInfos?.exists}
        url={doc.fileInfos?.previewUrl}
        mimeType={doc.fileInfos?.previewMimeType as MIME_TYPES}
        noPreviewUrl={
          doc.fileInfos?.fileServiceProviderInfos?.fspUrl ||
          doc.fileInfos?.downloadUrl
        }
        downloadUrl={doc.fileInfos?.downloadUrl}
        filePath={doc.filePath}
        onCloseClicked={onCloseClicked}
        onEditClicked={onEditClicked}
        onPdfEditClicked={onEditPdfClicked}
        fspUrl={doc.fileInfos?.fileServiceProviderInfos?.fspUrl}
        iconProps={{
          showPreviewExternallyIcon: canEditDocument(user, doc),
        }}
        onShareClicked={onShareClicked}
        pdfTextmarkConf={pdfTextmarkConf}
      />
    );
  }

  return (
    <FileListPreview
      title={doc.note}
      files={files}
      iconProps={{
        showPreviewExternallyIcon: canEditDocument(user, doc),
      }}
      onCloseClicked={onCloseClicked}
      onEditClicked={onEditClicked}
      onShareClicked={onShareClicked}
      onPdfEditClicked={onEditPdfClicked}
    />
  );
}
