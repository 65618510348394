import { useEffect } from 'react';

import { useMaskContext } from './mask-context';

/**
 * Override default scroll behaviour on mask content from inner element
 */
export function useMaskScroll(scroll: boolean) {
  const { setUseScroll } = useMaskContext();
  useEffect(() => {
    setUseScroll(scroll);
  }, [scroll, setUseScroll]);
}
