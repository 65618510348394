import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class TicketChecklisteMark<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: lookupCode */
  lookupId?: number;
  /** Alias: datum */
  date?: string;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: ticketId */
  ticketId?: string;

  __typename?: string;
}

const fields: FieldDefinitions<TicketChecklisteMark> = {
  id: {
    alias: 'code',
  },
  lookupId: {
    alias: 'lookupCode',
  },
  date: {
    alias: 'datum',
  },
  userId: {
    alias: 'benutzerCode',
  },
  ticketId: {
    alias: 'ticketId',
  },
  __typename: {
    alias: '__typename',
  },
};

export const ticketChecklisteMarkEntityDefinition: EntitiyDefinition<TicketChecklisteMark> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'TicketChecklisteMark',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
