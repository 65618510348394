import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class InputProjectAccessGroupProjectRelation<
  T extends EMode = EMode.entity
> {
  /** Alias: add */
  add?: number[];
  /** Alias: remove */
  remove?: number[];
  /** Alias: setAll */
  setAll?: boolean;
  /** Alias: unsetAll */
  unsetAll?: boolean;

  __typename?: string;
}

const fields: FieldDefinitions<InputProjectAccessGroupProjectRelation> = {
  add: {
    alias: 'add',
  },
  remove: {
    alias: 'remove',
  },
  setAll: {
    alias: 'setAll',
  },
  unsetAll: {
    alias: 'unsetAll',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputProjectAccessGroupProjectRelationEntityDefinition: EntitiyDefinition<InputProjectAccessGroupProjectRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputProjectAccessGroupProjectRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
