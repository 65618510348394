import { useCallback } from 'react';

import { components } from '../../apiModels';
import { httpClient } from '../http-client';
import { UploadFileParams } from '../interfaces';

import { useTenant } from './routing/TenantProvider';
import { useUser } from './useUser';

export function useUploadTempFile() {
  const user = useUser();
  const { activeTenant } = useTenant();

  const { baseUrl, token } = user ?? {};

  return useCallback(
    async function uploadTempFile(
      file: File
    ): Promise<components['schemas']['UploadResult']> {
      if (baseUrl === undefined) {
        throw new Error();
      }

      const formData = new FormData();
      formData.append('myFile', file, file.name);

      const response = await httpClient.post<
        components['schemas']['UploadResult'],
        FormData,
        UploadFileParams
      >({
        url: `${baseUrl}/api/file`,
        params: {
          type: 'TempDatei',
        },
        body: formData,
        headers: {
          Authorization: `bearer ${token}`,
          mandantenCode: String(activeTenant),
          'x-work4all-apiurl': baseUrl,
        },
      });

      return response.data;
    },
    [baseUrl, token, activeTenant]
  );
}
