import { EMail } from '@work4all/models/lib/Classes/EMail.entity';
import { JobState } from '@work4all/models/lib/Enums/JobState.enum';

import { LockOverride } from '../../../../../components/LockOverride';
import { Panel } from '../../../../../components/panel/Panel';
import { useMaskContext } from '../../../../../hooks/mask-context';
import { EmailView } from '../../../EmailView';

export const GeneralTabPanel = () => {
  const mask = useMaskContext<EMail>();
  const { data } = mask;
  const isEmailBeingSent =
    data.saveSendMailJob?.jobState === JobState.UNSTARTED;

  return (
    <Panel>
      <LockOverride forceLock={isEmailBeingSent}>
        <EmailView />
      </LockOverride>
    </Panel>
  );
};
