import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  ContractServiceContractPicker,
  IContractServiceContractPickerProps,
} from '@work4all/components/lib/components/entity-picker/contract-service-contract-picker/ContractServiceContractPicker';

import { Contract } from '@work4all/models/lib/Classes/Contract.entity';
import { ServiceContract } from '@work4all/models/lib/Classes/ServiceContract.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { reactRefSetter } from '@work4all/utils';

import { LabeledInput } from '../../containers/mask-overlays/locked-inputs';

import { EntityPickerActions } from './EntityPickerActions';

interface ContractServiceContractPickerFieldProps
  extends Omit<
    IContractServiceContractPickerProps<false>,
    'multiple' | 'onChange'
  > {
  error?: string;
  label?: string;
  onChange: (value: Contract | ServiceContract, entity: Entities) => void;
}

export const ContractServiceContractPickerField = React.forwardRef<
  HTMLDivElement,
  ContractServiceContractPickerFieldProps
>(function ContractServiceContractPickerField(props, ref) {
  const { error, ...pickerProps } = props;
  const { value, onChange, prefilter } = pickerProps;

  const { t } = useTranslation();
  const [entity, setEntity] = useState(() => {
    return value ? getEntity(value) : Entities.contract;
  });

  const wrapperRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<EntityPickerPopover>(null);

  const inputValue = value?.note ?? '';

  const label = value
    ? getEntity(value) === Entities.contract
      ? t('COMMON.CONTRACT')
      : t('COMMON.SERVICECONTRACT')
    : t('COMMON.CONTRACT_ABO_CONTRACT');

  return (
    <div ref={reactRefSetter(wrapperRef, ref)}>
      <EntityPickerPopover
        ref={popoverRef}
        anchorEl={wrapperRef}
        picker={
          <ContractServiceContractPicker
            {...pickerProps}
            value={value}
            multiple={false}
            onTabChange={setEntity}
            onChange={(value) => {
              onChange(value, entity);
            }}
          />
        }
        footer={
          <EntityPickerActions
            entity={entity}
            multiple={false}
            onChange={(value) => {
              onChange(value, entity);
              popoverRef.current?.close();
            }}
            prefilter={prefilter}
          ></EntityPickerActions>
        }
      >
        <LabeledInput
          value={inputValue}
          label={label}
          error={error}
          onClear={() => {
            onChange(null, entity);
          }}
        />
      </EntityPickerPopover>
    </div>
  );
});

function getEntity(value: Contract | ServiceContract): Entities {
  if (value.__typename === 'Auftrag') {
    return Entities.contract;
  }

  return Entities.serviceContract;
}
