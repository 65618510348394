import React from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  IPartialInvoiceCalculationLogicPickerProps,
  PartialInvoiceCalculationLogicPicker,
} from '@work4all/components/lib/components/entity-picker/partial-invoice-calculation-logic-picker/PartialInvoiceCalculationLogicPicker';

import { reactRefSetter } from '@work4all/utils';

import { LabeledInput } from '../../containers/mask-overlays/locked-inputs';

interface Props
  extends Omit<IPartialInvoiceCalculationLogicPickerProps<false>, 'multiple'> {
  error?: string;
}

export const PartialInvoiceCalculationLogicPickerField = React.forwardRef<
  HTMLDivElement,
  Props
>((props, ref) => {
  const { t } = useTranslation();
  const partialInvoiceCalculationLogicWrapperRef = useRef();
  const { error, ...pickerProps } = props;
  const { value, onChange } = pickerProps;

  return (
    <div ref={reactRefSetter(partialInvoiceCalculationLogicWrapperRef, ref)}>
      <EntityPickerPopover
        anchorEl={partialInvoiceCalculationLogicWrapperRef}
        picker={
          <PartialInvoiceCalculationLogicPicker
            {...pickerProps}
            multiple={false}
          />
        }
      >
        <LabeledInput
          onClear={() => {
            onChange(null);
          }}
          label={t('INPUTS.PARTIAL_INVOICE_CALCULATION_LOGIC')}
          error={error}
          value={value?.name || ''}
        />
      </EntityPickerPopover>
    </div>
  );
});
