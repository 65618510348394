import throttle from 'lodash.throttle';
import { useMemo } from 'react';

import { ChangeType } from '@work4all/models/lib/Enums/ChangeType.enum';

import { useUser } from '../..';

import {
  UseEntityChangedOptions,
  useEntityChanges,
} from './use-entity-changed';

interface UseRefetchOnEntityChangesOptions
  extends Pick<UseEntityChangedOptions, 'entity' | 'objectsType'> {
  refetch: () => void;
  skipIfCurrentUser?: boolean;
}

export const useRefetchOnEntityChanges = ({
  refetch,
  entity,
  objectsType,
  skipIfCurrentUser = false,
}: UseRefetchOnEntityChangesOptions) => {
  const user = useUser();
  const onEntityChanged = useMemo(() => throttle(refetch, 500), [refetch]);

  useEntityChanges({
    entity,
    changeType: [
      ChangeType.ITEM_INSERTED,
      ChangeType.ITEM_UPDATED,
      ChangeType.ITEM_DELETED,
    ],
    onEntityChanged: (info) => {
      if (!skipIfCurrentUser || user.benutzerCode !== info.changedBy) {
        onEntityChanged();
      }
    },
    objectsType,
  });
};
