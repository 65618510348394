import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { BzObjType } from '../Enums/BzObjType.enum';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { Contact } from './Contact.entity';
import { Supplier } from './Supplier.entity';

export class SupplierDemandAddign<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: sdObjMemberCode */
  sdObjMemberCode?: number;
  /** Alias: sdObjType */
  sdObjType?: SdObjType;
  /** Alias: bzObjMemberCode */
  bzObjMemberCode?: number;
  /** Alias: bzObjType */
  bzObjType?: BzObjType;
  /** Alias: contactCode */
  contactCode?: number;
  /** Alias: address */
  address?: string;
  /** Alias: supplier */
  supplier?: Supplier<T>;
  /** Alias: contact */
  contact?: Contact<T>;

  __typename?: string;
}

const fields: FieldDefinitions<SupplierDemandAddign> = {
  id: {
    alias: 'code',
  },
  sdObjMemberCode: {
    alias: 'sdObjMemberCode',
  },
  sdObjType: {
    alias: 'sdObjType',
  },
  bzObjMemberCode: {
    alias: 'bzObjMemberCode',
  },
  bzObjType: {
    alias: 'bzObjType',
  },
  contactCode: {
    alias: 'contactCode',
  },
  address: {
    alias: 'address',
  },
  supplier: {
    alias: 'supplier',
    entity: Entities.supplier,
  },
  contact: {
    alias: 'contact',
    entity: Entities.contact,
  },
  __typename: {
    alias: '__typename',
  },
};

export const supplierDemandAddignEntityDefinition: EntitiyDefinition<SupplierDemandAddign> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'SupplierDemandAssign',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
