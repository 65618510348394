import { ArrowDropDown } from '@mui/icons-material';
import { Box, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { BaseActionButton } from '../base-action-button/BaseActionButton';

interface SelectButtonValue {
  icon?: React.ReactNode;
  name?: string;
  id: number | string;
}

interface Props {
  label: string;
  value: SelectButtonValue;
  options: SelectButtonValue[];
  renderOption?: (value: SelectButtonValue) => React.ReactNode;
  onChange?: (value: SelectButtonValue) => void;
  buttonColor?: 'primary' | 'text01' | 'error' | 'textInverse';
  popOverState?: 'bottom' | 'top';
}

export const SelectButton = (props: Props) => {
  const {
    label,
    value,
    onChange,
    renderOption,
    options,
    buttonColor = 'text01',
    popOverState = 'bottom',
  } = props;

  const { handleClick, ...menuState } = usePopoverState(popOverState);

  const displayValue = (value: SelectButtonValue) =>
    renderOption ? (
      renderOption(value)
    ) : (
      <>
        {value.icon && (
          <ListItemIcon style={{ minWidth: 1, marginRight: '0.25rem' }}>
            {value.icon}
          </ListItemIcon>
        )}
        {value.name && <ListItemText primary={value.name} />}
      </>
    );

  return (
    <>
      <Box>
        <BaseActionButton
          color={buttonColor}
          onClick={handleClick}
          horizontalPadding="xs"
        >
          {value.icon && (
            <ListItemIcon style={{ minWidth: 1, marginRight: '0.25rem' }}>
              {value.icon}
            </ListItemIcon>
          )}
          <ListItemText primary={label} />
          <ArrowDropDown />
        </BaseActionButton>
      </Box>
      <Menu {...menuState}>
        {options.map((opt, idx) => (
          <MenuItem
            key={idx}
            onClick={() => {
              onChange(opt);
              menuState.onClose();
            }}
            selected={value.id === opt.id}
          >
            {displayValue(opt)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
