import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class InputNotificationKey<T extends EMode = EMode.entity> {
  /** Alias: notificationType */
  notificationType?: number;
  /** Alias: entityId */
  entityId?: any;

  __typename?: string;
}

const fields: FieldDefinitions<InputNotificationKey> = {
  notificationType: {
    alias: 'notificationType',
  },
  entityId: {
    alias: 'entityId',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputNotificationKeyEntityDefinition: EntitiyDefinition<InputNotificationKey> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputNotificationKey',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
