import { useRef } from 'react';

export interface FileButtonProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'children'> {
  children: (props: { onClick: React.MouseEventHandler }) => React.ReactNode;
}

export function FileButton(props: FileButtonProps) {
  const { children, ...inputProps } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const onClick = () => {
    inputRef.current?.click();
  };

  return (
    <>
      <input
        ref={inputRef}
        style={{ display: 'none' }}
        type="file"
        {...inputProps}
      />
      {children({ onClick })}
    </>
  );
}
