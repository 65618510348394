import { useCallback, useMemo } from 'react';

import { PDFTextmark } from '@work4all/components/lib/preview/pdf-textmarks/PDFTextmarkContainer';

import { useDataMutation, useDataProvider } from '@work4all/data';

import { WordDocumentTemplate } from '@work4all/models/lib/Classes/WordDocumentTemplate.entity';
import { WordLetterTemplate } from '@work4all/models/lib/Classes/WordLetterTemplate.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export interface DocumentTemplateSettings {
  pdfTextmarkItems: PDFTextmark[];
}

export function useDocumentTemplateSettings(
  id: number,
  entity: Entities.wordDocumentTemplate | Entities.wordLetterTemplate
) {
  const requestData = useMemo<DataRequest>(() => {
    const filter = [{ id: { $eq: id } }];

    return {
      entity: entity,
      data: FIELDS,
      filter,
    };
  }, [id, entity]);

  const { data, pending, refetch } = useDataProvider<
    WordLetterTemplate | WordDocumentTemplate
  >(requestData, !id);

  const settings: DocumentTemplateSettings = useMemo(
    () =>
      data?.[0]?.settings
        ? JSON.parse(data?.[0]?.settings)
        : { pdfTextmarkItems: [] },
    [data]
  );

  const [mutate] = useDataMutation({
    entity: entity,
    mutationType: EMode.upsert,
    responseData: { id: null, settings: null },
    resetStore: true,
  });

  const update = useCallback(
    async (settings: DocumentTemplateSettings) => {
      await mutate({ id, settings: JSON.stringify(settings) });
      refetch();
    },
    [id, mutate, refetch]
  );

  return { settings, pending, refetch, update };
}

const FIELDS: WordLetterTemplate<EMode.query> = {
  id: null,
  settings: null,
};
