import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class TextBuildingBlockGroup<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: index */
  index?: number;
  /** Alias: level */
  level?: number;
  /** Alias: name */
  name?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: insertTime */
  insertTime?: string;

  __typename?: string;
}

const fields: FieldDefinitions<TextBuildingBlockGroup> = {
  id: {
    alias: 'code',
  },
  index: {
    alias: 'index',
  },
  level: {
    alias: 'level',
  },
  name: {
    alias: 'name',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  __typename: {
    alias: '__typename',
  },
};

export const textBuildingBlockGroupEntityDefinition: EntitiyDefinition<TextBuildingBlockGroup> =
  {
    local: {},
    remote: {
      queryName: 'getTextbausteinGruppen',
      fragmentName: 'TextbausteinGruppe',
      withPaginationWrapper: true,
      params: [
        { name: 'querySize', type: 'Int' },
        { name: 'queryPage', type: 'Int' },
        { name: 'querySortOrder', type: 'SortOrderStyle' },
        { name: 'querySortBy', type: 'String' },
        { name: 'filter', type: 'String' },
      ],
    },
    fieldDefinitions: fields,
  };
