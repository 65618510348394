import React from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BaseDataLanguagePicker,
  IBaseDataLanguagePickerProps,
} from '@work4all/components/lib/components/entity-picker/base-data-language-picker/BaseDataLanguagePicker';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';

import { reactRefSetter } from '@work4all/utils';

import { LabeledInput } from '../../containers/mask-overlays/locked-inputs';

interface Props extends Omit<IBaseDataLanguagePickerProps<false>, 'multiple'> {
  error?: string;
}

export const BaseDataLanguagePickerField = React.forwardRef<
  HTMLDivElement,
  Props
>((props, ref) => {
  const { t } = useTranslation();
  const baseDataLanguageWrapperRef = useRef();
  const { error, ...pickerProps } = props;
  const { value, onChange } = pickerProps;

  return (
    <div ref={reactRefSetter(baseDataLanguageWrapperRef, ref)}>
      <EntityPickerPopover
        anchorEl={baseDataLanguageWrapperRef}
        picker={<BaseDataLanguagePicker {...pickerProps} multiple={false} />}
      >
        <LabeledInput
          onClear={() => {
            onChange(null);
          }}
          label={t('COMMON.LANGUAGE')}
          error={error}
          value={value?.name || ''}
        />
      </EntityPickerPopover>
    </div>
  );
});
