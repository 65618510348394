import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputUrlaub } from './InputUrlaub.entity';

export class InputVacationRequest<T extends EMode = EMode.entity> {
  /** Alias: vacationDays */
  vacationDays?: InputUrlaub<T>[];
  /** Alias: mailServiceId */
  mailServiceId?: string;

  __typename?: string;
}

const fields: FieldDefinitions<InputVacationRequest> = {
  vacationDays: {
    alias: 'vacationDays',
  },
  mailServiceId: {
    alias: 'mailServiceId',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputVacationRequestEntityDefinition: EntitiyDefinition<InputVacationRequest> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputVacationRequest',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
