import { gql, useQuery } from '@apollo/client';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { useMemo, useRef, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDataProvider } from '@work4all/data';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { reactRefSetter } from '@work4all/utils';

const CssTextField = styled(TextField)({
  '& .MuiFilledInput-root': {
    paddingTop: '1rem',
    paddingBottom: '0.25rem !important',
    borderRadius: '0.25rem',
    paddingLeft: '0.25rem',
  },
  '& .MuiFilledInput-root:before, .MuiFilledInput-root:after': {
    display: 'none',
  },
  '& .MuiInputLabel-root': {
    transform: 'translate(7px, 11px) scale(1)',
  },
  '& .Mui-focused.MuiInputLabel-root, .MuiFormLabel-filled.MuiInputLabel-root':
    {
      transform: 'translate(7px, 4px) scale(0.75)',
      fontSize: '0.85rem',
      fontWeight: '500',
    },
});

const getEmailVariants = (
  firstName: string,
  lastName: string,
  domain: string
) => {
  const fn = firstName?.toLowerCase() || '';
  const ln = lastName?.toLowerCase() || '';
  const result = [];
  if (fn) {
    result.push(fn + '@' + domain);
  }
  if (fn && ln) {
    result.push(fn?.charAt(0) + '.' + ln + '@' + domain);
    result.push(fn + '.' + ln + '@' + domain);
    result.push(fn?.charAt(0) + '.' + ln?.charAt(0) + '@' + domain);
    result.push(fn?.charAt(0) + ln + '@' + domain);
  }

  return result;
};

export interface EMailSuggestionPickerFieldProps {
  value: string | null;
  businesspartnerType?: SdObjType;
  onChange: (value: string) => void;
  businessPartnerId?: number;
  firstName: string;
  lastName: string;
}

export const EMailSuggestionPickerField = React.forwardRef<
  HTMLDivElement,
  EMailSuggestionPickerFieldProps
>(function EMailSuggestionPickerField(props, ref) {
  const {
    value,
    onChange,
    businesspartnerType,
    businessPartnerId,
    firstName: firstname,
    lastName: lastname,
  } = props;

  const { t } = useTranslation();

  const [focused, setFocused] = useState(false);

  const { options } = useEMailSuggestions({
    businesspartnerType,
    businessPartnerId,
    firstname,
    lastname,
    skip: !focused,
  });

  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={reactRefSetter(wrapperRef, ref)}>
      <Autocomplete
        freeSolo
        ListboxProps={{ style: { maxHeight: '14rem' } }}
        getOptionLabel={(option) => option as string}
        options={options}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onInputChange={(e, value) => {
          onChange(value);
        }}
        inputValue={value || ''}
        defaultValue={value}
        renderInput={(params) => {
          return (
            <CssTextField
              {...params}
              label={t('COMMON.EMAIL')}
              variant="filled"
              size="small"
              onFocus={() => setFocused(true)}
            />
          );
        }}
        onChange={(e, value) => {
          onChange(value as string);
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} style={{ textAlign: 'left' }}>
              <div>{option}</div>
            </li>
          );
        }}
      />
    </div>
  );
});

const GET_EMAIL_SUGGESTIONS = gql`
  query suggestContactEMailAddress(
    $businesspartnerType: SdObjType
    $businessPartnerId: Int
    $firstname: String
    $lastname: String
  ) {
    suggestContactEMailAddress(
      sdObjType: $businesspartnerType
      sdObjMemberCode: $businessPartnerId
      firstname: $firstname
      lastname: $lastname
    )
  }
`;

export function useEMailSuggestions(variables: {
  businesspartnerType?: SdObjType;
  businessPartnerId?: number;
  firstname?: string;
  lastname?: string;
  skip?: boolean;
}): { hasDistinctEmailSuggestion: boolean; options } {
  const {
    firstname,
    lastname,
    businesspartnerType: sdObjType,
    businessPartnerId: sdObjMemberCode,
    skip,
  } = variables;

  const request = useMemo(() => {
    const data: Customer | Supplier = {
      eMail: null,
      contactList: [
        {
          eMail: null,
        },
      ],
    };
    return {
      entity:
        sdObjType === SdObjType.KUNDE ? Entities.customer : Entities.supplier,
      data,
      filter: [{ id: { $eq: sdObjMemberCode } }],
    };
  }, [sdObjMemberCode, sdObjType]);

  const {
    data: [businessPartner],
  } = useDataProvider<Customer | Supplier>(request, skip);

  const apiGeneratedSuggestion = useQuery(GET_EMAIL_SUGGESTIONS, {
    variables,
    skip: skip || !businessPartner?.contactList?.find((x) => x.eMail !== null),
  });

  const options: string[] = useMemo(() => {
    const contactList = businessPartner?.contactList;

    if (contactList) {
      if (!contactList?.find((x) => Boolean(x.eMail) === true)) {
        if (businessPartner?.eMail) {
          const domain = businessPartner?.eMail?.split('@')?.[1];
          if (!domain) {
            return [];
          }
          return getEmailVariants(firstname, lastname, domain);
        }
      }
    }

    return apiGeneratedSuggestion?.data?.suggestContactEMailAddress
      ? [apiGeneratedSuggestion?.data?.suggestContactEMailAddress]
      : [];
  }, [
    firstname,
    lastname,
    businessPartner,
    apiGeneratedSuggestion?.data?.suggestContactEMailAddress,
  ]);

  return { options, hasDistinctEmailSuggestion: options.length === 1 };
}
