import styles from './styles.module.scss';

import { Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function WorkInProgressBanderole() {
  const { t } = useTranslation();

  return (
    <Paper className={styles.root}>
      <Typography className={styles.body2}>{t('MASK.WIP_WARNING')}</Typography>
    </Paper>
  );
}
