import styles from './../../MaskOverlay.module.scss';

import { DateTime } from 'luxon';
import { memo, useEffect, useMemo, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useLock } from '@work4all/components/lib/hooks/object-lock';

import { IUser, useUser } from '@work4all/data';
import { useCustomFieldsConfig } from '@work4all/data/lib/custom-fields';
import { useDataMutation } from '@work4all/data/lib/hooks/data-provider/useDataMutation';
import {
  TempFileManagerContext,
  useTempFileManager,
} from '@work4all/data/lib/hooks/data-provider/useTempFileManager';
import { useSearchHistory } from '@work4all/data/lib/hooks/use-search-history';
import { useEntityJsonSchema } from '@work4all/data/lib/json-schema/EntityJsonSchemasContext';

import { W4ADateFormat } from '@work4all/models/lib/additionalEnums/DateFormat.enum';
import { CallMemo } from '@work4all/models/lib/Classes/CallMemo.entity';
import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { CRMTypes } from '@work4all/models/lib/Classes/CRMTypes.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { InputTopicMarkRelation } from '@work4all/models/lib/Classes/InputTopicMarkRelation.entity';
import { Note } from '@work4all/models/lib/Classes/Note.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Task } from '@work4all/models/lib/Classes/Task.entity';
import { TopicSimple } from '@work4all/models/lib/Classes/TopicSimple.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';
import { TaskStatus } from '@work4all/models/lib/Enums/TaskStatus.enum';
import { InputCrmAnhangAttachementsRelation } from '@work4all/models/lib/InputTypes/InputCrmAnhangAttachementsRelation';

import { useJSONSchemaResolver } from '@work4all/utils';
import {
  canAddCrmEntity,
  canDeleteCrmEntity,
  canEditCrmEntity,
} from '@work4all/utils/lib/permissions';

import { usePageTitle } from '../../../../../hooks';
import useAttachementsRelation from '../../../../../hooks/useAttachementsRelation';
import { formatPageTitle } from '../../../../../utils/format-page-title';
import {
  MaskTab,
  MaskTabContext,
  MaskTabPanel,
  MaskTabs,
} from '../../../mask-tabs';
import { CUSTOM_FIELDS_DATA } from '../../components/custom-fields/custom-fields-data';
import { IndividualTabPanel } from '../../components/custom-fields/IndividualTabPanel';
import { normalizeCustomFields } from '../../components/custom-fields/normalize-custom-fields';
import { prepareInputWithCustomFields } from '../../components/custom-fields/prepare-input-with-custom-fields';
import { Form } from '../../components/form';
import { MaskContent } from '../../components/MaskContent/MaskContent';
import { MaskOverlayDeleteMenuItem } from '../../components/MaskOverlayDeleteMenuItem';
import { MaskOverlayFullscreenToggleButton } from '../../components/MaskOverlayFullscreenToggleButton';
import { MaskOverlayHeader } from '../../components/MaskOverlayHeader/MaskOverlayHeader';
import { MaskOverlayMenuWrapper } from '../../components/MaskOverlayMenuWrapper';
import { MaskOverlaySubmitButton } from '../../components/MaskOverlaySubmitButton';
import { HistoryTabPanel } from '../../components/tab-panels/history/HistoryTabPanel';
import {
  MaskContextProvider,
  useMaskConfig,
  useMaskContextValue,
} from '../../hooks/mask-context';
import { useConfirmBeforeCloseMask } from '../../hooks/use-confrm-before-close-mask';
import { EntityRightsContext } from '../../hooks/use-entity-rights';
import { useStandardDeleteEntityHandler } from '../../hooks/use-standard-delete-entity-handler';
import { normalizeFormValue } from '../../hooks/useExtendedFormContext';
import { useInitialFormValue } from '../../hooks/useInitialFormValue';
import { MaskControllerProps } from '../../types';
import { pickUpdateFields } from '../../utils/pick-update-fields';
import {
  formatTopicMarkName,
  titleWithTopicMark,
} from '../../utils/titleWithTopicMark';
import {
  AssignableEntityResult,
  useAssignableTemplateEntity,
} from '../../utils/use-assignable-template-entity';
import { useFormUpdate } from '../../utils/use-form-update';

import { AttachmentsTabPanel } from './components/tab-panels/attachments/AttachmentsTabPanel';
import { GeneralTabPanel } from './components/tab-panels/general/GeneralTabPanel';
import { CrmMaskFormValue } from './types';

type CRMTypesIntersection = Omit<
  Note<EMode.query> | Task<EMode.query> | CallMemo<EMode.query>,
  'status'
>;

export const CRMOverlayController = (props: MaskControllerProps) => {
  const { t } = useTranslation();

  const mask = useMaskConfig(props);

  const customFields = useCustomFieldsConfig({ entity: mask.entity });

  const requestData = useMemo(() => {
    // we will always filter by id and will always try to normalize the data
    // so an id field will be available
    const filter = [{ id: { $eq: mask.id } }];
    let entity = Entities.task;

    let data: CRMTypes<EMode.query> = {};
    const commonFields: CRMTypesIntersection = {
      id: null,
      title: null,
      note: null,
      date: null,
      attachmentList: [
        {
          note: null,
          id: null,
          fileName: null,
          fileInfos: {
            fileSize: null,
            fileEntityFilename: null,
            previewUrl: null,
            downloadUrl: null,
            previewMimeType: null,
            downloadMimeType: null,
          },
          date: null,
          userId: null,
          user: {
            id: null,
            displayName: null,
          },
        },
      ],
      user: {
        id: null,
        displayName: null,
      },
      creatorUserId: null,
      topicMarkList: [
        {
          name: null,
          id: null,
        },
      ],
      businessPartnerId: null,
      businessPartnerType: null,
      businessPartner: {
        id: null,
        data: {
          customer: {
            name: null,
            id: null,
            number: null,
            mainContactId: null,
            website: null,
            contactList: [
              {
                id: null,
                displayName: null,
                role: null,
                layedOff: null,
                salutation: {
                  id: null,
                  isMale: null,
                  isFemale: null,
                },
              },
            ],
          },
          supplier: {
            name: null,
            id: null,
            mainContactId: null,
            number: null,
            website: null,
            contactList: [
              {
                id: null,
                displayName: null,
                role: null,
                layedOff: null,
                salutation: {
                  id: null,
                  isMale: null,
                  isFemale: null,
                },
              },
            ],
          },
        },
      },
      contact: {
        name: null,
        firstName: null,
        displayName: null,
        id: null,
        businessPartnerType: null,
        businessPartnerId: null,
        eMail: null,
      },
      project: {
        name: null,
        id: null,
        number: null,
      },
      projectId: null,
      projectProcessId: null,
      projectProcess: {
        process: null,
        id: null,
      },
      customFieldList: [CUSTOM_FIELDS_DATA],
    };

    // TODO dirty mapping between enums, this needs to be consolidated

    if (mask.entity === Entities.task) {
      entity = Entities.task;
      const taskData: Task<EMode.query> = {
        ...commonFields,
        endDate: null,
        reminderDate: null,
        status: null,
        priority: null,
        timeRequired: null,
      } as Task<EMode.query>;

      data = taskData as unknown as CRMTypes<EMode.query>;
    }
    if (mask.entity === Entities.callMemo) {
      entity = Entities.callMemo;
      const callMemoData: CallMemo<EMode.query> = {
        ...commonFields,
        contactId: null,
      } as CallMemo<EMode.query>;

      data = callMemoData as unknown as CRMTypes<EMode.query>;
    }
    if (mask.entity === Entities.note) {
      entity = Entities.note;
      // wait until business partner union type works correctly on backend side
      const noteData: Note<EMode.query> = {
        ...commonFields,
        contactId: null,
      } as Note<EMode.query>;
      data = noteData as unknown as CRMTypes<EMode.query>;
    }
    return {
      filter,
      entity,
      data,
    };
  }, [mask.entity, mask.id]);

  // TODO Similar changes must be made to the queries for "parent" or "template"
  // entities. Right now they use "live" queries that will get updated after
  // a mutation.
  //
  // Maybe there is a better way to implement this. What we need is fetch the
  // data once, re-fetch if the query changes, and be able to manually refresh
  // the query after a form submit. But ignore all updates caused by changes
  // to Apollo's cache.
  const initialFormValue = useInitialFormValue<Note | Task | CallMemo>(
    requestData,
    mask.isCreateMode
  );

  const template = useAssignableTemplateEntity(mask);

  const user = useUser();

  const { presetFields } = props.params ?? {};
  const newEntityData = useMemo(() => {
    const userPartial = {
      benutzerCode: user.benutzerCode,
      displayName: user.displayName,
    };
    const data = createCRMDefaultData({
      template,
      entity: mask.entity,
      user: userPartial,
      presetFieldsJSON: presetFields,
    });

    return data;
  }, [
    user.benutzerCode,
    user.displayName,
    template,
    mask.entity,
    presetFields,
  ]);

  const dataRaw = mask.isCreateMode
    ? newEntityData
    : initialFormValue.value ?? newEntityData;

  const data = useMemo(() => {
    return normalizeCustomFields(
      normalizeFormValue(dataRaw),
      customFields
    ) as typeof dataRaw;
  }, [dataRaw, customFields]);

  const schema = useEntityJsonSchema(mask.entity);
  const resolver = useJSONSchemaResolver(schema);

  const form = useForm<CrmMaskFormValue>({
    resolver,
    mode: 'onChange',
    defaultValues: data,
    shouldFocusError: false,
    context: {
      schema,
    },
  });

  const { handleSubmit, reset, formState, watch, getValues, getFieldState } =
    form;

  const { lock, unlock } = useLock({
    subEntityType: mask.entity,
    subEntityIds: data.id ? [data.id.toString()] : [],
  });

  useEffect(() => {
    lock();
    return () => {
      unlock();
    };
  }, [data]);

  useEffect(() => {
    reset(data);
  }, [reset, data]);

  const tempFileManager = useTempFileManager(data?.attachmentList);
  const { fileListDirty: attachmentListDirty } = tempFileManager;

  const isDirty = formState.isDirty || attachmentListDirty;

  useConfirmBeforeCloseMask(isDirty);

  const { saveSearchItemFromEnityData } = useSearchHistory();
  const [mutate] = useDataMutation<
    CRMTypes,
    EMode.upsert,
    {
      attachements: InputCrmAnhangAttachementsRelation;
    }
  >({
    entity: mask.entity,
    mutationType: EMode.upsert,
    responseData: requestData.data as unknown as CRMTypes<EMode.entity>,
    onCompleted: (data) => {
      if (mask.isCreateMode) {
        saveSearchItemFromEnityData(data);
      }

      props.onAfterSave(data);
    },
  });

  const usedTopicMark = useRef<string>();
  useEffect(() => {
    usedTopicMark.current = data?.topicMarkList?.[0]
      ? formatTopicMarkName(data.topicMarkList[0].name)
      : undefined;
  }, [data]);

  useFormUpdate(
    {
      'businessPartner.data': (businessPartner: Customer | Supplier) => {
        const businessPartnerId = businessPartner?.id ?? 0;
        const businessPartnerType = getBusinessPartnerType(businessPartner);
        const contact = businessPartner?.mainContact ?? getValues('contact');

        return {
          businessPartnerId,
          businessPartnerType,
          contact,
        };
      },
      contact: (contact: Contact) => {
        const contactId = contact?.id ?? 0;

        return { contactId };
      },
      project: (project: Project) => {
        const projectId = project?.id ?? 0;
        const businessPartnerField = getValues('businessPartner.data');

        if (businessPartnerField !== null) {
          return { projectId };
        } else {
          const businessPartner =
            project?.customer ?? project?.supplier ?? null;

          return {
            projectId,
            'businessPartner.data': businessPartner,
          };
        }
      },
      projectProcess: (projectProcess: ProjectProcess) => {
        const projectProcessId = projectProcess?.id ?? 0;

        return { projectProcessId };
      },
      user: (user: User) => {
        const userId = user?.id ?? 0;

        return { userId: userId };
      },
      topicMarkList: (topicMarkList: TopicSimple[]) => {
        const { title, topicMark } = titleWithTopicMark(
          getValues('title'),
          topicMarkList,
          usedTopicMark.current,
          !getFieldState('topicMarkList').isDirty
        );

        usedTopicMark.current = topicMark;

        if (title) return { title };
      },
      date: (date: string) => {
        const todaysDate = Date.now();
        const endDate = getValues('endDate');
        if (
          Date.parse(date) > todaysDate &&
          mask.entity === Entities.task &&
          (!endDate || Date.parse(endDate) < Date.parse(date))
        ) {
          return {
            endDate: date,
          };
        }
      },
      endDate: (endDate: string) => {
        const startDate = getValues('date');

        if (
          Date.parse(endDate) < Date.parse(startDate) &&
          mask.entity === Entities.task &&
          endDate
        ) {
          return {
            date: endDate,
          };
        }
      },
    },
    form
  );

  const entityName = t(`COMMON.${mask.entity.toUpperCase()}`);

  usePageTitle(formatPageTitle([entityName, data?.title]), {
    priority: 1,
  });

  const handleDeleteEntitiesClick = useStandardDeleteEntityHandler(props);

  const currentTopicMarkList = watch('topicMarkList');

  const attachementsRelation =
    useAttachementsRelation<InputCrmAnhangAttachementsRelation>(
      tempFileManager,
      Entities.inputCrmAnhangAttachementModify,
      'code'
    );

  const relations = useMemo(() => {
    const topic: InputTopicMarkRelation = {
      set: currentTopicMarkList ? currentTopicMarkList[0]?.id : 0,
    };

    const result =
      attachementsRelation || currentTopicMarkList
        ? { ...attachementsRelation, topic }
        : undefined;

    return result;
  }, [attachementsRelation, currentTopicMarkList]);

  const submitForm = async (data: CrmMaskFormValue) => {
    const updateRaw = mask.isCreateMode
      ? data
      : pickUpdateFields(data, formState.dirtyFields);

    const updateMapped = prepareInputWithCustomFields(updateRaw);

    await mutate(updateMapped, relations ? { relations } : undefined);
  };

  const entityRights = useMemo(
    () => ({
      create: canAddCrmEntity(user),
      read: false, //ToDo there is no real read right, rather a visibility right
      update: canEditCrmEntity(user, data),
      delete: canDeleteCrmEntity(user, data),
    }),
    [data, user]
  );

  const maskContext = useMaskContextValue({ ...mask, data, customFields });

  const noRights = mask.isEditMode
    ? !entityRights.update
    : !entityRights.create;

  const submitDisabled =
    noRights ||
    (mask.isEditMode &&
      (!formState.isValid || (!formState.isDirty && !attachmentListDirty)));

  const shouldRenderIndividualTab = customFields && customFields.length > 0;

  return (
    <MaskContextProvider value={maskContext}>
      <EntityRightsContext.Provider value={entityRights}>
        <FormProvider {...form}>
          <MaskTabContext defaultValue={mask.params?.tab ?? 'general'}>
            <Form
              className={styles.maskForm}
              onSubmit={handleSubmit(submitForm)}
            >
              <TempFileManagerContext.Provider value={tempFileManager}>
                <MaskOverlayHeader
                  title={entityName}
                  subTitle={data?.title}
                  actions={
                    <>
                      <MaskOverlaySubmitButton
                        disabled={submitDisabled}
                        disableReason={
                          noRights ? t('RIGHTS.MISSING') : undefined
                        }
                        loading={formState.isSubmitting}
                      />
                      <MaskOverlayFullscreenToggleButton />
                      {mask.isEditMode && (
                        <MaskOverlayMenuWrapper>
                          <MaskOverlayDeleteMenuItem
                            disabled={!entityRights.delete || mask.wip}
                            onClick={handleDeleteEntitiesClick}
                          />
                        </MaskOverlayMenuWrapper>
                      )}
                    </>
                  }
                  tabs={
                    <CrmTabs
                      isCreateMode={mask.isCreateMode}
                      renderIndividualTab={shouldRenderIndividualTab}
                    />
                  }
                />

                <Content renderIndividualTab={shouldRenderIndividualTab} />
              </TempFileManagerContext.Provider>
            </Form>
          </MaskTabContext>
        </FormProvider>
      </EntityRightsContext.Provider>
    </MaskContextProvider>
  );
};

const CrmTabs = memo(function CrmTabs({
  isCreateMode,
  renderIndividualTab,
}: {
  isCreateMode: boolean;
  renderIndividualTab: boolean;
}) {
  const { t } = useTranslation();

  return (
    <MaskTabs>
      <MaskTab value="general" label={t('MASK.GENERAL')}></MaskTab>
      <MaskTab value="attachments" label={t('MASK.ATTACHMENTS')}></MaskTab>
      <MaskTab
        value="history"
        label={t('MASK.HISTORY')}
        disabled={isCreateMode}
      ></MaskTab>
      {renderIndividualTab && (
        <MaskTab value="individual" label={t('MASK.INDIVIDUAL')} />
      )}
    </MaskTabs>
  );
});

const Content = memo(function CrmTabPanels({
  renderIndividualTab,
}: {
  renderIndividualTab: boolean;
}) {
  return (
    <MaskContent>
      <MaskTabPanel value="general">
        <GeneralTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value="attachments">
        <AttachmentsTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value="history">
        <HistoryTabPanel />
      </MaskTabPanel>

      {renderIndividualTab && (
        <MaskTabPanel value="individual">
          <IndividualTabPanel />
        </MaskTabPanel>
      )}
    </MaskContent>
  );
});

type ICreateCRMDefaultParams = {
  template: AssignableEntityResult;
  entity: Entities;
  user: Pick<IUser, 'displayName' | 'benutzerCode'>;
  presetFieldsJSON: string;
};

function createCRMDefaultData(
  params: ICreateCRMDefaultParams
): Note | Task | CallMemo {
  const { entity, template, user, presetFieldsJSON = '{}' } = params;

  const common: Note | Task | CallMemo = {
    attachmentList: [],
    title: '',
    note: '',
    date: DateTime.now().toFormat(W4ADateFormat.DEFAULT),
    user: {
      displayName: user.displayName,
      id: user.benutzerCode,
    },
    userId: user.benutzerCode,
    topicMarkList: null,
  };

  if (entity === Entities.task) {
    common.status = TaskStatus.OFFEN;
    (common as unknown as Task).priority = 1;
  }

  if (template.enabled) {
    if (!template.data) {
      return null;
    }

    const contact =
      template.data.contact || template.data.businessPartner?.data?.mainContact;
    const data: Note | Task | CallMemo = {
      ...common,
      businessPartner: {
        id: template.data.businessPartner?.data?.id ?? 0,
        data: template.data.businessPartner?.data
          ? { ...template.data.businessPartner?.data, mainContact: null }
          : null,
      },
      businessPartnerId: template.data.businessPartner?.data?.id ?? 0,
      businessPartnerType:
        template.data.businessPartnerType ?? SdObjType.LIEFERANT,
      contact: contact,
      contactId: contact?.id ?? 0,
      project: template.data.project,
      projectId: template.data.project?.id ?? 0,
      projectProcess: template.data.projectProcess,
      projectProcessId: template.data.projectProcess?.id ?? 0,
      topicMarkList: template.data.topicMarkList,
    };

    return data;
  }

  const presetFields = JSON.parse(presetFieldsJSON);

  return {
    ...common,
    businessPartner: null,
    businessPartnerId: 0,
    businessPartnerType: SdObjType.LIEFERANT,
    contact: null,
    contactId: 0,
    project: null,
    projectId: 0,
    ...presetFields,
  };
}

function getBusinessPartnerType(
  businessPartner: Customer | Supplier
): SdObjType {
  if (businessPartner) {
    type GraphQLEntity = { __typename: string };

    const typename = (businessPartner as GraphQLEntity).__typename;

    if (typename === 'Kunde') {
      return SdObjType.KUNDE;
    }
  }

  return SdObjType.LIEFERANT;
}
