import styles from './SimpleEntityWidget.module.scss';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { usePrepareFilters } from '@work4all/components';
import { CountIndicator } from '@work4all/components/lib/dataDisplay/count-indicator/CountIndicator';
import { Body1 } from '@work4all/components/lib/typography/body1/Body1';

import { Link, useDataProvider } from '@work4all/data';

import { DataRequest } from '@work4all/models/lib/DataProvider';

import { stringifyTableSettings } from '../../../../components/data-tables/make-table-settings';

import { widgetDataByEntity } from './default-entity-widget-data';
import { HomeWidget } from './HomeWidgetsContextProvider';

export const SimpleEntityWidget = (
  props: HomeWidget & {
    onDeleteClick?: () => void;
    onEditClick?: () => void;
  }
) => {
  const { t } = useTranslation();

  const settings = stringifyTableSettings({
    filter:
      props.definition?.filters?.map((filter) => {
        return {
          ...filter,
          value: filter?.value?.value,
        };
      }) || [],
  });

  const preparedFilters = usePrepareFilters(props?.definition?.filters);

  const requestData = useMemo<DataRequest>(() => {
    return {
      filter: preparedFilters.filter,
      entity: props?.definition?.entity,
      data: widgetDataByEntity[props.definition.entity],
    };
  }, [preparedFilters, props?.definition?.entity]);

  const { total } = useDataProvider(requestData, false, 1);

  return (
    <div>
      <Link
        to={`/more/entity/${props?.definition?.entity}?settings=${settings}`}
        className={styles.link}
      >
        <div style={{ width: '100%' }}>
          <Body1>
            {props.title ||
              t(`COMMON.${props?.definition?.entity.toUpperCase()}_plural`)}
          </Body1>
        </div>
        <div>
          <CountIndicator value={total} variant="secondary" />
        </div>
      </Link>
    </div>
  );
};
