import { useCallback, useMemo, useState } from 'react';

import { AttachmentRenameContextValue } from '@work4all/components/lib/components/entity-preview/FileListPreview/attachment-rename-context';

export function useAttachmentRenameContextState({
  disabled,
}: {
  disabled: boolean;
}): AttachmentRenameContextValue {
  const [names, setNames] = useState<{ [key: string]: string }>({});

  const getName = useCallback(
    (id: string | number) => {
      if (disabled) return null;
      return names[id] ?? null;
    },
    [disabled, names]
  );

  const setName = useCallback(
    (id: string | number, name: string) => {
      if (disabled) return;
      setNames((prevNames) => ({ ...prevNames, [id]: name }));
    },
    [disabled]
  );

  const state = useMemo<AttachmentRenameContextValue>(
    () => ({
      disabled,
      getName,
      setName,
    }),
    [disabled, getName, setName]
  );

  return state;
}
