import styles from './Body2.module.scss';

import { Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { ITypographyProps } from '..';

export interface IBody2Props extends ITypographyProps {
  semiBold?: boolean;
  title?: string;
}

export const Body2: React.FC<IBody2Props> = (props) => {
  return (
    <Typography
      title={props.title}
      className={clsx(
        props.semiBold ? styles['body2-semi-bold'] : styles['body2'],
        props.className
      )}
      variant="body2"
      component={props.component}
      onClick={props.onClick}
    >
      {props.children}
    </Typography>
  );
};
