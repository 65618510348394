import Typography from '@mui/material/Typography';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { AppointmentState } from '@work4all/models/lib/Classes/AppointmentState.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type IAppointmentStatePickerProps<TMultiple extends boolean> =
  IPickerProps<AppointmentState, TMultiple>;

export function AppointmentStatePicker<TMultiple extends boolean>(
  props: IAppointmentStatePickerProps<TMultiple>
) {
  const { data, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...APPOINTMENTSTATE_FIELDS, ...data };
  }, [data]);

  const request = useMemo(() => {
    return {
      entity: Entities.appointmentState,
      completeDataResponse: true,
      data: {
        id: null,
        name: null,
      },
    };
  }, []);

  const response = useDataProvider<AppointmentState>(request);

  const dataset = useMemo(() => {
    return {
      total: response.data.length,
      loading: false,
      fetchMore: () => Promise.resolve(),
      refetch: () => Promise.resolve(null),
      data: response.data,
    };
  }, [response]);

  return (
    <ListEntityPicker
      entity={Entities.appointmentState}
      fixedDataSet={dataset}
      data={allFields}
      filterBy="name"
      sortBy="name"
      {...rest}
      renderItemContent={(appointmentState) => (
        <Tooltip title={appointmentState.name}>
          <Typography variant="body2" noWrap>
            {appointmentState.name}
          </Typography>
        </Tooltip>
      )}
    />
  );
}

const APPOINTMENTSTATE_FIELDS: AppointmentState = {
  id: null,
  name: null,
};
