import styles from './SalesOpportunitiesRatingStatusList.module.scss';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton } from '@mui/material';
import { noop } from 'lodash';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps, Column, TableInstance } from 'react-table';

import { useSalesOpportunityRatingStatus } from '@work4all/components/lib/components/entity-picker/sales-opportunities-rating-status-picker/components/use-sales-opportunity-rating-status';

import { useDataMutation, useDeleteEntity } from '@work4all/data';

import { SalesOpportunityRatingStatus } from '@work4all/models/lib/Classes/SalesOpportunityRatingStatus.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Collapse } from '../../../../components';
import { useMaskScroll } from '../../../../hooks/use-mask-scroll';
import { EditTable } from '../../../erp/components/tab-panels/positions/components/edit-table/EditTable';
import { useEditableState } from '../../../erp/components/tab-panels/positions/components/edit-table/hooks/use-editable-state';
import {
  IdType,
  OnEditPosition,
} from '../../../erp/components/tab-panels/positions/components/edit-table/types';

interface DeleteButtonCellProps
  extends CellProps<SalesOpportunityRatingStatus, string> {
  onDelete: (ids: IdType[]) => void;
}
export const DeleteButonCell = (
  props: React.PropsWithChildren<DeleteButtonCellProps>
) => {
  const { onDelete, cell } = props;
  return (
    <IconButton
      style={{
        padding: 0,
      }}
      color="primary"
      onClick={() => {
        onDelete([cell.row.id]);
      }}
    >
      <DeleteIcon />
    </IconButton>
  );
};

const prepareRequest = (
  result: OnEditPosition<SalesOpportunityRatingStatus>
) => {
  const { position } = result;
  const request: Partial<SalesOpportunityRatingStatus> = {};
  request.id = position.id;

  Object.entries(position).forEach((x) => {
    const [field, value] = x;
    if (field === 'ratingValue') {
      request[field] = Math.max(0, Math.min(100, parseFloat(`${value}`)));
    } else request[field] = value;
  });

  return request;
};

export const SalesOpportunitiesRatingStatusList = () => {
  const { t } = useTranslation();
  const { data, refetch } = useSalesOpportunityRatingStatus();

  useMaskScroll(false);
  const [deleteEntity] = useDeleteEntity(false);

  const [mutate] = useDataMutation<SalesOpportunityRatingStatus, EMode.upsert>({
    entity: Entities.salesOpportunityRatingStatus,
    mutationType: EMode.upsert,
    onCompleted: () => {
      refetch();
    },
    responseData: { id: null },
  });

  const callbacks = useMemo(() => {
    const onAddPosition = () => {
      mutate({ name: '', rating: 0 });
    };

    const onEditPosition = (
      result: OnEditPosition<SalesOpportunityRatingStatus>
    ) => {
      const request = prepareRequest(result);
      mutate(request);
    };

    const onRemovePosition = async (ids: number[]) => {
      try {
        await deleteEntity({
          ids: ids,
          type: Entities.salesOpportunityRatingStatus,
        });
      } finally {
        refetch();
      }
    };

    return {
      onAddPosition,
      onEditPosition,
      onMovePosition: noop,
      onRemovePosition,
    };
  }, [mutate, deleteEntity, refetch]);

  const { positions: items, ...localCallbacks } = useEditableState({
    positions: data,
    ...callbacks,
  });

  const columns = useMemo(() => {
    const columns: Column<SalesOpportunityRatingStatus>[] = [
      {
        Header: t('INPUTS.VALUE'),
        accessor: 'ratingValue',
        width: 80,
        Cell: (props) => `${props.value} %`,
      },
      {
        Header: t('INPUTS.DESCRIPTION'),
        accessor: 'name',
        width: 160,
      },
      {
        Header: t('COMMON.COMMENT'),
        accessor: 'comment',
      },
      {
        accessor: 'id',
        Cell: (props) => (
          <DeleteButonCell
            {...props}
            onDelete={localCallbacks.onRemovePosition}
          />
        ),
        width: 40,
      },
    ];
    return columns;
  }, [t, localCallbacks.onRemovePosition]);

  const tableInstanceRef = useRef<TableInstance>();

  const cellDefinition = useMemo(() => {
    return {
      ratingValue: {
        type: 'number',
        unit: '%',
        min: 0,
        step: 10,
        max: 100,
        transform: (input: number) =>
          Math.min(Math.max(Math.round(input / 10) * 10, 0), 100),
      },
      name: {
        type: 'text',
      },
      comment: {
        type: 'text',
        style: {
          width: `calc(100% - ${columns.reduce(
            (p, c) => p + (typeof c.width === 'number' ? c.width : 0),
            0
          )}px)`,
        },
      },
    };
  }, [columns]);

  return (
    <Collapse
      title={t('COMMON.RATED_STATUS')}
      defaultOpen={true}
      className={styles['collapse-indent']}
      collapseContainerStyles={styles.collapse}
      classes={{
        wrapper: styles['collapse-wrapper'],
        wrapperInner: styles['collapse-wrapper-inner'],
      }}
      headerBar={
        <Box width="100%" display="flex" justifyContent="flex-end">
          <IconButton
            color="primary"
            onClick={() => {
              localCallbacks.onAddPosition();
            }}
          >
            <AddIcon />
          </IconButton>
        </Box>
      }
    >
      <EditTable<SalesOpportunityRatingStatus>
        tableInstanceRef={tableInstanceRef}
        onEditPosition={localCallbacks.onEditPosition}
        items={items}
        columns={columns}
        cellDefinition={cellDefinition}
        classes={{
          tbody: styles['no-scroll'],
          table: styles.table,
        }}
      />
    </Collapse>
  );
};
