import { Close, ContentCopy } from '@mui/icons-material';
import { Button, IconButton, Stack, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CopyButton } from '../../CopyButton';

interface Props {
  eventId: string;
  onClose: () => void;
  debugInfoData: string;
}

export const SentryActions: React.FC<Props> = ({
  eventId,
  onClose,
  debugInfoData,
}) => {
  const { t } = useTranslation();

  return (
    <Stack direction={'row'} alignItems={'center'}>
      <CopyButton
        value={debugInfoData}
        timeout={5000}
        children={({ copy, copied }) => {
          const btn = (
            <Button
              sx={{
                color: 'var(--ui01)',
              }}
              endIcon={
                <ContentCopy
                  sx={{
                    color: 'var(--ui01)',
                  }}
                />
              }
              title={t('ERROR.COPYREF.BUTTON')}
              disabled={copied}
              onClick={copy}
            >
              ({eventId})
            </Button>
          );
          return copied ? (
            <Tooltip
              title={t('ERROR.COPYREF.BUTTON')}
              leaveDelay={2500}
              open={true}
              onClose={onClose}
              sx={{
                color: 'var(--ui01)',
              }}
            >
              {btn}
            </Tooltip>
          ) : (
            btn
          );
        }}
      />

      <IconButton
        sx={{
          color: 'var(--ui01)',
        }}
        onClick={onClose}
      >
        <Close />
      </IconButton>
    </Stack>
  );
};
