import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputRaPaymentRelation } from './InputRaPaymentRelation.entity';

export class InputRaRelation<T extends EMode = EMode.entity> {
  /** Alias: payments */
  payments?: InputRaPaymentRelation<T>;

  __typename?: string;
}

const fields: FieldDefinitions<InputRaRelation> = {
  payments: {
    alias: 'payments',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputRaRelationEntityDefinition: EntitiyDefinition<InputRaRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputRaRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
