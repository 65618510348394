import styles from './TimePicker.module.scss';

import { Datepicker } from '@mobiscroll/react';
import { Paper } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';

import { withNavigatorLocale } from '@work4all/utils/lib/date-utils/withNavigatorLocale';

import { useMobiscrollLanguage } from '../../utils/use-mobiscroll-language/use-mobiscroll-language';

export interface ITimePickerProps {
  timeValue?: DateTime | string;
  onTimeSelect?: (dt: DateTime) => void;
  onDestroyPopover?: () => void;
}

export const TimePicker: React.FC<ITimePickerProps> = (props) => {
  let defaultValue = null;
  let dt: DateTime | null = null;
  if (typeof props.timeValue === 'string') {
    dt = DateTime.fromFormat(props.timeValue, 'HH:mm');
  } else if (props.timeValue) {
    dt = props.timeValue;
  }

  defaultValue = dt && dt.isValid ? dt.toFormat('HH:mm') : undefined;

  const language = useMobiscrollLanguage();

  return (
    <Paper className={styles.root}>
      <Datepicker
        locale={language}
        touchUi={true}
        display="inline"
        controls={['time']}
        timeWheels="HHmm"
        defaultValue={defaultValue}
        onDestroy={props.onDestroyPopover}
        onChange={(ev) => {
          if (props.onTimeSelect) {
            props.onTimeSelect(
              withNavigatorLocale(DateTime.fromJSDate(ev.value as Date))
            );
          }
        }}
      />
    </Paper>
  );
};
