import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Appointment } from './Appointment.entity';
import { Calculation } from './Calculation.entity';
import { CallMemo } from './CallMemo.entity';
import { CheckList } from './CheckList.entity';
import { Contract } from './Contract.entity';
import { Customer } from './Customer.entity';
import { DeliveryNote } from './DeliveryNote.entity';
import { Demand } from './Demand.entity';
import { Document } from './Document.entity';
import { EMail } from './EMail.entity';
import { InboundDeliveryNote } from './InboundDeliveryNote.entity';
import { InboundInvoice } from './InboundInvoice.entity';
import { Invoice } from './Invoice.entity';
import { Letter } from './Letter.entity';
import { Note } from './Note.entity';
import { Offer } from './Offer.entity';
import { Order } from './Order.entity';
import { ProductionContract } from './ProductionContract.entity';
import { Project } from './Project.entity';
import { Supplier } from './Supplier.entity';
import { Task } from './Task.entity';
import { Ticket } from './Ticket.entity';
import { TravelExpenses } from './TravelExpenses.entity';
import { VisitationReport } from './VisitationReport.entity';
import { WidgetDataUnion } from './WidgetDataUnion.entity';

export class WidgetData<T extends EMode = EMode.entity> {
  /** Alias: data */
  data?: T extends EMode.query
    ? WidgetDataUnion<T>
    :
        | Customer<T>[]
        | Supplier<T>[]
        | Project<T>[]
        | Letter<T>[]
        | Task<T>[]
        | Ticket<T>[]
        | EMail<T>[]
        | Appointment<T>[]
        | CallMemo<T>[]
        | Note<T>[]
        | CheckList<T>[]
        | VisitationReport<T>[]
        | Document<T>[]
        | TravelExpenses<T>[]
        | Offer<T>[]
        | Contract<T>[]
        | Invoice<T>[]
        | DeliveryNote<T>[]
        | InboundDeliveryNote<T>[]
        | Calculation<T>[]
        | ProductionContract<T>[]
        | Demand<T>[]
        | Order<T>[]
        | InboundInvoice<T>[];
  /** Alias: totalCount */
  totalCount?: number;
  /** Alias: widgetId */
  widgetId?: string;
  /** Alias: widgetTitle */
  widgetTitle?: string;

  __typename?: string;
}

const fields: FieldDefinitions<WidgetData> = {
  data: {
    alias: 'data',
    entity: [
      Entities.customer,
      Entities.supplier,
      Entities.project,
      Entities.letter,
      Entities.task,
      Entities.ticket,
      Entities.eMail,
      Entities.appointment,
      Entities.callMemo,
      Entities.note,
      Entities.checkList,
      Entities.visitationReport,
      Entities.document,
      Entities.travelExpenses,
      Entities.offer,
      Entities.contract,
      Entities.invoice,
      Entities.deliveryNote,
      Entities.inboundDeliveryNote,
      Entities.calculation,
      Entities.productionContract,
      Entities.demand,
      Entities.order,
      Entities.inboundInvoice,
    ],
  },
  totalCount: {
    alias: 'totalCount',
  },
  widgetId: {
    alias: 'widgetId',
  },
  widgetTitle: {
    alias: 'widgetTitle',
  },
  __typename: {
    alias: '__typename',
  },
};

export const widgetDataEntityDefinition: EntitiyDefinition<WidgetData> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'WidgetData',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
