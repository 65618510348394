import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputRESachkontenSplit<T extends EMode = EMode.entity> {
  /** Alias: anteilDM */
  anteilDM?: number;
  /** Alias: anteilProzent */
  anteilProzent?: number;
  /** Alias: aufmass */
  aufmass?: string;
  /** Alias: berichtigungsschluessel */
  berichtigungsschluessel?: number;
  /** Alias: bestellCode */
  bestellCode?: number;
  /** Alias: datev */
  datev?: string;
  /** Alias: gegenkonto */
  gegenkonto?: number;
  /** Alias: kostengruppe */
  kostengruppe?: number;
  /** Alias: kostenstCode */
  kostenstCode?: number;
  /** Alias: kostenstNummer */
  kostenstNummer?: number;
  /** Alias: mwst */
  mwst?: number;
  /** Alias: mwstBetrag */
  mwstBetrag?: number;
  /** Alias: notiz */
  notiz?: string;
  /** Alias: projektCode */
  projektCode?: number;
  /** Alias: rechnungCode */
  rechnungCode?: number;
  /** Alias: rECode */
  rECode?: number;
  /** Alias: reisekostenabrechnungBelegCode */
  reisekostenabrechnungBelegCode?: number;
  /** Alias: sachkCode */
  sachkCode?: number;
  /** Alias: sachkNummer */
  sachkNummer?: number;
  /** Alias: steuerschluessel */
  steuerschluessel?: number;
  /** Alias: umbuchung */
  umbuchung?: number;
  /** Alias: vorlauf */
  vorlauf?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];
  /** Alias: code */
  code?: number;
  /** Alias: bestellungCode */
  oderId?: number;
  /** Alias: kostengruppeCode */
  kostengruppeCode?: number;
  /** Alias: kostenstelleCode */
  costCenterId?: number;
  /** Alias: kostenstelleNummer */
  costCenter1Number?: number;
  /** Alias: sachkontoCode */
  sachkontoCode?: number;
  /** Alias: sachkontoNummer */
  ledgerAccountNumber?: number;
  /** Alias: valueNet */
  valueNet?: number;

  __typename?: string;
}

const fields: FieldDefinitions<InputRESachkontenSplit> = {
  anteilDM: {
    alias: 'anteilDM',
  },
  anteilProzent: {
    alias: 'anteilProzent',
  },
  aufmass: {
    alias: 'aufmass',
  },
  berichtigungsschluessel: {
    alias: 'berichtigungsschluessel',
  },
  bestellCode: {
    alias: 'bestellCode',
  },
  datev: {
    alias: 'datev',
  },
  gegenkonto: {
    alias: 'gegenkonto',
  },
  kostengruppe: {
    alias: 'kostengruppe',
  },
  kostenstCode: {
    alias: 'kostenstCode',
  },
  kostenstNummer: {
    alias: 'kostenstNummer',
  },
  mwst: {
    alias: 'mwst',
  },
  mwstBetrag: {
    alias: 'mwstBetrag',
  },
  notiz: {
    alias: 'notiz',
  },
  projektCode: {
    alias: 'projektCode',
  },
  rechnungCode: {
    alias: 'rechnungCode',
  },
  rECode: {
    alias: 'rECode',
  },
  reisekostenabrechnungBelegCode: {
    alias: 'reisekostenabrechnungBelegCode',
  },
  sachkCode: {
    alias: 'sachkCode',
  },
  sachkNummer: {
    alias: 'sachkNummer',
  },
  steuerschluessel: {
    alias: 'steuerschluessel',
  },
  umbuchung: {
    alias: 'umbuchung',
  },
  vorlauf: {
    alias: 'vorlauf',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customFields: {
    alias: 'customFields',
  },
  code: {
    alias: 'code',
  },
  oderId: {
    alias: 'bestellungCode',
  },
  kostengruppeCode: {
    alias: 'kostengruppeCode',
  },
  costCenterId: {
    alias: 'kostenstelleCode',
  },
  costCenter1Number: {
    alias: 'kostenstelleNummer',
  },
  sachkontoCode: {
    alias: 'sachkontoCode',
  },
  ledgerAccountNumber: {
    alias: 'sachkontoNummer',
  },
  valueNet: {
    alias: 'valueNet',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputRESachkontenSplitEntityDefinition: EntitiyDefinition<InputRESachkontenSplit> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputRESachkontenSplit',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
