import styles from './CheckListPreview.module.scss';

import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import {
  MouseEventHandler,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Row, TableInstance } from 'react-table';

import {
  useDataMutation,
  useInaccessibleFields,
  useUser,
} from '@work4all/data';

import { CheckList } from '@work4all/models/lib/Classes/CheckList.entity';
import { CheckListPosition } from '@work4all/models/lib/Classes/CheckListPosition.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import {
  DateFormatPreset,
  formatDateString,
} from '@work4all/utils/lib/date-utils/formatDateString';

import {
  BasicTable,
  IBasicTableProps,
  TableStateBagProvider,
} from '../../../dataDisplay/basic-table';
import { useContactActions } from '../../../hooks/use-contact-actions';
import { DatePicker } from '../../../input/date-picker';
import { Body1 } from '../../../typography/body1/Body1';
import { useMobiscrollLanguage } from '../../../utils/use-mobiscroll-language/use-mobiscroll-language';
import { IConvertPopoverProps } from '../../convert-popover/ConvertPopover';
import { EntityPickerPopover } from '../../entity-picker/components';
import { ContactPicker } from '../../entity-picker/contact-picker/ContactPicker';
import { CustomerPicker } from '../../entity-picker/customer-picker/CustomerPicker';
import { ProjectPicker } from '../../entity-picker/project-picker/ProjectPicker';
import { UserPicker } from '../../entity-picker/user-picker/UserPicker';
import { useEntityTemplate } from '../../entity-template-provider/EntityTemplateProvider';
import { HookedUserIcon } from '../../user-icon/useUserIconRegister';
import {
  InfoCard,
  InfoCards,
  OpenFileIconButton,
  PreviewEditMessage,
  PreviewTitle,
  PreviewWrapper,
} from '../components';
import { useEntityPreview } from '../hooks/use-entity-preview';

import { ChecklistPositionStatusCell } from './ChecklistPositionStatusCell';
import { checklistPreviewFields } from './ChecklistPreviewContainer';

export type IChecklistPreviewProps = {
  checklists: CheckList[];
  currentUserId: number;
  onCloseClicked?: MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onShareClicked?: () => void;
  convertProps?: Pick<IConvertPopoverProps, 'exclude' | 'onClick'>;
};

enum previewFields {
  DATE = 'date',
  CUSTOMER = 'customer',
  CONTACT = 'contact',
  EMPLOYEES = 'employees',
  PREVIEW_TITLE = 'previewTitle',
  PROJECT = 'project',
}

export function ChecklistPreview(props: IChecklistPreviewProps) {
  const {
    checklists,
    onCloseClicked,
    onEditClicked,
    currentUserId,
    onShareClicked,
    convertProps,
  } = props;
  const { t } = useTranslation();
  const [tableInstance, setTableInstance] = useState<TableInstance>(null);
  const language = useMobiscrollLanguage();

  const user = useUser();
  const { isInaccessible } = useInaccessibleFields();

  const titleIsAccessible = !isInaccessible(Entities.checkList, 'title');
  const userIsAccessible = !isInaccessible(Entities.checkList, 'user');
  const dateIsAccessible = !isInaccessible(Entities.checkList, 'date');
  const businessPartnerIsAccessible = !isInaccessible(
    Entities.checkList,
    'businessPartner'
  );
  const contactIsAccessible = !isInaccessible(Entities.checkList, 'contact');
  const projectIsAccessible = !isInaccessible(Entities.checkList, 'project');
  const positionListIsAccessible = !isInaccessible(
    Entities.checkList,
    'positionList'
  );

  const dateRef = useRef<EntityPickerPopover>(null);
  const customerRef = useRef<EntityPickerPopover>(null);
  const contactRef = useRef<EntityPickerPopover>(null);
  const projectRef = useRef<EntityPickerPopover>(null);
  const employeesRef = useRef<EntityPickerPopover>(null);
  const titleRef = useRef<PreviewTitle>(null);

  const openPicker = useCallback((picker: previewFields) => {
    switch (picker) {
      case previewFields.DATE: {
        dateRef.current.open();
        break;
      }

      case previewFields.CUSTOMER: {
        customerRef.current.open();
        break;
      }

      case previewFields.CONTACT: {
        contactRef.current.open();
        break;
      }

      case previewFields.PROJECT: {
        projectRef.current.open();
        break;
      }

      case previewFields.EMPLOYEES: {
        employeesRef.current.open();
        break;
      }

      case previewFields.PREVIEW_TITLE: {
        titleRef.current.open();
        break;
      }

      default:
        return;
    }
  }, []);

  const [mutate] = useDataMutation<CheckList, EMode.upsert>({
    entity: Entities.checkList,
    mutationType: EMode.upsert,
    responseData: checklistPreviewFields as unknown as CheckList,
  });

  const {
    loading,
    isMultiselect,
    commonFields,
    activePicker,
    optimisticTitle,
    handleLock,
    onPopoverClose,
    setOptimisticTitle,
    onEdit,
    canEditAllSelected,
  } = useEntityPreview({
    user,
    subEntityType: Entities.checkList,
    entries: checklists,
    openPicker,
    mutate,
  });

  const dateIsCommon = commonFields.includes('date');
  const customerIsCommon = commonFields.includes('businessPartner');
  const contactIsCommon = commonFields.includes('contact');
  const projectIsCommon = commonFields.includes('project');
  const employeesIsCommon = commonFields.includes('user');

  const prepareRowDisplayModifiers = (value) => {
    return { isBold: (value as CheckListPosition).kind === 'HEADLINE' };
  };

  const checklist = checklists[0];

  const sortedList = useMemo(() => {
    return [...checklist.positionList].sort((a, b) => a.number - b.number);
  }, [checklist.positionList]);

  const columns = useMemo(() => {
    const columns: IBasicTableProps['columns'] = [
      {
        Header: t('COMMON.STATUS'),
        id: 'doneDate1',
        sticky: 'left',
        accessor: 'doneDate',
        Cell: ({ row }: { row: Row<CheckListPosition> }) => {
          if (row.original.kind === 'DEFAULT') {
            return (
              <ChecklistPositionStatusCell
                value={row.original.doneDate}
                row={row}
                currentUserId={currentUserId}
              />
            );
          }
          return '';
        },
        width: 55,
      },
      {
        Header: t('COMMON.POSITION_SHORT'),
        accessor: 'positionNumber',
        width: 55,
        sticky: 'left',
      },
      {
        Header: t('COMMON.CONTENTS'),
        accessor: 'name',
        width: 250,
      },
      {
        Header: t('COMMON.RESPONSIBLE_SHORT'),
        accessor: 'plannedByUserId',
        Cell: ({ row }: { row: Row<CheckListPosition> }) => {
          if (row.original.plannedByUserId === 0) {
            return '';
          }
          return <HookedUserIcon userId={row.original.doneByUserId} size="m" />;
        },
        width: 55,
      },
      {
        Header: t('COMMON.DONE'),
        id: 'doneDate2',
        accessor: 'doneDate',
        Cell: ({ row }: { row: Row<CheckListPosition> }) => {
          if (!row.original.doneDate) {
            return '';
          }

          return (
            <div className={styles['user-icon-wrapper']}>
              <HookedUserIcon userId={row.original.doneByUserId} size="m" />
              {formatDateString(
                row.original.doneDate,
                DateFormatPreset.DATE_SIMPLE_2YEAR
              )}
            </div>
          );
        },
        width: 200,
      },
    ];

    return columns;
  }, [t, currentUserId]);

  const { actions: contactActions } = useContactActions(checklist?.contact);
  const entityTemplate = useEntityTemplate();

  return (
    <PreviewWrapper>
      <PreviewTitle
        disabled={true}
        showEdit={!isMultiselect && canEditAllSelected}
        ref={titleRef}
        label={checklist?.title}
        onClick={() =>
          !isMultiselect &&
          titleIsAccessible &&
          handleLock(previewFields.PREVIEW_TITLE)
        }
        onClose={(e) => {
          if (e.target.value !== checklist?.title) {
            setOptimisticTitle(e.target.value);
            onEdit({ title: e.target.value });
          }
          onPopoverClose();
        }}
        onCloseClicked={onCloseClicked}
        onEditClicked={onEditClicked}
        actions={
          loading && activePicker === previewFields.PREVIEW_TITLE ? (
            <CircularProgress size="1rem" color="secondary" />
          ) : null
        }
        onShareClicked={onShareClicked}
        convertProps={convertProps}
      >
        {isMultiselect
          ? `${t('COMMON.SELECTION')}: ${checklists.length} ${t(
              'COMMON.ELEMENTS'
            )}`
          : titleIsAccessible
          ? optimisticTitle ?? checklist?.title
          : ''}
      </PreviewTitle>
      {isMultiselect && <PreviewEditMessage entityId={String(checklist.id)} />}

      <InfoCards columns={2}>
        {userIsAccessible && (
          <EntityPickerPopover
            ref={employeesRef}
            disabled={true}
            onTargetClick={() => handleLock(previewFields.EMPLOYEES)}
            onClose={onPopoverClose}
            picker={
              <UserPicker
                multiple={false}
                value={employeesIsCommon ? checklist.user : null}
                onChange={(value) => {
                  onEdit({
                    userId: value.id,
                  });
                }}
              />
            }
          >
            <InfoCard
              truncate
              disabled={false}
              label={t('COMMON.EMPLOYEE')}
              decorator={
                loading && activePicker === previewFields.EMPLOYEES ? (
                  <CircularProgress size="1rem" color="secondary" />
                ) : null
              }
              decoratorPos="right"
            >
              {employeesIsCommon ? (
                <Body1 className={styles['user-icon-wrapper']}>
                  <HookedUserIcon userId={checklist.userId} size="l" />
                  {checklist?.user.firstName} {checklist?.user.lastName}
                </Body1>
              ) : (
                `(${t('COMMON.MULTIPLE')})`
              )}
            </InfoCard>
          </EntityPickerPopover>
        )}

        {dateIsAccessible && (
          <EntityPickerPopover
            ref={dateRef}
            disabled={true}
            onTargetClick={() => handleLock(previewFields.DATE)}
            onClose={onPopoverClose}
            autoclose={true}
            picker={
              <DatePicker
                locale={language}
                controls={['calendar']}
                defaultValue={formatDateString(
                  checklist?.date,
                  DateFormatPreset.DATE_SIMPLE_2YEAR
                )}
                onChange={(val) => {
                  onEdit({ date: new Date(val.value).toISOString() });
                }}
              />
            }
          >
            <InfoCard
              label={t('COMMON.DATE')}
              decorator={
                loading && activePicker === previewFields.DATE ? (
                  <CircularProgress size="1rem" color="secondary" />
                ) : null
              }
              decoratorPos="right"
            >
              {dateIsCommon
                ? formatDateString(
                    checklist?.date,
                    DateFormatPreset.DATE_SIMPLE_2YEAR
                  )
                : `(${t('COMMON.MULTIPLE')})`}
            </InfoCard>
          </EntityPickerPopover>
        )}

        {businessPartnerIsAccessible && (
          <EntityPickerPopover
            ref={customerRef}
            disabled={true}
            onTargetClick={() => handleLock(previewFields.CUSTOMER)}
            onClose={onPopoverClose}
            footer={entityTemplate.renderPickerFooter({
              entity: Entities.customer,
              popoverRef: customerRef,
              data: checklist.businessPartner?.data as Customer,
              onChange: (value) => {
                onEdit({
                  businessPartner:
                    value && !Array.isArray(value)
                      ? {
                          data: value,
                          businessPartnerType: SdObjType.KUNDE,
                          id: value?.id,
                        }
                      : null,
                  businessPartnerId:
                    value && !Array.isArray(value) ? value?.id : 0,
                  businessPartnerType: SdObjType.KUNDE,
                });
              },
            })}
            picker={
              <CustomerPicker
                onChange={(value) => {
                  onEdit({
                    businessPartner: value
                      ? {
                          data: value,
                          businessPartnerType: SdObjType.KUNDE,
                          id: value?.id,
                        }
                      : null,
                    businessPartnerId: value ? value?.id : 0,
                    businessPartnerType: SdObjType.KUNDE,
                  });
                }}
                value={checklist.businessPartner?.data as Customer}
                multiple={false}
              />
            }
          >
            <InfoCard
              truncate
              label={t('COMMON.BUSINESS_PARTNER', {
                context: checklist?.businessPartner?.businessPartnerType,
              })}
              decorator={
                loading && activePicker === previewFields.CUSTOMER ? (
                  <CircularProgress size="1rem" color="secondary" />
                ) : null
              }
              decoratorPos="right"
              beforeContentElement={
                <OpenFileIconButton data={checklist?.businessPartner?.data} />
              }
            >
              {customerIsCommon
                ? checklist?.businessPartner?.data?.name
                : `(${t('COMMON.MULTIPLE')})`}
            </InfoCard>
          </EntityPickerPopover>
        )}

        {contactIsAccessible && (
          <Stack direction="row">
            <div style={{ width: '100%' }}>
              <EntityPickerPopover
                ref={contactRef}
                disabled={true}
                onTargetClick={() =>
                  customerIsCommon &&
                  checklist?.businessPartner?.data &&
                  handleLock(previewFields.CONTACT)
                }
                onClose={onPopoverClose}
                picker={
                  <ContactPicker
                    prefilter={[
                      {
                        businessPartnerType: { $eq: SdObjType.KUNDE },
                      },
                      {
                        businessPartnerId: {
                          $eq: checklist?.businessPartner?.data.id,
                        },
                      },
                    ]}
                    value={checklist.contact}
                    multiple={false}
                    onChange={(value) => {
                      onEdit({
                        contact: value,
                        contactId: value ? value.id : 0,
                      });
                    }}
                  />
                }
              >
                <InfoCard
                  disabled={
                    !customerIsCommon || !checklist?.businessPartner?.data
                  }
                  tooltip={
                    !customerIsCommon || !checklist?.businessPartner?.data
                      ? 'Wählen Sie zunächst ein einheitliches Projekt'
                      : undefined
                  }
                  label={t('COMMON.CONTACT')}
                  decorator={
                    loading && activePicker === previewFields.CONTACT ? (
                      <CircularProgress size="1rem" color="secondary" />
                    ) : null
                  }
                  decoratorPos="right"
                >
                  {contactIsCommon
                    ? checklist?.contact?.displayName || '-'
                    : `(${t('COMMON.MULTIPLE')})`}
                </InfoCard>
              </EntityPickerPopover>
            </div>
            {contactActions}
          </Stack>
        )}

        {projectIsAccessible && (
          <EntityPickerPopover
            ref={projectRef}
            disabled={true}
            onTargetClick={() => handleLock(previewFields.PROJECT)}
            onClose={onPopoverClose}
            footer={entityTemplate.renderPickerFooter({
              entity: Entities.project,
              popoverRef: projectRef,
              data: checklist.project,
              onChange: (value) => {
                onEdit({
                  project: value,
                  projectId: value && !Array.isArray(value) ? value.id : 0,
                });
              },
            })}
            picker={
              <ProjectPicker
                onChange={(value) => {
                  onEdit({
                    project: value,
                    projectId: value ? value.id : 0,
                  });
                }}
                value={checklist.project}
                multiple={false}
              />
            }
          >
            <InfoCard
              truncate
              label={t('COMMON.PROJECT')}
              decorator={
                loading && activePicker === previewFields.PROJECT ? (
                  <CircularProgress size="1rem" color="secondary" />
                ) : null
              }
              decoratorPos="right"
              beforeContentElement={
                <OpenFileIconButton data={checklist?.project} />
              }
            >
              {projectIsCommon
                ? checklist?.project?.name
                : `(${t('COMMON.MULTIPLE')})`}
            </InfoCard>
          </EntityPickerPopover>
        )}
      </InfoCards>
      {!isMultiselect && positionListIsAccessible ? (
        <div style={{ display: 'grid', height: '100%' }}>
          <TableStateBagProvider tableInstance={tableInstance}>
            <BasicTable
              className={styles.transparentTable}
              ref={setTableInstance}
              mode="client"
              prepareRowDisplayModifiers={prepareRowDisplayModifiers}
              reordableColumns={true}
              resizableColumns={false}
              data={sortedList}
              columns={columns}
              allItemsCount={checklist?.positionList.length || 0}
              cardsView={false}
              selectableMultiple={false}
            />
          </TableStateBagProvider>
        </div>
      ) : null}
    </PreviewWrapper>
  );
}
