import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class ChangeEventChangeInfo<T extends EMode = EMode.entity> {
  /** Alias: propertyName */
  propertyName?: string;
  /** Alias: oldValue */
  oldValue?: string;
  /** Alias: newValue */
  newValue?: string;

  __typename?: string;
}

const fields: FieldDefinitions<ChangeEventChangeInfo> = {
  propertyName: {
    alias: 'propertyName',
  },
  oldValue: {
    alias: 'oldValue',
  },
  newValue: {
    alias: 'newValue',
  },
  __typename: {
    alias: '__typename',
  },
};

export const changeEventChangeInfoEntityDefinition: EntitiyDefinition<ChangeEventChangeInfo> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'ChangeEventChangeInfo',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
