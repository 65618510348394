import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { MfaMode } from '../Enums/MfaMode.enum';

export class InputSetMfaMode<T extends EMode = EMode.entity> {
  /** Alias: userCode */
  userCode?: any;
  /** Alias: mfaMode */
  mfaMode?: MfaMode;

  __typename?: string;
}

const fields: FieldDefinitions<InputSetMfaMode> = {
  userCode: {
    alias: 'userCode',
  },
  mfaMode: {
    alias: 'mfaMode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputSetMfaModeEntityDefinition: EntitiyDefinition<InputSetMfaMode> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputSetMfaMode',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
