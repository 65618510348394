import {
  RxCollection,
  RxDatabase,
  RxGraphQLPullResponseModifier,
  RxGraphQLReplicationPullQueryBuilder,
} from 'rxdb';
import { replicateGraphQL } from 'rxdb/plugins/replication-graphql';
import { of } from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  filter,
  map,
  skip,
  switchMap,
} from 'rxjs/operators';

import { USER_META_ID } from '../../db';
import { IUser } from '../../hooks/useUser';
import { deriveTenantCollectionName } from '../../utils';

import { ReplicationEventType } from './replication.types';

type ReplicateTenantCollectionConfig<Checkpoint, DocType> = {
  queryBuilder: RxGraphQLReplicationPullQueryBuilder<Checkpoint>;
  responseModifier: RxGraphQLPullResponseModifier<DocType, Checkpoint>;
};

type ReplicationConfigCreator<CollectionDocType, Checkpoint> = (params: {
  tenant: number;
  db: RxDatabase;
  collection: RxCollection<CollectionDocType>;
}) => ReplicateTenantCollectionConfig<Checkpoint, CollectionDocType>;

type ReplicateTenantCollectionParams = {
  user: IUser;
  tenant: number;
  db: RxDatabase;
  refreshToken: () => void;
  reportEvent: (event: ReplicationEventType) => void;
};

export function createReplicateTenantCollection(collectionBaseName: string) {
  return function createReplicateTenantCollectionInner<
    Checkpoint,
    CollectionDocType
  >(createConfig: ReplicationConfigCreator<CollectionDocType, Checkpoint>) {
    return function replicateSettings(params: ReplicateTenantCollectionParams) {
      const { user, tenant, db, refreshToken, reportEvent } = params;

      const collectioFullName = deriveTenantCollectionName(
        tenant,
        collectionBaseName
      );
      const collection = db[collectioFullName];

      const { queryBuilder, responseModifier } = createConfig({
        tenant,
        db,
        collection,
      });

      let currentToken = user.token;

      function createRequestHeaders(): Record<string, string> {
        return {
          authorization: `bearer ${currentToken}`,
          'x-work4all-mandant': tenant.toString(),
          'x-work4all-apiurl': user.baseUrl,
        };
      }

      const replicationState = replicateGraphQL<CollectionDocType, Checkpoint>({
        collection,
        url: { http: `${user.baseUrl}/graphql` },
        pull: {
          queryBuilder,
          responseModifier,
        },
        live: true,
        autoStart: true,
        waitForLeadership: true,
        headers: createRequestHeaders(),
      });

      const tokenSubscription = db
        .getLocal$<{ user: IUser }>(USER_META_ID)
        .pipe(
          map((nextDoc) => {
            return nextDoc._data.data.user;
          }),
          filter((user) => {
            // we wait for the update with the new token and skip all other updates, that may occur in the meantime
            return user != null && user.token !== currentToken;
          })
        )
        .subscribe((user) => {
          currentToken = user.token;
          replicationState.setHeaders(createRequestHeaders());
        });

      const errorSubscription = replicationState.error$.subscribe((err) => {
        console.error('Replication error.', err);

        // this is the message, that occurs in offline mode
        const networkError = err?.parameters?.errors?.find?.(
          (err) => err.message === 'Failed to fetch'
        );

        // this is the actual error with an expired token
        //const authError = err.parameters.errors.find((err) => err.message === 'Unexpected end of JSON input')
        if (networkError === undefined) {
          refreshToken();
        }
      });

      const activeSubscription = replicationState.active$
        // Skip the initial "active: false" to avoid resetting the tracked
        // replication status.
        .pipe(
          skip(1),
          distinctUntilChanged(),
          switchMap((active) => {
            if (active) {
              return of(true);
            }

            // When the replication finishes, add an artificial delay before
            // emitting the "done" event. Without this sometimes RxDB hooks will
            // not have yet updated to the latest value, even though the
            // replication has finished.
            return of(false).pipe(delay(50));
          })
        )
        .subscribe((active) => {
          reportEvent(active ? 'start' : 'done');
        });

      let reSyncTimeout: number | null = null;

      function queueReSync() {
        reSyncTimeout = window.setTimeout(() => {
          reSync();
        }, 2 * 60 * 1000);
      }

      function clearReSync() {
        clearTimeout(reSyncTimeout);
      }

      queueReSync();

      let cancellationPromise: Promise<void> | null = null;

      function reSync() {
        if (cancellationPromise !== null) return;

        clearReSync();
        replicationState.reSync();
        queueReSync();
      }

      async function cancel() {
        if (cancellationPromise === null) {
          reportEvent('reset');

          clearReSync();

          tokenSubscription.unsubscribe();
          activeSubscription.unsubscribe();
          errorSubscription.unsubscribe();

          cancellationPromise = replicationState.cancel();
        }

        return cancellationPromise;
      }

      return { reSync, cancel };
    };
  };
}
