import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class AppointmentState<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: name */
  name?: string;
  /** Alias: color */
  color?: string;
  /** Alias: ausserHaus */
  outOfOffice?: boolean;
  /** Alias: hexColorForeground */
  hexColorForeground?: string;
  /** Alias: hexColorBackground */
  hexColorBackground?: string;

  __typename?: string;
}

const fields: FieldDefinitions<AppointmentState> = {
  id: {
    alias: 'code',
  },
  name: {
    alias: 'name',
  },
  color: {
    alias: 'color',
  },
  outOfOffice: {
    alias: 'ausserHaus',
  },
  hexColorForeground: {
    alias: 'hexColorForeground',
  },
  hexColorBackground: {
    alias: 'hexColorBackground',
  },
  __typename: {
    alias: '__typename',
  },
};

export const appointmentStateEntityDefinition: EntitiyDefinition<AppointmentState> =
  {
    local: {},
    remote: {
      queryName: 'getTerminFarben',
      fragmentName: 'TerminFarbe',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
