import styles from './HeaderCheckbox.module.scss';

import { CheckboxProps } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { Checkbox } from '../../../../../../input/checkbox-radio/CheckboxRadio';

interface IHeaderCheckboxProps extends Omit<CheckboxProps, 'ref'> {
  className?: string;
}

export const HeaderCheckbox: React.FC<IHeaderCheckboxProps> = (props) => {
  return (
    <Checkbox {...props} className={clsx(styles.checkbox, props.className)} />
  );
};
