import { DialogState } from './types';

interface DialogsState {
  dialogs: DialogState[];
}

interface AddAction {
  type: 'add_dialog';
  dialog: DialogState;
}

interface CloseAction {
  type: 'close_dialog';
  id: string;
}

interface RemoveAction {
  type: 'remove_dialog';
  id: string;
}

type Action = AddAction | CloseAction | RemoveAction;

export function dialogsReducer(
  state: DialogsState,
  action: Action
): DialogsState {
  switch (action.type) {
    case 'add_dialog': {
      return {
        dialogs: [...state.dialogs, action.dialog],
      };
    }

    case 'close_dialog': {
      return {
        dialogs: state.dialogs.map((dialog) => {
          return dialog.id === action.id ? { ...dialog, open: false } : dialog;
        }),
      };
    }

    case 'remove_dialog': {
      return {
        dialogs: state.dialogs.filter((dialog) => dialog.id !== action.id),
      };
    }

    default: {
      return state;
    }
  }
}
