import { useMemo } from 'react';

import {
  checkUserRight,
  UserRights,
} from '@work4all/models/lib/additionalEnums/UserRights';
import { InboundInvoice } from '@work4all/models/lib/Classes/InboundInvoice.entity';

interface Props {
  inboundInvoice: Pick<InboundInvoice, 'userId' | 'datevDate'>;
  userRights: string;
  userId: number;
  fileName: string;
}

export const useInboundInvoicePdfEditRights = (props: Props) => {
  const { inboundInvoice, userRights, userId, fileName } = props;

  const inboundInvoiceIsPDF = useMemo(() => {
    const segments = fileName?.split('.');
    return (
      segments && segments[segments.length - 1].toLocaleLowerCase() === 'pdf'
    );
  }, [fileName]);

  const inboundInvoicePdfEditAllowed = useMemo(() => {
    if (!inboundInvoiceIsPDF) {
      return false;
    }
    if (inboundInvoice?.datevDate) {
      return false;
    }
    if (
      checkUserRight(UserRights.RechnungseingangErstellenAendern, userRights)
    ) {
      return true;
    }
    if (checkUserRight(UserRights.FreigabeEingangsrechnungen, userRights)) {
      return true;
    }
    if (
      checkUserRight(
        UserRights.FreigabeEingangsrechnungenNurEigene,
        userRights
      ) &&
      inboundInvoice?.userId === userId
    ) {
      return true;
    }
    return false;
  }, [
    inboundInvoiceIsPDF,
    inboundInvoice?.datevDate,
    inboundInvoice?.userId,
    userRights,
    userId,
  ]);

  return inboundInvoicePdfEditAllowed;
};
