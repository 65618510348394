import produce from 'immer';
import { uuid } from 'short-uuid';

import { createFolder, createLink, CreateLinkOptions } from './creators';
import {
  IFavoriteLink,
  IFavoriteLinkOrFolder,
  IFavoriteLinksFolder,
} from './types';
import { generateNewFolderName, isFavoriteLinksFolder } from './utils';

export interface IAddFavoriteLinksFolderOptions {
  name?: string;
  links?: CreateLinkOptions[];
}

export function addFavoriteLinksFolder(
  options: IAddFavoriteLinksFolderOptions,
  allLinks: IFavoriteLinkOrFolder[]
) {
  const { name = generateNewFolderName(allLinks), links = [] } = options;

  const folder = createFolder({ name, links });

  return [...allLinks, folder];
}

export function removeFavoriteLinksFolder(
  id: string,
  links: IFavoriteLinkOrFolder[]
) {
  return links.filter((folder) => folder.id !== id);
}

export interface IRenameFavoriteLinksFolderOptions {
  id: string;
  name: string;
}

export function renameFavoriteLinksFolder(
  options: IRenameFavoriteLinksFolderOptions,
  links: IFavoriteLinkOrFolder[]
) {
  const { id, name } = options;

  return produce(links, (draft) => {
    const found = draft
      .filter(isFavoriteLinksFolder)
      .find((item) => item.id === id);
    found.name = name;
  });
}

/**
 * Specifies the position of a favorite link.
 */
export type IFavoriteLinkPosition = {
  folder?: string;
  /**
   * Any of properties `index`, `before` or `after` can be used to specify the position at which to add the link.
   * If multiple properties are specified, the first non-undefined value is used in order of precedence: `index`, `before`, `after`.
   * If all the properties are `undefined`, appends the link at the end of the list.
   */
  index?: number;
  /**
   * Any of properties `index`, `before` or `after` can be used to specify the position at which to add the link.
   * If multiple properties are specified, the first non-undefined value is used in order of precedence: `index`, `before`, `after`.
   * If all the properties are `undefined`, appends the link at the end of the list.
   */
  before?: string;
  /**
   * Any of properties `index`, `before` or `after` can be used to specify the position at which to add the link.
   * If multiple properties are specified, the first non-undefined value is used in order of precedence: `index`, `before`, `after`.
   * If all the properties are `undefined`, appends the link at the end of the list.
   */
  after?: string;
};

export interface IAddFavoriteLinkOptions {
  link: CreateLinkOptions;
  position?: IFavoriteLinkPosition;
}

export function addFavoriteLink(
  { link: { name, href }, position = {} }: IAddFavoriteLinkOptions,
  links: IFavoriteLinkOrFolder[]
) {
  const link = createLink({ name, href });

  return produce(links, (draft) => {
    insertLink(link, draft, position);
  });
}

export interface IMoveFavoriteLinkOptions {
  target: {
    folder?: string;
    link: string;
  };
  position: IFavoriteLinkPosition;
}

export function moveFavoriteLink(
  { target, position }: IMoveFavoriteLinkOptions,
  links: IFavoriteLinkOrFolder[]
) {
  return produce(links, (draft) => {
    // Dropped the link at the same position as before.
    // No need to do anything.
    if (
      ((target.folder == null && position.folder == null) ||
        target.folder === position.folder) &&
      (target.link === position.before || target.link === position.after)
    ) {
      return;
    }

    const [link, oldIndex] = removeLink(target, draft);

    // If moving a link inside a folder and destination is specified by the index
    // and the new index is > the old index, decrement the new index by 1.
    if (
      ((target.folder == null && position.folder == null) ||
        target.folder === position.folder) &&
      position.index != null &&
      position.index > oldIndex
    ) {
      position = {
        ...position,
        index: position.index - 1,
      };
    }

    insertLink(link, draft, position);
  });
}

export interface IRemoveLinkOptions {
  folder?: string;
  link: string;
}

function removeLink(
  { folder, link }: IRemoveLinkOptions,
  links: IFavoriteLinkOrFolder[]
): [IFavoriteLink, number] {
  const targetFolder = folder != null ? findFolder(links, folder) : null;
  const targetLinks = targetFolder?.links ?? links;

  const found = targetLinks.findIndex((l) => l.id === link);

  if (found === -1) {
    throw getLinkNotFoundError(link, folder);
  }

  const [removed] = targetLinks.splice(found, 1) as IFavoriteLink[];
  return [removed, found];
}

function findFolder(
  links: IFavoriteLinkOrFolder[],
  folderId: string
): IFavoriteLinksFolder {
  const folder = links
    .filter(isFavoriteLinksFolder)
    .find((folder) => folder.id === folderId);

  if (!folder) {
    throw new Error(`Could not find folder ${folderId}`);
  }

  return folder;
}

function insertLink(
  link: IFavoriteLink,
  links: IFavoriteLinkOrFolder[],
  position: IFavoriteLinkPosition
): void {
  const targetFolder =
    position.folder != null ? findFolder(links, position.folder) : null;
  const targetLinks = targetFolder?.links ?? links;

  if (position.index != null) {
    targetLinks.splice(position.index, 0, link);
  } else if (position.before != null) {
    const index = targetLinks.findIndex((link) => link.id === position.before);

    if (index === -1) {
      throw getLinkNotFoundError(position.before, position.folder);
    }

    targetLinks.splice(index, 0, link);
  } else if (position.after != null) {
    const index = targetLinks.findIndex((link) => link.id === position.after);

    if (index === -1) {
      throw getLinkNotFoundError(position.after, position.folder);
    }

    targetLinks.splice(index + 1, 0, link);
  } else {
    targetLinks.push(link);
  }
}

function getLinkNotFoundError(
  linkId: string,
  folderId: string | undefined
): Error {
  const error = new Error(
    `Could not find link ${linkId}${
      folderId != null ? ` inside folder ${folderId}` : ''
    }`
  );
  Error.captureStackTrace?.(error, getLinkNotFoundError);
  return error;
}

/**
 * Specifies the position of a favorite links folder.
 */
export type IFavoriteLinksFolderPosition = {
  /**
   * Any of properties `index`, `before` or `after` can be used to specify the position to which to move the folder.
   * If multiple properties are specified, the first non-undefined value is used in order of precedence: `index`, `before`, `after`.
   * If all the properties are `undefined`, appends the link at the end of the list.
   */
  index?: number;
  /**
   * Any of properties `index`, `before` or `after` can be used to specify the position to which to move the folder.
   * If multiple properties are specified, the first non-undefined value is used in order of precedence: `index`, `before`, `after`.
   * If all the properties are `undefined`, appends the link at the end of the list.
   */
  after?: string;
};

export interface IMoveFavoriteLinksFolderOptions {
  id: string;
  position: IFavoriteLinksFolderPosition;
}

export function moveFavoriteLinksFolder(
  options: IMoveFavoriteLinksFolderOptions,
  links: IFavoriteLinkOrFolder[]
) {
  const { id } = options;
  let { position } = options;

  if (position.after === id) {
    return;
  }

  return produce(links, (draft) => {
    const oldIndex = draft.findIndex((link) => link.id === id);

    if (position.index != null && position.index > oldIndex) {
      position = { index: position.index - 1 };
    }

    const [removed] = draft.splice(oldIndex, 1) as IFavoriteLinksFolder[];

    if (position.index != null) {
      draft.splice(position.index, 0, removed);
    } else if (position.after != null) {
      const index = draft.findIndex((link) => link.id === position.after);
      draft.splice(index + 1, 0, removed);
    } else {
      draft.push(removed);
    }
  });
}

export function removeFavoriteLink(id: string, links: IFavoriteLinkOrFolder[]) {
  return links
    .map((link) => {
      if (isFavoriteLinksFolder(link)) {
        return {
          ...link,
          links: link.links.filter((link) => link.id !== id),
        };
      } else {
        return link.id === id ? null : link;
      }
    })
    .filter(Boolean);
}

export function removeFavoriteLinkByHref(
  href: string,
  links: IFavoriteLinkOrFolder[]
) {
  return links
    .map((item) => {
      if (isFavoriteLinksFolder(item)) {
        return {
          ...item,
          links: item.links.filter((link) => link.href !== href),
        };
      } else {
        return item.href === href ? null : item;
      }
    })
    .filter(Boolean);
}

export type ICombineLinksOptions = {
  targetId: string;
} & ({ name: string; href: string } | { folderId: string | null; id: string });

export function combineLinks(
  config: ICombineLinksOptions,
  links: IFavoriteLinkOrFolder[]
) {
  return produce(links, (draft) => {
    const { targetId } = config;

    let link: IFavoriteLink;

    // Is adding a new link
    if ('href' in config) {
      const { name, href } = config;

      link = createLink({ name, href });
    } else {
      const { id, folderId } = config;

      if (folderId) {
        const folder = draft.find(
          (f) => f.id === folderId
        ) as IFavoriteLinksFolder;

        [link] = folder.links.splice(
          folder.links.findIndex((l) => l.id === id),
          1
        ) as IFavoriteLink[];
      } else {
        [link] = draft.splice(
          draft.findIndex((l) => l.id === id),
          1
        ) as IFavoriteLink[];
      }
    }

    const targetLinkIndex = draft.findIndex((l) => l.id === targetId);
    const [targetLink] = draft.splice(targetLinkIndex, 1) as IFavoriteLink[];

    const folderName = generateNewFolderName(draft);

    const folder: IFavoriteLinksFolder = {
      kind: 'folder',
      id: uuid(),
      name: folderName,
      links: [targetLink, link],
    };

    draft.splice(targetLinkIndex, 0, folder);
  });
}
