import Typography from '@mui/material/Typography';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data/lib/hooks/data-provider/useDataProvider';

import { EMailTemplateGroup } from '@work4all/models/lib/Classes/EMailTemplateGroup.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type IEMailTemplateGroupProps<TMultiple extends boolean> = IPickerProps<
  EMailTemplateGroup,
  TMultiple
>;

export function EMailTemplateGroupPicker<TMultiple extends boolean>(
  props: IEMailTemplateGroupProps<TMultiple>
) {
  const { data, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...DATA_FIELDS, ...data };
  }, [data]);

  const request = useMemo<DataRequest>(() => {
    return {
      data: allFields,
      entity: Entities.eMailTemplateGroup,
      completeDataResponse: true,
      operationName: 'GetEmailTemplateGroups',
    };
  }, [allFields]);

  const response = useDataProvider(request);

  // The data provider does not set the `total` property when
  // `completeDataResponse` is `true`.   So we have to set it manually.
  // Otherwise, the <ListEntityPicker /> will not be able to render the list.
  const patchedResponse = useMemo(() => {
    return { ...response, total: response.data.length };
  }, [response]);

  const renderItem = (priceGroup: EMailTemplateGroup) => {
    const label = priceGroup.name;

    return (
      <Tooltip title={label}>
        <Typography variant="body2" noWrap>
          {label}
        </Typography>
      </Tooltip>
    );
  };

  return (
    <ListEntityPicker
      entity={Entities.eMailTemplateGroup}
      data={allFields}
      filterBy={'note'}
      sortBy="note"
      {...rest}
      fixedDataSet={patchedResponse}
      renderItemContent={renderItem}
    />
  );
}

const DATA_FIELDS: EMailTemplateGroup = {
  id: null,
  name: null,
};
