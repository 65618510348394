import { IUser } from '../hooks/useUser';

export enum UserActions {
  REFRESH = '[UserAction] Refresh',
  LOGOUT = '[UserAction] Logout',
  UPDATE = '[UserAction] Update',
  REFRESH_TOKEN = '[UserAction] Refresh Token',
  CHANGE_TENANT = '[UserAction] Change tenant',
  REPLICATE_USER_COLS = '[REPLICATE_USER_COLS] Replicate the users collections',
}

export interface UserAction {
  type: UserActions;
}

export interface RefreshUserAction extends UserAction {
  type: UserActions.REFRESH;
  data: {
    id: number;
  };
}

export const refreshUserMeta = (userId: number): RefreshUserAction => {
  return {
    type: UserActions.REFRESH,
    data: {
      id: userId,
    },
  };
};

export const updateUserData = (data: Partial<IUser>) => {
  return {
    type: UserActions.UPDATE,
    data,
  };
};

export const replicateUserCollections = (tenantId: number, stop = false) => {
  return {
    type: UserActions.REPLICATE_USER_COLS,
    data: {
      tenantId,
      stop,
    },
  };
};

export const logoutUser = (): UserAction => {
  return {
    type: UserActions.LOGOUT,
  };
};

export interface RefreshTokenAction extends UserAction {
  type: UserActions.REFRESH_TOKEN;
}

export const refreshTokenAction = (): RefreshTokenAction => {
  return {
    type: UserActions.REFRESH_TOKEN,
  };
};

export interface ChangeTenantAction extends UserAction {
  type: UserActions.CHANGE_TENANT;
  data: {
    tenantId: number;
  };
}

export const changeTenant = (tenantId: number): ChangeTenantAction => ({
  type: UserActions.CHANGE_TENANT,
  data: {
    tenantId,
  },
});
