import styles from './styles.module.scss';

import { Backdrop, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ServersIcon } from '@work4all/assets/icons/servers.svg';

import { Modal } from '@work4all/components/lib/components/modal/Modal';

const titleId = 'api-update-required-modal-title';
const descriptionId = 'api-update-required-modal-description';

interface ApiUpdateRequiredAlertProps {
  open: boolean;
  onConfirm: () => void;
}

export function ApiUpdateRequiredAlert({
  open,
  onConfirm,
}: ApiUpdateRequiredAlertProps) {
  const { t } = useTranslation();

  return (
    <Modal
      aria-labelledby={titleId}
      aria-describedby={descriptionId}
      open={open}
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500, className: styles.backdrop }}
      sx={{
        zIndex: 9999,
      }}
    >
      <div className={styles.root}>
        <div className={styles.content}>
          <ServersIcon className={styles.icon} />

          <div>
            <Typography
              id={titleId}
              className={styles.title}
              variant="h4"
              component="h2"
            >
              {t('APP_UPDATES.API_UPDATE_REQUIRED.TITLE')}
            </Typography>

            <Typography
              id={descriptionId}
              className={styles.description}
              variant="body2"
            >
              {t('APP_UPDATES.API_UPDATE_REQUIRED.DESCRIPTION')}
            </Typography>
          </div>
        </div>

        <div className={styles.actions}>
          <Button size="large" color="primary" fullWidth onClick={onConfirm}>
            {t('USER.SIGN_OUT')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
