import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class TicketKind<T extends EMode = EMode.entity> {
  /** Alias: iD */
  id?: number;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: name */
  name?: string;
  /** Alias: updateTime */
  updateTime?: string;

  __typename?: string;
}

const fields: FieldDefinitions<TicketKind> = {
  id: {
    alias: 'iD',
  },
  insertTime: {
    alias: 'insertTime',
  },
  name: {
    alias: 'name',
  },
  updateTime: {
    alias: 'updateTime',
  },
  __typename: {
    alias: '__typename',
  },
};

export const ticketKindEntityDefinition: EntitiyDefinition<TicketKind> = {
  local: {},
  remote: {
    queryName: 'getTicketArten',
    fragmentName: 'TicketArt',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
