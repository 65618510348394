import styles from './table/Table.module.scss';

import AddIcon from '@mui/icons-material/Add';
import { IconButton, Stack } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';
import { TextmarkPickerResult } from '@work4all/components/lib/components/entity-picker/textmark-picker/TextmarkPicker';
import { WordDocumentTemplatePreviewContainer } from '@work4all/components/lib/components/entity-preview/template-preview/WordDocumentTemplatePreviewContainer';
import { PDFTextmarkConf } from '@work4all/components/lib/preview/pdf-textmarks/PDFTextmarkContainer';
import { Heading4 } from '@work4all/components/lib/typography/heading4/Heading4';

import { useUser } from '@work4all/data';

import { DocumentTemplateGroup } from '@work4all/models/lib/Classes/DocumentTemplateGroup.entity';
import { WordDocumentTemplate } from '@work4all/models/lib/Classes/WordDocumentTemplate.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { DndTypes } from '@work4all/utils/lib/variables';

import { useDocumentTemplateSettings } from '../../containers/files/detail/components/queries/use-document-template-settings';
import { TextmarkPickerButton } from '../entity-picker/TextmarkPickerButton';
import { WordDocumentTemplateGroupPicker } from '../filters/WordDocumentTemplateGroupPicker';

import { EntityTable } from './EntityTable';
import schema from './schemata/wordDocumentTemplate-table-schema.json';
import { Table } from './table/Table';
import { TableLayoutContext, useTableLayoutState } from './table-layout';
import { TableNoRowsPlaceholder } from './TableNoRowsPlaceholder';
import { useDataTable } from './use-data-table';
import { useDeleteEntitiesToolbarConfig } from './use-delete-entities-toolbar-config';
import { useTableMaskHandlers } from './use-table-mask-handlers';
import { useUserColumnConfigs } from './use-user-column-configs';

type Props = EntityTable;

type IDocumentTemplateCustomColumns = Record<string, never>;

const defaultSort = [{ field: 'body', direction: SortDirection.ASCENDING }];
const manualGroupBy = true;

export const WordDocumentTemplateTable = React.forwardRef<TableInstance, Props>(
  function WordDocumentTemplateTable(props, ref) {
    const { prefilter, onOpenMask, forceRequestFields, ...rest } = props;
    const { entity: entityType } = schema as never;

    const tableStateBag = useTableStateBag();

    const layoutState = useTableLayoutState();
    const [layout] = layoutState;

    const {
      columnConfigs,
      prepareRowDisplayModifiers,
      data,
      fetchMore,
      total,
      selectedEntity,
      initialSortBy,
      pending,
    } = useDataTable<WordDocumentTemplate, IDocumentTemplateCustomColumns>({
      layout,
      schema: schema as never,
      tableStateBag,
      prefilter,
      defaultSort,
      forceRequestFields,
    });

    const maskHandlers = useTableMaskHandlers(entityType, onOpenMask);

    const deleteConfig = useDeleteEntitiesToolbarConfig({
      entityType,
    });

    const [userConfig, userConfigMethods] = useUserColumnConfigs({
      layout,
      entityType,
      columnConfigs,
    });

    const user = useUser();

    const { settings, update: updateSettings } = useDocumentTemplateSettings(
      selectedEntity?.id,
      Entities.wordDocumentTemplate
    );

    const isPDF = useMemo(() => {
      const segments = selectedEntity?.templateFilename.split('.');
      return segments && segments[segments.length - 1].toLowerCase() === 'pdf';
    }, [selectedEntity?.templateFilename]);

    const handleTextmarkPickerChange = useCallback(
      async (value: TextmarkPickerResult) => {
        if (!value) {
          return;
        }

        updateSettings({
          pdfTextmarkItems: [
            ...settings.pdfTextmarkItems,
            {
              id: value.textmark.id,
              left: 0.5,
              top: 0.25,
              title: value.textmark.name,
              type: DndTypes.BOX,
              accessor: value.textmark.accessor,
              pageNumber: 1,
            },
          ],
        });
      },
      [settings.pdfTextmarkItems, updateSettings]
    );

    const pdfTextmarkConf = useMemo<PDFTextmarkConf>(() => {
      return {
        pdfTextMarkItems: settings.pdfTextmarkItems,
        onItemsChange: (items) => updateSettings({ pdfTextmarkItems: items }),
        disabled: !user.isMaster,
      };
    }, [settings.pdfTextmarkItems, updateSettings, user.isMaster]);

    if (!userConfig) return null;

    return (
      <TableLayoutContext value={layoutState}>
        <Table
          pending={pending}
          noRowsRenderer={() => <TableNoRowsPlaceholder />}
          ref={ref}
          areas={{
            left: {
              content: <WordDocumentTemplateTableQuickFilters />,
            },
            right: selectedEntity !== null && {
              content: (
                <WordDocumentTemplatePreviewContainer
                  wordDocumentTemplateId={selectedEntity.id}
                  onCloseClicked={() =>
                    tableStateBag.tableInstance.toggleAllRowsSelected(false)
                  }
                  onEditClicked={maskHandlers.edit.handler}
                  pdfTextmarkConf={isPDF && pdfTextmarkConf}
                  additionalTitleItems={
                    user.isMaster &&
                    isPDF && (
                      <TextmarkPickerButton
                        onChange={handleTextmarkPickerChange}
                        context="document"
                      />
                    )
                  }
                />
              ),
            },
          }}
          actions={{
            add: maskHandlers.create,
            edit: maskHandlers.edit,
            convert: {
              exclude: Entities.wordDocumentTemplate,
              handler: maskHandlers.convert,
            },
            remove: deleteConfig,
            resetColumns: userConfigMethods.remove,
          }}
          columnConfigs={userConfig}
          manualGroupBy={manualGroupBy}
          initialSortBy={initialSortBy}
          loadMoreItems={fetchMore}
          prepareRowDisplayModifiers={prepareRowDisplayModifiers}
          data={data}
          total={total}
          {...rest}
        />
      </TableLayoutContext>
    );
  }
);

function WordDocumentTemplateTableQuickFilters() {
  const { t } = useTranslation();

  const tableStateBag = useTableStateBag();

  const columns = {
    group: tableStateBag.columnsById['groupId'],
  };

  const groups = columns.group?.filterValue?.value ?? null;

  const handleGroupsChange = (groups: DocumentTemplateGroup[]) => {
    const column = columns.group;
    if (!column) {
      return;
    }

    if (groups.length === 0) {
      column.setFilter(null);
    } else {
      column.setFilter({
        value: groups,
        filterType: column.filterType,
      });
    }
  };

  return (
    <Stack height="100%">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <div className={styles.filterHeadline}>
          <Heading4>{t('FIELDS.folder')}</Heading4>
        </div>
        <IconButton color="primary" disabled>
          <AddIcon />
        </IconButton>
      </Stack>

      <div className="custom-scrollbar" style={{ flex: 1, overflow: 'auto' }}>
        <WordDocumentTemplateGroupPicker
          value={groups}
          onChange={handleGroupsChange}
        />
      </div>
    </Stack>
  );
}
