export enum IndividualFieldControlType {
  NUMERIC = 'NUMERIC',
  CHECK = 'CHECK',
  RADIO = 'RADIO',
  TEXT = 'TEXT',
  DATE_TYPE = 'DATE_TYPE',
  CAPTION = 'CAPTION',
  COMBO = 'COMBO',
  LIST = 'LIST',
  LEERSPALTE = 'LEERSPALTE',
  ZEILENUMBRUCH = 'ZEILENUMBRUCH',
  COUNTER = 'COUNTER',
  USER_EXIT_BUTTON = 'USER_EXIT_BUTTON',
  LINK = 'LINK',
}
