import packageJson from '../package.json';

export const APP_VERSION = packageJson.version;

const DEFAULT_MINIMUM_API_VERSION = '5.1.299.0';

export const MINIMUM_API_VERSION =
  process.env.MINIMUM_API_VERSION ?? DEFAULT_MINIMUM_API_VERSION;

export const WORK4ALL_CUSTOMER_ID = 12345;
