export function parseNameAndExtension(filename = ''): {
  extension: string;
  name: string;
} {
  if (!filename?.includes('.')) {
    return { extension: '', name: filename || '' };
  }

  const index = filename.lastIndexOf('.');

  const name = filename.slice(0, index);
  const extension = filename.slice(index + 1);

  return {
    extension,
    name,
  };
}
