import styles from './DisplayError.module.scss';

import clsx from 'clsx';
import React from 'react';

import { Heading1 } from '../../../../../../typography/heading1/Heading1';

export const DisplayError: React.FC<{
  isDisplayError: boolean;
  errorMsg?: string;
}> = (props) => {
  if (!props.isDisplayError || !props.errorMsg) {
    return null;
  }

  return (
    <div className={clsx(styles.displayError)}>
      <Heading1>Camera error: {props.errorMsg}</Heading1>
    </div>
  );
};
