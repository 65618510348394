import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SalesOpportunityStatus } from '@work4all/models/lib/Enums/SalesOpportunityStatus.enum';

import { FixedDataPicker } from '../fixed-data-picker/FixedDataPicker';
import { IPickerProps } from '../types';

interface SalesOpportunityStatusOption {
  id: SalesOpportunityStatus;
  name: string;
}

export type ISalesOpportunitiesStatusPickerProps<TMultiple extends boolean> =
  IPickerProps<SalesOpportunityStatusOption, TMultiple>;

export function SalesOpportunitiesStatusPicker<TMultiple extends boolean>(
  props: ISalesOpportunitiesStatusPickerProps<TMultiple>
) {
  const { t } = useTranslation();

  const dataSet: SalesOpportunityStatusOption[] = useMemo(() => {
    const result = Object.values(SalesOpportunityStatus).map((x) => ({
      id: x,
      name: t(`COMMON.SALESOPPORTUNITIES.${x}`),
    }));
    return result;
  }, [t]);

  return <FixedDataPicker dataSet={dataSet} {...props} />;
}
