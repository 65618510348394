import styles from './AddFileMenuMobile.module.scss';

import AddIcon from '@mui/icons-material/Add';
import { Menu, MenuItem, Theme, useMediaQuery } from '@mui/material';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import ListItemText from '@mui/material/ListItemText';
import clsx from 'clsx';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Link, useWidgetsDataBag } from '@work4all/data/lib/hooks';
import { usePermissions } from '@work4all/data/lib/hooks/use-permissions';
import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { ILayoutDefinition } from '@work4all/models';

import { reactRefSetter } from '@work4all/utils';

import { useWidgetConfig } from '../card-widgets/CardWidgetContainer';
import { useEntityLinks } from '../card-widgets/hooks/useEntityLinks';

export interface AddFileMenuMobileProps {
  buttonRef?: React.ForwardedRef<HTMLButtonElement>;
}

interface MenuActionProps {
  definition: ILayoutDefinition;
  closeMenu: () => void;
}

export function MenuAction(props: MenuActionProps) {
  const { definition, closeMenu } = props;
  const { t } = useTranslation();

  const { entityType } = useWidgetConfig(definition);
  const { untypedPermissions } = usePermissions();
  const canAdd = untypedPermissions(entityType).canAdd();

  const { newHref } = useEntityLinks(definition, entityType);

  const disabled = !canAdd;
  return (
    <Link to={newHref} className={styles.noLinkStyles}>
      <MenuItem
        disabled={disabled}
        href={newHref}
        className={styles.item}
        onClick={closeMenu}
      >
        <ListItemText>{t(definition.title)}</ListItemText>
      </MenuItem>
    </Link>
  );
}

export function AddFileMenuMobile(props: AddFileMenuMobileProps) {
  const { buttonRef } = props;

  const { widgetsDefinitions } = useWidgetsDataBag();
  const { t } = useTranslation();

  const menuButtonRef = useRef<HTMLButtonElement>(null);

  const { handleClick, ...menuState } = usePopoverState('top');
  const { onClose: closeMenu } = menuState;

  const lg = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'));

  const components = widgetsDefinitions
    ? widgetsDefinitions.definition.map((x) =>
        x.widgets.length ? (
          <div key={x.id} className={styles.container}>
            {[
              <Divider
                key={`${x.id}_divider`}
                className={styles.divider}
                textAlign="left"
              >
                {t(x.name)}
              </Divider>,
              ...x.widgets.map((def) => (
                <MenuAction
                  key={def.id}
                  definition={def}
                  closeMenu={closeMenu}
                />
              )),
            ]}
          </div>
        ) : null
      )
    : [];

  return (
    <>
      <Fab
        ref={reactRefSetter(buttonRef, menuButtonRef)}
        color="primary"
        size="medium"
        onClick={handleClick}
        className={clsx({ [styles.leftBottom]: lg, [styles.rightBottom]: !lg })}
      >
        <AddIcon className={styles.iconLight} />
      </Fab>
      <Menu classes={{ paper: styles.addFileMenu }} {...menuState}>
        {components}
      </Menu>
    </>
  );
}
