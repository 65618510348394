import { addRxPlugin, createRxDatabase, RxDatabase } from 'rxdb';
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election';
import { RxDBLocalDocumentsPlugin } from 'rxdb/plugins/local-documents';
import { observeNewCollections } from 'rxdb-hooks';
import { getRxStorageIndexedDB } from 'rxdb-premium/plugins/storage-indexeddb';

import {
  createLocalDocuments,
  createSharedCollections,
} from '@work4all/data/lib/db';

let db: RxDatabase;

export const bootstrapDatabase = async () => {
  if (process.env.NODE_ENV !== 'production') {
    const { RxDBDevModePlugin } = await import('rxdb/plugins/dev-mode');
    addRxPlugin(RxDBDevModePlugin);
  }

  addRxPlugin(RxDBLeaderElectionPlugin);
  addRxPlugin(RxDBLocalDocumentsPlugin);
  addRxPlugin(observeNewCollections);

  db = await createRxDatabase({
    name: 'work4allv2',
    storage: getRxStorageIndexedDB(), //the licence of this premium package has been aquired by atroo for this project
    // native apps do not share the database in differente processes, so set to false in hybrid
    multiInstance: true, // <- multiInstance (optional, default: true)
    eventReduce: true, // <- queryChangeDetection (optional, default: false)
    localDocuments: true, // Enable local document on the DB instance itself.
  });

  await Promise.all([createLocalDocuments(db), createSharedCollections(db)]);

  return db;
};

export const getDatabase = () => db;
