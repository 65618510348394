import { useEffect, useMemo, useState } from 'react';

import {
  IStackItem,
  StackProvider,
} from '@work4all/components/lib/navigation/history-stack';
import { Content } from '@work4all/components/lib/navigation/navigation-overlay/components/content/Content';

import {
  CalendarUsersDialogContext,
  CalendarUsersDialogContextValue,
} from './calendar-users-dialog-context';
import { View1 } from './View1';

interface CalendarUsersDialogProps extends CalendarUsersDialogContextValue {
  open: boolean;
  onClose: () => void;
}

export function CalendarUsersDialog(props: CalendarUsersDialogProps) {
  const {
    open,
    onClose,
    users,
    onUsersChange,
    onUsersClear,
    focusedUserIds,
    onFocusedUserIdsChange,
    views,
    activeView,
    onSelectView,
    onCreateView,
    onDeleteView,
    calendarCardColorBy,
  } = props;

  const [initialView, setInitialView] = useState<IStackItem>(null);

  useEffect(() => {
    if (open) {
      setInitialView({
        view: <View1 />,
      });
    }
  }, [open]);

  const handleExited = () => {
    setInitialView(null);
  };

  const context = useMemo(() => {
    const context: CalendarUsersDialogContextValue = {
      users,
      onUsersChange,
      onUsersClear,
      focusedUserIds,
      onFocusedUserIdsChange,
      views,
      activeView,
      onSelectView,
      onCreateView,
      onDeleteView,
      calendarCardColorBy,
    };

    return context;
  }, [
    users,
    onUsersChange,
    onUsersClear,
    focusedUserIds,
    onFocusedUserIdsChange,
    views,
    activeView,
    onSelectView,
    onCreateView,
    onDeleteView,
    calendarCardColorBy,
  ]);

  return (
    <CalendarUsersDialogContext.Provider value={context}>
      <StackProvider onClose={onClose} initialView={initialView}>
        {initialView && (
          <Content open={open} close={onClose} onExited={handleExited} />
        )}
      </StackProvider>
    </CalendarUsersDialogContext.Provider>
  );
}
