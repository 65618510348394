import { ControllerPlus } from '../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../form-plus/use-form-context-plus';
import { CheckboxRadioItem } from '../../../../locked-inputs';
import { WithCustomFields } from '../with-custom-fields';

import { CustomFieldProps } from './types';

export function CustomCheckboxField(props: CustomFieldProps) {
  const { name, label } = props;

  const { control } = useFormContextPlus<WithCustomFields<object>>();

  return (
    <ControllerPlus
      control={control}
      name={name}
      render={({ field: { value, ...others } }) => {
        return (
          <CheckboxRadioItem
            control="checkbox"
            label={label}
            checked={value}
            {...others}
          />
        );
      }}
    />
  );
}
