import { Theme, useMediaQuery } from '@mui/material';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  IProjectProcessPickerProps,
  ProjectProcessPicker,
} from '@work4all/components/lib/components/entity-picker/project-process-picker/ProjectProcessPicker';

import { reactRefSetter } from '@work4all/utils';

import { LabeledInput } from '../../containers/mask-overlays/locked-inputs';

interface ProjectProcessPickerFieldProps
  extends Omit<IProjectProcessPickerProps<false>, 'multiple'> {
  error?: string;
  name?: string;
}

export const ProjectProcessPickerField = React.forwardRef<
  HTMLDivElement,
  ProjectProcessPickerFieldProps
>(function ProjectProcessPickerField(props, ref) {
  const { error, ...pickerProps } = props;
  const { value, onChange } = pickerProps;

  const { t } = useTranslation();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );

  return (
    <div ref={reactRefSetter(wrapperRef, ref)}>
      <EntityPickerPopover
        fullscreen={isMobile}
        anchorEl={wrapperRef}
        picker={<ProjectProcessPicker {...pickerProps} multiple={false} />}
      >
        <LabeledInput
          value={value?.process ?? ''}
          name={props.name}
          label={t('COMMON.PROJECTPROCESS')}
          error={error}
          onClear={() => {
            onChange(null);
          }}
        />
      </EntityPickerPopover>
    </div>
  );
});
