import { DateTime } from 'luxon';

const stringToTime = (str: string) => {
  return DateTime.fromFormat(str, 'HH:mm');
};

const validateTimeString = (timeString?: string) => {
  if (!timeString) {
    return false;
  }

  const dt = stringToTime(timeString);
  return dt.isValid ? dt : false;
};

const timeToString = (time?: DateTime) => {
  if (!time) {
    return '';
  }
  return time.toFormat('HH:mm');
};

export const timeUtils = { stringToTime, validateTimeString, timeToString };
