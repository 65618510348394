import { useDataProvider } from '@work4all/data';
import { nameof } from '@work4all/data/lib/helper/nameof';
import { useEntityChanges } from '@work4all/data/lib/hooks/use-entity-changed';

import { Notification } from '@work4all/models/lib/Classes/Notification.entity';
import { DataRequest, SortDirection } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ObjectType } from '@work4all/models/lib/Enums/ObjectType.enum';

const data: Notification<EMode.query> = {
  id: null,
};

const request: DataRequest = {
  operationName: 'GetUnreadNotifications',
  entity: Entities.notification,
  data,
  filter: [{ isRead: { $eq: false } }],
  sort: [
    {
      field: nameof<Notification>('insertTime'),
      direction: SortDirection.DESCENDING,
    },
  ],
};

export function useUnreadNotifications(): { total: number } {
  const query = useDataProvider<Notification>(request, false, 0);

  // Refetch unread notifications whenever a notification is created/updated.
  useEntityChanges({
    entity: Entities.mention,
    objectsType: [ObjectType.UNREAD_NOTIFICATION],
    onEntityChanged() {
      query.refetch();
    },
  });

  const { total } = query;

  return { total };
}
