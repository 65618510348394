import styles from './ControlViewLeft.module.scss';

import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import React, { useCallback } from 'react';

import { ResizableArea } from '@work4all/components/lib/components/ResizableArea';
import { ExpandButton } from '@work4all/components/lib/input/expand-button/ExpandButton';

import { settings, useSetting } from '../../../settings';

export interface ControlViewLeftProps {
  children: React.ReactNode;
  resizable?: boolean;
  collapseConfig?: {
    title: string;
    isCollapsed: boolean;
    onCollapsedChanged: () => void;
  };
}

export const ControlViewLeft = ({
  children,
  resizable = false,
  collapseConfig,
}: ControlViewLeftProps) => {
  const settingsSize = useSetting(settings.leftAreaSize());

  const renderExpandButton = useCallback(() => {
    if (!collapseConfig) return null;

    return (
      <ExpandButton
        icon={collapseConfig.isCollapsed ? <ChevronRight /> : <ChevronLeft />}
        textStart="top"
        title={collapseConfig.title}
        color="text03"
        onClick={collapseConfig.onCollapsedChanged}
      />
    );
  }, [collapseConfig]);

  return collapseConfig && collapseConfig.isCollapsed ? (
    renderExpandButton()
  ) : (
    <ResizableArea
      minWidth={250}
      maxWidth={500}
      size={{ width: settingsSize.value.width }}
      onResize={(size) => settingsSize.set(size)}
      handles={resizable ? 'right' : undefined}
    >
      <div className={styles.control}>
        {collapseConfig ? (
          <div className={styles.wrapper}>
            <div className={styles.children}>{children}</div>
            {renderExpandButton()}
          </div>
        ) : (
          children
        )}
      </div>
    </ResizableArea>
  );
};
