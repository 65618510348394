import styles from './LinkPreview.module.scss';

import React from 'react';

export interface ILinkPreviewProps {
  children: React.ReactNode;
}

export function LinkPreview(props: ILinkPreviewProps) {
  return <div className={styles.linkPreview}>{props.children}</div>;
}
