import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { FileEntity } from './FileEntity.entity';

export class TempFile<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: dateiname */
  fileName?: string;
  /** Alias: datum */
  date?: string;
  /** Alias: fileInfos */
  fileInfos?: FileEntity<T>;
  /** Alias: objectIdentificationHash */
  objectIdentificationHash?: string;

  __typename?: string;
}

const fields: FieldDefinitions<TempFile> = {
  id: {
    alias: 'id',
  },
  fileName: {
    alias: 'dateiname',
  },
  date: {
    alias: 'datum',
  },
  fileInfos: {
    alias: 'fileInfos',
    entity: Entities.fileEntity,
  },
  objectIdentificationHash: {
    alias: 'objectIdentificationHash',
  },
  __typename: {
    alias: '__typename',
  },
};

export const tempFileEntityDefinition: EntitiyDefinition<TempFile> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'TempDatei',
    withPaginationWrapper: false,
    mutations: {
      upsert: {
        mutationName: 'createTempFile',
        args: [
          { name: 'type', type: 'CreateTempFileOriginType!' },
          { name: 'id', type: 'String!' },
        ],
      },
    },
  },
  fieldDefinitions: fields,
};
