import React from 'react';

const EraserIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="6.08887"
        y="19.0387"
        width="16.1633"
        height="1.97634"
        fill="#EEC764"
      />
      <path
        d="M12.7779 4.48114C13.1626 4.09645 13.7863 4.09645 14.171 4.48114L20.354 10.6641C20.7386 11.0488 20.7386 11.6725 20.354 12.0572L16.6693 15.7419C16.2846 16.1266 15.6609 16.1266 15.2762 15.7419L9.09321 9.55892C8.70851 9.17423 8.70852 8.55053 9.0932 8.16584L12.7779 4.48114Z"
        fill="#EEC764"
        stroke="#EEC764"
        strokeWidth="2"
      />
      <path
        d="M13.8489 4.80305L20.0942 11.0484L10.1276 21.015H12.9349L21.4979 12.4521C22.2731 11.6769 22.2731 10.42 21.4979 9.64477L15.2525 3.3994C14.4773 2.62419 13.2205 2.62419 12.4452 3.3994L1.67028 14.1744C0.895063 14.9496 0.895064 16.2064 1.67028 16.9817L5.70363 21.015H8.51093L3.07393 15.578L13.8489 4.80305Z"
        fill="#EEC764"
      />
    </svg>
  );
};

export default EraserIcon;
