import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { LedgerAccount } from './LedgerAccount.entity';
import { User } from './User.entity';

export class InboundInvoicePayment<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: inboundInvoiceCode */
  inboundInvoiceCode?: number;
  /** Alias: date */
  date?: string;
  /** Alias: account */
  account?: string;
  /** Alias: text */
  text?: string;
  /** Alias: amount */
  amount?: number;
  /** Alias: ledgerAccountCode */
  ledgerAccountCode?: number;
  /** Alias: ledgerAccountNumber */
  ledgerAccountNumber?: number;
  /** Alias: datevDate */
  datevDate?: string;
  /** Alias: userCode */
  userCode?: number;
  /** Alias: bookingDate */
  bookingDate?: string;
  /** Alias: user */
  user?: User<T>;
  /** Alias: ledgerAccount */
  ledgerAccount?: LedgerAccount<T>;

  __typename?: string;
}

const fields: FieldDefinitions<InboundInvoicePayment> = {
  id: {
    alias: 'code',
  },
  inboundInvoiceCode: {
    alias: 'inboundInvoiceCode',
  },
  date: {
    alias: 'date',
  },
  account: {
    alias: 'account',
  },
  text: {
    alias: 'text',
  },
  amount: {
    alias: 'amount',
  },
  ledgerAccountCode: {
    alias: 'ledgerAccountCode',
  },
  ledgerAccountNumber: {
    alias: 'ledgerAccountNumber',
  },
  datevDate: {
    alias: 'datevDate',
  },
  userCode: {
    alias: 'userCode',
  },
  bookingDate: {
    alias: 'bookingDate',
  },
  user: {
    alias: 'user',
    entity: Entities.user,
  },
  ledgerAccount: {
    alias: 'ledgerAccount',
    entity: Entities.ledgerAccount,
  },
  __typename: {
    alias: '__typename',
  },
};

export const inboundInvoicePaymentEntityDefinition: EntitiyDefinition<InboundInvoicePayment> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InboundInvoicePayment',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
