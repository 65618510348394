import { useDebugValue, useMemo } from 'react';

import { CustomFieldDefinition } from '@work4all/models/lib/Classes/CustomFieldDefinition.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { IndividualFieldTableType } from '@work4all/models/lib/Enums/IndividualFieldTableType.enum';

import { useDataProvider } from '../../lib/hooks/data-provider/useDataProvider';

import { parseCustomFieldDefinitions } from './parse-custom-field-definitions';
import { ParsedCustomFieldConfig } from './types';

type CustomFieldEntityTypeOverrides = 'contact:customer' | 'contact:supplier';

type CustomFieldEntityType = Entities | CustomFieldEntityTypeOverrides;

export type UseCustomFieldsConfigOptions = {
  entity: CustomFieldEntityType;
};

export function useCustomFieldsConfig(
  options: UseCustomFieldsConfigOptions
): ParsedCustomFieldConfig[] {
  const { customFields } = useCustomFieldsConfigExtended(options);
  return customFields;
}

export function useCustomFieldsConfigExtended(
  options: UseCustomFieldsConfigOptions
): {
  loading: boolean;
  customFields: ParsedCustomFieldConfig[];
} {
  const { entity } = options;

  const request = useMemo(() => {
    const tableType = resolveTableType(entity);

    const request: DataRequest = {
      operationName: `GetCustomFields`,
      entity: Entities.customFieldDefinition,
      data: CUSTOM_FIELD_DEFINITION_FIELDS,
      vars: { tableType },
      completeDataResponse: true,
      skip: !tableType,
    };

    return request;
  }, [entity]);

  const { loading, data: fields } =
    useDataProvider<CustomFieldDefinition>(request);

  const customFields = useMemo(() => {
    return parseCustomFieldDefinitions(fields);
  }, [fields]);

  useDebugValue({ entity, disabled: request.skip, customFields });

  return { loading, customFields };
}

const CUSTOM_FIELD_DEFINITION_FIELDS: CustomFieldDefinition = {
  id: null,
  name: null,
  fieldType: null,
  fieldNumber: null,
};

const TABLE_TYPE_MAP: Partial<
  Record<CustomFieldEntityType, IndividualFieldTableType>
> = {
  'contact:customer': IndividualFieldTableType.K_ANSPRECH_P,
  'contact:supplier': IndividualFieldTableType.L_ANSPRECH_P,
  [Entities.note]: IndividualFieldTableType.NOTIZEN,
  [Entities.task]: IndividualFieldTableType.AUFGABEN,
  [Entities.callMemo]: IndividualFieldTableType.TELEFONATE,
  [Entities.ticket]: IndividualFieldTableType.TICKET,
  [Entities.customer]: IndividualFieldTableType.KUNDEN,
  [Entities.supplier]: IndividualFieldTableType.LIEFERANTEN,
  [Entities.project]: IndividualFieldTableType.PROJEKTE,
  [Entities.timeTracking]: IndividualFieldTableType.ZEITERFASSUNG,
  [Entities.appointment]: IndividualFieldTableType.TERMINE,
  [Entities.salesOpportunities]: IndividualFieldTableType.VERKAUFSCHANCEN,
  [Entities.deliveryNote]: IndividualFieldTableType.ERP,
  [Entities.inboundDeliveryNote]: IndividualFieldTableType.ERP,
  [Entities.calculation]: IndividualFieldTableType.ERP,
  [Entities.invoice]: IndividualFieldTableType.ERP,
  [Entities.contract]: IndividualFieldTableType.ERP,
  [Entities.offer]: IndividualFieldTableType.ERP,
  [Entities.order]: IndividualFieldTableType.ERP,
  [Entities.demand]: IndividualFieldTableType.ERP,
};

function resolveTableType(
  entity: CustomFieldEntityType
): IndividualFieldTableType | null {
  // TODO Check if this is still relevant.
  // Return null instead of crashing because some entity masks share a
  // common controller component (e.g. CRM), and not all of the entities
  // necessarily have custom fields configured.
  return TABLE_TYPE_MAP[entity] ?? null;
}
