/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum SortOrderStyle {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

//==============================================================
// END Enums and Input Objects
//==============================================================
