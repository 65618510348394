import styles from './Sorter.module.scss';

import React from 'react';

import { ReactComponent as DownIcon } from '@work4all/assets/icons/arrow_drop_down.svg';
import { ReactComponent as UpIcon } from '@work4all/assets/icons/arrow_drop_up.svg';

import { ColumnInstance } from '../../../../types';

interface ISorterProps {
  column: ColumnInstance;
}

export const Sorter: React.FC<ISorterProps> = (props) => {
  return (
    <div className={styles.sorter} {...props.column.getSortByToggleProps?.()}>
      {props.column.isSorted ? (
        props.column.isSortedDesc ? (
          <DownIcon className={styles.icon} />
        ) : (
          <UpIcon className={styles.icon} />
        )
      ) : undefined}
      <div className={styles.content}>{props.children}</div>
    </div>
  );
};
