import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { ArticleImage } from './ArticleImage.entity';
import { File } from './File.entity';

export type ArticleImageUnion<T extends EMode = EMode.entity> =
  T extends EMode.entity
    ? File<T> | ArticleImage<T>
    : {
        /** Alias: Datei */
        file?: File<T>;
        /** Alias: Artikelbild */
        articleImage?: ArticleImage<T>;
      };

const fields: FieldDefinitions<ArticleImageUnion<EMode.query>> = {
  file: {
    alias: 'file',
    entity: Entities.file,
  },
  articleImage: {
    alias: 'articleImage',
    entity: Entities.articleImage,
  },
};

export const articleImageUnionEntityDefinition: EntitiyDefinition<
  ArticleImageUnion<EMode.query>
> = {
  kind: 'union',
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'ArtikelBildUnion',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
