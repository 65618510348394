import styles from './FileListGallery.module.scss';

import { FileListGalleryItem } from './FileListGalleryItem';
import { FileAndThumbnail } from './types';

export interface FileListGalleryProps {
  files: FileAndThumbnail[];
  onSelect: (file: FileAndThumbnail) => void;
}

export function FileListGallery(props: FileListGalleryProps) {
  const { files, onSelect } = props;

  return (
    <div className={styles.root}>
      {files.map((file, index) => {
        return (
          <FileListGalleryItem
            key={index}
            file={file}
            onClick={() => {
              onSelect(file);
            }}
          />
        );
      })}
    </div>
  );
}
