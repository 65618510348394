import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputIncomingMailBlockedSender<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: any;
  /** Alias: address */
  address?: any;
  /** Alias: userCode */
  userCode?: any;
  /** Alias: mailboxId */
  mailboxId?: any;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputIncomingMailBlockedSender> = {
  id: {
    alias: 'id',
  },
  address: {
    alias: 'address',
  },
  userCode: {
    alias: 'userCode',
  },
  mailboxId: {
    alias: 'mailboxId',
  },
  customFields: {
    alias: 'customFields',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputIncomingMailBlockedSenderEntityDefinition: EntitiyDefinition<InputIncomingMailBlockedSender> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputIncomingMailBlockedSender',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
