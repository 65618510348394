import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { SendMailErrorType } from '../Enums/SendMailErrorType.enum';

export class SendEMailResult<T extends EMode = EMode.entity> {
  /** Alias: ok */
  ok?: boolean;
  /** Alias: errorMessage */
  errorMessage?: string;
  /** Alias: errorType */
  errorType?: SendMailErrorType;

  __typename?: string;
}

const fields: FieldDefinitions<SendEMailResult> = {
  ok: {
    alias: 'ok',
  },
  errorMessage: {
    alias: 'errorMessage',
  },
  errorType: {
    alias: 'errorType',
  },
  __typename: {
    alias: '__typename',
  },
};

export const sendEMailResultEntityDefinition: EntitiyDefinition<SendEMailResult> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'SendEMailResult',
      withPaginationWrapper: false,
      mutations: {
        upsert: {
          mutationName: 'sendMail',
          args: [
            { name: 'eMailCode', type: 'Int!' },
            { name: 'mailServiceId', type: 'ID' },
            { name: 'senderMailAddress', type: 'String' },
          ],
        },
      },
    },
    fieldDefinitions: fields,
  };
