import styles from './EntityCell.module.scss';

import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { CellProps } from 'react-table';

import { useObjectTypeLookUp } from '@work4all/components/lib/components/entity-picker/object-type-picker/useObjectTypeLookUp';

import { ChronoFileItem } from '@work4all/models/lib/Classes/ChronoFileItem.entity';
import { ChronoFileItemUnion } from '@work4all/models/lib/Classes/ChronoFileItemUnion.entity';
import { EMail } from '@work4all/models/lib/Classes/EMail.entity';
import { EMailKind } from '@work4all/models/lib/Enums/EMailKind.enum';
import { ObjectType } from '@work4all/models/lib/Enums/ObjectType.enum';

export interface IEntityCellProps extends CellProps<ChronoFileItem> {
  value: ObjectType;
}

export const EntityCell = (props: IEntityCellProps) => {
  const entity = props.cell.row.original.fileItem;
  let incoming = false;
  if (isEmail(entity)) {
    incoming =
      entity.kind === EMailKind.EINGEHEND ||
      entity.kind === EMailKind.EINGEHEND_HTML;
  }

  const lookUp = useObjectTypeLookUp({
    incoming,
  });

  const type = lookUp[props.value] || {
    label: 'TODO',
    icon: QuestionMarkIcon,
  };

  return (
    <div className={styles.main}>
      <div className={styles.iconDiv}>
        <type.icon className={styles.icon} />
      </div>
      {type.label}
    </div>
  );
};

function isEmail(entity: ChronoFileItemUnion): entity is EMail {
  return entity?.__typename === 'EMail';
}
