import { Theme, useMediaQuery } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';
import { EmailPreviewContainer } from '@work4all/components/lib/components/entity-preview/email-preview';

import { useEntityEvents } from '@work4all/data/lib/entity-events/use-entity-events';
import { usePermissions } from '@work4all/data/lib/hooks/use-permissions';
import { useSyncEmailStatus } from '@work4all/data/lib/hooks/use-sync-email-status';

import { EMail } from '@work4all/models/lib/Classes/EMail.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Table } from '../../components/data-tables/table/Table';
import { OnOpenMask } from '../../containers/mask-overlays/mask-overlay';
import {
  EmailActions,
  useEmailActions,
} from '../../containers/mask-overlays/mask-overlay/views/email/EmailActions';
import { PersonalAndGeneralView } from '../filters/PersonalAndGeneralView';

import { AttachmentCell } from './AttachmentCell';
import { EmailKindCell } from './EmailKindCell';
import { EmailStatusCell } from './EmailStatusCell';
import { PreviewMobileWrapper } from './PreviewMobileWrapper';
import schema from './schemata/eMail-table-schema.json';
import { TableLayoutContext, useTableLayoutState } from './table-layout';
import { TableNoRowsPlaceholder } from './TableNoRowsPlaceholder';
import { useDataTable } from './use-data-table';
import { useDeleteEntitiesToolbarConfig } from './use-delete-entities-toolbar-config';
import { useTableMaskHandlers } from './use-table-mask-handlers';
import { useUserColumnConfigs } from './use-user-column-configs';

interface Props {
  prefilter?: unknown[];
  onOpenMask: OnOpenMask;
}

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];
const manualGroupBy = true;

export type IEmailKindCellConfig = {
  type: 'EmailKind';
};

export type IAttachmentCellConfig = {
  type: 'EmailAttachment';
};

export type IEmailStatusCellConfig = {
  type: 'EmailStatus';
};

type IEmailTableCustomColumns = {
  EmailKind?: IEmailKindCellConfig;
  Attachment?: IAttachmentCellConfig;
  EmailStatus?: IEmailStatusCellConfig;
};

export const EmailsTable = React.forwardRef<TableInstance, Props>(
  function EmailsTable(props, ref) {
    const { prefilter, onOpenMask } = props;
    const { entity: entityType } = schema as never;

    const tableStateBag = useTableStateBag();

    const { permissions } = usePermissions();

    const layoutState = useTableLayoutState();
    const [layout] = layoutState;

    const {
      columnConfigs,
      cardConfig,
      prepareRowDisplayModifiers,
      data,
      fetchMore,
      total,
      selectedEntity,
      initialSortBy,
      pending,
      refetch,
    } = useDataTable<EMail, IEmailTableCustomColumns>({
      layout,
      schema: schema as never,
      cells: {
        EmailKind: EmailKindCell,
        Attachment: AttachmentCell,
        EmailStatus: EmailStatusCell,
      },
      cellProcessors: {
        EmailStatus: (config) => {
          return [config.accessor, 'saveSendMailJob.errorMessages'];
        },
      },
      tableStateBag,
      prefilter,
      defaultSort,
    });

    useSyncEmailStatus({
      shouldSync({ id }) {
        const emails = data as EMail[];
        const found = emails.find((email) => email.id === id);
        return found !== undefined;
      },
    });

    const maskHandlers = useTableMaskHandlers(entityType, onOpenMask);

    const deleteConfig = useDeleteEntitiesToolbarConfig({
      entityType,
    });

    const [userConfig, userConfigMethods] = useUserColumnConfigs({
      layout,
      entityType,
      columnConfigs,
    });

    const { emailActionDefinitions } = useEmailActions({
      onOpenMask,
      hasRight: permissions.eMail.canAdd(),
      outbound: selectedEntity?.kind === 'AUSGEHEND' ? true : false,
    });

    const isDesktop = useMediaQuery<Theme>((theme) =>
      theme.breakpoints.up('sm')
    );

    const isOutbound = (kind: string): boolean => {
      return kind === 'AUSGEHEND' ? true : false;
    };

    useEntityEvents((event) => {
      if (event.entity === Entities.eMail) {
        refetch();
      }
    });

    const { t } = useTranslation();

    if (!userConfig) return null;

    return (
      <TableLayoutContext value={layoutState}>
        <Table
          pending={pending}
          noRowsRenderer={() => <TableNoRowsPlaceholder />}
          ref={ref}
          layout={layout}
          columnConfigs={userConfig}
          cardConfig={cardConfig}
          manualGroupBy={manualGroupBy}
          initialSortBy={initialSortBy}
          loadMoreItems={fetchMore}
          areas={{
            left: {
              content: <PersonalAndGeneralView entity={Entities.eMail} />,
              resizable: true,
              collapseConfig: {
                title: t('COMMON.FILTER'),
              },
            },
            right: selectedEntity !== null && {
              content: (
                <PreviewMobileWrapper
                  active={!isDesktop}
                  actions={{
                    edit: maskHandlers.edit,
                    remove: deleteConfig,
                    additionalActionsLeft: (
                      <EmailActions
                        hasRight
                        id={selectedEntity.id}
                        onOpenMask={onOpenMask}
                        outbound={isOutbound(selectedEntity?.kind)}
                      />
                    ),
                  }}
                >
                  <EmailPreviewContainer
                    onCloseClicked={() =>
                      tableStateBag.tableInstance.toggleAllRowsSelected(false)
                    }
                    onEditClicked={maskHandlers.edit.handler}
                    emailId={selectedEntity.id}
                    onShareClicked={maskHandlers.share}
                  />
                </PreviewMobileWrapper>
              ),
            },
          }}
          actions={{
            add: maskHandlers.create,
            edit: maskHandlers.edit,
            convert: {
              exclude: entityType,
              handler: maskHandlers.convert,
            },
            remove: deleteConfig,
            resetColumns: userConfigMethods.remove,
            custom: {
              left: emailActionDefinitions,
            },
            createWidget: true,
          }}
          prepareRowDisplayModifiers={prepareRowDisplayModifiers}
          data={data}
          total={total}
        />
      </TableLayoutContext>
    );
  }
);
