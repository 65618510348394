import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Vacation } from './Vacation.entity';
import { VacationEntitlement } from './VacationEntitlement.entity';

export class VacationInfo<T extends EMode = EMode.entity> {
  /** Alias: year */
  year?: number;
  /** Alias: valueHolidayEntitlement */
  valueHolidayEntitlement?: number;
  /** Alias: vacationTransferLastYear */
  vacationTransferLastYear?: number;
  /** Alias: entitlement */
  entitlement?: VacationEntitlement<T>;
  /** Alias: vacation */
  vacation?: Vacation<T>[];
  /** Alias: vacationTaken */
  vacationTaken?: number;

  __typename?: string;
}

const fields: FieldDefinitions<VacationInfo> = {
  year: {
    alias: 'year',
  },
  valueHolidayEntitlement: {
    alias: 'valueHolidayEntitlement',
  },
  vacationTransferLastYear: {
    alias: 'vacationTransferLastYear',
  },
  entitlement: {
    alias: 'entitlement',
    entity: Entities.vacationEntitlement,
  },
  vacation: {
    alias: 'vacation',
    entity: Entities.vacation,
  },
  vacationTaken: {
    alias: 'vacationTaken',
  },
  __typename: {
    alias: '__typename',
  },
};

export const vacationInfoEntityDefinition: EntitiyDefinition<VacationInfo> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'VacationInfo',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
