import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { REBelegart } from '../Enums/REBelegart.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputEingangsrechnung<T extends EMode = EMode.entity> {
  /** Alias: bankkontoCode */
  bankkontoCode?: number;
  /** Alias: bankverbindungCode */
  bankverbindungCode?: number;
  /** Alias: belegart */
  belegart?: REBelegart;
  /** Alias: benutzerCode */
  benutzerCode?: number;
  /** Alias: bezahltSumme */
  bezahltSumme?: number;
  /** Alias: buchungsDatum */
  buchungsDatum?: string;
  /** Alias: code */
  code?: number;
  /** Alias: creationDate */
  creationDate?: string;
  /** Alias: datev */
  datev?: string;
  /** Alias: datum */
  datum?: string;
  /** Alias: dMSDokumentenID */
  dMSDokumentenID?: string;
  /** Alias: dTANr */
  dTANr?: number;
  /** Alias: editBenutzerCode */
  editBenutzerCode?: number;
  /** Alias: editDate */
  editDate?: string;
  /** Alias: eingangsDatum */
  eingangsDatum?: string;
  /** Alias: eingangslieferscheinCode */
  eingangslieferscheinCode?: number;
  /** Alias: faelligDatum */
  faelligDatum?: string;
  /** Alias: frei1 */
  frei1?: number;
  /** Alias: freigabeBCode */
  freigabeBCode?: number;
  /** Alias: freigabeDatum */
  freigabeDatum?: string;
  /** Alias: freigabeNotiz */
  freigabeNotiz?: string;
  /** Alias: genehmigungDurchBCode */
  genehmigungDurchBCode?: number;
  /** Alias: kostenstelle */
  kostenstelle?: number;
  /** Alias: kurs */
  kurs?: number;
  /** Alias: lieferdatum */
  lieferdatum?: string;
  /** Alias: lieferdatumGeplant */
  lieferdatumGeplant?: string;
  /** Alias: mahnstuffe */
  mahnstuffe?: number;
  /** Alias: notiz */
  notiz?: string;
  /** Alias: objGrCode */
  objGrCode?: number;
  /** Alias: positionstext */
  positionstext?: string;
  /** Alias: projektCode */
  projektCode?: number;
  /** Alias: rBetrag */
  rBetrag?: number;
  /** Alias: rBetragErstellt */
  rBetragErstellt?: number;
  /** Alias: rBetragFremd */
  rBetragFremd?: number;
  /** Alias: referenz */
  referenz?: string;
  /** Alias: reisekostenabrechnung */
  reisekostenabrechnung?: number;
  /** Alias: rESachkGesamt */
  rESachkGesamt?: number;
  /** Alias: rMwst */
  rMwst?: number;
  /** Alias: rMwstErstellt */
  rMwstErstellt?: number;
  /** Alias: rNettoErstellt */
  rNettoErstellt?: number;
  /** Alias: rNummer */
  rNummer?: number;
  /** Alias: rNummerbeiLieferant */
  rNummerbeiLieferant?: string;
  /** Alias: sDObjMemberCode */
  sDObjMemberCode?: number;
  /** Alias: skonto */
  skonto?: number;
  /** Alias: skonto2 */
  skonto2?: number;
  /** Alias: skonto2Datum */
  skonto2Datum?: string;
  /** Alias: skonto2Tg */
  skonto2Tg?: number;
  /** Alias: skontoDatum */
  skontoDatum?: string;
  /** Alias: skontoProzent */
  skontoProzent?: number;
  /** Alias: skontoTg */
  skontoTg?: number;
  /** Alias: sperrungBcode */
  sperrungBcode?: number;
  /** Alias: statusCode */
  statusCode?: number;
  /** Alias: stornSumme */
  stornSumme?: number;
  /** Alias: summe */
  summe?: number;
  /** Alias: templatename */
  templatename?: string;
  /** Alias: tmpSkonto */
  tmpSkonto?: number;
  /** Alias: ursprungBezSumme */
  ursprungBezSumme?: number;
  /** Alias: ursprungMwst */
  ursprungMwst?: number;
  /** Alias: ursprungRBetrag */
  ursprungRBetrag?: number;
  /** Alias: verrechnetCode */
  verrechnetCode?: number;
  /** Alias: waehrungCode */
  waehrungCode?: number;
  /** Alias: wiederkehrend */
  wiederkehrend?: number;
  /** Alias: zahlungsCode */
  zahlungsCode?: number;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];
  /** Alias: paymentTermDays */
  paymentTermDays?: number;
  /** Alias: createdByUserCode */
  createdByUserCode?: number;

  __typename?: string;
}

const fields: FieldDefinitions<InputEingangsrechnung> = {
  bankkontoCode: {
    alias: 'bankkontoCode',
  },
  bankverbindungCode: {
    alias: 'bankverbindungCode',
  },
  belegart: {
    alias: 'belegart',
  },
  benutzerCode: {
    alias: 'benutzerCode',
  },
  bezahltSumme: {
    alias: 'bezahltSumme',
  },
  buchungsDatum: {
    alias: 'buchungsDatum',
  },
  code: {
    alias: 'code',
  },
  creationDate: {
    alias: 'creationDate',
  },
  datev: {
    alias: 'datev',
  },
  datum: {
    alias: 'datum',
  },
  dMSDokumentenID: {
    alias: 'dMSDokumentenID',
  },
  dTANr: {
    alias: 'dTANr',
  },
  editBenutzerCode: {
    alias: 'editBenutzerCode',
  },
  editDate: {
    alias: 'editDate',
  },
  eingangsDatum: {
    alias: 'eingangsDatum',
  },
  eingangslieferscheinCode: {
    alias: 'eingangslieferscheinCode',
  },
  faelligDatum: {
    alias: 'faelligDatum',
  },
  frei1: {
    alias: 'frei1',
  },
  freigabeBCode: {
    alias: 'freigabeBCode',
  },
  freigabeDatum: {
    alias: 'freigabeDatum',
  },
  freigabeNotiz: {
    alias: 'freigabeNotiz',
  },
  genehmigungDurchBCode: {
    alias: 'genehmigungDurchBCode',
  },
  kostenstelle: {
    alias: 'kostenstelle',
  },
  kurs: {
    alias: 'kurs',
  },
  lieferdatum: {
    alias: 'lieferdatum',
  },
  lieferdatumGeplant: {
    alias: 'lieferdatumGeplant',
  },
  mahnstuffe: {
    alias: 'mahnstuffe',
  },
  notiz: {
    alias: 'notiz',
  },
  objGrCode: {
    alias: 'objGrCode',
  },
  positionstext: {
    alias: 'positionstext',
  },
  projektCode: {
    alias: 'projektCode',
  },
  rBetrag: {
    alias: 'rBetrag',
  },
  rBetragErstellt: {
    alias: 'rBetragErstellt',
  },
  rBetragFremd: {
    alias: 'rBetragFremd',
  },
  referenz: {
    alias: 'referenz',
  },
  reisekostenabrechnung: {
    alias: 'reisekostenabrechnung',
  },
  rESachkGesamt: {
    alias: 'rESachkGesamt',
  },
  rMwst: {
    alias: 'rMwst',
  },
  rMwstErstellt: {
    alias: 'rMwstErstellt',
  },
  rNettoErstellt: {
    alias: 'rNettoErstellt',
  },
  rNummer: {
    alias: 'rNummer',
  },
  rNummerbeiLieferant: {
    alias: 'rNummerbeiLieferant',
  },
  sDObjMemberCode: {
    alias: 'sDObjMemberCode',
  },
  skonto: {
    alias: 'skonto',
  },
  skonto2: {
    alias: 'skonto2',
  },
  skonto2Datum: {
    alias: 'skonto2Datum',
  },
  skonto2Tg: {
    alias: 'skonto2Tg',
  },
  skontoDatum: {
    alias: 'skontoDatum',
  },
  skontoProzent: {
    alias: 'skontoProzent',
  },
  skontoTg: {
    alias: 'skontoTg',
  },
  sperrungBcode: {
    alias: 'sperrungBcode',
  },
  statusCode: {
    alias: 'statusCode',
  },
  stornSumme: {
    alias: 'stornSumme',
  },
  summe: {
    alias: 'summe',
  },
  templatename: {
    alias: 'templatename',
  },
  tmpSkonto: {
    alias: 'tmpSkonto',
  },
  ursprungBezSumme: {
    alias: 'ursprungBezSumme',
  },
  ursprungMwst: {
    alias: 'ursprungMwst',
  },
  ursprungRBetrag: {
    alias: 'ursprungRBetrag',
  },
  verrechnetCode: {
    alias: 'verrechnetCode',
  },
  waehrungCode: {
    alias: 'waehrungCode',
  },
  wiederkehrend: {
    alias: 'wiederkehrend',
  },
  zahlungsCode: {
    alias: 'zahlungsCode',
  },
  insertTime: {
    alias: 'insertTime',
  },
  updateTime: {
    alias: 'updateTime',
  },
  customFields: {
    alias: 'customFields',
  },
  paymentTermDays: {
    alias: 'paymentTermDays',
  },
  createdByUserCode: {
    alias: 'createdByUserCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputEingangsrechnungEntityDefinition: EntitiyDefinition<InputEingangsrechnung> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputEingangsrechnung',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
