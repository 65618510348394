import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDataProvider } from '@work4all/data';

import { VacationKind } from '@work4all/models/lib/Classes/VacationKind.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { APPROVED_COLOR, SICKNESS_COLOR } from '../constants';

export const useKinds = () => {
  const { t } = useTranslation();

  const dataRequest = useMemo<DataRequest>(() => {
    return {
      operationName: 'GetVacationKind',
      entity: Entities.vacationKind,
      data: {
        id: null,
        name: null,
        color: null,
      } as VacationKind,
      completeDataResponse: true,
    };
  }, []);

  const request = useDataProvider<VacationKind>(dataRequest);

  const kinds = useMemo(() => {
    const defaults = [
      {
        id: 'vacation',
        color: APPROVED_COLOR,
        name: t('COMMON.VACATION_KIND_NULL_LABEL'),
      },
      {
        id: 'sickness',
        color: SICKNESS_COLOR,
        name: t('COMMON.SICKNESS'),
      },
    ];

    return [...defaults, ...request.data] as VacationKind[];
  }, [request.data, t]);

  return { kinds };
};
