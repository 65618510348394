import styles from './Logo.module.scss';

import React from 'react';

import logo from '@work4all/assets/icons/logo.png';

export const Logo: React.FC = () => {
  return (
    <div className={styles.logo}>
      <img src={logo} alt="work4all logo" />
    </div>
  );
};
