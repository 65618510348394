import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { ProcessedEMailTemplateAttachment } from './ProcessedEMailTemplateAttachment.entity';

export class ProcessedEMailTemplate<T extends EMode = EMode.entity> {
  /** Alias: subject */
  subject?: string;
  /** Alias: body */
  body?: string;
  /** Alias: createdAttachementTempfiles */
  createdAttachementTempfiles?: ProcessedEMailTemplateAttachment<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<ProcessedEMailTemplate> = {
  subject: {
    alias: 'subject',
  },
  body: {
    alias: 'body',
  },
  createdAttachementTempfiles: {
    alias: 'createdAttachementTempfiles',
    entity: Entities.processedEMailTemplateAttachment,
  },
  __typename: {
    alias: '__typename',
  },
};

export const processedEMailTemplateEntityDefinition: EntitiyDefinition<ProcessedEMailTemplate> =
  {
    local: {},
    remote: {
      queryName: 'getProcessedMailTemplateWithSubject',
      fragmentName: 'ProcessedEMailVorlagen',
      withPaginationWrapper: false,
      params: [
        { name: 'templateId', type: 'ID' },
        { name: 'templateContent', type: 'String' },
        { name: 'signatureId', type: 'ID' },
        { name: 'textFieldUserCode', type: 'Int' },
        { name: 'textFieldCustomerCode', type: 'Int' },
        { name: 'textFieldSupplierCode', type: 'Int' },
        { name: 'textFieldProjectCode', type: 'Int' },
        { name: 'textFieldApCode', type: 'Int' },
        { name: 'textFieldApSdObjType', type: 'SdObjType' },
        { name: 'textFieldTicketId', type: 'ID' },
        { name: 'textFieldMailContactIds', type: '[String]' },
        { name: 'textFieldCustom', type: '[StringKeyValue]' },
      ],
    },
    fieldDefinitions: fields,
  };
