import { capitalize } from '@mui/material/utils';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { AssignableEntityResult } from '../../../utils/use-assignable-template-entity';

export function calculateToMailContacts(
  template: AssignableEntityResult
): string | undefined {
  const getEmailFromContact = (contact: Contact) => {
    const typeName = capitalize(
      (
        contact?.businessPartnerType ||
        template.data.businessPartnerType ||
        SdObjType.LIEFERANT
      ).toLowerCase()
    );

    if (contact) {
      const id = `${typeName}Ap_${contact.id}`;
      return JSON.stringify(
        contact?.eMail
          ? [
              {
                id,
                mailAddress: contact.eMail,
                contact: contact,
              },
            ]
          : []
      );
    }
  };

  const getEmailFromCompany = () => {
    const bp = template.data.businessPartner?.data;
    if (bp?.eMail && bp?.id) {
      const id = `${bp.__typename}_${bp.id}`;
      return JSON.stringify(
        bp?.eMail
          ? [
              {
                id,
                mailAddress: bp?.eMail,
              },
            ]
          : []
      );
    }
  };

  if (template.data.contact) {
    if (template.data.contact?.eMail) {
      return getEmailFromContact(template.data.contact);
    } else {
      const result = getEmailFromCompany();
      return (
        result ||
        getEmailFromContact(template.data.businessPartner?.data.mainContact)
      );
    }
  }
  if (template.data.businessPartner?.data.mainContact) {
    return getEmailFromContact(template.data.businessPartner?.data.mainContact);
  }
  return getEmailFromCompany();
}
