import { DateTime } from 'luxon';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';

export function filterArray(contacts: Contact[]): Contact[] {
  const today = DateTime.now().set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  const nextWeek = today.plus({ week: 1 });

  const filteredContacts = contacts.filter((contact) => {
    const birthdayDate = DateTime.fromISO(contact.birthdayDate).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    return (
      birthdayDate >= today &&
      birthdayDate <= nextWeek &&
      contact.businessPartner.data.extinct !== -1
    );
  });

  return filteredContacts.sort((a, b) => {
    const birthdayDateA = DateTime.fromISO(a.birthdayDate);
    const birthdayDateB = DateTime.fromISO(b.birthdayDate);

    if (birthdayDateA.month === birthdayDateB.month) {
      return birthdayDateA.day - birthdayDateB.day;
    } else {
      return birthdayDateA.month - birthdayDateB.month;
    }
  });
}
