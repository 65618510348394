import { RxJsonSchema } from 'rxdb';

import { jsonSchemaEntity } from './jsonSchemaEntity';
import { layoutEntity } from './layoutEntity';
import { settingEntity } from './settingEntity';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface EntityDescriptor<T = any> {
  name: string;
  schema: RxJsonSchema<T>;
}

export const syncEntities: EntityDescriptor[] = [];

export const syncTenantSpecificEntities: EntityDescriptor[] = [
  layoutEntity,
  jsonSchemaEntity,
  settingEntity,
];
