import styles from './Schedule.module.scss';

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';

import { useProjectCaptions } from '../../../../../../../../../../hooks/useProjectCaptions';
import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { DateTimeInputPicker } from '../../../../../../../../locked-inputs';
import { ControlWrapper } from '../../../../../../../components/control-wrapper';
import { ProjectMaskFormValue } from '../../../../../types';

export const Schedule: React.FC = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<ProjectMaskFormValue>();
  const {
    projectStarDateCaption,
    projectEndDateCaption,
    eventStartDateCaption,
    eventEndDateCaption,
  } = useProjectCaptions();

  return (
    <Collapse
      defaultOpen
      title={t('MASK.SCHEDULE')}
      classes={{ wrapperInner: styles.wrapperInner }}
      className={styles.root}
      collapseContainerStyles={styles.collapseContainer}
    >
      <ControlWrapper paddingBottom={false}>
        <ControllerPlus
          name="startDateOuter"
          control={control}
          render={({ field }) => {
            return (
              <DateTimeInputPicker
                withTime={false}
                required={false}
                dateLabel={projectStarDateCaption}
                defaultValue={field?.value}
                {...field}
              />
            );
          }}
        />

        <ControllerPlus
          name="endDateOuter"
          control={control}
          render={({ field }) => {
            return (
              <DateTimeInputPicker
                withTime={false}
                required={false}
                dateLabel={projectEndDateCaption}
                defaultValue={field?.value}
                {...field}
              />
            );
          }}
        />

        <ControllerPlus
          name="startDateInner"
          control={control}
          render={({ field }) => {
            return (
              <DateTimeInputPicker
                withTime={false}
                required={false}
                dateLabel={eventStartDateCaption}
                defaultValue={field?.value}
                {...field}
              />
            );
          }}
        />

        <ControllerPlus
          name="endDateInner"
          control={control}
          render={({ field }) => {
            return (
              <DateTimeInputPicker
                withTime={false}
                required={false}
                dateLabel={eventEndDateCaption}
                defaultValue={field?.value}
                {...field}
              />
            );
          }}
        />
      </ControlWrapper>
    </Collapse>
  );
};
