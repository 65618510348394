import classes from './Design.module.scss';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonBase from '@mui/material/ButtonBase';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { useCustomBackgroundsContext, usePageZoom } from '@work4all/data';
import { useCustomSnackbar } from '@work4all/data/lib/snackbar/PresetSnackbarProvider';

import { settings, useSetting } from '../../../../settings';

export function Design() {
  const {
    activeBackground,
    standardBackgrounds,
    customBackgrounds,
    setActiveBackground,
    resetActiveBackground,
    addBackground,
    removeBackground,
  } = useCustomBackgroundsContext();

  const { t } = useTranslation();

  const { zoomLevel = 100, setZoomLevel } = usePageZoom();

  const pageTheme = useSetting(settings.pageTheme());

  return (
    <Stack direction="column" spacing={2} padding={2}>
      <Divider>{t('PAGE_THEME.SETTINGS')}</Divider>

      <div>
        <ToggleButtonGroup value={pageTheme.value} exclusive>
          <ToggleButton value="auto" onClick={() => pageTheme.set('auto')}>
            {t('PAGE_THEME.AUTO')}
          </ToggleButton>
          <ToggleButton value="light" onClick={() => pageTheme.set('light')}>
            {t('PAGE_THEME.LIGHT')}
          </ToggleButton>
          <ToggleButton value="dark" onClick={() => pageTheme.set('dark')}>
            {t('PAGE_THEME.DARK')}
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <Divider>{t('ZOOM.SETTINGS')}</Divider>

      <div>
        <ToggleButtonGroup value={zoomLevel} exclusive>
          <ToggleButton value={80} onClick={() => setZoomLevel(80)}>
            {t('ZOOM.MINI')}
          </ToggleButton>
          <ToggleButton value={90} onClick={() => setZoomLevel(90)}>
            {t('ZOOM.SMALLER')}
          </ToggleButton>
          <ToggleButton value={100} onClick={() => setZoomLevel(100)}>
            {t('ZOOM.STANDARD')}
          </ToggleButton>
          <ToggleButton value={110} onClick={() => setZoomLevel(110)}>
            {t('ZOOM.BIGGER')}
          </ToggleButton>
        </ToggleButtonGroup>
      </div>

      <Divider>{t('BACKGROUND.IMAGE.TITLE')}</Divider>

      <div className={classes.grid}>
        <BackgroundThumbnail
          active={activeBackground === null}
          onSelect={() => resetActiveBackground()}
        />

        {standardBackgrounds.map(({ id, urlThumbnail: src }) => {
          return (
            <BackgroundThumbnail
              key={id}
              active={activeBackground?.id === id}
              src={src}
              onSelect={() => setActiveBackground(id)}
            />
          );
        })}
      </div>

      <Divider>{t('BACKGROUND.IMAGE.TITLE', { context: 'own' })}</Divider>

      <div className={classes.grid}>
        {customBackgrounds.map(({ id, urlThumbnail: src }) => {
          return (
            <BackgroundThumbnail
              key={id}
              active={activeBackground?.id === id}
              src={src}
              onSelect={() => setActiveBackground(id)}
              onRemove={() => removeBackground(id)}
            />
          );
        })}

        <UploadBackgroundButton onFile={addBackground} />
      </div>
    </Stack>
  );
}

function UploadBackgroundButton({ onFile }: { onFile: (file: File) => void }) {
  const ACCEPT = ['.jpg', '.jpeg', '.gif', '.png', '.mp4'];

  const { enqueueSnackbar, hideApiErrors } = useCustomSnackbar();
  const { t } = useTranslation();

  const MB = 1000 * 1000;
  const maxSize = 2 * MB;

  const errorSnackbar = (message: string, timeout: number) => {
    enqueueSnackbar(message, {
      variant: 'error',
      autoHideDuration: timeout,
    });
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      accept: ACCEPT,
      multiple: false,
      maxSize: maxSize,
      onDrop: (acceptedFiles, fileRejections) => {
        const file = acceptedFiles[0];

        const rejectionError = fileRejections[0]?.errors[0].code;

        if (rejectionError) {
          switch (rejectionError) {
            case 'file-too-large':
              errorSnackbar(t('BACKGROUND.IMAGE.SIZE.ERROR'), 10000);
              break;
            case 'file-invalid-type':
              errorSnackbar(
                t('FILE.TYPE.ERROR', {
                  types: ACCEPT.map((type) => `'${type}'`).join(', '),
                }),
                10000
              );
              break;
            default:
              if (!hideApiErrors)
                errorSnackbar(t('ERROR.COULD_NOT_REACH_SERVICE'), 4000);
              break;
          }
        } else {
          onFile(file);
          enqueueSnackbar(t('UPLOADING.BACKGROUND'), {
            variant: 'info',
            autoHideDuration: 4000,
          });
        }
      },
    });

  return (
    <div
      {...getRootProps()}
      className={clsx({
        [classes.thumbnail]: true,
        [classes.dnd]: true,
        [classes.dragAccept]: isDragAccept,
        [classes.dragReject]: isDragReject,
      })}
    >
      <AddIcon fontSize="medium" color={isDragReject ? 'error' : 'primary'} />

      <input {...getInputProps()} />
    </div>
  );
}

interface BackgroundThumbnailProps {
  active: boolean;
  src?: string;
  onSelect: () => void;
  onRemove?: () => void;
}

function BackgroundThumbnail(props: BackgroundThumbnailProps) {
  const { active, src, onSelect, onRemove } = props;

  const rootClasses = clsx({
    [classes.thumbnail]: true,
    [classes.active]: active,
  });

  return (
    <div className={rootClasses}>
      <div style={{ width: '100%', height: '100%' }} onClick={onSelect}>
        {src ? <img className={classes.image} src={src} alt="" /> : null}
      </div>

      {onRemove && (
        <ButtonBase
          className={classes.deleteButton}
          color="primary"
          onClick={onRemove}
        >
          <DeleteIcon fontSize="medium" />
        </ButtonBase>
      )}
    </div>
  );
}
