import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { SalesOpportunities } from '@work4all/models/lib/Classes/SalesOpportunities.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export function useSalesOpportunities(customerId?: string) {
  const requestData = useMemo<DataRequest>(() => {
    const filter = [{ businessPartnerId: { $eq: customerId } }];

    return {
      entity: Entities.salesOpportunities,
      data: SALES_OPPORTUNITIES_FIELDS,
      filter,
    };
  }, [customerId]);

  const { data, pending, refetch } = useDataProvider<SalesOpportunities>(
    requestData,
    !customerId
  );
  const salesOpportunities = data;
  return { salesOpportunities, pending, refetch };
}

const SALES_OPPORTUNITIES_FIELDS: SalesOpportunities<EMode.query> = {
  id: null,
  name: null,
  note: null,
  assignmentType: null,
  businessPartner: {
    id: null,
    businessPartnerType: null,
    data: {
      customer: {
        id: null,
        name: null,
      },
      supplier: {
        id: null,
        name: null,
      },
    },
  },
  frozen: null,
  status: null,
};
