import { concat } from 'lodash';

import { DataRequest, SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { FileData } from '@work4all/models/lib/File';

import { EntityId, EntityLike } from '../../types';

export function getEntityId<T extends EntityLike>(entity: T): EntityId {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return entity?.id ?? (entity as any)?.data?.id;
}

export function toSelectionValue<T>(value: T | T[] | null): T[] {
  if (!value) return [];
  if (Array.isArray(value)) return value;
  return [value];
}

export type IPrepareRequestOptions = {
  entity: Entities;
  query: string;
  prefilter: unknown[];
  data: unknown;
  filterBy: string | string[];
  sortBy: string;
  sortByDirection?: SortDirection;
  searchHistory: FileData[];
  skip: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  vars: any;
};
export function prepareRequest({
  entity,
  query,
  prefilter,
  data,
  filterBy,
  sortBy,
  sortByDirection = SortDirection.ASCENDING,
  searchHistory,
  skip,
  vars,
}: IPrepareRequestOptions): DataRequest {
  const filterProperties = filterBy instanceof Array ? filterBy : [filterBy];
  const filterExpressions =
    query === ''
      ? []
      : filterProperties.map((field) => ({
          [field]: { $eq: `%${query}%` },
        }));
  const searchFilter =
    filterExpressions.length > 0 ? { $or: filterExpressions } : null;

  const historyFilter =
    searchHistory.length > 0 && query === ''
      ? { id: { $in: searchHistory.map((item) => item.id) } }
      : null;

  return {
    skip,
    entity,
    data,
    vars,
    sort: [{ field: sortBy, direction: sortByDirection }],
    filter: concat(...[prefilter, searchFilter, historyFilter].filter(Boolean)),
  };
}

export function filterOutById<T extends EntityLike>(
  list: T[],
  remove: T[]
): T[] {
  if (!remove.length) return list;

  const removeIds = new Set(remove.map((item) => getEntityId(item)));
  return list.filter((item) => !removeIds.has(getEntityId(item)));
}
