import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IBasicTableProps } from '@work4all/components';
import { HookedUserIcon } from '@work4all/components/lib/components/user-icon/useUserIconRegister';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import {
  DateFormatPreset,
  formatDateString,
} from '@work4all/utils/lib/date-utils/formatDateString';

import { Tab } from '../types';

export const useTabColumns = (entity: Tab) => {
  const { t } = useTranslation();
  const columns = useMemo<IBasicTableProps['columns']>(() => {
    if (entity === Entities.rELedgerAccountSplit) {
      return [
        {
          Header: t('BOOKINGS_TABLE.COLUMNS.TOTAL_NET'),
          accessor: 'valueNet',
          width: 120,
        },
        {
          Header: t('BOOKINGS_TABLE.COLUMNS.VAT'),
          accessor: 'vat',
          width: 120,
        },
        {
          Header: t('BOOKINGS_TABLE.COLUMNS.TOTAL_VAT'),
          accessor: 'vatAmount',
          width: 120,
        },
        {
          Header: t('BOOKINGS_TABLE.COLUMNS.TOTAL_GROSS'),
          accessor: 'proportionDM',
          width: 120,
        },
        {
          Header: t('BOOKINGS_TABLE.COLUMNS.TAX_KEY'),
          accessor: 'taxKey',
          width: 120,
        },
        {
          Header: t('BOOKINGS_TABLE.COLUMNS.KST'),
          accessor: 'costCenter.name',
          width: 120,
        },
        {
          Header: t('BOOKINGS_TABLE.COLUMNS.ACCOUNT_DESCRIPTION'),
          accessor: 'konto.name',
          width: 120,
        },
        {
          Header: t('BOOKINGS_TABLE.COLUMNS.COST_ACCOUNT'),
          accessor: 'konto.number',
          width: 120,
        },
        {
          Header: t('COMMON.PROJECTNUMBER'),
          accessor: 'project.id',
          width: 120,
        },
        {
          Header: t('COMMON.PROJECT'),
          accessor: 'project.name',
          width: 120,
        },
        {
          Header: t('BOOKINGS_TABLE.COLUMNS.CONTRA_ACCOUNT'),
          accessor: 'contraAccount',
          width: 120,
        },
        {
          Header: t('COMMON.NOTE'),
          accessor: 'note',
          width: 120,
        },
      ];
    }

    if (entity === Entities.inboundInvoicePayment) {
      return [
        {
          Header: t('PAYMENTS_TABLE.COLUMNS.BOOKING_DATE'),
          accessor: 'bookingDate',
          width: 120,
          Cell: (cell) =>
            formatDateString(cell?.value, DateFormatPreset.DATE_SIMPLE_2YEAR),
        },
        {
          Header: t('PAYMENTS_TABLE.COLUMNS.FINANCIAL_ACCOUNT_TRANSFER'),
          accessor: 'datevDate',
          width: 120,
        },
        {
          Header: t('PAYMENTS_TABLE.COLUMNS.ACCOUNT'),
          accessor: 'account',
          width: 120,
        },
        {
          Header: t('PAYMENTS_TABLE.COLUMNS.LEDGER_ACCOUNT_NUMBER'),
          accessor: 'ledgerAccountNumber',
          width: 120,
        },
        {
          Header: t('PAYMENTS_TABLE.COLUMNS.NOTE'),
          accessor: 'text',
          width: 120,
        },
        {
          Header: t('PAYMENTS_TABLE.COLUMNS.USER'),
          accessor: 'user',
          width: 250,
          Cell: (cell) => {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <HookedUserIcon userId={cell?.value?.id} />
                <Typography style={{ marginLeft: '12px' }}>
                  {cell?.value?.displayName}
                </Typography>
              </div>
            );
          },
        },
      ];
    }
  }, [t, entity]);

  return columns;
};
