import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as TakePictureIcon } from '@work4all/assets/icons/take_picture.svg';

import {
  BaseActionButton,
  IBaseActionButtonProps,
} from '../../../base-action-button/BaseActionButton';

export const TakePictureButton: React.FC<
  Pick<IBaseActionButtonProps, 'onClick'>
> = (props) => {
  const { t } = useTranslation();

  return (
    <BaseActionButton
      onClick={props.onClick}
      title={t('INPUTS.TAKE_PICTURE')}
      icon={<TakePictureIcon />}
    />
  );
};
