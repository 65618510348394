import { useEffect, useState } from 'react';

export function useBlobUrl(blob: Blob | null) {
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    const url = blob ? URL.createObjectURL(blob) : null;

    setUrl(url);
  }, [blob]);

  useEffect(() => {
    if (url) {
      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [url]);

  return url;
}
