export const triggerOnChange = (
  fieldInstance: HTMLInputElement,
  value: string
) => {
  if (!fieldInstance) {
    return;
  }

  // we can't set value to input directly
  // it possible that react can override value setter and use it's own setter.
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    'value'
  ).set;
  nativeInputValueSetter.call(fieldInstance, value);

  const ev1 = new Event('change', { bubbles: true });
  const ev2 = new Event('input', { bubbles: true });
  fieldInstance.dispatchEvent(ev1);
  fieldInstance.dispatchEvent(ev2);
};
