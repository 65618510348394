import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class SalesOpportunityGroup<T extends EMode = EMode.entity> {
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: code */
  id?: number;
  /** Alias: index */
  index?: number;
  /** Alias: level */
  level?: number;
  /** Alias: name */
  name?: string;

  __typename?: string;
}

const fields: FieldDefinitions<SalesOpportunityGroup> = {
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  id: {
    alias: 'code',
  },
  index: {
    alias: 'index',
  },
  level: {
    alias: 'level',
  },
  name: {
    alias: 'name',
  },
  __typename: {
    alias: '__typename',
  },
};

export const salesOpportunityGroupEntityDefinition: EntitiyDefinition<SalesOpportunityGroup> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'SalesOpportunityGroup',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
