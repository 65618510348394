import styles from './InlineData.module.scss';

import { Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import { MouseEventHandler } from 'react';

import { Tooltip } from '@work4all/components/lib/components/tooltip/Tooltip';

export interface InlineDataProps {
  label: string;
  content: React.ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
  column?: boolean;
  onlyContent?: boolean;
  onLabelClick?: () => void;
}

export const InlineData = (props: InlineDataProps) => {
  const {
    label,
    content,
    onClick,
    column = false,
    onlyContent = false,
    onLabelClick,
  } = props;

  return (
    <Stack
      onClick={onClick}
      flexDirection={column ? 'column' : 'row'}
      gap="0.5rem"
      alignItems="start"
    >
      {onlyContent ? (
        content
      ) : (
        <>
          <Tooltip title={label} enterDelay={2000} placement={'left'}>
            <Typography
              variant="body2"
              className={clsx(styles.label, {
                [styles.clickable]: onLabelClick,
              })}
              onClick={onLabelClick}
            >{`${label}:`}</Typography>
          </Tooltip>
          <Typography variant="body2">{content || '-'}</Typography>
        </>
      )}
    </Stack>
  );
};
