import { PopoverOrigin } from '@mui/material/Popover';
import { useCallback, useState } from 'react';

type Placement = {
  anchorOrigin: PopoverOrigin;
  transformOrigin: PopoverOrigin;
};

const bottomPlacement: Placement = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

const topPlacement: Placement = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
};

export const usePopoverState = (placement?: 'bottom' | 'top') => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = useCallback(
    (event?: React.MouseEvent<HTMLElement>, customAnchorEl?: HTMLElement) => {
      if (customAnchorEl) {
        setAnchorEl(customAnchorEl);
      } else if (event) {
        setAnchorEl(event.currentTarget);
      } else {
        setAnchorEl(document.body);
      }
    },
    []
  );

  const onClose = () => {
    if (anchorEl) anchorEl.focus();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  let anchorOrigin: PopoverOrigin | undefined;
  let transformOrigin: PopoverOrigin | undefined;
  if (placement === 'bottom') {
    anchorOrigin = bottomPlacement.anchorOrigin;
    transformOrigin = bottomPlacement.transformOrigin;
  } else if (placement === 'top') {
    anchorOrigin = topPlacement.anchorOrigin;
    transformOrigin = topPlacement.transformOrigin;
  }

  return {
    anchorEl,
    handleClick,
    onClose,
    open,
    anchorOrigin,
    transformOrigin,
  };
};
