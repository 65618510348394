import { Theme, useMediaQuery } from '@mui/material';
import { useCallback, useState } from 'react';

interface TooltipReturnValue {
  toggle: () => void | undefined;
  open: boolean | undefined;
  placement: 'bottom' | 'right';
  breakpoint: 'sm' | 'md' | 'lg';
}

export const useTooltip = (): TooltipReturnValue => {
  const md = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xl'));
  const sm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const [open, setOpen] = useState<boolean | undefined>(false);

  const toggle = useCallback(() => {
    setOpen((x) => !x);
  }, [setOpen]);
  return {
    breakpoint: sm ? 'sm' : md ? 'md' : 'lg',
    toggle: md ? toggle : undefined,
    open: md ? open : undefined,
    placement: sm ? 'bottom' : 'right',
  };
};
