import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { User } from './User.entity';

export class UserCollection<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: name */
  name?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: members */
  members?: User<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<UserCollection> = {
  id: {
    alias: 'id',
  },
  name: {
    alias: 'name',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  members: {
    alias: 'members',
    entity: Entities.user,
  },
  __typename: {
    alias: '__typename',
  },
};

export const userCollectionEntityDefinition: EntitiyDefinition<UserCollection> =
  {
    local: {},
    remote: {
      queryName: 'getUserCollections',
      fragmentName: 'UserCollection',
      withPaginationWrapper: true,
      mutations: {
        upsert: {
          mutationName: 'upsertUserCollection',
          args: [
            { name: 'input', type: 'InputUserCollection!' },
            { name: 'relations', type: 'InputUserCollectionRelation' },
            { name: 'drySave', type: 'Boolean' },
          ],
        },
      },
      params: [
        { name: 'querySize', type: 'Int' },
        { name: 'queryPage', type: 'Int' },
        { name: 'querySortOrder', type: 'SortOrderStyle' },
        { name: 'querySortBy', type: 'String' },
        { name: 'filter', type: 'String' },
      ],
    },
    fieldDefinitions: fields,
  };
