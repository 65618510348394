import styles from './DateTimeInputPicker.module.scss';

import clsx from 'clsx';
import { DateTime } from 'luxon';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { W4ADateFormat } from '@work4all/models/lib/additionalEnums/DateFormat.enum';

import { dateTimeFromString } from '@work4all/utils/lib/date-utils/dateTimeFromString';
import { validDate } from '@work4all/utils/lib/date-utils/validDate';
import { withNavigatorLocale } from '@work4all/utils/lib/date-utils/withNavigatorLocale';
import { reactRefSetter } from '@work4all/utils/lib/reactRefSetter';

import { Caption } from '../../typography/caption/Caption';
import { LabeledDateInputWithDropdown } from '../labeled-date-input';
import { LabeledTimeInputWithDropdown } from '../labeled-time-input';
import { MultiStepControls, Step } from '../multi-step-controls';
import { timeUtils } from '../time-input-picker/timeUtils';

import { useInputState } from './hooks/useInputState';
import { useOnChangeTrigger } from './hooks/useOnChangeTrigger';
import { usePickerVisibilityState } from './hooks/usePickerVisibilityState';
import { IDateTimePickerProps } from './types';

export const validateDateString = (dateString?: string) => {
  if (!dateString) {
    return false;
  }
  // We decided to use this custom Format
  const dt = dateTimeFromString(dateString, 'dd.MM.yyyy');
  return dt.isValid ? dt : false;
};

const dateToString = (date?: DateTime) => {
  if (!date) {
    return '';
  }

  return date.toFormat('dd.MM.yyyy');
};

const parseDateString = (
  dateString: string | Date,
  dateFromCustomString: (str: string, customFormat?: string) => DateTime,
  customFormat?: string
) => {
  let value: Date | DateTime | string;
  if (typeof dateString === 'string') {
    value = customFormat
      ? dateFromCustomString(dateString, customFormat)
      : DateTime.fromISO(dateString);
  } else {
    value = DateTime.fromJSDate(dateString);
  }

  return value && value.isValid && validDate(value.toJSDate())
    ? withNavigatorLocale(value)
    : null;
};

const EnhancedDateInput: React.FC<
  IDateTimePickerProps & {
    hiddenInputField: HTMLInputElement;
    anchorEl: HTMLDivElement;
  }
> = (props) => {
  const {
    withTime = true,
    dateLabel,
    timeLabel,
    customFormat = W4ADateFormat.DEFAULT,
    required = false,
    anchorCenterElRef,
    anchorEl,
    hiddenInputField,
    clearable = true,
    disabled,
    disabledDate,
  } = props;

  const { t } = useTranslation();
  const [activeValue, setActiveValue] = useState<string>();

  const activeRef = useRef<string>();
  activeRef.current = activeValue;

  const {
    showPicker: showDatePicker,
    onShowPicker: onShowDatePicker,
    onHidePicker: onHideDatePicker,
  } = usePickerVisibilityState();

  const {
    showPicker: showTimePicker,
    onShowPicker: onShowTimePicker,
    onHidePicker: onHideTimePicker,
  } = usePickerVisibilityState();

  const { defaultDate, defaultTime, defaultValue } = useMemo(() => {
    return {
      defaultDate: parseDateString(
        activeValue,
        dateTimeFromString,
        customFormat
      ),
      defaultTime: parseDateString(
        activeValue,
        dateTimeFromString,
        customFormat
      ),
      defaultValue: parseDateString(
        activeValue,
        dateTimeFromString,
        customFormat
      ),
    };
  }, [activeValue, customFormat]);

  const { date, setDate, dateString, ...dateInputStateProps } = useInputState(
    validateDateString,
    dateToString,
    defaultDate
  );

  const {
    date: time,
    setDate: setTime,
    dateString: timeString,
    ...timeInputProps
  } = useInputState(
    timeUtils.validateTimeString,
    timeUtils.timeToString,
    defaultTime
  );

  useOnChangeTrigger(defaultValue, hiddenInputField, date, time, customFormat);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    //this needs to be performed always as a sideeffect to be evaluated in the next renderframe
    if (hiddenInputField?.value !== activeRef.current) {
      setActiveValue(hiddenInputField?.value);
    }
  });

  return (
    <MultiStepControls>
      <Step active={true} index={0}>
        <LabeledDateInputWithDropdown
          disabled={disabled || disabledDate}
          anchorEl={anchorEl}
          anchorCenterEl={anchorCenterElRef?.current}
          required={required}
          inputProps={{
            'data-testid': props['data-testid']
              ? `${props['data-testid']}-date`
              : undefined,
          }}
          value={dateString}
          label={
            dateLabel ||
            (date
              ? t('COMMON.DATE')
              : `${t('COMMON.DATE')} / ${t('COMMON.TIME')}`)
          }
          showDropdown={showDatePicker}
          onHideDropdown={onHideDatePicker}
          onShowDropdown={(e) => {
            e.preventDefault();
            onShowDatePicker();
          }}
          onDateSelect={(date) => {
            setDate(date);
          }}
          onClear={
            clearable === true
              ? () => {
                  setDate(null);
                  // setActiveValue('');
                  // setTime(null);
                }
              : undefined
          }
          onIconClick={onShowDatePicker}
          {...dateInputStateProps}
          {...props.dateInputProps}
          style={disabledDate ? { cursor: 'default' } : null}
        />
      </Step>
      <Step
        active={
          /*date needs to be set first to activate timepicker*/ Boolean(date) &&
          withTime
        }
        index={1}
      >
        <LabeledTimeInputWithDropdown
          disabled={disabled}
          anchorEl={anchorEl}
          anchorCenterEl={anchorCenterElRef?.current}
          required={required}
          inputProps={{
            'data-testid': props['data-testid']
              ? `${props['data-testid']}-time`
              : undefined,
          }}
          value={timeString}
          label={timeLabel || t('COMMON.TIME')}
          placeholder="hh:mm"
          showDropdown={showTimePicker}
          onHideDropdown={onHideTimePicker}
          onShowDropdown={onShowTimePicker}
          onTimeSelect={(time) => {
            setTime(time);
          }}
          {...props.timeInputProps}
          {...timeInputProps}
        />
      </Step>
    </MultiStepControls>
  );
};

export const DateTimeInputPicker = React.forwardRef<
  HTMLInputElement,
  IDateTimePickerProps
>(function DateTimeInputPicker(props, ref) {
  const {
    value,
    customFormat = W4ADateFormat.DEFAULT,
    error,
    disabled,
    defaultValue: _defaultValue,
    dateLabel: _dateLabel,
    withTime: _withTime,
    timeLabel: _timeLabel,
    clearable: _clearable,
    ...inputProps
  } = props;
  const inputRef = useRef<HTMLInputElement>();
  const anchorElRef = useRef<HTMLDivElement>();
  const [mounted, setMounted] = useState(false);

  //we only do render the enhanced markup after we have the ref to our inputfield so that we can safley determine if the initial value is the default value or stems from the field
  useEffect(() => {
    setMounted(true);
  }, []);

  const getValue = useCallback(
    (value: string | Date) => {
      const val =
        value &&
        (typeof value === 'string'
          ? value
          : DateTime.fromJSDate(value).toFormat(customFormat));

      return val;
    },
    [customFormat]
  );

  return (
    <div
      ref={anchorElRef}
      className={clsx(styles.wrapper, {
        [styles.error]: error,
      })}
    >
      {error !== undefined && (
        <div className={styles.errorMessage}>
          <Caption color="error">{error}</Caption>
        </div>
      )}
      {/** input holds string (a custom dateformat or ISO by default) thats created by merging "date" and "time" objects */}
      <input
        ref={reactRefSetter(ref, inputRef)}
        {...inputProps}
        hidden
        value={getValue(value)}
        defaultValue={getValue(_defaultValue)}
      />
      {mounted && (
        <EnhancedDateInput
          {...props}
          disabled={disabled}
          anchorEl={anchorElRef.current}
          hiddenInputField={inputRef.current}
        />
      )}
    </div>
  );
});
