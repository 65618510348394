import { Theme, useMediaQuery } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { ErpPreviewContainer } from '@work4all/components/lib/components/entity-preview/erp-preview/ErpPreviewContainer';
import { useTableStateBag } from '@work4all/components/lib/dataDisplay/basic-table';

import { Demand } from '@work4all/models/lib/Classes/Demand.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { OnOpenMask } from '../../containers/mask-overlays/mask-overlay';
import { PersonalAndGeneralView } from '../filters/PersonalAndGeneralView';

import { PreviewMobileWrapper } from './PreviewMobileWrapper';
import schema from './schemata/demand-table-schema.json';
import { Table } from './table/Table';
import { TableLayoutContext, useTableLayoutState } from './table-layout';
import { TableNoRowsPlaceholder } from './TableNoRowsPlaceholder';
import { useDataTable } from './use-data-table';
import { useDeleteEntitiesToolbarConfig } from './use-delete-entities-toolbar-config';
import { useErpTableMaskHandlers } from './use-erp-table-mask-handlers';
import { useUserColumnConfigs } from './use-user-column-configs';

interface Props {
  prefilter?: unknown[];
  onOpenMask: OnOpenMask;
}

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];
const manualGroupBy = true;

export const DemandsTable = React.forwardRef<TableInstance, Props>(
  function DemandsTable(props, ref) {
    const { prefilter, onOpenMask } = props;
    const { entity: entityType } = schema as never;

    const tableStateBag = useTableStateBag();

    const layoutState = useTableLayoutState();
    const [layout] = layoutState;

    const {
      columnConfigs,
      cardConfig,
      prepareRowDisplayModifiers,
      data,
      fetchMore,
      total,
      selectedEntity,
      initialSortBy,
      pending,
    } = useDataTable<Demand, never>({
      layout,
      schema: schema as never,
      tableStateBag,
      prefilter,
      defaultSort,
    });

    const maskHandlers = useErpTableMaskHandlers(entityType, onOpenMask);

    const deleteConfig = useDeleteEntitiesToolbarConfig({
      entityType,
    });

    const [userConfig, userConfigMethods] = useUserColumnConfigs({
      layout,
      entityType,
      columnConfigs,
    });

    const isDesktop = useMediaQuery<Theme>((theme) =>
      theme.breakpoints.up('sm')
    );

    const rowModifiers = useCallback(
      (value: Demand) => {
        const modifiers = prepareRowDisplayModifiers(value);
        return {
          ...modifiers,
          isFaded: value.isClosed && value.frozen,
          isShade1: !value.isClosed && value.frozen,
        };
      },
      [prepareRowDisplayModifiers]
    );

    const { t } = useTranslation();

    if (!userConfig) return null;

    return (
      <TableLayoutContext value={layoutState}>
        <Table
          pending={pending}
          noRowsRenderer={() => <TableNoRowsPlaceholder />}
          ref={ref}
          layout={layout}
          cardConfig={cardConfig}
          areas={{
            left: {
              content: <PersonalAndGeneralView entity={Entities.demand} />,
              resizable: true,
              collapseConfig: {
                title: t('COMMON.FILTER'),
              },
            },
            right: selectedEntity !== null && {
              content: (
                <PreviewMobileWrapper
                  active={!isDesktop}
                  actions={{
                    edit: maskHandlers.edit,
                    convert: {
                      exclude: entityType,
                      handler: maskHandlers.convert,
                    },
                    remove: deleteConfig,
                  }}
                >
                  <ErpPreviewContainer
                    entityId={selectedEntity.id}
                    entity={entityType}
                    onCloseClicked={() =>
                      tableStateBag.tableInstance.toggleAllRowsSelected(false)
                    }
                    onEditClicked={maskHandlers.edit.handler}
                    onShareClicked={maskHandlers.share}
                  />
                </PreviewMobileWrapper>
              ),
            },
          }}
          actions={{
            add: maskHandlers.create,
            edit: maskHandlers.edit,
            remove: deleteConfig,
            resetColumns: userConfigMethods.remove,
            createWidget: true,
          }}
          columnConfigs={userConfig}
          manualGroupBy={manualGroupBy}
          initialSortBy={initialSortBy}
          loadMoreItems={fetchMore}
          prepareRowDisplayModifiers={rowModifiers}
          data={data}
          total={total}
        />
      </TableLayoutContext>
    );
  }
);
