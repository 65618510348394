import { useMemo } from 'react';

import { Notification } from '@work4all/models/lib/Classes/Notification.entity';

import { NotificationListItem } from '../NotificationListItem';

export interface NotificationListProps {
  items: Notification[];
  onNotificationClick: (notificationList: Notification[]) => void;
}

export function NotificationList(props: NotificationListProps) {
  const { items, onNotificationClick } = props;

  const notificationGroups = useMemo(() => {
    const result: Array<Notification[]> = [];

    items.forEach((item) => {
      const existingArray = result.find(
        (x) =>
          x[0].parentObjectType === item.parentObjectType &&
          x[0].parentObjectId === item.parentObjectId &&
          x[0].parentObjectCode === item.parentObjectCode
      );

      if (existingArray) {
        existingArray.push(item);
      } else {
        result.push([item]);
      }
    });

    return result;
  }, [items]);

  return (
    <div>
      {notificationGroups.map((notificationGroup) => {
        return (
          <NotificationListItem
            key={notificationGroup[0].id}
            notification={notificationGroup[0]}
            count={notificationGroup.length}
            onClick={() => onNotificationClick(notificationGroup)}
          />
        );
      })}
    </div>
  );
}
