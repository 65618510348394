import React, { useEffect, useMemo, useState } from 'react';

import { useNavigate } from '@work4all/data';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useLegalNotice } from '../../../../../hooks/useLegalNotice';
import { MaskModal } from '../../../../entity-picker/MaskModal';
import { useDragLayer } from '../../DragLayerProvider';

interface Props {
  entity: Entities;
  text: string;
}

export const ImprintOverlayView: React.FC<Props> = (props: Props) => {
  const { entity, text: imprintValue } = props;
  const { clear } = useDragLayer();

  const navigate = useNavigate();
  const [maskModalOpen, setMaskModalOpen] = useState(true);

  const { data } = useLegalNotice(imprintValue, !imprintValue);
  useEffect(() => {
    const inData = Array.isArray(data) ? data : [data];
    if (inData.length && entity) {
      setMaskModalOpen(true);
    }
  }, [data, entity]);

  const params = useMemo(
    () => ({
      presetFields: JSON.stringify(data),
    }),
    [data]
  );

  return (
    <MaskModal
      entity={entity}
      params={params}
      open={maskModalOpen}
      onClose={() => {
        setMaskModalOpen(false);
        clear();
      }}
      onAfterSave={(result: Customer | Supplier) => {
        setMaskModalOpen(false);
        navigate(`/${entity}s/${result.id}`);
        clear();
      }}
    />
  );
};
