import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class InputAufgabeAnhang<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: number;
  /** Alias: filename */
  filename?: string;
  /** Alias: notiz */
  notiz?: string;

  __typename?: string;
}

const fields: FieldDefinitions<InputAufgabeAnhang> = {
  code: {
    alias: 'code',
  },
  filename: {
    alias: 'filename',
  },
  notiz: {
    alias: 'notiz',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputAufgabeAnhangEntityDefinition: EntitiyDefinition<InputAufgabeAnhang> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputAufgabeAnhang',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
