import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { ObjectLockResultEnum } from '../Enums/ObjectLockResultEnum.enum';

import { SubObjectLockResult } from './SubObjectLockResult.entity';
import { User } from './User.entity';

export class ObjectLockResult<T extends EMode = EMode.entity> {
  /** Alias: application */
  application?: string;
  /** Alias: ownerCode */
  ownerCode?: number;
  /** Alias: lockResult */
  lockResult?: ObjectLockResultEnum;
  /** Alias: creationDatetime */
  creationDatetime?: string;
  /** Alias: benutzer */
  user?: User<T>;
  /** Alias: multiLockResults */
  multiLockResults?: SubObjectLockResult<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<ObjectLockResult> = {
  application: {
    alias: 'application',
  },
  ownerCode: {
    alias: 'ownerCode',
  },
  lockResult: {
    alias: 'lockResult',
  },
  creationDatetime: {
    alias: 'creationDatetime',
  },
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  multiLockResults: {
    alias: 'multiLockResults',
    entity: Entities.subObjectLockResult,
  },
  __typename: {
    alias: '__typename',
  },
};

export const objectLockResultEntityDefinition: EntitiyDefinition<ObjectLockResult> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'ObjectLockResult',
      withPaginationWrapper: false,
      mutations: {
        upsert: {
          mutationName: 'setObjectLock',
          args: [
            { name: 'objectType', type: 'ObjectType!' },
            { name: 'objectPrimaryKey', type: '[PrimaryKey]!' },
            { name: 'optimisticLock', type: 'Boolean' },
            { name: 'application', type: 'String' },
          ],
        },
      },
    },
    fieldDefinitions: fields,
  };
