import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class ManagementDashboard<T extends EMode = EMode.entity> {
  /** Alias: kennzahl */
  figure?: string;
  /** Alias: jahr */
  year?: number;
  /** Alias: monat */
  month?: number;
  /** Alias: wert */
  value?: number;
  /** Alias: rhythmus */
  rythm?: string;
  /** Alias: datumNaechsteAbrechnung */
  nextAccountingDate?: string;
  /** Alias: wV_Von */
  wVStart?: string;
  /** Alias: wV_Bis */
  wVEnd?: string;
  /** Alias: bereich */
  bereich?: string;

  __typename?: string;
}

const fields: FieldDefinitions<ManagementDashboard> = {
  figure: {
    alias: 'kennzahl',
  },
  year: {
    alias: 'jahr',
  },
  month: {
    alias: 'monat',
  },
  value: {
    alias: 'wert',
  },
  rythm: {
    alias: 'rhythmus',
  },
  nextAccountingDate: {
    alias: 'datumNaechsteAbrechnung',
  },
  wVStart: {
    alias: 'wV_Von',
  },
  wVEnd: {
    alias: 'wV_Bis',
  },
  bereich: {
    alias: 'bereich',
  },
  __typename: {
    alias: '__typename',
  },
};

export const managementDashboardEntityDefinition: EntitiyDefinition<ManagementDashboard> =
  {
    local: {},
    remote: {
      queryName: 'getManagementDashboard',
      fragmentName: 'ManagementDashboard',
      withPaginationWrapper: false,
      params: [
        { name: 'rangeStart', type: 'Date!' },
        { name: 'rangeEnd', type: 'Date' },
      ],
    },
    fieldDefinitions: fields,
  };
