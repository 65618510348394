import styles from './UpdateEntity.module.scss';

import SaveIcon from '@mui/icons-material/Save';
import WarningIcon from '@mui/icons-material/Warning';
import { Theme, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { BaseActionButton } from '@work4all/components/lib/input/base-action-button/BaseActionButton';
import { Body1 } from '@work4all/components/lib/typography/body1/Body1';

export interface UpdateEntityProps {
  content?: React.ReactNode;
}

export const UpdateEntity: React.FC<UpdateEntityProps> = (props) => {
  const { content } = props;
  const { t } = useTranslation();
  const isViewportDownMd = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );
  return (
    <div className={clsx(styles.wrap, { [styles.mobile]: isViewportDownMd })}>
      <WarningIcon className={styles.icon} />
      <Body1>{content || t('COMMON.ENTITY_NEEDS_UPDATE')}.</Body1>
      <BaseActionButton
        icon={<SaveIcon />}
        type="submit"
        title={t('COMMON.SAVE_NOW')}
      ></BaseActionButton>
    </div>
  );
};
