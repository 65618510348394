import styles from './Heading4.module.scss';

import { Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { ITypographyProps } from '..';

export const Heading4: React.FC<ITypographyProps> = (props) => {
  return (
    <Typography className={clsx(styles.heading4, props.className)} variant="h4">
      {props.children}
    </Typography>
  );
};
