import { createContext } from 'react';

import {
  AlertDialogRequiredProps,
  ConfirmDialogRequiredProps,
  CustomDialogRequiredProps,
  CustomDialogResult,
} from './types';

export interface DialogsContextValue {
  confirm(props: ConfirmDialogRequiredProps): Promise<boolean>;
  alert(props: AlertDialogRequiredProps): Promise<void>;
  open<Props extends CustomDialogRequiredProps>(
    component: React.ComponentType<Props>,
    props: Omit<Props, 'open'>
  ): CustomDialogResult;
  close(id: string): void;
}

export const DialogsContext = createContext<DialogsContextValue>(null);
