import React, { useContext } from 'react';

//there are generic controls that react to certain zoomsizes
//eg the w4a texteditor, the attachments panel and so on

export interface PageZoomCtx {
  zoomLevel: number;
  setZoomLevel: (value: number) => void;
}
export const PageZoomContext = React.createContext<PageZoomCtx | null>(null);

export const usePageZoom = () => {
  return useContext(PageZoomContext);
};
