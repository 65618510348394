import { createContext, PropsWithChildren, useContext } from 'react';

interface WidgetDragDropProps {
  value: WidgetDragDrop;
}

interface WidgetDragDrop {
  canDrag: () => boolean;
  canDrop: () => boolean;
  canSkip: () => boolean;
  onDrop: (item: unknown) => void;
}

const WidgetDragDropContext = createContext<WidgetDragDrop>({
  canDrag: () => true,
  canDrop: () => true,
  canSkip: () => true,
  onDrop: () => {},
});

export const WidgetDragDropProvider = (
  props: PropsWithChildren<WidgetDragDropProps>
) => {
  const { children, value } = props;

  return (
    <WidgetDragDropContext.Provider value={value}>
      {children}
    </WidgetDragDropContext.Provider>
  );
};

export const useWidgetDrapDropContext = () => useContext(WidgetDragDropContext);
