export const MAX_CARD_ITEMS_LENGTH = 10;

export const breakpoints = {
  xs: 0,
  sm: 600,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1920,
};

export type Breakpoint = keyof typeof breakpoints;

export enum DndTypes {
  BASIC_TABLE_COL = 'basic-table-col',
  GROUP_CHIP = 'group-chip',
  LINK = 'link',
  LINKS_FOLDER = 'links-folder',
  FAVORITE_LINK = 'favorite-link',
  FAVORITE_LINKS_FOLDER = 'favorite-links-folder',
  HTML = '__NATIVE_HTML__',
  FILE_CARD_WIDGET = 'file-card-widget',
  BOX = 'box',
}

/**
 * Delay in milliseconds before popover should open when dragging something inside it.
 */
export const DND_POPOVER_OPEN_DELAY = 500;

/**
 * Delay in milliseconds before popover should close when dragging something outside of it.
 */
export const DND_POPOVER_CLOSE_DELAY = 150;

/**
 * Delay in missilesonds before drop target should become active after mounting.
 * If a user happens to be hovering a drag item over a valid drop target
 * right after a popover opens, this drop target should not react to hover events
 * for this duration. After this delay a drag item must be moved outside of this drop
 * target and back inside for it to become active.
 */
export const DND_POPOVER_DROP_ENABLED_DELAY = 100;
