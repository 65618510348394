import { noop } from 'lodash';
import React, { useContext, useRef, useState } from 'react';
import { TableInstance, TableState } from 'react-table';

import { ColumnInstance } from '../types';

interface TableApi {
  toggleAllRowsExpanded: (value?: boolean) => void;
}

export type TableInitialState = Partial<TableInstance['state']> & {
  forceSelection?: boolean;
};

export interface ITableStateBag {
  tableState: TableState | null;
  tableInstance: TableInstance;
  visibleColumns: ColumnInstance[] | null;
  columnsById: Record<string, ColumnInstance>;
  setColumnsById: React.Dispatch<
    React.SetStateAction<ITableStateBag['columnsById']>
  >;
  setTableState: React.Dispatch<
    React.SetStateAction<ITableStateBag['tableState']>
  >;
  setVisibleColumns: React.Dispatch<
    React.SetStateAction<ITableStateBag['visibleColumns']>
  >;
  apiRef: React.MutableRefObject<TableApi>;
  underPressSelect: boolean;
  setUnderPressSelect: React.Dispatch<React.SetStateAction<boolean>>;
  searchFilterText: string;
  setSearchFilterText: React.Dispatch<
    React.SetStateAction<ITableStateBag['searchFilterText']>
  >;
  initialState?: TableInitialState;
}

const TableStateBagContext = React.createContext<ITableStateBag | null>(null);

const tryThrowBagUndefinedError = (bag: ITableStateBag | null) => {
  if (!bag) {
    throw new Error(
      'bag is undefined. Make sure you wrapped your component with <TableStateBagProvider />'
    );
  }
};

export const TableStateBagProvider: React.FC<{
  tableInstance: TableInstance | null;
  initialState?: TableInitialState;
}> = (props) => {
  const { tableInstance, initialState } = props;
  const [tableState, setTableState] =
    useState<ITableStateBag['tableState']>(null);
  const [visibleColumns, setVisibleColumns] =
    useState<ITableStateBag['visibleColumns']>(null);
  const [underPressSelect, setUnderPressSelect] = useState<boolean>(false);
  const [searchFilterText, setSearchFilterText] = useState('');
  const [columnsById, setColumnsById] = useState<ITableStateBag['columnsById']>(
    {}
  );

  const apiRef = useRef<TableApi>({
    toggleAllRowsExpanded: noop,
  });
  return (
    <TableStateBagContext.Provider
      value={{
        tableState,
        setTableState,
        visibleColumns,
        setVisibleColumns,
        setColumnsById,
        underPressSelect,
        setUnderPressSelect,
        columnsById,
        apiRef,
        tableInstance,
        searchFilterText,
        setSearchFilterText,
        initialState,
      }}
    >
      {props.children}
    </TableStateBagContext.Provider>
  );
};

export const useTableStateBag = () => {
  return useContext(TableStateBagContext);
};

export const useTableUnderPressSelect = () => {
  const bag = useTableStateBag();
  tryThrowBagUndefinedError(bag);
  return bag.underPressSelect;
};

export const useTableVisibleColumns = () => {
  const bag = useTableStateBag();
  tryThrowBagUndefinedError(bag);
  return bag.visibleColumns;
};

export const useTableState = () => {
  const bag = useTableStateBag();
  tryThrowBagUndefinedError(bag);
  return bag.tableState;
};
