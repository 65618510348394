import { useTranslation } from 'react-i18next';

type Props = {
  value: string;
};

export const SalesOpportunityStatusCell = (props: Props) => {
  const { value } = props;
  const { t } = useTranslation();
  return <div>{t(`COMMON.SALESOPPORTUNITIES.${value}`)}</div>;
};
