import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class ExchangeRate<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: waehrungCode */
  currencyId?: number;
  /** Alias: kursDatum */
  kursDatum?: string;
  /** Alias: kurs */
  course?: number;

  __typename?: string;
}

const fields: FieldDefinitions<ExchangeRate> = {
  id: {
    alias: 'code',
  },
  currencyId: {
    alias: 'waehrungCode',
  },
  kursDatum: {
    alias: 'kursDatum',
  },
  course: {
    alias: 'kurs',
  },
  __typename: {
    alias: '__typename',
  },
};

export const exchangeRateEntityDefinition: EntitiyDefinition<ExchangeRate> = {
  local: {},
  remote: {
    queryName: 'getWaehrungKurse',
    fragmentName: 'WaehrungKurs',
    withPaginationWrapper: false,
    params: [
      { name: 'waehrungCode', type: '[Int]' },
      { name: 'kursDatum', type: 'DateTimeEx' },
    ],
  },
  fieldDefinitions: fields,
};
