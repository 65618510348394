import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputProjectAccessGroupProjectRelation } from './InputProjectAccessGroupProjectRelation.entity';
import { InputProjectAccessGroupUserRelation } from './InputProjectAccessGroupUserRelation.entity';

export class InputProjectAccessGroupRelation<T extends EMode = EMode.entity> {
  /** Alias: users */
  users?: InputProjectAccessGroupUserRelation<T>;
  /** Alias: projects */
  projects?: InputProjectAccessGroupProjectRelation<T>;

  __typename?: string;
}

const fields: FieldDefinitions<InputProjectAccessGroupRelation> = {
  users: {
    alias: 'users',
  },
  projects: {
    alias: 'projects',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputProjectAccessGroupRelationEntityDefinition: EntitiyDefinition<InputProjectAccessGroupRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputProjectAccessGroupRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
