import { useEventCallback } from '@mui/material/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  IStackItem,
  StackProvider,
} from '@work4all/components/lib/navigation/history-stack';
import { Content } from '@work4all/components/lib/navigation/navigation-overlay/components/content/Content';

import { OverlayFullscreenProvider } from '@work4all/data';

import { MaskControllerProps } from '../../containers/mask-overlays/mask-overlay';
import { getMaskControllerComponent } from '../../containers/mask-overlays/mask-overlay/utils/get-mask-controller-component';

interface MaskModalProps<P extends string = string, T = unknown>
  extends MaskControllerProps<P, T> {
  open: boolean;
  onClose: () => void;
  onExited?: () => void;
}

export function MaskModal<P extends string = string, T = unknown>(
  props: MaskModalProps<P, T>
) {
  const { open, onClose, onExited, entity, id, template, params } = props;

  const onAfterSave = useEventCallback(props.onAfterSave);
  const onOpenMask = useEventCallback(props.onOpenMask);

  const { t } = useTranslation();

  const [initialView, setInitialView] = useState<IStackItem | null>(null);

  useEffect(() => {
    if (open) {
      const MaskComponent = getMaskControllerComponent(entity);

      if (!MaskComponent) {
        setInitialView(null);
        return;
      }

      setInitialView({
        view: (
          <MaskComponent
            entity={entity}
            id={id}
            template={template}
            params={params}
            onAfterSave={onAfterSave}
            onOpenMask={onOpenMask}
            amplitudeEntryPoint={props.amplitudeEntryPoint}
          />
        ),
      });
    }
  }, [
    open,
    entity,
    id,
    template,
    params,
    onAfterSave,
    onOpenMask,
    t,
    props.amplitudeEntryPoint,
  ]);

  const handleExited = () => {
    setInitialView(null);
    onExited?.();
  };

  return (
    <OverlayFullscreenProvider entityType={entity}>
      <StackProvider onClose={onClose} initialView={initialView}>
        {initialView && (
          <Content open={open} close={onClose} onExited={handleExited} />
        )}
      </StackProvider>
    </OverlayFullscreenProvider>
  );
}
