import { DateTime } from 'luxon';

import { hasWindow } from '../hasWindow';

export const withNavigatorLocale = (dt: DateTime) => {
  if (!hasWindow()) {
    return dt;
  }

  return dt.setLocale(navigator.language);
};
