import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class Salutation<T extends EMode = EMode.entity> {
  /** Alias: anredeCodeAlternativ */
  alternativeSalutationId?: number;
  /** Alias: anredeName */
  name?: string;
  /** Alias: code */
  id?: number;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: maennlich */
  isMale?: boolean;
  /** Alias: nameOderVorname */
  nameOrFirstName?: boolean;
  /** Alias: nummer */
  number?: number;
  /** Alias: standardBriefanrede */
  standardletterSalutation?: string;
  /** Alias: titel */
  title?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: weiblich */
  isFemale?: boolean;

  __typename?: string;
}

const fields: FieldDefinitions<Salutation> = {
  alternativeSalutationId: {
    alias: 'anredeCodeAlternativ',
  },
  name: {
    alias: 'anredeName',
  },
  id: {
    alias: 'code',
  },
  insertTime: {
    alias: 'insertTime',
  },
  isMale: {
    alias: 'maennlich',
  },
  nameOrFirstName: {
    alias: 'nameOderVorname',
  },
  number: {
    alias: 'nummer',
  },
  standardletterSalutation: {
    alias: 'standardBriefanrede',
  },
  title: {
    alias: 'titel',
  },
  updateTime: {
    alias: 'updateTime',
  },
  isFemale: {
    alias: 'weiblich',
  },
  __typename: {
    alias: '__typename',
  },
};

export const salutationEntityDefinition: EntitiyDefinition<Salutation> = {
  local: {},
  remote: {
    queryName: 'getAnreden',
    fragmentName: 'Anrede',
    withPaginationWrapper: false,
    params: [{ name: 'codes', type: '[Int]' }],
  },
  fieldDefinitions: fields,
};
