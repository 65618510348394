export enum IndividualFieldTableType {
  KUNDEN = 'KUNDEN',
  LIEFERANTEN = 'LIEFERANTEN',
  PROJEKTE = 'PROJEKTE',
  ARTIKEL = 'ARTIKEL',
  INVENTAR = 'INVENTAR',
  PRODUKTIONSAUFTRAG = 'PRODUKTIONSAUFTRAG',
  VERKAUFSCHANCEN = 'VERKAUFSCHANCEN',
  POSITIONEN = 'POSITIONEN',
  ERP = 'ERP',
  OBJEKTE = 'OBJEKTE',
  PROJEKTGRUPPE = 'PROJEKTGRUPPE',
  TICKET = 'TICKET',
  REISEKOSTENABRECHNUNG = 'REISEKOSTENABRECHNUNG',
  TELEFONATE = 'TELEFONATE',
  TERMINE = 'TERMINE',
  AUFGABEN = 'AUFGABEN',
  NOTIZEN = 'NOTIZEN',
  CHECKLISTE_POSITIONEN = 'CHECKLISTE_POSITIONEN',
  ZEITERFASSUNG = 'ZEITERFASSUNG',
  K_ANSPRECH_P = 'K_ANSPRECH_P',
  L_ANSPRECH_P = 'L_ANSPRECH_P',
  SERIENNUMMERN = 'SERIENNUMMERN',
  WARENEINGANG = 'WARENEINGANG',
}
