import styles from './HeaderCellContent.module.scss';

import { Box } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { TableHeading } from '../../../../../../typography/table-heading/TableHeading';
import { ColumnInstance } from '../../../../types';
import { getAlignment } from '../../../../utils/alignment';
import { Sorter } from '../sorter/Sorter';

interface IHeaderCellContent {
  col: ColumnInstance;
  classes?: {
    headerCellHeading?: string;
  };
}

export const HeaderCellContent: React.FC<IHeaderCellContent> = (props) => {
  const { col } = props;

  const alignment = getAlignment(col.alignment, col.dataType);

  const filter =
    col.canFilter && !col.isResizing && col.Filter && col.render('Filter');

  return (
    <div className={clsx(styles.content, col.headerClass)}>
      <TableHeading
        className={clsx(styles[alignment], props.classes?.headerCellHeading)}
        component="span"
      >
        {alignment === 'right' ? filter : null}
        <Sorter column={col}>
          {col.render('Header')}
          {col.required === true && (
            <Box
              component="span"
              sx={(theme) => ({ color: theme.palette.error.main })}
            >
              *
            </Box>
          )}
        </Sorter>
        {alignment !== 'right' ? filter : null}
      </TableHeading>
    </div>
  );
};
