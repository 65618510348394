import styles from './makeRowsSelectable.module.scss';

import clsx from 'clsx';
import { memo } from 'react';
import { Hooks } from 'react-table';

import { Checkbox } from '../../../input/checkbox-radio/CheckboxRadio';
import { HeaderCheckbox } from '../components/header/components/header-checkbox/HeaderCheckbox';

export const SELECT_BOX_MARKER = 'table-row-selectbox-marker';

const MemoizedCheckbox = memo(Checkbox);
const MemoizedHeaderCheckbox = memo(HeaderCheckbox);

export const SELECTION_COLUMN_ID = 'selection';

export const makeRowsSelectable = (hooks: Hooks, className?: string) => {
  const attr = { [`data-${SELECT_BOX_MARKER}`]: true };
  hooks.allColumns.push((columns) => [
    {
      id: SELECTION_COLUMN_ID,
      disableResizing: true,
      minWidth: 48,
      width: 48,
      maxWidth: 48,
      sticky: 'left',
      depth: 0,

      Header: ({ getToggleAllRowsSelectedProps }) => {
        const { checked, indeterminate, onChange } =
          getToggleAllRowsSelectedProps();

        return (
          <div>
            <MemoizedHeaderCheckbox
              className={className}
              checked={checked}
              indeterminate={indeterminate}
              onChange={onChange}
            />
          </div>
        );
      },
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }) => {
        const { checked, indeterminate } = row.getToggleRowSelectedProps();

        return (
          <div {...attr}>
            <MemoizedCheckbox
              className={clsx(styles['checkbox'], checked && styles['checked'])}
              readOnly
              checked={checked}
              indeterminate={indeterminate}
            />
          </div>
        );
      },
    },
    ...columns,
  ]);
};
