import CloseIcon from '@mui/icons-material/Close';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Dialog, IconButton, Tooltip } from '@mui/material';
import { dialogClasses } from '@mui/material/Dialog';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UserCompilationPicker } from '../../../components/entity-picker/UsersCompilationPicker';

import { UsersList, UsersListProps } from './UsersList';
import { ViewsList, ViewsListProps } from './ViewsList';

interface SidebarProps extends UsersListProps, ViewsListProps {
  onUsersClear: () => void;
  onCreateView: () => void;
}

export function Sidebar(props: SidebarProps) {
  const {
    users,
    onUsersChange,
    onUsersClear,
    focusedUserIds,
    onFocusedUserIdsChange,
    views,
    activeView,
    onSelectView,
    onCreateView,
    onDeleteView,
    calendarCardColorBy,
  } = props;

  const { t } = useTranslation();
  const [usersDialogOpen, setUsersDialogOpen] = useState(false);

  return (
    <Box
      sx={(theme) => ({
        gridArea: 'sidebar',
        borderRight: `1px solid ${theme.palette.ui4.main}`,
        minHeight: 0,
      })}
    >
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        <Dialog
          open={usersDialogOpen}
          onClose={() => setUsersDialogOpen(false)}
          maxWidth="sm"
          fullWidth
          sx={{
            [`.${dialogClasses.paper}`]: {
              height: 500,
            },
          }}
        >
          <UserCompilationPicker value={users} onChange={onUsersChange} />
        </Dialog>

        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '0.5rem 1rem',
            gap: '0.25rem',
            borderBottom: `1px solid ${theme.palette.ui4.main}`,
          })}
        >
          <Tooltip title={t('USER_PICKER.ADD_USER')}>
            <IconButton
              color="primary"
              size="medium"
              onClick={() => setUsersDialogOpen(true)}
            >
              <PersonAddIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title={t('USER_PICKER.CLEAR_SELECTION')}>
            <IconButton color="primary" size="medium" onClick={onUsersClear}>
              <CloseIcon />
            </IconButton>
          </Tooltip>

          <Tooltip
            title={
              !activeView
                ? t('USER_PICKER.SAVE_COLLECTION')
                : t('USER_PICKER.COLLECTION_ALREADY_SAVED')
            }
            placement="top-end"
          >
            <div>
              <IconButton
                color="primary"
                size="medium"
                onClick={onCreateView}
                disabled={Boolean(activeView)}
              >
                <SaveIcon />
              </IconButton>
            </div>
          </Tooltip>
        </Box>

        <Box sx={{ flex: '1 ', overflow: 'auto' }}>
          <UsersList
            users={users}
            onUsersChange={onUsersChange}
            focusedUserIds={focusedUserIds}
            onFocusedUserIdsChange={onFocusedUserIdsChange}
            calendarCardColorBy={calendarCardColorBy}
          />
        </Box>

        {views.length > 0 && (
          <Box sx={{ overflow: 'auto' }}>
            <ViewsList
              views={views}
              activeView={activeView}
              onSelectView={onSelectView}
              onDeleteView={onDeleteView}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
