import styles from './NotificationText.module.scss';

import { Typography } from '@mui/material';

export interface NotificationTextProps {
  children?: React.ReactNode;
}

export function NotificationText(props: NotificationTextProps) {
  const { children } = props;

  return (
    <div className={styles.root}>
      <Typography component="p" variant="caption" noWrap>
        {children}
      </Typography>
    </div>
  );
}
