import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { ObjectType } from '../Enums/ObjectType.enum';

import { User } from './User.entity';

export class ObjectLock<T extends EMode = EMode.entity> {
  /** Alias: application */
  application?: string;
  /** Alias: code */
  id?: number;
  /** Alias: creationDateTime */
  creationDateTime?: string;
  /** Alias: heartbeat */
  heartbeat?: string;
  /** Alias: id */
  _id?: string;
  /** Alias: objType */
  objType?: ObjectType;
  /** Alias: ownerCode */
  ownerCode?: number;
  /** Alias: benutzer */
  user?: User<T>;
  /** Alias: objPrimaryKey */
  objPrimaryKey?: any;

  __typename?: string;
}

const fields: FieldDefinitions<ObjectLock> = {
  application: {
    alias: 'application',
  },
  id: {
    alias: 'code',
  },
  creationDateTime: {
    alias: 'creationDateTime',
  },
  heartbeat: {
    alias: 'heartbeat',
  },
  _id: {
    alias: 'id',
  },
  objType: {
    alias: 'objType',
  },
  ownerCode: {
    alias: 'ownerCode',
  },
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  objPrimaryKey: {
    alias: 'objPrimaryKey',
  },
  __typename: {
    alias: '__typename',
  },
};

export const objectLockEntityDefinition: EntitiyDefinition<ObjectLock> = {
  local: {},
  remote: {
    queryName: 'getObjectLocks',
    fragmentName: 'ObjectLock',
    withPaginationWrapper: false,
    params: [{ name: 'objectTypes', type: '[ObjectType]!' }],
  },
  fieldDefinitions: fields,
};
