import {
  IFavoriteLink,
  IFavoriteLinksFolder,
  isFavoriteLink,
} from '../../../../containers/more/data/favorite-links';
import { FavoriteLinkContextMenu } from '../context-menu/FavoriteLinkContextMenu';

import { PopoverListFolderItemWrapper } from './PopoverListFolderItemWrapper';
import { PopoverListLinkItem } from './PopoverListLinkItem';

export type IPopoverListItemProps = {
  index: number;
  item: IFavoriteLink | IFavoriteLinksFolder;
  folder?: IFavoriteLinksFolder;
  activeLink: { folderId: string | null; linkId: string } | null;
  onLinkClick: (link: IFavoriteLink) => void;
  onFolderClick: (foler: IFavoriteLinksFolder) => void;
  onMove: (index: number) => void;
  onDrop: (type: unknown, item: unknown) => void;
  onDropOnLink: (link: IFavoriteLink, type: unknown, item: unknown) => void;
  onDropOnFolder: (
    folder: IFavoriteLinksFolder,
    type: unknown,
    item: unknown
  ) => void;
};

export function PopoverListItem({
  index,
  item,
  folder,
  activeLink,
  onLinkClick,
  onFolderClick,
  onMove,
  onDrop,
  onDropOnLink,
  onDropOnFolder,
}: IPopoverListItemProps) {
  return isFavoriteLink(item) ? (
    <FavoriteLinkContextMenu link={item}>
      {({ onContextMenu }) => (
        <PopoverListLinkItem
          index={index}
          folderId={folder?.id}
          link={item}
          active={activeLink?.linkId === item.id}
          onClick={onLinkClick}
          onContextMenu={onContextMenu}
          onMove={onMove}
          onDrop={onDrop}
          onDropOnLink={onDropOnLink}
        />
      )}
    </FavoriteLinkContextMenu>
  ) : (
    <PopoverListFolderItemWrapper
      index={index}
      folder={item}
      active={activeLink?.folderId === item.id}
      onClick={onFolderClick}
      onMove={onMove}
      onDrop={onDrop}
      onDropOnFolder={onDropOnFolder}
    />
  );
}
