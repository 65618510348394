import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { User } from './User.entity';

export class IncomingMailBlockedSender<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: address */
  address?: string;
  /** Alias: userCode */
  userId?: number;
  /** Alias: mailboxId */
  mailboxId?: string;
  /** Alias: user */
  user?: User<T>;

  __typename?: string;
}

const fields: FieldDefinitions<IncomingMailBlockedSender> = {
  id: {
    alias: 'id',
  },
  address: {
    alias: 'address',
  },
  userId: {
    alias: 'userCode',
  },
  mailboxId: {
    alias: 'mailboxId',
  },
  user: {
    alias: 'user',
    entity: Entities.user,
  },
  __typename: {
    alias: '__typename',
  },
};

export const incomingMailBlockedSenderEntityDefinition: EntitiyDefinition<IncomingMailBlockedSender> =
  {
    local: {},
    remote: {
      queryName: 'getIncomingMailBlockedSender',
      fragmentName: 'IncomingMailBlockedSender',
      withPaginationWrapper: false,
      mutations: {
        upsert: {
          mutationName: 'upsertIncomingMailBlockedSender',
          args: [
            { name: 'input', type: 'InputIncomingMailBlockedSender!' },
            { name: 'drySave', type: 'Boolean' },
          ],
        },
      },
      params: [{ name: 'mailboxIds', type: '[ID]' }],
    },
    fieldDefinitions: fields,
  };
