import styles from './CalendarPage.module.scss';

import { CircularProgress } from '@mui/material';
import { useState } from 'react';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';

import { Calendar } from './Calendar';
import { AppointmentMaskOverlay } from './components/AppointmentMaskOverlay';
import { CalendarPageHeader } from './components/CalendarPageHeader';
import { useCalendarURLState } from './hooks/use-calendar-url-state';
import { mapUsersToAppointmentAttendees } from './utils/map-users-to-appointment-attendees';

export function CalendarPage() {
  const [overlay, setOverlay] = useState<{
    open: boolean;
    appointmentId?: number | null;
    presetFields?: string | null;
  }>({ open: false });

  const calendar = useCalendarURLState();

  // Since we need to load the users list form the API, show a loading indicator
  // to avoid displaying an empty calendar.
  if (!calendar.isReady) {
    return (
      <div className={styles.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  const calendarProps = calendar.getCalendarProps();

  return (
    <>
      <div className={styles.page}>
        <CalendarPageHeader
          {...calendarProps}
          onCreateClick={() => {
            const { users, focusedUserIds } = calendarProps;
            const appointmentAttendees =
              focusedUserIds.length > 0
                ? users?.filter((user) =>
                    calendarProps?.focusedUserIds?.includes(user.id)
                  )
                : users;

            const template: Appointment = {
              appointmentAttendeeList:
                mapUsersToAppointmentAttendees(appointmentAttendees),
            };

            setOverlay({
              open: true,
              appointmentId: null,
              presetFields: JSON.stringify(template),
            });
          }}
        />

        <Calendar
          {...calendarProps}
          onOpenMask={(appointmentId, presetFields) => {
            setOverlay({ open: true, appointmentId, presetFields });
          }}
        />
      </div>

      <AppointmentMaskOverlay
        open={overlay.open}
        appointmentId={overlay.appointmentId}
        presetFields={overlay.presetFields}
        onClose={() => setOverlay({ open: false })}
      />
    </>
  );
}
