import styles from './FooterCellContent.module.scss';

import clsx from 'clsx';
import React from 'react';

import { ColumnInstance } from '../../../../types';
import { getAlignment } from '../../../../utils/alignment';

interface IFooterCellContent {
  col: ColumnInstance;
}

export const FooterCellContent: React.FC<IFooterCellContent> = (props) => {
  const { col } = props;

  const alignment = getAlignment(col.alignment, col.dataType);

  return (
    <div className={clsx(styles.content, styles[alignment])}>
      {col.render('Footer')}
    </div>
  );
};
