import { RxJsonSchema } from 'rxdb';

import { DbEntities } from '@work4all/models';

export interface JsonSchemaEntity {
  objectType: string;
  entityName: string;
  tenantId: number;
  jsonSchema: string;
  databaseType: string;
  updateTime: string;
}

const schema: RxJsonSchema<JsonSchemaEntity> = {
  title: DbEntities.JsonSchema,
  version: 0,
  description: 'A JSON schema to validate an entity',
  primaryKey: 'objectType',
  type: 'object',
  properties: {
    objectType: {
      type: 'string',
      maxLength: 100, // the primary key must have set maxLength
    },
    entityName: {
      type: 'string',
    },
    updateTime: {
      type: 'string',
    },
    jsonSchema: {
      type: 'string',
    },
    tenantId: {
      type: 'number',
    },
    databaseType: {
      type: 'string',
    },
  },
  required: [
    'objectType',
    'entityName',
    'updateTime',
    'jsonSchema',
    'tenantId',
    'databaseType',
  ],
};

export const jsonSchemaEntity = {
  name: DbEntities.JsonSchema,
  schema,
};
