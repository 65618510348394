import { ChangeEvent, useCallback, useMemo, useState } from 'react';

import { triggerOnChange } from '@work4all/utils/lib/triggerOnChange';

import { IInputProps, InputRefsMap, ValOrEvent } from '../types';
import { getInputKey } from '../utils/getInputKey';

/**
 * TODO (?)
 * using memo here can be a bad idea
 * if it is uncontrolled component then we can use ref
 * in case we need performance optimization I will allow to pass aditional "uncontrolled" prop
 *  */

const genInputsMap = <
  T extends {
    title: string;
    id: string;
  },
  ControlProps
>(
  inputs: IInputProps<T, ControlProps>[]
) => {
  return inputs.reduce((acc: Record<string, number>, input, i) => {
    const key = getInputKey(input);
    acc[key] = i;
    return acc;
  }, {});
};

export const useInputsStates = <
  T extends {
    title: string;
    id: string;
  },
  ControlProps
>(
  inputs: IInputProps<T, ControlProps>[],
  inputRefs: React.MutableRefObject<InputRefsMap>,
  alwaysActive?: boolean,
  orderCanCHange?: boolean
) => {
  const [inputStates, setInputStates] = useState<
    Record<string, { value?: string; selectedItem: T | null }>
  >({});

  /**
   * Writing deps like this is tradeoff.
   * I want to optimize map generation in sutations when order never changes.
   * When deps provided not as literal linter cannot statically parse them.
   * That's why I had to turn it off.
   */
  const inputPropsMapDeps = orderCanCHange ? [inputs] : [];
  const inputIndexBykey = useMemo(() => {
    return genInputsMap(inputs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, inputPropsMapDeps);

  // TODO: split this function on "setValueFromString" and "setValueFromEvent"
  const setValue = useCallback(
    (key: string, valOrEvent: ValOrEvent, data?: T) => {
      const input = inputs[inputIndexBykey[key]];
      const isSynthEvent = typeof valOrEvent !== 'string';
      const isControllable = input.value !== undefined;
      const value = isSynthEvent
        ? (valOrEvent as ChangeEvent<HTMLInputElement>).target.value
        : (valOrEvent as string);

      if (data && !isControllable) {
        /**
         * don't pass JSON to user in case if he decided to make input "controllable"
         * he can handle item selection we "onItemSelected" callback
         * in any other case safe selected data in inputRef and trigger "onChange" manually.
         */
        triggerOnChange(inputRefs.current[key].current, JSON.stringify(data));
      } else {
        /**
         * when value of input changes "onChange" event always should be triggered.
         * But in this case "change" event is not accessible to us.
         * So, we need to emulate this event.
         */
        triggerOnChange(inputRefs.current[key].current, value);
      }

      if (isControllable) {
        /**
         * this means that user made component "controllable"
         * in this case we shouldn't use local state.
         * just call "onChange" handler and allow user to set and provide input state.
         */
        return value;
      }

      setInputStates((old) => {
        return {
          ...old,
          [key]: { value, selectedItem: data || null },
        };
      });

      return value;
    },
    [inputIndexBykey, inputRefs, inputs]
  );

  const clear = useCallback(
    (key: string) => {
      const idx = inputIndexBykey[key];
      const clearInput = (input: IInputProps<T, ControlProps>) => {
        const inputKey = getInputKey(input);
        const inputRef = inputRefs.current[inputKey];
        if (!inputRef?.current) {
          return;
        }

        if (!input.onClear) {
          setValue(inputKey, '');
        } else {
          input.onClear(inputRef);
        }
      };

      if (alwaysActive) {
        clearInput(inputs[idx]);
      } else {
        /** clear inputs values that depend on current input active state */
        const slice = inputs.slice(idx);
        slice.forEach((input) => {
          clearInput(input);
        });
      }
    },
    [alwaysActive, inputIndexBykey, inputRefs, inputs, setValue]
  );

  const getValue = (key: string) => {
    if (!key) {
      return '';
    }

    const input = inputs[inputIndexBykey[key]];

    return input?.value !== undefined
      ? input.value
      : inputStates[key]?.value !== undefined
      ? inputStates[key].value
      : input?.defaultValue || '';
  };

  const getInputProps = (key: string) => {
    return inputs[inputIndexBykey[key]];
  };

  return { setValue, getValue, getInputProps, clear };
};
