import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TableLayout } from '@work4all/components/lib/components/table';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { useTableLayout } from '../../../../table-layout';

export interface TableLayoutPopoverProps {
  popoverState: ReturnType<typeof usePopoverState>;
}

const tableLayoutOptions: { value: TableLayout; label: string }[] = [
  {
    value: 'table',
    label: 'COMMON.LAYOUT_MODE_TABLE',
  },
  {
    value: 'cards',
    label: 'COMMON.LAYOUT_MODE_CARDS',
  },
];

export function TableLayoutPopover(props: TableLayoutPopoverProps) {
  const { popoverState } = props;

  const { t } = useTranslation();

  const [layout, setLayout] = useTableLayout();

  return (
    <Menu {...popoverState}>
      {tableLayoutOptions.map((option) => {
        const { value, label } = option;

        return (
          <MenuItem
            key={value}
            onClick={() => {
              setLayout(value);
            }}
          >
            <ListItemIcon>
              {layout === value ? (
                <RadioButtonCheckedIcon color="primary" />
              ) : (
                <RadioButtonUncheckedIcon />
              )}
            </ListItemIcon>
            <ListItemText primary={t(label)} />
          </MenuItem>
        );
      })}
    </Menu>
  );
}
