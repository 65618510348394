import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { User } from './User.entity';

export class SalesOpportunityRating<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: salesOpportunityCode */
  salesOpportunityCode?: number;
  /** Alias: ratingDate */
  ratingDate?: string;
  /** Alias: userCode */
  userId?: number;
  /** Alias: ratingPercent */
  ratingPercent?: number;
  /** Alias: comment */
  comment?: string;
  /** Alias: value */
  value?: number;
  /** Alias: status */
  status?: number;
  /** Alias: user */
  user?: User<T>;

  __typename?: string;
}

const fields: FieldDefinitions<SalesOpportunityRating> = {
  id: {
    alias: 'code',
  },
  salesOpportunityCode: {
    alias: 'salesOpportunityCode',
  },
  ratingDate: {
    alias: 'ratingDate',
  },
  userId: {
    alias: 'userCode',
  },
  ratingPercent: {
    alias: 'ratingPercent',
  },
  comment: {
    alias: 'comment',
  },
  value: {
    alias: 'value',
  },
  status: {
    alias: 'status',
  },
  user: {
    alias: 'user',
    entity: Entities.user,
  },
  __typename: {
    alias: '__typename',
  },
};

export const salesOpportunityRatingEntityDefinition: EntitiyDefinition<SalesOpportunityRating> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'SalesOpportunityRating',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
