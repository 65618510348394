import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { ProjectCategoryClass } from './ProjectCategoryClass.entity';

export class ProjectCategory<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: name */
  name?: string;
  /** Alias: oberkategorieCode */
  categoryClassId?: number;
  /** Alias: topLevelCategory */
  topLevelCategory?: ProjectCategoryClass<T>;

  __typename?: string;
}

const fields: FieldDefinitions<ProjectCategory> = {
  id: {
    alias: 'code',
  },
  name: {
    alias: 'name',
  },
  categoryClassId: {
    alias: 'oberkategorieCode',
  },
  topLevelCategory: {
    alias: 'topLevelCategory',
    entity: Entities.projectCategoryClass,
  },
  __typename: {
    alias: '__typename',
  },
};

export const projectCategoryEntityDefinition: EntitiyDefinition<ProjectCategory> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'ProjektKategorie',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
