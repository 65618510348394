import styles from './SignatureDialog.module.scss';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogActions,
  DialogContent,
} from '@work4all/components/lib/dialog';
import { ConfirmDialog } from '@work4all/components/lib/dialogs/ConfirmDialog';
import { BaseActionButton } from '@work4all/components/lib/input/base-action-button/BaseActionButton';

import { IAttachmentEntity } from '@work4all/models';

import { useSignatureCanvas } from './use-signature-canvas';

interface SignatureBaseDialogProps {
  onClose: () => void;
  open: boolean;
  signature?: IAttachmentEntity;
  onConfirm: (file: File) => void;
}

interface SignatureDialogProps extends SignatureBaseDialogProps {
  onDelete: () => void;
}

export const SignatureDialog = (props: SignatureDialogProps) => {
  const { open, onClose, onConfirm, signature, onDelete } = props;
  const { t } = useTranslation();

  const { canvas, getFile } = useSignatureCanvas(signature);

  return (
    <Dialog
      open={open}
      title={t('COMMON.SIGNATURE')}
      onClose={onClose}
      classes={{ dialog: { paper: styles['dialog'] } }}
    >
      <DialogContent>
        <div className={styles['signature']}>{canvas}</div>
      </DialogContent>
      {signature ? (
        <DialogActions>
          <BaseActionButton
            title={t('ALERTS.BTN_DELETE')}
            component="body2"
            color="primary"
            onClick={onDelete}
          />
        </DialogActions>
      ) : (
        <DialogActions>
          <BaseActionButton
            title={t('ALERTS.BTN_ABORT')}
            component="body2"
            color="primary"
            onClick={onClose}
          />
          <BaseActionButton
            title={t('ALERTS.BTN_SAVE')}
            component="body2"
            color="primary"
            onClick={async () => {
              const file = await getFile();
              onConfirm(file);
            }}
          />
        </DialogActions>
      )}
    </Dialog>
  );
};

interface SignatureDialogConfirmDialogProps extends SignatureBaseDialogProps {
  onDelete: (id: string | number) => void;
}

export const SignatureDialogConfirmDialog = (
  props: SignatureDialogConfirmDialogProps
) => {
  const { t } = useTranslation();
  const [isDeleteDialog, setDeleteDialog] = useState(false);

  if (isDeleteDialog && props.signature)
    return (
      <ConfirmDialog
        open={isDeleteDialog}
        title={t('COMMON.SIGNATURE_DELETE')}
        description={t('COMMON.SIGNATURE_DELETE_MESSAGE')}
        onConfirm={() => {
          props.onDelete(props.signature.id);
          setDeleteDialog(false);
        }}
        onCancel={() => setDeleteDialog(false)}
      />
    );

  return <SignatureDialog {...props} onDelete={() => setDeleteDialog(true)} />;
};
