import styles from './BanderoleInfo.module.scss';

import { IconButton, Typography } from '@mui/material';
import React from 'react';

import { ReactComponent as ClearIcon } from '@work4all/assets/icons/outline-close-24-2.svg';

export interface BanderoleInfoProps {
  visible: boolean;
  text: string;
  onClose?: () => void;
}

export const BanderoleInfo = (props: BanderoleInfoProps) => {
  const { visible, onClose: close, text } = props;
  if (!visible) return null;

  return (
    <div className={styles.banderoleContainer}>
      <Typography variant="body2" className={styles.banderole}>
        {text}
      </Typography>
      {close && (
        <IconButton size="small" onClick={close}>
          <ClearIcon />
        </IconButton>
      )}
    </div>
  );
};
