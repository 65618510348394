import styles from './VacationApproverPicker.module.scss';

import { Typography } from '@mui/material';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { useCallback, useMemo } from 'react';

import { User } from '@work4all/models/lib/Classes/User.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { UserClass } from '@work4all/models/lib/Enums/UserClass.enum';
import { UserRights } from '@work4all/models/lib/Enums/UserRights.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { HookedUserIcon } from '../../user-icon/useUserIconRegister';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type IVacationApproverPickerProps<TMultiple extends boolean> =
  IPickerProps<User, TMultiple>;

export function VacationApproverPicker<TMultiple extends boolean>(
  props: IVacationApproverPickerProps<TMultiple>
) {
  const { data, prefilter, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...USER_FIELDS, ...data };
  }, [data]);

  const renderUser = (user: User) => {
    if (props)
      return (
        <div className={styles.listItem}>
          <ListItemAvatar>
            <HookedUserIcon userId={user.id} size="l" />
          </ListItemAvatar>

          <Tooltip title={user.displayName}>
            <Typography noWrap>{user.displayName}</Typography>
          </Tooltip>
        </div>
      );
  };

  const prepareRequest = useCallback(
    function prepareRequest({ search }: { search: string }): DataRequest {
      const result: DataRequest = {
        entity: Entities.user,
        data: allFields,
        filter: [
          prefilter,
          search ? { displayName: { $eq: `%${search}%` } } : null,
          { hasLeft: { $eq: false } },
          { userKind: { $eq: UserClass.BENUTZER } },
        ]
          .filter(Boolean)
          .flat(1),
        vars: {
          filterUserRights: [
            {
              userRight: UserRights.URLAUBSGENEHMIGUNG_ERTEILEN,
              state: true,
            },
          ],
        },
      };

      return result;
    },
    [allFields, prefilter]
  );

  return (
    <ListEntityPicker<User, TMultiple>
      entity={null}
      data={null}
      filterBy={null}
      sortBy={null}
      prepareRequest={prepareRequest}
      {...rest}
      renderItemContent={renderUser}
    />
  );
}

const USER_FIELDS: User = {
  id: null,
  displayName: null,
  shortName: null,
  firstName: null,
  lastName: null,
  hasLeft: null,
};
