import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputCategoryMarkRelationAdd } from './InputCategoryMarkRelationAdd.entity';
import { InputCategoryMarkRelationModify } from './InputCategoryMarkRelationModify.entity';

export class InputCategoryMarkRelation<T extends EMode = EMode.entity> {
  /** Alias: add */
  add?: InputCategoryMarkRelationAdd<T>[];
  /** Alias: modify */
  modify?: InputCategoryMarkRelationModify<T>[];
  /** Alias: remove */
  remove?: number[];

  __typename?: string;
}

const fields: FieldDefinitions<InputCategoryMarkRelation> = {
  add: {
    alias: 'add',
  },
  modify: {
    alias: 'modify',
  },
  remove: {
    alias: 'remove',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputCategoryMarkRelationEntityDefinition: EntitiyDefinition<InputCategoryMarkRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputCategoryMarkRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
