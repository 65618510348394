import produce from 'immer';
import { useCallback, useMemo } from 'react';

import { useTableStateBag } from '@work4all/components';
import {
  EventType,
  sendAmplitudeData,
} from '@work4all/components/lib/utils/amplitude/amplitude';

import { useDeleteEntity, useUser } from '@work4all/data';
import { usePermissions } from '@work4all/data/lib/hooks/use-permissions';
import { MAX_LAST_ITEMS_TO_SAVE } from '@work4all/data/lib/hooks/use-search-history';
import { SearchHistoryResults } from '@work4all/data/lib/settings/types';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { FileType } from '@work4all/models/lib/File';

import { environment } from '../../environments';
import { settings, useSetting } from '../../settings';

import {
  DELETE_ENTITY_ADMIN_MAX_COUNT,
  DELETE_ENTITY_MAX_COUNT,
} from './constants';

export interface IUseDeleteEntitiesConfigParams<T> {
  entityType: Entities;
}

export function useDeleteEntitiesToolbarConfig<
  T extends { id?: number | string } = { id?: number | string }
>(params: IUseDeleteEntitiesConfigParams<T>) {
  const { entityType } = params;
  const { untypedPermissions } = usePermissions();

  const wip = !environment.entities[entityType];

  const tableStateBag = useTableStateBag();

  const [deleteEntity] = useDeleteEntity();

  const user = useUser();

  const selectedEntities = useMemo(() => {
    return (
      tableStateBag?.tableInstance?.selectedFlatRows.map(
        (row) => row.original as T
      ) ?? []
    );
  }, [tableStateBag?.tableInstance?.selectedFlatRows]);

  const canDeleteSelectedEntities = selectedEntities.every((entity) => {
    return untypedPermissions(entityType).canDelete(entity);
  });

  const disabled = wip || !canDeleteSelectedEntities;
  const maxCount = user.isMaster
    ? DELETE_ENTITY_ADMIN_MAX_COUNT
    : DELETE_ENTITY_MAX_COUNT;

  const { set: setSearchHistory, value: searchHistory } = useSetting(
    settings.searchHistory()
  );

  const handler = useCallback(
    (ids?: string[]) => {
      const entitiesIds = ids ?? selectedEntities.map(({ id }) => id);

      deleteEntity({ type: entityType, ids: entitiesIds });
      sendAmplitudeData(EventType.DeleteElement, {
        entryPoint: entityType + 'List',
      });

      if (isFileType(entityType)) {
        switch (entityType) {
          case Entities.customer:
            setSearchHistory(
              filterSearchedItems(FileType.CUSTOMER, entitiesIds, searchHistory)
            );
            break;

          case Entities.supplier:
            setSearchHistory(
              filterSearchedItems(FileType.SUPPLIER, entitiesIds, searchHistory)
            );
            break;

          case Entities.project:
            setSearchHistory(
              filterSearchedItems(FileType.PROJECT, entitiesIds, searchHistory)
            );
            break;
        }
      }
    },
    [
      deleteEntity,
      entityType,
      searchHistory,
      selectedEntities,
      setSearchHistory,
    ]
  );

  return useMemo(
    () => ({ disabled, maxCount, handler }),
    [disabled, maxCount, handler]
  );
}

const isFileType = (entityType: Entities) => {
  return Object.values(FileType).includes(entityType.toString() as FileType);
};

const filterSearchedItems = (
  entityType: FileType,
  ids: (string | number)[],
  value: SearchHistoryResults
) => {
  const filteredItems = produce(value, (draft) => {
    draft[entityType] = draft[entityType]
      .filter((x) => !ids.includes(Number(x.id)))
      .slice(0, MAX_LAST_ITEMS_TO_SAVE);
  });
  return filteredItems;
};
