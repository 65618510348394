import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';

export function useConfirmBeforeCloseMask(enabled: boolean) {
  const { t } = useTranslation();

  const { setObjectionListener, confirm } = useHistoryStack();

  useEffect(() => {
    if (enabled) {
      setObjectionListener(() => {
        return confirm(t('ALERTS.UNSAVED_CHANGES'));
      });

      return () => {
        setObjectionListener(null);
      };
    }
  }, [enabled, setObjectionListener, confirm, t]);
}
