import styles from './PickerTargetButton.module.scss';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { forwardRef } from 'react';

export const PickerTargetButton = forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>((props, ref) => {
  return (
    <button ref={ref} {...props} type="button" className={styles.pickerButton}>
      <span className={styles.pickerButtonContent}>{props.children}</span>
      <ArrowDropDownIcon className={styles.pickerButtonDropdownIcon} />
    </button>
  );
});
