import { createContext, useCallback, useContext, useMemo } from 'react';

import { useMaskTabContext } from './MaskTabContext';

interface BoundUnregisterTabFormInputFunction {
  (): void;
}

interface UnregisterTabFormInputFunction {
  (name: string): void;
}

interface RegisterTabFormInputFunction {
  (name: string): BoundUnregisterTabFormInputFunction;
}

export interface MaskTabPanelContextValue {
  value: string | number;
  isActive: boolean;
  register: RegisterTabFormInputFunction;
  unregister: UnregisterTabFormInputFunction;
}

export interface MaskTabPanelContextProps {
  value: string | number;
  children?: React.ReactNode;
}

const Context = createContext<MaskTabPanelContextValue | null>(null);

export interface UseMaskTabPanelContextProps {
  optional?: boolean;
}

export function MaskTabPanelContext(props: MaskTabPanelContextProps) {
  const { value: tab, children } = props;

  const tabContext = useMaskTabContext();

  const { value: activeTab, register, unregister } = tabContext;

  const boundRegister = useCallback(
    (name: string) => {
      return register(tab, name);
    },
    [register, tab]
  );

  const boundUnregister = useCallback(
    (name: string) => {
      return unregister(tab, name);
    },
    [unregister, tab]
  );

  const context = useMemo<MaskTabPanelContextValue>(() => {
    return {
      value: tab,
      isActive: tab === activeTab,
      register: boundRegister,
      unregister: boundUnregister,
    };
  }, [tab, activeTab, boundRegister, boundUnregister]);

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

export interface UseRequiredMaskTabPanelContextProps
  extends UseMaskTabPanelContextProps {
  optional?: false;
}

export interface UseOptionalMaskTabPanelContextProps
  extends UseMaskTabPanelContextProps {
  optional: true;
}

/**
 * Returns the context value of the MaskTabContext. If this hook is called
 * outside of a MaskTabContext, it will throw.
 */
export function useMaskTabPanelContext(
  props?: UseRequiredMaskTabPanelContextProps
): MaskTabPanelContextValue;

/**
 * Returns the context value of the MaskTabPanelContext. If this hook is called
 * outside of a MaskTabPanelContext, it will return null instead.
 */
export function useMaskTabPanelContext(
  props: UseOptionalMaskTabPanelContextProps
): MaskTabPanelContextValue | null;

/**
 * Returns the context value of the MaskTabPanelContext. If this hook is called
 * outside of a MaskTabPanelContext, it will throw.
 */
export function useMaskTabPanelContext(
  props: UseMaskTabPanelContextProps = {}
): MaskTabPanelContextValue | null {
  const { optional = false } = props;

  const context = useContext(Context);

  if (context === null && !optional) {
    throw new Error(
      'useMaskTabPanelContext must be used inside a <MaskTabPanelContext />'
    );
  }

  return context;
}
