import React from 'react';
import { ColumnInstance, Hooks, TableInstance } from 'react-table';

// Original: https://github.com/GuillaumeJasmin/react-table-sticky
//
// Modified it correctly handle grouped columns that are hidden in our tables
// even though in the table instance they are (incorrectly?) marked as visible.

export function findLastIndex<T>(
  array: Array<T>,
  predicate: (value: T, index: number, obj: T[]) => boolean
): number {
  let l = array.length;
  while (l) {
    l -= 1;
    if (predicate(array[l], l, array)) {
      return l;
    }
  }
  return -1;
}

const checkErrors = (columns: ColumnInstance[]) => {
  const hasGroups = !!columns.find((column) => column.parent);
  const stickyColumnsWithoutGroup = columns
    .filter((column) => column.sticky && !column.parent)
    .map(({ Header }) => `'${Header}'`);

  if (hasGroups && stickyColumnsWithoutGroup.length) {
    throw new Error(`WARNING react-table-sticky:
      \nYour ReactTable has group and sticky columns outside groups, and that will break UI.
      \nYou must place ${stickyColumnsWithoutGroup.join(
        ' and '
      )} columns into a group (even a group with an empty Header label)\n`);
  }

  const bugWithUnderColumnsSticky = columns.find(
    (parentCol) =>
      !parentCol.sticky &&
      parentCol.columns &&
      parentCol.columns.find((col) => col.sticky)
  );

  if (!bugWithUnderColumnsSticky) return;

  const childBugs = bugWithUnderColumnsSticky.columns.find(
    ({ sticky }) => sticky
  );

  if (!childBugs) return;

  throw new Error(`WARNING react-table-sticky:
    \nYour ReactTable contain columns group with at least one child columns sticky.
    \nWhen ReactTable has columns groups, only columns groups can be sticky
    \nYou must set sticky: 'left' | 'right' for the '${bugWithUnderColumnsSticky.Header}'
    column, or remove the sticky property of '${childBugs.Header}' column.`);
};

function getStickyValue(column: ColumnInstance): null | 'left' | 'right' {
  if (column.sticky === 'left' || column.sticky === 'right') {
    return column.sticky;
  }

  if (column.parent) {
    return getStickyValue(column.parent);
  }

  return null;
}

function columnIsLastLeftSticky(
  columnId: string,
  columns: ColumnInstance[]
): boolean {
  const index = columns.findIndex(({ id }) => id === columnId);
  const lastLeftStickyIndex = findLastIndex(
    columns,
    (column) => getStickyValue(column) === 'left'
  );
  return index === lastLeftStickyIndex;
}

function columnIsFirstRightSticky(
  columnId: string,
  columns: ColumnInstance[]
): boolean {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const index = columns.findIndex(({ id }: any) => id === columnId);
  const firstRightStickyIndex = columns.findIndex(
    (column) => getStickyValue(column) === 'right'
  );
  return index === firstRightStickyIndex;
}

function getMarginLeft(columnId: string, columns: ColumnInstance[]) {
  const currentIndex = columns.findIndex(({ id }) => id === columnId);
  let leftMargin = 0;

  for (let i = currentIndex - 1; i >= 0; i -= 1) {
    const column = columns[i];
    if (column.sticky === 'left') {
      leftMargin += column.totalWidth;
    }
  }
  return leftMargin;
}

function getMarginRight(columnId: string, columns: ColumnInstance[]) {
  const currentIndex = columns.findIndex(({ id }) => id === columnId);
  let rightMargin = 0;
  for (let i = currentIndex + 1; i < columns.length; i += 1) {
    if (columns[i].sticky === 'right') {
      rightMargin += columns[i].totalWidth;
    }
  }

  return rightMargin;
}

const cellStylesSticky = {
  position: 'sticky',
  zIndex: 3,
} as const;

function findHeadersSameLevel(
  header: ColumnInstance,
  headers: ColumnInstance[]
) {
  return headers.filter((flatHeaderItem) => {
    return flatHeaderItem.depth === header.depth;
  });
}

function getStickyProps(header: ColumnInstance, instance: TableInstance) {
  let style: React.CSSProperties = {};
  const dataAttrs = {};

  checkErrors(instance.columns);

  const sticky = getStickyValue(header);

  if (sticky) {
    style = {
      ...cellStylesSticky,
    };

    dataAttrs['data-sticky-td'] = true;

    const headers = findHeadersSameLevel(header, instance.flatHeaders).filter(
      (column) => column.isVisible && !column.isGrouped
    );

    const margin =
      sticky === 'left'
        ? getMarginLeft(header.id, headers)
        : getMarginRight(header.id, headers);

    style = {
      ...style,
      [sticky]: `${margin}px`,
    };

    const isLastLeftSticky = columnIsLastLeftSticky(header.id, headers);

    if (isLastLeftSticky) {
      dataAttrs['data-sticky-last-left-td'] = true;
    }

    const isFirstRightSticky = columnIsFirstRightSticky(header.id, headers);

    if (isFirstRightSticky) {
      dataAttrs['data-sticky-first-right-td'] = true;
    }
  }

  return {
    style,
    ...dataAttrs,
  };
}

export const useSticky = (hooks: Hooks) => {
  hooks.visibleColumns.push((columns) => {
    const leftStickyColumns = columns.filter((col) => col.sticky === 'left');
    const rightStickyColumns = columns.filter((col) => col.sticky === 'right');
    const normalColumns = columns.filter((col) => !col.sticky);

    return [...leftStickyColumns, ...normalColumns, ...rightStickyColumns];
  });

  hooks.getHeaderProps.push((props, { instance, column }) => {
    const nextProps = getStickyProps(column, instance);
    return [props, nextProps];
  });

  hooks.getCellProps.push((props, { instance, cell }) => {
    const nextProps = getStickyProps(cell.column, instance);
    return [props, nextProps];
  });
};

useSticky.pluginName = 'useSticky';
