export enum FileType {
  PROJECT = 'project',
  CUSTOMER = 'customer',
  SUPPLIER = 'supplier',
}

export enum FileTypes {
  Projects = 'projects',
  Customers = 'customers',
  Suppliers = 'suppliers',
  More = 'more',
}

export interface FileData {
  name: string;
  id: string;
  website?: string;
  number: string | number;
  category?: string;
  lastUsed?: number;
}

export interface FileEntity {
  type: FileType;
  lastSearchItems?: FileData[];
  tenant: number;
}

export interface FileEntityWithId extends FileEntity {
  id: string;
}
