import styles from './FixedBackground.module.scss';

import clsx from 'clsx';

interface FixedBackgroundProps {
  className?: string;
  /** A URL to a custom background image. If `undefined`, the default background
   * image will be displayed. */
  src?: string;
  /**
   * Blend the background image with the primary color. If `'auto'`, will blend a
   * custom background but not the default one.
   */
  blend?: boolean | 'auto';
}

/**
 * A component that displays a background image that is fixed to window. The
 * image will be displayed in a container with `display: fixed`, so if only a
 * part of the image should be visible, you should apply `clip-path` to the
 * parent container.
 *
 * The element will apply `z-index: -1` to itself, so in some cases you may need
 * to create a new stacking context to avoid the image being covered by other
 * elements.
 */
export function FixedBackground(props: FixedBackgroundProps) {
  const { className, src, blend } = props;

  return (
    <div
      className={clsx(
        styles.root,
        'mui-fixed',
        { [styles.blend]: blend === true || (blend === 'auto' && src != null) },
        className
      )}
      style={src ? { backgroundImage: `url(${src})` } : undefined}
    />
  );
}
