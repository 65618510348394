import styles from './WorkHours.module.scss';

import WarningIcon from '@mui/icons-material/Warning';
import { Alert } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DateTimeInputDialog } from '@work4all/components/lib/dialogs/DateTimeInputDialog';

import { useUser } from '@work4all/data';

import {
  checkUserRight,
  UserRights,
} from '@work4all/models/lib/additionalEnums/UserRights';

import { MAX_HOURS_PRESENT } from '../calendar/constants';
import { useCalendarHoursConfig } from '../calendar/hooks/use-calendar-hours-config';

import { Card, CardHeader } from './components';
import {
  TIMETRACKER_LAYOUTS,
  TimeTrackerControls,
} from './TimeTrackerControls';
import { TimeTrackerInputSettings } from './TimeTrackerInputSettings';
import { TimeTracker } from './use-time-tracker';
import { getDefaultTime } from './utils/getDefaultTime';
import { WorkTimeDisabledAlert } from './WorkTimeDisableAlert';

export interface WorkHoursProps {
  tracker: TimeTracker;
  renderHeader?: boolean;
}

export function WorkHours(props: WorkHoursProps) {
  const { tracker, renderHeader = true } = props;

  const { t } = useTranslation();

  const user = useUser();

  const [showWorkTimeAlert, setShowWorkTimeAlert] = useState(false);

  const workTime = useCalendarHoursConfig();

  const [dialog, setDialog] = useState<{
    open: boolean;
    type: 'start' | 'stop';
  }>({
    open: false,
    type: 'start',
  });

  const determineCheckinCheckoutTime = (type: 'start' | 'stop') => () => {
    const canModifyExactTimes = checkUserRight(
      UserRights.KommtGehtZeitenAendernEigene,
      user.benutzerRechte
    );

    if (canModifyExactTimes) {
      setDialog({
        open: true,
        type,
      });
    } else {
      tracker[type](new Date());
    }
  };

  const closeDialog = () => {
    setDialog((state) => ({ ...state, open: false }));
  };

  const { result } = tracker;

  const earliestStart = useMemo(() => {
    if (
      result === null ||
      result.state !== 'initial' ||
      !user?.kommtGehtMinimaleStartZeit
    ) {
      return null;
    }

    const timeNow = DateTime.now();
    const earliestStartTime = timeNow.set({
      hour: 0,
      minute: 0,
      second: 0,
      // backend returns `kommtGehtMinimaleStartZeit` in minutes
      millisecond: user.kommtGehtMinimaleStartZeit * 60000,
    });

    if (timeNow > earliestStartTime) {
      return null;
    }

    return t('TIME_TRACKER.EARLIEST_START', {
      time: earliestStartTime.toFormat('HH:mm'),
    });
  }, [result, user?.kommtGehtMinimaleStartZeit, t]);

  if (result == null) {
    return null;
  }

  const isRunningForMoreThanMaxHours =
    result.totalPresent.as('hours') > MAX_HOURS_PRESENT;

  return (
    <>
      <DateTimeInputDialog
        open={dialog.open}
        title={t(`TIME_TRACKER.DIALOG.${dialog.type}.title`)}
        description={t(`TIME_TRACKER.DIALOG.${dialog.type}.description`)}
        onConfirm={(dateTime) => {
          tracker[dialog.type](dateTime);
          closeDialog();
        }}
        onCancel={closeDialog}
        defaultValue={getDefaultTime({ ...tracker }, workTime.workDayEnd)}
        {...TimeTrackerInputSettings(tracker, dialog.type)}
      />

      <WorkTimeDisabledAlert
        open={showWorkTimeAlert}
        onClose={() => setShowWorkTimeAlert(false)}
      />

      <Card>
        {renderHeader ? (
          <CardHeader title={t('TIME_TRACKER.WORKING_TIME')}>
            {isRunningForMoreThanMaxHours ? (
              <div className={styles.maxHoursError}>
                <WarningIcon fontSize="medium" color="error" />
                <Typography variant="caption" color="error">
                  {t('TIME_TRACKER.MAX_HOURS_WARNING', {
                    hours: MAX_HOURS_PRESENT,
                  })}
                </Typography>
              </div>
            ) : (
              <Typography className={styles.text03}>
                {`${t('COMMON.TODAY')}, ${DateTime.now().toFormat(
                  'dd.MM.yyyy'
                )}`}
              </Typography>
            )}
          </CardHeader>
        ) : null}

        <TimeTrackerControls
          layout={TIMETRACKER_LAYOUTS.RIBBON}
          disabled={tracker.disabled}
          state={result.state}
          presentSince={result.presentSince}
          pausedSince={result.pausedSince}
          stoppedSince={result.stoppedSince}
          onStart={() => {
            if (tracker.disabled) {
              setShowWorkTimeAlert(true);
            } else {
              determineCheckinCheckoutTime('start')();
            }
          }}
          onStop={determineCheckinCheckoutTime('stop')}
          onPause={
            checkUserRight(UserRights.PausenStempeln, user.benutzerRechte)
              ? () => {
                  tracker.pause(new Date());
                }
              : undefined
          }
          onResume={() => tracker.resume(new Date())}
        />

        <div className={styles.stats}>
          <Tooltip title={t('TIME_TRACKER.PRESENCE_TOOLTIP')}>
            <div tabIndex={0} className={styles.stat}>
              <Typography variant="caption" color="text.tertiary">
                {t('TIME_TRACKER.PRESENCE')}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {result.totalPresent.toFormat('hh:mm')} h
              </Typography>
            </div>
          </Tooltip>

          <Tooltip title={t('TIME_TRACKER.BREAK_TIME_TOOLTIP')}>
            <div tabIndex={0} className={styles.stat}>
              <Typography variant="caption" color="text.tertiary">
                {t('TIME_TRACKER.BREAK_TIME')}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {result.totalPaused.toFormat('hh:mm')} h
              </Typography>
            </div>
          </Tooltip>

          <Tooltip title={t('TIME_TRACKER.WORKING_TIME_TOOLTIP')}>
            <div tabIndex={0} className={styles.stat}>
              <Typography variant="caption" color="text.tertiary">
                {t('TIME_TRACKER.WORKING_TIME')}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {result.totalWorked.toFormat('hh:mm')} h
              </Typography>
            </div>
          </Tooltip>
        </div>

        {earliestStart && (
          <Alert
            sx={{
              backgroundColor: '#D9A931',
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              color: '#ffffff',
            }}
            icon={false}
          >
            <Typography variant="h5">{earliestStart}</Typography>
          </Alert>
        )}
      </Card>
    </>
  );
}
