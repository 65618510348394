import { createContext, useContext } from 'react';

export interface ReleaseNotesDialogState {
  releaseNotesFile?: string;
  version?: string;
  openReleaseNotesDialog: () => void;
}

export const ReleaseNotesDialogContext =
  createContext<ReleaseNotesDialogState | null>(null);

export const useReleaseNotesDialogContext = () => {
  const context = useContext(ReleaseNotesDialogContext);

  return context;
};
