import { entityDefinition } from '@work4all/models/lib/Classes/entityDefinitions';
import { FieldDefinitions } from '@work4all/models/lib/DataProvider';

const generateFieldDefintions = (byAlias = true): FieldDefinitions<unknown> => {
  return Object.keys(entityDefinition).reduce<FieldDefinitions<unknown>>(
    (acc, entityKey) => {
      const originalFieldDefintions =
        entityDefinition[entityKey].fieldDefinitions;
      const definitions = Object.keys(originalFieldDefintions).reduce(
        (defs, defKey) => {
          const def = {
            ...originalFieldDefintions[defKey],
            key: defKey,
          };
          const key = byAlias ? def['alias'] : defKey;
          defs[key.toLowerCase()] = def;
          return defs;
        },
        {}
      );

      acc[entityKey] = definitions;

      return acc;
    },
    {} as FieldDefinitions<unknown>
  );
};

export const fieldDefinitions = generateFieldDefintions(false);
export const fieldDefinitionsByAlias = generateFieldDefintions(true);
