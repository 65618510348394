import { useObservableState } from 'observable-hooks';
import { useMemo, useState } from 'react';
import { debounceTime, distinctUntilChanged } from 'rxjs';

import { useDataProvider } from '@work4all/data';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { DataRequest, SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { sortSearchResults } from '@work4all/utils/lib/search/sortSearchResults';

export function useEntitySearchData<T>(
  entity: Entities,
  skip = false,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customFilter: any = undefined
) {
  const [inputValue, setInputValue] = useState<string>('');
  const [debouncedValue, setDebouncedValue] = useObservableState(
    (input$) => input$.pipe(distinctUntilChanged(), debounceTime(200)),
    inputValue
  );

  const reqDataEntity: DataRequest = useMemo(() => {
    const parsedNumber = parseInt(debouncedValue, 10);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filterArray: any[] = [
      {
        name: { $eq: `%${debouncedValue}%` },
      },
    ];

    if (!isNaN(parsedNumber) || entity === Entities.project) {
      // Project number is a string an supports wildcards
      filterArray.push({
        number: {
          $eq:
            entity === Entities.project ? `${debouncedValue}%` : parsedNumber,
        },
      });
    }

    let filter = [
      {
        $or: filterArray,
      },
    ];
    if (customFilter) {
      filter = [customFilter, ...filter];
    }

    const data =
      entity === Entities.project
        ? {
            id: null,
            number: null,
            name: null,
          }
        : {
            id: null,
            number: null,
            name: null,
            website: null,
          };

    return {
      entity: entity,
      sort: [{ field: 'name', direction: SortDirection.ASCENDING }],
      data,
      filter,
    };
  }, [debouncedValue, entity, customFilter]);

  const reqDataEntityContact: DataRequest = useMemo(() => {
    const debounceParts = (debouncedValue || '').split(' ');
    //all parts of the name need to be present anywhere in the searched fields
    const allOrFilterGroups = debounceParts.map((debouncePart) => {
      return {
        $or: [
          {
            name: { $eq: `${debouncePart}%` },
          },
          {
            nameExtension: { $eq: `${debouncePart}%` },
          },
          {
            firstName: { $eq: `${debouncePart}%` },
          },
        ],
      };
    });

    return {
      entity: Entities.contact,
      sort: [{ field: 'name', direction: SortDirection.ASCENDING }],
      data: {
        id: null,
        name: null,
        nameExtension: null,
        firstName: null,
        businessPartner: {
          id: null,
          data: {
            customer: {
              id: null,
              number: null,
              name: null,
              website: null,
            },
            supplier: {
              id: null,
              number: null,
              name: null,
              website: null,
            },
          },
        },
      },
      filter: [
        ...allOrFilterGroups,
        {
          businessPartnerType: {
            $eq:
              entity === Entities.customer
                ? SdObjType.KUNDE
                : SdObjType.LIEFERANT,
          },
        },
      ],
    };
  }, [debouncedValue, entity]);

  const response = useDataProvider<T>(reqDataEntity, !debouncedValue || skip);
  const responseContacts = useDataProvider<Contact>(
    reqDataEntityContact,
    ![Entities.customer, Entities.supplier].includes(entity) ||
      debouncedValue.length < 3
  );
  const onChange = useMemo(() => {
    const onInputChange = (value: string) => {
      setInputValue(value);
      setDebouncedValue(value);
    };
    return onInputChange;
  }, [setDebouncedValue]);

  const items = useMemo(() => {
    if (!debouncedValue) {
      return {
        direct: [],
        related: [],
      };
    }

    return {
      direct: sortSearchResults([...response.data]) || [],
      related: sortSearchResults([...responseContacts.data]) || [],
    };
  }, [response?.data, responseContacts?.data, debouncedValue]);

  return {
    isLoading: response.pending || responseContacts.pending,
    results: items,
    inputValue,
    debouncedValue,
    onChange,
  };
}
