import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { SafeArea, SafeAreaInsets } from 'capacitor-plugin-safe-area';
import { useEffect } from 'react';

export const useOverlayingWebView = () => {
  useEffect(() => {
    applySafeInsetStyles();
  }, []);
};

async function applySafeInsetStyles() {
  const style = document.documentElement.style;

  style.setProperty('--w4a-safe-area-top', 'env(safe-area-inset-top)');
  style.setProperty('--w4a-safe-area-left', 'env(safe-area-inset-left)');
  style.setProperty('--w4a-safe-area-right', 'env(safe-area-inset-right)');
  style.setProperty('--w4a-safe-area-bottom', 'env(safe-area-inset-bottom)');

  if (Capacitor.getPlatform() === 'android') {
    const info = await Device.getInfo();

    const applyInsets = async ({ insets }: SafeAreaInsets): Promise<void> => {
      const sdkVersion = info.androidSDKVersion;
      const isAndroid13OrLater = sdkVersion >= 33;

      const insetTop = insets.top;
      const insetBottom = isAndroid13OrLater ? insets.bottom : 0;

      style.setProperty('--w4a-safe-area-top', `${insetTop}px`);
      style.setProperty('--w4a-safe-area-bottom', `${insetBottom}px`);
    };

    const { insets } = await SafeArea.getSafeAreaInsets();

    applyInsets({ insets });

    await SafeArea.removeAllListeners();
    await SafeArea.addListener('safeAreaChanged', applyInsets);
  }
}
