import styles from './CalendarGroupModeSwitch.module.scss';

import {
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ColsIcon } from '@work4all/assets/icons/columns.svg';
import { ReactComponent as GroupedIcon } from '@work4all/assets/icons/grouped.svg';
import { ReactComponent as RowsIcon } from '@work4all/assets/icons/rows.svg';

import { SelectButton } from '@work4all/components/lib/input/select-button/SelectButton';

import { AppointmentsGroupMode, CalendarProps } from '../types';

export type CalendarGroupModeSwitchProps = Pick<
  CalendarProps,
  'range' | 'groupMode' | 'onGroupModeChange'
> & {
  layout: 'select' | 'list' | 'button';
};

export const CalendarGroupModeSwitch = (
  props: CalendarGroupModeSwitchProps
) => {
  const { groupMode, layout, onGroupModeChange, range } = props;

  const availableModes: AppointmentsGroupMode[] = [
    'grouped',
    'horizontal',
    'vertical',
  ];

  const getIcon = (range: AppointmentsGroupMode) => {
    switch (range) {
      case 'grouped':
        return <GroupedIcon fontSize="small" />;
      case 'vertical':
        return <RowsIcon fontSize="small" />;
      case 'horizontal':
        return <ColsIcon fontSize="small" />;
    }
  };

  const { t } = useTranslation();

  return layout === 'select' ? (
    <FormControl variant="filled">
      <InputLabel id="group-mode-select-label">
        {t('CALENDAR.VIEWMODE.TITLE')}
      </InputLabel>
      <Select
        disabled={range === 'agenda'}
        labelId="group-mode-select-label"
        value={groupMode}
        onChange={(event) => {
          onGroupModeChange(event.target.value as AppointmentsGroupMode);
        }}
        style={{ width: '14rem' }}
        classes={{
          select: styles.select,
        }}
      >
        {availableModes.map((mode) => {
          return (
            <MenuItem key={mode} value={mode}>
              <ListItemIcon>{getIcon(mode)}</ListItemIcon>
              <ListItemText
                primary={t(`CALENDAR.VIEWMODE.${mode.toUpperCase()}`)}
              />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  ) : layout === 'list' ? (
    <div>
      {availableModes.map((mode) => {
        return (
          <MenuItem
            key={mode}
            value={mode}
            onClick={() => {
              onGroupModeChange(mode);
            }}
            selected={mode === groupMode}
            disabled={mode !== 'grouped' && range === 'agenda'}
          >
            <ListItemIcon>{getIcon(mode)}</ListItemIcon>
            <ListItemText
              primary={t(`CALENDAR.VIEWMODE.${mode.toUpperCase()}`)}
            />
          </MenuItem>
        );
      })}
    </div>
  ) : (
    <SelectButton
      onChange={(value) => onGroupModeChange(value.id as AppointmentsGroupMode)}
      options={availableModes.map((mode) => ({
        id: mode,
        name: t(`CALENDAR.VIEWMODE.${mode.toUpperCase()}`),
        icon: getIcon(mode),
      }))}
      label={t('CALENDAR.VIEWMODE.TITLE')}
      value={{
        id: groupMode,
        name: t(`CALENDAR.VIEWMODE.${groupMode.toUpperCase()}`),
        icon: getIcon(groupMode),
      }}
    />
  );
};
