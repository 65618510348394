import React from 'react';
import { useTranslation } from 'react-i18next';

import { useTableStateBag } from '@work4all/components';
import { EntityGroupPicker } from '@work4all/components/lib/components/entity-picker/entity-group-picker/EntityGroupPicker';

import { Group } from '@work4all/models/lib/Classes/Group.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PersonalAndGeneralView } from '../../filters/PersonalAndGeneralView';
import { ClosedStatusQuickFilter } from '../ClosedStatusQuickFilter';
import {
  TableQuickFilters,
  TableQuickFiltersSection,
  TableQuickFiltersSectionHeader,
} from '../table-quick-filters';
import { createHandleColumnFilterChange } from '../table-quick-filters/utils';

export const ArticleTableQuickFilters = () => {
  const { t } = useTranslation();

  const tableStateBag = useTableStateBag();

  const columns = {
    group: tableStateBag.columnsById['groupId'],
    closed: tableStateBag.columnsById['isShutDown'],
  };

  const groups = columns.group?.filterValue?.value ?? null;
  const handleGroupsChange = createHandleColumnFilterChange<Group>(
    columns.group
  );

  return (
    <TableQuickFilters>
      <ClosedStatusQuickFilter
        entityType={Entities.article}
        column={columns.closed}
        label={t('ARTICLE.CLOSED_STATUS.HIDE_CLOSED')}
      />

      <TableQuickFiltersSection>
        <TableQuickFiltersSectionHeader>
          {t('COMMON.GROUPS')}
        </TableQuickFiltersSectionHeader>
        <EntityGroupPicker
          entity={Entities.articleGroup}
          value={groups}
          onChange={handleGroupsChange}
        />
      </TableQuickFiltersSection>
      <PersonalAndGeneralView entity={Entities.article} />
    </TableQuickFilters>
  );
};
