import bs from 'binary-search';
import { useEffect, useRef } from 'react';
import { UseExpandedState } from 'react-table';

import { TableRow } from '../types';

const rowIdToNum = (id: string) => {
  const parts = id.split('.');
  if (parts.length <= 1) {
    return parseInt(id);
  }

  return parts.reduce((acc, p, idx) => {
    if (idx === 0) {
      return acc + parseInt(p);
    }

    return acc + (parseInt(p) + 1) / Math.pow(10, idx);
  }, 0);
};

export const useOnRowExpanded = (
  expanded: UseExpandedState<never>['expanded'],
  rows: TableRow[],
  onRowExpanded?: (row: TableRow) => void
) => {
  const lastExpandedRows = useRef(expanded);
  useEffect(() => {
    const expandedIds = Object.keys(expanded);
    if (!expandedIds.length) {
      lastExpandedRows.current = {};
      return;
    }

    let expandedRowId: string | undefined;
    for (let i = 0; i < expandedIds.length; i += 1) {
      const key = expandedIds[i];
      if (lastExpandedRows.current[key]) {
        continue;
      }

      expandedRowId = key;
      break;
    }

    if (!expandedRowId) {
      lastExpandedRows.current = expanded;
      return;
    }

    const index = bs(rows, expandedRowId, (row, needle) => {
      return rowIdToNum(row.id) - rowIdToNum(needle);
    });
    const row = rows[index];
    lastExpandedRows.current = expanded;
    onRowExpanded?.(row);
  }, [expanded, onRowExpanded, rows]);
};
