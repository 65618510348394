import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

const DATA: EMailTemplate<EMode.query> = {
  id: null,
  name: null,
  body: null,
  subject: null,
  signature: {
    id: null,
    name: null,
    body: null,
  },
};

export const useEmailTemplates = (
  kind: EMailTemplateKind
): Pick<EMailTemplate, 'id' | 'name'>[] => {
  const requestData: DataRequest = useMemo(() => {
    return {
      data: DATA,
      entity: Entities.eMailTemplate,
      filter: [{ eMailTemplateKind: { $eq: kind } }],
      completeDataResponse: true,
    };
  }, [kind]);

  const resp = useDataProvider<EMailTemplate>(requestData);
  return resp.data;
};
