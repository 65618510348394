import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

export class TalkingPoint<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: kNBerichtCode */
  customerNumberVisitationId?: number;
  /** Alias: nummer */
  nummer?: number;
  /** Alias: thema */
  thema?: string;
  /** Alias: erledigung */
  erledigung?: string;
  /** Alias: wer */
  wer?: string;
  /** Alias: bisWann */
  bisWann?: string;
  /** Alias: oK */
  oK?: boolean;
  /** Alias: whattodoCode */
  whattodoCode?: number;
  /** Alias: aPCode */
  aPCode?: number;
  /** Alias: kCode */
  kCode?: number;
  /** Alias: themenCode */
  themenCode?: number;
  /** Alias: vereinbarung */
  vereinbarung?: string;
  /** Alias: isBenutzer */
  isBenutzer?: boolean;
  /** Alias: beginndatum */
  beginndatum?: string;
  /** Alias: endDatum */
  endDatum?: string;
  /** Alias: bewertung */
  bewertung?: number;
  /** Alias: dauer */
  dauer?: number;
  /** Alias: projektstep */
  projektstep?: number;
  /** Alias: erledigt */
  erledigt?: boolean;
  /** Alias: art */
  kind?: number;
  /** Alias: pozNr */
  pozNr?: string;
  /** Alias: sDObjType */
  sDObjType?: SdObjType;
  /** Alias: sDObjMemberCode */
  businessPartnerId?: number;

  __typename?: string;
}

const fields: FieldDefinitions<TalkingPoint> = {
  id: {
    alias: 'code',
  },
  customerNumberVisitationId: {
    alias: 'kNBerichtCode',
  },
  nummer: {
    alias: 'nummer',
  },
  thema: {
    alias: 'thema',
  },
  erledigung: {
    alias: 'erledigung',
  },
  wer: {
    alias: 'wer',
  },
  bisWann: {
    alias: 'bisWann',
  },
  oK: {
    alias: 'oK',
  },
  whattodoCode: {
    alias: 'whattodoCode',
  },
  aPCode: {
    alias: 'aPCode',
  },
  kCode: {
    alias: 'kCode',
  },
  themenCode: {
    alias: 'themenCode',
  },
  vereinbarung: {
    alias: 'vereinbarung',
  },
  isBenutzer: {
    alias: 'isBenutzer',
  },
  beginndatum: {
    alias: 'beginndatum',
  },
  endDatum: {
    alias: 'endDatum',
  },
  bewertung: {
    alias: 'bewertung',
  },
  dauer: {
    alias: 'dauer',
  },
  projektstep: {
    alias: 'projektstep',
  },
  erledigt: {
    alias: 'erledigt',
  },
  kind: {
    alias: 'art',
  },
  pozNr: {
    alias: 'pozNr',
  },
  sDObjType: {
    alias: 'sDObjType',
  },
  businessPartnerId: {
    alias: 'sDObjMemberCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const talkingPointEntityDefinition: EntitiyDefinition<TalkingPoint> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'Gespraechspunkt',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
