import styles from './EntityPickerActionBar.module.scss';

import React from 'react';

export type IEntityPickerActionBarProps = {
  children: React.ReactNode;
};

export function EntityPickerActionBar(props: IEntityPickerActionBarProps) {
  return <div className={styles['root']}>{props.children}</div>;
}
