import styles from './Autocomplete.module.scss';

import { IconButton, Popper as PopperComponent } from '@mui/material';
import { useObservableState } from 'observable-hooks';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { concatMap, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ReactComponent as AddIcon } from '@work4all/assets/icons/add.svg';

import { reactRefSetter } from '@work4all/utils/lib/reactRefSetter';
import { triggerOnChange } from '@work4all/utils/lib/triggerOnChange';

import { Chip } from '../../dataDisplay/chip/Chip';
import { LabeledInput } from '../labeled-input';
import { SearchBarWithDropDown } from '../search-bar-with-dropdown/SearchBarWithDropdown';
import { SearchResultItem } from '../search-result-item/SearchResultItem';

import { ILabeledFiltersSearchProps } from './types';
import { popperModifiers } from './utils/popper-modifiers';

export const Autocomplete = React.memo(
  React.forwardRef(function LableledItemsSearch<
    T extends {
      title: string;
      id: string;
      number: string;
      caption?: string;
    }
  >(props: ILabeledFiltersSearchProps<T>, ref: React.ForwardedRef<HTMLInputElement>) {
    const {
      SearchItem = SearchResultItem,
      onSearch,
      label,
      searchFooter,
      items: userItemsData,
      onAdd,
      defaultValues = [],
      withSeperator,
      disabled,
      ...rest
    } = props;
    const containerRef = useRef<HTMLDivElement>();
    const [open, setOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState<T[]>(defaultValues);
    const [inputValue, setInputValue] = useState('');
    const hiddenInputRef = useRef<HTMLInputElement>();
    const [itemsData, setSearchValue] = useObservableState<T[], string>(
      (input$) =>
        input$.pipe(
          distinctUntilChanged(),
          debounceTime(200),
          concatMap(async (value) => {
            if (!onSearch) {
              return [];
            }

            const d = await onSearch(value);
            if (d) {
              return d;
            }

            return [];
          })
        ),
      []
    );

    const hideDropdown = () => {
      setOpen(false);
      setInputValue('');
    };

    useEffect(() => {
      setSearchValue(inputValue);
    }, [inputValue, setSearchValue]);

    useEffect(() => {
      triggerOnChange(hiddenInputRef.current, JSON.stringify(itemsData));
    }, [itemsData]);

    const items = useMemo(() => {
      return (userItemsData || itemsData).map((item) => {
        return (
          <SearchItem
            key={item.id}
            {...item}
            onClick={() => {
              setSelectedItems((old) => {
                return [...old, item];
              });
              hideDropdown();
            }}
          />
        );
      });
    }, [SearchItem, itemsData, userItemsData]);

    return (
      <div ref={containerRef} className={styles.container}>
        <input ref={reactRefSetter(hiddenInputRef, ref)} hidden {...rest} />
        <LabeledInput
          disabled={disabled}
          defaultValue={selectedItems.join(',')}
          value={
            <div className={styles.chipsContainer}>
              {selectedItems.map((item, i) => {
                return (
                  <Chip
                    key={item.id}
                    withSeperator={withSeperator}
                    className={styles.chip}
                    maxWidth={false}
                    label={item.title}
                    caption={item.caption}
                    disabled={disabled}
                    handleDelete={() => {
                      if (disabled) {
                        return;
                      }

                      setSelectedItems((old) => {
                        return old.filter((it, idx) => {
                          return idx !== i;
                        });
                      });
                    }}
                  />
                );
              })}
              {onAdd && !disabled && (
                <IconButton className={styles.add} size="large">
                  <AddIcon />
                </IconButton>
              )}
            </div>
          }
          onClick={() => setOpen(true)}
          label={label}
        />
        <PopperComponent
          className={styles.tooltip}
          disablePortal={true}
          anchorEl={containerRef.current}
          open={!disabled && open}
          placement="bottom"
          modifiers={popperModifiers}
        >
          <SearchBarWithDropDown
            staticPosition={true}
            mobile={false}
            inputValue={inputValue}
            active={open}
            onHideDropDown={hideDropdown}
            onChange={(e) => {
              const val = e.target.value;
              setInputValue(val);
            }}
            autofocus={true}
            dropdown={{
              items,
              footer: searchFooter,
            }}
          />
        </PopperComponent>
      </div>
    );
  })
);
