import { useSearchParams } from 'react-router-dom';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';

import { mapUsersToAppointmentAttendees } from '../utils/map-users-to-appointment-attendees';

import { useInitialUsersDataProvider } from './use-initial-users-data-provider';

export const useQueryPresetFields = () => {
  const [searchParams] = useSearchParams();

  const userIdQueryParam = searchParams.get('userIds');

  const userIds =
    userIdQueryParam?.split(',')?.map((x) => parseInt(x, 10)) || [];

  const { data } = useInitialUsersDataProvider({
    userIds,
    disabled: !userIdQueryParam,
  });

  if (!userIdQueryParam) return undefined;

  const template: Appointment = {
    appointmentAttendeeList: mapUsersToAppointmentAttendees(data),
  };

  return JSON.stringify(template);
};
