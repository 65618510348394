import styles from './MaskOverlayHeader.module.scss';

import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';

import { NavigationOverlayHeader } from '../../../../../components/navigation-overlay-header/NavigationOverlayHeader';
import { AutoLockedBanderole } from '../../../components/banderoles/auto-lock-banderole';
import { MaskActions } from '../MaskActions';

export interface IMaskOverlayHeaderProps {
  title?: string;
  subTitle?: string;
  isLoading?: boolean;
  tabs?: JSX.Element;
  actions?: JSX.Element;
}

export const MaskOverlayHeader: React.VFC<IMaskOverlayHeaderProps> = (
  props
) => {
  const { title, subTitle, isLoading, tabs, actions } = props;

  return (
    <NavigationOverlayHeader
      title={title}
      subTitle={subTitle}
      breadcrumbTitle={subTitle}
      breadcrumbsChildren={
        actions ? <MaskActions>{actions}</MaskActions> : null
      }
    >
      {tabs ? <div className={styles.tabsWrapper}>{tabs}</div> : null}
      {isLoading ? (
        <LinearProgress className="linearProgress" />
      ) : (
        <AutoLockedBanderole />
      )}
    </NavigationOverlayHeader>
  );
};
