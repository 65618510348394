import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { EMailTemplate } from './EMailTemplate.entity';

export class EMailTemplateGroup<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: name */
  name?: string;
  /** Alias: templates */
  templates?: EMailTemplate<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<EMailTemplateGroup> = {
  id: {
    alias: 'id',
  },
  name: {
    alias: 'name',
  },
  templates: {
    alias: 'templates',
    entity: Entities.eMailTemplate,
  },
  __typename: {
    alias: '__typename',
  },
};

export const eMailTemplateGroupEntityDefinition: EntitiyDefinition<EMailTemplateGroup> =
  {
    local: {},
    remote: {
      queryName: 'getEMailTemplateGroups',
      fragmentName: 'EMailTemplateGroup',
      withPaginationWrapper: false,
      mutations: {
        upsert: {
          mutationName: 'upsertEMailTemplateGroup',
          args: [
            { name: 'input', type: 'InputEMailTemplateGroup!' },
            { name: 'drySave', type: 'Boolean' },
          ],
        },
      },
    },
    fieldDefinitions: fields,
  };
