import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputTicketAttachementsRelation } from './InputTicketAttachementsRelation.entity';
import { InputTicketChecklisteMarkRelation } from './InputTicketChecklisteMarkRelation.entity';

export class InputTicketRelation<T extends EMode = EMode.entity> {
  /** Alias: attachements */
  attachements?: InputTicketAttachementsRelation<T>;
  /** Alias: checklistMarks */
  checklistMarks?: InputTicketChecklisteMarkRelation<T>;

  __typename?: string;
}

const fields: FieldDefinitions<InputTicketRelation> = {
  attachements: {
    alias: 'attachements',
  },
  checklistMarks: {
    alias: 'checklistMarks',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputTicketRelationEntityDefinition: EntitiyDefinition<InputTicketRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputTicketRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
