import { IconButton } from '@mui/material';
import { createContext, useContext, useState } from 'react';

import { ReactComponent as FormatText } from '@work4all/assets/icons/format_color_text_edit.svg';

export const useEditorToolbar = (initialValue: boolean) => {
  const [hideToolbar, setHideToolbar] = useState(initialValue);

  return {
    actions: (
      <IconButton
        color={hideToolbar ? undefined : 'primary'}
        onClick={() => {
          setHideToolbar((x) => !x);
        }}
      >
        <FormatText />
      </IconButton>
    ),
    hideToolbar,
  };
};

interface EditorToolbarContextType {
  hideToolbar: boolean;
}

const EditorToolbarContext = createContext<EditorToolbarContextType>({
  hideToolbar: false,
});

export const EditorToolbarProvider = ({
  children,
  hideToolbar,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
EditorToolbarContextType & { children: any }) => {
  return (
    <EditorToolbarContext.Provider value={{ hideToolbar }}>
      {children}
    </EditorToolbarContext.Provider>
  );
};

export const useEditorToolbarContext = () => useContext(EditorToolbarContext);
