import { useEventCallback } from '@mui/material/utils';

import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';
import {
  EventType,
  sendAmplitudeData,
} from '@work4all/components/lib/utils/amplitude/amplitude';

import { useOverlayRouteParams } from './use-overlay-route-params';

export const useAfterSave = (amplitudeEntryPoint?: string) => {
  const metaData = useOverlayRouteParams();
  const { close, setObjectionListener } = useHistoryStack();

  return useEventCallback(() => {
    // Most of the masks will not have removed their objection listener at this
    // point. So we need to remove it here to be able to close the mask.
    setObjectionListener(null);
    sendAmplitudeData(EventType.SaveElement, {
      name: metaData?.subEntityType,
      entryPoint:
        amplitudeEntryPoint === 'fileDetailPage'
          ? metaData?.entityType
          : amplitudeEntryPoint,
    });
    close();
  });
};
