// TODO I don't think there is any reason to use an enum for this.
//
// This should just be a string constant. There can't be more than one
// application name in the final build anyway. If we need to use different names
// for different deployments, we should set the name during build time (using an
// environment variable, for example), or fetch it from the server on start-up
// if the same bundle will be running on multiple deployments and they must use
// different names.
export enum ApplicationName {
  work4all20 = 'work4all2.0',
}
