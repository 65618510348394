import { IFieldParam } from '@work4all/models/lib/DataProvider';

export const genOperationParams = (allParams?: IFieldParam[]) => {
  let str = '';
  if (!allParams || !allParams.length) {
    return str;
  }

  str += '(';
  str += allParams
    .map((p) => {
      return `$${p.alias ? p.alias : p.name}: ${p.type}`;
    })
    .join(', ');
  str += ')';

  return str;
};
