import { gql, useQuery } from '@apollo/client';
import { cloneDeep } from 'lodash';
import { useMemo } from 'react';

import { SalesOpportunityRatingStatus } from '@work4all/models/lib/Classes/SalesOpportunityRatingStatus.entity';

export const GetSalesOpportunityRatingStatus = gql`
  query GetSalesOpportunityRatingStatus {
    getSalesOpportunityRatingStatus {
      id
      name
      comment
      ratingValue
    }
  }
`;

export interface GetSalesOpportunityRatingStatusResponse {
  getSalesOpportunityRatingStatus: SalesOpportunityRatingStatus[];
}

export const emptyArray = [];

export const useSalesOpportunityRatingStatus = (
  defaultArray: SalesOpportunityRatingStatus[] = emptyArray
) => {
  const response = useQuery<GetSalesOpportunityRatingStatusResponse>(
    GetSalesOpportunityRatingStatus
  );

  const data = useMemo(() => {
    const incomingData =
      response.data?.getSalesOpportunityRatingStatus ?? defaultArray;
    return cloneDeep(incomingData).sort((a, b) =>
      a.ratingValue > b.ratingValue ? 1 : -1
    );
  }, [response.data?.getSalesOpportunityRatingStatus, defaultArray]);
  return {
    ...response,
    data,
  };
};
