import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class InputCategoryMarkRelationAdd<T extends EMode = EMode.entity> {
  /** Alias: categoryCode */
  categoryCode?: number;
  /** Alias: note */
  note?: string;

  __typename?: string;
}

const fields: FieldDefinitions<InputCategoryMarkRelationAdd> = {
  categoryCode: {
    alias: 'categoryCode',
  },
  note: {
    alias: 'note',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputCategoryMarkRelationAddEntityDefinition: EntitiyDefinition<InputCategoryMarkRelationAdd> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputCategoryMarkRelationAdd',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
