import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { Currency } from '@work4all/models/lib/Classes/Currency.entity';

import { formatNumberAsCurrency } from '@work4all/utils';

import { CurrencyExchangeInfoContext } from './currency-exchange-info-context';

export const GetCurrencyExchangeInfo = gql`
  query GetCurrencyExchangeInfo {
    exchangeRates: getWaehrungKurse {
      rate: kurs
      fromCurrencyId: waehrungCode
    }
    currencies: getWaehrungen {
      id: code
      name: name
    }
    settings: getGeneralSettings(name: ["LeitwährungCode"]) {
      value: wert
    }
  }
`;

export interface GetCurrencyExchangeInfoResponse {
  exchangeRates: {
    rate: number;
    fromCurrencyId: number;
    id1: number;
  }[];
  currencies: Currency[];
  settings: {
    value: number;
  }[];
}

export function CurrencyExchangeInfoContextProvider(props: {
  children: React.ReactNode;
}) {
  const { children } = props;

  const { data } = useQuery<GetCurrencyExchangeInfoResponse>(
    GetCurrencyExchangeInfo
  );

  const contextValue = useMemo(() => {
    if (!data) {
      return null;
    }

    try {
      const { currencies, exchangeRates, settings } = data;

      const defaultCurrencyId = settings[0].value;
      const defaultCurrency = currencies.find(
        (currency) => currency.id === defaultCurrencyId
      );

      if (!defaultCurrency) {
        throw new Error(`Default currency not found in the list of currencies`);
      }

      const getExchangeRate = (currencyId: number) => {
        // TODO Currently the exchange rate api only ever returns exchange rates
        // from target to EUR. Given the api is ever extended this needs to be
        // reflected here.
        const exchangeRate = exchangeRates.find(
          (exchangeRate) => exchangeRate.fromCurrencyId === currencyId
        );

        return exchangeRate?.rate ?? null;
      };

      const getFormattedRate = (id: number) => {
        const rate = getExchangeRate(id) ?? '?';

        //the api returns rates form target to base, we want to show it the way around
        const invertedRate = typeof rate === 'number' ? 1 / rate : '?';

        return typeof invertedRate === 'number'
          ? formatNumberAsCurrency(invertedRate)
          : invertedRate;
      };
      return {
        defaultCurrency,
        getExchangeRate,
        getFormattedRate,
      };
    } catch (error) {
      console.error(error);
      return null;
    }
  }, [data]);

  return (
    <CurrencyExchangeInfoContext.Provider value={contextValue}>
      {children}
    </CurrencyExchangeInfoContext.Provider>
  );
}
