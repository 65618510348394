import { useMemo } from 'react';

import { DocumentClass } from '@work4all/models/lib/Classes/DocumentClass.entity';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { useDocumentClassesContext } from './document-classes-context';

export function useDocumentClasses({
  businessPartnerType,
}: {
  businessPartnerType?: SdObjType;
} = {}): DocumentClass[] {
  const { documentClasses } = useDocumentClassesContext();

  const filteredDocumentClasses = useMemo(() => {
    if (!businessPartnerType) {
      return documentClasses;
    }

    return documentClasses.filter((documentClass) => {
      return documentClass.businessPartnerType === businessPartnerType;
    });
  }, [documentClasses, businessPartnerType]);

  return filteredDocumentClasses;
}
