import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { HomeWidget } from './HomeWidgetsContextProvider';

export const WidgetDivider = (
  props: HomeWidget & {
    onDeleteClick?: () => void;
    onEditClick?: () => void;
    pageSize?: number;
  }
) => {
  const { t } = useTranslation();

  const { onEditClick, onDeleteClick, title } = props;

  const { handleClick, ...popoverState } = usePopoverState('bottom');

  const [showMenuButton, setShowMenuButton] = useState(false);

  return (
    <>
      <Popover
        {...popoverState}
        transformOrigin={{ vertical: 'center', horizontal: 'right' }}
      >
        <List disablePadding>
          {onEditClick && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  onEditClick();
                  popoverState.onClose();
                }}
              >
                <ListItemText primary={t('SETTINGS.SETTINGS')} />
              </ListItemButton>
            </ListItem>
          )}
          {onDeleteClick && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  onDeleteClick();
                  popoverState.onClose();
                }}
              >
                <ListItemText primary={t('COMMON.REMOVE')} />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Popover>
      <div
        style={{ display: 'flex', alignContent: 'center' }}
        onMouseEnter={() => setShowMenuButton(true)}
        onMouseLeave={() => setShowMenuButton(false)}
      >
        <Divider title={title} style={{ width: '100%', padding: '1rem 0' }} />
        {showMenuButton && (
          <IconButton
            size="medium"
            onClick={() => {
              handleClick();
              setShowMenuButton(true);
            }}
          >
            <MoreVertIcon />
          </IconButton>
        )}
      </div>
    </>
  );
};
