import styles from './Message.module.scss';

import { Stack } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components/EntityPickerPopover';
import { Chip } from '@work4all/components/lib/dataDisplay/chip/Chip';
import { ChipList } from '@work4all/components/lib/dataDisplay/chip/ChipList';

import { useDataProvider } from '@work4all/data';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { EMail } from '@work4all/models/lib/Classes/EMail.entity';
import { MailboxConfiguration } from '@work4all/models/lib/Classes/MailboxConfiguration.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMailKind } from '@work4all/models/lib/Enums/EMailKind.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { uppercaseFirstLetter } from '@work4all/utils/lib/uppercaseFirstLetter';

import { MailboxPickerField } from '../../../../../../../components/entity-picker/MailboxPickerField';
import { useValidateEmail } from '../../../../../../../hooks';
import { ControllerPlus } from '../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../form-plus/use-form-context-plus';
import { BaseActionButton, LabeledInput } from '../../../../../locked-inputs';
import { ControlWrapper } from '../../../../components/control-wrapper/ControlWrapper';
import { useMaskContext } from '../../../../hooks/mask-context';
import { useEntityRightsContext } from '../../../../hooks/use-entity-rights';
import { useEmailTemplaterContext } from '../../email-templater/email-templater-context';
import { EmailMaskFormValue } from '../../types';
import { EmailAddressPicker } from '../picker/EmailAddressPicker';
import {
  EmailPrioPicker,
  EmailPrioTranslationKeys,
} from '../picker/EmailPrioPicker';

import { TemplateAndSignature } from './components/signature/Signature';

type HiddenInputs = 'sender' | 'bcc' | 'cc';

export const Message: React.FC = () => {
  const { t } = useTranslation();

  const mask = useMaskContext<EMail>();
  const { data, isCreateMode } = mask;

  const rights = useEntityRightsContext();

  const { disabled: isTemplateDisabled } = useEmailTemplaterContext();

  const { register, control, watch, getFieldState, setValue } =
    useFormContextPlus<EmailMaskFormValue>();

  const [hiddenInputs, setHiddenInputs] = useState<HiddenInputs[]>([
    'sender',
    'bcc',
    'cc',
  ]);

  const showInput = (inputName: HiddenInputs) => {
    setHiddenInputs((hidden) => {
      return hidden.filter((name) => {
        return name !== inputName;
      });
    });
  };

  const isInputVisible = (name: HiddenInputs) => {
    return !hiddenInputs.includes(name);
  };

  const bccMailContacts = watch('bccMailContactsInstance');
  const ccMailContacts = watch('ccMailContactsInstance');
  const toMailContacts = watch('toMailContactsInstance');
  const intendedSenderAddress = watch('intendedSenderAddress');

  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down('xl'));

  const requestData = useMemo<DataRequest>(() => {
    const data: MailboxConfiguration = {
      id: null,
      smtpAddress: null,
    };

    return {
      entity: Entities.mailboxConfiguration,
      data,
      completeDataResponse: true,
    };
  }, []);

  const response = useDataProvider(requestData);
  const multipleInboxes = response.total > 1;

  const senderVisible = multipleInboxes || isInputVisible('sender');
  const ccVisible = !downLg || !!ccMailContacts?.length || isInputVisible('cc');
  const bccVisible = !!bccMailContacts?.length || isInputVisible('bcc');

  const emailIsFinalized =
    data.kind !== EMailKind.ENTWURF && data.kind !== EMailKind.ENTWURF_HTML;

  const eMailPrioWrapperRef = useRef<HTMLDivElement>();
  const pickerFromWrapperRef = useRef<HTMLDivElement>();
  const pickerToWrapperRef = useRef<HTMLDivElement>();
  const pickerCcWrapperRef = useRef<HTMLDivElement>();
  const pickerBccWrapperRef = useRef<HTMLDivElement>();

  const getMailContactsChips = useCallback(
    (field, disabled = false) => {
      const emailList = field?.value?.filter((x) => x.mailAddress) || [];
      if (emailList.length) {
        return (
          <ChipList>
            {emailList.map((item, idx) => {
              const val = item.mailAddress;
              return (
                <Chip
                  key={idx}
                  withSeperator={false}
                  maxWidth={false}
                  label={val}
                  disabled={disabled || emailIsFinalized}
                  handleDelete={() => {
                    field.onChange(
                      field.value.filter((item, index) => index !== idx)
                    );
                  }}
                />
              );
            })}
          </ChipList>
        );
      }

      return '';
    },
    [emailIsFinalized]
  );

  const businessPartner = watch('businessPartner.data');

  const validateEmail = useValidateEmail();

  const getMailContactPicker = useCallback(
    (field) => {
      return (
        <EmailAddressPicker
          multiple={true}
          value={field.value}
          businessPartner={businessPartner}
          onChange={(value) => {
            field.onChange(
              value.map((x) => ({
                mailAddress: x.mailAddress || (x as unknown as Contact).eMail,
                id: x.id,
              }))
            );
            if (
              mask.isEditMode ||
              getFieldState('businessPartner.data').isDirty ||
              getFieldState('contact').isDirty
            ) {
              return;
            }

            if (value.length === 1) {
              const email = value[0];
              validateEmail(email.mailAddress);

              const contact = email.contact;
              if (contact) {
                const typename = contact.__typename;

                switch (typename) {
                  case 'Kunde':
                  case 'Lieferant': {
                    setValue(
                      'businessPartner.data',
                      contact as Customer | Supplier
                    );
                    setValue('contact', null);

                    break;
                  }

                  case 'Ansprechpartner': {
                    if (toMailContacts.length === 0) {
                      setValue(
                        'businessPartner.data',
                        (contact as Contact).businessPartner.data
                      );
                    } else {
                      return null;
                    }
                    if (toMailContacts.length === 0) {
                      setValue('contact', contact as Contact);
                    } else {
                      return null;
                    }

                    break;
                  }
                }
              }
            }
          }}
        />
      );
    },
    [
      businessPartner,
      getFieldState,
      mask.isEditMode,
      setValue,
      toMailContacts?.length,
    ]
  );

  const watchedFrom = watch('from');

  return (
    <div className={styles.root}>
      <Collapse
        title={t('MASK.MESSAGE')}
        topIndent={false}
        withToggleButton={false}
        bottomIndent="sm"
        headerBar={
          <HiddenInputButtonsWrapper>
            {!senderVisible &&
              watchedFrom &&
              (!intendedSenderAddress ||
                intendedSenderAddress === watchedFrom) && (
                <BaseActionButton
                  onClick={() => showInput('sender')}
                  title={t('MASK.SENDER')}
                />
              )}
            {!bccVisible && (
              <BaseActionButton
                onClick={() => showInput('bcc')}
                title={t('MASK.BCC')}
              />
            )}
            {!ccVisible && (
              <BaseActionButton
                onClick={() => showInput('cc')}
                title={t('MASK.CC')}
              />
            )}
          </HiddenInputButtonsWrapper>
        }
        defaultOpen={true}
      >
        {(senderVisible ||
          !watchedFrom ||
          (intendedSenderAddress && intendedSenderAddress !== watchedFrom)) && (
          <ControlWrapper
            ref={pickerFromWrapperRef}
            className={styles.container}
          >
            <ControllerPlus
              control={control}
              name="from"
              render={({ field: from }) => {
                return emailIsFinalized ? (
                  <LabeledInput
                    disabled
                    label={t('MASK.SENDER')}
                    value={watchedFrom}
                  />
                ) : (
                  <Stack direction="column">
                    <MailboxPickerField
                      {...from}
                      value={{
                        id: from.value,
                        mailboxPrimaryAddress: from.value,
                      }}
                      onChange={(value) => {
                        from.onChange(value.mailboxPrimaryAddress);
                      }}
                      clearable={false}
                      label={t('MASK.SENDER')}
                      required
                    />
                    {intendedSenderAddress &&
                      intendedSenderAddress !== from.value && (
                        <div className={styles.hint}>
                          {t('EMAIL.PLEASE_USE_MAILBOX')}{' '}
                          <strong>{intendedSenderAddress}</strong>
                        </div>
                      )}
                  </Stack>
                );
              }}
            />
          </ControlWrapper>
        )}
        <ControlWrapper ref={pickerToWrapperRef} className={styles.container}>
          <ControllerPlus
            control={control}
            name="toMailContactsInstance"
            render={({
              field: toMailContactInstance,
              fieldState: { error },
            }) => {
              return emailIsFinalized ? (
                <LabeledInput
                  disabled={true}
                  label={t('MASK.TO')}
                  value={getMailContactsChips(toMailContactInstance)}
                ></LabeledInput>
              ) : (
                <EntityPickerPopover
                  anchorEl={pickerToWrapperRef}
                  picker={getMailContactPicker(toMailContactInstance)}
                >
                  <LabeledInput
                    label={t('MASK.TO')}
                    error={error?.message}
                    value={getMailContactsChips(toMailContactInstance)}
                    required
                  ></LabeledInput>
                </EntityPickerPopover>
              );
            }}
          />
        </ControlWrapper>
        {ccVisible && (
          <ControlWrapper ref={pickerCcWrapperRef} className={styles.container}>
            <ControllerPlus
              control={control}
              name="ccMailContactsInstance"
              render={({ field: cc }) => {
                return emailIsFinalized ? (
                  <LabeledInput
                    disabled={true}
                    label={t('MASK.CC')}
                    value={getMailContactsChips(cc)}
                  ></LabeledInput>
                ) : (
                  <EntityPickerPopover
                    anchorEl={pickerCcWrapperRef}
                    picker={getMailContactPicker(cc)}
                  >
                    <LabeledInput
                      label={t('MASK.CC')}
                      value={getMailContactsChips(cc)}
                    ></LabeledInput>
                  </EntityPickerPopover>
                );
              }}
            />
          </ControlWrapper>
        )}
        {bccVisible && (
          <ControlWrapper
            ref={pickerBccWrapperRef}
            className={styles.container}
          >
            <ControllerPlus
              control={control}
              name="bccMailContactsInstance"
              render={({ field: bcc }) => {
                return emailIsFinalized ? (
                  <LabeledInput
                    disabled={true}
                    label={t('MASK.BCC')}
                    value={getMailContactsChips(bcc)}
                  ></LabeledInput>
                ) : (
                  <EntityPickerPopover
                    anchorEl={pickerBccWrapperRef}
                    picker={getMailContactPicker(bcc)}
                  >
                    <LabeledInput
                      label={t('MASK.BCC')}
                      value={getMailContactsChips(bcc)}
                    ></LabeledInput>
                  </EntityPickerPopover>
                );
              }}
            />
          </ControlWrapper>
        )}
        <ControlWrapper columns={3} paddingBottom={false}>
          <ControlWrapper gridColumn="1 / span 2">
            <LabeledInput
              {...register('subject')}
              label={t('COMMON.SUBJECT')}
              disabled={
                emailIsFinalized || isCreateMode
                  ? !rights.create
                  : !rights.update
              }
            />
          </ControlWrapper>
          <ControlWrapper gridColumn="3">
            <div ref={eMailPrioWrapperRef}>
              <ControllerPlus
                control={control}
                name="priority"
                render={({ field: priority }) => {
                  return (
                    <EntityPickerPopover
                      anchorEl={eMailPrioWrapperRef}
                      picker={
                        <EmailPrioPicker
                          multiple={false}
                          value={{ id: priority.value, name: '' }}
                          onChange={(value) => {
                            priority.onChange(value.id);
                          }}
                        />
                      }
                    >
                      <LabeledInput
                        disabled={
                          emailIsFinalized || isCreateMode
                            ? !rights.create
                            : !rights.update
                        }
                        label={uppercaseFirstLetter(t('INPUTS.PRIORITY'))}
                        error={priority.error}
                        value={
                          priority?.value
                            ? (t(
                                EmailPrioTranslationKeys[priority?.value]
                              ) as string)
                            : ''
                        }
                        onClear={() => priority.onChange(null)}
                      />
                    </EntityPickerPopover>
                  );
                }}
              />
            </div>
          </ControlWrapper>
        </ControlWrapper>
        <ControlWrapper columns={2}>
          {!isTemplateDisabled && (
            <ControlWrapper gridColumn="1 / span 2" paddingBottom={false}>
              <TemplateAndSignature />
            </ControlWrapper>
          )}
        </ControlWrapper>
      </Collapse>
    </div>
  );
};

function HiddenInputButtonsWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      divider={
        <Divider
          orientation="vertical"
          sx={{ height: '1rem', borderColor: 'var(--ui04)' }}
        />
      }
    >
      {children}
    </Stack>
  );
}
