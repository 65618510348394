import { gql, useMutation } from '@apollo/client';

export const SET_SETTING_MUTATION = gql`
  mutation SetSetting($setting: InputSetting!) {
    setSetting(input: $setting) {
      name
      value
    }
  }
`;

export interface SetSettingMutationVariables {
  setting: {
    name: string;
    personal: boolean;
    value: string | null;
  };
}

export function useSetSettingMutation() {
  return useMutation<void, SetSettingMutationVariables>(SET_SETTING_MUTATION, {
    context: {
      singleBatch: true,
    },
  });
}

export const DELETE_SETTING_MUTATION = gql`
  mutation DeleteSetting($setting: InputSetting!) {
    deleteSetting(setting: $setting)
  }
`;

export interface DeleteSettingMutationVariables {
  setting: {
    name: string;
    personal: boolean;
  };
}

export function useDeleteSettingMutation() {
  return useMutation<void, DeleteSettingMutationVariables>(
    DELETE_SETTING_MUTATION
  );
}
