import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton, Tab as MuiTab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tab } from '../types';

interface BottomViewHeaderProps {
  tab: Tab;
  onTabChange: (value: Tab) => void;
  isCollapsed: boolean;
  onCollapsedChange: (value: boolean) => void;
}

export const BottomViewHeader = ({
  isCollapsed,
  onCollapsedChange,
  tab,
  onTabChange,
}: BottomViewHeaderProps) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', padding: '12px' }}>
      <IconButton
        onClick={() => onCollapsedChange(!isCollapsed)}
        style={{ marginRight: '12px' }}
      >
        {isCollapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>

      <Tabs
        value={tab}
        onChange={(_, value) => {
          onTabChange(value);
        }}
      >
        <MuiTab
          value={Entities.rELedgerAccountSplit}
          label={t('COMMON.BOOKINGS')}
        />

        <MuiTab
          value={Entities.inboundInvoicePayment}
          label={t('COMMON.PAYMENTS')}
        />
      </Tabs>
    </div>
  );
};
