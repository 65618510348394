import { useTranslation } from 'react-i18next';

import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';

import { settings, useSetting } from '../../../../../../../../settings';

export const DragAndDropCheckbox = () => {
  const { t } = useTranslation();
  const { value, set } = useSetting(settings.calendarDragAndDropAppointments());

  return (
    <div style={{ paddingLeft: '3rem', paddingTop: '1rem' }}>
      <CheckboxRadioItem
        label={t('SETTINGS.CALENDAR.DRAG_AND_DROP')}
        onChange={(e) => {
          set(e.target.checked);
        }}
        checked={value}
      />
    </div>
  );
};
