const DEFAULT_FRACTION = 2;

export function formatNumberAsCurrency(
  value: number,
  fraction?: { minimum?: number; maximum?: number }
) {
  const currencyFormat = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: fraction?.minimum ?? DEFAULT_FRACTION,
    maximumFractionDigits: fraction?.maximum ?? DEFAULT_FRACTION,
  });
  return value != null ? currencyFormat.format(value) : '';
}
