import styles from './PreviewTextContent.module.scss';

import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React from 'react';

import { InfoCardLabel } from '../info-card-label/InfoCardLabel';

export type IPreviewTextContentProps = {
  title?: string | null;
  /**
   * Whether or not the preview content area should be scrollable. If set to
   * `true`, the content element will create a scroll container. Otherwise the
   * whole preview container will be scrolled.
   */
  scrollable?: boolean;

  children: React.ReactNode;
  className?: string;
  childrenClassName?: string;
  key?: React.Key;
};

export function PreviewTextContent(props: IPreviewTextContentProps) {
  const { title, scrollable, children, className, childrenClassName, key } =
    props;

  return (
    <div
      className={clsx(
        styles['preview-text-content'],
        scrollable && styles.scrollable,
        className
      )}
      key={key}
    >
      {!!title && (
        <div className={styles['title']}>
          <InfoCardLabel>{title}</InfoCardLabel>
        </div>
      )}
      <Typography className={childrenClassName}>{children}</Typography>
    </div>
  );
}
