import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputAddressConnectionRelation } from './InputAddressConnectionRelation.entity';
import { InputCategoryMarkRelation } from './InputCategoryMarkRelation.entity';

export class InputAnsprechpartnerRelation<T extends EMode = EMode.entity> {
  /** Alias: categoryMarks */
  categoryMarks?: InputCategoryMarkRelation<T>;
  /** Alias: addressConnection */
  addressConnection?: InputAddressConnectionRelation<T>;

  __typename?: string;
}

const fields: FieldDefinitions<InputAnsprechpartnerRelation> = {
  categoryMarks: {
    alias: 'categoryMarks',
  },
  addressConnection: {
    alias: 'addressConnection',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputAnsprechpartnerRelationEntityDefinition: EntitiyDefinition<InputAnsprechpartnerRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputAnsprechpartnerRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
