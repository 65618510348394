import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class InputTopicMarkRelation<T extends EMode = EMode.entity> {
  /** Alias: set */
  set?: number;

  __typename?: string;
}

const fields: FieldDefinitions<InputTopicMarkRelation> = {
  set: {
    alias: 'set',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputTopicMarkRelationEntityDefinition: EntitiyDefinition<InputTopicMarkRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputTopicMarkRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
