import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { ServiceContract } from '@work4all/models/lib/Classes/ServiceContract.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export function useServiceContracts(customerId?: string) {
  const requestData = useMemo<DataRequest>(() => {
    const filter = [{ mainAddressSdObjMemberId: { $eq: customerId } }];

    return {
      entity: Entities.serviceContract,
      data: SERVICE_CONTRACT_FIELDS,
      filter,
    };
  }, [customerId]);

  const { data, pending, refetch } = useDataProvider<ServiceContract>(
    requestData,
    !customerId
  );
  const serviceContracts = data;
  return { serviceContracts, pending, refetch };
}

const SERVICE_CONTRACT_FIELDS: ServiceContract<EMode.query> = {
  id: null,
  name: null,
  note: null,
  mainAddressSdObjMemberId: null,
  serviceContractEndDate: null,
  businessPartnerContactCombined: {
    businessPartner: {
      id: null,
      businessPartnerType: null,
      data: {
        customer: {
          id: null,
          name: null,
        },
        supplier: {
          id: null,
          name: null,
        },
      },
    },
  },
};
