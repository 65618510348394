import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';

import { useUser } from '@work4all/data';
import { useSetting } from '@work4all/data/lib/settings/use-setting';

import { settings } from '../../../../../../../../settings';

export function RtfSection() {
  const { t } = useTranslation();

  const rtfTextsInERP = useSetting(settings.rtfTextsInERP());
  const user = useUser();

  return (
    <Collapse title={t('SETTINGS.ERP.TEXTS')} defaultOpen>
      <CheckboxRadioItem
        label={t('SETTINGS.ERP.ALLOW_RTF')}
        checked={rtfTextsInERP.value}
        onChange={(e) => rtfTextsInERP.set(e.target.checked)}
        disabled={!user.isMaster}
      />
    </Collapse>
  );
}
