import { useEffect, useMemo, useState } from 'react';

import { useDataProvider } from '@work4all/data';

import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export function useEntityPreviewData<T>(
  entityIds: number[] | string[],
  requestedEntityType: Entities,
  requestedEntityData: object,
  additionalVars?: Record<string, unknown>
): T[] {
  const elementsToDeps = JSON.stringify(entityIds);
  const [dataResult, setDataResult] = useState<T[]>();

  const requestData = useMemo<DataRequest>(() => {
    // filter group element which has no id
    const ids = (entityIds as (string | number)[]).filter(
      (x: string | number) => x
    );
    return {
      entity: requestedEntityType,
      data: requestedEntityData,
      filter: [{ id: { $in: ids } }],
      vars: additionalVars,
    };
  }, [
    elementsToDeps,
    entityIds,
    requestedEntityType,
    requestedEntityData,
    additionalVars,
  ]);

  useEffect(() => {
    setDataResult(undefined);
  }, [elementsToDeps]);

  const { data } = useDataProvider<T>(requestData);
  useEffect(() => {
    if (data && data[0]) {
      setDataResult(data);
    }
  }, [data]);

  return dataResult;
}
