import AddIcon from '@mui/icons-material/Add';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams } from 'react-router-dom';

import { CountIndicator } from '@work4all/components/lib/dataDisplay/count-indicator/CountIndicator';

import { Link, useDataProvider } from '@work4all/data';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Calendar } from '../../../calendar/Calendar';
import { useCalendarInMemoryState } from '../../../calendar/hooks/use-calendar-in-memory-state';
import { CollapsibleCard } from '../CollapsibleCard/CollapsibleCard';

import { CardWidgetProps } from './types';

interface OpenVacationMaskOptions {
  appointmentId?: number | null;
  presetFields?: string | null;
}

export interface CalendarCardProps extends CardWidgetProps {
  onOpenAppointmentMask: (options: OpenVacationMaskOptions) => void;
  forceMobileView?: boolean;
  hideCollapseButton?: boolean;
  hideAddButton?: boolean;
}

const EMPTY_ARRAY = [];
export function CalendarCard(props: CalendarCardProps) {
  const {
    onOpenAppointmentMask,
    forceMobileView = false,
    hideCollapseButton,
    hideAddButton,
    userList = EMPTY_ARRAY,
    ...others
  } = props;
  const { collapsed } = others;

  const { t } = useTranslation();

  const calendar = useCalendarInMemoryState();

  function maybeRenderHeaderContent() {
    if (!calendar.isReady) return null;

    const params = createSearchParams({
      userIds: userList.map((x) => x.id).join(','),
    });
    return (
      <>
        {collapsed && <IndicatorContainer users={userList} />}

        {!hideAddButton && (
          <IconButton
            data-test-id="add-appointment-btn"
            component={Link}
            to={`details/appointment/new?${params}`}
            size="medium"
          >
            <AddIcon />
          </IconButton>
        )}
      </>
    );
  }

  function maybeRenderCalendar() {
    if (!calendar.isReady) {
      return (
        <Box sx={{ flex: '1', display: 'grid', placeItems: 'center' }}>
          <CircularProgress />
        </Box>
      );
    }

    const calendarProps = calendar.getCalendarProps();

    return (
      <Calendar
        {...calendarProps}
        users={userList}
        focusedUserIds={EMPTY_ARRAY}
        forceMobileView={forceMobileView}
        onOpenMask={(appointmentId, presetFields) => {
          onOpenAppointmentMask({ appointmentId, presetFields });
        }}
        hideMobileAddButton
        hideMobileUserSelection
      />
    );
  }

  return (
    <CollapsibleCard
      title={t('CALENDAR.VIEWS.MY_APPOINTMENTS')}
      href="/more/calendar"
      headerContent={maybeRenderHeaderContent()}
      hideCollapseButton={hideCollapseButton}
      {...others}
    >
      <Box
        sx={(theme) => ({
          minHeight: 300,
          display: 'flex',
          flex: '1 0 auto',
          position: 'relative',
          [theme.breakpoints.up('xl')]: {
            minHeight: 0,
          },
        })}
      >
        {maybeRenderCalendar()}
      </Box>
    </CollapsibleCard>
  );
}

function IndicatorContainer(props: { users: User[] }) {
  const { users } = props;

  const appointments = useAppointmentsSummary({ users });

  return appointments.length > 0 ? (
    <Box paddingRight="1rem">
      <CountIndicator value={appointments.length} />
    </Box>
  ) : null;
}

function useAppointmentsSummary({ users }: { users: User[] }) {
  const request = useMemo(() => {
    const today = DateTime.now();

    // adding a millisecond to avoid catching whole-day appointments from the day before
    const startDate = today.startOf('day').plus({ millisecond: 1 });
    const endDate = startDate.endOf('day');

    const startDateJson = startDate.toISO();
    const endDateJson = endDate.toISO();

    const dataRequest: DataRequest = {
      operationName: 'GetAppointmentsSummaryForCalendar',
      entity: Entities.appointment,
      data: {
        id: null,
      } as Appointment<EMode.query>,
      filter: [
        { startDate: { $lt: endDateJson } },
        { endDate: { $gte: startDateJson } },
        {
          userId: {
            $in: users.length > 0 ? users.map((user) => user.id) : [-1],
          },
        },
      ].filter(Boolean),
    };

    return dataRequest;
  }, [users]);

  const response = useDataProvider<Appointment>(request, false, 9999);

  const { data } = response;

  return data;
}
