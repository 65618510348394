import styles from './CountIndicator.module.scss';

import clsx from 'clsx';
import React from 'react';

export interface ICountIndicatorProps {
  value: number;
  variant?: 'primary' | 'secondary';
}

export const CountIndicator: React.FC<ICountIndicatorProps> = (props) => {
  const { value, variant = 'primary' } = props;

  return (
    <div
      className={clsx(
        styles.indicator,
        variant === 'secondary'
          ? styles.secondaryIndicator
          : styles.primaryIndicator
      )}
    >
      {value}
    </div>
  );
};
