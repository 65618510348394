import React, { useMemo } from 'react';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import schema from '../../../../../components/data-tables/schemata/chronoFileItem-table-schema.json';
import { ListPage } from '../../../../../components/list-page/ListPage';
import {
  ListPageContext,
  ListPageContextValue,
} from '../../../../file-entities-lists/list-page-context';

import { AddFileMenuMobile } from './AddFileMenuMobile';
import { ChronoFileList, ChronoFileListProps } from './ChronoFileList';

export const ChronoFileListContainer: React.FC<Partial<ChronoFileListProps>> = (
  props: Partial<ChronoFileListProps>
) => {
  const entity: Entities = Entities.chronoFileItem;
  const listPageContextValue = useMemo<ListPageContextValue>(() => {
    return { entityType: entity };
  }, [entity]);

  if (!schema) return null;

  return (
    <ListPageContext.Provider value={listPageContextValue}>
      <ListPage
        renderTable={(tableApiRef) => {
          return (
            <ChronoFileList
              ref={tableApiRef}
              schema={schema as never}
              {...props}
            />
          );
        }}
      />
      <AddFileMenuMobile />
    </ListPageContext.Provider>
  );
};
