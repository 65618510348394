import styles from './ExpandButton.module.scss';

import { ButtonBase } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { ITypographyProps } from '../../typography';
import { Body1 } from '../../typography/body1/Body1';
import { Body2 } from '../../typography/body2/Body2';
import { ButtonText } from '../../typography/button-text/ButtonText';

export type IBaseActionButtonProps = {
  icon?: JSX.Element;
  title?: string;
  className?: string;
  color?: 'primary' | 'error' | 'text01' | 'text03' | 'textInverse';
  component?: 'body1' | 'body2' | 'buttonText';
  alignment?: 'left' | 'center' | 'right';
  horizontalPadding?: 'xs' | 's' | false;
  disabled?: boolean;
  classes?: {
    title?: string;
  };
  autoFocus?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  textStart?: 'top' | 'bottom';
  orientation?: 'vertical' | 'horizontal';
};

export const ExpandButton: React.FC<IBaseActionButtonProps> = (props) => {
  const {
    icon,
    title,
    className,
    color = 'text03',
    alignment,
    disabled,
    horizontalPadding = 'xs',
    component = 'body1',
    classes,
    children,
    type,
    onClick,
    autoFocus = false,
    textStart = 'top',
    orientation = 'vertical',
  } = props;

  let TitleComponent: React.FC<ITypographyProps>;
  if (component === 'body1') {
    TitleComponent = Body1;
  } else if (component === 'body2') {
    TitleComponent = Body2;
  } else {
    TitleComponent = ButtonText;
  }

  return (
    <ButtonBase
      disabled={disabled}
      type={type}
      className={clsx(
        styles.action,
        styles[orientation],
        styles[color],
        {
          [styles[alignment]]: alignment,
          [styles[`horizontalPadding--${horizontalPadding}`]]:
            horizontalPadding,
        },
        styles.verticalPadding,
        className
      )}
      onClick={onClick}
      autoFocus={autoFocus}
    >
      {icon && <div className={styles.iconWrapper}>{icon}</div>}
      {title && (
        <TitleComponent
          className={clsx(styles.title, classes?.title, {
            [styles.rotate]: textStart === 'bottom',
          })}
        >
          {title}
        </TitleComponent>
      )}
      {children}
    </ButtonBase>
  );
};
