import { useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { DndTypes } from '@work4all/utils/lib/variables';

import { useWidgetDrapDropContext } from './use-widget-drag-drop-context';

export const useWidgetDrop = () => {
  const { canDrop: canDropItem, canSkip, onDrop } = useWidgetDrapDropContext();
  const isCorrect = canDropItem();

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: DndTypes.FILE_CARD_WIDGET,
      drop: (item) => {
        if (isCorrect) onDrop(item);
      },
      collect: (monitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [isCorrect, onDrop]
  );

  return {
    isOver,
    canDrop: canDrop && !canSkip(),
    drop,
    isCorrect,
  };
};

export function useWidgetDrag<T>(item: T) {
  const { canDrag: canDragItem } = useWidgetDrapDropContext();

  const [{ isDragging }, drag, preview] = useDrag(
    {
      type: DndTypes.FILE_CARD_WIDGET,
      item,
      collect(monitor) {
        return {
          isDragging: monitor.isDragging(),
        };
      },
    },
    [item]
  );

  useEffect(() => {
    preview(getEmptyImage());
  }, [preview]);

  return canDragItem()
    ? { isDragging, drag, preview }
    : {
        isDragging: false,
        drag: undefined,
        preview: undefined,
      };
}
