import styles from '../EntityPicker.module.scss';

import Typography from '@mui/material/Typography';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import {
  EntityPickerTabProps,
  ListEntityPicker,
} from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

const ErpPositionKindTransKeys = {
  [ErpPositionsKind.ZWISCHENSUMME]: 'COMMON.ERP.SUB_TOTAL',
  [ErpPositionsKind.TITEL]: 'COMMON.ERP.TITLE',
  [ErpPositionsKind.TITELSUMME]: 'COMMON.ERP.TITLE_TOTAL',
  [ErpPositionsKind.SEITENUMBRUCH]: 'COMMON.PAGE_BREAK',
};

const availableErpPositionKinds = [
  ErpPositionsKind.ZWISCHENSUMME,
  ErpPositionsKind.TITEL,
  ErpPositionsKind.TITELSUMME,
  ErpPositionsKind.SEITENUMBRUCH,
];

const CONST_ARRAY = [];
export type IArticlePickerProps<TMultiple extends boolean> = IPickerProps<
  Article,
  TMultiple
> & {
  showFunctions?: boolean;
};

export function ArticlePicker<TMultiple extends boolean>(
  props: IArticlePickerProps<TMultiple>
) {
  const {
    data,
    onChange,
    showFunctions,
    prefilter = CONST_ARRAY,
    ...rest
  } = props;
  const { t } = useTranslation();
  const allFields = useMemo(() => {
    return { ...ARTICLE_FIELDS, ...data };
  }, [data]);

  const [searchTerm, setSearchTerm] = useState('');

  const specialPositionsDataset = useMemo(() => {
    const result = availableErpPositionKinds
      .filter(
        (x) => !searchTerm || x.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .map((x) => ({ id: x, number: x }));

    const value = Array.isArray(props.value)
      ? props.value
      : props.value
      ? [props.value]
      : [];
    return {
      total: result.length,
      loading: false,
      fetchMore: () => Promise.resolve(),
      refetch: () => Promise.resolve(null),
      data: searchTerm.trim().length === 0 ? [...result, ...value] : result,
    };
  }, [searchTerm, props.value]);

  const tabs = useMemo<EntityPickerTabProps<Article>[]>(() => {
    const result: EntityPickerTabProps<Article>[] = [
      {
        entity: Entities.article,
        data: allFields,
        label: t('COMMON.ARTICLE'),
        placeholder: t('SEARCH.ARTICLE_SEARCH_BY'),
        filterBy: ['number', 'name'],
        sortBy: 'name',
        useSearchHistory: true,
        prefilter: [
          {
            isShutDown: { $eq: false },
          },
          ...prefilter,
        ],
        renderItemContent: (article) => (
          <Tooltip title={`${article.number} ${article.name}`}>
            <Typography
              variant="body2"
              noWrap
              sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
            >
              {article?.number && (
                <>
                  <span className={styles.secondaryInfo}>
                    {article?.number}
                  </span>
                  <span className={styles.secondaryInfo}>| </span>
                </>
              )}

              {article.name}
            </Typography>
          </Tooltip>
        ),
        favorites: true,
      },
    ];

    if (showFunctions) {
      result.push({
        entity: Entities.article,
        data: allFields,
        label: t('COMMON.FUNCTIONS'),
        placeholder: t('SEARCH.SEARCH_FUNCTIONS'),
        filterBy: ['name', 'number'],
        sortBy: 'name',
        useSearchHistory: false,
        fixedDataSet: specialPositionsDataset,
        renderItemContent: (article) => (
          <Tooltip title={`${article.number} ${article.name}`}>
            <Typography
              variant="body2"
              noWrap
              sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
            >
              {t(ErpPositionKindTransKeys[article?.number])}
            </Typography>
          </Tooltip>
        ),
        favorites: false,
      });
    }

    return result;
  }, [allFields, showFunctions, specialPositionsDataset, t, prefilter]);

  return (
    <ListEntityPicker
      tabs={tabs}
      onSearchValueChange={setSearchTerm}
      {...rest}
      onChange={(article) => {
        // @ts-expect-error TECH_DEBT
        if (article?.number in ErpPositionsKind) {
          // @ts-expect-error TECH_DEBT
          onChange(article?.number);
        } else {
          onChange(article);
        }
      }}
      layout="advanced"
    />
  );
}

const ARTICLE_FIELDS: Article = {
  id: null,
  number: null,
  name: null,
};
