import styles from './MaskPopover.module.scss';

import {
  ButtonBase,
  Popover,
  PopoverOrigin,
  PopoverProps,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from '@work4all/assets/icons/outline-close-24-2.svg';

import { BaseActionButton } from '@work4all/components/lib/input/base-action-button/BaseActionButton';
import { Heading4 } from '@work4all/components/lib/typography/heading4/Heading4';

const anchorOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const transformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

type Action = (() => void) | { onClicked: () => void; name: string };

interface IMaskPopoverProps
  extends Omit<PopoverProps, 'anchorOrigin' | 'transformOrigin'> {
  cancel: Action;
  submit: Action;
  title: string;
}

export const MaskPopover: React.FC<IMaskPopoverProps> = (props) => {
  const { children, cancel, submit, title, ...popoverProps } = props;
  const [innerOpen, setInnerOpen] = useState(popoverProps.open);

  const { t } = useTranslation();

  let cancelName: string;
  let submitName: string;
  let onSubmitClicked: () => void;
  let onCancelClicked: () => void;
  if (typeof cancel === 'function') {
    cancelName = t('ALERTS.BTN_ABORT');
    onCancelClicked = cancel;
  } else {
    cancelName = cancel?.name;
    onCancelClicked = cancel?.onClicked;
  }

  if (typeof submit === 'function') {
    submitName = t('MASK.TAKE_OVER');
    onSubmitClicked = submit;
  } else {
    submitName = submit.name;
    onSubmitClicked = submit.onClicked;
  }

  useEffect(() => {
    if (popoverProps.open) {
      setInnerOpen(true);
    }
  }, [popoverProps.open]);

  return (
    <Popover
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      classes={{ paper: styles.root }}
      onClose={onCancelClicked}
      {...popoverProps}
      TransitionProps={{
        onExited: () => {
          /**
           * "innerOpen" is performance optimization.
           * set it to false only after "hide" animation is complete
           */
          setInnerOpen(false);
        },
      }}
    >
      <div className={styles.header}>
        <Heading4 className={styles.heading}>{title}</Heading4>
        <ButtonBase className={styles.close} onClick={onCancelClicked}>
          <CloseIcon />
        </ButtonBase>
      </div>
      <div className={styles.body}>{innerOpen && children}</div>
      <div className={styles.footer}>
        <BaseActionButton
          className={styles.footerBtn}
          onClick={onCancelClicked}
        >
          {cancelName}
        </BaseActionButton>
        <BaseActionButton
          className={styles.footerBtn}
          onClick={onSubmitClicked}
        >
          {submitName}
        </BaseActionButton>
      </div>
    </Popover>
  );
};
