import { ColumnInstance, GlobalFilter } from '../types';

export const updateFilter = (
  value: unknown,
  setFilter: ColumnInstance['setFilter'],
  type: 'default' | 'popover'
) => {
  setFilter((old: GlobalFilter) => {
    if (type === 'popover') {
      return {
        ...old,
        popoverFilter: value,
      };
    }

    return {
      ...old,
      defaultFilter: value,
    };
  });
};

export const getDefaultFilter = <T>(filter?: GlobalFilter): T => {
  if (!filter) {
    return undefined;
  }

  return filter.defaultFilter as T;
};
