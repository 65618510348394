import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class KpisCustomerSalesValueYear<T extends EMode = EMode.entity> {
  /** Alias: fromDate */
  fromDate?: string;
  /** Alias: toDate */
  toDate?: string;
  /** Alias: value */
  value?: number;

  __typename?: string;
}

const fields: FieldDefinitions<KpisCustomerSalesValueYear> = {
  fromDate: {
    alias: 'fromDate',
  },
  toDate: {
    alias: 'toDate',
  },
  value: {
    alias: 'value',
  },
  __typename: {
    alias: '__typename',
  },
};

export const kpisCustomerSalesValueYearEntityDefinition: EntitiyDefinition<KpisCustomerSalesValueYear> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'KpisCustomerSalesValueYear',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
