import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';

import { IFilterPickerConfig } from '../../../../components/entity-picker/components/list-entity-picker';
import { ContactPicker } from '../../../../components/entity-picker/contact-picker/ContactPicker';
import { FilterCard } from '../filter-card/FilterCard';

export type IContactFilterCardProps = {
  title: string;
  value: Contact[] | null;
  onChange: (value: Contact[]) => void;
  expanded: boolean;
  onExpandedChange: (val: boolean) => void;
  filterConfig?: IFilterPickerConfig;
};

export function ContactFilterCard(props: IContactFilterCardProps) {
  const { title, value, onChange, expanded, onExpandedChange, filterConfig } =
    props;

  const { t } = useTranslation();

  const filterValueText: string = useMemo(() => {
    if (!value || value.length === 0) return null;
    return value.length + ' ' + t('COMMON.CHOSEN');
  }, [value, t]);

  return (
    <FilterCard
      title={title}
      filterValue={filterValueText}
      filterValueSet={!!filterValueText}
      expanded={expanded}
      onExpandedChange={onExpandedChange}
      onValueDelete={() => {
        onChange([]);
      }}
    >
      <ContactPicker
        layout="advanced"
        multiple={true}
        value={value}
        favorites={false}
        onChange={(newValue) => {
          onChange(newValue);
        }}
        filterConfig={filterConfig}
      />
    </FilterCard>
  );
}
