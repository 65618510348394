import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputBesuchsbericht<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: number;
  /** Alias: ansprechpartnerCode */
  ansprechpartnerCode?: number;
  /** Alias: benutzerCode */
  benutzerCode?: number;
  /** Alias: sdObjType */
  sdObjType?: SdObjType;
  /** Alias: sdObjMemberCode */
  sdObjMemberCode?: number;
  /** Alias: creationDate */
  creationDate?: string;
  /** Alias: thema */
  thema?: string;
  /** Alias: ort */
  ort?: string;
  /** Alias: uhrzeitVon */
  uhrzeitVon?: string;
  /** Alias: uhrzeitBis */
  uhrzeitBis?: string;
  /** Alias: objGrCode */
  objGrCode?: number;
  /** Alias: projektCode */
  projektCode?: number;
  /** Alias: art */
  art?: number;
  /** Alias: kNType */
  kNType?: number;
  /** Alias: klassifizierungCode */
  klassifizierungCode?: number;
  /** Alias: notiz */
  notiz?: string;
  /** Alias: templateName */
  templateName?: string;
  /** Alias: datei */
  datei?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];
  /** Alias: createdByUserCode */
  createdByUserCode?: number;

  __typename?: string;
}

const fields: FieldDefinitions<InputBesuchsbericht> = {
  code: {
    alias: 'code',
  },
  ansprechpartnerCode: {
    alias: 'ansprechpartnerCode',
  },
  benutzerCode: {
    alias: 'benutzerCode',
  },
  sdObjType: {
    alias: 'sdObjType',
  },
  sdObjMemberCode: {
    alias: 'sdObjMemberCode',
  },
  creationDate: {
    alias: 'creationDate',
  },
  thema: {
    alias: 'thema',
  },
  ort: {
    alias: 'ort',
  },
  uhrzeitVon: {
    alias: 'uhrzeitVon',
  },
  uhrzeitBis: {
    alias: 'uhrzeitBis',
  },
  objGrCode: {
    alias: 'objGrCode',
  },
  projektCode: {
    alias: 'projektCode',
  },
  art: {
    alias: 'art',
  },
  kNType: {
    alias: 'kNType',
  },
  klassifizierungCode: {
    alias: 'klassifizierungCode',
  },
  notiz: {
    alias: 'notiz',
  },
  templateName: {
    alias: 'templateName',
  },
  datei: {
    alias: 'datei',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customFields: {
    alias: 'customFields',
  },
  createdByUserCode: {
    alias: 'createdByUserCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputBesuchsberichtEntityDefinition: EntitiyDefinition<InputBesuchsbericht> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputBesuchsbericht',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
