import { cloneDeep } from 'lodash';
import { useMemo } from 'react';

import { ITableSchema } from '@work4all/models/lib/table-schema/table-schema';

export const useRightsSchema = (
  schema: ITableSchema<never>,
  convert: boolean
) => {
  return useMemo(() => {
    if (!convert) return schema;
    const newSchema = cloneDeep(schema);
    newSchema.columns = newSchema.columns.sort((x, y) => {
      if (y.id === 'number') return 1;
      if (x.id === 'number') return -1;
      if (y.id === 'name') return 1;
      if (x.id === 'name') return -1;
      if (y.id === 'customer.id') return 1;
      if (x.id === 'customer.id') return -1;
      return -1;
    });

    return newSchema;
  }, [schema, convert]);
};
