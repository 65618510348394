import React from 'react';

import { IStepWrapper } from '../../types';

export const StepWrapper = React.memo<IStepWrapper>((props) => {
  return React.cloneElement(props.children, {
    noUserStyles: {
      noLeftRadiuses: props.noLeftRadiuses,
      noRightRadiuses: props.noRightRadiuses,
      noTopRadiuses: props.noTopRadiuses,
      noBottomRadiuses: props.noBottomRadiuses,
    },
  });
});
