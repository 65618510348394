import styles from './Dialog.module.scss';

import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IBasicTableProps, Table } from '@work4all/components';
import { BaseActionButton } from '@work4all/components/lib/input/base-action-button/BaseActionButton';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';

interface DialogProps {
  open: boolean;
  data: (Customer | Supplier)[];
  onCancel: () => void;
  onSave: () => void;
}

export const Dialog = ({ open, data, onCancel, onSave }: DialogProps) => {
  const { t } = useTranslation();
  const columns = useMemo<IBasicTableProps['columns']>(() => {
    return [
      {
        Header: t('COMMON.NAME'),
        accessor: 'firm1',
        width: 150,
      },
      {
        Header: t('COMMON.STREET'),
        accessor: 'street',
        width: 150,
      },
      {
        Header: t('COMMON.POSTAL_CODE'),
        accessor: 'postalCode',
        width: 150,
      },
      {
        Header: t('COMMON.CITY'),
        accessor: 'city',
        width: 150,
      },
    ];
  }, [t]);

  return (
    <MUIDialog
      open={open}
      scroll="paper"
      PaperProps={{ style: { height: '400px', overflow: 'hidden' } }}
    >
      <DialogTitle>
        <div className={styles.dialogTitle}>
          <Typography variant="h4">
            {t('MASK.DUPLICATES_DIALOG.TITLE')}
          </Typography>
          <IconButton onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </div>
        <Typography variant="body1">
          {t('MASK.DUPLICATES_DIALOG.DESCRIPTION')}
        </Typography>
      </DialogTitle>

      <DialogContent className={styles.dialogContent}>
        <Table
          mode="client"
          allItemsCount={data.length}
          columns={columns}
          data={data}
          className={styles.table}
          resizableColumns
        />
      </DialogContent>

      <DialogActions>
        <BaseActionButton
          title={t('ALERTS.BTN_ABORT')}
          onClick={onCancel}
          color="primary"
          className={styles.actionButton}
          autoFocus
        />

        <BaseActionButton
          title={t('ALERTS.IGNORE_AND_SAVE')}
          onClick={onSave}
          color="primary"
          className={styles.actionButton}
          autoFocus
        />
      </DialogActions>
    </MUIDialog>
  );
};
