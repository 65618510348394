import { Theme, useMediaQuery } from '@mui/material';
import React from 'react';

import { MaskTabPanel } from '../../../../../mask-tabs';
import { INDIVIDUAL_TAB_ID } from '../../../../components/custom-fields/contants';
import { IndividualTabPanel } from '../../../../components/custom-fields/IndividualTabPanel';
import { MaskContent } from '../../../../components/MaskContent/MaskContent';
import { HistoryTabPanel } from '../../../../components/tab-panels/history/HistoryTabPanel';

import { AttachmentsTabPanel } from './attachments/AttachmentsTabPanel';
import { GeneralTabPanel } from './general/GeneralTabPanel';
import { MiscellaneousTabPanel } from './miscellaneous/MiscellaneousTabPanel';
import { PositionsTabPanel } from './positions/PositionsTabPanel';
import { TextTabPanel } from './text/TextTabPanel';

interface ErpTabPanelsProps {
  isCreateMode: boolean;
  renderIndividualTab: boolean;
}

export const ErpTabPanels: React.FC<ErpTabPanelsProps> = (props) => {
  const isTablet = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));

  return (
    <MaskContent>
      {isTablet && (
        <MaskTabPanel value="general">
          <GeneralTabPanel />
        </MaskTabPanel>
      )}

      <MaskTabPanel value="miscellaneous">
        <MiscellaneousTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value="text">
        <TextTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value="positions">
        <PositionsTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value="attachments">
        <AttachmentsTabPanel />
      </MaskTabPanel>

      {!props.isCreateMode && (
        <MaskTabPanel value="history">
          <HistoryTabPanel />
        </MaskTabPanel>
      )}

      {props.renderIndividualTab && (
        <MaskTabPanel value={INDIVIDUAL_TAB_ID}>
          <IndividualTabPanel />
        </MaskTabPanel>
      )}
    </MaskContent>
  );
};
