import styles from './../../MaskOverlay.module.scss';

import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AttachmentsDropZone } from '@work4all/components/lib/components/attachments/AttachmentsDropZone';
import { TRAVEL_RECEIPTS_DATA } from '@work4all/components/lib/components/entity-preview/travel-receipts-preview/TravelReceiptsPreviewContainer';
import { useGetTravelReceiptsStatus } from '@work4all/components/lib/components/entity-preview/travel-receipts-preview/use-get-travel-receipts-status';
import { useLock } from '@work4all/components/lib/hooks';

import {
  useDataMutation,
  useDataProvider,
  usePermissions,
  useUser,
} from '@work4all/data';
import {
  TempFileManagerContext,
  useTempFileManager,
} from '@work4all/data/lib/hooks/data-provider/useTempFileManager';
import { useSearchHistory } from '@work4all/data/lib/hooks/use-search-history';
import { useEntityJsonSchema } from '@work4all/data/lib/json-schema/EntityJsonSchemasContext';

import { InputCrmAnhangAttachementsRelation } from '@work4all/models/lib/Classes/InputCrmAnhangAttachementsRelation.entity';
import { InputReisekostenabrechnungBelegRelation } from '@work4all/models/lib/Classes/InputReisekostenabrechnungBelegRelation.entity';
import { LedgerAccount } from '@work4all/models/lib/Classes/LedgerAccount.entity';
import { TravelReceipts } from '@work4all/models/lib/Classes/TravelReceipts.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ReceiptKindInternal } from '@work4all/models/lib/Enums/ReceiptKindInternal.enum';

import { useJSONSchemaResolver } from '@work4all/utils';

import useAttachementsRelation from '../../../../../hooks/useAttachementsRelation';
import { settings, useSetting } from '../../../../../settings';
import { MaskOverlayStandardSubmitButton } from '../../components';
import { Form } from '../../components/form';
import { LockOverride } from '../../components/LockOverride';
import { MaskOverlayDeleteMenuItem } from '../../components/MaskOverlayDeleteMenuItem';
import { MaskOverlayHeader } from '../../components/MaskOverlayHeader/MaskOverlayHeader';
import { MaskOverlayMenuWrapper } from '../../components/MaskOverlayMenuWrapper';
import {
  MaskContextProvider,
  useMaskConfig,
  useMaskContextValue,
} from '../../hooks/mask-context';
import { useConfirmBeforeCloseMask } from '../../hooks/use-confrm-before-close-mask';
import { EntityRightsContext } from '../../hooks/use-entity-rights';
import { useStandardDeleteEntityHandler } from '../../hooks/use-standard-delete-entity-handler';
import { MaskControllerProps } from '../../types';
import { pickUpdateFields } from '../../utils/pick-update-fields';
import { useFormUpdate } from '../../utils/use-form-update';
import { CurrencyExchangeInfoContext } from '../inbound-invoice/currency-exchange-info-context';
import { CurrencyExchangeInfoContextProvider } from '../inbound-invoice/CurrencyExchangeInfoContextProvider';

import { General } from './components/General';
import { useTravelReceipts } from './hooks/use-travel-receipts';
import { TravelReceiptsFormValue } from './type';

export const TravelReceiptsOverlayController = (props: MaskControllerProps) => {
  return (
    <CurrencyExchangeInfoContextProvider>
      <TravelReceiptsOverlayControllerInternal {...props} />
    </CurrencyExchangeInfoContextProvider>
  );
};

const TravelReceiptsOverlayControllerInternal = (
  props: MaskControllerProps
) => {
  const { t } = useTranslation();

  const mask = useMaskConfig(props);

  const setting = useSetting(settings.travelReceiptDefaults());
  const data = useTravelReceipts(mask, setting.value);
  const getTravelReceiptsStatus = useGetTravelReceiptsStatus();
  const status = getTravelReceiptsStatus(data?.travelExpenses).key;

  const attachList = useMemo(() => {
    return data?.file?.map((x) => {
      return {
        ...x,
        fileName: x.localFilename,
      };
    });
  }, [data?.file]);

  const customRules = useCallback(
    (data: TravelReceiptsFormValue) => {
      const errors: Partial<
        Record<keyof TravelReceiptsFormValue, { message: string; type: string }>
      > = {};

      if (!data.description) {
        errors.description = {
          message: t('ERROR.FIELD_REQUIRED'),
          type: 'customValidation',
        };
      }

      if (!data.paymentMethod) {
        errors.paymentMethod = {
          message: t('ERROR.FIELD_REQUIRED'),
          type: 'customValidation',
        };
      }

      if (!data.receiptKind) {
        errors.receiptKind = {
          message: t('ERROR.FIELD_REQUIRED'),
          type: 'customValidation',
        };
      }

      if (
        data.receiptKind?.typeOfReceiptType === ReceiptKindInternal.FAHRTKOSTEN
      ) {
        if (!data.receiptKindTravelCost) {
          errors.receiptKindTravelCost = {
            message: t('ERROR.FIELD_REQUIRED'),
            type: 'customValidation',
          };
        }
      }
      if (data.vat1 === null || data.vat1 === undefined) {
        errors.vat1 = {
          message: t('ERROR.FIELD_REQUIRED'),
          type: 'customValidation',
        };
      }

      if (data.vat2 === null || data.vat2 === undefined) {
        errors.vat2 = {
          message: t('ERROR.FIELD_REQUIRED'),
          type: 'customValidation',
        };
      }

      if (data.vat3 === null || data.vat3 === undefined) {
        errors.vat3 = {
          message: t('ERROR.FIELD_REQUIRED'),
          type: 'customValidation',
        };
      }

      if (Object.keys(errors).length) return errors;
      return true;
    },
    [t]
  );

  const tempFileManager = useTempFileManager(attachList);
  const schema = useEntityJsonSchema(mask.entity);
  const resolver = useJSONSchemaResolver(schema, customRules);

  const form = useForm<TravelReceiptsFormValue>({
    resolver,
    mode: 'onChange',
    defaultValues: data,
    shouldFocusError: false,
    context: {
      schema,
    },
  });

  const { formState, handleSubmit, reset } = form;

  const { saveSearchItemFromEnityData } = useSearchHistory();

  const [mutate] = useDataMutation<
    TravelReceipts,
    EMode.upsert,
    InputReisekostenabrechnungBelegRelation
  >({
    entity: mask.entity,
    mutationType: EMode.upsert,
    responseData:
      TRAVEL_RECEIPTS_DATA as unknown as TravelReceipts<EMode.entity>,
    onCompleted: (data) => {
      if (mask.isCreateMode) {
        saveSearchItemFromEnityData(data);
      }

      props.onAfterSave(data);
    },
  });

  const ledgerAccountRequestData = useMemo(() => {
    return {
      entity: Entities.ledgerAccount,
      data: {
        id: null,
        name: null,
        number: null,
      },
    };
  }, []);

  const ledgerAccounts = useDataProvider<LedgerAccount>(
    ledgerAccountRequestData
  );
  const ledgerAccountsRef = useRef(ledgerAccounts);
  ledgerAccountsRef.current = ledgerAccounts;

  const exchangeInfoContext = useContext(CurrencyExchangeInfoContext);
  const exchangeRef = useRef(exchangeInfoContext);
  exchangeRef.current = exchangeInfoContext;
  useFormUpdate(
    {
      project(project) {
        return {
          projectId: project?.id ?? 0,
        };
      },
      costCenter(costCenter) {
        return {
          costCenterId: costCenter?.id ?? 0,
        };
      },
      currency(currency) {
        let course = 1;
        if (currency?.id && exchangeRef.current) {
          course = 1 / exchangeRef.current.getExchangeRate(currency?.id);
        }

        return {
          currencyId: currency?.id ?? 0,
          course,
        };
      },
      ledgerAccount1(ledgerAccount) {
        return {
          ledgerAccountId: ledgerAccount?.number ?? 0,
        };
      },
      ledgerAccount2(ledgerAccount) {
        return {
          ledgerAccount2Id: ledgerAccount?.number ?? 0,
        };
      },
      ledgerAccount3(ledgerAccount) {
        return {
          ledgerAccount3Id: ledgerAccount?.number ?? 0,
        };
      },
      receiptKindTravelCost(receiptKindTravelCost) {
        const res: TravelReceiptsFormValue = {
          receiptKindTravelCostId: receiptKindTravelCost?.id ?? 0,
        };
        if (ledgerAccountsRef.current?.data?.length) {
          const ledgerAccount1 = ledgerAccountsRef.current.data.find(
            (x) => x.number === receiptKindTravelCost.ledgerAccount1
          );
          const ledgerAccount2 = ledgerAccountsRef.current.data.find(
            (x) => x.number === receiptKindTravelCost.ledgerAccount2
          );
          const ledgerAccount3 = ledgerAccountsRef.current.data.find(
            (x) => x.number === receiptKindTravelCost.ledgerAccount3
          );
          if (ledgerAccount1) {
            res.ledgerAccount1 = ledgerAccount1;
            res.vat1 = receiptKindTravelCost.vat1;
          }
          if (ledgerAccount2) {
            res.ledgerAccount2 = ledgerAccount2;
            res.vat2 = receiptKindTravelCost.vat2;
          }
          if (ledgerAccount3) {
            res.ledgerAccount3 = ledgerAccount3;
            res.vat3 = receiptKindTravelCost.vat3;
          }
        }
        return res;
      },
      receiptKind(receiptKind) {
        return {
          receiptKindId: receiptKind?.id ?? 0,
        };
      },
      paymentMethod(paymentMethod) {
        return {
          paymentKind: paymentMethod?.id ?? 0,
        };
      },
    },
    form,
    [data, exchangeInfoContext]
  );

  const { lock, unlock } = useLock({
    subEntityType: Entities.travelReceipts,
    subEntityIds: data.id ? [data.id] : [],
  });
  useEffect(() => {
    lock();
    return () => {
      unlock();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    reset(data);
  }, [reset, data]);

  const isDirty = formState.isDirty || tempFileManager.fileListDirty;

  useConfirmBeforeCloseMask(isDirty);

  const handleDeleteEntitiesClick = useStandardDeleteEntityHandler(mask);
  const attachementsRelation =
    useAttachementsRelation<InputCrmAnhangAttachementsRelation>(
      tempFileManager,
      Entities.inputCrmAnhangAttachementsRelation,
      'id'
    );

  const user = useUser();
  const onSubmit = useCallback(
    async (input: TravelReceiptsFormValue) => {
      const updateRaw = mask.isCreateMode
        ? input
        : pickUpdateFields(input, formState.dirtyFields);

      if (typeof updateRaw.amountGross1 === 'string')
        updateRaw.amountGross1 = parseFloat(updateRaw.amountGross1);
      if (typeof updateRaw.amountGross2 === 'string')
        updateRaw.amountGross3 = parseFloat(updateRaw.amountGross2);
      if (typeof updateRaw.amountGross3 === 'string')
        updateRaw.amountGross3 = parseFloat(updateRaw.amountGross3);
      if (typeof updateRaw.vat1 === 'string')
        updateRaw.vat1 = parseFloat(updateRaw.vat1);
      if (typeof updateRaw.vat2 === 'string')
        updateRaw.vat2 = parseFloat(updateRaw.vat2);
      if (typeof updateRaw.vat3 === 'string')
        updateRaw.vat3 = parseFloat(updateRaw.vat3);

      setting.set(updateRaw);
      await mutate(updateRaw, {
        relations: {
          ...attachementsRelation,
          supplierCode: user.supplierCode,
        },
      });
    },
    [
      mask.isCreateMode,
      formState.dirtyFields,
      setting,
      mutate,
      attachementsRelation,
      user.supplierCode,
    ]
  );

  const permissions = usePermissions();
  const entityRights = useMemo(() => {
    const { canAdd, canEdit, canDelete } =
      permissions.permissions.travelReceipts;
    return {
      create: canAdd?.() ?? false,
      read: false,
      update: canEdit?.(data) ?? false,
      delete: canDelete?.(data) ?? false,
    };
  }, [permissions, data]);

  const maskContext = useMaskContextValue({ ...mask, data });
  const disabled =
    (mask.isEditMode && status !== 'parked') || !user.supplierCode;
  return (
    <LockOverride
      forceLock={disabled}
      lockReason={t('ALERTS.TRAVEL_RECEIPT_LOCKED')}
    >
      <MaskContextProvider value={maskContext}>
        <EntityRightsContext.Provider value={entityRights}>
          <TempFileManagerContext.Provider value={tempFileManager}>
            <FormProvider {...form}>
              <Form
                className={styles.maskForm}
                onSubmit={handleSubmit(onSubmit)}
              >
                <MaskOverlayHeader
                  title={t(`COMMON.${mask.entity.toUpperCase()}`)}
                  subTitle={data.note}
                  actions={
                    <>
                      <MaskOverlayStandardSubmitButton disabled={disabled} />
                      {mask.isEditMode && (
                        <MaskOverlayMenuWrapper>
                          <MaskOverlayDeleteMenuItem
                            key={'remove'}
                            disabled={mask.wip || !entityRights.delete}
                            onClick={handleDeleteEntitiesClick}
                          />
                        </MaskOverlayMenuWrapper>
                      )}
                    </>
                  }
                />
                <AttachmentsDropZone single>
                  <General />
                </AttachmentsDropZone>
              </Form>
            </FormProvider>
          </TempFileManagerContext.Provider>
        </EntityRightsContext.Provider>
      </MaskContextProvider>
    </LockOverride>
  );
};
