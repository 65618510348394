export const APP_VERSION_LS_KEY = 'appUpdates.version';
export const LAST_NOTIFICATION_DATE_LS_KEY = 'appUpdates.lastNotification';

/**
 * If a user dismisses an update notification, we'll show it again after this
 * interval. This value is in milliseconds.
 */
export const UPDATE_NOTIFICATION_INTERVAL_MS = 7 * 24 * 60 * 60 * 1000; // 1 week

export const API_VERSION_HEADER_NAME = 'x-work4all-api-version';
