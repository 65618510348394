import { ControllerPlus } from '../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../form-plus/use-form-context-plus';
import { LabeledInput } from '../../../../locked-inputs';
import { WithCustomFields } from '../with-custom-fields';

import { CustomFieldProps } from './types';

export function CustomTextField(props: CustomFieldProps) {
  const { name, label } = props;

  const { control } = useFormContextPlus<WithCustomFields<object>>();

  return (
    <ControllerPlus
      control={control}
      name={name}
      render={({ field }) => {
        return <LabeledInput label={label} {...field} />;
      }}
    />
  );
}
