import styles from './MARDialog.module.scss';

import { Divider, Typography } from '@mui/material';
import { useMemo } from 'react';

import { Dialog, DialogContent } from '@work4all/components/lib/dialog';

import { useModuleRights, useUser } from '@work4all/data';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';

import { ModuleAccessRight } from '@work4all/models/lib/Classes/ModuleAccessRight.entity';

export interface MARDialogProps {
  open: boolean;
  onClose: () => void;
}

export function MARDialog(props: MARDialogProps) {
  const { open, onClose } = props;

  return (
    <Dialog
      classes={{ dialog: { paper: styles.paper } }}
      open={open}
      onClose={onClose}
      closeButton={true}
      title={'Module access rights'}
    >
      <MARDialogContent />
    </Dialog>
  );
}

function MARDialogContent() {
  const user = useUser();
  const { activeTenant } = useTenant();

  const { rights } = useModuleRights();

  const mars = useMemo(() => {
    return {
      yes: formatMARs(rights.filter((mar) => mar.hasAccess)),
      no: formatMARs(rights.filter((mar) => !mar.hasAccess)),
    };
  }, [rights]);

  return (
    <DialogContent>
      <Typography variant="h3">User: {user.email}</Typography>
      <Typography variant="h3">Tenant: {activeTenant}</Typography>

      <Typography variant="h3" my={2}>
        Has access
      </Typography>
      <List items={mars.yes} />

      <Divider sx={{ my: 2 }} />

      <Typography variant="h3" my={2}>
        Doesn't have access
      </Typography>
      <List items={mars.no} />
    </DialogContent>
  );
}

function List({ items }: { items: ShortMAR[] }) {
  if (items.length === 0) {
    return (
      <Typography component="p" variant="body1" sx={{ fontStyle: 'italic' }}>
        None
      </Typography>
    );
  }

  return (
    <>
      {items.map((item, index) => (
        <Typography
          key={index}
          component="p"
          variant="body1"
          sx={{ whiteSpace: 'nowrap' }}
        >
          {item.name}
          {item.denyReason ? (
            <span style={{ opacity: 0.65 }}> {item.denyReason}</span>
          ) : null}
        </Typography>
      ))}
    </>
  );
}

interface ShortMAR {
  name: string;
  denyReason?: string;
}

function getShortMARName(mar: ModuleAccessRight): string {
  return [mar.type, mar.objectType, mar.sdObjType]
    .filter((x) => x !== null)
    .join(':');
}

function formatMARs(items: ModuleAccessRight[]): ShortMAR[] {
  return items
    .map((mar) => {
      const short: ShortMAR = {
        name: getShortMARName(mar),
      };

      if (mar.moduleAccessRightDenyReason) {
        short.denyReason = mar.moduleAccessRightDenyReason;
      }

      return short;
    })
    .sort((a, b) => a.name.localeCompare(b.name));
}
