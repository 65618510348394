import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ColumnsIcon } from '@work4all/assets/icons/cols.svg';

import { Base } from '../base/Base';
import { BaseActionProps } from '../types';

export const Columns: React.FC<BaseActionProps> = (props) => {
  const { t } = useTranslation();

  return <Base icon={<ColumnsIcon />} title={t('MASK.COLUMNS')} {...props} />;
};
