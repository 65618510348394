import styles from './BookingsTable.module.scss';

import { useEventCallback } from '@mui/material/utils';
import clsx from 'clsx';
import { isEqual, noop } from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Column,
  Row,
  TableInstance,
  useColumnOrder,
  useFlexLayout,
  useRowSelect,
  useTable,
} from 'react-table';

import { HeaderCheckbox } from '@work4all/components/lib/dataDisplay/basic-table/components/header/components/header-checkbox/HeaderCheckbox';
import {
  EditModeTableInstance,
  useEditMode,
} from '@work4all/components/lib/dataDisplay/basic-table/plugins/useEditMode';
import { Checkbox } from '@work4all/components/lib/input/checkbox-radio/CheckboxRadio';

import { LedgerAccount } from '@work4all/models/lib/Classes/LedgerAccount.entity';
import { Position } from '@work4all/models/lib/Classes/Position.entity';
import { RELedgerAccountSplit } from '@work4all/models/lib/Classes/RELedgerAccountSplit.entity';

import { formatNumberAsCurrency } from '@work4all/utils';

import { CostCenterCell } from '../../../../../../../../components/table-cells/CostCenterCell';
import { CostGroupCell } from '../../../../../../../../components/table-cells/CostGroupCell';
import { LedgerAccountCell } from '../../../../../../../../components/table-cells/LedgerAccountCell';
import { NumberInputCell } from '../../../../../../../../components/table-cells/NumberInputCell';
import { ProjectCell } from '../../../../../../../../components/table-cells/ProjectCell';
import { TextInputCell } from '../../../../../../../../components/table-cells/TextInputCell';

import { NumberParser } from './number-parser';

declare module 'react-table' {
  interface ColumnInterface {
    align?: 'left' | 'right';
  }
}

const NUMBER_INPUT_FORBIDDEN_PATTERN_REGEXP = /[^0-9,.]/g;
const WHOLE_NUMBER_INPUT_FORBIDDEN_PATTERN_REGEXP = /[^0-9]/g;

export type IBookingsTableProps = {
  disabled?: boolean;
  defaultLedgerAccount: LedgerAccount;
  bookings: RELedgerAccountSplit[];
  onSelectedPositionIdsChange: (selectedPositionIds: number[]) => void;
  onAddBooking: (booking: RELedgerAccountSplit) => void;
  onEditBooking: (booking: RELedgerAccountSplit) => void;
};

const defaultColumn = {
  Cell: ({ value }) => {
    return <span>{value}</span>;
  },
};

function parseNumberAsCurrency(value: string): number {
  const parser = new NumberParser('de');
  return parser.parse(value) || 0;
}

export const BookingsTable = (props: IBookingsTableProps) => {
  const {
    disabled = false,
    defaultLedgerAccount,
    bookings,
    onAddBooking,
    onEditBooking,
    onSelectedPositionIdsChange,
  } = props;

  const { t } = useTranslation();

  const placeholderBooking = useMemo((): RELedgerAccountSplit => {
    return {
      id: -1,
      konto: defaultLedgerAccount,
      costCenter: null,
      project: null,
      costGroup: null,
      note: '',
      taxKey: defaultLedgerAccount?.taxKey ?? 0,
      vat: 0,
      vatAmount: 0,
      valueNet: 0,
      proportionDM: 0,
    };
  }, [defaultLedgerAccount]);

  const bookingsWithExtraLine = useMemo(() => {
    if (disabled) {
      return bookings;
    }

    return [...bookings, placeholderBooking];
  }, [bookings, placeholderBooking, disabled]);

  const handleEdit = useEventCallback(
    (original: RELedgerAccountSplit, changes: RELedgerAccountSplit) => {
      if (original === placeholderBooking) {
        onAddBooking({ konto: original.konto, ...changes });
      } else {
        onEditBooking({ ...changes, id: original.id });
      }
    }
  );

  const { enqueueSnackbar } = useSnackbar();

  const columns = useMemo(() => {
    const columns: Column<RELedgerAccountSplit>[] = [
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.LEDGER_ACCOUNT'),
        accessor: 'konto',
        width: 180,
        Cell: (cell) => {
          return (
            <LedgerAccountCell
              disabled={disabled}
              value={cell.value}
              onChange={(ledgerAccount) => {
                handleEdit(cell.row.original, { konto: ledgerAccount });
              }}
            />
          );
        },
      },
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.COST_CENTER'),
        accessor: 'costCenter',
        width: 130,
        Cell: (cell) => {
          return (
            <CostCenterCell
              disabled={disabled}
              value={cell.value}
              onChange={(costCenter) => {
                handleEdit(cell.row.original, { costCenter });
              }}
            />
          );
        },
      },
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.PROJECT'),
        accessor: 'project',
        width: 140,
        Cell: (cell) => {
          return (
            <ProjectCell
              disabled={disabled}
              value={cell.value}
              onChange={(project) => {
                if (project.lockExternalServices) {
                  enqueueSnackbar(
                    t(
                      'VALIDATION.INBOUND_INVOICES.PROJECT_LOCKED_EXTERNAL_SERVICES'
                    )
                  );
                  return;
                }
                if (project.projectStatus?.closedStatus) {
                  enqueueSnackbar(
                    t('VALIDATION.INBOUND_INVOICES.PROJECT_CLOSED')
                  );
                  return;
                }
                handleEdit(cell.row.original, { project });
              }}
            />
          );
        },
      },
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.COST_GROUP'),
        accessor: 'costGroup',
        width: 140,
        Cell: (cell) => {
          return (
            <CostGroupCell
              disabled={disabled}
              value={cell.value}
              onChange={(costGroup) => {
                handleEdit(cell.row.original, { costGroup });
              }}
            />
          );
        },
      },
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.NOTE'),
        accessor: 'note',
        width: 140,
        Cell: (cell) => {
          return (
            <TextInputCell
              disabled={disabled}
              value={cell.value}
              onChange={(note) => {
                if (note !== cell.value) {
                  handleEdit(cell.row.original, { note });
                }
              }}
            />
          );
        },
      },
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.TAX_KEY'),
        accessor: 'taxKey',
        width: 50,
        align: 'right',
        Cell: (cell) => {
          return (
            <NumberInputCell
              disabled={disabled}
              value={cell.value}
              onChange={(taxKey) => {
                if (taxKey !== cell.value) {
                  handleEdit(cell.row.original, { taxKey });
                }
              }}
              parseValue={(value) => parseInt(value, 10) || 0}
              forbiddenPattern={WHOLE_NUMBER_INPUT_FORBIDDEN_PATTERN_REGEXP}
            />
          );
        },
      },
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.VAT'),
        accessor: 'vat',
        width: 60,
        align: 'right',
        Cell: (cell) => {
          return (
            <NumberInputCell
              disabled={disabled}
              value={cell.value}
              onChange={(vat) => {
                if (vat !== cell.value) {
                  handleEdit(cell.row.original, { vat });
                }
              }}
              formatValue={formatNumberAsCurrency}
              parseValue={parseNumberAsCurrency}
              max={29}
              forbiddenPattern={NUMBER_INPUT_FORBIDDEN_PATTERN_REGEXP}
            />
          );
        },
      },
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.TOTAL_NET'),
        id: 'netto',
        accessor: 'valueNet',
        width: 80,
        align: 'right',
        Cell: (cell) => {
          return (
            <NumberInputCell
              disabled={disabled}
              value={cell.value}
              onChange={(valueNet) => {
                if (valueNet !== cell.value) {
                  handleEdit(cell.row.original, { valueNet });
                }
              }}
              formatValue={formatNumberAsCurrency}
              parseValue={parseNumberAsCurrency}
              forbiddenPattern={NUMBER_INPUT_FORBIDDEN_PATTERN_REGEXP}
            />
          );
        },
      },
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.TOTAL_VAT'),
        accessor: 'vatAmount',
        width: 80,
        align: 'right',
        Cell: (x) => {
          return (
            <NumberInputCell
              disabled
              value={x.value}
              onChange={noop}
              formatValue={formatNumberAsCurrency}
              parseValue={parseNumberAsCurrency}
              forbiddenPattern={NUMBER_INPUT_FORBIDDEN_PATTERN_REGEXP}
            />
          );
        },
      },
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.TOTAL_GROSS'),
        accessor: 'proportionDM',
        width: 80,
        align: 'right',
        Cell: (cell) => {
          return (
            <NumberInputCell
              disabled={disabled}
              value={cell.value}
              onChange={(proportionDM) => {
                if (proportionDM !== cell.value) {
                  handleEdit(cell.row.original, { proportionDM });
                }
              }}
              formatValue={formatNumberAsCurrency}
              parseValue={parseNumberAsCurrency}
              forbiddenPattern={NUMBER_INPUT_FORBIDDEN_PATTERN_REGEXP}
            />
          );
        },
      },
    ];

    return columns;
  }, [t, handleEdit, disabled]);

  function getRowId(booking: RELedgerAccountSplit) {
    return String(booking.id);
  }

  const {
    headerGroups,
    getTableBodyProps,
    getTableProps,
    prepareRow,
    rows,
    state: { selectedRowIds },
  } = useTable<RELedgerAccountSplit>(
    {
      columns,
      defaultColumn,
      data: bookingsWithExtraLine,
      getRowId,
      onCellEdit: noop,
    },
    useFlexLayout,
    useColumnOrder,
    useRowSelect,
    useEditMode,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          disableResizing: true,
          minWidth: 40,
          width: 40,
          maxWidth: 40,

          Header: ({ getToggleAllRowsSelectedProps }) => {
            const { checked, indeterminate, onChange, role } =
              getToggleAllRowsSelectedProps();

            return (
              <div className={styles.checkboxWrapper}>
                <HeaderCheckbox
                  checked={checked}
                  indeterminate={indeterminate}
                  onChange={onChange}
                  role={role}
                />
              </div>
            );
          },
          Cell: ({ row }) => {
            const { checked, onChange, indeterminate, role } =
              row.getToggleRowSelectedProps();

            return (
              <div className={styles.checkboxWrapper}>
                <Checkbox
                  checked={checked}
                  indeterminate={indeterminate}
                  onChange={onChange}
                  role={role}
                />
              </div>
            );
          },
        },
        ...columns,
      ]);
    }
  ) as TableInstance<Position> & EditModeTableInstance;

  const lastEmittedSelectedPositionIds = useRef<number[] | null>(null);

  useEffect(() => {
    const selectedPositionIds = Object.entries(selectedRowIds)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_id, selected]) => selected)
      .map(([id]) => Number(id));

    if (!isEqual(selectedPositionIds, lastEmittedSelectedPositionIds.current)) {
      lastEmittedSelectedPositionIds.current = selectedPositionIds;
      onSelectedPositionIdsChange?.(selectedPositionIds);
    }
  }, [
    lastEmittedSelectedPositionIds,
    onSelectedPositionIdsChange,
    selectedRowIds,
  ]);

  const renderRow = (row: Row<Position>) => {
    prepareRow(row);

    const isPlaceholderBooking = row.original === placeholderBooking;

    return (
      <div
        {...row.getRowProps({
          className: clsx(
            styles.tableRow,
            isPlaceholderBooking && styles.placeholderRow
          ),
        })}
      >
        {row.cells.map((cell) => {
          return (
            <div
              {...cell.getCellProps({
                className: clsx(
                  styles.tableCell,
                  cell.column.align === 'right' && styles.alignRight
                ),
              })}
            >
              {cell.render('Cell')}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      className={clsx(styles.table, disabled && styles.disabled)}
      {...getTableProps()}
    >
      <div className={styles.thead}>
        {headerGroups.map((headerGroup) => (
          <div
            {...headerGroup.getHeaderGroupProps({
              className: clsx(styles.tableRow, styles.headerRow),
            })}
          >
            {headerGroup.headers.map((column) => (
              <div
                {...column.getHeaderProps({
                  className: clsx(
                    styles.tableCell,
                    column.align === 'right' && styles.alignRight
                  ),
                })}
              >
                {column.render('Header')}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className={styles.tbody} {...getTableBodyProps()}>
        {rows.map(renderRow)}
      </div>
    </div>
  );
};
