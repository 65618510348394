import KeyIcon from '@mui/icons-material/Key';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useState } from 'react';

import { MARDialog } from './MARDialog/MARDialog';

export function DevTools() {
  const [isMARDialogOpen, setMARDialogOpen] = useState(false);

  return (
    <List>
      <ListItem disablePadding>
        <ListItemButton onClick={() => setMARDialogOpen(true)}>
          <ListItemIcon>
            <KeyIcon />
          </ListItemIcon>
          <ListItemText primary={'Module access rights'} />
        </ListItemButton>
      </ListItem>

      <MARDialog
        open={isMARDialogOpen}
        onClose={() => setMARDialogOpen(false)}
      />
    </List>
  );
}
