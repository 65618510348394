import { Theme, useMediaQuery } from '@mui/material';

import { FileViewMode } from '@work4all/data';

import { settings, useSetting } from '../../../../settings';

export const useFileViewModeSetting = () => {
  const { value: viewModeData, set: setViewModeData } = useSetting(
    settings.fileViewMode()
  );

  const isViewportDownSm = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const isViewportDownMd = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );
  const isViewportDownLg = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('xl')
  );

  const breakpointName = isViewportDownSm
    ? 'sm'
    : isViewportDownMd
    ? 'md'
    : isViewportDownLg
    ? 'lg'
    : 'xl';

  const viewMode: FileViewMode = viewModeData[breakpointName];
  const setViewMode = (viewMode: FileViewMode) => {
    setViewModeData({
      ...viewModeData,
      [breakpointName]: viewMode,
    });
  };

  return { viewMode, setViewMode };
};
