import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { ReportBzObjType } from '../Enums/ReportBzObjType.enum';

import { CrystalReportFormular } from './CrystalReportFormular.entity';

export class CreateCrystalReportRequest<T extends EMode = EMode.entity> {
  /** Alias: reportCode */
  reportCode?: number;
  /** Alias: objectCode */
  objectCode?: number;
  /** Alias: bzObjType */
  bzObjType?: ReportBzObjType;
  /** Alias: formularCollection */
  formularCollection?: CrystalReportFormular<T>[];
  /** Alias: exportFormat */
  exportFormat?: string;
  /** Alias: extendedRecordSelektionFormular */
  extendedRecordSelektionFormular?: string;

  __typename?: string;
}

const fields: FieldDefinitions<CreateCrystalReportRequest> = {
  reportCode: {
    alias: 'reportCode',
  },
  objectCode: {
    alias: 'objectCode',
  },
  bzObjType: {
    alias: 'bzObjType',
  },
  formularCollection: {
    alias: 'formularCollection',
  },
  exportFormat: {
    alias: 'exportFormat',
  },
  extendedRecordSelektionFormular: {
    alias: 'extendedRecordSelektionFormular',
  },
  __typename: {
    alias: '__typename',
  },
};

export const createCrystalReportRequestEntityDefinition: EntitiyDefinition<CreateCrystalReportRequest> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'CreateCrystalReportRequest',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
