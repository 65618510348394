export const queryEquals = (val: unknown) => {
  return { $eq: val };
};

export const queryIn = (val: unknown[]) => {
  return { $in: val };
};

export const genNotSerializedQuery = <T extends Record<string, unknown>>(
  obj?: T
) => {
  if (!obj) {
    return;
  }

  const filter = Object.keys(obj).reduce((acc, curr) => {
    const v = obj[curr];
    if (!v) {
      return acc;
    }

    if (Array.isArray(v)) {
      acc.push({ [curr]: queryIn(v) });
      return acc;
    }

    if (typeof v === 'object') {
      acc.push({ [curr]: v });
      return acc;
    }

    acc.push({ [curr]: queryEquals(v) });
    return acc;
  }, []);

  return filter;
};

export const genQuery = <T extends Record<string, unknown>>(obj?: T) => {
  const filter = genNotSerializedQuery(obj);

  return JSON.stringify(filter);
};
