import styles from './UsersList.module.scss';

import { CheckCircleRounded } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Badge,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { partition } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { User } from '@work4all/models/lib/Classes/User.entity';
import { UserClass } from '@work4all/models/lib/Enums/UserClass.enum';

import { CalendarCardColorBy } from '../types';

import { CalendarUserIcon } from './CalendarUserIcon';

export interface UsersListProps {
  users: User[];
  onUsersChange: (users: User[]) => void;
  focusedUserIds: number[];
  onFocusedUserIdsChange: (ids: number[]) => void;
  calendarCardColorBy: CalendarCardColorBy;
}

export function UsersList(props: UsersListProps) {
  const {
    users: usersAndResources,
    onUsersChange,
    focusedUserIds,
    onFocusedUserIdsChange,
    calendarCardColorBy,
  } = props;

  const { t } = useTranslation();

  const isAnyUserFocused = focusedUserIds.length > 0;

  const { users, resources } = useMemo(() => {
    const [resources, users] = partition(
      usersAndResources,
      (user) => user.userKind === UserClass.RESSOURCE
    );
    return { users, resources };
  }, [usersAndResources]);

  const handleToggle = (user: User, focused: boolean) => {
    // If the user is already focused, remove the focus. Otherwise, focus the
    // user.
    if (focused) {
      onFocusedUserIdsChange(focusedUserIds.filter((id) => id !== user.id));
    } else {
      onFocusedUserIdsChange([...focusedUserIds, user.id]);
    }
  };

  if (users.length === 0 && resources.length === 0) {
    return null;
  }

  function renderListItem(item: User, itemType: 'user' | 'resource') {
    const isUserFocused = focusedUserIds.includes(item.id);

    return (
      <ListItem
        key={item.id}
        className={styles.item}
        disablePadding
        secondaryAction={
          <IconButton
            color="secondary"
            edge="end"
            onClick={() => {
              onUsersChange(
                usersAndResources.filter((it) => it.id !== item.id)
              );

              if (isUserFocused) {
                onFocusedUserIdsChange(
                  focusedUserIds.filter((id) => id !== item.id)
                );
              }
            }}
          >
            <ClearIcon />
          </IconButton>
        }
      >
        <ListItemButton onClick={() => handleToggle(item, isUserFocused)}>
          <ListItemAvatar
            sx={{
              marginRight: '0.5rem',
              filter:
                isAnyUserFocused && !isUserFocused ? 'grayscale(1)' : undefined,
            }}
          >
            <Badge
              invisible={!isUserFocused || !isAnyUserFocused}
              overlap="circular"
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              badgeContent={<CheckCircleRounded className={styles.checkIcon} />}
            >
              <CalendarUserIcon
                type={itemType}
                userId={item.id}
                size="l"
                withBorder={calendarCardColorBy === CalendarCardColorBy.User}
              />
            </Badge>
          </ListItemAvatar>

          <ListItemText
            primary={itemType === 'user' ? item.displayName : item.shortName}
            primaryTypographyProps={{
              color: (theme) =>
                isAnyUserFocused && !isUserFocused
                  ? theme.palette.text.tertiary
                  : theme.palette.text.primary,
            }}
          />
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <List disablePadding>
      {users.map((user) => {
        return renderListItem(user, 'user');
      })}

      {resources.length > 0 && (
        <Divider sx={{ mx: '0.5rem' }}>{t('COMMON.RESOURCES')}</Divider>
      )}

      {resources.map((resource) => {
        return renderListItem(resource, 'resource');
      })}
    </List>
  );
}
