import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class WorkTimeShortInfo<T extends EMode = EMode.entity> {
  /** Alias: workTime */
  workTime?: number;
  /** Alias: breakTime */
  breakTime?: number;

  __typename?: string;
}

const fields: FieldDefinitions<WorkTimeShortInfo> = {
  workTime: {
    alias: 'workTime',
  },
  breakTime: {
    alias: 'breakTime',
  },
  __typename: {
    alias: '__typename',
  },
};

export const workTimeShortInfoEntityDefinition: EntitiyDefinition<WorkTimeShortInfo> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'WorkTimeShortInfo',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
