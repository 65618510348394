import styles from './ERP.module.scss';

import { Box } from '@mui/material';
import React, { useState } from 'react';
import { TableInstance } from 'react-table';

import { TableStateBagProvider } from '@work4all/components/lib/dataDisplay/basic-table/hooks/useTableStateBag';

import { PrintBagProvider } from '@work4all/data/lib/hooks/useHandlePrint';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useMaskContext } from '../../../../../hooks/mask-context';

import { BankDetailsTable } from './components/bank-details/BankDetailsTable';
import { CustomizedPrices } from './components/customized-prices/CustomizedPrices';
import { Delivery } from './components/delivery/Delivery';
import { Invoice } from './components/invoice/Invoice';
import { Miscellaneous } from './components/miscellaneous/Miscellaneous';
import { PriceAndTax } from './components/price-and-tax/PriceAndTax';
import { PurchasePrices } from './components/purchase-prices/PurchasePrices';
import { TermsOfPayment } from './components/terms-of-payment/TermsOfPayment';
import { Warning } from './components/warning/Warning';

export const ERPTabPanel: React.FC = () => {
  const mask = useMaskContext();

  const [tableInstance, setTableInstance] = useState<TableInstance>();

  return (
    <Box className={styles.root}>
      <PriceAndTax />
      <TermsOfPayment />
      {mask.entity === Entities.customer && <Invoice />}
      <Delivery />
      {mask.entity === Entities.customer && <Warning />}
      <Miscellaneous />
      <PrintBagProvider>
        <TableStateBagProvider tableInstance={tableInstance}>
          {mask.entity === Entities.customer && (
            <CustomizedPrices ref={setTableInstance} />
          )}
          {mask.entity === Entities.supplier && (
            <PurchasePrices ref={setTableInstance} />
          )}
        </TableStateBagProvider>
      </PrintBagProvider>
      <BankDetailsTable />
    </Box>
  );
};
