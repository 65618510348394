import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { StatisticCompanyReportFigures } from './StatisticCompanyReportFigures.entity';

export class StatisticCompanyReportKeyFigures<T extends EMode = EMode.entity> {
  /** Alias: rowCount */
  rowCount?: number;
  /** Alias: keyFigure */
  keyFigure?: string;
  /** Alias: figures */
  figures?: StatisticCompanyReportFigures<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<StatisticCompanyReportKeyFigures> = {
  rowCount: {
    alias: 'rowCount',
  },
  keyFigure: {
    alias: 'keyFigure',
  },
  figures: {
    alias: 'figures',
    entity: Entities.statisticCompanyReportFigures,
  },
  __typename: {
    alias: '__typename',
  },
};

export const statisticCompanyReportKeyFiguresEntityDefinition: EntitiyDefinition<StatisticCompanyReportKeyFigures> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'StatisticCompanyReportKeyFigures',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
