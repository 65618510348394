import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { DefaultUserRoles } from '../Enums/DefaultUserRoles.enum';
import { EMode } from '../Enums/EMode.enum';

export class UserRole<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: name */
  name?: string;
  /** Alias: defaultUserRole */
  defaultUserRole?: DefaultUserRoles;

  __typename?: string;
}

const fields: FieldDefinitions<UserRole> = {
  id: {
    alias: 'code',
  },
  name: {
    alias: 'name',
  },
  defaultUserRole: {
    alias: 'defaultUserRole',
  },
  __typename: {
    alias: '__typename',
  },
};

export const userRoleEntityDefinition: EntitiyDefinition<UserRole> = {
  local: {},
  remote: {
    queryName: 'getUserRoles',
    fragmentName: 'BenutzerRolle',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
