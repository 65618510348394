import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { UserPresenceTimeInfoItem } from './UserPresenceTimeInfoItem.entity';

export class UserPresenceTimeInfo<T extends EMode = EMode.entity> {
  /** Alias: ende */
  end?: string;
  /** Alias: endeKorrigiert */
  endCorrected?: string;
  /** Alias: istBruttoMinuten */
  isGrossMinutes?: number;
  /** Alias: istNettoMinuten */
  istNetMinutes?: number;
  /** Alias: pauseAnzahlMinuten */
  pauseAmountMinutes?: number;
  /** Alias: start */
  start?: string;
  /** Alias: startKorrigiert */
  startCorrected?: string;
  /** Alias: items */
  items?: UserPresenceTimeInfoItem<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<UserPresenceTimeInfo> = {
  end: {
    alias: 'ende',
  },
  endCorrected: {
    alias: 'endeKorrigiert',
  },
  isGrossMinutes: {
    alias: 'istBruttoMinuten',
  },
  istNetMinutes: {
    alias: 'istNettoMinuten',
  },
  pauseAmountMinutes: {
    alias: 'pauseAnzahlMinuten',
  },
  start: {
    alias: 'start',
  },
  startCorrected: {
    alias: 'startKorrigiert',
  },
  items: {
    alias: 'items',
    entity: Entities.userPresenceTimeInfoItem,
  },
  __typename: {
    alias: '__typename',
  },
};

export const userPresenceTimeInfoEntityDefinition: EntitiyDefinition<UserPresenceTimeInfo> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'KommtGehtZeitkarteAnwesenheitInfo',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
