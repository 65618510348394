import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { ApiValidationErrorType } from '../Enums/ApiValidationErrorType.enum';
import { EMode } from '../Enums/EMode.enum';

export class ValidationMessage<T extends EMode = EMode.entity> {
  /** Alias: property */
  property?: string;
  /** Alias: data */
  data?: string;
  /** Alias: apiValidationErrorType */
  apiValidationErrorType?: ApiValidationErrorType;
  /** Alias: validationDataType */
  validationDataType?: string;

  __typename?: string;
}

const fields: FieldDefinitions<ValidationMessage> = {
  property: {
    alias: 'property',
  },
  data: {
    alias: 'data',
  },
  apiValidationErrorType: {
    alias: 'apiValidationErrorType',
  },
  validationDataType: {
    alias: 'validationDataType',
  },
  __typename: {
    alias: '__typename',
  },
};

export const validationMessageEntityDefinition: EntitiyDefinition<ValidationMessage> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'ValidationMessage',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
