import produce from 'immer';
import { useCallback, useEffect, useReducer } from 'react';

import { MailboxContent } from '@work4all/models/lib/Classes/MailboxContent.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';

import {
  ContactOrBusinessPartner,
  isBusinessPartner,
  isContact,
} from '../cells/contact-or-business-partner';

import {
  MailboxContentItem,
  MailboxContentState,
  SelectedMailboxFolder,
} from './types';

export interface MailboxContentStateManager {
  state: MailboxContentState;
  onContactChange: (
    id: string,
    contact: ContactOrBusinessPartner | null
  ) => void;
  onProjectChange: (id: string, project: Project) => void;
}

type ReducerAction =
  | { type: 'update_items'; items: MailboxContent[] }
  | { type: 'set_folder'; folder: SelectedMailboxFolder }
  | { type: 'set_selected'; selectedIds: string[] }
  | { type: 'set_contact'; id: string; contact: ContactOrBusinessPartner }
  | { type: 'set_project'; id: string; project: Project };

function reducer(
  state: MailboxContentState,
  action: ReducerAction
): MailboxContentState {
  switch (action.type) {
    case 'update_items': {
      return produce(state, (draft) => {
        const removedItemIds = new Set(Object.keys(draft.itemsById));

        for (const item of action.items) {
          if (draft.itemsById[item.id] != null) {
            draft.itemsById[item.id].data = item;
          } else {
            draft.itemsById[item.id] = {
              data: item,
              ...getSuggestedAssignments(item),
            };
          }

          removedItemIds.delete(item.id);
        }

        for (const id of removedItemIds) {
          delete draft.itemsById[id];
        }
      });
    }

    case 'set_folder': {
      return { ...state, folder: action.folder };
    }

    case 'set_selected': {
      return { ...state, selectedIds: action.selectedIds };
    }

    case 'set_contact': {
      return produce(state, (draft) => {
        const item = draft.itemsById[action.id];

        if (!item) {
          throw new Error(`Item with id ${action.id} not found`);
        }

        item.contact = action.contact;
      });
    }

    case 'set_project': {
      return produce(state, (draft) => {
        const item = draft.itemsById[action.id];

        if (!item) {
          throw new Error(`Item with id ${action.id} not found`);
        }

        item.project = action.project;

        if (!item.contact && action.project?.customer) {
          item.contact = action.project.customer;
        }
      });
    }

    default:
      return state;
  }
}

interface MailboxContentStateInit {
  items: MailboxContent[];
  folder: SelectedMailboxFolder;
  selectedIds: string[];
}

function createInitialState(
  init: MailboxContentStateInit
): MailboxContentState {
  const { items, folder, selectedIds } = init;

  const itemsById = mapItemsById(items);

  return { itemsById, folder, selectedIds };
}

function mapItemsById(items: MailboxContent[]) {
  const itemsById = items.reduce<Record<string, MailboxContentItem>>(
    (itemsById, item) => {
      itemsById[item.id] = {
        data: item,
        ...getSuggestedAssignments(item),
      };
      return itemsById;
    },
    {}
  );

  return itemsById;
}

function getSuggestedAssignments(
  content: MailboxContent
): Pick<MailboxContentItem, 'contact' | 'project'> {
  let contact: ContactOrBusinessPartner | null = null;
  let project: Project | null = null;

  const hasContactSuggestions =
    content.possibleSenders != null && content.possibleSenders.length > 0;

  if (content.possibleSenders != null && content.possibleSenders.length === 1) {
    const suggestedContact = content.possibleSenders[0];

    // The sender can also be a User, which we ignore at this time.
    if (isContact(suggestedContact) || isBusinessPartner(suggestedContact)) {
      contact = suggestedContact;
    }
  }

  if (
    content.possibleProjects != null &&
    content.possibleProjects.length === 1
  ) {
    const suggestedProject = content.possibleProjects[0];
    project = suggestedProject;

    if (contact === null && !hasContactSuggestions && project.customer) {
      contact = project.customer;
    }
  }

  return { contact, project };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseMailboxContentStateManagerOptions
  extends MailboxContentStateInit {}

export function useMailboxContentStateManager(
  options: UseMailboxContentStateManagerOptions
): MailboxContentStateManager {
  const { items, folder, selectedIds } = options;

  const [state, dispatch] = useReducer(
    reducer,
    { items, folder, selectedIds },
    createInitialState
  );

  useEffect(() => {
    dispatch({ type: 'update_items', items });
  }, [items]);

  useEffect(() => {
    dispatch({ type: 'set_folder', folder });
  }, [folder]);

  useEffect(() => {
    dispatch({ type: 'set_selected', selectedIds });
  }, [selectedIds]);

  const onContactChange = useCallback<
    MailboxContentStateManager['onContactChange']
  >((id, contact) => {
    dispatch({ type: 'set_contact', id, contact });
  }, []);

  const onProjectChange = useCallback<
    MailboxContentStateManager['onProjectChange']
  >((id, project) => {
    dispatch({ type: 'set_project', id, project });
  }, []);

  return {
    state,
    onContactChange,
    onProjectChange,
  };
}
