import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputTerminTeilnehmerAdd } from './InputTerminTeilnehmerAdd.entity';

export class InputTerminTeilnehmerRelation<T extends EMode = EMode.entity> {
  /** Alias: remove */
  remove?: number[];
  /** Alias: add */
  add?: InputTerminTeilnehmerAdd<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputTerminTeilnehmerRelation> = {
  remove: {
    alias: 'remove',
  },
  add: {
    alias: 'add',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputTerminTeilnehmerRelationEntityDefinition: EntitiyDefinition<InputTerminTeilnehmerRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputTerminTeilnehmerRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
