import { createContext, useContext, useMemo, useState } from 'react';

export interface GridState {
  selectedColumns: { id: number | string }[];
  setSelectedColumns: (val: { id: number | string }[]) => void;
}

const GridStateContext = createContext<GridState | null>(null);

export const GridStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [selectedColumns, setSelectedColumns] = useState<
    { id: number | string }[]
  >([]);

  const state = useMemo(() => {
    return {
      selectedColumns,
      setSelectedColumns,
    };
  }, [selectedColumns, setSelectedColumns]);
  return (
    <GridStateContext.Provider value={state}>
      {children}
    </GridStateContext.Provider>
  );
};

export const useGridState = () => {
  const state = useContext(GridStateContext);
  if (!state) {
    throw new Error(
      'state is undefined. Make sure you wrapped your component with <GridStateProvider />'
    );
  }
  return state;
};
