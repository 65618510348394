import { useTranslation } from 'react-i18next';

import { AlertDialog } from '../AlertDialog';

export type IDeleteEntitiesMaxCountAlertDialogProps = {
  maxCount: number;
  open: boolean;
  onClose: () => void;
};

export function DeleteEntitiesMaxCountAlertDialog(
  props: IDeleteEntitiesMaxCountAlertDialogProps
) {
  const { maxCount, open, onClose } = props;
  const { t } = useTranslation();

  return (
    <AlertDialog
      open={open}
      title={t('ALERTS.DELETE.NOT_ALLOWED')}
      description={t('ALERTS.DELETE.MAX_WARNING', { count: maxCount })}
      onClose={onClose}
    />
  );
}
