import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { SignaturMode } from '../Enums/SignaturMode.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputEMailVorlage<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string | number;
  /** Alias: name */
  name?: string;
  /** Alias: betreff */
  betreff?: string;
  /** Alias: body */
  body?: string;
  /** Alias: signaturId */
  signaturId?: string;
  /** Alias: signaturMode */
  signaturMode?: SignaturMode;
  /** Alias: vorlagenArt */
  vorlagenArt?: any;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];
  /** Alias: legacyTemplateCode */
  legacyTemplateCode?: number;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: groupId */
  groupId?: string;
  /** Alias: parentId */
  parentId?: string;
  /** Alias: languageCode */
  languageCode?: number;

  __typename?: string;
}

const fields: FieldDefinitions<InputEMailVorlage> = {
  id: {
    alias: 'id',
  },
  name: {
    alias: 'name',
  },
  betreff: {
    alias: 'betreff',
  },
  body: {
    alias: 'body',
  },
  signaturId: {
    alias: 'signaturId',
  },
  signaturMode: {
    alias: 'signaturMode',
  },
  vorlagenArt: {
    alias: 'vorlagenArt',
  },
  customFields: {
    alias: 'customFields',
  },
  legacyTemplateCode: {
    alias: 'legacyTemplateCode',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  groupId: {
    alias: 'groupId',
  },
  parentId: {
    alias: 'parentId',
  },
  languageCode: {
    alias: 'languageCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputEMailVorlageEntityDefinition: EntitiyDefinition<InputEMailVorlage> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputEMailVorlage',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
