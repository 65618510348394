import { WebSocketLink } from '@apollo/client/link/ws';

export const createWsLink = (
  accessToken: string,
  tenant: number,
  baseUrl = ''
) => {
  const wsUrl = baseUrl.replace('http', 'ws');
  return new WebSocketLink({
    uri: `${wsUrl}/graphql`,
    options: {
      reconnect: true,
      connectionParams: {
        // authToken: `bearer ${accessToken}`,
        authorization: `bearer ${accessToken}`,
        'x-work4all-mandant': tenant,
      },
    },
  });
};
