import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { UserRights } from '../Enums/UserRights.enum';

export class UserRightsFilter<T extends EMode = EMode.entity> {
  /** Alias: state */
  state?: boolean;
  /** Alias: userRight */
  userRight?: UserRights;

  __typename?: string;
}

const fields: FieldDefinitions<UserRightsFilter> = {
  state: {
    alias: 'state',
  },
  userRight: {
    alias: 'userRight',
  },
  __typename: {
    alias: '__typename',
  },
};

export const userRightsFilterEntityDefinition: EntitiyDefinition<UserRightsFilter> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'UserRightsFilter',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
