import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class VacationKind<T extends EMode = EMode.entity> {
  /** Alias: color */
  color?: string;
  /** Alias: code */
  id?: number;
  /** Alias: name */
  name?: string;
  /** Alias: anzeigenAls */
  showAs?: string;
  /** Alias: kurzarbeit */
  shortTimeWork?: boolean;
  /** Alias: anspruchsmindernd */
  demandReducing?: boolean;
  /** Alias: zeitausgleich */
  timeCompensation?: boolean;

  __typename?: string;
}

const fields: FieldDefinitions<VacationKind> = {
  color: {
    alias: 'color',
  },
  id: {
    alias: 'code',
  },
  name: {
    alias: 'name',
  },
  showAs: {
    alias: 'anzeigenAls',
  },
  shortTimeWork: {
    alias: 'kurzarbeit',
  },
  demandReducing: {
    alias: 'anspruchsmindernd',
  },
  timeCompensation: {
    alias: 'zeitausgleich',
  },
  __typename: {
    alias: '__typename',
  },
};

export const vacationKindEntityDefinition: EntitiyDefinition<VacationKind> = {
  local: {},
  remote: {
    queryName: 'getUrlaubsarten',
    fragmentName: 'Urlaubsart',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
