import styles from './RelationPickerWithArrow.module.scss';

import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';

import { ReactComponent as LeftArrow } from '@work4all/assets/icons/connection-left-arrow.svg';
import { ReactComponent as RightArrow } from '@work4all/assets/icons/connection-right-arrow.svg';

import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';

import { RelationTypePickerButton } from './RelationTypePickerButton';

type Direction = 'right' | 'left';
interface RelationPickerWithArrow {
  arrowDirection: Direction;
  formKey: string;
}

export const RelationPickerWithArrow = ({
  arrowDirection,
  formKey,
}: RelationPickerWithArrow) => {
  const isLeft = arrowDirection === 'left';

  const { watch, setValue } = useFormContext();
  const value = watch(formKey) as LookUp;

  return (
    <div className={clsx(styles.wrapper, { [styles.inverse]: isLeft })}>
      <div className={styles.picker}>
        <RelationTypePickerButton
          value={value}
          onChange={(value) => {
            setValue(formKey, value, { shouldDirty: true });
          }}
        />
      </div>

      {isLeft ? <LeftArrow /> : <RightArrow />}
    </div>
  );
};
