import styles from './TextBuildingBlockPreview.module.scss';

import { TextBuildingBlock } from '@work4all/models/lib/Classes/TextBuildingBlock.entity';

import { PreviewTitle, PreviewWrapper } from '../components';

export type ITextBuildingBlockPreviewProps = {
  textBuildingBlock: TextBuildingBlock;
  onCloseClicked?: React.MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: React.MouseEventHandler<HTMLButtonElement>;
};

export function TextBuildingBlockPreview(
  props: ITextBuildingBlockPreviewProps
) {
  const { textBuildingBlock, onCloseClicked, onEditClicked } = props;

  return (
    <PreviewWrapper>
      <div className={styles.wrap}>
        <PreviewTitle
          onCloseClicked={onCloseClicked}
          onEditClicked={onEditClicked}
        >
          {textBuildingBlock.name}
        </PreviewTitle>
        <pre className={styles.content}>{textBuildingBlock?.text}</pre>
      </div>
    </PreviewWrapper>
  );
}
