import scssVariablesDark from '@work4all/assets/theme/variablesDark.scss';
import scssVariablesLight from '@work4all/assets/theme/variablesLight.scss';

import { inputClasses } from '@mui/material/Input';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import { useEffect, useMemo, useState } from 'react';

import { breakpoints } from '@work4all/utils/lib/variables';

declare module '@mui/material/styles/createPalette' {
  interface TypeText {
    tertiary: string;
    inverse: string;
  }
  interface PaletteOptions {
    userShade1: string;
    userShade2: string;
    userShade3: string;
    userShade4: string;
    userShade5: string;
    userShade6: string;
    userShade7: string;
    userShade8: string;
    userShade9: string;
    ui1: PaletteColorOptions;
    ui2: PaletteColorOptions;
    ui3: PaletteColorOptions;
    ui4: PaletteColorOptions;
    ui5: PaletteColorOptions;
    ui6: PaletteColorOptions;
    ui7: PaletteColorOptions;
    widgetBorder: string;
  }
}

declare module '@mui/material' {
  interface ButtonPropsColorOverrides {
    ui1: true;
  }
  interface SvgIconPropsColorOverrides {
    ui1: true;
  }
  interface FabPropsColorOverrides {
    ui1: true;
  }
}

const defaultTheme = createTheme();

export const MuiThemeProvider: React.FC<{ pageTheme: 'dark' | 'light' }> = (
  props
) => {
  const { pageTheme = 'light' } = props;

  const [scssVariables, setScssVariables] = useState(scssVariablesLight);

  useEffect(() => {
    setScssVariables(
      pageTheme === 'light' ? scssVariablesLight : scssVariablesDark
    );
    document.querySelector('html').setAttribute('data-theme', pageTheme);
  }, [pageTheme]);

  const theme = useMemo(() => {
    return createTheme({
      spacing: 7,
      breakpoints: {
        values: breakpoints,
      },
      palette: {
        primary: {
          main: scssVariables.brand01,
          dark: scssVariables.hoverPrimary,
        },
        success: {
          main: scssVariables.success,
        },
        warning: {
          main: '#eec764',
        },
        error: {
          main: scssVariables.error,
        },
        secondary: {
          main: scssVariables.text03,
        },

        ui1: defaultTheme.palette.augmentColor({
          color: { main: scssVariables.ui01 },
          name: 'ui1',
        }),
        ui2: defaultTheme.palette.augmentColor({
          color: { main: scssVariables.ui02 },
          name: 'ui2',
        }),
        ui3: defaultTheme.palette.augmentColor({
          color: { main: scssVariables.ui03 },
          name: 'ui3',
        }),
        ui4: defaultTheme.palette.augmentColor({
          color: { main: scssVariables.ui04 },
          name: 'ui4',
        }),
        ui5: defaultTheme.palette.augmentColor({
          color: { main: scssVariables.ui05 },
          name: 'ui5',
        }),
        ui6: defaultTheme.palette.augmentColor({
          color: { main: scssVariables.ui06 },
          name: 'ui6',
        }),
        ui7: defaultTheme.palette.augmentColor({
          color: { main: scssVariables.ui07 },
          name: 'ui7',
        }),

        userShade1: scssVariables.userShade1,
        userShade2: scssVariables.userShade2,
        userShade3: scssVariables.userShade3,
        userShade4: scssVariables.userShade4,
        userShade5: scssVariables.userShade5,
        userShade6: scssVariables.userShade6,
        userShade7: scssVariables.userShade7,
        userShade8: scssVariables.userShade8,
        userShade9: scssVariables.userShade9,

        widgetBorder: scssVariables.widgetBorder,

        text: {
          primary: scssVariables.text01,
          secondary: scssVariables.text02,
          tertiary: scssVariables.text03,
          inverse: scssVariables.textInverse,
        },
      },
      // shadows: Array(25).fill('none') as any,
      typography: {
        body1: {
          fontWeight: 400,
          fontSize: '1rem',
          lineHeight: 1.5,
          // TODO Add colors defined in css to Mui's theme palette.
          color: scssVariables.text01,
        },
        body2: {
          fontWeight: 400,
          fontSize: '1rem',
          lineHeight: 1.5,
        },
        caption: {
          fontWeight: 400,
          fontSize: '0.85rem',
          lineHeight: 1.33,
          color: '#8B90A0',
        },
        h3: {
          fontWeight: 500,
          fontSize: '1.375rem',
          lineHeight: '2rem',
        },
        h4: {
          fontWeight: 700,
          fontSize: '1rem',
          lineHeight: '1.5rem',
        },
        h5: {
          fontWeight: 500,
          fontSize: '1rem',
          lineHeight: 1.5,
        },
      },
      components: {
        MuiTooltip: {
          defaultProps: {
            arrow: true,
          },
          styleOverrides: {
            tooltip: {
              backgroundColor: scssVariables.text01,
              color: scssVariables.textInverse,
              fontSize: '0.75rem',
            },
            arrow: {
              color: scssVariables.ui07,
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: ({ ownerState, theme }) => ({
              textTransform: 'none',
              ...(ownerState.variant === 'contained' &&
                ownerState.color === 'primary' && {
                  backgroundColor: theme.palette.primary.main,
                  color: scssVariables.brand01,
                }),
              ...(ownerState.variant === 'text' && {
                fontWeight: 400,
                fontSize: '1rem',
                lineHeight: 1.5,
              }),
              ...(ownerState.size === 'large' && {
                fontSize: '1rem',
                lineHeight: 1.5,
                fontWeight: 400,
                paddingTop: '0.75rem',
                paddingRight: '1rem',
                paddingBottom: '0.75rem',
                paddingLeft: '1rem',
              }),
              ...(ownerState.size === 'large' &&
                ownerState.variant === 'contained' && {
                  fontWeight: 900,
                }),
            }),
            containedPrimary: {
              color: 'white !important',
            },
          },
        },
        MuiIconButton: {
          styleOverrides: {
            root: ({ ownerState }) => {
              return {
                ...(ownerState.color === 'default' && {
                  color: 'var(--text01)',
                }),
              };
            },
          },
        },
        MuiFab: {
          styleOverrides: {
            root: ({ ownerState }) => ({
              textTransform: 'none',
              ...(ownerState.color === 'primary' && { color: 'white' }),
            }),
          },
        },
        MuiInputLabel: {
          styleOverrides: {
            root: {
              color: scssVariables.text03,
            },
          },
        },
        MuiSelect: {
          defaultProps: {
            disableUnderline: true,
          },
          styleOverrides: {
            select: {
              paddingTop: 21,
              paddingBottom: 4,
            },
          },
        },
        MuiDivider: {
          defaultProps: { textAlign: 'left' },
          styleOverrides: {
            root: ({ ownerState }) => ({
              borderColor: scssVariables.ui04,

              ...(ownerState.orientation === 'horizontal' &&
                ownerState.textAlign === 'left' && {
                  '&::before': {
                    display: 'none',
                  },
                }),

              ...(ownerState.orientation === 'horizontal' &&
                ownerState.textAlign === 'right' && {
                  '&::after': {
                    display: 'none',
                  },
                }),
            }),
            wrapper: ({ theme, ownerState }) => ({
              ...theme.typography.caption,

              ...(ownerState.orientation === 'horizontal' &&
                ownerState.textAlign === 'left' && {
                  paddingLeft: 0,
                }),

              ...(ownerState.orientation === 'horizontal' &&
                ownerState.textAlign === 'right' && {
                  paddingRight: 0,
                }),
            }),

            textAlignLeft: {},
          },
        },
        MuiRadio: {
          styleOverrides: {
            root: {
              color: scssVariables.ui05,
            },
          },
        },
        MuiInput: {
          styleOverrides: {
            input: {
              [`&.${inputClasses.disabled}`]: {
                WebkitTextFillColor: 'var(--text03)',
              },
            },
          },
        },
        MuiListItemIcon: {
          styleOverrides: {
            root: {
              minWidth: 30,
              color: scssVariables.text03,
            },
          },
        },
        MuiListItemAvatar: {
          styleOverrides: {
            root: {
              minWidth: 30,
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: () => ({
              backgroundColor: scssVariables.ui01,
            }),
          },
        },
        MuiButtonGroup: {
          styleOverrides: {
            grouped: {
              color: 'white',
            },
          },
        },
        MuiTabs: {
          styleOverrides: {
            root: {
              minHeight: '2.5rem',
            },
            flexContainer: {
              gap: '0.5rem',
            },
          },
          defaultProps: {
            TabIndicatorProps: {
              hidden: true,
            },
          },
        },
        MuiToggleButton: {
          styleOverrides: {
            root: {
              color: scssVariables.text01,
              borderColor: scssVariables.ui04,
            },
          },
        },
        MuiCheckbox: {
          styleOverrides: {
            root: {
              '&.Mui-disabled': {
                color: scssVariables.text03,
              },
            },
          },
        },
        MuiListItemButton: {
          styleOverrides: {
            root: {
              hover: {
                backgroundColor: scssVariables.brand02,
              },
            },
          },
        },
        MuiChip: {
          styleOverrides: {
            root: {
              backgroundColor: scssVariables.ui03,
            },
          },
        },
        MuiTab: {
          styleOverrides: {
            root: {
              minWidth: 'auto',
              minHeight: 'auto',
              textTransform: 'unset',
              opacity: 1,
              padding: '0.5rem',
              color: scssVariables.text01,
              fontSize: '1rem',
              lineHeight: 1.5,
              fontFamily: 'Roboto, sans-serif',
              fontWeight: 500,
              borderRadius: '0.25rem',

              '&.Mui-selected': {
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  inset: 0,
                  backgroundColor: 'currentColor',
                  opacity: 0.1,
                  pointerEvents: 'none',
                },
              },

              '&.Mui-disabled': {
                color: 'var(--text03)',
                opacity: 0.8,
              },
            },
          },
        },
        MuiFormControl: {
          styleOverrides: {
            root: ({ ownerState }) => ({
              ...(ownerState.disabled && {
                opacity: 0.5,
              }),
            }),
          },
        },
        MuiDialog: {
          styleOverrides: {
            paper: {
              backgroundColor: 'var(--ui01)',
            },
          },
        },
        MuiUseMediaQuery: {
          defaultProps: {
            noSsr: true,
          },
        },
        MuiFormControlLabel: {
          styleOverrides: {
            label: {
              '&.Mui-disabled': {
                color: scssVariables.text03 + ' !important',
              },
            },
          },
        },
      },
    });
  }, [
    scssVariables.text03,
    scssVariables.brand01,
    scssVariables.brand02,
    scssVariables.error,
    scssVariables.hoverPrimary,
    scssVariables.success,
    scssVariables.text01,
    scssVariables.text02,
    scssVariables.textInverse,
    scssVariables.ui01,
    scssVariables.ui02,
    scssVariables.ui03,
    scssVariables.ui04,
    scssVariables.ui05,
    scssVariables.ui06,
    scssVariables.ui07,
    scssVariables.userShade1,
    scssVariables.userShade2,
    scssVariables.userShade3,
    scssVariables.userShade4,
    scssVariables.userShade5,
    scssVariables.userShade6,
    scssVariables.userShade7,
    scssVariables.userShade8,
    scssVariables.userShade9,
    scssVariables.widgetBorder,
  ]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </StyledEngineProvider>
  );
};
