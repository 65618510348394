import styles from './Modal.module.scss';

import {
  ClickAwayListener,
  Grow,
  Modal as MuiModal,
  ModalProps,
} from '@mui/material';
import clsx from 'clsx';
import React from 'react';

interface Props extends ModalProps {
  isFullScreen?: boolean;
}

export const Modal: React.FC<Props> = (props) => {
  const { children, isFullScreen, ...modalProps } = props;

  return (
    <MuiModal {...modalProps}>
      <div
        className={clsx(styles.modal, { [styles.fullscreen]: isFullScreen })}
      >
        {/**
         * We need aditional click away listener here.
         * Reason: div "modal" takes all width, but it possible that "children"
         * content wants to be "content-fit". In this case modal won't close
         * on click action outside of content.
         */}
        <ClickAwayListener
          onClickAway={(event) => modalProps.onClose?.(event, 'backdropClick')}
          disableReactTree={true}
        >
          <Grow in={modalProps.open}>{children}</Grow>
        </ClickAwayListener>
      </div>
    </MuiModal>
  );
};
