import styles from './NumberInputCell.module.scss';

import clsx from 'clsx';
import { useCallback, useMemo } from 'react';

import { TextInputCell, TextInputCellProps } from './TextInputCell';

type NumberInputCellProps = Omit<TextInputCellProps, 'value' | 'onChange'> & {
  value: number;
  onChange: (value: number) => void;
  parseValue?: (value: string) => number;
  formatValue?: (value: number) => string;
  max?: number;
};

export const NumberInputCell = (props: NumberInputCellProps) => {
  const {
    className,
    value,
    onChange,
    parseValue,
    formatValue,
    max,
    ...others
  } = props;

  const formattedValue = useMemo(() => {
    return formatValue ? formatValue(value) : String(value);
  }, [value, formatValue]);

  const handleChange = useCallback(
    (value: string) => {
      const parsedValue = parseValue
        ? parseValue(value)
        : parseFloat(value) || 0;

      const clamped = Math.min(parsedValue, max ?? Infinity);

      onChange(clamped);
    },
    [onChange, parseValue, max]
  );

  return (
    <TextInputCell
      {...others}
      className={clsx(styles.numberInput, className)}
      value={formattedValue}
      onChange={handleChange}
    />
  );
};
