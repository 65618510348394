import React, { useMemo, useState } from 'react';

import { formatNumberAsCurrency } from '@work4all/utils';

import { LabeledCurrencyInput } from '../labeled-currency-input';
import { ILabeledInput } from '../labeled-input';

interface ILabeledCurrencyInputWithSeparatorProps
  extends Omit<ILabeledInput, 'endAdornment' | 'classes' | 'type'> {
  unit: string;
}

export const LabeledCurrencyInputWithSeparator = React.forwardRef<
  HTMLInputElement,
  ILabeledCurrencyInputWithSeparatorProps
>((props, ref) => {
  const [editMode, setEditMode] = useState(false);

  const formattedValue = useMemo(() => {
    return editMode
      ? props.value
      : formatNumberAsCurrency(Number(props.value)).replace(/,.*$/, ',-');
  }, [editMode, props.value]);

  return (
    <LabeledCurrencyInput
      ref={ref}
      {...props}
      value={formattedValue}
      onFocus={() => setEditMode(true)}
      onBlur={() => setEditMode(false)}
      type="text"
    />
  );
});
