import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import {
  MultiStepControls,
  Step,
} from '@work4all/components/lib/input/multi-step-controls';

import { Calculation } from '@work4all/models/lib/Classes/Calculation.entity';
import { Contract } from '@work4all/models/lib/Classes/Contract.entity';
import { Offer } from '@work4all/models/lib/Classes/Offer.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { DateTimeInputPicker } from '../../../../../../../../locked-inputs';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { ErpData } from '../../../../../ErpData';
import { FormCheck } from '../../../../FormCheck';

export const PerformancePeriod: React.FC = (props) => {
  const { t } = useTranslation();

  const mask = useMaskContext();
  const { register } = useFormContextPlus<ErpData>();

  let varianceColumn: JSX.Element;
  switch (mask.entity) {
    case Entities.deliveryNote:
    case Entities.inboundDeliveryNote:
      varianceColumn = null;
      break;
    default:
      varianceColumn = (
        <Grid item md={4} sm={12} xs={12}>
          <MultiStepControls>
            <Step active={true} index={0}>
              <FormCheck<ErpData, Offer | Contract | Calculation>
                register={register}
                entity={mask.entity}
                allowedEntities={[
                  Entities.offer,
                  Entities.contract,
                  Entities.order,
                  Entities.calculation,
                ]}
                render={({ register }) => (
                  <DateTimeInputPicker
                    required={false}
                    withTime={false}
                    dateLabel={t('INPUTS.DISPO_START')}
                    {...register('dispositionStart')}
                  />
                )}
              />
            </Step>
            <Step active={true} index={1}>
              <FormCheck<ErpData, Offer | Contract | Calculation>
                register={register}
                entity={mask.entity}
                allowedEntities={[
                  Entities.offer,
                  Entities.contract,
                  Entities.order,
                  Entities.calculation,
                ]}
                render={({ register }) => (
                  <DateTimeInputPicker
                    required={false}
                    withTime={false}
                    dateLabel={t('INPUTS.DISPO_END')}
                    {...register('dispositionEnd')}
                  />
                )}
              />
            </Step>
          </MultiStepControls>
        </Grid>
      );
  }

  return (
    <Collapse defaultOpen={true} title={t('MASK.PERFORMANCE_PERIOD')}>
      <Grid container spacing="1rem">
        <Grid item md={4} sm={12} xs={12}>
          <MultiStepControls>
            <Step active={true} index={0}>
              <DateTimeInputPicker
                withTime={false}
                required={false}
                dateLabel={t('INPUTS.START_OF_SERVICE')}
                {...register('serviceStartDate')}
              />
            </Step>
            <Step active={true} index={1}>
              <DateTimeInputPicker
                withTime={false}
                required={false}
                dateLabel={t('INPUTS.END_OF_SERVICE')}
                {...register('serviceEndDate')}
              />
            </Step>
          </MultiStepControls>
        </Grid>

        {varianceColumn}

        <FormCheck<ErpData, Offer | Calculation>
          register={register}
          entity={mask.entity}
          allowedEntities={[Entities.offer, Entities.calculation]}
          render={({ register }) => (
            <Grid item md={4} sm={6} xs={6}>
              <DateTimeInputPicker
                withTime={false}
                required={false}
                {...register('validUntilDate')}
                dateLabel={t('INPUTS.DATE_OF_EXPIRY')}
              />
            </Grid>
          )}
        />
      </Grid>
    </Collapse>
  );
};
