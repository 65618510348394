import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { BzObjType } from '../Enums/BzObjType.enum';
import { EMode } from '../Enums/EMode.enum';
import { SalesOpportunityStatus } from '../Enums/SalesOpportunityStatus.enum';
import { SalesOpportunityTedency } from '../Enums/SalesOpportunityTedency.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputVerkaufschance<T extends EMode = EMode.entity> {
  /** Alias: ansprechpCode */
  ansprechpCode?: number;
  /** Alias: art */
  art?: number;
  /** Alias: bCode */
  bCode?: number;
  /** Alias: bCode2 */
  bCode2?: number;
  /** Alias: beschreibung */
  beschreibung?: string;
  /** Alias: bZObjMemberCode */
  bZObjMemberCode?: number;
  /** Alias: bZObjType */
  bZObjType?: BzObjType;
  /** Alias: code */
  code?: number;
  /** Alias: datumEntscheidung */
  datumEntscheidung?: string;
  /** Alias: datumErstellt */
  datumErstellt?: string;
  /** Alias: datumNaechsteBewertung */
  datumNaechsteBewertung?: string;
  /** Alias: eingefroren */
  eingefroren?: number;
  /** Alias: erstellerBenutzerCode */
  erstellerBenutzerCode?: number;
  /** Alias: erwarteteKosten */
  erwarteteKosten?: number;
  /** Alias: grCode */
  grCode?: number;
  /** Alias: info */
  info?: string;
  /** Alias: kostenStCode */
  kostenStCode?: number;
  /** Alias: mitbewerber */
  mitbewerber?: string;
  /** Alias: naechsterKontakt */
  naechsterKontakt?: string;
  /** Alias: name */
  name?: string;
  /** Alias: notiz */
  notiz?: string;
  /** Alias: projektCode */
  projektCode?: number;
  /** Alias: projektePlanungCode */
  projektePlanungCode?: number;
  /** Alias: sDObjMemberCode */
  sDObjMemberCode?: number;
  /** Alias: status */
  status?: SalesOpportunityStatus;
  /** Alias: statusCode */
  statusCode?: number;
  /** Alias: tendenz */
  tendenz?: SalesOpportunityTedency;
  /** Alias: wahrscheinlichkeitProzent */
  wahrscheinlichkeitProzent?: number;
  /** Alias: waehrungCode */
  waehrungCode?: number;
  /** Alias: wert */
  wert?: number;
  /** Alias: wertAuftrag */
  wertAuftrag?: number;
  /** Alias: zuordnungsart */
  zuordnungsart?: number;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];
  /** Alias: ratingStatusId */
  ratingStatusId?: string;

  __typename?: string;
}

const fields: FieldDefinitions<InputVerkaufschance> = {
  ansprechpCode: {
    alias: 'ansprechpCode',
  },
  art: {
    alias: 'art',
  },
  bCode: {
    alias: 'bCode',
  },
  bCode2: {
    alias: 'bCode2',
  },
  beschreibung: {
    alias: 'beschreibung',
  },
  bZObjMemberCode: {
    alias: 'bZObjMemberCode',
  },
  bZObjType: {
    alias: 'bZObjType',
  },
  code: {
    alias: 'code',
  },
  datumEntscheidung: {
    alias: 'datumEntscheidung',
  },
  datumErstellt: {
    alias: 'datumErstellt',
  },
  datumNaechsteBewertung: {
    alias: 'datumNaechsteBewertung',
  },
  eingefroren: {
    alias: 'eingefroren',
  },
  erstellerBenutzerCode: {
    alias: 'erstellerBenutzerCode',
  },
  erwarteteKosten: {
    alias: 'erwarteteKosten',
  },
  grCode: {
    alias: 'grCode',
  },
  info: {
    alias: 'info',
  },
  kostenStCode: {
    alias: 'kostenStCode',
  },
  mitbewerber: {
    alias: 'mitbewerber',
  },
  naechsterKontakt: {
    alias: 'naechsterKontakt',
  },
  name: {
    alias: 'name',
  },
  notiz: {
    alias: 'notiz',
  },
  projektCode: {
    alias: 'projektCode',
  },
  projektePlanungCode: {
    alias: 'projektePlanungCode',
  },
  sDObjMemberCode: {
    alias: 'sDObjMemberCode',
  },
  status: {
    alias: 'status',
  },
  statusCode: {
    alias: 'statusCode',
  },
  tendenz: {
    alias: 'tendenz',
  },
  wahrscheinlichkeitProzent: {
    alias: 'wahrscheinlichkeitProzent',
  },
  waehrungCode: {
    alias: 'waehrungCode',
  },
  wert: {
    alias: 'wert',
  },
  wertAuftrag: {
    alias: 'wertAuftrag',
  },
  zuordnungsart: {
    alias: 'zuordnungsart',
  },
  customFields: {
    alias: 'customFields',
  },
  ratingStatusId: {
    alias: 'ratingStatusId',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputVerkaufschanceEntityDefinition: EntitiyDefinition<InputVerkaufschance> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputVerkaufschance',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
