import React from 'react';
import ReactDOM from 'react-dom';

interface IPrintStyles {
  printId: string;
  printing: boolean;
}

export const PrintStyles: React.FC<IPrintStyles> = (props) => {
  return ReactDOM.createPortal(
    <style
      dangerouslySetInnerHTML={{
        __html: `

      #${props.printId} {
        display: none;
      }

      ${
        props.printing
          ? `
        @media print {
          body > *:not(#${props.printId}) {
            display: none;
          }

          #${props.printId} {
            display: block;
          }
        }
        `
          : ''
      }
    `,
      }}
    />,
    document.head
  );
};
