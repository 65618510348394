import styles from './FilterCard.module.scss';

import { CancelRounded, ChevronLeft } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';

import { Heading4 } from '../../../../typography/heading4/Heading4';
import { Card } from '../../../card';

interface IFilterCardProps {
  title: string;
  filterValueSet: boolean;
  filterValue: string;
  expanded?: boolean;
  onExpandedChange: (val: boolean) => void;
  onValueDelete?: () => void;
}

export const FilterCard: React.FC<IFilterCardProps> = (props) => {
  const {
    title,
    filterValueSet,
    filterValue,
    expanded,
    onValueDelete,
    onExpandedChange,
  } = props;

  return (
    <Card noPadding={true}>
      <div className={styles.titleWrap}>
        <div
          className={styles.title}
          onClick={() => onExpandedChange(!expanded)}
        >
          <Heading4>{title}</Heading4>
        </div>
        {filterValueSet === true && (
          <>
            <div
              className={styles.valuePreview}
              onClick={() => onExpandedChange(!expanded)}
            >
              <Typography className={styles.filterValue}>
                {filterValue}
              </Typography>
            </div>
            {!!onValueDelete && (
              <IconButton onClick={onValueDelete} size="large">
                <CancelRounded />
              </IconButton>
            )}
          </>
        )}
        <IconButton onClick={() => onExpandedChange(!expanded)} size="large">
          <ChevronLeft
            className={clsx(
              styles.expanderIcon,
              expanded && [styles.expanderIconExpanded]
            )}
          />
        </IconButton>
      </div>
      {expanded && <div>{props.children}</div>}
    </Card>
  );
};
