import styles from './ErrorMessage.module.scss';

import { Paper } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Body2 } from '@work4all/components/lib/typography/body2/Body2';
import { Heading4 } from '@work4all/components/lib/typography/heading4/Heading4';

import { TestDataAttributes } from '@work4all/utils';

interface IErrorMessageProps extends TestDataAttributes {
  message: string;
}

export const ErrorMessage: React.FC<IErrorMessageProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Paper className={styles.root} data-test-id={props['data-test-id']}>
      <Heading4 className={styles.el}>{t('LOGIN.LOGIN_FAILED')}</Heading4>
      <Body2 className={styles.el}>{props.message}</Body2>
    </Paper>
  );
};
