import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Vacation } from './Vacation.entity';

export class VacationRequest<T extends EMode = EMode.entity> {
  /** Alias: vacationDays */
  vacationDays?: Vacation<T>[];
  /** Alias: vacationRequestId */
  vacationRequestId?: string;
  /** Alias: saveSendMailJobId */
  saveSendMailJobId?: string;

  __typename?: string;
}

const fields: FieldDefinitions<VacationRequest> = {
  vacationDays: {
    alias: 'vacationDays',
    entity: Entities.vacation,
  },
  vacationRequestId: {
    alias: 'vacationRequestId',
  },
  saveSendMailJobId: {
    alias: 'saveSendMailJobId',
  },
  __typename: {
    alias: '__typename',
  },
};

export const vacationRequestEntityDefinition: EntitiyDefinition<VacationRequest> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'VacationRequest',
      withPaginationWrapper: false,
      mutations: {
        upsert: {
          mutationName: 'upsertVacationRequest',
          args: [{ name: 'input', type: 'InputVacationRequest' }],
        },
      },
    },
    fieldDefinitions: fields,
  };
