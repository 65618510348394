import styles from './ConvertPopover.module.scss';

import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Popover,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';

import { useHiddenEntities } from '@work4all/data/lib/hooks/use-hidden-entities';
import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export const CONVERT_TYPES: Entities[] = [
  Entities.task,
  Entities.eMail,
  Entities.callMemo,
  Entities.note,
  Entities.appointment,
  Entities.visitationReport,
];

export interface IConvertPopoverProps {
  exclude?: Entities;
  popoverState: ReturnType<typeof usePopoverState>;
  onClick: (entity: Entities) => void;
  convertTypes?: Entities[];
  copyTypes?: Entities[];
  convertTitle?: string;
  copyTitle?: string;
}

export const ConvertPopover = (props: IConvertPopoverProps) => {
  const {
    exclude,
    onClick,
    popoverState,
    convertTypes = CONVERT_TYPES,
    copyTypes,
    copyTitle,
    convertTitle,
  } = props;

  const { t } = useTranslation();

  const { handleClick: _, ...popoverProps } = popoverState;

  const options = exclude
    ? convertTypes.filter((option) => option !== exclude)
    : convertTypes;

  const { isHidden } = useHiddenEntities();

  function renderOptions(opt: Entities[]) {
    return opt
      .filter((entity) => !isHidden(entity))
      .map((entity) => (
        <ListItem key={entity} dense disableGutters disablePadding>
          <ListItemButton
            classes={{ root: styles['button'] }}
            onClick={() => {
              onClick(entity);
            }}
          >
            <ListItemText
              classes={{ primary: styles['primary'] }}
              primary={t(`COMMON.${entity.toUpperCase()}`)}
            />
          </ListItemButton>
        </ListItem>
      ));
  }

  return (
    <Popover {...popoverProps} classes={{ paper: styles['popover'] }}>
      <Paper className={styles['paper']}>
        {copyTypes ? (
          <React.Fragment>
            <Divider
              className={styles['divider']}
              title={copyTitle ?? t('COMMON.TAKE.DATA.IN')}
            />
            <List disablePadding>{renderOptions(copyTypes)}</List>
          </React.Fragment>
        ) : null}
        <Divider
          className={styles['divider']}
          title={convertTitle ?? t('COMMON.TAKE.DATA.IN')}
        />
        <List disablePadding>{renderOptions(options)}</List>
      </Paper>
    </Popover>
  );
};
