import styles from './FooterRow.module.scss';

import clsx from 'clsx';
import React from 'react';

import { TableRow } from '../../../table-row/TableRow';

interface IFooterRowProps {
  className?: string;
}

export const FooterRow: React.FC<IFooterRowProps> = (props) => {
  const { className, ...rest } = props;

  return <TableRow className={clsx(styles.row, props.className)} {...rest} />;
};
