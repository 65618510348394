import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';

interface CreateFilterButtonProps {
  children: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
}

export const CreateFilterButton = ({
  children,
  onClick,
  disabled = false,
}: CreateFilterButtonProps) => {
  return (
    <Button
      variant="text"
      onClick={onClick}
      startIcon={<AddIcon />}
      style={{ margin: '0 1rem 0' }}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};
