import styles from './DetailsView.module.scss';

import clsx from 'clsx';
import React from 'react';

import { ResizableArea, Size } from '../../components/ResizableArea';

interface Props {
  children: React.ReactNode;
  size: Size;
  onResize: (size: Size) => void;
  className?: string;
}

export const DetailsView: React.FC<Props> = (props) => {
  const { size, onResize, children, className } = props;

  return (
    <ResizableArea
      className={clsx(styles.resizable, className)}
      handles="left"
      size={size}
      onResize={onResize}
    >
      <div className={styles.details}>{children}</div>
    </ResizableArea>
  );
};
