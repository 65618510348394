import { useOverlayRouteMatch } from '@work4all/components/lib/navigation/router-navigation-overlay';

import { IRouteParams } from '../types';

export function useOverlayRouteParams(): IRouteParams {
  const match = useOverlayRouteMatch();

  if (!match) {
    return null;
  }

  return match.params as unknown as IRouteParams;
}
