import { FileTypes } from '../File';

export enum DbEntities {
  Layout = 'layout',
  CardWidget = 'cardwidget',
  JsonSchema = 'jsonschema',
  Setting = 'setting',
}

export interface ICardWidgetStates {
  collapsed: boolean;
  closed: boolean;
}

export interface ICardWidgetEntity {
  id: string;
  states: {
    [FileTypes.Customers]: ICardWidgetStates;
    [FileTypes.Suppliers]: ICardWidgetStates;
    [FileTypes.Projects]: ICardWidgetStates;
  };
}
