import React from 'react';

import { FilterCard } from '../filter-card/FilterCard';
import { SearchInput } from '../search-input/SearchInput';

interface ISearchFilterCardProps {
  value: string;
  onChange: (value: string) => void;
  onExpandedChange: (val: boolean) => void;
  onDelete: () => void;
  title: string;
  expanded: boolean;
}

export const SearchFilterCard: React.FC<ISearchFilterCardProps> = (props) => {
  const { title, value, expanded, onChange, onExpandedChange, onDelete } =
    props;

  return (
    <FilterCard
      title={title}
      filterValue={value ? `"${value.trim()}"` : ''}
      filterValueSet={!!value}
      expanded={expanded}
      onExpandedChange={onExpandedChange}
      onValueDelete={onDelete}
    >
      <SearchInput value={value} onChange={onChange} />
    </FilterCard>
  );
};
