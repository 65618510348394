import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTableStateBag } from '@work4all/components';
import { useSalesOpportunityGroups } from '@work4all/components/lib/components/entity-picker/sales-opportunities-group-picker/useSalesOpportunityGroups';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import {
  CheckListField,
  ICheckItem,
} from '@work4all/components/lib/input/check-list-field/CheckListField';

export function SalesOpportunitiesQuickFilters() {
  const [selectedFilter, setSelectedFilter] = useState([]);

  const { t } = useTranslation();

  const { data } = useSalesOpportunityGroups();

  const tableStateBag = useTableStateBag();

  const groupIdColummn = tableStateBag.columnsById['groupId'];

  const handleGroupsChange = useCallback(
    (groups: ICheckItem[]) => {
      if (groups.length === 0) {
        groupIdColummn.setFilter(null);
        setSelectedFilter([]);
      } else {
        groupIdColummn.setFilter({
          value: groups,
          filterType: groupIdColummn.filterType,
        });
        setSelectedFilter(groups);
      }
    },
    [groupIdColummn, setSelectedFilter]
  );

  if (!data) return null;

  return (
    <CheckListField
      style={{ height: '100%' }}
      labelElement={
        <Divider
          title={t('COMMON.GROUP')}
          horizontalPadding={true}
          style={{ paddingBottom: '1rem' }}
        />
      }
      onChange={(list) => {
        handleGroupsChange(list);
      }}
      all={data}
      value={selectedFilter}
    />
  );
}
