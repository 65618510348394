import { IFieldData } from '@work4all/models/lib/DataProvider';

export const FILE_ID_ALIAS = 'id';

export enum FileQueriesRootFields {
  Project = 'getProjekte',
}

export enum FileIdQueriesNames {
  Project = 'code',
}

export enum FileNestedFieldsNames {
  Client = 'kunde',
  Contact = 'lieferantenAnsprechpartner',
}

export const fileNestedFieldsAliasesMap = {
  [FileNestedFieldsNames.Client]: 'customer',
  [FileNestedFieldsNames.Contact]: 'contact',
};

export const DATA_PREFIX = 'data';

export const defaultFields: IFieldData[] = [
  { name: 'total' },
  { name: 'size' },
  { name: 'page' },
];
