import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { ServiceContractDeductionKind } from '../Enums/ServiceContractDeductionKind.enum';
import { ServiceContractRythm } from '../Enums/ServiceContractRythm.enum';

import { BusinessPartnerContactCombined } from './BusinessPartnerContactCombined.entity';
import { CustomField } from './CustomField.entity';
import { ServiceContractPosition } from './ServiceContractPosition.entity';

export class ServiceContract<T extends EMode = EMode.entity> {
  /** Alias: abrechnungArt */
  serviceContractDeductionKind?: ServiceContractDeductionKind;
  /** Alias: artLookupCode */
  kindLookupId?: number;
  /** Alias: auftragCode */
  contractId?: number;
  /** Alias: betrag */
  amount?: number;
  /** Alias: code */
  id?: number;
  /** Alias: creationBenutzerCode */
  creatorUserId?: number;
  /** Alias: creationDate */
  creationDate?: string;
  /** Alias: datumKuendigung */
  terminationDate?: string;
  /** Alias: datumNaechsteAbrechnung */
  nextInvoiceDate?: string;
  /** Alias: dauerrechnung */
  permanentInvoice?: boolean;
  /** Alias: endKundeApCode */
  finalCustomerContactId?: number;
  /** Alias: endkundeSdObjMemberCode */
  finalCustomerId?: number;
  /** Alias: grCode */
  groupId?: number;
  /** Alias: hauptadresseAbteilungCode */
  mainAddressDepartmentId?: number;
  /** Alias: hauptadresseApCode */
  contactId?: number;
  /** Alias: hauptadresseSdObjMemberCode */
  mainAddressSdObjMemberId?: number;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: kostenstelleCode */
  costCenterId?: number;
  /** Alias: name */
  name?: string;
  /** Alias: nebenadresse1AbteilungCode */
  additionalAddress1DepartmentId?: number;
  /** Alias: nebenadresse1ApCode */
  additionalAddress1ContactId?: number;
  /** Alias: nebenadresse1SdObjMemberCode */
  nebenadresse1SdObjMemberCode?: number;
  /** Alias: nebenadresse2AbteilungCode */
  additionalAddress2DepartmentId?: number;
  /** Alias: nebenadresse2ApCode */
  additionalAddress2ContactId?: number;
  /** Alias: nebenadresse2SdObjMemberCode */
  additionalAddress2SdObjMemberId?: number;
  /** Alias: nebenadresse3ApCode */
  additionalAddress3ContactId?: number;
  /** Alias: nebenadresse3SdObjMemberCode */
  additionalAddress3SdObjMemberId?: number;
  /** Alias: notiz */
  note?: string;
  /** Alias: nummer */
  number?: number;
  /** Alias: positionen */
  positionList?: ServiceContractPosition<T>[];
  /** Alias: rhytmus */
  rythm?: ServiceContractRythm;
  /** Alias: skonto */
  skonto?: number;
  /** Alias: skontoFrist */
  skontoDurationDays?: number;
  /** Alias: spracheCode */
  languageId?: number;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: wartungsvertragEnde */
  serviceContractEndDate?: string;
  /** Alias: wartungsvertragNummer */
  serviceContractNumber?: string;
  /** Alias: wartunsgvertragBegin */
  serviceContractStartDate?: string;
  /** Alias: zahlungCode */
  paymentId?: number;
  /** Alias: zahlungsfrist */
  paymentDeadline?: number;
  /** Alias: customFields */
  customFieldList?: CustomField<T>[];
  /** Alias: mainAddress */
  businessPartnerContactCombined?: BusinessPartnerContactCombined<T>;

  __typename?: string;
}

const fields: FieldDefinitions<ServiceContract> = {
  serviceContractDeductionKind: {
    alias: 'abrechnungArt',
  },
  kindLookupId: {
    alias: 'artLookupCode',
  },
  contractId: {
    alias: 'auftragCode',
  },
  amount: {
    alias: 'betrag',
  },
  id: {
    alias: 'code',
  },
  creatorUserId: {
    alias: 'creationBenutzerCode',
  },
  creationDate: {
    alias: 'creationDate',
  },
  terminationDate: {
    alias: 'datumKuendigung',
  },
  nextInvoiceDate: {
    alias: 'datumNaechsteAbrechnung',
  },
  permanentInvoice: {
    alias: 'dauerrechnung',
  },
  finalCustomerContactId: {
    alias: 'endKundeApCode',
  },
  finalCustomerId: {
    alias: 'endkundeSdObjMemberCode',
  },
  groupId: {
    alias: 'grCode',
  },
  mainAddressDepartmentId: {
    alias: 'hauptadresseAbteilungCode',
  },
  contactId: {
    alias: 'hauptadresseApCode',
  },
  mainAddressSdObjMemberId: {
    alias: 'hauptadresseSdObjMemberCode',
  },
  insertTime: {
    alias: 'insertTime',
  },
  costCenterId: {
    alias: 'kostenstelleCode',
  },
  name: {
    alias: 'name',
  },
  additionalAddress1DepartmentId: {
    alias: 'nebenadresse1AbteilungCode',
  },
  additionalAddress1ContactId: {
    alias: 'nebenadresse1ApCode',
  },
  nebenadresse1SdObjMemberCode: {
    alias: 'nebenadresse1SdObjMemberCode',
  },
  additionalAddress2DepartmentId: {
    alias: 'nebenadresse2AbteilungCode',
  },
  additionalAddress2ContactId: {
    alias: 'nebenadresse2ApCode',
  },
  additionalAddress2SdObjMemberId: {
    alias: 'nebenadresse2SdObjMemberCode',
  },
  additionalAddress3ContactId: {
    alias: 'nebenadresse3ApCode',
  },
  additionalAddress3SdObjMemberId: {
    alias: 'nebenadresse3SdObjMemberCode',
  },
  note: {
    alias: 'notiz',
  },
  number: {
    alias: 'nummer',
  },
  positionList: {
    alias: 'positionen',
    entity: Entities.serviceContractPosition,
  },
  rythm: {
    alias: 'rhytmus',
  },
  skonto: {
    alias: 'skonto',
  },
  skontoDurationDays: {
    alias: 'skontoFrist',
  },
  languageId: {
    alias: 'spracheCode',
  },
  updateTime: {
    alias: 'updateTime',
  },
  serviceContractEndDate: {
    alias: 'wartungsvertragEnde',
  },
  serviceContractNumber: {
    alias: 'wartungsvertragNummer',
  },
  serviceContractStartDate: {
    alias: 'wartunsgvertragBegin',
  },
  paymentId: {
    alias: 'zahlungCode',
  },
  paymentDeadline: {
    alias: 'zahlungsfrist',
  },
  customFieldList: {
    alias: 'customFields',
    entity: Entities.customField,
  },
  businessPartnerContactCombined: {
    alias: 'mainAddress',
    entity: Entities.businessPartnerContactCombined,
  },
  __typename: {
    alias: '__typename',
  },
};

export const serviceContractEntityDefinition: EntitiyDefinition<ServiceContract> =
  {
    local: {},
    remote: {
      queryName: 'getWartungsvertrag',
      fragmentName: 'Wartungsvertrag',
      withPaginationWrapper: true,
      params: [
        { name: 'querySize', type: 'Int' },
        { name: 'queryPage', type: 'Int' },
        { name: 'querySortOrder', type: 'SortOrderStyle' },
        { name: 'querySortBy', type: 'String' },
        { name: 'filter', type: 'String' },
      ],
    },
    fieldDefinitions: fields,
  };
