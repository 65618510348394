import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { BzObjType } from '../Enums/BzObjType.enum';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { SalesOpportunityStatus } from '../Enums/SalesOpportunityStatus.enum';
import { SalesOpportunityTedency } from '../Enums/SalesOpportunityTedency.enum';

import { BusinessPartner } from './BusinessPartner.entity';
import { Contact } from './Contact.entity';
import { CostCenter } from './CostCenter.entity';
import { Currency } from './Currency.entity';
import { CustomField } from './CustomField.entity';
import { Offer } from './Offer.entity';
import { Project } from './Project.entity';
import { ProjectProcess } from './ProjectProcess.entity';
import { SalesOpportunityAttachement } from './SalesOpportunityAttachement.entity';
import { SalesOpportunityGroup } from './SalesOpportunityGroup.entity';
import { SalesOpportunityRating } from './SalesOpportunityRating.entity';
import { SalesOpportunityRatingStatus } from './SalesOpportunityRatingStatus.entity';
import { User } from './User.entity';

export class SalesOpportunities<T extends EMode = EMode.entity> {
  /** Alias: ansprechpCode */
  contactId?: number;
  /** Alias: art */
  kind?: number;
  /** Alias: bCode */
  userId?: number;
  /** Alias: bCode2 */
  user2Id?: number;
  /** Alias: beschreibung */
  description?: string;
  /** Alias: bZObjMemberCode */
  bZObjMemberCode?: number;
  /** Alias: bZObjType */
  bZObjType?: BzObjType;
  /** Alias: code */
  id?: number;
  /** Alias: datumEntscheidung */
  decisionDate?: string;
  /** Alias: datumErstellt */
  dateCreated?: string;
  /** Alias: datumNaechsteBewertung */
  dateNextReview?: string;
  /** Alias: eingefroren */
  frozen?: number;
  /** Alias: erstellerBenutzerCode */
  creatorUserId?: number;
  /** Alias: erwarteteKosten */
  expectedCosts?: number;
  /** Alias: grCode */
  groupId?: number;
  /** Alias: info */
  info?: string;
  /** Alias: kostenStCode */
  kostenStCode?: number;
  /** Alias: mitbewerber */
  competitors?: string;
  /** Alias: naechsterKontakt */
  nextContactDate?: string;
  /** Alias: name */
  name?: string;
  /** Alias: notiz */
  note?: string;
  /** Alias: projektCode */
  projectId?: number;
  /** Alias: projektePlanungCode */
  projectProcessId?: number;
  /** Alias: sDObjMemberCode */
  businessPartnerId?: number;
  /** Alias: status */
  status?: SalesOpportunityStatus;
  /** Alias: statusCode */
  statusId?: number;
  /** Alias: tendenz */
  tendency?: SalesOpportunityTedency;
  /** Alias: wahrscheinlichkeitProzent */
  probabilityPercent?: number;
  /** Alias: waehrungCode */
  currencyId?: number;
  /** Alias: wert */
  value?: number;
  /** Alias: wertAuftrag */
  orderValue?: number;
  /** Alias: zuordnungsart */
  assignmentType?: number;
  /** Alias: customFields */
  customFieldList?: CustomField<T>[];
  /** Alias: ansprechpartner */
  contact?: Contact<T>;
  /** Alias: benutzer */
  user?: User<T>;
  /** Alias: benutzer2 */
  user2?: User<T>;
  /** Alias: ersteller */
  creator?: User<T>;
  /** Alias: angebot */
  offer?: Offer<T>;
  /** Alias: kostenstelle */
  costCenter?: CostCenter<T>;
  /** Alias: projekt */
  project?: Project<T>;
  /** Alias: projektVorgang */
  projectProcess?: ProjectProcess<T>;
  /** Alias: kunde */
  businessPartner?: BusinessPartner<T>;
  /** Alias: waehrung */
  currency?: Currency<T>;
  /** Alias: group */
  group?: SalesOpportunityGroup<T>;
  /** Alias: ratings */
  ratings?: SalesOpportunityRating<T>[];
  /** Alias: ratingStatusId */
  ratingStatusId?: string;
  /** Alias: ratingStatus */
  ratingStatus?: SalesOpportunityRatingStatus<T>;
  /** Alias: attachements */
  attachementList?: SalesOpportunityAttachement<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<SalesOpportunities> = {
  contactId: {
    alias: 'ansprechpCode',
  },
  kind: {
    alias: 'art',
  },
  userId: {
    alias: 'bCode',
  },
  user2Id: {
    alias: 'bCode2',
  },
  description: {
    alias: 'beschreibung',
  },
  bZObjMemberCode: {
    alias: 'bZObjMemberCode',
  },
  bZObjType: {
    alias: 'bZObjType',
  },
  id: {
    alias: 'code',
  },
  decisionDate: {
    alias: 'datumEntscheidung',
  },
  dateCreated: {
    alias: 'datumErstellt',
  },
  dateNextReview: {
    alias: 'datumNaechsteBewertung',
  },
  frozen: {
    alias: 'eingefroren',
  },
  creatorUserId: {
    alias: 'erstellerBenutzerCode',
  },
  expectedCosts: {
    alias: 'erwarteteKosten',
  },
  groupId: {
    alias: 'grCode',
  },
  info: {
    alias: 'info',
  },
  kostenStCode: {
    alias: 'kostenStCode',
  },
  competitors: {
    alias: 'mitbewerber',
  },
  nextContactDate: {
    alias: 'naechsterKontakt',
  },
  name: {
    alias: 'name',
  },
  note: {
    alias: 'notiz',
  },
  projectId: {
    alias: 'projektCode',
  },
  projectProcessId: {
    alias: 'projektePlanungCode',
  },
  businessPartnerId: {
    alias: 'sDObjMemberCode',
  },
  status: {
    alias: 'status',
  },
  statusId: {
    alias: 'statusCode',
  },
  tendency: {
    alias: 'tendenz',
  },
  probabilityPercent: {
    alias: 'wahrscheinlichkeitProzent',
  },
  currencyId: {
    alias: 'waehrungCode',
  },
  value: {
    alias: 'wert',
  },
  orderValue: {
    alias: 'wertAuftrag',
  },
  assignmentType: {
    alias: 'zuordnungsart',
  },
  customFieldList: {
    alias: 'customFields',
    entity: Entities.customField,
  },
  contact: {
    alias: 'ansprechpartner',
    entity: Entities.contact,
  },
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  user2: {
    alias: 'benutzer2',
    entity: Entities.user,
  },
  creator: {
    alias: 'ersteller',
    entity: Entities.user,
  },
  offer: {
    alias: 'angebot',
    entity: Entities.offer,
  },
  costCenter: {
    alias: 'kostenstelle',
    entity: Entities.costCenter,
  },
  project: {
    alias: 'projekt',
    entity: Entities.project,
  },
  projectProcess: {
    alias: 'projektVorgang',
    entity: Entities.projectProcess,
  },
  businessPartner: {
    alias: 'kunde',
    entity: Entities.businessPartner,
  },
  currency: {
    alias: 'waehrung',
    entity: Entities.currency,
  },
  group: {
    alias: 'group',
    entity: Entities.salesOpportunityGroup,
  },
  ratings: {
    alias: 'ratings',
    entity: Entities.salesOpportunityRating,
  },
  ratingStatusId: {
    alias: 'ratingStatusId',
  },
  ratingStatus: {
    alias: 'ratingStatus',
    entity: Entities.salesOpportunityRatingStatus,
  },
  attachementList: {
    alias: 'attachements',
    entity: Entities.salesOpportunityAttachement,
  },
  __typename: {
    alias: '__typename',
  },
};

export const salesOpportunitiesEntityDefinition: EntitiyDefinition<SalesOpportunities> =
  {
    local: {},
    remote: {
      queryName: 'getVerkaufschancen',
      fragmentName: 'Verkaufschance',
      withPaginationWrapper: true,
      mutations: {
        upsert: {
          mutationName: 'upsertVerkaufschance',
          args: [
            { name: 'input', type: 'InputVerkaufschance!' },
            { name: 'relations', type: 'InputVerkaufschanceRelation' },
            { name: 'drySave', type: 'Boolean' },
          ],
        },
      },
      params: [
        { name: 'querySize', type: 'Int' },
        { name: 'queryPage', type: 'Int' },
        { name: 'querySortOrder', type: 'SortOrderStyle' },
        { name: 'querySortBy', type: 'String' },
        { name: 'filter', type: 'String' },
      ],
    },
    fieldDefinitions: fields,
  };
