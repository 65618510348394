import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';
import { AppointmentPreviewContainer } from '@work4all/components/lib/components/entity-preview/appointment-preview';
import { DEFAULT_PRE_FILTER } from '@work4all/components/lib/constants';
import { GroupedItem } from '@work4all/components/lib/dataDisplay/basic-table/hooks/query-table-data/types';

import { nameof } from '@work4all/data/lib/helper/nameof';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { fixAppointmentStartEndDates } from '@work4all/utils';

import { Table } from '../../components/data-tables/table/Table';
import { OnOpenMask } from '../../containers/mask-overlays/mask-overlay';
import { PersonalAndGeneralView } from '../filters/PersonalAndGeneralView';

import schema from './schemata/appointment-table-schema.json';
import { TableLayoutContext, useTableLayoutState } from './table-layout';
import { TableNoRowsPlaceholder } from './TableNoRowsPlaceholder';
import { useDataTable } from './use-data-table';
import { useDeleteEntitiesToolbarConfig } from './use-delete-entities-toolbar-config';
import { useTableMaskHandlers } from './use-table-mask-handlers';
import { useUserColumnConfigs } from './use-user-column-configs';

const APPOINTMENT_ABSOLUTE_DATE_FIELDS: Appointment<EMode.query> = {
  fromAbsolute: null,
  toAbsolute: null,
};

interface Props {
  prefilter?: unknown[];
  onOpenMask: OnOpenMask;
}

const defaultSort = [
  {
    field: nameof<Appointment>('startDate'),
    direction: SortDirection.DESCENDING,
  },
];
const manualGroupBy = true;

export const AppointmentsTable = React.forwardRef<TableInstance, Props>(
  function AppointmentsTable(props, ref) {
    const { prefilter, onOpenMask } = props;
    const { entity: entityType } = schema as never;

    const tableStateBag = useTableStateBag();

    const layoutState = useTableLayoutState();

    const adaptedPreFilter = useMemo(
      () => [...DEFAULT_PRE_FILTER[Entities.appointment], ...(prefilter ?? [])],
      [prefilter]
    );
    const [layout] = layoutState;

    const dataTable = useDataTable<Appointment, never>({
      layout,
      schema: schema as never,
      tableStateBag,
      prefilter: adaptedPreFilter,
      defaultSort,
      forceRequestFields: APPOINTMENT_ABSOLUTE_DATE_FIELDS,
    });

    const {
      columnConfigs,
      cardConfig,
      prepareRowDisplayModifiers,
      fetchMore,
      total,
      selectedEntities,
      initialSortBy,
      pending,
    } = dataTable;

    // Fix appointment start and end dates for full day appointments.
    const data = useFixedAppointmentsData(dataTable.data);

    const maskHandlers = useTableMaskHandlers(entityType, onOpenMask);

    const deleteConfig = useDeleteEntitiesToolbarConfig({
      entityType,
    });

    const [userConfig, userConfigMethods] = useUserColumnConfigs({
      layout,
      entityType,
      columnConfigs,
    });

    const { t } = useTranslation();

    if (!userConfig) return null;

    return (
      <TableLayoutContext value={layoutState}>
        <Table
          pending={pending}
          noRowsRenderer={() => <TableNoRowsPlaceholder />}
          layout={layout}
          cardConfig={cardConfig}
          ref={ref}
          areas={{
            left: {
              content: <PersonalAndGeneralView entity={Entities.appointment} />,
              resizable: true,
              collapseConfig: {
                title: t('COMMON.FILTER'),
              },
            },
            right: selectedEntities &&
              selectedEntities.length > 0 && {
                content: (
                  <AppointmentPreviewContainer
                    onCloseClicked={() =>
                      tableStateBag.tableInstance.toggleAllRowsSelected(false)
                    }
                    onEditClicked={maskHandlers.edit.handler}
                    onShareClicked={maskHandlers.share}
                    appointmentIds={selectedEntities.map((e) => e.id)}
                    convertProps={{
                      exclude: entityType,
                      onClick: maskHandlers.convert,
                    }}
                  />
                ),
              },
          }}
          actions={{
            add: maskHandlers.create,
            edit: maskHandlers.edit,
            convert: {
              exclude: entityType,
              handler: maskHandlers.convert,
            },
            remove: deleteConfig,
            resetColumns: userConfigMethods.remove,
            createWidget: true,
            clone: maskHandlers.clone,
          }}
          columnConfigs={userConfig}
          manualGroupBy={manualGroupBy}
          initialSortBy={initialSortBy}
          loadMoreItems={fetchMore}
          prepareRowDisplayModifiers={prepareRowDisplayModifiers}
          data={data}
          total={total}
        />
      </TableLayoutContext>
    );
  }
);

function isAppointment(object: object): object is Appointment {
  return (
    typeof object === 'object' &&
    object != null &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (object as any).__typename === 'Termin'
  );
}

function useFixedAppointmentsData(
  data: (Appointment | GroupedItem)[]
): (Appointment | GroupedItem)[] {
  const fixed = useMemo(() => {
    if (!data) return data;

    function fixRowRecursively(
      row: Appointment | GroupedItem
    ): Appointment | GroupedItem {
      if (isAppointment(row)) return fixAppointmentStartEndDates(row);

      if (row == null || row.skeleton) return row;

      if (row.isGrouped) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return { ...row, subRows: row.subRows.map(fixRowRecursively) as any };
      }
    }

    return data.map(fixRowRecursively);
  }, [data]);

  return fixed;
}
