import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { PROJECT_DATA } from '@work4all/components/lib/components/entity-picker/project-picker/projectPickerPresets';

import { InboundDeliveryNote } from '@work4all/models/lib/Classes/InboundDeliveryNote.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { InboundDeliveryNoteKindPickerField } from '../../../../../../../../../../components/entity-picker/InboundDeliveryNoteKindPickerField';
import { ProjectPickerField } from '../../../../../../../../../../components/entity-picker/ProjectPickerField';
import { UserPickerField } from '../../../../../../../../../../components/entity-picker/UserPickerField';
import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { LabeledInput } from '../../../../../../../../locked-inputs';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { ErpData } from '../../../../../ErpData';
import { FormCheck } from '../../../../FormCheck';

export const Assignment: React.VFC = () => {
  const { t } = useTranslation();

  const mask = useMaskContext();
  const { control, register } = useFormContextPlus<ErpData>();

  return (
    <Collapse defaultOpen={true} title={t('MASK.ASSIGNMENT')}>
      <Grid container spacing="1rem">
        <Grid item xs={12} sm={4}>
          <ControllerPlus
            control={control}
            name="user2"
            render={({ field, fieldState }) => {
              return (
                <UserPickerField
                  {...field}
                  label={t('INPUTS.RESPONSIBLE')}
                  error={fieldState?.error?.message}
                  clearable={false}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControllerPlus
            control={control}
            name="user"
            render={({ field, fieldState }) => {
              return (
                <UserPickerField
                  {...field}
                  label={t('COMMON.PERSON')}
                  value={field.value}
                  error={fieldState?.error?.message}
                  clearable={false}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={6} sm={2}>
          <LabeledInput label={t('INPUTS.OUR_SIGN')} {...register('ourSign')} />
        </Grid>
        <Grid item xs={6} sm={2}>
          <LabeledInput
            placeholder={t('INPUTS.YOUR_SIGN')}
            label={t('INPUTS.YOUR_SIGN')}
            {...register('yourSign')}
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <ControllerPlus
            control={control}
            name="project"
            render={({ field }) => {
              return <ProjectPickerField {...field} data={PROJECT_DATA} />;
            }}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <FormCheck<ErpData, InboundDeliveryNote>
            entity={mask.entity}
            allowedEntities={[Entities.inboundDeliveryNote]}
            control={control}
            render={({ control }) => (
              <ControllerPlus
                control={control}
                name={'inboundDeliveryNoteKind'}
                render={({ field }) => {
                  return <InboundDeliveryNoteKindPickerField {...field} />;
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Collapse>
  );
};
