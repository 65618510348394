import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Contact } from './Contact.entity';

export class ProjectInvolveds<T extends EMode = EMode.entity> {
  /** Alias: kundenansprechpartner */
  kundenansprechpartner?: Contact<T>[];
  /** Alias: lieferantenAnsprechpartner */
  supplierContact?: Contact<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<ProjectInvolveds> = {
  kundenansprechpartner: {
    alias: 'kundenansprechpartner',
    entity: Entities.contact,
  },
  supplierContact: {
    alias: 'lieferantenAnsprechpartner',
    entity: Entities.contact,
  },
  __typename: {
    alias: '__typename',
  },
};

export const projectInvolvedsEntityDefinition: EntitiyDefinition<ProjectInvolveds> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'ProjektBeteiligte',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
