import { AppointmentAttendee } from '@work4all/models/lib/Classes/AppointmentAttendee.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { UserClass } from '@work4all/models/lib/Enums/UserClass.enum';

export function mapUsersToAppointmentAttendees(
  users: User[]
): AppointmentAttendee[] {
  const appointmentAttendees = users.map<AppointmentAttendee>((user) => {
    if (user.userKind === UserClass.RESSOURCE) {
      return {
        ressource: user,
        userId: user.id,
      };
    }

    return {
      user,
      userId: user.id,
    };
  });

  return appointmentAttendees;
}
