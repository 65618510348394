import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { UserPresence } from './UserPresence.entity';

export class SetUserPresenceResult<T extends EMode = EMode.entity> {
  /** Alias: ok */
  ok?: boolean;
  /** Alias: message */
  message?: string;
  /** Alias: userPresence */
  userPresence?: UserPresence<T>;

  __typename?: string;
}

const fields: FieldDefinitions<SetUserPresenceResult> = {
  ok: {
    alias: 'ok',
  },
  message: {
    alias: 'message',
  },
  userPresence: {
    alias: 'userPresence',
    entity: Entities.userPresence,
  },
  __typename: {
    alias: '__typename',
  },
};

export const setUserPresenceResultEntityDefinition: EntitiyDefinition<SetUserPresenceResult> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'SetUserPresenceResult',
      withPaginationWrapper: false,
      mutations: {
        upsert: {
          mutationName: 'setUserPresenceTimestamp',
          args: [
            { name: 'timestampType', type: 'CreateZeitstempelArt!' },
            { name: 'timestampDate', type: 'DateTimeEx' },
            { name: 'userCode', type: 'Int' },
          ],
        },
      },
    },
    fieldDefinitions: fields,
  };
