import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Project } from '@work4all/models/lib/Classes/Project.entity';

import { IFilterPickerConfig } from '../../../../components/entity-picker/components/list-entity-picker';
import { ProjectPicker } from '../../../../components/entity-picker/project-picker/ProjectPicker';
import { FilterCard } from '../filter-card/FilterCard';

export type IProjectFilterCardProps = {
  title: string;
  value: Project[] | null;
  onChange: (value: Project[]) => void;
  expanded: boolean;
  onExpandedChange: (val: boolean) => void;
  filterConfig?: IFilterPickerConfig;
};

export function ProjectFilterCard(props: IProjectFilterCardProps) {
  const { title, value, onChange, expanded, onExpandedChange, filterConfig } =
    props;

  const { t } = useTranslation();

  const filterValueText: string = useMemo(() => {
    if (!value || value.length === 0) return null;

    return value.length + ' ' + t('COMMON.CHOSEN');
  }, [value, t]);

  return (
    <FilterCard
      title={title}
      filterValue={filterValueText}
      filterValueSet={!!filterValueText}
      expanded={expanded}
      onExpandedChange={onExpandedChange}
      onValueDelete={() => {
        onChange([]);
      }}
    >
      <ProjectPicker
        layout="advanced"
        multiple={true}
        value={value}
        onChange={(newValue) => {
          onChange(newValue);
        }}
        filterConfig={filterConfig}
      />
    </FilterCard>
  );
}
