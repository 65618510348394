import styles from './GeneralTabPanel.module.scss';

import { Divider, Theme, useMediaQuery } from '@mui/material';
import clsx from 'clsx';

import { Left } from './components/left';
import { Right } from './components/right';

export const GeneralTabPanel = () => {
  const mobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));

  return (
    <div className={clsx(styles.panel, { [styles.mobile]: mobile })}>
      <Left />
      {!mobile && <Divider orientation="vertical" />}
      <Right />
    </div>
  );
};
