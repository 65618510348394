import styles from './HeaderWrapper.module.scss';

import clsx from 'clsx';
import React from 'react';

import { useDraggableRow } from '../../../row-render/hooks/use-draggable-row';

interface IHeaderWrapperProps {
  className?: string;
  elementRef?: React.ForwardedRef<HTMLDivElement>;
}

export const HeaderWrapper: React.FC<IHeaderWrapperProps> = (props) => {
  const { draggable } = useDraggableRow();
  const className = clsx(styles.root, props.className, {
    [styles.draggableHeader]: draggable,
  });

  return (
    <div ref={props.elementRef} className={className}>
      {props.children}
    </div>
  );
};
