import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { EMailSignature } from '@work4all/models/lib/Classes/EMailSignature.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EmailSignaturePreview } from './EmailSignaturePreview';

export type IEmailPreviewContainerProps = {
  emailSignatureId: string;
  onCloseClicked?: React.MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: React.MouseEventHandler<HTMLButtonElement>;
};

export const EMAIL_SIGNATURE_PREVIEW_DATA: EMailSignature<EMode.query> = {
  id: null,
  name: null,
  body: null,
};

export function EmailSignaturePreviewContainer(
  props: IEmailPreviewContainerProps
) {
  const { emailSignatureId, onCloseClicked, onEditClicked } = props;

  const requestData = useMemo<DataRequest>(() => {
    return {
      completeDataResponse: true,
      entity: Entities.eMailSignature,
      data: EMAIL_SIGNATURE_PREVIEW_DATA,
    };
  }, []);

  const { data } = useDataProvider<EMailSignature>(requestData);

  const found = useMemo(() => {
    return data.find(
      (emailSignature) => emailSignature.id === emailSignatureId
    );
  }, [data, emailSignatureId]);

  if (!found) {
    return null;
  }

  return (
    <EmailSignaturePreview
      onCloseClicked={onCloseClicked}
      onEditClicked={onEditClicked}
      emailSignature={found}
    />
  );
}
