import styles from './ContainedButton.module.scss';

import { ButtonBase } from '@mui/material';
import clsx from 'clsx';
import React, { ButtonHTMLAttributes } from 'react';

export const ContainedButton: React.FC<
  ButtonHTMLAttributes<HTMLButtonElement> & { title: string }
> = (props) => {
  const { className, title, ...rest } = props;

  return (
    <ButtonBase className={clsx(styles.button, className)} {...rest}>
      {title}
    </ButtonBase>
  );
};
