import React from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';

import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { CheckboxRadioItem } from '../../../../../../../../locked-inputs';
import { ControlWrapper } from '../../../../../../../components/control-wrapper';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { BusinessPartners } from '../../../../../BusinessPartnerOverlayController';
import { BPMaskFormValue } from '../../../../../types';

export const Miscellaneous: React.FC = () => {
  const { t } = useTranslation();

  const { register } = useFormContextPlus<BPMaskFormValue>();
  const mask = useMaskContext<BusinessPartners>();

  return (
    <Collapse defaultOpen={true} title={t('MASK.MISCELLANEOUS')}>
      <ControlWrapper grid={false}>
        <CheckboxRadioItem
          label={t('INPUTS.DEACTIVE_ERP_APPROVAL_REQUIREMENT')}
          defaultChecked={mask.data.dontSumUpDTAN}
          {...register('deactivateERPReleaseRequirement')}
        />
        <CheckboxRadioItem
          label={t('INPUTS.DO_NOT_GROUP_SEPA_DTA')}
          defaultChecked={mask.data.dontSumUpDTAN}
          {...register('dontSumUpDTAN')}
        />
      </ControlWrapper>
    </Collapse>
  );
};
