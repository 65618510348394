import { fromPromise, Observable } from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';

interface ICreateTokenRefreshLinkOptions {
  /**
   * This function will be called to update the access token when a request
   * fails because of an authorization error. This must return a promise that
   * will resolve with the new access token.
   */
  refreshToken: () => Promise<string>;
  /**
   * If the promise returned by the refreshToken function rejects, this function
   * will be called.
   */
  onError: () => void;
}

export const createTokenRefreshLink = (
  options: ICreateTokenRefreshLinkOptions
) => {
  // Keep track of the already running refresh request.
  // The first request that fails will be the one that triggers the refresh.
  // All other requests will just wait until the refresh is done.
  let tokenPromise: Promise<string | null> | null = null;

  return onError((error) => {
    if (!isAuthorizationError(error)) {
      return;
    }

    const { operation, forward, response } = error;

    if (tokenPromise === null) {
      tokenPromise = options
        .refreshToken()
        .catch(() => {
          options.onError();
          return null;
        })
        .finally(() => {
          // Reset the token promise to null so that the next failed request
          // will trigger a new refresh.
          tokenPromise = null;
        });
    }

    return fromPromise(tokenPromise).flatMap((token) => {
      if (token === null) {
        // If the token is null, the refresh failed. Just return the original
        // response so that the caller can handle the error.
        return Observable.of(response);
      }

      // If the token is not null, the refresh succeeded. Repeat the request
      // with the new access token.
      operation.setContext(({ headers }) => ({
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      }));

      return forward(operation);
    });
  });
};

export function isAuthorizationError(response: ErrorResponse) {
  const { networkError } = response;

  if (networkError != null && 'statusCode' in networkError) {
    if (networkError.statusCode === 401) {
      return true;
    }
  }

  return false;
}
