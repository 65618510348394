import styles from './HeaderText.module.scss';

import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { TextEditor } from '@work4all/components/lib/input/format-text/TextEditor';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input';

import { settings, useSetting } from '../../../../../../../../../../settings';

export const HeaderText: React.VFC = () => {
  const { t } = useTranslation();

  const { control } = useFormContext();

  const rtfTextsInERP = useSetting(settings.rtfTextsInERP());

  return (
    <Collapse
      defaultOpen={false}
      title={t('MASK.HEADER_TEXT')}
      className={styles.root}
    >
      <Controller
        control={control}
        name="rtfHeadText"
        render={({ field }) => {
          const { value, onChange } = field;
          return rtfTextsInERP.value === true ? (
            <TextEditor value={value} onChange={onChange} rtf />
          ) : (
            <LabeledInput multiline {...field} />
          );
        }}
      />
    </Collapse>
  );
};
