import { createContext } from 'react';

import { Currency } from '@work4all/models/lib/Classes/Currency.entity';

export interface CurrencyExchangeInfoContextValue {
  defaultCurrency: Currency;
  getExchangeRate: (currencyId: number) => number | null;
  getFormattedRate: (currencyId: number) => string;
}

export const CurrencyExchangeInfoContext =
  createContext<CurrencyExchangeInfoContextValue | null>(null);
