import React, { useMemo, useRef } from 'react';

import { W4ADateFormat } from '@work4all/models/lib/additionalEnums/DateFormat.enum';

import { reactRefSetter } from '@work4all/utils';

import { useInputState } from '../date-time-input-picker/hooks/useInputState';
import { useOnChangeTrigger } from '../date-time-input-picker/hooks/useOnChangeTrigger';
import { usePickerVisibilityState } from '../date-time-input-picker/hooks/usePickerVisibilityState';
import { LabeledTimeInputWithDropdown } from '../labeled-time-input';

import { timeUtils } from './timeUtils';
import { ITimeInputPickerProps } from './types';

interface Props extends ITimeInputPickerProps {
  rootClassName?: string;
  onClear?: () => void;
  onDestroyPopover?: () => void;
}

export const TimeInputPicker = React.forwardRef<HTMLInputElement, Props>(
  function TimeInputPicker(props, ref) {
    const inputRef = useRef<HTMLInputElement>();
    const anchorElRef = useRef<HTMLDivElement>();

    const { label, value, defaultValue, ...inputProps } = props;

    const {
      showPicker: showTimePicker,
      onShowPicker: onShowTimePicker,
      onHidePicker: onHideTimePicker,
    } = usePickerVisibilityState();

    const {
      date: time,
      setDate: setTime,
      dateString: timeString,
      ...timeInputProps
    } = useInputState(
      timeUtils.validateTimeString,
      timeUtils.timeToString,
      defaultValue
    );

    const date = useMemo(
      () => defaultValue,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [defaultValue.toFormat('dd.mm.yy')]
    );

    useOnChangeTrigger(
      defaultValue,
      inputRef.current,
      date,
      time,
      W4ADateFormat.DEFAULT
    );
    return (
      <div ref={anchorElRef} className={props.rootClassName}>
        <input
          ref={reactRefSetter(ref, inputRef)}
          {...inputProps}
          hidden
          value={timeUtils.timeToString(value)}
          defaultValue={timeUtils.timeToString(defaultValue)}
        />
        <LabeledTimeInputWithDropdown
          disabled={inputProps.disabled}
          className={inputProps.className}
          showDropdown={showTimePicker}
          onHideDropdown={onHideTimePicker}
          onShowDropdown={onShowTimePicker}
          label={label}
          anchorEl={anchorElRef.current}
          value={timeString}
          onTimeSelect={(time) => {
            setTime(time);
          }}
          onClear={inputProps.onClear}
          onDestroyPopover={inputProps.onDestroyPopover}
          {...timeInputProps}
        />
      </div>
    );
  }
);
