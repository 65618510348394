import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputLetterDocumentFileRelation } from './InputLetterDocumentFileRelation.entity';
import { InputTopicMarkRelation } from './InputTopicMarkRelation.entity';

export class InputDokumentRelation<T extends EMode = EMode.entity> {
  /** Alias: topic */
  topic?: InputTopicMarkRelation<T>;
  /** Alias: fileContent */
  fileContent?: InputLetterDocumentFileRelation<T>;
  /** Alias: fileContents */
  fileContents?: InputLetterDocumentFileRelation<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputDokumentRelation> = {
  topic: {
    alias: 'topic',
  },
  fileContent: {
    alias: 'fileContent',
  },
  fileContents: {
    alias: 'fileContents',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputDokumentRelationEntityDefinition: EntitiyDefinition<InputDokumentRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputDokumentRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
