import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { StatisticCompanyReportColumn } from './StatisticCompanyReportColumn.entity';

export class StatisticCompanyReport<T extends EMode = EMode.entity> {
  /** Alias: start */
  start?: string;
  /** Alias: end */
  end?: string;
  /** Alias: columnCount */
  columnCount?: number;
  /** Alias: columns */
  columns?: StatisticCompanyReportColumn<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<StatisticCompanyReport> = {
  start: {
    alias: 'start',
  },
  end: {
    alias: 'end',
  },
  columnCount: {
    alias: 'columnCount',
  },
  columns: {
    alias: 'columns',
    entity: Entities.statisticCompanyReportColumn,
  },
  __typename: {
    alias: '__typename',
  },
};

export const statisticCompanyReportEntityDefinition: EntitiyDefinition<StatisticCompanyReport> =
  {
    local: {},
    remote: {
      queryName: 'getCompanyReport',
      fragmentName: 'StatisticCompanyReport',
      withPaginationWrapper: false,
      params: [
        { name: 'start', type: 'Date!' },
        { name: 'end', type: 'Date' },
      ],
    },
    fieldDefinitions: fields,
  };
