import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  EmailSignaturePicker,
  EmailSignaturePickerProps,
} from '@work4all/components/lib/components/entity-picker/EmailSignaturePicker';

import { useUser } from '@work4all/data';

import { EMailSignature } from '@work4all/models/lib/Classes/EMailSignature.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { reactRefSetter } from '@work4all/utils';
import { canAddEmailSignature } from '@work4all/utils/lib/permissions';

import { LabeledInput } from '../../containers/mask-overlays/locked-inputs';

import { EntityPickerActions } from './EntityPickerActions';

interface EmailSignaturePickerFieldProps extends EmailSignaturePickerProps {
  label?: string;
  error?: string;
  disabled?: boolean;
}

export const EmailSignaturePickerField = React.forwardRef<
  HTMLDivElement,
  EmailSignaturePickerFieldProps
>(function EmailSignaturePickerField(props, ref) {
  const { error, disabled = false, label, ...pickerProps } = props;
  const { value, onChange, clearable = true } = pickerProps;

  const { t } = useTranslation();
  const user = useUser();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<EntityPickerPopover>(null);

  const field = (
    <LabeledInput
      value={value ? value.name : ''}
      label={label ?? t('INPUTS.SIGNATURE')}
      error={error}
      onClear={
        disabled || !clearable
          ? undefined
          : () => {
              onChange(null);
            }
      }
    />
  );

  if (disabled) {
    return field;
  }

  return (
    <div ref={reactRefSetter(wrapperRef, ref)}>
      <EntityPickerPopover
        ref={popoverRef}
        anchorEl={wrapperRef}
        picker={<EmailSignaturePicker {...pickerProps} />}
        footer={
          <EntityPickerActions
            disableAddButton={!canAddEmailSignature(user)}
            entity={Entities.eMailSignature}
            multiple={false}
            data={props.data}
            onChange={(value) => {
              onChange(value as EMailSignature);
              popoverRef.current?.close();
            }}
          />
        }
      >
        {field}
      </EntityPickerPopover>
    </div>
  );
});
