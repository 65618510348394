import styles from './PdfEditor.module.scss';

import { useEffect, useRef, useState } from 'react';

import { IPDFPreviewProps } from '../pdf-preview/types';

export interface PDFEditorInstance {
  open: (props: { url: string; httpHeaders: Record<string, string> }) => void;
  downloadOrSave: () => { blob: Blob; url: string; filename: string };
}

export const PdfEditor = ({
  url,
  httpHeaders,
  onEditorInit,
  pageTheme = 'auto',
}: IPDFPreviewProps & {
  onEditorInit?: (editor: PDFEditorInstance) => void;
  pageTheme?: 'dark' | 'light' | 'auto';
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [editorInstance, setEditorInstance] = useState(null);

  /** Increase if changes to the viewer have been made and we want to  invalidate the iframe cache*/
  const version = '1.0';
  const frameUrl = '/assets/pdfjs/web/viewer.html?version=' + version;

  useEffect(() => {
    if (editorInstance) {
      if (pageTheme !== 'auto') {
        ['is-light', 'is-dark'].forEach((cls) => {
          iframeRef.current.contentDocument
            ?.querySelector('html')
            .classList.remove(cls);
        });

        iframeRef.current.contentDocument
          ?.querySelector('html')
          .classList.add('is-' + pageTheme);
      }
    }
  }, [editorInstance, pageTheme]);

  useEffect(() => {
    if (editorInstance) {
      onEditorInit?.(editorInstance);
      editorInstance.open({ url, httpHeaders });
    }
  }, [editorInstance, url]);

  useEffect(() => {
    let initialTimeout = null;

    function findInstance() {
      const instance =
        iframeRef.current.contentWindow?.['PDFViewerApplication'];
      if (instance) {
        setEditorInstance(instance);
      } else {
        initialTimeout = setTimeout(() => {
          findInstance();
        }, 100);
      }
    }

    findInstance();

    return () => {
      clearTimeout(initialTimeout);
    };
  }, []);

  return (
    <iframe
      className={styles.iFrame}
      ref={iframeRef}
      src={frameUrl}
      title="PDF Editor"
    ></iframe>
  );
};
