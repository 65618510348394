import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class EMailSignature<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: name */
  name?: string;
  /** Alias: body */
  body?: string;
  /** Alias: bodyTextmarkenAufgeloest */
  bodyTextmarksResolved?: string;

  __typename?: string;
}

const fields: FieldDefinitions<EMailSignature> = {
  id: {
    alias: 'id',
  },
  name: {
    alias: 'name',
  },
  body: {
    alias: 'body',
  },
  bodyTextmarksResolved: {
    alias: 'bodyTextmarkenAufgeloest',
  },
  __typename: {
    alias: '__typename',
  },
};

export const eMailSignatureEntityDefinition: EntitiyDefinition<EMailSignature> =
  {
    local: {},
    remote: {
      queryName: 'getEMailSignaturen',
      fragmentName: 'EMailSignatur',
      withPaginationWrapper: false,
      mutations: {
        upsert: {
          mutationName: 'upsertEMailSignatur',
          args: [
            { name: 'input', type: 'InputEMailSignatur!' },
            { name: 'drySave', type: 'Boolean' },
          ],
        },
      },
    },
    fieldDefinitions: fields,
  };
