import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { BusinessPartner } from './BusinessPartner.entity';
import { CheckListPosition } from './CheckListPosition.entity';
import { Contact } from './Contact.entity';
import { Project } from './Project.entity';
import { TopicSimple } from './TopicSimple.entity';
import { User } from './User.entity';

export class CheckList<T extends EMode = EMode.entity> {
  /** Alias: ansprechpartner */
  contact?: Contact<T>;
  /** Alias: ansprechpartnerCode */
  contactId?: number;
  /** Alias: benutzer */
  user?: User<T>;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: code */
  id?: number;
  /** Alias: datum */
  date?: string;
  /** Alias: geschaeftspartner */
  businessPartner?: BusinessPartner<T>;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: lastModificationDate */
  lastModificationDate?: string;
  /** Alias: mustervorlage */
  isSampleTemplate?: boolean;
  /** Alias: notiz */
  note?: string;
  /** Alias: positionen */
  positionList?: CheckListPosition<T>[];
  /** Alias: projekt */
  project?: Project<T>;
  /** Alias: projektCode */
  projectId?: number;
  /** Alias: projektePlanungCode */
  projectProcessId?: number;
  /** Alias: sdObjMemberCode */
  businessPartnerId?: number;
  /** Alias: sdObjType */
  businessPartnerType?: SdObjType;
  /** Alias: titel */
  title?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: themenZuordnungen */
  topicMarkList?: TopicSimple<T>[];
  /** Alias: done */
  done?: boolean;
  /** Alias: createdByUserCode */
  createdByUserCode?: number;

  __typename?: string;
}

const fields: FieldDefinitions<CheckList> = {
  contact: {
    alias: 'ansprechpartner',
    entity: Entities.contact,
  },
  contactId: {
    alias: 'ansprechpartnerCode',
  },
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  userId: {
    alias: 'benutzerCode',
  },
  id: {
    alias: 'code',
  },
  date: {
    alias: 'datum',
  },
  businessPartner: {
    alias: 'geschaeftspartner',
    entity: Entities.businessPartner,
  },
  insertTime: {
    alias: 'insertTime',
  },
  lastModificationDate: {
    alias: 'lastModificationDate',
  },
  isSampleTemplate: {
    alias: 'mustervorlage',
  },
  note: {
    alias: 'notiz',
  },
  positionList: {
    alias: 'positionen',
    entity: Entities.checkListPosition,
  },
  project: {
    alias: 'projekt',
    entity: Entities.project,
  },
  projectId: {
    alias: 'projektCode',
  },
  projectProcessId: {
    alias: 'projektePlanungCode',
  },
  businessPartnerId: {
    alias: 'sdObjMemberCode',
  },
  businessPartnerType: {
    alias: 'sdObjType',
  },
  title: {
    alias: 'titel',
  },
  updateTime: {
    alias: 'updateTime',
  },
  topicMarkList: {
    alias: 'themenZuordnungen',
    entity: Entities.topicSimple,
  },
  done: {
    alias: 'done',
  },
  createdByUserCode: {
    alias: 'createdByUserCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const checkListEntityDefinition: EntitiyDefinition<CheckList> = {
  local: {},
  remote: {
    queryName: 'getCheckliste',
    fragmentName: 'Checkliste',
    withPaginationWrapper: true,
    mutations: {
      upsert: {
        mutationName: 'upsertCheckliste',
        args: [
          { name: 'input', type: 'InputCheckliste!' },
          { name: 'drySave', type: 'Boolean' },
        ],
      },
    },
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
