import { Box } from '@mui/material';
import React from 'react';

import { Panel } from '../../../../../components/panel/Panel';

import { AddressData } from './components/address-data/AddressData';
import { Communication } from './components/communication/Communication';
import { Organization } from './components/organization/Organization';

export const GeneralTabPanel: React.FC = () => {
  return (
    <Box>
      <Panel>
        <AddressData />
        <Communication />
        <Organization />
      </Panel>
    </Box>
  );
};
