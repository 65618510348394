import 'react-html5-camera-photo/build/css/index.css';
import styles from './UploadImage.module.scss';

import { Paper } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { ImagePreview } from '../../preview/image-preview/ImagePreview';

import { PictureFromFileOrCam } from './components/camera-take-picture/PicFromFileOrCam';
import { TakePictureButton } from './components/take-picture-button/TakePictureButton';
import { UploadImageButton } from './components/upload-image-button/UploadImageButton';
import { IUploadImageProps } from './types';
import { userMediaAvailable } from './utils/userMediaAvailable';

const canTakePickture = userMediaAvailable();

const Upload: React.FC<
  Pick<IUploadImageProps, 'onPictureTaken' | 'onImageChoosen' | 'className'>
> = (props) => {
  const { onPictureTaken, onImageChoosen, className } = props;

  return (
    <PictureFromFileOrCam
      onPictureTaken={onPictureTaken}
      onImageChoosen={onImageChoosen}
    >
      {({
        chooseFileProps: uploadFileProps,
        takePicture,
        chooseFile: uploadFile,
      }) => {
        return (
          <Paper {...uploadFileProps} className={className}>
            <div className={styles.actionsWrapper}>
              <UploadImageButton onClick={uploadFile} />
              {canTakePickture && <TakePictureButton onClick={takePicture} />}
            </div>
          </Paper>
        );
      }}
    </PictureFromFileOrCam>
  );
};

const Result: React.FC<
  Pick<IUploadImageProps, 'dataUri' | 'onImageClick' | 'className'>
> = (props) => {
  const { dataUri, onImageClick, ...rest } = props;

  return (
    <Paper onClick={onImageClick} {...rest}>
      <ImagePreview url={dataUri} />
    </Paper>
  );
};

export const UploadImage: React.FC<IUploadImageProps> = (props) => {
  const className = clsx(styles.root, props.className);
  const {
    dataUri,
    onImageChoosen: onImageUploaded,
    onPictureTaken,
    onImageClick,
  } = props;

  if (props.dataUri) {
    return (
      <Result
        className={className}
        dataUri={dataUri}
        onImageClick={onImageClick}
      />
    );
  }

  return (
    <Upload
      className={clsx(className, {
        [styles.disabled]: props.disabled === true,
      })}
      onImageChoosen={onImageUploaded}
      onPictureTaken={onPictureTaken}
    />
  );
};
