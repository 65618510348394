export enum CommonActionTypes {
  NOOP = '[Common] No-Op',
}

export interface NoOpAction {
  type: CommonActionTypes.NOOP;
}

export const noop = (): NoOpAction => ({
  type: CommonActionTypes.NOOP,
});
