import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { FileEntity } from './FileEntity.entity';
import { User } from './User.entity';

export class TaskAttachment<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: datum */
  date?: string;
  /** Alias: filename */
  fileName?: string;
  /** Alias: notiz */
  note?: string;
  /** Alias: objCode */
  objId?: number;
  /** Alias: fileInfos */
  fileInfos?: FileEntity<T>;
  /** Alias: benutzer */
  user?: User<T>;

  __typename?: string;
}

const fields: FieldDefinitions<TaskAttachment> = {
  id: {
    alias: 'code',
  },
  userId: {
    alias: 'benutzerCode',
  },
  date: {
    alias: 'datum',
  },
  fileName: {
    alias: 'filename',
  },
  note: {
    alias: 'notiz',
  },
  objId: {
    alias: 'objCode',
  },
  fileInfos: {
    alias: 'fileInfos',
    entity: Entities.fileEntity,
  },
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  __typename: {
    alias: '__typename',
  },
};

export const taskAttachmentEntityDefinition: EntitiyDefinition<TaskAttachment> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'AufgabeAnhang',
      withPaginationWrapper: false,
      mutations: {
        upsert: {
          mutationName: 'upsertAufgabeAnhang',
          args: [
            { name: 'input', type: 'InputAufgabeAnhang!' },
            { name: 'drySave', type: 'Boolean' },
          ],
        },
      },
    },
    fieldDefinitions: fields,
  };
