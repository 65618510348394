import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputKunde<T extends EMode = EMode.entity> {
  /** Alias: hauptansprechpartnerCode */
  hauptansprechpartnerCode?: number;
  /** Alias: zahlungsCode */
  zahlungsCode?: number;
  /** Alias: tourCode */
  tourCode?: number;
  /** Alias: teilrechnungslogikCode */
  teilrechnungslogikCode?: number;
  /** Alias: rabatt2 */
  rabatt2?: number;
  /** Alias: rabatt3 */
  rabatt3?: number;
  /** Alias: rabatt4 */
  rabatt4?: number;
  /** Alias: outlookAdresse */
  outlookAdresse?: number;
  /** Alias: shopAnsichtGrId */
  shopAnsichtGrId?: string;
  /** Alias: shopDefinitionId */
  shopDefinitionId?: string;
  /** Alias: shopProjektGrCode */
  shopProjektGrCode?: number;
  /** Alias: eRechnungFormat */
  eRechnungFormat?: any;
  /** Alias: shopLizenzen */
  shopLizenzen?: number;
  /** Alias: isLieferadresse */
  isLieferadresse?: boolean;
  /** Alias: firma1 */
  firma1?: string;
  /** Alias: mahnsperre */
  mahnsperre?: boolean;
  /** Alias: adresserweiterung */
  adresserweiterung?: string;
  /** Alias: adresseWirdGepflegtBeiKundeCode */
  adresseWirdGepflegtBeiKundeCode?: number;
  /** Alias: anredeCode */
  anredeCode?: number;
  /** Alias: bankleitzahl */
  bankleitzahl?: string;
  /** Alias: bankverbindung */
  bankverbindung?: string;
  /** Alias: bIC */
  bIC?: string;
  /** Alias: briefAnrede */
  briefAnrede?: string;
  /** Alias: bruttorechnung */
  bruttorechnung?: boolean;
  /** Alias: code */
  code?: number;
  /** Alias: dB */
  dB?: number;
  /** Alias: doublettenCheck_NichtMehrAnzeigen */
  doublettenCheck_NichtMehrAnzeigen?: number;
  /** Alias: dTANichtZusammenfassen */
  dTANichtZusammenfassen?: boolean;
  /** Alias: eMail */
  eMail?: string;
  /** Alias: eMail2 */
  eMail2?: string;
  /** Alias: entfernung */
  entfernung?: number;
  /** Alias: erloschen */
  erloschen?: number;
  /** Alias: eRPFreigabepflichtDeaktivieren */
  eRPFreigabepflichtDeaktivieren?: boolean;
  /** Alias: erstkontakt */
  erstkontakt?: string;
  /** Alias: personErstkontakt */
  personErstkontakt?: string;
  /** Alias: firma2 */
  firma2?: string;
  /** Alias: firma3 */
  firma3?: string;
  /** Alias: funktion */
  funktion?: string;
  /** Alias: geburtsdatum */
  geburtsdatum?: string;
  /** Alias: geburtstagJahr */
  geburtstagJahr?: number;
  /** Alias: geburtstagMonat */
  geburtstagMonat?: number;
  /** Alias: geburtstagTag */
  geburtstagTag?: number;
  /** Alias: gesperrt */
  gesperrt?: number;
  /** Alias: grCode */
  grCode?: number;
  /** Alias: hausbankCode */
  hausbankCode?: number;
  /** Alias: iBAN */
  iBAN?: string;
  /** Alias: interNet */
  interNet?: string;
  /** Alias: intrastatNichtberuecksichtigen */
  intrastatNichtberuecksichtigen?: number;
  /** Alias: keineStaffelrabatte */
  keineStaffelrabatte?: boolean;
  /** Alias: kKontaktCode */
  kKontaktCode?: number;
  /** Alias: kontoinhaber */
  kontoinhaber?: string;
  /** Alias: kontonummer */
  kontonummer?: string;
  /** Alias: konzernkennzeichen */
  konzernkennzeichen?: string;
  /** Alias: kreditlimit */
  kreditlimit?: number;
  /** Alias: kundennr */
  kundennr?: string;
  /** Alias: kuerzel */
  kuerzel?: string;
  /** Alias: lastModificationDate */
  lastModificationDate?: string;
  /** Alias: letzteAenderung */
  letzteAenderung?: string;
  /** Alias: lieferungsArtCode */
  lieferungsArtCode?: number;
  /** Alias: mahntoleranz */
  mahntoleranz?: number;
  /** Alias: mailSperre */
  mailSperre?: number;
  /** Alias: mark */
  mark?: string;
  /** Alias: name */
  name?: string;
  /** Alias: namenserweiterung */
  namenserweiterung?: string;
  /** Alias: nebenadrAPCode1 */
  nebenadrAPCode1?: number;
  /** Alias: nebenadrAPCode2 */
  nebenadrAPCode2?: number;
  /** Alias: nebenadrAPCode3 */
  nebenadrAPCode3?: number;
  /** Alias: nebenAdrCode1 */
  nebenAdrCode1?: number;
  /** Alias: nebenAdrCode2 */
  nebenAdrCode2?: number;
  /** Alias: nebenAdrCode3 */
  nebenAdrCode3?: number;
  /** Alias: nebenAdrType1 */
  nebenAdrType1?: SdObjType;
  /** Alias: nebenAdrType2 */
  nebenAdrType2?: SdObjType;
  /** Alias: nebenAdrType3 */
  nebenAdrType3?: SdObjType;
  /** Alias: notiz */
  notiz?: string;
  /** Alias: notizRTF */
  notizRTF?: string;
  /** Alias: nummer */
  nummer?: number;
  /** Alias: ort */
  ort?: string;
  /** Alias: ortPostfach */
  ortPostfach?: string;
  /** Alias: plz */
  plz?: string;
  /** Alias: pLZPostfach */
  pLZPostfach?: string;
  /** Alias: postfach */
  postfach?: string;
  /** Alias: preisgruppe */
  preisgruppe?: number;
  /** Alias: privatkunde */
  privatkunde?: boolean;
  /** Alias: provision */
  provision?: number;
  /** Alias: rabattvorschlag */
  rabattvorschlag?: number;
  /** Alias: rechnungsadresseEMail */
  rechnungsadresseEMail?: string;
  /** Alias: rechnungsform */
  rechnungsform?: any;
  /** Alias: senderName */
  senderName?: string;
  /** Alias: serienbriefSperre */
  serienbriefSperre?: number;
  /** Alias: skonto */
  skonto?: number;
  /** Alias: skonto2 */
  skonto2?: number;
  /** Alias: skonto2Frist */
  skonto2Frist?: number;
  /** Alias: skontofrist */
  skontofrist?: number;
  /** Alias: sprachCode */
  sprachCode?: number;
  /** Alias: staat */
  staat?: string;
  /** Alias: standardkonto */
  standardkonto?: number;
  /** Alias: steuer */
  steuer?: any;
  /** Alias: steuerschluesselCode */
  steuerschluesselCode?: number;
  /** Alias: strasse */
  strasse?: string;
  /** Alias: telefax */
  telefax?: string;
  /** Alias: telefon */
  telefon?: string;
  /** Alias: telefon2 */
  telefon2?: string;
  /** Alias: titelerweiterung */
  titelerweiterung?: string;
  /** Alias: uSTIDNR */
  uSTIDNR?: string;
  /** Alias: vertreter2Code */
  vertreter2Code?: number;
  /** Alias: vertreterCode */
  vertreterCode?: number;
  /** Alias: vertreterSDObjMemberCode */
  vertreterSDObjMemberCode?: number;
  /** Alias: vertreterSDObjType */
  vertreterSDObjType?: number;
  /** Alias: vorwahl */
  vorwahl?: string;
  /** Alias: waehrungCode */
  waehrungCode?: number;
  /** Alias: zahlungsfrist */
  zahlungsfrist?: number;
  /** Alias: mobilfunk */
  mobilfunk?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];
  /** Alias: letzterkontakt */
  letzterkontakt?: string;
  /** Alias: personLetzterkontakt */
  personLetzterkontakt?: string;
  /** Alias: firmenAnrede */
  firmenAnrede?: string;
  /** Alias: createdByUserCode */
  createdByUserCode?: number;

  __typename?: string;
}

const fields: FieldDefinitions<InputKunde> = {
  hauptansprechpartnerCode: {
    alias: 'hauptansprechpartnerCode',
  },
  zahlungsCode: {
    alias: 'zahlungsCode',
  },
  tourCode: {
    alias: 'tourCode',
  },
  teilrechnungslogikCode: {
    alias: 'teilrechnungslogikCode',
  },
  rabatt2: {
    alias: 'rabatt2',
  },
  rabatt3: {
    alias: 'rabatt3',
  },
  rabatt4: {
    alias: 'rabatt4',
  },
  outlookAdresse: {
    alias: 'outlookAdresse',
  },
  shopAnsichtGrId: {
    alias: 'shopAnsichtGrId',
  },
  shopDefinitionId: {
    alias: 'shopDefinitionId',
  },
  shopProjektGrCode: {
    alias: 'shopProjektGrCode',
  },
  eRechnungFormat: {
    alias: 'eRechnungFormat',
  },
  shopLizenzen: {
    alias: 'shopLizenzen',
  },
  isLieferadresse: {
    alias: 'isLieferadresse',
  },
  firma1: {
    alias: 'firma1',
  },
  mahnsperre: {
    alias: 'mahnsperre',
  },
  adresserweiterung: {
    alias: 'adresserweiterung',
  },
  adresseWirdGepflegtBeiKundeCode: {
    alias: 'adresseWirdGepflegtBeiKundeCode',
  },
  anredeCode: {
    alias: 'anredeCode',
  },
  bankleitzahl: {
    alias: 'bankleitzahl',
  },
  bankverbindung: {
    alias: 'bankverbindung',
  },
  bIC: {
    alias: 'bIC',
  },
  briefAnrede: {
    alias: 'briefAnrede',
  },
  bruttorechnung: {
    alias: 'bruttorechnung',
  },
  code: {
    alias: 'code',
  },
  dB: {
    alias: 'dB',
  },
  doublettenCheck_NichtMehrAnzeigen: {
    alias: 'doublettenCheck_NichtMehrAnzeigen',
  },
  dTANichtZusammenfassen: {
    alias: 'dTANichtZusammenfassen',
  },
  eMail: {
    alias: 'eMail',
  },
  eMail2: {
    alias: 'eMail2',
  },
  entfernung: {
    alias: 'entfernung',
  },
  erloschen: {
    alias: 'erloschen',
  },
  eRPFreigabepflichtDeaktivieren: {
    alias: 'eRPFreigabepflichtDeaktivieren',
  },
  erstkontakt: {
    alias: 'erstkontakt',
  },
  personErstkontakt: {
    alias: 'personErstkontakt',
  },
  firma2: {
    alias: 'firma2',
  },
  firma3: {
    alias: 'firma3',
  },
  funktion: {
    alias: 'funktion',
  },
  geburtsdatum: {
    alias: 'geburtsdatum',
  },
  geburtstagJahr: {
    alias: 'geburtstagJahr',
  },
  geburtstagMonat: {
    alias: 'geburtstagMonat',
  },
  geburtstagTag: {
    alias: 'geburtstagTag',
  },
  gesperrt: {
    alias: 'gesperrt',
  },
  grCode: {
    alias: 'grCode',
  },
  hausbankCode: {
    alias: 'hausbankCode',
  },
  iBAN: {
    alias: 'iBAN',
  },
  interNet: {
    alias: 'interNet',
  },
  intrastatNichtberuecksichtigen: {
    alias: 'intrastatNichtberuecksichtigen',
  },
  keineStaffelrabatte: {
    alias: 'keineStaffelrabatte',
  },
  kKontaktCode: {
    alias: 'kKontaktCode',
  },
  kontoinhaber: {
    alias: 'kontoinhaber',
  },
  kontonummer: {
    alias: 'kontonummer',
  },
  konzernkennzeichen: {
    alias: 'konzernkennzeichen',
  },
  kreditlimit: {
    alias: 'kreditlimit',
  },
  kundennr: {
    alias: 'kundennr',
  },
  kuerzel: {
    alias: 'kuerzel',
  },
  lastModificationDate: {
    alias: 'lastModificationDate',
  },
  letzteAenderung: {
    alias: 'letzteAenderung',
  },
  lieferungsArtCode: {
    alias: 'lieferungsArtCode',
  },
  mahntoleranz: {
    alias: 'mahntoleranz',
  },
  mailSperre: {
    alias: 'mailSperre',
  },
  mark: {
    alias: 'mark',
  },
  name: {
    alias: 'name',
  },
  namenserweiterung: {
    alias: 'namenserweiterung',
  },
  nebenadrAPCode1: {
    alias: 'nebenadrAPCode1',
  },
  nebenadrAPCode2: {
    alias: 'nebenadrAPCode2',
  },
  nebenadrAPCode3: {
    alias: 'nebenadrAPCode3',
  },
  nebenAdrCode1: {
    alias: 'nebenAdrCode1',
  },
  nebenAdrCode2: {
    alias: 'nebenAdrCode2',
  },
  nebenAdrCode3: {
    alias: 'nebenAdrCode3',
  },
  nebenAdrType1: {
    alias: 'nebenAdrType1',
  },
  nebenAdrType2: {
    alias: 'nebenAdrType2',
  },
  nebenAdrType3: {
    alias: 'nebenAdrType3',
  },
  notiz: {
    alias: 'notiz',
  },
  notizRTF: {
    alias: 'notizRTF',
  },
  nummer: {
    alias: 'nummer',
  },
  ort: {
    alias: 'ort',
  },
  ortPostfach: {
    alias: 'ortPostfach',
  },
  plz: {
    alias: 'plz',
  },
  pLZPostfach: {
    alias: 'pLZPostfach',
  },
  postfach: {
    alias: 'postfach',
  },
  preisgruppe: {
    alias: 'preisgruppe',
  },
  privatkunde: {
    alias: 'privatkunde',
  },
  provision: {
    alias: 'provision',
  },
  rabattvorschlag: {
    alias: 'rabattvorschlag',
  },
  rechnungsadresseEMail: {
    alias: 'rechnungsadresseEMail',
  },
  rechnungsform: {
    alias: 'rechnungsform',
  },
  senderName: {
    alias: 'senderName',
  },
  serienbriefSperre: {
    alias: 'serienbriefSperre',
  },
  skonto: {
    alias: 'skonto',
  },
  skonto2: {
    alias: 'skonto2',
  },
  skonto2Frist: {
    alias: 'skonto2Frist',
  },
  skontofrist: {
    alias: 'skontofrist',
  },
  sprachCode: {
    alias: 'sprachCode',
  },
  staat: {
    alias: 'staat',
  },
  standardkonto: {
    alias: 'standardkonto',
  },
  steuer: {
    alias: 'steuer',
  },
  steuerschluesselCode: {
    alias: 'steuerschluesselCode',
  },
  strasse: {
    alias: 'strasse',
  },
  telefax: {
    alias: 'telefax',
  },
  telefon: {
    alias: 'telefon',
  },
  telefon2: {
    alias: 'telefon2',
  },
  titelerweiterung: {
    alias: 'titelerweiterung',
  },
  uSTIDNR: {
    alias: 'uSTIDNR',
  },
  vertreter2Code: {
    alias: 'vertreter2Code',
  },
  vertreterCode: {
    alias: 'vertreterCode',
  },
  vertreterSDObjMemberCode: {
    alias: 'vertreterSDObjMemberCode',
  },
  vertreterSDObjType: {
    alias: 'vertreterSDObjType',
  },
  vorwahl: {
    alias: 'vorwahl',
  },
  waehrungCode: {
    alias: 'waehrungCode',
  },
  zahlungsfrist: {
    alias: 'zahlungsfrist',
  },
  mobilfunk: {
    alias: 'mobilfunk',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customFields: {
    alias: 'customFields',
  },
  letzterkontakt: {
    alias: 'letzterkontakt',
  },
  personLetzterkontakt: {
    alias: 'personLetzterkontakt',
  },
  firmenAnrede: {
    alias: 'firmenAnrede',
  },
  createdByUserCode: {
    alias: 'createdByUserCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputKundeEntityDefinition: EntitiyDefinition<InputKunde> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'InputKunde',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
