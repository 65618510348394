import { Typography } from '@mui/material';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type IRelationTypePickerProps<TMultiple extends boolean> = IPickerProps<
  LookUp,
  TMultiple
>;

export function RelationTypePicker<TMultiple extends boolean>(
  props: IRelationTypePickerProps<TMultiple>
) {
  const { data, ...rest } = props;
  const requestData: DataRequest = useMemo(() => {
    return {
      entity: Entities.lookUp,
      data: RelationTypeFields,
      completeDataResponse: true,
      vars: {
        type: 'ADRESSVERKNUEPFUNG',
      },
    };
  }, []);
  const response = useDataProvider(requestData);

  const dataset = useMemo(() => {
    return {
      total: response.data.length,
      loading: false,
      fetchMore: () => Promise.resolve(),
      refetch: () => Promise.resolve(null),
      data: response.data,
    };
  }, [response]);

  return (
    <ListEntityPicker
      entity={Entities.lookUp}
      fixedDataSet={dataset}
      data={data}
      filterBy="name"
      sortBy="name"
      {...rest}
      renderItemContent={(lookupval) => (
        <Tooltip title={lookupval.name}>
          <Typography variant="body2" noWrap>
            {lookupval.name}
          </Typography>
        </Tooltip>
      )}
    />
  );
}

const RelationTypeFields: LookUp = { id: null, name: null };
