import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  CostCenterPicker,
  ICostCenterProps,
} from '@work4all/components/lib/components/entity-picker/costCenter-Picker/CostCenterPicker';

import { reactRefSetter } from '@work4all/utils';

import { LabeledInput } from '../../containers/mask-overlays/locked-inputs';

interface CostCenterPickerFieldProps
  extends Omit<ICostCenterProps<false>, 'multiple'> {
  error?: string;
  label?: string;
}

export const CostCenterPickerField = React.forwardRef<
  HTMLDivElement,
  CostCenterPickerFieldProps
>(function CostCenterPickerField(props, ref) {
  const { error, label, ...pickerProps } = props;
  const { value, onChange } = pickerProps;

  const { t } = useTranslation();

  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={reactRefSetter(wrapperRef, ref)}>
      <EntityPickerPopover
        anchorEl={wrapperRef}
        picker={<CostCenterPicker {...pickerProps} multiple={false} />}
      >
        <LabeledInput
          value={value?.name ?? ''}
          label={label || t('COMMON.COST_CENTER')}
          error={error}
          onClear={() => {
            onChange(null);
          }}
        />
      </EntityPickerPopover>
    </div>
  );
});
