import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { TravelExpenses } from '@work4all/models/lib/Classes/TravelExpenses.entity';

import { TravelReceiptsStatus } from '../../entity-picker/travel-receipts-status-picker/TravelReceiptsStatusPicker';

export function useGetTravelReceiptsStatus() {
  const { t } = useTranslation();

  return useCallback(
    (
      travelExpenses: TravelExpenses
    ): {
      key: TravelReceiptsStatus;
      label: string;
    } => {
      if (!travelExpenses?.closedByuserid || !travelExpenses)
        return {
          key: 'parked',
          label: t('COMMON.TRAVELRECEIPTS_STATUS_PARKED'),
        };

      if (!travelExpenses?.insertionREDate)
        return {
          key: 'closed',
          label: t('COMMON.TRAVELRECEIPTS_STATUS_CLOSED'),
        };

      return {
        key: 'booked',
        label: t('COMMON.TRAVELRECEIPTS_STATUS_BOOKED'),
      };
    },
    [t]
  );
}
