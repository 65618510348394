import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SettingsIcon } from '@work4all/assets/icons/settings3.svg';

import {
  EntityPickerActionBar,
  EntityPickerActionButton,
} from '@work4all/components/lib/components/entity-picker/components';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay/NavigationOverlay';

import { SalesOpportunityRatingStatusSettings } from '../../../../containers/mask-overlays/mask-overlay/views/sales-opportunities/components/template-settings/SalesOpportunityRatingStatusSettings';

export const SettingsPickerFooter = () => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);

  return (
    <EntityPickerActionBar>
      <EntityPickerActionButton
        startIcon={<SettingsIcon />}
        onClick={() => {
          setOpen(true);
        }}
      >
        {t('COMMON.SETTINGS')}
      </EntityPickerActionButton>

      <NavigationOverlay
        open={isOpen}
        initialView={{
          view: <SalesOpportunityRatingStatusSettings />,
          title: t('COMMON.SETTINGS'),
        }}
        close={() => {
          setOpen(false);
        }}
      />
    </EntityPickerActionBar>
  );
};
