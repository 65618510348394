import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SettingsIcon } from '@work4all/assets/icons/settings3.svg';

import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { settings, useSetting } from '../../../settings';
import { CalendarSettings } from '../../mask-overlays/mask-overlay/views/settings/calendar-settings/CalendarSettings';
import { MaskTabContext } from '../../mask-overlays/mask-tabs';
import { CalendarProps } from '../types';

import { AppointmentStatesSettings } from './AppointmentStatesDialog';
import { CalendarGroupModeSwitch } from './CalendarGroupModeSwitch';
import { CalendarTimeRangeSwitch } from './CalendarTimeRangeSwitch';

export type CalendarMobileMenuProps = Pick<
  CalendarProps,
  | 'appointmentStates'
  | 'onAppointmentStatesChange'
  | 'range'
  | 'onRangeChange'
  | 'groupMode'
  | 'onGroupModeChange'
>;

export const CalendarMobileMenu = (props: CalendarMobileMenuProps) => {
  const {
    appointmentStates,
    onAppointmentStatesChange,
    range,
    onRangeChange,
    groupMode,
    onGroupModeChange,
  } = props;

  const { t } = useTranslation();

  const [settingsOpen, setSettingsOpen] = useState(false);

  const openSettings = useCallback(() => {
    setSettingsOpen(true);
  }, []);

  const onSettingsClose = useCallback(() => {
    setSettingsOpen(false);
  }, []);

  const { value: calendarCardColorBy } = useSetting(
    settings.calendarAppointmentCardColorBy()
  );

  return (
    <MaskTabContext defaultValue="personal">
      <NavigationOverlay
        open={settingsOpen}
        initialView={{
          view: <CalendarSettings amplitudeEntryPoint="Calendar" />,
        }}
        close={onSettingsClose}
      />
      <Divider title={t('COMMON.GENERAL')} horizontalPadding />
      <MenuItem
        onClick={() => {
          openSettings();
        }}
      >
        <ListItemIcon>{<SettingsIcon />}</ListItemIcon>
        <ListItemText primary={t(`COMMON.SETTINGS`)} />
      </MenuItem>
      <Divider title={t('CALENDAR.VIEWMODE.TITLE')} horizontalPadding />
      <CalendarGroupModeSwitch
        layout="list"
        groupMode={groupMode}
        onGroupModeChange={onGroupModeChange}
        range={range}
      />
      <Divider title={t('CALENDAR.TIMERANGE.TITLE')} horizontalPadding />
      <CalendarTimeRangeSwitch
        layout="list"
        groupMode={groupMode}
        onChange={onRangeChange}
        value={range}
      />
      <Divider title={t('COMMON.APPOINTMENTTYPES')} horizontalPadding />
      <AppointmentStatesSettings
        selectedAppointmentStates={appointmentStates}
        onSelectedAppointmentStatesChange={onAppointmentStatesChange}
        calendarCardColorBy={calendarCardColorBy}
      />
    </MaskTabContext>
  );
};
