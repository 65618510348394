import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { ProjectAccessGroup } from '@work4all/models/lib/Classes/ProjectAccessGroup.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export const ALL_QUERY = 9999;

export const useProjectAccessRights = (skip: boolean = false) => {
  const requestData = useMemo(() => {
    return {
      sort: [{ field: 'name', direction: SortDirection.ASCENDING }],
      entity: Entities.projectAccessGroup,
      data: {
        id: null,
        name: null,
        users: [
          {
            id: null,
            displayName: null,
          },
        ],
      },
    };
  }, []);
  return useDataProvider<ProjectAccessGroup>(requestData, skip, ALL_QUERY);
};
