import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FixedDataPicker } from '../fixed-data-picker/FixedDataPicker';

export type TravelReceiptsStatus = 'parked' | 'closed' | 'booked' | null;

export type ITravelReceiptsStatusPickerProps = {
  value: TravelReceiptsStatus;
  onChange: (value: TravelReceiptsStatus) => void;
};

export function TravelReceiptsStatusPicker(
  props: ITravelReceiptsStatusPickerProps
) {
  const { value: valueProp, onChange } = props;

  const { t } = useTranslation();

  const dataSet = useMemo(() => {
    return [
      {
        id: 'parked',
        name: t('COMMON.TRAVELRECEIPTS_STATUS_PARKED'),
      },
      {
        id: 'closed',
        name: t('COMMON.TRAVELRECEIPTS_STATUS_CLOSED'),
      },
      {
        id: 'booked',
        name: t('COMMON.TRAVELRECEIPTS_STATUS_BOOKED'),
      },
    ];
  }, [t]);

  const value = useMemo(() => {
    return dataSet.find((item) => item.id === String(valueProp));
  }, [valueProp, dataSet]);

  return (
    <FixedDataPicker
      multiple={false}
      value={value}
      dataSet={dataSet}
      clearable={false}
      onChange={(value) => {
        onChange(value.id as TravelReceiptsStatus);
      }}
    />
  );
}
