import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class PriceGroup<T extends EMode = EMode.entity> {
  /** Alias: bruttopreis */
  isGrossPrice?: boolean;
  /** Alias: code */
  id?: number;
  /** Alias: name */
  name?: string;
  /** Alias: waehrungCode */
  currencyId?: number;

  __typename?: string;
}

const fields: FieldDefinitions<PriceGroup> = {
  isGrossPrice: {
    alias: 'bruttopreis',
  },
  id: {
    alias: 'code',
  },
  name: {
    alias: 'name',
  },
  currencyId: {
    alias: 'waehrungCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const priceGroupEntityDefinition: EntitiyDefinition<PriceGroup> = {
  local: {},
  remote: {
    queryName: 'getPreisgruppe',
    fragmentName: 'Preisgruppe',
    withPaginationWrapper: false,
    params: [{ name: 'codes', type: '[Int]' }],
  },
  fieldDefinitions: fields,
};
