import styles from './styles.module.scss';

import React, { useState } from 'react';

import { useFavoriteLinks } from '../../../../containers/more/data/favorite-links/favorite-links-context';
import { FavoriteLinksFolderContextMenu } from '../context-menu/FavoriteLinksFolderContextMenu';

import {
  IPopoverListFolderItemProps,
  PopoverListFolderItem,
} from './PopoverListFolderItem';

export type IPopoverListFolderItemWrapperProps = Omit<
  IPopoverListFolderItemProps,
  'onContextMenu'
>;

export function PopoverListFolderItemWrapper({
  folder,
  ...otherProps
}: IPopoverListFolderItemWrapperProps) {
  const [isEditing, setEditing] = useState(false);

  const { renameFavoriteLinksFolder, removeFavoriteLinksFolder } =
    useFavoriteLinks();

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
    const newName = event.currentTarget.value;
    renameFavoriteLinksFolder({ id: folder.id, name: newName });
    setEditing(false);
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.key === 'Enter') {
      const newName = event.currentTarget.value;
      renameFavoriteLinksFolder({ id: folder.id, name: newName });
      setEditing(false);
    }
  };

  const handleRenameFolder = () => {
    setEditing(true);
  };

  const handleRemoveFolder = () => {
    removeFavoriteLinksFolder(folder.id);
  };

  return isEditing ? (
    <input
      autoFocus
      className={styles.popoverFolderItemInput}
      defaultValue={folder.name}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
    />
  ) : (
    <FavoriteLinksFolderContextMenu
      onRename={handleRenameFolder}
      onRemove={handleRemoveFolder}
    >
      {({ onContextMenu }) => (
        <PopoverListFolderItem
          folder={folder}
          onContextMenu={onContextMenu}
          {...otherProps}
        />
      )}
    </FavoriteLinksFolderContextMenu>
  );
}
