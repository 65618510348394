import { get } from 'lodash';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { reactRefSetter } from '@work4all/utils';

import { useMaskTabPanelContext } from '../mask-tabs/MaskTabPanelContext';

export function useFormContextPlus<T>() {
  const form = useFormContext<T>();

  const { register, formState } = form;

  const tabContext = useMaskTabPanelContext({ optional: true });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const context = form.control._options.context as { schema: any } | undefined;
  const schema = context?.schema;

  const registerPlus = useCallback<typeof register>(
    (name, options) => {
      const { errors } = formState;
      const required = !!schema?.required?.includes(name);
      const defaultValue = get(form.control._defaultValues, name);
      const valueAsNumber = ['integer', 'number'].includes(
        schema?.properties[name]?.type
      );

      if (tabContext === null) {
        return {
          ...register(name, {
            valueAsNumber,
            ...options,
          }),
          required,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          error: errors[name as any]?.message,
          defaultValue,
        };
      }

      const result = register(name, {
        valueAsNumber,
        ...options,
      });

      const ourRef: React.RefCallback<Element> = (element) => {
        if (element !== null) {
          tabContext.register(name);
        } else {
          tabContext.unregister(name);
        }
      };

      return {
        ...result,
        ref: reactRefSetter(result.ref, ourRef),
        required,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        error: errors[name as any]?.message,
        defaultValue,
      };
    },
    // There is no reason to add default values to the dependencies because they
    // only matter on the initial render. Changing them after should not have
    // any effect anyway.

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [schema, formState, tabContext, register]
  );

  return {
    ...form,
    register: registerPlus,
  };
}
