import styles from './Grid.module.scss';

import clsx from 'clsx';
import React, {
  CSSProperties,
  memo,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { VariableSizeGrid, VariableSizeList as List } from 'react-window';

import { remToPx, useRemToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { reactRefSetter } from '@work4all/utils';
import { useForceUpdate } from '@work4all/utils/lib/hooks/use-force-update';

import { useSyncScroll } from '../table/hooks/use-sync-scroll';

export interface GridColumn {
  id: string | number;
  headerName: string;
}

export interface GridProps<T extends GridCellValue<T['cellValue']>>
  extends BaseGridProps {
  grid: T[][];
  columns: GridColumn[];
  Cell: React.ComponentType<GridCellProps<T>>;
  onItemClicked: GridCellProps<never>['onItemClicked'];
  onColumnClicked: (colId: number | string) => void;
  selectedRows?: { id: number | string }[];
  selectedColumns?: { id: number | string }[];
}

export interface GridCellProps<T> extends GridCellValue<T> {
  columnIndex: number;
  rowIndex: number;
  style: CSSProperties;
  onItemClicked: (columnIndex: number, rowIndex: number) => void;
  isCellSelected: boolean;
}

export interface BaseGridProps {
  scrollRef?: React.MutableRefObject<HTMLDivElement>;
}

/**
 * Grid for column & row virtualization.
 * This is 1st implementation and based on that we can make more generic.
 * Support only 1 cell type but could be extended
 */
export const Grid = memo(function Grid<T extends GridCellValue<T>>(
  props: GridProps<T['cellValue']>
) {
  const containerRef = useRef<HTMLDivElement>();
  const resizeObserver = useRef<ResizeObserver>();

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const htmlDiv = containerRef.current;
    if (htmlDiv) {
      const observer = new ResizeObserver(() => {
        forceUpdate();
      });
      observer.observe(htmlDiv);
      resizeObserver.current = observer;
      return () => {
        resizeObserver.current.unobserve(htmlDiv);
      };
    }
  }, [forceUpdate]);

  return (
    <div className={styles['relative-container']}>
      <div ref={containerRef} className={styles['resizable-container']}>
        {containerRef.current && (
          <InnerGrid
            {...props}
            width={containerRef.current?.clientWidth}
            height={containerRef.current?.clientHeight}
            rowHeightRem="3rem"
            columnWidthRem="3rem"
          />
        )}
      </div>
    </div>
  );
});

export interface GridCellValue<T> {
  cellValue: T;
  disabled: boolean;
  rowId: string | number;
  colId: string | number;
}

interface InnerGridProps<T extends GridCellValue<T>> extends GridProps<T> {
  width: number;
  height: number;
  headerHeight?: number;
  columnWidth?: number;
  rowHeight?: number;

  rowHeightRem?: string;
  columnWidthRem?: string;
}

const DEFAULT_GRID_CELL_SIZE = 40;
export function InnerGrid<T extends GridCellValue<T>>(
  props: InnerGridProps<T>
) {
  const {
    columns,
    grid,
    width,
    height,
    headerHeight = remToPx(7),
    columnWidthRem,
    rowHeightRem,
    scrollRef,
    Cell,
    onItemClicked,
    onColumnClicked,
    selectedRows,
    selectedColumns,
  } = props;

  const parsedColumnWidth = useRemToPx(columnWidthRem);
  const parsedRowHeight = useRemToPx(rowHeightRem);

  const rowHeight = rowHeightRem
    ? parsedRowHeight
    : props.rowHeight ?? DEFAULT_GRID_CELL_SIZE;
  const columnWidth = columnWidthRem
    ? parsedColumnWidth
    : props.columnWidth ?? DEFAULT_GRID_CELL_SIZE;

  const RenderCell = useCallback(
    (props) => {
      return (
        <Cell
          {...props}
          {...grid[props.columnIndex][props.rowIndex]}
          onItemClicked={onItemClicked}
          isCellSelected={
            selectedRows.some(
              (x) =>
                x.id.toString() ===
                grid[props.columnIndex][props.rowIndex].rowId.toString()
            ) ||
            selectedColumns.some(
              (x) =>
                x.id.toString() ===
                grid[props.columnIndex][props.rowIndex].colId.toString()
            )
          }
        />
      );
    },
    [grid, Cell, onItemClicked, selectedRows, selectedColumns]
  );

  const Column = useCallback(
    ({ index, style }) => (
      <div style={style} className={styles['column']}>
        <div
          className={clsx(styles['column-45'], {
            [styles['column-45-selected']]: selectedColumns.some(
              (x) => x.id === columns[index].id
            ),
          })}
          onClick={() => onColumnClicked(columns[index].id)}
          style={{ borderLeft: index === 0 ? 'none' : undefined }}
        >
          <span className={styles['text-rotate']}>
            {columns[index].headerName}
          </span>
        </div>
      </div>
    ),
    [columns, onColumnClicked, selectedColumns]
  );

  const listRef = useRef<HTMLDivElement>();
  const gridRef = useRef<HTMLDivElement>();

  useSyncScroll('horizontal', listRef, gridRef);

  return (
    <>
      <List
        outerRef={listRef}
        style={{
          overflowY: 'hidden',
        }}
        height={headerHeight}
        itemCount={columns.length}
        itemSize={() => columnWidth}
        layout="horizontal"
        width={width}
      >
        {Column}
      </List>
      <VariableSizeGrid
        outerRef={scrollRef ? reactRefSetter(gridRef, scrollRef) : gridRef}
        className="Grid"
        columnCount={grid.length}
        columnWidth={() => columnWidth}
        rowCount={grid?.[0]?.length ?? 0}
        rowHeight={() => rowHeight}
        height={height - headerHeight}
        width={width}
        overscanRowCount={10}
        overscanColumnCount={10}
      >
        {RenderCell}
      </VariableSizeGrid>
    </>
  );
}
