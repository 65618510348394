import { useState } from 'react';

export const usePickerVisibilityState = () => {
  const [showPicker, setShowPicker] = useState(false);

  const onShowPicker = () => {
    setShowPicker(true);
  };

  const onHidePicker = () => {
    setShowPicker(false);
  };

  return { showPicker, onShowPicker, onHidePicker };
};
