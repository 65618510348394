interface Props {
  url: string;
}

export const UrlPreview: React.FC<Props> = (props) => {
  return (
    <iframe
      style={{ width: '100%', height: '100%', border: 'none' }}
      src={props.url}
      title="preview"
    ></iframe>
  );
};
