import styles from './styles.module.scss';

import { List } from '@mui/material';
import { forwardRef, Fragment, useState } from 'react';

import { DndTypes } from '@work4all/utils/lib/variables';

import {
  IFavoriteLink,
  IFavoriteLinkOrFolder,
  IFavoriteLinksFolder,
  useFavoriteLinks,
} from '../../../../containers/more/data/favorite-links';
import {
  FavoriteLinkDragObject,
  FavoriteLinksFolderDragObject,
  LinkDragObject,
} from '../../../../dnd/drag-objects';
import { DropIndicator } from '../drop-indicator/DropIndicator';
import { FolderPlaceholderItem } from '../folder-placeholder-item/FolderPlaceholderItem';

import { PopoverListItem } from './PopoverListItem';

export type IPopoverListProps = {
  startIndex?: number;
  items: IFavoriteLinkOrFolder[];
  folder?: IFavoriteLinksFolder;
  activeLink: { folderId: string | null; linkId: string } | null;
  onLinkClick: (link: IFavoriteLink) => void;
  onFolderClick: (foler: IFavoriteLinksFolder) => void;
};

export const PopoverList = forwardRef<HTMLUListElement, IPopoverListProps>(
  function PopoverList(
    { startIndex = 0, items, folder, activeLink, onLinkClick, onFolderClick },
    ref
  ) {
    const {
      addFavoriteLink,
      moveFavoriteLink,
      moveFavoriteLinksFolder,
      combineLinks,
    } = useFavoriteLinks();

    const [hoverIndex, setHoverIndex] = useState(-1);

    function handleDrop(type: unknown, item: unknown) {
      setHoverIndex(-1);

      switch (type) {
        case DndTypes.LINK: {
          const link = item as LinkDragObject;
          addFavoriteLink({
            link: { href: link.href, name: link.text },
            position: {
              folder: folder?.id,
              index: startIndex + hoverIndex,
            },
          });
          break;
        }
        case DndTypes.FAVORITE_LINK: {
          const link = item as FavoriteLinkDragObject;
          moveFavoriteLink({
            target: {
              folder: link.folderId,
              link: link.id,
            },
            position: {
              folder: folder?.id,
              index: startIndex + hoverIndex,
            },
          });
          break;
        }
        case DndTypes.FAVORITE_LINKS_FOLDER: {
          const folder = item as FavoriteLinksFolderDragObject;
          moveFavoriteLinksFolder({
            id: folder.id,
            position: { index: startIndex + hoverIndex },
          });
        }
      }
    }

    function handleDropOnLink(
      link: IFavoriteLink,
      type: unknown,
      item: unknown
    ) {
      switch (type) {
        case DndTypes.LINK: {
          const dragItem = item as LinkDragObject;
          combineLinks({
            targetId: link.id,
            href: dragItem.href,
            name: dragItem.text,
          });
          break;
        }
        case DndTypes.FAVORITE_LINK: {
          const dragItem = item as FavoriteLinkDragObject;
          combineLinks({
            targetId: link.id,
            folderId: dragItem.folderId,
            id: dragItem.id,
          });
          break;
        }
      }
    }

    function handleDropOnFolder(
      folder: IFavoriteLinksFolder,
      type: unknown,
      item: unknown
    ) {
      const position = { folder: folder.id };

      switch (type) {
        case DndTypes.LINK: {
          const link = item as LinkDragObject;
          addFavoriteLink({
            link: { href: link.href, name: link.text },
            position,
          });
          break;
        }
        case DndTypes.FAVORITE_LINK: {
          const link = item as FavoriteLinkDragObject;
          moveFavoriteLink({
            target: {
              folder: link.folderId,
              link: link.id,
            },
            position,
          });
          break;
        }
      }
    }

    return (
      <List ref={ref} className={styles.popoverFolderList}>
        {folder && items.length === 0 && (
          <FolderPlaceholderItem folderId={folder.id} />
        )}

        {items.map((link, index) => (
          <Fragment key={link.id}>
            {hoverIndex === index && <DropIndicator onDrop={handleDrop} />}
            <PopoverListItem
              key={link.id}
              index={index}
              item={link}
              folder={folder}
              activeLink={activeLink}
              onLinkClick={onLinkClick}
              onFolderClick={onFolderClick}
              onMove={setHoverIndex}
              onDrop={handleDrop}
              onDropOnLink={handleDropOnLink}
              onDropOnFolder={handleDropOnFolder}
            />
          </Fragment>
        ))}

        {hoverIndex === items.length && <DropIndicator onDrop={handleDrop} />}
      </List>
    );
  }
);
