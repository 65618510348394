import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class Tenant<T extends EMode = EMode.entity> {
  /** Alias: backColor */
  backgroundColor?: string;
  /** Alias: beraternummer */
  advisorNumber?: number;
  /** Alias: bildFileName */
  imageFileName?: string;
  /** Alias: briefDirectory */
  letterDirectory?: string;
  /** Alias: bundesland */
  state?: string;
  /** Alias: bundeslandCode */
  stateCode?: string;
  /** Alias: code */
  id?: number;
  /** Alias: dBDirectory */
  dBDirectory?: string;
  /** Alias: dBPass */
  dBPass?: string;
  /** Alias: datevID */
  datevID?: number;
  /** Alias: hintergrundfarbeKunden */
  backgroundColorCustomers?: number;
  /** Alias: hintergrundfarbeLieferanten */
  backgroundColorSuppliers?: number;
  /** Alias: hintergrundfarbeProjekte */
  backgroundColorProjects?: number;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: mailDirectory */
  mailDirectory?: string;
  /** Alias: mandantennummer */
  tenantNumber?: number;
  /** Alias: name */
  name?: string;
  /** Alias: notiz */
  note?: string;
  /** Alias: ort */
  city?: string;
  /** Alias: pLZ */
  postalCode?: string;
  /** Alias: projektbezogeneRechte */
  projectRights?: number;
  /** Alias: rEVerzeichnis */
  rEDirectory?: string;
  /** Alias: sMTPMailAccount */
  sMTPMailAccount?: string;
  /** Alias: sMTPMailPassword */
  sMTPMailPassword?: string;
  /** Alias: sMTPMailServer */
  sMTPMailServer?: string;
  /** Alias: staat */
  country?: string;
  /** Alias: steuernummer */
  taxNumber?: string;
  /** Alias: strasse */
  street?: string;
  /** Alias: telefax */
  faxNumber?: string;
  /** Alias: telefon */
  phoneNumber?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: ustID */
  ustID?: string;
  /** Alias: wWW */
  www?: string;

  __typename?: string;
}

const fields: FieldDefinitions<Tenant> = {
  backgroundColor: {
    alias: 'backColor',
  },
  advisorNumber: {
    alias: 'beraternummer',
  },
  imageFileName: {
    alias: 'bildFileName',
  },
  letterDirectory: {
    alias: 'briefDirectory',
  },
  state: {
    alias: 'bundesland',
  },
  stateCode: {
    alias: 'bundeslandCode',
  },
  id: {
    alias: 'code',
  },
  dBDirectory: {
    alias: 'dBDirectory',
  },
  dBPass: {
    alias: 'dBPass',
  },
  datevID: {
    alias: 'datevID',
  },
  backgroundColorCustomers: {
    alias: 'hintergrundfarbeKunden',
  },
  backgroundColorSuppliers: {
    alias: 'hintergrundfarbeLieferanten',
  },
  backgroundColorProjects: {
    alias: 'hintergrundfarbeProjekte',
  },
  insertTime: {
    alias: 'insertTime',
  },
  mailDirectory: {
    alias: 'mailDirectory',
  },
  tenantNumber: {
    alias: 'mandantennummer',
  },
  name: {
    alias: 'name',
  },
  note: {
    alias: 'notiz',
  },
  city: {
    alias: 'ort',
  },
  postalCode: {
    alias: 'pLZ',
  },
  projectRights: {
    alias: 'projektbezogeneRechte',
  },
  rEDirectory: {
    alias: 'rEVerzeichnis',
  },
  sMTPMailAccount: {
    alias: 'sMTPMailAccount',
  },
  sMTPMailPassword: {
    alias: 'sMTPMailPassword',
  },
  sMTPMailServer: {
    alias: 'sMTPMailServer',
  },
  country: {
    alias: 'staat',
  },
  taxNumber: {
    alias: 'steuernummer',
  },
  street: {
    alias: 'strasse',
  },
  faxNumber: {
    alias: 'telefax',
  },
  phoneNumber: {
    alias: 'telefon',
  },
  updateTime: {
    alias: 'updateTime',
  },
  ustID: {
    alias: 'ustID',
  },
  www: {
    alias: 'wWW',
  },
  __typename: {
    alias: '__typename',
  },
};

export const tenantEntityDefinition: EntitiyDefinition<Tenant> = {
  local: {},
  remote: {
    queryName: 'getMandanten',
    fragmentName: 'Mandant',
    withPaginationWrapper: false,
    params: [{ name: 'codes', type: '[Int]' }],
  },
  fieldDefinitions: fields,
};
