import styles from './CommentEventView.module.scss';

import { Typography } from '@mui/material';
import { memo, useCallback, useMemo } from 'react';
import sanitizeHtml from 'sanitize-html';

import { useUsersContext } from '@work4all/data/lib/hooks/users';

import { CommentEvent } from '@work4all/models/lib/Classes/CommentEvent.entity';

import {
  extractMentions,
  ExtractMentionsResolveFn,
} from '../../../../input/format-text/TextEditor/plugins/mention/utils';

import { EventLayout } from './EventLayout';

export interface CommentEventViewProps {
  event: CommentEvent;
}

export const CommentEventView = memo(function CommentEventView(
  props: CommentEventViewProps
) {
  const { event } = props;

  const { usersById } = useUsersContext();

  const resolve = useCallback<ExtractMentionsResolveFn>(
    ({ id }) => {
      const user = usersById[id];

      if (user && user.displayName) {
        return { text: user.displayName };
      }
    },
    [usersById]
  );

  const html = useMemo(() => {
    return processHtml(event.text, resolve);
  }, [event.text, resolve]);

  return (
    <EventLayout event={event}>
      <Typography
        className={styles.comment}
        component="div"
        variant="body2"
        color="text.primary"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </EventLayout>
  );
});

function processHtml(html: string, resolve: ExtractMentionsResolveFn) {
  const withMentions = extractMentions(html, resolve);
  const sanitized = sanitizeHtml(withMentions, {
    allowedTags: [
      'img',
      'p',
      'div',
      'span',
      'table',
      'tr',
      'td',
      'th',
      'strong',
      'u',
      'em',
      'i',
      's',
      'li',
      'ol',
      'ul',
      'a',
    ],
    allowedAttributes: {
      span: ['data-mention', 'data-mention-user'],
      img: ['src', 'style'],
      a: ['href'],
      '*': ['style'],
    },
  });

  return sanitized;
}
