import { useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';

import { Appearance } from '@work4all/models/lib/Classes/Appearance.entity';
import { BackgroundImageType } from '@work4all/models/lib/Enums/BackgroundImageType.enum';

import { useUploadTempFile } from '../hooks/use-upload-temp-file';

import {
  CHANGE_BACKGROUND_MUTATION,
  ChangeBackgroundMutationResult,
  ChangeBackgroundMutationVariables,
  GET_APPEARANCE_QUERY,
  GetAppearanceQueryResult,
  MODIFY_APPEARANCE_MUTATION,
  ModifyAppearanceMutationVariables,
} from './appearance-graphql';

export function useAppearance() {
  const appearanceQuery = useQuery<
    GetAppearanceQueryResult,
    Record<string, never>
  >(GET_APPEARANCE_QUERY);

  // Change the selected background and refetch the appearance query.
  const [changeBackgroundMutation] = useMutation<
    ChangeBackgroundMutationResult,
    ChangeBackgroundMutationVariables
  >(CHANGE_BACKGROUND_MUTATION, {
    refetchQueries: [GET_APPEARANCE_QUERY],
  });

  const changeBackground = useCallback(
    (id: string | null) => {
      changeBackgroundMutation({
        variables: { input: { selectedBackgroundImage: id } },
      });
    },
    [changeBackgroundMutation]
  );

  // Add or remove a custom background and refetch the appearance query.
  const [modifyAppearanceMutation] = useMutation<
    void,
    ModifyAppearanceMutationVariables
  >(MODIFY_APPEARANCE_MUTATION, { refetchQueries: [GET_APPEARANCE_QUERY] });

  const uploadTempFile = useUploadTempFile();

  const addBackground = useCallback(
    async (file: File) => {
      const { generatedObject } = await uploadTempFile(file);

      modifyAppearanceMutation({
        variables: {
          input: {
            add: [
              {
                fromTempFile: generatedObject,
                bgImageType: BackgroundImageType.USER,
              },
            ],
          },
        },
      });
    },
    [modifyAppearanceMutation, uploadTempFile]
  );

  const removeBackground = useCallback(
    (id: string) => {
      modifyAppearanceMutation({
        variables: { input: { remove: [id] } },
      });
    },
    [modifyAppearanceMutation]
  );

  const appearance: Appearance = {
    ...appearanceQuery.data?.getAppearance,
    backgroundImagesGeneral:
      appearanceQuery.data?.getAppearance?.backgroundImagesGeneral,
  };

  return {
    appearance: appearance ?? null,
    loading: appearanceQuery.loading,
    changeBackground,
    addBackground,
    removeBackground,
  };
}
