import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputProjectAccessGroup<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: number;
  /** Alias: name */
  name?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputProjectAccessGroup> = {
  code: {
    alias: 'code',
  },
  name: {
    alias: 'name',
  },
  insertTime: {
    alias: 'insertTime',
  },
  updateTime: {
    alias: 'updateTime',
  },
  customFields: {
    alias: 'customFields',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputProjectAccessGroupEntityDefinition: EntitiyDefinition<InputProjectAccessGroup> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputProjectAccessGroup',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
