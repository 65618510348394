import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { FileEntity } from './FileEntity.entity';
import { User } from './User.entity';

export class EMailAttachment<T extends EMode = EMode.entity> {
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: code */
  id?: number;
  /** Alias: dateiName */
  fileName?: string;
  /** Alias: eMailCode */
  eMailId?: number;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: fileInfos */
  fileInfos?: FileEntity<T>;
  /** Alias: inlineAttachement */
  inlineAttachement?: boolean;
  /** Alias: displayFilename */
  displayFilename?: string;
  /** Alias: benutzer */
  user?: User<T>;

  __typename?: string;
}

const fields: FieldDefinitions<EMailAttachment> = {
  userId: {
    alias: 'benutzerCode',
  },
  id: {
    alias: 'code',
  },
  fileName: {
    alias: 'dateiName',
  },
  eMailId: {
    alias: 'eMailCode',
  },
  insertTime: {
    alias: 'insertTime',
  },
  updateTime: {
    alias: 'updateTime',
  },
  fileInfos: {
    alias: 'fileInfos',
    entity: Entities.fileEntity,
  },
  inlineAttachement: {
    alias: 'inlineAttachement',
  },
  displayFilename: {
    alias: 'displayFilename',
  },
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  __typename: {
    alias: '__typename',
  },
};

export const eMailAttachmentEntityDefinition: EntitiyDefinition<EMailAttachment> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'EMailAnhang',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
