import styles from '../PositionPicker.module.scss';

import { Theme, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Row, TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';

import schema from '../../../../../../../../../../../components/data-tables/schemata/article-table-schema.json';
import { Table } from '../../../../../../../../../../../components/data-tables/table/Table';
import { TableNoRowsPlaceholder } from '../../../../../../../../../../../components/data-tables/TableNoRowsPlaceholder';
import { useDataTable } from '../../../../../../../../../../../components/data-tables/use-data-table';
import { useUserColumnConfigs } from '../../../../../../../../../../../components/data-tables/use-user-column-configs';
import { ARTICLE_POSITIONS_DATA } from '../../constants';

type IArticlesTableProps = {
  searchString: string;
  groups: number[];
  disabled: boolean;
  onSelectedChange: (articles: Article[]) => void;
  onRowDoubleClick: (article: Article) => void;
  setResetColumns: (resetColumns: () => void) => void;
};

const defaultSort = [{ field: 'name', direction: SortDirection.ASCENDING }];

export const PositionArticlesTable = React.forwardRef<
  TableInstance,
  IArticlesTableProps
>(function ArticlesTable(props: IArticlesTableProps, ref) {
  const {
    searchString,
    groups,
    onSelectedChange,
    onRowDoubleClick,
    disabled,
    setResetColumns,
  } = props;

  const prefilter = undefined;
  const { entity: entityType } = schema as never;

  const tableStateBag = useTableStateBag();

  // Custom actions
  const handleSelectedRowsChange = useCallback(
    (rows: Row<Article>[]) => {
      if (onSelectedChange) {
        const articles = rows.map((row) => row.original);
        onSelectedChange(articles);
      }
    },
    [onSelectedChange]
  );

  useEffect(() => {
    if (!searchString || searchString.length === 0) {
      tableStateBag.setSearchFilterText('');
    } else {
      tableStateBag.setSearchFilterText(searchString);
    }
  }, [searchString, tableStateBag]);
  const groupColumn = tableStateBag.columnsById['groupId'];

  useEffect(() => {
    if (!groupColumn || !groups) {
      return;
    }
    if (groups.length === 0 || groups.includes(0)) {
      groupColumn.setFilter(null);
    } else {
      groupColumn.setFilter({
        value: groups.map((id) => ({ id })),
        filterType: groupColumn.filterType,
      });
    }
  }, [groups, groupColumn]);

  const isMdDown = useMediaQuery<Theme>((t) => t.breakpoints.down('md'));
  const layout = isMdDown ? 'cards' : 'table';

  const forceRequestFields: Article = useMemo(() => {
    return {
      articlePrices: [
        {
          singlePriceList: [
            {
              id: null,
            },
          ],
        },
      ],
      ...ARTICLE_POSITIONS_DATA,
    };
  }, []);

  const {
    columnConfigs,
    prepareRowDisplayModifiers,
    data,
    fetchMore,
    total,
    initialSortBy,
    pending,
  } = useDataTable<Article, never>({
    layout,
    schema: schema as never,
    tableStateBag,
    prefilter,
    defaultSort,
    forceRequestFields,
  });

  const [userConfig, handlers] = useUserColumnConfigs({
    layout,
    entityType,
    columnConfigs,
  });

  useEffect(() => {
    setResetColumns(handlers.remove);
  }, [handlers, setResetColumns]);

  if (!userConfig) return null;

  return (
    <Table
      ref={ref}
      pending={pending}
      noRowsRenderer={() => <TableNoRowsPlaceholder />}
      columnConfigs={userConfig}
      manualGroupBy={true}
      initialSortBy={initialSortBy}
      loadMoreItems={fetchMore}
      prepareRowDisplayModifiers={prepareRowDisplayModifiers}
      data={data}
      total={total}
      hideToolbar
      draggable={!disabled}
      selectableMultiple={false}
      actions={
        disabled
          ? undefined
          : {
              edit: {
                handler: (id) => {
                  const articles = data as Article[];
                  if (Array.isArray(id)) {
                    const lookupIds = id.map((x) => parseInt(x));
                    const article = articles.find((x) =>
                      lookupIds.includes(x.id)
                    );
                    onRowDoubleClick(article);
                  } else {
                    const lookupId = parseInt(id);
                    const article = articles.find((x) => x.id === lookupId);
                    onRowDoubleClick(article);
                  }
                },
              },
            }
      }
      classes={{
        root: disabled
          ? clsx(styles.table, styles.tableDisabled)
          : styles.table,
      }}
      onSelectedRowsChange={disabled ? undefined : handleSelectedRowsChange}
    />
  );
});
