import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import styles from './app.module.scss';

import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import * as ReactSentry from '@sentry/react';
import * as Sentry from '@sentry/react';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation as useReactLocation,
} from 'react-router-dom';

import {
  AppState,
  AppStateProps,
  DialogsProvider,
  MuiThemeProvider,
} from '@work4all/components';
import { EntityTemplateProvider } from '@work4all/components/lib/components/entity-template-provider/EntityTemplateProvider';
import { UserIconProvider } from '@work4all/components/lib/components/user-icon/useUserIconRegister';
import { DummyOverlay } from '@work4all/components/lib/dataDisplay/dummy-overlay';
import { LockProvider } from '@work4all/components/lib/hooks';
import { MobileNavContextProvider } from '@work4all/components/lib/navigation/mobile-navigation/MobileNavigation';
import {
  IRouteConfig,
  RouterNavigationOverlay,
} from '@work4all/components/lib/navigation/router-navigation-overlay';
import { initAmplitude } from '@work4all/components/lib/utils/amplitude/amplitude';
import { isTimeTrackingUser } from '@work4all/components/lib/utils/isTimeTrackingUser';

import {
  CustomBackgroundsContextProvider,
  ModuleRightsProvider,
  Navigate,
  TENANT_URL_PART,
  useLocation,
  useParams,
} from '@work4all/data';
import { DocumentClassesProvider } from '@work4all/data/lib/hooks/document-classes';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';
import { RoutesMetaProvider } from '@work4all/data/lib/hooks/useRoutesMetaBag';
import { UsersContext } from '@work4all/data/lib/hooks/users';
import { useUser } from '@work4all/data/lib/hooks/useUser';
import { WidgetsDefinitionsProvider } from '@work4all/data/lib/hooks/useWidgetsBag';
import { EntityJsonSchemasProvider } from '@work4all/data/lib/json-schema/EntityJsonSchemasContext';
import { SettingsProvider } from '@work4all/data/lib/settings';

import { CustomDragLayer } from '../components/dnd/custom-drag-layer/CustomDragLayer';
import { DefaultPickerAction } from '../components/entity-picker/EntityPickerActions';
import { NavigationWrapperActiveRouteMatcher } from '../components/navigation-wrapper-active-route-matcher/NavigationWrapperActiveRouteMatcher';
import { ProfilePage } from '../components/user-menu/views/Profile/Profile';
import { WORK4ALL_CUSTOMER_ID } from '../config';
import { GraphQLExplorerPage } from '../containers/apollo';
import { CalendarPage } from '../containers/calendar/CalendarPage';
import { FileEntitiesListsController } from '../containers/file-entities-lists';
import { FileDetailPage } from '../containers/files/detail/FilePage';
import { CallModalProvider } from '../containers/files/detail/providers/CallModalProvider';
import { SearchPage } from '../containers/files/search/SearchPage';
import { HomeWidgetsContextProvider } from '../containers/home/components/home-widgets/HomeWidgetsContextProvider';
import { HomePage } from '../containers/home/HomePage';
import { LoginPage } from '../containers/login';
import {
  MaskOverlay,
  maskRoute,
} from '../containers/mask-overlays/mask-overlay';
import { CurrencyExchangeInfoContextProvider } from '../containers/mask-overlays/mask-overlay/views/inbound-invoice/CurrencyExchangeInfoContextProvider';
import { PdfEditorPage } from '../containers/mask-overlays/mask-overlay/views/pdf-editor/PdfEditorPage';
import { FavoriteLinksProvider } from '../containers/more/data/favorite-links';
import { MorePage } from '../containers/more/MorePage';
import { NoAccess } from '../containers/NoAccess';
import { StatisticPage } from '../containers/statistic/StatisticPage';
import { TimeTrackerMenuProvider } from '../containers/time-tracker/providers/TimeTrackerMenuProvider';
import { TimeTrackerMenuPage } from '../containers/time-tracker/TimeTrackerMenu';
import { VacationPage } from '../containers/vacations';
import { HookedFavoritesProvider } from '../contexts/HookedFavoritesProvider';
import { environment } from '../environments';
import { NotificationsPage } from '../features/notifications';
import { useSettingsListener } from '../hooks/use-settings-listener';
import { BinaryTransferProvider } from '../hooks/useBinaryTransfer';
import { useOverlayingWebView } from '../hooks/useOverlayingWebView';
import { HookedMuiThemeProvider } from '../providers/HookedMuiThemeProvider';
import { ReleaseNotesDialogProvider } from '../providers/ReleaseNotesDialogProvider';
import { SuggestionsProvider } from '../providers/SuggestionsProvider';

import { W4AAppPageZoomProvider } from './../providers/AppPageZoomProvider';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const overlayRoutes: IRouteConfig[] = [
  {
    withBreadcrumbs: true,
    route: '/*/details/:activeviewurl/:entityid?/',
    view: <DummyOverlay type={'Default Overlay'} />,
    title: 'ROOT',
  },
  {
    withBreadcrumbs: true,
    route: '/projects/:id/details/:activeviewurl/:entityid?/',
    view: <DummyOverlay type={'Project Specific Overlay'} />,
    title: 'ROOT',
  },
  // For masks opened from a home
  {
    withBreadcrumbs: false,
    route: '/home/details/:subEntityType/:subEntityId',
    view: <MaskOverlay amplitudeEntryPoint="home" />,
    decorator: LockProvider,
  },
  // For masks opened from search page
  {
    withBreadcrumbs: false,
    route: '/:entityType/details/:subEntityType/:subEntityId',
    decorator: LockProvider,
    view: <MaskOverlay amplitudeEntryPoint="fileDetailPageEntityList" />,
  },
  // For masks opened from a file details page
  {
    withBreadcrumbs: false,
    route: maskRoute,
    view: <MaskOverlay amplitudeEntryPoint="fileDetailPage" />,
    decorator: LockProvider,
  },
  // For masks opened from an entity list of the same entity kind
  {
    withBreadcrumbs: false,
    route: '/more/entity/:subEntityType/details/:subEntityId',
    view: <MaskOverlay amplitudeEntryPoint="entityList" />,
    decorator: LockProvider,
  },
  // For masks opened from an entity list of another kind via a "Convert" action
  {
    withBreadcrumbs: false,
    route: '/more/entity/:subEntityType/details/:subEntityType/:subEntityId',
    view: <MaskOverlay amplitudeEntryPoint="convertViaEntityList" />,
    decorator: LockProvider,
  },
  // For masks opened from a file-specific entity list of another kind
  // via a "Convert" action
  {
    withBreadcrumbs: false,
    route:
      '/:entityType/:entityId/entity/:subEntityType/details/:subEntityType/:subEntityId',
    view: (
      <MaskOverlay amplitudeEntryPoint="convertViaFileDetailPageEntityList" />
    ),
    decorator: LockProvider,
  },
  // For masks opened from a list opened from a file details page
  {
    withBreadcrumbs: false,
    route: '/:entityType/:entityId/entity/:subEntityType/details/:subEntityId',
    decorator: LockProvider,
    view: <MaskOverlay amplitudeEntryPoint="fileDetailPageEntityList" />,
  },
];

const timeTrackingRoutes: IRouteConfig[] = [
  {
    withBreadcrumbs: false,
    route: '/home/details/:subEntityType/:subEntityId',
    view: <MaskOverlay amplitudeEntryPoint="home" />,
    decorator: LockProvider,
  },
];

export function App() {
  const user = useUser();
  useOverlayingWebView();

  useEffect(() => {
    SplashScreen.hide();
  }, []);

  useEffect(() => {
    initAmplitude({
      userId: user?.benutzerCode,
      customerId: user?.kundennummer,
    });
    if (user?.benutzerCode) {
      // As user ID is not guaranteed to be unique, we use the combination of
      // customer ID and user ID.
      const id = `${user.kundennummer}-${user.benutzerCode}`;

      // Add email for internal users for easier identification.
      if (user.kundennummer === WORK4ALL_CUSTOMER_ID) {
        ReactSentry.setUser({ id: id, email: user.email });
      } else {
        ReactSentry.setUser({ id: id });
      }

      ReactSentry.setTag('work4all.customer', user.kundennummer);
    } else {
      ReactSentry.setUser(null);
      ReactSentry.setTag('work4all.customer', undefined);
    }
  }, [user]);

  return (
    <div id="w4a-root" className={styles.app}>
      <div id="portal"></div>
      <DndProvider backend={HTML5Backend}>
        <BinaryTransferProvider>
          <MuiThemeProvider pageTheme="light">
            <DialogsProvider>
              <UserIconProvider isLoggedIn={user !== undefined}>
                <MobileNavContextProvider>
                  <Router>
                    <SentryRoutes>
                      <Route
                        path={`/:${TENANT_URL_PART}/*`}
                        element={<TenantSpecificRoutes />}
                      />
                      <Route path="/login" element={<LoginPage />} />
                      <Route path="*" element={<LoginPage />} />
                    </SentryRoutes>
                  </Router>
                </MobileNavContextProvider>
              </UserIconProvider>
            </DialogsProvider>
          </MuiThemeProvider>
        </BinaryTransferProvider>
      </DndProvider>
    </div>
  );
}

const TenantSpecificRoutes = () => {
  const user = useUser();
  const { activeTenant } = useTenant();
  const routeParams = useParams();
  const location = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const listener = useSettingsListener();

  const [appState, setAppState] = useState<AppStateProps>({
    overlayActive: false,
    forcedShareBaseUrl:
      Capacitor.getPlatform() !== 'web'
        ? environment.nativeShareBaseUrl
        : undefined,
  });

  // show error if no internet connection
  window.addEventListener('offline', () => {
    enqueueSnackbar(t('ERROR.NO_INTERNET_CONNECTION'), {
      variant: 'error',
    });
  });
  window.addEventListener('online', () => {
    closeSnackbar();
  });

  const loc = useReactLocation();

  if (!user) {
    return <Navigate to={`/login`} tenant={null} state={{ from: loc }} />;
  }

  if (!routeParams.tenantId) {
    /**
     * if we are not currently in a deeplink scenario we default to the homepage of the active tenant
     * this will most likely happen in a migration phase
     * */
    return <Navigate to="/home" tenant={user.mandanten[0].code} />;
  }

  if (
    !user.mandanten.map((el) => el.code).includes(activeTenant) &&
    !location.pathname.includes('error')
  ) {
    return <Navigate to="/error" tenant={activeTenant} />;
  }

  function renderRoutes(isRestrictedRouting: boolean) {
    if (isRestrictedRouting) {
      // Display a "No access" page for recognized app routes if the user does
      // not have access.
      const knownForbiddenRoutePaths = [
        '/overview',
        '/more',
        '/more/*',
        '/customers/*',
        '/suppliers/*',
        '/projects/*',
      ];

      const forbiddenRoutes = knownForbiddenRoutePaths.map((route) => (
        <Route key={route} path={route} element={<NoAccess reason="route" />} />
      ));

      return (
        <SentryRoutes key={`tenant-${activeTenant}`}>
          <Route path="/home/:entityType/*" element={<HomePage />} />
          <Route path="/error" element={<NoAccess />} />
          <Route path="/more/notifications" element={<NotificationsPage />} />
          <Route path="/more/timetracking" element={<TimeTrackerMenuPage />} />
          <Route path="/more/vacation" element={<VacationPage />} />
          <Route path="/more/profile" element={<ProfilePage />} />
          <Route
            path="/more/statistic/:statisticType"
            element={<StatisticPage />}
          />

          {forbiddenRoutes}

          <Route path="*" element={<Navigate to="/more/timetracking" />} />
        </SentryRoutes>
      );
    }

    return (
      <SentryRoutes key={`tenant-${activeTenant}`}>
        <Route path="/error" element={<NoAccess />} />
        {/*
          It's mostly a development tool, so we can remove it
          from the app later. Or use feature flags to choose to
          show/hide this page.
        */}
        <Route path="/graphql" element={<GraphQLExplorerPage />} />
        <Route path="/more" element={<MorePage />} />
        <Route path="/more/profile" element={<ProfilePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/home/:entityType/*" element={<HomePage />} />
        <Route path="/more/notifications" element={<NotificationsPage />} />
        <Route path="/more/calendar" element={<CalendarPage />} />
        <Route path="/more/statistic" element={<StatisticPage />} />
        <Route path="/more/vacation" element={<VacationPage />} />
        <Route path="/more/profile" element={<ProfilePage />} />
        <Route
          path="/more/pdf-editor/:entityType/:entityId/:docId"
          element={<PdfEditorPage />}
        />
        <Route path="/more/timetracking" element={<TimeTrackerMenuPage />} />
        <Route
          path="/more/statistic/:statisticType"
          element={<StatisticPage />}
        />
        <Route
          path="/more/entity/:entityType/*"
          element={<FileEntitiesListsController />}
        />
        <Route
          path="/:fileType/:fileId/entity/:entityType/*"
          element={<FileEntitiesListsController />}
        />
        {['/projects', '/customers', '/suppliers']
          .map((p) => `${p}/details/*`)
          .map((p) => (
            <Route path={p} key={p} element={<SearchPage />} />
          ))}
        {['/projects', '/customers', '/suppliers'].map((p) => (
          <Route path={p + '/:id/*'} key={p} element={<FileDetailPage />} />
        ))}
        {['/projects', '/customers', '/suppliers'].map((p) => (
          <Route key={p} path={p} element={<SearchPage />} />
        ))}

        <Route path="*" element={<Navigate to="/home" />} />
      </SentryRoutes>
    );
  }

  function renderOverlayRoutes(isRestrictedRouting: boolean) {
    const routes = isRestrictedRouting ? timeTrackingRoutes : overlayRoutes;

    return (
      <RouterNavigationOverlay
        routes={routes}
        onOverlayStateChanged={(visible) => {
          if (appState.overlayActive !== visible) {
            setAppState({
              ...appState,
              overlayActive: visible,
            });
          }
        }}
      />
    );
  }

  return (
    <UsersContext>
      <EntityJsonSchemasProvider>
        <SettingsProvider listener={listener}>
          <HookedMuiThemeProvider>
            <W4AAppPageZoomProvider>
              <CallModalProvider>
                <ModuleRightsProvider>
                  {(loading, rights) => {
                    return loading ? null /** ToDo: discuss blocking loading behaviour with product https://work4all.slack.com/archives/C03N4PA3AQY/p1699604145291699 */ : (
                      <FavoriteLinksProvider>
                        <EntityTemplateProvider
                          renderPickerFooter={(props) => {
                            return <DefaultPickerAction {...props} />;
                          }}
                        >
                          <HomeWidgetsContextProvider tenant={activeTenant}>
                            <HookedFavoritesProvider>
                              <AppState.Provider value={appState}>
                                <CustomBackgroundsContextProvider>
                                  <RoutesMetaProvider>
                                    <CustomDragLayer />
                                    <WidgetsDefinitionsProvider
                                      tenant={activeTenant}
                                    >
                                      <CurrencyExchangeInfoContextProvider>
                                        <DocumentClassesProvider>
                                          {renderOverlayRoutes(
                                            isTimeTrackingUser(rights)
                                          )}
                                          <SuggestionsProvider>
                                            <TimeTrackerMenuProvider>
                                              <ReleaseNotesDialogProvider>
                                                <NavigationWrapperActiveRouteMatcher
                                                  isLoggedIn={Boolean(user)}
                                                >
                                                  {renderRoutes(
                                                    isTimeTrackingUser(rights)
                                                  )}
                                                </NavigationWrapperActiveRouteMatcher>
                                              </ReleaseNotesDialogProvider>
                                            </TimeTrackerMenuProvider>
                                          </SuggestionsProvider>
                                        </DocumentClassesProvider>
                                      </CurrencyExchangeInfoContextProvider>
                                    </WidgetsDefinitionsProvider>
                                  </RoutesMetaProvider>
                                </CustomBackgroundsContextProvider>
                              </AppState.Provider>
                            </HookedFavoritesProvider>
                          </HomeWidgetsContextProvider>
                        </EntityTemplateProvider>
                      </FavoriteLinksProvider>
                    );
                  }}
                </ModuleRightsProvider>
              </CallModalProvider>
            </W4AAppPageZoomProvider>
          </HookedMuiThemeProvider>
        </SettingsProvider>
      </EntityJsonSchemasProvider>
    </UsersContext>
  );
};

export default App;
