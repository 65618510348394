import { isEqual } from 'lodash';

import { AppointmentAttendee } from '@work4all/models/lib/Classes/AppointmentAttendee.entity';
import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { ContactUnionWrapper } from '@work4all/models/lib/Classes/ContactUnionWrapper.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { ContactKind } from '@work4all/models/lib/Enums/ContactKind.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';
import { UserClass } from '@work4all/models/lib/Enums/UserClass.enum';

import { assertNever } from '@work4all/utils';

import { createContactUnionWrapper } from '../contactpicker/ContactRessourcePicker';

function wrap(
  data: Contact | User | Customer | Supplier,
  contactKind: ContactKind
): ContactUnionWrapper {
  return createContactUnionWrapper(data, contactKind);
}

function throwMapperError(
  kind: 'resource' | 'attendee',
  attendee: AppointmentAttendee
): never {
  const error = new Error(
    [
      `Could not map AppointmentAttendee to ${kind} ContactUnionWrapper.`,
      `\nAppointmentAttendee: ${JSON.stringify(attendee, null, 2)}`,
    ].join('')
  );

  Error.captureStackTrace(error, throwMapperError);

  throw error;
}

// TODO Verify that the mapping logic is correct here.

export function mapResourceContact(
  attendee: AppointmentAttendee
): ContactUnionWrapper {
  if (attendee.ressource !== null && attendee.ressource !== undefined) {
    return wrap(attendee.ressource, ContactKind.RESSOURCE);
  }

  throwMapperError('resource', attendee);
}

export function mapAttendeeContact(
  attendee: AppointmentAttendee
): ContactUnionWrapper {
  if (attendee.user?.userKind === UserClass.BENUTZER) {
    return wrap(attendee.user, ContactKind.BENUTZER);
  } else if (attendee.user?.userKind === UserClass.MITARBEITER) {
    return wrap(attendee.user, ContactKind.MITARBEITER);
  } else if (attendee.contact?.businessPartnerType === SdObjType.KUNDE) {
    return wrap(attendee.contact, ContactKind.KUNDENANSPRECHPARTNER);
  } else if (attendee.contact?.businessPartnerType === SdObjType.LIEFERANT) {
    return wrap(attendee.contact, ContactKind.LIEFERANTENANSPRECHPARTNER);
  } else if (
    attendee.businessPartner?.businessPartnerType === SdObjType.KUNDE ||
    attendee.businessPartnerType === SdObjType.KUNDE
  ) {
    return wrap(attendee.businessPartner.data, ContactKind.KUNDE);
  } else if (
    attendee.businessPartner?.businessPartnerType === SdObjType.LIEFERANT ||
    attendee.businessPartnerType === SdObjType.LIEFERANT
  ) {
    return wrap(attendee?.businessPartner?.data, ContactKind?.LIEFERANT);
  }
  throwMapperError('attendee', attendee);
}

function mapContact(attendee: AppointmentAttendee): ContactUnionWrapper {
  return isResource(attendee)
    ? mapResourceContact(attendee)
    : mapAttendeeContact(attendee);
}

export function mapAttendee(contact: ContactUnionWrapper): AppointmentAttendee {
  switch (contact.contactKind) {
    case ContactKind.RESSOURCE:
      return { ressource: contact.data as User, userId: contact.data.id };
    case ContactKind.BENUTZER:
    case ContactKind.MITARBEITER:
      return { user: contact.data as User, userId: contact.data.id };
    case ContactKind.KUNDENANSPRECHPARTNER:
    case ContactKind.LIEFERANTENANSPRECHPARTNER:
      return {
        contact: contact.data as Contact,
        contactId: contact.data.id,
        businessPartnerType: (contact.data as Contact).businessPartnerType,
        businessPartnerId: (contact.data as Contact).businessPartnerId,
      };
    case ContactKind.KUNDE:
      return {
        businessPartner: { data: contact.data as Customer },
        businessPartnerType: SdObjType.KUNDE,
        businessPartnerId: contact.data.id,
      };
    case ContactKind.LIEFERANT:
      return {
        businessPartner: { data: contact.data as Supplier },
        businessPartnerType: SdObjType.LIEFERANT,
        businessPartnerId: contact?.data?.id,
      };
    case ContactKind.UNKNOWN:
      throw new Error(`Unknown contactKind "${contact.contactKind}"`);
    default:
      assertNever(
        contact.contactKind,
        `Unknown contactKind "${contact.contactKind}"`
      );
  }
}

export function isResource(attendee: AppointmentAttendee): boolean {
  return attendee.ressource !== null && attendee.ressource !== undefined;
}

export function isAttendee(attendee: AppointmentAttendee): boolean {
  return !isResource(attendee);
}

export function isTheSameAttendee(
  attendee: AppointmentAttendee,
  contact: ContactUnionWrapper
) {
  // A hack to easily compare values before and after conversions.

  // TODO Replace this hack with an actual comparison function?
  const attendeeContact = mapContact(attendee);
  const normalizedContact = mapContact(mapAttendee(contact));

  return isEqual(attendeeContact, normalizedContact);
}

export const filterDuplicateContactAttendees = (
  attendees: ContactUnionWrapper[]
) => {
  const filteredAttendees = attendees.filter(
    (value, index, self) => index === self.findIndex((t) => t.id === value.id)
  );
  return filteredAttendees;
};
