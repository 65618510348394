import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';
import { SalesOpportunitiesContainer } from '@work4all/components/lib/components/entity-preview/sales-opportunities-preview/SalesOpportunitiesContainer';
import { ProgressCell } from '@work4all/components/lib/dataDisplay/basic-table/utils/cells/ProgressCell';

import { SalesOpportunities } from '@work4all/models/lib/Classes/SalesOpportunities.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SalesOpportunityStatus } from '@work4all/models/lib/Enums/SalesOpportunityStatus.enum';

import { OnOpenMask } from '../../containers/mask-overlays/mask-overlay/types';

import { SalesOpportunityStatusCell } from './cell-renderer/SalesOpportunityStatusCell';
import { SalesRatedCell } from './cell-renderer/SalesRatedCell';
import { TendencyCell } from './cell-renderer/TendencyCell';
import { SalesOpportunitiesQuickFilters } from './sales-opportunities-table/SalesOpportunitiesQuickFilters';
import schema from './schemata/salesOpportunities-table-schema.json';
import { ITableProps, Table } from './table/Table';
import { TableLayoutContext, useTableLayoutState } from './table-layout';
import { TableNoRowsPlaceholder } from './TableNoRowsPlaceholder';
import { useDataTable } from './use-data-table';
import { useDeleteEntitiesToolbarConfig } from './use-delete-entities-toolbar-config';
import { useTableMaskHandlers } from './use-table-mask-handlers';
import { useUserColumnConfigs } from './use-user-column-configs';

interface Props
  extends Pick<
    ITableProps,
    'forceRequestFields' | 'selectableMultiple' | 'onSelectedRowsChange'
  > {
  prefilter?: unknown[];
  onOpenMask: OnOpenMask;
}

const manualGroupBy = true;

const defaultSort = [
  { field: 'dateCreated', direction: SortDirection.DESCENDING },
];

export const SalesOpportunitiesTable = React.forwardRef<TableInstance, Props>(
  function SalesOpportunitiesTable(props, ref) {
    const { prefilter, onOpenMask, forceRequestFields, ...rest } = props;
    const tableStateBag = useTableStateBag();
    const { entity: entityType } = schema as never;

    const layoutState = useTableLayoutState();
    const [layout] = layoutState;

    const {
      cardConfig,
      columnConfigs,
      prepareRowDisplayModifiers,
      data,
      footerData,
      fetchMore,
      total,
      selectedEntities,
      initialSortBy,
      pending,
    } = useDataTable<SalesOpportunities, Record<string, never>>({
      schema: schema as never,
      tableStateBag,
      prefilter,
      forceRequestFields,
      defaultSort,
      cells: {
        TendencyCell,
        ProgressCell,
        SalesOpportunityStatusCell,
        SalesRatedCell,
      },
      layout,
      enableFooter: true,
    });

    const [userConfig, userConfigMethods] = useUserColumnConfigs({
      entityType: Entities.salesOpportunities,
      columnConfigs,
      layout,
    });

    const deleteConfig = useDeleteEntitiesToolbarConfig({
      entityType,
    });

    const maskHandlers = useTableMaskHandlers(entityType, onOpenMask);

    const { t } = useTranslation();

    const rowModifiers = useCallback(
      (value: SalesOpportunities) => {
        const modifiers = prepareRowDisplayModifiers(value);
        return {
          ...modifiers,
          isFaded: [
            SalesOpportunityStatus.CONVERTED_TO_ORDER,
            SalesOpportunityStatus.CONVERTED_TO_INVOICE,
            SalesOpportunityStatus.CONVERTED_TO_DELIVERY_NOTE,
            SalesOpportunityStatus.CONVERTED_TO_OFFER,
            SalesOpportunityStatus.LOST,
            SalesOpportunityStatus.HIDE,
          ].includes(value.status),
        };
      },
      [prepareRowDisplayModifiers]
    );

    if (!userConfig) return null;

    return (
      <TableLayoutContext value={layoutState}>
        <Table
          layout={layout}
          pending={pending}
          noRowsRenderer={() => <TableNoRowsPlaceholder />}
          ref={ref}
          areas={{
            left: {
              content: <SalesOpportunitiesQuickFilters />,
              resizable: true,
              collapseConfig: {
                title: t('COMMON.FILTER'),
              },
            },
            right:
              selectedEntities?.length > 0
                ? {
                    content: (
                      <SalesOpportunitiesContainer
                        ids={selectedEntities.map((x) => x.id)}
                        onCloseClicked={() =>
                          tableStateBag.tableInstance.toggleAllRowsSelected(
                            false
                          )
                        }
                        onEditClicked={maskHandlers.edit?.handler}
                        onShareClicked={maskHandlers.share}
                      />
                    ),
                  }
                : null,
          }}
          actions={{
            add: maskHandlers.create,
            edit: maskHandlers.edit,
            remove: deleteConfig,
            resetColumns: userConfigMethods.remove,
            createWidget: true,
          }}
          columnConfigs={userConfig}
          manualGroupBy={manualGroupBy}
          initialSortBy={initialSortBy}
          loadMoreItems={fetchMore}
          prepareRowDisplayModifiers={rowModifiers}
          displayFooter
          footerData={footerData}
          data={data}
          total={total}
          cardConfig={cardConfig}
          {...rest}
        />
      </TableLayoutContext>
    );
  }
);
