import styles from './Breadcrumbs.module.scss';

import { Box, ButtonBase, Theme, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { ReactComponent as ArrowIcon } from '@work4all/assets/icons/outline-arrow-24.svg';
import { ReactComponent as CloseIcon } from '@work4all/assets/icons/outline-close-24.svg';

import { Tooltip } from '../../components/tooltip/Tooltip';
import { InlineCaption } from '../../dataDisplay/inline-caption';
import { Heading3 } from '../../typography/heading3/Heading3';
import { Heading4 } from '../../typography/heading4/Heading4';

import { BreadcrumbItem } from './components/breadcrumb-item/BreadCrumbItem';

interface IBreadcrumbItem {
  title?: string;
  subTitle?: string;
  breadcrumbTitle?: string;
  breadcrumbRightElement?: React.ReactNode;
}

export interface IBreadrumbsClasses {
  root?: string;
  breadcrumbs?: string;
  heading?: string;
}

export interface IBreadcrumbsProps {
  stack: IBreadcrumbItem[];
  current?: IBreadcrumbItem;
  currentIndex?: number;
  goBack: (idx?: number) => void;
  close?: () => void;
  showCloseIcon?: boolean;
  withMinHeight?: boolean;
  classes?: IBreadrumbsClasses;
  heading?: 'h3' | 'h4';
  hideBreadcrumbs?: boolean;
  forceStackItems?: boolean;
}

export const Breadcrumbs: React.FC<IBreadcrumbsProps> = (props) => {
  const {
    stack: origStack,
    goBack,
    close,
    current,
    currentIndex,
    showCloseIcon = true,
    withMinHeight,
    heading = 'h4',
    hideBreadcrumbs,
    forceStackItems = false,
  } = props;

  const isViewportDownMd = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  if (!current?.title) {
    return null;
  }

  const Heading = heading === 'h3' ? Heading3 : Heading4;

  const stack =
    typeof currentIndex === 'number'
      ? origStack.slice(0, currentIndex + 1)
      : origStack;

  const lastBreadCrumbIndex = stack.length - 2;
  const canGoBack = currentIndex !== 0;
  const withBreadCrumbs =
    !hideBreadcrumbs && (stack.length > 1 || withMinHeight);
  return (
    <div className={clsx(styles.root, props?.classes?.root)}>
      <div className={styles.leftWrapper}>
        <ButtonBase
          className={clsx(styles.navButton, {
            [styles.withBreadCrumbs]: withBreadCrumbs,
          })}
          onClick={canGoBack ? () => goBack() : close}
        >
          {!canGoBack && showCloseIcon ? (
            <CloseIcon data-test-id={'closeIcon'} />
          ) : (
            <ArrowIcon data-test-id={'goBackIcon'} />
          )}
        </ButtonBase>
        <div
          className={clsx(styles.wrapper, {
            [styles.wrapperCentered]: !withBreadCrumbs,
            [styles.withBreadCrumbs]: withBreadCrumbs,
          })}
        >
          {withBreadCrumbs && <div className={styles.wrapperCap} />}
          <Heading
            className={clsx(styles.heading, props.classes?.heading, {
              [styles.downMd]: isViewportDownMd,
            })}
          >
            <span className={styles.title}>{current?.title}&nbsp;</span>
            {current?.subTitle && (
              <Tooltip
                title={<Box whiteSpace="pre">{current.subTitle ?? ''}</Box>}
                placement="bottom"
              >
                <div style={{ display: 'inline-block' }}>
                  <InlineCaption
                    className={styles.subTitle}
                    caption={
                      (isViewportDownMd ? '' : ' | ') +
                      current.subTitle?.split('\n')?.[0]
                    }
                  />
                </div>
              </Tooltip>
            )}
          </Heading>
          {(!isViewportDownMd || forceStackItems) && withBreadCrumbs && (
            <div
              className={clsx(styles.breadcrumbs, props.classes?.breadcrumbs)}
            >
              {stack.map((item, idx) => {
                const itemTitle = item.breadcrumbTitle || item.title;
                if (idx > lastBreadCrumbIndex || !itemTitle) {
                  return null;
                }

                return (
                  <React.Fragment key={idx}>
                    <BreadcrumbItem
                      onClick={() => {
                        goBack(idx);
                      }}
                    >
                      {itemTitle}
                    </BreadcrumbItem>{' '}
                    {idx < lastBreadCrumbIndex ? (
                      <span className={styles.divider}>/</span>
                    ) : (
                      ''
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          )}
        </div>
      </div>

      {current?.breadcrumbRightElement}
    </div>
  );
};
