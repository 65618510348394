import { CloseRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { ProviderContext, SnackbarProvider, useSnackbar } from 'notistack';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

type SnackbarContextValue = ProviderContext & {
  hideApiErrors?: boolean;
  setHideApiErrors: (value: boolean) => void;
};
const SnackbarContext = createContext<SnackbarContextValue>(null);

export const PresetSnackbarProvider = (props) => {
  const snackRef = useRef<SnackbarProvider>();
  return (
    <SnackbarProvider
      ref={snackRef}
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      classes={{
        containerAnchorOriginBottomCenter: 'snackbar-container',
      }}
      autoHideDuration={999999}
      preventDuplicate={true}
      action={(snackbarId) => (
        <IconButton onClick={() => snackRef.current?.closeSnackbar(snackbarId)}>
          <CloseRounded sx={{ color: '#fff' }} />
        </IconButton>
      )}
    >
      <SnackbarInternalProvider>{props.children}</SnackbarInternalProvider>
    </SnackbarProvider>
  );
};

const SnackbarInternalProvider = (props: PropsWithChildren<unknown>) => {
  const { children } = props;

  const snackbar = useSnackbar();

  const [hideApiErrors, setHideApiErrors] = useState(false);

  const value = useMemo(() => {
    return { ...snackbar, hideApiErrors, setHideApiErrors };
  }, [snackbar, setHideApiErrors, hideApiErrors]);

  return (
    <SnackbarContext.Provider value={value}>
      {children}
    </SnackbarContext.Provider>
  );
};

export const useCustomSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error(
      'context is null. Make sure you wrapped your component with <PresetSnackbarProvider />'
    );
  }
  return context;
};
