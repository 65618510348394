import { CustomFieldDefinition } from '@work4all/models/lib/Classes/CustomFieldDefinition.entity';
import { IndividualFieldControlType } from '@work4all/models/lib/Enums/IndividualFieldControlType.enum';

import { ParsedCustomFieldConfig } from './types';

const SUPPORTED_FIELD_TYPES: IndividualFieldControlType[] = [
  IndividualFieldControlType.TEXT,
  IndividualFieldControlType.NUMERIC,
  IndividualFieldControlType.DATE_TYPE,
  IndividualFieldControlType.CHECK,
  IndividualFieldControlType.RADIO,
  IndividualFieldControlType.COMBO,
  IndividualFieldControlType.CAPTION,
  IndividualFieldControlType.ZEILENUMBRUCH,
];

export function parseCustomFieldDefinitions(
  fields: CustomFieldDefinition[]
): ParsedCustomFieldConfig[] {
  const parsedFields: ParsedCustomFieldConfig[] = fields
    .filter((field) => {
      return SUPPORTED_FIELD_TYPES.includes(field.fieldType);
    })
    .map((field) => {
      const { name, options } = parseFieldName(field.name);

      return {
        id: field.id,
        fieldType: field.fieldType,
        name,
        options,
        fieldNumber: field.fieldNumber,
      };
    });

  return parsedFields;
}

/**
 * Some types of fields have a list of selectable options. There are stored as
 * part the the field name with some special syntax. This function extracts real
 * field name and options from the name string.
 */
function parseFieldName(fieldName: string): {
  name: string;
  options: string[];
} {
  if (!fieldName.includes(';;;')) {
    return { name: fieldName, options: [] };
  }

  const [name, ...options] = fieldName.split(';;;');

  return {
    name,
    options: options.map((option) => option.split('```')[1]).filter(Boolean),
  };
}
