import styles from './CallModal.module.scss';

import { Close } from '@mui/icons-material';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { CallModalProps } from '@work4all/components/lib/hooks/call-modal/CallModalProps';
import { useCallModalContext } from '@work4all/components/lib/hooks/call-modal/useCallModalContext';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { BusinessPartner } from '@work4all/models/lib/Classes/BusinessPartner.entity';
import { CallMemo } from '@work4all/models/lib/Classes/CallMemo.entity';
import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { settings, useSetting } from '../../../../settings';
import {
  BaseActionButton,
  CheckboxRadioItem,
} from '../../../mask-overlays/locked-inputs';
import { CRMOverlayController } from '../../../mask-overlays/mask-overlay/views/crm/CrmOverlayController';

export const CallModal = ({ data, telephoneNumbers }: CallModalProps) => {
  const { t } = useTranslation();
  const { setCallModal } = useCallModalContext();
  const [callMemoMaskOpen, setCallMemoMaskOpen] = useState<boolean>(false);

  const { title, subtitle1, subtitle2, presetFields } = useMemo(() => {
    let title = '';
    let subtitle1 = null;
    let subtitle2 = null;
    let defaultValue: CallMemo = {};

    if (data.__typename === 'Ansprechpartner') {
      const contact = data as Contact;

      title = contact.displayName;
      subtitle1 = contact?.role || contact.department?.name;
      subtitle2 = contact.businessPartner?.data?.name;

      defaultValue = {
        businessPartner: contact.businessPartner,
        businessPartnerId: contact.businessPartnerId,
        businessPartnerType: contact.businessPartnerType,
        contact: contact,
        contactId: contact.id,
      };
    } else if (data.__typename === 'Benutzer') {
      const user = data as User;

      title = user.displayName;
      subtitle1 = user?.role || user?.departmentName;
    } else {
      const businessPartner = data as Customer | Supplier;

      title = businessPartner.name;

      defaultValue = {
        businessPartner: {
          id: null,
          data: {
            id: businessPartner.id,
            name: businessPartner.name,
          },
        } as BusinessPartner,
        businessPartnerId: businessPartner.id,
        businessPartnerType:
          businessPartner.__typename === 'Kunde'
            ? SdObjType.KUNDE
            : SdObjType.LIEFERANT,
      };
    }

    const presetFields = JSON.stringify(defaultValue);

    return { title, subtitle1, subtitle2, presetFields };
  }, [data]);

  const availableNumbers = useMemo(() => {
    const keys = [
      'directDial',
      'mobile',
      'directFax',
      'central',
      'fax',
      'privateTelephone',
      'privateMobile',
    ];
    return keys
      .map((key) => {
        return telephoneNumbers?.[key]
          ? { key, value: telephoneNumbers?.[key] }
          : null;
      })
      .filter((x) => x !== null);
  }, [telephoneNumbers]);

  const addCallMemo = useSetting(settings.callModalAddCallMemo());

  return (
    <>
      <NavigationOverlay
        initialView={{
          view: (
            <CRMOverlayController
              entity={Entities.callMemo}
              params={{
                presetFields,
              }}
              onAfterSave={() => setCallModal(undefined)}
            />
          ),
        }}
        open={callMemoMaskOpen}
        close={() => {
          setCallMemoMaskOpen(false);
          setCallModal(undefined);
        }}
      />

      <Modal open={!callMemoMaskOpen} onClose={() => setCallModal(undefined)}>
        <Box className={styles.outerWrap}>
          <Box className={styles.box}>
            <div className={styles.top}>
              <div style={{ paddingTop: '0.5rem' }}>
                <Typography variant="h4">
                  {t('CALL_DIALOG.HEADLINE', {
                    name: title,
                  })}
                </Typography>

                {subtitle1 ? (
                  <Typography variant="body2" className={styles.subtitle}>
                    {subtitle1}
                  </Typography>
                ) : null}
                {subtitle2 ? (
                  <Typography variant="body2" className={styles.subtitle}>
                    {subtitle2}
                  </Typography>
                ) : null}
              </div>

              <div>
                <IconButton
                  size="small"
                  onClick={() => setCallModal(undefined)}
                >
                  <Close />
                </IconButton>
              </div>
            </div>

            <Divider className={styles.divider} />

            <div className={styles.phoneNumbersContainer}>
              <div className={styles.phoneNumberContainer}>
                {availableNumbers.length === 0 ? (
                  <Typography
                    className={styles.title}
                    variant="body2"
                    color="text.tertiary"
                    noWrap
                  >
                    {t('CALL_DIALOG.NONE_AVAILABLE')}
                  </Typography>
                ) : (
                  availableNumbers.map((number) => {
                    return (
                      <>
                        <Typography
                          className={styles.title}
                          variant="body2"
                          color="text.tertiary"
                          noWrap
                        >
                          {t('CALL_DIALOG.' + number.key)}
                        </Typography>

                        <BaseActionButton
                          className={styles.button}
                          onClick={() => {
                            if (addCallMemo.value) {
                              setCallMemoMaskOpen(true);
                            } else {
                              setCallModal(undefined);
                            }
                            document.location.href = 'tel:' + number.value;
                          }}
                          title={number.value}
                        />
                      </>
                    );
                  })
                )}
              </div>
            </div>
            <Divider className={styles.divider} />

            <CheckboxRadioItem
              label={t('INPUTS.ADD_CALL_MEMO')}
              defaultChecked={addCallMemo.value}
              onChange={(e) => {
                addCallMemo.set(e.target.checked);
              }}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};
