import React from 'react';
import { TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';
import { TextBuildingBlockPreviewContainer } from '@work4all/components/lib/components/entity-preview/text-building-block-preview';

import { TextBuildingBlock } from '@work4all/models/lib/Classes/TextBuildingBlock.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EntityTable } from './EntityTable';
import schema from './schemata/textBuildingBlock-table-schema.json';
import { Table } from './table/Table';
import { TableNoRowsPlaceholder } from './TableNoRowsPlaceholder';
import { useDataTable } from './use-data-table';
import { useDeleteEntitiesToolbarConfig } from './use-delete-entities-toolbar-config';
import { useTableMaskHandlers } from './use-table-mask-handlers';
import { useUserColumnConfigs } from './use-user-column-configs';

type Props = EntityTable;
const manualGroupBy = false;

const defaultSort = [{ field: 'name', direction: SortDirection.DESCENDING }];

export const TextBuildingBlockTable = React.forwardRef<TableInstance, Props>(
  function TextBuildingBlockTable(props, ref) {
    const { prefilter, onOpenMask, forceRequestFields, ...rest } = props;
    const tableStateBag = useTableStateBag();

    const {
      columnConfigs,
      prepareRowDisplayModifiers,
      data,
      fetchMore,
      total,
      selectedEntity,
      initialSortBy,
      pending,
    } = useDataTable<TextBuildingBlock, Record<string, never>>({
      schema: schema as never,
      tableStateBag,
      prefilter,
      forceRequestFields,
      defaultSort,
    });

    const maskHandlers = useTableMaskHandlers(
      Entities.textBuildingBlock,
      onOpenMask
    );

    const deleteConfig = useDeleteEntitiesToolbarConfig({
      entityType: Entities.textBuildingBlock,
    });

    const [userConfig, userConfigMethods] = useUserColumnConfigs({
      entityType: Entities.textBuildingBlock,
      columnConfigs,
      layout: 'table',
    });

    if (!userConfig) return null;

    return (
      <Table
        pending={pending}
        noRowsRenderer={() => <TableNoRowsPlaceholder />}
        ref={ref}
        areas={{
          right: selectedEntity
            ? {
                content: (
                  <TextBuildingBlockPreviewContainer
                    textBuildingBlockId={selectedEntity.id}
                    onCloseClicked={() =>
                      tableStateBag.tableInstance.toggleAllRowsSelected(false)
                    }
                  />
                ),
              }
            : null,
        }}
        actions={{
          add: maskHandlers.create,
          edit: maskHandlers.edit,
          remove: deleteConfig,
          resetColumns: userConfigMethods.remove,
        }}
        columnConfigs={userConfig}
        manualGroupBy={manualGroupBy}
        initialSortBy={initialSortBy}
        loadMoreItems={fetchMore}
        prepareRowDisplayModifiers={prepareRowDisplayModifiers}
        data={data}
        total={total}
        {...rest}
      />
    );
  }
);
