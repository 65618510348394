import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { OcrInvoiceSupplierGeuessing } from '../Enums/OcrInvoiceSupplierGeuessing.enum';

import { Supplier } from './Supplier.entity';

export class OcrInvoiceSupplierGuessing<T extends EMode = EMode.entity> {
  /** Alias: sdObjMemberCode */
  sdObjMemberCode?: number;
  /** Alias: guessingType */
  guessingType?: OcrInvoiceSupplierGeuessing;
  /** Alias: guessingByValue */
  guessingByValue?: string;
  /** Alias: supplier */
  supplier?: Supplier<T>;

  __typename?: string;
}

const fields: FieldDefinitions<OcrInvoiceSupplierGuessing> = {
  sdObjMemberCode: {
    alias: 'sdObjMemberCode',
  },
  guessingType: {
    alias: 'guessingType',
  },
  guessingByValue: {
    alias: 'guessingByValue',
  },
  supplier: {
    alias: 'supplier',
    entity: Entities.supplier,
  },
  __typename: {
    alias: '__typename',
  },
};

export const ocrInvoiceSupplierGuessingEntityDefinition: EntitiyDefinition<OcrInvoiceSupplierGuessing> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'OcrInvoiceSupplierGuessing',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
