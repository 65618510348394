export enum ReceiptKindInternal {
  OHNE = 'OHNE',
  FAHRTKOSTEN = 'FAHRTKOSTEN',
  UEBERNACHTUNG = 'UEBERNACHTUNG',
  VERPFLEGUNG = 'VERPFLEGUNG',
  BEWIRTUNG = 'BEWIRTUNG',
  KOMMUNIKATION = 'KOMMUNIKATION',
  SONSTIGE = 'SONSTIGE',
  PAUSCHBETRAG = 'PAUSCHBETRAG',
}
