import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

export class BankDetails<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: name */
  name?: string;
  /** Alias: sDObjMemberCode */
  businessPartnerId?: number;
  /** Alias: sDObjType */
  businessPartnerType?: SdObjType;
  /** Alias: hauptbank */
  isMainBank?: boolean;
  /** Alias: iBAN */
  iban?: string;
  /** Alias: bIC */
  bic?: string;
  /** Alias: kontoinhaber */
  accountOwner?: string;
  /** Alias: sEPAGlaeubigerID */
  sepaCreditorId?: string;
  /** Alias: mandatsReferenznummer */
  mandateReferenceNumber?: string;
  /** Alias: mandatsDatum */
  mandateDate?: string;
  /** Alias: sachkonto */
  ledgerAccountId?: number;
  /** Alias: bankleitzahl */
  bankSortCode?: number;
  /** Alias: kontonummer */
  accountNumber?: string;

  __typename?: string;
}

const fields: FieldDefinitions<BankDetails> = {
  id: {
    alias: 'code',
  },
  name: {
    alias: 'name',
  },
  businessPartnerId: {
    alias: 'sDObjMemberCode',
  },
  businessPartnerType: {
    alias: 'sDObjType',
  },
  isMainBank: {
    alias: 'hauptbank',
  },
  iban: {
    alias: 'iBAN',
  },
  bic: {
    alias: 'bIC',
  },
  accountOwner: {
    alias: 'kontoinhaber',
  },
  sepaCreditorId: {
    alias: 'sEPAGlaeubigerID',
  },
  mandateReferenceNumber: {
    alias: 'mandatsReferenznummer',
  },
  mandateDate: {
    alias: 'mandatsDatum',
  },
  ledgerAccountId: {
    alias: 'sachkonto',
  },
  bankSortCode: {
    alias: 'bankleitzahl',
  },
  accountNumber: {
    alias: 'kontonummer',
  },
  __typename: {
    alias: '__typename',
  },
};

export const bankDetailsEntityDefinition: EntitiyDefinition<BankDetails> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'Bankverbindung',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
