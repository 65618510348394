import produce from 'immer';

import { Meta, Path } from '../../../types';
import { createTraverseRows } from '../../../utils';
import { GroupedItem, GroupedItems } from '../types';

import { generateRow } from './generateRow';

const traverse = createTraverseRows<GroupedItem, 'meta.groupByVal', 'subRows'>(
  'meta.groupByVal',
  'subRows'
);

export const replaceRows = (
  rows: GroupedItems,
  options: {
    path: Path;
    offset: number;
    items: Array<{ meta: Meta }>;
    total: number;
  }
) => {
  const { path, offset, items, total } = options;

  return produce(rows, (draft) => {
    const parentId = path[path.length - 1];
    const currentItems = parentId === undefined ? draft : traverse(path, draft);

    for (let i = 0; i < items.length; i++) {
      currentItems[offset + i] = items[i];
    }

    if (currentItems.length > total) {
      currentItems.length = total;
    }

    if (currentItems.length < total) {
      const skeletons = Array.from(
        { length: Math.min(total - currentItems.length, 2) },
        () => generateRow(path, true)
      );

      currentItems.push(...skeletons);
    }
  });
};
