import React, { useMemo } from 'react';
import { TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';
import { EmailSignaturePreviewContainer } from '@work4all/components/lib/components/entity-preview/email-signature-preview';

import { EMailSignature } from '@work4all/models/lib/Classes/EMailSignature.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EntityTable } from './EntityTable';
import schema from './schemata/eMailSignature-table-schema.json';
import { Table } from './table/Table';
import { TableNoRowsPlaceholder } from './TableNoRowsPlaceholder';
import { useDataTable } from './use-data-table';
import { useDeleteEntitiesToolbarConfig } from './use-delete-entities-toolbar-config';
import { useTableMaskHandlers } from './use-table-mask-handlers';
import { useUserColumnConfigs } from './use-user-column-configs';

type Props = EntityTable;
const manualGroupBy = false;

export const EMailSignatureTable = React.forwardRef<TableInstance, Props>(
  function EMailSignatureTable(props, ref) {
    const { prefilter, onOpenMask, forceRequestFields, ...rest } = props;
    const tableStateBag = useTableStateBag();

    const {
      columnConfigs,
      prepareRowDisplayModifiers,
      data,
      fetchMore,
      total,
      selectedEntity,
      initialSortBy,
      pending,
    } = useDataTable<EMailSignature, Record<string, never>>({
      schema: schema as never,
      tableStateBag,
      prefilter,
      forceRequestFields,
    });

    const maskHandlers = useTableMaskHandlers(
      Entities.eMailSignature,
      onOpenMask
    );

    const deleteConfig = useDeleteEntitiesToolbarConfig({
      entityType: Entities.eMailSignature,
    });

    const [userConfig, userConfigMethods] = useUserColumnConfigs({
      entityType: Entities.eMailSignature,
      columnConfigs,
      layout: 'table',
    });

    const dataSorted = useMemo(() => {
      return [...data].sort((a, b) => a.name.localeCompare(b.name));
    }, [data]);

    if (!userConfig) return null;

    return (
      <Table
        pending={pending}
        noRowsRenderer={() => <TableNoRowsPlaceholder />}
        ref={ref}
        areas={{
          right: selectedEntity
            ? {
                content: (
                  <EmailSignaturePreviewContainer
                    emailSignatureId={selectedEntity.id}
                    onCloseClicked={() =>
                      tableStateBag.tableInstance.toggleAllRowsSelected(false)
                    }
                  />
                ),
              }
            : null,
        }}
        actions={{
          add: maskHandlers.create,
          edit: maskHandlers.edit,
          remove: deleteConfig,
          resetColumns: userConfigMethods.remove,
        }}
        columnConfigs={userConfig}
        manualGroupBy={manualGroupBy}
        initialSortBy={initialSortBy}
        loadMoreItems={fetchMore}
        prepareRowDisplayModifiers={prepareRowDisplayModifiers}
        data={dataSorted}
        total={total}
        {...rest}
      />
    );
  }
);
