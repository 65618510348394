import styles from './EmailStatus.module.scss';

import { Edit, Warning } from '@mui/icons-material';
import { Link, Tooltip, Typography } from '@mui/material';
import clsx from 'clsx';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { SaveSendMailJob } from '@work4all/models/lib/Classes/SaveSendMailJob.entity';
import { EMailKind } from '@work4all/models/lib/Enums/EMailKind.enum';

import { SendingAnimation } from '../animations';

// TODO need refactoring as discussed in PR 1191

interface EmailStatusProps {
  kind?: EMailKind;
  saveSendMailJob?: SaveSendMailJob;
  options?: {
    iconOnly?: boolean;
    errorAction?: (e?: unknown) => void;
  };
}

export const EmailStatus = ({
  kind,
  saveSendMailJob,
  options,
}: EmailStatusProps) => {
  const { t } = useTranslation();

  const status = useMemo(() => {
    if (saveSendMailJob?.errorMessages) {
      return 'error';
    }

    if (saveSendMailJob) {
      return 'sending';
    }

    if (kind === EMailKind.ENTWURF || kind === EMailKind.ENTWURF_HTML) {
      return 'draft';
    }

    return;
  }, [kind, saveSendMailJob]);

  if (!status) return null;

  const renderIcon = () => {
    switch (status) {
      case 'error':
        return (
          <Warning
            sx={{
              color: options?.iconOnly ? '#E14E2E' : '#fff',
            }}
          />
        );

      case 'draft':
        return (
          <Edit
            sx={{
              color: options?.iconOnly ? '#EEC764' : '#232735',
            }}
          />
        );

      case 'sending':
        return (
          <div className={styles.sendingIcon}>
            <SendingAnimation
              loop={true}
              autoPlay={true}
              style={{ width: 28 }}
            />
          </div>
        );
    }
  };

  if (options?.iconOnly)
    return (
      <Tooltip title={t(`EMAIL.STATUSES.${status}`)}>{renderIcon()}</Tooltip>
    );

  const Action = (children) => {
    if (status === 'error') {
      return (
        <Link
          component="button"
          type="button"
          variant="caption"
          fontWeight={900}
          onClick={(e) => options?.errorAction?.(e)}
          className={styles.action}
        >
          {children.children}
        </Link>
      );
    }

    return null;
  };

  return (
    <div className={clsx(styles.statusMessage, styles[status])}>
      <div className={styles.icon}>{renderIcon()}</div>

      <Typography variant="caption" className={styles[status]}>
        <Trans
          components={{
            action: <Action />,
          }}
        >
          {t(`EMAIL.STATUSES_MESSAGE.${status}`)}
        </Trans>
      </Typography>
    </div>
  );
};
