import styles from './TaskStatusPicker.module.scss';

import { CheckCircleRounded, CircleTwoTone } from '@mui/icons-material';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TaskStatus } from '@work4all/models/lib/Enums/TaskStatus.enum';

import { Body1 } from '../../../typography/body1/Body1';
import { FixedDataPicker } from '../fixed-data-picker/FixedDataPicker';
import { IPickerProps } from '../types';

export const TaskStatusChangeLookUp = {
  [TaskStatus.OFFEN]: [TaskStatus.OFFEN],
  [TaskStatus.IN_BEARBEITUNG]: [TaskStatus.IN_BEARBEITUNG],
  [TaskStatus.ERLEDIGT]: [TaskStatus.ERLEDIGT],
  [TaskStatus.WARTET]: [TaskStatus.WARTET],
  [TaskStatus.ZURUECK_GESTELLT]: [TaskStatus.ZURUECK_GESTELLT],
};

export type ITaskStatusPickerProps<TMultiple extends boolean> = IPickerProps<
  { id: string; name: string },
  TMultiple
> & { availableStatus?: TaskStatus[] };

export function TaskStatusPicker<TMultiple extends boolean>(
  props: ITaskStatusPickerProps<TMultiple>
) {
  const { availableStatus, ...rest } = props;

  const { t } = useTranslation();

  const dataSet = useMemo(() => {
    let result = [
      {
        id: TaskStatus.OFFEN,
        name: t('TASK_STATUS.' + TaskStatus.OFFEN),
      },
      {
        id: TaskStatus.IN_BEARBEITUNG,
        name: t('TASK_STATUS.' + TaskStatus.IN_BEARBEITUNG),
      },
      {
        id: TaskStatus.WARTET,
        name: t('TASK_STATUS.' + TaskStatus.WARTET),
      },
      {
        id: TaskStatus.ZURUECK_GESTELLT,
        name: t('TASK_STATUS.' + TaskStatus.ZURUECK_GESTELLT),
      },
      {
        id: TaskStatus.ERLEDIGT,
        name: t('TASK_STATUS.' + TaskStatus.ERLEDIGT),
      },
    ];

    if (availableStatus) {
      result = result.filter((x) => availableStatus.includes(x.id));
    }

    return result;
  }, [availableStatus, t]);

  return (
    <FixedDataPicker
      dataSet={dataSet}
      {...rest}
      renderItemContent={(item) => {
        const value = item.id;
        const Icon =
          value === TaskStatus.ERLEDIGT ? CheckCircleRounded : CircleTwoTone;
        return (
          <div className={styles['task-status-cell-root']}>
            <Icon
              className={clsx(styles['check-circle'], {
                [styles['check-circle-success']]: value === TaskStatus.ERLEDIGT,
                [styles['check-circle-edit']]:
                  value === TaskStatus.IN_BEARBEITUNG ||
                  value === TaskStatus.WARTET ||
                  value === TaskStatus.ZURUECK_GESTELLT,
              })}
            />
            <Body1 className={styles['label']}>{item.name}</Body1>
          </div>
        );
      }}
    />
  );
}
