import styles from './Bubble.module.scss';

import React from 'react';

interface IBubbleProps {
  content: string | number;
}

export const Bubble: React.FC<IBubbleProps> = (props) => {
  return <span className={styles['bubble']}>{props.content}</span>;
};
