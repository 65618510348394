import styles from './Right.module.scss';

import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { IImageItem } from '@work4all/components/lib/components/image-gallery/ImageGallery';

import { useDataProvider } from '@work4all/data';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { settings, useSetting } from '../../../../../../../../../../settings';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { CheckboxRadioItem } from '../../../../../../../../locked-inputs';
import { TabPanelSectionProps } from '../../../../../../..';
import { MaskUploadImage } from '../../../../../../../components/mask-upload-image/MaskUploadImage';
import { Panel } from '../../../../../../../components/panel/Panel';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { ContactMaskFormValue } from '../../../../../types';

export const Right: React.FC<TabPanelSectionProps> = (props: {
  mobile: boolean;
}) => {
  const { t } = useTranslation();

  const { register, watch } = useFormContextPlus<ContactMaskFormValue>();
  const { data } = useMaskContext<Contact>();

  const mainContactRequestData = useMemo<DataRequest>(() => {
    const filter = [
      { isMainContact: { $eq: true } },
      { businessPartnerId: { $eq: data?.businessPartnerId } },
      {
        businessPartnerType: {
          $eq: data?.businessPartnerType,
        },
      },
    ];

    const dataFields: Contact = {
      id: null,
      displayName: null,
      isMainContact: null,
    };

    return {
      filter,
      entity: Entities.contact,
      data: dataFields,
    };
  }, [data?.businessPartnerId, data?.businessPartnerType]);

  const requestData = useMemo<DataRequest>(() => {
    const filter = [{ id: { $eq: data?.businessPartnerId } }];

    if (data?.businessPartnerType === 'KUNDE') {
      return {
        entity: Entities.customer,
        data: BUSINESSPARTNER_FIELDS,
        filter,
      };
    }

    return {
      entity: Entities.supplier,
      data: BUSINESSPARTNER_FIELDS,
      filter,
    };
  }, [data?.businessPartnerId]);

  const businessPartnerData = useDataProvider<Customer>(
    requestData,
    !data?.businessPartnerId
  );

  const imgItems = useMemo<IImageItem[]>(() => {
    return (
      data?.attachements?.map((x) => ({
        id: x?.id?.toString(),
        fileName: x?.fileInfos?.fileEntityFilename,
        fileSize: x?.fileInfos?.fileSize,
        imgThumbUrl: x?.fileInfos?.downloadUrl,
        imgUrl: x?.fileInfos?.downloadUrl,
      })) || []
    );
  }, [data?.attachements]);

  const mainContactData = useDataProvider<Contact>(mainContactRequestData);

  const isMainContact = watch('isMainContact');
  const showFunctionInAddress = watch('showFunctionInAddress');
  const showDepartmentInAddress = watch('showDepartmentInAddress');
  const isLayedOff = watch('layedOff');

  const { value: mainContactFlag } = useSetting(settings.mainContactFlag());

  return (
    <Panel
      className={clsx(styles.right, {
        [styles.desktop]: !props.mobile,
        [styles.mobile]: props.mobile,
      })}
    >
      <Collapse defaultOpen={!props.mobile} title={t('MASK.IMAGE')}>
        <MaskUploadImage
          disabled={true}
          imgItems={imgItems}
          allowEnlargeImage={false}
        />
      </Collapse>
      <Collapse defaultOpen={!props.mobile} title={t('MASK.SETTINGS')}>
        <CheckboxRadioItem
          label={t('INPUTS.COMPANY_MAIN_CONTACT')}
          caption={
            isMainContact
              ? undefined
              : `${t('COMMON.CURRENT')}: ${
                  mainContactData?.data?.[0]?.displayName || t('COMMON.NOONE')
                }`
          }
          {...register('isMainContact')}
          defaultChecked={
            data?.isMainContact === undefined &&
            businessPartnerData?.data[0]?.contactList?.length === 0 &&
            !mainContactFlag
              ? true
              : data?.isMainContact
          }
        />
        <CheckboxRadioItem
          label={t('INPUTS.SHOW_FUNCTION_IN_ADDRESS')}
          {...register('showFunctionInAddress')}
          defaultChecked={Boolean(showFunctionInAddress)}
        />
        <CheckboxRadioItem
          label={t('INPUTS.SHOW_DEPARTMENT_IN_ADDRESS')}
          {...register('showDepartmentInAddress')}
          defaultChecked={Boolean(showDepartmentInAddress)}
        />
        <CheckboxRadioItem
          label={t('INPUTS.CONTACT_HAS_LEFT')}
          {...register('layedOff')}
          defaultChecked={Boolean(isLayedOff)}
        />
      </Collapse>
      {/* <Collapse defaultOpen={true} title={t('MASK.MARKETING')}>
        <CheckboxRadioItem
          label={t('INPUTS.NO_SERIAL_EMAILS')}
          {...register('noSerialEmails')}
          defaultChecked={contact?.serialEmailsForbidden}
        />
        <CheckboxRadioItem
          label={t('INPUTS.NO_SERIAL_LETTER')}
          {...register('noSerialLetters')}
          defaultChecked={contact?.serialLettersForbidden}
        />
      </Collapse> */}
    </Panel>
  );
};
const BUSINESSPARTNER_FIELDS: Customer | Supplier = {
  id: null,
  contactList: [
    {
      id: null,
      displayName: null,
      phoneNumber: null,
      phoneNumber2: null,
      phoneNumber3: null,
      phoneNumberPrivate: null,
      mobileNumber: null,
      mobileNumberPrivate: null,
      eMail: null,
      eMailPrivate: null,
      showFunctionInAddress: null,
      showDepartmentInAddress: null,
      layedOff: null,
      role: null,
      departmentId: null,
      department: {
        id: null,
        customerId: null,
        supplierId: null,
        name: null,
        insertTime: null,
        updateTime: null,
      },
      salutation: {
        id: null,
        isMale: null,
        isFemale: null,
      },
      firstName: null,
      name: null,
      note: null,
      businessPartnerType: null,
      businessPartnerId: null,
      categoryAssignmentList: [
        {
          id: null,
          categoryId: null,
          categoryKindName: null,
          categoryName: null,
        },
      ],
      attachements: [
        {
          id: null,
          index: null,
          objMemberCode: null,
          type: null,
          fileInfos: {
            fileEntityFilename: null,
            downloadUrl: null,
            previewUrl: null,
            fileRequestType: null,
            downloadMimeType: null,
            previewMimeType: null,
            fileSize: null,
          },
        },
      ],
    },
  ],
};
