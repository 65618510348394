import styles from './FileListPreview.module.scss';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ShareIcon from '@mui/icons-material/Share';
import { IconButton } from '@mui/material';
import { MouseEventHandler } from 'react';

import { ReactComponent as EditCopyIcon } from '@work4all/assets/icons/edit-pdf.svg';

import { PreviewTitle } from '../components';

import { FileListGalleryPagination } from './FileListGalleryPagination';
import {
  FileListPreviewContent,
  FileListPreviewContentProps,
} from './FileListPreviewContent';
import { useSelectedIndex } from './use-selected-index';

export interface FileListPreviewProps
  extends Omit<
    FileListPreviewContentProps,
    'selectedIndex' | 'onSelectedIndexChange'
  > {
  title: string;
  onCloseClicked?: MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onPdfEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onShareClicked?: () => void;
  onDeleteClicked?: MouseEventHandler<HTMLButtonElement>;
}

export function FileListPreview(props: FileListPreviewProps) {
  const {
    title,
    files,
    iconProps,
    onCloseClicked,
    onEditClicked,
    onPdfEditClicked,
    onShareClicked,
    onDeleteClicked,
  } = props;

  const [_index, setIndex] = useSelectedIndex({ length: files.length });
  const index = files.length === 1 ? 0 : _index;

  function renderHeader() {
    return (
      <PreviewTitle
        onCloseClicked={(event) => {
          //eslint-disable-next-line
          //@ts-expect-error type not assignable to parameter 'MouseEvent<HTMLButtonElement, MouseEvent>'
          onCloseClicked(event);
        }}
        onShareClicked={onShareClicked}
        actions={
          <>
            {onPdfEditClicked ? (
              <IconButton
                color="primary"
                onClick={onPdfEditClicked}
                className={styles['fileheader-icon']}
                size="large"
              >
                <EditCopyIcon />
              </IconButton>
            ) : null}

            {onShareClicked && (
              <IconButton size="large" color="primary" onClick={onShareClicked}>
                <ShareIcon />
              </IconButton>
            )}
            {onEditClicked ? (
              <IconButton
                color="primary"
                onClick={onEditClicked}
                className={styles['fileheader-icon']}
                size="large"
              >
                <EditIcon />
              </IconButton>
            ) : null}

            {onDeleteClicked ? (
              <IconButton
                color="primary"
                onClick={onDeleteClicked}
                className={styles['fileheader-icon']}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            ) : null}

            {files.length > 1 && index !== -1 && (
              <FileListGalleryPagination
                page={index}
                count={files.length}
                onChange={setIndex}
              />
            )}
          </>
        }
      >
        {title}
      </PreviewTitle>
    );
  }

  function renderContent() {
    return (
      <FileListPreviewContent
        files={files}
        selectedIndex={index}
        onSelectedIndexChange={setIndex}
        iconProps={iconProps}
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      {renderHeader()}
      {renderContent()}
    </div>
  );
}
