import { Tooltip } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckboxRadioItem } from '../containers/mask-overlays/locked-inputs';
import { SuggestionsContext } from '../contexts/SuggestionsContext';
import { settings, useSetting } from '../settings';

interface SuggestionsProviderProps {
  children: React.ReactNode;
}

export const SuggestionsProvider = ({ children }: SuggestionsProviderProps) => {
  const { t } = useTranslation();

  const [hiddenSuggestions, setHiddenSuggestions] = useState<number[]>([]);
  const hideSuggestion = (id: number) => {
    setHiddenSuggestions((hiddenSuggestions) => [...hiddenSuggestions, id]);
  };

  const { set: setShowSuggestions, value: showSuggestions } = useSetting(
    settings.showTimeTrackingSuggestions()
  );

  const SuggestionsToggleIcon = () => {
    return (
      <Tooltip title={t('TIME_TRACKER.SHOW_APPOINTMENT_SUGGESTIONS_TOOLTIP')}>
        <div>
          <CheckboxRadioItem
            checked={showSuggestions}
            label={t('TIME_TRACKER.SHOW_APPOINTMENT_SUGGESTIONS')}
            onChange={() => setShowSuggestions(!showSuggestions)}
          />
        </div>
      </Tooltip>
    );
  };

  return (
    <SuggestionsContext.Provider
      value={{
        showSuggestions,
        setShowSuggestions,
        hiddenSuggestions,
        hideSuggestion,
        SuggestionsToggleIcon,
      }}
    >
      {children}
    </SuggestionsContext.Provider>
  );
};
