import { useContext, useEffect, useMemo, useState } from 'react';

import { CustomFilterMapping, useTableStateBag } from '@work4all/components';
import { pickerToQuery } from '@work4all/components/lib/dataDisplay/basic-table/utils/filters/filterConversion';

import { nameof } from '@work4all/data/lib/helper/nameof';

import { ChronoFileItem } from '@work4all/models/lib/Classes/ChronoFileItem.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';
import { FileType } from '@work4all/models/lib/File';
import { ITableSchema } from '@work4all/models/lib/table-schema/table-schema';

import { useDataTable } from '../../../../../../components/data-tables/use-data-table';
import { useUserColumnConfigs } from '../../../../../../components/data-tables/use-user-column-configs';
import { useSelectedContact } from '../../card-widgets/hooks/useEntityLinks';
import { FileContext } from '../../FileContext';
import { ContactCell } from '../cells/ContactCell';
import { DescriptionCell } from '../cells/DescriptionCell';
import { EmptyCell } from '../cells/EmptyCell';
import { EntityCell } from '../cells/EntityCell';
import { ProjectCell } from '../cells/ProjectCell';
import { SumCell } from '../cells/SumCell';
import { UserCell } from '../cells/UserCell';

import { useChronoFileRequestFields } from './useChronoFileRequestFields';

type ICellConfig = {
  type:
    | 'Empty'
    | 'Contact'
    | 'Entity'
    | 'Sum'
    | 'Project'
    | 'User'
    | 'Description';
};

type IChronoFileItemColumns = {
  Empty?: ICellConfig;
  Entity?: ICellConfig;
  Contact?: ICellConfig;
  Sum?: ICellConfig;
  Project?: ICellConfig;
  User?: ICellConfig;
  Description?: ICellConfig;
};

export interface IAggregatedList {
  id?: string | number;
  type?: FileType;
  layout?: 'table' | 'cards';
  schema: ITableSchema<never>;
}

export const useChronoFileList = (props: IAggregatedList) => {
  const { layout, schema, id, type } = props;
  const tableStateBag = useTableStateBag();

  const defaultSort = useMemo(() => {
    return [
      {
        field: nameof<ChronoFileItem>('dateTime'),
        direction: SortDirection.DESCENDING,
      },
    ];
  }, []);

  const { contactMetadata } = useContext(FileContext);
  const selectedContact = useSelectedContact(contactMetadata);

  const [lastSelected, setLastSelected] = useState(selectedContact);
  useEffect(() => {
    setLastSelected(selectedContact);
  }, [selectedContact]);

  const contactColumn = tableStateBag.columnsById['Contact'];
  useEffect(() => {
    const current = contactColumn?.filterValue?.value || [];
    if (contactColumn && selectedContact) {
      contactColumn.setFilter({
        value: [...current, selectedContact],
        filterType: contactColumn.filterType,
      });
    } else if (!selectedContact && lastSelected) {
      const existOnFilter = current.find((x) => x.id === lastSelected.id);
      if (existOnFilter) {
        const value = current.filter((x) => x.id !== lastSelected.id);
        if (value.length) {
          contactColumn.setFilter({
            value: current.filter((x) => x.id !== lastSelected.id),
            filterType: contactColumn.filterType,
          });
        } else {
          contactColumn.setFilter(null);
        }
      }
    }
  }, [selectedContact, contactColumn, lastSelected]);

  const customFilterMapping: CustomFilterMapping = useMemo(
    () => ({
      ChronoContactPicker: (id: string, filterValue: unknown) => {
        const query = pickerToQuery(
          //eslint-disable-next-line
          //@ts-ignore
          Array.isArray(filterValue) ? filterValue : [filterValue]
        );
        return [['contactCode', query]];
      },
    }),
    []
  );

  const prefilter = useMemo(() => {
    if (type === FileType.PROJECT) {
      return {
        $or: [
          { projectCode: { $eq: id.toString() } },
          { 'project.parentProject.id': { $eq: id.toString() } },
        ],
      };
    }

    const baseFilter: unknown[] = [
      { sdObjMemberCode: { $eq: id } },
      {
        sdObjType: { $eq: convertFileTypeToSdObjType(type) },
      },
    ];
    if (selectedContact)
      baseFilter.push({
        //eslint-disable-next-line
        //@ts-ignore
        contactCode: { $eq: selectedContact.id },
      });

    return baseFilter;
  }, [id, type, selectedContact]);

  const forceRequestFields = useChronoFileRequestFields();

  const config = useDataTable<ChronoFileItem, IChronoFileItemColumns>({
    layout,
    schema,
    tableStateBag,
    defaultSort,
    //eslint-disable-next-line
    //@ts-ignore
    prefilter,
    forceRequestFields,
    customFilterMapping,
    cells: {
      Empty: EmptyCell,
      Entity: EntityCell,
      Contact: ContactCell,
      Sum: SumCell,
      Project: ProjectCell,
      User: UserCell,
      Description: DescriptionCell,
    },
  });

  const memoizedColumnConfigs = useMemo(() => {
    if (type === FileType.PROJECT) {
      const updatedColumnConfigs = config.columnConfigs.map((column) => {
        if (column.id === 'Contact') {
          return { ...column, filterType: undefined };
        }
        return column;
      });
      return updatedColumnConfigs;
    }
    return config.columnConfigs;
  }, [type, config.columnConfigs]);

  const [userConfig, userConfigMethods] = useUserColumnConfigs({
    layout,
    entityType: schema.entity,
    columnConfigs: memoizedColumnConfigs,
  });

  return {
    ...config,
    userConfig,
    userConfigMethods,
  };
};

function convertFileTypeToSdObjType(entity: FileType): SdObjType {
  switch (entity) {
    case FileType.CUSTOMER:
      return SdObjType.KUNDE;
    case FileType.SUPPLIER:
      return SdObjType.LIEFERANT;
    case FileType.PROJECT:
      return SdObjType.PROJEKT;
    default:
      throw new Error(`Cannot convert entity "${entity}" to \`SdObjType\``);
  }
}
