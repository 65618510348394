import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { GroupQueryResultRow } from './GroupQueryResultRow.entity';

export class GroupQueryResult<T extends EMode = EMode.entity> {
  /** Alias: data */
  data?: GroupQueryResultRow<T>[];
  /** Alias: page */
  page?: number;
  /** Alias: size */
  size?: number;
  /** Alias: totalCount */
  totalCount?: number;

  __typename?: string;
}

const fields: FieldDefinitions<GroupQueryResult> = {
  data: {
    alias: 'data',
    entity: Entities.groupQueryResultRow,
  },
  page: {
    alias: 'page',
  },
  size: {
    alias: 'size',
  },
  totalCount: {
    alias: 'totalCount',
  },
  __typename: {
    alias: '__typename',
  },
};

export const groupQueryResultEntityDefinition: EntitiyDefinition<GroupQueryResult> =
  {
    local: {},
    remote: {
      queryName: 'groupBy',
      fragmentName: 'GroupQueryResult',
      withPaginationWrapper: false,
      params: [
        { name: 'query', type: 'GroupQuery!' },
        { name: 'page', type: 'Int' },
        { name: 'size', type: 'Int' },
      ],
    },
    fieldDefinitions: fields,
  };
