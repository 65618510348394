import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { DeleteErrorType } from '../Enums/DeleteErrorType.enum';
import { EMode } from '../Enums/EMode.enum';

export class DeleteErrorMessage<T extends EMode = EMode.entity> {
  /** Alias: primaryKey */
  primaryKey?: string;
  /** Alias: message */
  message?: string;
  /** Alias: type */
  type?: DeleteErrorType;

  __typename?: string;
}

const fields: FieldDefinitions<DeleteErrorMessage> = {
  primaryKey: {
    alias: 'primaryKey',
  },
  message: {
    alias: 'message',
  },
  type: {
    alias: 'type',
  },
  __typename: {
    alias: '__typename',
  },
};

export const deleteErrorMessageEntityDefinition: EntitiyDefinition<DeleteErrorMessage> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'DeleteErrorMessage',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
