import { CellProps } from 'react-table';

import { EmailStatus } from '@work4all/components/lib/components/email-status/EmailStatus';

import { EMail } from '@work4all/models/lib/Classes/EMail.entity';
import { SaveSendMailJob } from '@work4all/models/lib/Classes/SaveSendMailJob.entity';

export type IEmailAttachmentCellProps = CellProps<EMail, SaveSendMailJob>;

export const EmailStatusCell = (props: IEmailAttachmentCellProps) => {
  const kind = props.row.original.kind;
  const saveSendMailJob = props.value;

  return (
    <EmailStatus
      kind={kind}
      saveSendMailJob={saveSendMailJob}
      options={{
        iconOnly: true,
      }}
    />
  );
};
