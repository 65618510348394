// Keep track of already reported columns to avoid duplicate errors in the
// console.
const reportedMissingColumns = new Set<string>();

/**
 * Report a warning when trying to render a cell for a non-existent column. Will
 * `console.error` a debug message that should help you fix invalid table
 * configurations.
 *
 * Will only report the warning for a specific column once and will ignore all
 * other calls. As the `Row` component doesn't know about the  entity type used
 * in the table, the columns are simply referenced by the id.
 *
 * In some cases this can lead to missing columns being left unreported if the
 * same column is missing in 2 or more tables (the warning will only be reported
 * once). This should not be an issue, since this is intended to just be a
 * helpful debugging message and not an application critical component.
 */
export function reportMissingColumn(id: string): void {
  if (reportedMissingColumns.has(id)) {
    return;
  }

  console.error(
    [
      `Missing column definition for column "${id}".`,
      'You tried to render a column with this id, but it was not found in the table row instance.',
      'You probably forgot to add this column to "columns" array in the table schema.',
    ].join(' ')
  );

  reportedMissingColumns.add(id);
}
