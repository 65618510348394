import styles from '../EntityPicker.module.scss';

import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { WebsiteFavicon } from '../../website-favicon/WebsiteFavicon';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type ICustomerPickerProps<TMultiple extends boolean> = IPickerProps<
  Customer,
  TMultiple
>;

export function CustomerPicker<TMultiple extends boolean>(
  props: ICustomerPickerProps<TMultiple>
) {
  const { data, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...FIELDS, ...data };
  }, [data]);

  const renderItem = (item: Customer) => (
    <Tooltip title={item.name}>
      <Typography variant="body2" noWrap>
        <Box display="flex" gap="0.5rem" alignItems="center">
          {<WebsiteFavicon website={item?.website} />}{' '}
          {item.number ? (
            <span className={styles.secondaryInfo}> {item.number} | </span>
          ) : null}
          {item.name}
        </Box>
      </Typography>
    </Tooltip>
  );

  return (
    <ListEntityPicker
      entity={Entities.customer}
      data={allFields}
      filterBy={['name', 'number']}
      sortBy="name"
      useSearchHistory
      {...rest}
      renderItemContent={renderItem}
    />
  );
}

const FIELDS: Customer = {
  id: null,
  name: null,
  website: null,
  number: null,
  mainContact: {
    id: null,
    name: null,
    firstName: null,
    displayName: null,
  },
};
