import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { IActionBarProps } from './IActionsBarActions';

export type MoreMenuProps = Pick<
  IActionBarProps,
  'disabled' | 'group' | 'columns' | 'createWidget'
> & {
  buttonRef?: React.ForwardedRef<HTMLButtonElement>;
};

export function MoreMenu(props: MoreMenuProps) {
  const { buttonRef } = props;

  const { t } = useTranslation();

  const labels = useMemo(() => {
    return {
      columns: t('MASK.COLUMNS'),
      group: t('INPUTS.GROUPING'),
      createWidget: t('INPUTS.CREATE_WIDGET'),
      settings: t('SETTINGS.SETTINGS'),
    };
  }, [t]);

  const { handleClick, ...menuState } = usePopoverState('bottom');
  const { onClose: closeMenu } = menuState;

  const withAutoClose =
    (handler: React.MouseEventHandler<HTMLElement>) =>
    (event: React.MouseEvent<HTMLElement>) => {
      handler(event);
      closeMenu();
    };

  const action = (
    actionType: 'group' | 'columns' | 'createWidget' | 'settings'
  ) => {
    const config = props[actionType];

    if (!config) {
      return null;
    }

    if (typeof config === 'function') {
      const onClick = config;
      const disabled = props.disabled;
      const label = labels[actionType];

      return (
        <MenuItem disabled={disabled} onClick={withAutoClose(onClick)}>
          <ListItemText>{label}</ListItemText>
        </MenuItem>
      );
    } else {
      const onClick = config.onClick;
      const disabled = props.disabled || config.disabled;
      const label = labels[actionType];

      return (
        <MenuItem disabled={disabled} onClick={withAutoClose(onClick)}>
          <ListItemText>{label}</ListItemText>
        </MenuItem>
      );
    }
  };

  return (
    <>
      <Tooltip title={t('MASK.OPTIONS')} placement="top">
        <IconButton
          ref={buttonRef}
          size="medium"
          color="primary"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>

      <Menu {...menuState}>
        {action('columns')}
        {action('group')}
        {action('createWidget')}
        {action('settings')}
      </Menu>
    </>
  );
}
