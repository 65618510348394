import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputUrlaub<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: number;
  /** Alias: benutzerCode */
  benutzerCode?: number;
  /** Alias: datum */
  datum?: string;
  /** Alias: menge */
  menge?: number;
  /** Alias: notiz */
  notiz?: string;
  /** Alias: urlaubsArtCode */
  urlaubsArtCode?: number;
  /** Alias: datumAntrag */
  datumAntrag?: string;
  /** Alias: datumGenehmigung */
  datumGenehmigung?: string;
  /** Alias: benutzerCodeGenehmigung */
  benutzerCodeGenehmigung?: number;
  /** Alias: vacationDayPosition */
  vacationDayPosition?: any;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];
  /** Alias: vacationRequestId */
  vacationRequestId?: string;

  __typename?: string;
}

const fields: FieldDefinitions<InputUrlaub> = {
  code: {
    alias: 'code',
  },
  benutzerCode: {
    alias: 'benutzerCode',
  },
  datum: {
    alias: 'datum',
  },
  menge: {
    alias: 'menge',
  },
  notiz: {
    alias: 'notiz',
  },
  urlaubsArtCode: {
    alias: 'urlaubsArtCode',
  },
  datumAntrag: {
    alias: 'datumAntrag',
  },
  datumGenehmigung: {
    alias: 'datumGenehmigung',
  },
  benutzerCodeGenehmigung: {
    alias: 'benutzerCodeGenehmigung',
  },
  vacationDayPosition: {
    alias: 'vacationDayPosition',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customFields: {
    alias: 'customFields',
  },
  vacationRequestId: {
    alias: 'vacationRequestId',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputUrlaubEntityDefinition: EntitiyDefinition<InputUrlaub> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'InputUrlaub',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
