import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { MailboxLoadErrorType } from '../Enums/MailboxLoadErrorType.enum';
import { MailboxType } from '../Enums/MailboxType.enum';

import { MailboxFolder } from './MailboxFolder.entity';

export class Mailbox<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: mailboxPrimaryAddress */
  mailboxPrimaryAddress?: string;
  /** Alias: folder */
  folder?: MailboxFolder<T>[];
  /** Alias: mailboxType */
  mailboxType?: MailboxType;
  /** Alias: loadErrorType */
  loadErrorType?: MailboxLoadErrorType;
  /** Alias: loadErrorMessage */
  loadErrorMessage?: string;

  __typename?: string;
}

const fields: FieldDefinitions<Mailbox> = {
  id: {
    alias: 'id',
  },
  mailboxPrimaryAddress: {
    alias: 'mailboxPrimaryAddress',
  },
  folder: {
    alias: 'folder',
    entity: Entities.mailboxFolder,
  },
  mailboxType: {
    alias: 'mailboxType',
  },
  loadErrorType: {
    alias: 'loadErrorType',
  },
  loadErrorMessage: {
    alias: 'loadErrorMessage',
  },
  __typename: {
    alias: '__typename',
  },
};

export const mailboxEntityDefinition: EntitiyDefinition<Mailbox> = {
  local: {},
  remote: {
    queryName: 'readMailboxes',
    fragmentName: 'Mailbox',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
