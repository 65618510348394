import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { CheckInOut } from '../Enums/CheckInOut.enum';
import { EMode } from '../Enums/EMode.enum';
import { TimeStampKind } from '../Enums/TimeStampKind.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputKommtGehtZeitstempel<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: number;
  /** Alias: art */
  art?: TimeStampKind;
  /** Alias: benutzerCode */
  benutzerCode?: number;
  /** Alias: checkInOut */
  checkInOut?: CheckInOut;
  /** Alias: lastModificationBenutzerCode */
  lastModificationBenutzerCode?: number;
  /** Alias: korrekturKontoCode */
  korrekturKontoCode?: number;
  /** Alias: lastModificationDate */
  lastModificationDate?: string;
  /** Alias: zeitstempel */
  zeitstempel?: string;
  /** Alias: automaticZeitstempel */
  automaticZeitstempel?: string;
  /** Alias: notiz */
  notiz?: string;
  /** Alias: differenzMinuten */
  differenzMinuten?: number;
  /** Alias: benutzerMaxMinutenProTag */
  benutzerMaxMinutenProTag?: number;
  /** Alias: benutzerPausenMinuten */
  benutzerPausenMinuten?: number;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputKommtGehtZeitstempel> = {
  code: {
    alias: 'code',
  },
  art: {
    alias: 'art',
  },
  benutzerCode: {
    alias: 'benutzerCode',
  },
  checkInOut: {
    alias: 'checkInOut',
  },
  lastModificationBenutzerCode: {
    alias: 'lastModificationBenutzerCode',
  },
  korrekturKontoCode: {
    alias: 'korrekturKontoCode',
  },
  lastModificationDate: {
    alias: 'lastModificationDate',
  },
  zeitstempel: {
    alias: 'zeitstempel',
  },
  automaticZeitstempel: {
    alias: 'automaticZeitstempel',
  },
  notiz: {
    alias: 'notiz',
  },
  differenzMinuten: {
    alias: 'differenzMinuten',
  },
  benutzerMaxMinutenProTag: {
    alias: 'benutzerMaxMinutenProTag',
  },
  benutzerPausenMinuten: {
    alias: 'benutzerPausenMinuten',
  },
  customFields: {
    alias: 'customFields',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputKommtGehtZeitstempelEntityDefinition: EntitiyDefinition<InputKommtGehtZeitstempel> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputKommtGehtZeitstempel',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
