import { searchText } from '@work4all/components/lib/utils/search-text-result/SearchTextResult';

import type {
  MatchedNavigationGroup,
  MatchedNavigationLink,
  MatchedNavigationSubgroup,
  NavigationGroup,
  NavigationLink,
  NavigationSubgroup,
} from './types';

function searchItem(
  item: NavigationLink,
  filter: string
): MatchedNavigationLink {
  const result = searchText(item.name, filter);

  return {
    ...item,
    result,
  };
}

function searchSubgroup(
  subgroup: NavigationSubgroup,
  filter: string
): MatchedNavigationSubgroup {
  const result = searchText(subgroup.name ?? '', filter);

  const matchedLinks = subgroup.links.map((item) => {
    return searchItem(item, filter);
  });

  const hasMatchingLinks = matchedLinks.some((link) => link.result.match);
  const isMatch = result.match;

  return {
    ...subgroup,
    links: matchedLinks,
    result,
    // If any of the links matched, return the actual number of matched links.
    // Otherwise, if the subgroup itself is matched, return all links. If
    // nothing matched, return 0.
    matches: hasMatchingLinks
      ? matchedLinks.filter((link) => link.result.match).length
      : isMatch
      ? matchedLinks.length
      : 0,
  };
}

function searchGroup(
  group: NavigationGroup,
  filter: string
): MatchedNavigationGroup {
  const result = searchText(group.name, filter);

  const matchedSubgroups = group.subgroups.map((group) =>
    searchSubgroup(group, filter)
  );

  const hasMatchingSubgroups = matchedSubgroups.some(
    (subgroup) => subgroup.matches > 0
  );
  const isMatch = result.match;

  return {
    ...group,
    subgroups: matchedSubgroups,
    result,
    // If any of the links or subgroups matched, return the actual number of matched links.
    // Otherwise, if the group itself is matched, return all links. If
    // nothing matched, return 0.
    matches: hasMatchingSubgroups
      ? matchedSubgroups.reduce((acc, subgroup) => acc + subgroup.matches, 0)
      : isMatch
      ? matchedSubgroups.reduce(
          (acc, subgroup) => acc + subgroup.links.length,
          0
        )
      : 0,
  };
}

export function filterNavGroups(
  groups: NavigationGroup[],
  filter: string
): MatchedNavigationGroup[] {
  if (!filter) return groups;
  return groups.map((group) => searchGroup(group, filter));
}
