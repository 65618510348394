import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputAttachementAdd } from './InputAttachementAdd.entity';
import { InputErpAnhangAttachementModify } from './InputErpAnhangAttachementModify.entity';

export class InputErpAnhangAttachementsRelation<
  T extends EMode = EMode.entity
> {
  /** Alias: remove */
  remove?: number[];
  /** Alias: add */
  add?: InputAttachementAdd<T>[];
  /** Alias: modify */
  modify?: InputErpAnhangAttachementModify<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputErpAnhangAttachementsRelation> = {
  remove: {
    alias: 'remove',
  },
  add: {
    alias: 'add',
  },
  modify: {
    alias: 'modify',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputErpAnhangAttachementsRelationEntityDefinition: EntitiyDefinition<InputErpAnhangAttachementsRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputErpAnhangAttachementsRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
