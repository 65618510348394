import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OkAlert } from '@work4all/components/lib/dataDisplay/alert/OkAlert';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useDragLayer } from '../../DragLayerProvider';

interface Props {
  entity: Entities;
}

export const UnauhtorizedOverlayView: React.FC<Props> = (props: Props) => {
  const { entity } = props;
  const { t } = useTranslation();
  const { clear } = useDragLayer();

  const [open, setOpen] = useState(true);
  return (
    <OkAlert
      open={open}
      cancel={() => {
        setOpen(false);
        clear();
      }}
      header={t('ALERTS.PERMISSION_READ_FAILED')}
      body={
        entity === Entities.customer
          ? t('ALERTS.NOT_AUTHORIZED_TO_CREATE_CUSTOMER')
          : t('ALERTS.NOT_AUTHORIZED_TO_CREATE_SUPPLIER')
      }
    />
  );
};
