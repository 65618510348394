import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Project } from './Project.entity';
import { User } from './User.entity';

export class ProjectAccessGroup<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: name */
  name?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: users */
  users?: User<T>[];
  /** Alias: projects */
  projects?: Project<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<ProjectAccessGroup> = {
  id: {
    alias: 'code',
  },
  name: {
    alias: 'name',
  },
  insertTime: {
    alias: 'insertTime',
  },
  updateTime: {
    alias: 'updateTime',
  },
  users: {
    alias: 'users',
    entity: Entities.user,
  },
  projects: {
    alias: 'projects',
    entity: Entities.project,
  },
  __typename: {
    alias: '__typename',
  },
};

export const projectAccessGroupEntityDefinition: EntitiyDefinition<ProjectAccessGroup> =
  {
    local: {},
    remote: {
      queryName: 'getProjectAccessGroups',
      fragmentName: 'ProjectAccessGroup',
      withPaginationWrapper: true,
      mutations: {
        upsert: {
          mutationName: 'upsertProjectAccessGroup',
          args: [
            { name: 'input', type: 'InputProjectAccessGroup!' },
            { name: 'relations', type: 'InputProjectAccessGroupRelation' },
            { name: 'drySave', type: 'Boolean' },
          ],
        },
      },
      params: [
        { name: 'querySize', type: 'Int' },
        { name: 'queryPage', type: 'Int' },
        { name: 'querySortOrder', type: 'SortOrderStyle' },
        { name: 'querySortBy', type: 'String' },
        { name: 'filter', type: 'String' },
      ],
    },
    fieldDefinitions: fields,
  };
