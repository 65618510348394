import styles from './TableRow.module.scss';

import clsx from 'clsx';
import React, { ForwardedRef, forwardRef } from 'react';

import { ITableRowComponentProps } from '../../types';

// FORWARD REF
export const TableRow = forwardRef(function (
  props: ITableRowComponentProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const {
    children,
    isSelected,
    isGrouped,
    className,
    displayModifiers = {},
    cardsView = false,
    ...rest
  } = props;

  return (
    <div
      ref={ref}
      className={clsx(
        styles.row,
        {
          [styles.card]: cardsView,
          [styles.grouped]: isGrouped,
          [styles.bold]: displayModifiers?.isBold,
          [styles.faded]: displayModifiers?.isFaded,
          [styles.bgShade1]: displayModifiers?.isShade1,
          [styles.bgShade2]: displayModifiers?.isShade2,
          [styles.bgShade3]: displayModifiers?.isShade3,
          [styles.bgShade4]: displayModifiers?.isShade4,
          [styles.bgShade5]: displayModifiers?.isShade5,
          [styles.bgShade6]: displayModifiers?.isShade6,
        },

        isSelected && styles['row--selected'],
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
});
