import WidgetsIcon from '@mui/icons-material/Widgets';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Base } from '../base/Base';
import { BaseActionProps } from '../types';

export const CreateWidget: React.FC<BaseActionProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Base title={t('INPUTS.CREATE_WIDGET')} icon={<WidgetsIcon />} {...props} />
  );
};
