import styles from './PositionsBillOfMaterials.module.scss';

import { Box, Button, Checkbox, Typography } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InfoIcon } from '@work4all/assets/icons/info.svg';

import { Dialog } from '@work4all/components';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';

import { Article } from '@work4all/models/lib/Classes/Article.entity';

import { settings, useSetting } from '../../../../../../../../../../settings';
import { PositionsBillOfMaterialsValues } from '../hooks/use-bom-dialog';

import { BomVariantExample } from './BomVariantExample';
import { BOM_VARIANT_LOOK_UP, mapBomVariantToOption } from './BomVariantPicker';
import { BomVariantPickerField } from './BomVariantPickerField';

interface PositionsBillOfMaterialsProps {
  article: Article | null;
  onAddArticle: (context: PositionsBillOfMaterialsValues) => void;
  onClose: () => void;
}

export const PositionsBillOfMaterials = (
  props: PositionsBillOfMaterialsProps
) => {
  const { article, onAddArticle, onClose } = props;
  const articleId = article?.id;
  const { t } = useTranslation();

  const { value } = useSetting(settings.bomVariants());

  const { control, watch, reset } = useForm<PositionsBillOfMaterialsValues>({
    defaultValues: {
      variant: mapBomVariantToOption(BOM_VARIANT_LOOK_UP[0].id, t),
      noShow: false,
    },
  });

  useEffect(() => {
    const currentBom = value.find((x) => x.articleId === articleId);
    if (currentBom) {
      reset({
        variant: mapBomVariantToOption(currentBom.variant, t),
        noShow: false,
      });
    } else {
      reset({
        variant: mapBomVariantToOption(BOM_VARIANT_LOOK_UP[0].id, t),
        noShow: false,
      });
    }
  }, [reset, value, articleId, t]);

  const onSubmit = useCallback(() => {
    const values = watch();
    onAddArticle(values);
  }, [onAddArticle, watch]);

  return (
    <Dialog
      open={Boolean(article)}
      title={t('MASK.BILL_OF_MATERIALS_TITLE')}
      onClose={onClose}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap="1rem"
        className={styles.container}
      >
        <Controller
          control={control}
          name="variant"
          render={({ field }) => (
            <React.Fragment>
              <BomVariantPickerField
                value={field.value}
                onChange={field.onChange}
              />

              {field.value && (
                <React.Fragment>
                  <Box display="flex" gap="0.5rem" alignItems="center">
                    <InfoIcon className={styles.info} />
                    <Typography variant="body1">
                      {t(`MASK.BILL_OF_MATERIALS_${field.value.id}VAL`)}
                    </Typography>
                  </Box>
                  <Divider
                    title={t('COMMON.EXAMPLE')}
                    style={{ paddingTop: 0 }}
                  />

                  <BomVariantExample variant={field.value} />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        />

        <Box display="flex" gap="0.5rem">
          <Controller
            control={control}
            name="noShow"
            render={({ field }) => (
              <Checkbox
                className={styles.checkbox}
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Box>
            <Typography variant="body1">
              {t('MASK.BILL_OF_MATERIALS_NO_SHOW')}
            </Typography>
            <Typography variant="caption">
              {t('MASK.BILL_OF_MATERIALS_NO_SHOW_SETTINGS')}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-around">
          <Button onClick={onClose}>{t('ALERTS.BTN_ABORT')}</Button>
          <Button onClick={onSubmit}>{t('COMMON.INSERT')}</Button>
        </Box>
      </Box>
    </Dialog>
  );
};
