import styles from './ButtonText.module.scss';

import { Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { ITypographyProps } from '..';

export const ButtonText: React.FC<ITypographyProps> = (props) => {
  return (
    <Typography
      className={clsx(styles['button-text'], props.className)}
      component="span"
    >
      {props.children}
    </Typography>
  );
};
