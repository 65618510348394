import { useMemo, useState } from 'react';

import { ListEntityPickerWithoutTabsProps } from '@work4all/components/lib/components/entity-picker/components/list-entity-picker';
import { ProjectPicker } from '@work4all/components/lib/components/entity-picker/project-picker/ProjectPicker';

import { IResponse } from '@work4all/data';

import { Project } from '@work4all/models/lib/Classes/Project.entity';

export interface ProjectPickerWithSuggestionsProps
  extends Pick<
    ListEntityPickerWithoutTabsProps<Project, false>,
    'value' | 'onChange' | 'onResize'
  > {
  suggestions?: Project[];
}

export function ProjectPickerWithSuggestions(
  props: ProjectPickerWithSuggestionsProps
) {
  const { suggestions, ...otherProps } = props;

  const [_search, setSearch] = useState('');
  const search = _search.trim();

  const suggestionsDataSet = useMemo(() => {
    if (!suggestions || suggestions.length < 1) return null;

    const response: IResponse<Project> = {
      data: suggestions,
      total: suggestions.length,
      loading: false,
      pending: false,
      fetchMore: () => Promise.resolve(),
      refetch: () => Promise.resolve(null),
    };

    return response;
  }, [suggestions]);

  const fixedDataSet =
    search.length < 1 && suggestionsDataSet != null
      ? suggestionsDataSet
      : undefined;

  return (
    <ProjectPicker
      multiple={false}
      onSearchValueChange={setSearch}
      fixedDataSet={fixedDataSet}
      layout="advanced"
      {...otherProps}
    />
  );
}
