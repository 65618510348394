import { DateTime } from 'luxon';

import { getLocaleDateString } from './getLocaleDateStringPattern';
import { withNavigatorLocale } from './withNavigatorLocale';

export const dateTimeFromString = (
  dateString: string,
  customFormat?: string
) => {
  return withNavigatorLocale(
    DateTime.fromFormat(dateString, customFormat || getLocaleDateString())
  );
};
