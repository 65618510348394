import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Customer } from './Customer.entity';

export class StatisticsSalesVolumeByCustomerRow<
  T extends EMode = EMode.entity
> {
  /** Alias: date */
  date?: string;
  /** Alias: objCode */
  objId?: number;
  /** Alias: amount */
  amount?: number;
  /** Alias: kunde */
  kunde?: Customer<T>;

  __typename?: string;
}

const fields: FieldDefinitions<StatisticsSalesVolumeByCustomerRow> = {
  date: {
    alias: 'date',
  },
  objId: {
    alias: 'objCode',
  },
  amount: {
    alias: 'amount',
  },
  kunde: {
    alias: 'kunde',
    entity: Entities.customer,
  },
  __typename: {
    alias: '__typename',
  },
};

export const statisticsSalesVolumeByCustomerRowEntityDefinition: EntitiyDefinition<StatisticsSalesVolumeByCustomerRow> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'StatisticsSalesVolumeByCustomerRow',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
