import { DateTime } from 'luxon';
import { useContext, useMemo } from 'react';

import { TRAVEL_RECEIPTS_DATA } from '@work4all/components/lib/components/entity-preview/travel-receipts-preview/TravelReceiptsPreviewContainer';

import { useDataProvider, useUser } from '@work4all/data';

import { W4ADateFormat } from '@work4all/models/lib/additionalEnums/DateFormat.enum';
import { TravelReceipts } from '@work4all/models/lib/Classes/TravelReceipts.entity';
import { VatRate } from '@work4all/models/lib/Classes/VatRate.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { normalizeFormValue } from '../../../hooks/useExtendedFormContext';
import { useInitialFormValue } from '../../../hooks/useInitialFormValue';
import { MaskExtendedConfig } from '../../../types';
import { CurrencyExchangeInfoContext } from '../../inbound-invoice/currency-exchange-info-context';

export const useTravelReceipts = (
  mask: MaskExtendedConfig<string>,
  defaults: TravelReceipts
) => {
  const exchangeInfo = useContext(CurrencyExchangeInfoContext);

  const requestData = useMemo<DataRequest>(() => {
    const filter = [{ id: { $eq: mask.id } }];
    const entity = Entities.travelReceipts;
    const data = {
      ...TRAVEL_RECEIPTS_DATA,
      enumReceiptKindTravelCosts: null,
      costCenterId: null,
      costCenter: {
        id: null,
        name: null,
      },
      vat: null,
      vat1: null,
      vat2: null,
      vat3: null,
      amountGross1: null,
      amountGross2: null,
      amountGross3: null,
      ledgerAccountId: null,
      ledgerAccount2Id: null,
      ledgerAccount3Id: null,
      ledgerAccount1: {
        id: null,
        name: null,
        number: null,
      },
      ledgerAccount2: {
        id: null,
        name: null,
        number: null,
      },
      ledgerAccount3: {
        id: null,
        name: null,
        number: null,
      },
      travelExpenses: {
        ...TRAVEL_RECEIPTS_DATA.travelExpenses,
        businessPartner: {
          id: null,
          businessPartnerType: null,
        },
      },
    };
    return {
      filter,
      entity,
      data,
    };
  }, [mask.id]);

  const initialFormValue = useInitialFormValue<TravelReceipts>(
    requestData,
    mask.isCreateMode
  );
  const user = useUser();

  const vatRates = useDataProvider<VatRate>(
    useMemo(() => {
      return {
        entity: Entities.vatRate,
        data: {
          id: null,
          sentence: null,
        },
        completeDataResponse: true,
      };
    }, []),
    !mask.isCreateMode
  );

  const newEntityData = useMemo<TravelReceipts>(() => {
    return {
      date: DateTime.now().toFormat(W4ADateFormat.DEFAULT),
      userId: user.benutzerCode,
      currency: exchangeInfo?.defaultCurrency,
      currencyId: exchangeInfo?.defaultCurrency?.id,
      vat1: defaults.vat1 ? defaults.vat1 : vatRates.data?.[0]?.sentence,
      vat2: defaults.vat2 ? defaults.vat2 : vatRates.data?.[0]?.sentence,
      vat3: defaults.vat3 ? defaults.vat3 : vatRates.data?.[0]?.sentence,
      paymentMethod: defaults.paymentMethod,
      paymentKind: defaults.paymentKind,
      receiptKind: defaults.receiptKind,
    };
  }, [
    exchangeInfo?.defaultCurrency,
    user.benutzerCode,
    vatRates.data,
    defaults,
  ]);
  const dataRaw = useMemo(() => {
    return mask.isCreateMode
      ? newEntityData
      : {
          ...initialFormValue.value,
          // API return NULL but it should be undefined
          enumReceiptKindTravelCosts:
            initialFormValue.value?.enumReceiptKindTravelCosts ?? undefined,
        } ?? newEntityData;
  }, [initialFormValue.value, mask.isCreateMode, newEntityData]);

  const data = useMemo(() => {
    return normalizeFormValue(dataRaw);
  }, [dataRaw]);
  return data;
};
