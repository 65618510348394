import { useEventCallback } from '@mui/material/utils';
import { useEffect } from 'react';

import { EntityEvent } from './entity-events';
import { useEntityEventsContext } from './entity-events-context';

export function useEntityEvents(callback: (event: EntityEvent) => void) {
  const { subscribe } = useEntityEventsContext();

  const memoizedCallback = useEventCallback(callback);

  useEffect(() => subscribe(memoizedCallback), [subscribe, memoizedCallback]);
}
