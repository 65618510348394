import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  ISalesOpportunitiesStatusPickerProps,
  SalesOpportunitiesStatusPicker,
} from '@work4all/components/lib/components/entity-picker/sales-opportunities-status-picker/SalesOpportunitiesStatusPicker';

import { SalesOpportunityStatus } from '@work4all/models/lib/Enums/SalesOpportunityStatus.enum';

import { reactRefSetter } from '@work4all/utils';

import { LabeledInput } from '../../containers/mask-overlays/locked-inputs';

interface Props
  extends Omit<
    ISalesOpportunitiesStatusPickerProps<false>,
    'multiple' | 'value' | 'onChange'
  > {
  error?: string;
  value: SalesOpportunityStatus;
  onChange: (value: SalesOpportunityStatus) => void;
}

export const SalesOpportunitiesStatusPickerField = React.forwardRef<
  HTMLDivElement,
  Props
>((props, ref) => {
  const { error, ...pickerProps } = props;
  const { value, onChange } = pickerProps;

  const { t } = useTranslation();

  const wrapperRef = useRef();

  const pickerValue = {
    id: value,
    name: t(`COMMON.SALESOPPORTUNITIES.${value}`),
  };

  return (
    <div ref={reactRefSetter(wrapperRef, ref)}>
      <EntityPickerPopover
        anchorEl={wrapperRef}
        picker={
          <SalesOpportunitiesStatusPicker
            {...pickerProps}
            onChange={(value) => {
              onChange(value.id);
            }}
            multiple={false}
            value={pickerValue}
          />
        }
      >
        <LabeledInput
          label={t('INPUTS.STATUS')}
          error={error}
          value={pickerValue?.name || ''}
        />
      </EntityPickerPopover>
    </div>
  );
});
