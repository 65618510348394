import { useEffect, useState } from 'react';
import { RxCollection } from 'rxdb';
import { useRxDB } from 'rxdb-hooks';

import { useTenant } from '../../hooks/routing/TenantProvider';
import { useUser } from '../../hooks/useUser';
import { deriveTenantCollectionName } from '../../utils/derive-tenant-collection-name';

export function useTenantRxCollection<T>(name: string): RxCollection<T> | null {
  const db = useRxDB();
  const user = useUser();
  const { activeTenant } = useTenant();

  const [collection, setCollection] = useState<RxCollection<T> | null>(null);

  useEffect(() => {
    if (!user || !db) {
      return null;
    }
    const fullCollectionName = deriveTenantCollectionName(activeTenant, name);
    const found = db[fullCollectionName];
    if (found) {
      setCollection(found);
    }
    const sub = db.newCollections$.subscribe((col) => {
      if (col[fullCollectionName]) {
        // We don't unsubscribe so that we get notified
        // and update collection if it gets deleted/recreated
        setCollection(col[fullCollectionName]);
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, [user, db, name, activeTenant]);

  return collection;
}
