interface BaseItem {
  id?: string | number;
}

interface Props<T> {
  items: T[];
  selectedItem: (number | string)[];
  onChange: (ids: (number | string)[]) => void;
}

export const useCalendarSelection = <T extends BaseItem>({
  items,
  selectedItem,
  onChange,
}: Props<T>) => {
  const selected = new Set(selectedItem);

  // Empty array means "do not filter" (the same as "show everything").
  const isEverythingSelected =
    selected.size === 0 || items.every((state) => selected.has(state.id));

  const isSelected = (item: T) => {
    return selected.size === 0 || selected.has(item.id);
  };

  const toggleAll = () => {
    if (isEverythingSelected) {
      // We use -1 to represent "nothing selected" here.
      onChange([-1]);
    } else {
      onChange(items.map((item) => item.id));
    }
  };

  const toggle = (item: T, checked: boolean) => {
    const newSelected = new Set(selected);

    // Special case, where nothing is yet filtered and we display all checkboxes
    // as "checked", even though they are not saved in the selection.
    //
    // Update the selection by including all items, except the one clicked.
    if (newSelected.size === 0 && !checked) {
      for (const selectedItem of items) {
        if (selectedItem.id !== item.id) {
          newSelected.add(selectedItem.id);
        }
      }
    } else {
      newSelected.delete(-1);

      if (checked) {
        newSelected.add(item.id);
      } else {
        newSelected.delete(item.id);
      }
    }

    if (newSelected.size === 0) {
      newSelected.add(-1);
    }

    onChange([...newSelected]);
  };

  return { isEverythingSelected, isSelected, toggleAll, toggle };
};
