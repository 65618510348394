import { InboundInvoice } from '@work4all/models/lib/Classes/InboundInvoice.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tab } from '../types';

export const BOOKINGS_DATA: InboundInvoice = {
  id: null,
  buchungen: [
    {
      id: null,
      valueNet: null,
      vat: null,
      vatAmount: null,
      proportionDM: null,
      taxKey: null,
      project: {
        id: null,
        name: null,
      },
      contraAccount: null,
      note: null,
      costCenter: {
        id: null,
        name: null,
        number: null,
      },
      konto: {
        id: null,
        name: null,
        number: null,
      },
    },
  ],
};

export const PAYMNETS_DATA: InboundInvoice = {
  id: null,
  payments: [
    {
      id: null,
      inboundInvoiceCode: null,
      bookingDate: null,
      datevDate: null,
      account: null,
      ledgerAccountNumber: null,
      text: null,
      user: {
        id: null,
        displayName: null,
      },
    },
  ],
};

export const DATA_BY_ENTITY: { [key in Tab]: InboundInvoice } = {
  [Entities.rELedgerAccountSplit]: BOOKINGS_DATA,
  [Entities.inboundInvoicePayment]: PAYMNETS_DATA,
};

export const KEY_BY_ENTITY: { [key in Tab]: string } = {
  [Entities.rELedgerAccountSplit]: 'buchungen',
  [Entities.inboundInvoicePayment]: 'payments',
};
