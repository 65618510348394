import styles from './ErpText.module.scss';

import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { TextEditor } from '@work4all/components/lib/input/format-text/TextEditor';
import { Tabs } from '@work4all/components/lib/navigation/tabs/tabs/Tabs';

import { ControllerPlus } from '../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../form-plus/use-form-context-plus';
import { BPMaskFormValue } from '../../../types';

interface ErpTextProps {
  type: 'HEADER' | 'CLOSING';
  handleTabChange: (_: unknown, tab: string) => void;
  tab: string;
  tabs: {
    label: string;
    value: string;
  }[];
}

export const ErpText: React.FC<ErpTextProps> = (props: ErpTextProps) => {
  const { type, handleTabChange, tab, tabs } = props;
  const { t } = useTranslation();

  const { control } = useFormContextPlus<BPMaskFormValue>();

  const renderTabContent = () => {
    return (
      <div className={styles['root']}>
        <ControllerPlus
          control={control}
          name="defaultErpTexts"
          render={({ field }) => {
            const { value, onChange } = field;

            const erpTextObject = value?.find(
              (x) => x.textType === `${tab}_${type}`
            );
            if (!erpTextObject) return null;
            return (
              <TextEditor
                value={erpTextObject.text}
                onChange={(newValue: string) => {
                  erpTextObject.text = newValue;
                  onChange([...value]);
                }}
              />
            );
          }}
        />
      </div>
    );
  };

  return (
    <Collapse
      defaultOpen={true}
      title={
        <>
          {t(`MASK.${type}_TEXT`)}
          <Tooltip title={t(`COMMON.TOOLTIP_TEXT_${type}`)} placement="right">
            <InfoIcon color="primary" style={{ marginLeft: '0.5rem' }} />
          </Tooltip>
        </>
      }
      topIndent
    >
      <Tabs value={tab} handleChange={handleTabChange} tabs={tabs} />
      {renderTabContent()}
    </Collapse>
  );
};
