export enum ContactKind {
  BENUTZER = 'BENUTZER',
  MITARBEITER = 'MITARBEITER',
  RESSOURCE = 'RESSOURCE',
  KUNDENANSPRECHPARTNER = 'KUNDENANSPRECHPARTNER',
  LIEFERANTENANSPRECHPARTNER = 'LIEFERANTENANSPRECHPARTNER',
  KUNDE = 'KUNDE',
  LIEFERANT = 'LIEFERANT',
  UNKNOWN = 'UNKNOWN',
}
