import styles from './Details.module.scss';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Assignments } from './components/assignements/Assignments';

export const Details = () => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down('xl'));

  return (
    <div className={styles.root}>
      <Assignments defaultOpen={!downLg} />
    </div>
  );
};
