import styles from './TemplateCard.module.scss';

import { Tooltip } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { Body2 } from '@work4all/components/lib/typography/body2/Body2';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { categoryType } from '../TimeTemplatesWidget';

interface TemplateCardProps {
  title: string;
  subtitle1?: string;
  subtitle2?: string;
  subtitle3?: string;
  subtitle4?: string;
  type: categoryType;
  onClick: () => void;
  businessPartnerName?: string;
  projectName?: string;
}

const TemplateCard: React.FC<TemplateCardProps> = ({
  title,
  subtitle1,
  subtitle2,
  subtitle3,
  subtitle4,
  type,
  onClick,
  businessPartnerName,
  projectName,
}) => {
  const conditionalColors = {
    [styles.grey]: type === 'last',
    [styles.blue]: type === Entities.task,
    [styles.red]: type === Entities.contract,
    [styles.yellow]: type === Entities.project,
    [styles.green]: type === Entities.appointment,
    [styles.purple]: type === Entities.ticket,
  };

  return (
    <Tooltip
      title={
        <>
          {title}
          {businessPartnerName && (
            <>
              <br />
              {businessPartnerName}
            </>
          )}
          {projectName && (
            <>
              <br />
              {projectName}
            </>
          )}
        </>
      }
      placement="top"
    >
      <div onClick={onClick} className={styles.root}>
        <div className={clsx(styles.indicator, conditionalColors)} />
        <div className={clsx(conditionalColors, styles.content)}>
          <Body2>{title || '-'}</Body2>

          <Body2 className={styles.subtitle}>{subtitle1 || '-'}</Body2>

          {subtitle2 ? (
            <Body2 className={styles.subtitle}>{subtitle2}</Body2>
          ) : null}
          {subtitle3 ? (
            <Body2 className={styles.subtitle}>{subtitle3}</Body2>
          ) : null}
          {subtitle4 ? (
            <Body2 className={styles.subtitle}>{subtitle4}</Body2>
          ) : null}
        </div>
      </div>
    </Tooltip>
  );
};

export default TemplateCard;
