import styles from './MaskSize.module.scss';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export function getMaskClasses(entity: Entities) {
  switch (entity) {
    case Entities.vacationRequest:
      return styles.vacation;
    case Entities.timeTrackingOverviewItem:
      return styles.projectTime;
    default:
      return undefined;
  }
}
