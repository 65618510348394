import styles from './TemplatesSettings.module.scss';

import { Checkbox, FormControlLabel, InputBase } from '@mui/material';
import Divider from '@mui/material/Divider';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Body2 } from '@work4all/components/lib/typography/body2/Body2';
import { Label } from '@work4all/components/lib/typography/label/Label';

import { categoryType } from '../../../containers/time-tracker/TimeTemplatesWidget';
import { useTrackHistory } from '../../../hooks/use-time-track-history';

interface SettingsCategories {
  categories: { title: string; type: categoryType }[];
}

const TemplatesSettings: React.FC<SettingsCategories> = ({ categories }) => {
  const { saveCategorySettings, categoriesSettings } = useTrackHistory();

  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      {categories.map((c) => (
        <>
          <Divider classes={{ root: styles.divider }} textAlign="left">
            {c.title}
          </Divider>
          <div className={styles.controlsGroup}>
            <FormControlLabel
              control={
                <Checkbox
                  classes={{ root: styles.checkbox }}
                  checked={categoriesSettings[c.type].show}
                  onChange={() =>
                    saveCategorySettings(c.type, {
                      show: !categoriesSettings[c.type].show,
                      count: categoriesSettings[c.type].count,
                    })
                  }
                />
              }
              label={t<string>('COMMON.DISPLAY')}
            />
            <div className={styles.textField}>
              <Label className={styles['textField-title']}>
                {t('SETTINGS.PROJECT_TIME_TEMPLATES.MAX_AMOUNT')}
              </Label>
              <InputBase
                classes={{
                  root: styles['textField-input'],
                  input: styles['textAlign-end'],
                }}
                type="number"
                value={categoriesSettings[c.type].count}
                disabled={!categoriesSettings[c.type].show}
                onChange={(e) => {
                  saveCategorySettings(c.type, {
                    show: categoriesSettings[c.type].show,
                    count: parseInt(e.target.value),
                  });
                }}
                onBlur={(e) => {
                  saveCategorySettings(c.type, {
                    show: categoriesSettings[c.type].show,
                    count:
                      Number(e.target.value) < 1 ? 1 : Number(e.target.value),
                  });
                }}
                id="standard-basic"
                onFocus={(e) => e.target.select()}
              />
              <Body2 className={styles['textField-adornment']}>x</Body2>
            </div>
          </div>
        </>
      ))}
    </div>
  );
};

export default TemplatesSettings;
