import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { MfaMode } from '../Enums/MfaMode.enum';
import { UserClass } from '../Enums/UserClass.enum';

import { EntityAccessRights } from './EntityAccessRights.entity';
import { PaginationListVacation } from './PaginationListVacation.entity';
import { ProjectAccessGroup } from './ProjectAccessGroup.entity';
import { UserLicenseInformation } from './UserLicenseInformation.entity';
import { UserRole } from './UserRole.entity';
import { VacationEntitlement } from './VacationEntitlement.entity';

export class User<T extends EMode = EMode.entity> {
  /** Alias: abteilung */
  departmentName?: string;
  /** Alias: anmeldeName */
  loginName?: string;
  /** Alias: anzeigename */
  displayName?: string;
  /** Alias: ausgeschieden */
  hasLeft?: boolean;
  /** Alias: benutzerArt */
  userKind?: UserClass;
  /** Alias: briefAnrede */
  letterSalutation?: string;
  /** Alias: code */
  id?: number;
  /** Alias: eMail */
  eMail?: string;
  /** Alias: fax */
  faxNumber?: string;
  /** Alias: funktion */
  role?: string;
  /** Alias: gruppenCode */
  groupId?: number;
  /** Alias: gruppenName */
  groupName?: string;
  /** Alias: instanzCode */
  instanceId?: number;
  /** Alias: kostenstelle */
  costCenterId?: number;
  /** Alias: master */
  isMaster?: boolean;
  /** Alias: mobil */
  mobileNumber?: string;
  /** Alias: nachname */
  lastName?: string;
  /** Alias: telefon */
  phoneNumber?: string;
  /** Alias: titel */
  title?: string;
  /** Alias: urlaubsgenehmigerCode */
  vacationApproverId?: number;
  /** Alias: vorgesetzterCode */
  supervisorId?: number;
  /** Alias: vorname */
  firstName?: string;
  /** Alias: zeichen */
  shortName?: string;
  /** Alias: urlaubsanspruch */
  vactationEntitlement?: VacationEntitlement<T>[];
  /** Alias: role */
  accountRole?: UserRole<T>;
  /** Alias: vorgesetzter */
  supervisor?: User<T>;
  /** Alias: urlaubsgenehmiger */
  vacationApprover?: User<T>;
  /** Alias: PaginationListurlaub */
  vacation?: PaginationListVacation<T>;
  /** Alias: vacationInfo */
  vacationInfo?: string[];
  /** Alias: licenseInformation */
  licenseInformation?: UserLicenseInformation<T>;
  /** Alias: entityAccessRights */
  entityAccessRights?: EntityAccessRights<T>[];
  /** Alias: avatarUrl */
  avatarUrl?: string;
  /** Alias: projectAccessRightsLimited */
  projectAccessRightsLimited?: boolean;
  /** Alias: geburtsdatum */
  geburtsdatum?: string;
  /** Alias: kommtGehtMinimaleStartZeit */
  kommtGehtMinimaleStartZeit?: number;
  /** Alias: projectAccessGroups */
  projectAccessGroups?: ProjectAccessGroup<T>[];
  /** Alias: mfaMode */
  mfaMode?: MfaMode;

  __typename?: string;
}

const fields: FieldDefinitions<User> = {
  departmentName: {
    alias: 'abteilung',
  },
  loginName: {
    alias: 'anmeldeName',
  },
  displayName: {
    alias: 'anzeigename',
  },
  hasLeft: {
    alias: 'ausgeschieden',
  },
  userKind: {
    alias: 'benutzerArt',
  },
  letterSalutation: {
    alias: 'briefAnrede',
  },
  id: {
    alias: 'code',
  },
  eMail: {
    alias: 'eMail',
  },
  faxNumber: {
    alias: 'fax',
  },
  role: {
    alias: 'funktion',
  },
  groupId: {
    alias: 'gruppenCode',
  },
  groupName: {
    alias: 'gruppenName',
  },
  instanceId: {
    alias: 'instanzCode',
  },
  costCenterId: {
    alias: 'kostenstelle',
  },
  isMaster: {
    alias: 'master',
  },
  mobileNumber: {
    alias: 'mobil',
  },
  lastName: {
    alias: 'nachname',
  },
  phoneNumber: {
    alias: 'telefon',
  },
  title: {
    alias: 'titel',
  },
  vacationApproverId: {
    alias: 'urlaubsgenehmigerCode',
  },
  supervisorId: {
    alias: 'vorgesetzterCode',
  },
  firstName: {
    alias: 'vorname',
  },
  shortName: {
    alias: 'zeichen',
  },
  vactationEntitlement: {
    alias: 'urlaubsanspruch',
    entity: Entities.vacationEntitlement,
  },
  accountRole: {
    alias: 'role',
    entity: Entities.userRole,
  },
  supervisor: {
    alias: 'vorgesetzter',
    entity: Entities.user,
  },
  vacationApprover: {
    alias: 'urlaubsgenehmiger',
    entity: Entities.user,
  },
  vacation: {
    alias: 'urlaub',
    entity: Entities.vacation,
  },
  vacationInfo: {
    alias: 'vacationInfo',
  },
  licenseInformation: {
    alias: 'licenseInformation',
    entity: Entities.userLicenseInformation,
  },
  entityAccessRights: {
    alias: 'entityAccessRights',
    entity: Entities.entityAccessRights,
  },
  avatarUrl: {
    alias: 'avatarUrl',
  },
  projectAccessRightsLimited: {
    alias: 'projectAccessRightsLimited',
  },
  geburtsdatum: {
    alias: 'geburtsdatum',
  },
  kommtGehtMinimaleStartZeit: {
    alias: 'kommtGehtMinimaleStartZeit',
  },
  projectAccessGroups: {
    alias: 'projectAccessGroups',
    entity: Entities.projectAccessGroup,
  },
  mfaMode: {
    alias: 'mfaMode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const userEntityDefinition: EntitiyDefinition<User> = {
  local: {},
  remote: {
    queryName: 'getBenutzer',
    fragmentName: 'Benutzer',
    withPaginationWrapper: true,
    mutations: {
      upsert: {
        mutationName: 'updateBenutzer',
        args: [{ name: 'input', type: 'InputBenutzer!' }],
      },
    },
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'filter', type: 'String' },
      { name: 'filterUserRights', type: '[UserRightsFilter]' },
    ],
  },
  fieldDefinitions: fields,
};
