import { noop } from 'lodash';
import { createContext, useContext } from 'react';

import { TableLayout } from '@work4all/components';

export type TableLayoutContextValue = [
  value: TableLayout,
  setValue: (value: TableLayout) => void
];

const Context = createContext<TableLayoutContextValue>(['table', noop]);

export const TableLayoutContext = Context.Provider;

export function useTableLayout(): TableLayoutContextValue {
  return useContext(Context);
}
