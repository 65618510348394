import { memo, useEffect } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  EventType,
  sendAmplitudeData,
} from '@work4all/components/lib/utils/amplitude/amplitude';

import { useUser } from '@work4all/data';

import { NavigationOverlayHeader } from '../../../../../../components/navigation-overlay-header/NavigationOverlayHeader';
import { NoAdminRightsBanderole } from '../../../../components/banderoles/NoAdminRightsBanderole';
import {
  MaskTab,
  MaskTabContext,
  MaskTabPanel,
  MaskTabs,
  useMaskTabContext,
} from '../../../../mask-tabs';
import { MaskContent } from '../../../components/MaskContent/MaskContent';

import { AddressManagementSection } from './components/address-management-section/AddressManagementSection';
import { BomSection } from './components/bom-section/BomSection';
import { RtfSection } from './components/rtf-section/RtfSection';

type ERPSettingsProps = {
  amplitudeEntryPoint: string;
};

export function ERPSettings(props: ERPSettingsProps) {
  const { amplitudeEntryPoint } = props;

  const { t } = useTranslation();

  useEffect(() => {
    sendAmplitudeData(EventType.OpenERPSettings, {
      entryPoint: amplitudeEntryPoint,
    });
  }, [amplitudeEntryPoint]);

  return (
    <MaskTabContext defaultValue="personal">
      <NavigationOverlayHeader
        title={t('SETTINGS.SETTINGS')}
        subTitle={t('SETTINGS.ERP')}
        breadcrumbsChildren={<div></div>}
        tabs={<SettingsTabs />}
      />

      <Content />
    </MaskTabContext>
  );
}

const SettingsTabs = memo(function Tabs() {
  const { t } = useTranslation();

  return (
    <MaskTabs>
      <MaskTab value="personal" label={t('COMMON.PERSONAL')} />
      <MaskTab value="general" label={t('COMMON.GENERAL')} />
    </MaskTabs>
  );
});

const Content = memo(function TabPanels() {
  const user = useUser();
  const context = useMaskTabContext();
  return (
    <React.Fragment>
      {!user.isMaster && context.value === 'general' && (
        <NoAdminRightsBanderole />
      )}
      <MaskContent>
        <MaskTabPanel value="personal">
          <BomSection />
        </MaskTabPanel>
        <MaskTabPanel value="general">
          <AddressManagementSection />
          <RtfSection />
        </MaskTabPanel>
      </MaskContent>
    </React.Fragment>
  );
});
