import { useTranslation } from 'react-i18next';

import { ConfirmDialog } from '@work4all/components/lib/dialogs/ConfirmDialog';

interface CopyAttachmentsDialogProps {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const CopyAttachmentsDialog = ({
  open,
  onConfirm,
  onCancel,
}: CopyAttachmentsDialogProps) => {
  const { t } = useTranslation();

  return (
    <ConfirmDialog
      open={open}
      title={t('ALERTS.HINT')}
      description={t('ALERTS.COPY_ATTACHMENTS')}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
};
