import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { ChangeType } from '../Enums/ChangeType.enum';
import { EMode } from '../Enums/EMode.enum';
import { ObjectType } from '../Enums/ObjectType.enum';

export class EntityChangedInfo<T extends EMode = EMode.entity> {
  /** Alias: changeId */
  changeId?: string;
  /** Alias: changeType */
  changeType?: ChangeType;
  /** Alias: changedBy */
  changedBy?: number;
  /** Alias: mandantenCode */
  tenantId?: number;
  /** Alias: objCode */
  objCode?: number;
  /** Alias: objId */
  objId?: string;
  /** Alias: objType */
  objType?: ObjectType;
  /** Alias: subObjectType */
  subObjectType?: ObjectType;

  __typename?: string;
}

const fields: FieldDefinitions<EntityChangedInfo> = {
  changeId: {
    alias: 'changeId',
  },
  changeType: {
    alias: 'changeType',
  },
  changedBy: {
    alias: 'changedBy',
  },
  tenantId: {
    alias: 'mandantenCode',
  },
  objCode: {
    alias: 'objCode',
  },
  objId: {
    alias: 'objId',
  },
  objType: {
    alias: 'objType',
  },
  subObjectType: {
    alias: 'subObjectType',
  },
  __typename: {
    alias: '__typename',
  },
};

export const entityChangedInfoEntityDefinition: EntitiyDefinition<EntityChangedInfo> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'EntityChangendInfo',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
