import styles from './WebsiteFavicon.module.scss';

import { Public } from '@mui/icons-material';

export const WebsiteFavicon = (props: { website?: string }) => {
  const { website } = props;
  return website ? (
    <img
      src={`https://www.google.com/s2/favicons?domain=${website}`}
      alt="Favicon"
      className={styles.favicon}
    />
  ) : (
    <Public className={styles.favicon} />
  );
};
