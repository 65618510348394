import styles from './TaskPreview.module.scss';

import { Divider, Stack, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { DateTime } from 'luxon';
import { MouseEventHandler, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useDataMutation,
  useInaccessibleFields,
  useUser,
} from '@work4all/data';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Task } from '@work4all/models/lib/Classes/Task.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';
import { TaskStatus } from '@work4all/models/lib/Enums/TaskStatus.enum';

import {
  DateFormatPreset,
  formatDateString,
} from '@work4all/utils/lib/date-utils/formatDateString';

import { TaskStatusIndicator } from '../../../components/task-status-indicator';
import { useContactActions } from '../../../hooks/use-contact-actions';
import { DatePicker } from '../../../input/date-picker';
import { useMobiscrollLanguage } from '../../../utils/use-mobiscroll-language/use-mobiscroll-language';
import { IConvertPopoverProps } from '../../convert-popover/ConvertPopover';
import { EntityPickerPopover } from '../../entity-picker/components';
import { ContactPicker } from '../../entity-picker/contact-picker/ContactPicker';
import { CustomerPicker } from '../../entity-picker/customer-picker/CustomerPicker';
import { ProjectPicker } from '../../entity-picker/project-picker/ProjectPicker';
import { TaskStatusPicker } from '../../entity-picker/task-status-picker/TaskStatusPicker';
import { UserPicker } from '../../entity-picker/user-picker/UserPicker';
import { useEntityTemplate } from '../../entity-template-provider/EntityTemplateProvider';
import {
  InfoCard,
  InfoCards,
  OpenFileIconButton,
  PreviewEditMessage,
  PreviewTextContent,
  PreviewTitle,
  PreviewWrapper,
} from '../components';
import { useEntityPreview } from '../hooks/use-entity-preview';

import { taskPreviewFields } from './TaskPreviewContainer';

export type ITaskPreviewProps = {
  tasks: Task[];
  onCloseClicked?: MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onShareClicked?: () => void;
  convertProps?: Pick<IConvertPopoverProps, 'exclude' | 'onClick'>;
};

enum previewFields {
  BEGIN_DATE = 'beginDate',
  DUE_DATE = 'dueDate',
  CUSTOMER = 'customer',
  CONTACT = 'contact',
  STATUS = 'status',
  RESPONSIBLE = 'responsible',
  PREVIEW_TITLE = 'previewTitle',
  PROJECT = 'project',
}

export function TaskPreview(props: ITaskPreviewProps) {
  const { tasks, onCloseClicked, onEditClicked, onShareClicked, convertProps } =
    props;
  const { t } = useTranslation();
  const language = useMobiscrollLanguage();

  const user = useUser();
  const { isInaccessible } = useInaccessibleFields();

  const titleIsAccessible = !isInaccessible(Entities.task, 'title');
  const creatorIsAccessible = !isInaccessible(Entities.task, 'creator');
  const userIsAccessible = !isInaccessible(Entities.task, 'user');
  const beginDateIsAccessible = !isInaccessible(Entities.task, 'date');
  const dueDateIsAccessible = !isInaccessible(Entities.task, 'endDate');
  const businessPartnerIsAccessible = !isInaccessible(
    Entities.task,
    'businessPartner'
  );
  const contactIsAccessible = !isInaccessible(Entities.task, 'contact');
  const projectIsAccessible = !isInaccessible(Entities.task, 'project');
  const statusIsAccessible = !isInaccessible(Entities.task, 'status');
  const noteIsAccessible = !isInaccessible(Entities.task, 'note');

  const beginDateRef = useRef<EntityPickerPopover>(null);
  const dueDateRef = useRef<EntityPickerPopover>(null);
  const customerRef = useRef<EntityPickerPopover>(null);
  const contactRef = useRef<EntityPickerPopover>(null);
  const projectRef = useRef<EntityPickerPopover>(null);
  const statusRef = useRef<EntityPickerPopover>(null);
  const responsibleRef = useRef<EntityPickerPopover>(null);
  const titleRef = useRef<PreviewTitle>(null);

  const openPicker = useCallback((picker: previewFields) => {
    switch (picker) {
      case previewFields.BEGIN_DATE: {
        beginDateRef.current.open();
        break;
      }

      case previewFields.DUE_DATE: {
        dueDateRef.current.open();
        break;
      }

      case previewFields.CUSTOMER: {
        customerRef.current.open();
        break;
      }

      case previewFields.CONTACT: {
        contactRef.current.open();
        break;
      }

      case previewFields.PROJECT: {
        projectRef.current.open();
        break;
      }

      case previewFields.STATUS: {
        statusRef.current.open();
        break;
      }

      case previewFields.PREVIEW_TITLE: {
        titleRef.current.open();
        break;
      }

      case previewFields.RESPONSIBLE: {
        responsibleRef.current.open();
        break;
      }

      default:
        return;
    }
  }, []);

  const [mutate] = useDataMutation<Task, EMode.upsert>({
    entity: Entities.task,
    mutationType: EMode.upsert,
    responseData: taskPreviewFields as unknown as Task,
  });

  const {
    loading,
    isMultiselect,
    commonFields,
    activePicker,
    optimisticTitle,
    handleLock,
    onPopoverClose,
    setOptimisticTitle,
    onEdit,
    canEditAllSelected,
  } = useEntityPreview({
    user,
    subEntityType: Entities.task,
    entries: tasks,
    openPicker,
    mutate,
  });

  const beginDateIsCommon = commonFields.includes('date');
  const dueDateIsCommon = commonFields.includes('endDate');
  const customerIsCommon = commonFields.includes('businessPartner');
  const contactIsCommon = commonFields.includes('contact');
  const projectIsCommon = commonFields.includes('project');
  const statusIsCommon = commonFields.includes('status');
  const creatorIsCommon = commonFields.includes('creator');
  const responsibleIsCommon = commonFields.includes('user');

  const task = tasks[0];

  const { actions: contactActions } = useContactActions(task?.contact);
  const entityTemplate = useEntityTemplate();

  return (
    <PreviewWrapper>
      <PreviewTitle
        disabled={true}
        showEdit={!isMultiselect && canEditAllSelected}
        ref={titleRef}
        label={task?.title}
        onClick={() =>
          !isMultiselect &&
          titleIsAccessible &&
          handleLock(previewFields.PREVIEW_TITLE)
        }
        onClose={(e) => {
          if (e.target.value !== task?.title) {
            setOptimisticTitle(e.target.value);
            onEdit({ title: e.target.value });
          }
          onPopoverClose();
        }}
        onCloseClicked={onCloseClicked}
        onEditClicked={onEditClicked}
        actions={
          loading && activePicker === previewFields.PREVIEW_TITLE ? (
            <CircularProgress size="1rem" color="secondary" />
          ) : null
        }
        onShareClicked={onShareClicked}
        convertProps={convertProps}
      >
        {isMultiselect
          ? `${t('COMMON.SELECTION')}: ${tasks.length} ${t('COMMON.ELEMENTS')}`
          : titleIsAccessible
          ? optimisticTitle ?? task?.title
          : ''}
      </PreviewTitle>
      {isMultiselect && <PreviewEditMessage entityId={String(task.id)} />}

      <InfoCards columns={3}>
        {creatorIsAccessible && (
          <InfoCard staticField={true} label={t('COMMON.CREATOR')}>
            {creatorIsCommon
              ? task?.creator?.displayName || '-'
              : `(${t('COMMON.MULTIPLE')})`}
          </InfoCard>
        )}

        {userIsAccessible && (
          <EntityPickerPopover
            ref={responsibleRef}
            disabled={true}
            onTargetClick={() => handleLock(previewFields.RESPONSIBLE)}
            onClose={onPopoverClose}
            picker={
              <UserPicker
                multiple={false}
                value={responsibleIsCommon ? task.user : null}
                onChange={(value) => {
                  onEdit({
                    userId: value.id,
                    user: { id: value.id, displayName: value.displayName },
                  });
                }}
              />
            }
          >
            <InfoCard
              disabled={false}
              label={t('COMMON.RESPONSIBLE')}
              decorator={
                loading && activePicker === previewFields.RESPONSIBLE ? (
                  <CircularProgress size="1rem" color="secondary" />
                ) : null
              }
              decoratorPos="right"
            >
              {responsibleIsCommon
                ? task?.user?.displayName || '-'
                : `(${t('COMMON.MULTIPLE')})`}
            </InfoCard>
          </EntityPickerPopover>
        )}

        {beginDateIsAccessible && (
          <EntityPickerPopover
            ref={beginDateRef}
            disabled={true}
            onTargetClick={() => handleLock(previewFields.BEGIN_DATE)}
            onClose={onPopoverClose}
            autoclose={true}
            picker={
              <DatePicker
                locale={language}
                controls={['calendar']}
                defaultValue={formatDateString(
                  task?.date,
                  DateFormatPreset.DATE_SIMPLE_2YEAR
                )}
                onChange={(val) => {
                  onEdit({ date: new Date(val.value).toISOString() });
                }}
              />
            }
          >
            <InfoCard
              label={t('COMMON.BEGIN')}
              decorator={
                loading && activePicker === previewFields.BEGIN_DATE ? (
                  <CircularProgress size="1rem" color="secondary" />
                ) : null
              }
              decoratorPos="right"
            >
              {beginDateIsCommon
                ? DateTime.fromISO(task.date).toFormat('d.M.yyyy')
                : `(${t('COMMON.MULTIPLE')})`}
            </InfoCard>
          </EntityPickerPopover>
        )}

        {dueDateIsAccessible && (
          <EntityPickerPopover
            ref={dueDateRef}
            disabled={true}
            onTargetClick={() => handleLock(previewFields.DUE_DATE)}
            onClose={onPopoverClose}
            autoclose={true}
            picker={
              <DatePicker
                locale={language}
                controls={['calendar']}
                defaultValue={formatDateString(
                  task?.endDate,
                  DateFormatPreset.DATE_SIMPLE_2YEAR
                )}
                onChange={(val) => {
                  onEdit({ endDate: new Date(val.value).toISOString() });
                }}
              />
            }
          >
            <InfoCard
              label={t('FIELDS.end_task')}
              decorator={
                loading && activePicker === previewFields.DUE_DATE ? (
                  <CircularProgress size="1rem" color="secondary" />
                ) : null
              }
              decoratorPos="right"
            >
              {dueDateIsCommon
                ? task?.endDate
                  ? DateTime.fromISO(task.endDate).toFormat('d.M.yyyy')
                  : undefined
                : `(${t('COMMON.MULTIPLE')})`}
            </InfoCard>
          </EntityPickerPopover>
        )}

        {businessPartnerIsAccessible && (
          <EntityPickerPopover
            ref={customerRef}
            disabled={true}
            onTargetClick={() => handleLock(previewFields.CUSTOMER)}
            onClose={onPopoverClose}
            footer={entityTemplate.renderPickerFooter({
              entity: Entities.customer,
              popoverRef: customerRef,
              data: task.businessPartner?.data as Customer,
              onChange: (value) => {
                onEdit({
                  businessPartner:
                    value && !Array.isArray(value)
                      ? {
                          data: value,
                          businessPartnerType: SdObjType.KUNDE,
                          id: value?.id,
                        }
                      : null,
                  businessPartnerId:
                    value && !Array.isArray(value) ? value?.id : 0,
                  businessPartnerType: SdObjType.KUNDE,
                });
              },
            })}
            picker={
              <CustomerPicker
                onChange={(value) => {
                  onEdit({
                    businessPartner: value
                      ? {
                          data: value,
                          businessPartnerType: SdObjType.KUNDE,
                          id: value?.id,
                        }
                      : null,
                    businessPartnerId: value ? value?.id : 0,
                    businessPartnerType: SdObjType.KUNDE,
                  });
                }}
                value={task.businessPartner?.data as Customer}
                multiple={false}
              />
            }
          >
            <InfoCard
              truncate
              label={t('COMMON.BUSINESS_PARTNER', {
                context: task?.businessPartner?.businessPartnerType,
              })}
              decorator={
                loading && activePicker === previewFields.CUSTOMER ? (
                  <CircularProgress size="1rem" color="secondary" />
                ) : null
              }
              decoratorPos="right"
              beforeContentElement={
                <OpenFileIconButton data={task?.businessPartner?.data} />
              }
            >
              {customerIsCommon
                ? task?.businessPartner?.data?.name
                : `(${t('COMMON.MULTIPLE')})`}
            </InfoCard>
          </EntityPickerPopover>
        )}

        {contactIsAccessible && (
          <Stack direction="row">
            <div style={{ width: '100%' }}>
              <EntityPickerPopover
                ref={contactRef}
                disabled={true}
                onTargetClick={() =>
                  customerIsCommon &&
                  task?.businessPartner?.data &&
                  handleLock(previewFields.CONTACT)
                }
                onClose={onPopoverClose}
                picker={
                  <ContactPicker
                    prefilter={[
                      {
                        businessPartnerType: { $eq: SdObjType.KUNDE },
                      },
                      {
                        businessPartnerId: {
                          $eq: task?.businessPartner?.data.id,
                        },
                      },
                    ]}
                    value={task.contact}
                    multiple={false}
                    onChange={(value) => {
                      onEdit({
                        contact: value,
                        contactId: value ? value.id : 0,
                      });
                    }}
                  />
                }
              >
                <InfoCard
                  disabled={!customerIsCommon || !task?.businessPartner?.data}
                  tooltip={
                    !customerIsCommon || !task?.businessPartner?.data
                      ? 'Wählen Sie zunächst einen einhietlichen Geschäftspartner'
                      : undefined
                  }
                  label={t('COMMON.CONTACT')}
                  decorator={
                    loading && activePicker === previewFields.CONTACT ? (
                      <CircularProgress size="1rem" color="secondary" />
                    ) : null
                  }
                  decoratorPos="right"
                >
                  {contactIsCommon
                    ? task?.contact?.displayName || '-'
                    : `(${t('COMMON.MULTIPLE')})`}
                </InfoCard>
              </EntityPickerPopover>
            </div>
            {contactActions}
          </Stack>
        )}

        {projectIsAccessible && (
          <EntityPickerPopover
            ref={projectRef}
            disabled={true}
            onTargetClick={() => handleLock(previewFields.PROJECT)}
            onClose={onPopoverClose}
            footer={entityTemplate.renderPickerFooter({
              entity: Entities.project,
              popoverRef: projectRef,
              data: task.project,
              onChange: (value) => {
                onEdit({
                  project: value,
                  projectId: value && !Array.isArray(value) ? value.id : 0,
                });
              },
            })}
            picker={
              <ProjectPicker
                onChange={(value) => {
                  onEdit({
                    project: value,
                    projectId: value ? value.id : 0,
                  });
                }}
                value={task.project}
                multiple={false}
              />
            }
          >
            <InfoCard
              truncate
              label={t('COMMON.PROJECT')}
              decorator={
                loading && activePicker === previewFields.PROJECT ? (
                  <CircularProgress size="1rem" color="secondary" />
                ) : null
              }
              decoratorPos="right"
              beforeContentElement={<OpenFileIconButton data={task?.project} />}
            >
              {projectIsCommon
                ? task?.project?.name
                : `(${t('COMMON.MULTIPLE')})`}
            </InfoCard>
          </EntityPickerPopover>
        )}

        {statusIsAccessible && (
          <EntityPickerPopover
            ref={statusRef}
            disabled={true}
            onTargetClick={() => handleLock(previewFields.STATUS)}
            onClose={onPopoverClose}
            picker={
              <TaskStatusPicker
                multiple={false}
                value={
                  statusIsCommon
                    ? { id: task.status as string, name: '' }
                    : null
                }
                availableStatus={[
                  TaskStatus.OFFEN,
                  TaskStatus.ERLEDIGT,
                  TaskStatus.IN_BEARBEITUNG,
                  task.status as TaskStatus,
                ]}
                onChange={(value) => onEdit({ status: value?.id })}
              />
            }
          >
            <InfoCard
              truncate
              label={t('COMMON.STATUS', {
                context: task?.status,
              })}
              decorator={
                loading && activePicker === previewFields.STATUS ? (
                  <CircularProgress size="1rem" color="secondary" />
                ) : null
              }
              decoratorPos="right"
            >
              <Typography
                variant="body1"
                className={styles['task-status-wrapper']}
              >
                <TaskStatusIndicator taskStatus={task?.status as TaskStatus} />
                {statusIsCommon
                  ? t('TASK_STATUS.' + task?.status)
                  : `(${t('COMMON.MULTIPLE')})`}
              </Typography>
            </InfoCard>
          </EntityPickerPopover>
        )}
      </InfoCards>

      <Divider orientation="horizontal" />

      {noteIsAccessible && (
        <PreviewTextContent>{task?.note}</PreviewTextContent>
      )}
    </PreviewWrapper>
  );
}
