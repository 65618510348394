import { ButtonBase, ButtonBaseProps } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DateTimeInputDialog } from '@work4all/components/lib/dialogs/DateTimeInputDialog';

import { useUser } from '@work4all/data';

import {
  checkUserRight,
  UserRights,
} from '@work4all/models/lib/additionalEnums/UserRights';

import { useCalendarHoursConfig } from '../calendar/hooks/use-calendar-hours-config';

import {
  TIMETRACKER_LAYOUTS,
  TimeTrackerControls,
} from './TimeTrackerControls';
import { TimeTrackerInputSettings } from './TimeTrackerInputSettings';
import { TimeTracker } from './use-time-tracker';
import { getDefaultTime } from './utils/getDefaultTime';
import { WorkTimeDisabledAlert } from './WorkTimeDisableAlert';

export interface WorkHoursProps extends ButtonBaseProps {
  tracker: TimeTracker;
}

export function WorkHoursButton(props: WorkHoursProps) {
  const { tracker, ...buttonBaseProps } = props;

  const { t } = useTranslation();

  const user = useUser();

  const [showWorkTimeAlert, setShowWorkTimeAlert] = useState(false);

  const workTime = useCalendarHoursConfig();

  const [dialog, setDialog] = useState<{
    open: boolean;
    type: 'start' | 'stop';
  }>({
    open: false,
    type: 'start',
  });

  const determineCheckinCheckoutTime = (type: 'start' | 'stop') => () => {
    const canModifyExactTimes = checkUserRight(
      UserRights.KommtGehtZeitenAendernEigene,
      user.benutzerRechte
    );

    if (canModifyExactTimes) {
      setDialog({
        open: true,
        type,
      });
    } else {
      tracker[type](new Date());
    }
  };

  const closeDialog = () => {
    setDialog((state) => ({ ...state, open: false }));
  };

  const { result } = tracker;

  if (result == null) {
    return null;
  }

  return (
    <>
      <DateTimeInputDialog
        open={dialog.open}
        title={t(`TIME_TRACKER.DIALOG.${dialog.type}.title`)}
        description={t(`TIME_TRACKER.DIALOG.${dialog.type}.description`)}
        onConfirm={(dateTime) => {
          tracker[dialog.type](dateTime);
          closeDialog();
        }}
        onCancel={closeDialog}
        defaultValue={getDefaultTime({ ...tracker }, workTime.workDayEnd)}
        {...TimeTrackerInputSettings(tracker, dialog.type)}
      />

      <WorkTimeDisabledAlert
        open={showWorkTimeAlert}
        onClose={() => setShowWorkTimeAlert(false)}
      />

      <ButtonBase {...buttonBaseProps}>
        <TimeTrackerControls
          layout={TIMETRACKER_LAYOUTS.BUTTON}
          disabled={tracker.disabled}
          state={result.state}
          presentSince={result.presentSince}
          pausedSince={result.pausedSince}
          stoppedSince={result.stoppedSince}
          onStart={() => {
            if (tracker.disabled) {
              setShowWorkTimeAlert(true);
            } else {
              determineCheckinCheckoutTime('start')();
            }
          }}
          onStop={determineCheckinCheckoutTime('stop')}
          onPause={
            checkUserRight(UserRights.PausenStempeln, user.benutzerRechte)
              ? () => tracker.pause(new Date())
              : undefined
          }
          onResume={() => tracker.resume(new Date())}
          tracker={tracker}
          className={props.className}
        />
      </ButtonBase>
    </>
  );
}
