import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useEntitySearchData } from './useEntitySearchData';

const filter = {
  $or: [
    {
      projectStatusId: { $eq: 0 },
    },
    {
      projectStatusId: { $eq: null },
    },
    {
      'projectStatus.closedStatus': { $eq: 0 },
    },
  ],
};
export function useProjectSearchData(skip = false) {
  return useEntitySearchData<Project>(Entities.project, skip, filter);
}
