import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { useUser } from '@work4all/data';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { ContactKind } from '@work4all/models/lib/Enums/ContactKind.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';
import { UserClass } from '@work4all/models/lib/Enums/UserClass.enum';

import { useMsTeamsAvailable } from '../../../../../hooks';
import { useQueryPresetFields } from '../../../../calendar/hooks/use-query-preset-fields';
import { MaskExtendedConfig } from '../../types';
import { useAssignableTemplateEntity } from '../../utils/use-assignable-template-entity';

import { sortAttendees } from './AppointmentOverlayController';
import { createContactUnionWrapper } from './components/tab-panels/general/components/contactpicker/ContactRessourcePicker';
import { mapAttendee } from './components/tab-panels/general/components/participant/utils';

export const useAppointmentDefaultData = (mask: MaskExtendedConfig) => {
  const user = useUser();
  const skip = mask.isEditMode;
  const template = useAssignableTemplateEntity(mask);
  const queryPresetFields = useQueryPresetFields();
  const presetFieldsJson = mask.params.presetFields || queryPresetFields;

  const msTeamsAvailable = useMsTeamsAvailable();

  return useMemo(() => {
    if (skip) return null;
    let data: Appointment = {
      userId: user.benutzerCode,
      startDate: DateTime.now().startOf('hour').toISO(),
      endDate: DateTime.now().startOf('hour').plus({ hour: 1 }).toISO(),
      remind: true,
      remindDate: DateTime.now().startOf('hour').minus({ minutes: 15 }).toISO(),
      isRealAppointment: true,
      appointmentAttendeeList: [
        {
          user: {
            id: user.benutzerCode,
            displayName: user.displayName,
            firstName: user.firstName,
            lastName: user.lastName,
            eMail: user.email,
            userKind: UserClass.BENUTZER,
          },
          userId: user.benutzerCode,
        },
      ],
    };

    if (template.enabled) {
      if (template.data) {
        data = {
          ...data,
          businessPartner: {
            id: template.data.businessPartner?.data?.id ?? 0,
            data: template.data.businessPartner?.data
              ? { ...template.data.businessPartner?.data, mainContact: null }
              : null,
          },
          businessPartnerId: template.data.businessPartner?.data?.id ?? 0,
          businessPartnerType:
            template.data.businessPartner?.businessPartnerType ??
            template.data.businessPartnerType ??
            SdObjType.LIEFERANT,
          contact: template.data.contact,
          contactId: template.data.contact?.id ?? 0,
          project: template.data.project,
          projectId: template.data.project?.id ?? 0,
          projectProcess: template.data.projectProcess,
          projectProcessId: template.data.projectProcess?.id ?? 0,
          topicMarkList: template.data.topicMarkList,
        };
      }
    }

    if (data?.contact) {
      data.appointmentAttendeeList.push(
        mapAttendee(
          createContactUnionWrapper(
            {
              ...data.contact,
              businessPartnerId: data.businessPartnerId,
              businessPartnerType: data.businessPartnerType,
              businessPartner: data.businessPartner,
            },
            data.businessPartnerType === SdObjType.KUNDE
              ? ContactKind.KUNDENANSPRECHPARTNER
              : ContactKind.LIEFERANTENANSPRECHPARTNER
          )
        )
      );
    }
    data.appointmentAttendeeList = data
      ? sortAttendees(data.appointmentAttendeeList)
      : [];

    if (presetFieldsJson) {
      try {
        const presetFields = JSON.parse(presetFieldsJson);
        Object.assign(data, presetFields);
      } catch {
        console.error('Could not parse preset fields:', presetFieldsJson);
      }
    }

    data = {
      ...data,
      exchangeMeeting:
        msTeamsAvailable && data.appointmentAttendeeList.length > 1,
    };

    //default
    return data;
  }, [
    skip,
    template.data,
    template.enabled,
    user.benutzerCode,
    user.email,
    user.firstName,
    user.lastName,
    user.displayName,
    presetFieldsJson,
    msTeamsAvailable,
  ]);
};
