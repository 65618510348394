import React, { RefObject } from 'react';
import { TableInstance } from 'react-table';

import { ColumnInstance } from '@work4all/components/lib/dataDisplay/basic-table/types';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { Popover } from '../popover/Popover';

import { VisibleColumnsContext } from './visible-columns-context';

export interface IGroupingsProps {
  visibleColumns: ColumnInstance[];
  tableInstanceRef: RefObject<TableInstance>;
  popoverState: ReturnType<typeof usePopoverState>;
  onResetColumns?: () => void;
  title?: string;
}

export const ColumnsVisibility: React.FC<IGroupingsProps> = React.memo(
  function ColumnsVisibility(props) {
    const {
      visibleColumns,
      tableInstanceRef,
      popoverState,
      onResetColumns,
      title,
    } = props;

    return (
      <VisibleColumnsContext.Provider value={visibleColumns}>
        <Popover
          popoverState={popoverState}
          tableInstanceRef={tableInstanceRef}
          type="columnsVisibility"
          onResetColumns={onResetColumns}
          title={title}
        />
      </VisibleColumnsContext.Provider>
    );
  }
);
