import React from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { LabeledCurrencyInput } from '@work4all/components/lib/input/labeled-currency-input';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { CheckboxRadioItem } from '../../../../../../../../locked-inputs';
import { ControlWrapper } from '../../../../../../../components/control-wrapper';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { BPMaskFormValue } from '../../../../../types';

export const Warning: React.FC = () => {
  const { t } = useTranslation();

  const { register } = useFormContextPlus<BPMaskFormValue>();

  const mask = useMaskContext<Customer>();
  const data = mask.data;

  return (
    <Collapse defaultOpen={true} title={t('MASK.WARNING')}>
      <ControlWrapper columns={3}>
        <ControlWrapper paddingBottom={false}>
          <LabeledCurrencyInput
            defaultValue={data?.dunningTolerance}
            {...register('dunningTolerance', {
              value: data?.dunningTolerance,
            })}
            label={t('INPUTS.DUNNING_TOLERANCE')}
            unit={t('INPUTS.DAYS')}
          />
          {mask.entity === Entities.customer && (
            <CheckboxRadioItem
              {...register('dunningBlock')}
              label={t('INPUTS.DUNNING_BLOCK')}
              defaultChecked={mask.data?.dunningBlock}
            />
          )}
        </ControlWrapper>
      </ControlWrapper>
    </Collapse>
  );
};
