import { gql, MutationHookOptions, useMutation } from '@apollo/client';

import { InputNotificationKey } from '@work4all/models/lib/Classes/InputNotificationKey.entity';

export function useSetNotificationReadMutation(
  options?: MutationHookOptions<
    SetNotificationReadResponse,
    SetNotificationReadVariables
  >
) {
  return useMutation<SetNotificationReadResponse, SetNotificationReadVariables>(
    SET_NOTIFICATION_READ,
    options
  );
}

const SET_NOTIFICATION_READ = gql`
  mutation SetNotificationRead(
    $notificationIds: [InputNotificationKey]
    $all: Boolean
  ) {
    setNotificationRead(notificationIds: $notificationIds, all: $all)
  }
`;

type SetNotificationReadVariables = {
  notificationIds?: InputNotificationKey[];
  all?: boolean;
};

type SetNotificationReadResponse = {
  setNotificationRead: boolean;
};
