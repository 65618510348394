import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item/CheckboxRadioItem';

import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { ProjectMaskFormValue } from '../../../../../types';

export const Blockages: React.FC = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<ProjectMaskFormValue>();

  return (
    <Collapse bottomIndent="lg" defaultOpen title={t('MASK.BLOCKAGES')}>
      <ControllerPlus
        name="lockInhouseServices"
        control={control}
        render={({ field }) => {
          const value = Boolean(field?.value);

          return (
            <CheckboxRadioItem
              label={t('INPUTS.BLOCKING_OF_PERSONAL_CONTRIBUTIONS')}
              checked={value}
              onChange={() => field.onChange(value ? 0 : 1)}
            />
          );
        }}
      />

      <ControllerPlus
        name="lockExternalServices"
        control={control}
        render={({ field }) => {
          const value = Boolean(field?.value);

          return (
            <CheckboxRadioItem
              label={t('INPUTS.BLOCKING_OF_EXTERNAL_SERVICES')}
              checked={value}
              onChange={() => field.onChange(value ? 0 : 1)}
            />
          );
        }}
      />
    </Collapse>
  );
};
