import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputUserRelation } from './InputUserRelation.entity';

export class InputUserCollectionRelation<T extends EMode = EMode.entity> {
  /** Alias: users */
  users?: InputUserRelation<T>;

  __typename?: string;
}

const fields: FieldDefinitions<InputUserCollectionRelation> = {
  users: {
    alias: 'users',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputUserCollectionRelationEntityDefinition: EntitiyDefinition<InputUserCollectionRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputUserCollectionRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
