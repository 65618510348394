import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useDataMutation,
  useInaccessibleFields,
  useUser,
} from '@work4all/data';

import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EntityPickerPopover } from '../../entity-picker/components';
import { useEntityPreview } from '../hooks/use-entity-preview';

import { InfoCard } from './info-card/InfoCard';
import { InfoCards } from './info-cards/InfoCards';

export interface PreviewContentField<T> {
  key: keyof T;
  label: string;
  disabled?: boolean;
  truncate?: boolean;
  formater?: (field: T[keyof T], entry: T) => string;
  getPicker?: (props: GetPickerProps<T>) => JSX.Element;
}

export interface PreviewContentProps<T> {
  entity: Entities;
  entries: T[];
  fields: PreviewContentField<T>[];
}

export function PreviewContent<T>(props: PreviewContentProps<T>) {
  const { entity, fields, entries } = props;

  const user = useUser();

  const [mutate] = useDataMutation<T, EMode.upsert>({
    entity,
    mutationType: EMode.upsert,
    responseData: { id: null } as unknown as T,
  });

  // TODO: is it even needed?
  const openPicker = useCallback(() => {}, []);
  const entityPreviewResult = useEntityPreview({
    user,
    subEntityType: entity,
    entries,
    openPicker,
    mutate,
  });
  const { isInaccessible } = useInaccessibleFields();

  const cards = fields
    .filter((field) => !isInaccessible(entity, field.key.toString()))
    .map((field) => {
      return (
        <PickerCardInfo
          field={field}
          entry={entries[0]}
          entityPreviewResult={entityPreviewResult}
        />
      );
    });

  return <InfoCards>{cards}</InfoCards>;
}

// TODO: hopefully this is only
interface GetPickerProps<T> {
  isCommon: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onEdit: (field: any, otherArgs?: any) => Promise<void>;
  entry: T;
}

interface PickerCardInfo<T> {
  field: PreviewContentField<T>;
  entityPreviewResult: ReturnType<typeof useEntityPreview>;
  entry: T;
}

function PickerCardInfo<T>(props: PickerCardInfo<T>) {
  const { field, entityPreviewResult, entry } = props;
  const { handleLock, commonFields, isMultiselect, onPopoverClose, onEdit } =
    entityPreviewResult;
  const {
    getPicker,
    disabled = true,
    truncate = true,
    formater,
    key,
    label,
  } = field;

  const isCommon = commonFields.includes(key.toString());

  const { t } = useTranslation();
  const popoverRef = useRef();

  const carInfoComponent = (
    <InfoCard
      key={key.toString()}
      disabled={disabled}
      truncate={truncate}
      label={label}
    >
      {isMultiselect && !isCommon
        ? `(${t('COMMON.MULTIPLE')})`
        : formater
        ? formater(entry[key], entry)
        : entry[key]}
    </InfoCard>
  );

  if (!getPicker) return carInfoComponent;

  return (
    <EntityPickerPopover
      ref={popoverRef}
      disabled={disabled}
      onTargetClick={() => handleLock(key)}
      onClose={onPopoverClose}
      // TODO: better centrilize place for pickers like we have get controller / table
      picker={getPicker({ isCommon, onEdit, entry })}
    >
      {carInfoComponent}
    </EntityPickerPopover>
  );
}
