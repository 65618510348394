import { gql } from '@apollo/client';

import { prepareResponse } from '@work4all/data';

import { InboundInvoice } from '@work4all/models/lib/Classes/InboundInvoice.entity';
import { InputEingangsrechnungRelation } from '@work4all/models/lib/Classes/InputEingangsrechnungRelation.entity';
import { InputErpAnhangAttachementsRelation } from '@work4all/models/lib/Classes/InputErpAnhangAttachementsRelation.entity';
import { InputRESachkontenSplit } from '@work4all/models/lib/Classes/InputRESachkontenSplit.entity';
import { ModifyShadowREResult } from '@work4all/models/lib/Classes/ModifyShadowREResult.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

const SHADOW_RE_OBJECT_RESPONSE_FIELDS: ModifyShadowREResult<EMode.query> = {
  id: null,
  name: null,
  data: {
    id: null,
    note: null,
    paymentTermDays: null,
    receiptKind: null,
    reference: null,
    rNumber: null,
    rNumberAtSupplier: null,
    receivedDate: null,
    dueDate: null,
    date: null,
    plannedDeliveryDate: null,
    deliveryDate: null,
    bookingDate: null,
    supplier: {
      id: null,
      name: null,
      number: null,
      standardAccount: null,
    },
    currencyId: null,
    currency: {
      id: null,
      name: null,
    },
    paymentKind: {
      id: null,
      note: null,
    },
    paymentId: null,
    businessPartnerId: null,
    rAmount: null,
    rVat: null,
    skonto: null,
    skonto2: null,
    skontoDate: null,
    skonto2Date: null,
    skontoDurationDays: null,
    skonto2DurationDays: null,
    skontoPercent: null,
    inboundDeliveryNoteId: null,
    approvalByUser: {
      id: null,
      displayName: null,
    },
    belegList: [
      {
        id: null,
        name: null,
        fileInfos: {
          fileSize: null,
          fileEntityFilename: null,
          previewUrl: null,
          downloadUrl: null,
          previewMimeType: null,
          downloadMimeType: null,
        },
      },
    ],
    approvedByUserId: null,
    bankDetailsId: null,
    bankDetails: {
      id: null,
      name: null,
      bic: null,
      iban: null,
    },
    releaseUserId: null,
    releaseUser: {
      id: null,
      displayName: null,
    },
    order: [
      {
        id: null,
        date: null,
        number: null,
        note: null,
      },
    ],
    inboundDeliveryNote: [
      {
        id: null,
        date: null,
        number: null,
        note: null,
      },
    ],
    blockedByUser: {
      id: null,
      displayName: null,
    },
    lockUserCode: null,
    buchungen: [
      {
        id: null,
        konto: { id: null, number: null, name: null },
        costCenter: { id: null, number: null, name: null },
        project: { id: null, number: null, name: null },
        costGroup: { id: null, name: null },
        note: null,
        taxKey: null,
        valueNet: null,
        vat: null,
        vatAmount: null,
        proportionDM: null,
      },
    ],
    sum: null,
  },
};

export { SHADOW_RE_OBJECT_RESPONSE_FIELDS };

const SHADOW_RE_OBJECT_RESPONSE_GQL = prepareResponse(
  Entities.modifyShadowREResult,
  SHADOW_RE_OBJECT_RESPONSE_FIELDS
);

export const CREATE_SHADOW_RE_OBJECT = gql`
mutation CreateShadowRe(
  $reCode: Int
  $sdObjMemberCode: Int
  $projectCode: Int
  $name: String
) {
  createShadowRe(
    reCode: $reCode
    sdObjMemberCode: $sdObjMemberCode
    projectCode: $projectCode
    name: $name
  ) {
    ${SHADOW_RE_OBJECT_RESPONSE_GQL}
  }
}
`;

export const MODIFY_SHADOW_RE_OBJECT = gql`
mutation ModifyShadowRe(
  $id: ID!
  $invoiceData: InputEingangsrechnung!
  $relations: InputEingangsrechnungRelation
) {
  modifyShadowRe(
    id: $id
    invoiceData: $invoiceData
    relations: $relations
  ) {
    ${SHADOW_RE_OBJECT_RESPONSE_GQL}
  }
}
`;

export type ModifyShadowReObjectResponse = {
  modifyShadowRe: ModifyShadowREResult;
};

export type ModifyShadowReObjectVars = {
  id: string | number;
  invoiceData?: InboundInvoice;
  relations?: InputEingangsrechnungRelation;
};

export type CreateShadowReObjectVars = {
  reCode?: number;
  projectCode?: number;
  sdObjMemberCode?: number;
  name?: string | null;
};

export type CreateShadowReObjectResponse = {
  createShadowRe: ModifyShadowREResult;
};

export const MODIFY_SHADOW_BZ_OBJECT_ADD_POSITION = gql`
  mutation ModifyShadowBzObjectAddBooking(
    $id: ID!
    $fromDeliveryNotes: [Int]
    $fromOrders: [Int]
    $recreate: Boolean
  ) {
    modifyShadowReAddBuchung( 
      id: $id
      fromDeliveryNotes: $fromDeliveryNotes
      fromOrders: $fromOrders
      recreate: $recreate
    ) {
      ${SHADOW_RE_OBJECT_RESPONSE_GQL}
    }
  }
`;

export type ModifyShadowBzObjectAddPositionResponse = {
  modifyShadowReAddBuchung: ModifyShadowREResult;
};

export type ModifyShadowBzObjectAddPositionVars = {
  id: string;
  fromDeliveryNotes?: number[];
  fromOrders?: number[];
  recreate?: boolean;
};

export const MODIFY_SHADOW_BZ_OBJECT_REMOVE_BOOKING = gql`
mutation ModifyShadowBzObjectRemoveBooking($id: ID!, $lineId: Int!) {
  modifyShadowReRemoveBuchung(id: $id, lineCode: $lineId) {
    ${SHADOW_RE_OBJECT_RESPONSE_GQL}
  }
}
`;

export type ModifyShadowBzObjectRemoveBookingResponse = {
  modifyShadowReRemoveBuchung: ModifyShadowREResult;
};

export type ModifyShadowBzObjectRemoveBookingVars = {
  id: string;
  lineId: number;
};

export const MODIFY_SHADOW_BZ_OBJECT_MOVE_POSITION = gql`
mutation ModifyShadowObjectMovePosition(
  $id: ID!
  $positionId: Int!
  $index: Int!
) {
  modifyShadowBzObjectMovePosition(
    id: $id
    positionCode: $positionId
    newIndex: $index
  ) {
    ${SHADOW_RE_OBJECT_RESPONSE_GQL}
  }
}
`;

export type ModifyShadowBzObjectMovePositionResponse = {
  modifyShadowBzObjectMovePosition: ModifyShadowREResult;
};

export type ModifyShadowBzObjectMovePositionVars = {
  id: string | number;
  positionId: number;
  index: number;
};

export const PERSIST_SHADOW_RE_OBJECT = gql`
  mutation PersistShadowRe($id: ID!, $attachments: InputErpAnhangAttachementsRelation, $receipts: InputErpAnhangAttachementsRelation) {
    persistShadowRe(id: $id, removeShadowBzObject: false, attachments: $attachments, receipts: $receipts) {
      ${SHADOW_RE_OBJECT_RESPONSE_GQL}
    }
  }
`;

export type PersistShadowReObjectResponse = {
  persistShadowRe: number;
};

export type PersistShadowReObjectVars = {
  id: string;
  attachments?: InputErpAnhangAttachementsRelation;
  receipts?: InputErpAnhangAttachementsRelation;
  closeAssignedObjects?: boolean;
};

export const DELETE_SHADOW_RE_OBJECT = gql`
  mutation DeleteShadowRe($id: ID!) {
    deleteShadowRe(id: $id)
  }
`;

export type DeleteShadowReObjectResponse = {
  deleteShadowRe: ModifyShadowREResult;
};

export type DeleteShadowBzObjectVars = {
  id: string | number;
};

export const MODIFY_SHADOW_BZ_OBJECT_MODIFY_POSITION = gql`
  mutation ModifyShadowBzObjectModifyPosition(
    $id: ID!
    $booking: InputRESachkontenSplit!
  ) {
    modifyShadowREModifyLine(id: $id, line: $booking) {
      ${SHADOW_RE_OBJECT_RESPONSE_GQL}
    }
  }
`;

export type ModifyShadowBzObjectModifyBookingResponse = {
  modifyShadowREModifyLine: ModifyShadowREResult;
};

export type ModifyShadowBzObjectModifyBookingVars = {
  id: string;
  booking: InputRESachkontenSplit;
};
