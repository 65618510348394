import { Grid } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import {
  MultiStepControls,
  Step,
} from '@work4all/components/lib/input/multi-step-controls';

import { Contract } from '@work4all/models/lib/Classes/Contract.entity';
import { Offer } from '@work4all/models/lib/Classes/Offer.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { BankDetailsPickerField } from '../../../../../../../../../../components/entity-picker/BankDetailsPickerField';
import { CurrencyPickerField } from '../../../../../../../../../../components/entity-picker/CurrencyPickerField';
import { DeliveryKindPickerField } from '../../../../../../../../../../components/entity-picker/DeliveryKindPickerField';
import { PartialInvoiceCalculationLogicPickerField } from '../../../../../../../../../../components/entity-picker/PartialInvoiceCalculationPickerField';
import { PaymentKindPickerField } from '../../../../../../../../../../components/entity-picker/PaymentKindPickerField';
import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import {
  DateTimeInputPicker,
  LabeledInputWithUnit,
} from '../../../../../../../../locked-inputs';
import { ControlWrapper } from '../../../../../../../components/control-wrapper';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { ErpData, ErpPaymentData } from '../../../../../ErpData';
import { FormCheck } from '../../../../FormCheck';

export const Payment: React.FC = () => {
  const { t } = useTranslation();
  const mask = useMaskContext();
  const { control, watch, register } = useFormContextPlus<ErpPaymentData>();

  if (mask.entity === Entities.inboundDeliveryNote) {
    return null;
  }
  const businessPartner = watch(
    'businessPartnerContactCombined.businessPartner'
  );

  const date = watch('date');

  return (
    <Collapse defaultOpen={true} title={t('MASK.PAYMENT')}>
      <ControlWrapper columns={3}>
        <Grid container spacing="1rem">
          <Grid item xs={6}>
            <ControllerPlus
              control={control}
              name="paymentKind"
              render={({ field }) => {
                return <PaymentKindPickerField {...field} />;
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <ControllerPlus
              control={control}
              name="currency"
              render={({ field }) => {
                return <CurrencyPickerField {...field} clearable={false} />;
              }}
            />
          </Grid>
        </Grid>
        <LabeledInputWithUnit
          label={t('INPUTS.NET_PAYMENT')}
          unit={t('INPUTS.DAY')}
          {...register('paymentDeadline')}
          type="number"
        />
        <MultiStepControls>
          <Step active={true} index={0}>
            <LabeledInputWithUnit
              label={t('INPUTS.DISCOUNT')}
              unit={t('INPUTS.DAY')}
              {...register('skontoDurationDays')}
            />
          </Step>
          <Step active={true} index={1}>
            <LabeledInputWithUnit
              label={t('INPUTS.DISCOUNT_VALUE')}
              unit="%"
              {...register('skonto')}
              type="number"
            />
          </Step>
          <Step active={true} index={2}>
            <ControllerPlus
              name="skontoDurationDays"
              control={control}
              render={({ field }) => {
                const dateTime = DateTime.fromISO(date);

                return (
                  <DateTimeInputPicker
                    withTime={false}
                    required={false}
                    value={dateTime.plus({ days: field.value || 0 }).toISO()}
                    onChange={(
                      newValue: React.ChangeEvent<HTMLInputElement>
                    ) => {
                      const newDateTime = DateTime.fromISO(
                        newValue.target.value
                      );
                      const days = newDateTime.diff(dateTime, ['days']).days;
                      if (days >= 0) {
                        field.onChange(days);
                      } else {
                        field.onChange(0);
                      }
                    }}
                    dateLabel={t('INPUTS.DUE_DATE')}
                  />
                );
              }}
            />
          </Step>
        </MultiStepControls>
      </ControlWrapper>
      <ControlWrapper columns={3}>
        {![Entities.demand, Entities.calculation].includes(mask.entity) && (
          <ControllerPlus
            name="deliveryKind"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <DeliveryKindPickerField
                  {...field}
                  error={fieldState?.error?.message}
                />
              );
            }}
          />
        )}

        {![Entities.demand, Entities.calculation].includes(mask.entity) && (
          <ControllerPlus
            control={control}
            name="bankDetails"
            render={({ field }) => {
              const { value, onChange } = field;
              const disabled = !businessPartner?.id;
              const entity =
                businessPartner?.businessPartnerType === SdObjType.KUNDE
                  ? Entities.customer
                  : Entities.supplier;
              return (
                <BankDetailsPickerField
                  disabled={disabled}
                  value={value}
                  onChange={onChange}
                  entity={entity}
                  entityId={businessPartner?.id}
                />
              );
            }}
          />
        )}
        <FormCheck<ErpData, Offer | Contract>
          control={control}
          entity={mask.entity}
          allowedEntities={[Entities.offer, Entities.contract]}
          render={({ control }) => (
            <ControllerPlus
              name="partialInvoiceLogic"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <PartialInvoiceCalculationLogicPickerField
                    {...field}
                    error={fieldState?.error?.message}
                  />
                );
              }}
            />
          )}
        />
      </ControlWrapper>
    </Collapse>
  );
};
