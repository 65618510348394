import { Header } from '../../../../components/header/Header';
import { TakeRestHeight, YSplit } from '../../../../layout/y-split';
import { DesktopWrapper } from '../../../../navigation/desktop-wrapper/DesktopWrapper';

interface DesktopProps {
  timeTracker: React.ReactNode;
  calendar: React.ReactNode;
  userMenu: React.ReactNode;
  globalSearch: React.ReactNode;
  favoriteLinks?: React.ReactNode;
}

export const Desktop: React.FC<DesktopProps> = (props) => {
  const {
    favoriteLinks,
    timeTracker,
    userMenu,
    children,
    globalSearch,
    calendar,
  } = props;

  const takeRestScreenStyles = { height: '100%' };

  return (
    <YSplit style={{ height: '100%' }}>
      <Header favoriteLinks={favoriteLinks}>
        {calendar}
        {timeTracker}
        {globalSearch}
        {userMenu}
      </Header>
      <TakeRestHeight style={takeRestScreenStyles}>
        <DesktopWrapper>{children}</DesktopWrapper>
      </TakeRestHeight>
    </YSplit>
  );
};
