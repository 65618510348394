import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomToolbarAction } from '@work4all/components/lib/dataDisplay/actions-bar/ToolbarTypes';

import { Checked, Unchecked } from '../../grid/cells/CheckBoxGridCell';
import { useGridState } from '../../grid/GridStateProvider';

type IdType = number | string;
interface UseProjectActionsOptions {
  addGroupRight: () => void;
  editGroupRight: (id: IdType | IdType[]) => void;
  disallowAll: (id: IdType | IdType[]) => void;
  allowAll: (id: IdType | IdType[]) => void;
}

export const useProjectActions = (
  options: UseProjectActionsOptions
): CustomToolbarAction[] => {
  const { selectedColumns } = useGridState();

  const { addGroupRight, editGroupRight, allowAll, disallowAll } = options;
  const { t } = useTranslation();
  return useMemo<CustomToolbarAction[]>(() => {
    const result: CustomToolbarAction[] = [];

    result.push({
      actionKey: 'addGroupRight',
      IconComponent: PersonAddIcon,
      title: t('COMMON.RIGHTS_GROUP.NEW'),
      handler: addGroupRight,
      mode: 'Add',
      disabled: false,
    });
    if (!selectedColumns.length) return result;
    result.push({
      actionKey: 'editGroupRight',
      IconComponent: EditIcon,
      title: t('COMMON.RIGHTS_GROUP.EDIT'),
      handler: editGroupRight,
      mode: 'Add',
      disabled: false,
    });

    result.push({
      actionKey: 'allowAll',
      IconComponent: () => Checked,
      title: t('COMMON.RIGHTS_GROUP.ALLOW_ALL_TITLE'),
      handler: allowAll,
      mode: 'Add',
      disabled: false,
    });

    result.push({
      actionKey: 'disallowAll',
      IconComponent: () => Unchecked,
      title: t('COMMON.RIGHTS_GROUP.DISALLOW_ALL_TITLE'),
      handler: disallowAll,
      mode: 'Add',
      disabled: false,
    });

    return result;
  }, [
    t,
    addGroupRight,
    selectedColumns.length,
    editGroupRight,
    allowAll,
    disallowAll,
  ]);
};
