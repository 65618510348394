import styles from './LabeledInputWithUnit.module.scss';

import React from 'react';

import { ILabeledInput, LabeledInput } from '../labeled-input';

import { Unit } from './components/unit/Unit';

interface ILabeledInputWithUnitProps
  extends Omit<ILabeledInput, 'endAdornment' | 'classes'> {
  unit: string;
}

export const LabeledInputWithUnit = React.forwardRef<
  HTMLInputElement,
  ILabeledInputWithUnitProps
>((props, ref) => {
  const { unit, ...rest } = props;
  return (
    <LabeledInput
      ref={ref}
      {...rest}
      classes={{ input: styles.input }}
      endAdornment={<Unit unit={unit} />}
    />
  );
});
