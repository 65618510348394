import { useMemo } from 'react';
import { Row } from 'react-table';

import { fuzzyText } from '../utils/filters/fuzzyText';

export const useFilterTypes = () => {
  return useMemo(() => {
    return {
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyText,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows: Row[], id: string[], filterValue: string) => {
        return rows.filter((row) => {
          const rowValue = row.values[id[0]];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    };
  }, []);
};
