import { useEventCallback } from '@mui/material/utils';
import { useEffect, useState } from 'react';
import {
  createSearchParams,
  URLSearchParamsInit,
  useSearchParams,
} from 'react-router-dom';

import {
  EventType,
  sendAmplitudeData,
} from '@work4all/components/lib/utils/amplitude/amplitude';

import { useLocation, useNavigate } from '@work4all/data';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { resolveEntityVariant } from '../../file-entities-lists';

import { useAfterSave } from './hooks/use-after-save';
import { useOverlayRouteParams } from './hooks/use-overlay-route-params';
import { NEW_ENTITY_ID } from './mask-metadata';
import { IRouteParams, MaskConfig, MaskTemplateEntity } from './types';
import { getMaskControllerComponent } from './utils/get-mask-controller-component';

export const MaskOverlay: React.FC<{ amplitudeEntryPoint: string }> = (
  props
) => {
  const metaData = useMaskMetaData();

  const [searchParams] = useSearchParams();
  const location = useLocation();

  const navigate = useNavigate();

  const handleAfterSave = useAfterSave(props?.amplitudeEntryPoint);

  const handleOpenMask = useEventCallback(() => {
    const getNavigationPropeties = (config: MaskConfig) => {
      const { entity, id, template, params = {} } = config;
      // We can't use navigate('./{id}') form here because this component doesn't
      // actually render inside react-router's Route.
      // So relative navigation is not possible.

      let pathname = location.pathname;

      if (entity !== metaData.subEntityType) {
        // This might be problematic if the URL contains this fragment already in
        // some other place unrelated to the mask.
        pathname = pathname.replace(metaData.subEntityType, entity);
      }

      pathname = pathname.replace(
        metaData.subEntityId,
        id?.toString() ?? NEW_ENTITY_ID
      );

      const searchInit: URLSearchParamsInit = {};

      if (template) {
        searchInit.template = `${template.entity}:${template.id}`;
      }

      for (const [key, value] of Object.entries(params)) {
        if (value != null) {
          searchInit[key] = value;
        }
      }
      return {
        pathname,
        search: `${createSearchParams(searchInit)}`,
      };
    };

    return {
      getHref: (config: MaskConfig) => {
        const { pathname, search } = getNavigationPropeties(config);
        return `${pathname}?${search}`;
      },
      handler: (config: MaskConfig) => {
        const { pathname, search } = getNavigationPropeties(config);
        navigate(
          {
            pathname,
            search,
          },
          { replace: true }
        );
      },
    };
  });

  const resolved = resolveEntityVariant(metaData.subEntityType);
  const entity = resolved.entityType;
  const id =
    metaData.subEntityId === NEW_ENTITY_ID ? null : metaData.subEntityId;
  const template =
    tryParseTemplateFromSearchParams(searchParams) ??
    tryParseTemplateFromRouteParams(metaData);
  const params = parseCustomParamsFromSearchParams(searchParams);
  const openTab = searchParams.get('openTab') ?? undefined;

  const MaskController = getMaskControllerComponent(entity);

  useEffect(() => {
    sendAmplitudeData(
      id === null || id === NEW_ENTITY_ID
        ? EventType.AddElement
        : EventType.EditElement,
      {
        name: metaData.subEntityType,
        entryPoint:
          props.amplitudeEntryPoint === 'fileDetailPage'
            ? metaData.entityType
            : props.amplitudeEntryPoint,
      }
    );
  }, [
    id,
    metaData.entityType,
    metaData.subEntityType,
    props.amplitudeEntryPoint,
  ]);

  return (
    <MaskController
      key={`${entity}_${id}`}
      entity={entity}
      id={id}
      template={template}
      openTab={openTab}
      params={params}
      onAfterSave={handleAfterSave}
      onOpenMask={handleOpenMask}
      amplitudeEntryPoint={props.amplitudeEntryPoint}
      data-test-id="maskForm"
    />
  );
};

function tryParseTemplateFromSearchParams(
  searchParams: URLSearchParams
): MaskTemplateEntity | null {
  const raw = searchParams.get('template');

  if (!raw) {
    return null;
  }

  const result = /^(?<entity>.+?):(?<id>.+)$/.exec(raw);

  if (!result) {
    return null;
  }

  const { entity, id } = result.groups;

  return { entity: entity as Entities, id };
}

function tryParseTemplateFromRouteParams(
  params: IRouteParams
): MaskTemplateEntity | null {
  const entity = pathnameToEntity(params.entityType);
  const id = params.entityId;

  if (!entity || !id) {
    return null;
  }

  return { entity, id };
}

function pathnameToEntity(pathname: string): Entities | null {
  switch (pathname) {
    case 'customers':
      return Entities.customer;
    case 'suppliers':
      return Entities.supplier;
    case 'projects':
      return Entities.project;

    default:
      console.warn(`Could not parse entity from pathname: ${pathname}`);
      return null;
  }
}

function parseCustomParamsFromSearchParams(searchParams: URLSearchParams): {
  [key: string]: string;
} {
  const result: { [key: string]: string } = {};

  searchParams.forEach((value, key) => {
    // Template is a special parameter and will be passed separately from other
    // params after processing.
    if (!['template', 'openTab'].includes(key)) {
      result[key] = value;
    }
  });

  return result;
}

export function useMaskMetaData(): IRouteParams {
  // When you navigate back in the browser, the route will change and will no
  // longer include the mask parameters, but the mask component still needs to
  // render during its closing animation. Because of this we keep the old
  // `metaDataSaved` value and use it if the actual `metaDataCurrent` resolves
  // to `null`.
  const metaDataCurrent = useOverlayRouteParams();
  const [metaDataSaved, setMetaDataSaved] = useState(metaDataCurrent);

  // Update the saved value whenever the mask parameters change, except if you
  // close the mask (in this case just keep the old value).
  useEffect(() => {
    if (metaDataCurrent != null) {
      setMetaDataSaved(metaDataCurrent);
    }
  }, [metaDataCurrent]);

  const metaData = metaDataCurrent ?? metaDataSaved;

  return metaData;
}
