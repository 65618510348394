import styles from './CheckListField.module.scss';

import { Checkbox, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { useCallback } from 'react';

import { Heading4 } from '../../typography/heading4/Heading4';

interface ICheckListFieldProps {
  label?: string;
  all: ICheckItem[];
  value: ICheckItem[];
  disabled?: boolean;
  onChange: (list: ICheckItem[]) => void;
  renderIndicator?: (id: number | string) => React.ReactElement;
  hideCheckboxs?: boolean;
  scrollableHeight?: string;
  renderSideElementOnHover?: (id: number | string) => React.ReactElement;
  labelElement?: React.ReactElement;
  style?: React.CSSProperties;
}

export interface ICheckItem {
  id?: number | string;
  name?: string;
}

export const CheckListField: React.FC<ICheckListFieldProps> = (
  props: ICheckListFieldProps
) => {
  const {
    onChange,
    value,
    all,
    disabled = false,
    label,
    renderIndicator,
    hideCheckboxs = false,
    scrollableHeight,
    renderSideElementOnHover,
    labelElement,
    style,
  } = props;

  const onClick = useCallback(
    (id: number | string) => {
      if (value.some((x) => x.id === id)) {
        onChange(value.filter((x) => x.id !== id));
      } else {
        onChange([...value, all.find((x) => x.id === id)]);
      }
    },
    [value, onChange, all]
  );

  const setSingle = useCallback(
    (id: number | string) => {
      onChange([all.find((x) => x.id === id)]);
    },
    [onChange, all]
  );

  const components = all.map((item) => {
    const checked = value?.some((current) => current?.id === item?.id);

    return (
      <Stack
        key={item.id}
        className={clsx(styles['check-list__row'], {
          [styles.checked]: checked,
        })}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={style}
      >
        {hideCheckboxs === false && (
          <Checkbox
            className={styles['check-list__item']}
            onClick={() => onClick(item.id)}
            checked={checked}
            disabled={disabled}
          />
        )}
        <Typography
          className={clsx(styles['check-list__label'], {
            [styles['check-list__pointer']]: !disabled,
          })}
          component="div"
          noWrap
          title={item.name}
          onClick={() => !disabled && setSingle(item.id)}
          sx={{
            ...(disabled && { color: 'var(--text03)' }),
          }}
        >
          {item.name}
        </Typography>

        <div className={styles['check-list__item']}>
          <div className={styles['side-element']}>
            {renderSideElementOnHover && renderSideElementOnHover(item.id)}
          </div>

          {renderIndicator && renderIndicator(item.id)}
        </div>
      </Stack>
    );
  });

  return (
    <div style={{ flex: 1, overflow: 'auto' }}>
      {label && (
        <Heading4 className={styles['check-list__header']}>{label}</Heading4>
      )}
      {labelElement}
      <div
        className={clsx({
          [styles['check-list__container']]: scrollableHeight,
        })}
        style={{ maxHeight: scrollableHeight ? scrollableHeight : 'auto' }}
      >
        {components}
      </div>
    </div>
  );
};
