import { Send } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CommentEditor } from './CommentEditor';

interface CommentEditorWithAddButtonProps {
  disabled?: boolean;
  value: string;
  setValue: (value: string) => void;
  onSubmit: () => void | Promise<void>;
  canAddOrInsertImage: boolean;
  editorConf?: Record<string, unknown>;
  hideToolbar?: boolean;
}

export const CommentEditorWithAddButton = ({
  disabled,
  value,
  setValue,
  onSubmit,
  canAddOrInsertImage,
  editorConf,
  hideToolbar,
}: CommentEditorWithAddButtonProps) => {
  const { t } = useTranslation();

  return (
    <>
      <CommentEditor
        disabled={disabled}
        value={value}
        setValue={setValue}
        canAddOrInsertImage={canAddOrInsertImage}
        editorConf={editorConf}
        hideToolbar={hideToolbar}
      />
      {value.trim().length !== 0 && (
        <Button
          onClick={onSubmit}
          disabled={disabled}
          sx={{ position: 'absolute', padding: '1.5rem 0rem' }}
        >
          <Send
            style={{
              marginRight: 9,
            }}
          />
          <span>{t('MASK.ADD_COMMENT')}</span>
        </Button>
      )}
    </>
  );
};
