import styles from './ToggleButton.module.scss';

import clsx from 'clsx';
import React from 'react';

import { BaseActionButton } from '../../input/base-action-button/BaseActionButton';

interface IToggleButtonProps {
  active?: boolean;
  title: string;
  onChange?: (state: boolean) => void;
}

export const ToggleButton: React.FC<IToggleButtonProps> = (props) => {
  const { active, title, onChange } = props;

  return (
    <BaseActionButton
      onClick={() => {
        onChange !== undefined && onChange(!active);
      }}
      className={clsx(styles.toggleButton, { [styles.active]: active })}
      title={title}
    ></BaseActionButton>
  );
};
