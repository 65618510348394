import styles from './Login.module.scss';

import {
  Button,
  ButtonBase,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import md5 from 'md5';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Path } from 'react-router-dom';
import { useRxDB } from 'rxdb-hooks';

import { ReactComponent as CircleNotch } from '@work4all/assets/icons/circle_notch.svg';

import {
  TakeRestHeight,
  YSplit,
} from '@work4all/components/lib/layout/y-split';

import {
  components,
  httpClient,
  IUser,
  Navigate,
  useLocation,
  useUser,
} from '@work4all/data';
import { USER_META_ID } from '@work4all/data/lib/db';

import Bubble from '../../assets/icons/Bubble_4.svg';
import { ApplicationName } from '../../utils/ApplicationName.enum';

import { ErrorMessage } from './components/error-message/ErrorMessage';
import { TextField } from './components/text-field';
import { getAutodiscoveryBaseUrl } from './utils/getAutodiscoveryBaseUrl';

interface IForm {
  apiUrl?: string;
  password: string;
}

const initialValues: components['schemas']['AuthRequestV2'] & IForm = {
  username: '',
  password: '',
  apiUrl: '',
};

export type LoginFormikFields = typeof initialValues;

export const LoginPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <LoginPageWrapper>
      {({ onSubmit, loading, error, setAutoDiscovery, autodiscovery }) => {
        return (
          <>
            <img className={styles['login__bubble-icon']} src={Bubble} alt="" />
            <div className={styles.titleWrapper}>
              <Typography
                className={styles['login__title']}
                component="h4"
                variant="h4"
              >
                {t('LOGIN.WELCOME')}
              </Typography>
            </div>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
              <Form className={styles['login__form']}>
                {error && (
                  <ErrorMessage
                    data-test-id="login-error-message"
                    message={
                      t(`LOGIN.MESSAGE.${error.type}`) ||
                      t('LOGIN.WRONG_PASSWORD')
                    }
                  />
                )}
                <TextField
                  label={t('LOGIN.EMAIL')}
                  name="username"
                  type="email"
                  required
                />
                <TextField
                  label={t('LOGIN.PASSWORD')}
                  name="password"
                  type="password"
                  required
                />
                {!autodiscovery && (
                  <TextField
                    label={t('LOGIN.API_URL')}
                    name="apiUrl"
                    onClear={(formikHelpers) => {
                      formikHelpers.setValue('');
                    }}
                  />
                )}
                <ButtonBase className={styles['autodiscovery-controller']}>
                  <FormControlLabel
                    classes={{
                      root: styles['autodiscovery-controller__label-root'],
                      label: styles['autodiscovery-controller__label'],
                    }}
                    checked={autodiscovery}
                    onChange={() => setAutoDiscovery(!autodiscovery)}
                    value="autodiscovery"
                    control={
                      <Checkbox
                        classes={{
                          root: styles['autodiscovery-controller__checkbox'],
                          checked:
                            styles[
                              'autodiscovery-controller__checkbox--checked'
                            ],
                        }}
                        size="small"
                      />
                    }
                    label={t<string>('LOGIN.API_AUTODETECT')}
                  />
                </ButtonBase>
                <Button
                  type="submit"
                  className={styles['login__submit-btn']}
                  variant="text"
                  color="secondary"
                >
                  <span className={styles['login__submit-btn-label']}>
                    {loading ? (
                      <CircleNotch className={styles.circle} />
                    ) : (
                      t('LOGIN.LOGIN')
                    )}
                  </span>
                </Button>
              </Form>
            </Formik>
          </>
        );
      }}
    </LoginPageWrapper>
  );
};

interface LoginPageWrapperProps {
  children: (props: {
    onSubmit: (values: LoginFormikFields) => Promise<void>;
    loading: boolean;
    error: {
      message: string;
      type: number;
    };
    autodiscovery: boolean;
    setAutoDiscovery: React.Dispatch<React.SetStateAction<boolean>>;
  }) => React.ReactNode;
}

interface AuthLoginBody {
  username: string;
  passwordHash: string;
  application: ApplicationName.work4all20;
}

export const LoginPageWrapper: React.FC<LoginPageWrapperProps> = (
  props: LoginPageWrapperProps
) => {
  const { t } = useTranslation();
  const [autodiscovery, setAutoDiscovery] = useState(true);
  const db = useRxDB();
  const user = useUser();
  const [error, setError] = useState<{ message: string; type: number } | null>(
    null
  );
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  const unmountedRef = useRef(false);
  useEffect(() => {
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  if (user) {
    const locationState = location.state as { from: Path };
    return <Navigate to={locationState?.from || '/home'} replace />;
  }

  const onSubmit = async (values: LoginFormikFields) => {
    let baseUrl: string;
    let apiUrl: string;
    const username = values.username.trim();
    if (autodiscovery) {
      baseUrl = await getAutodiscoveryBaseUrl(username || '');
      apiUrl = `${baseUrl}/api`;
    } else {
      apiUrl = values.apiUrl?.trim().replace(/\/$/, '') || '';
      baseUrl = new URL(apiUrl).origin;
    }

    const passwordHash = md5(values.password);
    setError(null);
    setLoading(true);

    if (baseUrl === '') {
      setError({ message: t('LOGIN.API_NOT_FOUND'), type: 404 });
      setLoading(false);
      return;
    }

    try {
      // TODO The API response is not typed correctly after the recent changes.
      const res = await httpClient.post<
        components['schemas']['AuthResultV2'],
        AuthLoginBody
      >({
        url: `${apiUrl}/Auth/login`,
        body: {
          username: username,
          passwordHash,
          application: ApplicationName.work4all20,
        },
        headers: {
          'x-work4all-apiurl': baseUrl,
        },
        contentType: 'application/json',
      });

      if (res.data === null) {
        setError({ message: t('LOGIN.API_NOT_FOUND'), type: 404 });
        setLoading(false);
        return;
      }

      if (res.data.mandanten.length === 0) {
        setError({ message: t('LOGIN.NO_MANDANTEN'), type: 403 });
        setLoading(false);
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = res.data as any;

      const roles: IUser['roles'] = data.currentUser.userRoles.map((role) => ({
        id: role.code,
        name: role.name,
      }));

      const user: IUser = {
        benutzerCode: data.benutzerCode,
        benutzerRechte: data.benutzerRechte,
        token: data.token,
        refreshToken: data.refreshToken,
        baseUrl,
        mandanten: data.mandanten,
        roles,
        firstName: data.currentUser.vorname,
        lastName: data.currentUser.nachname,
        shortName: data.currentUser.zeichen,
        displayName: data.currentUser.anzeigename,
        salutation: data.currentUser.briefAnrede,
        phone: data.currentUser.telefon,
        email: data.currentUser.eMail,
        departmentName: data.currentUser.abteilung,
        isMaster: data.currentUser.master,
        kundennummer: data.kundennummer,
        senderMailaddresses: data.currentUser.senderMailaddresses,
        kommtGehtMinimaleStartZeit: data.currentUser.kommtGehtMinimaleStartZeit,
        supplierCode: data.currentUser.lieferantenCode,
      };

      sessionStorage.removeItem('currentTimeSettings');
      await db.upsertLocal(USER_META_ID, {
        user,
      });
    } catch (error) {
      console.error('Login Error:', error);
      setError({
        message: error?.data?.message,
        type: error?.data?.type,
      });
    }

    if (!unmountedRef.current) {
      setLoading(false);
    }
  };

  return (
    <YSplit>
      <TakeRestHeight>
        <div className={styles.login}>
          <div className={styles['login__top-block']} />
          {props.children({
            onSubmit,
            loading,
            error,
            autodiscovery,
            setAutoDiscovery,
          })}
        </div>
      </TakeRestHeight>
    </YSplit>
  );
};
