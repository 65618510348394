const LS_KEY = 'missing-chunks-retry';

// Will not reload again if we already tried within this window.
const RETRY_TIME_WINDOW = 60_000; // 1 minute.

type RetryState = {
  revision: string;
  timestamp: number;
};

// Keep track of reloading state. There is no sense in trying to reload again if
// we are already reloading.
let isReloading = false;

function getSavedState(): RetryState | null {
  const item = localStorage.getItem(LS_KEY);

  try {
    const parsed = JSON.parse(item);
    return parsed;
  } catch {
    // Do nothing.
  }

  return null;
}

function setSavedState(state: RetryState): void {
  localStorage.setItem(LS_KEY, JSON.stringify(state));
}

/**
 * Check the last reload attempt. If we recently tried to reload already, this
 * returns `false` so we don't go into an infinite loop.
 */
export function checkReloadState(): {
  canReload: boolean;
  isReloading: boolean;
} {
  if (isReloading)
    return {
      canReload: false,
      isReloading: true,
    };

  const state = getSavedState();

  if (state) {
    const { revision, timestamp } = state;

    if (
      revision === process.env.APP_REVISION &&
      timestamp > Date.now() - RETRY_TIME_WINDOW
    ) {
      return {
        canReload: false,
        isReloading: false,
      };
    }
  }

  // If any of the checks fails, we should reload.
  return {
    canReload: true,
    isReloading: false,
  };
}

/**
 * Saves metadata about current reload attempts and reloads the page.
 */
export function reload() {
  isReloading = true;

  const state: RetryState = {
    revision: process.env.APP_REVISION,
    timestamp: Date.now(),
  };

  setSavedState(state);

  // Firefox accepts a boolean `forceGet` parameter that allows you to bypass
  // the cache when reloading. This can be helpful if cache control headers are
  // not configured correctly so we can still reload. This doesn't affect other
  // browsers.
  // https://developer.mozilla.org/en-US/docs/Web/API/Location/reload#parameters

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore-error
  window.location.reload(true);
}
