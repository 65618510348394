import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class InputNotizAnhang<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: number;
  /** Alias: filename */
  filename?: string;
  /** Alias: notiz */
  notiz?: string;

  __typename?: string;
}

const fields: FieldDefinitions<InputNotizAnhang> = {
  code: {
    alias: 'code',
  },
  filename: {
    alias: 'filename',
  },
  notiz: {
    alias: 'notiz',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputNotizAnhangEntityDefinition: EntitiyDefinition<InputNotizAnhang> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputNotizAnhang',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
