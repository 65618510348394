import styles from './Document.module.scss';

import { BlurOn } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { InfoCard } from '@work4all/components/lib/components/entity-preview/components';
import { getTextHeight } from '@work4all/components/lib/dataDisplay/basic-table/utils/get-text-height/get-text-height';

import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';
import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import {
  DateFormatPreset,
  formatDateString,
} from '@work4all/utils/lib/date-utils/formatDateString';

import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { ErpDataIntersection } from '../../../../../ErpData';
import { Signature } from '../../../../signature/Signature';
import { PositionsWithAutoDisable } from '../../../positions/components/Positions';
import { ErpTextEditor } from '../erp-text-editor/ErpTextEditor';
import { LogoPlaceholder } from '../logo-placeholder/LogoPlaceholder';
import { ParticipantsAndAddresses } from '../participants-and-addresses/ParticipantsAndAddresses';

export const Document: React.VFC = () => {
  const { entity } = useMaskContext();
  const { watch, setValue } = useFormContextPlus<ErpDataIntersection>();

  const headText = watch('rtfHeadText');
  const footerText = watch('rtfFooterText');

  const date = watch(entity === Entities.contract ? 'contractDate' : 'date');
  const responsible = watch('user');
  const project = watch('project');
  const positionList = watch('positionList');
  const businessPartnerContactCombined = watch(
    'businessPartnerContactCombined'
  );

  const number = watch(
    entity === Entities.contract ? 'contractNumber' : 'number'
  );

  const { t } = useTranslation();
  const { activeTenantData } = useTenant();

  const totalHeight = useMemo(() => {
    const allHeights = positionList?.map((x) => {
      const calc = getTextHeight({ text: x.longtext }) + remToPx(0.5);
      const minHeight = 40;
      return calc > minHeight ? calc : minHeight;
    });

    return (
      (allHeights?.length ? allHeights.reduce((a, b) => a + b) : 0) +
      remToPx(12)
    );
  }, [positionList]);

  return (
    <Box position="relative" display="flex" flex="1" width="100%">
      <BlurOn className={styles.backgroundIcon} />
      <Box
        position="relative"
        zIndex="100"
        display="flex"
        flexDirection="column"
        flex="1"
      >
        <Box textAlign="right" padding="6rem 3rem 6rem 6rem">
          <Box display="inline-block">
            <LogoPlaceholder title={activeTenantData?.name} />
          </Box>
        </Box>
        <Box
          padding="0 3rem 0 6rem"
          marginBottom="4rem"
          display="flex"
          gap="1rem"
          alignItems="start"
        >
          <Box flex={1}>
            <ParticipantsAndAddresses />
          </Box>
        </Box>
        <Box
          padding="0 3rem 0 6rem"
          marginBottom="2rem"
          display="flex"
          alignItems="center"
          gap="2rem"
        >
          <Typography flex="1" variant="h3" className={styles.bzObjTypeHeading}>
            {t('COMMON.' + entity.toUpperCase())} {number}
          </Typography>
        </Box>
        <Box
          padding="0 3rem 0 6rem"
          marginBottom="1rem"
          display="flex"
          alignItems="top"
          gap="4rem"
        >
          <InfoCard
            label={t(
              businessPartnerContactCombined?.businessPartner
                ?.businessPartnerType === SdObjType.KUNDE
                ? 'INPUTS.CLIENT_NUMBER'
                : 'INPUTS.SUPPLIER_NUMBER'
            )}
            staticField
          >
            {businessPartnerContactCombined?.businessPartner?.['data']
              ?.number || '-'}
          </InfoCard>

          <InfoCard label={t('COMMON.CONTACT')} staticField>
            {responsible?.displayName}
          </InfoCard>
          <Box flex={1}>
            <InfoCard label={t('COMMON.PROJECT')} staticField>
              {project ? `${project?.number} | ${project?.name}` : '-'}
            </InfoCard>
          </Box>

          <InfoCard label={t('COMMON.DATE')} staticField>
            {formatDateString(date, DateFormatPreset.DATE_SIMPLE_4YEAR)}
          </InfoCard>
        </Box>

        <Box padding="0 3rem 0 6rem" marginBottom="1rem">
          <ErpTextEditor
            onChange={(value: string) => {
              setValue('rtfHeadText', value, { shouldDirty: true });
            }}
            text={headText}
          />
        </Box>

        <Box
          minHeight={totalHeight + `px`}
          height="100%"
          display="flex"
          overflow="auto"
          width="100%"
        >
          <PositionsWithAutoDisable
            onPaper={false}
            showActions={false}
            showArticleSelectionBig={false}
            showColumnSelector={false}
            showPositionMask={false}
            bordersHorizontal={false}
            allowedColumns={[
              'number',
              'longtext',
              'amount',
              'unit',
              'singlePriceNet',
              'totalPriceNet',
            ]}
          />
        </Box>
        <Box padding="0 3rem 0 6rem" marginTop="1rem" paddingBottom="3rem">
          <ErpTextEditor
            onChange={(value: string) => {
              setValue('rtfFooterText', value, { shouldDirty: true });
            }}
            text={footerText}
          />
        </Box>
        {entity === Entities.deliveryNote ? (
          <Box padding="0 3rem 4rem 6rem" width="100%" display="flex">
            <Box flex={1}></Box>
            <Signature showSignature />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
