import styles from './Dropdown.module.scss';

import { List } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

interface IDropdownProps extends React.HTMLAttributes<HTMLDivElement> {
  staticPosition?: boolean;
  classes?: {
    root?: string;
    input?: string;
    list?: string;
  };
}

export const Dropdown: React.FC<IDropdownProps> = (props) => {
  const { children, staticPosition, ...rest } = props;
  const { root, input, list } = props.classes;
  return (
    <div
      {...rest}
      className={clsx(
        styles['dropdown'],
        {
          [styles.static]: staticPosition,
        },
        root
      )}
    >
      {!staticPosition && (
        <div className={clsx(styles['dropdown__input-stub'], input)} />
      )}
      <List className={clsx(styles['dropdown__list'], list)}>{children}</List>
    </div>
  );
};
