import React, { useEffect, useState } from 'react';

import { ReleaseNotesDialog } from '../components/user-menu/components/ReleaseNotesDialog/ReleaseNotesDialog';
import { ReleaseNotesDialogContext } from '../contexts/ReleaseNotesDialogContext';
import { useReleaseNotesFile } from '../hooks';

export const ReleaseNotesDialogProvider = ({ children }) => {
  const [showReleaseNotes, setShowReleaseNotes] = useState(false);

  const { releaseNotesFile, version } = useReleaseNotesFile();

  useEffect(() => {
    const hideReleaseNotes = window.HIDE_RELEASE_NOTES;
    const lastSeenReleaseNotesVersion = localStorage.getItem(
      'displayedReleaseNotesVersion'
    );

    // check the global flag to see if it is locked manually
    // check the localStorage to see if the release notes for the latest version
    // have already been displayed and if the current release notes file has content
    if (
      !hideReleaseNotes &&
      lastSeenReleaseNotesVersion !== version &&
      releaseNotesFile?.length
    ) {
      setShowReleaseNotes(true);
      localStorage.setItem('displayedReleaseNotesVersion', version);
    }
  }, [releaseNotesFile, version]);

  const openReleaseNotesDialog = () => {
    setShowReleaseNotes(true);
    localStorage.setItem('displayedReleaseNotesVersion', version);
  };

  const hideReleaseNotesDialog = () => {
    setShowReleaseNotes(false);
  };

  return (
    <ReleaseNotesDialogContext.Provider
      value={{
        releaseNotesFile,
        version,
        openReleaseNotesDialog,
      }}
    >
      <ReleaseNotesDialog
        open={showReleaseNotes}
        onClose={() => {
          hideReleaseNotesDialog();
        }}
      />
      {children}
    </ReleaseNotesDialogContext.Provider>
  );
};
