import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class InputTicketAttachementModify<T extends EMode = EMode.entity> {
  /** Alias: iD */
  iD?: string | number;
  /** Alias: name */
  name?: string;

  __typename?: string;
}

const fields: FieldDefinitions<InputTicketAttachementModify> = {
  iD: {
    alias: 'iD',
  },
  name: {
    alias: 'name',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputTicketAttachementModifyEntityDefinition: EntitiyDefinition<InputTicketAttachementModify> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputTicketAttachementModify',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
