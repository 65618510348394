import React, { useState } from 'react';

import { OverlayFullscreenContext } from '@work4all/data/lib/hooks/data-provider/useOverlayFullscreenDataProvider';

import { IStackProviderProps, StackProvider } from '../history-stack';

import { Content, IContentProps } from './components/content/Content';

export interface INavigationOverlayProps
  extends Omit<IStackProviderProps, 'onClose'>,
    IContentProps {}

export const NavigationOverlay: React.FC<INavigationOverlayProps> = (props) => {
  const [fullscreen, setFullscreen] = useState<boolean>(false);

  return (
    <OverlayFullscreenContext.Provider value={{ fullscreen, setFullscreen }}>
      <StackProvider onClose={props.close} initialView={props.initialView}>
        {props.initialView && (
          <Content
            classes={props.classes}
            open={props.open}
            close={props.close}
            withBreadcrumbs={props.withBreadcrumbs}
            onExited={props.onExited}
            heading={props.heading}
            decorator={props.decorator}
          />
        )}
        {props.children}
      </StackProvider>
    </OverlayFullscreenContext.Provider>
  );
};
