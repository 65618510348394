import { DateTime } from 'luxon';

import { parseNameAndExtension } from '@work4all/data/lib/utils';

import { IAttachmentEntity } from '@work4all/models';

export interface IAttachmentTableDisplayData {
  id?: string | number;
  type: string;
  filename: string;
  filesize: string;
  downloadUrl: string;
  date: string;
  userName: string;
}

export function mapAttachmentData(
  attachment: IAttachmentEntity,
  cureentUserDisplayName: string
): IAttachmentTableDisplayData {
  if (!attachment) {
    return null;
  }

  const { name, extension } = parseNameAndExtension(attachment.fileName);
  const filesize = formatByteSize(attachment.fileInfos?.fileSize || 0);
  const date = DateTime.fromISO(
    attachment?.date ?? attachment?.lastModificationDate
  ).toFormat('dd.MM.yyyy');

  return {
    type: extension.toUpperCase(),
    filename: name,
    filesize: filesize,
    id: attachment.id,
    downloadUrl: attachment.fileInfos?.downloadUrl,
    date: date,
    //if __typename undefined (which means the attachment is unsaved yet) use cureent user displayName
    //because the cureent user is responsible for uploading the attachment
    //but user obj not available on unsaved attachment
    userName: attachment?.__typename
      ? attachment?.user?.displayName
      : cureentUserDisplayName,
  };
}

export function formatByteSize(bytes: number): string {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  const dm = i === 0 || i === 1 ? 0 : 2;

  return `${parseFloat(
    (bytes / Math.pow(k, i)).toFixed(dm)
  ).toLocaleString()} ${sizes[i]}`;
}
