import CloseIcon from '@mui/icons-material/Close';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SaveIcon from '@mui/icons-material/Save';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  onUsersClear: () => void;
  showSelfOnly: boolean;
  openDialog: () => void;
}

export const ResourcesHeader = ({
  openDialog,
  onUsersClear,
  showSelfOnly,
}: Props) => {
  const { t } = useTranslation();

  if (showSelfOnly) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '0.25rem',
      }}
    >
      <Tooltip title={t('USER_PICKER.ADD_USER')}>
        <IconButton color="primary" size="medium" onClick={openDialog}>
          <PersonAddIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={t('USER_PICKER.CLEAR_SELECTION')}>
        <IconButton color="primary" size="medium" onClick={onUsersClear}>
          <CloseIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={t('USER_PICKER.SAVE_COLLECTION')}>
        <IconButton disabled color="primary" size="medium">
          <SaveIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
