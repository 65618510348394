import type React from 'react';

import { useMaskContext } from '../../hooks/mask-context';

export type IFormProps = React.FormHTMLAttributes<HTMLFormElement>;

/**
 * A simple wrapper component to use in masks.
 *
 * Masks can sometimes be nested inside each other in React component tree.
 * Because of React's event propagation this will cause `onSubmit` to be called
 * on all parent forms when a child form is submitted.
 *
 * This component will call `preventDefault()` and `stopPropagation()` in its
 * `onSubmit` handler so you don't have to add it in every mask component.
 */
export function Form(props: IFormProps) {
  const { onSubmit, children, ...rest } = props;

  const ctx = useMaskContext();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();

    onSubmit?.(event);
  };

  return (
    <form
      onSubmit={handleSubmit}
      {...rest}
      data-test-id={`maskForm maskForm-${ctx.entity}`}
    >
      {children}
    </form>
  );
}
