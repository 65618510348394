import React from 'react';

import {
  ITravelReceiptsStatusPickerProps,
  TravelReceiptsStatusPicker,
} from '../../../../components/entity-picker/travel-receipts-status-picker/TravelReceiptsStatusPicker';
import { FilterCard } from '../filter-card/FilterCard';

export type TravelReceiptsStatusCardProps = {
  title: string;
  expanded: boolean;
  onExpandedChange: (val: boolean) => void;
} & ITravelReceiptsStatusPickerProps;

export const TravelReceiptsStatusCard: React.FC<
  TravelReceiptsStatusCardProps
> = (props) => {
  const { title, value, onChange, expanded, onExpandedChange } = props;

  return (
    <FilterCard
      title={title}
      filterValue={value}
      filterValueSet={!!value}
      expanded={expanded}
      onExpandedChange={onExpandedChange}
      onValueDelete={value ? () => onChange(null) : undefined}
    >
      <TravelReceiptsStatusPicker value={value} onChange={onChange} />
    </FilterCard>
  );
};
