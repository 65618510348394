import { IFileInfoPanelItems } from './types';

export const projectDisplayItems = [
  IFileInfoPanelItems.Number,
  IFileInfoPanelItems.CustomerSupplier,
  IFileInfoPanelItems.InternalProjectLeaders,
  IFileInfoPanelItems.CustomerProjectLeaders,
  IFileInfoPanelItems.ProjectDirectory,
  IFileInfoPanelItems.Categories,
  IFileInfoPanelItems.Group,
  IFileInfoPanelItems.Kind,
  IFileInfoPanelItems.Budget,
  IFileInfoPanelItems.EventPeriod,
  IFileInfoPanelItems.Status,
  IFileInfoPanelItems.ParentProject,
  IFileInfoPanelItems.Note,
  IFileInfoPanelItems.SubscriptionContract,
  IFileInfoPanelItems.ProjectPeriod,
];

export const supplierDisplayItems = [
  IFileInfoPanelItems.Address,
  IFileInfoPanelItems.Number,
  IFileInfoPanelItems.ContactOptions,
  IFileInfoPanelItems.Group,
  IFileInfoPanelItems.Created,
  IFileInfoPanelItems.FirstContact,
  IFileInfoPanelItems.Payment,
  IFileInfoPanelItems.Relations,
  IFileInfoPanelItems.ContactList,
  IFileInfoPanelItems.Categories,
  IFileInfoPanelItems.Note,
  IFileInfoPanelItems.ShowFunction,
  IFileInfoPanelItems.OpenDuePositions,
  IFileInfoPanelItems.Sales,
];

export const customerDisplayItems = [
  IFileInfoPanelItems.Address,
  IFileInfoPanelItems.Number,
  IFileInfoPanelItems.ContactOptions,
  IFileInfoPanelItems.Group,
  IFileInfoPanelItems.Created,
  IFileInfoPanelItems.FirstContact,
  IFileInfoPanelItems.Payment,
  IFileInfoPanelItems.Relations,
  IFileInfoPanelItems.ContactList,
  IFileInfoPanelItems.Categories,
  IFileInfoPanelItems.Note,
  IFileInfoPanelItems.ShowFunction,
  IFileInfoPanelItems.OpenDuePositions,
  IFileInfoPanelItems.Opportunities,
  IFileInfoPanelItems.Sales,
  IFileInfoPanelItems.SubscriptionContract,
];

export const customerDisplayItemsMobile = [
  IFileInfoPanelItems.Address,
  IFileInfoPanelItems.Number,
  IFileInfoPanelItems.ContactOptions,
  IFileInfoPanelItems.Group,
  IFileInfoPanelItems.Created,
  IFileInfoPanelItems.FirstContact,
  IFileInfoPanelItems.Payment,
  IFileInfoPanelItems.Relations,
  IFileInfoPanelItems.Categories,
  IFileInfoPanelItems.Note,
  IFileInfoPanelItems.ShowFunction,
  IFileInfoPanelItems.OpenDuePositions,
  IFileInfoPanelItems.Opportunities,
  IFileInfoPanelItems.SubscriptionContract,
];

export const supplierDisplayItemsMobile = [
  IFileInfoPanelItems.Address,
  IFileInfoPanelItems.Number,
  IFileInfoPanelItems.ContactOptions,
  IFileInfoPanelItems.Group,
  IFileInfoPanelItems.Created,
  IFileInfoPanelItems.FirstContact,
  IFileInfoPanelItems.Payment,
  IFileInfoPanelItems.Relations,
  IFileInfoPanelItems.Categories,
  IFileInfoPanelItems.Note,
  IFileInfoPanelItems.ShowFunction,
  IFileInfoPanelItems.OpenDuePositions,
];
