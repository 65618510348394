import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class CategoryAssignment<T extends EMode = EMode.entity> {
  /** Alias: kategorieCode */
  categoryId?: number;
  /** Alias: kategorieKlasseCode */
  categoryKindId?: number;
  /** Alias: kategorieKlasseName */
  categoryKindName?: string;
  /** Alias: kategorieName */
  categoryName?: string;
  /** Alias: zuordnungBenutzerCode */
  assignmentUserId?: number;
  /** Alias: zuordnungDatum */
  assignmentDate?: string;
  /** Alias: zuordnungNotiz */
  assignmentNote?: string;
  /** Alias: markCode */
  id?: number;

  __typename?: string;
}

const fields: FieldDefinitions<CategoryAssignment> = {
  categoryId: {
    alias: 'kategorieCode',
  },
  categoryKindId: {
    alias: 'kategorieKlasseCode',
  },
  categoryKindName: {
    alias: 'kategorieKlasseName',
  },
  categoryName: {
    alias: 'kategorieName',
  },
  assignmentUserId: {
    alias: 'zuordnungBenutzerCode',
  },
  assignmentDate: {
    alias: 'zuordnungDatum',
  },
  assignmentNote: {
    alias: 'zuordnungNotiz',
  },
  id: {
    alias: 'markCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const categoryAssignmentEntityDefinition: EntitiyDefinition<CategoryAssignment> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'KategorieZuordnung',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
