import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class GeneralSettings<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: name */
  name?: string;
  /** Alias: nichtSichtbar */
  isNotVisible?: boolean;
  /** Alias: textWert */
  textValue?: string;
  /** Alias: wert */
  value?: number;

  __typename?: string;
}

const fields: FieldDefinitions<GeneralSettings> = {
  id: {
    alias: 'code',
  },
  name: {
    alias: 'name',
  },
  isNotVisible: {
    alias: 'nichtSichtbar',
  },
  textValue: {
    alias: 'textWert',
  },
  value: {
    alias: 'wert',
  },
  __typename: {
    alias: '__typename',
  },
};

export const generalSettingsEntityDefinition: EntitiyDefinition<GeneralSettings> =
  {
    local: {},
    remote: {
      queryName: 'getGeneralSettings',
      fragmentName: 'GeneralSettings',
      withPaginationWrapper: false,
      params: [
        { name: 'querySize', type: 'Int' },
        { name: 'queryPage', type: 'Int' },
        { name: 'querySortOrder', type: 'SortOrderStyle' },
        { name: 'querySortBy', type: 'String' },
        { name: 'name', type: '[String]!' },
        { name: 'textWert', type: 'String' },
        { name: 'wert', type: 'Decimal' },
      ],
    },
    fieldDefinitions: fields,
  };
