import styles from './EntityPickerActionButton.module.scss';

import Button from '@mui/material/Button';
import React from 'react';

import { Link } from '@work4all/data';

export type IEntityPickerActionButtonProps = {
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  children?: React.ReactNode;
  href?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
};

export function EntityPickerActionButton(
  props: IEntityPickerActionButtonProps
) {
  const {
    href,
    startIcon,
    endIcon,
    onClick,
    disabled = false,
    children,
  } = props;

  const commonProps = {
    disabled,
    startIcon: startIcon,
    endIcon: endIcon,
    className: styles['root'],
    variant: 'text',
    color: 'primary',
    size: 'large',
    onClick: onClick,
  } as const;

  if (href !== undefined) {
    return (
      <Button component={Link} to={href} {...commonProps}>
        {children}
      </Button>
    );
  }

  return <Button {...commonProps}>{children}</Button>;
}
