import { useCallback, useMemo } from 'react';

import { ParsedCustomFieldConfig } from '@work4all/data/lib/custom-fields';
import {
  IResponse,
  useDataProvider,
} from '@work4all/data/lib/hooks/data-provider';

import { DataRequest } from '@work4all/models/lib/DataProvider';

import { TableRow } from '../../types';
import { useTableState } from '../useTableStateBag';

import { useGroupByData } from './hooks/useGroupByData';
import { GroupedItems } from './types';

const defaultGroupBy: string[] = [];

export const useQueryTableData = (
  requestedData: DataRequest,
  getGroupKeyFields: (field: string) => {
    id: string;
    label: string;
  },
  pageSize = 100,
  customFields
): {
  data: GroupedItems;
  total: number | 'rowsLength';
  isItemLoaded?: (index: number, row: TableRow) => boolean;
  fetchMore: (
    startIndex: number,
    stopIndex: number,
    rows: TableRow[]
  ) => Promise<void>;
  refetch: () => Promise<void>;
  loading?: boolean;
  pending?: boolean;
  customFields?: ParsedCustomFieldConfig[];
} => {
  const tableState = useTableState();
  const groupedBy = tableState?.groupBy || defaultGroupBy;

  /** run default useDataProvider in case there's no groupings  */
  const withGroupings = groupedBy.length > 0;

  const defaultData = usePatchedDefaultData(
    useDataProvider(requestedData, withGroupings, pageSize),
    pageSize
  );

  const groupByData = useGroupByData(
    requestedData,
    pageSize,
    groupedBy,
    getGroupKeyFields,
    !withGroupings,
    customFields
  );

  return withGroupings ? groupByData : defaultData;
};

export function usePatchedDefaultData<T>(
  response: IResponse<T>,
  pageSize: number
) {
  const fetchMore = useCallback(
    async (startIndex: number) => {
      await response.fetchMore(startIndex, startIndex + pageSize - 1);
    },
    [pageSize, response]
  );

  return useMemo(
    () => ({
      ...response,
      total: Math.min(response.total, response.data.length + 3),
      fetchMore,
    }),
    [response, fetchMore]
  );
}
