import { FileData } from '@work4all/models/lib/File';

export function sortFilesData(filesData: FileData[], ids: string[]) {
  const sortedData: FileData[] = [];
  for (const id of ids) {
    const index = filesData.findIndex((file) => file.id.toString() === id);

    if (index > -1 && !sortedData.includes(filesData[index])) {
      sortedData.push(filesData[index]);
    }
  }
  return sortedData;
}
