import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class InputEMailAnhangAttachementAdd<T extends EMode = EMode.entity> {
  /** Alias: tempFileId */
  tempFileId?: string | number;
  /** Alias: eMailAttachementCode */
  eMailAttachementCode?: number;
  /** Alias: name */
  name?: string;

  __typename?: string;
}

const fields: FieldDefinitions<InputEMailAnhangAttachementAdd> = {
  tempFileId: {
    alias: 'tempFileId',
  },
  eMailAttachementCode: {
    alias: 'eMailAttachementCode',
  },
  name: {
    alias: 'name',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputEMailAnhangAttachementAddEntityDefinition: EntitiyDefinition<InputEMailAnhangAttachementAdd> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputEMailAnhangAttachementAdd',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
