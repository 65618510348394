import React, { createContext, useContext } from 'react';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EntityPickerPopover } from '../entity-picker/components/EntityPickerPopover';

interface TemplateEntity {
  entity: Entities;
  id: string | number;
}

export interface FooterPickerAction<TValue> {
  disableAddButton?: boolean;
  hideAddButton?: boolean;
  disableListButton?: boolean;
  hideListButton?: boolean;
  entity: Entities;
  data?: TValue;
  onChange?: (value: TValue | TValue[]) => void;
  popoverRef: React.MutableRefObject<EntityPickerPopover>;
  template?: TemplateEntity;
}

interface IEntityTemplateProviderContext {
  renderPickerFooter: <T>(props: FooterPickerAction<T>) => React.ReactElement;
}

const EntityTemplateProviderContext =
  createContext<IEntityTemplateProviderContext>({
    renderPickerFooter: () => null,
  });

export const EntityTemplateProvider = ({
  children,
  ...props
}: React.PropsWithChildren<IEntityTemplateProviderContext>) => {
  return (
    <EntityTemplateProviderContext.Provider value={props}>
      {children}
    </EntityTemplateProviderContext.Provider>
  );
};

export const useEntityTemplate = () =>
  useContext(EntityTemplateProviderContext);
