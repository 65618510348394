import { WordLetterTemplate } from '@work4all/models/lib/Classes/WordLetterTemplate.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PDFTextmarkConf } from '../../../preview/pdf-textmarks/PDFTextmarkContainer';
import { MIME_TYPES } from '../../../preview/Preview';
import { IDocumentPreviewContainerProps } from '../document-preview';
import { DocumentPreview } from '../document-preview/DocumentPreview';
import { useEntityPreviewData } from '../hooks/use-entity-preview-data';

export interface ILetterTemplatePreviewContainerProps
  extends Omit<IDocumentPreviewContainerProps, 'documentId'> {
  wordLetterTemplateId: number;
  additionalTitleItems?: React.ReactNode;
  pdfTextmarkConf?: PDFTextmarkConf;
}

const requestedWordLetterTemplateData: WordLetterTemplate<EMode.query> = {
  id: null,
  fileInfos: {
    previewUrl: null,
    previewMimeType: null,
    fileServiceProviderInfos: {
      id: null,
      fileName: null,
      mimeType: null,
      fspUrl: null,
    },
  },
  title: null,
};

export function WordLetterTemplatePreviewContainer(
  props: ILetterTemplatePreviewContainerProps
) {
  const {
    wordLetterTemplateId,
    onCloseClicked,
    onEditClicked,
    additionalTitleItems,
    pdfTextmarkConf,
  } = props;

  const wordLetterTemplates = useEntityPreviewData<WordLetterTemplate>(
    [wordLetterTemplateId],
    Entities.wordLetterTemplate,
    requestedWordLetterTemplateData
  );

  if (!wordLetterTemplates) return null;

  return (
    <DocumentPreview
      title={
        wordLetterTemplates[0].title ||
        wordLetterTemplates[0].fileInfos?.fileServiceProviderInfos?.fileName ||
        ''
      }
      url={`${wordLetterTemplates[0].fileInfos.previewUrl}`}
      mimeType={wordLetterTemplates[0].fileInfos?.previewMimeType as MIME_TYPES}
      noPreviewUrl={
        wordLetterTemplates[0].fileInfos?.fileServiceProviderInfos?.fspUrl ||
        wordLetterTemplates[0].fileInfos?.downloadUrl
      }
      onCloseClicked={onCloseClicked}
      onEditClicked={onEditClicked}
      additionalTitleItems={additionalTitleItems}
      pdfTextmarkConf={pdfTextmarkConf}
    />
  );
}
