import React, { useCallback, useState } from 'react';
import { HeaderGroup, TableInstance } from 'react-table';

import { ColumnAdditionalData, ColumnInstance, ICssClasses } from '../../types';
import { SELECTION_COLUMN_ID } from '../../utils/makeRowsSelectable';

import {
  HeaderCell,
  IHeaderCellProps,
} from './components/header-cell/HeaderCell';
import {
  DraggableColumnHeaderWrapper,
  IDraggableColumnDndItem,
} from './components/header-cell-dnd-wrapper/HeaderCellDndWrapper';
import { HeaderRow } from './components/header-row/HeaderRow';
import { HeaderWrapper } from './components/header-wrapper/HeaderWrapper';

export interface IHeaderProps {
  headerGroups: HeaderGroup<ColumnAdditionalData>[];
  setColumnOrder: TableInstance['setColumnOrder'];
  flatColumns: ColumnInstance[];
  reordableColumns?: boolean;
  resizableColumns?: boolean;
  classes?: ICssClasses;
  noSeperator?: boolean;
  groupBy: string[];
  elementRef?: React.ForwardedRef<HTMLDivElement>;
}

/*
 * do not use memo here.
 * in most cases header won't be updated.
 * it's very hard to maintain
 */
export const TableHeader: React.FC<IHeaderProps> = (props) => {
  const { flatColumns, setColumnOrder } = props;
  const onDragEnd = useCallback(
    (item: IDraggableColumnDndItem) => {
      const columns = flatColumns.map((o) => o.id);

      const originalIndex = columns.indexOf(item.id);

      if (item.index === originalIndex) return;

      const adjust = item.index > originalIndex ? 0 : 1;

      /**
       * despite the fact that setColumnOrder
       * can accept "updater" function
       * this is not true.
       * soruce code states that only an array can be accepted.
       * updating "react-table" package didn't help.
       */

      columns.splice(item.index, 0, item.id);
      columns.splice(originalIndex + adjust, 1);

      setColumnOrder(columns);
    },
    [setColumnOrder, flatColumns]
  );

  const [dragState, setDragState] = useState<number | null>(null);

  const canReorderColumn = (column: ColumnInstance) => {
    if (!props.reordableColumns) return false;
    if (column.sticky) return false;
    if (column.id === SELECTION_COLUMN_ID) return false;
    return true;
  };

  let canResizeLast = false;

  return (
    <HeaderWrapper
      elementRef={props.elementRef}
      className={props.classes?.headerWrapper}
    >
      {props.headerGroups.map((group) => {
        return (
          <HeaderRow
            className={props.classes?.headerRow}
            {...group.getHeaderGroupProps()}
          >
            {group.headers
              .filter((column) => !column.isGrouped)
              .map((col, index) => {
                const canResizeCurrent =
                  col.canResize && !col?.placeholderOf?.canResize;

                const commonCellProps: IHeaderCellProps = {
                  noSeperator: props.noSeperator,
                  resizableColumns: props.resizableColumns,
                  col,
                  classes: props.classes,
                  canResizeCurrent: canResizeCurrent,
                  canResizeLast: canResizeLast,
                };

                const view = canReorderColumn(col) ? (
                  <DraggableColumnHeaderWrapper
                    id={col.id}
                    index={index}
                    onHover={({ index }) => setDragState(index)}
                    onDrop={onDragEnd}
                    onDragEnd={() => setDragState(null)}
                  >
                    {({ dragRef, dropRef }) => {
                      return (
                        <HeaderCell
                          {...commonCellProps}
                          rootRef={dropRef}
                          cellRef={dragRef}
                          columnDragIndicators={{
                            before: dragState === index,
                            after: dragState === index + 1,
                          }}
                        />
                      );
                    }}
                  </DraggableColumnHeaderWrapper>
                ) : (
                  <HeaderCell {...commonCellProps} />
                );

                canResizeLast = canResizeCurrent;
                return <React.Fragment key={col.id}>{view}</React.Fragment>;
              })}
          </HeaderRow>
        );
      })}
    </HeaderWrapper>
  );
};
