import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputAttachementAdd } from './InputAttachementAdd.entity';
import { InputTicketAttachementModify } from './InputTicketAttachementModify.entity';

export class InputTicketAttachementsRelation<T extends EMode = EMode.entity> {
  /** Alias: remove */
  remove?: (string | number)[];
  /** Alias: add */
  add?: InputAttachementAdd<T>[];
  /** Alias: modify */
  modify?: InputTicketAttachementModify<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputTicketAttachementsRelation> = {
  remove: {
    alias: 'remove',
  },
  add: {
    alias: 'add',
  },
  modify: {
    alias: 'modify',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputTicketAttachementsRelationEntityDefinition: EntitiyDefinition<InputTicketAttachementsRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputTicketAttachementsRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
