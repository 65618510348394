import { BroadcastChannel } from 'broadcast-channel';
import { fromEvent } from 'rxjs';

import {
  ReplicationEventType,
  ReplicationId,
} from '../entities/utils/replication.types';

/**
 * Command that is send by the leader tab. All others tabs that receive this
 * message should reply with TenantMessaage.
 *
 * If `ping` is set to `true`, the `ping` property in the response should also
 * be set to `true` to avoid unnecessary replications restarts.
 */
export type ReportTenantMessage = { type: 'report_tenant'; ping?: boolean };

/**
 * A message that a tab sends to the leader tab to trigger replication for a
 * specific tenant.
 *
 * When a leader tab receives this message, it will start a new replication (or
 * resync an existing one) and will begin broadcasting replication status
 * changes with ReplicationEventMessage messages.
 *
 * If the `ping` property is set to `true`, the leader tab will not start a
 * replication. This flag is used to collect all tenants from still active tabs
 * so we can clean up replications for no longer used tenants.
 */
export type TenantMessaage = {
  type: 'tenant';
  tenant: number;
  ping?: boolean;
};

/**
 * A message that the leader tab  broadcasts when a running replication changes
 * its status. This will happen when a replication starts and stops syncing data
 * and if the replication is cancelled.
 */
export type ReplicationEventMessage = {
  type: 'replication_event';
  tenant: number;
  replicationId: ReplicationId;
  event: ReplicationEventType;
};

/**
 * A message that non-leader tabs send to the leader tab to start the logout
 * process. This will cancel all replications, log out the user and clean up the
 * database.
 */
export type LogoutMessage = { type: 'logout' };

export type ReplicationMessage =
  | ReportTenantMessage
  | TenantMessaage
  | ReplicationEventMessage
  | LogoutMessage;

export function isReportTenantMessage(
  message: ReplicationMessage
): message is ReportTenantMessage {
  return message.type === 'report_tenant';
}

export function isTenantMessaage(
  message: ReplicationMessage
): message is TenantMessaage {
  return message.type === 'tenant';
}

export function isReplicationEventMessage(
  message: ReplicationMessage
): message is ReplicationEventMessage {
  return message.type === 'replication_event';
}

export function isLogoutMessage(
  message: ReplicationMessage
): message is LogoutMessage {
  return message.type === 'logout';
}

export const channel = new BroadcastChannel<ReplicationMessage>(
  'rxdb-replication-sync'
);

export const messages$ = fromEvent(channel, 'message');
