import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class InputbankAccountRelationAddModify<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: number;
  /** Alias: name */
  name?: string;
  /** Alias: mainBank */
  mainBank?: boolean;
  /** Alias: iBAN */
  iBAN?: string;
  /** Alias: bIC */
  bIC?: string;
  /** Alias: accountowner */
  accountowner?: string;
  /** Alias: sEPACreditorID */
  sEPACreditorID?: string;
  /** Alias: mandateRefNo */
  mandateRefNo?: string;
  /** Alias: mandateDate */
  mandateDate?: string;
  /** Alias: ledger */
  ledger?: number;
  /** Alias: sortCode */
  sortCode?: number;
  /** Alias: bankAccountNumber */
  bankAccountNumber?: string;

  __typename?: string;
}

const fields: FieldDefinitions<InputbankAccountRelationAddModify> = {
  code: {
    alias: 'code',
  },
  name: {
    alias: 'name',
  },
  mainBank: {
    alias: 'mainBank',
  },
  iBAN: {
    alias: 'iBAN',
  },
  bIC: {
    alias: 'bIC',
  },
  accountowner: {
    alias: 'accountowner',
  },
  sEPACreditorID: {
    alias: 'sEPACreditorID',
  },
  mandateRefNo: {
    alias: 'mandateRefNo',
  },
  mandateDate: {
    alias: 'mandateDate',
  },
  ledger: {
    alias: 'ledger',
  },
  sortCode: {
    alias: 'sortCode',
  },
  bankAccountNumber: {
    alias: 'bankAccountNumber',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputbankAccountRelationAddModifyEntityDefinition: EntitiyDefinition<InputbankAccountRelationAddModify> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputbankAccountRelationAddModify',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
