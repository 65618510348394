import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { DateienObjectType } from '../Enums/DateienObjectType.enum';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { FileEntity } from './FileEntity.entity';

export class File<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: dateienType */
  fileType?: DateienObjectType;
  /** Alias: objMemberCode */
  objMemberCode?: number;
  /** Alias: name */
  name?: string;
  /** Alias: info1 */
  info1?: string;
  /** Alias: info2 */
  info2?: string;
  /** Alias: stillgelegt */
  stillgelegt?: boolean;
  /** Alias: pfad */
  path?: string;
  /** Alias: localFilename */
  localFilename?: string;
  /** Alias: dMSId */
  dMSId?: string;
  /** Alias: anhangAngebot */
  offerAttachment?: boolean;
  /** Alias: anhangAuftrag */
  contractAttachment?: boolean;
  /** Alias: anhangBedarf */
  demandAttachment?: boolean;
  /** Alias: anhangBestellung */
  orderAttachment?: boolean;
  /** Alias: anhangKalkulation */
  calculationAttachment?: boolean;
  /** Alias: anhangLieferschein */
  deliveryNoteAttachment?: boolean;
  /** Alias: anhangRechnung */
  invoiceAttachment?: boolean;
  /** Alias: showInShop */
  showInShop?: boolean;
  /** Alias: index */
  index?: number;
  /** Alias: fileInfos */
  fileInfos?: FileEntity<T>;
  /** Alias: parentCode */
  parentId?: number;

  __typename?: string;
}

const fields: FieldDefinitions<File> = {
  id: {
    alias: 'code',
  },
  fileType: {
    alias: 'dateienType',
  },
  objMemberCode: {
    alias: 'objMemberCode',
  },
  name: {
    alias: 'name',
  },
  info1: {
    alias: 'info1',
  },
  info2: {
    alias: 'info2',
  },
  stillgelegt: {
    alias: 'stillgelegt',
  },
  path: {
    alias: 'pfad',
  },
  localFilename: {
    alias: 'localFilename',
  },
  dMSId: {
    alias: 'dMSId',
  },
  offerAttachment: {
    alias: 'anhangAngebot',
  },
  contractAttachment: {
    alias: 'anhangAuftrag',
  },
  demandAttachment: {
    alias: 'anhangBedarf',
  },
  orderAttachment: {
    alias: 'anhangBestellung',
  },
  calculationAttachment: {
    alias: 'anhangKalkulation',
  },
  deliveryNoteAttachment: {
    alias: 'anhangLieferschein',
  },
  invoiceAttachment: {
    alias: 'anhangRechnung',
  },
  showInShop: {
    alias: 'showInShop',
  },
  index: {
    alias: 'index',
  },
  fileInfos: {
    alias: 'fileInfos',
    entity: Entities.fileEntity,
  },
  parentId: {
    alias: 'parentCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const fileEntityDefinition: EntitiyDefinition<File> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'Datei',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
