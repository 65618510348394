import React from 'react';

import {
  Direction,
  GetLocalState,
  GoBack,
  HistoryStack,
  IStackItem,
  ObjectionHandler,
  SetLocalState,
} from '../../types';

export interface IHistoryStackBag {
  stack: HistoryStack;
  currentStackIndex: number;
  current?: IStackItem;
  viewStackItem?: IStackItem;
  setStack: React.Dispatch<React.SetStateAction<HistoryStack>>;
  go: (item: IStackItem) => void;
  close: () => void;
  goBack: GoBack;
  direction: Direction;
  setLocalState: SetLocalState;
  getLocalState: GetLocalState;
  setCurrentViewState: SetLocalState;
  getCurrentViewState: GetLocalState;
  onClose: () => void;
  /**
   * add an objection to a potentially close operation for the currently active stackIndex element e.g. if the view has pending changes
   * remove it by settings the objection listener to null again
   */
  setObjectionListener: (handler: ObjectionHandler) => void;
  confirm: (reason?: string) => Promise<boolean>;
  updateCurrent: (item: IStackItem | ((old: IStackItem) => IStackItem)) => void;
}
export const StackContext = React.createContext<IHistoryStackBag | null>(null);
