import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CONVERT_TYPES } from '@work4all/components/lib/components/convert-popover/ConvertPopover';
import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';

import { DISMISS_POPOVER } from '../../../../context-menu/ContextMenu';

import { useTableContextMenuContext } from './table-context-menu-context';

export function TableContextMenuConvertView() {
  const { t } = useTranslation();

  const { actions } = useTableContextMenuContext();
  const { goBack } = useHistoryStack();

  const { convert } = actions;

  const options = convert
    ? convert.exclude
      ? CONVERT_TYPES.filter((option) => option !== convert.exclude)
      : CONVERT_TYPES
    : [];

  return (
    <List disablePadding>
      <ListItem dense disableGutters disablePadding>
        <ListItemButton
          onClick={() => {
            goBack();
          }}
        >
          <ListItemIcon>
            <ArrowBackIcon />
          </ListItemIcon>
          <ListItemText>{t('MASK.CONVERT')}</ListItemText>
        </ListItemButton>
      </ListItem>

      {options.map((entity) => (
        <ListItem dense disableGutters disablePadding>
          <ListItemButton
            className={DISMISS_POPOVER}
            onClick={() => {
              convert.onClick(entity);
            }}
          >
            <ListItemText>{t(`COMMON.${entity.toUpperCase()}`)}</ListItemText>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}
