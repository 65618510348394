import produce from 'immer';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { assertNever } from '@work4all/utils';
import { useLatest } from '@work4all/utils/lib/hooks/use-latest';

import {
  AppointmentCalendarParams,
  AppointmentCalendarState,
  AppointmentCalendarView,
  AppointmentCalendarViewInit,
  AppointmentsGroupMode,
  CalendarPhase,
  CalendarProps,
} from '../types';
import {
  createCalendarSearchParams,
  parseCalendarSearchParams,
} from '../utils/calendar-page-search-params';

import { UseCalendarStateReturn } from './types';
import { useCalendarSettingsSync } from './use-calendar-settings-sync';
import { useCalendarViews } from './use-calendar-views';
import { useInitialUsersDataProvider } from './use-initial-users-data-provider';
import { useLastCalendarParams } from './use-last-calendar-params';
import { useUpdateLastCalendarParams } from './use-update-last-calendar-params';

interface CalendarCombinedState {
  phase: CalendarPhase;
  state: AppointmentCalendarState;
  parsedParams: AppointmentCalendarParams;
}

// TODO Refactor calendar state management hooks to reduce duplication.
export function useCalendarURLState(): UseCalendarStateReturn {
  const { isReady: isSettingsStoreReady } = useCalendarSettingsSync();

  const { lastParams, setLastParams } = useLastCalendarParams();
  const { views, createView, deleteView } = useCalendarViews();

  const [searchParams, setSearchParams] = useSearchParams();

  const [state, setState] = useState<CalendarCombinedState>(() => ({
    phase: 'initial',
    state: null,
    parsedParams: null,
  }));

  // Update the last used params whenever calendar state changes.
  useUpdateLastCalendarParams({ state: state.state, setLastParams });

  const { phase } = state;

  const stateRef = useLatest(state);

  // Redirect to last used calendars if search params are empty..
  useEffect(() => {
    if (!isSettingsStoreReady) return;

    // If there are params in the URL, use them to configure the calendar.
    // Otherwise load the last viewed calendar. If there is no saved view, use
    // the default configuration.
    if (searchParams.toString() === '') {
      // Redirect to the last viewed calendar
      setSearchParams(createCalendarSearchParams(lastParams), {
        replace: true,
      });
    }

    // This effect should only when `searchParams` change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSettingsStoreReady, searchParams, setSearchParams]);

  // If search params aren't empty, initialize the calendar.
  useEffect(() => {
    // If the URL does not have any params, wait for the redirect.
    if (searchParams.toString() === '') return;

    const state = stateRef.current;

    // Do nothing if the calendar state already matches the URL state.
    if (
      state.state !== null &&
      createCalendarSearchParams(state.state).toString() ===
        searchParams.toString()
    ) {
      return;
    }

    const parsedParams = parseCalendarSearchParams(searchParams);

    setState((state) => {
      const { phase } = state;

      switch (phase) {
        case 'initial':
        case 'loading':
          return {
            phase: 'loading',
            state: null,
            parsedParams,
          };

        case 'ready':
        case 'reloading':
          return {
            phase: 'reloading',
            state: state.state,
            parsedParams,
          };

        default:
          assertNever(phase, `Unknown phase ${phase}`);
      }
    });
  }, [searchParams, stateRef]);

  const initialUsers = useInitialUsersDataProvider({
    userIds: state.parsedParams?.userIds ?? [],
    disabled: state.phase !== 'loading' && state.phase !== 'reloading',
  });

  // When the users are loaded, store them in the state and show the calendar.
  useEffect(() => {
    const isLoadingPhase = phase === 'loading' || phase === 'reloading';

    if (!isLoadingPhase || initialUsers.loading) return;

    const state = stateRef.current;
    const users = initialUsers.data;

    const focusedUserIds = state.parsedParams.focusedUserIds.filter((id) =>
      users.find((user) => user.id === id)
    );

    const { date, range, groupMode, appointmentStates } = state.parsedParams;

    const s = {
      users,
      focusedUserIds,
      date,
      range,
      groupMode,
      appointmentStates,
    };

    setState({
      ...state,
      phase: 'ready',
      state: s,
    });

    const resolvedSearchParams = createCalendarSearchParams(s);

    setSearchParams(resolvedSearchParams, { replace: true });
  }, [phase, stateRef, setSearchParams, initialUsers]);

  const searchParamsRef = useLatest(searchParams);

  // Whenever the calendar state is changed, update the URL to match it.
  useEffect(() => {
    if (phase !== 'ready') return;

    const newSearchParams = createCalendarSearchParams(state.state);

    if (newSearchParams.toString() === searchParamsRef.current.toString()) {
      return;
    }

    setSearchParams(newSearchParams, { replace: true });
  }, [phase, searchParamsRef, setSearchParams, state.state]);

  function updateState<T extends keyof AppointmentCalendarState>(property: T) {
    return (value: AppointmentCalendarState[T]) => {
      setState(
        produce((draft) => {
          draft.state[property] = value;
          if (property === 'range' && value === 'agenda') {
            draft.state.groupMode = 'grouped';
          }
        })
      );
    };
  }

  const onUsersChange = updateState('users');
  const onFocusedUserIdsChange = updateState('focusedUserIds');
  const onDateChange = updateState('date');
  const onRangeChange = updateState('range');
  const onGroupModeChange = (groupMode: AppointmentsGroupMode) => {
    setState(
      produce((draft) => {
        draft.state.groupMode = groupMode;

        if (groupMode === 'vertical' && draft.state.range === 'day') {
          draft.state.range = 'week-5';
        }
      })
    );
  };
  const onAppointmentStatesChange = updateState('appointmentStates');

  const onSelectView = (view: AppointmentCalendarView): void => {
    setSearchParams(createCalendarSearchParams(view.params), { replace: true });
  };

  const onCreateView = (name: string): void => {
    const { users, focusedUserIds, range, groupMode, appointmentStates } =
      state.state;

    const init: AppointmentCalendarViewInit = {
      name,
      params: {
        userIds: users.map((user) => user.id),
        focusedUserIds,
        range,
        groupMode,
        appointmentStates,
      },
    };

    createView(init);
  };

  const onDeleteView = (view: AppointmentCalendarView): void => {
    deleteView(view.id);
  };

  const getCalendarProps = () => {
    const props: Omit<CalendarProps, 'onOpenMask'> = {
      loading: phase === 'reloading',
      ...state.state,
      onUsersChange,
      onFocusedUserIdsChange,
      onDateChange,
      onRangeChange,
      onGroupModeChange,
      onAppointmentStatesChange,
      views,
      onSelectView,
      onCreateView,
      onDeleteView,
    };

    return props;
  };

  const isReady = isSettingsStoreReady && state.state !== null;

  if (isReady === false) {
    return { isReady };
  }

  return { isReady, getCalendarProps };
}
