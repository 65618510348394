import { default as scssVariables } from '@work4all/assets/theme/variablesDark.scss';

import {
  DEFAULT_HTML_FONT_FAMILY,
  DEFAULT_HTML_FONT_SIZE,
} from '../../input/format-text/TextEditor/utils/html-parser';

export const FROALA_GENERAL_STYLES_URL =
  'https://mobil.work4all.de/work4all-2-0-styles/froala-general-styles.css';
export const FROALA_TEXTMARKS_STYLES_URL =
  'https://mobil.work4all.de/work4all-2-0-styles/froala-textmarks.css';
export const FROALA_COMPACT_MODE_STYLE_URL =
  'https://mobil.work4all.de/work4all-2-0-styles/froala-compact-mode.css';

export const FROALA_ZOOM_STYLE_URL = (zoomLevel: number) =>
  `https://mobil.work4all.de/work4all-2-0-styles/zoom-${zoomLevel}.css`;

export const getPageThemeStyles = () => {
  const theme = document.querySelector('html').getAttribute('data-theme');

  return `
    .textmarke {
      display: inline-block;
    }

    .textmarke:hover {
      opacity: 0.8;
    }

    body, .fr-view{
      color: ${theme === 'dark' ? 'white' : 'black'};
    }

    body {
      font-size: ${DEFAULT_HTML_FONT_SIZE};
      font-family: ${DEFAULT_HTML_FONT_FAMILY};
    }

    a{
      color: ${scssVariables.brand01}
    }
`;
};

export function setDefaultHeadStyles(props: {
  value: string;
  textmarkStyles?: boolean;
  zoom?: number;
}): string {
  let { value } = props;

  const { textmarkStyles, zoom = 100 } = props;
  const head = '<head>';
  const textmarkCss = textmarkStyles
    ? `<link rel="stylesheet" href="${FROALA_TEXTMARKS_STYLES_URL}"/>`
    : '';
  const index = value.indexOf(head);

  const defaultStyles =
    `<!-- default styles for work4all web preview-->
    <style type="text/css"> 
    body  { 
      font-family: ${DEFAULT_HTML_FONT_FAMILY};
      font-size: ${DEFAULT_HTML_FONT_SIZE};
      zoom:${zoom / 100}; 
    }    
    ${getPageThemeStyles()}
    </style>` + textmarkCss;

  if (index !== -1) {
    value =
      value.slice(0, index + head.length) +
      defaultStyles +
      value.slice(index + head.length);
  } else {
    value = defaultStyles + value;
  }

  const replacements = [
    { n: '<head></head>', r: '' },
    { n: 'font-family: "Calibri"', r: 'font-family: Calibri' },
    { n: "font-family: 'Calibri'", r: 'font-family: Calibri' },
    { n: 'font-family: Calibri', r: 'FAMILY_C_ALIBRI' },
    { n: 'FAMILY_C_ALIBRI', r: 'font-family: Calibri, sans-serif' },
    { n: 'face="Calibri', r: 'FACE_C_ALIBRI' },
    { n: 'FACE_C_ALIBRI', r: 'face="Calibri, sans-serif' },
  ];

  for (const replacement of replacements) {
    while (value.indexOf(replacement.n) !== -1) {
      value = value.replace(replacement.n, replacement.r);
    }
  }
  return value;
}

export const includesHtml = (str: string) => {
  return str.startsWith('<');
};
