import { useTranslation } from 'react-i18next';

import { FixedDataPicker } from '../fixed-data-picker/FixedDataPicker';
import { IPickerProps } from '../types';

export type IBaseDataTaxPickerProps<TMultiple extends boolean> = IPickerProps<
  { id: string; name: string },
  TMultiple
>;

export function BooleanPicker<TMultiple extends boolean>(
  props: IBaseDataTaxPickerProps<TMultiple>
) {
  const { ...rest } = props;

  const { t } = useTranslation();

  const dataSet = [
    {
      id: true,
      name: t('BOOLEAN_FILTER.true'),
    },
    {
      id: false,
      name: t('BOOLEAN_FILTER.false'),
    },
  ];

  return <FixedDataPicker dataSet={dataSet} {...rest} />;
}
