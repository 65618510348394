import { cloneDeep } from 'lodash';

import { Position } from '@work4all/models/lib/Classes/Position.entity';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

export const recalculatePositions = (data: Position[]) => {
  const result: Position[] = [];

  let total: number = 0;
  let partSum: number = 0;
  let partVatSum: number = 0;

  let titleSum: number = 0;
  let titleVatSum: number = 0;

  let number = 0;
  let titleNumber = 0;
  data.forEach((pos, index) => {
    const isChild = Boolean(pos.posId);

    const val = cloneDeep(pos);

    const vat = val.vat ?? 0;
    const vatMod = 1 + vat / 100;

    if (val.positionKind === ErpPositionsKind.STUECKLISTE) {
      let internalIndex = index;
      let internalVal = data[++internalIndex];
      let stucklisteNet = 0;
      while (internalVal?.posId) {
        const { totalPriceNet } = calcTotal(internalVal);
        stucklisteNet += totalPriceNet;
        internalVal = data[++internalIndex];
      }
      val.singlePriceNet = stucklisteNet;
    }

    if (
      val.positionKind === ErpPositionsKind.STANDARD ||
      val.positionKind === ErpPositionsKind.STUECKLISTE
    ) {
      if (!isChild) number++;
      const { totalPriceNet, totalMod } = calcTotal(val);
      val.totalPriceNet = totalPriceNet;
      val.singlePriceGross =
        Math.round(val.singlePriceNet * vatMod * 100) / 100;
      val.totalPriceGross = val.singlePriceGross * totalMod;

      if (!isChild) {
        total += val.totalPriceNet;
        partSum += val.totalPriceNet;
        titleSum += val.totalPriceNet;

        partVatSum += val.totalPriceGross;
        titleVatSum += val.totalPriceGross;

        val.number = titleNumber ? `${titleNumber}.${number}` : `${number}`;
      }
    }

    if (val.positionKind === ErpPositionsKind.ZWISCHENSUMME) {
      val.totalPriceNet = partSum;
      val.totalPriceGross = partVatSum;
      partSum = 0;
      partVatSum = 0;
    }

    if (val.positionKind === ErpPositionsKind.TITEL) {
      titleNumber = titleNumber === 0 ? number + 1 : titleNumber + 1;
      number = 0;
      titleSum = 0;
      val.number = `${titleNumber}`;
    }

    if (val.positionKind === ErpPositionsKind.TITELSUMME) {
      val.totalPriceNet = titleSum;
      val.totalPriceGross = titleVatSum;
      titleSum = 0;
      titleVatSum = 0;
    }

    result.push(val);
  });

  return { result, total };
};

function calcTotal(val: Position) {
  const discount = val.discount ?? 0;
  const totalMod = val.amount * (1 - discount / 100);
  const totalPriceNet = val.singlePriceNet * totalMod;
  return { totalPriceNet, totalMod };
}
