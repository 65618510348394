import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { ObjectType } from '../Enums/ObjectType.enum';

import { FileEntity } from './FileEntity.entity';
import { User } from './User.entity';

export class ErpAttachment<T extends EMode = EMode.entity> {
  /** Alias: bzObjMemberCode */
  bzObjMemberId?: number;
  /** Alias: bzObjType */
  bzObjType?: ObjectType;
  /** Alias: code */
  id?: number;
  /** Alias: dateiErweiterung */
  fileExtension?: string;
  /** Alias: dateiname */
  fileName?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: lastModificationDate */
  lastModificationDate?: string;
  /** Alias: mailVersand */
  sendPerMail?: boolean;
  /** Alias: originalDateiname */
  originalFileName?: string;
  /** Alias: pfad */
  path?: string;
  /** Alias: rechnungsbegruendet */
  isReasonedByInvoice?: boolean;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: fileInfos */
  fileInfos?: FileEntity<T>;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: benutzer */
  user?: User<T>;

  __typename?: string;
}

const fields: FieldDefinitions<ErpAttachment> = {
  bzObjMemberId: {
    alias: 'bzObjMemberCode',
  },
  bzObjType: {
    alias: 'bzObjType',
  },
  id: {
    alias: 'code',
  },
  fileExtension: {
    alias: 'dateiErweiterung',
  },
  fileName: {
    alias: 'dateiname',
  },
  insertTime: {
    alias: 'insertTime',
  },
  lastModificationDate: {
    alias: 'lastModificationDate',
  },
  sendPerMail: {
    alias: 'mailVersand',
  },
  originalFileName: {
    alias: 'originalDateiname',
  },
  path: {
    alias: 'pfad',
  },
  isReasonedByInvoice: {
    alias: 'rechnungsbegruendet',
  },
  updateTime: {
    alias: 'updateTime',
  },
  fileInfos: {
    alias: 'fileInfos',
    entity: Entities.fileEntity,
  },
  userId: {
    alias: 'benutzerCode',
  },
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  __typename: {
    alias: '__typename',
  },
};

export const erpAttachmentEntityDefinition: EntitiyDefinition<ErpAttachment> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'ErpAnhang',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
