import { BusinessPartner } from '@work4all/models/lib/Classes/BusinessPartner.entity';
import { EMail } from '@work4all/models/lib/Classes/EMail.entity';
import { MailboxContent } from '@work4all/models/lib/Classes/MailboxContent.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';

const BUSINESS_PARTNER_FIELDS: BusinessPartner<EMode.query> = {
  id: null,
  data: {
    customer: {
      id: null,
      name: null,
    },
    supplier: {
      id: null,
      name: null,
    },
  },
};

export const CONVERTED_MAIL_FIELDS: EMail<EMode.query> = {
  id: null,
  subject: null,
  user: {
    id: null,
    displayName: null,
  },
  creatorUserId: null,
  insertTime: null,
  contact: {
    id: null,
    displayName: null,
    businessPartner: BUSINESS_PARTNER_FIELDS,
  },
  businessPartner: BUSINESS_PARTNER_FIELDS,
  project: {
    id: null,
    name: null,
  },
};

export const MAILBOX_CONTENT_REQUIRED_FIELDS: MailboxContent<EMode.query> = {
  assignStatus: null,
  title: null,
  body: null,
  from: null,
  date: null,
  to: null,
  cc: null,
  isRead: null,
  convertedMail: CONVERTED_MAIL_FIELDS,
  possibleSenders: {
    contact: {
      id: null,
      displayName: null,
      businessPartner: BUSINESS_PARTNER_FIELDS,
    },
    customer: {
      id: null,
      name: null,
    },
    supplier: {
      id: null,
      name: null,
    },
  },
  possibleProjects: [
    {
      id: null,
      name: null,
      customer: {
        id: null,
        name: null,
      },
    },
  ],
};
