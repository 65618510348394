export enum BzObjType {
  UNKNOWN = 'UNKNOWN',
  KALKULATION = 'KALKULATION',
  AUFTRAG = 'AUFTRAG',
  ANGEBOT = 'ANGEBOT',
  RECHNUNG = 'RECHNUNG',
  LIEFERSCHEIN = 'LIEFERSCHEIN',
  BESTELLUNG = 'BESTELLUNG',
  BEDARFSANFORDERUNG = 'BEDARFSANFORDERUNG',
  EINGANGSLIEFERSCHEIN = 'EINGANGSLIEFERSCHEIN',
}
