import { useMemo, useState } from 'react';

import { EntityPickerTab } from '@work4all/components/lib/components/entity-picker/components/EntityPickerTab';
import { EntityPickerTabBar } from '@work4all/components/lib/components/entity-picker/components/EntityPickerTabBar';

import { useHeaderInject } from './HeaderComponentProvider';

interface Tab {
  key: string;
  label: string;
}
interface UsePageHeaderTabsOptions {
  hasPermission?: boolean;
  tabs: Tab[];
}

export const usePageHeaderTabs = (props: UsePageHeaderTabsOptions) => {
  const { hasPermission, tabs } = props;
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const component = useMemo(
    () =>
      hasPermission ? (
        <PageHeaderActions
          tabs={tabs}
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
        />
      ) : null,
    [activeTabIndex, setActiveTabIndex, hasPermission, tabs]
  );
  useHeaderInject(component);
  return { activeTab: tabs[activeTabIndex].key };
};

interface PageHeaderActionsProps {
  activeTabIndex: number;
  setActiveTabIndex: (v: number) => void;
  tabs: Tab[];
}
export const PageHeaderActions = ({
  activeTabIndex,
  setActiveTabIndex,
  tabs,
}: PageHeaderActionsProps) => {
  return (
    <EntityPickerTabBar
      value={activeTabIndex}
      onChange={(tab) => {
        setActiveTabIndex(tab);
      }}
    >
      {tabs.map((tab, index) => (
        <EntityPickerTab key={index} value={index}>
          {tab.label}
        </EntityPickerTab>
      ))}
    </EntityPickerTabBar>
  );
};
