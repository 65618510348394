import styles from './Toolbar.module.scss';

import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/system';
import clsx from 'clsx';

import {
  TimeFrameSwitch,
  TimeUnitType,
} from '@work4all/components/lib/components/time-frame-switch/TimeFrameSwitch';

import { assertNever } from '@work4all/utils';

import {
  AgendaSize,
  AppointmentsGroupMode,
  AppointmentsTimeRange,
} from '../types';

import { CalendarGroupModeSwitch } from './CalendarGroupModeSwitch';
import { CalendarTimeRangeSwitch } from './CalendarTimeRangeSwitch';

interface ToolbarProps {
  date: Date;
  onDateChange: (date: Date) => void;
  range: AppointmentsTimeRange;
  onRangeChange: (range: AppointmentsTimeRange) => void;
  groupMode: AppointmentsGroupMode;
  onGroupModeChange: (usersDisplayMode: AppointmentsGroupMode) => void;
  agendaSize: AgendaSize;
}
export function Toolbar(props: ToolbarProps) {
  const {
    date,
    onDateChange,
    range,
    onRangeChange,
    groupMode,
    onGroupModeChange,
    agendaSize,
  } = props;

  const isMdUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));
  const isXsDown = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('xs')
  );

  return (
    <Box
      sx={{
        gridArea: 'toolbar',
        display: 'flex',
        alignItems: isMdUp || isXsDown ? 'center' : 'start',
        flexWrap: 'wrap',
        flexDirection: isMdUp || isXsDown ? 'row' : 'column',
        borderBottom: '1px solid #cccccc',
      }}
    >
      <div
        className={clsx({
          [styles.timeFrameSwitchAndAgendaSizeContainer]:
            range === 'agenda' && !isMdUp,
        })}
      >
        <TimeFrameSwitch
          timeUnit={getTimeUnitFromAppointmentTimeRange(range, agendaSize)}
          initialTime={date}
          onChange={onDateChange}
        />
      </div>

      {isMdUp ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: isMdUp || isXsDown ? 'auto' : 0,
          }}
        >
          <CalendarTimeRangeSwitch
            value={range}
            onChange={onRangeChange}
            groupMode={groupMode}
            layout={'tabs'}
          />

          <Box>
            <CalendarGroupModeSwitch
              range={range}
              groupMode={groupMode}
              onGroupModeChange={onGroupModeChange}
              layout={'button'}
            />
          </Box>
        </Box>
      ) : null}
    </Box>
  );

  function getTimeUnitFromAppointmentTimeRange(
    range: AppointmentsTimeRange,
    agendaSize: AgendaSize
  ): TimeUnitType {
    switch (range) {
      case 'agenda':
        return agendaSize === 'expanded' ? 'month' : 'week';
      case 'day':
      case 'month':
        return range;
      case 'week-5':
      case 'week-7':
        return 'week';
      default:
        assertNever(range, `Unknown appointment time range ${range}`);
    }
  }
}
