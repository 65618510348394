import { useApolloClient } from '@apollo/client';
import { useEffect, useMemo, useRef, useState } from 'react';

import { DataRequest } from '@work4all/models/lib/DataProvider';

import {
  IndexRange,
  indexRangeToPagination,
} from '@work4all/utils/lib/pagination';

import { useDeepMemo } from '../../../../../components/src/lib/hooks';

import { useBuildQuery } from './hooks/useBuildQuery';

// TODO later we can switch on a user and/or entity base which entities to request live / offline
const mode = 'remote';

export type IResponseItem = Record<
  string,
  string | number | Record<string, number>
>;

export interface IResponse<T = IResponseItem> {
  data: T[];
  loading: boolean;
  pending: boolean;
  total: number;
  fetchMore: (startIndex: number, stopIndex: number) => Promise<unknown>;
  refetch: () => Promise<void>;
}

const defaultResult = {
  data: [],
  total: 0,
  loading: true,
  pending: true,
  fetchMore: () => Promise.resolve(),
  refetch: () => {
    return Promise.resolve(null);
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDataProvider<T = any>(
  requestData: DataRequest,
  skip?: boolean,
  pageSize = 100,
  forceRefUpdate = false
): IResponse<T> {
  const client = useApolloClient();
  const [result, setResult] = useState<IResponse<T>>(defaultResult);

  const {
    query,
    variables: newVariables,
    queryName,
  } = useBuildQuery(requestData, pageSize, skip);

  // Keep track of all fetched pages so we can refetch them later.
  //
  // Not strictly necessary, since we could calculate the pages from the actual
  // data instead. This is just easier.
  const loadedPagesRef = useRef<number[]>([]);

  const deepEqualVariables = useDeepMemo(() => newVariables, [newVariables]);

  const variables = forceRefUpdate ? newVariables : deepEqualVariables;

  useEffect(() => {
    if (mode === 'remote') {
      if (requestData.skip || skip) {
        setResult(defaultResult);
        return;
      }

      const execQuery = () => {
        loadedPagesRef.current = [0];

        let isFetching = false;
        let nextRangeToFetch: IndexRange | null = null;

        const response = client.watchQuery({
          query,
          variables,
          context: {
            singleBatch: requestData.singleBatch === true,
          },
        });

        // let's keep a closure here to remember the last value of the total count
        let total: number;
        const fetchMore = (startIndex: number, stopIndex: number) => {
          if (startIndex >= total - 1) {
            return;
          }

          if (isFetching) {
            nextRangeToFetch = { startIndex, stopIndex };
            return;
          }

          isFetching = true;

          const fetchNextPage = (props: { page: number; size: number }) => {
            const page = props?.page || 0;
            const size = props?.size || 0;
            return response.fetchMore({
              updateQuery: (
                previousQueryResult,
                { fetchMoreResult, variables }
              ) => {
                const page = variables.queryPage;

                if (!loadedPagesRef.current.includes(page)) {
                  loadedPagesRef.current.push(page);
                }

                const newItems = fetchMoreResult?.[queryName]?.data;
                if (!newItems || !newItems.length) {
                  return previousQueryResult;
                }

                const d = {
                  ...previousQueryResult[queryName],
                  ...fetchMoreResult?.[queryName],
                };

                const start = page * size;
                const newData =
                  previousQueryResult[queryName]?.data?.slice(0) || [];
                const len = newData.length;
                // splice won't append items at specified index if this index is "empty".
                newData[start] = null;
                if (start >= len) {
                  // graphql cache recognizes only "null values"
                  newData.fill(null, len, start);
                }

                const itemsToAdd = fetchMoreResult?.[queryName]?.data || [];

                newData.splice(start, itemsToAdd.length, ...itemsToAdd);
                d.data = newData;

                const res = {
                  ...previousQueryResult,
                  ...fetchMoreResult,
                  [queryName]: d,
                };

                if (nextRangeToFetch) {
                  const pagination = indexRangeToPagination(nextRangeToFetch);
                  nextRangeToFetch = null;
                  fetchNextPage(pagination);
                }

                isFetching = false;

                return res;
              },
              variables: { queryPage: page, querySize: size },
            });
          };

          return fetchNextPage(
            indexRangeToPagination({ startIndex, stopIndex })
          );
        };

        return response.subscribe(
          (result) => {
            const noPaginationWrapper =
              queryName === 'getLookups' || !!requestData.completeDataResponse;

            total = noPaginationWrapper
              ? result.data[queryName]?.length || 0
              : result.data[queryName].total;

            setResult((oldRes) => {
              const data = noPaginationWrapper
                ? result.data[queryName]
                : result.data[queryName].data;

              return {
                ...oldRes,
                data: !skip ? data : [],
                loading: result.loading,
                pending: false,
                total,
                fetchMore: fetchMore,
                async refetch() {
                  setResult((oldRes) => {
                    return {
                      ...oldRes,
                      data: !skip ? oldRes.data : [],
                      loading: true,
                      pending: true,
                    };
                  });

                  // If the query does not support pagination, there is no reason
                  // to do a custom refetch and the default one will do just fine.
                  if (noPaginationWrapper) {
                    await response.refetch();
                  } else {
                    // Otherwise we need to refetch all pages manually to avoid
                    // losing already displayed data. (The default refetch would
                    // fetch the first page only.)
                    await refetchManually();
                  }

                  setResult((oldRes) => {
                    return {
                      ...oldRes,
                      data: !skip ? oldRes.data : [],
                      pending: false,
                      loading: false,
                    };
                  });

                  return;

                  // Manually refetch all loaded pages and merge the results.
                  async function refetchManually() {
                    // Bypass Apollo cache for these requests so that the UI
                    // doesn't update with partial data while we are still doing
                    // the refresh.
                    const chunks = await Promise.all(
                      loadedPagesRef.current.map((page) => {
                        return client.query({
                          query,
                          variables: { ...variables, queryPage: page },
                          fetchPolicy: 'no-cache',
                        });
                      })
                    );

                    const lastChunk = chunks[chunks.length - 1];
                    const merged = {
                      ...lastChunk.data[queryName],
                      data: chunks.reduce((acc, cur) => {
                        return acc.concat(cur.data[queryName].data);
                      }, []),
                    };

                    client.writeQuery({
                      query,
                      variables,
                      data: { [queryName]: merged },
                    });
                  }
                },
              };
            });
          },
          () => {
            setResult((oldRes) => {
              return {
                ...oldRes,
                data: !skip ? oldRes.data : [],
                loading: false,
                pending: false,
              };
            });
          }
        );
      };

      setResult((oldRes) => {
        return { ...oldRes, data: !skip ? oldRes.data : [], pending: true };
      });

      const responseSubscription = execQuery();

      return () => {
        responseSubscription?.unsubscribe();
      };
    }
  }, [
    client,
    query,
    queryName,
    requestData.completeDataResponse,
    requestData.skip,
    skip,
    variables,
  ]);

  const resolvedSkip = requestData.skip || skip || false;
  const finalResult = useMemo(() => {
    if (!resolvedSkip) {
      return { ...result };
    }

    return { ...result, loading: false, pending: false };
  }, [result, resolvedSkip]);

  return finalResult;
}
