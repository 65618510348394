import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';

import { IFileInfoPanelItems } from '../file-info-panel/types';

export function dsiplyItemsFilter(
  businessPartner: Customer | Supplier,
  displayItems: IFileInfoPanelItems[]
) {
  if (businessPartner?.isPrivateCustomer) {
    return [...displayItems.filter((item) => item !== 'contactList')];
  } else return [...displayItems];
}
