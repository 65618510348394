import { useTranslation } from 'react-i18next';

import { KindsFooter } from '../../../components/more/kinds-footer/KindsFooter';
import { useAppointmentStatesHelper } from '../hooks/use-appointment-states-helper';
import { CalendarCardColorBy } from '../types';

interface FooterProps {
  selectedAppointmentStates: number[];
  onSelectedAppointmentStatesChange: (states: number[]) => void;
  calendarCardColorBy: CalendarCardColorBy;
}

export function Footer(props: FooterProps) {
  const {
    selectedAppointmentStates,
    onSelectedAppointmentStatesChange,
    calendarCardColorBy,
  } = props;
  const { t } = useTranslation();
  const {
    appointmentStates,
    isSelected,
    isEverythingSelected,
    toggle,
    toggleAll,
  } = useAppointmentStatesHelper({
    selectedAppointmentStates,
    onSelectedAppointmentStatesChange,
  });

  return (
    <KindsFooter
      items={appointmentStates}
      isEverythingSelected={isEverythingSelected}
      toggle={toggle}
      toggleAll={toggleAll}
      colorAccessor="hexColorForeground"
      isSelected={isSelected}
      applyColor={calendarCardColorBy === CalendarCardColorBy.AppointmentStatus}
      title={t('COMMON.APPOINTMENTTYPES')}
    />
  );
}
