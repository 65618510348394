import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { BzObjType } from '../Enums/BzObjType.enum';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { ErpPositionsKind } from '../Enums/ErpPositionsKind.enum';
import { ErpPositionXmlImportKind } from '../Enums/ErpPositionXmlImportKind.enum';

import { Article } from './Article.entity';
import { Project } from './Project.entity';
import { User } from './User.entity';

export class InboundPosition<T extends EMode = EMode.entity> {
  /** Alias: anteiligeBezugskosten */
  partialPurchaseCosts?: number;
  /** Alias: anzahl */
  amount?: number;
  /** Alias: artikelCode */
  articleId?: number;
  /** Alias: artikelNummer */
  articleNumber?: string;
  /** Alias: aufmass */
  measurement?: string;
  /** Alias: bestellmenge */
  orderAmount?: number;
  /** Alias: bzObjMemberCode */
  bzObjMemberId?: number;
  /** Alias: bzObjType */
  bzObjType?: BzObjType;
  /** Alias: code */
  id?: number;
  /** Alias: einheit */
  unit?: string;
  /** Alias: einzelgewicht */
  singleWeight?: number;
  /** Alias: einzelpreis */
  singlePriceNet?: number;
  /** Alias: faktor */
  factor?: number;
  /** Alias: frei1 */
  free1?: number;
  /** Alias: gebucht */
  booked?: number;
  /** Alias: gesamtpreis */
  totalPriceNet?: number;
  /** Alias: gewicht */
  weight?: number;
  /** Alias: index */
  index?: number;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: internerText */
  internalText?: string;
  /** Alias: kartonFaktorMultiplikator */
  cartonFactorMultiplicator?: number;
  /** Alias: kostenkonto */
  costAccount?: number;
  /** Alias: kostenstelle */
  costCenterId?: number;
  /** Alias: kostenstelle2 */
  costCenter2Id?: number;
  /** Alias: kurztext */
  shortText?: string;
  /** Alias: lagerMobilFertig */
  lagerMobilFinished?: boolean;
  /** Alias: lagerortCode */
  storageId?: number;
  /** Alias: langtext */
  longtext?: string;
  /** Alias: lieferantenArtikelnummer */
  supplierArticleNumber?: string;
  /** Alias: mengeKarton */
  amountCarton?: number;
  /** Alias: mengePaletten */
  amoundPalettes?: number;
  /** Alias: mengeVe */
  amountVe?: number;
  /** Alias: mwst */
  vat?: number;
  /** Alias: nummer */
  number?: string;
  /** Alias: originalCode */
  originalId?: number;
  /** Alias: palettenFaktorMultiplikator */
  paletteFactorMultiplicator?: number;
  /** Alias: positionsArt */
  positionKind?: ErpPositionsKind;
  /** Alias: projektCode */
  projectId?: number;
  /** Alias: rabatt */
  discount?: number;
  /** Alias: rabatt2 */
  discount2?: number;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: veFaktorMultiplikator */
  veFactorMultiplicator?: number;
  /** Alias: vorgang */
  process?: string;
  /** Alias: xmlImport */
  xmlImport?: ErpPositionXmlImportKind;
  /** Alias: artikel */
  article?: Article<T>;
  /** Alias: responsibleUser */
  responsibleUser?: User<T>;
  /** Alias: project */
  project?: Project<T>;

  __typename?: string;
}

const fields: FieldDefinitions<InboundPosition> = {
  partialPurchaseCosts: {
    alias: 'anteiligeBezugskosten',
  },
  amount: {
    alias: 'anzahl',
  },
  articleId: {
    alias: 'artikelCode',
  },
  articleNumber: {
    alias: 'artikelNummer',
  },
  measurement: {
    alias: 'aufmass',
  },
  orderAmount: {
    alias: 'bestellmenge',
  },
  bzObjMemberId: {
    alias: 'bzObjMemberCode',
  },
  bzObjType: {
    alias: 'bzObjType',
  },
  id: {
    alias: 'code',
  },
  unit: {
    alias: 'einheit',
  },
  singleWeight: {
    alias: 'einzelgewicht',
  },
  singlePriceNet: {
    alias: 'einzelpreis',
  },
  factor: {
    alias: 'faktor',
  },
  free1: {
    alias: 'frei1',
  },
  booked: {
    alias: 'gebucht',
  },
  totalPriceNet: {
    alias: 'gesamtpreis',
  },
  weight: {
    alias: 'gewicht',
  },
  index: {
    alias: 'index',
  },
  insertTime: {
    alias: 'insertTime',
  },
  internalText: {
    alias: 'internerText',
  },
  cartonFactorMultiplicator: {
    alias: 'kartonFaktorMultiplikator',
  },
  costAccount: {
    alias: 'kostenkonto',
  },
  costCenterId: {
    alias: 'kostenstelle',
  },
  costCenter2Id: {
    alias: 'kostenstelle2',
  },
  shortText: {
    alias: 'kurztext',
  },
  lagerMobilFinished: {
    alias: 'lagerMobilFertig',
  },
  storageId: {
    alias: 'lagerortCode',
  },
  longtext: {
    alias: 'langtext',
  },
  supplierArticleNumber: {
    alias: 'lieferantenArtikelnummer',
  },
  amountCarton: {
    alias: 'mengeKarton',
  },
  amoundPalettes: {
    alias: 'mengePaletten',
  },
  amountVe: {
    alias: 'mengeVe',
  },
  vat: {
    alias: 'mwst',
  },
  number: {
    alias: 'nummer',
  },
  originalId: {
    alias: 'originalCode',
  },
  paletteFactorMultiplicator: {
    alias: 'palettenFaktorMultiplikator',
  },
  positionKind: {
    alias: 'positionsArt',
  },
  projectId: {
    alias: 'projektCode',
  },
  discount: {
    alias: 'rabatt',
  },
  discount2: {
    alias: 'rabatt2',
  },
  updateTime: {
    alias: 'updateTime',
  },
  veFactorMultiplicator: {
    alias: 'veFaktorMultiplikator',
  },
  process: {
    alias: 'vorgang',
  },
  xmlImport: {
    alias: 'xmlImport',
  },
  article: {
    alias: 'artikel',
    entity: Entities.article,
  },
  responsibleUser: {
    alias: 'responsibleUser',
    entity: Entities.user,
  },
  project: {
    alias: 'project',
    entity: Entities.project,
  },
  __typename: {
    alias: '__typename',
  },
};

export const inboundPositionEntityDefinition: EntitiyDefinition<InboundPosition> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'Wareneingang',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
