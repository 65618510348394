import i18next from 'i18next';
import { DateTime } from 'luxon';

import { assertNever } from './assert';

export type SectionName =
  | 'future'
  | 'thisMonth'
  | 'thisYear'
  | 'lastYear'
  | 'before'
  | 'unknown';

type Section = {
  name: SectionName;
  date: DateTime;
};

export type SectionInfo = {
  sectionName: SectionName;
  sectionLabel: string;
  isSectionStart: boolean;
};

export function markSectionsByDate<Item, Result>(options: {
  items: Item[];
  getDate: (item: Item) => string;
  mapFn: (item: Item, info: SectionInfo) => Result;
}): Result[] {
  const { items, getDate, mapFn } = options;

  const now = DateTime.local();

  const groups: Section[] = [
    { name: 'future', date: now.startOf('day').plus({ days: 1 }) },
    { name: 'thisMonth', date: now.startOf('month') },
    { name: 'thisYear', date: now.startOf('year') },
    { name: 'lastYear', date: now.startOf('year').minus({ years: 1 }) },
  ];

  let lastSectionLabel: string | null = null;

  return items.map((item) => {
    const date = DateTime.fromISO(getDate(item));

    const sectionName = getSectionName(date, groups);
    const sectionLabel = getSectionLabel(date, sectionName);

    let isSectionStart = false;

    if (lastSectionLabel !== sectionLabel) {
      lastSectionLabel = sectionLabel;
      isSectionStart = true;
    }

    return mapFn(item, {
      sectionName: sectionName,
      sectionLabel: sectionLabel,
      isSectionStart: isSectionStart,
    });
  });
}

function getSectionName(date: DateTime, groups: Section[]): SectionName {
  if (!date.isValid) {
    return 'unknown';
  }

  for (const group of groups) {
    if (date >= group.date) {
      return group.name;
    }
  }

  return 'before';
}

function getSectionLabel(date: DateTime, name: SectionName): string {
  switch (name) {
    case 'future':
      return i18next.t('DATE_TIME.future');
    case 'thisMonth':
      return `${i18next.t('DATE_TIME.thisMonth')}`;
    case 'thisYear':
      return `${i18next.t('DATE_TIME.thisYear')}`;
    case 'lastYear':
    case 'before':
      return date.toFormat('yyyy');
    case 'unknown':
      return i18next.t('DATE_TIME.unknown');
    default:
      assertNever(name, `Unknown date group "${name}"`);
  }
}
