import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { TempFile } from './TempFile.entity';

export class CreateReportResult<T extends EMode = EMode.entity> {
  /** Alias: tempFileId */
  tempFileId?: string;
  /** Alias: displayName */
  displayName?: string;
  /** Alias: tempFile */
  tempFile?: TempFile<T>;
  /** Alias: errorMessage */
  errorMessage?: string;

  __typename?: string;
}

const fields: FieldDefinitions<CreateReportResult> = {
  tempFileId: {
    alias: 'tempFileId',
  },
  displayName: {
    alias: 'displayName',
  },
  tempFile: {
    alias: 'tempFile',
    entity: Entities.tempFile,
  },
  errorMessage: {
    alias: 'errorMessage',
  },
  __typename: {
    alias: '__typename',
  },
};

export const createReportResultEntityDefinition: EntitiyDefinition<CreateReportResult> =
  {
    local: {},
    remote: {
      queryName: 'createCrystalReport',
      fragmentName: 'CreateReportResult',
      withPaginationWrapper: false,
      params: [{ name: 'input', type: 'CreateCrystalReportRequest' }],
    },
    fieldDefinitions: fields,
  };
