import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { YELLOW_NOTE_FIELDS_QUERY } from '../yellow-note/yellow-note-fields';

export function useProject(id: string) {
  const requestData = useMemo<DataRequest>(() => {
    const filter = [{ id: { $eq: id } }];

    return {
      entity: Entities.project,
      data: PROJECT_FIELDS,
      filter,
    };
  }, [id]);

  const { data, pending, refetch } = useDataProvider<Project>(requestData);
  const project = data?.[0];
  return { project, pending, refetch };
}

const PROJECT_FIELDS: Project<EMode.query> = {
  id: null,
  name: null,
  number: null,
  externalProjectManager: null,
  internalProjectManager: {
    id: null,
    displayName: null,
  },
  budget: null,
  customer: {
    name: null,
    id: null,
    website: null,
  },
  supplier: {
    name: null,
    id: null,
    website: null,
  },
  group: {
    name: null,
    id: null,
  },
  projectKind: {
    name: null,
    id: null,
  },
  startDateInner: null,
  endDateInner: null,
  startDateOuter: null,
  endDateOuter: null,
  projectStatus: {
    name: null,
    id: null,
    closedStatus: null,
  },
  parentProject: {
    name: null,
    id: null,
  },
  note: null,
  postIt: YELLOW_NOTE_FIELDS_QUERY,
  categoryAssignmentList: [
    {
      id: null,
      categoryId: null,
      categoryName: null,
      categoryKindName: null,
    },
  ],
};
