import styles from './MaskTab.module.scss';

import Badge from '@mui/material/Badge';
import Tab, { TabProps } from '@mui/material/Tab';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useMaskTabContext } from './MaskTabContext';

export interface MaskTabProps extends Omit<TabProps, 'classes'> {
  value: string | number;
}

export function MaskTab(props: MaskTabProps) {
  const { t } = useTranslation();

  const context = useMaskTabContext();

  const errors = context.errors[props.value] ?? 0;

  const title =
    errors > 0 ? t('ERROR.SUBFIELDERROR', { count: errors }) : undefined;

  return (
    <div className={styles.tabWrapper}>
      <Badge
        color="error"
        badgeContent={errors}
        classes={{
          badge: styles.badge,
        }}
      >
        <Tab
          title={title}
          {...props}
          classes={{
            root: clsx(styles.root, {
              [styles.error]: errors > 0,
            }),
            selected: styles.selected,
          }}
        />
      </Badge>
    </div>
  );
}
