import styles from './EMailTemplateOverlayController.module.scss';

import { Button } from '@mui/material';
import { useEventCallback } from '@mui/material/utils';
import clsx from 'clsx';
import { pick } from 'lodash';
import { useContext, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Attachments } from '@work4all/components/lib/components/attachments';
import { AttachmentsDropZone } from '@work4all/components/lib/components/attachments/AttachmentsDropZone';
import { AttachmentsUploadButton } from '@work4all/components/lib/components/attachments/AttachmentsUploadButton';
import { FROALA_TEXTMARKS_STYLES_URL } from '@work4all/components/lib/components/entity-preview/utils';
import { TextEditor } from '@work4all/components/lib/input/format-text/TextEditor';
import { htmlParser } from '@work4all/components/lib/input/format-text/TextEditor/utils/html-parser';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input';
import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';
import {
  EventType,
  sendAmplitudeData,
} from '@work4all/components/lib/utils/amplitude/amplitude';

import { useDataMutation, useDataProvider, useUser } from '@work4all/data';
import {
  TempFileManagerContext,
  useTempFileManager,
} from '@work4all/data/lib/hooks/data-provider/useTempFileManager';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';
import { useEntityJsonSchema } from '@work4all/data/lib/json-schema/EntityJsonSchemasContext';
import { EMPTY_UID } from '@work4all/data/lib/utils/empty-uid';

import { EMailSignature } from '@work4all/models/lib/Classes/EMailSignature.entity';
import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { EMailTemplateGroup } from '@work4all/models/lib/Classes/EMailTemplateGroup.entity';
import { InputEMailVorlagenAnhangRelation } from '@work4all/models/lib/Classes/InputEMailVorlagenAnhangRelation.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useJSONSchemaResolver } from '@work4all/utils';
import { PathsOf } from '@work4all/utils/lib/paths-of/paths-of';

import { EmailSignaturePickerField } from '../../../../../components/entity-picker/EmailSignaturePickerField';
import { EMailTemplateGroupPickerField } from '../../../../../components/entity-picker/EMailTemplateGroupPickerField';
import { EMailTemplateKindPickerField } from '../../../../../components/entity-picker/EMailTemplateKindPickerField';
import { NavigationOverlayHeader } from '../../../../../components/navigation-overlay-header/NavigationOverlayHeader';
import { ControllerPlus } from '../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../form-plus/use-form-context-plus';
import { MenuItem } from '../../../locked-inputs';
import { Collapse, ControlWrapper, Form } from '../../components';
import { MaskActions } from '../../components/MaskActions';
import { MaskOverlayMenuWrapper } from '../../components/MaskOverlayMenuWrapper';
import {
  MaskContextProvider,
  useMaskConfig,
  useMaskContextValue,
} from '../../hooks/mask-context';
import { useConfirmBeforeCloseMask } from '../../hooks/use-confrm-before-close-mask';
import { useStandardDeleteEntityHandler } from '../../hooks/use-standard-delete-entity-handler';
import { normalizeFormValue } from '../../hooks/useExtendedFormContext';
import { MaskControllerProps } from '../../types';
import { useFormUpdate } from '../../utils/use-form-update';

import { HtmlInputField } from './components/html-input-field/HtmlInputField';

type EMailTemplateFormValue = PathsOf<EMailTemplate, 2>;

type EMailTemplateOverlayControllerProps = MaskControllerProps & {
  amplitudeEntryPoint: string;
};

const REQUEST_DATA: EMailTemplate<EMode.query> = {
  id: null,
  subject: null,
  body: null,
  eMailTemplateKind: null,
  name: null,
  groupId: null,
  templateGroup: {
    id: null,
    name: null,
  },
  signature: {
    body: null,
    id: null,
    name: null,
  },
  signaturId: null,
  attachmentList: [
    {
      id: null,
      fileName: null,
      fileInfos: {
        fileSize: null,
        downloadUrl: null,
        fileEntityFilename: null,
      },
    },
  ],
};

export function EmailTemplateOverlayController(
  props: EMailTemplateOverlayControllerProps
) {
  const { amplitudeEntryPoint } = props;
  const { t } = useTranslation();

  const { goBack, close, currentStackIndex, setObjectionListener } =
    useHistoryStack();
  const [mutate] = useDataMutation<
    EMailTemplate,
    EMode.upsert,
    {
      attachements;
    }
  >({
    entity: Entities.eMailTemplate,
    mutationType: EMode.upsert,
    responseData: REQUEST_DATA,
  });

  const mask = useMaskConfig(props);

  useEffect(() => {
    if (mask.isCreateMode) {
      sendAmplitudeData(EventType.AddEMailTemplate, {
        entryPoint: amplitudeEntryPoint,
      });
    } else {
      sendAmplitudeData(EventType.EditEMailTemplate, {
        entryPoint: amplitudeEntryPoint,
      });
    }
  }, [amplitudeEntryPoint, mask, mask.isCreateMode, props.id]);

  const requestEMailTemplateData = useMemo<DataRequest>(() => {
    const EMailTemplateRequestData: EMailTemplate<EMode.query> = REQUEST_DATA;
    const filter = [{ id: { $eq: mask.id } }];
    const data = EMailTemplateRequestData;
    return {
      entity: Entities.eMailTemplate,
      data,
      filter,
      completeDataResponse: true,
    };
  }, [mask.id]);

  const currentEMailTemplate = useDataProvider<EMailTemplate>(
    requestEMailTemplateData,
    mask.isCreateMode
  );

  const dataRaw = useMemo(() => {
    const defaultValues: EMailTemplate = {
      attachmentList: [],
      eMailTemplateKind: EMailTemplateKind.KEINE,
      subject: '',
      body: '',
    };

    const result =
      (mask.isCreateMode ? defaultValues : currentEMailTemplate?.data?.[0]) ??
      defaultValues;
    return result;
  }, [currentEMailTemplate, mask.isCreateMode]);

  const data = useMemo<PathsOf<EMailTemplate, 2>>(() => {
    const result = normalizeFormValue(dataRaw);
    return result;
  }, [dataRaw]);

  const schema = useEntityJsonSchema(Entities.eMailTemplate);
  const resolver = useJSONSchemaResolver(schema);

  const form = useForm<EMailTemplateFormValue>({
    resolver,
    defaultValues: data,
    mode: 'onChange',
    shouldFocusError: false,
  });

  const { formState, getValues } = form;

  /*
    The [key: string] used to prevent entering a loop
    Which cause a "RangeError: Maximum Call Stack Size Exceeded"
  */
  useFormUpdate(
    {
      templateGroup: (value: EMailTemplateGroup) => {
        return {
          groupId: value?.id || EMPTY_UID,
        };
      },
      signature: (signature: EMailSignature) => {
        const currentBody = getValues('body');
        const updatedBody = htmlParser.replaceSignature(
          currentBody,
          signature?.body
        );

        return {
          body: updatedBody,
          signaturId: signature?.id || EMPTY_UID,
        };
      },
    },
    form
  );

  const isDirtyForm = Object.entries(formState.dirtyFields).length > 0;
  useConfirmBeforeCloseMask(isDirtyForm);

  const { handleSubmit, reset } = form;

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  const deleteHandler = useStandardDeleteEntityHandler({
    entity: Entities.eMailTemplate,
    id: mask.id,
  });

  const cleanedPersistantAttachmentList = useMemo(() => {
    return data?.attachmentList?.map((x) => ({ ...x, __typename: undefined }));
  }, [data?.attachmentList]);

  const tempFileManager = useTempFileManager(cleanedPersistantAttachmentList, {
    maxAttachmentTotalSize: 50 * 1024 * 1024,
  });

  const relations = useMemo(() => {
    const hasAttachmentToAdd = tempFileManager.temporaryFileUploads.length > 0;
    const hasAttachmentToRemove = tempFileManager.fileIdsToDelete.length > 0;

    const attachements: InputEMailVorlagenAnhangRelation = {
      add: hasAttachmentToAdd
        ? [
            ...tempFileManager.temporaryFileUploads.map((x) => ({
              tempFileId: x.id as string,
              name: x.fileName,
            })),
          ]
        : undefined,
      remove: hasAttachmentToRemove
        ? (tempFileManager.fileIdsToDelete as string[])
        : undefined,
    };
    const result = { attachements };
    return result;
  }, [tempFileManager.fileIdsToDelete, tempFileManager.temporaryFileUploads]);

  const onSubmit = useEventCallback(async (values: EMailTemplateFormValue) => {
    const update = mask.isCreateMode
      ? values
      : pick(values, ['id', ...Object.keys(formState.dirtyFields)]);

    if (update.body) {
      update.body = htmlParser.replaceSignature(update.body, '');
    }

    await mutate(update, { relations });

    setObjectionListener(null);
    sendAmplitudeData(EventType.SaveEMailTemplate, {
      entryPoint: amplitudeEntryPoint,
    });
    if (currentStackIndex !== 0) {
      goBack();
    } else {
      close();
    }
  });

  const maskContext = useMaskContextValue({ ...mask, data });

  return (
    <MaskContextProvider value={maskContext}>
      <FormProvider {...form}>
        <TempFileManagerContext.Provider value={tempFileManager}>
          <AttachmentsDropZone>
            <div className={clsx(styles.root, 'custom-scrollbar')}>
              <div>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <NavigationOverlayHeader
                    title={t('COMMON.EMAILTEMPLATE')}
                    breadcrumbsChildren={
                      <MaskActions>
                        <AttachmentsUploadButton />
                        <Button
                          type="submit"
                          size="large"
                          color="primary"
                          variant="contained"
                        >
                          {t('INPUTS.SAVE')}
                        </Button>
                        {mask.id && (
                          <MaskOverlayMenuWrapper>
                            <MenuItem onClick={deleteHandler}>
                              {t('MASK.REMOVE')}
                            </MenuItem>
                          </MaskOverlayMenuWrapper>
                        )}
                      </MaskActions>
                    }
                  />
                  <EMailTemplateMaskContent />
                </Form>
              </div>
            </div>
          </AttachmentsDropZone>
        </TempFileManagerContext.Provider>
      </FormProvider>
    </MaskContextProvider>
  );
}

const generateFileUploadUrl = (baseUrl: string, authToken: string) => {
  return `${baseUrl}/api/file/inlinemailimage?auth=${authToken}`;
};

function EMailTemplateMaskContent() {
  const { t } = useTranslation();

  const { register, control } = useFormContextPlus<EMailTemplateFormValue>();

  const { activeTenant } = useTenant();
  const user = useUser();

  const { fileList: attachmentList } = useContext(TempFileManagerContext);

  return (
    <>
      <div className={styles.metaData}>
        <div className={styles.left}>
          <Collapse title={t('EMAILTEMPLATE.DESCRIPTION')} defaultOpen>
            <ControlWrapper>
              <LabeledInput label={t('COMMON.NAME')} {...register('name')} />
            </ControlWrapper>

            <ControlWrapper>
              <ControllerPlus
                control={control}
                name="subject"
                render={({ field }) => (
                  <HtmlInputField label={t('COMMON.SUBJECT')} {...field} />
                )}
              />
            </ControlWrapper>
          </Collapse>
        </div>
        <div className={styles.right}>
          <Collapse title={t('COMMON.ASSIGNMENT')} defaultOpen>
            <div className={styles.xSplit}>
              <ControlWrapper>
                <ControllerPlus
                  control={control}
                  name="templateGroup"
                  render={({ field }) => (
                    <EMailTemplateGroupPickerField {...field} />
                  )}
                />
              </ControlWrapper>
              <ControlWrapper>
                <ControllerPlus
                  control={control}
                  name="eMailTemplateKind"
                  render={({ field }) => (
                    <EMailTemplateKindPickerField multiple={false} {...field} />
                  )}
                />
              </ControlWrapper>
            </div>
            <ControlWrapper>
              <ControllerPlus
                control={control}
                name="signature"
                render={({ field }) => <EmailSignaturePickerField {...field} />}
              />
            </ControlWrapper>
          </Collapse>
        </div>
      </div>
      {attachmentList.length > 0 ? (
        <div className={styles.sectionPadding}>
          <Attachments
            disableEditAction={false}
            disableAddAction={false}
            disableRemoveAction={false}
            layout="compact"
          />
        </div>
      ) : null}
      <Collapse defaultOpen>
        <ControllerPlus
          control={control}
          name="body"
          render={({ field }) => (
            <TextEditor
              {...field}
              enableTextBuildingBlocks={true}
              enableTextmarks={true}
              config={{
                heightMin: 200,
                requestHeaders: {
                  'x-work4all-mandant': activeTenant,
                },
                imageUpload: true,
                imageUploadURL: generateFileUploadUrl(user.baseUrl, user.token),
                iframeStyleFiles: [FROALA_TEXTMARKS_STYLES_URL],
              }}
            />
          )}
        />
      </Collapse>
    </>
  );
}
