import { useMemo } from 'react';

import {
  checkUserRight,
  UserRights,
} from '@work4all/models/lib/additionalEnums/UserRights';
import { Document } from '@work4all/models/lib/Classes/Document.entity';

interface Props {
  document: Pick<Document, 'userId'>;
  userRights: string;
  userId: number;
  fileName: string;
}

export const useDocumentPdfEditRights = (props: Props) => {
  const { document, userRights, userId, fileName } = props;

  const docIsPDF = useMemo(() => {
    const segments = fileName?.split('.');
    return (
      segments && segments[segments.length - 1].toLocaleLowerCase() === 'pdf'
    );
  }, [fileName]);

  const docPdfEditAllowed = useMemo(() => {
    if (!docIsPDF) {
      return false;
    }
    if (checkUserRight(UserRights.KorrespondenzAnlegenAendern, userRights)) {
      return true;
    }
    if (
      checkUserRight(UserRights.KorrespondenzLoeschenEigene, userRights) &&
      document?.userId === userId
    ) {
      return true;
    }
    return false;
  }, [docIsPDF, userRights, document?.userId, userId]);

  return docPdfEditAllowed;
};
