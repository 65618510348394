import { useState } from 'react';

export interface CopyButtonProps {
  value: string;
  timeout?: number;
  children(props: { copied: boolean; copy: () => void }): React.ReactNode;
}

export function CopyButton(props: CopyButtonProps) {
  const { value, timeout = 1000, children } = props;

  const [copied, setCopied] = useState(false);
  const [copyTimeout, setCopyTimeout] = useState(null);

  const handleCopyResult = (value: boolean) => {
    clearTimeout(copyTimeout);
    setCopyTimeout(setTimeout(() => setCopied(false), timeout));
    setCopied(value);
  };

  const copy = () => {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(value).then(() => handleCopyResult(true));
    } else {
      throw new Error('navigator.clipboard is not supported');
    }
  };

  return <>{children({ copy, copied })}</>;
}
