import { RxCollectionCreator, RxDatabase } from 'rxdb';
import { CollectionsOfDatabase, RxLocalDocument } from 'rxdb/dist/types/types';

import { syncEntities, syncTenantSpecificEntities } from '../entities';
import { deriveTenantCollectionName } from '../utils';

export const USER_META_ID = 'usermeta';
export const TENANTS_META_DOC_ID = 'tenantsmeta';

export type ITenantsMeta = {
  tenants: {
    [tenantId: number]: string;
  };
};

export async function createLocalDocuments(db: RxDatabase) {
  const userMetaDoc = await db.getLocal(USER_META_ID);
  if (!userMetaDoc) {
    await db.insertLocal(USER_META_ID, {});
  }
}

export async function removeLocalDocuments(db: RxDatabase) {
  const idsToRemove = [USER_META_ID, TENANTS_META_DOC_ID];

  await Promise.all(
    idsToRemove.map(async (id) => {
      const doc = await db.getLocal(id);

      if (doc) {
        // return doc.remove();
        return doc.incrementalModify(() => {
          return {};
        });
      }
    })
  );
}

export async function createSharedCollections(db: RxDatabase) {
  const missingCollections = syncEntities
    .filter(({ name }) => !db[name])
    .map((ent) => {
      return [ent.name, { schema: ent.schema }];
    });

  if (missingCollections.length > 0) {
    await db.addCollections(Object.fromEntries(missingCollections));
  }
}

async function cleanSharedCollections(db: RxDatabase) {
  await Promise.all(
    syncEntities.map(async ({ name }) => {
      console.debug(`Clearing ${name} collection.`);
      await db.collections[name].remove();
    })
  );
}

export async function createTenantCollections(
  db: RxDatabase,
  tenantId: number
) {
  if (tenantId === null) return;

  const missingCollections = syncTenantSpecificEntities
    .map((creator) => {
      const name = deriveTenantCollectionName(tenantId, creator.name);
      return [name, { schema: creator.schema }] as const;
    })
    .filter(([name]) => !db[name]);

  if (missingCollections.length > 0) {
    // Create all collections that are missing for this tenant.
    await db.addCollections(Object.fromEntries(missingCollections));
  }

  // Save the info about created tenant collections

  let tenantsMetaDoc: RxLocalDocument<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    RxDatabase<CollectionsOfDatabase, any, any>,
    ITenantsMeta
  > = null;

  try {
    tenantsMetaDoc = await db.insertLocal<ITenantsMeta>(TENANTS_META_DOC_ID, {
      tenants: {},
    });
  } catch (e) {
    // If the document already exists, we'll get an error. Just return the
    // current document.
    tenantsMetaDoc = await db.getLocal<ITenantsMeta>(TENANTS_META_DOC_ID);
  }

  await tenantsMetaDoc.incrementalModify((draft) => {
    draft.tenants = draft.tenants || {};
    draft.tenants[tenantId] = new Date().toISOString();
    return draft;
  });
}

async function removeTenantCollections(db: RxDatabase) {
  const tenantsMetaDoc = await db.getLocal<ITenantsMeta>(TENANTS_META_DOC_ID);
  const tenantIds = Object.keys(tenantsMetaDoc.toJSON().data.tenants ?? {});

  if (tenantIds.length > 0) {
    console.debug(`Clearing tenant collections for tenants ${tenantIds}.`);
  }

  const allTenantCollections: {
    name: string;
    creator: RxCollectionCreator;
  }[] = tenantIds.flatMap((tenantId) => {
    return syncTenantSpecificEntities.map((creator) => {
      const collectionName = deriveTenantCollectionName(tenantId, creator.name);
      return {
        name: collectionName,
        creator: { ...creator, autoMigrate: false },
      };
    });
  });

  const mappedTenantCols = allTenantCollections
    .filter(({ name }) => !db[name])
    .map(({ name, creator }) => [name, { schema: creator.schema }]);

  // Collections that have been created before that are not initialized
  // with this instance of RxDB. We need to initialize them before we can
  // remove them. as they are not know to the rxdb instance elsewise
  //in this cases add will acutally not do anything but load the colleciton

  if (mappedTenantCols.length > 0) {
    const missingCollections = Object.fromEntries(mappedTenantCols);
    await db.addCollections(missingCollections);
  }

  // Remove tenant-specific collections.
  await Promise.all(
    allTenantCollections.map(async ({ name }) => {
      console.debug(`Clearing ${name} collection.`);
      await db.collections[name].remove();
    })
  );
}

export async function cleanupDatabase(db: RxDatabase) {
  //make sure to reset to the inital state of the db
  await cleanSharedCollections(db);
  await removeTenantCollections(db);
  await removeLocalDocuments(db);
  // await Promise.all([createLocalDocuments(db), createSharedCollections(db)]);
  console.debug('cleanedup & reconfigured db');
}
