import styles from './Alert.module.scss';

import { ModalProps } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ActionButton } from '../../input/action-button/ActionButton';
import { Body1 } from '../../typography/body1/Body1';

import { Alert } from './Alert';

interface Props extends Omit<ModalProps, 'children'> {
  header: string;
  body: string;
  cancel: () => void;
}

export const OkAlert: React.FC<Props> = (props: Props) => {
  const { header, body, cancel, ...modalProps } = props;
  const { t } = useTranslation();

  return (
    <Alert
      {...modalProps}
      onClose={cancel}
      header={header}
      body={<Body1 className={styles.title}>{body}</Body1>}
      footer={
        <ActionButton
          title={t('ALERTS.OK')}
          className={clsx(styles.actionButton, styles.grant)}
          onClick={cancel}
        />
      }
    />
  );
};
