import { APP_VERSION } from '../../../config';
import {
  APP_VERSION_LS_KEY,
  LAST_NOTIFICATION_DATE_LS_KEY,
  UPDATE_NOTIFICATION_INTERVAL_MS,
} from '../constants';

/**
 * Check if we have already shown a notification for this version recently.
 */
export function didShowUpdateNotificationRecently(): boolean {
  const lastNotificationVersion =
    window.localStorage.getItem(APP_VERSION_LS_KEY);

  if (lastNotificationVersion !== APP_VERSION) {
    return false;
  }

  const lastNotificationDate = window.localStorage.getItem(
    LAST_NOTIFICATION_DATE_LS_KEY
  );

  if (lastNotificationDate === null) {
    return false;
  }

  const date = new Date(lastNotificationDate);

  if (
    Number.isNaN(date.getTime()) ||
    date.getTime() + UPDATE_NOTIFICATION_INTERVAL_MS < Date.now()
  ) {
    return false;
  }

  return true;
}

export function saveLastNotificationDate(): void {
  const now = new Date().toISOString();
  window.localStorage.setItem(APP_VERSION_LS_KEY, APP_VERSION);
  window.localStorage.setItem(LAST_NOTIFICATION_DATE_LS_KEY, now);
}
