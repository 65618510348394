import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FileViewMode,
  useWidgetsDataBag,
} from '@work4all/data/lib/hooks/useWidgetsBag';

import { ILayoutGroupDefinition } from '@work4all/models/lib/Layout';

import { WidgetGroup } from '../WidgetGroup';
import { WidgetGroupContent } from '../WidgetGroupContent';

export const WidgetsController = memo(function WidgetsController(props: {
  viewMode: FileViewMode;
}) {
  const { widgetsDefinitions } = useWidgetsDataBag();
  const { t } = useTranslation();

  const { viewMode } = props;

  function getGroupDisplayName(group: ILayoutGroupDefinition): string {
    if (group.type === 'default' && group.i18nKey) {
      return t(group.i18nKey);
    } else {
      return group.name;
    }
  }

  if (!widgetsDefinitions) {
    return null;
  }

  const widgetGroups = widgetsDefinitions.definition;

  switch (widgetGroups.length) {
    case 0:
      return null;
    case 1:
      return (
        <WidgetGroupContent
          widgets={widgetGroups[0].widgets}
          viewMode={viewMode}
        />
      );
    default:
      return (
        <>
          {widgetGroups.map((group) => (
            <WidgetGroup
              key={group.id}
              id={group.id}
              name={getGroupDisplayName(group)}
              defaultCollapsed={group.collapsed}
              widgets={group.widgets}
              viewMode={viewMode}
            />
          ))}
        </>
      );
  }
});
