import { gql } from '@apollo/client';

import { Appearance } from '@work4all/models/lib/Classes/Appearance.entity';
import { AppearanceSettingsInput } from '@work4all/models/lib/Classes/AppearanceSettingsInput.entity';

export const GET_APPEARANCE_QUERY = gql`
  query GetAppearance {
    getAppearance {
      backgroundImagesGeneral {
        id
        url
        urlThumbnail
        filename
      }
      backgroundImagesTenant {
        id
        url
        urlThumbnail
        filename
      }
      backgroundImagesUser {
        id
        url
        urlThumbnail
        filename
      }
      configuration {
        selectedBackgroundImage
      }
    }
  }
`;

export interface GetAppearanceQueryResult {
  getAppearance: Appearance;
}

export const CHANGE_BACKGROUND_MUTATION = gql`
  mutation ChangeBackground($input: UserApperanceConfigurationInput!) {
    modifyUserAppearanceConfiguration(input: $input) {
      configuration {
        selectedBackgroundImage
      }
    }
  }
`;

export interface ChangeBackgroundMutationResult {
  modifyUserAppearanceConfiguration: {
    configuration: {
      selectedBackgroundImage: string | null;
    };
  };
}

export interface ChangeBackgroundMutationVariables {
  input: {
    selectedBackgroundImage: string | null;
  };
}

export const MODIFY_APPEARANCE_MUTATION = gql`
  mutation AddCustomBackground($input: AppearanceSettingsInput!) {
    modifyAppearance(input: $input) {
      __typename
    }
  }
`;

export interface ModifyAppearanceMutationVariables {
  input: AppearanceSettingsInput;
}
