import { DateTime } from 'luxon';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  DeepPartial,
  get,
  Path,
  UnpackNestedValue,
  UseFormReturn,
} from 'react-hook-form';

import { W4ADateFormat } from '@work4all/models/lib/additionalEnums/DateFormat.enum';

import { AccesorsOf } from '@work4all/utils/lib/paths-of/paths-of';

export function normalizeFormValue<T>(value: T): T {
  const normalizeData: T = { ...value };
  Object.keys(normalizeData).forEach((key) => {
    const val = normalizeData[key];
    //textarea does use \n for linebreaks only, make sure not to use carriage return anymore
    let normalizedVal =
      val && typeof val === 'string' ? val.replace(/\r/g, '') : val;

    //the database does not return miliseconds if the time is 00:00.0 we normalize that here
    if (typeof val === 'string' && DateTime.fromISO(val).isValid) {
      const testForDate = val.replace(/(.*)Z$/, '$1+0');
      if (DateTime.fromFormat(testForDate, W4ADateFormat.DEFAULT).isValid) {
        if (
          DateTime.fromISO(val).toMillis() === -62135599980000 //01.01.1. is supposed to be the bottom value in w4a -> .NET legacy
        ) {
          normalizedVal = '';
        } else {
          normalizedVal = DateTime.fromISO(val).toFormat(W4ADateFormat.DEFAULT);
        }
      }
    }
    normalizeData[key] = normalizedVal;
  });

  return normalizeData;
}

/**
 *
 * Material UIs input elements need to have either a value or a default value to properly
 * display their shrink state (floating label)
 * Similary the react-hook-form needs to have proper defaultValues as well top determine dirty state,
 * beeing resetable and so on
 *
 * this hook combines both so that its extenden "register" call will also simiultaneosly maintain the fields default value
 * when the inital inputs change
 */
export const useExtendedForm = <T extends object>(
  defaultValues: T,
  formMethods: UseFormReturn<T>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema?: any
) => {
  const [initalValues, setInitialValues] = useState<T>(() =>
    normalizeFormValue(defaultValues)
  );

  const reset = formMethods.reset;
  useEffect(() => {
    const normalizeData = normalizeFormValue(defaultValues);
    setInitialValues(normalizeData);
  }, [defaultValues]);

  useEffect(() => {
    reset(initalValues as UnpackNestedValue<DeepPartial<T>>);
  }, [initalValues, reset]);

  const internalReg = formMethods.register;

  /**
   * formState.errors and formState are a proxy, therefore we cannot directly listen to their changes as they will never change
   * if we want to reliably figure out if an error is added or has gone we can only count the number of active erros to indicate changes
   */
  const formStateRef = useRef(formMethods.formState);
  formStateRef.current = formMethods.formState;
  const errCount = Object.keys(formMethods.formState.errors).length;

  const extendedRegister = useCallback(
    (name: AccesorsOf<T>, opts?) => {
      return {
        ...internalReg(name as unknown as Path<T>, {
          valueAsNumber: ['integer', 'number'].includes(
            schema?.properties[name]?.type
          ),
          ...opts,
        }),
        defaultValue: get(initalValues, name as unknown as string),
        required: schema?.required?.includes(name as unknown as string),
        error:
          errCount > 0
            ? formStateRef.current.errors[name as unknown as string]?.message
            : undefined,
      };
    },
    [internalReg, schema?.properties, schema?.required, initalValues, errCount]
  );

  return {
    ...formMethods,
    defaultValues: initalValues,
    register: extendedRegister,
  };
};
