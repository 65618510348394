import styles from './Payment.module.scss';

import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { PaymentKindPicker } from '@work4all/components/lib/components/entity-picker/PaymentKindPicker';
import {
  MultiStepControls,
  Step,
} from '@work4all/components/lib/input/multi-step-controls';

import { formatCurrency } from '@work4all/utils';

import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import {
  DateTimeInputPicker,
  LabeledInput,
  LabeledInputWithUnit,
} from '../../../../../../../../locked-inputs';
import { Collapse } from '../../../../../../../components';
import { InboundInvoiceFormValue } from '../../../../../types';

export function Payment() {
  const { t } = useTranslation();

  const form = useFormContextPlus<InboundInvoiceFormValue>();
  const { register, control, watch } = form;

  const amount = watch('rAmount');
  const discount = watch('skontoPercent');
  const discount2 = watch('skonto2');
  const currency = watch('currency');
  const calcualtedSum = ((amount * discount) / 100).toFixed(2);
  const calcualtedSum2 = ((amount * discount2) / 100).toFixed(2);

  return (
    <div className={styles.payment}>
      <Collapse title={t('MASK.PAYMENT')} defaultOpen>
        <Stack direction="column" spacing={2}>
          <ControllerPlus
            control={control}
            name={'paymentKind'}
            render={({ field }) => {
              const { value, onChange } = field;

              const onClear = () => onChange(null);

              return (
                <EntityPickerPopover
                  picker={
                    <PaymentKindPicker
                      multiple={false}
                      value={value}
                      onChange={onChange}
                    />
                  }
                >
                  <LabeledInput
                    label={t('INPUTS.PAYMENT_KIND')}
                    value={value ? value.note : ''}
                    onClear={onClear}
                  />
                </EntityPickerPopover>
              );
            }}
          />

          <MultiStepControls>
            <Step active index={0}>
              <DateTimeInputPicker
                dateLabel={t('INPUTS.RECEIVED_DATE')}
                {...register('receivedDate')}
                clearable={false}
                withTime={false}
                disabled
              />
            </Step>

            <Step active index={1}>
              <LabeledInputWithUnit
                label={t('INPUTS.PERIOD')}
                {...register('paymentTermDays')}
                unit={t('INPUTS.DAYS')}
                type="number"
              />
            </Step>

            <Step active index={2}>
              <DateTimeInputPicker
                dateLabel={t('INPUTS.DUE_DATE')}
                {...register('dueDate')}
                clearable={false}
                withTime={false}
              />
            </Step>
          </MultiStepControls>
        </Stack>
      </Collapse>
      <Collapse title={t('INPUTS.DISCOUNT')} defaultOpen>
        <Stack direction="column" spacing={2}>
          <MultiStepControls>
            <Step active index={0}>
              <LabeledInputWithUnit
                label={t('INPUTS.TO')}
                {...register('skontoDurationDays')}
                unit={t('INPUTS.DAYS')}
                type="number"
              />
            </Step>

            <Step active index={1}>
              <DateTimeInputPicker
                dateLabel={t('INPUTS.DATE')}
                withTime={false}
                {...register('skontoDate')}
              />
            </Step>

            <Step active index={2}>
              <LabeledInputWithUnit
                label={t('INPUTS.DISCOUNT')}
                {...register('skontoPercent')}
                unit={'%'}
                type="number"
              />
            </Step>

            <Step active index={3}>
              <LabeledInputWithUnit
                textColor="text03"
                label={t('INPUTS.PAYMENT_AMOUNT')}
                unit={formatCurrency(currency, t)}
                value={calcualtedSum}
                type="number"
                disabled
              />
            </Step>
          </MultiStepControls>

          <MultiStepControls>
            <Step active index={0}>
              <LabeledInputWithUnit
                label={t('INPUTS.TO')}
                {...register('skonto2DurationDays')}
                unit={t('INPUTS.DAYS')}
                type="number"
              />
            </Step>

            <Step active index={1}>
              <DateTimeInputPicker
                dateLabel={t('INPUTS.DATE')}
                {...register('skonto2Date')}
                withTime={false}
              />
            </Step>

            <Step active index={2}>
              <LabeledInputWithUnit
                label={t('INPUTS.DISCOUNT')}
                // Desktop version used wrong field that's why it's skonto2 not skonto2Percent
                {...register('skonto2')}
                unit={'%'}
                type="number"
              />
            </Step>

            <Step active index={3}>
              <LabeledInputWithUnit
                textColor="text03"
                label={t('INPUTS.PAYMENT_AMOUNT')}
                value={calcualtedSum2}
                unit={formatCurrency(currency, t)}
                type="number"
                disabled
              />
            </Step>
          </MultiStepControls>
        </Stack>
      </Collapse>
    </div>
  );
}
