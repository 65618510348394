import styles from './EntityPicker.module.scss';

import { Stack } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { BankDetails } from '@work4all/models/lib/Classes/BankDetails.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ListEntityPicker } from './components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from './types';

export interface BankDetailsPickerProps
  extends Omit<IPickerProps<BankDetails, false>, 'multiple' | 'prefilter'> {
  entity: Entities.supplier | Entities.customer;
  entityId: number;
}

export function BankDetailsPicker(props: BankDetailsPickerProps) {
  const { data, entity, entityId, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...FIELDS, ...data };
  }, [data]);

  const requestData = useMemo(() => {
    return {
      entity,
      data: {
        bankDetailsList: FIELDS,
      },
      filter: [
        {
          id: {
            $eq: entityId,
          },
        },
      ],
    };
  }, [entity, entityId]);

  const response = useDataProvider<Supplier | Customer>(requestData);

  const renderItem = (item: BankDetails) => {
    return (
      <Tooltip title={item.iban}>
        <Stack
          direction="row"
          justifyContent="space-between"
          style={{ width: '100%' }}
        >
          <Typography variant="body2" noWrap>
            {item.name}
          </Typography>
          <Typography variant="body2" noWrap className={styles.secondaryInfo}>
            {item.iban}
          </Typography>
        </Stack>
      </Tooltip>
    );
  };

  const dataset = useMemo(() => {
    const fixedData = response.data?.[0]?.bankDetailsList || [];

    return {
      total: fixedData.length,
      loading: response.loading,
      fetchMore: () => Promise.resolve(),
      refetch: () => Promise.resolve(null),
      data: fixedData,
    };
  }, [response]);

  return (
    <ListEntityPicker
      entity={Entities.bankDetails}
      data={allFields}
      layout="advanced"
      filterBy={['name', 'iban']}
      sortBy="name"
      multiple={false}
      fixedDataSet={dataset}
      {...rest}
      renderItemContent={renderItem}
    />
  );
}

const FIELDS: BankDetails = {
  id: null,
  name: null,
  iban: null,
};
