import styles from './styles.module.scss';

import { Divider, MenuItem, MenuList } from '@mui/material';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import { OverflowList } from '@work4all/components/lib/components/overflow-list/OverflowList';

import { DndTypes } from '@work4all/utils/lib/variables';

import { useFavoriteLinks } from '../../../containers/more/data/favorite-links';
import {
  FavoriteLinkDragObject,
  FavoriteLinksFolderDragObject,
  LinkDragObject,
  LinksFolderDragObject,
} from '../../../dnd/drag-objects';
import { ContextMenu, DISMISS_POPOVER } from '../../context-menu/ContextMenu';

import { FavoriteLink } from './FavoriteLink';
import { FavoriteLinksFolder } from './FavoriteLinksFolder';
import { OverflowListPopover } from './overflow/OveflowListPopover';

export type IFavoriteLinksProps = {
  activeLink: { folderId: string | null; linkId: string } | null;
};

export function FavoriteLinks({ activeLink }: IFavoriteLinksProps) {
  const { t } = useTranslation();

  const {
    favoriteLinks,
    addFavoriteLink,
    moveFavoriteLink,
    addFavoriteLinksFolder,
    moveFavoriteLinksFolder,
  } = useFavoriteLinks();

  const onCreateNewFolder = () => {
    addFavoriteLinksFolder();
  };

  const [{ isDragging }, drop] = useDrop({
    accept: [
      DndTypes.LINK,
      DndTypes.LINKS_FOLDER,
      DndTypes.FAVORITE_LINK,
      DndTypes.FAVORITE_LINKS_FOLDER,
    ],
    drop: (item: unknown, monitor) => {
      if (!monitor.didDrop()) {
        switch (monitor.getItemType()) {
          case DndTypes.LINK: {
            const link = item as LinkDragObject;
            addFavoriteLink({ link: { href: link.href, name: link.text } });
            break;
          }
          case DndTypes.FAVORITE_LINK: {
            const favoriteLink = item as FavoriteLinkDragObject;
            moveFavoriteLink({
              target: { link: favoriteLink.id, folder: favoriteLink.folderId },
              position: {},
            });
            break;
          }
          case DndTypes.FAVORITE_LINKS_FOLDER: {
            const favoriteLinksFolderItem =
              item as FavoriteLinksFolderDragObject;

            moveFavoriteLinksFolder({
              id: favoriteLinksFolderItem.id,
              position: {},
            });

            break;
          }
          case DndTypes.LINKS_FOLDER: {
            const typedItem = item as LinksFolderDragObject;

            addFavoriteLinksFolder({
              name: typedItem.text,
              links: typedItem.links,
            });
            break;
          }
        }
      }
    },
    collect: (monitor) => {
      const itemType = monitor.getItemType();

      return {
        isDragging:
          itemType === DndTypes.LINK ||
          itemType === DndTypes.LINKS_FOLDER ||
          itemType === DndTypes.FAVORITE_LINK ||
          itemType === DndTypes.FAVORITE_LINKS_FOLDER,
      };
    },
  });

  const [start, startDrop] = useDrop({
    accept: [
      DndTypes.LINK,
      DndTypes.LINKS_FOLDER,
      DndTypes.FAVORITE_LINK,
      DndTypes.FAVORITE_LINKS_FOLDER,
    ],
    drop: (item: unknown, monitor) => {
      switch (monitor.getItemType()) {
        case DndTypes.LINK: {
          const link = item as LinkDragObject;
          addFavoriteLink({
            link: { href: link.href, name: link.text },
            position: { index: 0 },
          });
          break;
        }
        case DndTypes.FAVORITE_LINK: {
          const favoriteLink = item as FavoriteLinkDragObject;
          moveFavoriteLink({
            target: { link: favoriteLink.id, folder: favoriteLink.folderId },
            position: { index: 0 },
          });
          break;
        }
        case DndTypes.FAVORITE_LINKS_FOLDER: {
          const favoriteLinksFolderItem = item as FavoriteLinksFolderDragObject;

          moveFavoriteLinksFolder({
            id: favoriteLinksFolderItem.id,
            position: { index: 0 },
          });

          break;
        }
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const isEmpty = favoriteLinks.length === 0;

  const overflowList = useMemo(
    () => (
      <OverflowList
        className={styles.overflowList}
        alwaysRenderOverflow
        items={favoriteLinks}
        visibleItemRenderer={(item, index) =>
          item.kind === 'link' ? (
            <FavoriteLink
              key={index}
              link={item}
              active={activeLink?.linkId === item.id}
            />
          ) : (
            <FavoriteLinksFolder
              key={index}
              folder={item}
              activeLink={activeLink}
            />
          )
        }
        overflowRenderer={(items) => (
          <OverflowListPopover
            offset={favoriteLinks.length - items.length}
            items={items}
            activeLink={activeLink}
          />
        )}
      />
    ),
    [activeLink, favoriteLinks]
  );

  return (
    <ContextMenu
      className={styles.favoriteLinksRoot}
      content={
        <MenuList className={styles.menuList}>
          <MenuItem
            className={clsx(styles.menuItem, DISMISS_POPOVER)}
            onClick={onCreateNewFolder}
          >
            {t('FAV_LINKS.CREATE_FOLDER')}
          </MenuItem>
        </MenuList>
      }
    >
      <Divider
        className={clsx(styles.divider, { [styles.hidden]: isEmpty })}
        orientation="vertical"
      />

      <div
        ref={drop}
        className={clsx(styles.linksWrapper, {
          [styles.dndActive]: isDragging,
        })}
      >
        <div
          ref={startDrop}
          className={clsx(
            styles.dropTarget,
            styles.start,
            start.isOver && styles.dndActive
          )}
        />
        {overflowList}
      </div>
    </ContextMenu>
  );
}
