import React, { lazy } from 'react';

type NamedExports<T> = { [P in keyof T]: T[P] };
type ComponentExports<T> = {
  [P in keyof T]: T[P] extends  // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | React.ComponentType<any>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | React.ExoticComponent<any>
    ? P
    : never;
};
type ComponentExportNames<T> = ComponentExports<T>[keyof ComponentExports<T>];

export function lazyNamed<
  T extends NamedExports<T>,
  E extends ComponentExportNames<T>
>(factory: () => Promise<T>, exportName: E): React.LazyExoticComponent<T[E]> {
  return lazy(async () => {
    const module = await factory();
    return { default: module[exportName] };
  });
}
