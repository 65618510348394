import { createContext } from 'react';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export interface EntityHistoryContextValue {
  entity: Entities;
}

export const EntityHistoryContext =
  createContext<EntityHistoryContextValue>(null);
