import styles from '../EntityPicker.module.scss';

import Typography from '@mui/material/Typography';
import { useMemo } from 'react';

import { Contract } from '@work4all/models/lib/Classes/Contract.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type IContractPickerProps<TMultiple extends boolean> = IPickerProps<
  Contract,
  TMultiple
>;

export function ContractPicker<TMultiple extends boolean>(
  props: IContractPickerProps<TMultiple>
) {
  const { data, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...CONTRACT_FIELDS, ...data };
  }, [data]);

  return (
    <ListEntityPicker
      entity={Entities.contract}
      data={allFields}
      filterBy={['note', 'contractNumber', 'number']}
      sortBy="number"
      {...rest}
      renderItemContent={(contract) => (
        <Tooltip title={`${contract.contractNumber} ${contract.note}`}>
          <Typography variant="body2" noWrap>
            <span className={styles.secondaryInfo}>
              {contract.contractNumber} |{' '}
            </span>
            {contract.note}
          </Typography>
        </Tooltip>
      )}
    />
  );
}

const CONTRACT_FIELDS: Contract<EMode.query> = {
  id: null,
  number: null,
  contractNumber: null,
  note: null,
  positionList: [
    {
      id: null,
      index: null,
      number: null,
      shortText: null,
      longtext: null,
      positionKind: null,
      amount: null,
      unit: null,
      article: {
        id: null,
        name: null,
        notdeductable: null,
        isActivity: null,
      },
    },
  ],
  businessPartnerId: null,
  businessPartnerType: null,
  businessPartnerContactCombined: {
    businessPartner: {
      id: null,
      data: {
        customer: {
          id: null,
          name: null,
          number: null,
          website: null,
        },
      },
    },
  },
  project: {
    id: null,
    name: null,
    number: null,
  },
  projectProcess: {
    id: null,
    process: null,
  },
};
