import styles from './EditWrap.module.scss';

import { Edit } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

interface Props {
  onEditModeToggle: (value: boolean) => void;
  children: React.ReactNode;
  editMode: boolean;
  disabled?: boolean;
}

export const EditWrap = (props: Props) => {
  const { children, onEditModeToggle, disabled = false, editMode } = props;

  const { t } = useTranslation();

  return (
    <Box className={clsx(styles.editWrap, { [styles.disabled]: disabled })}>
      <Box
        onClick={() => {
          onEditModeToggle(!editMode);
        }}
        className={clsx(styles.iconContainer, { [styles.active]: editMode })}
      >
        {editMode ? (
          <Typography color="primary">{t('COMMON.OK')}</Typography>
        ) : (
          <Edit color="primary" />
        )}
      </Box>

      {children}
    </Box>
  );
};
