import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMailAttachToEntityType } from '../Enums/EMailAttachToEntityType.enum';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { JobState } from '../Enums/JobState.enum';
import { SaveSendMailJobMode } from '../Enums/SaveSendMailJobMode.enum';

import { EMail } from './EMail.entity';
import { ValidationMessage } from './ValidationMessage.entity';

export class SaveSendMailJob<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: mailData */
  mailData?: string;
  /** Alias: jobAction */
  jobAction?: SaveSendMailJobMode;
  /** Alias: mailServiceId */
  mailServiceId?: string;
  /** Alias: senderMailAddress */
  senderMailAddress?: string;
  /** Alias: jobState */
  jobState?: JobState;
  /** Alias: jobStateDateTime */
  jobStateDateTime?: string;
  /** Alias: errorMessages */
  errorMessages?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: errors */
  errors?: ValidationMessage<T>[];
  /** Alias: createdMail */
  createdMail?: EMail<T>;
  /** Alias: attachEntityCode */
  attachEntityCode?: number;
  /** Alias: attachEntityId */
  attachEntityId?: string;
  /** Alias: attachEntityType */
  attachEntityType?: EMailAttachToEntityType;
  /** Alias: apiInstance */
  apiInstance?: string;

  __typename?: string;
}

const fields: FieldDefinitions<SaveSendMailJob> = {
  id: {
    alias: 'id',
  },
  userId: {
    alias: 'benutzerCode',
  },
  mailData: {
    alias: 'mailData',
  },
  jobAction: {
    alias: 'jobAction',
  },
  mailServiceId: {
    alias: 'mailServiceId',
  },
  senderMailAddress: {
    alias: 'senderMailAddress',
  },
  jobState: {
    alias: 'jobState',
  },
  jobStateDateTime: {
    alias: 'jobStateDateTime',
  },
  errorMessages: {
    alias: 'errorMessages',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  errors: {
    alias: 'errors',
    entity: Entities.validationMessage,
  },
  createdMail: {
    alias: 'createdMail',
    entity: Entities.eMail,
  },
  attachEntityCode: {
    alias: 'attachEntityCode',
  },
  attachEntityId: {
    alias: 'attachEntityId',
  },
  attachEntityType: {
    alias: 'attachEntityType',
  },
  apiInstance: {
    alias: 'apiInstance',
  },
  __typename: {
    alias: '__typename',
  },
};

export const saveSendMailJobEntityDefinition: EntitiyDefinition<SaveSendMailJob> =
  {
    local: {},
    remote: {
      queryName: 'getSaveSendMailJobs',
      fragmentName: 'SaveSendMailJob',
      withPaginationWrapper: false,
      mutations: {
        upsert: {
          mutationName: 'startJobSaveSendMail',
          args: [
            { name: 'jobAction', type: 'SaveSendMailJobMode!' },
            { name: 'mail', type: 'InputEMail!' },
            { name: 'mailRelation', type: 'InputEMailRelation' },
            { name: 'mailServiceId', type: 'ID' },
            { name: 'senderMailAddress', type: 'String' },
            { name: 'attachToEntityType', type: 'EMailAttachToEntityType' },
            { name: 'attachToEntityPK', type: 'PrimaryKey' },
          ],
        },
      },
      params: [{ name: 'filter', type: 'String' }],
    },
    fieldDefinitions: fields,
  };
