import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ConvertIcon } from '@work4all/assets/icons/convert.svg';

import { Dialog } from '@work4all/components';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { LockOverride } from '../../../containers/mask-overlays/mask-overlay/components/LockOverride';
import { getRequiredEntity } from '../../../containers/mask-overlays/mask-overlay/views/erp/getRequiredEntity';
import { PartnerPickerByEntity } from '../../entity-picker/PartnerPickerByEntity';

interface ErpDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (bp: Supplier | Customer) => void;
  sourceEntity: Entities;
  targetEntity: Entities;
  title: string;
  body: string;
  businessPartner?: Supplier | Customer;
}

const ErpDialog = (props: ErpDialogProps) => {
  const {
    open,
    onClose,
    onConfirm,
    targetEntity,
    title,
    body,
    businessPartner,
  } = props;
  const { t } = useTranslation();

  const [bp, setBp] = useState(businessPartner);
  const bpEntity = getRequiredEntity(targetEntity);

  return (
    <Dialog open={open} title={title} onClose={onClose} icon={<ConvertIcon />}>
      <Box padding="0 1rem 1rem;">
        <Typography paddingBottom="1rem" variant="body1">
          {body}
        </Typography>
        {/* TODO: enable when api ready to pass bp to clone / convert (remove LockOverride) */}
        <LockOverride forceLock>
          <PartnerPickerByEntity
            entity={bpEntity}
            businessPartner={bp}
            onChange={(array) => {
              setBp(array[0]);
            }}
          />
        </LockOverride>
        <Box display="flex" justifyContent="flex-end" paddingTop="1rem">
          <Button onClick={onClose}>{t('ALERTS.BTN_ABORT')}</Button>
          <Button
            // TODO: enable when api ready to pass bp to clone / convert
            // disabled={!bp}
            onClick={() => onConfirm(bp)}
          >
            {t('ALERTS.BTN_APPLY')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export const CopyErpDialog = (
  props: Omit<ErpDialogProps, 'title' | 'body'>
) => {
  const { sourceEntity } = props;
  const { t } = useTranslation();

  return (
    <ErpDialog
      {...props}
      title={`${t(`COMMON.${sourceEntity.toUpperCase()}`)} ${t('COMMON.COPY')}`}
      body={t('COMMON.COPY_ERP', {
        bz: t(`COMMON.${sourceEntity.toUpperCase()}`),
      })}
    />
  );
};

export const ConvertErpDialog = (
  props: Omit<ErpDialogProps, 'title' | 'body'>
) => {
  const { sourceEntity, targetEntity } = props;
  const { t } = useTranslation();
  return (
    <ErpDialog
      {...props}
      title={t('COMMON.CONVERT_ERP_TITLE', {
        source: t(`COMMON.${sourceEntity.toUpperCase()}`),
        target: t(`COMMON.${targetEntity.toUpperCase()}`),
      })}
      body={t('COMMON.CONVERT_ERP', {
        bz: t(`COMMON.${targetEntity.toUpperCase()}`),
      })}
    />
  );
};
