import styles from './MaskActions.module.scss';

import { useBottomOverlayHeight } from '../../../../../hooks';

export interface IMaskActionsProps {
  children?: React.ReactNode;
}

export function MaskActions(props: IMaskActionsProps) {
  const { children } = props;
  const bottomOverlayHeight = useBottomOverlayHeight(); //this is the softkeyboard if toggled

  return (
    <div
      className={styles.root}
      style={{
        paddingBottom: bottomOverlayHeight
          ? `${bottomOverlayHeight}px`
          : undefined,
      }}
    >
      <div className={styles.buttons}>{children}</div>
    </div>
  );
}
