import { useTranslation } from 'react-i18next';

import { EMailKind } from '@work4all/models/lib/Enums/EMailKind.enum';

import { FixedDataPicker } from '../fixed-data-picker/FixedDataPicker';
import { IPickerProps } from '../types';

export const EmailKindChangeLookUp = {
  [EMailKind.AUSGEHEND]: [EMailKind.AUSGEHEND],
  [EMailKind.EINGEHEND]: [EMailKind.EINGEHEND, EMailKind.EINGEHEND_HTML],
  [EMailKind.ENTWURF]: [EMailKind.ENTWURF, EMailKind.ENTWURF_HTML],
};

export type IBaseDataTaxPickerProps<TMultiple extends boolean> = IPickerProps<
  { id: string; name: string },
  TMultiple
>;

export function EmailKindPicker<TMultiple extends boolean>(
  props: IBaseDataTaxPickerProps<TMultiple>
) {
  const { onChange, ...rest } = props;

  const { t } = useTranslation();

  const dataSet = [
    {
      id: EMailKind.AUSGEHEND,
      name: t('FIELDS.values.kind.' + EMailKind.AUSGEHEND),
    },
    {
      id: EMailKind.EINGEHEND,
      name: t('FIELDS.values.kind.' + EMailKind.EINGEHEND),
    },
    {
      id: EMailKind.ENTWURF,
      name: t('FIELDS.values.kind.' + EMailKind.ENTWURF),
    },
  ];

  const enrichedDataSet = [
    ...dataSet,
    {
      id: EMailKind.EINGEHEND_HTML,
      name: t('FIELDS.values.kind.' + EMailKind.EINGEHEND_HTML),
    },

    {
      id: EMailKind.ENTWURF_HTML,
      name: t('FIELDS.values.kind.' + EMailKind.ENTWURF_HTML),
    },
    {
      id: EMailKind.UNBEKANNT,
      name: t('FIELDS.values.kind.' + EMailKind.UNBEKANNT),
    },
  ];
  return (
    <FixedDataPicker
      dataSet={dataSet}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(value: any) => {
        let result = value;
        if (Array.isArray(value)) {
          result = value
            .flatMap((x) => {
              return EmailKindChangeLookUp[x.id];
            })
            .filter((x) => x)
            .map((x) => {
              return enrichedDataSet.find((y) => y.id === x);
            });
        }
        onChange(result);
      }}
      {...rest}
    />
  );
}
