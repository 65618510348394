import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class InputModifyAddSupplierDemandAssign<
  T extends EMode = EMode.entity
> {
  /** Alias: supplierCode */
  supplierCode?: number;
  /** Alias: contactCode */
  contactCode?: number;

  __typename?: string;
}

const fields: FieldDefinitions<InputModifyAddSupplierDemandAssign> = {
  supplierCode: {
    alias: 'supplierCode',
  },
  contactCode: {
    alias: 'contactCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputModifyAddSupplierDemandAssignEntityDefinition: EntitiyDefinition<InputModifyAddSupplierDemandAssign> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputModifyAddSupplierDemandAssign',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
