import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';

import { ControllerPlus } from '../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../form-plus/use-form-context-plus';
import { CheckboxRadioItem } from '../../../../locked-inputs';
import { WithCustomFields } from '../with-custom-fields';

import { CustomFieldProps } from './types';

const DEFAULT_OPTIONS = [];

export function CustomRadioGroupField(props: CustomFieldProps) {
  const { name, label, options = DEFAULT_OPTIONS } = props;

  const { control } = useFormContextPlus<WithCustomFields<object>>();

  return (
    <ControllerPlus
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <FormControl>
            <FormLabel>{label}</FormLabel>
            <RadioGroup {...field}>
              {options.map((option) => {
                return (
                  <CheckboxRadioItem
                    key={option}
                    control="radio"
                    label={option}
                    value={option}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        );
      }}
    />
  );
}
