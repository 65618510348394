import styles from './LogoPlaceholder.module.scss';

import { Box, Typography } from '@mui/material';

interface Props {
  title?: string;
}

export const LogoPlaceholder: React.VFC<Props> = (props) => {
  const { title = 'LOGO' } = props;
  return (
    <Box className={styles.wrapper}>
      <Box className={styles.iconWrapper}>{title.charAt(0).toUpperCase()}</Box>
      <Typography className={styles.logoFont}>{title}</Typography>
    </Box>
  );
};
