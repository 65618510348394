import React from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  CurrencyPicker,
  ICurrencyPickerProps,
} from '@work4all/components/lib/components/entity-picker/CurrencyPicker';

import { formatCurrency, reactRefSetter } from '@work4all/utils';

import { LabeledInput } from '../../containers/mask-overlays/locked-inputs';

interface Props extends Omit<ICurrencyPickerProps<false>, 'multiple'> {
  error?: string;
}

export const CurrencyPickerField = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { t } = useTranslation();
    const currencyWrapperRef = useRef();
    const { error, clearable, ...pickerProps } = props;
    const { value, onChange } = pickerProps;

    return (
      <div ref={reactRefSetter(currencyWrapperRef, ref)}>
        <EntityPickerPopover
          anchorEl={currencyWrapperRef}
          picker={<CurrencyPicker {...pickerProps} multiple={false} />}
        >
          <LabeledInput
            onClear={
              clearable
                ? () => {
                    onChange(null);
                  }
                : undefined
            }
            label={t('INPUTS.CURRENCY')}
            error={error}
            value={(value && formatCurrency(value, t)) || ''}
          />
        </EntityPickerPopover>
      </div>
    );
  }
);
