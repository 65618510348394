import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputStammdatenPostItDetails } from './InputStammdatenPostItDetails.entity';

export class InputStammdatenPostIt<T extends EMode = EMode.entity> {
  /** Alias: set */
  set?: InputStammdatenPostItDetails<T>;
  /** Alias: delete */
  delete?: boolean;

  __typename?: string;
}

const fields: FieldDefinitions<InputStammdatenPostIt> = {
  set: {
    alias: 'set',
  },
  delete: {
    alias: 'delete',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputStammdatenPostItEntityDefinition: EntitiyDefinition<InputStammdatenPostIt> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputStammdatenPostIt',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
