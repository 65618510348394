import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { User } from './User.entity';

export class ProjectPlanningFeedback<T extends EMode = EMode.entity> {
  /** Alias: abarbeitungsgradProzent */
  processDegree?: number;
  /** Alias: benutzer */
  user?: User<T>;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: code */
  id?: number;
  /** Alias: datum */
  date?: string;
  /** Alias: vorgangCode */
  projectProcessId?: number;

  __typename?: string;
}

const fields: FieldDefinitions<ProjectPlanningFeedback> = {
  processDegree: {
    alias: 'abarbeitungsgradProzent',
  },
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  userId: {
    alias: 'benutzerCode',
  },
  id: {
    alias: 'code',
  },
  date: {
    alias: 'datum',
  },
  projectProcessId: {
    alias: 'vorgangCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const projectPlanningFeedbackEntityDefinition: EntitiyDefinition<ProjectPlanningFeedback> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'ProjektePlanungRueckmeldung',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
