import styles from './MaskContent.module.scss';

import { useMaskContext } from '../../hooks/mask-context';

export interface MaskContentProps {
  children?: React.ReactNode;
}

export function MaskContent(props: MaskContentProps) {
  const { children } = props;

  const ctx = useMaskContext();

  return (
    <div
      className={styles.root}
      id="settingsWrapper"
      data-test-id={`settingsWrapper-${ctx.entity}`}
    >
      <div className={ctx.useScroll ? styles.scroll : styles.disableScroll}>
        {children}
      </div>
    </div>
  );
}
