import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
} from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { usePrepareFilters } from '@work4all/components';
import { CardWidget } from '@work4all/components/lib/dataDisplay/card-widget';

import { useDataProvider, useNavigate } from '@work4all/data';
import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';
import { DOCUMENT_FILTER_EXCLUDE_CHILD_DOCUMENTS } from '@work4all/data/lib/prefilters/document';

import { DataRequest, SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { stringifyTableSettings } from '../../../../components/data-tables/make-table-settings';

import {
  widgetColsByEntity,
  widgetDataByEntity,
} from './default-entity-widget-data';
import { HomeWidget } from './HomeWidgetsContextProvider';

const ENTITY_DEFAULT_FILTER = {
  [Entities.appointment]: [{ parentId: { $eq: 0 } }],
  [Entities.document]: [DOCUMENT_FILTER_EXCLUDE_CHILD_DOCUMENTS],
};

const ENTITY_DEFAULT_PRESET_FIELDS_FILTERS = {
  [Entities.document]: [{ infoWindowName: { $eq: '' } }],
};

export const EntityWidget = (
  props: HomeWidget & {
    onDeleteClick?: () => void;
    onEditClick?: () => void;
    pageSize?: number;
    onCollapsedChange?: (value: boolean) => void;
    collapsible?: boolean;
    collapsed?: boolean;
  }
) => {
  const { t } = useTranslation();

  const {
    pageSize,
    definition,
    onEditClick,
    onDeleteClick,
    title,
    collapsed,
    onCollapsedChange,
  } = props;

  const parsedPresetFields = JSON.parse(definition?.presetFields ?? '{}');
  const searchParams = useMemo(() => {
    const searchParams = new URLSearchParams();

    if (definition?.presetFields) {
      searchParams.set('presetFields', definition?.presetFields);
    }

    if (definition?.customizedTitle) {
      searchParams.set('customizedTitle', definition?.customizedTitle);
    }

    searchParams.set(
      'settings',
      stringifyTableSettings({
        filter:
          definition?.filters?.map((filter) => {
            return {
              ...filter,
              value: filter?.value?.value,
            };
          }) || [],
      })
    );

    return searchParams;
  }, [
    definition?.customizedTitle,
    definition?.filters,
    definition?.presetFields,
  ]);

  const preparedFilters = usePrepareFilters(definition?.filters);

  const requestData = useMemo<DataRequest>(() => {
    const presetFieldsFilters = Object.keys(parsedPresetFields).map((key) => {
      return { [key]: { $eq: parsedPresetFields[key] } };
    });
    const filter = [
      ...(preparedFilters.filter ?? []),
      ...(ENTITY_DEFAULT_FILTER[definition.entity] ?? []),
      ...(presetFieldsFilters.length > 0
        ? presetFieldsFilters
        : ENTITY_DEFAULT_PRESET_FIELDS_FILTERS[definition.entity] ?? []),
    ];

    return {
      filter,
      entity: definition?.entity,
      data: { id: null, ...widgetDataByEntity[definition.entity] },
      sort: [
        {
          direction: SortDirection.DESCENDING,
          field: widgetColsByEntity[definition.entity]?.[0].field,
        },
      ],
    };
  }, [parsedPresetFields, preparedFilters.filter, definition.entity]);

  const { data, total } = useDataProvider(requestData, false, pageSize);

  const { handleClick, ...popoverState } = usePopoverState('bottom');

  const navigate = useNavigate();

  const listHrefEntitiy = useMemo(() => {
    if (
      definition?.entity === Entities.document &&
      parsedPresetFields?.infoWindowName
    ) {
      return `${Entities.document}(${parsedPresetFields?.infoWindowName})`;
    }

    return definition?.entity;
  }, [definition?.entity, parsedPresetFields?.infoWindowName]);

  return (
    <>
      <Popover
        {...popoverState}
        transformOrigin={{ vertical: 'center', horizontal: 'right' }}
      >
        <List disablePadding>
          {onEditClick && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  onEditClick();
                  popoverState.onClose();
                }}
              >
                <ListItemText primary={t('SETTINGS.SETTINGS')} />
              </ListItemButton>
            </ListItem>
          )}
          {onDeleteClick && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  onDeleteClick();
                  popoverState.onClose();
                }}
              >
                <ListItemText primary={t('COMMON.REMOVE')} />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Popover>
      <CardWidget
        entity={definition.entity}
        canAdd={true}
        columns={widgetColsByEntity[definition.entity]}
        getItemHref={() => ''}
        listHref={`/more/entity/${listHrefEntitiy}?${searchParams.toString()}`}
        maxRows={total}
        newHref={`/more/entity/${definition?.entity}/details/new`}
        items={data}
        title={title || t(`COMMON.${definition?.entity.toUpperCase()}_plural`)}
        totalCount={total}
        collapsible={true}
        collapsed={collapsed}
        onItemClick={(item) => {
          navigate(`/more/entity/${definition?.entity}/details/${item.id}`);
        }}
        onCollapsedChange={onCollapsedChange}
        additionalActions={
          <IconButton size="medium" onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
        }
      />
    </>
  );
};
