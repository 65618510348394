// TODO Remove this workaround when the "groupBy" query is updated to properly
// handle aliases.

// The API does not accept actual property names. Sometimes it returns aliases
// that are different from the ones sent in the request, and sometimes just
// crashes if there are certain characters in the alias.

// These are simple utilities to simple aphanumeric aliases using only allowed
// characters and map them back and forth.

const aliasByName = new Map<string, string>();
const nameByAlias = new Map<string, string>();

const PREFIX = 'a';

let nextId = 1;

export function encodeAlias(name: string): string {
  let alias = aliasByName.get(name);

  if (alias === undefined) {
    const id = nextId++;

    alias = PREFIX + id.toString(36);

    aliasByName.set(name, alias);
    nameByAlias.set(alias, name);
  }

  return alias;
}

export function decodeAlias(alias: string): string {
  const name = nameByAlias.get(alias);

  if (name === undefined) {
    throw new Error(
      `Can't decode alias "${alias}". Alias not found in the cache.`
    );
  }

  return name;
}
