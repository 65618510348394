import styles from './Heading1.module.scss';

import { Typography } from '@mui/material';
import React from 'react';

import { replaceClassName } from '@work4all/utils/lib/replaceClassName';

import { ITypographyProps } from '..';

export const Heading1: React.FC<ITypographyProps> = (props) => {
  return (
    <Typography
      className={replaceClassName(styles.heading1, props.className)}
      variant="h1"
    >
      {props.children}
    </Typography>
  );
};
