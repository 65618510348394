import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { BzObjType } from '../Enums/BzObjType.enum';
import { EMode } from '../Enums/EMode.enum';
import { ErpPositionSlMode } from '../Enums/ErpPositionSlMode.enum';

export class SlModeSetting<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: itemCode */
  itemId?: number;
  /** Alias: bzObjType */
  bzObjType?: BzObjType;
  /** Alias: slMode */
  slMode?: ErpPositionSlMode;

  __typename?: string;
}

const fields: FieldDefinitions<SlModeSetting> = {
  id: {
    alias: 'code',
  },
  itemId: {
    alias: 'itemCode',
  },
  bzObjType: {
    alias: 'bzObjType',
  },
  slMode: {
    alias: 'slMode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const slModeSettingEntityDefinition: EntitiyDefinition<SlModeSetting> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'SlModeSetting',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
