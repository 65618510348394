import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { BusinessPartner } from './BusinessPartner.entity';
import { Contact } from './Contact.entity';
import { FileEntity } from './FileEntity.entity';
import { Project } from './Project.entity';
import { TopicSimple } from './TopicSimple.entity';
import { User } from './User.entity';

export class Letter<T extends EMode = EMode.entity> {
  /** Alias: ansprechpartner */
  contact?: Contact<T>;
  /** Alias: ansprechpartnerCode */
  contactId?: number;
  /** Alias: art */
  kind?: number;
  /** Alias: benutzer */
  user?: User<T>;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: blobFileType */
  blobFileType?: number;
  /** Alias: briefdatei */
  letterFile?: string;
  /** Alias: code */
  id?: number;
  /** Alias: dateiPfad */
  filePath?: string;
  /** Alias: datum */
  date?: string;
  /** Alias: ersteller */
  creator?: User<T>;
  /** Alias: erstellerBenutzerCode */
  creatorUserId?: number;
  /** Alias: geschaeftspartner */
  businessPartner?: BusinessPartner<T>;
  /** Alias: gesperrt */
  locked?: boolean;
  /** Alias: inhalt */
  content?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: lastModificationDate */
  lastModificationDate?: string;
  /** Alias: lookupCode */
  lookupId?: number;
  /** Alias: notiz */
  note?: string;
  /** Alias: objGrCode */
  objGrId?: number;
  /** Alias: projekt */
  project?: Project<T>;
  /** Alias: projektCode */
  projectId?: number;
  /** Alias: sdObjMemberCode */
  businessPartnerId?: number;
  /** Alias: sdObjType */
  businessPartnerType?: SdObjType;
  /** Alias: status1 */
  status1?: boolean;
  /** Alias: status2 */
  status2?: boolean;
  /** Alias: status3 */
  status3?: boolean;
  /** Alias: status4 */
  status4?: boolean;
  /** Alias: status5 */
  status5?: boolean;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: fileInfos */
  fileInfos?: FileEntity<T>;
  /** Alias: themenZuordnungen */
  topicMarkList?: TopicSimple<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<Letter> = {
  contact: {
    alias: 'ansprechpartner',
    entity: Entities.contact,
  },
  contactId: {
    alias: 'ansprechpartnerCode',
  },
  kind: {
    alias: 'art',
  },
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  userId: {
    alias: 'benutzerCode',
  },
  blobFileType: {
    alias: 'blobFileType',
  },
  letterFile: {
    alias: 'briefdatei',
  },
  id: {
    alias: 'code',
  },
  filePath: {
    alias: 'dateiPfad',
  },
  date: {
    alias: 'datum',
  },
  creator: {
    alias: 'ersteller',
    entity: Entities.user,
  },
  creatorUserId: {
    alias: 'erstellerBenutzerCode',
  },
  businessPartner: {
    alias: 'geschaeftspartner',
    entity: Entities.businessPartner,
  },
  locked: {
    alias: 'gesperrt',
  },
  content: {
    alias: 'inhalt',
  },
  insertTime: {
    alias: 'insertTime',
  },
  lastModificationDate: {
    alias: 'lastModificationDate',
  },
  lookupId: {
    alias: 'lookupCode',
  },
  note: {
    alias: 'notiz',
  },
  objGrId: {
    alias: 'objGrCode',
  },
  project: {
    alias: 'projekt',
    entity: Entities.project,
  },
  projectId: {
    alias: 'projektCode',
  },
  businessPartnerId: {
    alias: 'sdObjMemberCode',
  },
  businessPartnerType: {
    alias: 'sdObjType',
  },
  status1: {
    alias: 'status1',
  },
  status2: {
    alias: 'status2',
  },
  status3: {
    alias: 'status3',
  },
  status4: {
    alias: 'status4',
  },
  status5: {
    alias: 'status5',
  },
  updateTime: {
    alias: 'updateTime',
  },
  fileInfos: {
    alias: 'fileInfos',
    entity: Entities.fileEntity,
  },
  topicMarkList: {
    alias: 'themenZuordnungen',
    entity: Entities.topicSimple,
  },
  __typename: {
    alias: '__typename',
  },
};

export const letterEntityDefinition: EntitiyDefinition<Letter> = {
  local: {},
  remote: {
    queryName: 'getBriefe',
    fragmentName: 'Brief',
    withPaginationWrapper: true,
    mutations: {
      upsert: {
        mutationName: 'upsertBrief',
        args: [
          { name: 'input', type: 'InputBrief!' },
          { name: 'relations', type: 'InputBriefRelation' },
          { name: 'drySave', type: 'Boolean' },
        ],
      },
    },
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
