import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { SettingType } from '../Enums/SettingType.enum';

export class Setting<T extends EMode = EMode.entity> {
  /** Alias: name */
  name?: string;
  /** Alias: settingType */
  settingType?: SettingType;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: value */
  value?: string;

  __typename?: string;
}

const fields: FieldDefinitions<Setting> = {
  name: {
    alias: 'name',
  },
  settingType: {
    alias: 'settingType',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  value: {
    alias: 'value',
  },
  __typename: {
    alias: '__typename',
  },
};

export const settingEntityDefinition: EntitiyDefinition<Setting> = {
  local: {},
  remote: {
    queryName: 'getSettings',
    fragmentName: 'Setting',
    withPaginationWrapper: false,
    params: [
      { name: 'syncDate', type: 'DateTime' },
      { name: 'filterPrefix', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
