import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputBankAccountRelation } from './InputBankAccountRelation.entity';
import { InputCategoryMarkRelation } from './InputCategoryMarkRelation.entity';
import { InputCompanyDefaultErpText } from './InputCompanyDefaultErpText.entity';
import { InputStammdatenAttachementsRelation } from './InputStammdatenAttachementsRelation.entity';
import { InputStammdatenPostIt } from './InputStammdatenPostIt.entity';

export class InputKundeRelation<T extends EMode = EMode.entity> {
  /** Alias: categoryMarks */
  categoryMarks?: InputCategoryMarkRelation<T>;
  /** Alias: bankAccounts */
  bankAccounts?: InputBankAccountRelation<T>;
  /** Alias: attachements */
  attachements?: InputStammdatenAttachementsRelation<T>;
  /** Alias: postIt */
  postIt?: InputStammdatenPostIt<T>;
  /** Alias: defaultErpTexts */
  defaultErpTexts?: InputCompanyDefaultErpText<T>;

  __typename?: string;
}

const fields: FieldDefinitions<InputKundeRelation> = {
  categoryMarks: {
    alias: 'categoryMarks',
  },
  bankAccounts: {
    alias: 'bankAccounts',
  },
  attachements: {
    alias: 'attachements',
  },
  postIt: {
    alias: 'postIt',
  },
  defaultErpTexts: {
    alias: 'defaultErpTexts',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputKundeRelationEntityDefinition: EntitiyDefinition<InputKundeRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputKundeRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
