import styles from './HorizontalSelect.module.scss';

import { IconButton, Tooltip } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

interface HorizontalSelectItemProps<T> {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  value: T;
  title?: string;
}

interface HorizontalSelectProps<T> {
  value?: T;
  onClick?: (value: T) => void;
  items: HorizontalSelectItemProps<T>[];
}

export function HorizontalSelect<T>(props: HorizontalSelectProps<T>) {
  const { value, onClick, items } = props;

  const options = items.map((x) => (
    <Tooltip key={`${x.value}`} title={x.title ? x.title : ''} placement="top">
      <IconButton key={`${x.value}`} onClick={() => onClick(x.value)}>
        <x.Icon
          className={clsx(styles['horizontal-select__icon'], {
            [styles['horizontal-select__icon--selected']]: value === x.value,
          })}
        />
      </IconButton>
    </Tooltip>
  ));

  const selectedIndex = items.findIndex((x) => x.value === value);

  return (
    <div
      className={clsx(
        styles['horizontal-select'],
        styles[`horizontal-select--selected-${selectedIndex}`]
      )}
    >
      {options}
    </div>
  );
}
