import { useCallback, useState } from 'react';
import { Row } from 'react-table';

export const useRowSelection = () => {
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const onSelectedRowsChange = useCallback((rows: Row[]) => {
    setSelectedRows(rows.map((row) => row.id));
  }, []);

  return { selectedRows, onSelectedRowsChange };
};
