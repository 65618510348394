import Typography from '@mui/material/Typography';
import { useEffect, useMemo, useState } from 'react';

import { IResponse, useDataProvider } from '@work4all/data';

import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';
import { TopicSimple } from '@work4all/models/lib/Classes/TopicSimple.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { TypeOfCrm } from '@work4all/models/lib/Enums/TypeOfCrm.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type ITopicPickerProps<TMultiple extends boolean> = IPickerProps<
  TopicSimple,
  TMultiple
> & { type: Entities };

const TOPIC_FIELDS: TopicSimple = {
  id: null,
  name: null,
};

export function TopicPicker<TMultiple extends boolean>(
  props: ITopicPickerProps<TMultiple>
) {
  const { data, ...rest } = props;

  const crmType = useMemo(() => {
    switch (props.type) {
      case Entities.note:
        return TypeOfCrm.NOTIZ;
      case Entities.callMemo:
        return TypeOfCrm.TELEFONAT;
      case Entities.task:
        return TypeOfCrm.WHATTODO;
      case Entities.appointment:
        return TypeOfCrm.TERMIN;
      case Entities.eMail:
        return TypeOfCrm.EMAIL;
      case Entities.document:
        return TypeOfCrm.DOKUMENT;
      default:
        throw new Error(
          'topicPicker is missing a mapping for the requested topic type'
        );
    }
  }, [props.type]);

  const allFields = useMemo(() => {
    return { ...TOPIC_FIELDS, ...data };
  }, [data]);

  const [dataSet, setDataSet] = useState<IResponse<LookUp>>({
    total: 0,
    loading: false,
    pending: false,
    fetchMore: (startIndex: number, stopIndex: number) => Promise.resolve(),
    refetch: () => Promise.resolve(null),
    data: [],
  });

  const requestData = useMemo(() => {
    return {
      entity: Entities.topicSimple,
      data: allFields,
      completeDataResponse: true,
      vars: {
        typeOfCrm: crmType,
      },
    };
  }, [allFields, crmType]);

  const resp = useDataProvider(requestData);

  useEffect(() => {
    if (!resp.loading) {
      setDataSet({
        total: resp.data.length,
        loading: false,
        pending: false,
        fetchMore: (startIndex: number, stopIndex: number) => Promise.resolve(),
        refetch: () => Promise.resolve(null),
        data: resp.data,
      });
    }
  }, [resp]);

  return (
    <ListEntityPicker
      entity={Entities.topicSimple}
      multiple={true}
      data={allFields}
      filterBy="name"
      sortBy="name"
      {...rest}
      fixedDataSet={dataSet}
      renderItemContent={(topic) => (
        <Tooltip title={topic.name}>
          <Typography variant="body2" noWrap>
            {topic.name}
          </Typography>
        </Tooltip>
      )}
    />
  );
}
