import { ControllerRenderProps } from 'react-hook-form';

import { EMPTY_UID, isUUID } from '@work4all/data/lib/utils/empty-uid';

import { SalesOpportunityStatus } from '@work4all/models/lib/Enums/SalesOpportunityStatus.enum';

import { FormValue } from './SalesOpportunitiesEvaluationPicker';

export const getRatingValue = (
  field: ControllerRenderProps<FormValue, 'probabilityPercent'>,
  relField: ControllerRenderProps<FormValue, 'ratingStatus'>
) => {
  const value = relField?.value;

  if (value && field.value !== value?.ratingValue) {
    return null;
  }
  return value;
};

export const getValuesOnRatingStatusChange = (
  ratingStatusId: string,
  ratingValue: number,
  status?: SalesOpportunityStatus
) => {
  const ratingStatus = {
    ratingStatusId: isUUID(ratingStatusId) ? ratingStatusId : undefined,
    probabilityPercent: ratingValue,
  };
  if (status) {
    const { status: newStatus } = getValuesOnProbabilityChange(
      status,
      ratingValue
    );

    return {
      ...ratingStatus,
      status: newStatus,
    };
  }
  return ratingStatus;
};

export const roundPercent = (value: number) =>
  Math.max(0, Math.min(100, Math.round(value / 10.0) * 10));

export const getValuesOnProbabilityChange = (
  status: SalesOpportunityStatus,
  probabilityPercent: number | string
) => {
  const valueNumber =
    typeof probabilityPercent === 'string'
      ? parseInt(probabilityPercent)
      : probabilityPercent;

  const result = {
    probabilityPercent: Number.isNaN(valueNumber) ? 0 : valueNumber,
    status,
  };

  if (
    valueNumber === 0 &&
    status !== SalesOpportunityStatus.CONVERTED_TO_OFFER
  ) {
    result.status = SalesOpportunityStatus.LOST;
  } else if (valueNumber !== 0 && status === SalesOpportunityStatus.LOST) {
    result.status = SalesOpportunityStatus.ACTIVE;
  }
  return result;
};

export const getValuesOnStatusChange = (status: SalesOpportunityStatus) => {
  switch (status) {
    case SalesOpportunityStatus.LOST:
    case SalesOpportunityStatus.CONVERTED_TO_OFFER:
      return {
        probabilityPercent: 0,
        ratingStatusId: EMPTY_UID,
      };
    case SalesOpportunityStatus.CONVERTED_TO_INVOICE:
    case SalesOpportunityStatus.CONVERTED_TO_DELIVERY_NOTE:
    case SalesOpportunityStatus.CONVERTED_TO_ORDER:
      return {
        probabilityPercent: 100,
        ratingStatusId: EMPTY_UID,
      };
    default:
      break;
  }
};
