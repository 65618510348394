import styles from './EditableCell.module.scss';

import { Box, Input } from '@mui/material';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CellProps } from 'react-table';

import { Unit } from '../../../../../../input/labeled-currency-input/components/unit/Unit';
import { EditModeCell } from '../../../../plugins/useEditMode';
import { getTextHeight } from '../../../../utils/get-text-height/get-text-height';

export interface EditableCellNumericProps {
  min?: number;
  max?: number;
  step?: number;
  transform?: (input: number) => number;
  unit?: string;
}

interface EditableCellUserProps extends EditableCellNumericProps {
  onSubmit?: () => void;
  onExit?: () => void;
  onNext?: () => void;
  textarea?: boolean;
  type?: string;
}

interface EditableCellProps
  extends CellProps<Record<string, unknown>>,
    EditableCellUserProps {
  cell: EditModeCell;
}

export function EditableCell(props: EditableCellProps) {
  const initialValue = props.value;
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    if (props.type === 'date') {
      const transformed = DateTime.fromISO(initialValue).toFormat('yyyy-MM-dd');
      setValue(transformed);
    }
    if (props.type === 'time') {
      const transformed = DateTime.fromISO(initialValue).toFormat('HH:mm');
      setValue(transformed);
    } else {
      setValue(initialValue);
    }
  }, [initialValue, props.type]);

  const onChange = (event) => {
    if (props.type === 'number') {
      const numericValue = parseFloat(`${event.target.value}`);
      event.target.value = `${numericValue}`;
    }
    setValue(event.target.value);
  };

  const { autoFocus, onEdit } = props.cell.getEditableCellProps();

  const lastNumber = useRef<number>(parseFloat(initialValue));

  function tranformBeforeExit(value: string) {
    if (props.type === 'date') {
      return DateTime.fromFormat(value, 'yyyy-MM-dd');
    }
    if (props.type === 'time') {
      return DateTime.fromFormat(value, 'HH:mm');
    }
    if (props.type === 'number') {
      const resultValue = parseFloat(value);
      if (props.transform) {
        return props.transform(resultValue);
      }

      if (isNaN(resultValue)) {
        return lastNumber.current;
      }
      lastNumber.current = resultValue;
      return resultValue;
    }
    return value;
  }

  const [isFocused, setIsFocused] = useState(false);

  function onBlur() {
    const resultValue = tranformBeforeExit(value);
    setIsFocused(false);
    if (props.onNext) {
      onEdit(resultValue, 'update');
      return;
    }
    onEdit(resultValue, 'blur');
    props.onExit?.();
  }

  const [withShift, setWithShift] = useState(false);

  const onKeyDown = (event) => {
    const resultValue = tranformBeforeExit(value);
    switch (event.key) {
      case 'Shift':
        setWithShift(true);
        break;
      case 'Escape':
      case 'Enter':
        if (withShift) return;
        onEdit(resultValue, 'submit');
        props.onSubmit?.();
        break;
      case 'Tab':
        event.stopPropagation();
        onEdit(resultValue, 'update');
        props.onNext?.();
        break;
    }
  };

  const onKeyUp = (event) => {
    switch (event.key) {
      case 'Shift':
        setWithShift(false);
        break;
    }
  };
  const innerProps = {
    autoFocus,
    value,
    onChange,
    onBlur,
    onKeyDown,
    onKeyUp,
    type: props.type,
  };

  const inputRef = useRef<HTMLTextAreaElement>(null);

  const textFieldHeight = useMemo(() => {
    return getTextHeight({ text: innerProps.value });
  }, [innerProps.value]);

  return props.textarea ? (
    <Box
      className={clsx(styles.input, { [styles.focused]: isFocused })}
      style={{ height: `${textFieldHeight}px` }}
    >
      <textarea
        {...innerProps}
        ref={inputRef}
        className={styles.innerInput}
        style={{ height: `100%`, resize: 'none' }}
        onFocus={() => {
          inputRef?.current?.select();
          setIsFocused(true);
        }}
      />
    </Box>
  ) : (
    <Input
      {...innerProps}
      className={clsx(styles.input, { [styles.focused]: isFocused })}
      classes={{
        input: styles.innerInput,
      }}
      inputProps={{
        max: props.max,
        min: props.min,
        step: props.step,
      }}
      style={{
        height: '2rem',
      }}
      inputRef={inputRef}
      onFocus={() => {
        inputRef?.current?.select();
        setIsFocused(true);
      }}
      endAdornment={props.unit ? <Unit unit={'%'} /> : undefined}
    />
  );
}
