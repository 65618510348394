import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { DuplicateRequest } from '@work4all/models/lib/Classes/DuplicateRequest.entity';
import { DuplicateResponse } from '@work4all/models/lib/Classes/DuplicateResponse.entity';
import { DuplicateEntityType } from '@work4all/models/lib/Enums/DuplicateEntityType.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

const CLONE_ENTITY_MUTATION = gql`
  mutation DuplicateEntity($request: DuplicateRequest!) {
    duplicateEntity(request: $request) {
      errorMessage
    }
  }
`;

interface DuplicateVariables {
  request: DuplicateRequest;
}

type Entity =
  | Entities.note
  | Entities.appointment
  | Entities.task
  | Entities.callMemo;
const DUPLICATE_ENTITY_TYPE_BY_ENTITY: {
  [key in Entity]: DuplicateEntityType;
} = {
  [Entities.note]: DuplicateEntityType.NOTE,
  [Entities.appointment]: DuplicateEntityType.APPOINTMENT,
  [Entities.task]: DuplicateEntityType.TASK,
  [Entities.callMemo]: DuplicateEntityType.CALL_MEMO,
};

interface CloneProps {
  id: number;
  entity: Entities;
}

export const useCloneEntityMutation = () => {
  const { t } = useTranslation();
  const [cloneEntityMutation] = useMutation<
    DuplicateResponse,
    DuplicateVariables
  >(CLONE_ENTITY_MUTATION);

  const clone = useCallback(
    ({ id, entity }: CloneProps) => {
      const sourceEntityType = DUPLICATE_ENTITY_TYPE_BY_ENTITY[entity];

      if (!sourceEntityType) {
        throw new Error('Invalid source entity type');
      }

      return cloneEntityMutation({
        variables: {
          request: {
            sourceEntityCode: id,
            sourceEntityType,
            textSuffix: t('COMMON.CLONE_ENTITY_TEXT_SUFFIX'),
            includeAttachements: true,
          },
        },
      });
    },
    [cloneEntityMutation, t]
  );

  return clone;
};
