import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class FileMetaInfo<T extends EMode = EMode.entity> {
  /** Alias: exists */
  exists?: boolean;
  /** Alias: filename */
  fileName?: string;
  /** Alias: fspUrl */
  fspUrl?: string;
  /** Alias: id */
  id?: string;
  /** Alias: key */
  key?: string;
  /** Alias: mimeType */
  mimeType?: string;
  /** Alias: size */
  size?: number;
  /** Alias: thumbnail */
  thumbnail?: string;
  /** Alias: customUrl */
  customUrl?: string;

  __typename?: string;
}

const fields: FieldDefinitions<FileMetaInfo> = {
  exists: {
    alias: 'exists',
  },
  fileName: {
    alias: 'filename',
  },
  fspUrl: {
    alias: 'fspUrl',
  },
  id: {
    alias: 'id',
  },
  key: {
    alias: 'key',
  },
  mimeType: {
    alias: 'mimeType',
  },
  size: {
    alias: 'size',
  },
  thumbnail: {
    alias: 'thumbnail',
  },
  customUrl: {
    alias: 'customUrl',
  },
  __typename: {
    alias: '__typename',
  },
};

export const fileMetaInfoEntityDefinition: EntitiyDefinition<FileMetaInfo> = {
  local: {},
  remote: {
    queryName: 'getFileInfo',
    fragmentName: 'FileMetaInfo',
    withPaginationWrapper: false,
    params: [
      { name: 'type', type: 'FileRequestType!' },
      { name: 'codeOrId', type: 'String!' },
    ],
  },
  fieldDefinitions: fields,
};
