// Most of the operations with RxDB can't be cancelled and need to be executed
// in the correct order to avoid issues. Like, we need to wait the "create
// collections" task to finish before we can call "remove collections". So we
// just queue all operations to be executed one at a time in the order they have
// been queued

type MaybePromise<T> = T | Promise<T>;

type Task<T = unknown> = (signal: AbortSignal) => MaybePromise<T>;

type QueueItem = {
  signal: AbortSignal;
  task: Task;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resolve: (value: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reject: (reason: any) => void;
};

const queueItems: QueueItem[] = [];

let isRunning = false;
let controller = new AbortController();

async function startQueue() {
  // Don't start executing any tasks until the current stack frame is empty.
  await Promise.resolve();

  if (isRunning) return;

  isRunning = true;

  while (queueItems.length > 0) {
    const item = queueItems.shift()!;

    const { signal, task, resolve, reject } = item;

    // If the task has been aborted, reject it right away and go to the next
    // task.
    if (signal.aborted) {
      reject(signal.reason);
      continue;
    }

    try {
      // Pass the AbortSignal to the task and leave it up to the caller to
      // handle aborting the task. We can't just blindly reject here, because
      // some tasks can't be aborted we need to wait for them to finish before
      // starting the next task.
      const promise = task(signal);
      const result = await promise;
      resolve(result);
    } catch (reason) {
      reject(reason);
    }
  }

  isRunning = false;
}

type QueueTaskOptions = {
  /**
   * If `true`, will cancel all pending tasks. You can cancel your task when
   * it's already running by using the provided AbortSignal.
   */
  cancelPending?: boolean;
};

/**
 * Queues a new task to be executed after all previosly queued tasks complete.
 *
 * @returns A promise that settles when the promise returned by `task` settles.
 */
export async function queueTask<T>(
  task: Task<T>,
  options?: QueueTaskOptions
): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    if (options?.cancelPending) {
      const reason = new DOMException(
        'The task has been cancelled.',
        'AbortError'
      );

      for (const item of queueItems) {
        item.reject(reason);
      }

      queueItems.length = 0;

      controller.abort(reason);
      controller = new AbortController();
    }

    queueItems.push({ signal: controller.signal, task, resolve, reject });

    startQueue();
  });
}
