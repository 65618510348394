import styles from './TopBar.module.scss';

import { useTranslation } from 'react-i18next';

import { SearchBar } from '@work4all/components/lib/input/search-bar/SearchBar';
import { Caption } from '@work4all/components/lib/typography/caption/Caption';

export interface ITopBarProps {
  onFilterChange?: (filter: string) => void;

  filter: string;
  tabs: React.ReactNode;
  showAlert?: boolean;
}

export function TopBar({
  filter,
  onFilterChange,
  tabs,
  showAlert,
}: ITopBarProps) {
  const { t } = useTranslation();
  const handleSearchInputValueChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    const value = event.currentTarget.value;
    onFilterChange(value);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.root}>
        <div className={styles.tabs}>{tabs}</div>

        <SearchBar
          inputValue={filter}
          onChange={handleSearchInputValueChange}
          placeholder={t('MORE.SEARCH_INPUT_PLACEHOLDER')}
          className={styles.search}
        />
      </div>
      {showAlert && (
        <div className={styles.noRightsWrapper}>
          <Caption>
            <span className={styles.bold}>{t('ALERTS.EDIT.NOT_ALLOWED')}</span>
          </Caption>
        </div>
      )}
    </div>
  );
}
