import { useMemo, useRef, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ConvertIcon } from '@work4all/assets/icons/convert.svg';

import { ConvertPopover } from '@work4all/components/lib/components/convert-popover/ConvertPopover';
import { CustomToolbarAction } from '@work4all/components/lib/dataDisplay/actions-bar/ToolbarTypes';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { Offer } from '@work4all/models/lib/Classes/Offer.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ConvertErpDialog, CopyErpDialog } from '../ErpDialog';

import { useConvertBzObjectMutation } from './use-convert-bz-object-mutation';

interface UseCloneConvertErpOptions {
  offers?: Offer[];
}

export const OFFER_CONVERT_TYPES: Entities[] = [
  Entities.contract,
  Entities.calculation,
  Entities.deliveryNote,
  Entities.invoice,
];
export const OFFER_CONVERT_TYPES_BLOCKED: Entities[] = [
  Entities.order,
  Entities.demand,
  Entities.inboundDeliveryNote,
];

const ARRAY_CONST: Offer[] = [];

export const useCloneConvertErp = (props: UseCloneConvertErpOptions) => {
  const { offers = ARRAY_CONST } = props;

  const businessPartner = offers.length
    ? offers[0]?.businessPartnerContactCombined?.businessPartner?.data
    : undefined;

  const { t } = useTranslation();
  const [targetEntity, setTargetEntity] = useState<Entities>(null);

  const { mutate } = useConvertBzObjectMutation({ targetEntity });
  const convertRootRef = useRef<HTMLButtonElement>();
  const convertPopoverState = usePopoverState('bottom');
  const actions: CustomToolbarAction[] = useMemo((): CustomToolbarAction[] => {
    return [
      {
        actionKey: 'cloneOrConvert',
        IconComponent: ConvertIcon,
        title: t('MASK.CONVERT'),
        disabled: false,
        handler: (_id, event) => {
          convertPopoverState.handleClick(
            undefined,
            (event?.target as HTMLElement) ?? convertRootRef.current
          );
        },
        rootRef: convertRootRef,
        mode: 'Edit',
      },
    ];
  }, [convertPopoverState, t]);

  const onClose = () => {
    setTargetEntity(null);
  };

  const onConfirm = async () => {
    await mutate({
      sourceEntity: Entities.offer,
      sourceIds: offers.map((x) => x.id),
      targetEntity,
    });
    setTargetEntity(null);
  };

  const dialogProps = {
    onClose,
    onConfirm,
  };

  const convertOnClick = (entity: Entities) => {
    convertPopoverState.onClose();
    setTargetEntity(entity);
  };

  const component = (
    <React.Fragment>
      {targetEntity === Entities.offer ? (
        <CopyErpDialog
          {...dialogProps}
          open
          sourceEntity={Entities.offer}
          targetEntity={Entities.offer}
          businessPartner={businessPartner}
        />
      ) : null}
      {targetEntity && targetEntity !== Entities.offer ? (
        <ConvertErpDialog
          {...dialogProps}
          open
          sourceEntity={Entities.offer}
          targetEntity={targetEntity}
          businessPartner={businessPartner}
        />
      ) : null}

      <ConvertPopover
        copyTypes={[Entities.offer]}
        convertTypes={OFFER_CONVERT_TYPES}
        onClick={convertOnClick}
        popoverState={convertPopoverState}
        convertTitle={t('COMMON.CONVERT_TO')}
        copyTitle={t('COMMON.COPY_CREATE')}
      />
    </React.Fragment>
  );

  return { actions, component };
};
