import styles from './PositionsTabPanel.module.scss';

import { Panel } from '../../../../../components/panel/Panel';
import { useMaskScroll } from '../../../../../hooks/use-mask-scroll';

import { PositionsWithAutoDisable } from './components/Positions';

export const PositionsTabPanel = () => {
  useMaskScroll(false);
  return (
    <Panel className={styles.panel}>
      <PositionsWithAutoDisable
        showArticleSelectionSmall={false}
        bordersHorizontal={false}
      />
    </Panel>
  );
};
