import { ApolloLink } from '@apollo/client';

import { API_VERSION_HEADER_NAME } from '../constants';

import { reportHeadersErrorOne } from './report-headers-error-once';

export interface CreateApiVersionLinkOptions {
  onVersion: (version: string) => void;
}

export function createApiVersionLink(options: CreateApiVersionLinkOptions) {
  const { onVersion } = options;

  const link = new ApolloLink((operation, forward) => {
    return forward(operation).map((data) => {
      const headers = operation.getContext().response.headers as Headers;

      const version = headers.get(API_VERSION_HEADER_NAME);

      if (version === null) {
        reportHeadersErrorOne();
      } else {
        onVersion(version);
      }

      return data;
    });
  });

  return link;
}
