import styles from './SearchBarWithFullScreen.module.scss';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import React, { useEffect } from 'react';

import { useMobileNav } from '../../navigation/mobile-navigation/MobileNavigation';
import {
  ISearchBarWidthDropdownProps,
  SearchBarWithDropDown,
} from '../search-bar-with-dropdown/SearchBarWithDropdown';

import { DropdownFooter } from './components/dropdown-footer/DropdownFooter';
import { ActionType } from './types';

export interface ISearchBarWidthFullScreenProps
  extends ISearchBarWidthDropdownProps {
  footerActions: ActionType[];
  onChangeValue: (value: string) => void;
}

export const withFullScreen = (
  SearchBarComponent: typeof SearchBarWithDropDown
) => {
  const SearchBarWithDropdown = React.forwardRef<
    HTMLInputElement,
    ISearchBarWidthFullScreenProps
  >((props, ref) => {
    const { show, hide } = useMobileNav();
    const dropdownFooterElements = props.footerActions ? (
      <DropdownFooter
        actions={[
          props.footerActions[0],
          props.footerActions[props.footerActions.length - 1],
        ]}
        className={styles['with-fullscreen__list__footer']}
      />
    ) : undefined;

    const dropdownFullscreen = props.active
      ? {
          listClass: styles['with-fullscreen__list'],
          itemsClass: styles['with-fullscreen__items'],
          rootClass: styles['with-fullscreen__dropdown'],
          inputClass: styles['with-fullscreen__dropdownInput'],
        }
      : {};

    const backButton = props.active ? (
      <IconButton
        className={styles['with-fullscreen__search-input__left-icon']}
        onClick={() => {
          props.onChangeValue?.('');
          props.onHideDropDown?.();
        }}
      >
        <ArrowBackIcon />
      </IconButton>
    ) : undefined;

    const component = (
      <SearchBarComponent
        ref={ref}
        {...props}
        dropdown={{
          ...props.dropdown,
          footer: dropdownFooterElements,
          ...dropdownFullscreen,
        }}
        mobile={false}
        classes={{
          root: props.active
            ? styles['with-fullscreen__search']
            : props.classes.root,
          searchBar: props.active
            ? styles['with-fullscreen__search-input']
            : props.classes.searchBar,
          searchIcon: props.active
            ? styles['with-fullscreen__search-input__right-icon']
            : undefined,
        }}
        backButton={backButton}
        autofocus={false}
        selectAll={false}
      />
    );

    useEffect(() => {
      if (props.active) {
        hide();
      } else {
        show();
      }
      return () => {
        show();
      };
    }, [hide, props.active, show]);

    return component;
  });

  return SearchBarWithDropdown;
};

export const SearchBarWithFullScreen = withFullScreen(SearchBarWithDropDown);
