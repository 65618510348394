import styles from './../../MaskOverlay.module.scss';

import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useGroups } from '@work4all/components/lib/components/entity-picker/entity-group-picker/EntityGroupPicker';
import { DEFAULT_PAYMENT_KIND } from '@work4all/components/lib/components/entity-picker/PaymentKindPicker';
import { useLock } from '@work4all/components/lib/hooks/object-lock';
import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';

import {
  useDataMutation,
  useDataProvider,
  useNavigate,
  useUser,
} from '@work4all/data';
import { useCustomFieldsConfig } from '@work4all/data/lib/custom-fields';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';
import { useSearchHistory } from '@work4all/data/lib/hooks/use-search-history';
import { useEntityJsonSchema } from '@work4all/data/lib/json-schema/EntityJsonSchemasContext';

import { BaseDataLanguage } from '@work4all/models/lib/Classes/BaseDataLanguage.entity';
import { BusinessPartnerContactCombined } from '@work4all/models/lib/Classes/BusinessPartnerContactCombined.entity';
import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Currency } from '@work4all/models/lib/Classes/Currency.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { CustomerGroup } from '@work4all/models/lib/Classes/CustomerGroup.entity';
import { DeliveryKind } from '@work4all/models/lib/Classes/DeliveryKind.entity';
import { Group } from '@work4all/models/lib/Classes/Group.entity';
import { InputbankAccountRelationAddModify } from '@work4all/models/lib/Classes/InputbankAccountRelationAddModify.entity';
import { InputKundeRelation } from '@work4all/models/lib/Classes/InputKundeRelation.entity';
import { InputLieferantRelation } from '@work4all/models/lib/Classes/InputLieferantRelation.entity';
import { LedgerAccount } from '@work4all/models/lib/Classes/LedgerAccount.entity';
import { PartialInvoiceLogic } from '@work4all/models/lib/Classes/PartialInvoiceLogic.entity';
import { PaymentKind } from '@work4all/models/lib/Classes/PaymentKind.entity';
import { PriceGroup } from '@work4all/models/lib/Classes/PriceGroup.entity';
import { Salutation } from '@work4all/models/lib/Classes/Salutation.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { SupplierGroup } from '@work4all/models/lib/Classes/SupplierGroup.entity';
import { Tour } from '@work4all/models/lib/Classes/Tour.entity';
import { EInvoiceFormat } from '@work4all/models/lib/Enums/EInvoiceFormat.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { useJSONSchemaResolver } from '@work4all/utils/lib/form-utils/jsonSchemaResolver';
import {
  canAddCustomer,
  canAddSupplier,
  canDeleteCustomer,
  canDeleteSupplier,
  canEditCustomer,
  canEditSupplier,
} from '@work4all/utils/lib/permissions';

import { useDragLayer } from '../../../../../components/dnd/custom-drag-layer/DragLayerProvider';
import { usePageTitle } from '../../../../../hooks';
import { settings, useSetting } from '../../../../../settings';
import { formatPageTitle } from '../../../../../utils/format-page-title';
import {
  ListPageContext,
  ListPageContextValue,
} from '../../../../file-entities-lists/list-page-context';
import {
  MaskTab,
  MaskTabContext,
  MaskTabPanel,
  MaskTabs,
} from '../../../mask-tabs';
import { INDIVIDUAL_TAB_ID } from '../../components/custom-fields/contants';
import { CUSTOM_FIELDS_DATA } from '../../components/custom-fields/custom-fields-data';
import { IndividualTabPanel } from '../../components/custom-fields/IndividualTabPanel';
import { normalizeCustomFields } from '../../components/custom-fields/normalize-custom-fields';
import { prepareInputWithCustomFields } from '../../components/custom-fields/prepare-input-with-custom-fields';
import { Form } from '../../components/form';
import { MaskContent } from '../../components/MaskContent/MaskContent';
import { MaskOverlayDeleteMenuItem } from '../../components/MaskOverlayDeleteMenuItem';
import { MaskOverlayFullscreenToggleButton } from '../../components/MaskOverlayFullscreenToggleButton';
import { MaskOverlayHeader } from '../../components/MaskOverlayHeader/MaskOverlayHeader';
import { MaskOverlayMenuWrapper } from '../../components/MaskOverlayMenuWrapper';
import { MaskOverlayStandardSubmitButton } from '../../components/MaskOverlayStandardSubmitButton';
import {
  MaskContextProvider,
  useMaskConfig,
  useMaskContextValue,
} from '../../hooks/mask-context';
import { useConfirmBeforeCloseMask } from '../../hooks/use-confrm-before-close-mask';
import { EntityRightsContext } from '../../hooks/use-entity-rights';
import { useStandardDeleteEntityHandler } from '../../hooks/use-standard-delete-entity-handler';
import { normalizeFormValue } from '../../hooks/useExtendedFormContext';
import { useInitialFormValue } from '../../hooks/useInitialFormValue';
import { MaskControllerProps } from '../../types';
import { pickUpdateFields } from '../../utils/pick-update-fields';
import { useFormUpdate } from '../../utils/use-form-update';

import { ContactsTabPanel } from './components/tab-panels/contacts/ContactsTabPanel';
import { DepartmentsTabPanel } from './components/tab-panels/departments/DepartmentsTabPanel';
import { ERPTabPanel } from './components/tab-panels/erp/ERP';
import { GeneralTabPanel } from './components/tab-panels/general/GeneralTabPanel';
import { RelationsTabPanel } from './components/tab-panels/relations/Relations';
import { TextTabPanel } from './components/tab-panels/text/TextTabPanel';
import { useEnsureErpTextsInitialized } from './components/tab-panels/text/use-text-tab-panel';
import { useDuplicates } from './hooks';
import { BPMaskFormValue } from './types';

export type BusinessPartners<T extends EMode = EMode.entity> =
  | Supplier<T>
  | Customer<T>;

export enum BusinessPartnerGroups {
  general = 'general',
  department = 'department',
  contactlist = 'contactlist',
  ressources = 'ressources',
  relations = 'relations',
  attachments = 'attachments',
  individual = 'individual',
}

export const BusinessPartnerOverlayController = (
  _props: MaskControllerProps
) => {
  const { setCurrentViewState, getCurrentViewState } = useHistoryStack();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [props, setProps] = useState<MaskControllerProps>({
    ..._props,
    id: getCurrentViewState('id') || _props?.id,
  });
  const { t } = useTranslation();

  const { params = {} } = _props;

  const { presetFields = '{}' } = params;
  useEffect(() => {
    if (props.id) {
      setCurrentViewState('id', props.id);
    }
  }, [props, setCurrentViewState]);

  const [mask, setMask] = useState(useMaskConfig(props));

  const openTab = searchParams.get('openTab');
  const [currentTab, setCurrentTab] = useState('general');

  const customFields = useCustomFieldsConfig({ entity: mask.entity });

  const requestData = useMemo(() => {
    let data = {};
    let entity = Entities.customer;
    const filter = [{ id: { $eq: mask.id } }];

    const common: Omit<BusinessPartners<EMode.query>, '__typename'> = {
      id: null,
      letterSalutation: null,
      salutation: {
        id: null,
        name: null,
        standardletterSalutation: null,
      },
      titleExtension: null,
      birthdayDate: null,
      firm1: null,
      firm2: null,
      firm3: null,
      isPrivateCustomer: null,
      street: null,
      city: null,
      country: null,
      name: null,
      number: null,
      externalNumber: null,
      eMail: null,
      eMail2: null,
      website: null,
      phoneNumber: null,
      phoneNumber2: null,
      faxNumber: null,
      mobileNumber: null,
      note: null,
      postalCode: null,
      postOfficeBox: null,
      postOfficeBoxPostalCode: null,
      postOfficeBoxLocation: null,
      priceGroup: { name: null, id: null },
      currency: { name: null, id: null },
      creditLimit: null,
      skonto: null,
      skonto2: null,
      dontConsiderIntrastat: null,
      skontoDurationDays: null,
      skonto2DurationDays: null,
      dontSumUpDTAN: null,
      paymentDeadline: null,
      dunningTolerance: null,
      isGrossInvoice: null,
      representativeId: null,
      representativeSdObjType: null,
      representativeSdObjMemberId: null,
      paymentId: null,
      paymentKind: {
        id: null,
        longtext: null,
        note: null,
      },
      vatId: null,
      commission: null,
      deactivateERPReleaseRequirement: null,
      additionalAddress1Id: null,
      additionalAddress1Type: null,
      additionalAddressContact1Id: null,
      additionalAddress2Id: null,
      additionalAddress2Type: null,
      additionalAddressContact2Id: null,
      additionalAddress1: {
        contact: {
          id: null,
          name: null,
          firstName: null,
          displayName: null,
        },
        businessPartner: {
          businessPartnerType: null,
          id: null,
          data: {
            customer: {
              id: null,
              name: null,
              website: null,
            },
            supplier: {
              id: null,
              name: null,
              website: null,
            },
          },
        },
      },
      additionalAddress2: {
        contact: {
          id: null,
          name: null,
          firstName: null,
          displayName: null,
        },
        businessPartner: {
          businessPartnerType: null,
          id: null,
          data: {
            customer: {
              id: null,
              name: null,
              website: null,
            },
            supplier: {
              id: null,
              name: null,
              website: null,
            },
          },
        },
      },
      languageId: null,
      language: {
        name: null,
        id: null,
      },
      groupId: null,
      bankDetailsList: [
        {
          bic: null,
          accountNumber: null,
          accountOwner: null,
          iban: null,
          mandateReferenceNumber: null,
          mandateDate: null,
          name: null,
          id: null,
          bankSortCode: null,
          businessPartnerId: null,
          businessPartnerType: null,
          isMainBank: null,
          ledgerAccountId: null,
          sepaCreditorId: null,
        },
      ],
      standardAccount: null,
      tax: null,
      currencyId: null,
      categoryAssignmentList: [
        {
          id: null,
          categoryId: null,
          categoryName: null,
          categoryKindName: null,
        },
      ],
      firmSign: null,
      contactList: [
        {
          firstName: null,
          name: null,
          eMail: null,
          id: null,
          role: null,
          phoneNumber: null,
          mobileNumber: null,
          salutation: {
            id: null,
            name: null,
          },
          department: {
            id: null,
            name: null,
          },
          layedOff: null,
        },
      ],
      customFieldList: [CUSTOM_FIELDS_DATA],
      defaultErpTexts: [
        {
          id: null,
          sdObjMemberCode: null,
          sdObjType: null,
          text: null,
          textType: null,
        },
      ],
    };

    if (mask.entity === Entities.supplier) {
      entity = Entities.supplier;

      const supplierData: Omit<
        Supplier<EMode.query>,
        '_props' | '__typename'
      > = {
        ...common,
        standardAccount: null,
        group: {
          id: null,
          name: null,
        },
        defaultAccount: {
          number: null,
          id: null,
          name: null,
        },
        purchasePriceList: [
          {
            id: null,
            articleNumber: null,
            discount: null,
            listPrice: null,
            purchasePrice: null,
            validFromDate: null,
            supplierId: null,
            articleId: null,
          },
        ],
      };
      data = supplierData;
    }

    if (mask.entity === Entities.customer) {
      const customerData: Omit<
        Customer<EMode.query>,
        '_props' | '__typename'
      > = {
        ...common,
        commission: null,
        deliveryKindId: null,
        group: {
          id: null,
          name: null,
        },
        deliveryKind: {
          id: null,
          text: null,
        },
        commisionar: {
          id: null,
          name: null,
        },
        eInvoiceFormat: null,
        invoiceForm: null,
        dunningBlock: null,
        invoiceEMailAddress: null,
        language: {
          id: null,
          name: null,
        },
        discount2: null,
        discountProposal: null,
        paymentDeadline: null,
        partialInvoiceLogicId: null,
        partialInvoiceLogic: {
          id: null,
          name: null,
          longtext: null,
        },
        tour: {
          id: null,
          name: null,
        },
        customerIndividualPriceList: [
          {
            id: null,
            articleId: null,
            article: {
              id: null,
              name: null,
            },
            articleNumber: null,
            comment: null,
            netPrice: null,
          },
        ],
      };
      data = customerData;
    }

    return {
      filter,
      entity,
      data,
    };
  }, [mask.entity, mask.id]);

  // TODO Similar changes must be made to the queries for "parent" or "template"
  // entities. Right now they use "live" queries that will get updated after
  // a mutation.
  //
  // Maybe there is a better way to implement this. What we need is fetch the
  // data once, re-fetch if the query changes, and be able to manually refresh
  // the query after a form submit. But ignore all updates caused by changes
  // to Apollo's cache.
  const initialFormValue = useInitialFormValue<Customer | Supplier>(
    requestData,
    mask.isCreateMode
  );

  const user = useUser();
  const { activeTenantData } = useTenant();

  const requestDataLanguages = useMemo(
    () => ({
      entity: Entities.baseDataLanguage,
      data: { id: null, name: null } as BaseDataLanguage,
      operationName: 'GetLanguages',
      completeDataResponse: true,
    }),
    []
  );

  const languagesResult =
    useDataProvider<BaseDataLanguage>(requestDataLanguages);

  const requestDataDefaultPaymentKind = useMemo(
    () => ({
      entity: Entities.paymentKind,
      data: {
        note: null,
        id: null,
        paymentTarget: null,
        skonto1DurationDays: null,
        skonto1Percent: null,
      } as PaymentKind,
      completeDataResponse: true,
    }),
    []
  );

  const defaultPaymentKindResult = useDataProvider<PaymentKind>(
    requestDataDefaultPaymentKind
  );

  const defaultPaymentKindId = useSetting(settings.defaultPaymentKindId());

  const defaultPaymentDeadlineDays = useSetting(
    settings.defaultPaymentDeadlineDays()
  );

  const defaultDunningToleranceDays = useSetting(
    settings.defaultDunningToleranceDays()
  );

  const defaultSkontoDeadlineDays = useSetting(
    settings.defaultSkontoDeadlineDays()
  );

  const defaultSkontoPercent = useSetting(settings.defaultSkontoPercent());

  const defaultSupplierGroupId = useSetting(settings.defaultSupplierGroupId());

  const defaultCustomerGroupId = useSetting(settings.defaultCustomerGroupId());

  const supplierGroups = useGroups(Entities.supplierGroup);
  const customerGroups = useGroups(Entities.customerGroup);

  const newEntityData: Supplier | Customer = useMemo(() => {
    //TO DO: Find default payment kind
    const markedPaymentKind = defaultPaymentKindResult?.data?.find(
      (paymentKind) => paymentKind.id === defaultPaymentKindId.value
    );

    const customerDefaultGroup = customerGroups?.data?.groups?.find(
      (x) => x.id === defaultCustomerGroupId.value
    ) as CustomerGroup;

    const supplierDefaultGroup = supplierGroups?.data?.groups?.find(
      (x) => x.id === defaultSupplierGroupId.value
    ) as SupplierGroup;

    const common: Supplier | Customer = {
      number: 0,
      contactList: [],
      bankDetailsList: [],
      skonto: 0,
      skonto2: 0,
      skonto2DurationDays: 0,
      skontoDurationDays: 0,
      paymentDeadline: 0,
      dunningTolerance: 0,
      dontConsiderIntrastat: 1,
      country: mask.isCreateMode ? activeTenantData?.country : null,
      language: languagesResult?.data?.find((lang) => lang.id === 0),
      languageId: languagesResult?.data?.find((lang) => lang.id === 0)?.id,
      discount2: 0,
      discount3: 0,
      discount4: 0,
      discountProposal: 0,
      creditLimit: 0,
    };

    const customer: Customer = {
      customerIndividualPriceList: [],
      paymentDeadline: defaultPaymentDeadlineDays?.value,
      skonto: defaultSkontoPercent?.value,
      skontoDurationDays: defaultSkontoDeadlineDays?.value,
      dunningTolerance: defaultDunningToleranceDays?.value,
      paymentKind: markedPaymentKind || {
        ...DEFAULT_PAYMENT_KIND,
        note: t(DEFAULT_PAYMENT_KIND.translationKey),
      },
      paymentId: (markedPaymentKind || DEFAULT_PAYMENT_KIND).id,
      groupId: customerDefaultGroup ? defaultCustomerGroupId.value : 0,
      group: customerDefaultGroup,
    };

    const supplier: Supplier = {
      purchasePriceList: [],
      groupId: supplierDefaultGroup ? defaultSupplierGroupId.value : 0,
      group: supplierDefaultGroup,
    };

    const defaultValue = JSON.parse(presetFields) || {};
    if (defaultValue.__typename) delete defaultValue.__typename;

    Object.entries(defaultValue).forEach((x) => {
      if (x[1] === null) delete defaultValue[x[0]];
    });

    if (mask.entity === Entities.customer) {
      return { ...common, ...customer, ...defaultValue } as Customer;
    } else {
      return { ...common, ...supplier, ...defaultValue } as Supplier;
    }
  }, [
    activeTenantData?.country,
    customerGroups?.data?.groups,
    defaultCustomerGroupId.value,
    defaultDunningToleranceDays?.value,
    defaultPaymentDeadlineDays?.value,
    defaultPaymentKindId.value,
    defaultPaymentKindResult?.data,
    defaultSkontoDeadlineDays?.value,
    defaultSkontoPercent?.value,
    defaultSupplierGroupId.value,
    languagesResult?.data,
    mask.entity,
    mask.isCreateMode,
    presetFields,
    supplierGroups?.data?.groups,
    t,
  ]);

  const dataRaw = mask.isCreateMode
    ? newEntityData
    : initialFormValue.value ?? newEntityData;

  const data = useMemo(() => {
    return normalizeCustomFields(normalizeFormValue(dataRaw), customFields);
  }, [dataRaw, customFields]);

  const schema = useEntityJsonSchema(mask.entity);
  const resolver = useJSONSchemaResolver(schema);
  const form = useForm<BPMaskFormValue>({
    resolver,
    mode: 'onChange',
    defaultValues: data,
    shouldFocusError: false,
    context: {
      schema,
    },
  });
  const { handleSubmit, reset, formState, getValues, watch } = form;

  const { lock, unlock } = useLock({
    subEntityType: mask.entity,
    subEntityIds: data.id ? [data.id.toString()] : [],
  });

  useEffect(() => {
    lock();
    return () => {
      unlock();
    };
  }, [data]);

  useEffect(() => {
    reset(data);
  }, [reset, data]);

  const isDirty = Object.keys(formState.dirtyFields).length > 0;

  useConfirmBeforeCloseMask(isDirty);

  const [closeOnSave, setCloseOnSave] = useState(false);
  const { clear } = useDragLayer();
  const { saveSearchItem } = useSearchHistory();
  const [mutate] = useDataMutation<BusinessPartners, EMode.upsert>({
    entity: mask.entity,
    mutationType: EMode.upsert,
    responseData: requestData.data,
    onCompleted: (e) => {
      if (mask.isCreateMode) {
        saveSearchItem(mask.entity, {
          id: e.id.toString(),
          number: e.number.toString(),
          name: e.name,
        });
      }

      if (mask.isCreateMode && !closeOnSave) {
        setProps({ ...props, id: e.id.toString() });
        setMask({
          ...mask,
          id: e.id.toString(),
          isCreateMode: false,
          isEditMode: true,
          mode: 'edit',
        });

        const subEntityId = e.id.toString();
        const subEntityType =
          mask.entity === Entities.customer
            ? Entities.customer
            : Entities.supplier;

        clear();
        navigate(
          `/${subEntityType}s/${subEntityId}/details/${subEntityType}/${subEntityId}?openTab=${currentTab}`
        );
      } else {
        props.onAfterSave(e);
      }
      setCloseOnSave(false);
    },
  });

  const nameUpdate = useCallback(() => {
    const deriveBPName = () => {
      const lastName = getValues('firm1') || '';
      const firstName = getValues('firm2') || '';
      const city = getValues('city') || '';
      const isPrivateCustomer = getValues('isPrivateCustomer') || false;
      if (!isPrivateCustomer) {
        if (!city?.length && !lastName?.length) return undefined;
        return city?.length > 0 && lastName?.length > 0
          ? `${lastName}, ${city}`
          : lastName;
      } else {
        if (!city?.length && !lastName?.length && !firstName?.length)
          return undefined;

        let firmName = undefined;

        if (lastName.length && firstName.length) {
          firmName = `${firstName} ${lastName}`;
        } else {
          firmName = lastName.length
            ? lastName
            : firstName.length
            ? firstName
            : undefined;
        }

        if (firmName) {
          firmName = city.length ? `${firmName}, ${city}` : firmName;
        }

        return firmName;
      }
    };

    const {
      formState: { dirtyFields },
    } = form;
    if (dirtyFields.firm1 || dirtyFields.firm2 || dirtyFields.city)
      return { name: deriveBPName() };
    return {};
  }, [getValues, form]);

  const salutationUpdate = () => {
    const deriveBPName = () => {
      const extension = getValues('titleExtension') || '';
      const salutation = getValues('salutation') || null;
      const isPrivateCustomer = getValues('isPrivateCustomer') || false;
      const lastName = getValues('firm1') || '';

      if (isPrivateCustomer) {
        const result = [
          salutation?.standardletterSalutation,
          extension,
          lastName,
        ]
          .join(' ')
          .trim()
          .replace(/\s\s+/g, ' ');
        return result;
      }
    };

    const {
      formState: { dirtyFields },
    } = form;
    if (
      dirtyFields.titleExtension ||
      dirtyFields.salutation ||
      dirtyFields.firm1
    )
      return { letterSalutation: deriveBPName() };
    return {};
  };

  useFormUpdate(
    {
      currency: (value: Currency) => {
        return { currencyId: value?.id ?? 0 } as Customer | Supplier;
      },
      firm1: () => {
        return { ...nameUpdate(), ...salutationUpdate() };
      },
      firm2: nameUpdate,
      city: nameUpdate,
      titleExtension: salutationUpdate,
      salutation: (salutation: Salutation) => {
        return { salutationId: salutation?.id, ...salutationUpdate() };
      },
      priceGroup: (value: PriceGroup) => {
        return { priceGroupId: value?.id ?? 0 } as Customer | Supplier;
      },
      paymentKind: (value: PaymentKind) => {
        return {
          paymentId: value?.id ?? 0,
          skonto: value?.skonto1Percent,
          skonto2: value?.skonto2Percent,
          skontoDurationDays: value?.skonto1DurationDays,
          skonto2DurationDays: value?.skonto2DurationDays,
          paymentDeadline: value?.paymentTarget,
        } as Customer | Supplier;
      },
      tour: (value: Tour) => {
        return { tourId: value?.id ?? 0 } as Customer | Supplier;
      },
      deliveryKind: (value: DeliveryKind) => {
        return { deliveryKindId: value?.id ?? 0 } as Customer | Supplier;
      },
      partialInvoiceLogic: (value: PartialInvoiceLogic) => {
        return { partialInvoiceLogicId: value?.id ?? 0 } as Customer | Supplier;
      },
      language: (value: BaseDataLanguage) => {
        return { languageId: value?.id ?? 0 } as Customer | Supplier;
      },
      eInvoiceFormat: (value: EInvoiceFormat) => {
        return { eInvoiceFormat: value } as Customer | Supplier;
      },
      defaultAccount: (value: LedgerAccount) => {
        return { standardAccount: value?.number || 0 } as Customer | Supplier;
      },
      group: (value: Group) => {
        return { groupId: value?.id || 0 } as Customer | Supplier;
      },
      bankDetailsList: () => {
        return {} as Customer | Supplier;
      },
      commisionar: (value: Supplier) => {
        return {
          representativeId: value?.id ?? 0,
          representativeSdObjMemberId: value?.id ?? 0,
          representativeSdObjType: 1,
        } as Customer | Supplier;
      },
      additionalAddress1: (value: BusinessPartnerContactCombined) => {
        const result = {
          additionalAddress1Id: value?.businessPartner?.id || 0,
          additionalAddress1Type:
            value?.businessPartner?.businessPartnerType || SdObjType.KUNDE,
        } as Customer | Supplier;
        return result;
      },
      'additionalAddress1.contact': (value: Contact) => {
        return {
          additionalAddressContact1Id: value?.id || 0,
        };
      },
      additionalAddress2: (value: BusinessPartnerContactCombined) => {
        const result = {
          additionalAddress2Id: value?.businessPartner?.id || 0,
          additionalAddress2Type:
            value?.businessPartner?.businessPartnerType || SdObjType.KUNDE,
        } as Customer | Supplier;
        return result;
      },
      'additionalAddress2.contact': (value: Contact) => {
        return {
          additionalAddressContact2Id: value?.id || 0,
        };
      },
    },
    form
  );

  const canDeleteBusinessPartner = useCallback(
    (businessPartner: Customer | Supplier) => {
      switch (mask.entity) {
        case Entities.customer:
          return canDeleteCustomer(user, businessPartner as Customer);
        case Entities.supplier:
          return canDeleteSupplier(user, businessPartner as Supplier);
        default:
          return false;
      }
    },
    [mask.entity, user]
  );
  const canEditBusinessPartner = useCallback(
    (businessPartner: Customer | Supplier) => {
      switch (mask.entity) {
        case Entities.customer:
          return canEditCustomer(user, businessPartner as Customer);
        case Entities.supplier:
          return canEditSupplier(user, businessPartner as Supplier);
        default:
          return false;
      }
    },
    [mask.entity, user]
  );

  const setDefaultErpTexts = useCallback(
    (erp) => {
      //eslint-disable-next-line
      //@ts-ignore
      form.setValue('defaultErpTexts', erp);
    },
    [form]
  );

  useEnsureErpTextsInitialized({
    isDataFetched: !initialFormValue.loading && !!initialFormValue.value,
    setValue: setDefaultErpTexts,
    defaultErpTexts: initialFormValue.value?.defaultErpTexts,
    entity: mask.entity,
  });

  const entityName = data?.name || '';
  const isPrivateCustomer = watch('isPrivateCustomer');

  usePageTitle(formatPageTitle([entityName, data?.name]), {
    priority: 1,
  });

  const handleDeleteEntitiesClick = useStandardDeleteEntityHandler(mask);

  const entityRights = useMemo(
    () => ({
      create:
        mask.entity === Entities.customer
          ? canAddCustomer(user)
          : canAddSupplier(user),
      read: false,
      update: canEditBusinessPartner(data),
      delete: canDeleteBusinessPartner(data),
    }),
    [canDeleteBusinessPartner, canEditBusinessPartner, data, mask.entity, user]
  );

  const maskContext = useMaskContextValue({ ...mask, data, customFields });
  const shouldRenderIndividualTab = customFields && customFields.length > 0;

  const listPageContextValue = useMemo<ListPageContextValue>(
    () => ({ entityType: mask.entity }),
    [mask.entity]
  );

  const postalCode = watch('postalCode');
  const street = watch('street');
  const { duplicatesHandler, DuplicatesDialog } = useDuplicates({
    entity: mask.entity as Entities.customer | Entities.supplier,
    postalCode,
    street,
  });

  const handleChangeTab = (tabValue: string) => {
    setCurrentTab(tabValue);
  };

  return (
    <MaskContextProvider value={maskContext}>
      <EntityRightsContext.Provider value={entityRights}>
        <ListPageContext.Provider value={listPageContextValue}>
          <FormProvider {...form}>
            <MaskTabContext
              defaultValue={openTab ?? 'general'}
              onChange={handleChangeTab}
            >
              <DuplicatesDialog />
              <Form
                className={styles.maskForm}
                onSubmit={handleSubmit(async (submitData) => {
                  const relations: InputKundeRelation | InputLieferantRelation =
                    {
                      bankAccounts: {
                        addModify: submitData.bankDetailsList.map((item) => {
                          return {
                            accountowner: item.accountOwner,
                            bankAccountNumber: item.accountNumber,
                            bIC: item.bic,
                            code: item.id,
                            iBAN: item.iban,
                            ledger: item.ledgerAccountId,
                            mandateDate: item.mandateDate?.length
                              ? item.mandateDate
                              : null,
                            mandateRefNo: item.mandateReferenceNumber,
                            name: item.name,
                            sEPACreditorID: item.sepaCreditorId,
                            sortCode: item.bankSortCode,
                            mainBank: Boolean(item.isMainBank),
                          } as InputbankAccountRelationAddModify;
                        }),
                        remove: data.bankDetailsList
                          .filter((item) => {
                            return (
                              submitData.bankDetailsList.find(
                                (x) => x.id === item.id
                              ) === undefined
                            );
                          })
                          .map((item) => {
                            return item.id;
                          }),
                      },
                      categoryMarks: {
                        add: submitData?.categoryAssignmentList
                          ?.filter(
                            (currentAssignment) =>
                              !data?.categoryAssignmentList?.find(
                                (prevAssignment) =>
                                  currentAssignment.categoryId ===
                                  prevAssignment.categoryId
                              )
                          )
                          ?.map((assignment) => ({
                            categoryCode: assignment.categoryId,
                          })),
                        remove: data.categoryAssignmentList
                          ?.filter(
                            (prevAssignment) =>
                              !submitData?.categoryAssignmentList.find(
                                (currentAssignment) =>
                                  currentAssignment.categoryId ===
                                  prevAssignment.categoryId
                              )
                          )
                          ?.map((assignment) => assignment.categoryId),
                      },
                    };

                  const updateRaw = mask.isCreateMode
                    ? submitData
                    : pickUpdateFields(submitData, formState.dirtyFields);

                  const updateMapped = prepareInputWithCustomFields(updateRaw);
                  if (updateMapped.defaultErpTexts)
                    relations.defaultErpTexts = {
                      addModify: updateMapped.defaultErpTexts.map((x) => ({
                        text: x.text,
                        textType: x.textType,
                      })),
                    };

                  await duplicatesHandler(
                    async () => await mutate(updateMapped, { relations }),
                    mask.isEditMode
                  );
                })}
              >
                <MaskOverlayHeader
                  title={t(`COMMON.${mask.entity.toUpperCase()}`)}
                  subTitle={entityName}
                  actions={
                    <>
                      <MaskOverlayStandardSubmitButton
                        onClick={() => setCloseOnSave(true)}
                      />
                      <MaskOverlayFullscreenToggleButton />
                      {mask.isEditMode && (
                        <MaskOverlayMenuWrapper>
                          <MaskOverlayDeleteMenuItem
                            disabled={mask.wip || !entityRights.delete}
                            onClick={handleDeleteEntitiesClick}
                          />
                        </MaskOverlayMenuWrapper>
                      )}
                    </>
                  }
                  tabs={
                    <BPTabs
                      renderIndividualTab={shouldRenderIndividualTab}
                      isPrivate={isPrivateCustomer}
                    />
                  }
                />
                <Content renderIndividualTab={shouldRenderIndividualTab} />
              </Form>
            </MaskTabContext>
          </FormProvider>
        </ListPageContext.Provider>
      </EntityRightsContext.Provider>
    </MaskContextProvider>
  );
};

const BPTabs = memo(function BPTabs({
  isPrivate = false,
  renderIndividualTab,
}: {
  isPrivate?: boolean;
  renderIndividualTab: boolean;
}) {
  const { t } = useTranslation();

  return (
    <MaskTabs>
      <MaskTab value="general" label={t('MASK.GENERAL')}></MaskTab>
      <MaskTab
        value="departments"
        label={t('MASK.DEPARTMENTS')}
        disabled={true}
      ></MaskTab>
      {!isPrivate && (
        <MaskTab value="contact" label={t('MASK.CONTACT_PERSON')}></MaskTab>
      )}
      <MaskTab value="erp" label={t('MASK.ERP')}></MaskTab>
      <MaskTab value="text" label={t('MASK.TEXT')}></MaskTab>
      <MaskTab value="relations" label={t('MASK.RELATIONS')}></MaskTab>
      <MaskTab
        value="attachments"
        label={t('MASK.ATTACHMENTS')}
        disabled={true}
      ></MaskTab>
      {renderIndividualTab && (
        <MaskTab value={INDIVIDUAL_TAB_ID} label={t('MASK.INDIVIDUAL')} />
      )}
    </MaskTabs>
  );
});

const Content = memo(function CrmTabPanels({
  renderIndividualTab,
}: {
  renderIndividualTab: boolean;
}) {
  return (
    <MaskContent>
      <MaskTabPanel value="general">
        <GeneralTabPanel />
      </MaskTabPanel>
      <MaskTabPanel value="departments">
        <DepartmentsTabPanel />
      </MaskTabPanel>
      <MaskTabPanel value="contact">
        <ContactsTabPanel />
      </MaskTabPanel>
      <MaskTabPanel value="erp">
        <ERPTabPanel />
      </MaskTabPanel>
      <MaskTabPanel value="text">
        <TextTabPanel />
      </MaskTabPanel>
      <MaskTabPanel value="relations">
        <RelationsTabPanel />
      </MaskTabPanel>
      <MaskTabPanel value="attachments">
        {/* <AttachmentsTabPanel /> */}
      </MaskTabPanel>

      {renderIndividualTab && (
        <MaskTabPanel value={INDIVIDUAL_TAB_ID}>
          <IndividualTabPanel />
        </MaskTabPanel>
      )}
    </MaskContent>
  );
});
