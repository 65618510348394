import styles from './VacationMaskOverlay.module.scss';

import { useEffect, useState } from 'react';

import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { User } from '@work4all/models/lib/Classes/User.entity';

import { VacationMask } from './VacationMask';

export interface VacationMaskOverlayProps {
  open: boolean;
  onClose: () => void;
  date?: Date | null;
  user?: User | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  init?: any;
}

export function VacationMaskOverlay(props: VacationMaskOverlayProps) {
  const { open, onClose, date, user, init } = props;

  const [view, setView] = useState(null);

  useEffect(() => {
    if (open) {
      setView({ view: <VacationMask date={date} user={user} init={init} /> });
    }
  }, [open, date, user, init]);

  return (
    <NavigationOverlay
      open={open}
      classes={{
        wrapper: styles.wrapper,
      }}
      initialView={view}
      close={onClose}
      onExited={() => {
        setView(null);
      }}
    />
  );
}
