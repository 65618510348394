import { EmailSignaturePickerField } from '../../../../../../../../../components/entity-picker/EmailSignaturePickerField';
import { EmailTemplatePickerField } from '../../../../../../../../../components/entity-picker/EmailTemplatePickerField';
import { useEmailTemplaterContext } from '../../../../email-templater/email-templater-context';

export function TemplateAndSignature() {
  const { disabled, template, signature, applyTemplate, applySignature } =
    useEmailTemplaterContext();

  if (disabled) {
    return null;
  }

  return (
    <>
      <EmailTemplatePickerField
        value={template}
        onChange={(template) => {
          applyTemplate({ template, signature: template?.signature });
        }}
      />
      <EmailSignaturePickerField
        value={signature}
        onChange={(signature) => {
          applySignature({ signature });
        }}
      />
    </>
  );
}
