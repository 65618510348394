import { Typography } from '@mui/material';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { Department } from '@work4all/models/lib/Classes/Department.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type IDepartmentPickerProps<TMultiple extends boolean> = IPickerProps<
  Department,
  TMultiple
> & {
  ofType?: Entities;
  ofId?: string;
};

export function DepartmentPicker<TMultiple extends boolean>(
  props: IDepartmentPickerProps<TMultiple>
) {
  const { data, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...DEPARTMENT_FIELDS, ...data };
  }, [data]);

  const request = useMemo<DataRequest>(() => {
    let vars: Record<string, number[] | null>;
    if ((Entities.customer || Entities.supplier).includes(props.ofType)) {
      const argName =
        props.ofType === Entities.customer ? 'kundenCode' : 'lieferantenCode';
      vars = {};
      vars[argName] = props.ofId ? [parseInt(props.ofId, 10)] : null;
    }

    return {
      data: allFields,
      entity: Entities.department,
      completeDataResponse: true,
      vars: vars,
    };
  }, [allFields, props.ofId, props.ofType]);

  const response = useDataProvider(request);

  // The data provider does not set the `total` property when
  // `completeDataResponse` is `true`.   So we have to set it manually.
  // Otherwise, the <ListEntityPicker /> will not be able to render the list.
  const patchedResponse = useMemo(() => {
    return { ...response, total: response.data.length };
  }, [response]);

  const renderItem = (department: Department) => {
    const label = department.name;

    return (
      <Tooltip title={label}>
        <Typography variant="body2" noWrap>
          {label}
        </Typography>
      </Tooltip>
    );
  };

  return (
    <ListEntityPicker
      entity={Entities.department}
      data={allFields}
      filterBy={'name'}
      sortBy="name"
      {...rest}
      fixedDataSet={patchedResponse}
      renderItemContent={renderItem}
    />
  );
}

const DEPARTMENT_FIELDS: Department = {
  id: null,
  name: null,
};
