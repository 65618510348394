import { PostIt } from '@work4all/models/lib/Classes/PostIt.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';

export const YELLOW_NOTE_FIELDS_QUERY: PostIt<EMode.query> = {
  id: null,
  date: null,
  formName: null,
  body: null,
  user: {
    id: null,
    displayName: null,
  },
};

export const YELLOW_NOTE_FIELDS_ENTITY: PostIt<EMode.entity> = {
  id: null,
  date: null,
  formName: null,
  body: null,
  user: {
    id: null,
    displayName: null,
  },
};
