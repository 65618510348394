import { useRef, useState } from 'react';

import {
  BusinessPartnerPicker,
  IBusinessPartnerPickerProps,
} from '@work4all/components/lib/components/entity-picker/business-partner-picker/BusinessPartnerPicker';
import {
  EntityPickerPopover,
  IEntityPickerPopoverProps,
} from '@work4all/components/lib/components/entity-picker/components';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EntityPickerActions } from './EntityPickerActions';

export type IBusinessPartnerPickerPopoverProps = {
  withActionBar?: boolean;
} & Omit<IBusinessPartnerPickerProps, 'onTabChange'> &
  Pick<IEntityPickerPopoverProps, 'children' | 'anchorEl'>;

export function BusinessPartnerPickerPopover(
  props: IBusinessPartnerPickerPopoverProps
) {
  const { withActionBar, children, anchorEl, ...pickerProps } = props;

  const [entity, setEntity] = useState(Entities.customer);

  const popoverRef = useRef<EntityPickerPopover>();

  return (
    <EntityPickerPopover
      ref={popoverRef}
      anchorEl={anchorEl}
      picker={
        <BusinessPartnerPicker {...pickerProps} onTabChange={setEntity} />
      }
      footer={
        withActionBar ? (
          <EntityPickerActions
            entity={entity}
            multiple={pickerProps.multiple}
            onChange={(value) => {
              pickerProps.onChange(value);
              popoverRef.current?.close();
            }}
          ></EntityPickerActions>
        ) : null
      }
    >
      {children}
    </EntityPickerPopover>
  );
}
