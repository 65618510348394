import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { PROJECT_DATA } from '@work4all/components/lib/components/entity-picker/project-picker/projectPickerPresets';
import {
  MultiStepControls,
  Step,
} from '@work4all/components/lib/input/multi-step-controls';

import { BusinessPartnerPickerField } from '../../../../../../../../../components/entity-picker/BusinessPartnerPickerField';
import { ContactPickerField } from '../../../../../../../../../components/entity-picker/ContactPickerField';
import { ProjectPickerField } from '../../../../../../../../../components/entity-picker/ProjectPickerField';
import { UserPickerField } from '../../../../../../../../../components/entity-picker/UserPickerField';
import { ControllerPlus } from '../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../form-plus/use-form-context-plus';
import { DateTimeInputPicker } from '../../../../../../../locked-inputs';
import { ControlWrapper } from '../../../../../../components/control-wrapper';
import { DocumentMaskFormValue } from '../../../../types';

export function Assignment() {
  const { t } = useTranslation();
  const { control, watch, register } =
    useFormContextPlus<DocumentMaskFormValue>();

  const businessPartnerType = watch('businessPartnerType');
  const businessPartnerId = watch('businessPartnerId');

  return (
    <Collapse title={t('COMMON.ASSIGNMENT')} defaultOpen>
      <ControlWrapper>
        <MultiStepControls isColumnDirection={false}>
          <Step active={true} index={0}>
            <ControllerPlus
              name={'businessPartner.data'}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <BusinessPartnerPickerField
                    {...field}
                    onChange={(value) => {
                      field.onChange(value?.data);
                    }}
                  />
                );
              }}
            />
          </Step>

          <Step
            active={businessPartnerId !== 0 && businessPartnerId !== undefined}
            index={1}
          >
            <ControllerPlus
              control={control}
              name={'contact'}
              render={({ field, fieldState }) => {
                return (
                  <ContactPickerField
                    {...field}
                    error={fieldState.error?.message}
                    businessPartnerId={businessPartnerId}
                    businessPartnerType={businessPartnerType}
                  />
                );
              }}
            />
          </Step>
        </MultiStepControls>
      </ControlWrapper>

      <ControlWrapper>
        <ControllerPlus
          control={control}
          name={'user'}
          render={({ field }) => {
            return <UserPickerField {...field} />;
          }}
        />

        <DateTimeInputPicker
          {...register('date')}
          dateLabel={t('FILTERS.DATE')}
          withTime={false}
        />
      </ControlWrapper>

      <ControlWrapper>
        <ControllerPlus
          control={control}
          name={'project'}
          render={({ field, fieldState }) => {
            return (
              <ProjectPickerField
                data={{
                  projectSubDirectories: [
                    {
                      id: null,
                      name: null,
                    },
                  ],
                  ...PROJECT_DATA,
                }}
                {...field}
                error={fieldState.error?.message}
              />
            );
          }}
        />
      </ControlWrapper>
    </Collapse>
  );
}
