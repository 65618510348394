import { useContext } from 'react';

import { AvailableRoutesContext } from '../navigation-wrapper/NavigationWrapper';

const fileItems = ['/customers', '/suppliers', '/projects'];

export const useMobileNavigation = () => {
  const navigationConfig = useContext(AvailableRoutesContext);

  const home = navigationConfig.find((x) => x.pathname === '/home');
  const more = navigationConfig.find((x) => x.pathname === '/more');
  const files = navigationConfig.filter((x) => fileItems.includes(x.pathname));

  const vacation = navigationConfig.find(
    (x) => x.pathname === '/more/vacation'
  );
  const calendar = navigationConfig.find(
    (x) => x.pathname === '/more/calendar'
  );
  const timetracking = navigationConfig.find(
    (x) => x.pathname === '/more/timetracking'
  );

  const profile = { title: 'NAVIGATION.PROFILE', pathname: '/more/profile' };

  return { home, more, files, vacation, calendar, profile, timetracking };
};
