import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { EMailSignature } from '@work4all/models/lib/Classes/EMailSignature.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ListEntityPicker } from './components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from './types';

export type EmailSignaturePickerProps = Omit<
  IPickerProps<EMailSignature, false>,
  'multiple' | 'prefilter'
>;

export function EmailSignaturePicker(props: EmailSignaturePickerProps) {
  const { data, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...EMAIL_SIGNATURE_PICKER_DATA, ...data };
  }, [data]);

  const request = useMemo<DataRequest>(() => {
    return {
      data: allFields,
      entity: Entities.eMailSignature,
      completeDataResponse: true,
      operationName: 'GetEmailSignatures',
    };
  }, [allFields]);

  const response = useDataProvider<EMailSignature>(request);

  const dataset = useMemo(() => {
    return {
      loading: response.loading,
      data: [...response.data].sort((a, b) => a.name.localeCompare(b.name)),
      total: response.data.length,
      fetchMore: () => Promise.resolve(),
      refetch: () => Promise.resolve(null),
    };
  }, [response]);

  const renderItem = (item: EMailSignature) => {
    const label = item.name;

    return (
      <Tooltip title={label}>
        <Typography variant="body2" noWrap>
          {label}
        </Typography>
      </Tooltip>
    );
  };

  return (
    <ListEntityPicker
      multiple={false}
      entity={Entities.eMailSignature}
      data={allFields}
      filterBy="name"
      sortBy="name"
      fixedDataSet={dataset}
      renderItemContent={renderItem}
      {...rest}
    />
  );
}

const EMAIL_SIGNATURE_PICKER_DATA: EMailSignature = {
  id: null,
  name: null,
  body: null,
};
