import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { InboundInvoice } from '@work4all/models/lib/Classes/InboundInvoice.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { DATA_BY_ENTITY, KEY_BY_ENTITY } from '../constants';
import { BottomViewProps } from '../interfaces';
import { Tab } from '../types';

export const useTabData = (
  entity: Tab,
  selectedEntityId: BottomViewProps['selectedEntityId']
) => {
  const request = useMemo(() => {
    return {
      entity: Entities.inboundInvoice,
      data: DATA_BY_ENTITY[entity],
      filter: [{ id: { $eq: selectedEntityId } }],
    };
  }, [entity, selectedEntityId]);

  const { data: requestData } = useDataProvider<InboundInvoice>(request);

  return requestData?.[0]?.[KEY_BY_ENTITY[entity]] ?? [];
};
