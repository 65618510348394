import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';

import { useUser } from '@work4all/data';
import { useSetting } from '@work4all/data/lib/settings/use-setting';

import { settings } from '../../../../../../../../settings';

export function CostsSection() {
  const { t } = useTranslation();

  const projectTimeTrackingPriceOfCostCalculation = useSetting(
    settings.projectTimeTrackingPriceOfCostCalculation()
  );

  const user = useUser();

  return (
    <Collapse title={t('SETTINGS.PROJECT_TIME.COSTS')} defaultOpen>
      <CheckboxRadioItem
        disabled={!user.isMaster}
        checked={projectTimeTrackingPriceOfCostCalculation.value}
        onChange={() => projectTimeTrackingPriceOfCostCalculation.set(true)}
        control="radio"
        label={t('SETTINGS.PROJECT_TIME.COST_PRICE_OF_ARTICLE')}
      />
      <CheckboxRadioItem
        disabled={!user.isMaster}
        checked={!projectTimeTrackingPriceOfCostCalculation.value}
        onChange={() => projectTimeTrackingPriceOfCostCalculation.set(false)}
        control="radio"
        label={t('SETTINGS.PROJECT_TIME.HOURLY_RATE_OF_COLLEGUE')}
      />
    </Collapse>
  );
}
