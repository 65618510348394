import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { TimeTrackingOverviewItem } from '@work4all/models/lib/Classes/TimeTrackingOverviewItem.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { CUSTOM_FIELDS_DATA } from '../mask-overlays/mask-overlay/components/custom-fields/custom-fields-data';

interface Options {
  disabled?: boolean;
  from: Date;
  to: Date;
  userId: number;
}

export function useSelectedDay(options: Options) {
  const { from } = options;
  const query = useTimeTrackingOverview(options);
  const refetch = query.refetch;

  const selectedDay = useMemo(() => {
    const days: TimeTrackingOverviewItem[] = cloneDeep({
      data: query.data,
    }).data;

    //todo double check
    const selectedDay = days.find((day) => {
      return (
        day.date ===
        DateTime.fromJSDate(from).toFormat('yyyy-MM-dd') + 'T00:00:00Z'
      );
    });

    if (selectedDay?.timeTrackingItems && selectedDay?.timeTrackingItems.length)
      selectedDay.timeTrackingItems.sort((a, b) => {
        return a.startDateTime > b.startDateTime
          ? 1
          : a.startDateTime < b.startDateTime
          ? -1
          : a.insertTime > b.insertTime
          ? 1
          : a.insertTime < b.insertTime
          ? -1
          : 0;
      });

    return selectedDay ?? null;
  }, [query.data, from]);

  return { selectedDay, refetch };
}

export function useTimeTrackingOverview(options: Options) {
  const { disabled, from, to, userId } = options;

  const request = useMemo(() => {
    const fields: TimeTrackingOverviewItem = {
      date: null,
      targetTime: null,
      trackedTime: null,
      timeTrackingItems: [
        {
          id: null,
          date: null,
          startDateTime: null,
          insertTime: null,
          endDateTime: null,
          timeTrackingKind: null,
          creator: {
            id: null,
          },
          amount: null,
          timeUnits: null,
          comment: null,
          pause: null,
          customer: {
            id: null,
            name: null,
            number: null,
            website: null,
          },
          user: {
            id: null,
            displayName: null,
            shortName: null,
          },
          project: {
            id: null,
            name: null,
            number: null,
          },
          projectProcess: {
            id: null,
            process: null,
          },
          contract: {
            id: null,
            number: null,
            contractNumber: null,
            note: null,
            positionList: [
              {
                id: null,
                index: null,
                number: null,
                shortText: null,
                longtext: null,
                positionKind: null,
                amount: null,
                unit: null,
              },
            ],
            businessPartnerId: null,
            businessPartnerType: null,
          },
          position: {
            id: null,
            number: null,
            shortText: null,
            longtext: null,
          },
          positionId: null,
          ticket: {
            id: null,
            title: null,
            number: null,
          },
          activityArticle: {
            id: null,
            name: null,
            number: null,
          },
          customFieldList: [CUSTOM_FIELDS_DATA],
        },
      ],
    };

    const request: DataRequest = {
      entity: Entities.timeTrackingOverviewItem,
      data: fields,
      operationName: 'GetTimeTrackingOverview',
      vars: {
        from: DateTime.fromJSDate(from).toFormat('yyyy-MM-dd'),
        to: DateTime.fromJSDate(to).toFormat('yyyy-MM-dd'),
        userCode: userId,
      },
      completeDataResponse: true,
    };

    return request;
  }, [from, to, userId]);

  const query = useDataProvider<TimeTrackingOverviewItem>(request, disabled);

  return query;
}
