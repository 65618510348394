import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputZeiterfassung<T extends EMode = EMode.entity> {
  /** Alias: abrechenbar */
  abrechenbar?: boolean;
  /** Alias: anteil */
  anteil?: number;
  /** Alias: anzahl */
  anzahl?: number;
  /** Alias: bemerkung */
  bemerkung?: string;
  /** Alias: benutzerCode */
  benutzerCode?: number;
  /** Alias: bZObjMemberCode */
  bZObjMemberCode?: number;
  /** Alias: bZObjMemberType */
  bZObjMemberType?: number;
  /** Alias: code */
  code?: number;
  /** Alias: datum */
  datum?: string;
  /** Alias: datumUhrzeitBis */
  datumUhrzeitBis?: string;
  /** Alias: datumUhrzeitVon */
  datumUhrzeitVon?: string;
  /** Alias: einheitCode */
  einheitCode?: number;
  /** Alias: eKPreis */
  eKPreis?: number;
  /** Alias: gesperrt */
  gesperrt?: number;
  /** Alias: kAnsprechpartnerCode */
  kAnsprechpartnerCode?: number;
  /** Alias: kNCode */
  kNCode?: number;
  /** Alias: kNType */
  kNType?: number;
  /** Alias: kosten */
  kosten?: number;
  /** Alias: kostenart */
  kostenart?: number;
  /** Alias: lohnartCode */
  lohnartCode?: number;
  /** Alias: lohnsatz */
  lohnsatz?: number;
  /** Alias: lookupArtCode */
  lookupArtCode?: number;
  /** Alias: mAText */
  mAText?: string;
  /** Alias: mitarbeiterCode */
  mitarbeiterCode?: number;
  /** Alias: miteID */
  miteID?: string;
  /** Alias: pause */
  pause?: number;
  /** Alias: planungCode */
  planungCode?: number;
  /** Alias: positionCode */
  positionCode?: number;
  /** Alias: projectCode */
  projectCode?: number;
  /** Alias: rechnungCode */
  rechnungCode?: number;
  /** Alias: rECode */
  rECode?: number;
  /** Alias: scanDatei */
  scanDatei?: string;
  /** Alias: sDObjMemberCode */
  sDObjMemberCode?: number;
  /** Alias: status */
  status?: number;
  /** Alias: taetigkeit */
  taetigkeit?: string;
  /** Alias: taetigkeitCode */
  taetigkeitCode?: number;
  /** Alias: ticketNumber */
  ticketNumber?: number;
  /** Alias: uhrzeitBis */
  uhrzeitBis?: string;
  /** Alias: uhrzeitVon */
  uhrzeitVon?: string;
  /** Alias: wartungsobjektCode */
  wartungsobjektCode?: number;
  /** Alias: zeiteinheiten */
  zeiteinheiten?: number;
  /** Alias: erfassungArt */
  erfassungArt?: any;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];
  /** Alias: erstellerCode */
  creatorId?: number;

  __typename?: string;
}

const fields: FieldDefinitions<InputZeiterfassung> = {
  abrechenbar: {
    alias: 'abrechenbar',
  },
  anteil: {
    alias: 'anteil',
  },
  anzahl: {
    alias: 'anzahl',
  },
  bemerkung: {
    alias: 'bemerkung',
  },
  benutzerCode: {
    alias: 'benutzerCode',
  },
  bZObjMemberCode: {
    alias: 'bZObjMemberCode',
  },
  bZObjMemberType: {
    alias: 'bZObjMemberType',
  },
  code: {
    alias: 'code',
  },
  datum: {
    alias: 'datum',
  },
  datumUhrzeitBis: {
    alias: 'datumUhrzeitBis',
  },
  datumUhrzeitVon: {
    alias: 'datumUhrzeitVon',
  },
  einheitCode: {
    alias: 'einheitCode',
  },
  eKPreis: {
    alias: 'eKPreis',
  },
  gesperrt: {
    alias: 'gesperrt',
  },
  kAnsprechpartnerCode: {
    alias: 'kAnsprechpartnerCode',
  },
  kNCode: {
    alias: 'kNCode',
  },
  kNType: {
    alias: 'kNType',
  },
  kosten: {
    alias: 'kosten',
  },
  kostenart: {
    alias: 'kostenart',
  },
  lohnartCode: {
    alias: 'lohnartCode',
  },
  lohnsatz: {
    alias: 'lohnsatz',
  },
  lookupArtCode: {
    alias: 'lookupArtCode',
  },
  mAText: {
    alias: 'mAText',
  },
  mitarbeiterCode: {
    alias: 'mitarbeiterCode',
  },
  miteID: {
    alias: 'miteID',
  },
  pause: {
    alias: 'pause',
  },
  planungCode: {
    alias: 'planungCode',
  },
  positionCode: {
    alias: 'positionCode',
  },
  projectCode: {
    alias: 'projectCode',
  },
  rechnungCode: {
    alias: 'rechnungCode',
  },
  rECode: {
    alias: 'rECode',
  },
  scanDatei: {
    alias: 'scanDatei',
  },
  sDObjMemberCode: {
    alias: 'sDObjMemberCode',
  },
  status: {
    alias: 'status',
  },
  taetigkeit: {
    alias: 'taetigkeit',
  },
  taetigkeitCode: {
    alias: 'taetigkeitCode',
  },
  ticketNumber: {
    alias: 'ticketNumber',
  },
  uhrzeitBis: {
    alias: 'uhrzeitBis',
  },
  uhrzeitVon: {
    alias: 'uhrzeitVon',
  },
  wartungsobjektCode: {
    alias: 'wartungsobjektCode',
  },
  zeiteinheiten: {
    alias: 'zeiteinheiten',
  },
  erfassungArt: {
    alias: 'erfassungArt',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customFields: {
    alias: 'customFields',
  },
  creatorId: {
    alias: 'erstellerCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputZeiterfassungEntityDefinition: EntitiyDefinition<InputZeiterfassung> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputZeiterfassung',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
