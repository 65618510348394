import { orderBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DefaultErpText } from '@work4all/models/lib/Classes/DefaultErpText.entity';
import { DefaultErpTextType } from '@work4all/models/lib/Enums/DefaultErpTextType.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useMaskContext } from '../../../../../hooks/mask-context';

const useInitialValues = (entity: Entities) => {
  const { t } = useTranslation();
  return useMemo(() => {
    let internalTabs: { label: string; value: string }[] = [];
    if (entity === Entities.customer)
      internalTabs = [
        {
          label: t('COMMON.OFFER'),
          value: 'OFFER',
        },
        {
          label: t('COMMON.DELIVERYNOTE'),
          value: 'DELIVERY_NOTE',
        },
        {
          label: t('COMMON.INVOICE'),
          value: 'INVOICE',
        },
        {
          label: t('COMMON.CONTRACT'),
          value: 'ORDER',
        },
      ];
    else
      internalTabs = [
        {
          label: t('COMMON.DELIVERYNOTE'),
          value: 'DELIVERY_NOTE',
        },
        {
          label: t('COMMON.ORDER'),
          value: 'BOOKING',
        },
        {
          label: t('COMMON.DEMAND'),
          value: 'DEMAND',
        },
      ];

    return orderBy(internalTabs, 'label');
  }, [t, entity]);
};

export const useTextTabPanel = () => {
  const mask = useMaskContext();

  const tabs = useInitialValues(mask.entity);

  const [tab, setTab] = useState<string>(tabs[0].value);

  const handleTabChange = (_: unknown, tab: string) => {
    setTab(tab);
  };

  return { tab, tabs, handleTabChange };
};

interface UseEnsureErpTextsInitializedOptions {
  isDataFetched: boolean;
  defaultErpTexts?: DefaultErpText[];
  setValue: (erp: DefaultErpText[]) => void;
  entity: Entities;
}

export const useEnsureErpTextsInitialized = (
  options: UseEnsureErpTextsInitializedOptions
) => {
  const { isDataFetched, defaultErpTexts, setValue, entity } = options;

  const initalValues = useInitialValues(entity);

  useEffect(() => {
    if (isDataFetched && defaultErpTexts) {
      const mapToEntity = (type: string): DefaultErpText[] =>
        initalValues.map((x) => ({
          textType: `${x.value}_${type}` as DefaultErpTextType,
        }));

      const initialDefaultErpTexts: DefaultErpText[] = [
        ...mapToEntity('CLOSING'),
        ...mapToEntity('HEADER'),
      ];

      let needUpdate = false;
      const combined = initialDefaultErpTexts.map((initial) => {
        const exist = defaultErpTexts.find(
          (y) => y.textType === initial.textType
        );
        if (exist) return exist;
        needUpdate = true;
        return initial;
      });

      if (needUpdate) {
        setValue(combined);
      }
    }
  }, [isDataFetched, defaultErpTexts, setValue, initalValues]);
};
