import { useState } from 'react';

export function useSelectedIndex(options: {
  length: number;
}): [number, (index: number) => void] {
  const { length } = options;

  const [state, setState] = useState(-1);

  const index = Math.min(state, length - 1);

  return [index, setState];
}
