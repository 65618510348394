import { MentionDataset } from '../constants';

export function createMentionElement({
  id,
  text,
}: {
  id: string;
  text: string;
}): HTMLElement {
  const element = document.createElement('span');

  element.dataset[MentionDataset.Mention] = 'user';
  element.dataset[MentionDataset.MentionUser] = id;
  element.contentEditable = 'false';
  element.className = 'fr-deletable';
  element.textContent = text;

  return element;
}
