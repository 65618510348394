import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';

export const sortSearchResults = <
  T extends Customer | Supplier | Project | Contact
>(
  results: T[]
) => {
  return results.sort((a, b) =>
    (
      a.name.toLowerCase() +
      ' ' +
      (a as Contact)?.firstName?.toLowerCase()
    ).trim() >
    (
      b.name.toLowerCase() +
      ' ' +
      (b as Contact)?.firstName?.toLowerCase()
    ).trim()
      ? 1
      : -1
  );
};
