import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { FileRequestType } from '../Enums/FileRequestType.enum';

import { FileMetaInfo } from './FileMetaInfo.entity';

export class FileEntity<T extends EMode = EMode.entity> {
  /** Alias: fileEntityFilename */
  fileEntityFilename?: string;
  /** Alias: downloadUrl */
  downloadUrl?: string;
  /** Alias: previewUrl */
  previewUrl?: string;
  /** Alias: fileRequestType */
  fileRequestType?: FileRequestType;
  /** Alias: downloadMimeType */
  downloadMimeType?: string;
  /** Alias: previewMimeType */
  previewMimeType?: string;
  /** Alias: fileSize */
  fileSize?: number;
  /** Alias: fileServiceProviderInfos */
  fileServiceProviderInfos?: FileMetaInfo<T>;

  __typename?: string;
}

const fields: FieldDefinitions<FileEntity> = {
  fileEntityFilename: {
    alias: 'fileEntityFilename',
  },
  downloadUrl: {
    alias: 'downloadUrl',
  },
  previewUrl: {
    alias: 'previewUrl',
  },
  fileRequestType: {
    alias: 'fileRequestType',
  },
  downloadMimeType: {
    alias: 'downloadMimeType',
  },
  previewMimeType: {
    alias: 'previewMimeType',
  },
  fileSize: {
    alias: 'fileSize',
  },
  fileServiceProviderInfos: {
    alias: 'fileServiceProviderInfos',
    entity: Entities.fileMetaInfo,
  },
  __typename: {
    alias: '__typename',
  },
};

export const fileEntityEntityDefinition: EntitiyDefinition<FileEntity> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'FileEntity',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
