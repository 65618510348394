import Typography from '@mui/material/Typography';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ListEntityPicker } from '@work4all/components/lib/components/entity-picker/components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '@work4all/components/lib/components/entity-picker/types';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { InvoiceForm } from '@work4all/models/lib/Enums/InvoiceForm.enum';

export const InvoiceFormTranslationKeys: Record<string, string> = {
  [InvoiceForm.ELEKTRONISCH]: 'INVOICE_FORM.ELEKTRONISCH',
  [InvoiceForm.PAPIER]: 'INVOICE_FORM.PAPIER',
};

export type IInvoiceFormPickerProps<TMultiple extends boolean> = IPickerProps<
  { id: string; name: string },
  TMultiple
>;

export function InvoiceFormPicker<TMultiple extends boolean>(
  props: IInvoiceFormPickerProps<TMultiple>
) {
  const { ...rest } = props;

  const { t } = useTranslation();

  const dataSet = useMemo(() => {
    const taskStatusValues: { id: string; name: string }[] = [
      {
        id: InvoiceForm.ELEKTRONISCH,
        name: t(InvoiceFormTranslationKeys[InvoiceForm.ELEKTRONISCH]),
      },
      {
        id: InvoiceForm.PAPIER,
        name: t(InvoiceFormTranslationKeys[InvoiceForm.PAPIER]),
      },
    ];

    return {
      total: taskStatusValues.length,
      loading: false,
      fetchMore: () => Promise.resolve(),
      refetch: () => Promise.resolve(null),
      data: taskStatusValues,
    };
  }, [t]);

  return (
    <ListEntityPicker
      entity={Entities.customer}
      multiple={false}
      maxItems={Math.max(dataSet.total, 3)}
      data={INVOICE_FORM_FIELDS}
      filterBy="name"
      sortBy="name"
      clearable={false}
      {...rest}
      fixedDataSet={dataSet}
      renderItemContent={(status) => (
        <Typography variant="body2" noWrap>
          {status.name}
        </Typography>
      )}
    />
  );
}

const INVOICE_FORM_FIELDS = {
  id: null,
  name: null,
};
