import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { PartialCalculationLogicDetails } from './PartialCalculationLogicDetails.entity';

export class PartialInvoiceLogic<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: details */
  details?: PartialCalculationLogicDetails<T>[];
  /** Alias: langtext */
  longtext?: string;
  /** Alias: name */
  name?: string;

  __typename?: string;
}

const fields: FieldDefinitions<PartialInvoiceLogic> = {
  id: {
    alias: 'code',
  },
  details: {
    alias: 'details',
    entity: Entities.partialCalculationLogicDetails,
  },
  longtext: {
    alias: 'langtext',
  },
  name: {
    alias: 'name',
  },
  __typename: {
    alias: '__typename',
  },
};

export const partialInvoiceLogicEntityDefinition: EntitiyDefinition<PartialInvoiceLogic> =
  {
    local: {},
    remote: {
      queryName: 'getTeilrechnungLogik',
      fragmentName: 'TeilrechnungLogik',
      withPaginationWrapper: false,
      params: [{ name: 'codes', type: '[Int]' }],
    },
    fieldDefinitions: fields,
  };
