import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { ObjectType } from '../Enums/ObjectType.enum';

import { User } from './User.entity';

export class CreateEvent<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: creationDate */
  creationDate?: string;
  /** Alias: objectType */
  objectType?: ObjectType;
  /** Alias: objectId */
  objectId?: string;
  /** Alias: userCode */
  userId?: number;
  /** Alias: user */
  user?: User<T>;

  __typename?: string;
}

const fields: FieldDefinitions<CreateEvent> = {
  id: {
    alias: 'id',
  },
  creationDate: {
    alias: 'creationDate',
  },
  objectType: {
    alias: 'objectType',
  },
  objectId: {
    alias: 'objectId',
  },
  userId: {
    alias: 'userCode',
  },
  user: {
    alias: 'user',
    entity: Entities.user,
  },
  __typename: {
    alias: '__typename',
  },
};

export const createEventEntityDefinition: EntitiyDefinition<CreateEvent> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'CreateEvent',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
