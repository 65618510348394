import React, { useMemo } from 'react';

import { numbersToString } from '../../utils/filters/filterConversion';
import { FilterCard } from '../filter-card/FilterCard';
import { NumberRangePicker } from '../number-range-picker/NumberRangePicker';

interface INumberFilterCardProps {
  from: number;
  to: number;
  onChange: (from: number, to: number) => void;
  onDelete: () => void;
  onExpandedChange: (val: boolean) => void;
  title: string;
  expanded: boolean;
}

export const NumberFilterCard: React.FC<INumberFilterCardProps> = (props) => {
  const { title, onChange, from, to, expanded, onExpandedChange, onDelete } =
    props;

  const filterValueText: string = useMemo(() => {
    return numbersToString(from, to);
  }, [from, to]);

  return (
    <FilterCard
      title={title}
      filterValue={filterValueText}
      filterValueSet={!(from === null && to === null)}
      expanded={expanded}
      onExpandedChange={onExpandedChange}
      onValueDelete={() => {
        onDelete();
      }}
    >
      <NumberRangePicker
        from={from}
        to={to}
        onChange={(start, end) => {
          !start && !end ? onDelete() : onChange(start, end);
        }}
      />
    </FilterCard>
  );
};
