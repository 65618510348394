import styles from './statistics-styles.module.scss';

import { Theme, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { CostCenter } from '@work4all/models/lib/Classes/CostCenter.entity';
import { StatisticSalesVoumeByCostCenter } from '@work4all/models/lib/Classes/StatisticSalesVoumeByCostCenter.entity';
import { StatisticsSalesVolumeByCostCenterColumn } from '@work4all/models/lib/Classes/StatisticsSalesVolumeByCostCenterColumn.entity';
import { StatisticsSalesVolumeByCostCenterRow } from '@work4all/models/lib/Classes/StatisticsSalesVolumeByCostCenterRow.entity';

import { BasicTable } from '../BasicTable';
import { BasicTableColumn } from '../types';
import { CurrencyCell } from '../utils';

import { getStatisticFormatedDate } from './getStatisticFormatedDate';

interface StatisticSalesVolumeByCostCenterTableProps {
  data: StatisticSalesVoumeByCostCenter;
}

interface MonthDefinition {
  amount: number;
  date: string;
}

interface StatisticItemRemodeled {
  costCenter: CostCenter;
  sum: number;
  months: MonthDefinition[];
}

const GetTotalSum = (
  months: StatisticsSalesVolumeByCostCenterColumn[],
  rowIndex: number
) => {
  let result = 0;
  months.forEach((column) => {
    result += column.rows?.[rowIndex].amount;
  });

  return result;
};

const GetTotalMonthValue = (data: StatisticItemRemodeled[], month: number) => {
  let result = 0;

  data.forEach((row) => {
    result += row.months[month].amount;
  });

  if (result === 0) {
    return '';
  } else {
    return (
      <CurrencyCell
        className={clsx({ [styles.red]: result < 0 })}
        value={result}
        maximumFractionDigits={0}
        minimumFractionDigits={0}
        hideUnit={false}
      />
    );
  }
};

const GetTotalTotalSumValue = (data: StatisticItemRemodeled[]) => {
  let result = 0;

  data.forEach((row) => {
    result += row?.sum || 0;
  });

  if (result === 0) {
    return '';
  } else {
    return (
      <CurrencyCell
        className={clsx({ [styles.red]: result < 0 })}
        value={result}
        maximumFractionDigits={0}
        minimumFractionDigits={0}
        hideUnit={false}
      />
    );
  }
};

export const StatisticSalesVolumeByCostCenterTable: React.FC<
  StatisticSalesVolumeByCostCenterTableProps
> = (props) => {
  const { data } = props;

  const isViewportDownMd = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  const { t } = useTranslation();

  const newData: StatisticItemRemodeled[] = useMemo(() => {
    let result: StatisticItemRemodeled[] = [];

    if (data.columns === undefined) {
      return [];
    }
    const months = [...data.columns].sort((a, b) => {
      if (new Date(a.rangeStart) > new Date(b.rangeStart)) {
        return 1;
      }
      if (new Date(a.rangeStart) < new Date(b.rangeStart)) {
        return -1;
      }
      return 0;
    });

    const unknonwCostCenters: StatisticItemRemodeled[] = [];
    const knonwCostCenters: StatisticItemRemodeled[] = [];
    months?.[0].rows.forEach(
      (row: StatisticsSalesVolumeByCostCenterRow, rowIndex) => {
        if (row.costCenter === null) {
          const unknownCostCenter: StatisticItemRemodeled = {
            costCenter: row.costCenter,
            sum: GetTotalSum(months, rowIndex),
            months: months.map((col) => {
              return {
                amount: col.rows?.[rowIndex].amount,
                date: col.rows?.[rowIndex].date,
              };
            }),
          };
          unknonwCostCenters.push(unknownCostCenter);
        }
        if (row.costCenter !== null) {
          const knownCostCenter: StatisticItemRemodeled = {
            costCenter: row.costCenter,
            sum: GetTotalSum(months, rowIndex),
            months: months.map((col) => {
              return {
                amount: col.rows?.[rowIndex].amount,
                date: col.rows?.[rowIndex].date,
              };
            }),
          };
          knonwCostCenters.push(knownCostCenter);
        }
      }
    );

    if (months?.[0]?.rows.length === 0) {
      knonwCostCenters.push({
        costCenter: null,
        months: months.map((col) => {
          return {
            amount: 0,
            date: col.rangeStart,
          };
        }),
        sum: 0,
      });
    }

    const unknownCostCentersReduce: StatisticItemRemodeled[] = [];
    unknownCostCentersReduce.push(unknonwCostCenters[0]);
    unknonwCostCenters.slice(1).forEach((row: StatisticItemRemodeled) => {
      row.months.forEach((month: MonthDefinition, colIndex) => {
        unknownCostCentersReduce[0].months[colIndex].amount += month.amount;
      });
    });

    if (unknownCostCentersReduce[0]) {
      knonwCostCenters.push(unknownCostCentersReduce[0]);
    }
    result = knonwCostCenters;

    result = result.sort((a, b) => {
      if (a.sum > b.sum) {
        return -1;
      }
      if (a.sum < b.sum) {
        return 1;
      }
      return 0;
    });

    return result;
  }, [data]);

  const columns = useMemo<BasicTableColumn[]>(() => {
    const defaultProperties: BasicTableColumn = {
      accessor: '',
      width: 130,
      Cell: ({ value }: { value: number }) => {
        if (value === 0) {
          return '';
        }
        return (
          <CurrencyCell
            className={clsx({ [styles.red]: value < 0 })}
            value={value}
            maximumFractionDigits={0}
            minimumFractionDigits={0}
            hideUnit={true}
          />
        );
      },
      alignment: 'right',
    };

    let result: BasicTableColumn[] = [
      {
        Header: t('COMMON.COST_CENTER'),
        accessor: 'costCenter.name',
        sticky: 'left',
        width: isViewportDownMd ? 150 : 300,
        Cell: ({ value }: { value: string }) => {
          if (value === undefined) {
            return 'ohne Kostenstelle';
          }
          return <span>{value}</span>;
        },
      },

      {
        ...defaultProperties,
        Header: t('COMMON.SUM'),
        accessor: 'sum',
        Footer: GetTotalTotalSumValue(newData),
        Cell: ({ value }: { value: number }) => {
          if (value === 0) {
            return '';
          }
          return (
            <CurrencyCell
              className={clsx({ [styles.red]: value < 0 })}
              value={value}
              maximumFractionDigits={0}
              minimumFractionDigits={0}
              hideUnit={true}
            />
          );
        },
      },
    ];

    if (newData[0] !== undefined) {
      result = result.concat(
        newData?.[0].months.map((month, monthIndex) => {
          return {
            ...defaultProperties,
            Header: getStatisticFormatedDate(new Date(month.date), 'MMM yyyy'),
            accessor: `months[${monthIndex}].amount`,
            Footer: GetTotalMonthValue(newData, monthIndex),
          };
        })
      );
    }

    return result;
  }, [newData, t, isViewportDownMd]);

  const apiRef = useRef<TableInstance>(null);

  return (
    <BasicTable
      displayFooter={true}
      mode="client"
      ref={apiRef}
      reordableColumns={true}
      resizableColumns={false}
      selectableRows={true}
      data={newData}
      columns={columns}
      allItemsCount={newData.length || 0}
      cardsView={false}
      selectableMultiple={false}
      className={styles.table}
    />
  );
};
