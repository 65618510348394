import styles from './GeneralTabPanel.module.scss';

import { Box, Theme, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { Left } from './components/left/Left';
import { Right } from './components/right/Right';

export const GeneralTabPanel: React.FC = () => {
  const mobile = useMediaQuery<Theme>('(max-width:960px)');

  return (
    <Box className={clsx(styles.root, { [styles.mobile]: mobile })}>
      <Left mobile={mobile} />
      <Right mobile={mobile} />
    </Box>
  );
};
