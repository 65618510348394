import styles from './Heading4.module.scss';

import clsx from 'clsx';

import { Heading4 as RawHeading4 } from '@work4all/components/lib/typography/heading4/Heading4';

export const Heading4: typeof RawHeading4 = (props) => {
  return (
    <RawHeading4 className={clsx(styles.heading, props.className)}>
      {props.children}
    </RawHeading4>
  );
};
