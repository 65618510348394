import { BusinessPartnerPicker } from '@work4all/components/lib/components/entity-picker/business-partner-picker/BusinessPartnerPicker';
import { ContactPicker } from '@work4all/components/lib/components/entity-picker/contact-picker/ContactPicker';
import { CostCenterPicker } from '@work4all/components/lib/components/entity-picker/costCenter-Picker/CostCenterPicker';
import { CustomFieldComboPicker } from '@work4all/components/lib/components/entity-picker/custom-field-combo-picker/CustomFieldComboPicker';
import { CustomerPicker } from '@work4all/components/lib/components/entity-picker/customer-picker/CustomerPicker';
import {
  ArticleGroupPicker,
  CustomerGroupPicker,
  ProjectGroupPicker,
  SupplierGroupPicker,
} from '@work4all/components/lib/components/entity-picker/entity-group-picker/EntityGroupPicker';
import { LookUpPicker } from '@work4all/components/lib/components/entity-picker/look-up-picker/LookUpPicker';
import { MailAssignStatusPicker } from '@work4all/components/lib/components/entity-picker/MailAssignStatusPicker/MailAssignStatusPicker';
import { ProjectPicker } from '@work4all/components/lib/components/entity-picker/project-picker/ProjectPicker';
import { SupplierPicker } from '@work4all/components/lib/components/entity-picker/supplier-picker/SupplierPicker';
import { TicketKindPicker } from '@work4all/components/lib/components/entity-picker/TicketKindPicker';
import { UserPicker } from '@work4all/components/lib/components/entity-picker/user-picker/UserPicker';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { IndividualFieldControlType } from '@work4all/models/lib/Enums/IndividualFieldControlType.enum';

export function getConditionalPicker(
  entity: Entities | string,
  type?: IndividualFieldControlType
) {
  switch (entity) {
    case Entities.user:
      return UserPicker;
    case Entities.project:
      return ProjectPicker;
    case Entities.customer:
      return CustomerPicker;
    case Entities.supplier:
      return SupplierPicker;
    case Entities.businessPartner:
      return BusinessPartnerPicker;
    case Entities.contact:
      return ContactPicker;
    case Entities.ticketKind:
      return TicketKindPicker;
    case Entities.customerGroup:
      return CustomerGroupPicker;
    case Entities.supplierGroup:
      return SupplierGroupPicker;
    case Entities.articleGroup:
      return ArticleGroupPicker;
    case Entities.projectGroup:
      return ProjectGroupPicker;
    case Entities.lookUp:
      return LookUpPicker;
    case Entities.costCenter:
      return CostCenterPicker;
    case 'mailAssignStatus':
      return MailAssignStatusPicker;
    case Entities.customField:
      return getConditionalCustomFieldPicker(type);
    default:
      return null;
  }
}

const getConditionalCustomFieldPicker = (type: IndividualFieldControlType) => {
  switch (type) {
    case IndividualFieldControlType.COMBO:
      return CustomFieldComboPicker;
    default:
      return null;
  }
};
