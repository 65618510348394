import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { BackgroundImageType } from '../Enums/BackgroundImageType.enum';
import { EMode } from '../Enums/EMode.enum';

export class AppearanceSettingsInputAddBackgroundImage<
  T extends EMode = EMode.entity
> {
  /** Alias: name */
  name?: string;
  /** Alias: fromTempFile */
  fromTempFile?: string | number;
  /** Alias: bgImageType */
  bgImageType?: BackgroundImageType;
  /** Alias: objectCode */
  objectCode?: number;

  __typename?: string;
}

const fields: FieldDefinitions<AppearanceSettingsInputAddBackgroundImage> = {
  name: {
    alias: 'name',
  },
  fromTempFile: {
    alias: 'fromTempFile',
  },
  bgImageType: {
    alias: 'bgImageType',
  },
  objectCode: {
    alias: 'objectCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const appearanceSettingsInputAddBackgroundImageEntityDefinition: EntitiyDefinition<AppearanceSettingsInputAddBackgroundImage> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'AppearanceSettingsInputAddBackgroundImage',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
