import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class LookUp<T extends EMode = EMode.entity> {
  /** Alias: abgeschlossenStatus */
  closedStatus?: number;
  /** Alias: anzeigenAls */
  showAs?: string;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: code */
  id?: number;
  /** Alias: farbe */
  color?: string;
  /** Alias: gruppenAnsicht */
  groupView?: number;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: lookUpCode */
  lookUpId?: number;
  /** Alias: name */
  name?: string;
  /** Alias: objArt */
  objKind?: number;
  /** Alias: standard */
  standard?: number;
  /** Alias: symbol */
  symbol?: number;
  /** Alias: textwert */
  textValue?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: urlaubsartKurzarbeit */
  isVacationKindForShortTimeWork?: boolean;
  /** Alias: wert */
  value?: number;
  /** Alias: wertDouble */
  valueDouble?: number;
  /** Alias: hexColorPair */
  hexColorPair?: string;

  __typename?: string;
}

const fields: FieldDefinitions<LookUp> = {
  closedStatus: {
    alias: 'abgeschlossenStatus',
  },
  showAs: {
    alias: 'anzeigenAls',
  },
  userId: {
    alias: 'benutzerCode',
  },
  id: {
    alias: 'code',
  },
  color: {
    alias: 'farbe',
  },
  groupView: {
    alias: 'gruppenAnsicht',
  },
  insertTime: {
    alias: 'insertTime',
  },
  lookUpId: {
    alias: 'lookUpCode',
  },
  name: {
    alias: 'name',
  },
  objKind: {
    alias: 'objArt',
  },
  standard: {
    alias: 'standard',
  },
  symbol: {
    alias: 'symbol',
  },
  textValue: {
    alias: 'textwert',
  },
  updateTime: {
    alias: 'updateTime',
  },
  isVacationKindForShortTimeWork: {
    alias: 'urlaubsartKurzarbeit',
  },
  value: {
    alias: 'wert',
  },
  valueDouble: {
    alias: 'wertDouble',
  },
  hexColorPair: {
    alias: 'hexColorPair',
  },
  __typename: {
    alias: '__typename',
  },
};

export const lookUpEntityDefinition: EntitiyDefinition<LookUp> = {
  local: {},
  remote: {
    queryName: 'getLookups',
    fragmentName: 'LookUp',
    withPaginationWrapper: false,
    params: [
      { name: 'type', type: 'LookupType!' },
      { name: 'codes', type: '[Int]' },
    ],
  },
  fieldDefinitions: fields,
};
