import { cloneDeep } from 'lodash';
import { createContext, useCallback } from 'react';

import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { UseSettingReturn } from '../../settings';

export class IFavoritesContext {
  register: Record<Entities | string, Array<string | number>>;
  transformDataRequest: (dataRequest: DataRequest) => DataRequest;
  toggleFavorite: (entity: Entities, id: string | number) => void;
}

export const FavoritesContext = createContext<IFavoritesContext | null>(null);

export const FavoritesContextProvider =
  FavoritesContext.Provider as React.Provider<IFavoritesContext>;

export function useFavoritesContext(
  favoritesRegister: UseSettingReturn<Record<string, Array<string | number>>>
): IFavoritesContext {
  const transformDataRequest = useCallback(
    (request: DataRequest) => {
      return {
        ...request,
        filter: [
          {
            $and: [
              ...(request?.filter ? request.filter : []),
              {
                id: {
                  $in: favoritesRegister.value?.[request.entity] || ['0'],
                },
              },
            ],
          },
        ],
      };
    },
    [favoritesRegister.value]
  );

  const toggleFavorite = useCallback(
    (entity: Entities, id: string | number) => {
      const idList: Array<string | number> = cloneDeep(
        favoritesRegister.value[entity] || []
      );

      if (!idList.includes(id)) {
        idList.push(id);
      } else {
        idList.splice(idList.indexOf(id), 1);
      }
      favoritesRegister.set({
        ...favoritesRegister.value,
        [entity]: idList,
      });
    },
    [favoritesRegister]
  );

  return {
    transformDataRequest,
    register: favoritesRegister.value,
    toggleFavorite,
  };
}

export interface FavoritesProviderProps {
  children?: React.ReactNode;
  favoritesRegister: UseSettingReturn<Record<string, Array<string | number>>>;
}

export function FavoritesProvider(props: FavoritesProviderProps) {
  const context = useFavoritesContext(props.favoritesRegister);

  return (
    <FavoritesContextProvider value={context}>
      {props.children}
    </FavoritesContextProvider>
  );
}
