import { Theme, useMediaQuery } from '@mui/material';
import React, { useCallback, useMemo, useRef } from 'react';
import CanvasDraw from 'react-canvas-draw';

import { useSecureBlobUrl } from '@work4all/data';
import { useRemToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { IAttachmentEntity } from '@work4all/models';

type SignatureCanvas = CanvasDraw & {
  getDataURL: () => string;
};

const BORDER = 2;
const MAX_BRUSH = 3;
const MAX_HEIGHT = 220 + BORDER;
const MAX_WIDTH = 440 + BORDER;

function resizeImage(dataURL: string, maxWidth: number, maxHeight: number) {
  return new Promise<Blob>((resolve) => {
    const image = new Image();
    image.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = maxWidth;
      canvas.height = maxHeight;
      canvas.getContext('2d').drawImage(image, 0, 0, maxWidth, maxHeight);

      canvas.toBlob(
        function (blob) {
          resolve(blob);
        },
        'image/png',
        1
      );
    };
    image.src = dataURL;
  });
}

export const useSignatureCanvas = (file?: IAttachmentEntity) => {
  const isSmDown = useMediaQuery<Theme>((t) => t.breakpoints.down('sm'));

  const blobUrl = useSecureBlobUrl(file?.fileInfos?.previewUrl ?? null);
  const rem = useRemToPx(2);

  const canvasRef = useRef<SignatureCanvas>();
  const sizes = useMemo(() => {
    if (!isSmDown)
      return {
        width: MAX_WIDTH,
        height: MAX_HEIGHT,
        brush: 3,
      };
    const smWidth = window.innerWidth - (rem + BORDER + 2 * 32);
    const ratio = smWidth / MAX_WIDTH;
    const smHeight = MAX_HEIGHT * ratio;
    const smBrush = MAX_BRUSH * ratio;
    return {
      width: smWidth,
      height: smHeight,
      brush: smBrush,
    };
  }, [isSmDown, rem]);

  const canvas = useMemo(() => {
    return (
      <CanvasDraw
        ref={(canvasDraw) =>
          (canvasRef.current = canvasDraw as SignatureCanvas)
        }
        hideGrid
        canvasWidth={sizes.width}
        canvasHeight={sizes.height}
        brushColor="#000"
        brushRadius={sizes.brush}
        hideInterface={true}
        imgSrc={blobUrl}
        disabled={Boolean(blobUrl)}
      />
    );
  }, [sizes, blobUrl]);

  const getFile = useCallback(async () => {
    if (!canvasRef.current) {
      console.error('Check the ref of the canvas');
      return;
    }
    const dataUrl = canvasRef.current.getDataURL('png', false, '#fff');

    const blob = await resizeImage(dataUrl, 440, 220);
    const resizedFile = new File([blob], 'signature.png', {
      type: 'image/png',
    });
    return resizedFile;
  }, []);

  return {
    canvas,
    getFile,
  };
};
