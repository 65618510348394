import produce from 'immer';
import { useReducer } from 'react';

import { ReplicationEvent } from '@work4all/data/lib/entities/utils/replication.types';

import { assertNever } from '@work4all/utils';

import { ReplicationStateContextValue } from './types';
import { resolveNextReplicationStatus } from './utils';

type ReducerState = ReplicationStateContextValue;
type ReducerAction = { type: 'event'; event: ReplicationEvent };

const INITIAL_STATE: ReducerState = {
  settings: 'initial',
  layouts: 'initial',
  schemas: 'initial',
};

function reducer(state: ReducerState, action: ReducerAction): ReducerState {
  switch (action.type) {
    case 'event': {
      const { event } = action;

      // Do not reset the "ready" state after it has been set. Exception: do not
      // ignore the "reset" event as we still need to reset the state when a
      // user logs out.
      if (state[event.id] === 'ready' && event.type !== 'reset') return state;

      return produce(state, (draft) => {
        const nextStatus = resolveNextReplicationStatus({ event: event.type });
        draft[event.id] = nextStatus;
      });
    }

    default:
      assertNever(action.type, `Unknown action ${action.type}`);
  }
}

export function useReplicationStateReducer() {
  return useReducer(reducer, INITIAL_STATE);
}
