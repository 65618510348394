import { noop } from 'lodash';
import { useMemo } from 'react';

import { AppearanceBackgroundImage } from '@work4all/models/lib/Classes/AppearanceBackgroundImage.entity';

import {
  CustomBackgroundsContext,
  CustomBackgroundsContextValue,
} from './CustomBackgroundsContext';
import { IsCustomBackgroundActiveContextProvider } from './is-custom-background-active-context';
import { useAppearance } from './use-appearance';

export interface CustomBackgroundsContextProviderProps {
  children: React.ReactNode;
}

const defaultValue: CustomBackgroundsContextValue = {
  isLoading: true,
  isActive: false,
  activeBackground: null,
  standardBackgrounds: [],
  customBackgrounds: [],
  setActiveBackground: noop,
  resetActiveBackground: noop,
  addBackground: noop,
  removeBackground: noop,
};

export function CustomBackgroundsContextProvider(
  props: CustomBackgroundsContextProviderProps
) {
  const { children } = props;

  const {
    appearance,
    loading,
    changeBackground,
    addBackground,
    removeBackground,
  } = useAppearance();

  const contextValue = useMemo<CustomBackgroundsContextValue>(() => {
    if (loading) {
      return defaultValue;
    }

    if (!appearance) {
      return defaultValue;
    }

    const {
      backgroundImagesGeneral = [],
      backgroundImagesTenant = [],
      backgroundImagesUser = [],
      configuration = {},
    } = appearance || {};

    const activeBackgroundId = configuration.selectedBackgroundImage;
    const isActiveBackground = (image: AppearanceBackgroundImage) =>
      image.id === activeBackgroundId;
    const activeBackground =
      activeBackgroundId === null
        ? null
        : backgroundImagesGeneral.find(isActiveBackground) ??
          backgroundImagesTenant.find(isActiveBackground) ??
          backgroundImagesUser.find(isActiveBackground) ??
          null;

    const value: CustomBackgroundsContextValue = {
      isLoading: false,
      isActive: activeBackground !== null,
      activeBackground: activeBackground,
      standardBackgrounds: [
        ...backgroundImagesGeneral,
        ...backgroundImagesTenant,
      ],
      customBackgrounds: backgroundImagesUser,
      setActiveBackground: (id) => {
        changeBackground(id);
      },
      resetActiveBackground: () => {
        changeBackground(null);
      },
      addBackground,
      removeBackground,
    };

    return value;
  }, [loading, appearance, changeBackground, addBackground, removeBackground]);

  return (
    <CustomBackgroundsContext.Provider value={contextValue}>
      <IsCustomBackgroundActiveContextProvider value={contextValue.isActive}>
        {children}
      </IsCustomBackgroundActiveContextProvider>
    </CustomBackgroundsContext.Provider>
  );
}
