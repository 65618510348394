import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { BulkPriceTierItem } from './BulkPriceTierItem.entity';
import { PriceGroup } from './PriceGroup.entity';

export class BulkPrice<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: preisgruppeCode */
  priceGroupId?: number;
  /** Alias: sdObjMemberCode */
  sdObjMemberCode?: number;
  /** Alias: artikelCode */
  articleId?: number;
  /** Alias: preisstaffelItemCode */
  bulkPriceTierItemId?: number;
  /** Alias: preis */
  price?: number;
  /** Alias: einstand */
  costPrice?: number;
  /** Alias: definition */
  bulkPriceTierItem?: BulkPriceTierItem<T>;
  /** Alias: preisgruppe */
  priceGroup?: PriceGroup<T>;

  __typename?: string;
}

const fields: FieldDefinitions<BulkPrice> = {
  id: {
    alias: 'code',
  },
  priceGroupId: {
    alias: 'preisgruppeCode',
  },
  sdObjMemberCode: {
    alias: 'sdObjMemberCode',
  },
  articleId: {
    alias: 'artikelCode',
  },
  bulkPriceTierItemId: {
    alias: 'preisstaffelItemCode',
  },
  price: {
    alias: 'preis',
  },
  costPrice: {
    alias: 'einstand',
  },
  bulkPriceTierItem: {
    alias: 'definition',
    entity: Entities.bulkPriceTierItem,
  },
  priceGroup: {
    alias: 'preisgruppe',
    entity: Entities.priceGroup,
  },
  __typename: {
    alias: '__typename',
  },
};

export const bulkPriceEntityDefinition: EntitiyDefinition<BulkPrice> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'Staffelpreis',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
