import styles from './Base.module.scss';

import React from 'react';

import {
  BaseActionButton,
  IBaseActionButtonProps,
} from '../../base-action-button/BaseActionButton';

export const Base: React.FC<IBaseActionButtonProps> = (props) => {
  return <BaseActionButton className={styles.button} {...props} />;
};
