import styles from './Tabs.module.scss';

import { Tabs as MUITabs } from '@mui/material';
import React from 'react';

import { Tab } from '../components/tab/Tab';
import { ITabsProps } from '../types';

const classes = { indicator: styles.indicator };

export const Tabs: React.FC<ITabsProps> = (props) => {
  return (
    <MUITabs
      classes={classes}
      value={props.value}
      onChange={props.handleChange}
      variant="scrollable"
    >
      {props.tabs.map((tab, i) => {
        const value = tab.value !== undefined ? tab.value : i;
        return (
          <Tab
            disabled={tab.disabled}
            key={props.tabs.length + '-' + value}
            {...tab}
            value={value}
          />
        );
      })}
    </MUITabs>
  );
};
