import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  CreditorPicker,
  CreditorPickerProps,
  renderCreditorPickerItem,
} from '@work4all/components/lib/components/entity-picker/creditor-picker/CreditorPicker';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { reactRefSetter } from '@work4all/utils';

import { LabeledInput } from '../../containers/mask-overlays/locked-inputs';

import { EntityPickerActions } from './EntityPickerActions';

interface CreditorPickerFieldProps<TMultiple extends boolean>
  extends Omit<CreditorPickerProps<TMultiple>, 'multiple'> {
  label?: string;
  error?: string;
  disabled?: boolean;
  field?: JSX.Element;
  required?: boolean;
}

const createCreditorPickerField = () => {
  return React.forwardRef<HTMLDivElement, CreditorPickerFieldProps<false>>(
    function CreditorPickerField(props, ref) {
      const {
        field,
        error,
        disabled,
        label,
        favorites = true,
        ...pickerProps
      } = props;
      const { value, onChange } = pickerProps;
      const { t } = useTranslation();

      const wrapperRef = useRef<HTMLDivElement>(null);
      const popoverRef = useRef<EntityPickerPopover>(null);

      const onClear = props.clearable ? () => onChange(null) : undefined;

      const internalField = (
        <LabeledInput
          required={props.required || props.required === undefined}
          onClear={onClear}
          error={error}
          value={
            value ? (
              <div style={{ paddingTop: '1rem' }}>
                {renderCreditorPickerItem(value)}
              </div>
            ) : null
          }
          label={
            label || label === undefined ? t('COMMON.CREDITOR') : undefined
          }
          placeholder={props.placeholder}
          disabled={disabled}
        />
      );

      if (disabled) {
        return internalField;
      }

      return (
        <div ref={reactRefSetter(wrapperRef, ref)}>
          <EntityPickerPopover
            ref={popoverRef}
            anchorEl={wrapperRef}
            picker={
              <CreditorPicker
                layout="advanced"
                multiple={false}
                favorites={favorites}
                {...pickerProps}
              />
            }
            footer={
              <EntityPickerActions
                entity={Entities.supplier}
                multiple={false}
                data={props.data}
                onChange={(value) => {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange(value as any);
                  popoverRef.current?.close();
                }}
              />
            }
          >
            {internalField}
          </EntityPickerPopover>
        </div>
      );
    }
  );
};

export const CreditorPickerField = createCreditorPickerField();
