import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';

interface CreateFilterIconButtonProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
}

export const CreateFilterIconButton = ({
  onClick,
  disabled = false,
}: CreateFilterIconButtonProps) => {
  return (
    <IconButton
      size="small"
      onClick={onClick}
      color="primary"
      disabled={disabled}
    >
      <AddIcon />
    </IconButton>
  );
};
