import { useObservableState } from 'observable-hooks';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { debounceTime, distinctUntilChanged, of } from 'rxjs';

import { useTableStateBag } from '@work4all/components';
import { Search } from '@work4all/components/lib/input/actions/search/Search';

interface Props {
  handleSearchFilter: (e: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
}

export const SearchAction: React.FC<Props> = (props: Props) => {
  const tableStateBag = useTableStateBag();
  const [mountCount, setMountCount] = useState(0);

  const [_, setDebouncedSearchFilterText] = useObservableState(
    (input$) =>
      input$.pipe(distinctUntilChanged(), debounceTime(200), (source) => {
        source.subscribe((val) => {
          tableStateBag.setSearchFilterText(val);
        });
        return of();
      }),
    ''
  );

  useEffect(() => {
    if (tableStateBag.searchFilterText === '') {
      //remout to have an empty value again
      setMountCount((prev) => prev + 1);
    }
  }, [tableStateBag.searchFilterText]);

  const handleSearchFilterText = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setDebouncedSearchFilterText(e?.target?.value);
    },
    [setDebouncedSearchFilterText]
  );

  return (
    <Search
      key={mountCount}
      onChange={handleSearchFilterText}
      fieldStyle="fill"
      label={props.label}
    />
  );
};
