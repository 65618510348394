import styles from './CommentEditor.module.scss';

import { useEffect, useState } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import { useTranslation } from 'react-i18next';

import { useUser } from '@work4all/data';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';

import { TextEditor } from '../../../../input/format-text/TextEditor';

interface CommentEditorProps {
  value: string;
  setValue: (value: string) => void;
  canAddOrInsertImage: boolean;
  editorConf?: Record<string, unknown>;
  hideToolbar?: boolean;
  disabled?: boolean;
}

export const CommentEditor = ({
  value,
  setValue,
  canAddOrInsertImage,
  editorConf,
  hideToolbar,
  disabled,
}: CommentEditorProps) => {
  const [editor, setEditor] = useState<FroalaEditor>();
  const { activeTenant } = useTenant();
  const user = useUser();
  const generateFileUploadUrl = (baseUrl: string, authToken: string) => {
    return `${baseUrl}/api/file/inlinemailimage?auth=${authToken}`;
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (editor) {
      editor?.getEditor()?.events?.focus();
    }
  }, [editor]);

  return (
    <div className={styles.root}>
      <TextEditor
        disabled={disabled}
        value={value}
        onChange={setValue}
        onInitialized={setEditor}
        enableMentions
        enableTextBuildingBlocks={true}
        config={{
          ...{
            placeholderText: t('MASK.ADD_COMMENT'),
            requestHeaders: {
              'x-work4all-mandant': activeTenant,
            },
            imageUpload: true,
            imageUploadURL: generateFileUploadUrl(user.baseUrl, user.token),
            height: 43,
            heightMin: 150,
            heightMax: 300,
          },
          ...(editorConf ? editorConf : {}),
        }}
        ignoreButtons={canAddOrInsertImage ? undefined : ['insertImage']}
        imagePaste={canAddOrInsertImage}
        hideToolbar={hideToolbar}
      />
    </div>
  );
};
