import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class ContactTelephonenumbers<T extends EMode = EMode.entity> {
  /** Alias: central */
  central?: string;
  /** Alias: directDial */
  directDial?: string;
  /** Alias: fax */
  fax?: string;
  /** Alias: privateMobile */
  privateMobile?: string;
  /** Alias: privateTelephone */
  privateTelephone?: string;
  /** Alias: mobile */
  mobile?: string;
  /** Alias: directFax */
  directFax?: string;
  /** Alias: others */
  others?: string[];

  __typename?: string;
}

const fields: FieldDefinitions<ContactTelephonenumbers> = {
  central: {
    alias: 'central',
  },
  directDial: {
    alias: 'directDial',
  },
  fax: {
    alias: 'fax',
  },
  privateMobile: {
    alias: 'privateMobile',
  },
  privateTelephone: {
    alias: 'privateTelephone',
  },
  mobile: {
    alias: 'mobile',
  },
  directFax: {
    alias: 'directFax',
  },
  others: {
    alias: 'others',
  },
  __typename: {
    alias: '__typename',
  },
};

export const contactTelephonenumbersEntityDefinition: EntitiyDefinition<ContactTelephonenumbers> =
  {
    local: {},
    remote: {
      queryName: 'getCompleteTelephonenumbers',
      fragmentName: 'ContactTelephonenumbers',
      withPaginationWrapper: false,
      params: [
        { name: 'sdObjType', type: 'SdObjType' },
        { name: 'sdObjMemberCode', type: 'Int' },
        { name: 'contactCode', type: 'Int' },
        { name: 'userCode', type: 'Int' },
      ],
    },
    fieldDefinitions: fields,
  };
