import styles from './GridLayout.module.scss';

import React, { useMemo } from 'react';
import { Responsive, ResponsiveProps, WidthProvider } from 'react-grid-layout';

import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

export const GridLayout = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: ResponsiveProps & { children: React.ReactNode; sizeDeps?: any[] }
) => {
  const { sizeDeps = [] } = props;

  const ResponsiveReactGridLayout = useMemo(
    () => WidthProvider(Responsive),
    [...sizeDeps]
  );

  const rem = remToPx(1);

  return (
    <div className={styles.reactGrid}>
      <ResponsiveReactGridLayout
        className="layout"
        margin={[rem, rem]}
        rowHeight={3 * rem}
        useCSSTransforms={false}
        resizeHandles={['se']}
        draggableHandle=".drag-handle"
        {...props}
      >
        {props.children}
      </ResponsiveReactGridLayout>
    </div>
  );
};
