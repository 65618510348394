import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class BulkPriceTierItem<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: name */
  name?: string;
  /** Alias: preisstaffelCode */
  bulkPriceTierId?: number;
  /** Alias: grenzwert1 */
  edgeValue1?: number;
  /** Alias: grenzwert2 */
  edgeValue2?: number;
  /** Alias: zuschlag */
  surcharge?: number;
  /** Alias: index */
  index?: number;

  __typename?: string;
}

const fields: FieldDefinitions<BulkPriceTierItem> = {
  id: {
    alias: 'code',
  },
  name: {
    alias: 'name',
  },
  bulkPriceTierId: {
    alias: 'preisstaffelCode',
  },
  edgeValue1: {
    alias: 'grenzwert1',
  },
  edgeValue2: {
    alias: 'grenzwert2',
  },
  surcharge: {
    alias: 'zuschlag',
  },
  index: {
    alias: 'index',
  },
  __typename: {
    alias: '__typename',
  },
};

export const bulkPriceTierItemEntityDefinition: EntitiyDefinition<BulkPriceTierItem> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'PreisstaffelItem',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
