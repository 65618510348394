import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { FileEntity } from './FileEntity.entity';

export class ProcessWordTemplateResult<T extends EMode = EMode.entity> {
  /** Alias: tempFileId */
  tempFileId?: string;
  /** Alias: ok */
  ok?: boolean;
  /** Alias: errorMessages */
  errorMessages?: string[];
  /** Alias: fileInfos */
  fileInfos?: FileEntity<T>;

  __typename?: string;
}

const fields: FieldDefinitions<ProcessWordTemplateResult> = {
  tempFileId: {
    alias: 'tempFileId',
  },
  ok: {
    alias: 'ok',
  },
  errorMessages: {
    alias: 'errorMessages',
  },
  fileInfos: {
    alias: 'fileInfos',
    entity: Entities.fileEntity,
  },
  __typename: {
    alias: '__typename',
  },
};

export const processWordTemplateResultEntityDefinition: EntitiyDefinition<ProcessWordTemplateResult> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'ProcessWordTemplateResult',
      withPaginationWrapper: false,
      mutations: {
        upsert: {
          mutationName: 'processWordTemplate',
          args: [
            { name: 'letterTemplateCode', type: 'Int' },
            { name: 'letterCode', type: 'Int' },
          ],
        },
      },
    },
    fieldDefinitions: fields,
  };
