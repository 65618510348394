import { gql, useMutation } from '@apollo/client';

const IGNORE_MAIL = gql`
  mutation ignoreMail(
    $mailboxId: String!
    $mailId: String!
    $mailFolderId: String!
  ) {
    ignoreMail(
      mailboxId: $mailboxId
      mailId: $mailId
      mailFolderId: $mailFolderId
    )
  }
`;

type IgnoreMailResponse = {
  ignoreMail: boolean;
};

type IgnoreMailVars = {
  mailboxId: string;
  mailId: string;
  mailFolderId: string;
};

export function useIgnoreMailMutation() {
  return useMutation<IgnoreMailResponse, IgnoreMailVars>(IGNORE_MAIL);
}
