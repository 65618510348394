import styles from '../EntityPicker.module.scss';

import { Tooltip, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Contract } from '@work4all/models/lib/Classes/Contract.entity';
import { DeliveryNote } from '@work4all/models/lib/Classes/DeliveryNote.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { entityTypeLookup } from '@work4all/models/lib/table-schema/entityTypeLookup';

import { ListEntityPicker } from '../components/list-entity-picker';
import { IPickerWithTabsProps } from '../types';

export type IContractDeliveryNotePickerProps<
  TMultiple extends boolean = boolean
> = IPickerWithTabsProps<Contract | DeliveryNote, TMultiple>;
export function ContractDeliveryNotePicker<TMultiple extends boolean = boolean>(
  props: IContractDeliveryNotePickerProps<TMultiple>
) {
  const { data, prefilter, onChange, onTabChange, ...rest } = props;

  const { t } = useTranslation();

  const allFields = useMemo(() => {
    return { ...COMMON_FIELDS, ...data };
  }, [data]);

  const renderItem = useCallback((item: Contract | DeliveryNote) => {
    const isContract = item.__typename === entityTypeLookup[Entities.contract];
    return (
      <Tooltip
        title={`${isContract ? item.contractNumber : item.number} ${item.note}`}
      >
        <Typography variant="body2" noWrap>
          <span className={styles.secondaryInfo}>
            {isContract ? item.contractNumber : item.number} |{' '}
          </span>
          {item.note}
        </Typography>
      </Tooltip>
    );
  }, []);

  const tabs = useMemo(() => {
    return [
      {
        entity: Entities.contract,
        data: { ...(allFields as Contract), ...CONTRACT_FIELDS },
        label: t('COMMON.CONTRACT'),
        filterBy: ['note', 'contractNumber', 'number'],
        sortBy: 'contractDate',
        sortByDirection: SortDirection.DESCENDING,
        prefilter,
        renderItemContent: renderItem,
      },
      {
        entity: Entities.deliveryNote,
        data: { ...(allFields as DeliveryNote), ...DELIVERY_NOTE_FIELDS },
        label: t('COMMON.DELIVERYNOTE'),
        filterBy: ['note', 'number'],
        sortBy: 'date',
        sortByDirection: SortDirection.DESCENDING,
        prefilter,
        renderItemContent: renderItem,
      },
    ];
  }, [allFields, prefilter, renderItem, t]);

  return (
    <ListEntityPicker
      {...rest}
      onChange={onChange}
      getTabIndex={getTabIndex}
      onTabChange={onTabChange}
      tabs={tabs}
    />
  );
}

const COMMON_FIELDS: Contract | DeliveryNote = {
  id: null,
  number: null,
  note: null,
  isClosed: null,
};

const CONTRACT_FIELDS: Contract = {
  contractNumber: null,
  contractDate: null,
};

const DELIVERY_NOTE_FIELDS: DeliveryNote = {
  date: null,
};

function getTabIndex(value: Contract | DeliveryNote): 0 | 1 {
  return value.__typename === 'Auftrag' ? 0 : 1;
}
