import { GroupPicker } from '@work4all/components/lib/components/entity-picker/GroupPicker';

import { useDataProvider } from '@work4all/data';

import { DocumentTemplateGroup } from '@work4all/models/lib/Classes/DocumentTemplateGroup.entity';
import { Group } from '@work4all/models/lib/Classes/Group.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export interface WordDocumentTemplateGroupPickerProps {
  value: Group[] | null;
  onChange: (value: DocumentTemplateGroup[]) => void;
}

const DocumentTemplateGroupsRequest = {
  entity: Entities.documentTemplateGroup,
  completeDataResponse: true,
  data: {
    id: null,
    name: null,
  },
};

export function WordDocumentTemplateGroupPicker(
  props: WordDocumentTemplateGroupPickerProps
) {
  const { value, onChange } = props;

  const response = useDataProvider<DocumentTemplateGroup>(
    DocumentTemplateGroupsRequest
  );

  const groups =
    response.data?.map((item) => ({
      name: item.name,
      id: item.id,
      parentId: 0,
    })) ?? null;

  return (
    <GroupPicker
      value={value?.map((item) => ({ ...item, parentId: 0 }))}
      onChange={(e) => {
        onChange(e.map((item) => ({ name: item.name, id: item.id })));
      }}
      groups={groups}
    />
  );
}
