import TuneIcon from '@mui/icons-material/Tune';
import { IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { BaseActionProps } from '../types';

export const Filter: React.FC<BaseActionProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('COMMON.FILTER')} placement="top">
      {/* @ts-expect-error TECH_DEBT */}
      <IconButton color="primary" size="medium" {...props}>
        <TuneIcon />
      </IconButton>
    </Tooltip>
  );
};
