import { matchPath } from 'react-router-dom';

import { useLocation } from '@work4all/data';

export const useListMatch = () => {
  const location = useLocation();
  const isEntityList = matchPath(
    {
      path: '/more/entity/:entityType',
      end: true,
    },
    location.pathname
  );
  const isFileList = matchPath(
    {
      path: '/:fileType/:fileId/entity/:entityType',
      end: true,
    },
    location.pathname
  );

  const isBaseListPageUrl = !!isFileList || !!isEntityList;
  return { isBaseListPageUrl, isFileList, isEntityList };
};
