import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class TicketCategory1<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: name */
  name?: string;
  /** Alias: updateTime */
  updateTime?: string;

  __typename?: string;
}

const fields: FieldDefinitions<TicketCategory1> = {
  id: {
    alias: 'code',
  },
  insertTime: {
    alias: 'insertTime',
  },
  name: {
    alias: 'name',
  },
  updateTime: {
    alias: 'updateTime',
  },
  __typename: {
    alias: '__typename',
  },
};

export const ticketCategory1EntityDefinition: EntitiyDefinition<TicketCategory1> =
  {
    local: {},
    remote: {
      queryName: 'getTicketKategorien1',
      fragmentName: 'TicketKategorie1',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
