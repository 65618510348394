import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Group } from '@work4all/models/lib/Classes/Group.entity';

import { BusinessPartnerGroupPicker } from '../../../../components/entity-picker/business-partner-group-picker/BusinessPartnerGroupPicker';
import { IFilterPickerConfig } from '../../../../components/entity-picker/components/list-entity-picker';
import { FilterCard } from '../filter-card/FilterCard';

export type IBusinessPartnerGroupFilterCardProps = {
  title: string;
  value: Group[] | null;
  onChange: (value: Group[]) => void;
  expanded: boolean;
  onExpandedChange: (val: boolean) => void;
  filterConfig?: IFilterPickerConfig;
};

export function BusinessPartnerGroupFilterCard(
  props: IBusinessPartnerGroupFilterCardProps
) {
  const { title, value, onChange, expanded, onExpandedChange } = props;

  const { t } = useTranslation();

  const filterValueText: string = useMemo(() => {
    if (!value || value.length === 0) return null;
    return value.length + ' ' + t('COMMON.CHOSEN');
  }, [value, t]);

  return (
    <FilterCard
      title={title}
      filterValue={filterValueText}
      filterValueSet={!!filterValueText}
      expanded={expanded}
      onExpandedChange={onExpandedChange}
      onValueDelete={() => {
        onChange([]);
      }}
    >
      <BusinessPartnerGroupPicker value={value} onChange={onChange} multiple />
    </FilterCard>
  );
}
