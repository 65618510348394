import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class DocumentClass<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: convertedItem */
  convertedItem?: boolean;
  /** Alias: name */
  name?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: sdObjType */
  businessPartnerType?: string;

  __typename?: string;
}

const fields: FieldDefinitions<DocumentClass> = {
  id: {
    alias: 'id',
  },
  convertedItem: {
    alias: 'convertedItem',
  },
  name: {
    alias: 'name',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  businessPartnerType: {
    alias: 'sdObjType',
  },
  __typename: {
    alias: '__typename',
  },
};

export const documentClassEntityDefinition: EntitiyDefinition<DocumentClass> = {
  local: {},
  remote: {
    queryName: 'getDocumentClasses',
    fragmentName: 'DocumentClass',
    withPaginationWrapper: false,
    params: [{ name: 'since', type: 'DateTime' }],
  },
  fieldDefinitions: fields,
};
