import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputLetterDocumentFileRelation } from './InputLetterDocumentFileRelation.entity';
import { InputTemplateFileRelation } from './InputTemplateFileRelation.entity';
import { InputTopicMarkRelation } from './InputTopicMarkRelation.entity';

export class InputBriefRelation<T extends EMode = EMode.entity> {
  /** Alias: topic */
  topic?: InputTopicMarkRelation<T>;
  /** Alias: templateFile */
  templateFile?: InputTemplateFileRelation<T>;
  /** Alias: fileContent */
  fileContent?: InputLetterDocumentFileRelation<T>;

  __typename?: string;
}

const fields: FieldDefinitions<InputBriefRelation> = {
  topic: {
    alias: 'topic',
  },
  templateFile: {
    alias: 'templateFile',
  },
  fileContent: {
    alias: 'fileContent',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputBriefRelationEntityDefinition: EntitiyDefinition<InputBriefRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputBriefRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
