import { forwardRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';
import { CustomerGroupPicker } from '@work4all/components/lib/components/entity-picker/entity-group-picker/EntityGroupPicker';

import { useEntityEvents } from '@work4all/data/lib/entity-events/use-entity-events';

import { Category } from '@work4all/models/lib/Classes/Category.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Group } from '@work4all/models/lib/Classes/Group.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Table } from '../../components/data-tables/table/Table';
import { CustomerFileInfoPanel } from '../../containers/files/detail/components/file-info-panel/CustomerFileInfoPanel';
import { useCustomer } from '../../containers/files/detail/components/queries/use-customer';
import { useSalesOpportunities } from '../../containers/files/detail/components/queries/use-sales-opportunities';
import { useServiceContracts } from '../../containers/files/detail/components/queries/use-service-contracts';
import { CustomerCategoryPicker } from '../filters/CustomerCategoryPicker';
import { PersonalAndGeneralView } from '../filters/PersonalAndGeneralView';

import { ClosedStatusQuickFilter } from './ClosedStatusQuickFilter';
import { EntityTable } from './EntityTable';
import { FileActions, useFileActions } from './FileActions';
import { PreviewMobileWrapper } from './PreviewMobileWrapper';
import schema from './schemata/customer-table-schema.json';
import { TableLayoutContext, useTableLayoutState } from './table-layout';
import {
  TableQuickFilters,
  TableQuickFiltersSection,
  TableQuickFiltersSectionHeader,
} from './table-quick-filters';
import { TableNoRowsPlaceholder } from './TableNoRowsPlaceholder';
import { useDataTable } from './use-data-table';
import { useDeleteEntitiesToolbarConfig } from './use-delete-entities-toolbar-config';
import { useTableMaskHandlers } from './use-table-mask-handlers';
import { useUserColumnConfigs } from './use-user-column-configs';

type Props = EntityTable;

type ICustomerTableCustomColumns = Record<string, never>;

const defaultSort = [{ field: 'name', direction: SortDirection.ASCENDING }];
const manualGroupBy = true;

export const CustomersTable = forwardRef<TableInstance, Props>(
  function CustomersTable(props, ref) {
    const { prefilter, onOpenMask, ...rest } = props;
    const { entity: entityType } = schema as never;

    const tableStateBag = useTableStateBag();

    const layoutState = useTableLayoutState();
    const [layout] = layoutState;

    const {
      columnConfigs,
      cardConfig,
      prepareRowDisplayModifiers,
      data,
      fetchMore,
      total,
      selectedEntity,
      initialSortBy,
      pending,
    } = useDataTable<Customer, ICustomerTableCustomColumns>({
      layout,
      schema: schema as never,
      tableStateBag,
      prefilter,
      defaultSort,
    });

    const { customer: fullyLoadedCustomer, refetch } = useCustomer(
      selectedEntity?.id.toString()
    );
    const { salesOpportunities } = useSalesOpportunities(
      selectedEntity?.id.toString()
    );
    const { serviceContracts } = useServiceContracts(
      selectedEntity?.id.toString()
    );

    const maskHandlers = useTableMaskHandlers(entityType, onOpenMask);

    const deleteConfig = useDeleteEntitiesToolbarConfig({
      entityType,
    });

    const [userConfig, userConfigMethods] = useUserColumnConfigs({
      layout,
      entityType,
      columnConfigs,
    });

    useEntityEvents((event) => {
      if (event.entity === Entities.contact) {
        refetch();
      }
    });

    const rowModifiers = useCallback(
      (value) => {
        const modifiers = prepareRowDisplayModifiers(value);
        return { ...modifiers, isFaded: value.extinct === -1 };
      },
      [prepareRowDisplayModifiers]
    );

    const { fileActionDefinitions } = useFileActions({
      entity: entityType,
    });

    if (!userConfig) return null;

    return (
      <TableLayoutContext value={layoutState}>
        <Table
          pending={pending}
          noRowsRenderer={() => <TableNoRowsPlaceholder />}
          ref={ref}
          layout={layout}
          cardConfig={cardConfig}
          areas={{
            left: {
              content: <CustomersTableQuickFilters />,
              resizable: true,
            },
            right: selectedEntity !== null && {
              content: (
                <PreviewMobileWrapper
                  active={true}
                  actions={{
                    edit: maskHandlers.edit,
                    remove: deleteConfig,
                    additionalActionsLeft: <FileActions entity={entityType} />,
                  }}
                >
                  <CustomerFileInfoPanel
                    openSalesOpportunityList={salesOpportunities}
                    activeServiceContractList={serviceContracts}
                    customer={fullyLoadedCustomer}
                    onCloseClicked={() =>
                      tableStateBag.tableInstance.toggleAllRowsSelected(false)
                    }
                    onEdit={maskHandlers.edit?.handler}
                    onAddContact={() => {
                      onOpenMask().handler({
                        entity: Entities.contact,
                        template: {
                          entity: Entities.customer,
                          id: String(selectedEntity.id),
                        },
                      });
                    }}
                    onEditContact={(contactId) => {
                      onOpenMask().handler({
                        entity: Entities.contact,
                        id: String(contactId),
                        template: {
                          entity: Entities.customer,
                          id: String(selectedEntity.id),
                        },
                      });
                    }}
                  />
                </PreviewMobileWrapper>
              ),
            },
          }}
          actions={{
            add: maskHandlers.create,
            edit: maskHandlers.edit,
            convert: {
              exclude: entityType,
              handler: maskHandlers.convert,
            },
            remove: deleteConfig,
            resetColumns: userConfigMethods.remove,
            custom: {
              left: fileActionDefinitions,
            },
            createWidget: true,
          }}
          columnConfigs={userConfig}
          manualGroupBy={manualGroupBy}
          initialSortBy={initialSortBy}
          loadMoreItems={fetchMore}
          prepareRowDisplayModifiers={rowModifiers}
          data={data}
          total={total}
          {...rest}
        />
      </TableLayoutContext>
    );
  }
);

function CustomersTableQuickFilters() {
  const { t } = useTranslation();

  const tableStateBag = useTableStateBag();

  const columns = {
    group: tableStateBag.columnsById['groupId'],
    categories: tableStateBag.columnsById['categoryAssignmentList.categoryId'],
    closed: tableStateBag.columnsById['extinct'],
  };

  const groups = columns.group?.filterValue?.value ?? null;

  const handleGroupsChange = (groups: Group[]) => {
    const column = columns.group;

    if (!column) {
      return;
    }

    if (groups.length === 0) {
      column.setFilter(null);
    } else {
      column.setFilter({
        value: groups,
        filterType: column.filterType,
      });
    }
  };

  const categories = columns.categories?.filterValue?.value ?? null;

  const handleCategoriesChange = (categories: Category[]) => {
    const column = columns.categories;

    if (!column) {
      return;
    }

    if (categories.length === 0) {
      column.setFilter(null);
    } else {
      column.setFilter({
        value: categories,
        filterType: column.filterType,
      });
    }
  };

  return (
    <TableQuickFilters>
      <ClosedStatusQuickFilter
        entityType={Entities.customer}
        column={columns.closed}
        label={t('CUSTOMER.CLOSED_STATUS.HIDE_CLOSED')}
      />

      <TableQuickFiltersSection>
        <TableQuickFiltersSectionHeader>
          {t('COMMON.GROUPS')}
        </TableQuickFiltersSectionHeader>
        <CustomerGroupPicker value={groups} onChange={handleGroupsChange} />
      </TableQuickFiltersSection>

      <TableQuickFiltersSection>
        <TableQuickFiltersSectionHeader>
          {t('COMMON.CATEGORIES')}
        </TableQuickFiltersSectionHeader>
        <CustomerCategoryPicker
          value={categories}
          onChange={handleCategoriesChange}
        />
      </TableQuickFiltersSection>

      <PersonalAndGeneralView
        value={groups ?? categories}
        entity={Entities.customer}
      />
    </TableQuickFilters>
  );
}
