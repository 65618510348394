import { useEventCallback } from '@mui/material/utils';
import { useMemo, useState } from 'react';

import { OverlayFullscreenContext } from '@work4all/data';

import { StackProvider } from '../../../../navigation/history-stack';
import { Content } from '../../../../navigation/navigation-overlay/components/content/Content';

import { NewCommentView, NewCommentViewContext } from './NewCommentView';

export interface NewCommentModalProps {
  open: boolean;
  onSubmit: (comment: string) => void | Promise<void>;
  onClose: () => void;
  breadcrumbTitle?: string;
  canAddOrInsertImage: boolean;
  newComment?: string;
  onNewCommentChange?: (value: string) => void;
}

const INITIAL_VIEW = {
  view: <NewCommentView />,
};

export function NewCommentModal(props: NewCommentModalProps) {
  const {
    open,
    onClose,
    breadcrumbTitle,
    canAddOrInsertImage,
    newComment,
    onNewCommentChange,
  } = props;

  const onSubmitProp = useEventCallback(props.onSubmit);

  const [fullscreen, setFullscreen] = useState(false);

  const fullscreenContext = useMemo(
    () => ({ fullscreen, setFullscreen }),
    [fullscreen, setFullscreen]
  );

  const context = useMemo(
    () => ({
      onSubmit: onSubmitProp,
      canAddOrInsertImage,
      breadcrumbTitle,
      newComment,
      onNewCommentChange,
    }),
    [
      breadcrumbTitle,
      canAddOrInsertImage,
      newComment,
      onNewCommentChange,
      onSubmitProp,
    ]
  );

  return (
    <OverlayFullscreenContext.Provider value={fullscreenContext}>
      <NewCommentViewContext.Provider value={context}>
        <StackProvider onClose={onClose} initialView={INITIAL_VIEW}>
          <Content open={open} close={onClose} />
        </StackProvider>
      </NewCommentViewContext.Provider>
    </OverlayFullscreenContext.Provider>
  );
}
