import { uuid } from 'short-uuid';

import { IFavoriteLink, IFavoriteLinksFolder } from './types';

export interface CreateLinkOptions {
  name: string;
  href: string;
}

export function createLink({ name, href }: CreateLinkOptions): IFavoriteLink {
  return { kind: 'link', id: uuid(), name, href };
}

export interface CreateFolderOptions {
  name: string;
  links?: CreateLinkOptions[];
}

export function createFolder({
  name,
  links = [],
}: CreateFolderOptions): IFavoriteLinksFolder {
  return {
    kind: 'folder',
    id: uuid(),
    name,
    links: links.map((link) => createLink(link)),
  };
}
