import { useEffect } from 'react';

import { useApolloExtrasContext } from './apollo-extras-context';

/**
 * Disables automatic Apollo cache invalidation.
 *
 * By default, all upsert and delete mutations (invoked from `useDataMutation`
 * and `useDeleteEntity` hooks) invalidate Apollo cache after the mutation
 * succeeds in order to avoid stale UI state. When this hook is mounted
 * somewhere in the components tree, the automatic invalidation will be disabled
 * globally.
 *
 * Instead, you can use `useEntityEvents` hook to subscribe to changes and
 * manually refetch affected queries from your component.
 */
export function useDisableApolloAutoInvalidation() {
  const { disableAutoInvalidation } = useApolloExtrasContext();
  useEffect(() => disableAutoInvalidation(), [disableAutoInvalidation]);
}
