import styles from './General.module.scss';

import { useTranslation } from 'react-i18next';

import { Card } from '@work4all/components/lib/dataDisplay/card';

import { LedgerAccountPickerField } from '../../../../../../components/entity-picker/LedgerAccountPickerField';
import { VatPickerField } from '../../../../../../components/entity-picker/VatPickerField';
import { ControllerPlus } from '../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../form-plus/use-form-context-plus';
import { LabeledInput } from '../../../../locked-inputs';
import { Collapse, ControlWrapper } from '../../../components';
import { TravelReceiptsFormValue } from '../type';

const LEDGER_ACCOUNT_PREFILTER = [
  {
    kind: {
      $in: [0, 4, 10],
    },
  },
];

export const Costs = () => {
  const { t } = useTranslation();

  const form = useFormContextPlus<TravelReceiptsFormValue>();
  const { register, control } = form;

  return (
    <Collapse
      title={t('SETTINGS.PROJECT_TIME.COSTS')}
      className={styles.costs}
      defaultOpen
    >
      <Card>
        <ControlWrapper gridTemplateColumns="1fr 1fr 2fr">
          <LabeledInput
            label={t('COMMON.GROSS')}
            {...register('amountGross1')}
            type="number"
            inputProps={{
              step: '0.01',
            }}
          />
          <ControllerPlus
            name="vat1"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <VatPickerField {...field} error={fieldState?.error?.message} />
              );
            }}
          />
          <ControllerPlus
            name="ledgerAccount1"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <LedgerAccountPickerField
                  {...field}
                  label={t('BOOKINGS_TABLE.COLUMNS.LEDGER_ACCOUNT')}
                  error={fieldState?.error?.message}
                  prefilter={LEDGER_ACCOUNT_PREFILTER}
                />
              );
            }}
          />
        </ControlWrapper>

        <ControlWrapper gridTemplateColumns="1fr 1fr 2fr">
          <LabeledInput
            label={t('COMMON.GROSS')}
            {...register('amountGross2')}
            type="number"
            inputProps={{
              step: '0.01',
            }}
          />
          <ControllerPlus
            name="vat2"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <VatPickerField {...field} error={fieldState?.error?.message} />
              );
            }}
          />

          <ControllerPlus
            name="ledgerAccount2"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <LedgerAccountPickerField
                  {...field}
                  label={t('BOOKINGS_TABLE.COLUMNS.LEDGER_ACCOUNT')}
                  error={fieldState?.error?.message}
                  prefilter={LEDGER_ACCOUNT_PREFILTER}
                />
              );
            }}
          />
        </ControlWrapper>

        <ControlWrapper gridTemplateColumns="1fr 1fr 2fr">
          <LabeledInput
            label={t('COMMON.GROSS')}
            {...register('amountGross3')}
            type="number"
            inputProps={{
              step: '0.01',
            }}
          />
          <ControllerPlus
            name="vat3"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <VatPickerField {...field} error={fieldState?.error?.message} />
              );
            }}
          />

          <ControllerPlus
            name="ledgerAccount3"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <LedgerAccountPickerField
                  {...field}
                  label={t('BOOKINGS_TABLE.COLUMNS.LEDGER_ACCOUNT')}
                  error={fieldState?.error?.message}
                  prefilter={LEDGER_ACCOUNT_PREFILTER}
                />
              );
            }}
          />
        </ControlWrapper>
      </Card>
    </Collapse>
  );
};
