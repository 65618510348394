import React from 'react';

export const reactRefSetter = <T>(
  ...refs: Array<React.ForwardedRef<T> | React.MutableRefObject<T>>
) => {
  return (instance: T) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(instance);
      } else if (ref) {
        ref.current = instance;
      }
    });
  };
};
