import { createContext, useContext } from 'react';

import { User } from '@work4all/models/lib/Classes/User.entity';

export type UsersContextValue = {
  users: User[];
  usersById: Record<number, User>;
};

const Context = createContext<UsersContextValue>({ users: [], usersById: {} });

export const UsersContextProvider = Context.Provider;

export function useUsersContext(): UsersContextValue {
  const context = useContext(Context);

  if (context === null) {
    throw new Error(
      `useUsersContext must be used within a <UsersContextProvider>`
    );
  }

  return context;
}
