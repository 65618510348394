import { Typography } from '@mui/material';
import { forwardRef } from 'react';

import { Project } from '@work4all/models/lib/Classes/Project.entity';

export interface ProjectTextProps extends React.HTMLAttributes<HTMLDivElement> {
  project: Project;
}

export const ProjectText = forwardRef<HTMLDivElement, ProjectTextProps>(
  function ProjectText(props, ref) {
    const { project, ...otherProps } = props;

    if (!project) {
      return null;
    }

    const renderContent = () => {
      return (
        <>
          <Typography component="span" variant="body2" color="text.tertiary">
            {project.number}
            {project.number && project.name ? ' | ' : ''}
          </Typography>
          <Typography component="span" variant="body2">
            {project.name}
          </Typography>
        </>
      );
    };

    return (
      <Typography
        ref={ref}
        component="div"
        variant="body2"
        noWrap
        {...otherProps}
      >
        {renderContent()}
      </Typography>
    );
  }
);

export function projectAsString(project: Project): string {
  if (!project) return '';

  return project.name;
}
