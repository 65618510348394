import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class CrystalReportFormular<T extends EMode = EMode.entity> {
  /** Alias: formular */
  formular?: string;
  /** Alias: values */
  values?: string[];

  __typename?: string;
}

const fields: FieldDefinitions<CrystalReportFormular> = {
  formular: {
    alias: 'formular',
  },
  values: {
    alias: 'values',
  },
  __typename: {
    alias: '__typename',
  },
};

export const crystalReportFormularEntityDefinition: EntitiyDefinition<CrystalReportFormular> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'CrystalReportFormular',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
