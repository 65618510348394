import React from 'react';

export const withStopPropogation = (clb?: (e: React.MouseEvent) => void) => {
  return (e: React.MouseEvent) => {
    e.stopPropagation();
    if (clb) {
      clb(e);
    }
  };
};
