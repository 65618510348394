export type ApiVersion = [number, number, number, number];

/**
 * Parses the given string and returns it as a number tuple
 *
 * Will throw an error if the version string can't be parsed.
 */
export function parseApiVersion(version: string): ApiVersion {
  function throwError() {
    const error = new Error(`Could not parse version string ${version}`);
    Error?.captureStackTrace(error, throwError);
    throw error;
  }

  const parts = version.split('.');

  if (parts.length !== 4) throwError();

  const parsedParts = parts.map((part) => {
    const num = Number(part);
    if (Number.isNaN(num) || num < 0 || String(num) !== part) throwError();
    return num;
  });

  return parsedParts as ApiVersion;
}
