import styles from './statistics-styles.module.scss';

import { Box } from '@mui/material';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, TableInstance } from 'react-table';

import { TimeCard } from '@work4all/models/lib/Classes/TimeCard.entity';
import { UserPresenceTimeInfo } from '@work4all/models/lib/Classes/UserPresenceTimeInfo.entity';
import { WorkingTimeTimeCardDayInfo } from '@work4all/models/lib/Classes/WorkingTimeTimeCardDayInfo.entity';

import { BasicTable } from '../BasicTable';
import { BasicTableColumn } from '../types';

interface StatisticTimeCardTableProps {
  data: TimeCard;
  onSelectedRowsChange?: (selectedRows: Row[]) => void;
  maxTargetTimeMinutes: number;
}

export function renderTime(totalMinutes: number | string) {
  totalMinutes = parseFloat(totalMinutes + '');
  if (!totalMinutes) {
    return '';
  }
  totalMinutes = Math.round(totalMinutes);
  const minutes = Math.abs(totalMinutes % 60).toFixed(0);
  const hours = Math.floor(Math.abs(totalMinutes) / 60).toFixed(0);

  return (
    <span className={clsx({ [styles.red]: totalMinutes < 0 })}>
      {totalMinutes < 0 && '-'}
      {`${padTo2Digits(hours)}:${padTo2Digits(minutes)}`}
    </span>
  );
}

export function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export const StatisticTimeCardTable: React.FC<StatisticTimeCardTableProps> = (
  props
) => {
  const { data, maxTargetTimeMinutes } = props;
  const { t, i18n } = useTranslation();

  const totalValues = useMemo(() => {
    return {
      pauseAmountMinutes: data.timeCardDays
        ?.map((x) => x.pauseAmountMinutes)
        .reduce((a, b) => a + b, 0),
      targetMinutes: data.timeCardDays
        ?.map((x) => x.targetMinutes)
        .reduce((a, b) => a + b, 0),
      isGrossMinutes: data.timeCardDays
        ?.map((x) => x.isGrossMinutes)
        .reduce((a, b) => a + b, 0),
      differenceminutes: data.timeCardDays
        ?.map((x) => x.differenceminutes)
        .reduce((a, b) => a + b, 0),
      timeAccountMinutes: data.timeCardDays
        ?.map((x) => x.timeAccountMinutes)
        .reduce((a, b) => a + b, 0),
      payedTime: data.timeCardDays
        ?.map((x) => x.isGrossMinutes - x.pauseAmountMinutes)
        .reduce((a, b) => a + b, 0),
      projectTimeTotal: data.timeCardDays
        ?.map((x) => x.projectTimeTotal * 60)
        .reduce((a, b) => a + b, 0),
      projectTimeDifference: data.timeCardDays
        ?.map(
          (x) =>
            x.projectTimeTotal * 60 - x.isGrossMinutes + x.pauseAmountMinutes
        )
        .reduce((a, b) => a + b, 0),
    };
  }, [data.timeCardDays]);

  const columns: BasicTableColumn[] = [
    {
      Header: t('COMMON.DATE'),
      accessor: 'date',
      Cell: ({ value }: { value: number }) => {
        return (
          <>
            <div className={styles.beginCell}>
              {DateTime.fromJSDate(new Date(value)).setLocale(i18n.language)
                .weekdayShort + '. '}
            </div>
            <div className={styles.endCell}>
              {DateTime.fromJSDate(new Date(value)).toFormat('dd.MM')}
            </div>
          </>
        );
      },
      width: 90,
      sticky: 'left',
    },
    {
      Header: `${t('COMMON.BEGINNING')} / ${t('COMMON.END')}`,
      accessor: 'startDate',
      width: 110,
      Cell: (data: {
        value: string;
        row: { original: WorkingTimeTimeCardDayInfo };
      }) => {
        if (data.row.original.amountSickness > 0) {
          return (
            data.row.original.sicknessList?.[0]?.sicknessType?.name ||
            t('COMMON.SICKNESSDAY')
          );
        } else if (data.row.original.amountHoliday > 0) {
          return t('COMMON.HOLIDAY');
        } else if (data.row.original.amountVacation > 0) {
          return (
            data.row.original.vacationList?.[0]?.vacationKind?.name ||
            t('COMMON.VACATIONDAY')
          );
        } else {
          if (data.row.original.presenceList?.[0]?.start === undefined) {
            return '...';
          } else {
            return data.row.original.presenceList.map(
              (item: UserPresenceTimeInfo) => {
                return (
                  <>
                    <div className={styles.beginCell}>
                      {DateTime.fromISO(item.start).toFormat('HH:mm')}
                    </div>
                    <div className={styles.endCell}>
                      {DateTime.fromISO(item.end).toFormat('HH:mm')}
                    </div>
                  </>
                );
              }
            );
          }
        }
      },
      alignment: 'center',
    },
    {
      Header: t('COMMON.PAUSE'),
      accessor: 'pauseAmountMinutes',
      Footer: (
        <Box height="100%">{renderTime(totalValues.pauseAmountMinutes)}</Box>
      ),
      width: 70,
      Cell: ({ value }: { value: number }) => {
        return renderTime(value);
      },
      alignment: 'center',
    },
    {
      Header: t('COMMON.SHOULD'),
      accessor: 'targetMinutes',
      Footer: (
        <Box textAlign="center">
          {renderTime(totalValues.targetMinutes)}
          {maxTargetTimeMinutes !== 0 && (
            <span>
              <br />({renderTime(maxTargetTimeMinutes)})
            </span>
          )}
        </Box>
      ),
      width: 60,
      Cell: ({ value }: { value: number }) => {
        return renderTime(value);
      },
      alignment: 'center',
    },
    {
      Header: t('COMMON.IS'),
      accessor: 'isGrossMinutes',
      Footer: <Box height="100%">{renderTime(totalValues.isGrossMinutes)}</Box>,
      width: 60,
      Cell: ({ value }: { value: number }) => {
        return renderTime(value);
      },
      alignment: 'center',
    },
    {
      Header: t('COMMON.PAYED'),
      accessor: '',
      Footer: <Box height="100%">{renderTime(totalValues.payedTime)}</Box>,
      width: 80,
      Cell: (data: { row: { original: WorkingTimeTimeCardDayInfo } }) => {
        const value =
          data.row.original.isGrossMinutes -
          data.row.original.pauseAmountMinutes;
        return renderTime(value);
      },
      alignment: 'center',
    },
    {
      Header: t('COMMON.DAY'),
      accessor: 'differenceminutes',
      Footer: (
        <Box height="100%">{renderTime(totalValues.differenceminutes)}</Box>
      ),
      width: 60,
      Cell: ({ value }: { value: number }) => {
        return renderTime(value);
      },
      alignment: 'center',
    },
    {
      Header: t('COMMON.BALANCE'),
      accessor: 'timeAccountMinutes',
      width: 60,
      Cell: ({ value }: { value: number }) => {
        return renderTime(value);
      },
      alignment: 'center',
    },
    {
      Header: t('COMMON.PROJECTTIME'),
      accessor: 'projectTimeTotal',
      Footer: (
        <Box height="100%">{renderTime(totalValues.projectTimeTotal)}</Box>
      ),
      width: 100,
      Cell: ({ value }: { value: number }) => {
        return renderTime(value * 60);
      },
      alignment: 'center',
    },
    {
      Header: t('COMMON.DIFF_WORKINGTIME'),
      id: 'projectTimeDifference',
      Footer: (
        <Box height="100%">{renderTime(totalValues.projectTimeDifference)}</Box>
      ),
      width: 100,
      Cell: (data: { row: { original: WorkingTimeTimeCardDayInfo } }) => {
        if (
          (data.row.original.amountSickness > 0 ||
            data.row.original.amountHoliday > 0 ||
            data.row.original.amountVacation > 0) &&
          !data.row.original.presenceList?.length
        ) {
          return null;
        } else {
          return renderTime(
            data.row.original.projectTimeTotal * 60 -
              data.row.original.isGrossMinutes +
              data.row.original.pauseAmountMinutes
          );
        }
      },
      alignment: 'center',
    },
  ];

  const apiRef = useRef<TableInstance>(null);

  const rowModifiers = useCallback(
    (value) => {
      return {
        isShade1:
          DateTime.fromISO(value.date) <=
          DateTime.fromISO(data.lastInterimBalanceDate),
        isShade2: value.amountVacation,
        isShade4: value.amountSickness,
        isShade5: value.amountHoliday,
        isShade6:
          DateTime.fromISO(value?.date).weekday === 6 ||
          DateTime.fromISO(value?.date).weekday === 7,
      };
    },
    [data.lastInterimBalanceDate]
  );

  return (
    <BasicTable
      displayFooter={true}
      prepareRowDisplayModifiers={rowModifiers}
      onSelectedRowsChange={props.onSelectedRowsChange}
      mode="client"
      cardsView={null}
      ref={apiRef}
      reordableColumns={true}
      resizableColumns={false}
      isVirtual={false}
      selectableRows={true}
      data={data?.timeCardDays || []}
      columns={columns}
      selectableMultiple={false}
      allItemsCount={data?.timeCardDays?.length || 0}
      className={styles.table}
    />
  );
};
