import { Subject } from 'rxjs';

type Key = string | number | symbol;

export type KeyFn<TValue> = (value: TValue) => Key;
export type SortFn<TValue> = (a: TValue, b: TValue) => number;

export type Selection<TValue, TMultiple> = TMultiple extends true
  ? TValue[]
  : TValue | null;

export type SelectionModelOptions<TValue, TMultiple> = {
  multiple: TMultiple;
  keyFn: KeyFn<TValue>;
  sortFn?: SortFn<TValue>;
  initialSelected?: TValue[];
};

/**
 * Keep track of one or more values selected from a list.
 *
 * Performs value comparison using the provided `keyFn`. This function should
 * return a primitive value that uniquely identifies its value. (This should
 * usually be the entity's id, but can be any other value.)
 *
 * The `isSelected` method can be used to check if a value was previously
 * selected. The values will be compared using `keyFn`.
 *
 * Selection mode can be configured with `multiple` option and the mode
 * can't be changed after initialization.
 *
 * In single-selection mode only one value can be selected at a time. The
 * previous value will be deselected. If you pass multiple values to
 * `select`, `deselect` or `setSelected` in this mode, an error will be thrown.
 *
 * In multiple-selection mode new values will be appended to the ones
 * currently selected. If a new value computes to the same key as the one
 * currently selected, it will be replaced.
 *
 * You can optionally pass `sortFn` function that will be used to sort the
 * array returned by `getSelected` in multi-selection mode.
 */
export class SelectionModel<TValue, TMultiple extends boolean> {
  public readonly multiple: TMultiple;
  private keyFn: KeyFn<TValue>;
  private sortFn: SortFn<TValue> | null;

  private selection = new Map<unknown, TValue>();

  public changes = new Subject<void>();

  constructor(options: SelectionModelOptions<TValue, TMultiple>) {
    const { multiple, keyFn, sortFn = null, initialSelected } = options;

    this.multiple = multiple;
    this.keyFn = keyFn;
    this.sortFn = sortFn;

    if (initialSelected) {
      this.select(...initialSelected);
    }
  }

  select(...values: TValue[]): void {
    for (const value of values) {
      this.markSelected(value);
    }
  }

  deselect(...values: TValue[]): void {
    for (const value of values) {
      this.unmarkSelected(value);
    }
  }

  isSelected(value: TValue): boolean {
    return this.selection.has(this.keyFn(value));
  }

  getLength(): number {
    return this.selection.size;
  }

  getSelected(): Selection<TValue, TMultiple> {
    if (this.multiple) {
      const selected = [...this.selection.values()];

      if (this.sortFn) {
        selected.sort(this.sortFn);
      }

      return selected as Selection<TValue, TMultiple>;
    } else {
      const selected = [...this.selection.values()];

      if (selected.length === 0) return null;

      return selected[0] as Selection<TValue, TMultiple>;
    }
  }

  setSelected(values: TValue[]): void {
    this.clear();
    this.select(...values);
  }

  isEmpty(): boolean {
    return this.getLength() === 0;
  }

  clear(): void {
    this.selection.clear();
    this.changes.next();
  }

  private markSelected(value: TValue): void {
    if (!this.multiple) {
      this.clear();
    }

    this.selection.set(this.keyFn(value), value);

    this.changes.next();
  }

  private unmarkSelected(value: TValue): void {
    this.selection.delete(this.keyFn(value));

    this.changes.next();
  }
}
