import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputAttachementAdd } from './InputAttachementAdd.entity';
import { InputCrmAnhangAttachementModify } from './InputCrmAnhangAttachementModify.entity';

export class InputCrmAnhangAttachementsRelation<
  T extends EMode = EMode.entity
> {
  /** Alias: remove */
  remove?: number[];
  /** Alias: add */
  add?: InputAttachementAdd<T>[];
  /** Alias: modify */
  modify?: InputCrmAnhangAttachementModify<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputCrmAnhangAttachementsRelation> = {
  remove: {
    alias: 'remove',
  },
  add: {
    alias: 'add',
  },
  modify: {
    alias: 'modify',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputCrmAnhangAttachementsRelationEntityDefinition: EntitiyDefinition<InputCrmAnhangAttachementsRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputCrmAnhangAttachementsRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
