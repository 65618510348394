import React, { useMemo } from 'react';
import { TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';
import { EmailTemplatePreviewContainer } from '@work4all/components/lib/components/entity-preview/email-template-preview';

import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EntityTable } from './EntityTable';
import schema from './schemata/eMailTemplate-table-schema.json';
import { Table } from './table/Table';
import { TableNoRowsPlaceholder } from './TableNoRowsPlaceholder';
import { useDataTable } from './use-data-table';
import { useDeleteEntitiesToolbarConfig } from './use-delete-entities-toolbar-config';
import { useTableMaskHandlers } from './use-table-mask-handlers';
import { useUserColumnConfigs } from './use-user-column-configs';

type Props = EntityTable;
const manualGroupBy = false;
const defaultSort = [{ field: 'name', direction: SortDirection.DESCENDING }];

export const EmailTemplateTable = React.forwardRef<TableInstance, Props>(
  function EmailTemplateTable(props, ref) {
    const { prefilter, onOpenMask, forceRequestFields, ...rest } = props;
    const tableStateBag = useTableStateBag();

    const {
      columnConfigs,
      prepareRowDisplayModifiers,
      data,
      fetchMore,
      total,
      selectedEntity,
      initialSortBy,
      pending,
    } = useDataTable<EMailTemplate, Record<string, never>>({
      schema: schema as never,
      tableStateBag,
      prefilter,
      forceRequestFields,
      defaultSort,
    });

    const maskHandlers = useTableMaskHandlers(
      Entities.eMailTemplate,
      onOpenMask
    );

    const deleteConfig = useDeleteEntitiesToolbarConfig({
      entityType: Entities.eMailTemplate,
    });

    const [userConfig, userConfigMethods] = useUserColumnConfigs({
      entityType: Entities.eMailTemplate,
      columnConfigs,
      layout: 'table',
    });

    const dataSorted = useMemo(() => {
      return [...data].sort((a, b) => a.name.localeCompare(b.name));
    }, [data]);

    if (!userConfig) return null;

    return (
      <Table
        pending={pending}
        noRowsRenderer={() => <TableNoRowsPlaceholder />}
        ref={ref}
        areas={{
          right: selectedEntity
            ? {
                content: (
                  <EmailTemplatePreviewContainer
                    emailTemplateId={selectedEntity.id}
                    onCloseClicked={() =>
                      tableStateBag.tableInstance.toggleAllRowsSelected(false)
                    }
                  />
                ),
              }
            : null,
        }}
        actions={{
          add: maskHandlers.create,
          edit: { ...maskHandlers.edit },
          remove: deleteConfig,
          resetColumns: userConfigMethods.remove,
        }}
        columnConfigs={userConfig}
        manualGroupBy={manualGroupBy}
        initialSortBy={initialSortBy}
        loadMoreItems={fetchMore}
        prepareRowDisplayModifiers={prepareRowDisplayModifiers}
        data={dataSorted}
        total={total}
        {...rest}
      />
    );
  }
);
