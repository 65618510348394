import { useMemo } from 'react';

import { useUser } from '@work4all/data';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';

export const useAuthHeaders = () => {
  const { activeTenant: tenant } = useTenant();
  const user = useUser();
  const bearerToken = user.token;
  const baseUrl = user.baseUrl;

  return useMemo(
    () => ({
      Authorization: `bearer ${bearerToken}`,
      mandantenCode: tenant.toString(),
      'x-work4all-apiurl': baseUrl,
    }),
    [bearerToken, tenant, baseUrl]
  );
};
