import { IUser } from '@work4all/data';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { Contract } from '@work4all/models/lib/Classes/Contract.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { Task } from '@work4all/models/lib/Classes/Task.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { TaskStatus } from '@work4all/models/lib/Enums/TaskStatus.enum';
import { TicketStatus } from '@work4all/models/lib/Enums/TicketStatus.enum';

interface normalizedData {
  title: string;
  subtitle1?: string;
  subtitle2?: string;
  subtitle3?: string;
  subtitle4?: string;
  toApply: {
    project?: Project;
    projectProcess?: ProjectProcess;
    businessPartner?: Customer;
    contract?: Contract;
    ticket?: Ticket;
  };
  businessPartnerName?: string;
  projectName?: string;
}

export const normalizeData = (
  data: Task[] | Contract[] | Project[] | Appointment[] | Ticket[],
  entityType
): normalizedData[] => {
  switch (entityType) {
    case Entities.task: {
      return (data as Task[]).map((item) => {
        return {
          title: item.title,
          subtitle1: item.businessPartner?.data?.name,
          subtitle2: item.project?.name,
          toApply: {
            project: item.project,
            businessPartner: item.businessPartner?.data as Customer,
            projectProcess: item.projectProcess,
          },
          businessPartnerName: item.businessPartner?.data?.name,
          projectName: item.project?.name,
        };
      });
    }

    case Entities.contract: {
      return (data as Contract[]).map((item) => {
        return {
          title: item.note,
          subtitle1: item.contractNumber.toString(),
          subtitle2:
            item.businessPartnerContactCombined?.businessPartner?.data?.name,
          subtitle3: item.project?.name,
          toApply: {
            project: item.project,
            businessPartner: item.businessPartnerContactCombined
              ?.businessPartner?.data as Customer,
            contract: item,
            projectProcess: item.projectProcess,
          },
          businessPartnerName:
            item.businessPartnerContactCombined?.businessPartner?.data?.name,
          projectName: item.project?.name,
        };
      });
    }

    case Entities.appointment: {
      return (data as Appointment[]).map((item) => {
        return {
          title: item.title,
          subtitle1: `${new Date(item.startDate).toLocaleString('de-DE', {
            dateStyle: 'short',
            timeStyle: 'short',
          })} - ${new Date(item.endDate).toLocaleString('de-DE', {
            dateStyle: 'short',
            timeStyle: 'short',
          })}`,
          subtitle2: item.businessPartner?.data?.name,
          subtitle3: item.project?.name,
          toApply: {
            project: item.project,
            businessPartner: item.businessPartner?.data as Customer,
            projectProcess: item.projectProcess,
          },
          businessPartnerName: item.businessPartner?.data?.name,
          projectName: item.project?.name,
        };
      });
    }

    case Entities.ticket: {
      return (data as Ticket[]).map((item) => {
        return {
          title: item.title,
          subtitle1: item.number.toString(),
          subtitle2: item.businessPartner?.data?.name,
          subtitle3: item.project?.name,
          subtitle4: item.ticketKind?.name,
          toApply: {
            project: item.project,
            businessPartner: item.businessPartner?.data as Customer,
            ticket: item,
            projectProcess: item.projectProcess,
          },
          businessPartnerName: item.businessPartner?.data?.name,
          projectName: item.project?.name,
        };
      });
    }

    case Entities.project: {
      return (data as Project[]).map((item) => {
        return {
          title: item.name,
          subtitle1: item.number.toString(),
          subtitle2: item.customer?.name || item.supplier?.name,
          toApply: {},
        };
      });
    }

    default: {
      return [];
    }
  }
};

export const requestContract: Contract<EMode.query> = {
  id: null,
  contractNumber: null,
  businessPartnerId: null,
  businessPartnerContactCombined: {
    businessPartner: {
      id: null,
      data: {
        customer: {
          id: null,
          name: null,
          website: null,
        },
        supplier: {
          id: null,
          name: null,
          website: null,
        },
      },
    },
  },
  project: {
    id: null,
    name: null,
    number: null,
  },
  projectProcess: {
    id: null,
    process: null,
  },
  user2: {
    displayName: null,
    id: null,
  },
  note: null,
  isClosed: null,
  positionList: [
    {
      id: null,
      index: null,
      number: null,
      shortText: null,
      longtext: null,
      positionKind: null,
      amount: null,
      unit: null,
    },
  ],
};

export const requestTask: Task<EMode.query> = {
  id: null,
  title: null,
  date: null,
  endDate: null,
  user: {
    id: null,
    displayName: null,
    phoneNumber: null,
  },
  project: {
    id: null,
    name: null,
    number: null,
    parentProject: {
      id: null,
      name: null,
      number: null,
    },
  },
  projectProcess: {
    id: null,
    process: null,
  },
  businessPartner: {
    id: null,
    data: {
      customer: {
        name: null,
        id: null,
        number: null,
        website: null,
      },
      supplier: {
        name: null,
        id: null,
        number: null,
        website: null,
      },
    },
  },
  businessPartnerType: null,
};

export const requestAppointment: Appointment<EMode.query> = {
  id: null,
  title: null,
  project: {
    id: null,
    name: null,
    number: null,
    parentProject: {
      id: null,
      name: null,
      number: null,
    },
  },
  projectProcess: {
    id: null,
    process: null,
  },
  businessPartner: {
    id: null,
    data: {
      customer: {
        name: null,
        id: null,
        number: null,
        website: null,
      },
      supplier: {
        name: null,
        id: null,
        number: null,
        website: null,
      },
    },
  },
  endDate: null,
  startDate: null,
  contractId: null,
  contract: {
    id: null,
    note: null,
    contractNumber: null,
  },
  deliveryNoteId: null,
  deliveryNote: {
    id: null,
    note: null,
    number: null,
  },
};

export const requestProject: Project<EMode.query> = {
  id: null,
  name: null,
  number: null,
};

export const requestTicket: Ticket<EMode.query> = {
  id: null,
  number: null,
  title: null,
  businessPartner: {
    id: null,
    data: {
      customer: {
        name: null,
        id: null,
        number: null,
        website: null,
      },
      supplier: {
        name: null,
        id: null,
        number: null,
        website: null,
      },
    },
  },
  project: {
    id: null,
    name: null,
    number: null,
  },
  projectProcess: {
    id: null,
    process: null,
  },
  editor1: {
    id: null,
    displayName: null,
  },
  ticketKind: {
    id: null,
    name: null,
  },
  contract: {
    id: null,
    note: null,
    number: null,
    contractNumber: null,
    positionList: [
      {
        id: null,
        positionKind: null,
        number: null,
        longtext: null,
        amount: null,
        unit: null,
      },
    ],
  },
  position: {
    id: null,
    positionKind: null,
    number: null,
    longtext: null,
    amount: null,
    unit: null,
  },
};

export const requestedData = (user: IUser) => ({
  [Entities.task]: {
    data: requestTask,
    sort: [
      {
        field: 'date',
        direction: SortDirection.DESCENDING,
      },
    ],
    filter: [
      {
        'user.id': { $eq: user.benutzerCode },
      },
      {
        status: { $eq: TaskStatus.OFFEN },
      },
    ],
  },
  [Entities.appointment]: {
    data: requestAppointment,
    sort: [
      {
        field: 'startDate',
        direction: SortDirection.DESCENDING,
      },
    ],
    filter: [
      {
        'user.id': { $eq: user.benutzerCode },
      },
    ],
  },
  [Entities.ticket]: {
    data: requestTicket,
    sort: [
      {
        field: 'followUpDate',
        direction: SortDirection.DESCENDING,
      },
    ],
    filter: [
      {
        $or: [
          {
            $and: [
              {
                status1: {
                  $nin: [TicketStatus.ERLEDIGT, TicketStatus.ZURUECKGESTELLT],
                },
              },
              {
                'editor1.id': { $eq: user.benutzerCode },
              },
            ],
          },
          {
            $and: [
              {
                status2: {
                  $nin: [TicketStatus.ERLEDIGT, TicketStatus.ZURUECKGESTELLT],
                },
              },
              {
                'editor2.id': { $eq: user.benutzerCode },
              },
            ],
          },
        ],
      },
    ],
  },
  [Entities.contract]: {
    data: requestContract,
    sort: [
      {
        field: 'contractDate',
        direction: SortDirection.DESCENDING,
      },
    ],
    filter: [
      {
        isClosed: { $eq: false },
      },
      {
        user2Id: { $eq: user.benutzerCode },
      },
    ],
  },
  [Entities.project]: {
    data: requestProject,
    sort: undefined,
    filter: [],
  },
});
