import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class CopyPosition<T extends EMode = EMode.entity> {
  /** Alias: positionCode */
  positionCode?: number;

  __typename?: string;
}

const fields: FieldDefinitions<CopyPosition> = {
  positionCode: {
    alias: 'positionCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const copyPositionEntityDefinition: EntitiyDefinition<CopyPosition> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'CopyPosition',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
