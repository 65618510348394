import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class TemplateGroup<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: name */
  name?: string;
  /** Alias: groupIndex */
  groupIndex?: number;

  __typename?: string;
}

const fields: FieldDefinitions<TemplateGroup> = {
  id: {
    alias: 'code',
  },
  name: {
    alias: 'name',
  },
  groupIndex: {
    alias: 'groupIndex',
  },
  __typename: {
    alias: '__typename',
  },
};

export const templateGroupEntityDefinition: EntitiyDefinition<TemplateGroup> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'TemplateGroup',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
