import styles from './SearchInput.module.scss';

import { IconButton } from '@mui/material';
import { useEventCallback } from '@mui/material/utils';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from '@work4all/assets/icons/close.svg';
import { ReactComponent as SearchIcon } from '@work4all/assets/icons/search.svg';

export interface ISearchInputProps {
  value: string;
  onChange: (value: string) => void;
}

export const SearchInput: React.VFC<ISearchInputProps> = (props) => {
  const { value, onChange } = props;
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  // Debounce changes to the input value to avoid re-fetching data and
  // re-rendering the table on every keystroke. In React 18 the rendering issue
  // could be resolve with `useTransition` API instead.
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const emitChange = useEventCallback(() => {
    if (inputValue !== value) {
      onChange(inputValue);
    }
  });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      emitChange();
    }, 300);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [emitChange, inputValue]);

  useEffect(() => {
    return () => {
      emitChange();
    };
  }, [emitChange]);

  const handleClearClick: React.MouseEventHandler = () => {
    setInputValue('');
    onChange('');
    inputRef.current?.focus();
  };

  return (
    <div className={styles['root']}>
      <input
        ref={inputRef}
        autoFocus
        className={styles['input']}
        placeholder={t('SEARCH.SEARCH_TERM')}
        value={inputValue}
        onChange={(event) => {
          setInputValue(event.target.value);
        }}
      />

      <IconButton
        disabled={!inputValue}
        onClick={handleClearClick}
        size="large"
      >
        {inputValue ? (
          <CloseIcon className={styles['icon-override']} />
        ) : (
          <SearchIcon className={styles['icon-override']} />
        )}
      </IconButton>
    </div>
  );
};
