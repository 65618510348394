import { createContext, useContext } from 'react';

import { Settings, SettingScope } from './types';

export type SettingsContext = {
  /**
   * Wether or not the settings state is being loaded. If `true`, the
   * `getValue()` function will return `null`.
   */
  isLoading: boolean;

  /**
   * Get the current value of the settings state.
   */
  getValue: () => Settings | null;

  /**
   * Subscribe to value changes. This function will be called whenever settings
   * state is changed. The returned function can be called to unsubscribe.
   */
  subscribe: (callback: () => void) => () => void;

  /**
   * Set the setting value in the state synchronously and send an update
   * mutation.
   */
  setSetting: (params: {
    name: string;
    scope: SettingScope;
    value: unknown;
  }) => void;

  /**
   * Delete the setting value in the state synchronously and send a delete
   * mutation.
   */
  deleteSetting: (params: { name: string; scope: SettingScope }) => void;

  /**
   * Set settings locally.
   * Can be use whenever you want to optimize loading for specific settings.
   */
  syncSettings: (
    params: {
      name: string;
      scope: SettingScope;
      value: unknown;
    }[]
  ) => void;
};

const Context = createContext<SettingsContext | null>(null);

export const SettingsContextProvider =
  Context.Provider as React.Provider<SettingsContext>;

export function useSettingsContext(): SettingsContext {
  const context = useContext(Context);

  if (context === null) {
    throw new Error(
      'useSettingsContext must be used within <SettingsContextProvider>'
    );
  }

  return context;
}
