import styles from './ReportButton.module.scss';

import { Star } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Fab, Theme, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useReportOptions } from '@work4all/components/lib/components/entity-picker/report-picker/ReportPicker';
import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack/hooks/useHistoryStack';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { useMaskContext } from '../../../../../hooks/mask-context';
import { convertEntityToBzObjType } from '../../../hooks/use-bz-shadow-object-api/use-shadow-bz-object-api';
import { useShadowBzObjectApiContext } from '../../../hooks/use-bz-shadow-object-api/use-shadow-bz-object-api-context';

import { ReportPreviewMask } from './ReportPreviewMask';

interface ReportButtonProps {
  disabled?: boolean;
}

export function ReportButton(props: ReportButtonProps) {
  const { disabled } = props;
  const { t } = useTranslation();
  const mask = useMaskContext();
  const { watch, handleSubmit } = useFormContext();

  const {
    persist,
    isDirty,
    entityId: persistedEntityId,
  } = useShadowBzObjectApiContext();

  const entityId = persistedEntityId || mask.id;

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const businessPartner = watch(
    'businessPartnerContactCombined.businessPartner'
  );
  const contact = watch('businessPartnerContactCombined.contact');
  const businessPartnerType = watch('businessPartnerType');

  const bzObjecType = useMemo(() => {
    try {
      return convertEntityToBzObjType(mask.entity);
    } catch {
      return undefined;
    }
  }, [mask.entity]);
  const { options: reports, data } = useReportOptions(bzObjecType);
  const options = useMemo(() => {
    return [{ id: 'default', name: t('COMMON.PREVIEW') }, ...reports];
  }, [reports, t]);

  const { go } = useHistoryStack();

  const handleOnClick = useCallback(
    (report) => {
      go({
        view: (
          <ReportPreviewMask
            id={entityId}
            bzObjecType={bzObjecType}
            report={report}
            contactId={contact?.id}
            businessPartnerId={businessPartner?.id}
            businessPartnerType={
              businessPartnerType === SdObjType.KUNDE
                ? Entities.customer
                : Entities.supplier
            }
          />
        ),
      });
    },
    [go, entityId, bzObjecType, contact, businessPartner, businessPartnerType]
  );

  const shouldOpenReport = useRef(false);
  const reportToOpen = useRef(null);

  useEffect(() => {
    if (!isDirty && shouldOpenReport.current && entityId) {
      if (pathname.includes('/new')) {
        navigate(pathname.replace('new', entityId), { replace: true });
      }

      shouldOpenReport.current = false;
      handleOnClick(reportToOpen.current);
    }
  }, [isDirty, handleOnClick, entityId, pathname, navigate]);

  return (
    <SplitButton
      disabled={disabled || !reports.length}
      firstIsTitle
      options={options}
      onClick={async (option) => {
        let report = data.find((x) => x.id === option.id);
        if (!report) {
          report = data.find((x) => x.standardReport);
          if (!report) report = data[0];
        }
        if (isDirty || mask.isCreateMode) {
          await handleSubmit(persist)();
          shouldOpenReport.current = true;
          reportToOpen.current = report;
        } else {
          handleOnClick(report || null);
        }
      }}
      renderItem={(option) => {
        const report = data.find((x) => x.id === option.id);
        return (
          <React.Fragment>
            <div className={styles.description}>{option.name}</div>
            {report.standardReport ? (
              <Star className={styles.favActive} />
            ) : null}
          </React.Fragment>
        );
      }}
    />
  );
}

interface Option {
  id: number | string;
  name: string;
}

interface SplitButtonProps {
  firstIsTitle?: boolean;
  options: Option[];
  onClick: (option: Option) => void;
  disabled?: boolean;
  renderItem?: (option: Option) => JSX.Element;
}

// TODO: move to dummy components
export const SplitButton = (props: SplitButtonProps) => {
  const { options, firstIsTitle, onClick, disabled } = props;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement | HTMLButtonElement>(null);
  const [selected, setSelected] = useState(options[0]);

  const handleClick = () => {
    onClick(selected);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: Option
  ) => {
    if (!firstIsTitle) setSelected(option);
    setOpen(false);
    onClick(option);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const md = useMediaQuery<Theme>((t) => t.breakpoints.down('md'));

  return (
    <React.Fragment>
      {md ? (
        <Fab
          ref={anchorRef as React.MutableRefObject<HTMLButtonElement>}
          color="primary"
          size="medium"
          data-test-id={'moreVertIcon'}
          onClick={handleToggle}
        >
          <KeyboardArrowDownIcon />
        </Fab>
      ) : (
        <ButtonGroup
          variant="contained"
          ref={anchorRef as React.MutableRefObject<HTMLDivElement>}
          aria-label="split button"
        >
          <Button
            disabled={disabled}
            style={{
              borderRightColor: 'white',
            }}
            className={styles['border']}
            onClick={handleClick}
          >
            {selected.name}
          </Button>
          <Button
            disabled={disabled}
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            //   aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <KeyboardArrowDownIcon />
          </Button>
        </ButtonGroup>
      )}

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {(firstIsTitle ? options.slice(1) : options).map((option) => (
                    <MenuItem
                      key={option.id}
                      className={styles['menu-item']}
                      selected={option.id === selected.id}
                      onClick={(event) => handleMenuItemClick(event, option)}
                    >
                      {props.renderItem
                        ? props.renderItem(option)
                        : option.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};
