import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CityPicker,
  CityPickerProps,
} from '@work4all/components/lib/components/entity-picker/city-picker/CityPicker';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';

import { reactRefSetter } from '@work4all/utils';

import { LabeledInput } from '../../containers/mask-overlays/locked-inputs';

interface CityPickerFieldProps
  extends Omit<
    CityPickerProps,
    'multiple' | 'value' | 'onChange' | 'canWriteValue'
  > {
  error?: string;
  postalCodeFilter?: string;
  value: string;
  onChange: (value: string) => void;
  onClick?: () => void;
  label?: string;
  canWriteValue?: boolean;
}

export const CityPickerField = React.forwardRef<
  HTMLDivElement,
  CityPickerFieldProps
>(function CityPickerField(props, ref) {
  const { error, value, label, onChange, canWriteValue, ...pickerProps } =
    props;

  const { t } = useTranslation();

  const wrapperRef = useRef<HTMLDivElement>(null);
  return (
    <div ref={reactRefSetter(wrapperRef, ref)}>
      <EntityPickerPopover
        anchorEl={wrapperRef}
        picker={
          <CityPicker
            {...pickerProps}
            value={{ id: null, city: value }}
            onChange={(e) => {
              onChange(e.city);
            }}
            canWriteValue={canWriteValue}
          />
        }
      >
        <LabeledInput
          value={value ?? ''}
          label={label || t('INPUTS.LOCATION')}
          error={error}
          onClear={() => {
            onChange(null);
          }}
          onChange={
            canWriteValue
              ? (e) => {
                  onChange(e.target.value);
                }
              : undefined
          }
        />
      </EntityPickerPopover>
    </div>
  );
});
