import styles from './styles.module.scss';

import { ListItem } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { ReactComponent as ChevronRightIcon } from '@work4all/assets/icons/chevron-right.svg';

import {
  DND_POPOVER_OPEN_DELAY,
  DndTypes,
} from '@work4all/utils/lib/variables';

import { IFavoriteLinksFolder } from '../../../../containers/more/data/favorite-links';
import { FavoriteLinksFolderDragObject } from '../../../../dnd/drag-objects';
import { getHoverPosition } from '../../../../dnd/get-hover-position';
import { useDropEnabled } from '../../../../dnd/use-drop-enabled';
import { useEmptyDragPreview } from '../../../../dnd/use-empty-drag-preview';

export type IPopoverListFolderItemProps = {
  index: number;
  folder: IFavoriteLinksFolder;
  active?: boolean;
  onClick: (folder: IFavoriteLinksFolder) => void;
  onContextMenu?: React.MouseEventHandler;
  onMove: (index: number) => void;
  onDrop: (type: unknown, item: unknown) => void;
  onDropOnFolder: (
    folder: IFavoriteLinksFolder,
    type: unknown,
    item: unknown
  ) => void;
};

export function PopoverListFolderItem({
  index,
  folder,
  active = false,
  onClick,
  onContextMenu,
  onMove,
  onDrop,
  onDropOnFolder,
}: IPopoverListFolderItemProps) {
  const ref = useRef<HTMLDivElement>(null);

  const [, drag, preview] = useDrag<
    FavoriteLinksFolderDragObject,
    unknown,
    unknown
  >({
    type: DndTypes.FAVORITE_LINKS_FOLDER,
    item: { id: folder.id, text: folder.name },
  });

  useEmptyDragPreview(preview);

  const [{ isOver }, drop] = useDrop({
    accept: [
      DndTypes.LINK,
      DndTypes.FAVORITE_LINK,
      DndTypes.FAVORITE_LINKS_FOLDER,
    ],
    hover: (_item, monitor) => {
      if (!ref.current) {
        return;
      }

      const position = getHoverPosition(
        monitor.getClientOffset(),
        ref.current.getBoundingClientRect()
      );

      const itemType = monitor.getItemType();

      if (position.over && itemType !== DndTypes.FAVORITE_LINKS_FOLDER) {
        onMove(-1);
      } else if (position.before) {
        onMove(index);
      } else if (position.after) {
        onMove(index + 1);
      }
    },
    drop: (item, monitor) => {
      if (!ref.current) {
        return;
      }

      const position = getHoverPosition(
        monitor.getClientOffset(),
        ref.current.getBoundingClientRect()
      );

      const itemType = monitor.getItemType();

      if (position.over && itemType !== DndTypes.FAVORITE_LINKS_FOLDER) {
        onDropOnFolder(folder, itemType, item);
      } else {
        onDrop(itemType, item);
      }
    },
    collect: (monitor) => {
      if (ref.current && monitor.isOver()) {
        const itemType = monitor.getItemType();
        const position = getHoverPosition(
          monitor.getClientOffset(),
          ref.current.getBoundingClientRect()
        );

        if (position.over && itemType !== DndTypes.FAVORITE_LINKS_FOLDER) {
          return { isOver: true };
        }
      }

      return { isOver: false };
    },
  });

  const dropEnabled = useDropEnabled(isOver);

  useEffect(() => {
    if (dropEnabled && isOver) {
      const timeout = setTimeout(() => {
        onClick(folder);
      }, DND_POPOVER_OPEN_DELAY);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [folder, dropEnabled, isOver, onClick]);

  drag(drop(ref));

  return (
    <ListItem
      ref={ref}
      button
      className={clsx(
        styles.popoverItem,
        styles.popoverFolderItem,
        isOver && styles.popoverLinkItemOver,
        active && styles.active
      )}
      onClick={() => onClick(folder)}
      onContextMenu={onContextMenu}
    >
      <span>{folder.name}</span>
      <ChevronRightIcon className={styles.folderIcon} />
    </ListItem>
  );
}
