import { useCallback, useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { LegalNoticeParseResult } from '@work4all/models/lib/Classes/LegalNoticeParseResult.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

const requestAdressData: LegalNoticeParseResult<EMode.query> = {
  name: null,
  firm1: null,
  firm2: null,
  firm3: null,
  street: null,
  country: null,
  city: null,
  vatId: null,
  eMail: null,
  website: null,
  postalCode: null,
  phoneNumber: null,
  faxNumber: null,
};

export const useLegalNotice = (imprintValue: string, skip: boolean) => {
  const requestData = useMemo(() => {
    return {
      entity: Entities.legalNoticeParseResult,
      data: requestAdressData,
      completeDataResponse: true,
      vars: { legalNotice: imprintValue },
    } as DataRequest;
  }, [imprintValue]);

  const { data, loading } = useDataProvider<LegalNoticeParseResult>(
    requestData,
    skip || imprintValue.length === 0
  );

  const transformData = useCallback(
    (
      inData: LegalNoticeParseResult[]
    ): LegalNoticeParseResult[] | LegalNoticeParseResult => {
      if (loading || skip) return inData;

      if (imprintValue && (!inData || inData?.length === 0)) {
        return { firm1: imprintValue };
      }

      return inData;
    },
    [loading, imprintValue, skip]
  );
  return { data: transformData(data), loading };
};
