import { useEffect, useRef } from 'react';

export function useOnWindowFocus(callback: () => void): void {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const listener = () => {
      return callbackRef.current();
    };

    window.addEventListener('focus', listener);

    return () => {
      window.removeEventListener('focus', listener);
    };
  }, []);
}
