import { useApolloClient } from '@apollo/client';

import { EMail } from '@work4all/models/lib/Classes/EMail.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { ChangeType } from '@work4all/models/lib/Enums/ChangeType.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { buildQuery } from './data-provider/utils/buildQuery';
import {
  UseEntityChangedOptions,
  useEntityChanges,
} from './use-entity-changed';

interface UseRefetchEmailStatusOptions
  extends Pick<UseEntityChangedOptions, 'disabled'> {
  /**
   * Whenever a change event is received, this function will be called to test
   * if a given email's status should be synced. If the function returns `true`,
   * we will fetch the new email status and update the Apollo cache.
   */
  shouldSync: (info: { id: number }) => boolean;
}

/**
 * Subscribe to `entityChanged` GraphQL subscription and re-fetch the email
 * status and update the Apollo cache whenever a change is detected. You can
 * control whether or not a given email status should be fetched with the
 * `shouldSync`.
 */
export function useSyncEmailStatus(
  options: UseRefetchEmailStatusOptions
): void {
  const { disabled, shouldSync } = options;

  const apolloClient = useApolloClient();

  useEntityChanges({
    disabled,
    entity: Entities.eMail,
    changeType: [ChangeType.ITEM_UPDATED],
    async onEntityChanged(info) {
      const id = info.objCode;

      if (!shouldSync({ id })) return;

      const data: EMail<EMode.query> = {
        id: null,
        kind: null,
        saveSendMailJob: {
          id: null,
          errorMessages: null,
          jobState: null,
        },
      };

      const request: DataRequest = {
        operationName: 'RefetchEmailStatus',
        entity: Entities.eMail,
        data,
        filter: [{ id: { $eq: id } }],
      };

      const { query, variables } = buildQuery(request, 1);

      await apolloClient.query({
        query,
        variables,
        fetchPolicy: 'network-only',
      });
    },
  });
}
