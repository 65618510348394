import styles from './AttachmentsTabPanel.module.scss';

import { Attachments } from '@work4all/components/lib/components/attachments/Attachments';

import { useTableLayoutState } from '../../../../../../../../components/data-tables/table-layout';
import { Panel } from '../../../../../components/panel/Panel';

export const AttachmentsTabPanel = () => {
  const [tableLayout] = useTableLayoutState();
  return (
    <Panel className={styles.maxHeight}>
      <Attachments tableLayout={tableLayout} />
    </Panel>
  );
};
