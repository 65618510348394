import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ListEntityPicker } from './components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from './types';

export type EmailTemplatePickerProps = Omit<
  IPickerProps<EMailTemplate, false>,
  'multiple' | 'prefilter'
>;

export function EmailTemplatePicker(props: EmailTemplatePickerProps) {
  const { data, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...EMAIL_TEMPLATE_PICKER_DATA, ...data };
  }, [data]);

  const request = useMemo<DataRequest>(() => {
    return {
      data: allFields,
      entity: Entities.eMailTemplate,
      completeDataResponse: true,
      operationName: 'GetEmailTemplates',
    };
  }, [allFields]);

  const response = useDataProvider<EMailTemplate>(request);

  const dataset = useMemo(() => {
    return {
      loading: response.loading,
      data: [...response.data].sort((a, b) => a.name.localeCompare(b.name)),
      total: response.data.length,
      fetchMore: () => Promise.resolve(),
      refetch: () => Promise.resolve(null),
    };
  }, [response]);

  const renderItem = (item: EMailTemplate) => {
    const label = item.name;

    return (
      <Tooltip title={label}>
        <Typography variant="body2" noWrap>
          {label}
        </Typography>
      </Tooltip>
    );
  };

  return (
    <ListEntityPicker
      multiple={false}
      entity={Entities.eMailTemplate}
      data={allFields}
      filterBy="name"
      sortBy="name"
      fixedDataSet={dataset}
      renderItemContent={renderItem}
      {...rest}
    />
  );
}

const EMAIL_TEMPLATE_PICKER_DATA: EMailTemplate = {
  id: null,
  name: null,
  body: null,
  subject: null,
  signature: {
    body: null,
    id: null,
    name: null,
  },
  attachmentList: [
    {
      id: null,
    },
  ],
};
