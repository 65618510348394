import { GroupQueryResult } from '@work4all/models/lib/Classes/GroupQueryResult.entity';
import { AggregationType } from '@work4all/models/lib/Enums/AggregationType.enum';
import { SortOrderStyle } from '@work4all/models/lib/GraphQL/globalTypes';
import { ObjectTypesUnion } from '@work4all/models/lib/GraphQLEntities';

import { IRow, Path } from '../../types';

export enum GroupFieldSortType {
  SUM = 'SUM',
  MIN = 'MIN',
  MAX = 'MAX',
  MID = 'MID',
  COUNT = 'COUNT',
  BY_FIELD = 'BY_FIELD',
}

export interface GroupByQuery {
  entityType: ObjectTypesUnion;
  groupKeyFields?: {
    field: string;
    alias?: string;
    aggregations?: AggregationType[];
    sort?: {
      sortType: GroupFieldSortType;
      sortOrder: SortOrderStyle;
    }[];
  }[];
  filter: string | number;
}

// TODO: create global type (no any).
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Filters = Record<string, any>[];

export type Variables = Record<string, string | number> & {
  sortBy: string;
  sortOrder: SortOrderStyle;
};

export interface FetchResponse {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: Record<string, any>[];
  total: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GroupedItem = Record<string, any> &
  IRow & {
    subRows?: GroupedItems;
  };

export type GroupedItems = GroupedItem[];

export interface IGroupByResponse {
  groupBy: GroupQueryResult;
}

export interface FetchMoreOptions {
  path: Path;
  offset: number;
  filters: Filters;
}
