import { noop } from 'lodash';
import { createContext } from 'react';

import { WidgetSelectedItemId } from '@work4all/data/lib/hooks/useWidgetsBag';

export interface ISelectedItemContextValue {
  item: null | WidgetSelectedItemId;
  setItem: (item: null | WidgetSelectedItemId) => void;
}

export const SelectedItemContext = createContext<ISelectedItemContextValue>({
  item: null,
  setItem: noop,
});
