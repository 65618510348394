import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { FileEntity } from './FileEntity.entity';

export class EMailTemplateAttachment<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: vorlagenId */
  templateId?: string;
  /** Alias: dateiname */
  fileName?: string;
  /** Alias: name */
  name?: string;
  /** Alias: fileInfos */
  fileInfos?: FileEntity<T>;

  __typename?: string;
}

const fields: FieldDefinitions<EMailTemplateAttachment> = {
  id: {
    alias: 'id',
  },
  templateId: {
    alias: 'vorlagenId',
  },
  fileName: {
    alias: 'dateiname',
  },
  name: {
    alias: 'name',
  },
  fileInfos: {
    alias: 'fileInfos',
    entity: Entities.fileEntity,
  },
  __typename: {
    alias: '__typename',
  },
};

export const eMailTemplateAttachmentEntityDefinition: EntitiyDefinition<EMailTemplateAttachment> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'EMailVorlageAnhang',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
