import { gql, useQuery } from '@apollo/client';
import { useEventCallback } from '@mui/material/utils';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { AppParts, IUser, useCanView, useDataProvider } from '@work4all/data';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Mailbox } from '@work4all/models/lib/Classes/Mailbox.entity';
import { SalesOpportunities } from '@work4all/models/lib/Classes/SalesOpportunities.entity';
import { Task } from '@work4all/models/lib/Classes/Task.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { TravelReceipts } from '@work4all/models/lib/Classes/TravelReceipts.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { ChangeType } from '@work4all/models/lib/Enums/ChangeType.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SalesOpportunityStatus } from '@work4all/models/lib/Enums/SalesOpportunityStatus.enum';
import { TaskStatus } from '@work4all/models/lib/Enums/TaskStatus.enum';
import { TicketStatus } from '@work4all/models/lib/Enums/TicketStatus.enum';

import { extendMailboxFolders } from '../../../../../components/data-tables/MailboxContentTable/extend-mailbox-folders';
import { settings, useSetting } from '../../../../../settings';
import { useProjectHoursState } from '../../../../time-tracker/ProjectHours';
import { useVacationStats } from '../../../../vacations/use-vacation-stats';

import {
  useRefetchOnSubscription,
  useWidgetEntryData,
} from './use-widget-entry-data';

const CHANGE_TYPE = [
  ChangeType.ITEM_INSERTED,
  ChangeType.ITEM_UPDATED,
  ChangeType.ITEM_DELETED,
];

const CONTACT_DATA: Contact<EMode.query> = {
  id: null,
  firstName: null,
  name: null,
  displayName: null,
  businessPartnerType: null,
  businessPartnerId: null,
  businessPartner: {
    id: null,
    businessPartnerType: null,
    data: {
      customer: {
        name: null,
        id: null,
        extinct: null,
      },
      supplier: {
        name: null,
        id: null,
        extinct: null,
      },
    },
  },
  letterSalutation: null,
  birthdayDate: null,
  birthdayMonth: null,
  birthdayDay: null,
  eMail: null,
  eMailPrivate: null,
  phoneNumber: null,
  mobileNumber: null,
  mobileNumberPrivate: null,
};

const USER_DATA: User<EMode.query> = {
  id: null,
  displayName: null,
  letterSalutation: null,
  eMail: null,
  mobileNumber: null,
  geburtsdatum: null,
};

export function useRelevantData(props: {
  user: IUser;
  startDate: DateTime;
  endDate: DateTime;
  endOfWeek?: DateTime;
}) {
  const { user, startDate, endDate } = props;
  const canViewBirthdays = useCanView(AppParts.BIRTHDAYS);

  const requestDataAppointment: DataRequest['filter'] = useMemo(() => {
    return [
      { startDate: { $lt: endDate.toISO() } },
      { endDate: { $gte: startDate.toISO() } },
      {
        userId: {
          $eq: user.benutzerCode,
        },
      },
    ];
  }, [endDate, startDate, user.benutzerCode]);

  const resAppointment = useWidgetEntryData<Appointment>({
    entity: Entities.appointment,
    filter: requestDataAppointment,
    size: 1,
  });

  const requestDataTask: DataRequest['filter'] = useMemo(() => {
    return [
      {
        status: { $nin: [TaskStatus.ERLEDIGT, TaskStatus.ZURUECK_GESTELLT] },
      },
      { date: { $lte: endDate } },
      {
        userId: {
          $eq: user.benutzerCode,
        },
      },
    ];
  }, [endDate, user.benutzerCode]);

  const resTask = useWidgetEntryData<Task>({
    entity: Entities.task,
    filter: requestDataTask,
    size: 1,
  });

  const requestDataTicketS1: DataRequest = useMemo(() => {
    return {
      entity: Entities.ticket,
      data: {},
      filter: [
        {
          $and: [
            {
              status1: {
                $nin: [TicketStatus.ERLEDIGT, TicketStatus.ZURUECKGESTELLT],
              },
            },
            {
              'editor1.id': { $eq: user.benutzerCode },
            },
            { followUpDate: { $lte: endDate.toISO() } },
          ],
        },
      ],
    };
  }, [endDate, user.benutzerCode]);

  const resTicketS1 = useDataProvider<Ticket>(requestDataTicketS1, false, 1);

  const requestDataTicketS2: DataRequest = useMemo(() => {
    return {
      entity: Entities.ticket,
      data: {},
      filter: [
        {
          $and: [
            {
              status2: {
                $nin: [TicketStatus.ERLEDIGT, TicketStatus.ZURUECKGESTELLT],
              },
            },
            {
              'editor2.id': { $eq: user.benutzerCode },
            },
            { followUpDate: { $lte: endDate.toISO() } },
          ],
        },
      ],
    };
  }, [endDate, user.benutzerCode]);

  const resTicketS2 = useDataProvider<Ticket>(requestDataTicketS2, false, 1);

  const refetchTickets = useEventCallback(() => {
    resTicketS1.refetch();
    resTicketS2.refetch();
  });
  useRefetchOnSubscription({
    entity: Entities.ticket,
    refetch: refetchTickets,
  });

  const { stats: vacationStats, refetch: refetchVacationStats } =
    useVacationStats({
      userId: user.benutzerCode,
      year: new Date().getFullYear(),
    });

  useRefetchOnSubscription({
    entity: Entities.user,
    refetch: refetchVacationStats,
  });

  const projectHoursState = useProjectHoursState({
    userToTrack: user,
  });

  useRefetchOnSubscription({
    entity: Entities.timeTrackingOverviewItem,
    refetch: projectHoursState.refetch,
  });

  const resSalesOpportunities = useWidgetEntryData<SalesOpportunities>({
    entity: Entities.salesOpportunities,
    filter: useMemo(() => {
      return [
        {
          $and: [
            { status: { $eq: SalesOpportunityStatus.ACTIVE } },
            { 'user2.id': { $eq: user.benutzerCode } },
          ],
        },
      ];
    }, [user.benutzerCode]),
  });

  const dataRequest: DataRequest = useMemo(() => {
    return {
      entity: Entities.travelReceipts,
      data: {},
      filter: [
        {
          $and: [
            { 'travelExpenses.closedByuserid': { $eq: 0 } },
            { 'travelExpenses.businessPartner.id': { $eq: user.supplierCode } },
          ],
        },
      ],
    };
  }, [user.supplierCode]);

  const resTravelReceipts = useDataProvider<TravelReceipts>(dataRequest);

  // TODO: this is under investigation why useEntityChanges here blocks events from other places
  // TICKET: https://work4all.atlassian.net/browse/WW-3677
  // const resTravelReceipts = useWidgetEntryData<TravelReceipts>({
  //   entity: Entities.travelReceipts,
  //   filter: useMemo(() => {
  //     return [
  //       {
  //         $and: [
  //           { 'travelExpenses.closedByuserid': { $eq: 0 } },
  //           { 'travelExpenses.businessPartner.id': { $eq: user.benutzerCode } },
  //         ],
  //       },
  //     ];
  //   }, [user.benutzerCode]),
  // });

  const requestDataContactsBirthdays: DataRequest['filter'] = useMemo(() => {
    return [
      {
        $and: [
          { birthdayMonth: { $gte: startDate.month } },
          { birthdayMonth: { $lte: startDate.plus({ month: 1 }).month } },
          { layedOff: { $eq: false } },
        ],
      },
    ];
  }, [startDate]);

  const resContactsBirthdays = useWidgetEntryData<Contact>({
    entity: Entities.contact,
    filter: requestDataContactsBirthdays,
    skip: !canViewBirthdays,
    data: CONTACT_DATA,
  });

  const usersId = useUpcomingBirthdaysUsersId(!canViewBirthdays);
  const requestDataUsersBirthdays: DataRequest = useMemo(() => {
    return {
      entity: Entities.user,
      data: USER_DATA,
      filter: [
        {
          $and: [{ hasLeft: { $eq: false } }, { id: { $in: usersId } }],
        },
      ],
      skip: usersId.length === 0,
    };
  }, [usersId]);

  const resUsersBirthdays = useDataProvider<User>(
    requestDataUsersBirthdays,
    !canViewBirthdays
  );

  useRefetchOnSubscription({
    entity: Entities.user,
    refetch: resUsersBirthdays.refetch,
  });

  const mailboxesRequest = useMemo<DataRequest>(() => {
    const data: Mailbox = {
      id: null,
      mailboxPrimaryAddress: null,
      folder: [
        {
          id: null,
          name: null,
          folderType: null,
          unreadItemsCount: null,
          parentId: null,
        },
      ],
    };

    return {
      entity: Entities.mailbox,
      singleBatch: true,
      data,
      completeDataResponse: true,
      operationName: 'GetMailboxes',
    };
  }, []);

  const mailboxesResponse = useDataProvider<Mailbox>(mailboxesRequest);

  const mailboxFolderVisibilitySetting = useSetting(
    settings.mailboxFolderVisibility()
  );

  // Remove hidden folders and don't process mailboxes with errors, as these
  // won't have the folders info.
  const visibleMailboxFolders = useMemo(() => {
    const mailboxes = extendMailboxFolders(
      mailboxesResponse.data,
      mailboxFolderVisibilitySetting.value
    );

    const folders = mailboxes
      .flatMap((mailbox) => mailbox.folder)
      .filter((folder) => folder != null && folder.isVisible);

    return folders;
  }, [mailboxesResponse.data, mailboxFolderVisibilitySetting.value]);

  const unreadEmailsToAssign = useMemo(() => {
    const totalUnread = visibleMailboxFolders
      .map((folder) => folder.unreadItemsCount ?? 0)
      .reduce((acc, cur) => acc + cur, 0);

    return { totalUnread };
  }, [visibleMailboxFolders]);

  return {
    resAppointment,
    resTask,
    projectHoursState,
    vacationStats,
    resTicketS1,
    resTicketS2,
    resSalesOpportunities,
    resTravelReceipts,
    resContactsBirthdays,
    unreadEmailsToAssign,
    mailboxList: mailboxesResponse.data,
    resUsersBirthdays,
  };
}

const GET_UPCOMMING_BIRTHDAYS = gql`
  query GetUpcommingBirthdays(
    $startDate: DateTimeEx!
    $lookaheadDays: Int
    $contactTypes: [ContactType]
  ) {
    getUpcommingBirthdays(
      startDate: $startDate
      lookaheadDays: $lookaheadDays
      contactTypes: $contactTypes
    ) {
      ... on Benutzer {
        code
      }
    }
  }
`;

const useUpcomingBirthdaysUsersId = (skip = false) => {
  const { data } = useQuery<{ getUpcommingBirthdays: { code: number }[] }>(
    GET_UPCOMMING_BIRTHDAYS,
    {
      variables: {
        startDate: DateTime.now().toISODate(),
        lookaheadDays: 7,
        contactTypes: 'BENUTZER',
      },
      skip: skip,
    }
  );

  const ids = useMemo(
    () =>
      data?.getUpcommingBirthdays
        ? data.getUpcommingBirthdays.map((user) => user.code)
        : [],
    [data]
  );

  return ids;
};
