import { useState } from 'react';

import { AppParts, useCanView } from '@work4all/data';

import { TimeTrackerMenuContext } from '../contexts/TimeTrackerMenuContext';
import { TimeTrackerMenu } from '../TimeTrackerMenu';
import { useTimeTracker } from '../use-time-tracker';

interface TimeTrackerMenuProviderProps {
  children: React.ReactNode;
}

export const TimeTrackerMenuProvider = ({
  children,
}: TimeTrackerMenuProviderProps) => {
  const [isOpen, setOpen] = useState(false);

  const isWorkTimeEnabled = useCanView(AppParts.WORKTIMETRACKING);
  const tracker = useTimeTracker({
    disabled: !isWorkTimeEnabled,
    amplitudeEntryPoint: 'NavigationBar',
  });

  return (
    <TimeTrackerMenuContext.Provider value={{ setOpen }}>
      <TimeTrackerMenu
        open={isOpen}
        onClose={() => setOpen(false)}
        tracker={tracker}
        onClick={() => setOpen(true)}
      />

      {children}
    </TimeTrackerMenuContext.Provider>
  );
};
