import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { ReceiptKindInternal } from '../Enums/ReceiptKindInternal.enum';

export class TravelCostInvoiceKind<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: name */
  name?: string;
  /** Alias: ledgerAccount1 */
  ledgerAccount1?: number;
  /** Alias: payrollAccount */
  payrollAccount?: number;
  /** Alias: vat1 */
  vat1?: number;
  /** Alias: vat2 */
  vat2?: number;
  /** Alias: vat3 */
  vat3?: number;
  /** Alias: ledgerAccount2 */
  ledgerAccount2?: number;
  /** Alias: ledgerAccount3 */
  ledgerAccount3?: number;
  /** Alias: vatKey1 */
  vatKey1?: number;
  /** Alias: vatKey2 */
  vatKey2?: number;
  /** Alias: vatKey3 */
  vatKey3?: number;
  /** Alias: typeOfReceiptType */
  typeOfReceiptType?: ReceiptKindInternal;

  __typename?: string;
}

const fields: FieldDefinitions<TravelCostInvoiceKind> = {
  id: {
    alias: 'code',
  },
  name: {
    alias: 'name',
  },
  ledgerAccount1: {
    alias: 'ledgerAccount1',
  },
  payrollAccount: {
    alias: 'payrollAccount',
  },
  vat1: {
    alias: 'vat1',
  },
  vat2: {
    alias: 'vat2',
  },
  vat3: {
    alias: 'vat3',
  },
  ledgerAccount2: {
    alias: 'ledgerAccount2',
  },
  ledgerAccount3: {
    alias: 'ledgerAccount3',
  },
  vatKey1: {
    alias: 'vatKey1',
  },
  vatKey2: {
    alias: 'vatKey2',
  },
  vatKey3: {
    alias: 'vatKey3',
  },
  typeOfReceiptType: {
    alias: 'typeOfReceiptType',
  },
  __typename: {
    alias: '__typename',
  },
};

export const travelCostInvoiceKindEntityDefinition: EntitiyDefinition<TravelCostInvoiceKind> =
  {
    local: {},
    remote: {
      queryName: 'getTravelAndReceiptReceiptTypes',
      fragmentName: 'ReisekostenabrechnungBelegart',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
