import { gql, MutationHookOptions, useMutation } from '@apollo/client';

import { ObjectTypesUnion } from '@work4all/models';

const ADD_COMMENT_MUTATION = gql`
  mutation AddComment(
    $codeOrId: String!
    $objectType: ObjectType!
    $comment: String!
  ) {
    addComment(
      codeOrId: $codeOrId
      objectType: $objectType
      comment: $comment
    ) {
      id
    }
  }
`;

export interface AddCommentMutationResponse {
  addComment: {
    id: string;
  };
}

export interface AddCommentMutationVariables {
  codeOrId: string | number;
  objectType: ObjectTypesUnion;
  comment: string;
}

export function useAddCommentMutation(
  options?: MutationHookOptions<
    AddCommentMutationResponse,
    AddCommentMutationVariables
  >
) {
  return useMutation<AddCommentMutationResponse, AddCommentMutationVariables>(
    ADD_COMMENT_MUTATION,
    options
  );
}
