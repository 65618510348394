import { SettingType } from '@work4all/models/lib/Enums/SettingType.enum';

import { assertNever } from '@work4all/utils';

import { SettingEntity } from '../entities/settingEntity';

import { SETTING_NAME_PREFIX, UNPREFIXED_USER_SETTINGS } from './config';
import { SettingScope } from './types';

type ParsedSetting = {
  name: string;
  scope: SettingScope;
  value: unknown;
};

interface ParseSettingOptions {
  tenant: number;
}

/**
 * Try to parse a given object as a setting. If an object cannot be parsed
 * (settings with "PERSONAL" type but incorrect name prefix), return `null`.
 */
export function parseSetting(
  setting: SettingEntity,
  options: ParseSettingOptions
): ParsedSetting | null {
  switch (setting.settingType) {
    case SettingType.GLOBAL:
      return parseGlobalSetting(setting);

    case SettingType.PERSONAL:
      return parsePersonalSetting(setting, options);

    default:
      assertNever(
        setting.settingType,
        `Unknown setting type ${setting.settingType}`
      );
  }
}

function parseGlobalSetting(setting: SettingEntity): ParsedSetting | null {
  return {
    name: setting.name,
    scope: 'global',
    value: parseSettingValue(setting.value),
  };
}

function parsePersonalSetting(
  setting: SettingEntity,
  options: ParseSettingOptions
): ParsedSetting | null {
  if (UNPREFIXED_USER_SETTINGS.includes(setting.name)) {
    return {
      name: setting.name,
      scope: 'user',
      value: parseSettingValue(setting.value),
    };
  }

  if (!setting.name.startsWith(SETTING_NAME_PREFIX)) {
    return null;
  }

  // Remove the prefix from the name for PERSONAL settings.
  const _setting = {
    ...setting,
    name: setting.name.slice(SETTING_NAME_PREFIX.length),
  };

  return parseTenantSetting(_setting, options) ?? parseUserSetting(_setting);
}

/**
 * Try to parse the given setting as a tenant setting. If the setting cannot be
 * parsed as a tenant setting (the name does not match the known pattern),
 * return `null`.
 */
function parseTenantSetting(
  setting: SettingEntity,
  options: ParseSettingOptions
): ParsedSetting | null {
  // Tenant-specific names follow the pattern "w4a2.t-{tenant}.{name}".
  const prefix = `t-${options.tenant}.`;

  if (!setting.name.startsWith(prefix)) {
    return null;
  }

  const name = setting.name.slice(prefix.length);

  return {
    name: name,
    scope: 'tenant',
    value: parseSettingValue(setting.value),
  };
}

function parseUserSetting(setting: SettingEntity): ParsedSetting | null {
  return {
    name: setting.name,
    scope: 'user',
    value: parseSettingValue(setting.value),
  };
}

/**
 * Try to parse the given string as JSON and return the result. If the string is
 * not a valid JSON string, return the original string. Return `null` for an
 * empty string.
 */
function parseSettingValue(value: string): unknown {
  if (value === '') return null;

  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
}
