import LinkIcon from '@mui/icons-material/Link';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useTranslation } from 'react-i18next';

import { useFormContextPlus } from '../../../../../../form-plus/use-form-context-plus';
import { LabeledInput } from '../../../../../../locked-inputs';
import { Collapse, ControlWrapper } from '../../../../../components';
import { useMaskContext } from '../../../../../hooks/mask-context';
import { DocumentMaskFormValue } from '../../../types';

export function ExternalSource() {
  const { t } = useTranslation();

  const { watch, register } = useFormContextPlus<DocumentMaskFormValue>();

  const filePath = watch('filePath');
  const mask = useMaskContext();

  return (
    <Collapse title={t('COMMON.EXTERNAL_SOURCE')} defaultOpen>
      <ControlWrapper>
        <LabeledInput
          disabled={mask.isEditMode}
          label={t('INPUTS.LINK')}
          {...register('filePath')}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                href={filePath}
                target="_blank"
                edge="end"
                sx={{
                  // LabeledInput overrides a lot of styles for any nested
                  // element, so these workarounds are needed.
                  marginRight: 0,
                  cursor: 'pointer !important',
                }}
              >
                <LinkIcon
                  sx={{
                    fontSize: '1.5rem !important',
                  }}
                />
              </IconButton>
            </InputAdornment>
          }
        />
      </ControlWrapper>
    </Collapse>
  );
}
