import { gql, useMutation } from '@apollo/client';

const UNIGNORE_MAIL = gql`
  mutation UnignoreMail(
    $mailboxId: String!
    $mailId: String!
    $mailFolderId: String!
  ) {
    unignoreMail(
      mailboxId: $mailboxId
      mailId: $mailId
      mailFolderId: $mailFolderId
    )
  }
`;

type UnignoreMailResponse = {
  unignoreMail: boolean;
};

type UnignoreMailVars = {
  mailboxId: string;
  mailId: string;
  mailFolderId: string;
};

export function useUnignoreMailMutation() {
  return useMutation<UnignoreMailResponse, UnignoreMailVars>(UNIGNORE_MAIL);
}
