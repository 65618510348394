import styles from './PurchasePricePopover.module.scss';

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LabeledCurrencyInput } from '@work4all/components/lib/input/labeled-currency-input';

import { CheckboxRadioItem } from '../../../../../../../../../../locked-inputs';
import { LabeledInput } from '../../../../../../../../../../locked-inputs';
import { MultiStepSearch } from '../../../../../../../../../../locked-inputs';
import { ControlWrapper } from '../../../../../../../../../components/control-wrapper/ControlWrapper';

export const PurchasePricePopover: React.FC = (props) => {
  const { t } = useTranslation();
  const { register } = useFormContext();

  return (
    <>
      <ControlWrapper>
        <MultiStepSearch
          inputs={[
            {
              label: t('COMMON.CUSTOMER'),
              required: true,
              ...register('customer', { required: true }),
            },
          ]}
        />
      </ControlWrapper>
      <ControlWrapper>
        <MultiStepSearch
          inputs={[
            {
              label: t('MASK.ARTICLE'),
              required: true,
              ...register('article', { required: true }),
            },
          ]}
        />
      </ControlWrapper>
      <ControlWrapper>
        <LabeledCurrencyInput label={t('MASK.DISCOUNT2')} unit="%" />
        <LabeledCurrencyInput label={t('MASK.PRICE')} unit="€" />
        <MultiStepSearch
          inputs={[
            { label: t('INPUTS.CURRENCY'), ...register('chooseCurrency') },
          ]}
        />
      </ControlWrapper>
      <ControlWrapper>
        <CheckboxRadioItem label={t('INPUTS.DISCOUNTABLE')} />
      </ControlWrapper>
      <ControlWrapper>
        <LabeledInput
          label={t('INPUTS.ITEM_NUMBER')}
          {...register('itemNumber')}
        />
      </ControlWrapper>
      <ControlWrapper>
        <LabeledInput
          className={styles.comment}
          multiline={true}
          label={t('MASK.COMMENT')}
          {...register('comment')}
        />
      </ControlWrapper>
    </>
  );
};
