import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, DialogActions, DialogContent } from '../dialog';
import { BaseActionButton } from '../input/base-action-button/BaseActionButton';
import { LabeledInput } from '../input/labeled-input';

export type TextInputDialogProps = {
  /**
   * The title of the dialog.
   */
  title: string;

  /**
   * Whether the dialog is open or not.
   */
  open: boolean;

  /**
   * The initial value of the input.
   */
  value: string;

  /**
   * The callback function that is called when the user closes the dialog
   * without confirming.
   */
  onClose: () => void;

  /**
   * The callback function that is called when the clicks the confirm button.
   * The value of the input is passed as an argument.
   */
  onConfirm: (value: string) => void;
};

export function TextInputDialog(props: TextInputDialogProps) {
  const { title, open, onClose, onConfirm } = props;

  const { t } = useTranslation();

  const [value, setValue] = useState(props.value);

  useEffect(() => {
    if (open) {
      setValue(props.value);
    }
  }, [open, props.value]);

  const handleConfirm = () => {
    onConfirm(value);
  };

  return (
    <Dialog title={title} open={open} onClose={onClose}>
      <DialogContent>
        <LabeledInput
          multiline={true}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <BaseActionButton
          title={t('INPUTS.SAVE')}
          component="body2"
          color="primary"
          onClick={handleConfirm}
        />
      </DialogActions>
    </Dialog>
  );
}
