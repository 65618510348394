import type { Modifier } from '@popperjs/core/lib';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const popperModifiers: Partial<Modifier<any, any>>[] = [
  {
    name: 'offset',
    options: {
      offset: ({ placement, reference, popper }) => {
        if (placement === 'top' || placement === 'bottom') {
          return [0, -reference.height];
        } else {
          return [];
        }
      },
    },
  },
];
