import styles from './TakeRestHeight.module.scss';

import clsx from 'clsx';
import React from 'react';

export const TakeRestHeight: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = (props) => {
  return (
    <div {...props} className={clsx(styles.take, props.className)}>
      {props.children}
    </div>
  );
};
