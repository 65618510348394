import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { StatisticsSalesVolumeByItemRow } from './StatisticsSalesVolumeByItemRow.entity';

export class StatisticsSalesVolumeByItemColumn<T extends EMode = EMode.entity> {
  /** Alias: rangeStart */
  rangeStart?: string;
  /** Alias: rangeEnd */
  rangeEnd?: string;
  /** Alias: name */
  name?: string;
  /** Alias: rowCount */
  rowCount?: number;
  /** Alias: rows */
  rows?: StatisticsSalesVolumeByItemRow<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<StatisticsSalesVolumeByItemColumn> = {
  rangeStart: {
    alias: 'rangeStart',
  },
  rangeEnd: {
    alias: 'rangeEnd',
  },
  name: {
    alias: 'name',
  },
  rowCount: {
    alias: 'rowCount',
  },
  rows: {
    alias: 'rows',
    entity: Entities.statisticsSalesVolumeByItemRow,
  },
  __typename: {
    alias: '__typename',
  },
};

export const statisticsSalesVolumeByItemColumnEntityDefinition: EntitiyDefinition<StatisticsSalesVolumeByItemColumn> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'StatisticsSalesVolumeByItemColumn',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
