import styles from './AddressData.module.scss';

import { RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { useCities as getCities } from '@work4all/components/lib/components/entity-picker/city-picker/CityPicker';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { SalutationPicker } from '@work4all/components/lib/components/entity-picker/salutation-picker/SalutationPicker';

import { CityPickerField } from '../../../../../../../../../../components/entity-picker/CityPickerField';
import { CountryPickerField } from '../../../../../../../../../../components/entity-picker/CountryPickerField';
import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import {
  CheckboxRadioItem,
  DateTimeInputPicker,
  LabeledInput,
} from '../../../../../../../../locked-inputs';
import { ControlWrapper } from '../../../../../../../components/control-wrapper';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { BusinessPartners } from '../../../../../BusinessPartnerOverlayController';
import { BPMaskFormValue } from '../../../../../types';

export const AddressData: React.FC = () => {
  const { t } = useTranslation();

  const { register, watch, setValue, getValues, control } = useFormContextPlus<
    BPMaskFormValue & { nameEqualsStandard?: boolean }
  >();

  const mask = useMaskContext<
    BusinessPartners & { nameEqualsStandard?: boolean }
  >();

  const data = getValues();
  const currentFirmString = [data.firm1, data.firm2, data.firm3]
    .filter((el) => ![undefined, ''].includes(el))
    .join('\n');

  const isPrivateCustomer = watch('isPrivateCustomer');

  const [angloSaxon, setAnglosaxon] = useState(false);

  const registerFirm1 = register('firm1');

  const contact = getValues('contactList');
  const city = watch('city');
  const postOfficeBoxLocation = watch('postOfficeBoxLocation');

  const [currentPostalCode, setCurrentPostalCode] = useState<
    string | undefined
  >();

  const cityResponse = getCities(
    {
      cityFilter: undefined,
      queryPage: 0,
      querySize: 100,
      postalCodeFilter: currentPostalCode
        ? currentPostalCode.toString()
        : undefined,
    },
    !currentPostalCode || currentPostalCode.trim().length === 0
  );

  useEffect(() => {
    const res =
      cityResponse.data?.getCities?.data.length > 0
        ? cityResponse.data?.getCities?.data?.[0]
        : undefined;

    if ((!city || city.length === 0) && res) {
      setValue('city', res);
    }
  }, [cityResponse, city, setValue]);

  const [currentPostOfficeBoxPostalCode, setCurrentPostOfficeBoxPostalCode] =
    useState<string | undefined>(getValues().postOfficeBoxPostalCode);

  const cityPostOfficeBoxResponse = getCities({
    cityFilter: undefined,
    queryPage: 0,
    querySize: 100,
    postalCodeFilter: currentPostOfficeBoxPostalCode
      ? currentPostOfficeBoxPostalCode.toString()
      : undefined,
  });

  useEffect(() => {
    const res =
      cityPostOfficeBoxResponse.data?.getCities?.data?.length === 1
        ? cityPostOfficeBoxResponse.data?.getCities?.data?.[0]
        : undefined;

    if ((!postOfficeBoxLocation || postOfficeBoxLocation.length === 0) && res) {
      setValue('postOfficeBoxLocation', res);
    }
  }, [cityPostOfficeBoxResponse, postOfficeBoxLocation, setValue]);

  if (!mask.data) return <div></div>;

  return (
    <>
      <Collapse defaultOpen={true} title={t('MASK.ADDRESS_DATA')}>
        <div className={styles.wrapper}>
          <div className={styles.left}>
            <ControllerPlus
              control={control}
              name="isPrivateCustomer"
              render={({ field }) => {
                const val = field.value ? 'private' : 'company';

                return (
                  <ControlWrapper
                    as={RadioGroup}
                    grid={false}
                    asProps={{
                      ...field,
                      value: val,
                      onChange: (e) => {
                        field.onChange(e.target.value === 'private');
                      },
                    }}
                  >
                    <CheckboxRadioItem
                      className={styles.inlineRadio}
                      label={t('MASK.COMPANY_ADDRESS')}
                      value="company"
                      control={'radio'}
                      horizontalPadding={false}
                    />
                    <CheckboxRadioItem
                      className={styles.inlineRadio}
                      horizontalPadding={false}
                      label={t('COMMON.PRIVATE_ADDRESS')}
                      value="private"
                      control={'radio'}
                      disabled={contact.length > 0 ? true : false}
                    />
                  </ControlWrapper>
                );
              }}
            />

            <ControlWrapper>
              {!isPrivateCustomer && (
                <ControlWrapper grid={false} columns={0} paddingBottom={false}>
                  <LabeledInput
                    defaultValue={currentFirmString}
                    style={{ height: '7rem' }}
                    label={t('INPUTS.FIRM')}
                    multiline={true}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    error={(registerFirm1 as any).error}
                    required={registerFirm1.required}
                    onChange={(e) => {
                      const val = e.currentTarget.value;
                      const splits = val.split('\n');
                      const firm1 = splits[0] || '';
                      const firm2 = splits[1] || '';
                      const firm3 = splits.slice(2).join('\n') || '';
                      setValue('firm1', firm1, { shouldDirty: true });
                      setValue('firm2', firm2, { shouldDirty: true });
                      setValue('firm3', firm3, { shouldDirty: true });
                    }}
                  />
                </ControlWrapper>
              )}
              {isPrivateCustomer && (
                <ControlWrapper columns={0} paddingBottom={false}>
                  <LabeledInput
                    {...register('firm2')}
                    label={t('INPUTS.NAME')}
                    onChange={(e) => {
                      const val = e.currentTarget.value;
                      const firm2 = val;
                      setValue('firm2', firm2, { shouldDirty: true });
                    }}
                  />
                  <LabeledInput
                    {...register('firm1')}
                    label={t('INPUTS.LAST_NAME')}
                    onChange={(e) => {
                      const val = e.currentTarget.value;
                      const firm1 = val;
                      setValue('firm1', firm1, { shouldDirty: true });
                    }}
                  />
                </ControlWrapper>
              )}
              <ControlWrapper columns={0} paddingBottom={false}>
                <div>
                  <LabeledInput
                    label={t('INPUTS.STREET_HOUSE_NUMBER')}
                    style={angloSaxon ? { height: '7rem' } : null}
                    {...register('street')}
                  />
                </div>
                {!angloSaxon && (
                  <ControllerPlus
                    name="postalCode"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <LabeledInput
                          label={t('INPUTS.POSTAL_CODE')}
                          {...field}
                          error={fieldState.error?.message}
                          onBlur={(e) => setCurrentPostalCode(e.target.value)}
                        />
                      );
                    }}
                  />
                )}
              </ControlWrapper>
              <ControlWrapper
                columns={angloSaxon ? 2 : 1}
                paddingBottom={false}
              >
                <ControllerPlus
                  name="country"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <CountryPickerField
                        {...field}
                        error={fieldState.error?.message}
                        value={field.value ? { shortName: field.value } : null}
                        onChange={(result) => {
                          setAnglosaxon(result?.anglosaxon);
                          field.onChange(result?.shortName || '');
                        }}
                      />
                    );
                  }}
                />
                {angloSaxon && (
                  <ControllerPlus
                    name="postalCode"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <LabeledInput
                          label={t('INPUTS.POSTAL_CODE')}
                          {...field}
                          error={fieldState.error?.message}
                          onBlur={(e) => setCurrentPostalCode(e.target.value)}
                        />
                      );
                    }}
                  />
                )}
                <div className={angloSaxon ? styles.gridControl : null}>
                  <ControllerPlus
                    name="city"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <CityPickerField
                          {...field}
                          postalCodeFilter={currentPostalCode}
                          value={field.value}
                          onChange={(value) => field.onChange(value)}
                          error={fieldState.error?.message}
                          canWriteValue
                        />
                      );
                    }}
                  />
                </div>
              </ControlWrapper>
            </ControlWrapper>
            {isPrivateCustomer && (
              <ControlWrapper paddingBottom={false}>
                <ControlWrapper>
                  <ControllerPlus
                    name="salutation"
                    control={control}
                    render={({ field: salutation }) => {
                      return (
                        <EntityPickerPopover
                          picker={
                            <SalutationPicker
                              multiple={false}
                              value={salutation?.value}
                              onChange={(value) => {
                                salutation.onChange(value);
                              }}
                            />
                          }
                        >
                          <LabeledInput
                            onClear={null}
                            value={`${
                              salutation?.value?.name
                                ? salutation?.value?.name
                                : ''
                            }${
                              salutation?.value?.standardletterSalutation
                                ? ' / ' +
                                  salutation?.value?.standardletterSalutation
                                : ''
                            }`}
                            label={t('INPUTS.SALUTATION')}
                          />
                        </EntityPickerPopover>
                      );
                    }}
                  />
                </ControlWrapper>
                <ControlWrapper>
                  <LabeledInput
                    label={t('INPUTS.TITLE_EXTENSION')}
                    {...register('titleExtension')}
                  ></LabeledInput>
                </ControlWrapper>
                <DateTimeInputPicker
                  withTime={false}
                  required={false}
                  dateLabel={t('INPUTS.BIRTH_DATE')}
                  {...register('birthdayDate')}
                />
              </ControlWrapper>
            )}
            {isPrivateCustomer && (
              <ControlWrapper>
                <LabeledInput
                  label={t('INPUTS.LETTER_SALUTATION')}
                  {...register('letterSalutation')}
                ></LabeledInput>
              </ControlWrapper>
            )}
            <ControlWrapper>
              <LabeledInput
                label={t('COMMON.SHORT_NAME')}
                {...register('name')}
              ></LabeledInput>
            </ControlWrapper>
          </div>
        </div>
      </Collapse>
      <Collapse defaultOpen={false} title={t('INPUTS.POST_OFFICE_BOX')}>
        <div className={styles.wrapper}>
          <div className={styles.left}>
            <ControlWrapper>
              <LabeledInput
                label={t('INPUTS.POST_OFFICE_BOX')}
                {...register('postOfficeBox')}
              />

              <ControllerPlus
                name="postOfficeBoxPostalCode"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <LabeledInput
                      label={t('INPUTS.POST_CODE_OF_POST_OFFICE_BOX')}
                      {...field}
                      error={fieldState.error?.message}
                      onBlur={(e) =>
                        setCurrentPostOfficeBoxPostalCode(e.target.value)
                      }
                    />
                  );
                }}
              />

              <ControllerPlus
                name="postOfficeBoxLocation"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <LabeledInput
                      {...field}
                      label={t('INPUTS.POST_OFFICE_BOX_LOCATION')}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      error={fieldState.error?.message}
                    />
                  );
                }}
              />
            </ControlWrapper>
          </div>
        </div>
      </Collapse>
    </>
  );
};
