import { ParsedCustomFieldConfig } from '../types';

export const prepareCustomFieldsDefinitions = (
  customFields: ParsedCustomFieldConfig[]
) => {
  if (!customFields) return {};

  const customFieldsDefinitions = {};
  customFields.forEach((field) => {
    const fieldName = `Feld${field.fieldNumber}`;

    customFieldsDefinitions[fieldName] = {
      alias: fieldName,
    };
  });

  return customFieldsDefinitions;
};
