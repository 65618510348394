import styles from './SearchHistoryListItem.module.scss';

import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { WebsiteFavicon } from '@work4all/components';
import { Body2 } from '@work4all/components/lib/typography/body2/Body2';
import { Caption } from '@work4all/components/lib/typography/caption/Caption';

import { IListItemProps } from '@work4all/models/lib/Project';

export const SearchHistoryListItem: React.FC<
  IListItemProps & { website: string }
> = (props) => {
  const number: number = props.number
    ? typeof props.number === 'string'
      ? parseInt(props.number, 10)
      : props.number
    : undefined;

  return (
    <ListItem
      classes={{
        root: clsx(
          styles['list-item__item'],
          props.noPadding && styles['list-item__item--no-padding'],
          props.className
        ),
      }}
      divider={props.divider}
    >
      {props?.website && (
        <ListItemIcon>
          <WebsiteFavicon website={props?.website} />
        </ListItemIcon>
      )}
      <ListItemText
        classes={{
          root: styles['list-item__text'],
          primary: styles['list-item__title-wrapper'],
          secondary: styles['list-item__meta-wrapper'],
        }}
        primary={
          <Body2 className={styles['list-item__title']}>{props.name}</Body2>
        }
        secondary={
          <>
            <Caption className={styles['list-item__id']}>
              {number && number !== 0 ? number : ''}
            </Caption>
            <Caption className={styles['list-item__category']}>
              {props.category}
            </Caption>
          </>
        }
      />
    </ListItem>
  );
};
