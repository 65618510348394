export enum LookupType {
  XX = 'XX',
  PROJEKT_STATI = 'PROJEKT_STATI',
  FUNKTIONEN_AP = 'FUNKTIONEN_AP',
  BEMERKUNGEN = 'BEMERKUNGEN',
  TITELBEIM_SCANNEN = 'TITELBEIM_SCANNEN',
  ABTEILUNGEN = 'ABTEILUNGEN',
  MINI_PROJEKT_STATI = 'MINI_PROJEKT_STATI',
  EINGANGSRECHNUNGS_STATUS = 'EINGANGSRECHNUNGS_STATUS',
  PROJEKT_TYPE = 'PROJEKT_TYPE',
  PROJEKTKATEGORIE_1 = 'PROJEKTKATEGORIE_1',
  PROJEKTKATEGORIE_2 = 'PROJEKTKATEGORIE_2',
  PROJEKTKATEGORIE_3 = 'PROJEKTKATEGORIE_3',
  RESSOURCENKLASSEN = 'RESSOURCENKLASSEN',
  KOSTENGRUPPEN = 'KOSTENGRUPPEN',
  ADRESSVERKNUEPFUNG = 'ADRESSVERKNUEPFUNG',
  AUFTRAGS_SCHLAGWORTE = 'AUFTRAGS_SCHLAGWORTE',
  WARTUNGSOBJEKT_ARTEN = 'WARTUNGSOBJEKT_ARTEN',
  BEZIEHUNGEN = 'BEZIEHUNGEN',
  KONTAKTBERICHT_KLASSEN = 'KONTAKTBERICHT_KLASSEN',
  KONTAKTBERICHT_TOPS = 'KONTAKTBERICHT_TOPS',
  DOKUMENT_UND_BRIEF_THEMEN = 'DOKUMENT_UND_BRIEF_THEMEN',
  E_MAIL_GRUPPEN = 'E_MAIL_GRUPPEN',
  KONTAKTBERICHT_VEREINBARUNGEN = 'KONTAKTBERICHT_VEREINBARUNGEN',
  WARENGRUPPEN = 'WARENGRUPPEN',
  KAMPAGNEN_STATUS = 'KAMPAGNEN_STATUS',
  KAMPAGNEN_ERGEBNIS = 'KAMPAGNEN_ERGEBNIS',
  NEUKUNDEN_STATUS = 'NEUKUNDEN_STATUS',
  FUNKTIONEN = 'FUNKTIONEN',
  DOKUMENT_ARTEN_QM = 'DOKUMENT_ARTEN_QM',
  PRUEFAUFTRAGSARTEN = 'PRUEFAUFTRAGSARTEN',
  KATEGORIEN_STATUS = 'KATEGORIEN_STATUS',
  PROJEKTVERKNUEPFUNGSARTEN = 'PROJEKTVERKNUEPFUNGSARTEN',
  PRODUKTIONSAUFTRAGSARTEN = 'PRODUKTIONSAUFTRAGSARTEN',
  ARTIKELRELATION = 'ARTIKELRELATION',
  PROJEKT_KOSTENSATZ_PAKET = 'PROJEKT_KOSTENSATZ_PAKET',
  BENUTZER_ZEITSTEMPEL_LOOKUP_ART = 'BENUTZER_ZEITSTEMPEL_LOOKUP_ART',
  FREIGABE_STATUS_CHARGEN = 'FREIGABE_STATUS_CHARGEN',
  URLAUBS_ARTEN = 'URLAUBS_ARTEN',
  KRANKHEITS_ARTEN = 'KRANKHEITS_ARTEN',
  PRIORITAETEN_PRODUKTIONSAUFTRAG = 'PRIORITAETEN_PRODUKTIONSAUFTRAG',
  VERZEICHNIS_TEMPLATE_TITEL = 'VERZEICHNIS_TEMPLATE_TITEL',
  TICKET_CHECKLISTENPUNKT = 'TICKET_CHECKLISTENPUNKT',
  TICKET_KATEGORIE_2 = 'TICKET_KATEGORIE_2',
  FORE_COLOR_POSITIONEN = 'FORE_COLOR_POSITIONEN',
  BILDER_ARTIKEL = 'BILDER_ARTIKEL',
  ZEITERFASSUNG_ART = 'ZEITERFASSUNG_ART',
  NIEDERLASSUNGEN = 'NIEDERLASSUNGEN',
  REISEKOSTENABRECHNUNG_REISEZWECK = 'REISEKOSTENABRECHNUNG_REISEZWECK',
  ESKALATIONSSTUFEN = 'ESKALATIONSSTUFEN',
  REPORTGRUPPEN = 'REPORTGRUPPEN',
  BILDER_GESCHAEFTSPARTNER = 'BILDER_GESCHAEFTSPARTNER',
  KOMMT_GEHT_KONTEN = 'KOMMT_GEHT_KONTEN',
  TICKET_RELATIONEN = 'TICKET_RELATIONEN',
  TICKET_ART = 'TICKET_ART',
  TICKET_KATEGORIE_1 = 'TICKET_KATEGORIE_1',
  MEHRWERT_STEUER_SAETZE = 'MEHRWERT_STEUER_SAETZE',
}
