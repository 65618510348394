import styles from './BackButton.module.scss';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { useNavigate } from '@work4all/data/lib/hooks/routing';

import { useCanGoBack } from '../../hooks/use-can-go-back';

export interface BackButtonProps {
  href: string;
}

export function BackButton(props: BackButtonProps) {
  const { href } = props;

  const navigate = useNavigate();
  const canGoBack = useCanGoBack();

  return (
    <IconButton
      className={styles.backButton}
      size="medium"
      onClick={() => {
        if (canGoBack) {
          navigate(-1);
        } else {
          navigate(href);
        }
      }}
    >
      {canGoBack ? <ArrowBackIcon /> : <CloseIcon />}
    </IconButton>
  );
}
