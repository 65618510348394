import { Undo } from '@mui/icons-material';
import { Box, Divider } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseActionButton } from '@work4all/components/lib/input/base-action-button/BaseActionButton';

import { HomeWidgetsContext } from '../../../../containers/home/components/home-widgets/HomeWidgetsContextProvider';

export function HomeScreen() {
  const homeWidgets = useContext(HomeWidgetsContext);
  const { t } = useTranslation();

  return (
    <Box sx={{ padding: '1rem' }}>
      <Divider>{t('COMMON.HOME_WIDGETS')}</Divider>
      <BaseActionButton
        icon={<Undo />}
        onClick={() => homeWidgets.resetWidgets()}
      >
        {t('HOME_WIDGETS.RESET')}
      </BaseActionButton>
    </Box>
  );
}
