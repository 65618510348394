import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { ArticleCalculationLogic } from '../Enums/ArticleCalculationLogic.enum';
import { ArticleKind } from '../Enums/ArticleKind.enum';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { ArticleGroup } from './ArticleGroup.entity';
import { ArticleImage } from './ArticleImage.entity';
import { ArticleImageUnion } from './ArticleImageUnion.entity';
import { ArticlePrices } from './ArticlePrices.entity';
import { ArticleText } from './ArticleText.entity';
import { BulkPrice } from './BulkPrice.entity';
import { CustomField } from './CustomField.entity';
import { File } from './File.entity';
import { SlModeSetting } from './SlModeSetting.entity';
import { Supplier } from './Supplier.entity';
import { Unit } from './Unit.entity';
import { VatRate } from './VatRate.entity';

export class Article<T extends EMode = EMode.entity> {
  /** Alias: artikelArt */
  articleKind?: ArticleKind;
  /** Alias: artikelPreise */
  articlePrices?: ArticlePrices<T>[];
  /** Alias: aufwand */
  expenditure?: number;
  /** Alias: besitzerSdObjMemberCode */
  ownerSdObjMemberId?: number;
  /** Alias: besitzerSdObjType */
  ownerSdObjType?: SdObjType;
  /** Alias: bewertung */
  valuation?: number;
  /** Alias: bezugskosten */
  purchaseCosts?: number;
  /** Alias: boxArtikel */
  isBoxArticle?: boolean;
  /** Alias: boxFuellgrad */
  boxFillingLevel?: number;
  /** Alias: breite */
  width?: number;
  /** Alias: chargenverwaltung */
  isCharge?: boolean;
  /** Alias: code */
  id?: number;
  /** Alias: creationDate */
  creationDate?: string;
  /** Alias: dienstleistung */
  isService?: boolean;
  /** Alias: disposition */
  isDisposition?: boolean;
  /** Alias: ean */
  ean?: string;
  /** Alias: einheitCode */
  unitId?: number;
  /** Alias: einstandspreis */
  entryPrice?: number;
  /** Alias: euroNettopreis */
  netPrice?: number;
  /** Alias: euroNettopreis2 */
  netPrice2?: number;
  /** Alias: euroNettopreis3 */
  netPrice3?: number;
  /** Alias: excelDatei */
  excelFile?: string;
  /** Alias: faktor */
  factor?: number;
  /** Alias: festpreis1 */
  fixedPrice1?: boolean;
  /** Alias: festpreis2 */
  fixedPrice2?: boolean;
  /** Alias: festpreis3 */
  fixedPrice3?: boolean;
  /** Alias: fremdleistung */
  isOutsideService?: boolean;
  /** Alias: garantieNachReparatur */
  hasWarrantyAfterrepair?: boolean;
  /** Alias: gewicht */
  weight?: number;
  /** Alias: grCode */
  groupId?: number;
  /** Alias: herstellerCode */
  manufacturerId?: number;
  /** Alias: herstellernummer */
  manufacturerNumber?: string;
  /** Alias: hoehe */
  height?: number;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: internerText */
  internalText?: string;
  /** Alias: kalkulationslogik */
  calculationLogicId?: ArticleCalculationLogic;
  /** Alias: kalkulationslogikFaktor */
  calculationLogicFactor?: number;
  /** Alias: kartonFaktor */
  cartonFaktor?: number;
  /** Alias: keineProvision */
  hasNoCommission?: boolean;
  /** Alias: kostenStCode */
  costCenterId?: number;
  /** Alias: kostenStCode2 */
  costCenter2Id?: number;
  /** Alias: kostenStNummer2 */
  costCenter3Id?: number;
  /** Alias: kostenkonto */
  costAccount?: string;
  /** Alias: kostenkonto2 */
  costAccount2?: number;
  /** Alias: kostenkontoEu */
  costAccountEu?: string;
  /** Alias: kostenkontoNonEu */
  costAccountNonEu?: number;
  /** Alias: laenge */
  length?: number;
  /** Alias: lagerwertFifo */
  stockValueFifo?: number;
  /** Alias: langtext */
  longtext?: string;
  /** Alias: meldebestand */
  reportingStock?: number;
  /** Alias: mietartikel */
  isRentalArticle?: boolean;
  /** Alias: mindestbestand */
  minimumStock?: number;
  /** Alias: minutenpreis1 */
  minutePrice1?: number;
  /** Alias: minutenpreis2 */
  minutePrice2?: number;
  /** Alias: minutenpreis3 */
  minutePrice3?: number;
  /** Alias: mittlererEk */
  averagePurchasePrice?: number;
  /** Alias: mwstCode */
  vatId?: number;
  /** Alias: name */
  name?: string;
  /** Alias: nichtAutomatischBestellen */
  noAutomaticPurchase?: boolean;
  /** Alias: nichtLagerArtikel */
  isNoStockArticle?: boolean;
  /** Alias: nichtSkontofaehig */
  noSkonto?: boolean;
  /** Alias: nummer */
  number?: string;
  /** Alias: nurEinkauf */
  purchaseOnly?: number;
  /** Alias: palettenFaktor */
  paletteFactor?: number;
  /** Alias: preisstaffelCode */
  bulkPriceTierId?: number;
  /** Alias: produktionsartikel */
  isProductionArticle?: boolean;
  /** Alias: rabattGrCode */
  discountGroupId?: number;
  /** Alias: sachkNummer */
  ledgerAccountNumber?: number;
  /** Alias: sachkNummerEu */
  ledgerAccountNumberEu?: number;
  /** Alias: sachkNummerNonEu */
  ledgerAccountNumberNonEu?: number;
  /** Alias: seriennummerBeiWarenausgang */
  serialNumberOutgoing?: boolean;
  /** Alias: seriennummerBeiWareneingang */
  serialNumberInbound?: boolean;
  /** Alias: seriennummernlogik */
  serialNumberLogic?: string;
  /** Alias: shopBestellungohneBestand */
  shopOrderWithoutStock?: boolean;
  /** Alias: sollBestand */
  targetStock?: number;
  /** Alias: staffelRabatte */
  hasBulkPriceDiscounts?: boolean;
  /** Alias: staffelpreis */
  hasBulkPrices?: boolean;
  /** Alias: staffelpreisZuschlag */
  bulkPricesSurcharge?: number;
  /** Alias: standardBildCode */
  standardImageId?: number;
  /** Alias: standardEkAlsEinstand */
  standardPurchasePriceAsEntryPrice?: number;
  /** Alias: standardLagerortCode */
  standardStorageId?: number;
  /** Alias: standardLieferantCode */
  standardSupplierId?: number;
  /** Alias: stillgelegt */
  isShutDown?: boolean;
  /** Alias: taetigkeit */
  isActivity?: boolean;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: veFaktor */
  veFactor?: number;
  /** Alias: wartungsanteil */
  maintenanceShare?: number;
  /** Alias: wartungsartikelFuer */
  maintenanceArticleFor?: string;
  /** Alias: webshopAusblenden */
  hideInShop?: boolean;
  /** Alias: zolltarifnummer */
  customsTariffNumber?: string;
  /** Alias: zuschlag1 */
  surcharge1?: number;
  /** Alias: zuschlag2 */
  surcharge2?: number;
  /** Alias: zuschlag3 */
  surcharge3?: number;
  /** Alias: zuschlag4 */
  surcharge4?: number;
  /** Alias: artikelGruppe */
  articleGroup?: ArticleGroup<T>;
  /** Alias: herkunftsland */
  landOfOrigin?: string;
  /** Alias: hersteller */
  manufacturer?: Supplier<T>;
  /** Alias: einheit */
  unit?: Unit<T>;
  /** Alias: mehrwertsteuerSatz */
  vatRate?: VatRate<T>;
  /** Alias: artikelTexte */
  articleTextList?: ArticleText<T>[];
  /** Alias: standardLieferant */
  defaultSupplier?: Supplier<T>;
  /** Alias: nichtAbrechenbar */
  notdeductable?: boolean;
  /** Alias: rabattfaehig */
  discountable?: boolean;
  /** Alias: staffelpreise */
  bulkPriceList?: BulkPrice<T>[];
  /** Alias: customFields */
  customFieldList?: CustomField<T>[];
  /** Alias: slModes */
  slModes?: SlModeSetting<T>[];
  /** Alias: artikelDokumente */
  articleDocumentList?: T extends EMode.query
    ? ArticleImageUnion<T>
    : File<T>[] | ArticleImage<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<Article> = {
  articleKind: {
    alias: 'artikelArt',
  },
  articlePrices: {
    alias: 'artikelPreise',
    entity: Entities.articlePrices,
  },
  expenditure: {
    alias: 'aufwand',
  },
  ownerSdObjMemberId: {
    alias: 'besitzerSdObjMemberCode',
  },
  ownerSdObjType: {
    alias: 'besitzerSdObjType',
  },
  valuation: {
    alias: 'bewertung',
  },
  purchaseCosts: {
    alias: 'bezugskosten',
  },
  isBoxArticle: {
    alias: 'boxArtikel',
  },
  boxFillingLevel: {
    alias: 'boxFuellgrad',
  },
  width: {
    alias: 'breite',
  },
  isCharge: {
    alias: 'chargenverwaltung',
  },
  id: {
    alias: 'code',
  },
  creationDate: {
    alias: 'creationDate',
  },
  isService: {
    alias: 'dienstleistung',
  },
  isDisposition: {
    alias: 'disposition',
  },
  ean: {
    alias: 'ean',
  },
  unitId: {
    alias: 'einheitCode',
  },
  entryPrice: {
    alias: 'einstandspreis',
  },
  netPrice: {
    alias: 'euroNettopreis',
  },
  netPrice2: {
    alias: 'euroNettopreis2',
  },
  netPrice3: {
    alias: 'euroNettopreis3',
  },
  excelFile: {
    alias: 'excelDatei',
  },
  factor: {
    alias: 'faktor',
  },
  fixedPrice1: {
    alias: 'festpreis1',
  },
  fixedPrice2: {
    alias: 'festpreis2',
  },
  fixedPrice3: {
    alias: 'festpreis3',
  },
  isOutsideService: {
    alias: 'fremdleistung',
  },
  hasWarrantyAfterrepair: {
    alias: 'garantieNachReparatur',
  },
  weight: {
    alias: 'gewicht',
  },
  groupId: {
    alias: 'grCode',
  },
  manufacturerId: {
    alias: 'herstellerCode',
  },
  manufacturerNumber: {
    alias: 'herstellernummer',
  },
  height: {
    alias: 'hoehe',
  },
  insertTime: {
    alias: 'insertTime',
  },
  internalText: {
    alias: 'internerText',
  },
  calculationLogicId: {
    alias: 'kalkulationslogik',
  },
  calculationLogicFactor: {
    alias: 'kalkulationslogikFaktor',
  },
  cartonFaktor: {
    alias: 'kartonFaktor',
  },
  hasNoCommission: {
    alias: 'keineProvision',
  },
  costCenterId: {
    alias: 'kostenStCode',
  },
  costCenter2Id: {
    alias: 'kostenStCode2',
  },
  costCenter3Id: {
    alias: 'kostenStNummer2',
  },
  costAccount: {
    alias: 'kostenkonto',
  },
  costAccount2: {
    alias: 'kostenkonto2',
  },
  costAccountEu: {
    alias: 'kostenkontoEu',
  },
  costAccountNonEu: {
    alias: 'kostenkontoNonEu',
  },
  length: {
    alias: 'laenge',
  },
  stockValueFifo: {
    alias: 'lagerwertFifo',
  },
  longtext: {
    alias: 'langtext',
  },
  reportingStock: {
    alias: 'meldebestand',
  },
  isRentalArticle: {
    alias: 'mietartikel',
  },
  minimumStock: {
    alias: 'mindestbestand',
  },
  minutePrice1: {
    alias: 'minutenpreis1',
  },
  minutePrice2: {
    alias: 'minutenpreis2',
  },
  minutePrice3: {
    alias: 'minutenpreis3',
  },
  averagePurchasePrice: {
    alias: 'mittlererEk',
  },
  vatId: {
    alias: 'mwstCode',
  },
  name: {
    alias: 'name',
  },
  noAutomaticPurchase: {
    alias: 'nichtAutomatischBestellen',
  },
  isNoStockArticle: {
    alias: 'nichtLagerArtikel',
  },
  noSkonto: {
    alias: 'nichtSkontofaehig',
  },
  number: {
    alias: 'nummer',
  },
  purchaseOnly: {
    alias: 'nurEinkauf',
  },
  paletteFactor: {
    alias: 'palettenFaktor',
  },
  bulkPriceTierId: {
    alias: 'preisstaffelCode',
  },
  isProductionArticle: {
    alias: 'produktionsartikel',
  },
  discountGroupId: {
    alias: 'rabattGrCode',
  },
  ledgerAccountNumber: {
    alias: 'sachkNummer',
  },
  ledgerAccountNumberEu: {
    alias: 'sachkNummerEu',
  },
  ledgerAccountNumberNonEu: {
    alias: 'sachkNummerNonEu',
  },
  serialNumberOutgoing: {
    alias: 'seriennummerBeiWarenausgang',
  },
  serialNumberInbound: {
    alias: 'seriennummerBeiWareneingang',
  },
  serialNumberLogic: {
    alias: 'seriennummernlogik',
  },
  shopOrderWithoutStock: {
    alias: 'shopBestellungohneBestand',
  },
  targetStock: {
    alias: 'sollBestand',
  },
  hasBulkPriceDiscounts: {
    alias: 'staffelRabatte',
  },
  hasBulkPrices: {
    alias: 'staffelpreis',
  },
  bulkPricesSurcharge: {
    alias: 'staffelpreisZuschlag',
  },
  standardImageId: {
    alias: 'standardBildCode',
  },
  standardPurchasePriceAsEntryPrice: {
    alias: 'standardEkAlsEinstand',
  },
  standardStorageId: {
    alias: 'standardLagerortCode',
  },
  standardSupplierId: {
    alias: 'standardLieferantCode',
  },
  isShutDown: {
    alias: 'stillgelegt',
  },
  isActivity: {
    alias: 'taetigkeit',
  },
  updateTime: {
    alias: 'updateTime',
  },
  veFactor: {
    alias: 'veFaktor',
  },
  maintenanceShare: {
    alias: 'wartungsanteil',
  },
  maintenanceArticleFor: {
    alias: 'wartungsartikelFuer',
  },
  hideInShop: {
    alias: 'webshopAusblenden',
  },
  customsTariffNumber: {
    alias: 'zolltarifnummer',
  },
  surcharge1: {
    alias: 'zuschlag1',
  },
  surcharge2: {
    alias: 'zuschlag2',
  },
  surcharge3: {
    alias: 'zuschlag3',
  },
  surcharge4: {
    alias: 'zuschlag4',
  },
  articleGroup: {
    alias: 'artikelGruppe',
    entity: Entities.articleGroup,
  },
  landOfOrigin: {
    alias: 'herkunftsland',
  },
  manufacturer: {
    alias: 'hersteller',
    entity: Entities.supplier,
  },
  unit: {
    alias: 'einheit',
    entity: Entities.unit,
  },
  vatRate: {
    alias: 'mehrwertsteuerSatz',
    entity: Entities.vatRate,
  },
  articleTextList: {
    alias: 'artikelTexte',
    entity: Entities.articleText,
  },
  defaultSupplier: {
    alias: 'standardLieferant',
    entity: Entities.supplier,
  },
  notdeductable: {
    alias: 'nichtAbrechenbar',
  },
  discountable: {
    alias: 'rabattfaehig',
  },
  bulkPriceList: {
    alias: 'staffelpreise',
    entity: Entities.bulkPrice,
  },
  customFieldList: {
    alias: 'customFields',
    entity: Entities.customField,
  },
  slModes: {
    alias: 'slModes',
    entity: Entities.slModeSetting,
  },
  articleDocumentList: {
    alias: 'artikelDokumente',
    entity: [Entities.file, Entities.articleImage],
  },
  __typename: {
    alias: '__typename',
  },
};

export const articleEntityDefinition: EntitiyDefinition<Article> = {
  local: {},
  remote: {
    queryName: 'getArtikel',
    fragmentName: 'Artikel',
    withPaginationWrapper: true,
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
