import { useCallback } from 'react';

import { MailAssignStatus } from '@work4all/models/lib/Enums/MailAssignStatus.enum';

import { MailboxContentState } from './types';

export interface MailboxContentStatuses {
  canAssign: (id: string) => boolean;
  canAssignToTicket: (id: string) => boolean;
  canIgnore: (id: string) => boolean;
  canReset: (id: string) => boolean;
  canAssignSelected: () => boolean;
  canAssignSelectedToTicket: () => boolean;
  canIgnoreSelected: () => boolean;
  canResetSelected: () => boolean;
  canDeleteSelected: () => boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseMailboxContentStatusesOptions extends MailboxContentState {}

export function useMailboxContentStatuses(
  options: UseMailboxContentStatusesOptions
): MailboxContentStatuses {
  const { itemsById, selectedIds } = options;

  const canAssign = useCallback<MailboxContentStatuses['canAssign']>(
    (id) => {
      const item = itemsById[id];

      return (
        item != null &&
        item.data.assignStatus === MailAssignStatus.NOT_YET_ASSIGNED &&
        item.contact != null
      );
    },
    [itemsById]
  );

  const canAssignToTicket = useCallback<
    MailboxContentStatuses['canAssignToTicket']
  >(
    (id) => {
      const item = itemsById[id];

      return (
        item != null &&
        item.data.assignStatus === MailAssignStatus.NOT_YET_ASSIGNED &&
        item.contact !== null
      );
    },
    [itemsById]
  );

  const canIgnore = useCallback<MailboxContentStatuses['canIgnore']>(
    (id) => {
      const item = itemsById[id];

      return (
        item != null &&
        item.data.assignStatus === MailAssignStatus.NOT_YET_ASSIGNED
      );
    },
    [itemsById]
  );

  const canReset = useCallback<MailboxContentStatuses['canReset']>(
    (id) => {
      const item = itemsById[id];

      return (
        item != null && item.data.assignStatus === MailAssignStatus.ITEM_IGNORED
      );
    },
    [itemsById]
  );

  const canAssignSelected = useCallback<
    MailboxContentStatuses['canAssignSelected']
  >(() => {
    return selectedIds.length > 0 && selectedIds.every((id) => canAssign(id));
  }, [canAssign, selectedIds]);

  const canAssignSelectedToTicket = useCallback<
    MailboxContentStatuses['canAssignSelectedToTicket']
  >(() => {
    return selectedIds.length === 1 && canAssignToTicket(selectedIds[0]);
  }, [canAssignToTicket, selectedIds]);

  const canIgnoreSelected = useCallback<
    MailboxContentStatuses['canIgnoreSelected']
  >(() => {
    return selectedIds.length > 0 && selectedIds.every((id) => canIgnore(id));
  }, [canIgnore, selectedIds]);

  const canResetSelected = useCallback<
    MailboxContentStatuses['canResetSelected']
  >(() => {
    return selectedIds.length > 0 && selectedIds.every((id) => canReset(id));
  }, [canReset, selectedIds]);

  const canDeleteSelected = useCallback<
    MailboxContentStatuses['canDeleteSelected']
  >(() => {
    return selectedIds.length > 0;
  }, [selectedIds]);

  return {
    canAssign,
    canAssignToTicket,
    canIgnore,
    canReset,
    canAssignSelected,
    canAssignSelectedToTicket,
    canIgnoreSelected,
    canResetSelected,
    canDeleteSelected,
  };
}
