import { useMemo } from 'react';

export const remToPx = (rem: number | string) => {
  let r = rem;
  if (typeof r === 'string') {
    r = parseFloat(r);
  }

  return Math.trunc(parseFloat(getComputedStyle(document.body).fontSize) * r);
};

export const useRemToPx = (rem: number | string) => {
  const px = useMemo(() => {
    return remToPx(rem);
  }, [rem]);
  return px;
};
