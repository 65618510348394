import { createContext, Provider, useContext } from 'react';

import { ReplicationStateContextValue } from './types';

const Context = createContext<ReplicationStateContextValue | null>(null);

export const ReplicationStateContextProvider =
  Context.Provider as Provider<ReplicationStateContextValue>;

export function useReplicationStateContext(): ReplicationStateContextValue {
  const ctx = useContext(Context);

  if (ctx === null) {
    throw new Error(
      'useReplicationStateContext must be used within a <ReplicationStateContextProvider>'
    );
  }

  return ctx;
}
