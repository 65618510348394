import { listItemSecondaryActionClasses } from '@mui/material/ListItemSecondaryAction';

const secondaryActionStyles = {
  left: `16px !important`,
  right: `unset !important`,
};

const listItemStyles = {
  [`.${listItemSecondaryActionClasses.root}`]: secondaryActionStyles,
};

const listItemButtonStyles = {
  paddingLeft: '48px !important',
  paddingRight: '8px !important',
  padding: '0.25rem 0.5rem 0.25rem 3.25rem',
};

export const listStyleOverrides = {
  listItem: listItemStyles,
  listItemButton: listItemButtonStyles,
};
