import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

export class InputAddressConnectionRelationAdd<T extends EMode = EMode.entity> {
  /** Alias: connectionId */
  connectionId?: number;
  /** Alias: businessPartnerId */
  businessPartnerId?: number;
  /** Alias: contactId */
  contactId?: number;
  /** Alias: businessPartnerType */
  businessPartnerType?: SdObjType;
  /** Alias: reverseConnectionId */
  reverseConnectionId?: number;

  __typename?: string;
}

const fields: FieldDefinitions<InputAddressConnectionRelationAdd> = {
  connectionId: {
    alias: 'connectionId',
  },
  businessPartnerId: {
    alias: 'businessPartnerId',
  },
  contactId: {
    alias: 'contactId',
  },
  businessPartnerType: {
    alias: 'businessPartnerType',
  },
  reverseConnectionId: {
    alias: 'reverseConnectionId',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputAddressConnectionRelationAddEntityDefinition: EntitiyDefinition<InputAddressConnectionRelationAdd> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputAddressConnectionRelationAdd',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
