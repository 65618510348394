import styles from './styles.module.scss';

import { Link } from '@mui/material';
import { FC, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { InlineData } from './InlineData';

type IAddressCardProps = {
  street: string;
  postalCode: string;
  city: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export const AddressCard: FC<IAddressCardProps> = ({
  street,
  postalCode,
  city,
  onClick,
}) => {
  const { t } = useTranslation();

  const createClassicAddress = () => {
    if (!street && !postalCode && !city) {
      return '';
    }
    let address = street;
    if (address && address.length && (postalCode || city)) {
      address = address + ',';
    }
    address = `${address} ${postalCode} ${city}`;

    return address;
  };

  const createAddressHRef = () =>
    `http://maps.google.com/maps?q=${createClassicAddress()}`;

  const address = createClassicAddress();
  return (
    <InlineData
      onClick={onClick}
      label={t('FILE_CONTACTS.ADDRESS')}
      content={
        address ? (
          <Link
            target="_blank"
            rel="noreferrer"
            underline="none"
            className={styles['link']}
            href={street && postalCode && city ? createAddressHRef() : '#'}
          >
            {createClassicAddress()}
          </Link>
        ) : (
          '-'
        )
      }
    />
  );
};
