import { TFunction } from 'i18next';

import { Currency } from '@work4all/models/lib/Classes/Currency.entity';

/**
 * Formats a currency value to a string. The value is formatted using the i18n
 * translation resources. If a given currency has a custom symbol configured, it will be
 * returned. Otherwise, the currency name will be returned.
 *
 * @param currency The currency to format.
 * @param t The translation function from i18next.
 * @returns The currency symbol or, if it's not found, the currency name.
 */
export function formatCurrency(currency: Currency, t: TFunction): string {
  if (!currency) return '';
  return t(`CURRENCY.SYMBOLS.${currency.name}`, {
    defaultValue: currency.name,
  });
}
