import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Contact } from './Contact.entity';
import { Customer } from './Customer.entity';
import { ProjectDistributor } from './ProjectDistributor.entity';
import { Supplier } from './Supplier.entity';
import { User } from './User.entity';

export class ProjectDistributorEntry<T extends EMode = EMode.entity> {
  /** Alias: _ProvisionProzent */
  commissionPercent?: number;
  /** Alias: ansprechpCode */
  contactId?: number;
  /** Alias: benutzer */
  user?: User<T>;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: code */
  id?: number;
  /** Alias: datum */
  date?: string;
  /** Alias: jahr */
  jahr?: number;
  /** Alias: kunde */
  customer?: Customer<T>;
  /** Alias: kundenAnsprechpartner */
  customerContact?: Contact<T>;
  /** Alias: lieferant */
  supplier?: Supplier<T>;
  /** Alias: lieferantenAnsprechpartner */
  supplierContact?: Contact<T>;
  /** Alias: lookupCode */
  lookupId?: number;
  /** Alias: mM */
  mM?: number;
  /** Alias: mT */
  mT?: number;
  /** Alias: notiz */
  note?: string;
  /** Alias: nummer */
  number?: string;
  /** Alias: objCode */
  objId?: number;
  /** Alias: objType */
  objType?: number;
  /** Alias: projektCode */
  projectId?: number;
  /** Alias: projektVerteiler */
  projectDistributorList?: ProjectDistributor<T>[];
  /** Alias: projektePlanungCode */
  projectProcessId?: number;
  /** Alias: schluessel */
  key?: number;
  /** Alias: verteilerCode */
  projectDistributorId?: number;

  __typename?: string;
}

const fields: FieldDefinitions<ProjectDistributorEntry> = {
  commissionPercent: {
    alias: '_ProvisionProzent',
  },
  contactId: {
    alias: 'ansprechpCode',
  },
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  userId: {
    alias: 'benutzerCode',
  },
  id: {
    alias: 'code',
  },
  date: {
    alias: 'datum',
  },
  jahr: {
    alias: 'jahr',
  },
  customer: {
    alias: 'kunde',
    entity: Entities.customer,
  },
  customerContact: {
    alias: 'kundenAnsprechpartner',
    entity: Entities.contact,
  },
  supplier: {
    alias: 'lieferant',
    entity: Entities.supplier,
  },
  supplierContact: {
    alias: 'lieferantenAnsprechpartner',
    entity: Entities.contact,
  },
  lookupId: {
    alias: 'lookupCode',
  },
  mM: {
    alias: 'mM',
  },
  mT: {
    alias: 'mT',
  },
  note: {
    alias: 'notiz',
  },
  number: {
    alias: 'nummer',
  },
  objId: {
    alias: 'objCode',
  },
  objType: {
    alias: 'objType',
  },
  projectId: {
    alias: 'projektCode',
  },
  projectDistributorList: {
    alias: 'projektVerteiler',
    entity: Entities.projectDistributor,
  },
  projectProcessId: {
    alias: 'projektePlanungCode',
  },
  key: {
    alias: 'schluessel',
  },
  projectDistributorId: {
    alias: 'verteilerCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const projectDistributorEntryEntityDefinition: EntitiyDefinition<ProjectDistributorEntry> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'ProjektVerteilerEintrag',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
