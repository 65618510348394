import styles from './Heading3.module.scss';

import { Typography } from '@mui/material';
import clsx from 'clsx';
import { forwardRef, PropsWithChildren } from 'react';

import { ITypographyProps } from '..';

export const Heading3 = forwardRef<
  HTMLElement,
  PropsWithChildren<ITypographyProps>
>(function Heading3(props, ref) {
  return (
    <Typography
      ref={ref}
      onClick={props.onClick}
      className={clsx(styles.heading3, props.className)}
      variant="h3"
    >
      {props.children}
    </Typography>
  );
});
