import React from 'react';

import { ClosedStatusPicker } from '../../../../components/entity-picker/ClosedStatusPicker/ClosedStatusPicker';
import { FilterCard } from '../filter-card/FilterCard';

export type ClosedStatusFilterCardProps = {
  title: string;
  value: boolean;
  onChange: (value: boolean) => void;
  expanded: boolean;
  trueText: string;
  falseText: string;
  onExpandedChange: (val: boolean) => void;
};

export const ClosedStatusFilterCard: React.FC<ClosedStatusFilterCardProps> = (
  props
) => {
  const {
    title,
    value,
    onChange,
    expanded,
    onExpandedChange,
    trueText,
    falseText,
  } = props;

  const filterValueText = value ? trueText : falseText;

  return (
    <FilterCard
      title={title}
      filterValue={filterValueText}
      filterValueSet={!!filterValueText}
      expanded={expanded}
      onExpandedChange={onExpandedChange}
      onValueDelete={value ? () => onChange(false) : undefined}
    >
      <ClosedStatusPicker
        value={value}
        onChange={onChange}
        trueText={trueText}
        falseText={falseText}
      />
    </FilterCard>
  );
};
