import { useCallback, useMemo, useState } from 'react';

import { DeleteDialog } from '../components/DeleteDialog';
import { DialogConfig, Handlers } from '../interfaces';
import { UserResponse } from '../types';

export const useDeleteDialog = () => {
  const [dialogConfig, setDialogConfig] = useState<DialogConfig>();
  const [handlers, setHandlers] = useState<Handlers>();

  const userResponse = useCallback(async () => {
    return new Promise((resolve) => {
      const onDelete = () => {
        resolve('delete');
      };

      const onClose = () => {
        resolve('close');
      };

      setHandlers({
        onClose,
        onDelete,
      });
    });
  }, []);

  const showDeleteDialog = useCallback(
    async (props: DialogConfig) => {
      setDialogConfig(props);
      const response = (await userResponse()) as UserResponse;
      setHandlers(undefined);
      setDialogConfig(undefined);

      return response;
    },
    [userResponse]
  );

  return useMemo(() => {
    return {
      showDeleteDialog,
      DeleteDialog: () => (
        <DeleteDialog dialogConfig={dialogConfig} handlers={handlers} />
      ),
    };
  }, [dialogConfig, handlers, showDeleteDialog]);
};
