import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { MailAssignStatus } from '../Enums/MailAssignStatus.enum';
import { ObjectType } from '../Enums/ObjectType.enum';

import { Contact } from './Contact.entity';
import { ContactUnion } from './ContactUnion.entity';
import { Customer } from './Customer.entity';
import { EMail } from './EMail.entity';
import { Project } from './Project.entity';
import { Supplier } from './Supplier.entity';
import { User } from './User.entity';

export class MailboxContent<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: title */
  title?: string;
  /** Alias: body */
  body?: string;
  /** Alias: date */
  date?: string;
  /** Alias: from */
  from?: string;
  /** Alias: to */
  to?: string;
  /** Alias: cc */
  cc?: string;
  /** Alias: convertedEMailCode */
  convertedEMailCode?: string;
  /** Alias: convertedEMailTenant */
  convertedEMailTenant?: number;
  /** Alias: convertedMail */
  convertedMail?: EMail<T>;
  /** Alias: possibleSender */
  possibleSender?: T extends EMode.query
    ? ContactUnion<T>
    : User<T> | Customer<T> | Supplier<T> | Contact<T>;
  /** Alias: assignStatus */
  assignStatus?: MailAssignStatus;
  /** Alias: possibleProjectCode */
  possibleProjectCode?: number;
  /** Alias: possibleProject */
  possibleProject?: Project<T>;
  /** Alias: isRead */
  isRead?: boolean;
  /** Alias: possibleSenders */
  possibleSenders?: T extends EMode.query
    ? ContactUnion<T>
    : User<T>[] | Customer<T>[] | Supplier<T>[] | Contact<T>[];
  /** Alias: possibleProjectCodes */
  possibleProjectCodes?: number[];
  /** Alias: possibleProjects */
  possibleProjects?: Project<T>[];
  /** Alias: convertedObjectTenant */
  convertedObjectTenant?: number;
  /** Alias: convertedObjectKey */
  convertedObjectKey?: string;
  /** Alias: convertedObjectType */
  convertedObjectType?: ObjectType;
  /** Alias: renderableBody */
  renderableBody?: string;

  __typename?: string;
}

const fields: FieldDefinitions<MailboxContent> = {
  id: {
    alias: 'id',
  },
  title: {
    alias: 'title',
  },
  body: {
    alias: 'body',
  },
  date: {
    alias: 'date',
  },
  from: {
    alias: 'from',
  },
  to: {
    alias: 'to',
  },
  cc: {
    alias: 'cc',
  },
  convertedEMailCode: {
    alias: 'convertedEMailCode',
  },
  convertedEMailTenant: {
    alias: 'convertedEMailTenant',
  },
  convertedMail: {
    alias: 'convertedMail',
    entity: Entities.eMail,
  },
  possibleSender: {
    alias: 'possibleSender',
    entity: [
      Entities.user,
      Entities.customer,
      Entities.supplier,
      Entities.contact,
    ],
  },
  assignStatus: {
    alias: 'assignStatus',
  },
  possibleProjectCode: {
    alias: 'possibleProjectCode',
  },
  possibleProject: {
    alias: 'possibleProject',
    entity: Entities.project,
  },
  isRead: {
    alias: 'isRead',
  },
  possibleSenders: {
    alias: 'possibleSenders',
    entity: [
      Entities.user,
      Entities.customer,
      Entities.supplier,
      Entities.contact,
    ],
  },
  possibleProjectCodes: {
    alias: 'possibleProjectCodes',
  },
  possibleProjects: {
    alias: 'possibleProjects',
    entity: Entities.project,
  },
  convertedObjectTenant: {
    alias: 'convertedObjectTenant',
  },
  convertedObjectKey: {
    alias: 'convertedObjectKey',
  },
  convertedObjectType: {
    alias: 'convertedObjectType',
  },
  renderableBody: {
    alias: 'renderableBody',
  },
  __typename: {
    alias: '__typename',
  },
};

export const mailboxContentEntityDefinition: EntitiyDefinition<MailboxContent> =
  {
    local: {},
    remote: {
      queryName: 'readMailboxContent',
      fragmentName: 'MailboxContent',
      withPaginationWrapper: true,
      mutations: {
        upsert: {
          mutationName: 'assignMail',
          args: [
            { name: 'mailboxId', type: 'String!' },
            { name: 'mailId', type: 'String!' },
            { name: 'mailFolderId', type: 'String!' },
            { name: 'sdObjMemberCode', type: 'Int' },
            { name: 'sdObjType', type: 'SdObjType' },
            { name: 'apCode', type: 'Int' },
            { name: 'projektCode', type: 'Int' },
          ],
        },
      },
      params: [
        { name: 'mailboxId', type: 'String!' },
        { name: 'folderId', type: 'String!' },
        { name: 'filterStatus', type: '[MailAssignStatus]' },
        { name: 'filterSender', type: '[String]' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'querySize', type: 'Int' },
        { name: 'queryPage', type: 'Int' },
      ],
    },
    fieldDefinitions: fields,
  };
