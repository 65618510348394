import styles from './NavigationItem.module.scss';

import { Button, Divider } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { Link } from '@work4all/data';

export interface INavigationItemProps {
  title: string;
  active?: boolean;
  divider?: boolean;
  href: string;
  onClick?: React.MouseEventHandler;
  onContextMenu?: React.MouseEventHandler;
}

export const NavigationItem: React.FC<INavigationItemProps> = (props) => {
  const { title, active, divider, href, onClick, onContextMenu } = props;

  return (
    <div className={styles['navigation-item']}>
      <Button
        component={Link}
        to={href}
        className={clsx(
          styles['navigation-item__item'],
          active && styles['navigation-item__item--active']
        )}
        onClick={onClick}
        onContextMenu={onContextMenu}
      >
        <span className={styles['navigation-item__text-wrapper']}>{title}</span>
      </Button>
      {divider && (
        <Divider
          className={styles['navigation-item__divider']}
          orientation="vertical"
        />
      )}
    </div>
  );
};
