import { CellProps } from 'react-table';

import { ChronoFileItem } from '@work4all/models/lib/Classes/ChronoFileItem.entity';
import { Contact } from '@work4all/models/lib/Classes/Contact.entity';

export const ContactCell = (props: CellProps<ChronoFileItem>) => {
  const fileItem = props.cell.row.original.fileItem;

  const contact = getContact(fileItem);
  if (contact) {
    return <div>{`${contact.displayName}`}</div>;
  }

  return null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getContact(fileItem: any): Contact {
  return fileItem?.contact;
}
