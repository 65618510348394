import PaymentIcon from '@mui/icons-material/Payment';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Base } from '../base/Base';
import { BaseActionProps } from '../types';

export const OpenPaymentMask: React.FC<BaseActionProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Base
      title={t('INPUTS.OPEN_PAYMENT_MASK')}
      icon={<PaymentIcon />}
      {...props}
    />
  );
};
