import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useEntityJsonSchema } from '@work4all/data/lib/json-schema/EntityJsonSchemasContext';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useJSONSchemaResolver } from '@work4all/utils';

import {
  MaskOverlayHeader,
  MaskOverlayStandardSubmitButton,
} from '../../../components';
import {
  MaskContextProvider,
  useMaskConfig,
} from '../../../hooks/mask-context';
import { InboundInvoiceFormValue } from '../../inbound-invoice/types';
import { usePaymentMaskData } from '../hooks';
import { PaymentMaskProps } from '../interfaces';

import { GeneralSection } from './GeneralSection';
import { TableSection } from './TableSection';

export const PaymentMask = ({ id, entity }: PaymentMaskProps) => {
  const { t } = useTranslation();
  const mask = useMaskConfig({ entity, id });
  const data = usePaymentMaskData({ id, entity });

  const schema = useEntityJsonSchema(Entities.inboundInvoice);
  const resolver = useJSONSchemaResolver(schema);
  const form = useForm<InboundInvoiceFormValue>({
    resolver,
    mode: 'onChange',
    defaultValues: data,
  });

  const { reset } = form;

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  return (
    <FormProvider {...form}>
      <MaskContextProvider value={mask}>
        <MaskOverlayHeader
          title={t('INPUTS.OPEN_PAYMENT_MASK')}
          subTitle={`${id} ${data?.note ? `| ${data.note}` : ''}`}
          actions={<MaskOverlayStandardSubmitButton />}
        />
        <GeneralSection />
        <TableSection />
      </MaskContextProvider>
    </FormProvider>
  );
};
