import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useMemo } from 'react';

import { getMaxBreakpoint } from '@work4all/utils/lib/getMaxBreakpoint';
import { Breakpoint } from '@work4all/utils/lib/variables';

const ALL_BREAKPOINTS: readonly Breakpoint[] = ['xs', 'sm', 'md', 'lg', 'xl'];

export interface UseCurrentBreakpointProps {
  /**
   * List of breakpoints to match. Only breakpoints present in this list will be
   * checked.
   *
   * You can use this if you don't care about all the breakpoints and only want
   * to check, for example, is the app is running on a mobile, a tablet or a
   * desktop device.
   *
   * @default ["xs", "sm", "md", "lg", "xl"]
   */
  breakpoints?: Breakpoint[];
}

/**
 * Returns the largest matched CSS breakpoint.
 */
export const useCurrentBreakpoint = (
  props?: UseCurrentBreakpointProps
): Breakpoint => {
  const includeBreakpoints = new Set(props?.breakpoints ?? ALL_BREAKPOINTS);

  const includeXs = includeBreakpoints.has('xs');
  const includeSm = includeBreakpoints.has('sm');
  const includeMd = includeBreakpoints.has('md');
  const includeLg = includeBreakpoints.has('lg');
  const includeXl = includeBreakpoints.has('xl');

  const xs = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xs'));
  const sm = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));
  const md = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));
  const lg = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'));
  const xl = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'));

  return useMemo(() => {
    const allBreakpoints = [
      includeXs && ['xs', xs],
      includeSm && ['sm', sm],
      includeMd && ['md', md],
      includeLg && ['lg', lg],
      includeXl && ['xl', xl],
    ].filter(Boolean);

    const activeBreakpoints = allBreakpoints.reduce((acc, current) => {
      const active = current[1];
      if (!active) {
        return acc;
      }

      const b = current[0] as Breakpoint;
      acc.push(b);

      return acc;
    }, []) as Breakpoint[];

    return getMaxBreakpoint(...activeBreakpoints);
  }, [
    lg,
    md,
    sm,
    xl,
    xs,
    includeXs,
    includeSm,
    includeMd,
    includeLg,
    includeXl,
  ]);
};
