import styles from './InlineCaption.module.scss';

import React from 'react';

interface IInlineCaptionProps {
  caption: string;
  className?: string;
}

export const InlineCaption: React.FC<IInlineCaptionProps> = (props) => {
  return (
    <span className={styles.inlineCaption + ' ' + props.className}>
      {props.caption}
    </span>
  );
};
