import styles from './General.module.scss';

import { useTranslation } from 'react-i18next';

import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import {
  DateTimeInputPicker,
  LabeledInput,
} from '../../../../../../../../locked-inputs';
import { Collapse, ControlWrapper } from '../../../../../../../components';
import { InboundInvoiceFormValue } from '../../../../../types';

export function General() {
  const { t } = useTranslation();

  const form = useFormContextPlus<InboundInvoiceFormValue>();
  const { register } = form;

  return (
    <Collapse className={styles.general} title={t('MASK.GENERAL')} defaultOpen>
      <ControlWrapper columns={3}>
        <DateTimeInputPicker
          dateLabel={t('INPUTS.RECEIVED_DATE')}
          withTime={false}
          clearable={false}
          {...register('receivedDate')}
        />

        <DateTimeInputPicker
          dateLabel={t('INPUTS.DATE_OF_INVOICE')}
          withTime={false}
          clearable={false}
          {...register('date')}
        />

        <DateTimeInputPicker
          dateLabel={t('INPUTS.BOOKING_DATE')}
          withTime={false}
          clearable={false}
          {...register('bookingDate')}
        />

        <LabeledInput
          label={t('INPUTS.DOCUMENT_NUMBER_INTERNAL')}
          {...register('rNumber')}
          type="number"
        />

        <LabeledInput
          label={t('INPUTS.BILL_NUMBER')}
          {...register('rNumberAtSupplier')}
        />

        <LabeledInput
          label={t('INPUTS.REFERENCE')}
          {...register('reference')}
        />
      </ControlWrapper>

      <LabeledInput
        label={t('INPUTS.NOTE')}
        {...register('note')}
        multiline={true}
      />
    </Collapse>
  );
}
