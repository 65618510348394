import styles from './EditableFileName.module.scss';

import { useState } from 'react';

import { parseNameAndExtension } from '@work4all/data/lib/utils';

type EditableFileNameProps = {
  value: string;
  onChange: (value: string) => void;
};

export function EditableFileName(props: EditableFileNameProps) {
  const { value, onChange } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleFocus = () => {
    const { name } = parseNameAndExtension(value);
    setInputValue(name || '');
    setIsEditing(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const emitChange = () => {
    const { extension } = parseNameAndExtension(value);
    const newValue = inputValue + (extension ? `.${extension}` : '');
    setIsEditing(false);
    onChange(newValue);
  };

  const handleBlur = () => {
    setIsEditing(false);
    emitChange();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.currentTarget.blur();
    }
  };

  return (
    <input
      className={styles.input}
      value={isEditing ? inputValue : value}
      title={value}
      onFocus={handleFocus}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
    />
  );
}
