import React, { createContext, useContext, useMemo } from 'react';
import { JsonSchema } from 'rxdb';
import { useRxQuery } from 'rxdb-hooks';

import { EntitySchema } from '@work4all/models/lib/Classes/EntitySchema.entity';
import { DbEntities } from '@work4all/models/lib/DB';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { EntityByObjectType } from '@work4all/models/lib/GraphQLEntities';

import { useTenantRxCollection } from '../data-retriever/hooks/useTenantRxCollection';

import { translateJsonSchema } from './translate-json-schema';

export type IEntityJsonSchemas = Partial<Record<Entities, JsonSchema>>;

const EntityJsonSchemasContext = createContext<IEntityJsonSchemas>(null);

export type IEntityJsonSchemasProviderProps = {
  children: React.ReactNode;
};

export function EntityJsonSchemasProvider({
  children,
}: IEntityJsonSchemasProviderProps) {
  const collection = useTenantRxCollection<EntitySchema>(DbEntities.JsonSchema);

  const query = useMemo(() => collection?.find(), [collection]);

  const { result: jsonSchemas } = useRxQuery<EntitySchema>(query, {
    json: true,
  });

  const mappedJsonSchemas: Partial<Record<Entities, JsonSchema>> =
    useMemo(() => {
      const missingDefinitions: string[] = [];
      const translationErrors: string[] = [];

      const mappedJsonSchemas = jsonSchemas
        .map((schema) => {
          const { objectType, jsonSchema } = schema;

          try {
            let entity = EntityByObjectType[objectType] as Entities;
            if (
              ['KUNDENANSPRECHPARTNER', 'LIEFERANTENANSPRECHPARTNER'].includes(
                objectType
              )
            ) {
              entity = Entities.contact;
            }
            if (!entity) {
              missingDefinitions.push(objectType);
              return null;
            }

            const parsedJsonSchema = JSON.parse(jsonSchema);
            if (
              ['KUNDENANSPRECHPARTNER', 'LIEFERANTENANSPRECHPARTNER'].includes(
                objectType
              )
            ) {
              delete parsedJsonSchema.properties.KundenCode;
              delete parsedJsonSchema.properties.LieferantenCode;
            }
            const translatedJsonSchema = translateJsonSchema(
              entity,
              parsedJsonSchema
            );

            return [entity, translatedJsonSchema] as const;
          } catch (error: unknown) {
            if (error instanceof Error) {
              translationErrors.push(error.message);
            } else {
              translationErrors.push('<Unknown error>');
            }

            return null;
          }
        })
        .filter(Boolean)
        .reduce<Partial<Record<Entities, JsonSchema>>>(
          (result, [entity, schema]) => {
            result[entity] = schema;
            return result;
          },
          {}
        );

      if (missingDefinitions.length > 0 || translationErrors.length > 0) {
        const errorMessageParts = [
          'Errors occured while trying to translate json schemas.',
        ];

        if (missingDefinitions.length > 0) {
          errorMessageParts.push(
            `\nCould not find definitions for ${
              missingDefinitions.length
            } entities: ${missingDefinitions.join(', ')}.`
          );
        }

        if (translationErrors.length > 0) {
          errorMessageParts.push(
            `\nOther errors:\n` + translationErrors.join('\n')
          );
        }

        console.warn(errorMessageParts.join('\n'));
      }

      return mappedJsonSchemas;
    }, [jsonSchemas]);

  return (
    <EntityJsonSchemasContext.Provider value={mappedJsonSchemas}>
      {children}
    </EntityJsonSchemasContext.Provider>
  );
}

export function useEntityJsonSchemas(): Partial<Record<Entities, JsonSchema>> {
  const schemas = useContext(EntityJsonSchemasContext);

  if (!schemas) {
    throw new Error(
      'useEntityJsonSchema must be used inside <EntityJsonSchemasProvider />'
    );
  }

  return schemas;
}

export function useEntityJsonSchema(entity: Entities): JsonSchema | null {
  const schemas = useEntityJsonSchemas();
  return schemas[entity] ?? null;
}
