import React, { useEffect, useRef } from 'react';

/**
 * Returns a RefObject initialized with the provided value
 * and updates it after every render.
 *
 * This is mostly useful to get access to the latest value
 * of some props or state inside an asynchronous callback,
 * instead of that value at the time the callback was created.
 */
export function useLatest<T>(value: T): React.RefObject<T> {
  const latest = useRef(value);

  useEffect(() => {
    latest.current = value;
  });

  return latest;
}
