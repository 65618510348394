import { Tooltip } from '@mui/material';
import { useMemo, useState } from 'react';

import {
  ListEntityPicker,
  ListEntityPickerWithoutTabsProps,
} from '@work4all/components/lib/components/entity-picker/components/list-entity-picker';

import { IResponse, useDataProvider } from '@work4all/data';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { DataRequest, SortDirection } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ContactOrBusinessPartner } from './contact-or-business-partner';
import { contactAsString, ContactText } from './ContactText';

export interface ContactOrBusinessPartnerPickerProps
  extends Pick<
    ListEntityPickerWithoutTabsProps<ContactOrBusinessPartner, false>,
    'value' | 'onChange' | 'onResize'
  > {
  suggestions?: ContactOrBusinessPartner[];
}

export function ContactOrBusinessPartnerPicker(
  props: ContactOrBusinessPartnerPickerProps
) {
  const { suggestions, ...otherProps } = props;

  const [_search, setSearch] = useState('');
  const search = _search.trim();

  const suggestionsDataSet = useMemo(() => {
    if (!suggestions || suggestions.length < 1) return null;

    const response: IResponse<ContactOrBusinessPartner> = {
      data: suggestions,
      total: suggestions.length,
      loading: false,
      pending: false,
      fetchMore: () => Promise.resolve(),
      refetch: () => Promise.resolve(null),
    };

    return response;
  }, [suggestions]);

  const requests = {
    customer: useMemo(() => {
      return makeCustomerRequest({ search });
    }, [search]),
    supplier: useMemo(() => {
      return makeSupplierRequest({ search });
    }, [search]),
    contact: useMemo(() => {
      return makeContactRequest({ search });
    }, [search]),
  };

  const queries = {
    customer: useDataProvider<Customer>(requests.customer),
    supplier: useDataProvider<Supplier>(requests.supplier),
    contact: useDataProvider<Contact>(requests.contact),
  };

  const searchedDataSet = useMemo(() => {
    const contacts =
      search.length < 1
        ? []
        : [
            ...queries.customer.data,
            ...queries.supplier.data,
            ...queries.contact.data,
          ];

    const response: IResponse<ContactOrBusinessPartner> = {
      data: sortContacts(contacts),
      total: contacts.length,
      loading:
        queries.customer.loading ||
        queries.supplier.loading ||
        queries.contact.loading,
      pending:
        queries.customer.pending ||
        queries.supplier.pending ||
        queries.contact.pending,
      fetchMore: () => Promise.resolve(),
      refetch: () => Promise.resolve(null),
    };

    return response;
  }, [
    search,
    queries.contact.data,
    queries.contact.loading,
    queries.contact.pending,
    queries.customer.data,
    queries.customer.loading,
    queries.customer.pending,
    queries.supplier.data,
    queries.supplier.loading,
    queries.supplier.pending,
  ]);

  const dataSet =
    search.length < 1 && suggestionsDataSet != null
      ? suggestionsDataSet
      : searchedDataSet;

  return (
    <ListEntityPicker
      multiple={false}
      entity={null}
      data={null}
      filterBy={null}
      sortBy={null}
      suppressFilter
      onSearchValueChange={setSearch}
      fixedDataSet={dataSet}
      renderItemContent={(contact) => (
        <Tooltip title={contactAsString(contact)}>
          <ContactText contact={contact} />
        </Tooltip>
      )}
      layout="advanced"
      useSearchHistory={false}
      {...otherProps}
    />
  );
}

const CUSTOMER_DATA: Customer<EMode.query> = {
  id: null,
  name: null,
};

function makeCustomerRequest({ search }: { search: string }): DataRequest {
  return {
    entity: Entities.customer,
    data: CUSTOMER_DATA,
    filter: [{ name: { $eq: `%${search}%` } }],
    sort: [{ field: 'name', direction: SortDirection.ASCENDING }],
    skip: search.length < 1,
  };
}

const SUPPLIER_DATA: Supplier<EMode.query> = CUSTOMER_DATA;

function makeSupplierRequest({ search }: { search: string }): DataRequest {
  return {
    entity: Entities.supplier,
    data: SUPPLIER_DATA,
    filter: [{ name: { $eq: `%${search}%` } }],
    sort: [{ field: 'name', direction: SortDirection.ASCENDING }],
    skip: search.length < 1,
  };
}

const CONTACT_DATA: Contact<EMode.query> = {
  id: null,
  displayName: null,
  businessPartner: {
    id: null,
    data: {
      customer: CUSTOMER_DATA,
      supplier: SUPPLIER_DATA,
    },
  },
};

function makeContactRequest({ search }: { search: string }): DataRequest {
  return {
    entity: Entities.contact,
    data: CONTACT_DATA,
    filter: [
      { layedOff: { $eq: false } },
      { displayName: { $eq: `%${search}%` } },
    ],
    sort: [{ field: 'displayName', direction: SortDirection.ASCENDING }],
    skip: search.length < 1,
  };
}

function sortContacts(
  contacts: ContactOrBusinessPartner[]
): ContactOrBusinessPartner[] {
  return contacts.slice().sort((a, b) => {
    return contactAsString(a).localeCompare(contactAsString(b));
  });
}
