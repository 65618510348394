import { useMemo } from 'react';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useCustomFieldsConfigExtended } from '../use-custom-fields-config';

import {
  CustomFieldsConfigContextProvider,
  CustomFieldsConfigContextValue,
} from './custom-fields-config-context';

export interface CustomFieldsConfigProviderProps {
  entity: Entities;
  children?: React.ReactNode;
}

export function CustomFieldsConfigProvider(
  props: CustomFieldsConfigProviderProps
) {
  const { entity, children } = props;

  const { loading, customFields } = useCustomFieldsConfigExtended({ entity });

  const ctx = useMemo<CustomFieldsConfigContextValue>(
    () => ({ customFields }),
    [customFields]
  );

  // The table can't correctly initialize until the custom fields are loaded. So
  // don't render the children until the request is finished.
  if (loading) {
    return null;
  }

  return (
    <CustomFieldsConfigContextProvider value={ctx}>
      {children}
    </CustomFieldsConfigContextProvider>
  );
}
