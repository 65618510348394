export enum UserRights {
  KUNDEN_NEU_AENDERN = 'KUNDEN_NEU_AENDERN',
  KUNDEN_LOESCHEN = 'KUNDEN_LOESCHEN',
  LIEFERANTEN_NEU_AENDERN = 'LIEFERANTEN_NEU_AENDERN',
  LIEFERANTEN_LOESCHEN = 'LIEFERANTEN_LOESCHEN',
  MITARBEITER_NEU_AENDERN = 'MITARBEITER_NEU_AENDERN',
  MITARBEITER_LOESCHEN = 'MITARBEITER_LOESCHEN',
  ADRESSVERKNUEPFUNG_VERWALTEN = 'ADRESSVERKNUEPFUNG_VERWALTEN',
  EXPORT_ASSISTENT_NUTZEN = 'EXPORT_ASSISTENT_NUTZEN',
  IMPORT_ASSISTENT_NUTZEN = 'IMPORT_ASSISTENT_NUTZEN',
  GENERELLE_EINSTELLUNG_VERWALTEN = 'GENERELLE_EINSTELLUNG_VERWALTEN',
  KATEGORIE_VERWALTEN = 'KATEGORIE_VERWALTEN',
  TELEFONWAHLMODUL_NUTZEN = 'TELEFONWAHLMODUL_NUTZEN',
  TEXTBAUSTEINE_VERWALTEN = 'TEXTBAUSTEINE_VERWALTEN',
  KUNDEN_LIEFERANTEN_ZUSAMMENFUEHREN = 'KUNDEN_LIEFERANTEN_ZUSAMMENFUEHREN',
  KORRESPONDENZ_ANLEGEN_AENDERN = 'KORRESPONDENZ_ANLEGEN_AENDERN',
  KORRESPONDENZ_LOESCHEN = 'KORRESPONDENZ_LOESCHEN',
  KORRESPONDENZ_LOESCHEN_EIGENE = 'KORRESPONDENZ_LOESCHEN_EIGENE',
  TELEFONATE_AUFGABEN_NOTIZEN_ANLEGEN_AENDERN = 'TELEFONATE_AUFGABEN_NOTIZEN_ANLEGEN_AENDERN',
  TELEFONATE_AUFGABEN_NOTIZEN_LOESCHEN = 'TELEFONATE_AUFGABEN_NOTIZEN_LOESCHEN',
  TELEFONATE_AUFGABEN_NOTIZEN_AENDERN_LOESCHEN_EIGENE = 'TELEFONATE_AUFGABEN_NOTIZEN_AENDERN_LOESCHEN_EIGENE',
  ANGEBOTE_WORD_ANLEGEN_AENDERN = 'ANGEBOTE_WORD_ANLEGEN_AENDERN',
  ANGEBOTE_WORD_LOESCHEN = 'ANGEBOTE_WORD_LOESCHEN',
  ANGEBOTE_WORD_AENDERN_LOESCHEN_EIGENE = 'ANGEBOTE_WORD_AENDERN_LOESCHEN_EIGENE',
  TERMINE_ANLEGEN_AENDERN = 'TERMINE_ANLEGEN_AENDERN',
  TERMINE_LOESCHEN = 'TERMINE_LOESCHEN',
  TERMINE_AENDERN_LOESCHEN_EIGENE = 'TERMINE_AENDERN_LOESCHEN_EIGENE',
  BESUCHSBERICHTE_ANLEGEN_AENDERN = 'BESUCHSBERICHTE_ANLEGEN_AENDERN',
  BESUCHSBERICHTE_LOESCHEN = 'BESUCHSBERICHTE_LOESCHEN',
  BESUCHSBERICHTE_AENDERN_LOESCHEN_EIGENE = 'BESUCHSBERICHTE_AENDERN_LOESCHEN_EIGENE',
  JOURNAL_NUTZEN = 'JOURNAL_NUTZEN',
  LISTENREPORTS_NUTZEN = 'LISTENREPORTS_NUTZEN',
  SERIEN_EMAIL_ERSTELLEN = 'SERIEN_EMAIL_ERSTELLEN',
  SERIENBRIEF_ERSTELLEN = 'SERIENBRIEF_ERSTELLEN',
  STATISTIK = 'STATISTIK',
  VORLAGEN_ANLEGEN_LOESCHEN = 'VORLAGEN_ANLEGEN_LOESCHEN',
  ZEIGEN_REGISTERKARTE_KUNDEN = 'ZEIGEN_REGISTERKARTE_KUNDEN',
  ZEIGEN_REGISTERKARTE_LIEFERANTEN = 'ZEIGEN_REGISTERKARTE_LIEFERANTEN',
  ZEIGEN_REGISTERKARTE_PROJEKTE = 'ZEIGEN_REGISTERKARTE_PROJEKTE',
  ZEIGEN_REGISTERKARTE_FIRMA = 'ZEIGEN_REGISTERKARTE_FIRMA',
  ZEIGEN_REGISTERKARTE_ERP = 'ZEIGEN_REGISTERKARTE_ERP',
  ZEIGEN_INFOFENSTER_KUNDEN_ANGEBOTE = 'ZEIGEN_INFOFENSTER_KUNDEN_ANGEBOTE',
  ZEIGEN_INFOFENSTER_KUNDEN_AUFGABEN = 'ZEIGEN_INFOFENSTER_KUNDEN_AUFGABEN',
  ZEIGEN_INFOFENSTER_KUNDEN_BESUCHSBERICHTE = 'ZEIGEN_INFOFENSTER_KUNDEN_BESUCHSBERICHTE',
  ZEIGEN_INFOFENSTER_KUNDEN_BRIEFE = 'ZEIGEN_INFOFENSTER_KUNDEN_BRIEFE',
  ZEIGEN_INFOFENSTER_KUNDEN_DOKUMENTE = 'ZEIGEN_INFOFENSTER_KUNDEN_DOKUMENTE',
  ZEIGEN_INFOFENSTER_KUNDEN_EMAIL = 'ZEIGEN_INFOFENSTER_KUNDEN_EMAIL',
  ZEIGEN_INFOFENSTER_KUNDEN_NOTIZEN = 'ZEIGEN_INFOFENSTER_KUNDEN_NOTIZEN',
  ZEIGEN_INFOFENSTER_KUNDEN_TELEFONATE = 'ZEIGEN_INFOFENSTER_KUNDEN_TELEFONATE',
  ZEIGEN_INFOFENSTER_KUNDEN_TERMINE = 'ZEIGEN_INFOFENSTER_KUNDEN_TERMINE',
  ZEIGEN_KUNDEN_MASKE_NOTIZEN = 'ZEIGEN_KUNDEN_MASKE_NOTIZEN',
  ZEIGEN_KUNDEN_MASKE_BILD = 'ZEIGEN_KUNDEN_MASKE_BILD',
  ZEIGEN_KUNDEN_MASKE_KATEGORIE = 'ZEIGEN_KUNDEN_MASKE_KATEGORIE',
  ZEIGEN_LIEFERANTEN_MASKE_NOTIZEN = 'ZEIGEN_LIEFERANTEN_MASKE_NOTIZEN',
  ZEIGEN_LIEFERANTEN_MASKE_BILD = 'ZEIGEN_LIEFERANTEN_MASKE_BILD',
  ZEIGEN_LIEFERANTEN_MASKE_KATEGORIE = 'ZEIGEN_LIEFERANTEN_MASKE_KATEGORIE',
  KUNDEN_ANSPRECHPARTNER_NEU_AENDERN = 'KUNDEN_ANSPRECHPARTNER_NEU_AENDERN',
  KUNDEN_ANSPRECHPARTNER_LOESCHEN = 'KUNDEN_ANSPRECHPARTNER_LOESCHEN',
  LIEFERANTEN_ANSPRECHPARTNER_NEU_AENDERN = 'LIEFERANTEN_ANSPRECHPARTNER_NEU_AENDERN',
  LIEFERANTEN_ANSPRECHPARTNER_LOESCHEN = 'LIEFERANTEN_ANSPRECHPARTNER_LOESCHEN',
  ZEIGEN_KUNDEN_MASKE_VERKNUEPFUNG = 'ZEIGEN_KUNDEN_MASKE_VERKNUEPFUNG',
  ZEIGEN_LIEFERANTEN_MASKE_VERKNUEPFUNG = 'ZEIGEN_LIEFERANTEN_MASKE_VERKNUEPFUNG',
  ZEIGEN_KUNDEN_ANSPRECHPARTNER_MASKE_PRIVATES = 'ZEIGEN_KUNDEN_ANSPRECHPARTNER_MASKE_PRIVATES',
  ZEIGEN_KUNDEN_ANSPRECHPARTNER_MASKE_NOTIZEN = 'ZEIGEN_KUNDEN_ANSPRECHPARTNER_MASKE_NOTIZEN',
  ZEIGEN_KUNDEN_ANSPRECHPARTNER_MASKE_BILD = 'ZEIGEN_KUNDEN_ANSPRECHPARTNER_MASKE_BILD',
  ZEIGEN_KUNDEN_ANSPRECHPARTNER_MASKE_KATEGORIE = 'ZEIGEN_KUNDEN_ANSPRECHPARTNER_MASKE_KATEGORIE',
  ZEIGEN_LIEFERANTEN_ANSPRECHPARTNER_MASKE_PRIVATES = 'ZEIGEN_LIEFERANTEN_ANSPRECHPARTNER_MASKE_PRIVATES',
  ZEIGEN_LIEFERANTEN_ANSPRECHPARTNER_MASKE_NOTIZEN = 'ZEIGEN_LIEFERANTEN_ANSPRECHPARTNER_MASKE_NOTIZEN',
  ZEIGEN_LIEFERANTEN_ANSPRECHPARTNER_MASKE_BILD = 'ZEIGEN_LIEFERANTEN_ANSPRECHPARTNER_MASKE_BILD',
  ZEIGEN_LIEFERANTEN_ANSPRECHPARTNER_MASKE_KATEGORIE = 'ZEIGEN_LIEFERANTEN_ANSPRECHPARTNER_MASKE_KATEGORIE',
  SCANNEN = 'SCANNEN',
  INSIGHT = 'INSIGHT',
  NEUKUNDENKALENDAR = 'NEUKUNDENKALENDAR',
  URLAUBSKALENDER_AUFRUFEN = 'URLAUBSKALENDER_AUFRUFEN',
  URLAUBSKALENDER_EIGENE_DATEN_PFLEGEN = 'URLAUBSKALENDER_EIGENE_DATEN_PFLEGEN',
  URLAUBSKALENDER_EIGENE_FREMDE_DATEN_PFLEGEN = 'URLAUBSKALENDER_EIGENE_FREMDE_DATEN_PFLEGEN',
  VERKAUFSCHANCEN_NUTZEN = 'VERKAUFSCHANCEN_NUTZEN',
  UMSATZANSICHT = 'UMSATZANSICHT',
  ZEIGEN_EXTRAS_MARKETING_TOOLS = 'ZEIGEN_EXTRAS_MARKETING_TOOLS',
  PROJEKTE_NEU = 'PROJEKTE_NEU',
  PROJEKTE_LOESCHEN = 'PROJEKTE_LOESCHEN',
  PROJEKTE_AENDERN_LOESCHEN_EIGENE = 'PROJEKTE_AENDERN_LOESCHEN_EIGENE',
  KALKULATIONEN_BEARBEITEN = 'KALKULATIONEN_BEARBEITEN',
  KOSTENERFASSUNG_NUTZEN = 'KOSTENERFASSUNG_NUTZEN',
  KOMMT_GEHT_COCKPIT_ZEIGEN = 'KOMMT_GEHT_COCKPIT_ZEIGEN',
  PROJEKT_KOSTEN_UEBERSICHT_NUTZEN = 'PROJEKT_KOSTEN_UEBERSICHT_NUTZEN',
  SOLL_IST_VERGLEICH_NUTZEN = 'SOLL_IST_VERGLEICH_NUTZEN',
  PLANTERMINE_ANLEGEN_AENDERN = 'PLANTERMINE_ANLEGEN_AENDERN',
  PROJEKTVORGAENGE_ANLEGEN_AENDERN = 'PROJEKTVORGAENGE_ANLEGEN_AENDERN',
  ARTIKEL_NEU_AENDERN = 'ARTIKEL_NEU_AENDERN',
  ARTIKEL_LOESCHEN = 'ARTIKEL_LOESCHEN',
  ARTIKEL_NUMMER_AENDERN = 'ARTIKEL_NUMMER_AENDERN',
  GRUPPEN_VERWALTEN = 'GRUPPEN_VERWALTEN',
  KUNDEN_LIEFERANTEN_DEAKTIVIEREN = 'KUNDEN_LIEFERANTEN_DEAKTIVIEREN',
  AUFTRAGSBESTAETIGUNG_ERSTELLEN_AENDERN = 'AUFTRAGSBESTAETIGUNG_ERSTELLEN_AENDERN',
  LIEFERSCHEIN_ERSTELLEN_AENDERN = 'LIEFERSCHEIN_ERSTELLEN_AENDERN',
  RECHNUNG_ERSTELLEN_AENDERN = 'RECHNUNG_ERSTELLEN_AENDERN',
  BESTELLUNG_ERSTELLEN_AENDERN = 'BESTELLUNG_ERSTELLEN_AENDERN',
  BEDARF_ERSTELLEN_AENDERN = 'BEDARF_ERSTELLEN_AENDERN',
  RECHNUNGSEINGANG_ERSTELLEN_AENDERN = 'RECHNUNGSEINGANG_ERSTELLEN_AENDERN',
  RECHNUNGEN_LOESCHEN = 'RECHNUNGEN_LOESCHEN',
  LIEFERSCHEINE_LOESCHEN = 'LIEFERSCHEINE_LOESCHEN',
  ANGEBOT_ERSTELLEN_AENDERN = 'ANGEBOT_ERSTELLEN_AENDERN',
  ANGEBOT_LOESCHEN = 'ANGEBOT_LOESCHEN',
  BEDARFSMELDUNGEN_LOESCHEN = 'BEDARFSMELDUNGEN_LOESCHEN',
  GAEB_IMPORT_DURCHFUEHREN = 'GAEB_IMPORT_DURCHFUEHREN',
  RECHNUNGSAUSGANGSBUCH_EINSEHEN_BEARBEITEN = 'RECHNUNGSAUSGANGSBUCH_EINSEHEN_BEARBEITEN',
  AUFTRAEGE_LOESCHEN = 'AUFTRAEGE_LOESCHEN',
  BESTELLUNGEN_LOESCHEN = 'BESTELLUNGEN_LOESCHEN',
  MAHNWESEN = 'MAHNWESEN',
  FIBU_SCHNITTSTELLE = 'FIBU_SCHNITTSTELLE',
  INVENTARVERWALTUNG = 'INVENTARVERWALTUNG',
  WARENEINGAENGE_BUCHEN = 'WARENEINGAENGE_BUCHEN',
  WARENAUSGAENGE_BUCHEN = 'WARENAUSGAENGE_BUCHEN',
  LOESCHEN_UMBUCHEN_UND_STORNIEREN_VON_LAGERBEWEGUNGEN = 'LOESCHEN_UMBUCHEN_UND_STORNIEREN_VON_LAGERBEWEGUNGEN',
  QM_DOKUMENTE_ERSTELLEN_AENDERN = 'QM_DOKUMENTE_ERSTELLEN_AENDERN',
  QM_DOKUMENTE_LOESCHEN = 'QM_DOKUMENTE_LOESCHEN',
  PRUEFAUFTRAEGE_ERSTELLEN_AENDERN = 'PRUEFAUFTRAEGE_ERSTELLEN_AENDERN',
  PRUEFAUFTRAEGE_LOESCHEN = 'PRUEFAUFTRAEGE_LOESCHEN',
  LIEFERANTENBEWERTUNGEN = 'LIEFERANTENBEWERTUNGEN',
  DTA_LASTSCHRIFTVERFAHREN = 'DTA_LASTSCHRIFTVERFAHREN',
  DTA_ZAHLUNGSVERFAHREN = 'DTA_ZAHLUNGSVERFAHREN',
  FREIGABE_EINGANGSRECHNUNGEN = 'FREIGABE_EINGANGSRECHNUNGEN',
  OFFENE_POSTEN_KUNDEN_EINSEHEN = 'OFFENE_POSTEN_KUNDEN_EINSEHEN',
  EINGANGSLIEFERSCHEIN_ANLEGEN_AENDERN = 'EINGANGSLIEFERSCHEIN_ANLEGEN_AENDERN',
  ZEIGEN_INFOFENSTER_KUNDEN_LIEFERSCHEINE = 'ZEIGEN_INFOFENSTER_KUNDEN_LIEFERSCHEINE',
  ZEIGEN_INFOFENSTER_KUNDEN_RECHNUNGEN = 'ZEIGEN_INFOFENSTER_KUNDEN_RECHNUNGEN',
  ZEIGEN_INFOFENSTER_KUNDEN_AUFTRAEGE = 'ZEIGEN_INFOFENSTER_KUNDEN_AUFTRAEGE',
  ZEIGEN_INFOFENSTER_PROJEKTE_TICKETS = 'ZEIGEN_INFOFENSTER_PROJEKTE_TICKETS',
  GRUPPE_AENDERN_LOESCHEN = 'GRUPPE_AENDERN_LOESCHEN',
  QM_DOKUMENTE_ANSEHEN = 'QM_DOKUMENTE_ANSEHEN',
  EINGANGSRECHNUNGEN_LOESCHEN = 'EINGANGSRECHNUNGEN_LOESCHEN',
  OFFENE_POSTEN_LIEFERANTEN_EINSEHEN = 'OFFENE_POSTEN_LIEFERANTEN_EINSEHEN',
  PRODUKTIONSAUFTRAEGE_ERSTELLEN_AENDERN = 'PRODUKTIONSAUFTRAEGE_ERSTELLEN_AENDERN',
  ZEIGEN_INFOFENSTER_LIEFERANTEN_AUFGABEN = 'ZEIGEN_INFOFENSTER_LIEFERANTEN_AUFGABEN',
  ZEIGEN_INFOFENSTER_KUNDEN_TICKETS = 'ZEIGEN_INFOFENSTER_KUNDEN_TICKETS',
  ZEIGEN_INFOFENSTER_LIEFERANTEN_BRIEFE = 'ZEIGEN_INFOFENSTER_LIEFERANTEN_BRIEFE',
  ZEIGEN_INFOFENSTER_LIEFERANTEN_DOKUMENTE = 'ZEIGEN_INFOFENSTER_LIEFERANTEN_DOKUMENTE',
  ZEIGEN_INFOFENSTER_LIEFERANTEN_EMAIL = 'ZEIGEN_INFOFENSTER_LIEFERANTEN_EMAIL',
  ZEIGEN_INFOFENSTER_LIEFERANTEN_NOTIZEN = 'ZEIGEN_INFOFENSTER_LIEFERANTEN_NOTIZEN',
  ZEIGEN_INFOFENSTER_LIEFERANTEN_TELEFONATE = 'ZEIGEN_INFOFENSTER_LIEFERANTEN_TELEFONATE',
  ZEIGEN_INFOFENSTER_LIEFERANTEN_TERMINE = 'ZEIGEN_INFOFENSTER_LIEFERANTEN_TERMINE',
  ZEIGEN_INFOFENSTER_LIEFERANTEN_EINGANGSRECHNUNG = 'ZEIGEN_INFOFENSTER_LIEFERANTEN_EINGANGSRECHNUNG',
  EINGANGSLIEFERSCHEIN_LOESCHEN = 'EINGANGSLIEFERSCHEIN_LOESCHEN',
  REISEKOSTENABRECHNUNG = 'REISEKOSTENABRECHNUNG',
  WARTUNGSMODUL_NUTZEN = 'WARTUNGSMODUL_NUTZEN',
  ZEIGEN_INFOFENSTER_LIEFERANTEN_BESTELLUNG = 'ZEIGEN_INFOFENSTER_LIEFERANTEN_BESTELLUNG',
  ZEIGEN_INFOFENSTER_LIEFERANTEN_BEDARFSANFORDERUNGEN = 'ZEIGEN_INFOFENSTER_LIEFERANTEN_BEDARFSANFORDERUNGEN',
  ZEIGEN_INFOFENSTER_PROJEKTE_ANGEBOTE = 'ZEIGEN_INFOFENSTER_PROJEKTE_ANGEBOTE',
  ZEIGEN_INFOFENSTER_PROJEKTE_AUFGABEN = 'ZEIGEN_INFOFENSTER_PROJEKTE_AUFGABEN',
  ZEIGEN_INFOFENSTER_PROJEKTE_BESUCHSBERICHTE = 'ZEIGEN_INFOFENSTER_PROJEKTE_BESUCHSBERICHTE',
  ZEIGEN_INFOFENSTER_PROJEKTE_BRIEFE = 'ZEIGEN_INFOFENSTER_PROJEKTE_BRIEFE',
  ZEIGEN_INFOFENSTER_PROJEKTE_DOKUMENTE = 'ZEIGEN_INFOFENSTER_PROJEKTE_DOKUMENTE',
  ZEIGEN_INFOFENSTER_PROJEKTE_EMAIL = 'ZEIGEN_INFOFENSTER_PROJEKTE_EMAIL',
  ZEIGEN_INFOFENSTER_PROJEKTE_NOTIZEN = 'ZEIGEN_INFOFENSTER_PROJEKTE_NOTIZEN',
  ZEIGEN_INFOFENSTER_PROJEKTE_TELEFONATE = 'ZEIGEN_INFOFENSTER_PROJEKTE_TELEFONATE',
  ZEIGEN_INFOFENSTER_PROJEKTE_TERMINE = 'ZEIGEN_INFOFENSTER_PROJEKTE_TERMINE',
  ZEIGEN_INFOFENSTER_PROJEKTE_EINGANGSRECHNUNGEN = 'ZEIGEN_INFOFENSTER_PROJEKTE_EINGANGSRECHNUNGEN',
  ZEIGEN_INFOFENSTER_PROJEKTE_LIEFERSCHEINE = 'ZEIGEN_INFOFENSTER_PROJEKTE_LIEFERSCHEINE',
  ZEIGEN_INFOFENSTER_PROJEKTE_RECHNUNGEN = 'ZEIGEN_INFOFENSTER_PROJEKTE_RECHNUNGEN',
  ZEIGEN_INFOFENSTER_PROJEKTE_AUFTRAEGE = 'ZEIGEN_INFOFENSTER_PROJEKTE_AUFTRAEGE',
  ZEIGEN_INFOFENSTER_PROJEKTE_BESTELLUNGEN = 'ZEIGEN_INFOFENSTER_PROJEKTE_BESTELLUNGEN',
  ZEIGEN_INFOFENSTER_PROJEKTE_BEDARF = 'ZEIGEN_INFOFENSTER_PROJEKTE_BEDARF',
  FREMDE_KALENDER_EINSEHEN = 'FREMDE_KALENDER_EINSEHEN',
  PRODUKTIONSAUFTRAEGE_LOESCHEN = 'PRODUKTIONSAUFTRAEGE_LOESCHEN',
  ZEIGEN_INFOFENSTER_LIEFERANTEN_REISEKOSTENABRECHNUNGEN = 'ZEIGEN_INFOFENSTER_LIEFERANTEN_REISEKOSTENABRECHNUNGEN',
  ARTIKELLISTE_EINSEHEN = 'ARTIKELLISTE_EINSEHEN',
  PROJEKTGRUPPEN_ANZEIGEN = 'PROJEKTGRUPPEN_ANZEIGEN',
  LIEFERANTENGRUPPEN_ANZEIGEN = 'LIEFERANTENGRUPPEN_ANZEIGEN',
  PROJEKTBEZOGENE_ANSICHTSRECHTE_IGNORIEREN = 'PROJEKTBEZOGENE_ANSICHTSRECHTE_IGNORIEREN',
  ANGEBOTE_NUR_ANSCHAUEN = 'ANGEBOTE_NUR_ANSCHAUEN',
  AUFTRAEGE_NUR_ANSCHAUEN = 'AUFTRAEGE_NUR_ANSCHAUEN',
  LIEFERSCHEINE_NUR_ANSCHAUEN = 'LIEFERSCHEINE_NUR_ANSCHAUEN',
  RECHNUNGEN_NUR_ANSCHAUEN = 'RECHNUNGEN_NUR_ANSCHAUEN',
  BESTELLUNGEN_NUR_ANSCHAUEN = 'BESTELLUNGEN_NUR_ANSCHAUEN',
  BEDARFSMELDUNGEN_NUR_ANSCHAUEN = 'BEDARFSMELDUNGEN_NUR_ANSCHAUEN',
  EINGANGSLIEFERSCHEINE_NUR_ANSCHAUEN = 'EINGANGSLIEFERSCHEINE_NUR_ANSCHAUEN',
  ZEIGEN_INFOFENSTER_KUNDEN_EINGANGSLIEFERSCHEINE = 'ZEIGEN_INFOFENSTER_KUNDEN_EINGANGSLIEFERSCHEINE',
  ZEIGEN_INFOFENSTER_LIEFERANTEN_EINGANGSLIEFERSCHEINE = 'ZEIGEN_INFOFENSTER_LIEFERANTEN_EINGANGSLIEFERSCHEINE',
  PRODUKTIONSARTIKELBEARBEITEN = 'PRODUKTIONSARTIKELBEARBEITEN',
  PROJEKT_STATUS_AENDERN = 'PROJEKT_STATUS_AENDERN',
  KASSE_BEARBEITEN = 'KASSE_BEARBEITEN',
  FREIGABE_ANGEBOT_FREMDE = 'FREIGABE_ANGEBOT_FREMDE',
  FREIGABE_AUFTRAG_FREMDE = 'FREIGABE_AUFTRAG_FREMDE',
  FREIGABE_RECHNUNG_FREMDE = 'FREIGABE_RECHNUNG_FREMDE',
  FREIGABE_BESTELLUNG_FREMDE = 'FREIGABE_BESTELLUNG_FREMDE',
  FREIGABE_LIEFERSCHEIN_FREMDE = 'FREIGABE_LIEFERSCHEIN_FREMDE',
  FREIGABE_BEDARF_FREMDE = 'FREIGABE_BEDARF_FREMDE',
  KOMMT_GEHT_ZEITEN_AENDERN_EIGENE = 'KOMMT_GEHT_ZEITEN_AENDERN_EIGENE',
  KOMMT_GEHT_ZEITEN_ANSEHEN = 'KOMMT_GEHT_ZEITEN_ANSEHEN',
  INFOFENSTER_POSITION_AENDERN = 'INFOFENSTER_POSITION_AENDERN',
  UNTERNEHMENSCONTROLLING = 'UNTERNEHMENSCONTROLLING',
  SUPER_INFO_DRUCKEN = 'SUPER_INFO_DRUCKEN',
  UNBENUTZT_EHEMALS_PLANKALENDER = 'UNBENUTZT_EHEMALS_PLANKALENDER',
  ZEIGEN_INFOFENSTER_LIEFERANTEN_LIEFERANTENBEWERTUNG = 'ZEIGEN_INFOFENSTER_LIEFERANTEN_LIEFERANTENBEWERTUNG',
  ZEIGEN_INFOFENSTER_PROJEKTE_LIEFERANTENBEWERTUNG = 'ZEIGEN_INFOFENSTER_PROJEKTE_LIEFERANTENBEWERTUNG',
  EXCEL_CONNECTOR_NUTZEN = 'EXCEL_CONNECTOR_NUTZEN',
  MITARBEITERLISTE = 'MITARBEITERLISTE',
  GERAETEVERWALTUNG = 'GERAETEVERWALTUNG',
  ZEITERFASSUNG_GESPERRTE_EINTRAEGE_AENDERN = 'ZEITERFASSUNG_GESPERRTE_EINTRAEGE_AENDERN',
  FREIGABE_EINGANGSRECHNUNGEN_NUR_EIGENE = 'FREIGABE_EINGANGSRECHNUNGEN_NUR_EIGENE',
  EK_PREISE_ANZEIGEN = 'EK_PREISE_ANZEIGEN',
  FREIGABE_EINGANGSRECHNUNGEN_NUR_WEITERLEITEN = 'FREIGABE_EINGANGSRECHNUNGEN_NUR_WEITERLEITEN',
  PLANKALENDER_PLANTAFEL_NUTZEN = 'PLANKALENDER_PLANTAFEL_NUTZEN',
  BANKING_NUTZEN = 'BANKING_NUTZEN',
  ZEITERFASSUNG_FUER_ANDERE_BENUTZER = 'ZEITERFASSUNG_FUER_ANDERE_BENUTZER',
  EXCEL_EXPORT_NUTZEN = 'EXCEL_EXPORT_NUTZEN',
  BANKVERBINDUNG_ANLEGEN_AENDERN = 'BANKVERBINDUNG_ANLEGEN_AENDERN',
  FORECAST_ERFASSEN = 'FORECAST_ERFASSEN',
  FORECAST_VERWALTEN = 'FORECAST_VERWALTEN',
  KONTROLLE_LETZTER_WERKTAG = 'KONTROLLE_LETZTER_WERKTAG',
  PROJEKT_AENDERN = 'PROJEKT_AENDERN',
  ANGEBOTE_VERDOPPELN = 'ANGEBOTE_VERDOPPELN',
  BEDARFSANFORDERUNGEN_VERDOPPELN = 'BEDARFSANFORDERUNGEN_VERDOPPELN',
  BESTELLUNGEN_VERDOPPELN = 'BESTELLUNGEN_VERDOPPELN',
  AUFTRAEGE_VERDOPPELN = 'AUFTRAEGE_VERDOPPELN',
  EINGANGSLIEFERSCHEINE_VERDOPPELN = 'EINGANGSLIEFERSCHEINE_VERDOPPELN',
  LIEFERSCHEINE_VERDOPPELN = 'LIEFERSCHEINE_VERDOPPELN',
  RECHNUNGEN_VERDOPPELN = 'RECHNUNGEN_VERDOPPELN',
  SUMMEN_ANZEIGE_RA = 'SUMMEN_ANZEIGE_RA',
  EIGENE_ARBEITSZEITEN_ANZEIGEN = 'EIGENE_ARBEITSZEITEN_ANZEIGEN',
  FREMDE_ARBEITSZEITEN_ANZEIGEN = 'FREMDE_ARBEITSZEITEN_ANZEIGEN',
  PROJEKT_CONTROLLING_EINSEHEN = 'PROJEKT_CONTROLLING_EINSEHEN',
  INDIVIDUELLES_CONTROLLING_EINSEHEN = 'INDIVIDUELLES_CONTROLLING_EINSEHEN',
  PROJEKTVERTEILER_PFLEGEN = 'PROJEKTVERTEILER_PFLEGEN',
  KUNDEN_DEBITORENNUMMER_AENDERN = 'KUNDEN_DEBITORENNUMMER_AENDERN',
  LIEFERANTEN_KREDITORENNUMMER_AENDERN = 'LIEFERANTEN_KREDITORENNUMMER_AENDERN',
  PROJEKT_NUMMER_AENDERN = 'PROJEKT_NUMMER_AENDERN',
  ANGEBOT_NUMMER_AENDERN = 'ANGEBOT_NUMMER_AENDERN',
  BEDARF_NUMMER_AENDERN = 'BEDARF_NUMMER_AENDERN',
  BESTELLUNG_NUMMER_AENDERN = 'BESTELLUNG_NUMMER_AENDERN',
  AUFTRAG_NUMMER_AENDERN = 'AUFTRAG_NUMMER_AENDERN',
  EINGANGSLIEFERSCHEIN_NUMMER_AENDERN = 'EINGANGSLIEFERSCHEIN_NUMMER_AENDERN',
  EINGANGSRECHNUNG_NUMMER_AENDERN = 'EINGANGSRECHNUNG_NUMMER_AENDERN',
  LIEFERSCHEIN_NUMMER_AENDERN = 'LIEFERSCHEIN_NUMMER_AENDERN',
  RECHNUNG_NUMMER_AENDERN = 'RECHNUNG_NUMMER_AENDERN',
  WARTUNGSVERTRAGS_NUMMER_AENDERN = 'WARTUNGSVERTRAGS_NUMMER_AENDERN',
  VERKAUFSCHANCEN_NUR_EIGENE_AENDERN_LOESCHEN = 'VERKAUFSCHANCEN_NUR_EIGENE_AENDERN_LOESCHEN',
  UEBERSTUNDEN_UEBERTRAG_AUSZAHLUNG_PFLEGEN = 'UEBERSTUNDEN_UEBERTRAG_AUSZAHLUNG_PFLEGEN',
  ZAHLUNGEN_ERFASSEN = 'ZAHLUNGEN_ERFASSEN',
  MONATS_LAGERABSCHLUSS = 'MONATS_LAGERABSCHLUSS',
  KALENDER_KRANKHEITSTAGE_ANZEIGEN = 'KALENDER_KRANKHEITSTAGE_ANZEIGEN',
  FREIGABE_GUTSCHRIFT = 'FREIGABE_GUTSCHRIFT',
  PAUSEN_STEMPELN = 'PAUSEN_STEMPELN',
  KOMMT_GEHT_ZEITEN_AENDERN_FREMDE = 'KOMMT_GEHT_ZEITEN_AENDERN_FREMDE',
  KOMMT_GEHT_ZEITEN_KORRIGIEREN_EIGENE = 'KOMMT_GEHT_ZEITEN_KORRIGIEREN_EIGENE',
  MITTLEREN_EK_AENDERN = 'MITTLEREN_EK_AENDERN',
  E_MAIL_SIGNATUREN_BEARBEITEN = 'E_MAIL_SIGNATUREN_BEARBEITEN',
  AUFTAUEN_VON_EINGEFRORENEN_VERKAUFSCHANCEN = 'AUFTAUEN_VON_EINGEFRORENEN_VERKAUFSCHANCEN',
  LIEFERANTENDATEN_VON_MITARBEITERN_EINSEHEN = 'LIEFERANTENDATEN_VON_MITARBEITERN_EINSEHEN',
  KOMMT_GEHT_MOANTS_KORREKTUREN_VERWALTEN = 'KOMMT_GEHT_MOANTS_KORREKTUREN_VERWALTEN',
  URLAUBS_KALENDER_HERVORGEHOBENE_BEREICHE_PFLEGEN = 'URLAUBS_KALENDER_HERVORGEHOBENE_BEREICHE_PFLEGEN',
  PROJEKT_KOSTENZUORDNEN = 'PROJEKT_KOSTENZUORDNEN',
  VK_PREISE_IN_ZEITERFASSUNG_ANZEIGEN = 'VK_PREISE_IN_ZEITERFASSUNG_ANZEIGEN',
  STATISTIK_UMSATZ_NACH_VERANTWORTLICHEM = 'STATISTIK_UMSATZ_NACH_VERANTWORTLICHEM',
  COCKPIT_NUR_ANSCHAUEN = 'COCKPIT_NUR_ANSCHAUEN',
  APP_DESKTOP_ICH = 'APP_DESKTOP_ICH',
  APP_DESKTOP_TEAM = 'APP_DESKTOP_TEAM',
  APP_DESKTOP_FIRMA = 'APP_DESKTOP_FIRMA',
  APP_DESKTOP_KUNDEN_DESKTOP = 'APP_DESKTOP_KUNDEN_DESKTOP',
  APP_ICH_AUFGABEN = 'APP_ICH_AUFGABEN',
  APP_ICH_TERMINE = 'APP_ICH_TERMINE',
  APP_ICH_TICKETS = 'APP_ICH_TICKETS',
  APP_ICH_VERKAUFSCHANCEN = 'APP_ICH_VERKAUFSCHANCEN',
  APP_ICH_ANGEBOTE = 'APP_ICH_ANGEBOTE',
  APP_ICH_AUFTRAEGE = 'APP_ICH_AUFTRAEGE',
  APP_ICH_LIEFERSCHEINE = 'APP_ICH_LIEFERSCHEINE',
  APP_ICH_RECHNUNGEN = 'APP_ICH_RECHNUNGEN',
  APP_ICH_BESTELLUNGEN = 'APP_ICH_BESTELLUNGEN',
  APP_ICH_KOSTENBELEGE = 'APP_ICH_KOSTENBELEGE',
  APP_ICH_ZEITERFASSUNG_PROJEKT = 'APP_ICH_ZEITERFASSUNG_PROJEKT',
  APP_ICH_ZEITERFASSUNG_KOMMT_GEHT = 'APP_ICH_ZEITERFASSUNG_KOMMT_GEHT',
  APP_TEAM_AUFGABEN = 'APP_TEAM_AUFGABEN',
  APP_TEAM_TERMINE = 'APP_TEAM_TERMINE',
  APP_TEAM_TICKETS = 'APP_TEAM_TICKETS',
  APP_TEAM_VERKAUFSCHANCEN = 'APP_TEAM_VERKAUFSCHANCEN',
  APP_TEAM_ANGEBOTE = 'APP_TEAM_ANGEBOTE',
  APP_TEAM_AUFTRAEGE = 'APP_TEAM_AUFTRAEGE',
  APP_TEAM_LIEFERSCHEINE = 'APP_TEAM_LIEFERSCHEINE',
  APP_TEAM_RECHNUNGEN = 'APP_TEAM_RECHNUNGEN',
  APP_TEAM_BESTELLUNGEN = 'APP_TEAM_BESTELLUNGEN',
  APP_TEAM_KOSTENBELEGE = 'APP_TEAM_KOSTENBELEGE',
  APP_FIRMA_AUFGABEN = 'APP_FIRMA_AUFGABEN',
  APP_FIRMA_BESUCHSBERICHTE = 'APP_FIRMA_BESUCHSBERICHTE',
  APP_FIRMA_BRIEFE = 'APP_FIRMA_BRIEFE',
  APP_FIRMA_DOKUMENTE = 'APP_FIRMA_DOKUMENTE',
  APP_FIRMA_E_MAILS = 'APP_FIRMA_E_MAILS',
  APP_FIRMA_TELEFONATE = 'APP_FIRMA_TELEFONATE',
  APP_FIRMA_TERMINE = 'APP_FIRMA_TERMINE',
  APP_FIRMA_TICKETS = 'APP_FIRMA_TICKETS',
  APP_FIRMA_VERKAUFSCHANCEN = 'APP_FIRMA_VERKAUFSCHANCEN',
  APP_FIRMA_ANGEBOTE = 'APP_FIRMA_ANGEBOTE',
  APP_FIRMA_AUFTRAEGE = 'APP_FIRMA_AUFTRAEGE',
  APP_FIRMA_BESTELLUNGEN = 'APP_FIRMA_BESTELLUNGEN',
  APP_FIRMA_LIEFERSCHEINE = 'APP_FIRMA_LIEFERSCHEINE',
  APP_FIRMA_KOSTENBELEGE = 'APP_FIRMA_KOSTENBELEGE',
  APP_FIRMA_RECHNUNGEN = 'APP_FIRMA_RECHNUNGEN',
  APP_FUNKTIONEN_AUFGABEN = 'APP_FUNKTIONEN_AUFGABEN',
  APP_FUNKTIONEN_DOKUMENTE = 'APP_FUNKTIONEN_DOKUMENTE',
  APP_FUNKTIONEN_TELEFONATE = 'APP_FUNKTIONEN_TELEFONATE',
  APP_FUNKTIONEN_BESUCHSBERICHTE = 'APP_FUNKTIONEN_BESUCHSBERICHTE',
  APP_FUNKTIONEN_ANGEBOTE = 'APP_FUNKTIONEN_ANGEBOTE',
  APP_FUNKTIONEN_AUFTRAEGE = 'APP_FUNKTIONEN_AUFTRAEGE',
  APP_FUNKTIONEN_LIEFERSCHEINE = 'APP_FUNKTIONEN_LIEFERSCHEINE',
  APP_DASHBOARD_UMSATZ = 'APP_DASHBOARD_UMSATZ',
  APP_DASHBOARD_AUFTRAGSEINGANG = 'APP_DASHBOARD_AUFTRAGSEINGANG',
  APP_DASHBOARD_OFFENE_POSTEN = 'APP_DASHBOARD_OFFENE_POSTEN',
  APP_DESKTOP_LIEFERANTEN_DESKTOP = 'APP_DESKTOP_LIEFERANTEN_DESKTOP',
  APP_DESKTOP_PROJEKT_DESKTOP = 'APP_DESKTOP_PROJEKT_DESKTOP',
  APP_DESKTOP_DASHBOARD = 'APP_DESKTOP_DASHBOARD',
  APP_ICH_MITARBEITERWECHSEL = 'APP_ICH_MITARBEITERWECHSEL',
  APP_DASHBOARD_MITARBEITERKENNZAHLEN = 'APP_DASHBOARD_MITARBEITERKENNZAHLEN',
  APP_DASHBOARD_VERTRIEB_NEUKUNDEN = 'APP_DASHBOARD_VERTRIEB_NEUKUNDEN',
  APP_DASHBOARD_VERTRIEB_INTERESSENTEN = 'APP_DASHBOARD_VERTRIEB_INTERESSENTEN',
  APP_DASHBOARD_FIRMA_KENNZAHLEN = 'APP_DASHBOARD_FIRMA_KENNZAHLEN',
  APP_ICH_TELEFONATE = 'APP_ICH_TELEFONATE',
  APP_FIRMA_OFFENE_POSTEN = 'APP_FIRMA_OFFENE_POSTEN',
  VORGANGSNACHVERFOLGUNG_NUTZEN = 'VORGANGSNACHVERFOLGUNG_NUTZEN',
  APP_ICH_GENEHMIGUNGEN = 'APP_ICH_GENEHMIGUNGEN',
  APP_FUNKTIONEN_WANDLUNG_ERP = 'APP_FUNKTIONEN_WANDLUNG_ERP',
  TICKET_ANLEGEN_AENDERN = 'TICKET_ANLEGEN_AENDERN',
  TICKET_LOESCHEN = 'TICKET_LOESCHEN',
  POSITIONEN_KURZTEXT_BEARBEITUNG_VERHINDERN = 'POSITIONEN_KURZTEXT_BEARBEITUNG_VERHINDERN',
  APP_WECHSEL_DER_ABTEILUNG = 'APP_WECHSEL_DER_ABTEILUNG',
  APP_FREIGABE_ANGEBOTE = 'APP_FREIGABE_ANGEBOTE',
  APP_FREIGABE_AUFTRAEGE = 'APP_FREIGABE_AUFTRAEGE',
  APP_FREIGABE_LIEFERSCHEINE = 'APP_FREIGABE_LIEFERSCHEINE',
  APP_FREIGABE_RECHNUNGEN = 'APP_FREIGABE_RECHNUNGEN',
  APP_FREIGABE_BESTELLUNGEN = 'APP_FREIGABE_BESTELLUNGEN',
  APP_GENEHMIGUNG_EINGANGSRECHNUNGEN = 'APP_GENEHMIGUNG_EINGANGSRECHNUNGEN',
  APP_FREIGABE_GUTSCHRIFTEN = 'APP_FREIGABE_GUTSCHRIFTEN',
  APP_TICKETS_ERFASSEN_UND_AENDERN = 'APP_TICKETS_ERFASSEN_UND_AENDERN',
  APP_ZEITERFASSUNG_OHNE_ZEITAENDERUNG = 'APP_ZEITERFASSUNG_OHNE_ZEITAENDERUNG',
  WARTUNGSVERTRAEGE_ANLEGENUND_AENDERN = 'WARTUNGSVERTRAEGE_ANLEGENUND_AENDERN',
  WARTUNGSVERTRAEGE_VERDOPPELN = 'WARTUNGSVERTRAEGE_VERDOPPELN',
  WARTUNGSVERTRAEGE_LOESCHEN = 'WARTUNGSVERTRAEGE_LOESCHEN',
  WARTUNGSVERTRAEGENUR_ANSCHAUEN = 'WARTUNGSVERTRAEGENUR_ANSCHAUEN',
  WARTUNGSVERTRAEGE_ABO_LAUF_AUSFUEHREN = 'WARTUNGSVERTRAEGE_ABO_LAUF_AUSFUEHREN',
  EINGANGSRECHNUNG_PROJEKTZUORDNUNGAENDERN = 'EINGANGSRECHNUNG_PROJEKTZUORDNUNGAENDERN',
  KALKULATIONEN_VERDOPPELN = 'KALKULATIONEN_VERDOPPELN',
  KALKULATIONEN_LOESCHEN = 'KALKULATIONEN_LOESCHEN',
  KALKULATIONEN_NUR_ANSCHAUEN = 'KALKULATIONEN_NUR_ANSCHAUEN',
  SUMMEN_IN_ERP_LISTEN_ANZEIGEN = 'SUMMEN_IN_ERP_LISTEN_ANZEIGEN',
  PROJEKT_WIEDER_AKTIVIEREN_WENN_ABGESCHLOSSEN = 'PROJEKT_WIEDER_AKTIVIEREN_WENN_ABGESCHLOSSEN',
  KRANKHEITSTAGE_IN_PROJEKTPLANUNG = 'KRANKHEITSTAGE_IN_PROJEKTPLANUNG',
  APP_FREIGABE_BEDARFSANFORDERUNG = 'APP_FREIGABE_BEDARFSANFORDERUNG',
  ARTIKEL_NUR_ANSCHAUEN = 'ARTIKEL_NUR_ANSCHAUEN',
  URLAUBSGENEHMIGUNG_ERTEILEN = 'URLAUBSGENEHMIGUNG_ERTEILEN',
  LAGER_MOBIL_EINGANGSLIEFERSCHEIN_ERSTELLEN = 'LAGER_MOBIL_EINGANGSLIEFERSCHEIN_ERSTELLEN',
  LAGER_MOBIL_EINGANGSLIEFERSCHEIN_OEFFNEN = 'LAGER_MOBIL_EINGANGSLIEFERSCHEIN_OEFFNEN',
  LAGER_MOBIL_EINGANGSLIEFERSCHEIN_POSITION_HINZUFUEGEN_EINBUCHEN = 'LAGER_MOBIL_EINGANGSLIEFERSCHEIN_POSITION_HINZUFUEGEN_EINBUCHEN',
  LAGER_MOBIL_EINGANGSLIEFERSCHEIN_POSITION_EINBUCHEN = 'LAGER_MOBIL_EINGANGSLIEFERSCHEIN_POSITION_EINBUCHEN',
  LAGER_MOBIL_LIEFERSCHEIN_ERSTELLEN = 'LAGER_MOBIL_LIEFERSCHEIN_ERSTELLEN',
  LAGER_MOBIL_LIEFERSCHEIN_OEFFNEN = 'LAGER_MOBIL_LIEFERSCHEIN_OEFFNEN',
  LAGER_MOBIL_LIEFERSCHEIN_POSITION_HINZUFUEGEN_AUSBUCHEN = 'LAGER_MOBIL_LIEFERSCHEIN_POSITION_HINZUFUEGEN_AUSBUCHEN',
  LAGER_MOBIL_LIEFERSCHEIN_POSITION_AUSBUCHEN = 'LAGER_MOBIL_LIEFERSCHEIN_POSITION_AUSBUCHEN',
  LAGER_MOBIL_UMBUCHUNG_LIEFERSCHEIN_ERSTELLEN = 'LAGER_MOBIL_UMBUCHUNG_LIEFERSCHEIN_ERSTELLEN',
  LAGER_MOBIL_UMBUCHUNG_LIEFERSCHEIN_OEFFNEN = 'LAGER_MOBIL_UMBUCHUNG_LIEFERSCHEIN_OEFFNEN',
  LAGER_MOBIL_UMBUCHUNG_LIEFERSCHEIN_POSITION_HINZUFUEGEN_BUCHEN = 'LAGER_MOBIL_UMBUCHUNG_LIEFERSCHEIN_POSITION_HINZUFUEGEN_BUCHEN',
  LAGER_MOBIL_UMBUCHUNG_LIEFERSCHEIN_POSITION_BUCHEN = 'LAGER_MOBIL_UMBUCHUNG_LIEFERSCHEIN_POSITION_BUCHEN',
  LAGER_MOBIL_ARTIKEL_INFOS_ANZEIGEN = 'LAGER_MOBIL_ARTIKEL_INFOS_ANZEIGEN',
  LAGER_MOBIL_ARTIKEL_ANLEGEN_AENDERN = 'LAGER_MOBIL_ARTIKEL_ANLEGEN_AENDERN',
  LAGER_MOBIL_INVENTUR_DURCHFUEHREN = 'LAGER_MOBIL_INVENTUR_DURCHFUEHREN',
  LAGER_MOBIL_BELEGLOSES_BUCHEN_ERLAUBEN = 'LAGER_MOBIL_BELEGLOSES_BUCHEN_ERLAUBEN',
  KOMMT_GEHT_ZEITKARTEN_AUSWERTUNG = 'KOMMT_GEHT_ZEITKARTEN_AUSWERTUNG',
  ARTIKELNUMMER_IN_ERP_BELEGEN_AENDERN = 'ARTIKELNUMMER_IN_ERP_BELEGEN_AENDERN',
  ER_PSTATUS_AENDERN = 'ER_PSTATUS_AENDERN',
  LEISTUNGSNACHWEIS_ANSICHT_EIGENER = 'LEISTUNGSNACHWEIS_ANSICHT_EIGENER',
  LEISTUNGSNACHWEIS_ANSICHT_EIGENER_UND_ZUGEORDNETE_MITARBEITER = 'LEISTUNGSNACHWEIS_ANSICHT_EIGENER_UND_ZUGEORDNETE_MITARBEITER',
  LEISTUNGSNACHWEIS_ANSICHT_ALLE = 'LEISTUNGSNACHWEIS_ANSICHT_ALLE',
  ZEITKARTE_ANSICHT_EIGENE = 'ZEITKARTE_ANSICHT_EIGENE',
  ZEITKARTE_ANSICHT_EIGENE_UND_ZUGEORDNETE_MITARBEITER = 'ZEITKARTE_ANSICHT_EIGENE_UND_ZUGEORDNETE_MITARBEITER',
  ZEITKARTE_ALLE = 'ZEITKARTE_ALLE',
  URLAUBSANSPRUCH_AENDERN_EIGENEN = 'URLAUBSANSPRUCH_AENDERN_EIGENEN',
  URLAUBSANSPRUCH_AENDERN_FREMDEN = 'URLAUBSANSPRUCH_AENDERN_FREMDEN',
  MOBIL_NUR_EIGENE_VORGAENGE = 'MOBIL_NUR_EIGENE_VORGAENGE',
  EINGANGSRECHNUNG_NUR_ANSCHAUEN = 'EINGANGSRECHNUNG_NUR_ANSCHAUEN',
  MOBIL_PREISE_AUSBLENDEN = 'MOBIL_PREISE_AUSBLENDEN',
  KUNDENINDIVIDUELLE_PREISE_PFLEGEN = 'KUNDENINDIVIDUELLE_PREISE_PFLEGEN',
  GESPERRTE_CHARGEN_BUCHEN = 'GESPERRTE_CHARGEN_BUCHEN',
  FREIGABE_ANGEBOT_EIGENE = 'FREIGABE_ANGEBOT_EIGENE',
  FREIGABE_AUFTRAG_EIGENE = 'FREIGABE_AUFTRAG_EIGENE',
  FREIGABE_LIEFERSCHEIN_EIGENE = 'FREIGABE_LIEFERSCHEIN_EIGENE',
  FREIGABE_RECHNUNG_EIGENE = 'FREIGABE_RECHNUNG_EIGENE',
  FREIGABE_GUTSCHRIFT_EIGENE = 'FREIGABE_GUTSCHRIFT_EIGENE',
  FREIGABE_BESTELLUNG_EIGENE = 'FREIGABE_BESTELLUNG_EIGENE',
  FREIGABE_BEDARF_EIGENE = 'FREIGABE_BEDARF_EIGENE',
  CHARGEN_IN_QUARANTAENE_AUSBUCHEN = 'CHARGEN_IN_QUARANTAENE_AUSBUCHEN',
  NOT_SET = 'NOT_SET',
}
