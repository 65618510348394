import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { ObjectType } from '../Enums/ObjectType.enum';
import { ReportMode } from '../Enums/ReportMode.enum';
import { ReportType } from '../Enums/ReportType.enum';

export class Report2<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: name */
  name?: string;
  /** Alias: reportType */
  reportType?: ReportType;
  /** Alias: location */
  location?: string;
  /** Alias: objectType */
  objectType?: ObjectType;
  /** Alias: reportMode */
  reportMode?: ReportMode;

  __typename?: string;
}

const fields: FieldDefinitions<Report2> = {
  id: {
    alias: 'id',
  },
  name: {
    alias: 'name',
  },
  reportType: {
    alias: 'reportType',
  },
  location: {
    alias: 'location',
  },
  objectType: {
    alias: 'objectType',
  },
  reportMode: {
    alias: 'reportMode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const report2EntityDefinition: EntitiyDefinition<Report2> = {
  local: {},
  remote: {
    queryName: 'getReport2',
    fragmentName: 'Report2',
    withPaginationWrapper: false,
    params: [{ name: 'filter', type: 'String' }],
  },
  fieldDefinitions: fields,
};
