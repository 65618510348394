import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Customer } from './Customer.entity';
import { Invoice } from './Invoice.entity';
import { RaLedgerAccountSplit } from './RaLedgerAccountSplit.entity';
import { RaPayment } from './RaPayment.entity';

export class Ra<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: datum */
  date?: string;
  /** Alias: rechnungNummer */
  rechnungNummer?: number;
  /** Alias: sdObjMemberCode */
  sdObjMemberCode?: number;
  /** Alias: faelligDatum */
  faelligDatum?: string;
  /** Alias: betrag */
  betrag?: number;
  /** Alias: mwstBetrag */
  mwstBetrag?: number;
  /** Alias: rMwstNeu */
  rMwstNeu?: number;
  /** Alias: bezehaltBetrag */
  bezehaltBetrag?: number;
  /** Alias: stornSumme */
  stornSumme?: number;
  /** Alias: mahnstufe */
  mahnstufe?: number;
  /** Alias: notiz */
  note?: string;
  /** Alias: skonto */
  skonto?: number;
  /** Alias: kostenstelle */
  kostenstelle?: number;
  /** Alias: frist */
  frist?: string;
  /** Alias: listFlag */
  listFlag?: number;
  /** Alias: dtaNummer */
  dtaNummer?: number;
  /** Alias: rechnungCode */
  rechnungCode?: number;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: raSachkGesamt */
  raSachkGesamt?: number;
  /** Alias: raKostenstGesamt */
  raKostenstGesamt?: number;
  /** Alias: datev */
  datev?: string;
  /** Alias: kurs */
  course?: number;
  /** Alias: waehrungCode */
  waehrungCode?: number;
  /** Alias: waehrungDatum */
  waehrungDatum?: string;
  /** Alias: provision */
  provision?: number;
  /** Alias: buchungsDatum */
  buchungsDatum?: string;
  /** Alias: summe */
  summe?: number;
  /** Alias: lastschriftOk */
  lastschriftOk?: boolean;
  /** Alias: zahlungCode */
  zahlungCode?: number;
  /** Alias: bankkontoCode */
  bankkontoCode?: number;
  /** Alias: buchhaltungssperre */
  buchhaltungssperre?: boolean;
  /** Alias: dmsId */
  dmsId?: string;
  /** Alias: splittings */
  splittings?: RaLedgerAccountSplit<T>[];
  /** Alias: kunde */
  kunde?: Customer<T>;
  /** Alias: invoice */
  invoice?: Invoice<T>;
  /** Alias: remainingAmount */
  remainingAmount?: number;
  /** Alias: payments */
  payments?: RaPayment<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<Ra> = {
  id: {
    alias: 'code',
  },
  date: {
    alias: 'datum',
  },
  rechnungNummer: {
    alias: 'rechnungNummer',
  },
  sdObjMemberCode: {
    alias: 'sdObjMemberCode',
  },
  faelligDatum: {
    alias: 'faelligDatum',
  },
  betrag: {
    alias: 'betrag',
  },
  mwstBetrag: {
    alias: 'mwstBetrag',
  },
  rMwstNeu: {
    alias: 'rMwstNeu',
  },
  bezehaltBetrag: {
    alias: 'bezehaltBetrag',
  },
  stornSumme: {
    alias: 'stornSumme',
  },
  mahnstufe: {
    alias: 'mahnstufe',
  },
  note: {
    alias: 'notiz',
  },
  skonto: {
    alias: 'skonto',
  },
  kostenstelle: {
    alias: 'kostenstelle',
  },
  frist: {
    alias: 'frist',
  },
  listFlag: {
    alias: 'listFlag',
  },
  dtaNummer: {
    alias: 'dtaNummer',
  },
  rechnungCode: {
    alias: 'rechnungCode',
  },
  userId: {
    alias: 'benutzerCode',
  },
  raSachkGesamt: {
    alias: 'raSachkGesamt',
  },
  raKostenstGesamt: {
    alias: 'raKostenstGesamt',
  },
  datev: {
    alias: 'datev',
  },
  course: {
    alias: 'kurs',
  },
  waehrungCode: {
    alias: 'waehrungCode',
  },
  waehrungDatum: {
    alias: 'waehrungDatum',
  },
  provision: {
    alias: 'provision',
  },
  buchungsDatum: {
    alias: 'buchungsDatum',
  },
  summe: {
    alias: 'summe',
  },
  lastschriftOk: {
    alias: 'lastschriftOk',
  },
  zahlungCode: {
    alias: 'zahlungCode',
  },
  bankkontoCode: {
    alias: 'bankkontoCode',
  },
  buchhaltungssperre: {
    alias: 'buchhaltungssperre',
  },
  dmsId: {
    alias: 'dmsId',
  },
  splittings: {
    alias: 'splittings',
    entity: Entities.raLedgerAccountSplit,
  },
  kunde: {
    alias: 'kunde',
    entity: Entities.customer,
  },
  invoice: {
    alias: 'invoice',
    entity: Entities.invoice,
  },
  remainingAmount: {
    alias: 'remainingAmount',
  },
  payments: {
    alias: 'payments',
    entity: Entities.raPayment,
  },
  __typename: {
    alias: '__typename',
  },
};

export const raEntityDefinition: EntitiyDefinition<Ra> = {
  local: {},
  remote: {
    queryName: 'getRa',
    fragmentName: 'Ra',
    withPaginationWrapper: true,
    mutations: {
      upsert: {
        mutationName: 'upsertOutgoingInvoiceLedger',
        args: [
          { name: 'input', type: 'InputRa!' },
          { name: 'relations', type: 'InputRaRelation' },
          { name: 'drySave', type: 'Boolean' },
        ],
      },
    },
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
