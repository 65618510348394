import { Add } from '@mui/icons-material';
import ExpandIcon from '@mui/icons-material/Expand';
import FunctionsIcon from '@mui/icons-material/Functions';
import SaveIcon from '@mui/icons-material/Save';
import SpaceBarIcon from '@mui/icons-material/SpaceBar';
import TitleIcon from '@mui/icons-material/Title';
import {
  Fab,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Theme,
  useMediaQuery,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useCallback, useMemo, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as TitleSum } from '@work4all/assets/icons/title_sum.svg';

import { ArticlePicker } from '@work4all/components/lib/components/entity-picker/article-picker/ArticlePicker';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { useMaskTabContext } from '../../../mask-tabs';
import { MaskOverlayFullscreenToggleButton } from '../../components/MaskOverlayFullscreenToggleButton';
import { MaskOverlayMenuWrapper } from '../../components/MaskOverlayMenuWrapper';
import { useMaskContext } from '../../hooks/mask-context';
import { ERPSettings } from '../settings/erp-settings/ERPSettings';

import { Signature } from './components/signature/Signature';
import { ReportButton } from './components/tab-panels/report-preview/ReportButton';
import { useShadowBzObjectApiContext } from './hooks/use-bz-shadow-object-api/use-shadow-bz-object-api-context';

interface ERPMaskHeaderActionsProps {
  isSubmitting: boolean;
  isDirty: boolean;
  hasRightToSave: boolean;
  hasError: boolean;
}
export const ERPMaskHeaderActions = (props: ERPMaskHeaderActionsProps) => {
  const { isDirty, isSubmitting, hasRightToSave, hasError } = props;
  const { t } = useTranslation();
  const { wip, entity } = useMaskContext();
  const disabled =
    !isDirty || isSubmitting || !hasRightToSave || wip || hasError;

  const signatureDisabled = isSubmitting || !hasRightToSave || wip || hasError;
  const reportDisabled = signatureDisabled;

  const shadowBzObjectApi = useShadowBzObjectApiContext();
  const onAddPosition = shadowBzObjectApi.addPosition;

  const [settingsOpen, setSettingsOpen] = useState(false);

  const openSettings = useCallback(() => {
    setSettingsOpen(true);
  }, []);

  const onSettingsClose = useCallback(() => {
    setSettingsOpen(false);
  }, []);

  const maskTabContext = useMaskTabContext();

  const { ...articlePopoverProps } = usePopoverState();

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  const erpSettingsView = useMemo(() => {
    return {
      view: <ERPSettings amplitudeEntryPoint="ERPMask" />,
    };
  }, []);

  return (
    <React.Fragment>
      <NavigationOverlay
        open={settingsOpen}
        initialView={erpSettingsView}
        close={onSettingsClose}
      />
      {entity === Entities.deliveryNote ? (
        <Signature disabled={signatureDisabled} />
      ) : null}
      <IconButton type="submit" size="large" disabled={disabled}>
        <SaveIcon color={disabled ? 'secondary' : 'primary'} />
      </IconButton>
      {!(isMobile && maskTabContext.value === 'positions') && (
        <ReportButton disabled={reportDisabled} />
      )}
      {isMobile && maskTabContext.value === 'positions' && (
        <EntityPickerPopover
          picker={
            <ArticlePicker
              multiple={false}
              onChange={(value) => {
                if (value) {
                  if ((value as ErpPositionsKind) in ErpPositionsKind) {
                    onAddPosition({ positionType: value as ErpPositionsKind });
                  } else {
                    onAddPosition({
                      positionType: ErpPositionsKind.STANDARD,
                      article: value as Article,
                    });
                  }
                }
                articlePopoverProps.onClose();
              }}
              value={null}
            />
          }
          fullscreen
        >
          <Fab size="medium" color="primary">
            <Add />
          </Fab>
        </EntityPickerPopover>
      )}
      <MaskOverlayFullscreenToggleButton />
      <MaskOverlayMenuWrapper>
        <MenuList>
          {!(isMobile && maskTabContext.value === 'positions') && (
            <MenuItem onClick={openSettings}>{t('MASK.SETTINGS')}</MenuItem>
          )}
          {isMobile && maskTabContext.value === 'positions' && (
            <>
              <MenuItem
                onClick={() =>
                  onAddPosition({
                    positionType: ErpPositionsKind.TEXTZEILE,
                  })
                }
              >
                <ListItemIcon>
                  <SpaceBarIcon />
                </ListItemIcon>
                <ListItemText>{t('COMMON.ERP.EMPTY')}</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() =>
                  onAddPosition({
                    positionType: ErpPositionsKind.SEITENUMBRUCH,
                  })
                }
              >
                <ListItemIcon>
                  <ExpandIcon />
                </ListItemIcon>
                <ListItemText>{t('COMMON.PAGE_BREAK')}</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() =>
                  onAddPosition({
                    positionType: ErpPositionsKind.TITEL,
                  })
                }
              >
                <ListItemIcon>
                  <TitleIcon />
                </ListItemIcon>
                <ListItemText> {t('COMMON.TITLE')}</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() =>
                  onAddPosition({
                    positionType: ErpPositionsKind.TITELSUMME,
                  })
                }
              >
                <ListItemIcon>
                  <TitleSum />
                </ListItemIcon>
                <ListItemText> {t('COMMON.ERP.TITLE_TOTAL')}</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() =>
                  onAddPosition({
                    positionType: ErpPositionsKind.ZWISCHENSUMME,
                  })
                }
              >
                <ListItemIcon>
                  <FunctionsIcon />
                </ListItemIcon>
                <ListItemText> {t('COMMON.ERP.SUB_TOTAL')}</ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem disabled>{t('COMMON.PREVIEW')}</MenuItem>
              <MenuItem onClick={openSettings}>{t('MASK.SETTINGS')}</MenuItem>
            </>
          )}
        </MenuList>
      </MaskOverlayMenuWrapper>
    </React.Fragment>
  );
};
