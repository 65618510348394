import styles from './ReportPicker.module.scss';

import { Star } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data/lib/hooks/data-provider/useDataProvider';

import { Report } from '@work4all/models/lib/Classes/Report.entity';
import { BzObjType } from '@work4all/models/lib/Enums/BzObjType.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { FixedDataPicker } from '../fixed-data-picker/FixedDataPicker';
import { IPickerProps } from '../types';
import { Selection } from '../utils/selection-model';

export type IReportPickerProps<TMultiple extends boolean> = Omit<
  IPickerProps<Report, TMultiple> & {
    bzObjecType?: BzObjType;
  },
  'onListChanged'
>;

const mapToOption = (item: Report) => ({
  id: item.id,
  name: item.note,
});

export function ReportPicker<TMultiple extends boolean>(
  props: IReportPickerProps<TMultiple>
) {
  const { data, bzObjecType, value, onChange, ...rest } = props;

  const { options: reports, data: orginals } = useReportOptions(bzObjecType);

  const mappedValue = !value
    ? value
    : Array.isArray(value)
    ? value.map(mapToOption)
    : mapToOption(value);

  return (
    <FixedDataPicker
      dataSet={reports}
      {...rest}
      value={mappedValue as Selection<{ id: number; name: string }, TMultiple>}
      onChange={(value) => {
        if (Array.isArray(value)) {
          const ids = value.map((x) => x.id);
          const orginal: Report[] = orginals.filter((x) => ids.includes(x.id));
          onChange(orginal as Selection<Report, TMultiple>);
          return;
        }
        const single: Report = orginals.find((x) => x.id === value.id);
        onChange(single as Selection<Report, TMultiple>);
      }}
      renderItemContent={(option) => {
        const report = orginals.find((x) => x.id === option.id);
        return (
          <Box display="flex" justifyContent="space-between">
            <Typography className={styles.description}>
              {option.name}
            </Typography>
            {report.standardReport ? (
              <Star className={styles.favActive} />
            ) : null}
          </Box>
        );
      }}
    />
  );
}

export const useReportOptions = (bzObjType?: BzObjType) => {
  const requestData = useMemo(() => {
    return {
      entity: Entities.report,
      data: REPORT_FIELDS,
      filter: bzObjType
        ? [
            {
              bzObjType: { $eq: bzObjType },
            },
          ]
        : undefined,
    };
  }, [bzObjType]);

  const { data } = useDataProvider<Report>(requestData);

  const options = useMemo(() => {
    return data.map(mapToOption);
  }, [data]);

  return { options, data };
};

export const REPORT_FIELDS: Report = {
  id: null,
  bzObjType: null,
  reportFileName: null,
  note: null,
  standardReport: null,
};
