import { createContext } from 'react';

import { IFavoriteLinkOrFolder } from '../../../../containers/more/data/favorite-links/types';

export type LinksOverflowContextValue = {
  offset: number;
  items: IFavoriteLinkOrFolder[];
  activeLink: { folderId: string | null; linkId: string } | null;
};

export const LinksOverflowContext = createContext<LinksOverflowContextValue>({
  offset: 0,
  items: [],
  activeLink: null,
});
