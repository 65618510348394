import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components/lib/dataDisplay/basic-table';

import { ProductionContract } from '@work4all/models/lib/Classes/ProductionContract.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PersonalAndGeneralView } from '../filters/PersonalAndGeneralView';

import schema from './schemata/productionContract-table-schema.json';
import { Table } from './table/Table';
import { TableLayoutContext, useTableLayoutState } from './table-layout';
import { TableNoRowsPlaceholder } from './TableNoRowsPlaceholder';
import { useDataTable } from './use-data-table';
import { useDeleteEntitiesToolbarConfig } from './use-delete-entities-toolbar-config';
import { useUserColumnConfigs } from './use-user-column-configs';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  prefilter?: any;
}

const defaultSort = [
  { field: 'creationDate', direction: SortDirection.DESCENDING },
];
const manualGroupBy = true;

export const ProductionContractsTable = React.forwardRef<TableInstance, Props>(
  function ProductionContractsTable(props, ref) {
    const { prefilter } = props;
    const { entity: entityType } = schema as never;

    const tableStateBag = useTableStateBag();

    const layoutState = useTableLayoutState();
    const [layout] = layoutState;

    const {
      cardConfig,
      columnConfigs,
      prepareRowDisplayModifiers,
      data,
      fetchMore,
      total,
      pending,
      initialSortBy,
    } = useDataTable<ProductionContract, never>({
      layout,
      schema: schema as never,
      tableStateBag,
      prefilter,
      defaultSort,
    });

    const deleteConfig = useDeleteEntitiesToolbarConfig({
      entityType,
    });

    const [userConfig, userConfigMethods] = useUserColumnConfigs({
      layout,
      entityType,
      columnConfigs,
    });

    const { t } = useTranslation();

    if (!userConfig) return null;

    return (
      <TableLayoutContext value={layoutState}>
        <Table
          pending={pending}
          noRowsRenderer={() => <TableNoRowsPlaceholder />}
          layout={layout}
          ref={ref}
          cardConfig={cardConfig}
          actions={{
            remove: deleteConfig,
            resetColumns: userConfigMethods.remove,
            createWidget: true,
          }}
          areas={{
            left: {
              content: (
                <PersonalAndGeneralView entity={Entities.productionContract} />
              ),
              resizable: true,
              collapseConfig: {
                title: t('COMMON.FILTER'),
              },
            },
          }}
          columnConfigs={userConfig}
          manualGroupBy={manualGroupBy}
          initialSortBy={initialSortBy}
          loadMoreItems={fetchMore}
          prepareRowDisplayModifiers={prepareRowDisplayModifiers}
          data={data}
          total={total}
        />
      </TableLayoutContext>
    );
  }
);
