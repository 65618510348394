import styles from './ErrorFallback.module.scss';

import { ContentCopy } from '@mui/icons-material';
import { Button, Stack, Tooltip } from '@mui/material';
import * as ReactSentry from '@sentry/react';
import { useEffect, useMemo, useState } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { CopyButton } from '@work4all/components/lib/components/CopyButton/CopyButton';
import { Loading } from '@work4all/components/lib/components/Loading';

import { useUser } from '@work4all/data';

import { formatErrorDetails } from '@work4all/utils/lib/formatErrorDetails';
import { getDebugInfoParts } from '@work4all/utils/lib/getDebugInfoParts';
import { checkReloadState, reload } from '@work4all/utils/lib/lazy-loading';

import { useApiVersionContext } from '../api-version-context';

function ErrorFallbackError(props: FallbackProps) {
  const user = useUser();
  const { error } = props;
  const { t } = useTranslation();
  const [eventId, setEventId] = useState('');

  const customerNumber = user?.kundennummer;

  const { version } = useApiVersionContext();

  const debugInfoData = useMemo(() => {
    return getDebugInfoParts({ customerNumber, apiVersion: version });
  }, [customerNumber, version]);

  const errorDetails = formatErrorDetails(eventId, debugInfoData);

  useEffect(() => {
    setEventId(ReactSentry.captureException(error));
    // intentionally only report the error once per mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div role="alert" className={styles.ErrorFallback}>
      <p>{t('APP_CRASH_ALERT')}</p>
      {eventId && (
        <CopyButton
          value={errorDetails}
          timeout={5000}
          children={({ copy, copied }) => {
            return (
              <Stack direction={'row'} alignItems={'center'}>
                {eventId}
                <Tooltip
                  title={t('ERROR.COPYREF.BUTTON')}
                  leaveDelay={2500}
                  open={copied}
                >
                  <Button
                    endIcon={<ContentCopy />}
                    disabled={copied}
                    onClick={copy}
                  >
                    ({t('ERROR.COPYREF.BUTTON')})
                  </Button>
                </Tooltip>
              </Stack>
            );
          }}
        />
      )}
    </div>
  );
}

function ErrorFallbackLoading() {
  return <Loading />;
}

export function ErrorFallback(props: FallbackProps) {
  const { error } = props;

  const [showLoadingForChunkError, setShowLoadingForChunkError] =
    useState(true);

  // If we haven't tried to reload recently, reload the page. If cache control
  // headers are configured correctly, reloading should always load the latest
  // version of the app. But in case they aren't, we keep track of reload
  // attempts to avoid an infinite reload loop.
  useEffect(() => {
    if (isChunkError(error)) {
      const { canReload, isReloading } = checkReloadState();

      if (canReload) {
        reload();
      }

      if (!canReload && !isReloading) {
        setShowLoadingForChunkError(false);
      }
    }
  }, [error]);

  if (isChunkError(error) && showLoadingForChunkError) {
    return <ErrorFallbackLoading />;
  } else {
    return <ErrorFallbackError {...props} />;
  }
}

function isChunkError(error: Error) {
  return error.name === 'ChunkLoadError';
}
