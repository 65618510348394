import {
  HookedUserIcon,
  UserIconProps,
} from '@work4all/components/lib/components/user-icon/useUserIconRegister';

import { useCurrentBreakpoint } from '@work4all/data/lib/hooks/useCurrentBreakpoint';

import { settings, useSetting } from '../../../settings';
import { CALENDAR_CSS_BREAKPOINTS } from '../constants';
import { CalendarUserAppearance } from '../types';

export interface CalendarUserIconProps extends UserIconProps {
  type: 'user' | 'resource';
}

export function CalendarUserIcon(props: CalendarUserIconProps) {
  const { type, ...others } = props;

  const breakpoint = useCurrentBreakpoint({
    breakpoints: CALENDAR_CSS_BREAKPOINTS,
  });

  const setting = useSetting(settings.calendarUserAppearance({ breakpoint }));

  const appearance: UserIconProps['appearance'] =
    type === 'user' && setting.value === CalendarUserAppearance.Avatar
      ? 'avatar'
      : 'initials';

  return <HookedUserIcon {...others} appearance={appearance} />;
}
