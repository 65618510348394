import { IconButton, MenuItem } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as TimerIcon } from '@work4all/assets/icons/timer.svg';

import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';

import {
  ProjectTimeMask,
  ProjectTimeMaskInit,
} from '../../../../../../time-tracker/ProjectTimeMask';

interface Props {
  init: ProjectTimeMaskInit;
  amplitudeEntryPoint: string;
  menuItemMode?: boolean;
  beforeMaskOpen?: () => Promise<void>;
}

export const MaskOverlayTimeTrack = (props: Props) => {
  const {
    init,
    amplitudeEntryPoint,
    menuItemMode = false,
    beforeMaskOpen,
  } = props;
  const { go } = useHistoryStack();
  const { t } = useTranslation();

  const handleOnClick = useCallback(async () => {
    if (beforeMaskOpen) await beforeMaskOpen();

    go({
      view: (
        <ProjectTimeMask
          init={init}
          amplitudeEntryPoint={amplitudeEntryPoint}
        />
      ),
      title: t('TIME_TRACKER.PROJECT_TIME'),
    });
  }, [go, init, t, amplitudeEntryPoint, beforeMaskOpen]);

  if (menuItemMode) {
    return (
      <MenuItem onClick={handleOnClick}>{t('TICKET.TRACK_TIME')}</MenuItem>
    );
  }

  return (
    <IconButton color="primary" onClick={handleOnClick}>
      <TimerIcon />
    </IconButton>
  );
};
