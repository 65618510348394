import React, { MutableRefObject, useCallback, useRef } from 'react';

type TReturn = {
  onMouseDown: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseLeave: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseUp: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;

  onTouchStart: (e: React.TouchEvent<HTMLDivElement>) => void;
  onTouchMove: (e: React.TouchEvent) => void;
  onTouchEnd: (e: React.TouchEvent<HTMLDivElement>) => void;
};

export interface UseLongPressOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (event: any) => void;
  onLongPress: () => void;

  /**
   * @default 300
   */
  delay?: number;
}

const IGNORED_ELEMENTS_SELECTOR = 'button';

const useLongPress = (options?: UseLongPressOptions): TReturn => {
  const { onClick, onLongPress, delay = 300 } = options;

  const timeoutRef: MutableRefObject<number | null> = useRef(null);

  const teardown = useCallback(() => {
    if (timeoutRef.current !== null) {
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    stateRef.current.active = false;
  }, []);

  const setup = useCallback(() => {
    if (timeoutRef.current !== null) {
      window.clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = window.setTimeout(() => {
      if (stateRef.current.active) {
        onLongPress();
      }

      stateRef.current.finished = true;
      timeoutRef.current = null;
    }, delay);

    stateRef.current.active = true;
    stateRef.current.finished = false;
  }, [delay, onLongPress]);

  const stateRef = useRef({
    active: false,
    finished: false,
  });

  const onMouseDown = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if ((event.target as HTMLElement).closest(IGNORED_ELEMENTS_SELECTOR)) {
        return;
      }

      // Ignore mouse events if it's not a left click.
      if (event.button !== 0) {
        return;
      }

      setup();
    },
    [setup]
  );

  const onMouseLeave = useCallback(() => {
    teardown();
  }, [teardown]);

  const onMouseUp = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!stateRef.current.active || stateRef.current.finished) {
        return;
      }

      // Ignore mouse events if it's not a left click.
      if (event.button !== 0) {
        return;
      }

      teardown();

      onClick(event);
    },
    [teardown, onClick]
  );

  const onTouchStart = useCallback(
    (event: React.TouchEvent<HTMLDivElement>) => {
      if ((event.target as HTMLElement).closest(IGNORED_ELEMENTS_SELECTOR)) {
        return;
      }

      if (event.touches.length !== 1) {
        teardown();
        return;
      }

      setup();
    },
    [teardown, setup]
  );

  const onTouchMove = useCallback(() => {
    teardown();
  }, [teardown]);

  const onTouchEnd = useCallback(
    (event: React.TouchEvent<HTMLDivElement>) => {
      if (stateRef.current.active && stateRef.current.finished) {
        if (event.cancelable) {
          event.preventDefault();
        }
      }

      teardown();
    },
    [teardown]
  );

  return {
    onMouseDown,
    onMouseLeave,
    onMouseUp,

    onTouchStart,
    onTouchMove,
    onTouchEnd,
  };
};

export default useLongPress;
