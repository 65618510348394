import { Capacitor } from '@capacitor/core';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useNavigate } from '@work4all/data/lib/hooks';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';
import { useSearchHistory } from '@work4all/data/lib/hooks/use-search-history';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

interface OpenFileIconButtonProps {
  data: Customer | Supplier | Project | undefined;
}

export const OpenFileIconButton = ({ data }: OpenFileIconButtonProps) => {
  const location = useLocation();
  const { activeTenant } = useTenant();
  const { t } = useTranslation();
  const { saveSearchItem } = useSearchHistory();
  const navigate = useNavigate();

  if (!data) {
    return null;
  }

  const fileType = getFileType(data.__typename);
  const filePath = `${fileType}s/${data.id}`;
  const isFileAlreadyOpend = location.pathname.includes(filePath);

  const handleFileOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    if (Capacitor.getPlatform() === 'web') {
      window.open(`/t-${activeTenant}/${filePath}`, '_blank');
    } else {
      if (data?.id) {
        saveSearchItem(fileType as Entities, {
          id: data.id.toString(),
          number: data.number?.toString(),
          name: data.name,
        });
      }
      navigate(`/${filePath}`);
    }
  };

  if (isFileAlreadyOpend) {
    return null;
  }

  return (
    <Tooltip
      title={t(`OPEN.FILE.${fileType.toLocaleUpperCase()}`)}
      placement="top"
    >
      <IconButton sx={{ p: 0 }} onClick={handleFileOpen}>
        <OpenInNewIcon color="primary" />
      </IconButton>
    </Tooltip>
  );
};

const getFileType = (typename: string) => {
  if (typename === 'Kunde') {
    return 'customer';
  }

  if (typename === 'Lieferant') {
    return 'supplier';
  }

  if (typename === 'Projekt') {
    return 'project';
  }
};
