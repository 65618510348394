import styles from './../../MaskOverlay.module.scss';

import * as ReactSentry from '@sentry/react';
import { cloneDeep, isEqual } from 'lodash';
import { SnackbarKey, useSnackbar } from 'notistack';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCustomFieldsConfig } from '@work4all/data/lib/custom-fields';
import { useDataProvider } from '@work4all/data/lib/hooks/data-provider';
import {
  ITempFileManagerContext,
  TempFileManagerContext,
  useTempFileManager,
} from '@work4all/data/lib/hooks/data-provider/useTempFileManager';
import { usePermissions } from '@work4all/data/lib/hooks/use-permissions';
import { useEntityJsonSchema } from '@work4all/data/lib/json-schema/EntityJsonSchemasContext';

import { BankDetails } from '@work4all/models/lib/Classes/BankDetails.entity';
import { BusinessPartnerUnion } from '@work4all/models/lib/Classes/BusinessPartnerUnion.entity';
import { Currency } from '@work4all/models/lib/Classes/Currency.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { DeliveryKind } from '@work4all/models/lib/Classes/DeliveryKind.entity';
import { InboundDeliveryNote } from '@work4all/models/lib/Classes/InboundDeliveryNote.entity';
import { InputErpAnhangAttachementsRelation } from '@work4all/models/lib/Classes/InputErpAnhangAttachementsRelation.entity';
import { Invoice } from '@work4all/models/lib/Classes/Invoice.entity';
import { Offer } from '@work4all/models/lib/Classes/Offer.entity';
import { PaymentKind } from '@work4all/models/lib/Classes/PaymentKind.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { useJSONSchemaResolver } from '@work4all/utils/lib/form-utils/jsonSchemaResolver';

import { usePageTitle } from '../../../../../hooks';
import useAttachementsRelation from '../../../../../hooks/useAttachementsRelation';
import { MaskTabContext } from '../../../mask-tabs/MaskTabContext';
import { normalizeCustomFields } from '../../components/custom-fields/normalize-custom-fields';
import { prepareInputWithCustomFields } from '../../components/custom-fields/prepare-input-with-custom-fields';
import { Form } from '../../components/form';
import { LockOverride } from '../../components/LockOverride';
import { MaskOverlayHeader } from '../../components/MaskOverlayHeader/MaskOverlayHeader';
import {
  MaskContextProvider,
  useMaskConfig,
  useMaskContext,
  useMaskContextValue,
} from '../../hooks/mask-context';
import { useConfirmBeforeCloseMask } from '../../hooks/use-confrm-before-close-mask';
import { useMaskLock } from '../../hooks/use-mask-lock';
import {
  normalizeFormValue,
  useExtendedForm,
} from '../../hooks/useExtendedFormContext';
import { MaskControllerProps } from '../../types';
import { pickUpdateFields } from '../../utils/pick-update-fields';
import { parseTemplate } from '../../utils/use-assignable-template-entity';
import { useFormUpdate } from '../../utils/use-form-update';

import {
  ErpInitialView,
  useErpInitialView,
} from './components/initial-view/ErpInitialView';
import { SignatureProvider } from './components/signature/signature-provider';
import { ErpTabPanels } from './components/tab-panels/ErpTabPanels';
import { ErpTabs } from './components/tab-panels/ErpTabs';
import { ErpDataIntersection as ErpData } from './ErpData';
import { ERPMaskHeaderActions } from './ERPMaskHeaderActions';
import { getRequiredEntity } from './getRequiredEntity';
import {
  useShadowBzObjectApi,
  ValidationErrors,
} from './hooks/use-bz-shadow-object-api';
import { ShadowBzObjectApiProvider } from './hooks/use-bz-shadow-object-api/use-shadow-bz-object-api-context';
import { createResponse } from './hooks/use-bz-shadow-object-api/use-shadow-bz-object-graphql';
import { useTransaction } from './sentry/hooks/use-transaction';
import { useERPOverlayControllerTabState } from './use-erp-overlay-controller-tab-state';

const SIGNED_INT_MAX_VALUE = 2147483647;

const ERPOverlayControllerInternal = (props: MaskControllerProps) => {
  const { t } = useTranslation();
  const mask = useMaskConfig(props);

  const lock = useMaskLock(mask);

  const shouldUseRegularQuery = !lock.isLoading && lock.isLocked;
  const shouldUseShadowObjectApi = !lock.isLoading && !lock.isLocked;

  const template = parseTemplate(mask);

  const initialProps = useErpInitialView(
    !template && !mask.id,
    props.onAfterSave
  );

  const customFields = useCustomFieldsConfig({ entity: mask.entity });

  const query = useDataProvider(
    useMemo(() => {
      const fields = createResponse(mask.entity);
      const data = {
        id: null,
        ...fields.data[mask.entity],
      };

      return {
        skip: !shouldUseRegularQuery,
        filter: [{ id: { $eq: mask.id } }],
        entity: mask.entity,
        data,
      };
    }, [mask.entity, mask.id, shouldUseRegularQuery])
  );

  const [shadowBzObject, shadowBzObjectApi, validationErrors] =
    useShadowBzObjectApi({
      entity: mask.entity,
      id: mask.id,
      parentEntity: template?.businessPartnerType
        ? template?.businessPartnerType
        : initialProps.businessPartner?.__typename === 'Kunde'
        ? Entities.customer
        : Entities.supplier,
      parentId: template?.businessPartnerId || initialProps.businessPartner?.id,
      contactId: template?.entity === Entities.contact ? template?.id : null,
      skip:
        !shouldUseShadowObjectApi ||
        (mask.isCreateMode && !template && !initialProps.businessPartner),
    });

  const data =
    (shouldUseRegularQuery
      ? query.data[0]
      : shouldUseShadowObjectApi
      ? shadowBzObject?.data
      : null) ?? null;

  const loading = shouldUseRegularQuery
    ? query.loading
    : shouldUseShadowObjectApi
    ? shadowBzObjectApi.loading
    : false;

  useTransaction({
    name: 'ERP Mask - Open',
    eventInProgress: loading,
  });

  const normalizedData = useMemo(() => {
    return normalizeCustomFields(normalizeFormValue(data), customFields);
  }, [data, customFields]);

  const { isDirty } = shadowBzObjectApi;

  useConfirmBeforeCloseMask(isDirty);

  const [mutateError, setMutateError] = useState<string | null>(null);

  const clearError = useCallback(() => setMutateError(null), []);

  usePageTitle(t(`COMMON.${mask.entity.toUpperCase()}`), {
    priority: 1,
  });

  const files = useMemo(() => {
    return shadowBzObject?.data.erpAttachmentList?.map((x) => {
      return {
        ...x,
        fileName: x.fileInfos?.fileEntityFilename,
        lastModificationDate: x.updateTime,
      };
    });
  }, [shadowBzObject?.data.erpAttachmentList]);
  const tempFileManager = useTempFileManager(files);

  const attachments =
    useAttachementsRelation<InputErpAnhangAttachementsRelation>(
      tempFileManager,
      Entities.erpAttachment,
      'id'
    );

  const handleSubmit = useCallback(async () => {
    await shadowBzObjectApi.persist(attachments?.attachements);
    // TODO This doesn't correctly implement the "save and close" functionality
    // because it doesn't pass the saved data to the `onAfterSave` callback.
    // There is no easy way to get around this, because the `id` property (which
    // is the only one that truly matters) is not included in the requested
    // GraphQL fields.
    //
    // This functionality for this mask is not used anywhere at the moment, but
    // could be required in the future. In which case we would need to find a
    // way to resolve this issue.
    //
    // The reason for not including the `id` field is not requested is to avoid
    // updating the original entity stored in the Apollo's cache when a shadow
    // object is updated, since they have the same `__typename` and `id`.
    //
    // One way to resolve this issue is to only request the `id` field in the
    // persist mutation and remove it from all other mutations.
    props.onAfterSave?.(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shadowBzObjectApi.persist, props.onAfterSave, attachments?.attachements]);

  const shadowBzObjectApiWithAttachements = useMemo(() => {
    const attachementsDirty = attachments?.attachements
      ? Object.entries(attachments?.attachements)
          .filter((x) => x[1])
          .some((x) => x[1].length)
      : false;
    const computedIsDirty = attachementsDirty || isDirty;
    return {
      ...shadowBzObjectApi,
      isDirty: computedIsDirty,
      persist: async () =>
        await shadowBzObjectApi.persist(attachments?.attachements),
    };
  }, [attachments?.attachements, isDirty, shadowBzObjectApi]);

  const maskContext = useMaskContextValue({ ...mask, data, customFields });

  const requiredEntity = getRequiredEntity(mask.entity);
  if (!data && !loading) {
    return <ErpInitialView {...initialProps} entity={requiredEntity} />;
  }

  return (
    <ShadowBzObjectApiProvider value={shadowBzObjectApiWithAttachements}>
      <MaskContextProvider value={maskContext}>
        <LockOverride forceLock={loading}>
          <ERPForm
            data={normalizedData}
            isLoading={loading}
            isDirty={shadowBzObjectApiWithAttachements.isDirty}
            error={mutateError}
            validationErrors={validationErrors}
            onClearError={clearError}
            onModify={shadowBzObjectApi.modify}
            onSubmit={handleSubmit}
            entity={mask.entity}
            tempFileManager={tempFileManager}
          />
        </LockOverride>
      </MaskContextProvider>
    </ShadowBzObjectApiProvider>
  );
};

interface ERPFormProps {
  data: ErpData;
  isLoading: boolean;
  isDirty: boolean;
  error: string | null;
  entity: Entities;
  onClearError: () => void;
  onModify: (data: ErpData) => void;
  onSubmit: () => Promise<void>;
  tempFileManager: ITempFileManagerContext;
  validationErrors: ValidationErrors;
}

const ERPForm = memo(function ERPForm(props: ERPFormProps) {
  const {
    data,
    isLoading,
    isDirty,
    error,
    entity,
    onClearError,
    onModify,
    onSubmit,
    tempFileManager,
    validationErrors,
  } = props;

  const { t } = useTranslation();
  const mask = useMaskContext();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    let id: SnackbarKey;
    if (error) {
      id = enqueueSnackbar(error, { onClose: onClearError });
    }
    return () => {
      closeSnackbar(id);
    };
  }, [closeSnackbar, enqueueSnackbar, error, onClearError]);

  const schema = useEntityJsonSchema(entity);
  const customRules = useCallback(
    (input: ErpData) => {
      const errors: Partial<
        Record<
          keyof ErpData,
          {
            message: string;
            type: string;
          }
        >
      > = {};

      if (!input.note) {
        errors.note = {
          message: t('ERROR.FIELD_REQUIRED'),
          type: 'customValidation',
        };
      }
      if (!input.businessPartnerId) {
        errors['businessPartnerContactCombined.businessPartner'] = {
          message: t('ERROR.FIELD_REQUIRED'),
          type: 'customValidation',
        };
      }
      if (mask.entity === Entities.contract) {
        if (input.contractNumber > SIGNED_INT_MAX_VALUE) {
          errors['contractNumber'] = {
            message: t('ERROR.TOO_BIG_NUMBER', {
              value: SIGNED_INT_MAX_VALUE + 1,
            }),
            type: 'customValidation',
          };
        }
      } else {
        if (input.number > SIGNED_INT_MAX_VALUE) {
          errors['number'] = {
            message: t('ERROR.TOO_BIG_NUMBER', {
              value: SIGNED_INT_MAX_VALUE + 1,
            }),
            type: 'customValidation',
          };
        }
      }

      if (Object.entries(errors).length) {
        return errors;
      }

      return true;
    },
    [t, mask]
  );

  // TODO: workaround - maybe there is possiblity to fix it on API level
  const optionalNumberSchema = useMemo(() => {
    const clone = cloneDeep(schema);
    if (!clone) return schema;
    if (clone.properties.number)
      clone.properties.number.type = ['number', 'null'];
    if (clone.properties.deliveryNoteNumber)
      clone.properties.deliveryNoteNumber.type = ['string', 'null'];
    return clone;
  }, [schema]);
  const resolver = useJSONSchemaResolver(optionalNumberSchema, customRules);

  const form = useForm<ErpData>({
    resolver,
    mode: 'onChange',
    defaultValues: normalizeFormValue(data),
    context: {
      schema,
    },
  });

  const formValues = form.watch();

  const { dirtyFields } = form.formState;
  useEffect(() => {
    validationErrors.forEach((x) => {
      let property: keyof ErpData = x.property;
      if (x.property === 'number' && mask.entity === Entities.contract)
        property = 'contractNumber';

      form.setError(property, {
        message: t(`ERROR.${x.code}`),
        type: 'apiValidation',
      });
    });
  }, [t, validationErrors, form, mask]);

  const {
    formState: { isSubmitting, errors },
    handleSubmit,
    getValues,
  } = useExtendedForm<ErpData>(data, form);

  useFormUpdate<ErpData>(
    {
      paymentKind: (paymentKind: PaymentKind) => ({
        paymentId: paymentKind?.id ?? 0,
      }),
      currency: (currency: Currency) => ({ currencyId: currency?.id ?? 0 }),
      user: (user) => ({ userId: user?.id ?? 0 }),
      user2: (user2) => ({ user2Id: user2?.id ?? 0 }),
      project: (project) => {
        const projectId = project?.id ?? 0;
        const businessPartnerField = getValues(
          'businessPartnerContactCombined.businessPartner'
        );

        if (businessPartnerField !== null) {
          return { projectId };
        } else {
          const businessPartner =
            project?.customer ?? project?.supplier ?? null;

          return {
            projectId,
            'businessPartnerContactCombined.businessPartner': businessPartner,
          };
        }
      },
      deliveryKind: (deliveryKind: DeliveryKind) => ({
        deliveryKindId: deliveryKind?.id ?? 0,
      }),
      partialInvoiceLogic: (partialInvoiceLogic) => ({
        partialInvoiceLogicId: partialInvoiceLogic?.id ?? 0,
      }),
      bankDetails: (bankDetails: BankDetails) => ({
        bankDetailsId: bankDetails?.id ?? 0,
      }),
      'businessPartnerContactCombined.businessPartner': (
        businessPartner: Customer | Supplier
      ) => {
        return {
          businessPartnerType: getBusinessPartnerType(businessPartner),
          businessPartnerId: businessPartner?.id ?? 0,
          bankDetailsId: 0,
          bankDetails: null,
          contactId: businessPartner?.mainContactId || 0,
        };
      },
      'businessPartnerContactCombined.contact': (contact) => ({
        contactId: contact?.id ?? 0,
      }),
      'additionalAddress1.businessPartner': (
        businessPartner: BusinessPartnerUnion
      ) => {
        const mainContactId = businessPartner?.mainContactId;
        return {
          additionalAddress1CompanyType:
            getBusinessPartnerType(businessPartner),
          additionalAddress1CompanyId: businessPartner?.id ?? 0,
          additionalAddress1ContactId: mainContactId || 0,
        };
      },
      'additionalAddress1.contact': (contact) => ({
        additionalAddress1ContactId: contact?.id ?? 0,
      }),
      'additionalAddress2.businessPartner': (
        businessPartner: BusinessPartnerUnion
      ) => {
        const mainContactId = businessPartner?.mainContactId;
        return {
          additionalAddress2CompanyType:
            getBusinessPartnerType(businessPartner),
          additionalAddress2CompanyId: businessPartner?.id ?? 0,
          additionalAddress2ContactId: mainContactId || 0,
        };
      },
      'additionalAddress2.contact': (contact) => ({
        additionalAddress2ContactId: contact?.id ?? 0,
      }),
      'additionalAddress3.businessPartner': (
        businessPartner: BusinessPartnerUnion
      ) => {
        const mainContactId = businessPartner?.mainContactId;
        return {
          additionalAddress3CompanyType:
            getBusinessPartnerType(businessPartner),
          additionalAddress3CompanyId: businessPartner?.id ?? 0,
          additionalAddress3ContactId: mainContactId || 0,
        };
      },
      'additionalAddress3.contact': (contact) => ({
        additionalAddress3ContactId: contact?.id ?? 0,
      }),
    },
    form,
    [data]
  );

  const hasError = Object.entries(errors).length > 0;
  // Form value is mutable, so we need to clone it here to compare changes
  // later.
  const lastFormValues = useRef<ErpData>(null);

  const handleFormChange = useCallback(() => {
    if (
      !isEqual(formValues, lastFormValues.current) &&
      formValues.businessPartnerId &&
      Object.keys(dirtyFields).length > 0
    ) {
      const { __typename, ...input } = formValues;
      if (!(input as InboundDeliveryNote).deliveryDate)
        (input as InboundDeliveryNote).deliveryDate = null;
      if (!(input as Offer).dispositionStart)
        (input as Offer).dispositionStart = null;
      if (!(input as Offer).dispositionEnd)
        (input as Offer).dispositionEnd = null;
      if (!(input as Offer).serviceStartDate)
        (input as Offer).serviceStartDate = null;
      if (!(input as Offer).serviceEndDate)
        (input as Offer).serviceEndDate = null;
      if (!(input as Offer).contractStartDate)
        (input as Offer).contractStartDate = null;
      if (!(input as Invoice).paid) (input as Invoice).paid = undefined;
      if (!(input as Invoice).ra) (input as Invoice).ra = undefined;
      if (!(input as Offer).outgoingDeliveryDate)
        (input as Offer).outgoingDeliveryDate = null;

      const toModify = pickUpdateFields(input, dirtyFields);
      const toModifyPrepared = prepareInputWithCustomFields(toModify);

      if (toModify.number > SIGNED_INT_MAX_VALUE) return;
      onModify(toModifyPrepared);
    }

    lastFormValues.current = cloneDeep(formValues);
  }, [dirtyFields, formValues, onModify]);

  useEffect(handleFormChange, [handleFormChange, formValues]);

  const { untypedPermissions } = usePermissions();

  const title =
    mask.entity === Entities.invoice && (data as Offer)?.value < 0
      ? t('COMMON.CREDIT_NOTE')
      : undefined;

  const shouldRenderIndividualTab =
    mask.customFields && mask.customFields.length > 0;

  const [tab, setTab] = useERPOverlayControllerTabState(
    mask.params?.tab ?? 'miscellaneous'
  );

  return (
    <FormProvider {...form}>
      <MaskTabContext value={tab} onChange={setTab}>
        <Form onSubmit={handleSubmit(onSubmit)} className={styles.maskForm}>
          <SignatureProvider>
            <TempFileManagerContext.Provider value={tempFileManager}>
              <MaskOverlayHeader
                isLoading={isLoading}
                title={title || t(`COMMON.${mask.entity.toUpperCase()}`)}
                subTitle={data.note}
                actions={
                  <ERPMaskHeaderActions
                    isSubmitting={isSubmitting}
                    isDirty={isDirty}
                    hasError={hasError}
                    hasRightToSave={
                      mask.isCreateMode
                        ? untypedPermissions(mask.entity).canAdd()
                        : untypedPermissions(mask.entity).canEdit(data)
                    }
                  />
                }
                tabs={
                  <ErpTabs
                    isCreateMode={mask.isCreateMode}
                    renderIndividualTab={shouldRenderIndividualTab}
                    isLoading={isLoading}
                  />
                }
              />

              <ErpTabPanels
                isCreateMode={mask.isCreateMode}
                renderIndividualTab={shouldRenderIndividualTab}
              />
            </TempFileManagerContext.Provider>
          </SignatureProvider>
        </Form>
      </MaskTabContext>
    </FormProvider>
  );
});

function getBusinessPartnerType(
  businessPartner: Customer | Supplier
): SdObjType {
  if (businessPartner) {
    const typename: string = businessPartner.__typename;

    if (typename === 'Kunde') {
      return SdObjType.KUNDE;
    }
  }

  return SdObjType.LIEFERANT;
}

export const ERPOverlayController = ReactSentry.withProfiler(
  ERPOverlayControllerInternal
);
