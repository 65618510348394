import { FieldValues, FormState } from 'react-hook-form';

export function useFormStatus<TFieldValues extends FieldValues>(
  formState: FormState<TFieldValues>
) {
  return {
    isSubmitting: formState.isSubmitting,
    isDirty: Object.keys(formState.dirtyFields).length > 0,
    isValid: Object.keys(formState.errors).length === 0,
  };
}
