import React, { useCallback, useEffect, useRef } from 'react';
import {
  InfiniteLoader as ReactWindowInfiniteLoader,
  InfiniteLoaderChildProps,
} from 'react-virtualized';

import { TableMode, TableRow } from '../../../types';

export interface IInfiniteLoader {
  isItemLoaded?: (index: number) => boolean;
  loadMoreItems?: (
    startIndex: number,
    stopIndex: number
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => Promise<any> | null | undefined;
  itemCount?: number;
  children: (props: InfiniteLoaderChildProps) => React.ReactNode;
  threshold?: number;
  minimumBatchSize?: number;
  mode: TableMode;
  rows: TableRow[];
}

export const InfiniteLoader: React.FC<IInfiniteLoader> = (props) => {
  const ref = useRef<ReactWindowInfiniteLoader>();

  // Force InfiniteLoader to re-check loaded status after the rows change
  useEffect(() => {
    ref.current?.resetLoadMoreRowsCache(true);
  }, [ref, props.rows]);

  const debouncedLoadMore = useDebouncedFn(props.loadMoreItems, 300);

  if (props.mode === 'client') {
    return (
      <>
        {props.children({
          onRowsRendered: undefined,
          registerChild: undefined,
        })}
      </>
    );
  }

  return (
    <ReactWindowInfiniteLoader
      ref={ref}
      minimumBatchSize={props.minimumBatchSize ?? 20}
      threshold={props.threshold ?? 10}
      isRowLoaded={({ index }) => props.isItemLoaded(index)}
      rowCount={typeof props.itemCount === 'number' ? props.itemCount : 4000}
      loadMoreRows={({ startIndex, stopIndex }) => {
        debouncedLoadMore(startIndex, stopIndex);
        // This function is supposed to return a promise to let <InfiniteLoader>
        // know when the new items have loaded, so it can re-render the list.
        // But with the current implementation not every call to `loadMoreItems`
        // will actually trigger a fetch. And when there is a fetch the list will
        // re-render anyway because the resulting `data` array will change.
        // This is simply here to avoid typing issues.
        return undefined;
      }}
    >
      {props.children}
    </ReactWindowInfiniteLoader>
  );
};

function useDebouncedFn<T extends (...args: never[]) => unknown>(
  func: T,
  timeout: number
): (...args: Parameters<T>) => void {
  const funcRef = useRef<typeof func>();

  useEffect(() => {
    funcRef.current = func;
  });

  const timeoutRef = useRef<number | null>(null);

  const debounced = useCallback(
    function debounced(...args: Parameters<T>) {
      const later = () => {
        funcRef.current.call(this, ...args);
      };

      if (timeoutRef.current) {
        window.clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = window.setTimeout(later, timeout);
    },
    [timeout]
  );

  return debounced;
}
