import { gql, useMutation } from '@apollo/client';

const DELETE_MAIL = gql`
  mutation DeleteMail(
    $mailboxId: String!
    $mailId: String!
    $mailFolderId: String!
  ) {
    deleteMail(
      mailboxId: $mailboxId
      mailId: $mailId
      mailFolderId: $mailFolderId
    )
  }
`;

type DeleteMailResponse = {
  deleteMail: boolean;
};

type DeleteMailVars = {
  mailboxId: string;
  mailFolderId: string;
  mailId: string;
};

export function useDeleteMailMutation() {
  return useMutation<DeleteMailResponse, DeleteMailVars>(DELETE_MAIL);
}
