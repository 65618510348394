import { FileIcon } from '../../../../components/file-icon/FileIcon';

export type IFileIconProps = {
  value: string;
};

export const FileIconCell = (props: IFileIconProps) => {
  const fileType = props.value?.split('.')?.pop() || props.value;
  return <FileIcon fileType={fileType ? fileType : 'BIN'} />;
};
