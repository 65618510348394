import { Entities } from '../Enums/Entities.enum';

export const entityTypeLookup = {
  [Entities.offer]: 'Angebot',
  [Entities.eMail]: 'EMail',
  [Entities.appointment]: 'Termin',
  [Entities.article]: 'Artikel',
  [Entities.calculation]: 'Kalkulation',
  [Entities.callMemo]: 'Telefonat',
  [Entities.checkList]: 'Checkliste',
  [Entities.contact]: 'Ansprechpartner',
  [Entities.contract]: 'Auftrag',
  [Entities.customer]: 'Kunde',
  [Entities.deliveryNote]: 'Lieferschein',
  [Entities.demand]: 'Bedarfsanforderung',
  [Entities.document]: 'Dokument',
  [Entities.inboundInvoice]: 'Eingangsrechnung',
  [Entities.inboundDeliveryNote]: 'Eingangslieferschein',
  [Entities.invoice]: 'Rechnung',
  [Entities.letter]: 'Brief',
  [Entities.note]: 'Notiz',
  [Entities.order]: 'Bestellung',
  [Entities.project]: 'Projekt',
  [Entities.productionContract]: 'Produktionsauftrag',
  [Entities.visitationReport]: 'Besuchsbericht',
  [Entities.supplier]: 'Lieferant',
  [Entities.task]: 'Aufgabe',
  [Entities.ticket]: 'Ticket',
  [Entities.user]: 'Benutzer',
  [Entities.unit]: 'Einheit',
  [Entities.wordLetterTemplate]: 'WordLetterTemplate',
  [Entities.wordDocumentTemplate]: 'WordDocumentTemplate',
  [Entities.travelExpenses]: 'Reisekostenabrechnung',
  [Entities.eMailTemplate]: 'EMailVorlage',
  [Entities.chronoFileItem]: 'ChronoFileItem',
  [Entities.eMailSignature]: 'EMailSignatur',
  [Entities.textBuildingBlock]: 'Textbaustein',
  [Entities.salesOpportunities]: 'Verkaufschance',
};
