import { useEffect, useState } from 'react';

export const useReleaseNotesFile = () => {
  const version = process.env.APP_VERSION ?? '';
  const [releaseNotesFile, setReleaseNotesFile] = useState(null);

  useEffect(() => {
    const loadMarkdown = async () => {
      try {
        // Dynamically import the release notes file based on latest version of the app
        const { default: markdown } = await import(
          `../../release-notes/v${version}.md`
        );
        setReleaseNotesFile(markdown);
      } catch (error) {
        console.error('Error loading release notes file:', error);
      }
    };
    loadMarkdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { releaseNotesFile, version };
};
