import { gql, useMutation } from '@apollo/client';

import { prepareResponse } from '@work4all/data';

import { MailboxContent } from '@work4all/models/lib/Classes/MailboxContent.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ObjectType } from '@work4all/models/lib/Enums/ObjectType.enum';

import { ASSIGN_MAIL_RESPONSE_FIELDS } from './use-assign-mail-mutation';

const ASSIGN_MAIL_RESPONSE_GQL = prepareResponse(
  Entities.mailboxContent,
  ASSIGN_MAIL_RESPONSE_FIELDS
);

const ASSIGN_MAIL_TO_OBJECT = gql`
mutation AssignMailToObject(
  $mailboxId: String!
  $mailFolderId: String!
  $mailId: String!
  $objectType: ObjectType!
  $objectKey: PrimaryKey!
) {
  assignMailToObject(
    mailboxId: $mailboxId
    mailFolderId: $mailFolderId
    mailId: $mailId
    objectType: $objectType
    objectKey: $objectKey
  ) {
    ${ASSIGN_MAIL_RESPONSE_GQL}
  }
}
`;

type AssignMailToObjectResponse = {
  assignMailToObject: MailboxContent;
};

type AssignMailToObjectVars = {
  mailboxId: string;
  mailFolderId: string;
  mailId: string;
  objectType: ObjectType;
  objectKey: string | number;
};

export function useAssignMailToObjectMutation() {
  return useMutation<AssignMailToObjectResponse, AssignMailToObjectVars>(
    ASSIGN_MAIL_TO_OBJECT,
    { context: { singleBatch: true } }
  );
}
