import { NavigationRoute } from '@work4all/components/lib/navigation/navigation-wrapper/NavigationWrapper';

import {
  IFavoriteLink,
  IFavoriteLinkOrFolder,
  IFavoriteLinksFolder,
  isFavoriteLink,
} from '../../containers/more/data/favorite-links';

export type IActiveStaticRoute = { pathname: string };
export type IActiveFavoriteLink = { folderId: string | null; linkId: string };
export type IActiveRoute = IActiveStaticRoute | IActiveFavoriteLink;

export function isActiveStaticRotue(
  activeRoute: IActiveRoute
): activeRoute is IActiveStaticRoute {
  return activeRoute !== null && 'pathname' in activeRoute;
}

export function isActiveFavoriteLink(
  activeRoute: IActiveRoute
): activeRoute is IActiveFavoriteLink {
  return activeRoute !== null && 'linkId' in activeRoute;
}

export function findActiveRoute(
  links: IFavoriteLinkOrFolder[],
  pathname: string,
  navigationConfig: NavigationRoute[]
): IActiveRoute | null {
  // Math all favorited links first
  for (const item of links) {
    const match = matchFavoriteLinkOrFolder(item, pathname);

    if (match) {
      return match;
    }
  }

  // Match links with 'manualMatch' from the navigation config
  for (const link of navigationConfig.filter((link) => link.manualMatch)) {
    if (pathname.startsWith(`${link.pathname}`)) {
      return { pathname: link.pathname };
    }
  }

  return null;
}

function matchFavoriteLink(
  link: IFavoriteLink,
  pathname: string
): IActiveFavoriteLink | null {
  return pathname.startsWith(link.href)
    ? { folderId: null, linkId: link.id }
    : null;
}

function matchFavoriteLinksFolder(
  folder: IFavoriteLinksFolder,
  pathname: string
): IActiveFavoriteLink | null {
  for (const link of folder.links) {
    if (matchFavoriteLink(link, pathname)) {
      return { folderId: folder.id, linkId: link.id };
    }
  }

  return null;
}

function matchFavoriteLinkOrFolder(
  item: IFavoriteLinkOrFolder,
  pathname: string
): IActiveFavoriteLink | null {
  if (isFavoriteLink(item)) {
    return matchFavoriteLink(item, pathname);
  } else {
    return matchFavoriteLinksFolder(item, pathname);
  }
}
