import { useMemo } from 'react';

import { useHiddenEntities } from '@work4all/data';

import { FixedDataPicker } from '../fixed-data-picker/FixedDataPicker';
import { IPickerProps } from '../types';

import { useObjectTypeLookUp } from './useObjectTypeLookUp';

export type IObjectTypePickerProps<TMultiple extends boolean> = IPickerProps<
  { id: string; name: string },
  TMultiple
>;

export function ObjectTypePicker<TMultiple extends boolean>(
  props: IObjectTypePickerProps<TMultiple>
) {
  const { ...rest } = props;
  const { isHidden } = useHiddenEntities();

  const lookUp = useObjectTypeLookUp({ general: true });
  const dataSet = useMemo(() => {
    return Object.entries(lookUp)
      .filter((x) => !isHidden(x[1].entity))
      .map((x) => ({
        id: x[0],
        name: x[1].label,
      }));
  }, [isHidden, lookUp]);

  return <FixedDataPicker dataSet={dataSet} {...rest} />;
}
