import { useMemo } from 'react';

import {
  FixedDataPicker,
  IEnumPickerProps,
} from '@work4all/components/lib/components/entity-picker/fixed-data-picker/FixedDataPicker';

import { MailboxTypeOption } from './MailboxTypeOption';
import { MailboxType } from './types';

type BoxedMailboxType = { id: MailboxType; name: string };

const OPTIONS: BoxedMailboxType[] = [
  { id: 'microsoft', name: 'Microsoft' },
  { id: 'manual', name: 'Manual setup' },
];

export interface MailboxTypePickerProps
  extends Omit<
    IEnumPickerProps<false>,
    'multiple' | 'value' | 'onChange' | 'dataSet'
  > {
  value: MailboxType;
  onChange: (value: MailboxType) => void;
}

export function MailboxTypePicker(props: MailboxTypePickerProps) {
  const { value, onChange, ...others } = props;

  const valueBoxed = useMemo(
    () => OPTIONS.find((o) => o.id === value) ?? null,
    [value]
  );

  const handleChange = (value: BoxedMailboxType) => {
    onChange(value ? value.id : null);
  };

  function renderItemContent(value: BoxedMailboxType) {
    return <MailboxTypeOption value={value ? value.id : null} />;
  }

  return (
    <FixedDataPicker
      multiple={false}
      value={valueBoxed}
      onChange={handleChange}
      dataSet={OPTIONS}
      renderItemContent={renderItemContent}
      {...others}
    />
  );
}
