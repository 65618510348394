import { gql, useMutation, useQuery } from '@apollo/client';
import { DateTime, Duration } from 'luxon';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useRxDB } from 'rxdb-hooks';

import {
  EventType,
  sendAmplitudeData,
} from '@work4all/components/lib/utils/amplitude/amplitude';

import { fieldsToQueryFields, useUser } from '@work4all/data';
import { USER_META_ID } from '@work4all/data/lib/db';

import { UserPresence } from '@work4all/models/lib/Classes/UserPresence.entity';
import { CreateTimestampKind } from '@work4all/models/lib/Enums/CreateTimestampKind.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { invariant } from '@work4all/utils';
import { genFields } from '@work4all/utils/lib/graphql-query-generation/helpers/genFields';
import { useForceUpdate } from '@work4all/utils/lib/hooks/use-force-update';
import { PathsOf } from '@work4all/utils/lib/paths-of/paths-of';

export type TimeTracker = {
  disabled: boolean;
  loading: boolean;
  result: {
    state: TimeTrackerState;
    presentSince: DateTime;
    pausedSince: DateTime;
    stoppedSince: DateTime;
    totalPresent: Duration;
    totalPaused: Duration;
    totalWorked: Duration;
    isPresent?: boolean;
    lastPauseFinished: DateTime;
  };
  start: (timestamp?: Date, userId?: number) => void;
  stop: (timestamp?: Date, userId?: number) => void;
  pause: (timestamp?: Date, userId?: number) => void;
  resume: (timestamp?: Date, userId?: number) => void;
};

export type TimeTrackerState = 'initial' | 'running' | 'paused' | 'stopped';

export interface UseTimeTrackerOptions {
  /**
   * @default false
   */
  disabled?: boolean;
  amplitudeEntryPoint: string;
}

export function useTimeTracker(options: UseTimeTrackerOptions): TimeTracker {
  const { disabled = false, amplitudeEntryPoint } = options;

  const rxDb = useRxDB();

  const snackbar = useSnackbar();

  const forceUpdate = useForceUpdate();

  const user = useUser();

  const query = useQuery<GetUserPresenceResult, GetUserPresenceVariables>(
    GET_USER_PRESENCE,
    {
      skip: disabled,
      // Refetch every 5 minutes so the data does not get too out of sync.
      pollInterval: 5 * 60 * 1000,
      variables: { userId: user.benutzerCode },
    }
  );

  const [mutate] = useMutation<
    SET_USER_PRESENCE_TIMESTAMP_RESULT,
    SET_USER_PRESENCE_TIMESTAMP_VARIABLES
  >(SET_USER_PRESENCE_TIMESTAMP, {
    onCompleted(data) {
      const result = data.setUserPresenceTimestamp;

      if (!result.ok) {
        console.error(result.message);
        snackbar.enqueueSnackbar(result.message, { variant: 'error' });
      }
    },
  });

  const start = async (timestamp?: Date, userCode?: number) => {
    if (disabled) {
      return;
    }

    sendAmplitudeData(EventType.StartWorkTime, {
      entryPoint: amplitudeEntryPoint,
    });
    await mutate({
      variables: { type: CreateTimestampKind.CHECK_IN, timestamp, userCode },
    });
  };

  const stop = async (timestamp?: Date, userCode?: number) => {
    if (disabled) {
      return;
    }
    sendAmplitudeData(EventType.StopWorkTime, {
      entryPoint: amplitudeEntryPoint,
    });
    await mutate({
      variables: { type: CreateTimestampKind.CHECK_OUT, timestamp, userCode },
    });
  };

  const pause = async (timestamp?: Date, userCode?: number) => {
    if (disabled) {
      return;
    }

    sendAmplitudeData(EventType.PauseWorkTime, {
      entryPoint: amplitudeEntryPoint,
    });
    await mutate({
      variables: {
        type: CreateTimestampKind.PAUSE_BEGINN,
        timestamp,
        userCode,
      },
    });
  };

  const resume = async (timestamp?: Date, userCode?: number) => {
    if (disabled) {
      return;
    }

    sendAmplitudeData(EventType.ResumeWorkTime, {
      entryPoint: amplitudeEntryPoint,
    });
    await mutate({
      variables: { type: CreateTimestampKind.PAUSE_ENDE, timestamp, userCode },
    });
  };

  const now = nowWithMinutePrecision();
  const result = disabled
    ? DISABLED_RESULT
    : query.data
    ? prepareResult(query.data, now)
    : null;

  useEffect(() => {
    //this effect will simply trigger a rerender in all open tabs if the state of the timer changes
    const t = async () => {
      const localUser = await rxDb.getLocal(USER_META_ID);
      if (localUser.get('timeTrackerOn') !== result?.state || false) {
        await localUser.incrementalModify((data) => {
          return {
            ...data,
            timeTrackerOn: result?.state || false,
          };
        });
      }
    };
    t();
  }, [rxDb, result?.state]);

  // If in "running" or "paused" state, schedule a re-render in one minute to
  // update all displayed duration values.
  useEffect(() => {
    if (result === null) {
      return;
    }

    if (result.state === 'initial' || result.state === 'stopped') {
      return;
    }

    const nextMinute = now.plus({ minutes: 1 });
    const delay = nextMinute.toMillis() - DateTime.now().toMillis();

    const timeoutId = setTimeout(forceUpdate, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  });

  return {
    disabled,
    loading: query.loading,
    result,
    start,
    stop,
    pause,
    resume,
  };
}

const DAY_ZERO = DateTime.fromISO('0001-01-01T00:00:00Z');
const DURATION_ZERO = Duration.fromObject({});

const DISABLED_RESULT: {
  state: TimeTrackerState;
  presentSince: DateTime;
  pausedSince: DateTime;
  stoppedSince: DateTime;
  totalPresent: Duration;
  totalPaused: Duration;
  totalWorked: Duration;
  isPresent: boolean;
  lastPauseFinished: DateTime;
} = {
  state: 'initial',
  presentSince: DAY_ZERO,
  pausedSince: DAY_ZERO,
  stoppedSince: DAY_ZERO,
  totalPresent: DURATION_ZERO,
  totalPaused: DURATION_ZERO,
  totalWorked: DURATION_ZERO,
  isPresent: false,
  lastPauseFinished: DAY_ZERO,
};

function prepareResult(
  data: GetUserPresenceResult,
  now: DateTime
): {
  state: TimeTrackerState;
  presentSince: DateTime;
  pausedSince: DateTime;
  stoppedSince: DateTime;
  totalPresent: Duration;
  totalPaused: Duration;
  totalWorked: Duration;
  isPresent?: boolean;
  lastPauseFinished: DateTime;
} {
  const userPresence = data.userPresence?.[0];

  invariant(userPresence != null, 'UserPresence is not found in the response');

  const state: TimeTrackerState = userPresence.isPresent
    ? userPresence.pause
      ? 'paused'
      : 'running'
    : userPresence.closingTime === null ||
      userPresence.presentSinceDate === null
    ? 'initial'
    : 'stopped';

  const presentSince = DateTime.fromISO(userPresence.presentSinceDate);
  const pausedSince = DateTime.fromISO(userPresence.pauseSince);
  const stoppedSince = DateTime.fromISO(userPresence.closingTime);
  const lastPauseFinished = DateTime.fromISO(userPresence.lastPauseFinished);

  if (state === 'initial') {
    const zero = Duration.fromObject({});

    return {
      state,
      presentSince,
      pausedSince,
      stoppedSince,
      totalPresent: zero,
      totalPaused: zero,
      totalWorked: zero,
      isPresent: userPresence.isPresent,
      lastPauseFinished,
    };
  }

  const currentPausedMinutes =
    state === 'paused'
      ? Math.floor((now.toMillis() - pausedSince.toMillis()) / 60_000)
      : 0;

  const totalPresent = Duration.fromMillis(
    state === 'stopped' ? 0 : now.toMillis() - presentSince.toMillis()
  );
  const totalPaused = Duration.fromObject({
    minutes: userPresence.workTimeInfo.breakTime + currentPausedMinutes,
  });

  const totalWorked =
    state === 'stopped'
      ? Duration.fromMillis(0)
      : totalPresent.minus(totalPaused);

  return {
    state,
    presentSince,
    pausedSince,
    stoppedSince,
    totalPresent,
    totalPaused,
    totalWorked,
    isPresent: userPresence.isPresent,
    lastPauseFinished,
  };
}

function nowWithMinutePrecision(): DateTime {
  const now = DateTime.now();

  return now.minus({
    seconds: now.second,
    milliseconds: now.millisecond,
  });
}

const USER_PRESENCE_FIELDS: PathsOf<UserPresence, 2> = {
  userId: null,
  isPresent: null,
  presentSinceDate: null,
  pause: null,
  pauseSince: null,
  closingTime: null,
  lastPauseFinished: null,
  workTimeInfo: {
    breakTime: null,
  },
};

const mapped = fieldsToQueryFields(USER_PRESENCE_FIELDS, Entities.userPresence);
const USER_PRESENCE_FIELDS_MAPPING = genFields(mapped).str;

interface GetUserPresenceResult {
  userPresence: UserPresence[];
}

interface GetUserPresenceVariables {
  userId: number;
}

const GET_USER_PRESENCE = gql`
  query GetUserPresence ($userId: Int!) {
    userPresence: getUserPresence(benutzerCodes: [$userId]) { ${USER_PRESENCE_FIELDS_MAPPING} }
  }
`;

interface SET_USER_PRESENCE_TIMESTAMP_RESULT {
  setUserPresenceTimestamp: {
    ok: boolean;
    message: string;
    userPresence: UserPresence | null;
  };
}

interface SET_USER_PRESENCE_TIMESTAMP_VARIABLES {
  type: CreateTimestampKind;
  timestamp?: Date;
  userCode?: number;
}

const SET_USER_PRESENCE_TIMESTAMP = gql`
  mutation StartTrackingUserPresence(
    $type: CreateZeitstempelArt!
    $timestamp: DateTimeEx
    $userCode: Int
  ) {
    setUserPresenceTimestamp(
      timestampType: $type
      timestampDate: $timestamp
      userCode: $userCode
    ) {
      ok
      message
      userPresence { ${USER_PRESENCE_FIELDS_MAPPING} }
    }
  }
`;
