import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { LicenseModulInfo } from './LicenseModulInfo.entity';

export class LicenseInfo<T extends EMode = EMode.entity> {
  /** Alias: licenseCustomerNumber */
  licenseCustomerNumber?: number;
  /** Alias: modules */
  modules?: LicenseModulInfo<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<LicenseInfo> = {
  licenseCustomerNumber: {
    alias: 'licenseCustomerNumber',
  },
  modules: {
    alias: 'modules',
    entity: Entities.licenseModulInfo,
  },
  __typename: {
    alias: '__typename',
  },
};

export const licenseInfoEntityDefinition: EntitiyDefinition<LicenseInfo> = {
  local: {},
  remote: {
    queryName: 'getLicenseInfo',
    fragmentName: 'LicenseInfo',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
