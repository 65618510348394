import React from 'react';

export function YellowNoteCornerGraphic(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <svg viewBox="0 0 39 39" fill="none" {...props}>
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H39L0 39V8Z"
        fill="url(#paint0_linear_1_6880)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_6880"
          x1="4.5"
          y1="6.5"
          x2="18.5"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E7CD71" />
          <stop offset="1" stopColor="#D1B961" />
        </linearGradient>
      </defs>
    </svg>
  );
}
