import styles from './Outline.module.scss';

import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useIsCustomBackgroundActive } from '@work4all/data';

import { MatchedNavigationGroup } from '../../../containers/more/types';

export interface IOutlineProps {
  groups: MatchedNavigationGroup[];
  selectedGroup: string | null;
  onSelectedGroupChange: (group: string | null) => void;
}

export function Outline({
  groups,
  selectedGroup,
  onSelectedGroupChange,
}: IOutlineProps) {
  const { t } = useTranslation();
  const isCustomBackgroundActive = useIsCustomBackgroundActive();

  const totalLinksMatched = groups.reduce(
    (acc, group) => acc + group.matches ?? 0,
    0
  );

  return (
    <nav className={clsx(styles.root, isCustomBackgroundActive && styles.dark)}>
      <OutlineItem
        label={t('COMMON.ALL')}
        count={totalLinksMatched}
        active={selectedGroup === null}
        onClick={() => onSelectedGroupChange(null)}
      />

      <Divider className={styles.divider} />

      <ul className={styles.list}>
        {groups.map((group) => {
          const isActive = selectedGroup === group.name;

          return (
            <li key={group.name}>
              <OutlineItem
                label={group.name}
                count={group.matches}
                active={isActive}
                onClick={() => onSelectedGroupChange(group.name)}
              />
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

function OutlineItem(props: {
  onClick: () => void;
  active: boolean;
  label: string;
  count: number | undefined;
}) {
  const { onClick, active, label, count } = props;

  return (
    <Typography
      component="button"
      className={clsx(styles.link, active && styles.active)}
      onClick={onClick}
    >
      {label}
      {count ? <span className={styles.count}>{count}</span> : null}
    </Typography>
  );
}
