import { useObservableState } from 'observable-hooks';
import { concatMap, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { IBaseSearchItemProps } from '@work4all/models/lib/Inputs';

import { IMultiStepSearchProps } from '../types';

export const useSearch = <T extends IBaseSearchItemProps, ControlProps>(
  onSearch?: IMultiStepSearchProps<T, ControlProps>['onSearch']
) => {
  const [itemsData, setSearchValue] = useObservableState<
    T[] | null,
    [string, string, T[]]
  >(
    (input$) =>
      input$.pipe(
        distinctUntilChanged(),
        debounceTime(250),
        concatMap(async (value) => {
          if (!onSearch) {
            return [];
          }

          const d = await onSearch(value[0], value[1], value[2]);
          if (!d) {
            return null;
          }

          return d;
        })
      ),
    []
  );

  return { itemsData, setSearchValue };
};
