import style from './BottomViewTabContent.module.scss';

import { AutoSizer } from 'react-virtualized';

import { Table, TableStateBagProvider } from '@work4all/components';

import { useTabColumns, useTabData } from '../hooks';
import { BottomViewProps } from '../interfaces';
import { Tab } from '../types';

interface BottomViewTabContentProps
  extends Pick<BottomViewProps, 'selectedEntityId'> {
  tab: Tab;
}

export const BottomViewTabContent = ({
  tab,
  selectedEntityId,
}: BottomViewTabContentProps) => {
  const columns = useTabColumns(tab);
  const data = useTabData(tab, selectedEntityId);

  return (
    <AutoSizer>
      {({ width }) => {
        return (
          <div style={{ width }}>
            <TableStateBagProvider tableInstance={null}>
              <Table
                mode="client"
                columns={columns}
                data={data}
                allItemsCount={data.length}
                className={style.table}
                resizableColumns
                selectableRows={false}
                selectableMultiple={false}
              />
            </TableStateBagProvider>
          </div>
        );
      }}
    </AutoSizer>
  );
};
