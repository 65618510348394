import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { ObjectType } from '../Enums/ObjectType.enum';

export class SavedListFilter<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: name */
  name?: string;
  /** Alias: userCode */
  userId?: number;
  /** Alias: global */
  global?: boolean;
  /** Alias: filter */
  filter?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: objectType */
  objectType?: ObjectType;
  /** Alias: version */
  version?: string;

  __typename?: string;
}

const fields: FieldDefinitions<SavedListFilter> = {
  id: {
    alias: 'id',
  },
  name: {
    alias: 'name',
  },
  userId: {
    alias: 'userCode',
  },
  global: {
    alias: 'global',
  },
  filter: {
    alias: 'filter',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  objectType: {
    alias: 'objectType',
  },
  version: {
    alias: 'version',
  },
  __typename: {
    alias: '__typename',
  },
};

export const savedListFilterEntityDefinition: EntitiyDefinition<SavedListFilter> =
  {
    local: {},
    remote: {
      queryName: 'getSavedListFilter',
      fragmentName: 'SavedListFilter',
      withPaginationWrapper: true,
      mutations: {
        upsert: {
          mutationName: 'upsertSavedListFilter',
          args: [
            { name: 'input', type: 'InputSavedListFilter!' },
            { name: 'drySave', type: 'Boolean' },
          ],
        },
      },
      params: [
        { name: 'querySize', type: 'Int' },
        { name: 'queryPage', type: 'Int' },
        { name: 'querySortOrder', type: 'SortOrderStyle' },
        { name: 'querySortBy', type: 'String' },
        { name: 'filter', type: 'String' },
      ],
    },
    fieldDefinitions: fields,
  };
