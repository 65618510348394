import { Grid } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';

import { useDataProvider } from '@work4all/data';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';

import { Tenant } from '@work4all/models/lib/Classes/Tenant.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { settings, useSetting } from '../../../../../../../../../../settings';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { FullAddressPicker } from '../../../../full-address-picker/FullAddressPicker';

interface Props {
  displayAsSection?: boolean;
  layoutCollapsed?: 'address' | 'input';
}

export const ParticipantsAndAddresses: React.VFC<Props> = (props: Props) => {
  const { t } = useTranslation();

  const { displayAsSection = false, layoutCollapsed = 'address' } = props;

  const { watch, setValue } = useFormContext();

  const businessPartner = watch(
    'businessPartnerContactCombined.businessPartner'
  );
  const address1 = watch('additionalAddress1.businessPartner');
  const address2 = watch('additionalAddress2.businessPartner');
  const address3 = watch('additionalAddress3.businessPartner');

  const contact = watch('businessPartnerContactCombined.contact');
  const address1Contact = watch('additionalAddress1.contact');
  const address2Contact = watch('additionalAddress2.contact');
  const address3Contact = watch('additionalAddress3.contact');

  const businessPartnerType = watch('businessPartnerType');
  const additionalAddress1CompanyType = watch('additionalAddress1CompanyType');
  const additionalAddress2CompanyType = watch('additionalAddress2CompanyType');
  const additionalAddress3CompanyType = watch('additionalAddress3CompanyType');

  const businessPartnerText = watch('businessPartnerText');
  const additionalAddress1Text = watch('additionalAddress1Text');
  const additionalAddress2Text = watch('additionalAddress2Text');
  const additionalAddress3Text = watch('additionalAddress3Text');

  const erpAdditionalAddress3Name = useSetting(
    settings.erpAdditionalAddress3Name()
  );

  const erpShowAdditionalAddress3 = useSetting(
    settings.erpShowAdditionalAddress3()
  );

  const [editMode, setEditMode] = useState(false);

  const mask = useMaskContext();

  const { activeTenant } = useTenant();

  const request = useMemo<DataRequest>(() => {
    return {
      entity: Entities.tenant,
      data: {
        id: null,
        name: null,
        imageFileName: null,
        street: null,
        postalCode: null,
        city: null,
      } as Tenant<EMode.query>,
      filter: [{ id: { $eq: activeTenant } }],
      completeDataResponse: true,
    };
  }, [activeTenant]);

  const tenantResponse = useDataProvider<Tenant>(request);

  const currentTenantData = tenantResponse.data?.find(
    (x) => x.id === activeTenant
  );

  const addressLabel = useMemo(() => {
    if (!currentTenantData) {
      return '';
    }
    const { name, street, postalCode, city } = currentTenantData;
    return `${name} - ${street} - ${postalCode} ${city}`;
  }, [currentTenantData]);

  const content = useMemo(() => {
    return (
      <Grid container spacing="1rem">
        <Grid
          item
          md={erpShowAdditionalAddress3.value ? 3 : 4}
          xs={12}
          position="relative"
        >
          <FullAddressPicker
            layoutCollapsed={layoutCollapsed}
            businessPartner={businessPartner?.data}
            contact={contact}
            businessPartnerType={businessPartnerType}
            addressString={businessPartnerText}
            expanded={editMode}
            onExpandChange={(expanded) => setEditMode(expanded)}
            label={addressLabel}
            onChange={(value) => {
              value.businessPartner &&
                setValue(
                  'businessPartnerContactCombined.businessPartner',
                  value.businessPartner,
                  { shouldDirty: true, shouldValidate: true }
                );
              value.contact !== undefined &&
                setValue(
                  'businessPartnerContactCombined.contact',
                  value.contact,
                  { shouldDirty: true, shouldValidate: true }
                );
              value.addressString &&
                setValue('businessPartnerText', value.addressString, {
                  shouldDirty: true,
                  shouldValidate: true,
                });
            }}
          />
        </Grid>

        {![Entities.inboundDeliveryNote].includes(mask.entity) && (
          <>
            <Grid item md={erpShowAdditionalAddress3.value ? 3 : 4} xs={12}>
              {(layoutCollapsed === 'input' || editMode) && (
                <FullAddressPicker
                  layoutCollapsed={layoutCollapsed}
                  label={t('COMMON.DELIVERY_ADDRESS')}
                  businessPartner={address1?.data}
                  contact={address1Contact}
                  businessPartnerType={additionalAddress1CompanyType}
                  addressString={additionalAddress1Text}
                  compareBusinessPartnerId={businessPartner?.data?.id}
                  compareContactId={contact?.id}
                  expanded={editMode}
                  onExpandChange={(expanded) => setEditMode(expanded)}
                  onChange={(value) => {
                    value.businessPartner &&
                      setValue(
                        'additionalAddress1.businessPartner',
                        value.businessPartner,
                        { shouldDirty: true, shouldValidate: true }
                      );
                    value.contact !== undefined &&
                      setValue('additionalAddress1.contact', value.contact, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    value.addressString &&
                      setValue('additionalAddress1Text', value.addressString, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                  }}
                  disableEditToggle
                />
              )}
            </Grid>
            <Grid item md={erpShowAdditionalAddress3.value ? 3 : 4} xs={12}>
              {(layoutCollapsed === 'input' || editMode) && (
                <FullAddressPicker
                  layoutCollapsed={layoutCollapsed}
                  label={t('COMMON.INVOICE_ADDRESS')}
                  businessPartner={address2?.data}
                  contact={address2Contact}
                  businessPartnerType={additionalAddress2CompanyType}
                  addressString={additionalAddress2Text}
                  compareBusinessPartnerId={businessPartner?.data?.id}
                  compareContactId={contact?.id}
                  expanded={editMode}
                  onExpandChange={(expanded) => setEditMode(expanded)}
                  onChange={(value) => {
                    value.businessPartner &&
                      setValue(
                        'additionalAddress2.businessPartner',
                        value.businessPartner,
                        { shouldDirty: true, shouldValidate: true }
                      );
                    value.contact !== undefined &&
                      setValue('additionalAddress2.contact', value.contact, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    value.addressString &&
                      setValue('additionalAddress2Text', value.addressString, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                  }}
                  disableEditToggle
                />
              )}
            </Grid>

            {erpShowAdditionalAddress3.value ? (
              <Grid item md={erpShowAdditionalAddress3.value ? 3 : 4} xs={12}>
                {(layoutCollapsed === 'input' || editMode) && (
                  <FullAddressPicker
                    layoutCollapsed={layoutCollapsed}
                    label={
                      erpAdditionalAddress3Name.value.trim() ||
                      t('ERP.ADDITIONAL_ADDRESS_DEFAULT_NAME')
                    }
                    businessPartner={address3?.data}
                    contact={address3Contact}
                    businessPartnerType={additionalAddress3CompanyType}
                    addressString={additionalAddress3Text}
                    compareBusinessPartnerId={businessPartner?.data?.id}
                    compareContactId={contact?.id}
                    expanded={editMode}
                    onExpandChange={(expanded) => setEditMode(expanded)}
                    onChange={(value) => {
                      value.businessPartner &&
                        setValue(
                          'additionalAddress3.businessPartner',
                          value.businessPartner,
                          { shouldDirty: true, shouldValidate: true }
                        );
                      value.contact !== undefined &&
                        setValue('additionalAddress3.contact', value.contact, {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                      value.addressString &&
                        setValue(
                          'additionalAddress3Text',
                          value.addressString,
                          {
                            shouldDirty: true,
                            shouldValidate: true,
                          }
                        );
                    }}
                    disableEditToggle
                  />
                )}
              </Grid>
            ) : null}
          </>
        )}
      </Grid>
    );
  }, [
    addressLabel,
    additionalAddress1CompanyType,
    additionalAddress1Text,
    additionalAddress2CompanyType,
    additionalAddress2Text,
    additionalAddress3CompanyType,
    additionalAddress3Text,
    address1?.data,
    address1Contact,
    address2?.data,
    address2Contact,
    address3?.data,
    address3Contact,
    businessPartner?.data,
    businessPartnerText,
    businessPartnerType,
    contact,
    editMode,
    erpAdditionalAddress3Name.value,
    erpShowAdditionalAddress3.value,
    layoutCollapsed,
    mask.entity,
    setValue,
    t,
  ]);

  return displayAsSection ? (
    <Collapse defaultOpen={true} title={t('MASK.PARTICIPANTS_AND_ADDRESSES')}>
      {content}
    </Collapse>
  ) : (
    content
  );
};
