import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Sickness } from './Sickness.entity';
import { UserPresenceTimeInfo } from './UserPresenceTimeInfo.entity';
import { Vacation } from './Vacation.entity';

export class WorkingTimeTimeCardDayInfo<T extends EMode = EMode.entity> {
  /** Alias: anzahlFeiertag */
  amountHoliday?: number;
  /** Alias: anzahlKrankheit */
  amountSickness?: number;
  /** Alias: anzahlUrlaub */
  amountVacation?: number;
  /** Alias: datum */
  date?: string;
  /** Alias: differenzMinuten */
  differenceminutes?: number;
  /** Alias: istBruttoMinuten */
  isGrossMinutes?: number;
  /** Alias: korrekturMengeMinuten */
  correctionMinutes?: number;
  /** Alias: korrekturNotiz */
  correctionNote?: string;
  /** Alias: krankheitItems */
  sicknessList?: Sickness<T>[];
  /** Alias: notiz */
  note?: string;
  /** Alias: pauseAnzahlGestempeltMinuten */
  pauseAmountMinutesStamped?: number;
  /** Alias: pauseAnzahlLautRegelMinuten */
  pauseAmountMinutesAccordingToRule?: number;
  /** Alias: pauseAnzahlMinuten */
  pauseAmountMinutes?: number;
  /** Alias: sollMinuten */
  targetMinutes?: number;
  /** Alias: tagEnde */
  endDate?: string;
  /** Alias: tagEndeKorrigiert */
  endDateCorrected?: string;
  /** Alias: tagStart */
  startDate?: string;
  /** Alias: tagStartKorrigiert */
  startDateCorrected?: string;
  /** Alias: urlaubItems */
  vacationList?: Vacation<T>[];
  /** Alias: zeitkontoMinuten */
  timeAccountMinutes?: number;
  /** Alias: anwesenheiten */
  presenceList?: UserPresenceTimeInfo<T>[];
  /** Alias: projektzeitSumme */
  projectTimeTotal?: number;
  /** Alias: istNettoMinuten */
  istNetMinutes?: number;

  __typename?: string;
}

const fields: FieldDefinitions<WorkingTimeTimeCardDayInfo> = {
  amountHoliday: {
    alias: 'anzahlFeiertag',
  },
  amountSickness: {
    alias: 'anzahlKrankheit',
  },
  amountVacation: {
    alias: 'anzahlUrlaub',
  },
  date: {
    alias: 'datum',
  },
  differenceminutes: {
    alias: 'differenzMinuten',
  },
  isGrossMinutes: {
    alias: 'istBruttoMinuten',
  },
  correctionMinutes: {
    alias: 'korrekturMengeMinuten',
  },
  correctionNote: {
    alias: 'korrekturNotiz',
  },
  sicknessList: {
    alias: 'krankheitItems',
    entity: Entities.sickness,
  },
  note: {
    alias: 'notiz',
  },
  pauseAmountMinutesStamped: {
    alias: 'pauseAnzahlGestempeltMinuten',
  },
  pauseAmountMinutesAccordingToRule: {
    alias: 'pauseAnzahlLautRegelMinuten',
  },
  pauseAmountMinutes: {
    alias: 'pauseAnzahlMinuten',
  },
  targetMinutes: {
    alias: 'sollMinuten',
  },
  endDate: {
    alias: 'tagEnde',
  },
  endDateCorrected: {
    alias: 'tagEndeKorrigiert',
  },
  startDate: {
    alias: 'tagStart',
  },
  startDateCorrected: {
    alias: 'tagStartKorrigiert',
  },
  vacationList: {
    alias: 'urlaubItems',
    entity: Entities.vacation,
  },
  timeAccountMinutes: {
    alias: 'zeitkontoMinuten',
  },
  presenceList: {
    alias: 'anwesenheiten',
    entity: Entities.userPresenceTimeInfo,
  },
  projectTimeTotal: {
    alias: 'projektzeitSumme',
  },
  istNetMinutes: {
    alias: 'istNettoMinuten',
  },
  __typename: {
    alias: '__typename',
  },
};

export const workingTimeTimeCardDayInfoEntityDefinition: EntitiyDefinition<WorkingTimeTimeCardDayInfo> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'KommtGehtZeitkarteTagInfo',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
