import styles from '../EntityPicker.module.scss';

import { Box, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';

import { BusinessPartner } from '@work4all/models/lib/Classes/BusinessPartner.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { WebsiteFavicon } from '../../website-favicon/WebsiteFavicon';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';
import { Selection } from '../utils/selection-model';

export type ISupplierPickerProps<TMultiple extends boolean> = IPickerProps<
  Supplier,
  TMultiple
>;

export function SupplierPicker<TMultiple extends boolean>(
  props: ISupplierPickerProps<TMultiple>
) {
  const { data, onChange, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...FIELDS, ...data };
  }, [data]);

  const normalizeValue = useCallback(
    (item: Supplier | BusinessPartner, __typename: string): Supplier => {
      if (!props.filterConfig) return item as Supplier;

      return 'data' in item
        ? ({ ...item.data, __typename } as Supplier)
        : ({ ...item, __typename: item.__typename || __typename } as Supplier);
    },
    [props.filterConfig]
  );

  const handlePickedItems = useCallback(
    (selectedItems: Selection<Supplier | BusinessPartner, TMultiple>) => {
      const newItems = Array.isArray(selectedItems)
        ? selectedItems.map((selectedItem) =>
            normalizeValue(selectedItem, 'Lieferant')
          )
        : normalizeValue(selectedItems, 'Lieferant');
      onChange(newItems as Selection<Supplier, TMultiple>);
    },
    [normalizeValue, onChange]
  );

  const renderItem = (item: Supplier) => {
    const name = normalizeValue(item, 'Lieferant').name;
    return (
      <Tooltip title={item.name}>
        <Typography variant="body2" noWrap>
          <Box display="flex" gap="0.5rem" alignItems="center">
            {<WebsiteFavicon website={item?.website} />}{' '}
            {item.number ? (
              <span className={styles.secondaryInfo}> {item.number} | </span>
            ) : null}
            {name}
          </Box>
        </Typography>
      </Tooltip>
    );
  };

  return (
    <ListEntityPicker
      entity={Entities.supplier}
      data={allFields}
      filterBy={['name', 'number']}
      sortBy="name"
      useSearchHistory
      onChange={handlePickedItems}
      {...rest}
      renderItemContent={renderItem}
    />
  );
}

const FIELDS: Supplier = {
  id: null,
  name: null,
  number: null,
  website: null,
  mainContact: {
    id: null,
    name: null,
    firstName: null,
    displayName: null,
  },
};
