import styles from './DateTimeInputDialog.module.scss';

import { DateTime } from 'luxon';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { W4ADateFormat } from '@work4all/models/lib/additionalEnums/DateFormat.enum';

import {
  DateFormatPreset,
  formatDateString,
} from '@work4all/utils/lib/date-utils/formatDateString';

import { Dialog, DialogContent } from '../../dialog';
import { DateTimeInputPicker } from '../../input/date-time-input-picker';
import { Caption } from '../../typography/caption/Caption';

export interface DateTimeInputDialogProps {
  open: boolean;
  title: string;
  description?: string;
  confirmationText?: string;
  cancellationText?: string;
  defaultValue?: Date;
  allowChangeDate?: boolean;
  minimumDateTime?: DateTime;
  minimumDateAlert?: string;
  maximumDateTime?: DateTime;
  onConfirm: (value: Date) => void;
  onCancel: () => void;
}

export class DialogSettings {
  showAlertMinimumDate: boolean;
  showAlerMaximumDate: boolean;
  disableConfirmButton: boolean;
}

export function DateTimeInputDialog(props: DateTimeInputDialogProps) {
  const {
    open,
    title,
    description,
    defaultValue,
    allowChangeDate,
    minimumDateTime,
    minimumDateAlert,
    maximumDateTime,
    onConfirm,
    onCancel,
  } = props;

  const [settings, setDialogSettings] = useState({
    showAlertMinimumDate: false,
    showAlertMaximumDate: false,
    disableConfirmButton: false,
  });

  const { t } = useTranslation();

  const [value, setValue] = useState<string>(() => {
    return DateTime.fromJSDate(defaultValue ?? new Date()).toFormat(
      W4ADateFormat.DEFAULT
    );
  });

  useEffect(() => {
    setDialogSettings({
      showAlertMinimumDate: false,
      showAlertMaximumDate: false,
      disableConfirmButton: false,
    });
  }, [open]);

  const checkValue = useCallback(
    (value) => {
      if (minimumDateTime !== null) {
        setDialogSettings((prev) => ({
          ...prev,
          showAlertMinimumDate: DateTime.fromISO(value) < minimumDateTime,
          disableConfirmButton: DateTime.fromISO(value) < minimumDateTime,
        }));
      }
      if (
        maximumDateTime !== null &&
        !(minimumDateTime !== null && DateTime.fromISO(value) < minimumDateTime)
      ) {
        setDialogSettings((prev) => ({
          ...prev,
          showAlertMaximumDate: DateTime.fromISO(value) > maximumDateTime,
          disableConfirmButton: DateTime.fromISO(value) > maximumDateTime,
        }));
      }

      setValue(value);
    },
    [minimumDateTime, maximumDateTime]
  );

  useLayoutEffect(() => {
    if (open) {
      setValue(
        DateTime.fromJSDate(defaultValue ?? new Date()).toFormat(
          W4ADateFormat.DEFAULT
        )
      );
    }
  }, [defaultValue, open]);

  return (
    <Dialog
      open={open}
      title={title}
      onClose={onCancel}
      onConfirm={(e) => {
        e.stopPropagation();
        const date = DateTime.fromFormat(
          value,
          W4ADateFormat.DEFAULT
        ).toJSDate();

        onConfirm(date);
      }}
      closeButton={true}
      confirmDisabled={settings.disableConfirmButton}
    >
      <DialogContent>
        {description ? (
          <div className={styles.description}>{description}</div>
        ) : null}

        <DateTimeInputPicker
          data-testid="date-time-input-dialog-input"
          withTime={true}
          clearable={false}
          value={value}
          onChange={(event) => {
            checkValue(event.currentTarget.value);
          }}
          disabledDate={!allowChangeDate}
        />

        {settings.showAlertMinimumDate && (
          <div className={styles.errorMessage}>
            <Caption color="error">
              {t(minimumDateAlert, {
                time: allowChangeDate
                  ? formatDateString(
                      minimumDateTime.plus({ minutes: 1 }).toJSDate(),
                      DateFormatPreset.DATE
                    )
                  : formatDateString(
                      minimumDateTime.plus({ minutes: 1 }).toJSDate(),
                      DateFormatPreset.TIME_SIMPLE
                    ),
              })}
            </Caption>
          </div>
        )}

        {settings.showAlertMaximumDate && (
          <div className={styles.errorMessage}>
            <Caption color="error">{t(`TIME_TRACKER.DIALOG.FUTURE`)}</Caption>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
