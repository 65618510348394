import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class SetMfaModeResponse<T extends EMode = EMode.entity> {
  /** Alias: ok */
  ok?: boolean;
  /** Alias: errorMessage */
  errorMessage?: string;
  /** Alias: qrCodeBase64 */
  qrCodeBase64?: string;
  /** Alias: secret */
  secret?: string;

  __typename?: string;
}

const fields: FieldDefinitions<SetMfaModeResponse> = {
  ok: {
    alias: 'ok',
  },
  errorMessage: {
    alias: 'errorMessage',
  },
  qrCodeBase64: {
    alias: 'qrCodeBase64',
  },
  secret: {
    alias: 'secret',
  },
  __typename: {
    alias: '__typename',
  },
};

export const setMfaModeResponseEntityDefinition: EntitiyDefinition<SetMfaModeResponse> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'SetMfaModeResponse',
      withPaginationWrapper: false,
      mutations: {
        upsert: {
          mutationName: 'updateUserMfaMode',
          args: [{ name: 'input', type: 'InputSetMfaMode!' }],
        },
      },
    },
    fieldDefinitions: fields,
  };
