import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class PartialCalculationLogicDetails<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: notiz */
  note?: string;
  /** Alias: prozent */
  percent?: number;
  /** Alias: reihenfolge */
  sequence?: number;
  /** Alias: tage */
  days?: string;
  /** Alias: teilrechnungLogikCode */
  partialCalculationLogicId?: number;

  __typename?: string;
}

const fields: FieldDefinitions<PartialCalculationLogicDetails> = {
  id: {
    alias: 'code',
  },
  note: {
    alias: 'notiz',
  },
  percent: {
    alias: 'prozent',
  },
  sequence: {
    alias: 'reihenfolge',
  },
  days: {
    alias: 'tage',
  },
  partialCalculationLogicId: {
    alias: 'teilrechnungLogikCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const partialCalculationLogicDetailsEntityDefinition: EntitiyDefinition<PartialCalculationLogicDetails> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'TeilrechnungLogikDetails',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
