import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { buildQuery } from '@work4all/data/lib/hooks/data-provider/utils/buildQuery';

import {
  User,
  userEntityDefinition,
} from '@work4all/models/lib/Classes/User.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

const DEFAULT_USERS = [];

export function useInitialUsersDataProvider({
  userIds,
  disabled,
}: {
  userIds: number[];
  disabled: boolean;
}) {
  const { query, variables } = useMemo(() => {
    return buildQuery(
      {
        entity: Entities.user,
        data: {
          id: null,
          displayName: null,
          firstName: null,
          lastName: null,
          shortName: null,
          userKind: null,
          departmentName: null,
        } as User<EMode.query>,
        operationName: 'GetInitialCalendarUsers',
        filter: [{ id: { $in: userIds } }],
      },
      9999
    );
  }, [userIds]);

  const skip = disabled || userIds.length === 0;

  // useDataProvider does not handle changes to variables correctly, so we use
  // Apollo's useQuery hook directly. (When the variables change, the `loading`
  // property has the value of `false` on that render, even though the request
  // will actually reload. You only see the correct `true` value on the next
  // render. The useQuery hook, however, correctly updates its state and returns
  // the expected value.)
  const { loading, data } = useQuery(query, { variables, skip });

  const users: User[] =
    data?.[userEntityDefinition.remote.queryName].data ?? DEFAULT_USERS;

  const result = useMemo(() => ({ loading, data: users }), [loading, users]);

  return result;
}
