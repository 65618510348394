import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input';
import { Caption } from '@work4all/components/lib/typography/caption/Caption';

import { useUser } from '@work4all/data';
import { useSetting } from '@work4all/data/lib/settings/use-setting';

import { settings } from '../../../../../../../../settings';

export function AddressManagementSection() {
  const { t } = useTranslation();

  const erpAdditionalAddress3Name = useSetting(
    settings.erpAdditionalAddress3Name()
  );

  const erpShowAdditionalAddress3 = useSetting(
    settings.erpShowAdditionalAddress3()
  );

  const user = useUser();

  const [additionalAddress3Name, setAdditionalAddress3Name] = useState('');

  useEffect(() => {
    setAdditionalAddress3Name(erpAdditionalAddress3Name.value);
  }, [erpAdditionalAddress3Name.value]);

  return (
    <Collapse title={t('SETTINGS.ERP.ADDRESSES')} defaultOpen>
      <CheckboxRadioItem
        label={t('SETTINGS.ERP.SHOW_ADDITIONAL_ADDRESS')}
        checked={erpShowAdditionalAddress3.value}
        onChange={(e) => erpShowAdditionalAddress3.set(e.target.checked)}
        disabled={!user.isMaster}
      />
      {erpShowAdditionalAddress3.value && (
        <>
          <LabeledInput
            disabled={!user.isMaster}
            label={t('SETTINGS.ERP.ADDITIONAL_ADDRESS_NAME')}
            value={additionalAddress3Name}
            onChange={(e) => {
              setAdditionalAddress3Name(e.target.value.trim());
            }}
            onBlur={() => {
              erpAdditionalAddress3Name.set(additionalAddress3Name);
            }}
          />
          <div style={{ paddingTop: '0.5rem' }}>
            <Caption>{t('SETTINGS.ERP.ADDITIONAL_ADDRESS_CAPTION')}</Caption>
          </div>
        </>
      )}
    </Collapse>
  );
}
