import { createContext, useContext } from 'react';

import { MailboxContentManager } from './mailbox-content-manager';

const Context = createContext<MailboxContentManager>(null);

export const MailboxContentManagerContextProvider = Context.Provider;

export function useMailboxContentManagerContext(): MailboxContentManager {
  const context = useContext(Context);

  if (context === null) {
    throw new Error(
      'useMailboxContentManagerContext must be used within a <MailboxContentManagerContextProvider>'
    );
  }

  return context;
}
