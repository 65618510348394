import { useMemo } from 'react';

import { usePrepareFilters, useTableState } from '@work4all/components';
import { IFilterPickerConfig } from '@work4all/components/lib/components/entity-picker/components/list-entity-picker';

export const StateFullPickerWrap: React.FC<{
  stateLessCfg: IFilterPickerConfig;
  render: (config: IFilterPickerConfig) => React.ReactElement;
}> = (props) => {
  const tableState = useTableState();
  const { filter } = usePrepareFilters(tableState?.filters, tableState?.sortBy);

  const stateFullCfg = useMemo(() => {
    if (props.stateLessCfg) return { ...props.stateLessCfg, filter };
    return null;
  }, [filter, props.stateLessCfg]);

  return props.render(stateFullCfg);
};
