import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useTooltip } from '../hooks/useTooltip';

export interface HeaderProps {
  title: string;
  description: string;
  /**
   * Will change all text and button colors to white. Should be set based on the
   * background color.
   */
  inverseColor?: boolean;
}

export const Header = (props: HeaderProps) => {
  const { title, description, inverseColor = false } = props;
  const { open, toggle, placement, breakpoint } = useTooltip();
  return (
    <Wrapper>
      <Typography
        variant="h3"
        color={inverseColor ? 'text.inverse' : 'text.secondary'}
      >
        {title}
      </Typography>

      <Tooltip
        key={`header-tooltip-${breakpoint}`}
        title={description}
        placement={placement}
        open={open}
      >
        <IconButton
          size="large"
          color={inverseColor ? 'ui1' : 'primary'}
          onClick={toggle}
        >
          <InfoIcon />
        </IconButton>
      </Tooltip>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: theme.spacing(4),

  [theme.breakpoints.down('xl')]: {
    marginTop: theme.spacing(4),
  },
}));
