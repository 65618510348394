import Typography from '@mui/material/Typography';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseDataTax } from '@work4all/models/lib/Enums/BaseDataTax.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ListEntityPicker } from '../components/list-entity-picker';
import { IPickerProps } from '../types';

export const BaseDataTaxTranslationKeys: Record<string, string> = {
  [BaseDataTax.DRITTLAND]: 'BASE_DATA_TAX.DRITTLAND',
  [BaseDataTax.EU]: 'BASE_DATA_TAX.EU',
  [BaseDataTax.INLAND]: 'BASE_DATA_TAX.INLAND',
};

export type IBaseDataTaxPickerProps<TMultiple extends boolean> = IPickerProps<
  { id: string; name: string },
  TMultiple
>;

export function BaseDataTaxPicker<TMultiple extends boolean>(
  props: IBaseDataTaxPickerProps<TMultiple>
) {
  const { ...rest } = props;

  const { t } = useTranslation();

  const dataSet = useMemo(() => {
    const taskStatusValues: { id: string; name: string }[] = [
      {
        id: BaseDataTax.INLAND,
        name: t(BaseDataTaxTranslationKeys[BaseDataTax.INLAND]),
      },
      {
        id: BaseDataTax.EU,
        name: t(BaseDataTaxTranslationKeys[BaseDataTax.EU]),
      },
      {
        id: BaseDataTax.DRITTLAND,
        name: t(BaseDataTaxTranslationKeys[BaseDataTax.DRITTLAND]),
      },
    ];

    return {
      total: taskStatusValues.length,
      loading: false,
      fetchMore: () => Promise.resolve(),
      refetch: () => Promise.resolve(null),
      data: taskStatusValues,
    };
  }, [t]);

  return (
    <ListEntityPicker
      entity={Entities.customer}
      multiple={false}
      maxItems={Math.max(dataSet.total, 3)}
      data={BASE_DATA_TAX_FIELDS}
      filterBy="name"
      sortBy="name"
      clearable={false}
      {...rest}
      fixedDataSet={dataSet}
      renderItemContent={(status) => (
        <Typography variant="body2" noWrap>
          {status.name}
        </Typography>
      )}
    />
  );
}

const BASE_DATA_TAX_FIELDS = {
  id: null,
  name: null,
};
