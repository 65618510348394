import { PrepareDataProps } from '../interfaces';

export const prepareData = async <TResponse,>({
  response,
  responseType,
}: PrepareDataProps) => {
  if (!response.ok) return (await response.text()) as TResponse;
  try {
    switch (responseType) {
      case 'JSON':
        return (await response.json()) as TResponse;
      case 'blob':
        return (await response.blob()) as TResponse;
    }
  } catch (error) {
    console.error('HTTP CLIENT SERIALIZATION ERROR: ', error);
    return {} as TResponse;
  }
};
