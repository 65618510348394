import { useTranslation } from 'react-i18next';

import { ConfirmDialog } from '../ConfirmDialog';

export type IDeleteEntitiesConfirmDialogProps = {
  count: number;
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

export function DeleteEntitiesConfirmDialog(
  props: IDeleteEntitiesConfirmDialogProps
) {
  const { count, open, onConfirm, onClose } = props;
  const { t } = useTranslation();

  return (
    <ConfirmDialog
      open={open}
      title={t('ALERTS.HINT')}
      description={t('ALERTS.DELETE.REASSURE', { count })}
      confirmLabel={t('ALERTS.BTN_DELETE')}
      cancelLabel={t('ALERTS.BTN_ABORT')}
      onConfirm={onConfirm}
      onCancel={onClose}
    />
  );
}
