import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { ObjectLockResultEnum } from '../Enums/ObjectLockResultEnum.enum';

import { User } from './User.entity';

export class SubObjectLockResult<T extends EMode = EMode.entity> {
  /** Alias: application */
  application?: string;
  /** Alias: ownerCode */
  ownerCode?: number;
  /** Alias: lockResult */
  lockResult?: ObjectLockResultEnum;
  /** Alias: creationDatetime */
  creationDatetime?: string;
  /** Alias: objPrimaryKey */
  objPrimaryKey?: any;
  /** Alias: benutzer */
  user?: User<T>;

  __typename?: string;
}

const fields: FieldDefinitions<SubObjectLockResult> = {
  application: {
    alias: 'application',
  },
  ownerCode: {
    alias: 'ownerCode',
  },
  lockResult: {
    alias: 'lockResult',
  },
  creationDatetime: {
    alias: 'creationDatetime',
  },
  objPrimaryKey: {
    alias: 'objPrimaryKey',
  },
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  __typename: {
    alias: '__typename',
  },
};

export const subObjectLockResultEntityDefinition: EntitiyDefinition<SubObjectLockResult> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'SubObjectLockResult',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
