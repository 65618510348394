import Typography from '@mui/material/Typography';

import { VatRate } from '@work4all/models/lib/Classes/VatRate.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export interface IVatPickerProps<TMultiple extends boolean>
  extends IPickerProps<VatRate, TMultiple> {}

const DEFAULT_SORT_BY = 'sentence';
const DEFAULT_SORT_BY_DIRECTION = SortDirection.ASCENDING;

export function VatPicker<TMultiple extends boolean>(
  props: IVatPickerProps<TMultiple>
) {
  const {
    sortBy = DEFAULT_SORT_BY,
    sortByDirection = DEFAULT_SORT_BY_DIRECTION,
    ...rest
  } = props;

  return (
    <ListEntityPicker
      entity={Entities.vatRate}
      data={VAT_FIELDS}
      filterBy={[]}
      sortBy={sortBy}
      sortByDirection={sortByDirection}
      completeDataResponse
      {...rest}
      renderItemContent={(vat) => {
        return (
          <Tooltip title={vat.sentence}>
            <Typography variant="body2" noWrap>
              {vat.sentence} %
            </Typography>
          </Tooltip>
        );
      }}
    />
  );
}

const VAT_FIELDS: VatRate<EMode.query> = {
  id: null,
  sentence: null,
  sequence: null,
  disabled: null,
};
