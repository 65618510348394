import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';

import { useCurrentBreakpoint } from '@work4all/data/lib/hooks/useCurrentBreakpoint';
import { useSetting } from '@work4all/data/lib/settings/use-setting';

import { settings } from '../../../../../../../../settings';
import { CALENDAR_CSS_BREAKPOINTS } from '../../../../../../../calendar/constants';
import { CalendarUserAppearance } from '../../../../../../../calendar/types';

export function UserAppearanceSection() {
  const { t } = useTranslation();

  const breakpoint = useCurrentBreakpoint({
    breakpoints: CALENDAR_CSS_BREAKPOINTS,
  });

  const setting = useSetting(settings.calendarUserAppearance({ breakpoint }));

  return (
    <Collapse title={t('SETTINGS.CALENDAR.USER_APPEARANCE.LABEL')} defaultOpen>
      <CheckboxRadioItem
        control="radio"
        label={t('SETTINGS.CALENDAR.USER_APPEARANCE.INITIALS')}
        checked={setting.value === CalendarUserAppearance.Initials}
        onChange={() => setting.set(CalendarUserAppearance.Initials)}
      />
      <CheckboxRadioItem
        control="radio"
        label={t('SETTINGS.CALENDAR.USER_APPEARANCE.AVATAR')}
        checked={setting.value === CalendarUserAppearance.Avatar}
        onChange={() => setting.set(CalendarUserAppearance.Avatar)}
      />
    </Collapse>
  );
}
