import * as React from 'react';

import { useGetTravelReceiptsStatus } from '@work4all/components/lib/components/entity-preview/travel-receipts-preview/use-get-travel-receipts-status';

import { TravelExpenses } from '@work4all/models/lib/Classes/TravelExpenses.entity';

type Props = {
  row: { original: { travelExpenses?: TravelExpenses } };
};

export const TravelStatusCell = (props: Props) => {
  const travelExpenses = props.row.original?.travelExpenses;

  const getTravelReceiptsStatus = useGetTravelReceiptsStatus();

  return <div>{getTravelReceiptsStatus(travelExpenses).label}</div>;
};
