import styles from './CardActionArea.module.scss';

import { CardActionArea as MUICardActionArea } from '@mui/material';
import React from 'react';

type MUICoponentType = typeof MUICardActionArea;

export const CardActionArea: MUICoponentType = (props) => {
  return <MUICardActionArea className={styles.area} {...props} />;
};
