import { FC, PropsWithChildren } from 'react';

import { useTableFooterContext } from '../../components/table-footer/TableFooterContext';

export function createFooterCell<P, T>(Component: FC<P>, defaultValue: T) {
  return function WrappedFooterCell(props: PropsWithChildren<P>) {
    const { data } = useTableFooterContext();

    // @ts-expect-error The `accessor` property exists on all columns. The
    // library is probably not typed correctly.
    const value = props.column.accessor(data) ?? defaultValue;

    return <Component {...props} value={value} />;
  };
}
