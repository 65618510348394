import styles from './MaskTabs.module.scss';

import Tabs from '@mui/material/Tabs';

import { useMaskTabContext } from './MaskTabContext';

export interface MaskTabsProps {
  children?: React.ReactNode;
}

const classes = { flexContainer: styles.flexContainer };

export function MaskTabs(props: MaskTabsProps) {
  const { children } = props;

  const context = useMaskTabContext();

  const { value, onChange } = context;

  function handleChange(_event: React.SyntheticEvent, value: string | number) {
    onChange?.(value);
  }

  return (
    <Tabs
      classes={classes}
      value={value}
      onChange={handleChange}
      TabIndicatorProps={{ hidden: true }}
      variant="scrollable"
    >
      {children}
    </Tabs>
  );
}
