import { createContext, useContext, useMemo, useState } from 'react';

import { PostIt } from '@work4all/models/lib/Classes/PostIt.entity';

export interface YellowNoteContextValue {
  data: PostIt | null;
  updateYellowNote(content: string): Promise<void>;
  deleteYellowNote(): Promise<void>;
  isOpen: boolean | undefined;
  setOpen(open: boolean): void;
}

const Context = createContext<YellowNoteContextValue | null>(null);

export function YellowNoteContext(
  props: React.PropsWithChildren<
    Pick<
      YellowNoteContextValue,
      'data' | 'updateYellowNote' | 'deleteYellowNote'
    >
  >
) {
  const { data, updateYellowNote, deleteYellowNote, children } = props;

  const [isOpenExplicit, setOpenExplicit] = useState<boolean | undefined>(
    undefined
  );

  const isOpenImplicit = data != null;
  const isOpen = isOpenExplicit ?? isOpenImplicit;

  const context = useMemo<YellowNoteContextValue>(() => {
    const context = {
      data,
      updateYellowNote,
      deleteYellowNote,
      isOpen,
      setOpen: setOpenExplicit,
    };
    return context;
  }, [data, updateYellowNote, deleteYellowNote, isOpen, setOpenExplicit]);

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

export function useYellowNoteContext(): YellowNoteContextValue {
  const context = useContext(Context);

  if (context === null) {
    throw new Error(
      `useYellowNoteContext must be used within a <YellowNoteContext>`
    );
  }

  return context;
}
