import { Typography } from '@mui/material';
import { capitalize } from 'lodash';
import React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ListEntityPicker,
  ListEntityPickerWithTabsProps,
} from '@work4all/components/lib/components/entity-picker/components/list-entity-picker';
import { IPickerProps } from '@work4all/components/lib/components/entity-picker/types';

import { IResponse } from '@work4all/data';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { ContactUnionWrapper } from '@work4all/models/lib/Classes/ContactUnionWrapper.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { DataRequest, SortDirection } from '@work4all/models/lib/DataProvider';
import { ContactKind } from '@work4all/models/lib/Enums/ContactKind.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { abbreviate } from '../../contactpicker/ContactRessourcePicker';

import {
  CONTACT_FIELDS,
  filterUniqueBusinessPartners,
  getRessourceName,
  prepareRequestAll,
  prepareRequestInternal,
  renderChipContent,
  transformResponse,
} from './utils';

export const renderItemContent = (
  resource: ContactUnionWrapper
): React.ReactNode => {
  const value = resource.data as User & Contact & Customer & Supplier;

  const title = abbreviate(getRessourceName(resource), 25);
  const subtitle = abbreviate(value?.businessPartner?.data?.name, 20);

  return (
    <Typography component="div">
      <span>{title}</span>
      {subtitle ? (
        <Typography
          color="text.tertiary"
          component="span"
        >{` | ${subtitle}`}</Typography>
      ) : null}
    </Typography>
  );
};

export interface AttendeePickerProps
  extends Omit<
    IPickerProps<ContactUnionWrapper, true>,
    'multiple' | 'data' | 'prefilter'
  > {
  businessPartner?: Customer | Supplier | null;
}

export function AttendeesPicker(props: AttendeePickerProps) {
  const { t } = useTranslation();

  const tabs = useMemo(() => {
    const uniqueBusinessPartners = filterUniqueBusinessPartners(
      props.businessPartner,
      props.value
    );

    type TabProps = ListEntityPickerWithTabsProps<
      ContactUnionWrapper,
      true
    >['tabs'][number];

    const tabs: TabProps[] = [
      {
        label: t('COMMON.ALL'),
        data: null,
        filterBy: null,
        sortBy: null,
        entity: null,
        renderItemContent: renderItemContent,
        prepareRequest: prepareRequestAll,
        transformResponse: transformResponse,
      },
      {
        label: t('COMMON.COLLEAGUES'),
        data: null,
        filterBy: null,
        sortBy: null,
        entity: null,
        renderItemContent: renderItemContent,
        prepareRequest: prepareRequestInternal,
        transformResponse: transformResponse,
      },
      ...uniqueBusinessPartners.map<TabProps>((bp) => {
        return {
          label: bp.name,
          data: null,
          filterBy: null,
          sortBy: null,
          entity: null,
          renderItemContent: renderItemContent,

          prepareRequest({ search }) {
            const result: DataRequest = {
              entity: Entities.contact,
              data: CONTACT_FIELDS.data.contact,
              sort: [
                { field: 'displayName', direction: SortDirection.ASCENDING },
              ],
              filter: [
                { businessPartnerType: { $eq: bp.type } },
                { businessPartnerId: { $eq: bp.id } },
                { layedOff: { $eq: false } },
                {
                  displayName: {
                    $eq: '%' + search.split(' ').join('%') + '%',
                  },
                },
              ],
            };

            return result;
          },
          transformResponse(response: IResponse<Contact>) {
            return { ...response, data: response.data.map(transformContact) };
          },
        };
      }),
    ];

    return tabs;
  }, [props.businessPartner, props.value, t]);

  return (
    <ListEntityPicker
      multiple={true}
      layout="advanced"
      tabs={tabs}
      renderChipContent={renderChipContent}
      {...props}
    />
  );
}

function transformContact(
  item: Contact | ContactUnionWrapper
): ContactUnionWrapper {
  if (!('businessPartnerType' in item)) {
    // This is not a contact (or the data provider was not configured
    // correctly). Return the original object and hope for the best.
    //
    // This can happen because of a bug (or not?) in the data provider, where
    // the old response will be returned when the request object changes even if
    // you request something completely different. In this case it will keep
    // returning the results for the previously selected tab, until the new
    // results are loaded.
    return item as ContactUnionWrapper;
  }

  const contactKind =
    item.businessPartnerType === SdObjType.KUNDE
      ? ContactKind.KUNDENANSPRECHPARTNER
      : ContactKind.LIEFERANTENANSPRECHPARTNER;

  const contact: ContactUnionWrapper = {
    id: `${capitalize(contactKind)}_${item.id}`,
    contactKind,
    data: item,
  };

  return contact;
}
