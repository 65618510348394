import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { AppointmentAttendee } from '@work4all/models/lib/Classes/AppointmentAttendee.entity';

export function filterAttendeesList(props: Appointment) {
  const appointment = props;
  const filteredAttendeesList: AppointmentAttendee[] = [];
  const seenIds = new Set();
  if (!appointment?.appointmentAttendeeList) {
    return filteredAttendeesList;
  } else {
    for (const item of appointment.appointmentAttendeeList) {
      const id =
        item.user?.id ?? item.contact?.id ?? item.businessPartner?.data?.id;

      if (!seenIds.has(id)) {
        filteredAttendeesList.push(item);
        seenIds.add(id);
      }
    }
    return filteredAttendeesList;
  }
}
