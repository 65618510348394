import { useMemo } from 'react';
import React from 'react';

import {
  ITempFileManagerContext,
  useTempFileManager,
} from '@work4all/data/lib/hooks/data-provider/useTempFileManager';

import { DeliveryNote } from '@work4all/models/lib/Classes/DeliveryNote.entity';

import { PathsOf } from '@work4all/utils/lib/paths-of/paths-of';

import { useFormContextPlus } from '../../../../../form-plus/use-form-context-plus';

export const SignatureContext =
  React.createContext<ITempFileManagerContext | null>(null);

export const SignatureProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const { watch } = useFormContextPlus<PathsOf<DeliveryNote, 2>>();
  const signature = watch('signature');
  const array = useMemo(() => {
    if (signature) return [signature];
    return [];
  }, [signature]);

  const tempFileManager = useTempFileManager(array);
  return (
    <SignatureContext.Provider value={tempFileManager}>
      {children}
    </SignatureContext.Provider>
  );
};
