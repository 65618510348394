import { createContext, useContext } from 'react';

import { DocumentClass } from '@work4all/models/lib/Classes/DocumentClass.entity';

export interface DocumentClassesContextValue {
  documentClasses: DocumentClass[];
}

const Context = createContext<DocumentClassesContextValue | null>(null);

export const DocumentClassesContextProvider =
  Context.Provider as React.Provider<DocumentClassesContextValue>;

export function useDocumentClassesContext(): DocumentClassesContextValue {
  const context = useContext(Context);

  if (context === null) {
    throw new Error(
      'useDocumentClassesContext must be used within <DocumentClassesContextProvider>'
    );
  }

  return context;
}
