import React, { useContext, useState } from 'react';

interface IPrintBag {
  printing: boolean;
  handlePrint: () => void;
  setPrinting: React.Dispatch<React.SetStateAction<boolean>>;
}

const PrintBagContext = React.createContext<IPrintBag | null>(null);

export const PrintBagProvider: React.FC = (props) => {
  const [printing, setPrinting] = useState(false);
  const handlePrint = () => {
    setPrinting(true);
  };

  return (
    <PrintBagContext.Provider value={{ printing, setPrinting, handlePrint }}>
      {props.children}
    </PrintBagContext.Provider>
  );
};

export const usePrintBag = () => {
  const bag = useContext(PrintBagContext);
  if (!bag) {
    throw new Error(
      'bag is undefined. Make sure that your component is rendered inside <PrintBagProvider>'
    );
  }

  return bag;
};

export const useHandlePrint = () => {
  const bag = usePrintBag();
  return bag.handlePrint;
};
