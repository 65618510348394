import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { IconButton, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

import { FileEntity } from '@work4all/models/lib/Classes/FileEntity.entity';

export interface NavigationItem {
  fileInfos?: FileEntity;
  id?: number | string;
}

export function usePreviewNavigation<T extends NavigationItem>(list: T[]) {
  const [current, setCurrent] = useState<number | null>(0);

  const isSet = list.length > 1;
  const currentClamped = Math.min(current, list.length - 1);

  const component = isSet ? (
    <Stack direction="row" alignItems="center">
      <IconButton
        color="primary"
        onClick={() => setCurrent((prev) => Math.max(0, prev - 1))}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <Typography
        display="flex"
        justifyContent="center"
        width="3.375rem"
        variant="body2"
      >
        {`${currentClamped + 1} / ${list.length}`}
      </Typography>
      <IconButton
        color="primary"
        onClick={() =>
          setCurrent((prev) => Math.min(list.length - 1, prev + 1))
        }
      >
        <KeyboardArrowRightIcon />
      </IconButton>
    </Stack>
  ) : null;

  return { isSet, current, component, file: list[currentClamped] };
}
