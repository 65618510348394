import EmailIcon from '@mui/icons-material/Email';
import { MenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton/IconButton';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { EmailTemplatesPopover } from '@work4all/components';
import { useEmailTemplates } from '@work4all/components/lib/hooks/use-email-templates';

import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';

interface EmailTemplateButtonContextProps {
  openEmailMask: (template: EMailTemplate) => Promise<void>;
  kind: EMailTemplateKind;
}

interface EmailTemplateButtonContextState {
  emailTempaltePickerButtonRef: React.MutableRefObject<undefined>;
  onClick: () => void;
}

const EmailTemplateButtonContext =
  createContext<EmailTemplateButtonContextState | null>(null);

export const EmailTemplateButtonProvider = (
  props: PropsWithChildren<EmailTemplateButtonContextProps>
) => {
  const { children, openEmailMask, kind } = props;

  const [pickEmailTemplateOpen, setPickEmailTemplateOpen] = useState(false);
  const emailTempaltePickerButtonRef = useRef();
  const templates = useEmailTemplates(kind);

  const sortedTemplates = useMemo(() => {
    return [...templates]?.sort((a, b) => {
      const firstName = a.name;
      const secondName = b.name;
      return firstName.localeCompare(secondName);
    });
  }, [templates]);

  const value = useMemo(() => {
    return {
      emailTempaltePickerButtonRef,
      onClick: () => {
        if (sortedTemplates.length > 1) {
          setPickEmailTemplateOpen(true);
        } else {
          openEmailMask(sortedTemplates[0]);
        }
      },
    };
  }, [openEmailMask, sortedTemplates]);

  return (
    <EmailTemplateButtonContext.Provider value={value}>
      {pickEmailTemplateOpen && (
        <EmailTemplatesPopover
          templates={sortedTemplates}
          anchorEl={emailTempaltePickerButtonRef.current}
          onClose={() => setPickEmailTemplateOpen(false)}
          onTemplateClick={(template) => {
            setPickEmailTemplateOpen(false);
            openEmailMask(template);
          }}
        />
      )}

      {children}
    </EmailTemplateButtonContext.Provider>
  );
};

const useEmailTemplateButton = () => {
  const state = useContext(EmailTemplateButtonContext);
  if (!state) {
    throw new Error(
      'Context is null. Make sure you wrapped your component with <EmailTemplateButtonContext />'
    );
  }
  return state;
};

export const EmailTemplateIconButton = () => {
  const { emailTempaltePickerButtonRef, onClick } = useEmailTemplateButton();
  return (
    <IconButton
      ref={emailTempaltePickerButtonRef}
      color="primary"
      onClick={onClick}
    >
      <EmailIcon />
    </IconButton>
  );
};

export const EmailTemplateMenuItemButton = () => {
  const { t } = useTranslation();
  const { emailTempaltePickerButtonRef, onClick } = useEmailTemplateButton();
  return (
    <MenuItem
      key={'email'}
      ref={emailTempaltePickerButtonRef}
      onClick={onClick}
    >
      {t('TICKET.SEND_EMAIL')}
    </MenuItem>
  );
};
