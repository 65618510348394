import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputRePaymentRelation } from './InputRePaymentRelation.entity';

export class InputReRelation<T extends EMode = EMode.entity> {
  /** Alias: payments */
  payments?: InputRePaymentRelation<T>;

  __typename?: string;
}

const fields: FieldDefinitions<InputReRelation> = {
  payments: {
    alias: 'payments',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputReRelationEntityDefinition: EntitiyDefinition<InputReRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputReRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
