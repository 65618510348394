import styles from './TicketStatusPicker.module.scss';

import { CheckCircleRounded, CircleTwoTone } from '@mui/icons-material';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TicketStatus } from '@work4all/models/lib/Enums/TicketStatus.enum';

import { Body1 } from '../../../typography/body1/Body1';
import { FixedDataPicker } from '../fixed-data-picker/FixedDataPicker';
import { IPickerProps } from '../types';

export const TicketStatusChangeLookUp = {
  [TicketStatus.OFFEN]: [TicketStatus.OFFEN],
  [TicketStatus.IN_BEARBEITUNG]: [TicketStatus.IN_BEARBEITUNG],
  [TicketStatus.ERLEDIGT]: [TicketStatus.ERLEDIGT],
  [TicketStatus.ZURUECKGESTELLT]: [TicketStatus.ZURUECKGESTELLT],
};

export type ITicketStatusPickerProps<TMultiple extends boolean> = IPickerProps<
  { id: string; name: string },
  TMultiple
> & {
  availableStatus?: TicketStatus[];
};

export function TicketStatusPicker<TMultiple extends boolean>(
  props: ITicketStatusPickerProps<TMultiple>
) {
  const { availableStatus, ...rest } = props;

  const { t } = useTranslation();

  const dataSet = useMemo(() => {
    let result = [
      {
        id: TicketStatus.OFFEN,
        name: t('TICKET_STATUS.' + TicketStatus.OFFEN),
      },
      {
        id: TicketStatus.IN_BEARBEITUNG,
        name: t('TICKET_STATUS.' + TicketStatus.IN_BEARBEITUNG),
      },
      {
        id: TicketStatus.ERLEDIGT,
        name: t('TICKET_STATUS.' + TicketStatus.ERLEDIGT),
      },
      {
        id: TicketStatus.ZURUECKGESTELLT,
        name: t('TICKET_STATUS.' + TicketStatus.ZURUECKGESTELLT),
      },
    ];

    if (availableStatus) {
      result = result.filter((x) => availableStatus.includes(x.id));
    }

    return result;
  }, [availableStatus, t]);

  return (
    <FixedDataPicker
      dataSet={dataSet}
      {...rest}
      renderItemContent={(item) => {
        const value = item.id;
        const Icon =
          value === TicketStatus.ERLEDIGT ? CheckCircleRounded : CircleTwoTone;
        return (
          <div className={styles['task-status-cell-root']}>
            <Icon
              className={clsx(styles['check-circle'], {
                [styles['check-circle-success']]:
                  value === TicketStatus.ERLEDIGT,
                [styles['check-circle-edit']]:
                  value === TicketStatus.IN_BEARBEITUNG ||
                  value === TicketStatus.ZURUECKGESTELLT,
              })}
            />
            <Body1 className={styles['label']}>{item.name}</Body1>
          </div>
        );
      }}
    />
  );
}
