import styles from './NotificationLayout.module.scss';

import clsx from 'clsx';

import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

export interface NotificationLayoutProps
  extends React.HTMLAttributes<HTMLDivElement> {
  unread: boolean;
}

export function NotificationLayout(props: NotificationLayoutProps) {
  const { className, unread, ...others } = props;

  return (
    <div
      className={clsx(styles.root, unread && styles.unread, className)}
      {...others}
    />
  );
}

export const NOTIFICATION_HEIGHT_PX = remToPx(3.5);
