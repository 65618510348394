import { useCallback } from 'react';

import { Position } from '@work4all/models/lib/Classes/Position.entity';

import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { ShadowObjectAddPositionArgs } from '../../../../../hooks/use-bz-shadow-object-api/use-shadow-bz-object-api';
import { useShadowBzObjectApiContext } from '../../../../../hooks/use-bz-shadow-object-api/use-shadow-bz-object-api-context';
import {
  useEditableState,
  UseEditableStateProps,
} from '../edit-table/hooks/use-editable-state';

import { recalculatePositions } from './recalculatePositions';

export type UsePostionState = UseEditableStateProps<
  Position,
  number,
  ShadowObjectAddPositionArgs
>;

interface UsePostionStateProps extends Pick<UsePostionState, 'positions'> {}

export const usePositionState = (props: UsePostionStateProps) => {
  const { positions } = props;
  const shadowBzObjectApi = useShadowBzObjectApiContext();

  const { setValue } = useFormContextPlus<{ value?: number }>();

  const mapAddContext = useCallback((context: ShadowObjectAddPositionArgs) => {
    const { article } = context;
    return {
      singlePriceNet: 0,
      singlePriceGross: 0,
      singleWeight: 0,
      totalPriceNet: 0,
      totalPriceGross: 0,
      purchasePrice: 0,
      vat: 0,
      amount: 1,
      unit: article?.unit?.name ?? '',
      longtext: article?.name,
      shortText: article?.name,
      articleNumber: article?.number,
      ownArticleNumber: article?.number,
    } as Position;
  }, []);

  const mutateState = useCallback(
    (input: Position[]) => {
      const { result, total } = recalculatePositions(input);
      setValue('value', total);
      return result;
    },
    [setValue]
  );

  return useEditableState<Position, number, ShadowObjectAddPositionArgs>({
    positions,
    onAddPosition: shadowBzObjectApi.addPosition,
    onRemovePosition: shadowBzObjectApi.removePosition,
    onMovePosition: shadowBzObjectApi.movePosition,
    onEditPosition: shadowBzObjectApi.editPosition,
    mapAddContext,
    mutateState,
  });
};
