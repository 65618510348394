import { createContext, useContext } from 'react';

import { AppearanceBackgroundImage } from '@work4all/models/lib/Classes/AppearanceBackgroundImage.entity';

export interface CustomBackgroundsContextValue {
  isLoading: boolean;
  isActive: boolean;
  standardBackgrounds: AppearanceBackgroundImage[];
  customBackgrounds: AppearanceBackgroundImage[];
  activeBackground: AppearanceBackgroundImage | null;
  setActiveBackground: (id: string) => void;
  resetActiveBackground: () => void;
  addBackground: (file: File) => void;
  removeBackground: (id: string) => void;
}

export const CustomBackgroundsContext =
  createContext<CustomBackgroundsContextValue | null>(null);

export function useCustomBackgroundsContext(): CustomBackgroundsContextValue {
  const context = useContext(CustomBackgroundsContext);

  if (context === null) {
    throw new Error(
      'useCustomBackgroundsContext must be used within a CustomBackgroundsContext'
    );
  }

  return context;
}
