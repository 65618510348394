import { GraphQLErrors } from '@apollo/client/errors';
import { GraphQLError } from 'graphql';

import { translateInputField } from '@work4all/components/lib/dataDisplay/basic-table/hooks/query-table-data/hooks/translate-utils';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export enum ApiErrorCodes {
  ALREADY_EXIST = 'BzObject_NumberAlreadyExist',
  POSITION_IS_SET_AS_COMPLETED = 'TimeTracking_SelectedPositionIsSetAsCompleted',
  FILE_NOT_FOUND = 'FILE_NOT_FOUND',
  TIME_TRACKING_EDIT_NOT_ALLOWED = 'TimeTracking_EditNotAllowed',
  USER_RIGHTS = 'USER_RIGHTS',
}

const COMPONENT_SPECIFIC_HANDLED_CODES = [
  ApiErrorCodes.ALREADY_EXIST,
  ApiErrorCodes.POSITION_IS_SET_AS_COMPLETED,
  ApiErrorCodes.FILE_NOT_FOUND,
];

const TRANSLATED_ERRORS = [
  ApiErrorCodes.USER_RIGHTS,
  ApiErrorCodes.TIME_TRACKING_EDIT_NOT_ALLOWED,
];

const hasTranslation = (err: GraphQLError) => {
  return TRANSLATED_ERRORS.includes(err.extensions?.code);
};

const shouldShowError = (errors: GraphQLErrors) => {
  return errors.some(
    (e) => !COMPONENT_SPECIFIC_HANDLED_CODES.includes(e.extensions?.code)
  );
};

const parseErrors = <T>(
  errors: GraphQLErrors,
  entity?: Entities
): ApiErrors<T> => {
  return errors.map((x) => {
    return {
      message: x.message,
      code: x.extensions.code,
      property: x.extensions.data?.info?.Property
        ? (translateInputField(
            x.extensions.data.info.Property,
            entity
          ) as keyof T)
        : undefined,
    };
  });
};

export const ValidationErrors = {
  hasTranslation,
  shouldShowError,
  parseErrors,
};

export type ApiErrors<T = unknown> = {
  message: string;
  property: keyof T | undefined;
  code: string;
}[];
