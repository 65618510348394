import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import ViewListIcon from '@mui/icons-material/ViewList';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BaseActionProps } from '../../actions/types';
import { HorizontalSelect } from '../HorizontalSelect';

type Props = BaseActionProps & {
  layout?: 'cards' | 'table';
  handleClick?: (layout: 'cards' | 'table') => void;
};

export const LayoutHorizontalSelect: React.FC<Props> = (props) => {
  const { layout, handleClick } = props;
  const { t } = useTranslation();

  const items = [
    {
      value: 'table',
      Icon: ViewHeadlineIcon,
      title: t('LISTS_PAGES.LAYOUT_LIST'),
    },
    {
      value: 'cards',
      Icon: ViewListIcon,
      title: t('LISTS_PAGES.LAYOUT_CARD'),
    },
  ];

  return (
    <HorizontalSelect items={items} onClick={handleClick} value={layout} />
  );
};
