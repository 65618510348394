import styles from './FooterCell.module.scss';

import clsx from 'clsx';
import React, { useContext } from 'react';

import { ColumnVisibilityContext } from '../../../../hooks/useColumnVisibility';
import { ColumnInstance, ICssClasses } from '../../../../types';
import { ITableCellProps, TableCell } from '../../../table-cell/TableCell';
import { FooterCellContent } from '../footer-cell-content/FooterCellContent';
import { FooterCellWrapper } from '../footer-cell-wrapper/FooterCellWrapper';

export interface IFooterCellProps {
  col: ColumnInstance;
  cellRef?: React.ForwardedRef<HTMLDivElement>;
  rootRef?: React.ForwardedRef<HTMLDivElement>;
  cellProps?: ITableCellProps;
  classes?: ICssClasses;
}

export const FooterCell: React.FC<IFooterCellProps> = (props) => {
  const { col, rootRef, cellRef, cellProps } = props;

  const noFlex = col.noFlex;

  const columnVisibilityContext = useContext(ColumnVisibilityContext);

  return (
    <div
      ref={rootRef}
      className={clsx(styles.headerCellRoot, {
        [styles.noFlex]: noFlex,
      })}
      {...col.getHeaderProps()}
    >
      <FooterCellWrapper>
        <TableCell
          className={clsx(styles['cell'], {
            [styles.highlighted]:
              columnVisibilityContext?.toggledColumnId === col.id,
          })}
          dataType={col.dataType}
          alignment={col.alignment}
          ref={cellRef}
          {...cellProps}
        >
          <FooterCellContent col={col} />
        </TableCell>
      </FooterCellWrapper>
    </div>
  );
};
