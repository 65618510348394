import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';

export type ContactOrBusinessPartner = Contact | Customer | Supplier;

export function isContact(
  contact: ContactOrBusinessPartner
): contact is Contact {
  return contact?.__typename === 'Ansprechpartner';
}

export function isCustomer(
  contact: ContactOrBusinessPartner
): contact is Customer {
  return contact?.__typename === 'Kunde';
}

export function isSupplier(
  contact: ContactOrBusinessPartner
): contact is Supplier {
  return contact?.__typename === 'Lieferant';
}

export function isBusinessPartner(
  contact: ContactOrBusinessPartner
): contact is Customer | Supplier {
  return isCustomer(contact) || isSupplier(contact);
}
