import styles from './styles.module.scss';

import { Link, Stack } from '@mui/material';
import { FC, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { InlineData } from './InlineData';

type IContactCardProps = {
  email: string;
  phoneNumber: string;
  website?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onPhoneNumberClick: () => void;
};

export const ContactCard: FC<IContactCardProps> = ({
  email,
  phoneNumber,
  onPhoneNumberClick,
  website,
}) => {
  const { t } = useTranslation();
  const createEmailHref = () => `mailto:${email}`;

  const createWebsiteHref = () =>
    website === undefined
      ? undefined
      : website.trim().startsWith('http')
      ? website
      : 'https://' + website;

  return (
    <>
      <InlineData
        label={t('FILE_CONTACTS.PHONE')}
        content={
          <div className={styles['contact-buttons']}>
            {!phoneNumber && '-'}
            {phoneNumber && (
              <Link
                onClick={onPhoneNumberClick}
                underline="none"
                className={styles['link']}
              >
                {phoneNumber}
              </Link>
            )}
          </div>
        }
      />
      <InlineData
        label={t('FILE_CONTACTS.CONTACT_OWN')}
        content={
          <div className={styles['contact-buttons']}>
            {!email && '-'}
            {email && (
              <Stack flexDirection="row" gap="1rem">
                {!website && !email && '-'}
                {website ? (
                  <Link
                    href={createWebsiteHref()}
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="none"
                    className={styles['link']}
                  >
                    {website}
                  </Link>
                ) : null}

                {email ? (
                  <Link
                    href={createEmailHref()}
                    underline="none"
                    className={styles['link']}
                  >
                    {email}
                  </Link>
                ) : null}
              </Stack>
            )}
          </div>
        }
      />
    </>
  );
};
