import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IResponse } from '@work4all/data';

import { MailAssignStatus } from '@work4all/models/lib/Enums/MailAssignStatus.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';

export type MailAssignStatusPickerProps = {
  value: Item[];
  onChange: (value: Item[]) => void;
};

type Item = { id: MailAssignStatus; name: string };

export function MailAssignStatusPicker(props: MailAssignStatusPickerProps) {
  const { value, onChange } = props;

  const { t } = useTranslation();

  const dataset = useMemo<IResponse<Item>>(() => {
    const options: Item[] = [
      {
        id: MailAssignStatus.NOT_YET_ASSIGNED,
        name: t('MAIL_ASSIGN_STATUS.NOT_YET_ASSIGNED'),
      },
      {
        id: MailAssignStatus.ALREADY_ASSIGNED,
        name: t('MAIL_ASSIGN_STATUS.ALREADY_ASSIGNED'),
      },
      {
        id: MailAssignStatus.ITEM_IGNORED,
        name: t('MAIL_ASSIGN_STATUS.ITEM_IGNORED'),
      },
    ];

    return {
      loading: false,
      pending: false,
      data: options,
      total: options.length,
      fetchMore: () => Promise.resolve(),
      refetch: () => Promise.resolve(null),
    };
  }, []);

  const renderItem = (item: Item) => {
    const label = item.name;

    return (
      <Tooltip title={label}>
        <Typography variant="body2" noWrap>
          {label}
        </Typography>
      </Tooltip>
    );
  };

  // const value = useMemo(() => {
  //   if (!valueProp) return null;

  //   return valueProp
  //     .map((id) => dataset.data.find((item) => item.id === id))
  //     .filter(Boolean);
  // }, [valueProp, dataset.data]);

  // const onChange = useCallback(
  //   (values: Item[]) => {
  //     const ids = values.map((value) => value.id);

  //     onChangeProp?.(ids);
  //   },
  //   [onChangeProp]
  // );

  return (
    <ListEntityPicker
      multiple={true}
      entity={null}
      data={null}
      filterBy="name"
      sortBy="name"
      fixedDataSet={dataset}
      renderItemContent={renderItem}
      value={value}
      onChange={onChange}
    />
  );
}
