import styles from './DoubleClickShield.module.scss';

import React, { useEffect, useState } from 'react';
import { of, timer } from 'rxjs';
import { delayWhen } from 'rxjs/operators';

interface IDoubleClickShield {
  active: boolean;
  timeout?: number;
}
/**
 * https://forum.playcanvas.com/t/solved-issue-with-click-event-on-touch-screen/6073
 * Relevant for chrome browser
 * Browser after the sequence of touchStart, touchEnd
 * always replays this sequence as mouseDown mouseUp for compatibility reasons
 *
 * Example:
 * Click on the red button. It shows the blue button and prints “click 1”.
 * Then click on the blue button. It prints “click 2”.
 * On mobile, as soon as I touch the red button, it prints “click 1” and “click 2”.
 *
 * Click will be replayed on "shield" instead of you button.
 *
 * @param {boolean} active Active "shield"
 * @param {number} timeout due "shield" should be decativated
 * @returns {React.ReactElement}
 */
export const DoubleClickShield: React.FC<IDoubleClickShield> = (props) => {
  const { active, timeout = 100 } = props;

  const [shieldActive, setShieldActive] = useState(props.active);

  useEffect(() => {
    const subscription = of(active)
      .pipe(delayWhen((active) => timer(active ? 0 : timeout)))
      .subscribe(setShieldActive);

    return () => {
      subscription.unsubscribe();
    };
  }, [active, timeout]);

  if (!shieldActive) {
    return null;
  }

  return <div className={styles.shield} />;
};
