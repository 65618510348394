import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as GoIcon } from '@work4all/assets/icons/go.svg';

import { Base } from '../base/Base';
import { BaseActionProps } from '../types';

export const Go: React.FC<BaseActionProps> = (props) => {
  const { t } = useTranslation();

  return <Base title={t('INPUTS.GO')} icon={<GoIcon />} {...props} />;
};
