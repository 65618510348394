import { Typography } from '@mui/material';
import { useContext } from 'react';

import { AttachmentRenameContext } from './attachment-rename-context';
import { EditableFileName } from './EditableFileName';

export interface EditableAttachmentFileNameProps {
  fileId: string;
  originalFileName: string;
}

export function EditableAttachmentFileName(
  props: EditableAttachmentFileNameProps
) {
  const { fileId, originalFileName } = props;

  const renameContext = useContext(AttachmentRenameContext);

  const disabled = renameContext.disabled || fileId == null;

  if (disabled) {
    return (
      <Typography
        variant="body1"
        noWrap
        title={originalFileName}
        textAlign="center"
      >
        {originalFileName}
      </Typography>
    );
  }

  return (
    <EditableFileName
      value={renameContext.getName(fileId) ?? originalFileName}
      onChange={(value) => {
        renameContext.setName(fileId, value);
      }}
    />
  );
}
