import { useEffect, useState } from 'react';

import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { AppointmentOverlayController } from '../../mask-overlays/mask-overlay/views/appointment/AppointmentOverlayController';

export interface AppointmentMaskOverlayProps {
  open: boolean;
  onClose: () => void;
  appointmentId: number | null;
  presetFields?: string | null;
}

export function AppointmentMaskOverlay(props: AppointmentMaskOverlayProps) {
  const { open, onClose, appointmentId, presetFields } = props;

  const [view, setView] = useState(null);

  useEffect(() => {
    if (open) {
      setView({
        view: (
          <AppointmentOverlayController
            entity={Entities.appointment}
            id={appointmentId}
            params={{ presetFields }}
          />
        ),
      });
    }
  }, [appointmentId, open, presetFields]);

  return (
    <NavigationOverlay
      open={open}
      initialView={view}
      close={onClose}
      onExited={() => {
        setView(null);
      }}
    />
  );
}
