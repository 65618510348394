import { useState } from 'react';

import { TicketFilter } from '@work4all/models/lib/Classes/TicketFilter.entity';
import { TicketKind } from '@work4all/models/lib/Classes/TicketKind.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PersonalAndGeneralView } from '../../../filters/PersonalAndGeneralView';
import { TableQuickFilters } from '../../table-quick-filters';

import { KindFilters } from './filters/KindFilters';

export const TicketsTableQuickFilters = () => {
  const [selectedFilter, setSelectedFilter] = useState<
    TicketFilter | TicketKind
  >();

  return (
    <TableQuickFilters>
      <KindFilters
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
      />
      <PersonalAndGeneralView<TicketFilter | TicketKind>
        value={selectedFilter}
        onChange={(value) => {
          setSelectedFilter(value);
        }}
        entity={Entities.ticket}
      />
    </TableQuickFilters>
  );
};
