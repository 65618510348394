import styles from './DropdownFooter.module.scss';

import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BaseActionButton } from '../../../base-action-button/BaseActionButton';
import { ActionType } from '../../types';

interface IDropdownFooter {
  actions: ActionType[];
  className?: string;
}

export const DropdownFooter: React.FC<IDropdownFooter> = (props) => {
  const { t } = useTranslation();

  return (
    <div className={clsx(styles.footer, props.className)}>
      {props.actions.map((el, idx) => {
        const actionProps = { href: undefined, onClick: undefined };
        if (el.handler) {
          actionProps.onClick = el.handler;
        } else if (el.route) {
          actionProps.href = el.route;
        }
        return (
          <BaseActionButton
            key={idx}
            title={t(el.title)}
            icon={<el.icon />}
            {...actionProps}
          />
        );
      })}
    </div>
  );
};
