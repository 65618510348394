import styles from './LinkMailboxDialog.module.scss';

import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useLayoutEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogActions,
  DialogContent,
} from '@work4all/components/lib/dialog';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input';

import { useUser } from '@work4all/data';

import { assertNever } from '@work4all/utils';

import { MailboxEncryptionPickerField } from './MailboxEncryptionPickerField';
import { MailboxTypePickerField } from './MailboxTypePickerField';
import { MailboxEncryption, MailboxType } from './types';

const FORM_ID = 'link-mailbox-dialog-form';
const REDIRECT_URL = 'https://mobil.work4all.de/microsoft-auth/';
const SMTP_CONFIGURATION_ERROR = 'SMTP configuration error';

export interface LinkMailboxDialogProps {
  open: boolean;
  onClose: () => void;
}

type Values = {
  type: MailboxType;
  email: string;
  password: string;
  smtpHost: string;
  smtpPort: number;
  smtpSecurity: MailboxEncryption;
  imapHost: string;
  imapPort: number;
  imapSecurity: MailboxEncryption;
};

const DEFAULT_VALUES: Values = {
  type: 'microsoft',
  email: '',
  password: '',
  smtpHost: '',
  smtpPort: null,
  smtpSecurity: null,
  imapHost: '',
  imapPort: null,
  imapSecurity: null,
};

async function validateResponse(
  response: Response,
  defaultErrorMessage = ''
): Promise<void> {
  if (!response.ok) {
    // If the request is not successful, display the error returned message
    // (if there is one).
    let message: string = defaultErrorMessage;

    try {
      const responseMessage = await response.json();
      if (responseMessage) {
        if (typeof responseMessage === 'string') {
          message = responseMessage;
        } else if (
          typeof responseMessage === 'object' &&
          typeof responseMessage.detail === 'string'
        ) {
          message = responseMessage.detail;
        }
      }
    } catch {
      // Noop
    }

    throw new Error(message);
  }
}

export function LinkMailboxDialog(props: LinkMailboxDialogProps) {
  const { open, onClose } = props;

  const { t } = useTranslation();

  const form = useForm<Values>({
    defaultValues: DEFAULT_VALUES,
  });
  const { register, formState, handleSubmit } = form;

  const user = useUser();

  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmitManual = async (values: Values) => {
    const url = new URL(
      `${user.baseUrl}/api/ExternalAuth/ImapSmtp/StoreCredentials`
    );

    url.searchParams.append('smtpAddress', values.email);

    const body = {
      username: values.email,
      password: values.password,
      smtpHost: values.smtpHost,
      smtpPort: values.smtpPort,
      smtpSecurity: values.smtpSecurity,
      imapHost: values.imapHost,
      imapPort: values.imapPort,
      imapSecurity: values.imapSecurity,
    };

    try {
      const response = await fetch(url.href, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user.token}`,
          'Content-Type': 'application/json',
          'x-work4all-apiurl': user.baseUrl,
        },
        body: JSON.stringify(body),
      });

      await validateResponse(response, SMTP_CONFIGURATION_ERROR);

      onClose();
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown error';
      const errorMessageKey = convertErrorMessageToKey(errorMessage);
      const errorMessageTranslated = t(errorMessageKey);

      setErrorMessage(errorMessageTranslated);
    }
  };

  const handleSubmitMicrosoft = async (values: Values) => {
    const url = new URL(
      `${user.baseUrl}/api/ExternalAuth/Microsoft/GenerateAuthUrl`
    );
    url.searchParams.append('smtpAddress', values.email);
    url.searchParams.append('redirectUrl', REDIRECT_URL);

    try {
      const response = await fetch(url.href, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user.token}`,
          Accept: 'application/json',
          'x-work4all-apiurl': user.baseUrl,
        },
        cache: 'no-cache',
      });

      await validateResponse(response);

      const json = await response.json();

      if (typeof json !== 'string') {
        throw new Error('Unexpected response');
      }

      window.open(json, '__blank');

      onClose();
    } catch (error: unknown) {
      const errorMessage = error instanceof Error ? error.message : '';
      const errorMessageKey = convertErrorMessageToKey(errorMessage);
      const errorMessageTranslated = t(errorMessageKey);

      setErrorMessage(errorMessageTranslated);
    }
  };

  const onSubmit = async (values: Values): Promise<void> => {
    setErrorMessage('');

    switch (values.type) {
      case 'manual':
        return handleSubmitManual(values);

      case 'microsoft':
        return handleSubmitMicrosoft(values);

      default:
        assertNever(values.type, 'Unknown mailbox type');
    }
  };

  useLayoutEffect(() => {
    if (open) {
      setErrorMessage('');
      form.reset(DEFAULT_VALUES);
    }
  }, [open, form]);

  const isManualConfiguration = form.watch('type') === 'manual';

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t('ASSIGN_INCOMING_EMAILS.LINK_MAILBOX')}
      classes={{ dialog: { paper: styles.dialog } }}
    >
      <DialogContent>
        <form id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
          {!!errorMessage && (
            <Typography
              component="p"
              variant="caption"
              color="error"
              sx={{ mb: '1rem' }}
            >
              {errorMessage}
            </Typography>
          )}

          <Stack direction="column" spacing="1rem">
            <Controller
              control={form.control}
              name="type"
              render={({ field }) => {
                return <MailboxTypePickerField clearable={false} {...field} />;
              }}
            />

            <LabeledInput
              {...register('email', { required: true })}
              required
              type="email"
              label={t('INPUTS.EMAIL_ADDRESS')}
            />

            {isManualConfiguration && (
              <LabeledInput
                {...register('password', { required: true })}
                required
                type="password"
                label={t('LINK_MAILBOX.INPUTS.PASSWORD')}
              />
            )}

            {isManualConfiguration && (
              <Box>
                <Grid container spacing={2} wrap="wrap">
                  <Grid item xs={12} md={6}>
                    <LabeledInput
                      label={t('LINK_MAILBOX.INPUTS.SMTP')}
                      required
                      {...register('smtpHost', { required: true })}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <LabeledInput
                      label={t('LINK_MAILBOX.INPUTS.PORT')}
                      type="number"
                      {...register('smtpPort', { valueAsNumber: true })}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      control={form.control}
                      name="smtpSecurity"
                      render={({ field }) => {
                        return <MailboxEncryptionPickerField {...field} />;
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}

            {isManualConfiguration && (
              <Box>
                <Grid container spacing={2} wrap="wrap">
                  <Grid item xs={12} md={6}>
                    <LabeledInput
                      label={t('LINK_MAILBOX.INPUTS.IMAP')}
                      required
                      {...register('imapHost', { required: true })}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <LabeledInput
                      label={t('LINK_MAILBOX.INPUTS.PORT')}
                      type="number"
                      {...register('imapPort', { valueAsNumber: true })}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      control={form.control}
                      name="imapSecurity"
                      render={({ field }) => {
                        return <MailboxEncryptionPickerField {...field} />;
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Stack>
        </form>
      </DialogContent>

      <DialogActions>
        <Button
          disabled={formState.isSubmitting}
          size="large"
          color="primary"
          type="button"
          onClick={onClose}
        >
          {t('ALERTS.CLOSE')}
        </Button>
        <Button
          disabled={formState.isSubmitting}
          size="large"
          color="primary"
          type="submit"
          form={FORM_ID}
        >
          {t('LINK_MAILBOX.LINK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/**
 * The API doesn't return any error codes for this request, only an error
 * message. This function matches the return error message against the known
 * error messages and returns a corresponding i18n key if a message is
 * recognized. If the message is not recognized, returns a i18n key for a
 * generic error message instead.
 *
 * @param message The error messaged to translate.
 * @returns Translation key to be passed to the `t` function.
 */
function convertErrorMessageToKey(message: string): string {
  switch (message) {
    case 'Mailbox with this address has already been linked for this user':
      return 'LINK_MAILBOX.ERROR.ALREADY_LINKED';

    case SMTP_CONFIGURATION_ERROR:
      return 'LINK_MAILBOX.ERROR.CONFIGURATION_ERROR';

    case 'Unknown error':
    default:
      return 'LINK_MAILBOX.ERROR.DEFAULT';
  }
}
