import styles from './ListItem2.module.scss';

import MuiListItemButton from '@mui/material/ListItemButton';
import clsx from 'clsx';
import React, { forwardRef } from 'react';

type ListItemProps = React.ComponentProps<typeof MuiListItemButton>;

export const ListItem2: React.FC<Omit<ListItemProps, 'button'>> = forwardRef(
  function ListItem2(props, ref) {
    return (
      <MuiListItemButton
        ref={ref}
        className={clsx(styles.listItem, props.className)}
        divider={props.divider}
        onClick={props.onClick}
        onContextMenu={props.onContextMenu}
      >
        {props.children}
      </MuiListItemButton>
    );
  }
);
