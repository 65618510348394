import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class Steuerschluessel<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: steuerschluesselValue */
  steuerschluesselValue?: number;
  /** Alias: mwst */
  vat?: number;
  /** Alias: notiz */
  note?: string;
  /** Alias: kreditor */
  kreditor?: boolean;
  /** Alias: konto */
  konto?: number;

  __typename?: string;
}

const fields: FieldDefinitions<Steuerschluessel> = {
  id: {
    alias: 'code',
  },
  steuerschluesselValue: {
    alias: 'steuerschluesselValue',
  },
  vat: {
    alias: 'mwst',
  },
  note: {
    alias: 'notiz',
  },
  kreditor: {
    alias: 'kreditor',
  },
  konto: {
    alias: 'konto',
  },
  __typename: {
    alias: '__typename',
  },
};

export const steuerschluesselEntityDefinition: EntitiyDefinition<Steuerschluessel> =
  {
    local: {},
    remote: {
      queryName: 'getSteuerschluessel',
      fragmentName: 'Steuerschluessel',
      withPaginationWrapper: true,
      params: [
        { name: 'querySize', type: 'Int' },
        { name: 'queryPage', type: 'Int' },
        { name: 'querySortOrder', type: 'SortOrderStyle' },
        { name: 'querySortBy', type: 'String' },
        { name: 'filter', type: 'String' },
      ],
    },
    fieldDefinitions: fields,
  };
