import { IconButton, IconButtonProps, Tooltip } from '@mui/material';
import React, { MutableRefObject } from 'react';

import { getLinkData } from '../base-action-button/utils';

import { BaseActionProps } from './types';

export type ActionTooltipProps = BaseActionProps & {
  icon: JSX.Element;
  tooltip: string;
  className?: string;
  rootRef?: MutableRefObject<HTMLButtonElement>;
};

export function IconButtonWithTooltip<T extends React.ElementType>(
  props: IconButtonProps<T, ActionTooltipProps>
) {
  const { href, tooltip, className, icon, rootRef, ...other } = props;
  const { linkProps } = getLinkData(href);

  return (
    <Tooltip title={tooltip || undefined} placement="top">
      <span className={className}>
        <IconButton
          ref={rootRef}
          sx={{ height: '100%' }}
          color="primary"
          {...other}
          {...linkProps}
        >
          {icon}
        </IconButton>
      </span>
    </Tooltip>
  );
}
