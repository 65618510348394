import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class ProcessedEMailTemplateAttachment<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: name */
  name?: string;
  /** Alias: size */
  size?: number;

  __typename?: string;
}

const fields: FieldDefinitions<ProcessedEMailTemplateAttachment> = {
  id: {
    alias: 'id',
  },
  name: {
    alias: 'name',
  },
  size: {
    alias: 'size',
  },
  __typename: {
    alias: '__typename',
  },
};

export const processedEMailTemplateAttachmentEntityDefinition: EntitiyDefinition<ProcessedEMailTemplateAttachment> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'ProcessedEMailVorlageAttachment',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
