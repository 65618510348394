import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Article } from './Article.entity';
import { Currency } from './Currency.entity';
import { Unit } from './Unit.entity';

export class CustomerIndividualPrice<T extends EMode = EMode.entity> {
  /** Alias: artikelCode */
  articleId?: number;
  /** Alias: artikelNummer */
  articleNumber?: string;
  /** Alias: bemerkung */
  comment?: string;
  /** Alias: code */
  id?: number;
  /** Alias: einheitCode */
  unitId?: number;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: kundenCode */
  customerId?: number;
  /** Alias: nettoPreis */
  netPrice?: number;
  /** Alias: preisstaffelCode */
  bulkPriceTierId?: number;
  /** Alias: rabattFaehig */
  isDiscountCapable?: boolean;
  /** Alias: rabattStaffelPreis */
  hasScaleDiscounts?: boolean;
  /** Alias: staffelpreisZuschlag */
  bulkPricesSurcharge?: number;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: waherungCode */
  currencyId?: number;
  /** Alias: waehrung */
  currency?: Currency<T>;
  /** Alias: artikel */
  article?: Article<T>;
  /** Alias: einheit */
  unit?: Unit<T>;
  /** Alias: discountValue */
  discountValue?: number;

  __typename?: string;
}

const fields: FieldDefinitions<CustomerIndividualPrice> = {
  articleId: {
    alias: 'artikelCode',
  },
  articleNumber: {
    alias: 'artikelNummer',
  },
  comment: {
    alias: 'bemerkung',
  },
  id: {
    alias: 'code',
  },
  unitId: {
    alias: 'einheitCode',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customerId: {
    alias: 'kundenCode',
  },
  netPrice: {
    alias: 'nettoPreis',
  },
  bulkPriceTierId: {
    alias: 'preisstaffelCode',
  },
  isDiscountCapable: {
    alias: 'rabattFaehig',
  },
  hasScaleDiscounts: {
    alias: 'rabattStaffelPreis',
  },
  bulkPricesSurcharge: {
    alias: 'staffelpreisZuschlag',
  },
  updateTime: {
    alias: 'updateTime',
  },
  currencyId: {
    alias: 'waherungCode',
  },
  currency: {
    alias: 'waehrung',
    entity: Entities.currency,
  },
  article: {
    alias: 'artikel',
    entity: Entities.article,
  },
  unit: {
    alias: 'einheit',
    entity: Entities.unit,
  },
  discountValue: {
    alias: 'discountValue',
  },
  __typename: {
    alias: '__typename',
  },
};

export const customerIndividualPriceEntityDefinition: EntitiyDefinition<CustomerIndividualPrice> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'KundenIndividuellerPreis',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
