import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class BaseDataLanguage<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: name */
  name?: string;

  __typename?: string;
}

const fields: FieldDefinitions<BaseDataLanguage> = {
  id: {
    alias: 'code',
  },
  name: {
    alias: 'name',
  },
  __typename: {
    alias: '__typename',
  },
};

export const baseDataLanguageEntityDefinition: EntitiyDefinition<BaseDataLanguage> =
  {
    local: {},
    remote: {
      queryName: 'getLanguages',
      fragmentName: 'StammdatenSprache',
      withPaginationWrapper: false,
      params: [{ name: 'codes', type: '[Int]' }],
    },
    fieldDefinitions: fields,
  };
