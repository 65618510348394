import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { ErpTextBuilidingBlockType } from '../Enums/ErpTextBuilidingBlockType.enum';

import { TextBuildingBlockGroup } from './TextBuildingBlockGroup.entity';

export class TextBuildingBlock<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: erpStandardTextBausteinType */
  erpStandardTextBuildingBlockType?: ErpTextBuilidingBlockType;
  /** Alias: grCode */
  groupId?: number;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: letzteAenderung */
  lastEdit?: string;
  /** Alias: name */
  name?: string;
  /** Alias: rtfText */
  rtfText?: string;
  /** Alias: text */
  text?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: gruppe */
  group?: TextBuildingBlockGroup<T>;

  __typename?: string;
}

const fields: FieldDefinitions<TextBuildingBlock> = {
  id: {
    alias: 'code',
  },
  erpStandardTextBuildingBlockType: {
    alias: 'erpStandardTextBausteinType',
  },
  groupId: {
    alias: 'grCode',
  },
  insertTime: {
    alias: 'insertTime',
  },
  lastEdit: {
    alias: 'letzteAenderung',
  },
  name: {
    alias: 'name',
  },
  rtfText: {
    alias: 'rtfText',
  },
  text: {
    alias: 'text',
  },
  updateTime: {
    alias: 'updateTime',
  },
  group: {
    alias: 'gruppe',
    entity: Entities.textBuildingBlockGroup,
  },
  __typename: {
    alias: '__typename',
  },
};

export const textBuildingBlockEntityDefinition: EntitiyDefinition<TextBuildingBlock> =
  {
    local: {},
    remote: {
      queryName: 'getTextbausteine',
      fragmentName: 'Textbaustein',
      withPaginationWrapper: true,
      mutations: {
        upsert: {
          mutationName: 'upsertTextBuildingBlock',
          args: [
            { name: 'input', type: 'InputTextbaustein!' },
            { name: 'drySave', type: 'Boolean' },
          ],
        },
      },
      params: [
        { name: 'querySize', type: 'Int' },
        { name: 'queryPage', type: 'Int' },
        { name: 'querySortOrder', type: 'SortOrderStyle' },
        { name: 'querySortBy', type: 'String' },
        { name: 'filter', type: 'String' },
        { name: 'codes', type: '[Int]' },
      ],
    },
    fieldDefinitions: fields,
  };
