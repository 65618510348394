import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class InputProjectAccessGroupUserRelation<
  T extends EMode = EMode.entity
> {
  /** Alias: add */
  add?: number[];
  /** Alias: remove */
  remove?: number[];

  __typename?: string;
}

const fields: FieldDefinitions<InputProjectAccessGroupUserRelation> = {
  add: {
    alias: 'add',
  },
  remove: {
    alias: 'remove',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputProjectAccessGroupUserRelationEntityDefinition: EntitiyDefinition<InputProjectAccessGroupUserRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputProjectAccessGroupUserRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
