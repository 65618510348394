import styles from './Content.module.scss';

import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { TopicPickerField } from '../../../../../../../../../components/entity-picker/TopicPickerField';
import { ControllerPlus } from '../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../form-plus/use-form-context-plus';
import { LabeledInput } from '../../../../../../../locked-inputs';
import { ControlWrapper } from '../../../../../../components/control-wrapper';
import { DocumentMaskFormValue } from '../../../../types';

const collapseClasses = {
  root: styles.collapseContainer,
  wrapperInner: styles.collapseWrapperInner,
};

export function Content() {
  const { t } = useTranslation();
  const { register, control } = useFormContextPlus<DocumentMaskFormValue>();

  return (
    <Collapse
      title={t('COMMON.CONTENTS')}
      defaultOpen
      className={styles.collapse}
      classes={collapseClasses}
    >
      <ControlWrapper>
        <ControllerPlus
          name="topicMarkList"
          control={control}
          render={({ field }) => {
            return (
              <TopicPickerField
                type={Entities.document}
                {...field}
                value={field.value ? field.value[0] : null}
                onChange={(val) => {
                  field.onChange(val ? [val] : null);
                }}
              />
            );
          }}
        />
      </ControlWrapper>

      <ControlWrapper className={styles.h100}>
        <LabeledInput
          {...register('note')}
          label={t('COMMON.SUBJECT_OR_CONTENT')}
          multiline={true}
        />
      </ControlWrapper>
    </Collapse>
  );
}
