import styles from './TableSkeletonRow.module.scss';

import Skeleton from '@mui/material/Skeleton';

interface ISkeletonRowProps {
  style?: React.CSSProperties;
}

export const TableSkeletonRow: React.FC<ISkeletonRowProps> = (props) => {
  return <Skeleton className={styles.skeleton} style={props.style} />;
};
