import { ControllerPlus } from '../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../form-plus/use-form-context-plus';
import { LabeledInput } from '../../../../locked-inputs';
import { WithCustomFields } from '../with-custom-fields';

import { CustomFieldProps } from './types';

export function CustomNumberField(props: CustomFieldProps) {
  const { name, label } = props;

  const { control } = useFormContextPlus<WithCustomFields<object>>();

  return (
    <ControllerPlus
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <LabeledInput
            type="number"
            label={label}
            {...field}
            onChange={(event) => {
              const value = event.target.value;
              const valueParsed = parseFloat(value) || 0;

              field.onChange(valueParsed);
            }}
          />
        );
      }}
    />
  );
}
