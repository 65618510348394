import { Box, Theme, useMediaQuery } from '@mui/material';
import React from 'react';

import { Panel } from '../../../../../components/panel/Panel';

import { Assignment } from './components/assignment/Assignment';
import { Blockages } from './components/blockages/Blockages';
import { GeneralData } from './components/general-data/GeneralData';
import { ManagementAndCosts } from './components/management-and-costs/ManagementAndCosts';
import { ParticipantsAndAddresses } from './components/participants-and-addresses/ParticipantsAndAddresses';
import { ProjectAccessGroup } from './components/project-access-group/ProjectAccessGroup';
import { Schedule } from './components/schedule/Schedule';

export const GeneralTabPanel: React.FC = () => {
  const isMdUp = useMediaQuery<Theme>((t) => t.breakpoints.up('md'));

  return (
    <Panel>
      <GeneralData />
      <Assignment />
      <ParticipantsAndAddresses />
      <ManagementAndCosts />
      <Schedule />
      <Box display="grid" gridTemplateColumns={isMdUp ? '1fr 1fr' : undefined}>
        <Blockages />
        <ProjectAccessGroup />
      </Box>
    </Panel>
  );
};
