import styles from './Divider.module.scss';

import { Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

export const Divider: React.FC<{
  title?: string;
  className?: string;
  size?: 'caption' | 'body';
  style?: React.CSSProperties;
  horizontalPadding?: boolean;
  orientation?: 'horizontal' | 'vertical';
}> = (props) => {
  const {
    title,
    size = 'caption',
    style,
    horizontalPadding,
    orientation = 'horizontal',
    ...rest
  } = props;
  return (
    <div
      className={clsx(
        styles.wrap,
        props.className,
        styles[orientation],
        styles[size],
        {
          [styles.horizontalPadding]: horizontalPadding,
        }
      )}
      style={style}
      {...rest}
    >
      {title !== undefined && title !== null && title.length > 0 ? (
        <Typography variant="caption" className={styles.title}>
          {title}
        </Typography>
      ) : null}
      <div className={styles.line}></div>
    </div>
  );
};
