import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputEMailVorlagenAnhangRelation } from './InputEMailVorlagenAnhangRelation.entity';

export class InputEMailVorlagenRelation<T extends EMode = EMode.entity> {
  /** Alias: attachements */
  attachements?: InputEMailVorlagenAnhangRelation<T>;

  __typename?: string;
}

const fields: FieldDefinitions<InputEMailVorlagenRelation> = {
  attachements: {
    alias: 'attachements',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputEMailVorlagenRelationEntityDefinition: EntitiyDefinition<InputEMailVorlagenRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputEMailVorlagenRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
