import i18next from 'i18next';
import { DateTime, Duration } from 'luxon';

//date formats are specced in: https://work4all.sharepoint.com/:w:/s/WebSquad/EaJl2jaOABJIgDqhdoODeboBhz8ImmBRfn5s2nLbtoLAmg?e=qNjQL6
export enum DateFormatPreset {
  TIME_SIMPLE = 'TIME_SIMPLE', // DATETIME_SHORT
  DATE = 'DATE', //this doe an autodetect of the date formats below
  DATE_SIMPLE_2YEAR = 'DATE_SIMPLE_2YEAR', // DATE_SHORT
  DATE_TIME_SIMPLE = 'DATE_TIME_SIMPLE', // DATETIME_SHORT
  DATE_SIMPLE_4YEAR = 'DATE_SIMPLE_4YEAR',
}

export enum DatePeriodPresets {
  DIFF = 'DIFF',
  PERIOD = 'PERIOD', //this does an autodetect of the period formats below
  PERIOD_MULTIPLEDAYS_ALLDAY = 'PERIOD_MULTIPLEDAYS_ALLDAY',
  PERIOD_SINGLEDAY_TIME = 'PERIOD_SINGLEDAY_TIME',
  PERIOD_MULTIPLEDAY_TIME = 'PERIOD_MULTIPLEDAY_TIME',
}

const ensureDateTime = (dateString: string | Date) => {
  return typeof dateString === 'string'
    ? DateTime.fromISO(dateString)
    : DateTime.fromJSDate(dateString);
};

export const formatDateString = (
  dateString: string | Date,
  format: DateFormatPreset | DatePeriodPresets | string,
  opts?: {
    periodEnd?: string | Date;
    isWholeDay?: boolean;
  }
): string | null => {
  //browser localized format
  const fallbackMap = {
    DATE_SIMPLE_2YEAR: DateTime.DATE_SHORT,
    DATE_TIME_SIMPLE: DateTime.DATETIME_SHORT,
    TIME_SIMPLE: DateTime.TIME_SIMPLE,
  };
  const startDate = ensureDateTime(dateString);
  if (!startDate.isValid || startDate.toMillis() <= 0) {
    return null;
  }

  //period formatting
  if (Object.values(DatePeriodPresets).includes(format as DatePeriodPresets)) {
    if (!opts?.periodEnd) return '[invalid period]';
    const endDate = ensureDateTime(opts.periodEnd);
    return formatDatePeriod(
      startDate,
      endDate,
      opts.isWholeDay,
      format as DatePeriodPresets
    );
  }

  if (format === DateFormatPreset.DATE) {
    if (opts?.isWholeDay) {
      return formatDateString(dateString, DateFormatPreset.DATE_SIMPLE_2YEAR);
    }
    return formatDateString(dateString, DateFormatPreset.DATE_TIME_SIMPLE);
  }
  //datepreset formatting
  if (Object.values(DateFormatPreset).includes(format as DateFormatPreset)) {
    if (i18next.exists('DATE.' + format, { fallbackLng: {} })) {
      return startDate.toFormat(i18next.t('DATE.' + format));
    }
    //fallback intls
    return startDate
      .setLocale(i18next.language)
      .toLocaleString(fallbackMap[format]);
  }

  //format expression formatting
  return startDate.toFormat(format);
};

function formatDatePeriod(
  start: DateTime,
  end: DateTime,
  isWholeDay = false,
  format: DatePeriodPresets
) {
  const isSameDay =
    start.hasSame(end, 'day') &&
    start.hasSame(end, 'year') &&
    start.hasSame(end, 'month');

  if (format === DatePeriodPresets.PERIOD) {
    if (isSameDay && isWholeDay) {
      return formatDateString(
        start.toJSDate(),
        DateFormatPreset.DATE_SIMPLE_2YEAR
      );
    }
    if (!isSameDay && isWholeDay) {
      return (
        formatDateString(start.toJSDate(), DateFormatPreset.DATE_SIMPLE_2YEAR) +
        ' - ' +
        formatDateString(end.toJSDate(), DateFormatPreset.DATE_SIMPLE_2YEAR)
      );
    }
    if (isSameDay && !isWholeDay) {
      return (
        formatDateString(start.toJSDate(), DateFormatPreset.DATE_TIME_SIMPLE) +
        ' - ' +
        formatDateString(end.toJSDate(), DateFormatPreset.TIME_SIMPLE)
      );
    }
    if (!isSameDay && !isWholeDay) {
      return (
        formatDateString(start.toJSDate(), DateFormatPreset.DATE_TIME_SIMPLE) +
        ' - ' +
        formatDateString(end.toJSDate(), DateFormatPreset.DATE_TIME_SIMPLE)
      );
    }
  }

  if (format === DatePeriodPresets.DIFF) {
    if (isWholeDay) return `1 ${i18next.t('COMMON.DAY')}`;

    const startDuration = Duration.fromMillis(start.toMillis());
    const endDuration = Duration.fromMillis(end.toMillis());
    const duration = endDuration.minus(startDuration);

    //if duration under 60000 millis return 0, the minimum unit is the minute
    if (duration.toMillis() < 60000) return '0';

    const durationObject = duration
      .shiftTo('minutes', 'hours', 'days')
      .toObject();
    const arrayValue = Object.keys(durationObject)
      .filter((key) => durationObject[key] > 0)
      .map(
        (key) =>
          `${durationObject[key]} ${i18next.t(
            `COMMON.${key.slice(0, -1).toUpperCase()}`,
            {
              count: durationObject[key],
            }
          )}`
      );

    return arrayValue.length === 1
      ? arrayValue[0]
      : `${arrayValue.slice(0, -1).join(', ')}, ${i18next.t(
          'COMMON.AND'
        )} ${arrayValue.slice(-1)}`;
  }
  return '[invalid period]';
}
