import styles from './SearchResultItem.module.scss';

import {
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  useMediaQuery,
} from '@mui/material';
import clsx from 'clsx';
import React, { MouseEventHandler } from 'react';

import { Link } from '@work4all/data';
import { useHasMounted } from '@work4all/data/lib/hooks/useHasMounted';

import { WebsiteFavicon } from '../../components/website-favicon/WebsiteFavicon';
import { Body2 } from '../../typography/body2/Body2';

import { Caption } from './components/caption/Caption';

interface Props {
  id: string;
  website?: string;
  title: string | React.ReactElement;
  number?: string;
  href?: string;
  onClick: MouseEventHandler;
}

export const SearchResultItem: React.FC<Props> = (props) => {
  const upSm = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));
  // "upSm" always will be "false" on first render.
  // do not render incorrect view first time.
  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }

  return (
    <Button
      id={props.id}
      component={props.href !== undefined ? Link : 'button'}
      to={props.href}
      onClick={props.onClick}
      className={clsx(styles.wrap)}
    >
      <ListItem className={styles.item} classes={{ divider: styles.divider }}>
        {props.website !== undefined && (
          <ListItemIcon>
            <WebsiteFavicon website={props.website} />
          </ListItemIcon>
        )}
        <ListItemText
          classes={{
            root: clsx(
              styles.root,
              (upSm && styles['root--up-sm']) || styles['root--sm']
            ),
            primary: styles.textEl,
            secondary: styles.secondary,
          }}
          primary={<Body2>{props.title}</Body2>}
          secondary={
            <Caption>
              {!props.number ||
              props.number.trim() === '' ||
              props.number.trim() === '0'
                ? ''
                : props.number}
            </Caption>
          }
        />
      </ListItem>
    </Button>
  );
};
