import styles from './MaskTabPanel.module.scss';

import clsx from 'clsx';

import { useMaskTabContext } from './MaskTabContext';
import { MaskTabPanelContext } from './MaskTabPanelContext';

export interface MaskTabPanelProps {
  value: string | number;
  children?: React.ReactNode;
  className?: string;
}

// TODO Add aria attributes to tab panels

export function MaskTabPanel(props: MaskTabPanelProps) {
  const { value, children, className } = props;

  const context = useMaskTabContext();

  const isActive = context.value === value;

  return (
    <MaskTabPanelContext value={value}>
      {isActive && (
        <div className={clsx(styles.maskTabPanelContent, className)}>
          {children}
        </div>
      )}
    </MaskTabPanelContext>
  );
}
