import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ListEntityPicker } from '@work4all/components/lib/components/entity-picker/components/list-entity-picker/ListEntityPicker';
import {
  CONTACT_FIELDS,
  renderContact,
} from '@work4all/components/lib/components/entity-picker/contact-picker/ContactPicker';
import { IPickerProps } from '@work4all/components/lib/components/entity-picker/types';

import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { FileType } from '@work4all/models/lib/File';

import { FileContext } from '../../../../../containers/files/detail/components/FileContext';

export type IObjectTypePickerProps<TMultiple extends boolean> = IPickerProps<
  { id: string; name: string },
  TMultiple
>;

export function ChronoContactPicker<TMultiple extends boolean>(
  props: IObjectTypePickerProps<TMultiple>
) {
  const { ...rest } = props;
  const { t } = useTranslation();
  const fileContext = useContext(FileContext);
  const prefilter = useMemo(() => {
    if (isProject(fileContext.data, fileContext.type)) {
      return [
        {
          businessPartnerId: {
            $in: [
              fileContext.data?.supplier?.id,
              fileContext.data?.customer?.id,
            ].filter((x) => x),
          },
        },
      ];
    }
    return [{ businessPartnerId: { $eq: fileContext.id } }];
  }, [fileContext]);

  return (
    <ListEntityPicker
      entity={Entities.contact}
      data={CONTACT_FIELDS}
      filterBy="displayName"
      sortBy="displayName"
      clearable={true}
      {...rest}
      prefilter={prefilter}
      renderItemContent={renderContact(null, t)}
    />
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isProject(data: any, type: FileType): data is Project {
  return type === FileType.PROJECT;
}
