import styles from './SelectedFiltersList.module.scss';

import clsx from 'clsx';
import { forwardRef } from 'react';

import { Chip } from '@work4all/components/lib/dataDisplay/chip/Chip';

interface IFilterItem {
  title: string;
  readOnly: boolean;
  isPrimaryFilter?: boolean;
  filterActive?: boolean;
  handleDelete?: () => void;
  renderFilter: (item: React.ReactElement) => React.ReactElement;
}

export interface IFiltersListProps {
  list?: IFilterItem[];
}

export const SelectedFiltersList = forwardRef<
  HTMLUListElement,
  IFiltersListProps
>(function SelectedFiltersList(props, ref) {
  if (!props.list || props.list.length === 0) {
    return null;
  }

  return (
    <ul ref={ref} className={styles.list}>
      {props.list.map((filterChip, i) => {
        return (
          <li key={i}>
            {filterChip.renderFilter(
              <Chip
                disabled={filterChip.readOnly}
                color={filterChip.readOnly ? 'primary' : 'default'}
                label={filterChip.title}
                handleDelete={filterChip.handleDelete}
                maxWidth={18}
                className={clsx(styles.visibleChip, {
                  [styles.inActiveFilterChip]: !filterChip.handleDelete,
                })}
              />
            )}
          </li>
        );
      })}
    </ul>
  );
});
