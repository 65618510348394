import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { CategoryClass } from './CategoryClass.entity';

export class Category<T extends EMode = EMode.entity> {
  /** Alias: artikelverteiler */
  isArticleDistributor?: boolean;
  /** Alias: auftragsverteiler */
  isContractDistributor?: boolean;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: code */
  id?: number;
  /** Alias: firmenverteiler */
  isFirmCategory?: boolean;
  /** Alias: grCode */
  groupId?: number;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: kategorieKlasse */
  categoryClass?: CategoryClass<T>;
  /** Alias: lookupCode */
  lookupId?: number;
  /** Alias: name */
  name?: string;
  /** Alias: personenverteiler */
  isPersonCategory?: boolean;
  /** Alias: position */
  positionId?: number;
  /** Alias: standardKunde */
  isStandardCustomer?: boolean;
  /** Alias: standardLieferant */
  isStandardSupplier?: boolean;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: verteilerKlassenCode */
  distributorClassId?: number;
  /** Alias: verteilerNummer */
  distributorNumber?: number;
  /** Alias: hide */
  hide?: boolean;

  __typename?: string;
}

const fields: FieldDefinitions<Category> = {
  isArticleDistributor: {
    alias: 'artikelverteiler',
  },
  isContractDistributor: {
    alias: 'auftragsverteiler',
  },
  userId: {
    alias: 'benutzerCode',
  },
  id: {
    alias: 'code',
  },
  isFirmCategory: {
    alias: 'firmenverteiler',
  },
  groupId: {
    alias: 'grCode',
  },
  insertTime: {
    alias: 'insertTime',
  },
  categoryClass: {
    alias: 'kategorieKlasse',
    entity: Entities.categoryClass,
  },
  lookupId: {
    alias: 'lookupCode',
  },
  name: {
    alias: 'name',
  },
  isPersonCategory: {
    alias: 'personenverteiler',
  },
  positionId: {
    alias: 'position',
  },
  isStandardCustomer: {
    alias: 'standardKunde',
  },
  isStandardSupplier: {
    alias: 'standardLieferant',
  },
  updateTime: {
    alias: 'updateTime',
  },
  distributorClassId: {
    alias: 'verteilerKlassenCode',
  },
  distributorNumber: {
    alias: 'verteilerNummer',
  },
  hide: {
    alias: 'hide',
  },
  __typename: {
    alias: '__typename',
  },
};

export const categoryEntityDefinition: EntitiyDefinition<Category> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'Verteiler',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
