import Typography from '@mui/material/Typography';
import { useEffect, useMemo, useState } from 'react';

import { IResponse, useDataProvider } from '@work4all/data';

import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';
import { Salutation } from '@work4all/models/lib/Classes/Salutation.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type ISalutationPickerProps<TMultiple extends boolean> = IPickerProps<
  Salutation,
  TMultiple
>;

export function SalutationPicker<TMultiple extends boolean>(
  props: ISalutationPickerProps<TMultiple>
) {
  const { data, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...SALUTATION_FIELDS, ...data };
  }, [data]);

  const [dataSet, setDataSet] = useState<IResponse<LookUp>>({
    total: 0,
    loading: false,
    pending: false,
    fetchMore: (startIndex: number, stopIndex: number) => Promise.resolve(),
    refetch: () => Promise.resolve(null),
    data: [],
  });

  const requestData = useMemo(() => {
    return {
      entity: Entities.salutation,
      data: SALUTATION_FIELDS,
      completeDataResponse: true,
    };
  }, []);

  const resp = useDataProvider(requestData);

  useEffect(() => {
    if (!resp.loading) {
      setDataSet({
        total: resp.data.length,
        loading: false,
        pending: false,
        fetchMore: (startIndex: number, stopIndex: number) => Promise.resolve(),
        refetch: () => Promise.resolve(null),
        data: resp.data,
      });
    }
  }, [resp]);

  return (
    <ListEntityPicker
      entity={Entities.salutation}
      data={allFields}
      filterBy={['name', 'standardletterSalutation', 'number']}
      sortBy="name"
      {...rest}
      fixedDataSet={dataSet}
      renderItemContent={(salutation) => (
        <Tooltip title={salutation.name}>
          <Typography variant="body2" noWrap>
            {`${salutation.name} ${
              salutation.standardletterSalutation && '/'
            } ${salutation.standardletterSalutation}`}
          </Typography>
        </Tooltip>
      )}
    />
  );
}

const SALUTATION_FIELDS: Salutation = {
  id: null,
  name: null,
  standardletterSalutation: null,
  nameOrFirstName: null,
};
