import styles from './Unit.module.scss';

import React from 'react';

import { Body2 } from '../../../../typography/body2/Body2';

interface IUnitProps {
  unit: string;
}

export const Unit: React.FC<IUnitProps> = (props) => {
  return <Body2 className={styles.unit}>{props.unit}</Body2>;
};
