import { useTranslation } from 'react-i18next';

import { Notification } from '@work4all/models/lib/Classes/Notification.entity';
import { SaveSendMailJob } from '@work4all/models/lib/Classes/SaveSendMailJob.entity';

import { NotificationDate } from './components/NotificationDate';
import { NotificationIcon } from './components/NotificationIcon';
import { NotificationLayout } from './components/NotificationLayout';
import { NotificationText } from './components/NotificationText';
import { NotificationTitle } from './components/NotificationTitle';

export interface NotificationListItemEmailProps {
  className?: string;
  style?: React.CSSProperties;
  notification: Notification;
  onClick: () => void;
}

export function NotificationListItemEmail(
  props: NotificationListItemEmailProps
) {
  const { className, style, notification, onClick } = props;

  const { t } = useTranslation();

  const job = notification.object as SaveSendMailJob;
  const { createdMail } = job;

  const title = createdMail.subject;
  const description = t('NOTIFICATION.EMAIL_SEND_FAILED_TEXT');

  return (
    <NotificationLayout
      className={className}
      style={style}
      unread={!notification.isRead}
      onClick={onClick}
    >
      <NotificationIcon>
        <Icon />
      </NotificationIcon>
      <NotificationTitle unread={!notification.isRead}>
        {title}
      </NotificationTitle>
      <NotificationText>{description}</NotificationText>
      <NotificationDate date={notification.insertTime} />
    </NotificationLayout>
  );
}

function Icon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3334 5.5H4.66671C3.38337 5.5 2.34504 6.55 2.34504 7.83333L2.33337 21.8333C2.33337 23.1167 3.38337 24.1667 4.66671 24.1667H23.3334C24.6167 24.1667 25.6667 23.1167 25.6667 21.8333V7.83333C25.6667 6.55 24.6167 5.5 23.3334 5.5ZM23.3334 10.1667L14 16L4.66671 10.1667V7.83333L14 13.6667L23.3334 7.83333V10.1667Z"
        fill="#232735"
      />
      <circle cx="22.0001" cy="8.00005" r="5.71429" fill="white" />
      <path
        d="M22 1.33337C18.3134 1.33337 15.3334 4.31337 15.3334 8.00004C15.3334 11.6867 18.3134 14.6667 22 14.6667C25.6867 14.6667 28.6667 11.6867 28.6667 8.00004C28.6667 4.31337 25.6867 1.33337 22 1.33337ZM25.3334 10.3934L24.3934 11.3334L22 8.94004L19.6067 11.3334L18.6667 10.3934L21.06 8.00004L18.6667 5.60671L19.6067 4.66671L22 7.06004L24.3934 4.66671L25.3334 5.60671L22.94 8.00004L25.3334 10.3934Z"
        fill="#E14E2E"
      />
    </svg>
  );
}
