import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class InputTemplateFileRelation<T extends EMode = EMode.entity> {
  /** Alias: templateCode */
  templateCode?: number;
  /** Alias: targetfileName */
  targetfileName?: string;

  __typename?: string;
}

const fields: FieldDefinitions<InputTemplateFileRelation> = {
  templateCode: {
    alias: 'templateCode',
  },
  targetfileName: {
    alias: 'targetfileName',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputTemplateFileRelationEntityDefinition: EntitiyDefinition<InputTemplateFileRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputTemplateFileRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
