import React, { createContext, useContext } from 'react';

import { EntityEvent } from './entity-events';

export interface EntityEventsContextValue {
  /**
   * Emit a new entity event. This should be called after changing server-side
   * state so that the rest of the app can be notified of what's changed and
   * optionally refetch some data.
   */
  emit: (event: EntityEvent) => void;

  /**
   * Subscribe to new entity events. The `callback` function will be called
   * every time a new event is emitted.
   *
   * @returns A function to that can be called to unsubscribe.
   */
  subscribe: (callback: (event: EntityEvent) => void) => () => void;
}

const Context = createContext<EntityEventsContextValue>(null);

export const EntityEventsContextProvider =
  Context.Provider as React.Provider<EntityEventsContextValue>;

export function useEntityEventsContext(): EntityEventsContextValue {
  const context = useContext(Context);

  if (context === null) {
    throw new Error(
      'useEntityEventsContext must be used within <EntityEventsContextProvider>'
    );
  }

  return context;
}
