import styles from './appointmentPreview.module.scss';

import CircularProgress from '@mui/material/CircularProgress';
import { MouseEventHandler, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useDataMutation,
  useInaccessibleFields,
  useUser,
} from '@work4all/data';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { AppointmentAttendee } from '@work4all/models/lib/Classes/AppointmentAttendee.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { fixAppointmentStartEndDates } from '@work4all/utils';
import {
  DatePeriodPresets,
  formatDateString,
} from '@work4all/utils/lib/date-utils/formatDateString';
import { filterAttendeesList } from '@work4all/utils/lib/filterAttendeesList';

import { Divider } from '../../../dataDisplay/divider/Divider';
import { IConvertPopoverProps } from '../../convert-popover/ConvertPopover';
import { AppointmentStatePicker } from '../../entity-picker/appointmentStatePicker/AppointmentStatePicker';
import { EntityPickerPopover } from '../../entity-picker/components';
import { CustomerPicker } from '../../entity-picker/customer-picker/CustomerPicker';
import { ProjectPicker } from '../../entity-picker/project-picker/ProjectPicker';
import { useEntityTemplate } from '../../entity-template-provider/EntityTemplateProvider';
import {
  InfoCard,
  OpenFileIconButton,
  PreviewEditMessage,
  PreviewTextContent,
  PreviewTitle,
  PreviewWrapper,
} from '../components';
import { useEntityPreview } from '../hooks/use-entity-preview';

import { appointmentPreviewFields } from './AppointmentPreviewContainer';

export type IAppointmentPreviewProps = {
  appointments: Appointment[];
  onCloseClicked?: MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onShareClicked?: () => void;
  convertProps?: Pick<IConvertPopoverProps, 'exclude' | 'onClick'>;
};

enum previewFields {
  DATE = 'date',
  CUSTOMER = 'customer',
  STATUS = 'status',
  PARTICIPANT = 'participant',
  PREVIEW_TITLE = 'previewTitle',
  PROJECT = 'project',
}

export function AppointmentPreview(props: IAppointmentPreviewProps) {
  const {
    appointments,
    onCloseClicked,
    onEditClicked,
    onShareClicked,
    convertProps,
  } = props;

  const { t } = useTranslation();

  const user = useUser();
  const { isInaccessible, isSomeInaccessible } = useInaccessibleFields();

  const titleIsAccessible = !isInaccessible(Entities.appointment, 'title');
  const statusIsAccessible = !isInaccessible(
    Entities.appointment,
    'appointmentState'
  );
  const timePeriodsIsAccessible = !isSomeInaccessible(Entities.appointment, [
    'startDate',
    'endDate',
    'isWholeDay',
  ]);
  const businessPartnerIsAccessible = !isInaccessible(
    Entities.appointment,
    'businessPartner'
  );
  const projectIsAccessible = !isInaccessible(Entities.appointment, 'project');
  const appointmentAttendeeListIsAccessible = !isInaccessible(
    Entities.appointment,
    'appointmentAttendeeList'
  );
  const noteIsAccessible = !isInaccessible(Entities.appointment, 'note');

  const dateRef = useRef<EntityPickerPopover>(null);
  const customerRef = useRef<EntityPickerPopover>(null);
  const projectRef = useRef<EntityPickerPopover>(null);
  const statusRef = useRef<EntityPickerPopover>(null);
  const participantRef = useRef<EntityPickerPopover>(null);
  const titleRef = useRef<PreviewTitle>(null);

  const openPicker = useCallback((picker: previewFields) => {
    switch (picker) {
      case previewFields.DATE: {
        dateRef.current.open();
        break;
      }

      case previewFields.CUSTOMER: {
        customerRef.current.open();
        break;
      }

      case previewFields.PROJECT: {
        projectRef.current.open();
        break;
      }

      case previewFields.STATUS: {
        statusRef.current.open();
        break;
      }

      case previewFields.PARTICIPANT: {
        participantRef.current.open();
        break;
      }

      case previewFields.PREVIEW_TITLE: {
        titleRef.current.open();
        break;
      }

      default:
        return;
    }
  }, []);

  const [mutate] = useDataMutation<Appointment, EMode.upsert>({
    entity: Entities.appointment,
    mutationType: EMode.upsert,
    responseData: appointmentPreviewFields as unknown as Appointment,
  });

  const {
    loading,
    isMultiselect,
    commonFields,
    activePicker,
    optimisticTitle,
    handleLock,
    onPopoverClose,
    setOptimisticTitle,
    onEdit,
    canEditAllSelected,
  } = useEntityPreview({
    user,
    subEntityType: Entities.appointment,
    entries: appointments,
    openPicker,
    mutate,
  });

  const customerIsCommon = commonFields.includes('businessPartner');
  const projectIsCommon = commonFields.includes('project');
  const statusIsCommon = commonFields.includes('appointmentState');

  const entityTemplate = useEntityTemplate();

  const originalAppointment = appointments[0];
  const appointment = useMemo(() => {
    const appointment = originalAppointment
      ? fixAppointmentStartEndDates(originalAppointment)
      : originalAppointment;
    return appointment;
  }, [originalAppointment]);

  const filteredAttendeesList: AppointmentAttendee[] = useMemo(() => {
    return filterAttendeesList(appointment);
  }, [appointment]);

  return (
    <PreviewWrapper>
      <PreviewTitle
        disabled={true}
        showEdit={!isMultiselect && canEditAllSelected}
        ref={titleRef}
        label={appointment?.title}
        onClick={() =>
          !isMultiselect &&
          titleIsAccessible &&
          handleLock(previewFields.PREVIEW_TITLE)
        }
        onClose={(e) => {
          if (e.target.value !== appointment?.title) {
            setOptimisticTitle(e.target.value);
            onEdit({ title: e.target.value });
          }
          onPopoverClose();
        }}
        onCloseClicked={onCloseClicked}
        onEditClicked={onEditClicked}
        actions={
          loading && activePicker === previewFields.PREVIEW_TITLE ? (
            <CircularProgress size="1rem" color="secondary" />
          ) : null
        }
        onShareClicked={onShareClicked}
        convertProps={convertProps}
      >
        {isMultiselect
          ? `${t('COMMON.SELECTION')}: ${appointments.length} ${t(
              'COMMON.ELEMENTS'
            )}`
          : titleIsAccessible
          ? optimisticTitle ?? appointment?.title
          : ''}
      </PreviewTitle>
      {isMultiselect && (
        <PreviewEditMessage entityId={String(appointment.id)} />
      )}
      <div className={styles.infoCardWrapper}>
        {statusIsAccessible && (
          <EntityPickerPopover
            ref={statusRef}
            disabled={true}
            onTargetClick={() => handleLock(previewFields.STATUS)}
            onClose={onPopoverClose}
            picker={
              <AppointmentStatePicker
                value={appointment?.appointmentState}
                onChange={(value) => {
                  onEdit({ colorId: value.id });
                }}
                multiple={false}
              />
            }
          >
            <InfoCard
              truncate
              label="Status"
              decorator={
                loading && activePicker === previewFields.STATUS ? (
                  <CircularProgress size="1rem" color="secondary" />
                ) : null
              }
              decoratorPos="right"
            >
              {statusIsCommon
                ? appointment?.appointmentState?.name
                : `(${t('COMMON.MULTIPLE')})`}
            </InfoCard>
          </EntityPickerPopover>
        )}

        {timePeriodsIsAccessible && (
          <InfoCard staticField={true} label={t('COMMON.TIMESPAN')}>
            {formatDateString(
              appointment?.startDate,
              DatePeriodPresets.PERIOD,
              {
                periodEnd: appointment?.endDate,
                isWholeDay: appointment?.isWholeDay,
              }
            )}
          </InfoCard>
        )}

        {businessPartnerIsAccessible && (
          <EntityPickerPopover
            ref={customerRef}
            disabled={true}
            onTargetClick={() => handleLock(previewFields.CUSTOMER)}
            onClose={onPopoverClose}
            footer={entityTemplate.renderPickerFooter({
              entity: Entities.customer,
              popoverRef: customerRef,
              data: appointment.businessPartner?.data as Customer,
              onChange: (value) => {
                onEdit({
                  businessPartner:
                    value && !Array.isArray(value)
                      ? {
                          data: value,
                          businessPartnerType: SdObjType.KUNDE,
                          id: value?.id,
                        }
                      : null,
                  businessPartnerId:
                    value && !Array.isArray(value) ? value?.id : 0,
                  businessPartnerType: SdObjType.KUNDE,
                });
              },
            })}
            picker={
              <CustomerPicker
                onChange={(value) =>
                  onEdit({
                    businessPartner: value
                      ? {
                          data: value,
                          businessPartnerType: SdObjType.KUNDE,
                          id: value?.id,
                        }
                      : null,
                    businessPartnerId: value ? value?.id : 0,
                    businessPartnerType: SdObjType.KUNDE,
                  })
                }
                value={appointment.businessPartner?.data as Customer}
                multiple={false}
              />
            }
          >
            <InfoCard
              truncate
              label={t('COMMON.BUSINESS_PARTNER', {
                context: appointment?.businessPartner?.businessPartnerType,
              })}
              decorator={
                loading && activePicker === previewFields.CUSTOMER ? (
                  <CircularProgress size="1rem" color="secondary" />
                ) : null
              }
              decoratorPos="right"
              beforeContentElement={
                <OpenFileIconButton data={appointment?.businessPartner?.data} />
              }
            >
              {customerIsCommon
                ? appointment?.businessPartner?.data?.name
                : `(${t('COMMON.MULTIPLE')})`}
            </InfoCard>
          </EntityPickerPopover>
        )}

        {projectIsAccessible && (
          <EntityPickerPopover
            ref={projectRef}
            disabled={true}
            onTargetClick={() => handleLock(previewFields.PROJECT)}
            onClose={onPopoverClose}
            footer={entityTemplate.renderPickerFooter({
              entity: Entities.project,
              popoverRef: projectRef,
              data: appointment.project,
              onChange: (value) => {
                onEdit({
                  project: value,
                  projectId: value && !Array.isArray(value) ? value.id : 0,
                });
              },
            })}
            picker={
              <ProjectPicker
                onChange={(value) => {
                  onEdit({
                    project: value,
                    projectId: value ? value.id : 0,
                  });
                }}
                value={appointment.project}
                multiple={false}
              />
            }
          >
            <InfoCard
              truncate
              label={t('COMMON.PROJECT')}
              decorator={
                loading && activePicker === previewFields.PROJECT ? (
                  <CircularProgress size="1rem" color="secondary" />
                ) : null
              }
              decoratorPos="right"
              beforeContentElement={
                <OpenFileIconButton data={appointment?.project} />
              }
            >
              {projectIsCommon
                ? appointment?.project?.name
                : `(${t('COMMON.MULTIPLE')})`}
            </InfoCard>
          </EntityPickerPopover>
        )}

        {appointmentAttendeeListIsAccessible && (
          <InfoCard staticField={true} label={t('COMMON.PARTICIPANT')}>
            {filteredAttendeesList
              ?.map(
                (el) =>
                  el.user?.displayName ||
                  el.contact?.displayName ||
                  el.ressource?.displayName ||
                  el?.businessPartner?.data?.name
              )
              .join(', ')}
          </InfoCard>
        )}
      </div>
      <Divider className={styles.divider} />

      {!isMultiselect && noteIsAccessible ? (
        <PreviewTextContent>{appointment?.note}</PreviewTextContent>
      ) : null}
    </PreviewWrapper>
  );
}
