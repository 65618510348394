import { setContext } from '@apollo/client/link/context';
import { DateTime } from 'luxon';

export const timeZoneLink = setContext((operation, prevContext) => {
  return {
    ...prevContext,
    headers: {
      ...prevContext.headers,
      'x-work4all-timezone': DateTime.now().toFormat('ZZ'),
    },
  };
});
