import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { TimeTrackingKind } from '../Enums/TimeTrackingKind.enum';

import { Article } from './Article.entity';
import { Contract } from './Contract.entity';
import { Customer } from './Customer.entity';
import { CustomField } from './CustomField.entity';
import { Position } from './Position.entity';
import { Project } from './Project.entity';
import { ProjectProcess } from './ProjectProcess.entity';
import { Ticket } from './Ticket.entity';
import { User } from './User.entity';

export class TimeTracking<T extends EMode = EMode.entity> {
  /** Alias: abrechenbar */
  isBillable?: boolean;
  /** Alias: anteil */
  proportion?: number;
  /** Alias: anzahl */
  amount?: number;
  /** Alias: auftrag */
  contract?: Contract<T>;
  /** Alias: bZObjMemberCode */
  bZObjMemberId?: number;
  /** Alias: bZObjMemberType */
  bZObjMemberType?: number;
  /** Alias: bemerkung */
  comment?: string;
  /** Alias: benutzer */
  user?: User<T>;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: code */
  id?: number;
  /** Alias: datum */
  date?: string;
  /** Alias: datumUhrzeitBis */
  endDateTime?: string;
  /** Alias: datumUhrzeitVon */
  startDateTime?: string;
  /** Alias: eKPreis */
  purchasePrice?: number;
  /** Alias: einheitCode */
  unitId?: number;
  /** Alias: erfassungArt */
  timeTrackingKind?: TimeTrackingKind;
  /** Alias: gesperrt */
  locked?: boolean;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: kAnsprechpartnerCode */
  customerContactId?: number;
  /** Alias: kNCode */
  sdObjMemberId?: number;
  /** Alias: kNType */
  sdObjMemberType?: number;
  /** Alias: kosten */
  costs?: number;
  /** Alias: kostenart */
  costKind?: number;
  /** Alias: kunde */
  customer?: Customer<T>;
  /** Alias: lohnartCode */
  wageKindId?: number;
  /** Alias: lohnsatz */
  payRate?: number;
  /** Alias: lookupArtCode */
  lookupKindId?: number;
  /** Alias: mAText */
  mAText?: string;
  /** Alias: miteID */
  miteId?: string;
  /** Alias: pause */
  pause?: number;
  /** Alias: planungCode */
  projectProcessId?: number;
  /** Alias: position */
  position?: Position<T>;
  /** Alias: positionCode */
  positionId?: number;
  /** Alias: projectCode */
  projectId?: number;
  /** Alias: projekt */
  project?: Project<T>;
  /** Alias: rECode */
  rEId?: number;
  /** Alias: rechnungCode */
  invoiceId?: number;
  /** Alias: sDObjMemberCode */
  businessPartnerId?: number;
  /** Alias: scanDatei */
  scanFile?: string;
  /** Alias: status */
  status?: number;
  /** Alias: taetigkeitArtikel */
  activityArticle?: Article<T>;
  /** Alias: taetigkeitCode */
  activityArticleId?: number;
  /** Alias: ticketNumber */
  ticketNumber?: number;
  /** Alias: uhrzeitBis */
  endTime?: string;
  /** Alias: uhrzeitVon */
  startTime?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: wartungsobjektCode */
  serviceObjectId?: number;
  /** Alias: zeiteinheiten */
  timeUnits?: number;
  /** Alias: projektvorgang */
  projectProcess?: ProjectProcess<T>;
  /** Alias: ticket */
  ticket?: Ticket<T>;
  /** Alias: ersteller */
  creator?: User<T>;
  /** Alias: erstellerCode */
  creatorId?: number;
  /** Alias: customFields */
  customFieldList?: CustomField<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<TimeTracking> = {
  isBillable: {
    alias: 'abrechenbar',
  },
  proportion: {
    alias: 'anteil',
  },
  amount: {
    alias: 'anzahl',
  },
  contract: {
    alias: 'auftrag',
    entity: Entities.contract,
  },
  bZObjMemberId: {
    alias: 'bZObjMemberCode',
  },
  bZObjMemberType: {
    alias: 'bZObjMemberType',
  },
  comment: {
    alias: 'bemerkung',
  },
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  userId: {
    alias: 'benutzerCode',
  },
  id: {
    alias: 'code',
  },
  date: {
    alias: 'datum',
  },
  endDateTime: {
    alias: 'datumUhrzeitBis',
  },
  startDateTime: {
    alias: 'datumUhrzeitVon',
  },
  purchasePrice: {
    alias: 'eKPreis',
  },
  unitId: {
    alias: 'einheitCode',
  },
  timeTrackingKind: {
    alias: 'erfassungArt',
  },
  locked: {
    alias: 'gesperrt',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customerContactId: {
    alias: 'kAnsprechpartnerCode',
  },
  sdObjMemberId: {
    alias: 'kNCode',
  },
  sdObjMemberType: {
    alias: 'kNType',
  },
  costs: {
    alias: 'kosten',
  },
  costKind: {
    alias: 'kostenart',
  },
  customer: {
    alias: 'kunde',
    entity: Entities.customer,
  },
  wageKindId: {
    alias: 'lohnartCode',
  },
  payRate: {
    alias: 'lohnsatz',
  },
  lookupKindId: {
    alias: 'lookupArtCode',
  },
  mAText: {
    alias: 'mAText',
  },
  miteId: {
    alias: 'miteID',
  },
  pause: {
    alias: 'pause',
  },
  projectProcessId: {
    alias: 'planungCode',
  },
  position: {
    alias: 'position',
    entity: Entities.position,
  },
  positionId: {
    alias: 'positionCode',
  },
  projectId: {
    alias: 'projectCode',
  },
  project: {
    alias: 'projekt',
    entity: Entities.project,
  },
  rEId: {
    alias: 'rECode',
  },
  invoiceId: {
    alias: 'rechnungCode',
  },
  businessPartnerId: {
    alias: 'sDObjMemberCode',
  },
  scanFile: {
    alias: 'scanDatei',
  },
  status: {
    alias: 'status',
  },
  activityArticle: {
    alias: 'taetigkeitArtikel',
    entity: Entities.article,
  },
  activityArticleId: {
    alias: 'taetigkeitCode',
  },
  ticketNumber: {
    alias: 'ticketNumber',
  },
  endTime: {
    alias: 'uhrzeitBis',
  },
  startTime: {
    alias: 'uhrzeitVon',
  },
  updateTime: {
    alias: 'updateTime',
  },
  serviceObjectId: {
    alias: 'wartungsobjektCode',
  },
  timeUnits: {
    alias: 'zeiteinheiten',
  },
  projectProcess: {
    alias: 'projektvorgang',
    entity: Entities.projectProcess,
  },
  ticket: {
    alias: 'ticket',
    entity: Entities.ticket,
  },
  creator: {
    alias: 'ersteller',
    entity: Entities.user,
  },
  creatorId: {
    alias: 'erstellerCode',
  },
  customFieldList: {
    alias: 'customFields',
    entity: Entities.customField,
  },
  __typename: {
    alias: '__typename',
  },
};

export const timeTrackingEntityDefinition: EntitiyDefinition<TimeTracking> = {
  local: {},
  remote: {
    queryName: 'getZeiterfassung',
    fragmentName: 'Zeiterfassung',
    withPaginationWrapper: true,
    mutations: {
      upsert: {
        mutationName: 'upsertZeiterfassung',
        args: [
          { name: 'input', type: 'InputZeiterfassung!' },
          { name: 'drySave', type: 'Boolean' },
        ],
      },
    },
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
