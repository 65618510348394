import styles from './TimeCardPreview.module.scss';

import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useTimeCardDetails } from './hooks/useTimeCardDetails';
import { useTimeEntryMutation } from './hooks/useTimeEntryMutation';
import { TimePreviewActions } from './types';

interface Props {
  actions: TimePreviewActions[];
}

const TimeCardPreviewButtons: React.FC<Props> = (props: Props) => {
  const { actions } = props;
  const { disabledPause, disabled } = useTimeCardDetails();

  const { t } = useTranslation();

  const { add, pause, resume, stop } = useTimeEntryMutation();

  const addButton = actions.includes('Add') ? (
    <Button
      size="large"
      disabled={disabled}
      color={disabled ? 'secondary' : 'primary'}
      startIcon={<AddIcon />}
      sx={{ mr: '-1rem' }}
      onClick={() => {
        add();
      }}
    >
      {t('TIME_TRACKER.ADD_TIME')}
    </Button>
  ) : undefined;

  const resumeButton = actions.includes('Resume') ? (
    <Button
      size="large"
      disabled={disabledPause}
      color={disabledPause ? 'secondary' : 'primary'}
      startIcon={<AddIcon />}
      sx={{ mr: '-1rem' }}
      onClick={() => {
        resume();
      }}
    >
      {t('TIME_TRACKER.END_BREAK_TIME')}
    </Button>
  ) : undefined;

  const pauseButton = actions.includes('Pause') ? (
    <Button
      size="large"
      disabled={disabledPause}
      color={disabledPause ? 'secondary' : 'primary'}
      startIcon={<AddIcon />}
      sx={{ mr: '-1rem' }}
      onClick={() => {
        pause();
      }}
    >
      {t('TIME_TRACKER.ADD_BREAK_TIME')}
    </Button>
  ) : undefined;
  const endButton = actions.includes('End') ? (
    <Button
      size="large"
      disabled={disabled}
      color={disabled ? 'secondary' : 'primary'}
      startIcon={<AddIcon />}
      sx={{ mr: '-1rem' }}
      onClick={() => {
        stop();
      }}
    >
      {t('TIME_TRACKER.END_TIME')}
    </Button>
  ) : undefined;

  return (
    <div className={styles['time-preview__buttons']}>
      {addButton}
      {pauseButton}
      {resumeButton}
      {endButton}
    </div>
  );
};

export default TimeCardPreviewButtons;
