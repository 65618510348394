import BorderColorIcon from '@mui/icons-material/BorderColor';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DateRangeIcon from '@mui/icons-material/DateRange';
import EuroIcon from '@mui/icons-material/Euro';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useTranslation } from 'react-i18next';

import { ReactComponent as IncomingIcon } from '@work4all/assets/icons/incoming.svg';
import { ReactComponent as CheckListIcon } from '@work4all/assets/icons/list-layout.svg';
import { ReactComponent as OutgoingIcon } from '@work4all/assets/icons/outgoing.svg';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ObjectType } from '@work4all/models/lib/Enums/ObjectType.enum';

interface ObjecTypeTranslation {
  label: string;
  icon: React.ComponentType;
  entity: Entities;
}

interface IUseObjectTypeLookUpOptions {
  incoming?: boolean;
  general?: boolean;
}

export const useObjectTypeLookUp = (options?: IUseObjectTypeLookUpOptions) => {
  const { t } = useTranslation();

  // TODO add missing props
  // Kunden-Bestellung
  const lookUp: Record<string, ObjecTypeTranslation> = {
    [ObjectType.E_MAIL]: {
      label: options.general
        ? t(`COMMON.EMAIL`)
        : t(`COMMON.EMAIL_${options?.incoming ? 'INCOMING' : 'OUTGOING'}`),
      icon: options?.incoming ? IncomingIcon : OutgoingIcon,
      entity: Entities.eMail,
    },
    [ObjectType.BRIEF]: {
      label: t('COMMON.LETTER'),
      icon: InsertDriveFileIcon,

      entity: Entities.letter,
    },
    [ObjectType.TICKET]: {
      label: t('COMMON.TICKET'),
      icon: CheckCircleOutlineIcon,
      entity: Entities.ticket,
    },
    [ObjectType.CHECKLISTEN]: {
      label: t('COMMON.CHECKLIST'),
      icon: CheckListIcon,
      entity: Entities.checkList,
    },
    [ObjectType.NOTIZ]: {
      label: t('COMMON.NOTE'),
      icon: BorderColorIcon,
      entity: Entities.note,
    },
    [ObjectType.TERMIN]: {
      label: t('COMMON.APPOINTMENT'),
      icon: DateRangeIcon,
      entity: Entities.appointment,
    },
    [ObjectType.PROJEKT]: {
      label: t('COMMON.PROJECT'),
      icon: FolderIcon,
      entity: Entities.project,
    },
    [ObjectType.DOKUMENT]: {
      label: t('COMMON.DOCUMENT'),
      icon: InsertDriveFileIcon,
      entity: Entities.document,
    },
    [ObjectType.BESUCHSBERICHT]: {
      label: t('COMMON.VISITATIONREPORT'),
      icon: BorderColorIcon,
      entity: Entities.visitationReport,
    },
    [ObjectType.BESTELLUNG]: {
      label: t('COMMON.ORDER'),
      icon: EuroIcon,
      entity: Entities.order,
    },
    [ObjectType.AUFTRAG]: {
      label: t('COMMON.CONTRACT'),
      icon: EuroIcon,
      entity: Entities.contract,
    },
    [ObjectType.LIEFERSCHEIN]: {
      label: t('COMMON.DELIVERYNOTE'),
      icon: InsertDriveFileIcon,
      entity: Entities.deliveryNote,
    },
    [ObjectType.RECHNUNG]: {
      label: t('COMMON.INVOICE'),
      icon: EuroIcon,
      entity: Entities.invoice,
    },
    [ObjectType.KALKULATION]: {
      label: t('COMMON.CALCULATION'),
      icon: EuroIcon,
      entity: Entities.calculation,
    },
    [ObjectType.ANGEBOT]: {
      label: t('COMMON.OFFER'),
      icon: EuroIcon,
      entity: Entities.offer,
    },
    [ObjectType.AUFGABE]: {
      label: t('COMMON.TASK'),
      icon: CheckCircleOutlineIcon,
      entity: Entities.task,
    },
    [ObjectType.EINGANGSLIEFERSCHEIN]: {
      label: t('COMMON.INBOUNDDELIVERYNOTE'),
      icon: BorderColorIcon,
      entity: Entities.inboundDeliveryNote,
    },
    [ObjectType.BEDARFSANFORDERUNG]: {
      label: t('COMMON.REQUISITION'),
      icon: EuroIcon,
      entity: Entities.demand,
    },
    [ObjectType.RECHNUNGSEINGAENGE]: {
      label: t('COMMON.INBOUNDINVOICE'),
      icon: InsertDriveFileIcon,
      entity: Entities.inboundInvoice,
    },
    [ObjectType.TELEFONAT]: {
      label: t('COMMON.CALLMEMO'),
      icon: BorderColorIcon,
      entity: Entities.callMemo,
    },
  };

  return lookUp;
};
