import { createContext, useContext } from 'react';

import { NewComment, NewCommentProps } from './NewComment';

export const NewCommentView = () => {
  const {
    onSubmit,
    canAddOrInsertImage,
    breadcrumbTitle,
    newComment,
    onNewCommentChange,
  } = useContext(NewCommentViewContext);

  return (
    <NewComment
      onSubmit={onSubmit}
      breadcrumbTitle={breadcrumbTitle}
      canAddOrInsertImage={canAddOrInsertImage}
      newComment={newComment}
      onNewCommentChange={onNewCommentChange}
    />
  );
};

export const NewCommentViewContext = createContext<NewCommentProps>(null);
