import { useApolloClient } from '@apollo/client';
import { compact, concat } from 'lodash';
import { useCallback } from 'react';

import { useBuildQuery } from '@work4all/data';
import { ParsedCustomFieldConfig } from '@work4all/data/lib/custom-fields';

import { DataRequest } from '@work4all/models/lib/DataProvider';

import { IRow } from '../../../types';
import { FetchMoreOptions } from '../types';
import { generateRow } from '../utils/generateRow';

import { translateFilter } from './translate-utils';

export const useFetchDefault = (
  requestData: DataRequest,
  pageSize: number,
  customFields: ParsedCustomFieldConfig[]
) => {
  const client = useApolloClient();

  const { query, variables, queryName } = useBuildQuery(requestData, pageSize);

  const createFetchDefault = useCallback(
    (params: FetchMoreOptions) => {
      const { path, offset, filters } = params;

      const combinedFilter = concat(...compact([requestData.filter, filters]));

      const fetchData = async () => {
        const result = await client.query({
          fetchPolicy: 'no-cache',
          query,
          variables: {
            ...variables,
            queryPage: Math.floor(offset / pageSize),
            querySize: pageSize,
            filter: JSON.stringify(
              translateFilter(combinedFilter, requestData.entity, customFields)
            ),
          },
        });

        const { data, total } = result.data[queryName];

        const items: IRow[] = data.map((item) => {
          return generateRow(path, false, item);
        });

        return { items, total };
      };

      return { fetchData };
    },
    [
      client,
      customFields,
      pageSize,
      query,
      queryName,
      requestData.entity,
      requestData.filter,
      variables,
    ]
  );

  return createFetchDefault;
};
