import { ForwardRefExoticComponent, RefAttributes } from 'react';
import { Link, LinkProps } from 'react-router-dom';

const externalProtocols = ['//', 'http', 'https', 'tel', 'mailto'];

export const isAbsoluteLink = (href: string) => {
  return externalProtocols.some((protocol) => href.startsWith(protocol + ':'));
};

interface GetLinkDataReturnType {
  isLink: boolean;
  linkProps: {
    href?: string;
    to?: string;
    component?: ForwardRefExoticComponent<
      LinkProps & RefAttributes<HTMLAnchorElement>
    >;
  };
}

export const getLinkData = (href: string): GetLinkDataReturnType => {
  let linkProps = {};
  const isLink = href !== undefined;
  if (isLink) {
    if (isAbsoluteLink(href)) {
      linkProps = { href: href };
    } else {
      linkProps = { component: Link, to: href };
    }
  }

  return { isLink, linkProps };
};
