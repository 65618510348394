import { useCallback, useEffect, useRef, useState } from 'react';

type MapToPicker<TPickerType> = (field: TPickerType[]) => TPickerType;

export function useMapPickerValueTypes<TPickerType>(
  mapToPicker: MapToPicker<TPickerType>
) {
  const refList = useRef<TPickerType[]>([]);

  const [pickerValue, updatePickerValue] = useState<TPickerType>();

  useEffect(() => {
    updatePickerValue(mapToPicker(refList.current));
  }, [mapToPicker]);

  const onListChanged = useCallback(
    (items: TPickerType[]) => {
      refList.current = items;
      updatePickerValue(mapToPicker(refList.current));
    },
    [mapToPicker]
  );

  return {
    onListChanged,
    pickerValue,
  };
}
