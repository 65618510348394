/* eslint-disable @typescript-eslint/no-explicit-any */
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { fieldDefinitionsByAlias } from '../hooks/data-provider/definitons/fieldDefinitions';

/**
 * Translates the JSON schema replacing all the property names
 * using the configured mappings for entities. Can only translate
 * schemas with only primitive properties.
 *
 * @throws If the schema contains non-primitive properties or if no mapping is found for any of the property names.
 *
 * @param entity Entity type. This will be used to find field definitions to look up property aliases.
 * @param schema JSON schema to translate.
 * @returns A new JSON schema with property names replaced with translated values.
 */
export function translateJsonSchema(entity: Entities, schema: any): any {
  const fields = fieldDefinitionsByAlias[entity];

  const { required, properties } = schema;

  return {
    ...schema,
    required: translateRequired(required),
    properties: translateProperties(properties),
  };

  function translateRequired(required: string[]) {
    return required.map((alias) => translatePropertyName(alias));
  }

  function translateProperties(properties: any) {
    return Object.entries(properties)
      .map(([key, value]) => translateProperty(key, value))
      .reduce<{ [key: string]: any }>((result, entry) => {
        const [key, value] = entry;
        result[key] = value;
        return result;
      }, {});
  }

  function isAllowedType(type: unknown, alias: string): boolean {
    if (typeof type !== 'string') {
      return false;
    }

    // Exception for Custom Fields. This is the only property that can be
    // nested.
    if (type === 'object' || alias === '_customFields') {
      return true;
    }

    switch (type) {
      case 'integer':
      case 'number':
      case 'string':
      case 'boolean':
      case 'array':
      case 'null':
        return true;
      default:
        return false;
    }
  }

  function translateProperty(alias: string, schema: any) {
    const { type } = schema;

    const typeElements = type instanceof Array ? type : [type];

    const invalidType = typeElements.find(
      (typeEl) => !isAllowedType(typeEl, alias)
    );

    if (invalidType) {
      throw new Error(`Unsupported property type "${invalidType}"`);
    }

    const key = translatePropertyName(alias);

    return [key, schema] as const;
  }

  function translatePropertyName(alias: string) {
    const field = fields[alias.toLowerCase()];

    if (!field) {
      // this field will not be properly translated
      return alias;
    }

    return field.key as string;
  }
}
