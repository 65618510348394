import { useCallback, useMemo } from 'react';

import { EntityByObjectType } from '@work4all/models';
import { ModuleAccessRight } from '@work4all/models/lib/Classes/ModuleAccessRight.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { useModuleRights } from './use-module-rights';

type RightsMap = {
  [K in Entities]?: boolean;
};

const filterHiddenEntitiesBySDobjType = (
  rights: ModuleAccessRight[],
  sdObjType: SdObjType
) => {
  const sdObjTypeRelatedRights: RightsMap = {};
  rights
    .filter((el) => el.type === 'WIDGET' && el.sdObjType === sdObjType)
    .reduce((curr, el) => {
      curr[EntityByObjectType[el.objectType]] = !el.hasAccess;
      return curr;
    }, sdObjTypeRelatedRights);
  return sdObjTypeRelatedRights;
};

export const useHiddenEntities = () => {
  const { rights } = useModuleRights();

  const hiddenForCustomer = useMemo<RightsMap>(
    () => filterHiddenEntitiesBySDobjType(rights, SdObjType.KUNDE),
    [rights]
  );

  const hiddenForSupplier = useMemo<Partial<Record<Entities, boolean>>>(
    () => filterHiddenEntitiesBySDobjType(rights, SdObjType.LIEFERANT),
    [rights]
  );

  const hiddenForProject = useMemo<Partial<Record<Entities, boolean>>>(
    () => filterHiddenEntitiesBySDobjType(rights, SdObjType.PROJEKT),
    [rights]
  );

  const isHidden = useCallback(
    (entity: Entities, businessPartneryType?: SdObjType) => {
      if (businessPartneryType) {
        //check if its hidden for this businesspartnerType
        switch (businessPartneryType) {
          case SdObjType.KUNDE:
            return hiddenForCustomer[entity] === true;
          case SdObjType.LIEFERANT:
            return hiddenForSupplier[entity] === true;
          case SdObjType.PROJEKT:
            return hiddenForProject[entity] === true;
        }
      }
      //either its hidden without a context, or hidden in all contexts it is available for
      const businessPartnersToCheck = partiallyAvailableEntities[entity] || [
        SdObjType.KUNDE,
        SdObjType.PROJEKT,
        SdObjType.LIEFERANT,
      ];

      return businessPartnersToCheck.every((bp) => isHidden(entity, bp));
    },
    [hiddenForCustomer, hiddenForProject, hiddenForSupplier]
  );
  return { isHidden };
};

const partiallyAvailableEntities: Partial<Record<Entities, SdObjType[]>> = {
  [Entities.ticket]: [SdObjType.KUNDE, SdObjType.PROJEKT],
  [Entities.offer]: [SdObjType.KUNDE, SdObjType.PROJEKT],
  [Entities.contract]: [SdObjType.KUNDE, SdObjType.PROJEKT],
  [Entities.calculation]: [SdObjType.KUNDE, SdObjType.PROJEKT],
  [Entities.invoice]: [SdObjType.KUNDE, SdObjType.PROJEKT],
  [Entities.demand]: [SdObjType.LIEFERANT, SdObjType.PROJEKT],
  [Entities.order]: [SdObjType.LIEFERANT, SdObjType.PROJEKT],
  [Entities.inboundInvoice]: [SdObjType.LIEFERANT, SdObjType.PROJEKT],
  [Entities.project]: [SdObjType.LIEFERANT, SdObjType.KUNDE],
};
