import { camelCase } from 'lodash';

import {
  ParsedCustomFieldConfig,
  prepareCustomFieldsDefinitions,
} from '@work4all/data/lib/custom-fields';
import { CUSTOM_FIELD_PREFIX } from '@work4all/data/lib/custom-fields/utils/createCustomFieldId';

import { entityDefinition } from '@work4all/models/lib/Classes/entityDefinitions';
import { EntitiyDefinition } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { invariant } from '@work4all/utils';

import { Filters } from '../types';

// TODO This might be duplicated code since there are a lot of other places
// where entity properties are translated. Should check it later and move all
// entity translation utility functions to a single place.
export function translateField(
  path: string,
  entityType: Entities,
  customFields?: ParsedCustomFieldConfig[]
): string {
  invariant(
    path,
    `Path must be a non-empty string, received ${
      typeof path === 'string' ? `"${path}"` : path
    }`
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const definition = entityDefinition[entityType] as EntitiyDefinition<any>;

  invariant(definition, `Definition of entity ${entityType} is not found.`);

  const [first, ...rest] = path.split('.');

  const customFieldsDefinitions = prepareCustomFieldsDefinitions(customFields);
  const fieldDefinitions = {
    ...definition.fieldDefinitions,
    ...customFieldsDefinitions,
  };

  // Custom field.
  if (first.startsWith(CUSTOM_FIELD_PREFIX)) {
    return camelCase(first);
  }

  const field = fieldDefinitions[first];

  invariant(
    field,
    `No definition found for field "${first}" in entity ${entityType}.`
  );

  // A primitive field.
  if (!field.entity) {
    return field.alias;
  }

  const nextPath = rest.join('.');

  // A simple entity.
  if (!Array.isArray(field.entity)) {
    return `${field.alias}.${translateField(nextPath, field.entity)}`;
  }

  // A union.
  return `${translateUnionField(nextPath, field.entity)}`;
}

function translateFieldLax(path: string, entity: Entities): string | Error {
  try {
    return translateField(path, entity);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return error;
  }
}

function translateUnionField(path: string, entities: Entities[]): string {
  const aliases = entities.map((entity) => translateFieldLax(path, entity));

  const isString = (value: unknown): value is string => {
    return typeof value === 'string';
  };

  // Pick the first alias that did successfully resolve.
  const alias = aliases.find(isString);

  invariant(alias, `Could not translate "${path}" for entities [${entities}].`);

  if (process.env.NODE_ENV !== 'production') {
    // Check that all entities in the union return the same result and, if not,
    // report the error.
    if (aliases.some((x) => x !== alias)) {
      const message = [
        `Translating field ${path} for entities [${entities}] produced different results.`,
        '\nTranslation details (entity, result):',
        entities
          .map((entity, index) => `\n\t${entity} -> ${aliases[index]}`)
          .join(''),
        `\n\nThe first resolved alias "${alias}" is being used.`,
      ].join('');

      Promise.reject(new Error(message));
    }
  }

  return alias;
}

export function translateFilter(
  filter: Filters | null | undefined,
  entityType: Entities,
  customFields?: ParsedCustomFieldConfig[]
) {
  if (!filter) return filter;

  const translateLogicalRule = (operator: '$and' | '$or', rules: unknown[]) => {
    return { [operator]: rules.map(translateRule).filter(Boolean) };
  };

  const translateSimpleRule = (field: string, value: unknown) => {
    const alias = translateField(field, entityType, customFields);
    return { [alias]: value };
  };

  const translateRule = (rule: Record<string, unknown>) => {
    if (!rule) return rule;

    const keys = Object.keys(rule);

    switch (keys.length) {
      case 0:
        return null;
      case 1: {
        const [key] = keys;

        if (key === '$and' || key === '$or') {
          const rules = rule[key];

          invariant(
            Array.isArray(rules),
            [
              'When using logical filters the value must be an array.',
              ` Received: ${JSON.stringify(rule)}.`,
            ].join('')
          );

          return translateLogicalRule(key, rules);
        } else {
          return translateSimpleRule(key, rule[key]);
        }
      }
      default:
        throw new Error(
          'Translating filters with multiple keys is currently no supported.'
        );
    }
  };

  return filter.map(translateRule).filter(Boolean);
}

/**
 * translate reverse primitive only
 * @param path
 * @param entityType
 */
export function translateInputField(
  path: string,
  entityType: Entities
): string {
  invariant(
    path,
    `Path must be a non-empty string, received ${
      typeof path === 'string' ? `"${path}"` : path
    }`
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const definition = entityDefinition[entityType] as EntitiyDefinition<any>;

  invariant(definition, `Definition of entity ${entityType} is not found.`);

  return Object.entries(definition.fieldDefinitions).find(
    (x) => x[1].alias.toLowerCase() === path.toLowerCase()
  )[0];
}
