import React from 'react';

import { ReactComponent as DropDownIcon } from '@work4all/assets/icons/outlined-arrow-drop-down-24.svg';

import { ILabeledInput, LabeledInput } from '../labeled-input';

export type ILabeledSelectInputProps = Omit<ILabeledInput, 'iconRight'>;

export const LabeledSelectInput = React.forwardRef<
  HTMLInputElement,
  ILabeledSelectInputProps
>((props, ref) => {
  return <LabeledInput ref={ref} {...props} iconRight={<DropDownIcon />} />;
});
