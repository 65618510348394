import { IGenerateParams } from '@work4all/models/lib/DataProvider';
import { IListQueryVars } from '@work4all/models/lib/Lists';

import { generateUseGraphQLQuery } from '@work4all/utils/lib/graphql-query-generation';
import { merge } from '@work4all/utils/lib/merge';

import { withPrefixedFields } from '../helpers/withPrefixedFields';
import { defaultFields } from '../variables';

export const generateUseFileQuery = <Data>(params: IGenerateParams) => {
  //remove all default rootfields
  const mergedParams: IGenerateParams = merge({
    ...params,
    // always concat defaut fields so all factory functions that generate queries
    // (generateUseFileQuery, generateUseFileQuery, etc) have always the same shape
    // in this case apollo graphql will realize that incoming and existing data can be merged safely
    // otherwise existing data will be replaced with incoming and that is definitely want we don't need.
    fields: defaultFields.concat(withPrefixedFields(params.fields)),
  });

  const {
    useQuery: useDefaultQuery,
    useLazyQuery: useDefaultLazyQuery,
    query,
    queryString,
    queryInner,
  } = generateUseGraphQLQuery(mergedParams);

  const useQuery = (vars: IListQueryVars) => {
    return useDefaultQuery<Data, IListQueryVars>({
      variables: vars,
    });
  };

  const useLazyQuery = (vars: IListQueryVars) => {
    return useDefaultLazyQuery<Data, IListQueryVars>({
      variables: vars,
    });
  };

  return { useQuery, useLazyQuery, query, queryString, queryInner };
};
