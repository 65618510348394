import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { EntityAccessDenyReason } from '../Enums/EntityAccessDenyReason.enum';

export class EntityAccessRightsState<T extends EMode = EMode.entity> {
  /** Alias: action */
  action?: string;
  /** Alias: assigned */
  assigned?: boolean;
  /** Alias: denyReason */
  denyReason?: EntityAccessDenyReason[];

  __typename?: string;
}

const fields: FieldDefinitions<EntityAccessRightsState> = {
  action: {
    alias: 'action',
  },
  assigned: {
    alias: 'assigned',
  },
  denyReason: {
    alias: 'denyReason',
  },
  __typename: {
    alias: '__typename',
  },
};

export const entityAccessRightsStateEntityDefinition: EntitiyDefinition<EntityAccessRightsState> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'EntityAccessRightsState',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
