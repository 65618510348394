import styles from './ImprintPreview.module.scss';

import { Portal } from '@mui/base';
import { Stack } from '@mui/material';
import React from 'react';

export const FullScreenDropZone: React.FC = ({ children }) => {
  return (
    <Portal>
      <div className={styles.overlay}>
        <Stack
          className={styles.container}
          alignItems="start"
          justifyContent="center"
          height="100%"
        >
          <Stack direction="column" flexWrap="wrap" gap="1rem">
            {children}
          </Stack>
        </Stack>
      </div>
    </Portal>
  );
};
