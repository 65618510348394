import styles from './Card.module.scss';

import clsx from 'clsx';

export interface CardProps {
  className?: string;
  children?: React.ReactNode;
}

export function Card(props: CardProps) {
  const { className, children } = props;

  return <div className={clsx(styles.root, className)}>{children}</div>;
}
