import styles from './Resource.module.scss';

import { MbscResource } from '@mobiscroll/react';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, Stack, Typography } from '@mui/material';

import { HookedUserIcon } from '@work4all/components/lib/components/user-icon/useUserIconRegister';

import { User } from '@work4all/models/lib/Classes/User.entity';

import { EMPTY_ROW_ID } from '../constants';

interface Props {
  showSelfOnly: boolean;
  resource: MbscResource;
  users: User[];
  currentUserId: number;
  onUsersChange: (users: User[]) => void;
}

export const Resource = ({
  resource,
  onUsersChange,
  showSelfOnly,
  currentUserId,
  users,
}: Props) => {
  if (resource.placeholder || resource.id === EMPTY_ROW_ID) {
    return null;
  }

  return (
    <div
      className={styles.resource}
      data-id={resource.id}
      data-self={resource.id === currentUserId}
    >
      <Stack
        direction={'row'}
        alignItems="center"
        gap="0.5rem"
        overflow="hidden"
      >
        <HookedUserIcon userId={resource.id as number} size="m" />
        <Typography variant="caption" color="var(--text01)" noWrap>
          {resource.name}
        </Typography>
      </Stack>
      {!showSelfOnly && (
        <IconButton
          className={styles.deselectButton}
          onClick={() => {
            onUsersChange(users.filter((usr) => usr.id !== resource.id));
          }}
        >
          <ClearIcon />
        </IconButton>
      )}
    </div>
  );
};
