import styles from './DesktopNavigation.module.scss';

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@work4all/data/lib/hooks/useNavigation';

import { EventType, sendAmplitudeData } from '../../utils/amplitude/amplitude';
import { AvailableRoutesContext } from '../navigation-wrapper/NavigationWrapper';

import { NavigationItem } from './components/navigation-item/NavigationItem';

export const DesktopNavigation: React.FC = () => {
  const navigationConfig = useContext(AvailableRoutesContext);
  const { activePathname } = useNavigation();
  const { t } = useTranslation();

  return (
    <div className={styles['desktop-navigation']}>
      {navigationConfig.map((item) => {
        return (
          <NavigationItem
            key={item.pathname}
            title={t(item.title)}
            active={activePathname.includes(item.pathname)}
            href={`${item.pathname}`}
            onClick={() => {
              sendAmplitudeData(EventType.MainNavigationTap, {
                entryPoint: item.title,
              });
            }}
          />
        );
      })}
    </div>
  );
};
