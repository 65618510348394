import styles from './NotificationDate.module.scss';

import { Typography } from '@mui/material';

import { formatRelativeDate } from '../utils/format-relative-date';

export interface NotificationDateProps {
  date: string;
}

export function NotificationDate(props: NotificationDateProps) {
  const { date } = props;

  return (
    <div className={styles.root}>
      <Typography variant="caption" noWrap>
        {formatRelativeDate(date)}
      </Typography>
    </div>
  );
}
