import styles from './Attachments.module.scss';

import clsx from 'clsx';
import { useCallback, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { TempFileManagerContext } from '@work4all/data/lib/hooks/data-provider/useTempFileManager';

import { useLock } from '../../hooks';

import { AttachmentProps } from './Attachments';

export const AttachmentsDropZone = (
  props: AttachmentProps & { children; className?: string; single?: boolean }
) => {
  const {
    children,
    disableAddAction = false,
    fullHeight = true,
    className = 'string',
    single = false,
  } = props;

  const lock = useLock();

  const attachmentsContext = useContext(TempFileManagerContext);
  const { uploadFiles: uploadAttachments } = attachmentsContext;

  const isUploadActionAllowed =
    !disableAddAction &&
    !lock?.locked &&
    !(single && attachmentsContext.fileList.length);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFullHeight(true);
      setFileDragActive(false);
      if (isUploadActionAllowed) {
        uploadAttachments(acceptedFiles);
      }
    },
    [isUploadActionAllowed, uploadAttachments]
  );

  const onDragEnter = useCallback(
    (e) => {
      if (isUploadActionAllowed) {
        setFileDragActive(true);
      }
      if (e.dataTransfer.items[0].type.startsWith('image/')) {
        setFullHeight(false);
      }
    },
    [isUploadActionAllowed]
  );

  const { getRootProps } = useDropzone({
    onDrop,
    onDragEnter,
    onDragLeave: () => {
      setFullHeight(true);
      setFileDragActive(false);
    },
  });

  const [fileDragActive, setFileDragActive] = useState(false);
  const [useFullHeight, setFullHeight] = useState(fullHeight);

  return (
    <div
      {...getRootProps()}
      className={clsx(className, styles.dropArea, {
        [styles.dropzoneActive]: fileDragActive,
        [styles.fullHeight]: useFullHeight,
      })}
    >
      {children}
    </div>
  );
};
