import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { VacationDayKind } from '../Enums/VacationDayKind.enum';

import { LookUp } from './LookUp.entity';
import { User } from './User.entity';
import { VacationKind } from './VacationKind.entity';

export class Vacation<T extends EMode = EMode.entity> {
  /** Alias: benutzer */
  user?: User<T>;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: benutzerCodeGenehmigung */
  approverUserId?: number;
  /** Alias: code */
  id?: number;
  /** Alias: datum */
  date?: string;
  /** Alias: datumAntrag */
  applicationDate?: string;
  /** Alias: datumGenehmigung */
  approveDate?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: menge */
  amount?: number;
  /** Alias: notiz */
  note?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: urlaubsArt */
  vacationKind?: LookUp<T>;
  /** Alias: urlaubsArtCode */
  vacationKindId?: number;
  /** Alias: vacationDayPosition */
  vacationDayPosition?: VacationDayKind;
  /** Alias: urlaubsgenehmiger */
  vacationApprover?: User<T>;
  /** Alias: vacationRequestId */
  vacationRequestId?: string;
  /** Alias: simpleUrlaubsArt */
  simpleVacationKind?: VacationKind<T>;

  __typename?: string;
}

const fields: FieldDefinitions<Vacation> = {
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  userId: {
    alias: 'benutzerCode',
  },
  approverUserId: {
    alias: 'benutzerCodeGenehmigung',
  },
  id: {
    alias: 'code',
  },
  date: {
    alias: 'datum',
  },
  applicationDate: {
    alias: 'datumAntrag',
  },
  approveDate: {
    alias: 'datumGenehmigung',
  },
  insertTime: {
    alias: 'insertTime',
  },
  amount: {
    alias: 'menge',
  },
  note: {
    alias: 'notiz',
  },
  updateTime: {
    alias: 'updateTime',
  },
  vacationKind: {
    alias: 'urlaubsArt',
    entity: Entities.lookUp,
  },
  vacationKindId: {
    alias: 'urlaubsArtCode',
  },
  vacationDayPosition: {
    alias: 'vacationDayPosition',
  },
  vacationApprover: {
    alias: 'urlaubsgenehmiger',
    entity: Entities.user,
  },
  vacationRequestId: {
    alias: 'vacationRequestId',
  },
  simpleVacationKind: {
    alias: 'simpleUrlaubsArt',
    entity: Entities.vacationKind,
  },
  __typename: {
    alias: '__typename',
  },
};

export const vacationEntityDefinition: EntitiyDefinition<Vacation> = {
  local: {},
  remote: {
    queryName: 'getUrlaub',
    fragmentName: 'Urlaub',
    withPaginationWrapper: true,
    mutations: {
      upsert: {
        mutationName: 'upsertUrlaub',
        args: [
          { name: 'input', type: 'InputUrlaub!' },
          { name: 'drySave', type: 'Boolean' },
        ],
      },
    },
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
