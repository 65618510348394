import { Theme, useMediaQuery } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';
import { ErpPreviewContainer } from '@work4all/components/lib/components/entity-preview/erp-preview/ErpPreviewContainer';

import { Contract } from '@work4all/models/lib/Classes/Contract.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Table } from '../../components/data-tables/table/Table';
import { PersonalAndGeneralView } from '../filters/PersonalAndGeneralView';

import { EntityTable } from './EntityTable';
import { PreviewMobileWrapper } from './PreviewMobileWrapper';
import schema from './schemata/contract-table-schema.json';
import { TableLayoutContext, useTableLayoutState } from './table-layout';
import { TableNoRowsPlaceholder } from './TableNoRowsPlaceholder';
import { useDataTable } from './use-data-table';
import { useDeleteEntitiesToolbarConfig } from './use-delete-entities-toolbar-config';
import { useErpTableMaskHandlers } from './use-erp-table-mask-handlers';
import { useUserColumnConfigs } from './use-user-column-configs';

type Props = EntityTable;
const defaultSort = [
  { field: 'contractDate', direction: SortDirection.DESCENDING },
];
const manualGroupBy = true;

export const ContractsTable = React.forwardRef<TableInstance, Props>(
  (props, ref) => {
    const { prefilter, onOpenMask, ...rest } = props;
    const { entity: entityType } = schema as never;

    const tableStateBag = useTableStateBag();

    const layoutState = useTableLayoutState();
    const [layout] = layoutState;

    const {
      columnConfigs,
      cardConfig,
      prepareRowDisplayModifiers,
      data,
      footerData,
      fetchMore,
      total,
      selectedEntity,
      initialSortBy,
      pending,
    } = useDataTable<Contract, never>({
      layout,
      schema: schema as never,
      tableStateBag,
      prefilter,
      defaultSort,
      enableFooter: true,
    });

    const maskHandlers = useErpTableMaskHandlers(entityType, onOpenMask);

    const deleteConfig = useDeleteEntitiesToolbarConfig({
      entityType,
    });

    const [userConfig, userConfigMethods] = useUserColumnConfigs({
      layout,
      entityType,
      columnConfigs,
    });

    const isDesktop = useMediaQuery<Theme>((theme) =>
      theme.breakpoints.up('sm')
    );

    const { t } = useTranslation();

    const rowModifiers = useCallback(
      (value: Contract) => {
        const modifiers = prepareRowDisplayModifiers(value);
        return {
          ...modifiers,
          isFaded: value.isClosed && value.frozen,
          isShade1: !value.isClosed && value.frozen,
        };
      },
      [prepareRowDisplayModifiers]
    );

    if (!userConfig) return null;

    return (
      <TableLayoutContext value={layoutState}>
        <Table
          pending={pending}
          noRowsRenderer={() => <TableNoRowsPlaceholder />}
          ref={ref}
          layout={layout}
          cardConfig={cardConfig}
          areas={{
            left: {
              content: <PersonalAndGeneralView entity={Entities.contract} />,
              resizable: true,
              collapseConfig: {
                title: t('COMMON.FILTER'),
              },
            },
            right: selectedEntity !== null && {
              content: (
                <PreviewMobileWrapper
                  active={!isDesktop}
                  actions={{
                    edit: maskHandlers.edit,
                    convert: {
                      exclude: entityType,
                      handler: maskHandlers.convert,
                    },
                    remove: deleteConfig,
                  }}
                >
                  <ErpPreviewContainer
                    entityId={selectedEntity.id}
                    entity={entityType}
                    onCloseClicked={() =>
                      tableStateBag.tableInstance.toggleAllRowsSelected(false)
                    }
                    onEditClicked={maskHandlers.edit?.handler}
                    onShareClicked={maskHandlers.share}
                  />
                </PreviewMobileWrapper>
              ),
            },
          }}
          actions={{
            add: maskHandlers.create,
            edit: maskHandlers.edit,
            remove: deleteConfig,
            resetColumns: userConfigMethods.remove,
            createWidget: true,
          }}
          columnConfigs={userConfig}
          manualGroupBy={manualGroupBy}
          initialSortBy={initialSortBy}
          loadMoreItems={fetchMore}
          prepareRowDisplayModifiers={rowModifiers}
          displayFooter
          footerData={footerData}
          data={data}
          total={total}
          {...rest}
        />
      </TableLayoutContext>
    );
  }
);
