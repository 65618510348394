import { UserRights } from '@work4all/models/lib/additionalEnums/UserRights';
import { ModuleAccessRightType } from '@work4all/models/lib/Enums/ModuleAccessRightType.enum';

export enum StatisticType {
  dashboard = 'dashboard',
  salesvolumeyear = 'salesvolumeyear',
  salesvolumebycustomer = 'salesvolumebycustomer',
  salesvolumebycostcenter = 'salesvolumebycostcenter',
  salesvolumebyitem = 'salesvolumebyitem',
  timecard = 'timecard',
  companyreport = 'companyreport',
  erp = 'erp',
  hr = 'hr',
  common = 'common',
}

export interface StatisticDefinitionItem {
  title: string;
  statisticType?: StatisticType;
  anyOfRights?: UserRights[];
  needsModule?: ModuleAccessRightType;
  children?: StatisticDefinitionItem[];
}

export const statisticDefinitions: StatisticDefinitionItem[] = [
  {
    title: 'common',
    statisticType: StatisticType.common,
    children: [
      {
        title: 'dashboard',
        statisticType: StatisticType.dashboard,
      },
      {
        title: 'timecard',
        statisticType: StatisticType.timecard,
        needsModule: ModuleAccessRightType.FEATURE_WORK_TIME_TRACKING,
        anyOfRights: [
          UserRights.Zeitkarte_Alle,
          UserRights.Zeitkarte_AnsichtEigene,
          UserRights.Zeitkarte_AnsichtEigeneUndZugeordneteMitarbeiter,
        ],
      },
    ],
  },
  {
    title: 'erp',
    statisticType: StatisticType.erp,
    children: [
      {
        title: 'salesvolumebycustomer',
        statisticType: StatisticType.salesvolumebycustomer,
      },
      {
        title: 'salesvolumebycostcenter',
        statisticType: StatisticType.salesvolumebycostcenter,
      },
      {
        title: 'salesvolumebyitem',
        statisticType: StatisticType.salesvolumebyitem,
      },
      {
        title: 'salesvolumeyear',
        statisticType: StatisticType.salesvolumeyear,
      },
      {
        title: 'companyreport',
        statisticType: StatisticType.companyreport,
      },
    ],
  },
];

export const getStatisticDefByStatisticType = (
  type: StatisticType,
  parent: StatisticDefinitionItem = null,
  definitions: StatisticDefinitionItem[] = statisticDefinitions
): { parent: StatisticDefinitionItem; item: StatisticDefinitionItem } => {
  let result: {
    parent: StatisticDefinitionItem;
    item: StatisticDefinitionItem;
  } = null;
  definitions.some((x) => {
    if (x.statisticType === type) {
      result = { parent, item: x };
      return true;
    }
    if (!result && x.children) {
      result = getStatisticDefByStatisticType(type, x, x.children);
    }
  });

  return result;
};
