import styles from './NotificationsPage.module.scss';

import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  Button,
  Divider,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { BackButton } from '@work4all/components/lib/components/BackButton';

import { MaskModal } from '../../../components/entity-picker/MaskModal';
import { usePageTitle } from '../../../hooks';
import { NotificationActions } from '../components/NotificationActions/NotificationActions';
import { useLatestNotifications } from '../hooks/use-latest-notifications';

import { NOTIFICATION_PAGE_QUERY_PAGE_SIZE } from './constant';
import { NotificationListVirtualized } from './NotificationListVirtualized';

export function NotificationsPage() {
  const query = useLatestNotifications({
    pageSize: NOTIFICATION_PAGE_QUERY_PAGE_SIZE,
  });

  return (
    <NotificationActions
      onMutationCompleted={() => {
        query.refetch();
      }}
    >
      {({ mask, onCloseMask, onNotificationClick, onMarkAllAsReadClick }) => {
        return (
          <div className={styles.root}>
            <Header />
            <Toolbar onMarkAllAsReadClick={onMarkAllAsReadClick} />

            <Divider />

            <Box sx={{ flex: '1' }}>
              <NotificationListVirtualized
                items={query.data}
                total={query.total}
                fetchMore={query.fetchMore}
                onNotificationClick={(item) => onNotificationClick([item])}
              />
            </Box>

            <MaskModal
              {...mask}
              onClose={onCloseMask}
              onAfterSave={onCloseMask}
            />
          </div>
        );
      }}
    </NotificationActions>
  );
}

function Header() {
  const { t } = useTranslation();

  const isViewportDownMd = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  const title = t(`NOTIFICATIONS.TITLE`);

  usePageTitle(title);

  return (
    <div className={styles.header}>
      <BackButton href="/more" />

      <Typography
        variant={isViewportDownMd ? 'h4' : 'h3'}
        color="text.secondary"
      >
        {title}
      </Typography>
    </div>
  );
}

function Toolbar({
  onMarkAllAsReadClick,
}: {
  onMarkAllAsReadClick: () => void;
}) {
  const { t } = useTranslation();

  return (
    <div>
      {
        <Button
          size="large"
          color="primary"
          startIcon={<CheckIcon />}
          onClick={onMarkAllAsReadClick}
        >
          {t('NOTIFICATIONS.MARK_ALL_AS_READ')}
        </Button>
      }
    </div>
  );
}
