import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';

import { MailboxContent } from '@work4all/models/lib/Classes/MailboxContent.entity';
import { MailAssignStatus } from '@work4all/models/lib/Enums/MailAssignStatus.enum';

import { useMailboxContentManagerContext } from '../context';

export function MailboxContentStatusCell(
  props: CellProps<MailboxContent, MailAssignStatus>
) {
  const status = props.value;
  const mailboxContent = props.row.original;
  const id = mailboxContent.id;

  const { t } = useTranslation();

  const context = useMailboxContentManagerContext();

  const canAssign = context.canAssign(id);
  const canIgnore = context.canIgnore(id);

  const resolveStatusText = (status: MailAssignStatus) => {
    switch (status) {
      case MailAssignStatus.NOT_YET_ASSIGNED:
        if (canAssign) {
          return t('LIST.MAILBOXCONTENT.ACTION.ASSIGN');
        } else {
          return t('MAIL_ASSIGN_STATUS.NOT_YET_ASSIGNED');
        }
      case MailAssignStatus.ALREADY_ASSIGNED:
      case MailAssignStatus.ASSIGNED_TO_OBJECT:
      case MailAssignStatus.ASSIGNED_TO_OBJECT_AND_MAIL:
        return t('MAIL_ASSIGN_STATUS.ALREADY_ASSIGNED');
      case MailAssignStatus.ITEM_IGNORED:
        return t('MAIL_ASSIGN_STATUS.ITEM_IGNORED');
    }
  };

  const buttonProps = canAssign
    ? {
        component: 'button',
        onClick: () => {
          context.assign([id]);
        },
      }
    : { component: 'span' };

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography
        noWrap
        sx={(theme) => ({
          flex: '0 1 auto',
          color: canAssign ? theme.palette.primary.main : undefined,
          appearance: 'none',
          background: 'none',
          border: 'none',
          outline: 'none',
          cursor: canAssign ? 'pointer' : 'default',
        })}
        {...buttonProps}
      >
        {resolveStatusText(status)}
      </Typography>
      {canIgnore && (
        <IconButton
          color="primary"
          sx={{ flex: 'none' }}
          onClick={() => {
            context.ignore([id]);
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Box>
  );
}
