import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { SalesOpportunityRating } from './SalesOpportunityRating.entity';

export type ChildItemCreatedObject<T extends EMode = EMode.entity> =
  T extends EMode.entity
    ? SalesOpportunityRating<T>
    : {
        /** Alias: SalesOpportunityRating */
        salesOpportunityRating?: SalesOpportunityRating<T>;
      };

const fields: FieldDefinitions<ChildItemCreatedObject<EMode.query>> = {
  salesOpportunityRating: {
    alias: 'salesOpportunityRating',
    entity: Entities.salesOpportunityRating,
  },
};

export const childItemCreatedObjectEntityDefinition: EntitiyDefinition<
  ChildItemCreatedObject<EMode.query>
> = {
  kind: 'union',
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'ChildItemCreatedObject',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
