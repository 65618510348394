import styles from './NotificationIcon.module.scss';

export interface NotificationIconProps {
  children?: React.ReactNode;
}

export function NotificationIcon(props: NotificationIconProps) {
  const { children } = props;

  return <div className={styles.root}>{children}</div>;
}
