import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputAttachementAdd } from './InputAttachementAdd.entity';
import { InputSalesOpportunityAttachementModify } from './InputSalesOpportunityAttachementModify.entity';

export class InputSalesOpportunityAttachementRelation<
  T extends EMode = EMode.entity
> {
  /** Alias: remove */
  remove?: any[];
  /** Alias: add */
  add?: InputAttachementAdd<T>[];
  /** Alias: modify */
  modify?: InputSalesOpportunityAttachementModify<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputSalesOpportunityAttachementRelation> = {
  remove: {
    alias: 'remove',
  },
  add: {
    alias: 'add',
  },
  modify: {
    alias: 'modify',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputSalesOpportunityAttachementRelationEntityDefinition: EntitiyDefinition<InputSalesOpportunityAttachementRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputSalesOpportunityAttachementRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
