import { useCallback, useEffect, useRef } from 'react';

import { UsePostionState } from './use-position-state';

type UsePositionUpdateTitleProps = Pick<
  UsePostionState,
  'onEditPosition' | 'positions'
>;

export const usePositionUpdateTitle = (props: UsePositionUpdateTitleProps) => {
  const { positions, onEditPosition } = props;
  // TODO: workaround of post edit
  const refPositions = useRef(positions);
  const newTitle = useRef<string>('');

  useEffect(() => {
    if (!newTitle.current) return;
    if (positions?.length === refPositions.current.length) return;
    // TODO: workaround until api is not ready
    const last = positions[positions?.length - 1];
    if (!last.id) return;
    if (last)
      onEditPosition({
        position: {
          id: last.id,
          longtext: newTitle.current,
        },
      });
    newTitle.current = '';
    refPositions.current = positions;
  }, [positions?.length, onEditPosition, positions]);

  const setTitleAsFollowAction = useCallback((title: string) => {
    newTitle.current = title;
  }, []);

  return {
    setTitleAsFollowAction,
  };
};
