import { gql, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';

import { IAttachmentEntity } from '@work4all/models';
import { TempFile } from '@work4all/models/lib/Classes/TempFile.entity';
import { CreateTempFileOriginType } from '@work4all/models/lib/Enums/CreateTempFileOriginType.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

const CREATE_TEMP_FILE_ORIGIN_TYPE_BY_ENTITY = {
  [Entities.eMail]: CreateTempFileOriginType.E_MAIL_ATTACHEMENT,
  [Entities.document]: CreateTempFileOriginType.DOCUMENT,
  [Entities.letter]: CreateTempFileOriginType.LETTER,
};

export const useConvertAttachments = (
  entity: Entities,
  ids: string[],
  skip?: boolean
) => {
  const [createTempFile] = useMutation<{ createTempFile: TempFile }>(
    CREATE_TEMP_FILE
  );

  const [convertedAttachments, setConvertedAttachments] = useState<
    IAttachmentEntity[]
  >([]);

  useEffect(() => {
    if (skip || !ids || ids.length === 0) return;

    const type = CREATE_TEMP_FILE_ORIGIN_TYPE_BY_ENTITY[entity];
    if (!type) {
      throw new Error("Type isn't defined");
    }

    const tempAttachments = ids.map(async (id) => {
      const response = await createTempFile({
        variables: {
          type,
          createTempFileId: id,
        },
      });

      return response;
    });

    Promise.all(tempAttachments).then((attachments) => {
      setConvertedAttachments(
        attachments.map((attachment) => {
          return {
            ...attachment.data.createTempFile,
            fileName:
              attachment.data.createTempFile.fileInfos.fileEntityFilename,
            type: attachment.data.createTempFile.fileInfos.fileRequestType,
          };
        })
      );
    });
  }, [ids, createTempFile, skip, entity]);

  return convertedAttachments;
};

const CREATE_TEMP_FILE = gql`
  mutation CreateTempFile(
    $type: CreateTempFileOriginType!
    $createTempFileId: String!
  ) {
    createTempFile(type: $type, id: $createTempFileId) {
      dateiname
      datum
      fileInfos {
        previewUrl
        previewMimeType
        fileSize
        fileServiceProviderInfos {
          filename
          customUrl
          exists
          fspUrl
          id
          key
          mimeType
          size
          thumbnail
        }
        downloadMimeType
        downloadUrl
        fileEntityFilename
        fileRequestType
      }
      id
    }
  }
`;
