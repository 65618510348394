import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class StringKeyValue<T extends EMode = EMode.entity> {
  /** Alias: key */
  key?: string;
  /** Alias: value */
  value?: string;

  __typename?: string;
}

const fields: FieldDefinitions<StringKeyValue> = {
  key: {
    alias: 'key',
  },
  value: {
    alias: 'value',
  },
  __typename: {
    alias: '__typename',
  },
};

export const stringKeyValueEntityDefinition: EntitiyDefinition<StringKeyValue> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'StringKeyValue',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
