import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class CustomerGroup<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: index */
  index?: number;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: level */
  level?: number;
  /** Alias: name */
  name?: string;
  /** Alias: updateTime */
  updateTime?: string;

  __typename?: string;
}

const fields: FieldDefinitions<CustomerGroup> = {
  id: {
    alias: 'code',
  },
  index: {
    alias: 'index',
  },
  insertTime: {
    alias: 'insertTime',
  },
  level: {
    alias: 'level',
  },
  name: {
    alias: 'name',
  },
  updateTime: {
    alias: 'updateTime',
  },
  __typename: {
    alias: '__typename',
  },
};

export const customerGroupEntityDefinition: EntitiyDefinition<CustomerGroup> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'KundenGruppe',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
