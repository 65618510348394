import { useEffect } from 'react';
import { actions, Row } from 'react-table';

import { getRowId } from '@work4all/components/lib/dataDisplay/basic-table/BasicTable';
import {
  GroupedItem,
  GroupedItems,
} from '@work4all/components/lib/dataDisplay/basic-table/hooks/query-table-data/types';
import { ITableStateBag } from '@work4all/components/lib/dataDisplay/basic-table/hooks/useTableStateBag';

export function useSelectedTableRows<T>({
  data,
  tableStateBag,
}: {
  data: GroupedItems;
  tableStateBag: ITableStateBag;
}): { selectedEntity: T; selectedEntities: T[] } {
  const selectedRowIds = tableStateBag.tableState?.selectedRowIds;

  // Whenever data or selection changes, check if selected rows are present in
  // the dataset and deselect ones that have been removed.
  useEffect(() => {
    if (!tableStateBag.tableInstance || !selectedRowIds) {
      return;
    }

    const presentIds = getAllRowIds(data);

    const selectedIds = Object.keys(selectedRowIds);
    const allowedIds = selectedIds.filter((it) => presentIds.has(it));

    // If some of the selected rows are not present in the current dataset,
    // deselect them.
    if (selectedIds.length !== allowedIds.length) {
      // It is not possible to deselect a row that does not exist. So instead we
      // reset the selection and select the remaining rows again.

      tableStateBag.tableInstance.dispatch({
        type: actions.resetSelectedRows,
      });

      for (const rowId of allowedIds) {
        tableStateBag.tableInstance.toggleRowSelected(rowId, true);
      }
    }
  }, [data, selectedRowIds, tableStateBag.tableInstance]);

  const selectedEntity =
    tableStateBag?.tableInstance?.selectedFlatRows?.length === 1
      ? (tableStateBag.tableInstance.selectedFlatRows[0].original as T)
      : null;

  const x = Object.keys(selectedRowIds ?? {})
    .map((id) => {
      return tableStateBag?.tableInstance?.rowsById[id]?.original;
    })
    .filter(Boolean);

  const selectedEntities: T[] =
    tableStateBag?.tableInstance?.selectedFlatRows?.map(
      (row) => row.original as T
    ) ?? null;

  return { selectedEntity, selectedEntities };
}

// Get all ids of all rows and their subrows.
function getAllRowIds(data: GroupedItems) {
  const presentIds = new Set<string>();

  function addRowId(
    row: GroupedItem,
    relativeIndex: number,
    parent: Row<object>
  ): void {
    const id = getRowId(row, relativeIndex, parent);

    presentIds.add(id);

    row.subRows?.forEach((subRow, index) => {
      addRowId(subRow, index, { id } as Row);
    });
  }

  data.forEach((row, index) => {
    addRowId(row, index, null);
  });

  return presentIds;
}
