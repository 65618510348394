import { useTableStateBag } from '@work4all/components/lib/dataDisplay/basic-table';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { OnOpenMask } from '../../containers/mask-overlays/mask-overlay/types';

import { useTableMaskHandlers } from './use-table-mask-handlers';

export function useErpTableMaskHandlers(
  entityType: Entities,
  onOpenMask: OnOpenMask,
  columnId = 'businessPartnerContactCombined.businessPartner.data.id',
  erpEntity?: Entities.customer | Entities.supplier,
  forwardContact?: boolean
) {
  const shouldForwardContact = forwardContact || false;
  const tableStateBag = useTableStateBag();
  const bpCol = tableStateBag.columnsById[columnId];
  const bp = bpCol?.filterValue?.value?.[0];
  const contactColumnId = 'businessPartnerContactCombined.contact.id';
  const contactCol = tableStateBag.columnsById[contactColumnId];
  const contactId = contactCol?.filterValue?.value?.[0]?.id;

  const defaultBp =
    bp?.id && bp?.__typename
      ? {
          id: bp?.id,
          entity:
            bp?.__typename === 'Kunde' ? Entities.customer : Entities.supplier,
        }
      : null;

  const template: {
    id: number | string;
    entity: string;
  } = erpEntity
    ? {
        id: bp?.id,
        entity: erpEntity,
      }
    : defaultBp;

  if (template && shouldForwardContact)
    template.entity = contactId
      ? `contact:${contactId}:${template?.entity}`
      : template.entity;

  return useTableMaskHandlers(entityType, onOpenMask, template);
}
