import { PrepareHeadersProps } from '../interfaces';

export const prepareHeaders = ({
  defaultHeaders,
  contentType,
}: PrepareHeadersProps) => {
  const headers: HeadersInit = { ...defaultHeaders };

  if (contentType) {
    headers['Content-Type'] = contentType;
  }

  return headers;
};
