import React from 'react';
import {
  actions,
  ensurePluginOrder,
  makePropGetter,
  Row,
  useGetLatest,
  useMountedLayoutEffect,
} from 'react-table';

import { TableRow } from '../types';

function flattenBy(arr, key) {
  const flat = [];

  const recurse = (arr) => {
    arr.forEach((d) => {
      if (!d[key]) {
        flat.push(d);
      } else {
        recurse(d[key]);
      }
    });
  };

  recurse(arr);

  return flat;
}

function getFirstDefined(...args) {
  for (let i = 0; i < args.length; i += 1) {
    if (typeof args[i] !== 'undefined') {
      return args[i];
    }
  }
}

// Actions
actions.resetGroupBy = 'resetGroupBy';
actions.setGroupBy = 'setGroupBy';
actions.toggleGroupBy = 'toggleGroupBy';

export const useGroupBy = (hooks) => {
  hooks.getGroupByToggleProps = [defaultGetGroupByToggleProps];
  hooks.stateReducers.push(reducer);
  hooks.visibleColumnsDeps.push((deps, { instance }) => [
    ...deps,
    instance.state.groupBy,
  ]);
  hooks.visibleColumns.push(visibleColumns);
  hooks.useInstance.push(useInstance);
  hooks.prepareRow.push(prepareRow);
};

useGroupBy.pluginName = 'useGroupBy';

const defaultGetGroupByToggleProps = (props, { header }) => [
  props,
  {
    onClick: header.canGroupBy
      ? (e) => {
          e.persist();
          header.toggleGroupBy();
        }
      : undefined,
    style: {
      cursor: header.canGroupBy ? 'pointer' : undefined,
    },
    title: 'Toggle GroupBy',
  },
];

// Reducer
function reducer(state, action, previousState, instance) {
  if (action.type === actions.init) {
    return {
      groupBy: [],
      ...state,
    };
  }

  if (action.type === actions.resetGroupBy) {
    return {
      ...state,
      groupBy: instance?.initialState.groupBy || [],
    };
  }

  if (action.type === actions.setGroupBy) {
    const { value } = action;
    return {
      ...state,
      groupBy: value,
    };
  }

  if (action.type === actions.toggleGroupBy) {
    const { columnId, value: setGroupBy } = action;

    const resolvedGroupBy =
      typeof setGroupBy !== 'undefined'
        ? setGroupBy
        : !state.groupBy.includes(columnId);

    if (resolvedGroupBy) {
      return {
        ...state,
        groupBy: [...state.groupBy, columnId],
      };
    }

    return {
      ...state,
      groupBy: state.groupBy.filter((d) => d !== columnId),
    };
  }
}

function visibleColumns(
  columns,
  {
    instance: {
      state: { groupBy },
    },
  }
) {
  columns.forEach((column) => {
    column.isGrouped = groupBy.includes(column.id);
    column.groupedIndex = groupBy.indexOf(column.id);
  });

  return columns;
}

function useInstance(instance) {
  const {
    data,
    rows,
    flatRows,
    rowsById,
    allColumns,
    flatHeaders,
    groupByFn = defaultGroupByFn,
    manualGroupBy,
    plugins,
    state: { groupBy },
    dispatch,
    autoResetGroupBy = true,
    disableGroupBy,
    defaultCanGroupBy,
    getHooks,
  } = instance;

  ensurePluginOrder(plugins, ['useColumnOrder', 'useFilters'], 'useGroupBy');

  const getInstance = useGetLatest(instance);

  allColumns.forEach((column) => {
    const {
      accessor,
      defaultGroupBy: defaultColumnGroupBy,
      disableGroupBy: columnDisableGroupBy,
    } = column;

    column.canGroupBy = accessor
      ? getFirstDefined(
          column.canGroupBy,
          columnDisableGroupBy === true ? false : undefined,
          disableGroupBy === true ? false : undefined,
          true
        )
      : getFirstDefined(
          column.canGroupBy,
          defaultColumnGroupBy,
          defaultCanGroupBy,
          false
        );

    if (column.canGroupBy) {
      column.toggleGroupBy = () => instance.toggleGroupBy(column.id);
    }

    column.Aggregated = column.Aggregated || column.Cell;
  });

  const toggleGroupBy = React.useCallback(
    (columnId, value) => {
      dispatch({ type: actions.toggleGroupBy, columnId, value });
    },
    [dispatch]
  );

  const setGroupBy = React.useCallback(
    (value) => {
      dispatch({ type: actions.setGroupBy, value });
    },
    [dispatch]
  );

  flatHeaders.forEach((header) => {
    header.getGroupByToggleProps = makePropGetter(
      getHooks().getGroupByToggleProps,
      { instance: getInstance(), header }
    );
  });

  const [
    groupedRows,
    groupedFlatRows,
    groupedRowsById,
    onlyGroupedFlatRows,
    onlyGroupedRowsById,
    nonGroupedFlatRows,
    nonGroupedRowsById,
  ] = React.useMemo(() => {
    if (manualGroupBy || !groupBy.length) {
      // We need to update the rows groups, so that other react-table plugins can understand that our server-side manually grouped rows are actually groups and not simple rows.
      // This is required for selection to work correctly.

      // Mark grouped rows as such, so that useRowSelect plugin can correctly handle their selected state
      flatRows.forEach((row: TableRow) => {
        if (row.original.isGrouped) {
          row.isGrouped = true;
          row.groupByID = row.original.meta.groupByID;
          row.groupByVal = row.original.meta.groupByVal;
          row.groupByLabel = row.original.meta.groupByLabel;
          row.groupByTotalCount = row.original.meta.groupByTotalCount;
        }
      });

      // Only select rows that are not groupes.
      // These rows will be selected when the checkbox in the table header is clicked.
      const nonGroupedFlatRows: Row[] = flatRows.filter(
        (row: Row) => !row.isGrouped
      );
      const nonGroupedRowsById = nonGroupedFlatRows.reduce<Record<string, Row>>(
        (acc, row) => {
          acc[row.id] = row;
          return acc;
        },
        {}
      );

      const onlyGroupedFlatRows: Row[] = flatRows.filter(
        (row: Row) => row.isGrouped
      );

      const onlyGroupedRowsById = onlyGroupedFlatRows.reduce<
        Record<string, Row>
      >((acc, row) => {
        acc[row.id] = row;
        return acc;
      }, {});

      return [
        rows,
        flatRows,
        rowsById,
        onlyGroupedFlatRows,
        onlyGroupedRowsById,
        nonGroupedFlatRows,
        nonGroupedRowsById,
      ];
    }

    // Ensure that the list of filtered columns exist
    const existingGroupBy = groupBy.filter((g) =>
      allColumns.find((col) => col.id === g)
    );

    const groupedFlatRows = [];
    const groupedRowsById = {};
    const onlyGroupedFlatRows = [];
    const onlyGroupedRowsById = {};
    const nonGroupedFlatRows = [];
    const nonGroupedRowsById = {};

    // Recursively group the data
    const groupUpRecursively = (rows: Row[], depth = 0, parentId?) => {
      // This is the last level, just return the rows
      if (depth === existingGroupBy.length) {
        // don't mutate rows. it's dangerous. can lead to inconsistent ui
        return rows.map((row) => ({ ...row }));
      }

      const columnId = existingGroupBy[depth];

      // Group the rows together for this level
      const rowGroupsMap = groupByFn(rows, columnId);

      // Peform aggregations for each group
      const aggregatedGroupedRows = Object.entries(rowGroupsMap).map(
        ([groupByVal, groupedRows]: [string, Row[]], index) => {
          let id = `${columnId}:${groupByVal}`;
          id = parentId ? `${parentId}>${id}` : id;

          // First, Recurse to group sub rows before aggregation
          const subRows = groupUpRecursively(groupedRows, depth + 1, id);

          // Flatten the leaf rows of the rows in this group
          const leafRows = depth
            ? flattenBy(groupedRows, 'leafRows')
            : groupedRows;

          // TODO: aggregations will be returned by the new grouping API later
          const values = {};

          const row = {
            id,
            isGrouped: true,
            groupByID: columnId,
            groupByVal,
            values,
            subRows,
            leafRows,
            depth,
            index,
          };

          subRows.forEach((subRow) => {
            subRow.depth = depth + 1;
            groupedFlatRows.push(subRow);
            groupedRowsById[subRow.id] = subRow;
            if (subRow.isGrouped) {
              onlyGroupedFlatRows.push(subRow);
              onlyGroupedRowsById[subRow.id] = subRow;
            } else {
              nonGroupedFlatRows.push(subRow);
              nonGroupedRowsById[subRow.id] = subRow;
            }
          });

          return row;
        }
      );

      return aggregatedGroupedRows;
    };

    const groupedRows = groupUpRecursively(rows);

    groupedRows.forEach((subRow) => {
      groupedFlatRows.push(subRow);
      groupedRowsById[subRow.id] = subRow;
      if (subRow.isGrouped) {
        onlyGroupedFlatRows.push(subRow);
        onlyGroupedRowsById[subRow.id] = subRow;
      } else {
        nonGroupedFlatRows.push(subRow);
        nonGroupedRowsById[subRow.id] = subRow;
      }
    });

    // Assign the new data
    return [
      groupedRows,
      groupedFlatRows,
      groupedRowsById,
      onlyGroupedFlatRows,
      onlyGroupedRowsById,
      nonGroupedFlatRows,
      nonGroupedRowsById,
    ];
  }, [groupBy, rows, allColumns, groupByFn, flatRows, manualGroupBy, rowsById]);

  const getAutoResetGroupBy = useGetLatest(autoResetGroupBy);

  useMountedLayoutEffect(() => {
    if (getAutoResetGroupBy()) {
      dispatch({ type: actions.resetGroupBy });
    }
  }, [dispatch, manualGroupBy ? null : data]);

  Object.assign(instance, {
    preGroupedRows: rows,
    preGroupedFlatRow: flatRows,
    preGroupedRowsById: rowsById,
    groupedRows,
    groupedFlatRows,
    groupedRowsById,
    onlyGroupedFlatRows,
    onlyGroupedRowsById,
    nonGroupedFlatRows,
    nonGroupedRowsById,
    rows: groupedRows,
    flatRows: groupedFlatRows,
    rowsById: groupedRowsById,
    toggleGroupBy,
    setGroupBy,
  });
}

function prepareRow(row) {
  row.allCells.forEach((cell) => {
    // Grouped cells are in the groupBy and the pivot cell for the row
    cell.isGrouped = cell.column.isGrouped && cell.column.id === row.groupByID;
    // Placeholder cells are any columns in the groupBy that are not grouped
    cell.isPlaceholder = !cell.isGrouped && cell.column.isGrouped;
    // Aggregated cells are not grouped, not repeated, but still have subRows
    cell.isAggregated =
      !cell.isGrouped && !cell.isPlaceholder && row.subRows?.length;
  });
}

export function defaultGroupByFn(rows, columnId) {
  return rows.reduce((prev, row, i) => {
    // TODO: Might want to implement a key serializer here so
    // irregular column values can still be grouped if needed?
    const resKey = `${row.values[columnId]}`;
    prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : [];
    prev[resKey].push(row);
    return prev;
  }, {});
}
