import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  IWordDocumentTemplatePickerProps,
  WordDocumentTemplatePicker,
} from '@work4all/components/lib/components/entity-picker/word-document-template-picker/WordDocumentTemplatePicker';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { reactRefSetter } from '@work4all/utils';

import { LabeledInput } from '../../containers/mask-overlays/locked-inputs';

import { EntityPickerActions } from './EntityPickerActions';

interface WordDocumentTemplatePickerFieldProps
  extends Omit<IWordDocumentTemplatePickerProps<false>, 'multiple'> {
  error?: string;
}

export const WordDocumentTemplatePickerField = React.forwardRef<
  HTMLDivElement,
  WordDocumentTemplatePickerFieldProps
>(function WordDocumentTemplatePickerField(props, ref) {
  const { error, ...pickerProps } = props;
  const { value, onChange } = pickerProps;

  const { t } = useTranslation();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<EntityPickerPopover>(null);

  return (
    <div ref={reactRefSetter(wrapperRef, ref)}>
      <EntityPickerPopover
        ref={popoverRef}
        anchorEl={wrapperRef}
        picker={
          <WordDocumentTemplatePicker {...pickerProps} multiple={false} />
        }
        footer={
          <EntityPickerActions
            entity={Entities.wordDocumentTemplate}
            multiple={false}
            data={props.data}
            onChange={(value) => {
              onChange(value);
              popoverRef.current?.close();
            }}
          ></EntityPickerActions>
        }
      >
        <LabeledInput
          value={value?.body ?? ''}
          label={t(`COMMON.WORDDOCUMENTTEMPLATE`)}
          error={error}
          onClear={() => {
            onChange(null);
          }}
        />
      </EntityPickerPopover>
    </div>
  );
});
