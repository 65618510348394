import React from 'react';

import { Panel } from '../../../../../components/panel/Panel';

import { ErpText } from './ErpText';
import { useTextTabPanel } from './use-text-tab-panel';

export const TextTabPanel: React.FC = () => {
  const tabPanelProps = useTextTabPanel();
  return (
    <Panel>
      <ErpText type="HEADER" {...tabPanelProps} />
      <ErpText type="CLOSING" {...tabPanelProps} />
    </Panel>
  );
};
