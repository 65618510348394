import { MentionDataset, MentionSelectors } from '../constants';

/**
 * Replaces all mention elements in the html string with plain text.
 *
 * For example, element `<span data-mention="user" data-mention-user="1">First
 * Last</span>` will be replaced with string `"@(First Last)[user:1]"`.
 *
 * Some characters (`'('`, `')'`, `'['`, `']'`) are removed to not interfere
 * with parsing, so not all names can saved without loss. This shouldn't be a
 * problem, since when parsing the mentions to display them later, we will
 * replace the text with real names resolved at parsing time. So all names
 * should be correct and up-to-date. This value will only be used as a fallback
 * if the mention can't be resolved to a real user.
 */
export function inlineMentions(html: string): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  const mentions = doc.querySelectorAll(
    MentionSelectors.MentionUser
  ) as NodeListOf<HTMLElement>;

  mentions.forEach((mention) => {
    const mentionType = mention.dataset[MentionDataset.Mention];

    if (mentionType === 'user') {
      const id = mention.dataset[MentionDataset.MentionUser];
      let name = mention.textContent;

      if (name.startsWith('@')) {
        name = name.slice(1);
      }

      // Remove certain characters from the inlined name to not interfere with
      // parsing.
      name = sanitizeString(name);

      const inlinedText = `@(${name})[${mentionType}:${id}]`;
      const textNode = new Text(inlinedText);
      mention.replaceWith(textNode);
    }
  });

  return doc.body.innerHTML;
}

function sanitizeString(str: string): string {
  // Remove "(", ")", "[", and "]" characters.
  return str.replace(/[\\()[\]]/g, '');
}
