import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputDefaultErpTextAddModify } from './InputDefaultErpTextAddModify.entity';

export class InputCompanyDefaultErpText<T extends EMode = EMode.entity> {
  /** Alias: remove */
  remove?: number[];
  /** Alias: addModify */
  addModify?: InputDefaultErpTextAddModify<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputCompanyDefaultErpText> = {
  remove: {
    alias: 'remove',
  },
  addModify: {
    alias: 'addModify',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputCompanyDefaultErpTextEntityDefinition: EntitiyDefinition<InputCompanyDefaultErpText> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputCompanyDefaultErpText',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
