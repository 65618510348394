import { useCallback } from 'react';

import { useCustomSnackbar } from '@work4all/data/lib/snackbar/PresetSnackbarProvider';

import { settings } from '../settings';

/**
 * This is APP custom behaviour logic to change related parent contexts by settings.
 * @returns Function to spread all needed information over upper components
 */
export const useSettingsListener = () => {
  const { setHideApiErrors } = useCustomSnackbar();

  return useCallback(
    (values) => {
      const { name, scope, defaultValue } = settings.isInDemoMode();
      const value: boolean = values[scope][name] ?? defaultValue;
      setHideApiErrors(value);
    },
    [setHideApiErrors]
  );
};
