import styles from './Caption.module.scss';

import { Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { ITypographyProps } from '..';

interface ICaptionProps extends ITypographyProps {
  color?: 'text01' | 'text03' | 'error';
}

export const Caption: React.FC<ICaptionProps> = (props) => {
  const { color = 'text01' } = props;

  return (
    <Typography
      className={clsx(styles.caption, styles[color], props.className)}
      variant="caption"
    >
      {props.children}
    </Typography>
  );
};
