import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IImageItem } from '@work4all/components/lib/components/image-gallery/ImageGallery';
import { UploadImage } from '@work4all/components/lib/input/upload-image';
import { IUploadImageProps } from '@work4all/components/lib/input/upload-image/types';
import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack/hooks/useHistoryStack';

import { CustomerSupplierImageGallery } from '../../../customer-supplier-image-gallery/CustomerSupplierImageGallery';

type Props = Omit<
  IUploadImageProps,
  'onPictureTaken' | 'onImageChoosen' | 'dataUri'
> & {
  imgItems: IImageItem[];
  allowEnlargeImage: boolean;
};

export const MaskUploadImage: React.FC<Props> = (props) => {
  const [items, setItems] = useState<IImageItem[]>([]);
  const { go } = useHistoryStack();
  const { t } = useTranslation();

  useEffect(() => {
    setItems(props.imgItems);
  }, [props.imgItems]);

  // TODO: send image to server

  const getFileSizeFromDataUri = (dataUri: string) => {
    const base64str = dataUri.substr(22);
    const decoded = atob(base64str);
    return decoded.length;
  };

  const setPicture = (
    dataUri: string,
    size?: number,
    type?: string,
    name?: string
  ) => {
    size = !size ? getFileSizeFromDataUri(dataUri) : size;

    setItems([
      {
        fileSize: size ? size : 0,
        fileName: name ? name : 'Image 1',
        id: 'xxx',
        imgUrl: dataUri,
        imgThumbUrl: dataUri,
      },
    ]);
  };

  return (
    <UploadImage
      onImageChoosen={setPicture}
      onPictureTaken={setPicture}
      onImageClick={() => {
        if (props.allowEnlargeImage)
          go({
            title: t('MASK.DISPLAY_IMAGE'),
            view: <CustomerSupplierImageGallery items={items} />,
          });
      }}
      dataUri={items[0]?.imgThumbUrl}
      {...props}
    />
  );
};
