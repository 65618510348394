import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { GroupFieldSortType } from '../Enums/GroupFieldSortType.enum';
import { SortOrderStyle } from '../Enums/SortOrderStyle.enum';

export class GroupQuerySortInfo<T extends EMode = EMode.entity> {
  /** Alias: sortType */
  sortType?: GroupFieldSortType;
  /** Alias: sortOrder */
  sortOrder?: SortOrderStyle;

  __typename?: string;
}

const fields: FieldDefinitions<GroupQuerySortInfo> = {
  sortType: {
    alias: 'sortType',
  },
  sortOrder: {
    alias: 'sortOrder',
  },
  __typename: {
    alias: '__typename',
  },
};

export const groupQuerySortInfoEntityDefinition: EntitiyDefinition<GroupQuerySortInfo> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'GroupQuerySortInfo',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
