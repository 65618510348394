import { DateTime } from 'luxon';

import { ControllerPlus } from '../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../form-plus/use-form-context-plus';
import { DateTimeInputPicker } from '../../../../locked-inputs';
import { WithCustomFields } from '../with-custom-fields';

import { CustomFieldProps } from './types';

export function CustomDateField(props: CustomFieldProps) {
  const { name, label } = props;

  const { control } = useFormContextPlus<WithCustomFields<object>>();

  return (
    <ControllerPlus
      control={control}
      name={name}
      render={({ field }) => {
        const value = formatValue(field.value);

        return (
          <DateTimeInputPicker
            withTime={false}
            clearable={false}
            dateLabel={label}
            {...field}
            value={value}
            onChange={(e) => {
              const value = parseValue(e.currentTarget.value);
              field.onChange(value);
            }}
          />
        );
      }}
    />
  );
}

function formatValue(value: string | null): string | null {
  const formatted =
    value == null
      ? null
      : DateTime.fromISO(value)
          .setZone('local', {
            keepLocalTime: true,
          })
          .toISO();

  return formatted;
}

function parseValue(value: string): string {
  const parsed = DateTime.fromISO(value)
    .setZone('utc', {
      keepLocalTime: true,
    })
    .toISODate();

  return parsed;
}
