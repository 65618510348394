import styles from './TicketPreview.module.scss';

import { Box, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import type React from 'react';
import {
  MouseEventHandler,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  useDataMutation,
  useInaccessibleFields,
  useUser,
} from '@work4all/data';
import {
  TempFileManagerContext,
  useTempFileManager,
} from '@work4all/data/lib/hooks/data-provider/useTempFileManager';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { InputTicketAttachementsRelation } from '@work4all/models/lib/Classes/InputTicketAttachementsRelation.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';
import { TicketStatus } from '@work4all/models/lib/Enums/TicketStatus.enum';

import {
  DateFormatPreset,
  formatDateString,
} from '@work4all/utils/lib/date-utils/formatDateString';

import { DatePicker } from '../../../input/date-picker';
import { useMobiscrollLanguage } from '../../../utils/use-mobiscroll-language/use-mobiscroll-language';
import { Attachments } from '../../attachments';
import { EntityPickerPopover } from '../../entity-picker/components';
import { ContactPicker } from '../../entity-picker/contact-picker/ContactPicker';
import { CustomerPicker } from '../../entity-picker/customer-picker/CustomerPicker';
import { ProjectPicker } from '../../entity-picker/project-picker/ProjectPicker';
import { TicketStatusPicker } from '../../entity-picker/ticket-status-picker/TicketStatusPicker';
import { TicketKindPicker } from '../../entity-picker/TicketKindPicker';
import { UserPicker } from '../../entity-picker/user-picker/UserPicker';
import { useEntityTemplate } from '../../entity-template-provider/EntityTemplateProvider';
import { TicketStatusIndicator } from '../../ticket-status-indicator/TicketStatusIndicator';
import {
  InfoCard,
  InfoCards,
  IPreviewTitleProps,
  OpenFileIconButton,
  PreviewEditMessage,
  PreviewTextEditor,
  PreviewTitle,
  PreviewWrapper,
} from '../components';
import { InfoCardLabel } from '../components/info-card-label/InfoCardLabel';
import { EntityHistory } from '../EntityHistory';
import { useEntityPreview } from '../hooks/use-entity-preview';
import { includesHtml } from '../utils';

import { TicketPreviewFields } from './TicketPreviewContainer';

export enum TICKETACTIONS {
  addAttachments,
}

export type TicketHandlers = {
  onCloseClicked?: MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onShareClicked?: () => void;
  /**this preview has subactions */
  onActionElClicked?: (
    event: React.MouseEvent<HTMLButtonElement>,
    action: TICKETACTIONS
  ) => void;
  openMaskTab?: (tab: string) => void;
} & Pick<IPreviewTitleProps, 'onEmailClicked' | 'onEmailRef' | 'onPhoneClick'>;

export type ITicketPreviewProps = {
  tickets: Ticket[];
} & TicketHandlers &
  Pick<IPreviewTitleProps, 'phoneIconRef'>;

enum previewFields {
  DATE = 'followUpDate',
  CUSTOMER = 'customer',
  CONTACT = 'contact',
  STATUS = 'status',
  EDITOR = 'editor',
  PREVIEW_TITLE = 'previewTitle',
  TEXT_EDITOR = 'textEditor',
  PROJECT = 'project',
  KIND = 'ticketKind',
}

export function TicketPreview(props: ITicketPreviewProps) {
  const {
    tickets,
    onCloseClicked,
    onEditClicked,
    onShareClicked,
    onEmailClicked,
    onEmailRef,
    onPhoneClick,
    phoneIconRef,
    openMaskTab,
  } = props;
  const { t } = useTranslation();

  const user = useUser();
  const language = useMobiscrollLanguage();

  const { isInaccessible } = useInaccessibleFields();
  const [newComment, setNewComment] = useState('');

  const titleIsAccessible = !isInaccessible(Entities.ticket, 'title');
  const dateIsAccessible = !isInaccessible(Entities.ticket, 'creationDate');
  const followUpDateIsAccessible = !isInaccessible(
    Entities.ticket,
    'followUpDate'
  );
  const businessPartnerIsAccessible = !isInaccessible(
    Entities.ticket,
    'businessPartner'
  );
  const contactIsAccessible = !isInaccessible(Entities.ticket, 'contact');
  const status1IsAccessible = !isInaccessible(Entities.ticket, 'status1');
  const editor1IsAccessible = !isInaccessible(Entities.ticket, 'editor1');
  const projectIsAccessible = !isInaccessible(Entities.ticket, 'project');
  const kindIsAccessible = !isInaccessible(Entities.ticket, 'ticketKind');

  const dateRef = useRef<EntityPickerPopover>(null);
  const customerRef = useRef<EntityPickerPopover>(null);
  const contactRef = useRef<EntityPickerPopover>(null);
  const statusRef = useRef<EntityPickerPopover>(null);
  const editorRef = useRef<EntityPickerPopover>(null);
  const textEditorRef = useRef<EntityPickerPopover>(null);
  const projectRef = useRef<EntityPickerPopover>(null);
  const kindRef = useRef<EntityPickerPopover>(null);
  const titleRef = useRef<PreviewTitle>(null);

  const openPicker = useCallback((picker: previewFields) => {
    switch (picker) {
      case previewFields.DATE: {
        dateRef.current.open();
        break;
      }

      case previewFields.CUSTOMER: {
        customerRef.current.open();
        break;
      }

      case previewFields.CONTACT: {
        contactRef.current.open();
        break;
      }

      case previewFields.STATUS: {
        statusRef.current.open();
        break;
      }

      case previewFields.EDITOR: {
        editorRef.current.open();
        break;
      }

      case previewFields.PREVIEW_TITLE: {
        titleRef.current.open();
        break;
      }

      case previewFields.TEXT_EDITOR: {
        textEditorRef.current.open();
        break;
      }
      case previewFields.PROJECT: {
        projectRef.current.open();
        break;
      }
      case previewFields.KIND: {
        kindRef.current.open();
        break;
      }

      default:
        return;
    }
  }, []);

  const [mutate] = useDataMutation<
    Ticket,
    EMode.upsert,
    {
      attachements: InputTicketAttachementsRelation;
    }
  >({
    entity: Entities.ticket,
    mutationType: EMode.upsert,
    responseData: TicketPreviewFields as unknown as Ticket,
  });

  const {
    loading,
    isMultiselect,
    commonFields,
    activePicker,
    optimisticTitle,
    handleLock,
    onPopoverClose,
    setOptimisticTitle,
    onEdit,
    canEditAllSelected,
  } = useEntityPreview({
    user,
    subEntityType: Entities.ticket,
    entries: tickets,
    openPicker,
    mutate,
  });

  const dateIsCommon = commonFields.includes('followUpDate');
  const customerIsCommon = commonFields.includes('businessPartner');
  const contactIsCommon = commonFields.includes('contact');
  const statusIsCommon = commonFields.includes('status1');
  const editorIsCommon = commonFields.includes('editor1');
  const projectIsCommon = commonFields.includes('project');
  const kindIsCommon = commonFields.includes('ticketKind');
  const problemDescriptionIsAccessible = !isInaccessible(
    Entities.ticket,
    'problemDescription'
  );

  const ticket = {
    ...tickets[0],
    problemDescription: !includesHtml(tickets[0].problemDescription)
      ? tickets[0].problemDescription.replace(/\r\n/g, '<br>')
      : tickets[0].problemDescription,
  };

  const [problemDescription, setProblemDescription] = useState(
    ticket?.problemDescription
  );

  const cleanedPersistantAttachmentList = useMemo(() => {
    return ticket?.attachmentList?.map((x) => ({
      ...x,
      fileName: x.fileName,
      __typename: undefined,
    }));
  }, [ticket?.attachmentList]);

  const tempFileManager = useTempFileManager(cleanedPersistantAttachmentList, {
    maxAttachmentTotalSize: 50 * 1024 * 1024,
  });

  const entityTemplate = useEntityTemplate();

  return (
    <TempFileManagerContext.Provider value={tempFileManager}>
      <PreviewWrapper>
        <PreviewTitle
          leftSubLabel={`${ticket.number}`}
          disabled={true}
          showEdit={!isMultiselect && canEditAllSelected}
          ref={titleRef}
          label={ticket?.title}
          onClick={() =>
            !isMultiselect &&
            titleIsAccessible &&
            handleLock(previewFields.PREVIEW_TITLE)
          }
          onClose={(e) => {
            if (e.target.value !== ticket?.title) {
              setOptimisticTitle(e.target.value);
              void onEdit({ title: e.target.value });
            }
            onPopoverClose();
          }}
          onCloseClicked={onCloseClicked}
          onEditClicked={onEditClicked}
          onShareClicked={onShareClicked}
          onEmailClicked={onEmailClicked}
          onEmailRef={onEmailRef}
          onPhoneClick={onPhoneClick}
          phoneIconRef={phoneIconRef}
          actions={
            loading && activePicker === previewFields.PREVIEW_TITLE ? (
              <CircularProgress size="1rem" color="secondary" />
            ) : null
          }
        >
          {isMultiselect
            ? `${t('COMMON.SELECTION')}: ${tickets.length} ${t(
                'COMMON.ELEMENTS'
              )}`
            : titleIsAccessible
            ? optimisticTitle ?? ticket?.title
            : ''}
        </PreviewTitle>
        {isMultiselect && <PreviewEditMessage entityId={ticket.id} />}
        <Stack direction={'column'}>
          <InfoCards>
            {dateIsAccessible && (
              <InfoCard
                disabled={true}
                truncate
                label={t('FIELDS.creationDate')}
              >
                {isMultiselect
                  ? `(${t('COMMON.MULTIPLE')})`
                  : formatDateString(
                      ticket?.creationDate,
                      DateFormatPreset.DATE_SIMPLE_2YEAR
                    )}
              </InfoCard>
            )}

            {followUpDateIsAccessible && (
              <EntityPickerPopover
                ref={dateRef}
                disabled={true}
                onTargetClick={() => handleLock(previewFields.DATE)}
                onClose={onPopoverClose}
                autoclose={true}
                picker={
                  <DatePicker
                    locale={language}
                    controls={['calendar']}
                    defaultValue={formatDateString(
                      ticket?.followUpDate,
                      DateFormatPreset.DATE_SIMPLE_2YEAR
                    )}
                    onChange={(val) => {
                      onEdit({
                        followUpDate: new Date(val.value).toISOString(),
                      });
                    }}
                  />
                }
              >
                <InfoCard
                  label={t('ticket.followUpDate')}
                  decorator={
                    loading && activePicker === previewFields.DATE ? (
                      <CircularProgress size="1rem" color="secondary" />
                    ) : null
                  }
                  decoratorPos="right"
                >
                  {dateIsCommon
                    ? formatDateString(
                        ticket?.followUpDate,
                        DateFormatPreset.DATE_SIMPLE_2YEAR
                      )
                    : `(${t('COMMON.MULTIPLE')})`}
                </InfoCard>
              </EntityPickerPopover>
            )}

            {businessPartnerIsAccessible && (
              <EntityPickerPopover
                ref={customerRef}
                disabled={true}
                onTargetClick={() => handleLock(previewFields.CUSTOMER)}
                onClose={onPopoverClose}
                footer={entityTemplate.renderPickerFooter({
                  entity: Entities.customer,
                  popoverRef: customerRef,
                  data: ticket.businessPartner?.data as Customer,
                  onChange: (value) => {
                    onEdit({
                      businessPartner:
                        value && !Array.isArray(value)
                          ? {
                              data: value,
                              businessPartnerType: SdObjType.KUNDE,
                              id: value?.id,
                            }
                          : null,
                      businessPartnerId:
                        value && !Array.isArray(value) ? value?.id : 0,
                      businessPartnerType: SdObjType.KUNDE,
                    });
                  },
                })}
                picker={
                  <CustomerPicker
                    onChange={(value) =>
                      onEdit({
                        businessPartner: value
                          ? {
                              data: value,
                              businessPartnerType: SdObjType.KUNDE,
                              id: value?.id,
                            }
                          : null,
                        businessPartnerId: value ? value?.id : 0,
                        businessPartnerType: SdObjType.KUNDE,
                      })
                    }
                    value={ticket.businessPartner?.data as Customer}
                    multiple={false}
                  />
                }
              >
                <InfoCard
                  truncate
                  label={t('COMMON.BUSINESS_PARTNER', {
                    context: ticket?.businessPartner?.businessPartnerType,
                  })}
                  decorator={
                    loading && activePicker === previewFields.CUSTOMER ? (
                      <CircularProgress size="1rem" color="secondary" />
                    ) : null
                  }
                  decoratorPos="right"
                  beforeContentElement={
                    <OpenFileIconButton data={ticket?.businessPartner?.data} />
                  }
                >
                  {customerIsCommon
                    ? ticket?.businessPartner?.data?.name
                    : `(${t('COMMON.MULTIPLE')})`}
                </InfoCard>
              </EntityPickerPopover>
            )}

            {contactIsAccessible && (
              <EntityPickerPopover
                ref={contactRef}
                disabled={true}
                onTargetClick={() =>
                  customerIsCommon &&
                  ticket?.businessPartner?.data &&
                  handleLock(previewFields.CONTACT)
                }
                onClose={onPopoverClose}
                picker={
                  <ContactPicker
                    prefilter={[
                      {
                        businessPartnerType: { $eq: SdObjType.KUNDE },
                      },
                      {
                        businessPartnerId: {
                          $eq: ticket?.businessPartner?.data.id,
                        },
                      },
                    ]}
                    value={ticket.contact}
                    multiple={false}
                    onChange={(value) =>
                      onEdit({
                        contact: value,
                        contactId: value ? value.id : 0,
                      })
                    }
                  />
                }
              >
                <InfoCard
                  disabled={!customerIsCommon || !ticket?.businessPartner?.data}
                  tooltip={
                    !customerIsCommon || !ticket?.businessPartner?.data
                      ? 'Wählen Sie zunächst ein einheitliches Projekt'
                      : undefined
                  }
                  label={t('COMMON.CONTACT')}
                  decorator={
                    loading && activePicker === previewFields.CONTACT ? (
                      <CircularProgress size="1rem" color="secondary" />
                    ) : null
                  }
                  decoratorPos="right"
                >
                  {contactIsCommon
                    ? ticket?.contact?.displayName || '-'
                    : `(${t('COMMON.MULTIPLE')})`}
                </InfoCard>
              </EntityPickerPopover>
            )}

            {status1IsAccessible && (
              <EntityPickerPopover
                ref={statusRef}
                disabled={true}
                onTargetClick={() => handleLock(previewFields.STATUS)}
                onClose={onPopoverClose}
                picker={
                  <TicketStatusPicker
                    multiple={false}
                    value={
                      statusIsCommon
                        ? { id: ticket.status1 as string, name: '' }
                        : null
                    }
                    onChange={(value) => onEdit({ status1: value?.id })}
                    availableStatus={[
                      TicketStatus.OFFEN,
                      TicketStatus.ERLEDIGT,
                      TicketStatus.IN_BEARBEITUNG,
                      ticket.status1 as TicketStatus,
                    ]}
                  />
                }
              >
                <InfoCard
                  truncate
                  label={t('COMMON.STATUS', {
                    context: ticket?.status1,
                  })}
                  decorator={
                    loading && activePicker === previewFields.STATUS ? (
                      <CircularProgress size="1rem" color="secondary" />
                    ) : null
                  }
                  decoratorPos="right"
                >
                  <span className={styles['ticket-status-wrapper']}>
                    <TicketStatusIndicator
                      taskStatus={ticket?.status1 as TicketStatus}
                    />
                    {statusIsCommon
                      ? t('TICKET_STATUS.' + ticket?.status1)
                      : `(${t('COMMON.MULTIPLE')})`}
                  </span>
                </InfoCard>
              </EntityPickerPopover>
            )}

            {editor1IsAccessible && (
              <EntityPickerPopover
                ref={editorRef}
                disabled={true}
                onTargetClick={() => handleLock(previewFields.EDITOR)}
                onClose={onPopoverClose}
                picker={
                  <UserPicker
                    multiple={false}
                    value={editorIsCommon ? ticket.editor1 : null}
                    onChange={(value) =>
                      onEdit({
                        editor1: value,
                        editor1Id: value ? value.id : 0,
                      })
                    }
                  />
                }
              >
                <InfoCard
                  label={t('COMMON.EDITOR')}
                  decorator={
                    loading && activePicker === previewFields.EDITOR ? (
                      <CircularProgress size="1rem" color="secondary" />
                    ) : null
                  }
                  decoratorPos="right"
                >
                  {editorIsCommon
                    ? ticket?.editor1
                      ? [
                          ticket?.editor1?.firstName,
                          ticket?.editor1?.lastName,
                        ].join(' ')
                      : null
                    : `(${t('COMMON.MULTIPLE')})`}
                </InfoCard>
              </EntityPickerPopover>
            )}

            {projectIsAccessible && (
              <EntityPickerPopover
                ref={projectRef}
                disabled={true}
                onTargetClick={() => handleLock(previewFields.PROJECT)}
                onClose={onPopoverClose}
                footer={entityTemplate.renderPickerFooter({
                  entity: Entities.project,
                  popoverRef: projectRef,
                  data: ticket.project,
                  onChange: (value) => {
                    onEdit({
                      project: value,
                      projectId: value && !Array.isArray(value) ? value.id : 0,
                    });
                  },
                })}
                picker={
                  <ProjectPicker
                    multiple={false}
                    value={projectIsCommon ? ticket.project : null}
                    onChange={(value) =>
                      onEdit({
                        project: value,
                        projectId: value ? value.id : 0,
                      })
                    }
                  />
                }
              >
                <InfoCard
                  label={t('COMMON.PROJECT')}
                  decorator={
                    loading && activePicker === previewFields.PROJECT ? (
                      <CircularProgress size="1rem" color="secondary" />
                    ) : null
                  }
                  decoratorPos="right"
                  beforeContentElement={
                    <OpenFileIconButton data={ticket?.project} />
                  }
                >
                  {projectIsCommon
                    ? ticket?.project
                      ? ticket?.project?.name
                      : null
                    : `(${t('COMMON.MULTIPLE')})`}
                </InfoCard>
              </EntityPickerPopover>
            )}

            {kindIsAccessible && (
              <EntityPickerPopover
                ref={kindRef}
                disabled={true}
                onTargetClick={() => handleLock(previewFields.KIND)}
                onClose={onPopoverClose}
                picker={
                  <TicketKindPicker
                    multiple={false}
                    value={kindIsCommon ? ticket.ticketKind : null}
                    onChange={(value) =>
                      onEdit({
                        ticketKindId: value ? value.id : 0,
                      })
                    }
                  />
                }
              >
                <InfoCard
                  label={t('COMMON.KIND')}
                  decorator={
                    loading && activePicker === previewFields.KIND ? (
                      <CircularProgress size="1rem" color="secondary" />
                    ) : null
                  }
                  decoratorPos="right"
                >
                  {projectIsCommon
                    ? ticket?.ticketKind
                      ? ticket?.ticketKind?.name
                      : null
                    : `(${t('COMMON.MULTIPLE')})`}
                </InfoCard>
              </EntityPickerPopover>
            )}
          </InfoCards>
          {!isMultiselect ? (
            <>
              <Divider orientation="horizontal" />
              {problemDescriptionIsAccessible && (
                <Box>
                  <Stack
                    direction={'column'}
                    justifyContent="space-between"
                    width={'100%'}
                    alignItems=""
                    sx={{ padding: '1rem 1rem 0 1rem', gap: '0.5rem' }}
                  >
                    <InfoCardLabel>
                      {t('FIELDS.problemDescription')}
                    </InfoCardLabel>
                  </Stack>
                  <PreviewTextEditor
                    ref={textEditorRef}
                    value={problemDescription}
                    onChange={(value) => setProblemDescription(value)}
                    onClick={() => handleLock(previewFields.TEXT_EDITOR)}
                    onClose={() => {
                      if (problemDescription !== ticket?.problemDescription) {
                        onEdit({ problemDescription });
                      }
                      onPopoverClose();
                    }}
                    decorator={
                      loading && activePicker === previewFields.TEXT_EDITOR ? (
                        <CircularProgress size="1rem" color="secondary" />
                      ) : null
                    }
                    previewMode="rich"
                  />
                  {ticket.attachmentList.length ? (
                    <Stack
                      direction={'column'}
                      justifyContent="space-between"
                      width={'100%'}
                      alignItems=""
                      sx={{ padding: '0 1rem 1rem 1rem ' }}
                    >
                      <Attachments
                        disableAddAction={true}
                        disableRemoveAction={true}
                        layout="compact"
                        disableEditAction={true}
                        truncate={{
                          after: 3,
                          onMoreClick: () => {
                            openMaskTab('attachments');
                          },
                        }}
                        newestAttachmentsFirst
                      />
                    </Stack>
                  ) : null}
                </Box>
              )}

              <Divider orientation="horizontal" />

              <EntityHistory
                fitContainer={false}
                entity={Entities.ticket}
                fullWidth
                id={ticket.id}
                className={styles.hist}
                breadcrumbTitle={`Ticket ${ticket.number} | ${ticket.title}`}
                newComment={newComment}
                onNewCommentChange={setNewComment}
              />
            </>
          ) : null}
        </Stack>
      </PreviewWrapper>
    </TempFileManagerContext.Provider>
  );
}
