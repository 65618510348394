import { MenuItemProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@work4all/components/lib/components/tooltip/Tooltip';

import { MenuItem } from '../../locked-inputs';
import { useEntityRightsContext } from '../hooks/use-entity-rights';

export const MaskOverlayDeleteMenuItem: React.FC<MenuItemProps> = (props) => {
  const { t } = useTranslation();
  const rights = useEntityRightsContext();

  return (
    <Tooltip
      title={!rights.delete ? t('RIGHTS.MISSING') : undefined}
      activateForDisabled={true}
    >
      <MenuItem {...props}>{t('MASK.REMOVE')}</MenuItem>
    </Tooltip>
  );
};
