import { Typography } from '@mui/material';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../tooltip/Tooltip';

import { ListEntityPicker } from './components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from './types';

export type ICostGroupPickerProps<TMultiple extends boolean> = IPickerProps<
  LookUp,
  TMultiple
>;

export function CostGroupPicker<TMultiple extends boolean>(
  props: ICostGroupPickerProps<TMultiple>
) {
  const { data, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...COST_GROUP_DATA, ...data };
  }, [data]);

  const request = useMemo<DataRequest>(() => {
    return {
      data: allFields,
      entity: Entities.lookUp,
      completeDataResponse: true,
      operationName: 'GetCostGroups',
      vars: {
        type: 'KOSTENGRUPPEN',
      },
    };
  }, [allFields]);

  const response = useDataProvider<LookUp>(request);

  const dataset = useMemo(() => {
    return {
      loading: response.loading,
      data: response.data,
      total: response.data.length,
      fetchMore: () => Promise.resolve(),
      refetch: () => Promise.resolve(null),
    };
  }, [response]);

  const renderItem = (item: LookUp) => {
    const label = item.name;

    return (
      <Tooltip title={label}>
        <Typography variant="body2" noWrap>
          {label}
        </Typography>
      </Tooltip>
    );
  };

  return (
    <ListEntityPicker
      entity={Entities.lookUp}
      data={allFields}
      filterBy="name"
      layout="simple"
      sortBy="name"
      fixedDataSet={dataset}
      renderItemContent={renderItem}
      {...rest}
    />
  );
}

const COST_GROUP_DATA: LookUp = {
  id: null,
  name: null,
};
