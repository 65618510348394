import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputRa<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: any;
  /** Alias: datum */
  datum?: any;
  /** Alias: rechnungNummer */
  rechnungNummer?: any;
  /** Alias: sdObjMemberCode */
  sdObjMemberCode?: any;
  /** Alias: faelligDatum */
  faelligDatum?: any;
  /** Alias: betrag */
  betrag?: any;
  /** Alias: mwstBetrag */
  mwstBetrag?: any;
  /** Alias: rMwstNeu */
  rMwstNeu?: any;
  /** Alias: bezehaltBetrag */
  bezehaltBetrag?: any;
  /** Alias: stornSumme */
  stornSumme?: any;
  /** Alias: mahnstufe */
  mahnstufe?: any;
  /** Alias: notiz */
  notiz?: any;
  /** Alias: skonto */
  skonto?: any;
  /** Alias: kostenstelle */
  kostenstelle?: any;
  /** Alias: frist */
  frist?: any;
  /** Alias: listFlag */
  listFlag?: any;
  /** Alias: dtaNummer */
  dtaNummer?: any;
  /** Alias: rechnungCode */
  rechnungCode?: any;
  /** Alias: benutzerCode */
  benutzerCode?: any;
  /** Alias: raSachkGesamt */
  raSachkGesamt?: any;
  /** Alias: raKostenstGesamt */
  raKostenstGesamt?: any;
  /** Alias: datev */
  datev?: any;
  /** Alias: kurs */
  kurs?: any;
  /** Alias: waehrungCode */
  waehrungCode?: any;
  /** Alias: waehrungDatum */
  waehrungDatum?: any;
  /** Alias: provision */
  provision?: any;
  /** Alias: buchungsDatum */
  buchungsDatum?: any;
  /** Alias: summe */
  summe?: any;
  /** Alias: lastschriftOk */
  lastschriftOk?: any;
  /** Alias: zahlungCode */
  zahlungCode?: any;
  /** Alias: bankkontoCode */
  bankkontoCode?: any;
  /** Alias: buchhaltungssperre */
  buchhaltungssperre?: any;
  /** Alias: dmsId */
  dmsId?: any;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputRa> = {
  code: {
    alias: 'code',
  },
  datum: {
    alias: 'datum',
  },
  rechnungNummer: {
    alias: 'rechnungNummer',
  },
  sdObjMemberCode: {
    alias: 'sdObjMemberCode',
  },
  faelligDatum: {
    alias: 'faelligDatum',
  },
  betrag: {
    alias: 'betrag',
  },
  mwstBetrag: {
    alias: 'mwstBetrag',
  },
  rMwstNeu: {
    alias: 'rMwstNeu',
  },
  bezehaltBetrag: {
    alias: 'bezehaltBetrag',
  },
  stornSumme: {
    alias: 'stornSumme',
  },
  mahnstufe: {
    alias: 'mahnstufe',
  },
  notiz: {
    alias: 'notiz',
  },
  skonto: {
    alias: 'skonto',
  },
  kostenstelle: {
    alias: 'kostenstelle',
  },
  frist: {
    alias: 'frist',
  },
  listFlag: {
    alias: 'listFlag',
  },
  dtaNummer: {
    alias: 'dtaNummer',
  },
  rechnungCode: {
    alias: 'rechnungCode',
  },
  benutzerCode: {
    alias: 'benutzerCode',
  },
  raSachkGesamt: {
    alias: 'raSachkGesamt',
  },
  raKostenstGesamt: {
    alias: 'raKostenstGesamt',
  },
  datev: {
    alias: 'datev',
  },
  kurs: {
    alias: 'kurs',
  },
  waehrungCode: {
    alias: 'waehrungCode',
  },
  waehrungDatum: {
    alias: 'waehrungDatum',
  },
  provision: {
    alias: 'provision',
  },
  buchungsDatum: {
    alias: 'buchungsDatum',
  },
  summe: {
    alias: 'summe',
  },
  lastschriftOk: {
    alias: 'lastschriftOk',
  },
  zahlungCode: {
    alias: 'zahlungCode',
  },
  bankkontoCode: {
    alias: 'bankkontoCode',
  },
  buchhaltungssperre: {
    alias: 'buchhaltungssperre',
  },
  dmsId: {
    alias: 'dmsId',
  },
  customFields: {
    alias: 'customFields',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputRaEntityDefinition: EntitiyDefinition<InputRa> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'InputRa',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
