import { useMemo } from 'react';
import { matchPath } from 'react-router-dom';

import { useLocation } from '@work4all/data';

import { IStackItem, useHistoryStack } from '../../history-stack';
import { IRouteConfig } from '../RouterNavigationOverlay';

const isRouteItem = (item: IStackItem | IRouteConfig): item is IRouteConfig => {
  return Boolean(item && 'route' in item);
};

export const useOverlayRouteMatch = () => {
  const { viewStackItem: current } = useHistoryStack();
  const location = useLocation();
  const route = isRouteItem(current) ? current.route : null;

  return useMemo(() => {
    return route ? matchPath(route, location.pathname) : null;
  }, [location.pathname, route]);
};
