import styles from './FileIcon.module.scss';

import clsx from 'clsx';
import { FC } from 'react';

export enum TEXTFILETYPES {
  DOC = 'DOC',
  DOCX = 'DOCX',
  TXT = 'TXT',
}
export enum SPREADSHEETFILES {
  XLS = 'XLS',
  XLSX = 'XLSX',
  CSV = 'CSV',
}
export enum PDFFILETYPE {
  PDF = 'PDF',
}
export enum VIDEOFILETYPES {
  MP4 = 'MP4',
  WMV = 'WMV',
}
export enum IMAGEFILETYPES {
  JPG = 'JPG',
  JPEG = 'JPEG',
  PNG = 'PNG',
  GIF = 'GIF',
  TIFF = 'TIFF',
  BMP = 'BMP',
}

export type IFileIconProps = {
  fileType:
    | TEXTFILETYPES
    | SPREADSHEETFILES
    | PDFFILETYPE
    | VIDEOFILETYPES
    | IMAGEFILETYPES
    | string;
};

export const FileIcon: FC<IFileIconProps> = (props) => {
  const { fileType } = props;
  return (
    <div
      className={clsx(styles.iconWrapper, {
        [styles.blue]: TEXTFILETYPES[fileType?.toUpperCase()],
        [styles.green]: SPREADSHEETFILES[fileType?.toUpperCase()],
        [styles.red]: PDFFILETYPE[fileType?.toUpperCase()],
        [styles.yellow]: VIDEOFILETYPES[fileType?.toUpperCase()],
        [styles.purple]: IMAGEFILETYPES[fileType?.toUpperCase()],
      })}
    >
      <div className={styles.sheet}>
        <div className={styles.ribbon}>{fileType}</div>
      </div>
    </div>
  );
};
