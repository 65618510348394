import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { BaseDataImageType } from '../Enums/BaseDataImageType.enum';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { FileEntity } from './FileEntity.entity';

export class BaseDataAttachment<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: index */
  index?: number;
  /** Alias: objMemberCode */
  objMemberCode?: number;
  /** Alias: type */
  type?: BaseDataImageType;
  /** Alias: fileInfos */
  fileInfos?: FileEntity<T>;

  __typename?: string;
}

const fields: FieldDefinitions<BaseDataAttachment> = {
  id: {
    alias: 'code',
  },
  index: {
    alias: 'index',
  },
  objMemberCode: {
    alias: 'objMemberCode',
  },
  type: {
    alias: 'type',
  },
  fileInfos: {
    alias: 'fileInfos',
    entity: Entities.fileEntity,
  },
  __typename: {
    alias: '__typename',
  },
};

export const baseDataAttachmentEntityDefinition: EntitiyDefinition<BaseDataAttachment> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'StammdatenAnhang',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
