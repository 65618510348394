import { TicketStatus } from '@work4all/models/lib/Enums/TicketStatus.enum';

import {
  IStatusIndicatorVariant,
  StatusIndicator,
} from '../status-indicator/StatusIndicator';

export type ITicketStatusIndicatorProps = {
  taskStatus: TicketStatus;
};

export function TicketStatusIndicator(props: ITicketStatusIndicatorProps) {
  const { taskStatus: ticketStatus } = props;

  const variant = mapStatusToVariant(ticketStatus);

  return <StatusIndicator variant={variant} />;
}

function mapStatusToVariant(taskStatus: TicketStatus): IStatusIndicatorVariant {
  switch (taskStatus) {
    case TicketStatus.ZURUECKGESTELLT:
    case TicketStatus.IN_BEARBEITUNG:
      return 'alert';
    case TicketStatus.OFFEN:
      return 'default';
    case TicketStatus.ERLEDIGT:
      return 'success';
  }
}
