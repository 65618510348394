import { useTranslation } from 'react-i18next';

import { FixedDataPicker } from '../fixed-data-picker/FixedDataPicker';
import { IPickerProps } from '../types';

export type SalesOpportunitiesKindPickerProps<TMultiple extends boolean> =
  IPickerProps<{ id: number; name: string }, TMultiple>;

export const SalesOpportunitiesKindLookUp = [
  'COMMON.PLANNING_ONGOING',
  'COMMON.PLANNING_ANNUAL',
];

export function SalesOpportunitiesKindPicker<TMultiple extends boolean>(
  props: SalesOpportunitiesKindPickerProps<TMultiple>
) {
  const { onChange, ...rest } = props;

  const { t } = useTranslation();

  const dataSet = SalesOpportunitiesKindLookUp.map((x, idx) => ({
    id: idx,
    name: t(x),
  }));

  return (
    <FixedDataPicker
      dataSet={dataSet}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(value: any) => {
        onChange(value?.id || 0);
      }}
      {...rest}
    />
  );
}
