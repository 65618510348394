import { Theme, useMediaQuery } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';
import { ErpPreviewContainer } from '@work4all/components/lib/components/entity-preview/erp-preview/ErpPreviewContainer';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { useDataProvider, useUser } from '@work4all/data';

import { File } from '@work4all/models/lib/Classes/File.entity';
import { InboundInvoice } from '@work4all/models/lib/Classes/InboundInvoice.entity';
import { DataRequest, SortDirection } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Table } from '../../../components/data-tables/table/Table';
import { DocumentCopyBanderole } from '../../../containers/mask-overlays/components/banderoles/DocumentCopyBanderole';
import {
  PaymentMask,
  PaymentMaskProps,
} from '../../../containers/mask-overlays/mask-overlay/views/payment';
import { useInboundInvoicePdfEditRights } from '../../../containers/mask-overlays/mask-overlay/views/pdf-editor/use-inbound-invoice-pdf-edit-rights';
import { usePdfEditorOverlayMask } from '../../../containers/mask-overlays/mask-overlay/views/pdf-editor/use-pdf-editor-overlay-mask';
import { PersonalAndGeneralView } from '../../filters/PersonalAndGeneralView';
import { EntityTable } from '../EntityTable';
import { PreviewMobileWrapper } from '../PreviewMobileWrapper';
import schema from '../schemata/inboundInvoice-table-schema.json';
import { TableLayoutContext, useTableLayoutState } from '../table-layout';
import { TableNoRowsPlaceholder } from '../TableNoRowsPlaceholder';
import { useDataTable } from '../use-data-table';
import { useDeleteEntitiesToolbarConfig } from '../use-delete-entities-toolbar-config';
import { useErpTableMaskHandlers } from '../use-erp-table-mask-handlers';
import { useUserColumnConfigs } from '../use-user-column-configs';

import { BottomView } from './bottom-view';

type Props = EntityTable;

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];
const manualGroupBy = true;

export const InboundInvoicesTable = React.forwardRef<TableInstance, Props>(
  function InboundInvoicesTable(props, ref) {
    const { prefilter, onOpenMask } = props;
    const { entity: entityType } = schema as never;

    const tableStateBag = useTableStateBag();

    const layoutState = useTableLayoutState();
    const [layout] = layoutState;

    const {
      columnConfigs,
      cardConfig,
      prepareRowDisplayModifiers,
      data,
      fetchMore,
      total,
      selectedEntity,
      initialSortBy,
      pending,
      selectedEntities,
    } = useDataTable<InboundInvoice, never>({
      layout,
      schema: schema as never,
      tableStateBag,
      prefilter,
      defaultSort,
    });

    const maskHandlers = useErpTableMaskHandlers(
      entityType,
      onOpenMask,
      'supplier.id',
      Entities.supplier
    );

    const deleteConfig = useDeleteEntitiesToolbarConfig({
      entityType,
    });

    const [userConfig, userConfigMethods] = useUserColumnConfigs({
      layout,
      entityType,
      columnConfigs,
    });

    const isDesktop = useMediaQuery<Theme>((theme) =>
      theme.breakpoints.up('sm')
    );

    const [paymentMask, setPaymentMask] = useState<PaymentMaskProps>();
    const [previewedFile, setPreviewedFile] = useState<File>();

    const rowModifiers = useCallback(
      (value: InboundInvoice) => {
        const modifiers = prepareRowDisplayModifiers(value);
        return {
          ...modifiers,
          isFaded: value.paid,
        };
      },
      [prepareRowDisplayModifiers]
    );

    const user = useUser();

    const { t } = useTranslation();

    const previewRequest = useMemo<DataRequest>(() => {
      const data: InboundInvoice<EMode.query> = {
        id: null,
        userId: null,
        datevDate: null,
        belegList: [
          {
            id: null,
            parentId: null,
            fileInfos: {
              fileServiceProviderInfos: {
                id: null,
                fspUrl: null,
              },
            },
          },
        ],
      };

      return {
        entity: Entities.inboundInvoice,
        data,
        completeDataResponse: false,
        filter: [{ id: { $eq: selectedEntity?.id } }],
      };
    }, [selectedEntity?.id]);

    const selectedEntityResult =
      useDataProvider<InboundInvoice>(previewRequest);

    const { data: selectedEntityData, refetch } = selectedEntityResult;

    const pdfEditAllowed = useInboundInvoicePdfEditRights({
      fileName: previewedFile?.fileInfos?.fileEntityFilename,
      inboundInvoice: selectedEntityData?.[0],
      userRights: user.benutzerRechte,
      userId: user.benutzerCode,
    });

    const docId = useMemo(() => {
      const selectedEntity = selectedEntityData?.[0];

      const childDoc = selectedEntity?.belegList.find(
        (x) => x.parentId === previewedFile?.id
      );
      const docId = previewedFile?.parentId
        ? previewedFile?.id
        : childDoc
        ? childDoc.id
        : previewedFile?.id;

      return docId;
    }, [previewedFile?.id, previewedFile?.parentId, selectedEntityData]);

    const { pdfEditOverlay, open: openPdfEditOverlay } =
      usePdfEditorOverlayMask({
        entityId: selectedEntity?.id,
        entityType: Entities.inboundInvoice,
        docId,
        onClose: refetch,
      });

    if (!userConfig) return null;

    return (
      <>
        {paymentMask && (
          <NavigationOverlay
            open={true}
            initialView={{
              view: <PaymentMask {...paymentMask} />,
            }}
            close={() => {
              setPaymentMask(undefined);
            }}
          />
        )}

        {pdfEditOverlay}

        <TableLayoutContext value={layoutState}>
          <Table
            pending={pending}
            noRowsRenderer={() => <TableNoRowsPlaceholder />}
            ref={ref}
            layout={layout}
            cardConfig={cardConfig}
            areas={{
              left: {
                content: (
                  <PersonalAndGeneralView entity={Entities.inboundInvoice} />
                ),
                resizable: true,
                collapseConfig: {
                  title: t('COMMON.FILTER'),
                },
              },
              right: selectedEntity !== null && {
                content: (
                  <PreviewMobileWrapper
                    active={!isDesktop}
                    actions={{
                      edit: maskHandlers.edit,
                      convert: {
                        exclude: entityType,
                        handler: maskHandlers.convert,
                      },
                      remove: deleteConfig,
                    }}
                  >
                    <ErpPreviewContainer
                      entityId={selectedEntity.id}
                      entity={entityType}
                      onCloseClicked={() =>
                        tableStateBag.tableInstance.toggleAllRowsSelected(false)
                      }
                      onEditClicked={maskHandlers.edit?.handler}
                      onShareClicked={maskHandlers.share}
                      onEditPdfClicked={
                        pdfEditAllowed
                          ? () => {
                              openPdfEditOverlay();
                            }
                          : undefined
                      }
                      onPreviewedFileChange={setPreviewedFile}
                      subtitleComp={
                        previewedFile?.parentId ? (
                          <DocumentCopyBanderole />
                        ) : undefined
                      }
                    />
                  </PreviewMobileWrapper>
                ),
              },
              bottom: selectedEntity
                ? {
                    content: (
                      <BottomView selectedEntityId={selectedEntity.id} />
                    ),
                  }
                : undefined,
            }}
            actions={{
              add: maskHandlers.create,
              edit: maskHandlers.edit,
              remove: {
                ...deleteConfig,
                canDeleteEntity: (id) => {
                  const entity = selectedEntities.find(
                    (item) => item.id === Number(id)
                  );
                  const value = entity?.payments?.length > 0 ? false : true;

                  return {
                    value,
                    preventMessage: value
                      ? null
                      : t('ALERTS.INCOMING_INVOICE_PAYMENTS_EXIST', {
                          number: entity?.rNumber,
                        }),
                  };
                },
              },
              resetColumns: userConfigMethods.remove,
              createWidget: true,
              openPaymentMask: selectedEntity
                ? {
                    handler: () =>
                      setPaymentMask({
                        id: selectedEntity.id,
                        entity: Entities.inboundInvoice,
                      }),
                  }
                : null,
            }}
            columnConfigs={userConfig}
            manualGroupBy={manualGroupBy}
            initialSortBy={initialSortBy}
            loadMoreItems={fetchMore}
            prepareRowDisplayModifiers={rowModifiers}
            data={data}
            total={total}
          />
        </TableLayoutContext>
      </>
    );
  }
);
