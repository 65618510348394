import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { MailboxFolderType } from '../Enums/MailboxFolderType.enum';

export class MailboxFolder<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: name */
  name?: string;
  /** Alias: index */
  index?: number;
  /** Alias: unreadItemsCount */
  unreadItemsCount?: number;
  /** Alias: folderType */
  folderType?: MailboxFolderType;
  /** Alias: parentId */
  parentId?: string;
  /** Alias: unassignedItemsCount */
  unassignedItemsCount?: number;

  __typename?: string;
}

const fields: FieldDefinitions<MailboxFolder> = {
  id: {
    alias: 'id',
  },
  name: {
    alias: 'name',
  },
  index: {
    alias: 'index',
  },
  unreadItemsCount: {
    alias: 'unreadItemsCount',
  },
  folderType: {
    alias: 'folderType',
  },
  parentId: {
    alias: 'parentId',
  },
  unassignedItemsCount: {
    alias: 'unassignedItemsCount',
  },
  __typename: {
    alias: '__typename',
  },
};

export const mailboxFolderEntityDefinition: EntitiyDefinition<MailboxFolder> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'MailboxFolder',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
