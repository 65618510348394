import styles from './LabeledTimeInput.module.scss';

import { ButtonBase } from '@mui/material';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import React from 'react';

import { ReactComponent as TimerIcon } from '@work4all/assets/icons/timer.svg';

import { ILabeledInput, withDropdown } from '../labeled-input';
import { SectionLabeledInput } from '../section-labeled-input/SectionLabeledInput';
import { withDateTimeMask } from '../section-labeled-input/withDateTimeMask';
import { ITimePickerProps, TimePicker } from '../time-picker/TimePicker';

const TimeMaskLabeledInput = withDateTimeMask(SectionLabeledInput);

interface ILabeledTimeInputProps
  extends Omit<ILabeledInput, 'iconRight' | 'value'>,
    ITimePickerProps {
  value: string;
}

const Input: React.FC<ILabeledTimeInputProps> = (props) => {
  // exclude props before passing them.
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { timeValue, onTimeSelect: onTimeSelected, ...rest } = props;

  return (
    <TimeMaskLabeledInput
      {...rest}
      className={clsx(styles.hideDefaultIcons, {
        [styles.disabled]: rest.disabled,
      })}
      wrapperClassName={rest.className}
      iconRight={
        <ButtonBase
          disabled={props.disabled}
          onClick={
            props.onClick as unknown as React.MouseEventHandler<HTMLButtonElement>
          }
        >
          <TimerIcon />
        </ButtonBase>
      }
      mask={'00:00'}
      splitter=":"
      modify={(value, section, direction) => {
        const date = DateTime.now();
        const directionValue =
          direction === 'none' ? 0 : direction === 'up' ? 1 : -1;
        if (section === 0) {
          return date
            .set({ hour: parseInt(value, 10) })
            .plus({ hours: directionValue })
            .toFormat('HH');
        } else if (section === 1) {
          return date
            .set({ minute: parseInt(value, 10) })
            .plus({ minutes: directionValue })
            .toFormat('mm');
        }
        return value;
      }}
    />
  );
};

export const LabeledTimeInputWithDropdown = withDropdown(Input, (props) => {
  const {
    value,
    onTimeSelect: onTimeSelected,
    disabled,
    onDestroyPopover,
  } = props;

  if (disabled) {
    return null;
  }

  return (
    <TimePicker
      timeValue={value}
      onTimeSelect={onTimeSelected}
      onDestroyPopover={onDestroyPopover}
    />
  );
});
