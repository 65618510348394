import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { CustomerIndividualPrice } from './CustomerIndividualPrice.entity';
import { PurchasePrice } from './PurchasePrice.entity';
import { SinglePrice } from './SinglePrice.entity';

export class ArticlePrices<T extends EMode = EMode.entity> {
  /** Alias: einkaufspreise */
  purchasePriceList?: PurchasePrice<T>[];
  /** Alias: einzelpreise */
  singlePriceList?: SinglePrice<T>[];
  /** Alias: kundenIndividuellerPreise */
  customerIndividualPriceList?: CustomerIndividualPrice<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<ArticlePrices> = {
  purchasePriceList: {
    alias: 'einkaufspreise',
    entity: Entities.purchasePrice,
  },
  singlePriceList: {
    alias: 'einzelpreise',
    entity: Entities.singlePrice,
  },
  customerIndividualPriceList: {
    alias: 'kundenIndividuellerPreise',
    entity: Entities.customerIndividualPrice,
  },
  __typename: {
    alias: '__typename',
  },
};

export const articlePricesEntityDefinition: EntitiyDefinition<ArticlePrices> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'ArtikelPreise',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
