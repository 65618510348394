import { Alignment, DataType } from '../types';

const rightAlignedDataTypes = [
  DataType.Percentage,
  DataType.Price,
  DataType.Number,
];

export const getAlignment = (
  alignment?: Alignment,
  dataType?: DataType
): Alignment => {
  if (alignment) {
    return alignment;
  }

  if (!dataType) {
    return 'left';
  }

  if (~rightAlignedDataTypes.indexOf(dataType)) {
    return 'right';
  }

  return 'left';
};
