import styles from './ProjectTable.module.scss';

import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';

import { useUser } from '@work4all/data';

import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { ITableSchema } from '@work4all/models/lib/table-schema/table-schema';

import { usePageHeaderTabs } from '../../../containers/file-entities-lists/components/contentWrapper/use-page-header-tabs';
import {
  IProjectFileInfoPanelProps,
  ProjectFileInfoPanel,
} from '../../../containers/files/detail/components/file-info-panel/ProjectFileInfoPanel';
import { useProject } from '../../../containers/files/detail/components/queries/use-project';
import { EntityTable } from '../EntityTable';
import { FileActions, useFileActions } from '../FileActions';
import { GridStateProvider } from '../grid/GridStateProvider';
import { PreviewMobileWrapper } from '../PreviewMobileWrapper';
import jsonSchema from '../schemata/project-table-schema.json';
import { useSyncScroll } from '../table/hooks/use-sync-scroll';
import { Table } from '../table/Table';
import { TableLayoutContext, useTableLayoutState } from '../table-layout';
import { TableNoRowsPlaceholder } from '../TableNoRowsPlaceholder';
import { useDataTable } from '../use-data-table';
import { useDeleteEntitiesToolbarConfig } from '../use-delete-entities-toolbar-config';
import { useTableMaskHandlers } from '../use-table-mask-handlers';
import { useUserColumnConfigs } from '../use-user-column-configs';

import { ProjectBanderoleInfo } from './components/ProjectBanderoleInfo';
import { ProjectRightsGrid } from './components/ProjectRightsGrid';
import { ProjectTableQuickFilters } from './components/ProjectTableQuickFilters';
import { useProjectActions } from './components/use-project-actions';
import { useProjectGrid } from './hooks/use-project-grid';
import { useRightsSchema } from './hooks/use-rights.schema';

type Props = EntityTable;
type IProjectTableCustomColumns = Record<string, never>;

const defaultSort = [{ field: 'name', direction: SortDirection.ASCENDING }];
const manualGroupBy = true;
const noRowsRenderer = () => <TableNoRowsPlaceholder />;

export const ProjectTable = React.forwardRef<TableInstance, Props>(
  function ProjectTable(props, ref) {
    return (
      <GridStateProvider>
        <ProjectTableInner {...props} ref={ref} />
      </GridStateProvider>
    );
  }
);

export const ProjectTableInner = React.forwardRef<TableInstance, Props>(
  function ProjectTableInner(props, ref) {
    const user = useUser();

    const { prefilter, onOpenMask, ...rest } = props;
    const schema = jsonSchema as unknown as ITableSchema<never>;
    const { entity: entityType } = schema;

    const { t } = useTranslation();
    const tabs = useMemo(() => {
      return [
        {
          key: 'LIST',
          label: t('PROJECT_TIME.DISPLAY_MODE.LIST'),
        },
        {
          key: 'RIGHT',
          label: t('MORE.RIGHTS'),
        },
      ];
    }, [t]);
    const { activeTab } = usePageHeaderTabs({
      tabs,
      hasPermission: user.isMaster,
    });

    const matrixVisible = activeTab === 'RIGHT';
    const matrixSchema = useRightsSchema(schema, matrixVisible);

    const tableStateBag = useTableStateBag();

    const layoutState = useTableLayoutState();
    const [layout] = layoutState;

    const forceRequestFields: Project = useMemo<Project>(() => {
      if (!user.isMaster) return props.forceRequestFields;
      return {
        ...props.forceRequestFields,
        projectAccessGroups: [
          {
            id: null,
          },
        ],
      };
    }, [props.forceRequestFields, user.isMaster]);

    const {
      pending,
      columnConfigs,
      cardConfig,
      prepareRowDisplayModifiers,
      data,
      fetchMore,
      total,
      selectedEntity,
      initialSortBy,
      refetch,
    } = useDataTable<Project, IProjectTableCustomColumns>({
      layout,
      schema: matrixSchema,
      tableStateBag,
      prefilter,
      defaultSort,
      forceRequestFields,
    });

    const maskHandlers = useTableMaskHandlers(entityType, onOpenMask);

    const deleteConfig = useDeleteEntitiesToolbarConfig({
      entityType,
    });

    const [userConfig, userConfigMethods] = useUserColumnConfigs({
      layout,
      entityType,
      columnConfigs,
    });

    const rowModifiers = useCallback(
      (value) => {
        const modifiers = prepareRowDisplayModifiers(value);
        return {
          ...modifiers,
          isFaded: value.projectStatus?.closedStatus === -1,
        };
      },
      [prepareRowDisplayModifiers]
    );

    const listScrollRef = useRef<HTMLDivElement>();
    const gridScrollRef = useRef<HTMLDivElement>();
    useSyncScroll('vertical', listScrollRef, gridScrollRef);

    const matrix = useProjectGrid({ projects: data as Project[], refetch });
    const projectActions = useProjectActions(matrix);
    const { fileActionDefinitions } = useFileActions({
      entity: entityType,
    });

    const classes = useMemo(() => {
      return {
        headerWrapper: matrixVisible ? styles.headerRow : undefined,
      };
    }, [matrixVisible]);

    const actions = useMemo(() => {
      return matrixVisible
        ? {
            custom: {
              left: projectActions,
              hideDivider: true,
            },
          }
        : {
            add: maskHandlers.create,
            edit: maskHandlers.edit,
            convert: {
              exclude: entityType,
              handler: maskHandlers.convert,
            },
            remove: deleteConfig,
            resetColumns: userConfigMethods.remove,
            custom: {
              left: fileActionDefinitions,
            },
            createWidget: true,
          };
    }, [
      deleteConfig,
      entityType,
      fileActionDefinitions,
      maskHandlers.create,
      maskHandlers.edit,
      maskHandlers.convert,
      matrixVisible,
      projectActions,
      userConfigMethods.remove,
    ]);

    if (!userConfig) return null;

    const detailsVisible = selectedEntity !== null;

    return (
      <TableLayoutContext value={layoutState}>
        {matrixVisible && <ProjectBanderoleInfo />}
        <Table
          pending={pending}
          noRowsRenderer={noRowsRenderer}
          ref={ref}
          layout={matrixVisible ? 'table' : layout}
          cardConfig={cardConfig}
          areas={{
            left: {
              content: <ProjectTableQuickFilters />,
              resizable: true,
            },

            right: !matrixVisible &&
              detailsVisible && {
                content: (
                  <PreviewMobileWrapper
                    active={true}
                    actions={{
                      edit: maskHandlers.edit,
                      remove: deleteConfig,
                      additionalActionsLeft: (
                        <FileActions entity={entityType} />
                      ),
                    }}
                  >
                    <ProjectFileInfoPanelContainer
                      onCloseClicked={() =>
                        tableStateBag.tableInstance.toggleAllRowsSelected(false)
                      }
                      onEdit={maskHandlers.edit.handler}
                      id={selectedEntity.id}
                    />
                  </PreviewMobileWrapper>
                ),
              },
            center: matrixVisible && {
              content: (
                <ProjectRightsGrid scrollRef={gridScrollRef} {...matrix} />
              ),
            },
          }}
          actions={actions}
          columnConfigs={userConfig}
          manualGroupBy={manualGroupBy}
          initialSortBy={initialSortBy}
          loadMoreItems={fetchMore}
          prepareRowDisplayModifiers={rowModifiers}
          data={data}
          total={total}
          scrollRef={listScrollRef}
          rowHeightRem={matrixVisible ? '3rem' : undefined}
          basicClasses={classes}
          {...rest}
        />
      </TableLayoutContext>
    );
  }
);

interface ProjectFileInfoPanelContainerProps
  extends Omit<IProjectFileInfoPanelProps, 'project'> {
  id: number;
}

function ProjectFileInfoPanelContainer(
  props: ProjectFileInfoPanelContainerProps
) {
  const { id, ...others } = props;

  const { project } = useProject(String(id));

  return <ProjectFileInfoPanel project={project} {...others} />;
}
