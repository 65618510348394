import { useMemo } from 'react';

import {
  FixedDataPicker,
  IEnumPickerProps,
} from '@work4all/components/lib/components/entity-picker/fixed-data-picker/FixedDataPicker';

import { MailboxEncryptionOption } from './MailboxEncryptionOption';
import { MailboxEncryption } from './types';

type BoxedMailboxEncryption = { id: MailboxEncryption; name: string };

const OPTIONS: BoxedMailboxEncryption[] = [
  { id: 'None', name: 'None' },
  { id: 'Unknown', name: 'Unknown' },
  { id: 'SSL', name: 'SSL' },
  { id: 'STARTTLS', name: 'STARTTLS' },
];

export interface MailboxEncryptionPickerProps
  extends Omit<
    IEnumPickerProps<false>,
    'multiple' | 'value' | 'onChange' | 'dataSet'
  > {
  value: MailboxEncryption;
  onChange: (value: MailboxEncryption) => void;
}

export function MailboxEncryptionPicker(props: MailboxEncryptionPickerProps) {
  const { value, onChange, ...others } = props;

  const valueBoxed = useMemo(
    () => OPTIONS.find((o) => o.id === value) ?? null,
    [value]
  );

  const handleChange = (value: BoxedMailboxEncryption) => {
    onChange(value ? value.id : null);
  };

  function renderItemContent(value: BoxedMailboxEncryption) {
    return <MailboxEncryptionOption value={value ? value.id : null} />;
  }

  return (
    <FixedDataPicker
      multiple={false}
      value={valueBoxed}
      onChange={handleChange}
      dataSet={OPTIONS}
      renderItemContent={renderItemContent}
      {...others}
    />
  );
}
