import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { ChangeEvent } from './ChangeEvent.entity';
import { ChildItemCreated } from './ChildItemCreated.entity';
import { CommentEvent } from './CommentEvent.entity';
import { CreateEvent } from './CreateEvent.entity';

export type Event<T extends EMode = EMode.entity> = T extends EMode.entity
  ? CreateEvent<T> | CommentEvent<T> | ChangeEvent<T> | ChildItemCreated<T>
  : {
      /** Alias: CreateEvent */
      createEvent?: CreateEvent<T>;
      /** Alias: CommentEvent */
      commentEvent?: CommentEvent<T>;
      /** Alias: ChangeEvent */
      changeEvent?: ChangeEvent<T>;
      /** Alias: ChildItemCreated */
      childItemCreated?: ChildItemCreated<T>;
    };

const fields: FieldDefinitions<Event<EMode.query>> = {
  createEvent: {
    alias: 'createEvent',
    entity: Entities.createEvent,
  },
  commentEvent: {
    alias: 'commentEvent',
    entity: Entities.commentEvent,
  },
  changeEvent: {
    alias: 'changeEvent',
    entity: Entities.changeEvent,
  },
  childItemCreated: {
    alias: 'childItemCreated',
    entity: Entities.childItemCreated,
  },
};

export const eventEntityDefinition: EntitiyDefinition<Event<EMode.query>> = {
  kind: 'union',
  local: {},
  remote: {
    queryName: 'getEvents',
    fragmentName: 'Event',
    withPaginationWrapper: true,
    mutations: {
      upsert: {
        mutationName: 'getEvents',
        args: [
          { name: 'objectType', type: 'ObjectType!' },
          { name: 'objectId', type: 'String!' },
          { name: 'querySize', type: 'Int' },
          { name: 'queryPage', type: 'Int' },
        ],
      },
    },
    params: [
      { name: 'objectType', type: 'ObjectType!' },
      { name: 'objectId', type: 'String!' },
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
    ],
  },
  fieldDefinitions: fields,
};
