import { useEffect, useMemo, useState } from 'react';

import { MuiThemeProvider } from '@work4all/components';

import { settings, useSetting } from '../settings';

export const HookedMuiThemeProvider: React.FC = (props) => {
  const pageTheme = useSetting(settings.pageTheme());

  const [preferredColorScheme, setPreferredColorScheme] = useState<
    'dark' | 'light'
  >(
    window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light'
  );

  useEffect(() => {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (event) => {
        setPreferredColorScheme(event.matches ? 'dark' : 'light');
      });
  });
  const resultingTheme = useMemo(() => {
    if (pageTheme.value === 'auto') {
      return preferredColorScheme;
    }
    return pageTheme.value;
  }, [pageTheme.value, preferredColorScheme]);

  return (
    <MuiThemeProvider pageTheme={resultingTheme}>
      {props.children}
    </MuiThemeProvider>
  );
};
