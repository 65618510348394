import styles from './LabeledCurrencyInput.module.scss';

import React from 'react';

import { ILabeledInput, LabeledInput } from '../labeled-input';

import { Unit } from './components/unit/Unit';

interface ILabeledCurrencyInputProps
  extends Omit<ILabeledInput, 'endAdornment' | 'classes'> {
  unit: string;
}

export const LabeledCurrencyInput = React.forwardRef<
  HTMLInputElement,
  ILabeledCurrencyInputProps
>((props, ref) => {
  const { unit, ...filterSearchProps } = props;

  return (
    <LabeledInput
      ref={ref}
      {...filterSearchProps}
      classes={{ input: styles.input }}
      endAdornment={<Unit unit={unit} />}
    />
  );
});
