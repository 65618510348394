import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';

import { IFileContactMetadata, IMainContactInfo } from '../FileContext';

type IFileContactMetadataPartial = Pick<
  IFileContactMetadata,
  'contacts' | 'mainContact' | 'categories' | 'note'
>;

export function mapFileContactMetadata(
  entity: Customer | Supplier | null
): IFileContactMetadataPartial | null {
  if (!entity) {
    return null;
  }

  const {
    isPrivateCustomer,
    phoneNumber,
    phoneNumber2,
    eMail,
    eMail2,
    mainContactId,
    contactList,
    categoryAssignmentList,
    note,
    website,
    group,
    firstContactDate,
    firstContactPerson,
    paymentKind,
  } = entity;

  const mainContact: IMainContactInfo = {
    isPrivateCustomer,
    phoneNumber,
    phoneNumber2,
    eMail,
    eMail2,
    mainContactId,
    addressText: `${entity.street && entity.street + ', '}${
      entity.postalCode && entity.postalCode + ' '
    }${entity.city}`,
    website,
    group,
    firstContactDate,
    firstContactPerson,
    paymentKind,
  };
  return {
    mainContact,
    contacts: contactList,
    categories: categoryAssignmentList,
    note,
  };
}
