import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { ContactType } from '../Enums/ContactType.enum';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Contact } from './Contact.entity';
import { ContactUnion } from './ContactUnion.entity';
import { Customer } from './Customer.entity';
import { Supplier } from './Supplier.entity';
import { User } from './User.entity';

export class MailSearchContactsResultItem<T extends EMode = EMode.entity> {
  /** Alias: mailAddress */
  mailAddress?: string;
  /** Alias: type */
  type?: ContactType;
  /** Alias: anrede */
  salutation?: string;
  /** Alias: anzeigeName */
  displayName?: string;
  /** Alias: gpCode */
  gpCode?: number;
  /** Alias: objCode */
  objId?: number;
  /** Alias: uniqueId */
  id?: string;
  /** Alias: contact */
  contact?: T extends EMode.query
    ? ContactUnion<T>
    : User<T> | Customer<T> | Supplier<T> | Contact<T>;

  __typename?: string;
}

const fields: FieldDefinitions<MailSearchContactsResultItem> = {
  mailAddress: {
    alias: 'mailAddress',
  },
  type: {
    alias: 'type',
  },
  salutation: {
    alias: 'anrede',
  },
  displayName: {
    alias: 'anzeigeName',
  },
  gpCode: {
    alias: 'gpCode',
  },
  objId: {
    alias: 'objCode',
  },
  id: {
    alias: 'uniqueId',
  },
  contact: {
    alias: 'contact',
    entity: [
      Entities.user,
      Entities.customer,
      Entities.supplier,
      Entities.contact,
    ],
  },
  __typename: {
    alias: '__typename',
  },
};

export const mailSearchContactsResultItemEntityDefinition: EntitiyDefinition<MailSearchContactsResultItem> =
  {
    local: {},
    remote: {
      queryName: 'searchMailContact',
      fragmentName: 'MailSearchContactsResultItem',
      withPaginationWrapper: false,
      params: [
        { name: 'searchValue', type: 'String' },
        { name: 'filterTypes', type: '[ContactType]' },
      ],
    },
    fieldDefinitions: fields,
  };
