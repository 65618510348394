import { useCallback, useMemo } from 'react';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export const useInaccessibleFields = () => {
  //ToDo this needs to be hooked up once api is done
  const inaccessibleFields = useMemo<
    Partial<Record<Entities, Record<string, boolean>>>
  >(
    () => ({
      [Entities.task]: {
        // note: true,
      },
      [Entities.visitationReport]: {
        //talkingPointList: false,
      },
      [Entities.appointment]: {
        // appointmentState: true, // Preview status field
        // 'appointmentState.name': true, // List status column
        // 'topicMarkList.name': true,
        // 'project.number': true,
        // 'project.parentProject.number': true,
        // title: false,
      },
      [Entities.invoice]: {
        title: true,
      },
    }),
    []
  );

  const isInaccessible = useCallback(
    (entity: Entities, fieldPath: string) => {
      return !!inaccessibleFields[entity]?.[fieldPath];
    },
    [inaccessibleFields]
  );

  const isSomeInaccessible = useCallback(
    (entity: Entities, fieldPaths: string[]) => {
      return fieldPaths.some((fieldPath) => {
        return isInaccessible(entity, fieldPath);
      });
    },
    [isInaccessible]
  );

  const isEveryInaccessible = useCallback(
    (entity: Entities, fieldPaths: string[]) => {
      return fieldPaths.every((fieldPath) => {
        return isInaccessible[entity]?.[fieldPath];
      });
    },
    [isInaccessible]
  );

  return { isInaccessible, isSomeInaccessible, isEveryInaccessible };
};
