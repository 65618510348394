import { FC, MouseEventHandler, useMemo } from 'react';

import { dateToString } from '@work4all/components/lib/dataDisplay/basic-table/utils/filters/filterConversion';

import { useProjectCaptions } from '../../../../../hooks';

import { InlineData } from './InlineData';

type IEventPeriodCardProps = {
  eventStartDate: Date;
  eventEndDate: Date;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export const EventPeriodCard: FC<IEventPeriodCardProps> = ({
  eventStartDate,
  eventEndDate,
  onClick,
}) => {
  const { eventStartEndDateCaption } = useProjectCaptions();
  const createEventStartEndStr = useMemo(
    () => dateToString(eventStartDate, eventEndDate, false),
    [eventStartDate, eventEndDate]
  );

  return (
    <InlineData
      onClick={onClick}
      label={eventStartEndDateCaption}
      content={createEventStartEndStr}
    />
  );
};
