import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  NormalizedCacheObject,
  Operation,
  selectURI,
  split,
} from '@apollo/client';
import { NetworkError } from '@apollo/client/errors';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import * as ReactSentry from '@sentry/react';
import { GraphQLError } from 'graphql';
import i18next from 'i18next';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { SentryActions } from '@work4all/components';

import { useUser } from '@work4all/data';
import {
  logoutUser,
  refreshUserMeta,
} from '@work4all/data/lib/actions/user-actions';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';
import { useCustomSnackbar } from '@work4all/data/lib/snackbar/PresetSnackbarProvider';

import { formatErrorDetails } from '@work4all/utils/lib/formatErrorDetails';
import { getDebugInfoParts } from '@work4all/utils/lib/getDebugInfoParts';

import { useApiVersionContext } from '../app-updates/api-version-context';
import { useApiVersionLinkContext } from '../app-updates/api-version-link-context';

import { useTokenRefresher } from './hooks/useTokenRefresher';
import { createAuthLink } from './utils/createAuthLink';
import {
  createTokenRefreshLink,
  isAuthorizationError,
} from './utils/createTokenRefreshLink';
import { createWsLink } from './utils/createWsLink';
import { timeZoneLink } from './utils/timeZoneLink';
import { ValidationErrors } from './ValidationErrors';

interface Props {
  client: ApolloClient<NormalizedCacheObject>;
  children: JSX.Element;
}

export const Apollo: React.VFC<Props> = (props) => {
  const user = useUser();
  const { activeTenant: selectedTenant } = useTenant();

  let activeTenant = selectedTenant;
  if (user && !user.mandanten.map((el) => el.code).includes(selectedTenant)) {
    activeTenant = user.mandanten[0].code;
  }
  const dispatch = useDispatch();
  const [client, setClient] =
    useState<ApolloClient<NormalizedCacheObject> | null>(props.client);
  const [initing, setIniting] = useState(true);

  const { enqueueSnackbar, closeSnackbar, hideApiErrors } = useCustomSnackbar();

  const refreshToken = useTokenRefresher();

  const { link: apiVersionCheckLink } = useApiVersionLinkContext();

  const customerNumber = user?.kundennummer;

  const { version } = useApiVersionContext();

  const debugInfoData = useMemo(() => {
    return getDebugInfoParts({ customerNumber, apiVersion: version });
  }, [customerNumber, version]);

  const previousUserInfoRef = useRef<{
    userId: number | null;
    tenant: number | null;
  } | null>(null);

  useEffect(() => {
    if (!user || !activeTenant) {
      // Reset client cache when a user logs out.
      if (previousUserInfoRef.current !== null) {
        props.client.cache.reset();
      }

      previousUserInfoRef.current = null;

      setClient(new ApolloClient({ cache: new InMemoryCache() }));
      setIniting(false);
      return;
    }

    // Log any GraphQL errors or network error that occurred
    const errorLink = onError((response) => {
      const { graphQLErrors } = response;
      //default inform about errors except validation errors as they are handled by the tokenrefreseher
      const showError = (
        e: GraphQLError | NetworkError,
        message = i18next.t('ERROR.COULD_NOT_REACH_SERVICE'),
        createSentryEvent = true
      ) => {
        const renderAction = (key) => {
          if (!createSentryEvent) {
            return (
              <IconButton
                sx={{
                  color: 'var(--ui01)',
                }}
                onClick={() => closeSnackbar(key)}
              >
                <Close />
              </IconButton>
            );
          }

          const eventId = ReactSentry.captureException(new Error(e.message));
          const errorDetails = formatErrorDetails(eventId, debugInfoData);
          return (
            <SentryActions
              onClose={() => closeSnackbar(key)}
              eventId={eventId}
              debugInfoData={errorDetails}
            />
          );
        };
        if (
          graphQLErrors &&
          ValidationErrors.shouldShowError(graphQLErrors) &&
          !hideApiErrors
        ) {
          enqueueSnackbar(message, {
            variant: 'error',
            action: renderAction,
          });
        }
      };

      if (graphQLErrors) {
        graphQLErrors.forEach((err) => {
          console.error('[GraphQL error]:', err);
          if (ValidationErrors.hasTranslation(err)) {
            showError(err, i18next.t(`ERROR.${err.extensions?.code}`), false);
          } else {
            showError(err);
          }
        });
      }

      if (!isAuthorizationError(response)) {
        console.error(`[Network error]: ${response.networkError}`);
        if (response.networkError) showError(response.networkError);
      }
    });

    const authLink = createAuthLink(user.token, activeTenant, user.baseUrl);
    const tokenRefreshLink = createTokenRefreshLink({
      refreshToken,
      onError: () => {
        dispatch(logoutUser());
      },
    });

    const wsLink = createWsLink(user.token, activeTenant, user.baseUrl);

    const httpLink = ApolloLink.from([
      timeZoneLink,
      authLink,
      tokenRefreshLink,
      errorLink,
      apiVersionCheckLink,
      new BatchHttpLink({
        uri: `${user.baseUrl}/graphql`,
        batchKey: (operation: Operation) => {
          const context = operation.getContext();

          const contextConfig = {
            http: context.http,
            options: context.fetchOptions,
            credentials: context.credentials,
            headers: context.headers,
          };

          let unifier = JSON.stringify(contextConfig);
          if (context.singleBatch === true) {
            unifier = `${operation.operationName}-${performance.now()}`;
          }

          //may throw error if config not serializable
          return selectURI(operation, `${user.baseUrl}/graphql`) + unifier;
        },
      }),
    ]);

    const link = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink
    );

    const previousUserInfo = previousUserInfoRef.current;

    // Reset cache if user or tenant changed.
    if (
      previousUserInfo !== null &&
      (user.benutzerCode !== previousUserInfo.userId ||
        activeTenant !== previousUserInfo.tenant)
    ) {
      props.client.cache.reset();
    }

    previousUserInfoRef.current = {
      userId: user.benutzerCode,
      tenant: activeTenant,
    };

    props.client.setLink(link);

    setClient(props.client);
    setIniting(false);
  }, [
    dispatch,
    refreshToken,
    user,
    debugInfoData,
    props.client,
    enqueueSnackbar,
    closeSnackbar,
    activeTenant,
    apiVersionCheckLink,
    hideApiErrors,
  ]);

  useEffect(() => {
    if (client && typeof user?.benutzerCode === 'number') {
      dispatch(refreshUserMeta(user.benutzerCode));
    }
  }, [client, dispatch, user?.benutzerCode]);

  if (initing) {
    return null;
  }

  if (!client) {
    return props.children;
  }

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
