import styles from './styles.module.scss';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Divider, IconButton } from '@mui/material';
import { useContext } from 'react';

import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';

import { UserCompilationPicker } from '../../../../components/entity-picker/UsersCompilationPicker';

import { CalendarUsersDialogContext } from './calendar-users-dialog-context';

export function View2() {
  const { goBack } = useHistoryStack();

  const { users, onUsersChange } = useContext(CalendarUsersDialogContext);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <IconButton size="large" onClick={() => goBack()}>
          <ArrowBackIcon />
        </IconButton>
      </div>

      <Divider />

      <div className={styles.content}>
        <UserCompilationPicker
          value={users}
          onChange={onUsersChange}
          autoFocusTextInput
        />
      </div>
    </div>
  );
}
