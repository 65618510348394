import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputRaZahlung<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: any;
  /** Alias: benutzerCode */
  benutzerCode?: any;
  /** Alias: buchungsDatum */
  buchungsDatum?: any;
  /** Alias: datev */
  datev?: any;
  /** Alias: datum */
  datum?: any;
  /** Alias: konto */
  konto?: any;
  /** Alias: notiz */
  notiz?: any;
  /** Alias: sachkontoCode */
  sachkontoCode?: any;
  /** Alias: sachkonto */
  sachkonto?: any;
  /** Alias: summe */
  summe?: any;
  /** Alias: raCode */
  raCode?: any;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputRaZahlung> = {
  code: {
    alias: 'code',
  },
  benutzerCode: {
    alias: 'benutzerCode',
  },
  buchungsDatum: {
    alias: 'buchungsDatum',
  },
  datev: {
    alias: 'datev',
  },
  datum: {
    alias: 'datum',
  },
  konto: {
    alias: 'konto',
  },
  notiz: {
    alias: 'notiz',
  },
  sachkontoCode: {
    alias: 'sachkontoCode',
  },
  sachkonto: {
    alias: 'sachkonto',
  },
  summe: {
    alias: 'summe',
  },
  raCode: {
    alias: 'raCode',
  },
  customFields: {
    alias: 'customFields',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputRaZahlungEntityDefinition: EntitiyDefinition<InputRaZahlung> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputRaZahlung',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
