import { useMemo } from 'react';

import { formatNumberAsCurrency } from '@work4all/utils';

export type INumberCellProps = {
  value: string | number;
  column?: {
    id: string;
  };
  row?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    original: any;
  };
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  className?: string;
  onEdit?: () => void;
};

export const NumberCell = (props: INumberCellProps) => {
  const {
    value,
    minimumFractionDigits = 0,
    maximumFractionDigits = 0,
    className = '',
  } = props;

  const displayValue = useMemo(() => {
    if ((typeof value === 'string' && value === '0') || value === 0) {
      return '';
    } else {
      return formatNumberAsCurrency(
        typeof value === 'string' ? parseInt(value, 10) : value,
        {
          minimum: minimumFractionDigits,
          maximum: maximumFractionDigits,
        }
      );
    }
  }, [maximumFractionDigits, minimumFractionDigits, value]);

  return (
    <div
      style={{ textAlign: 'right', paddingRight: '.5rem' }}
      className={className}
      onClick={props.onEdit}
    >
      {displayValue || ''}
    </div>
  );
};
