import styles from './CheckboxToggleCell.module.scss';

import { CheckCircleRounded, CircleTwoTone } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';

import { useDataMutation, useUser } from '@work4all/data';

import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { TicketStatus } from '@work4all/models/lib/Enums/TicketStatus.enum';
import { ITableColumnConfig } from '@work4all/models/lib/table-schema/table-schema';

import { canEditTicket } from '@work4all/utils/lib/permissions';

export type ITicketStatusCellProps = {
  value: TicketStatus;
  row: Row<Ticket>;
  column: ITableColumnConfig;
};

export const TicketStatusCell = (props: ITicketStatusCellProps) => {
  const {
    value,
    row: { original: ticket },
    column: { id: statusKey },
  } = props;

  const { t } = useTranslation();

  const [mutate] = useDataMutation({
    entity: Entities.ticket,
    mutationType: EMode.upsert,
    responseData: { id: null, [statusKey]: null },
    resetStore: false,
  });

  const user = useUser();
  const isAllowedToModify = canEditTicket(user, ticket);

  const isDone = value === TicketStatus.ERLEDIGT;

  const toggleStatus = () => {
    if (!isAllowedToModify) return;

    if (isDone) {
      mutate({ id: ticket.id, [statusKey]: TicketStatus.OFFEN });
    } else {
      mutate({ id: ticket.id, [statusKey]: TicketStatus.ERLEDIGT });
    }
  };

  const Icon =
    value === TicketStatus.ERLEDIGT ? CheckCircleRounded : CircleTwoTone;

  return (
    <div className={styles['task-status-cell-root']}>
      <IconButton
        disabled={!isAllowedToModify}
        sx={{ padding: 0 }}
        onClick={(e) => {
          e.stopPropagation();
          toggleStatus();
        }}
      >
        <Icon
          className={clsx(styles['check-circle'], {
            [styles['check-circle-success']]: value === TicketStatus.ERLEDIGT,
            [styles['check-circle-edit']]:
              value === TicketStatus.IN_BEARBEITUNG ||
              value === TicketStatus.ZURUECKGESTELLT,
            [styles['disabled']]: value === TicketStatus.ZURUECKGESTELLT,
          })}
        />
      </IconButton>

      <span className={styles['label']}>{t(`TICKET_STATUS.${value}`)}</span>
    </div>
  );
};
