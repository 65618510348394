import styles from './BirthdaysMenu.module.scss';

import { Email, Person, Phone } from '@mui/icons-material';
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { useCallModalContext } from '@work4all/components/lib/hooks/call-modal/useCallModalContext';

import { useDataProvider } from '@work4all/data';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { ContactTelephonenumbers } from '@work4all/models/lib/Classes/ContactTelephonenumbers.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { filterArray } from '../utils';

interface BirthdaysMenuProps {
  contacts: Contact<EMode.entity>[];
  users?: User<EMode.entity>[];
}

interface BirthdayWrap {
  date: string;
  items: Array<{
    type: Entities.user | Entities.contact;
    item: User | Contact;
  }>;
}

function groupBirthdays(
  userCollection: Array<User>,
  contactCollection: Array<Contact>
): BirthdayWrap[] {
  const result: BirthdayWrap[] = [];
  const items = [...userCollection, ...contactCollection];
  const currentYear = DateTime.now().year;

  items.forEach((item) => {
    const type =
      item.__typename === 'Ansprechpartner' ? Entities.contact : Entities.user;
    const birthdayDate =
      type === Entities.contact
        ? (item as Contact).birthdayDate
        : (item as User).geburtsdatum;
    const birthdayDateTime = DateTime.fromISO(birthdayDate).set({
      year: currentYear,
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    const group = result.find((x) =>
      DateTime.fromISO(x.date).equals(birthdayDateTime)
    );

    if (group) {
      group.items.push({
        type,
        item,
      });
    } else {
      result.push({
        date: birthdayDateTime.toISO(),
        items: [
          {
            type,
            item,
          },
        ],
      });
    }
  });

  return result.sort(
    (a, b) =>
      DateTime.fromISO(a.date).toMillis() - DateTime.fromISO(b.date).toMillis()
  );
}

export function BirthdaysMenu(props: BirthdaysMenuProps) {
  const { contacts, users = [] } = props;
  const [clickedContactId, setClickedContactId] = useState(0);
  const filteredArray = filterArray(contacts);

  const groups = useMemo(() => {
    return groupBirthdays(users, filteredArray);
  }, [filteredArray, users]);

  const { t } = useTranslation();

  const { setCallModal, callModal } = useCallModalContext();

  useEffect(() => {
    if (!callModal) setClickedContactId(0);
  }, [callModal]);

  const completeTelephonenumbersNumbersRequest = useMemo<DataRequest>(() => {
    return {
      entity: Entities.contactTelephonenumbers,
      completeDataResponse: true,
      vars: {
        sdObjType: contacts?.[0]?.businessPartnerType,
        sdObjMemberCode: contacts?.[0]?.businessPartnerId,
        contactCode: clickedContactId,
      },
      data: {
        central: null,
        directDial: null,
        directFax: null,
        fax: null,
        mobile: null,
        others: null,
        privateMobile: null,
        privateTelephone: null,
      } as ContactTelephonenumbers,
    };
  }, [clickedContactId, contacts]);

  const completeTelephoneNumbersResult =
    useDataProvider<ContactTelephonenumbers>(
      completeTelephonenumbersNumbersRequest,
      !clickedContactId
    );

  const completeTelephoneNumbers =
    completeTelephoneNumbersResult.data as unknown as ContactTelephonenumbers;

  useEffect(() => {
    if (
      completeTelephoneNumbers?.directDial !== undefined &&
      clickedContactId
    ) {
      setCallModal({
        data: contacts?.find((x) => x.id === clickedContactId),
        telephoneNumbers: completeTelephoneNumbers,
      });
    }
  }, [
    clickedContactId,
    completeTelephoneNumbers,
    completeTelephoneNumbersResult,
    completeTelephoneNumbersResult?.total,
    contacts,
    setCallModal,
  ]);

  return (
    <List dense={true} className={styles.optionsWrap}>
      {groups.map((group) => {
        const prepend: string = DateTime.fromISO(group.date)
          .startOf('day')
          .equals(DateTime.now().startOf('day'))
          ? `${t('COMMON.TODAY')}, `
          : DateTime.fromISO(group.date)
              .startOf('day')
              .equals(DateTime.now().plus({ day: 1 }).startOf('day'))
          ? `${t('COMMON.TOMORROW')}, `
          : '';

        return (
          <React.Fragment>
            <Divider
              title={`${prepend}${DateTime.fromISO(group.date).toFormat(
                'cccc'
              )}, ${DateTime.fromISO(group.date)
                .setLocale('de')
                .toLocaleString()}`}
            />
            {group.items.map((item) => {
              return (
                <ListItem
                  disableGutters
                  secondaryAction={
                    <React.Fragment>
                      <IconButton
                        edge={false}
                        aria-label="call"
                        color="primary"
                        disabled={!item.item.phoneNumber}
                        onClick={() => {
                          setClickedContactId(item.item.id);
                        }}
                      >
                        <Phone />
                      </IconButton>

                      <IconButton
                        edge="end"
                        aria-label="email"
                        color="primary"
                        href={`mailto:${item.item.eMail}`}
                        target="_blank"
                        disabled={!item.item.eMail}
                      >
                        <Email />
                      </IconButton>
                    </React.Fragment>
                  }
                >
                  <ListItemIcon>
                    <Person />
                  </ListItemIcon>
                  {item.type === 'contact' ? (
                    <ListItemText
                      className={styles.name}
                      primary={
                        item.item.displayName +
                        ` | ${(item.item as Contact).businessPartner.data.name}`
                      }
                    />
                  ) : (
                    <ListItemText
                      className={styles.name}
                      primary={item.item.displayName}
                    />
                  )}
                </ListItem>
              );
            })}
          </React.Fragment>
        );
      })}
    </List>
  );
}
