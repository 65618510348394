import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class WorkingTimeTimeCardMonthCorrection<
  T extends EMode = EMode.entity
> {
  /** Alias: anzahlKorrekturMinuten */
  correctionMinutes?: number;
  /** Alias: korrekturNotiz */
  correctionNote?: string;

  __typename?: string;
}

const fields: FieldDefinitions<WorkingTimeTimeCardMonthCorrection> = {
  correctionMinutes: {
    alias: 'anzahlKorrekturMinuten',
  },
  correctionNote: {
    alias: 'korrekturNotiz',
  },
  __typename: {
    alias: '__typename',
  },
};

export const workingTimeTimeCardMonthCorrectionEntityDefinition: EntitiyDefinition<WorkingTimeTimeCardMonthCorrection> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'KommtGehtZeitkarteMonatsKorrektur',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
