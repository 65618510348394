import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputTelefonat<T extends EMode = EMode.entity> {
  /** Alias: ansprechpartnerCode */
  ansprechpartnerCode?: number;
  /** Alias: artikelCode */
  artikelCode?: number;
  /** Alias: erstellerBenutzerCode */
  erstellerBenutzerCode?: number;
  /** Alias: beginTime */
  beginTime?: string;
  /** Alias: benutzerCode */
  benutzerCode?: number;
  /** Alias: creationDate */
  creationDate?: string;
  /** Alias: code */
  code?: number;
  /** Alias: datum */
  datum?: string;
  /** Alias: endTime */
  endTime?: string;
  /** Alias: erinnernDate */
  erinnernDate?: string;
  /** Alias: kontaktberichtCode */
  kontaktberichtCode?: number;
  /** Alias: kostenerfassungCode */
  kostenerfassungCode?: number;
  /** Alias: modificationDate */
  modificationDate?: string;
  /** Alias: notiz */
  notiz?: string;
  /** Alias: objGrCode */
  objGrCode?: number;
  /** Alias: parentCode */
  parentCode?: number;
  /** Alias: projektCode */
  projektCode?: number;
  /** Alias: projektePlanungCode */
  projektePlanungCode?: number;
  /** Alias: sDObjMemberCode */
  sDObjMemberCode?: number;
  /** Alias: sDObjType */
  sDObjType?: SdObjType;
  /** Alias: seriennummer */
  seriennummer?: string;
  /** Alias: status */
  status?: number;
  /** Alias: titel */
  titel?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];
  /** Alias: createdByUserCode */
  createdByUserCode?: number;

  __typename?: string;
}

const fields: FieldDefinitions<InputTelefonat> = {
  ansprechpartnerCode: {
    alias: 'ansprechpartnerCode',
  },
  artikelCode: {
    alias: 'artikelCode',
  },
  erstellerBenutzerCode: {
    alias: 'erstellerBenutzerCode',
  },
  beginTime: {
    alias: 'beginTime',
  },
  benutzerCode: {
    alias: 'benutzerCode',
  },
  creationDate: {
    alias: 'creationDate',
  },
  code: {
    alias: 'code',
  },
  datum: {
    alias: 'datum',
  },
  endTime: {
    alias: 'endTime',
  },
  erinnernDate: {
    alias: 'erinnernDate',
  },
  kontaktberichtCode: {
    alias: 'kontaktberichtCode',
  },
  kostenerfassungCode: {
    alias: 'kostenerfassungCode',
  },
  modificationDate: {
    alias: 'modificationDate',
  },
  notiz: {
    alias: 'notiz',
  },
  objGrCode: {
    alias: 'objGrCode',
  },
  parentCode: {
    alias: 'parentCode',
  },
  projektCode: {
    alias: 'projektCode',
  },
  projektePlanungCode: {
    alias: 'projektePlanungCode',
  },
  sDObjMemberCode: {
    alias: 'sDObjMemberCode',
  },
  sDObjType: {
    alias: 'sDObjType',
  },
  seriennummer: {
    alias: 'seriennummer',
  },
  status: {
    alias: 'status',
  },
  titel: {
    alias: 'titel',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customFields: {
    alias: 'customFields',
  },
  createdByUserCode: {
    alias: 'createdByUserCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputTelefonatEntityDefinition: EntitiyDefinition<InputTelefonat> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputTelefonat',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
