import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { AggregationType } from '../Enums/AggregationType.enum';
import { EMode } from '../Enums/EMode.enum';

import { GroupQuerySortInfo } from './GroupQuerySortInfo.entity';

export class GroupFieldDefinition<T extends EMode = EMode.entity> {
  /** Alias: field */
  field?: string;
  /** Alias: alias */
  alias?: string;
  /** Alias: aggregations */
  aggregations?: AggregationType[];
  /** Alias: sort */
  sort?: GroupQuerySortInfo<T>[];
  /** Alias: dontAggregate */
  dontAggregate?: boolean;

  __typename?: string;
}

const fields: FieldDefinitions<GroupFieldDefinition> = {
  field: {
    alias: 'field',
  },
  alias: {
    alias: 'alias',
  },
  aggregations: {
    alias: 'aggregations',
  },
  sort: {
    alias: 'sort',
  },
  dontAggregate: {
    alias: 'dontAggregate',
  },
  __typename: {
    alias: '__typename',
  },
};

export const groupFieldDefinitionEntityDefinition: EntitiyDefinition<GroupFieldDefinition> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'GroupFieldDefinition',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
