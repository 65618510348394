import { useMemo } from 'react';

import { ReplicationId } from '@work4all/data/lib/entities/utils/replication.types';

import { useReplicationStateContext } from './replication-state-context';
import { ReplicationState } from './types';
import { expandReplicationStatus } from './utils';

export function useReplicationState(
  replicationId: ReplicationId
): ReplicationState {
  const ctx = useReplicationStateContext();
  const status = ctx[replicationId];
  return useMemo(() => expandReplicationStatus(status), [status]);
}
