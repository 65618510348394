import { RefObject } from 'react';
import { TableInstance } from 'react-table';

import { ColumnInstance } from '@work4all/components/lib/dataDisplay/basic-table/types';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { VisibleColumnsContext } from './columns-visibility/visible-columns-context';
import { Popover } from './popover/Popover';

export interface SortColumnPopoverProps {
  sortBy: TableInstance['state']['sortBy'];
  visibleColumns: ColumnInstance[];
  tableInstanceRef: RefObject<TableInstance>;
  popoverState: ReturnType<typeof usePopoverState>;
}

export const SortColumnPopover = function SortColumnPopover(
  props: SortColumnPopoverProps
) {
  const { visibleColumns, tableInstanceRef, popoverState } = props;

  return (
    <VisibleColumnsContext.Provider value={visibleColumns}>
      <Popover
        popoverState={popoverState}
        tableInstanceRef={tableInstanceRef}
        type="sortColumn"
      />
    </VisibleColumnsContext.Provider>
  );
};
