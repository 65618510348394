import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogActions,
  DialogContent,
  useDialogs,
} from '@work4all/components';

interface CreateViewDialogProps {
  open: boolean;
  options?: string[];
  onClose: () => void;
  onConfirm: (value: string) => void;
}

export function CreateViewDialog(props: CreateViewDialogProps) {
  const { open, options = [], onClose, onConfirm } = props;

  const { t } = useTranslation();

  const dialogs = useDialogs();

  const [value, setValue] = useState('');

  useEffect(() => {
    if (open) {
      setValue('');
    }
  }, [open]);

  const trimmed = value.trim();

  const handleConfirm = async () => {
    if (options.includes(trimmed)) {
      const confirmed = await dialogs.confirm({
        title: t('ALERTS.CONFIRM'),
        description: t('CALENDAR.VIEWS.OVERWRITE_VIEW'),
        confirmLabel: t('ALERTS.BTN_SAVE'),
        cancelLabel: t('ALERTS.BTN_ABORT'),
      });

      if (!confirmed) return;
    }

    onConfirm(trimmed);
  };

  return (
    <Dialog title={t('CALENDAR.VIEWS.SAVE_VIEW')} open={open} onClose={onClose}>
      <DialogContent>
        <Autocomplete
          freeSolo
          options={options}
          inputValue={value}
          onInputChange={(event, newValue) => setValue(newValue ?? '')}
          renderInput={(params) => <TextField {...params} />}
        />
      </DialogContent>

      <DialogActions>
        <Button
          disabled={trimmed === ''}
          color="primary"
          onClick={handleConfirm}
        >
          {t('INPUTS.SAVE')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
