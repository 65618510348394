import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';
import { ArticlePreviewContainer } from '@work4all/components/lib/components/entity-preview/article-preview';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';

import { OnOpenMask } from '../../../containers/mask-overlays/mask-overlay';
import schema from '../schemata/article-table-schema.json';
import { Table } from '../table/Table';
import { TableLayoutContext, useTableLayoutState } from '../table-layout';
import { TableNoRowsPlaceholder } from '../TableNoRowsPlaceholder';
import { useDataTable } from '../use-data-table';
import { useDeleteEntitiesToolbarConfig } from '../use-delete-entities-toolbar-config';
import { useTableMaskHandlers } from '../use-table-mask-handlers';
import { useUserColumnConfigs } from '../use-user-column-configs';

import { ArticleTableQuickFilters } from './ArticleTableQuickFilters';

interface Props {
  prefilter?: unknown[];
  onOpenMask: OnOpenMask;
}

const defaultSort = [
  { field: 'creationDate', direction: SortDirection.DESCENDING },
];

const forceRequestFields = { groupId: null };
const manualGroupBy = true;

export const ArticleTable = React.forwardRef<TableInstance, Props>(
  function ArticleTable(props, ref) {
    const { prefilter, onOpenMask } = props;
    const { entity: entityType } = schema as never;

    const tableStateBag = useTableStateBag();

    const layoutState = useTableLayoutState();
    const [layout] = layoutState;

    const {
      columnConfigs,
      prepareRowDisplayModifiers,
      data,
      fetchMore,
      total,
      initialSortBy,
      pending,
      selectedEntity,
    } = useDataTable<Article, never>({
      layout,
      schema: schema as never,
      tableStateBag,
      prefilter,
      defaultSort,
      forceRequestFields,
    });

    const maskHandlers = useTableMaskHandlers(entityType, onOpenMask);

    const deleteConfig = useDeleteEntitiesToolbarConfig({
      entityType,
    });

    const [userConfig, userConfigMethods] = useUserColumnConfigs({
      layout,
      entityType,
      columnConfigs,
    });

    const { t } = useTranslation();

    const rowModifiers = useCallback(
      (value: Article) => {
        const modifiers = prepareRowDisplayModifiers(value);
        return {
          ...modifiers,
          isFaded: value.isShutDown,
        };
      },
      [prepareRowDisplayModifiers]
    );

    if (!userConfig) return null;

    return (
      <TableLayoutContext value={layoutState}>
        <Table
          pending={pending}
          noRowsRenderer={() => <TableNoRowsPlaceholder />}
          ref={ref}
          actions={{
            add: maskHandlers.create,
            edit: maskHandlers.edit,
            remove: deleteConfig,
            resetColumns: userConfigMethods.remove,
          }}
          areas={{
            left: {
              content: <ArticleTableQuickFilters />,
              resizable: true,
              collapseConfig: {
                title: t('COMMON.FILTER'),
              },
            },
            right: selectedEntity
              ? {
                  content: (
                    <ArticlePreviewContainer
                      articleId={selectedEntity.id}
                      onCloseClicked={() => {
                        tableStateBag.tableInstance.toggleAllRowsSelected(
                          false
                        );
                      }}
                      onEditClicked={maskHandlers.edit.handler}
                    />
                  ),
                  resizable: true,
                }
              : null,
          }}
          columnConfigs={userConfig}
          manualGroupBy={manualGroupBy}
          initialSortBy={initialSortBy}
          loadMoreItems={fetchMore}
          data={data}
          total={total}
          prepareRowDisplayModifiers={rowModifiers}
        />
      </TableLayoutContext>
    );
  }
);
