import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { LayoutType } from '../Enums/LayoutType.enum';

export class Layout<T extends EMode = EMode.entity> {
  /** Alias: defaultLayout */
  defaultLayout?: boolean;
  /** Alias: definition */
  definition?: string;
  /** Alias: id */
  _id?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: layoutType */
  layoutType?: LayoutType;
  /** Alias: mandantenCode */
  tenantId?: number;
  /** Alias: name */
  name?: string;
  /** Alias: updateTime */
  updateTime?: string;

  __typename?: string;
}

const fields: FieldDefinitions<Layout> = {
  defaultLayout: {
    alias: 'defaultLayout',
  },
  definition: {
    alias: 'definition',
  },
  _id: {
    alias: 'id',
  },
  insertTime: {
    alias: 'insertTime',
  },
  layoutType: {
    alias: 'layoutType',
  },
  tenantId: {
    alias: 'mandantenCode',
  },
  name: {
    alias: 'name',
  },
  updateTime: {
    alias: 'updateTime',
  },
  __typename: {
    alias: '__typename',
  },
};

export const layoutEntityDefinition: EntitiyDefinition<Layout> = {
  local: {},
  remote: {
    queryName: 'getLayouts',
    fragmentName: 'Layout',
    withPaginationWrapper: false,
    mutations: {
      upsert: {
        mutationName: 'upsertLayout',
        args: [
          { name: 'input', type: 'InputLayout!' },
          { name: 'drySave', type: 'Boolean' },
        ],
      },
    },
    params: [
      { name: 'userCode', type: 'Int' },
      { name: 'since', type: 'DateTime' },
      { name: 'lastId', type: 'Guid' },
      { name: 'showAll', type: 'Boolean' },
    ],
  },
  fieldDefinitions: fields,
};
