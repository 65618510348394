import { ApolloClient, InMemoryCache } from '@apollo/client';
import { Capacitor } from '@capacitor/core';
import * as Sentry from '@sentry/capacitor';
import * as ReactSentry from '@sentry/react';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { Provider as RxDBProvider } from 'rxdb-hooks';
import { Subject } from 'rxjs';

import { initTokenrefresh, UserProvider } from '@work4all/data';
import { logoutUser } from '@work4all/data/lib/actions/user-actions';
import { ApolloExtras } from '@work4all/data/lib/apollo-extras/ApolloExtras';
import { ReplicationEvent } from '@work4all/data/lib/entities/utils/replication.types';
import { EntityEvents } from '@work4all/data/lib/entity-events/EntityEvents';
import { UserEpicsDependencies } from '@work4all/data/lib/epics/user-effects';
import { TenantProvider } from '@work4all/data/lib/hooks/routing/TenantProvider';
import { PresetSnackbarProvider } from '@work4all/data/lib/snackbar/PresetSnackbarProvider';

import { loadI18n } from '@work4all/utils/lib/i18n';

import { bootstrapDatabase } from '@work4all/work4all-bootstrap/lib/bootstrapDatabase';
import { boostrapRootEpic } from '@work4all/work4all-bootstrap/lib/bootstrapRootEpic';

import App from './app/app';
import { Apollo } from './containers/apollo';
import { VersionChecker } from './containers/app-updates/VersionChecker';
import { ReplicationStateProvider } from './containers/replication-state/ReplicationStateProvider';
import { environment } from './environments';
import { ApplicationName } from './utils/ApplicationName.enum';

loadI18n();

declare global {
  interface Window {
    SENTRY_RELEASE?: {
      id: string;
    };
    HIDE_RELEASE_NOTES?: boolean;
  }
}
if (process.env.NODE_ENV === 'production') {
  Sentry.init(
    {
      dsn: 'https://60661856592ef1e88561c5e569c5f749@relay.sdc.work4allcloud.de/3',
      integrations: [
        new ReactSentry.BrowserTracing({
          routingInstrumentation: ReactSentry.reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
          tracePropagationTargets: [/.*work4all.*\/graphql$/],
        }),
        new ReactSentry.Replay({ useCompression: false }),
      ],
      enableNative: !['electron'].includes(Capacitor.getPlatform()),
      environment: environment.configuration,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      release: window.SENTRY_RELEASE?.id,
      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.02,
      replaysOnErrorSampleRate: 1.0,
    },
    ReactSentry.init
  );
}

bootstrapDatabase().then((db) => {
  const client = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        EMailAnhang: {
          fields: {
            fileInfos: {
              merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming);
              },
            },
          },
        },
        UserPresence: {
          keyFields: ['benutzerCode'],
        },
      },
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
      },
    },
    uri: '/',
  });

  const { rootEpic } = boostrapRootEpic();

  const replicationEvents = new Subject<ReplicationEvent>();
  const replicationEvents$ = replicationEvents.asObservable();

  const dependencies: UserEpicsDependencies = {
    db,
    tokenRefresh: initTokenrefresh(db, ApplicationName.work4all20, () => {
      store.dispatch(logoutUser());
    }),
    apollo: client,
    onReplicationEvent(event) {
      console.debug(`Replication event: ${event.id}=${event.type}`);
      replicationEvents.next(event);
    },
  };

  const epicMiddleware = createEpicMiddleware({
    dependencies,
  });
  const store = createStore(() => ({}), applyMiddleware(epicMiddleware));
  epicMiddleware.run(rootEpic);

  ReactDOM.render(
    <React.StrictMode>
      <ReplicationStateProvider events={replicationEvents$}>
        <RxDBProvider db={db}>
          <ReduxProvider store={store}>
            <PresetSnackbarProvider>
              <UserProvider>
                <TenantProvider>
                  <VersionChecker>
                    <Apollo client={client}>
                      <ApolloExtras>
                        <EntityEvents>
                          <App />
                        </EntityEvents>
                      </ApolloExtras>
                    </Apollo>
                  </VersionChecker>
                </TenantProvider>
              </UserProvider>
            </PresetSnackbarProvider>
          </ReduxProvider>
        </RxDBProvider>
      </ReplicationStateProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
});
