import styles from './TaskPriorityCell.module.scss';

import { ReactComponent as TaskPriorityMarkIcon } from '@work4all/assets/icons/task-actions/table/priority-mark.svg';

import { Task } from '@work4all/models/lib/Classes/Task.entity';

export enum TASKPRIORITY {
  'Niedrig' = 10,
  'NiedrigGelesen' = 0,
  'Normal' = 11,
  'NormalGelesen' = 1,
  'Hoch' = 12,
  'HochGelesen' = 2,
}

export type ITaskPriorityCellProps = {
  value: TASKPRIORITY;
  row: { original: Task };
};

export const TaskPriorityCell = (props: ITaskPriorityCellProps) => {
  const { value } = props;
  return value ? (
    <div className={styles.taskPriorityCellRoot}>
      {(value === TASKPRIORITY.HochGelesen || value === TASKPRIORITY.Hoch) && (
        <TaskPriorityMarkIcon />
      )}
    </div>
  ) : null;
};
