import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { BzObjType } from '../Enums/BzObjType.enum';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { FileEntity } from './FileEntity.entity';
import { User } from './User.entity';

export class ArchivePdf<T extends EMode = EMode.entity> {
  /** Alias: benutzer */
  user?: User<T>;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: bzObjMemberCode */
  bzObjMemberId?: number;
  /** Alias: bzObjType */
  bzObjType?: BzObjType;
  /** Alias: code */
  id?: number;
  /** Alias: datum */
  date?: string;
  /** Alias: downloadUrl */
  downloadUrl?: string;
  /** Alias: isERechnung */
  isEInvoice?: boolean;
  /** Alias: mimeType */
  mimeType?: string;
  /** Alias: nachweis */
  proof?: string;
  /** Alias: temporaer */
  temporary?: boolean;
  /** Alias: xML */
  xML?: string;
  /** Alias: fileInfos */
  fileInfos?: FileEntity<T>;

  __typename?: string;
}

const fields: FieldDefinitions<ArchivePdf> = {
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  userId: {
    alias: 'benutzerCode',
  },
  bzObjMemberId: {
    alias: 'bzObjMemberCode',
  },
  bzObjType: {
    alias: 'bzObjType',
  },
  id: {
    alias: 'code',
  },
  date: {
    alias: 'datum',
  },
  downloadUrl: {
    alias: 'downloadUrl',
  },
  isEInvoice: {
    alias: 'isERechnung',
  },
  mimeType: {
    alias: 'mimeType',
  },
  proof: {
    alias: 'nachweis',
  },
  temporary: {
    alias: 'temporaer',
  },
  xML: {
    alias: 'xML',
  },
  fileInfos: {
    alias: 'fileInfos',
    entity: Entities.fileEntity,
  },
  __typename: {
    alias: '__typename',
  },
};

export const archivePdfEntityDefinition: EntitiyDefinition<ArchivePdf> = {
  local: {},
  remote: {
    queryName: 'getArchivPdf',
    fragmentName: 'ArchivPdf',
    withPaginationWrapper: false,
    params: [
      { name: 'bzObjType', type: 'BzObjType!' },
      { name: 'bzObjMemberCode', type: '[Int]!' },
    ],
  },
  fieldDefinitions: fields,
};
