import styles from './CardContent.module.scss';
import cardScssVariables from '@work4all/assets/theme/variablesDark.scss';

import { get } from 'lodash';
import React from 'react';

import {
  ICardWidgetColumnDef,
  IGetCellModifiers,
  IGetRowModifiers,
} from '../../types';

import { Cell, Row } from './components';

export interface ICardContentProps<T> {
  columns: ICardWidgetColumnDef<T>[];
  items: T[];
  maxRows: number;
  getItemHref: (item: T) => string;
  isSelected?: (item: T) => boolean;
  onItemClick?: (item: T, event: React.MouseEvent<HTMLElement>) => void;
  onItemDoubleClick?: (item: T, event: React.MouseEvent<HTMLElement>) => void;
  getRowModifiers?: IGetRowModifiers<T>;
  getCellModifiers?: IGetCellModifiers<T>;
}

/**
 * This is grid where every row has one primary cell and several secondary
 * Primary always takes remaining space (1fr).
 * Secondary takes as much as he needs (auto).
 * The largest cell value determines the width of the whole column.
 */

export function CardContent<T>(props: ICardContentProps<T>) {
  const {
    items,
    maxRows,
    getItemHref,
    isSelected,
    onItemClick,
    onItemDoubleClick,
    getCellModifiers,
    getRowModifiers,
    columns = [],
  } = props;

  const rowHeight = cardScssVariables.cardWidgetRowHeightRem;
  const gridHeight = `calc(${rowHeight} * ${maxRows})`;

  const gridTemplateColumns = columns
    .map((column) => (column.primary ? '1fr' : 'auto'))
    .join(' ');

  const bindItemHandler = (
    handler: (item: T, event: React.MouseEvent<HTMLElement>) => void,
    item: T
  ) => {
    if (!handler) {
      return undefined;
    }

    return (event: React.MouseEvent<HTMLElement>) => {
      handler(item, event);
    };
  };

  return (
    <div
      className={styles.table}
      style={{
        gridAutoRows: rowHeight,
        height: gridHeight,
      }}
    >
      {items?.slice(0, maxRows)?.map((row, index) => {
        const rowModifiers = getRowModifiers?.({ row });

        return (
          <Row<T>
            key={index}
            item={row}
            href={getItemHref(row)}
            selected={isSelected?.(row)}
            onClick={bindItemHandler(onItemClick, row)}
            onDoubleClick={bindItemHandler(onItemDoubleClick, row)}
            modifiers={rowModifiers}
            style={{
              gridTemplateColumns,
            }}
          >
            {columns.map((column) => {
              const cellParams = getCellParams({
                row,
                column,
                getCellModifiers,
              });

              return <Cell key={column.field} {...cellParams} />;
            })}
          </Row>
        );
      })}
    </div>
  );
}

function getCellParams<T>({
  row,
  column,
  getCellModifiers,
}: {
  row: T;
  column: ICardWidgetColumnDef<T>;
  getCellModifiers: IGetCellModifiers<T>;
}) {
  const { field, align, primary, renderCell, type, modifiers } = column;

  const value = get(row, column.field);

  return {
    field,
    type,
    primary,
    align,
    value,
    modifiers: getCellModifiers
      ? getCellModifiers?.({ row, column })
      : modifiers,
    children: renderCell?.({ row, value }),
  };
}
