import { IFieldData, IFieldParam } from '@work4all/models/lib/DataProvider';

const genParams = (params?: IFieldParam[]) => {
  let str = '';

  if (!params || !params.length) {
    return str;
  }

  str += '(';
  str += params
    .map((p) => {
      return `${p.name}: $${p.alias ? p.alias : p.name}`;
    })
    .join(', ');
  str += ')';
  return str;
};

export const genFieldName = (field: IFieldData) => {
  const { name, alias, params } = field;
  const n = alias ? `${alias}: ${name}` : name;
  return n + genParams(params);
};
