import GroupWorkIcon from '@mui/icons-material/GroupWork';
import { IconButton } from '@mui/material';

import { BaseActionProps } from '../types';

export const Group: React.FC<BaseActionProps> = (props) => {
  return (
    // @ts-expect-error TECH_DEBT
    <IconButton color="primary" size="medium" {...props}>
      <GroupWorkIcon />
    </IconButton>
  );
};
