import styles from './NavSubgroup.module.scss';

import Divider from '@mui/material/Divider';

import {
  EventType,
  sendAmplitudeData,
} from '@work4all/components/lib/utils/amplitude/amplitude';
import { SearchTextResult } from '@work4all/components/lib/utils/search-text-result/SearchTextResult';

import { useIsFavoriteLink } from '../../../containers/more/is-favorite-link-context';
import {
  MatchedNavigationSubgroup,
  NavigationLink,
} from '../../../containers/more/types';
import { NavItem } from '../nav-item/NavItem';

export interface INavSubgroupProps {
  showAll: boolean;
  subgroup: MatchedNavigationSubgroup;
  onItemFavoriteChange?: (item: NavigationLink, favorite: boolean) => void;
}

export function NavSubgroup(props: INavSubgroupProps) {
  const { showAll, subgroup, onItemFavoriteChange } = props;

  const isFavoriteLink = useIsFavoriteLink();

  return (
    <>
      {subgroup.name ? (
        <Divider className={styles.name}>
          {subgroup.result ? (
            <SearchTextResult result={subgroup.result} />
          ) : (
            subgroup.name
          )}
        </Divider>
      ) : null}

      <ul className={styles.list}>
        {subgroup.links.map((link) => {
          // The `result` can be `undefined` if the search string is empty and
          // nothing was matched at all. But this should not be an issue here.
          // When `showAll` is true, the if conditions short circuits. When it
          // is false, it means the links were matched, so the `result`
          // properties should not be undefined here.
          if (!showAll && !subgroup.result.match && !link.result.match) {
            return null;
          }

          const isFavorite = isFavoriteLink(link.href);

          return (
            <li key={link.name}>
              <NavItem
                link={link}
                favorite={isFavorite}
                onClick={() =>
                  sendAmplitudeData(EventType.TabElementControlCenter, {
                    name: link.name,
                  })
                }
                onFavoriteChange={() =>
                  onItemFavoriteChange?.(link, !isFavorite)
                }
              />
            </li>
          );
        })}
      </ul>
    </>
  );
}
