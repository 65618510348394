import { isDev } from './isDev';

export function warning(msg: string) {
  if (isDev()) {
    const error = new Error(msg);
    Error.captureStackTrace?.(error, warning);
    throw error;
  } else {
    console.error(msg);
  }
}
