import { Theme, useMediaQuery } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';
import { ErpPreviewContainer } from '@work4all/components/lib/components/entity-preview/erp-preview/ErpPreviewContainer';

import { Offer } from '@work4all/models/lib/Classes/Offer.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { OnOpenMask } from '../../../containers/mask-overlays/mask-overlay';
import { PersonalAndGeneralView } from '../../filters/PersonalAndGeneralView';
import { PreviewMobileWrapper } from '../PreviewMobileWrapper';
import schema from '../schemata/offer-table-schema.json';
import { Table } from '../table/Table';
import { TableLayoutContext, useTableLayoutState } from '../table-layout';
import { TableNoRowsPlaceholder } from '../TableNoRowsPlaceholder';
import { useDataTable } from '../use-data-table';
import { useDeleteEntitiesToolbarConfig } from '../use-delete-entities-toolbar-config';
import { useErpTableMaskHandlers } from '../use-erp-table-mask-handlers';
import { useUserColumnConfigs } from '../use-user-column-configs';

import { useCloneConvertErp } from './hooks/use-clone-convert-erp';

interface Props {
  prefilter?: unknown[];
  onOpenMask: OnOpenMask;
}

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];
const manualGroupBy = true;

export const OffersTable = React.forwardRef<TableInstance, Props>(
  function OffersTable(props, ref) {
    const { prefilter, onOpenMask } = props;
    const { entity: entityType } = schema as never;

    const tableStateBag = useTableStateBag();

    const layoutState = useTableLayoutState();
    const [layout] = layoutState;

    const {
      columnConfigs,
      cardConfig,
      prepareRowDisplayModifiers,
      data,
      footerData,
      fetchMore,
      total,
      selectedEntity,
      initialSortBy,
      pending,
    } = useDataTable<Offer, never>({
      layout,
      schema: schema as never,
      tableStateBag,
      prefilter,
      defaultSort,
      enableFooter: true,
    });

    const maskHandlers = useErpTableMaskHandlers(entityType, onOpenMask);

    const deleteConfig = useDeleteEntitiesToolbarConfig({
      entityType,
    });

    const [userConfig, userConfigMethods] = useUserColumnConfigs({
      layout,
      entityType,
      columnConfigs,
    });

    const isDesktop = useMediaQuery<Theme>((theme) =>
      theme.breakpoints.up('sm')
    );

    const rowModifiers = useCallback(
      (value: Offer) => {
        const modifiers = prepareRowDisplayModifiers(value);
        return {
          ...modifiers,
          isFaded: value.isClosed && value.frozen,
          isShade1: !value.isClosed && value.frozen,
        };
      },
      [prepareRowDisplayModifiers]
    );

    const { t } = useTranslation();

    const { actions, component } = useCloneConvertErp({
      offers: useMemo(
        () =>
          tableStateBag.tableInstance?.selectedFlatRows.map(
            (x) => x.original as Offer
          ),
        [tableStateBag]
      ),
    });

    const custom = useMemo(
      () => ({
        left: actions,
      }),
      [actions]
    );

    if (!userConfig) return null;

    return (
      <TableLayoutContext value={layoutState}>
        {component}
        <Table
          pending={pending}
          noRowsRenderer={() => <TableNoRowsPlaceholder />}
          layout={layout}
          cardConfig={cardConfig}
          ref={ref}
          areas={{
            left: {
              content: <PersonalAndGeneralView entity={Entities.offer} />,
              resizable: true,
              collapseConfig: {
                title: t('COMMON.FILTER'),
              },
            },
            right: selectedEntity !== null && {
              content: (
                <PreviewMobileWrapper
                  active={!isDesktop}
                  actions={{
                    edit: maskHandlers.edit,
                    remove: deleteConfig,
                    /** Disabling temporarily for hotfix
                     * https://work4all.atlassian.net/browse/WW-3998?atlOrigin=eyJpIjoiMDY0ZWQ3ODkxMDFkNGEwYmIwOThhYzI5M2I4Zjg3ZDUiLCJwIjoiaiJ9
                     */
                    // custom,
                  }}
                >
                  <ErpPreviewContainer
                    entityId={selectedEntity.id}
                    entity={entityType}
                    onCloseClicked={() =>
                      tableStateBag.tableInstance.toggleAllRowsSelected(false)
                    }
                    onEditClicked={maskHandlers.edit?.handler}
                    onShareClicked={maskHandlers.share}
                    /** Disabling temporarily for hotfix
                     * https://work4all.atlassian.net/browse/WW-3998?atlOrigin=eyJpIjoiMDY0ZWQ3ODkxMDFkNGEwYmIwOThhYzI5M2I4Zjg3ZDUiLCJwIjoiaiJ9*/
                    // customActions={isDesktop ? custom : undefined}
                  />
                </PreviewMobileWrapper>
              ),
            },
          }}
          actions={{
            add: maskHandlers.create,
            edit: maskHandlers.edit,
            remove: deleteConfig,
            resetColumns: userConfigMethods.remove,
            createWidget: true,
            /** Disabling temporarily for hotfix
             * Ticket:
             * https://work4all.atlassian.net/browse/WW-3998?atlOrigin=eyJpIjoiMDY0ZWQ3ODkxMDFkNGEwYmIwOThhYzI5M2I4Zjg3ZDUiLCJwIjoiaiJ9*/
            // custom,
          }}
          columnConfigs={userConfig}
          manualGroupBy={manualGroupBy}
          initialSortBy={initialSortBy}
          loadMoreItems={fetchMore}
          prepareRowDisplayModifiers={rowModifiers}
          displayFooter
          footerData={footerData}
          data={data}
          total={total}
        />
      </TableLayoutContext>
    );
  }
);
