import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import germanBaseTrans from './translation-gen/de/de-base.json';
import englishBaseTrans from './translation-gen/en/en-base.json';

const germanTrans = {
  ...germanBaseTrans,
};

const englishTrans = {
  ...englishBaseTrans,
};

export const loadI18n = () => {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
      resources: {
        de: {
          translation: germanTrans,
        },
        en: {
          translation: englishTrans,
        },
      },
      fallbackLng: ['de'],
      supportedLngs: ['de', 'en'],
      interpolation: {
        escapeValue: false,
      },
      detection: {
        order: ['localStorage', 'navigator'],
      },
    });
};
