import { useEffect } from 'react';

import { SettingsContextProvider } from './context';
import { Settings } from './types';
import { useSettings } from './use-settings';

export interface SettingsProviderProps {
  children?: React.ReactNode;
  listener: (val: Settings) => void;
}

export function SettingsProvider(props: SettingsProviderProps) {
  const { listener } = props;
  const context = useSettings();

  useEffect(() => {
    const unsubscribe = context.subscribe(() => {
      listener(context.getValue());
    });
    return unsubscribe;
  }, [context, listener]);

  // Do not render children while the settings are loading to avoid FOC.
  // Could later replace this with a loading animation instead.
  if (context.isLoading) {
    return null;
  }

  return (
    <SettingsContextProvider value={context}>
      {props.children}
    </SettingsContextProvider>
  );
}
