import styles from './ConfirmationDialog.module.scss';

import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BaseActionButton } from '../../input/base-action-button/BaseActionButton';
import { Body2 } from '../../typography/body2/Body2';
import { Heading4 } from '../../typography/heading4/Heading4';

export const ConfirmationDialog: React.FC<{
  open: boolean;
  handleClose: () => void;
  agree: () => void;
  cancel: () => void;
  reason?: string;
}> = (props) => {
  const { t } = useTranslation();
  return (
    <Dialog open={props.open} onClose={props.cancel}>
      <Title onClick={props.cancel}>{t('ALERTS.UNSAVED_CHANGES_TITLE')}</Title>
      <ContentText>{props.reason || t('ALERTS.UNSAVED_CHANGES')}</ContentText>
      <DialogActions className={styles.actions}>
        <BaseActionButton
          title={t('ALERTS.CONTINUE_EDITING')}
          onClick={props.cancel}
          color="primary"
          className={styles.button}
          autoFocus
        />

        <BaseActionButton
          title={t('ALERTS.DISCARD')}
          onClick={props.agree}
          color="primary"
          className={styles.button}
        />
      </DialogActions>
    </Dialog>
  );
};

const Title = (props) => {
  const { children, onClick } = props;
  return (
    <DialogTitle className={styles.noPadding}>
      <div className={styles.wrap}>
        <div className={styles.title}>
          <Heading4>{children}</Heading4>
        </div>
        {/* {onClose ? ( */}
        <IconButton aria-label="close" onClick={onClick} size="large">
          <CloseIcon />
        </IconButton>
        {/* ) : null} */}
      </div>
    </DialogTitle>
  );
};

const ContentText = (props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogContentText className={styles.content}>
      <Body2 component="span">{children}</Body2>
    </DialogContentText>
  );
};
