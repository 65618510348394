import { createContext, useContext } from 'react';

import { TableContextMenuActions } from './types';

export interface TableContextMenuContextValue {
  actions: TableContextMenuActions;
}

const Context = createContext<TableContextMenuContextValue | null>(null);

export const TableContextMenuContextProvider = Context.Provider;

export function useTableContextMenuContext(): TableContextMenuContextValue {
  const context = useContext(Context);

  if (context === null) {
    throw new Error(
      'useTableContextMenuContext must be used within a <TableContextMenuContextProvider>'
    );
  }

  return context;
}
