import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input';

import { reactRefSetter } from '@work4all/utils';

import { MailboxTypeOption } from './MailboxTypeOption';
import { MailboxTypePicker, MailboxTypePickerProps } from './MailboxTypePicker';

interface Props extends MailboxTypePickerProps {
  error?: string;
  label?: string;
  required?: boolean;
}

export const MailboxTypePickerField = React.forwardRef<HTMLDivElement, Props>(
  function MailboxTypePickerField(props, forwardedRef) {
    const { error, label, required, ...pickerProps } = props;
    const { value } = pickerProps;

    const { t } = useTranslation();

    const ref = useRef();

    return (
      <div ref={reactRefSetter(ref, forwardedRef)}>
        <EntityPickerPopover
          anchorEl={ref}
          picker={<MailboxTypePicker {...pickerProps} />}
        >
          <LabeledInput
            label={label || t('COMMON.POST_OFFICE_BOX')}
            error={error}
            required={required}
            value={<MailboxTypeOption value={value} />}
          />
        </EntityPickerPopover>
      </div>
    );
  }
);
