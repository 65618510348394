import styles from './DocumentPreview.module.scss';

import { Delete, Fullscreen } from '@mui/icons-material';
import ShareIcon from '@mui/icons-material/Share';
import { IconButton } from '@mui/material';
import i18next from 'i18next';
import { MouseEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DownloadIcon } from '@work4all/assets/icons/download.svg';
import { ReactComponent as EditIcon } from '@work4all/assets/icons/edit.svg';
import { ReactComponent as EditCopyIcon } from '@work4all/assets/icons/edit-pdf.svg';
import { ReactComponent as LinkIcon } from '@work4all/assets/icons/link.svg';

import { useAuthHeaders } from '@work4all/data/lib/auth/use-auth-headers';
import {
  downloadAuthed,
  parseNameAndExtension,
} from '@work4all/data/lib/utils';

import { useToolbarCustomActionsComponents } from '../../../dataDisplay/actions-bar/hooks/use-toolbar-custom-actions';
import { CustomToolbar } from '../../../dataDisplay/actions-bar/ToolbarTypes';
import { PDFTextmarkConf } from '../../../preview/pdf-textmarks/PDFTextmarkContainer';
import { IPreviewProps, MIME_TYPES, Preview } from '../../../preview/Preview';
import { Tooltip } from '../../tooltip/Tooltip';
import { PreviewTitle } from '../components';

export interface IDocumentPreviewProps
  extends Pick<IPreviewProps, 'iconProps'> {
  title: string;
  url: string;
  filePath?: string;
  downloadUrl?: string;
  noPreviewUrl?: string;
  mimeType: MIME_TYPES;
  fspUrl?: string;
  exists?: boolean;
  onCloseClicked?: MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onPdfEditClicked?: MouseEventHandler<HTMLButtonElement>;
  navigation?: JSX.Element;
  onShareClicked?: () => void;
  onDeleteClicked?: MouseEventHandler<HTMLButtonElement>;
  openInFullscreen?: boolean;
  erpPreview?: boolean;
  showFullscreenButton?: boolean;
  customActions?: CustomToolbar;
  subtitleComp?: React.ReactNode;
  pdfTextmarkConf?: PDFTextmarkConf;
  additionalTitleItems?: React.ReactNode;
}

export function DocumentPreview(props: IDocumentPreviewProps) {
  const {
    title,
    url,
    noPreviewUrl,
    downloadUrl,
    filePath,
    mimeType,
    onCloseClicked,
    onEditClicked,
    onDeleteClicked,
    onPdfEditClicked,
    fspUrl,
    exists,
    navigation,
    iconProps,
    onShareClicked,
    openInFullscreen,
    erpPreview,
    showFullscreenButton = true,
    customActions: custom,
    subtitleComp,
    pdfTextmarkConf,
    additionalTitleItems,
  } = props;
  const [detailInFullsceen, setFullscreen] = useState(false);

  const isLinkable =
    typeof filePath === 'string' &&
    (filePath.startsWith('https') ||
      filePath.startsWith('http') ||
      filePath.startsWith('www'));

  const mimeTypeValues = Object.values(MIME_TYPES);

  const canPreview =
    showFullscreenButton && mimeTypeValues.includes(mimeType) ? true : false;

  useEffect(() => {
    if (openInFullscreen) {
      setFullscreen(true);
    }
  }, [openInFullscreen]);

  const toolbar = useToolbarCustomActionsComponents({
    custom,
    skipMode: true,
  });

  const { t } = useTranslation();
  const httpHeaders = useAuthHeaders();

  return (
    <div className={styles.wrapper}>
      <PreviewTitle
        onCloseClicked={(e) => {
          setFullscreen(false);
          //eslint-disable-next-line
          //@ts-expect-error type not assignable to parameter 'MouseEvent<HTMLButtonElement, MouseEvent>'
          onCloseClicked(e);
        }}
        onShareClicked={onShareClicked}
        actions={
          <>
            {additionalTitleItems}
            {onPdfEditClicked ? (
              <Tooltip title={t('COMMON.EDIT_COPY')}>
                <IconButton
                  color="primary"
                  onClick={onPdfEditClicked}
                  className={styles['fileheader-icon']}
                  size="large"
                >
                  <EditCopyIcon />
                </IconButton>
              </Tooltip>
            ) : null}
            {toolbar.left}
            {onShareClicked && (
              <IconButton size="large" color="primary" onClick={onShareClicked}>
                <ShareIcon />
              </IconButton>
            )}
            {onEditClicked ? (
              <Tooltip title={t('COMMON.EDIT')}>
                <IconButton
                  color="primary"
                  onClick={onEditClicked}
                  className={styles['fileheader-icon']}
                  size="large"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            ) : null}

            {onDeleteClicked ? (
              <Tooltip title={t('COMMON.DELETE')}>
                <IconButton
                  color="primary"
                  onClick={onDeleteClicked}
                  className={styles['fileheader-icon']}
                  size="large"
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            ) : null}

            {canPreview && (
              <IconButton color="primary" onClick={() => setFullscreen(true)}>
                <Fullscreen />
              </IconButton>
            )}

            {!erpPreview ? (
              !canPreview &&
              exists && (
                <IconButton color="primary" href={fspUrl} download>
                  <DownloadIcon />
                </IconButton>
              )
            ) : downloadUrl ? (
              <IconButton
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  downloadAuthed(
                    downloadUrl,
                    `${isLinkable ? filePath : title}.${
                      parseNameAndExtension(filePath).extension
                    }`,
                    httpHeaders
                  );
                }}
              >
                <DownloadIcon />
              </IconButton>
            ) : null}

            {iconProps?.showPreviewExternallyIcon !== false &&
            ((filePath && isLinkable) || fspUrl) ? (
              <IconButton
                color="primary"
                href={filePath && isLinkable ? filePath : fspUrl}
                target="_blank"
              >
                <LinkIcon />
              </IconButton>
            ) : null}
            {navigation ? navigation : null}
            {toolbar.right}
          </>
        }
      >
        {title}
      </PreviewTitle>
      {subtitleComp}
      <Preview
        onFullscreenClose={() => {
          setFullscreen(false);
          if (openInFullscreen) onCloseClicked(null);
        }}
        openInFullscreen={detailInFullsceen}
        onDeleteClicked={onDeleteClicked}
        onEditClicked={onEditClicked}
        url={isLinkable ? filePath : url}
        mimeType={isLinkable ? MIME_TYPES.urllist : mimeType}
        fileName={isLinkable ? filePath : title}
        filePath={filePath}
        exists={exists}
        fspUrl={fspUrl}
        downloadProps={
          filePath || downloadUrl
            ? {
                url: downloadUrl,
                filePath: filePath,
              }
            : undefined
        }
        noPreview={
          !exists
            ? i18next.t('MASK.NO_PREVIEWFILE')
            : i18next.t('MASK.NO_PREVIEW_DOWNLOAD')
        }
        noPreviewUrl={noPreviewUrl}
        isLinkable={isLinkable}
        iconProps={iconProps}
        pdfTextmarkConf={pdfTextmarkConf}
      />
    </div>
  );
}
