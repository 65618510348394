import styles from './Table.module.scss';

import { LinearProgress, Theme, useMediaQuery } from '@mui/material';
import Divider from '@mui/material/Divider';
import clsx from 'clsx';
import React, { useContext, useMemo, useRef } from 'react';
import { TableInstance, TableState } from 'react-table';

import {
  BasicTable,
  BasicTableColumn,
  DataType,
  DateCell,
  FilterType,
  IBasicTableProps,
} from '@work4all/components/lib/dataDisplay/basic-table';
import {
  ColumnVisibilityContext,
  useColumnVisibilityManager,
} from '@work4all/components/lib/dataDisplay/basic-table/hooks/useColumnVisibility';
import {
  ICssClasses,
  TableRow,
} from '@work4all/components/lib/dataDisplay/basic-table/types';
import { useLockObjectColumn } from '@work4all/components/lib/hooks/object-lock-subscription/useLockObjectColumn';
import { PrintComponent } from '@work4all/components/lib/layout/print-component/PrintComponent';

import { CardConfig } from '@work4all/models/lib/table-schema/card-config';
import { AreaConfig } from '@work4all/models/lib/Tables/Tables';

import { reactRefSetter } from '@work4all/utils/lib/reactRefSetter';

import { ListPageContext } from '../../../containers/file-entities-lists/list-page-context';
import { settings, useSetting } from '../../../settings';
import { ControlViewBottom } from '../control-view-bottom/ControlViewBottom';
import { ControlViewLeft } from '../control-view-left/ControlViewLeft';

import { TableContextMenu } from './components/context-menu/TableContextMenu';
import { TableRightArea } from './components/table-right-area/TableRightArea';
import { IToolBar, Toolbar } from './components/toolbar/ToolBar';
import { DataTableColumnConfig } from './DataTableColumnConfig';
import { TableFilter } from './TableFilter';

interface Areas {
  left?: AreaConfig;
  right?: AreaConfig;
  bottom?: AreaConfig;
  center?: AreaConfig;
  top?: AreaConfig;
}

export type ITableProps = Pick<
  IBasicTableProps,
  | 'data'
  | 'loadMoreItems'
  | 'isItemLoaded'
  | 'onRowExpanded'
  | 'prepareRowDisplayModifiers'
  | 'displayFooter'
  | 'footerData'
  | 'onSelectedRowsChange'
  | 'selectableMultiple'
  | 'initialSortBy'
  | 'pending'
  | 'noRowsRenderer'
  | 'draggable'
  | 'scrollRef'
  | 'rowHeightRem'
> &
  Pick<IToolBar, 'actions'> & {
    noLockableEntity?: boolean;
    cardConfig?: CardConfig;
    columnConfigs: DataTableColumnConfig[];
    total: IBasicTableProps['allItemsCount'];
    loadGroups?: (state: TableState, instance: TableInstance) => void;
    detailView?: JSX.Element;
    manualGroupBy?: boolean;
    areas?: Areas;
    forceRequestFields?;
    /**
     * @default "table"
     */
    layout?: 'table' | 'cards';
    onRowDoubleClick?: (id: string | number) => void;
    hideToolbar?: boolean;
    classes?: {
      root: string;
    };
    onRowClick?: (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      row: TableRow
    ) => void;

    additionalColumns?: DataTableColumnConfig[];
    basicClasses?: ICssClasses;
  };

const supportedFilters = [
  FilterType.ClosedStatus,
  FilterType.Date,
  FilterType.Search,
  FilterType.Picker,
  FilterType.EmailKind,
  FilterType.VacationKind,
  FilterType.Boolean,
  FilterType.Number,
  FilterType.TicketStatus,
  FilterType.TravelReceiptStatus,
  FilterType.TaskStatus,
  FilterType.EInvoiceFormat,
  FilterType.ObjectType,
  FilterType.SalesOpportunitiesGroupPicker,
  FilterType.SalesOpportunitiesStatusPicker,
  FilterType.ChronoContactPicker,
  FilterType.Check,
  FilterType.RequiredTime,
];
const isDate = (cfg: DataTableColumnConfig) => {
  return cfg.dataType === DataType.Date || cfg.filterType === FilterType.Date;
};

export const Table = React.forwardRef<TableInstance, ITableProps>(
  function Table(props, ref) {
    const {
      layout = 'table',
      noLockableEntity = false,
      hideToolbar = false,
    } = props;

    const isMdUp = useMediaQuery<Theme>((t) => t.breakpoints.up('md'));
    const isLgUp = useMediaQuery<Theme>((t) => t.breakpoints.up('xl'));

    const collapsedAreas = useSetting(settings.tableCollapsedAreas());

    const tableInstanceRef = useRef<TableInstance>();

    const listPageContext = useContext(ListPageContext);

    const columnConfigs = props.columnConfigs;
    const columnsCache = useMemo<{
      columns: IBasicTableProps['columns'];
      defaultHiddenColumnids: string[];
    }>(() => {
      const defaultHiddenColumnids = [];

      const columns = columnConfigs.map((cfg) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const accessor: any = cfg.accessor;
        const col: BasicTableColumn = {
          Header: cfg.title,
          Filter: (tableData) => <TableFilter column={tableData.column} />,
          disableFilters:
            cfg.disableFilters ||
            supportedFilters.indexOf(cfg.filterType) === -1,
          accessor: accessor,
          //handle hoverstates
          headerClass: styles.headerClass,

          isPrimaryFilter: cfg.isPrimaryFilter,
          filterSubgroupPath: cfg.columnSubGroupPath,
          filterParams: cfg.filterParams,
          quickSearchable: cfg.quickSearchable,

          disableSortBy: cfg.disableSortBy,
          disableGroupBy: cfg.disableGroupBy,
          disableColumnVisibility: cfg.disableColumnVisibility ?? false,

          sticky: cfg.sticky,
          order: cfg.order,
          required: cfg.required,
        };

        //these properties imply a different behaviour in react table if undefined, therefore check for their existence first instead of a blind set
        if (cfg.filterType) {
          col.filterType = cfg.filterType;
        }
        if (cfg.useFilterData) {
          col.useFilterData = cfg.useFilterData;
        }

        if (cfg.Cell) {
          col.Cell = cfg.Cell;

          if (cfg.cellParams) {
            col.cellParams = cfg.cellParams;
          }
        }

        if (cfg.Footer) {
          col.Footer = cfg.Footer;
        }

        if (cfg.aggregate) {
          col.aggregate = cfg.aggregate;
        }

        if (cfg.Aggregated) {
          col.Aggregated = cfg.Aggregated;
        } else if (isDate(cfg)) {
          col.Aggregated = DateCell;
        }

        if (cfg.width !== undefined) {
          col.width = cfg.width;
        }
        if (cfg.minWidth !== undefined) {
          col.minWidth = cfg.minWidth;
        }
        if (cfg.maxWidth !== undefined) {
          col.maxWidth = cfg.maxWidth;
        }
        if ('disableResizing' in cfg) {
          col.disableResizing = cfg.disableResizing;
        }
        if (cfg.id) {
          col.id = cfg.id;
        }
        if (cfg.defaultHidden) {
          defaultHiddenColumnids.push(cfg.id || cfg.accessor);
        }
        if (cfg.dataType) {
          col.dataType = cfg.dataType;
        }
        return col;
      });

      return {
        columns,
        defaultHiddenColumnids,
      };
    }, [listPageContext, columnConfigs]);

    const leftArea = props.areas?.left;
    const rightArea = props.areas?.right;
    const bottomArea = props.areas?.bottom;
    const centerArea = props.areas?.center;
    const topArea = props.areas?.top;

    const columnsVisibilityManager = useColumnVisibilityManager(
      tableInstanceRef.current
    );

    const { data } = useLockObjectColumn(
      props.data,
      columnsVisibilityManager,
      noLockableEntity
    );

    const tablesLayoutBorders = useSetting(settings.tablesLayoutBorders());
    const isLeftAreaCollapsed =
      collapsedAreas.value.left[listPageContext?.entityType] ?? true;
    return (
      <ColumnVisibilityContext.Provider value={columnsVisibilityManager}>
        <PrintComponent>
          {props.pending && <LinearProgress className={styles.progressBar} />}
          <div
            className={clsx(styles.root, props.classes?.root)}
            data-test-id="table"
          >
            <div className={styles.right}>
              {!hideToolbar && (
                <Toolbar
                  mobile={!isLgUp}
                  tableInstanceRef={tableInstanceRef}
                  actions={props.actions}
                />
              )}
              <Divider orientation="horizontal" />
              <div className={styles.rightRoot}>
                {leftArea && isMdUp ? (
                  <>
                    <ControlViewLeft
                      resizable={leftArea?.resizable}
                      collapseConfig={
                        leftArea?.collapseConfig
                          ? {
                              title: leftArea.collapseConfig.title,
                              isCollapsed: isLeftAreaCollapsed,
                              onCollapsedChanged: () => {
                                const newValue = {
                                  ...collapsedAreas.value.left,
                                };
                                newValue[listPageContext?.entityType] =
                                  !isLeftAreaCollapsed;

                                collapsedAreas.set({
                                  ...collapsedAreas.value,
                                  left: newValue,
                                });
                              },
                            }
                          : undefined
                      }
                    >
                      {leftArea.content}
                    </ControlViewLeft>
                    <Divider orientation="vertical" />
                  </>
                ) : null}
                <div
                  className={clsx(styles.rightBody, {
                    [styles['rightBody--split']]: !!bottomArea,
                    [styles['rightBody--horizontal-split']]: !!centerArea,
                  })}
                >
                  <TableContextMenu actions={props.actions}>
                    {({ onRowContextMenu }) => {
                      return (
                        <div className={styles.tableWrapper}>
                          {topArea ? topArea.content : null}
                          <BasicTable
                            pending={props.pending}
                            noRowsRenderer={props.noRowsRenderer}
                            entity={listPageContext?.entityType}
                            mode="server"
                            cardsView={layout === 'cards'}
                            bottomPadding={isLgUp ? '5rem' : null}
                            cardConfig={props.cardConfig}
                            ref={reactRefSetter(ref, tableInstanceRef)}
                            reordableColumns={true}
                            resizableColumns={true}
                            selectableRows={true}
                            selectableMultiple={props.selectableMultiple}
                            onSelectedRowsChange={props.onSelectedRowsChange}
                            columns={columnsCache.columns}
                            defaultHidden={columnsCache.defaultHiddenColumnids}
                            data={data}
                            allItemsCount={props.total}
                            loadMoreItems={props.loadMoreItems}
                            loadGroups={props.loadGroups}
                            manualGroupBy={props.manualGroupBy}
                            initialSortBy={props.initialSortBy}
                            isItemLoaded={props.isItemLoaded}
                            onRowExpanded={props.onRowExpanded}
                            className={styles.table}
                            onRowDoubleClick={
                              props.onRowDoubleClick ||
                              props.actions?.edit?.handler
                            }
                            onRowClick={props.onRowClick}
                            onRowContextMenu={onRowContextMenu}
                            prepareRowDisplayModifiers={
                              props.prepareRowDisplayModifiers
                            }
                            displayFooter={props.displayFooter}
                            footerData={props.footerData}
                            draggable={props.draggable}
                            classes={{
                              ...props.basicClasses,
                              cell: clsx({
                                [styles.borderVertical]:
                                  tablesLayoutBorders.value.vertical,
                              }),
                              row: clsx({
                                [styles.borderHorizontal]:
                                  tablesLayoutBorders.value.horizontal,
                              }),
                            }}
                            scrollRef={props.scrollRef}
                            rowHeightRem={props.rowHeightRem}
                          />
                        </div>
                      );
                    }}
                  </TableContextMenu>

                  {centerArea ? centerArea.content : null}
                  {bottomArea && (
                    <ControlViewBottom>{bottomArea.content}</ControlViewBottom>
                  )}
                </div>
                <TableRightArea rightArea={rightArea} />
              </div>
            </div>
          </div>
        </PrintComponent>
      </ColumnVisibilityContext.Provider>
    );
  }
);
