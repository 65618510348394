import styles from './FiltersOverlayStartPage.module.scss';

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';

import { FilterOverlayContext } from '../../FiltersOverlay';
import { FilterCardList } from '../filter-card-list/FilterCardList';
import { FilterSubGroupList } from '../filter-sub-group-list/FilterSubGroupList';

export const FiltersOverlayStartPage: React.FC = () => {
  const { additionalFilters: activeFilters } = useContext(FilterOverlayContext);

  const { t } = useTranslation();

  return (
    <div className={styles.content}>
      <FilterCardList cols={activeFilters} />

      <div className={styles.secondaryFiltersWrap}>
        <Divider title={t('COMMON.ADD_FILTER')}></Divider>
      </div>
      <FilterSubGroupList subgroupPath={[]} />
    </div>
  );
};
