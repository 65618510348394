import { useEffect, useState } from 'react';

import { useMaskTabContext } from './MaskTabContext';

interface MaskTabVisibilityValues {
  visitedTabs: string[];
}

export const MaskTabVisibility = ({
  children,
}: {
  children: (props: MaskTabVisibilityValues) => JSX.Element;
}) => {
  const masktabContext = useMaskTabContext();

  const [visitedTabs, setVisitedTabs] = useState([]);

  useEffect(() => {
    if (!visitedTabs.includes(masktabContext.value)) {
      setVisitedTabs((x) => [...x, masktabContext.value]);
    }
  }, [visitedTabs, setVisitedTabs, masktabContext.value]);

  return children({ visitedTabs });
};
