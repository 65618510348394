import React, { useContext, useEffect, useMemo, useState } from 'react';

import { useDataProvider, useSecureBlobUrl } from '@work4all/data';
import { useRemToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { User } from '@work4all/models/lib/Classes/User.entity';
import { DataRequest, SortDirection } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { IUserIconRegisterContext, UserIconRegisterProps } from './types';
import { IUserIcon, UserIcon } from './UserIcon';
import { withLocker, WithLockerProps } from './withLocker';

const userColorDefinitions: Pick<
  UserIconRegisterProps,
  'shade' | 'fontColor'
>[] = [
  {
    shade: 'userShade1',
    fontColor: 'text01',
  },
  {
    shade: 'userShade2',
    fontColor: 'textInverse',
  },
  {
    shade: 'userShade3',
    fontColor: 'text01',
  },
  {
    shade: 'userShade4',
    fontColor: 'text01',
  },
  {
    shade: 'userShade5',
    fontColor: 'text01',
  },
  {
    shade: 'userShade6',
    fontColor: 'textInverse',
  },
  {
    shade: 'userShade7',
    fontColor: 'text01',
  },
  {
    shade: 'userShade8',
    fontColor: 'textInverse',
  },
  {
    shade: 'userShade9',
    fontColor: 'text01',
  },
];

const USER_DATA: User<EMode.query> = {
  id: null,
  shortName: null,
  firstName: null,
  lastName: null,
  avatarUrl: null,
};

export function useUserIconRegister(): IUserIconRegisterContext {
  const requestData = useMemo<DataRequest>(() => {
    return {
      entity: Entities.user,
      data: USER_DATA,
      sort: [
        {
          field: 'id',
          direction: SortDirection.ASCENDING,
        },
      ],
      vars: {
        querySize: 99999,
        queryPage: 0,
      },
    };
  }, []);

  const { data: users } = useDataProvider<User>(requestData);

  const result = useMemo<IUserIconRegisterContext>(() => {
    const result: IUserIconRegisterContext = { register: {} };

    users.forEach((userItem) => {
      result.register[userItem.id] = {
        ...userColorDefinitions[userItem.id % userColorDefinitions.length],
        avatarUrl: userItem.avatarUrl,
        shortName:
          userItem.shortName?.trim()?.length > 0
            ? userItem.shortName
            : userItem?.firstName?.charAt(0) + userItem?.lastName?.charAt(0),
      };
    });

    return result;
  }, [users]);

  return result;
}

export const UserIconRegisterContext =
  React.createContext<IUserIconRegisterContext | null>(null);

export const UserIconProvider: React.FC<{ isLoggedIn: boolean }> = (props) => {
  const [userReady, setUserReady] = useState(props.isLoggedIn);
  useEffect(() => {
    setUserReady(props.isLoggedIn);
  }, [props.isLoggedIn]);

  if (userReady) {
    return <LoggedInRegistry>{props.children}</LoggedInRegistry>;
  }
  return (
    <UserIconRegisterContext.Provider value={{ register: {} }}>
      {props.children}
    </UserIconRegisterContext.Provider>
  );
};

const LoggedInRegistry = (props) => {
  const t = useUserIconRegister();

  return (
    <UserIconRegisterContext.Provider value={t}>
      {props.children}
    </UserIconRegisterContext.Provider>
  );
};

export interface UserIconProps
  extends WithLockerProps,
    Pick<IUserIcon, 'withBorder'> {
  size?: 'm' | 'l' | 'l-bold' | 'xl' | 'xxl' | 'xxxl';
  /**
   * You can choose between displaying user's avatar or just their initials with
   * a simple background color. Will always fallback to displaying initials if
   * the avatar image can't be loaded.
   *
   * @default "avatar"
   */
  appearance?: 'avatar' | 'initials';
}

const AvatarSizeMap: Record<IUserIcon['size'], number> = {
  m: 1.5,
  l: 2,
  'l-bold': 2,
  xl: 3,
  xxl: 4,
  xxxl: 8,
};

export const HookedUserIconInternal: React.FC<UserIconProps> = (props) => {
  const { userId, size = 'l', withBorder, appearance = 'avatar' } = props;
  const userIconRegister = useContext(UserIconRegisterContext);

  const imageSizeRem = AvatarSizeMap[size];
  const imageSize = useRemToPx(imageSizeRem);

  const user = userIconRegister?.register?.[userId];

  const avatarUrl = useFullAvatarUrl({ url: user?.avatarUrl, size: imageSize });
  const blobUrl = useSecureBlobUrl(avatarUrl);

  return (
    <UserIcon
      shortName={user?.shortName}
      size={size}
      bgColor={`var(--${user?.shade})`}
      fontColor={`var(--${user?.fontColor})`}
      src={appearance === 'avatar' ? blobUrl : undefined}
      withBorder={withBorder}
    />
  );
};

export const HookedUserIcon = withLocker(HookedUserIconInternal);

function getFullAvatarUrl({ url, size }: { url: string; size: number }) {
  if (!url) return null;

  const fullUrl = new URL(url);

  // The resizing in the API makes images too blurry if resized exactly to the
  // size of the rendered element. Request twice the size and let the browser
  // resize it down again. This results in better image quality overall while
  // still fetching less data that the full original image.
  const imageSize = 2 * window.devicePixelRatio * size;

  fullUrl.searchParams.append('newHeight', String(imageSize));
  fullUrl.searchParams.append('newWidth', String(imageSize));
  fullUrl.searchParams.append('useDefaultImage', 'false');

  return fullUrl.toString();
}

function useFullAvatarUrl({ url, size }: { url: string; size: number }) {
  return useMemo(() => {
    return getFullAvatarUrl({ url, size });
  }, [url, size]);
}
