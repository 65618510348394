import { PartialObject, pick } from 'lodash';
import { FieldNamesMarkedBoolean } from 'react-hook-form';

export function pickUpdateFields<T>(
  data: T,
  dirtyFields: FieldNamesMarkedBoolean<T>
): PartialObject<T> {
  return pick(data, ['id', ...keys(dirtyFields)]);
}

function keys<T>(dirtyFields: FieldNamesMarkedBoolean<T>): string[] {
  return Object.keys(dirtyFields)
    .flatMap((key) => {
      const field = dirtyFields[key];

      if (typeof field === 'boolean') {
        if (field === true) {
          return key;
        } else {
          return null;
        }
      } else {
        return keys(field).map((nestedKey) => `${key}.${nestedKey}`);
      }
    })
    .filter(Boolean);
}
