import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputAddressConnectionRelationAdd } from './InputAddressConnectionRelationAdd.entity';

export class InputAddressConnectionRelation<T extends EMode = EMode.entity> {
  /** Alias: add */
  add?: InputAddressConnectionRelationAdd<T>[];
  /** Alias: remove */
  remove?: number[];

  __typename?: string;
}

const fields: FieldDefinitions<InputAddressConnectionRelation> = {
  add: {
    alias: 'add',
  },
  remove: {
    alias: 'remove',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputAddressConnectionRelationEntityDefinition: EntitiyDefinition<InputAddressConnectionRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputAddressConnectionRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
