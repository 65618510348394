import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { BzObject } from './BzObject.entity';
import { Calculation } from './Calculation.entity';
import { Contract } from './Contract.entity';
import { DeliveryNote } from './DeliveryNote.entity';
import { Demand } from './Demand.entity';
import { InboundDeliveryNote } from './InboundDeliveryNote.entity';
import { Invoice } from './Invoice.entity';
import { Offer } from './Offer.entity';
import { Order } from './Order.entity';
import { ValidationMessage } from './ValidationMessage.entity';

export class BzObjectConversionResult<T extends EMode = EMode.entity> {
  /** Alias: bzObject */
  bzObject?: T extends EMode.query
    ? BzObject<T>
    :
        | Calculation<T>
        | Offer<T>
        | Contract<T>
        | Invoice<T>
        | DeliveryNote<T>
        | Demand<T>
        | Order<T>
        | InboundDeliveryNote<T>;
  /** Alias: code */
  id?: number;
  /** Alias: errors */
  errors?: ValidationMessage<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<BzObjectConversionResult> = {
  bzObject: {
    alias: 'bzObject',
    entity: [
      Entities.calculation,
      Entities.offer,
      Entities.contract,
      Entities.invoice,
      Entities.deliveryNote,
      Entities.demand,
      Entities.order,
      Entities.inboundDeliveryNote,
    ],
  },
  id: {
    alias: 'code',
  },
  errors: {
    alias: 'errors',
    entity: Entities.validationMessage,
  },
  __typename: {
    alias: '__typename',
  },
};

export const bzObjectConversionResultEntityDefinition: EntitiyDefinition<BzObjectConversionResult> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'BzObjectConversionResult',
      withPaginationWrapper: false,
      mutations: {
        upsert: {
          mutationName: 'convertBzObject',
          args: [{ name: 'request', type: 'ErpObjectConversionRequest!' }],
        },
      },
    },
    fieldDefinitions: fields,
  };
