import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class Holiday<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: name */
  name?: string;
  /** Alias: datum */
  date?: string;

  __typename?: string;
}

const fields: FieldDefinitions<Holiday> = {
  id: {
    alias: 'code',
  },
  name: {
    alias: 'name',
  },
  date: {
    alias: 'datum',
  },
  __typename: {
    alias: '__typename',
  },
};

export const holidayEntityDefinition: EntitiyDefinition<Holiday> = {
  local: {},
  remote: {
    queryName: 'getPublicHolidays',
    fragmentName: 'Feiertag',
    withPaginationWrapper: false,
    params: [
      { name: 'from', type: 'DateTimeEx!' },
      { name: 'to', type: 'DateTimeEx!' },
      { name: 'allTenants', type: 'Boolean' },
    ],
  },
  fieldDefinitions: fields,
};
