import { useEffect, useRef, useState } from 'react';

export function useSearchKeyHandlers(items) {
  const [keyboardLocation, setKeyboardLocation] = useState(0);
  const selectedElementRef = useRef(null);

  const keyMap = {
    MOVE_UP: ['ArrowUp', 'up'],
    MOVE_DOWN: ['ArrowDown', 'down'],
    NAVIGATE_TO: 'NAVIGATE_TO',
  };

  const keyHandlers = {
    MOVE_UP: () => move(keyboardLocation - 1),
    MOVE_DOWN: () => move(keyboardLocation + 1),
    NAVIGATE_TO: () => enter(),
  };

  useEffect(() => {
    // focusing the first element
    if (items.length > 0 && keyboardLocation === 0) {
      const firstElement = document.getElementById(items[0].id.toString());
      if (firstElement) {
        firstElement.style.backgroundColor = 'var(--ui02)';
        selectedElementRef.current = firstElement;
      }
    }
  }, [items]);

  const move = (index) => {
    // unfocusing the old element
    if (index >= 0 && index < items.length) {
      if (selectedElementRef.current) {
        selectedElementRef.current.style.backgroundColor = 'transparent';
      }

      // focusing & scrolling to the new element
      const newElement = document.getElementById(items[index].id.toString());
      if (newElement) {
        newElement.scrollIntoView({ block: 'nearest' });
        newElement.style.backgroundColor = 'var(--ui02)';
        selectedElementRef.current = newElement;
      }

      //saveing the new location
      setKeyboardLocation(index);
    }
  };

  const enter = () => {
    const element = document.getElementById(
      items[keyboardLocation]?.id.toString()
    );
    element?.click();
  };

  return {
    setKeyboardLocation,
    keyMap,
    keyHandlers,
  };
}
