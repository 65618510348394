import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { GeneralSettings } from '@work4all/models/lib/Classes/GeneralSettings.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export enum GeneralSetting {
  startBusinessYear = 'Beginn Geschäftsjahr',
}

export const useGeneralSetting = (props: { name: string }) => {
  const generalSettingDataRequest: DataRequest = useMemo(() => {
    const data: GeneralSettings = {
      name: null,
      id: null,
      value: null,
      textValue: null,
      isNotVisible: null,
    };
    return {
      completeDataResponse: true,
      entity: Entities.generalSettings,
      data: data,
      vars: {
        name: props.name,
      },
    };
  }, [props.name]);

  return useDataProvider<GeneralSettings>(generalSettingDataRequest);
};
