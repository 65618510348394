import { useCallback, useMemo } from 'react';

import { FixedDataPicker } from '../fixed-data-picker/FixedDataPicker';

export type IClosedStatusPickerProps = {
  value: boolean;
  onChange: (value: boolean) => void;
  trueText: string;
  falseText: string;
};

export function ClosedStatusPicker(props: IClosedStatusPickerProps) {
  const {
    value: valueProp,
    onChange: onChangeProp,
    trueText,
    falseText,
  } = props;

  const dataSet = useMemo(() => {
    return [
      {
        id: 'true',
        name: trueText,
      },
      {
        id: 'false',
        name: falseText,
      },
    ];
  }, [trueText, falseText]);

  const value = useMemo(() => {
    return dataSet.find((item) => item.id === String(valueProp));
  }, [valueProp, dataSet]);

  const onChange = useCallback(
    (value: { id: string | number }) => {
      onChangeProp(value.id === 'true');
    },
    [onChangeProp]
  );

  return (
    <FixedDataPicker
      multiple={false}
      value={value}
      dataSet={dataSet}
      clearable={false}
      onChange={onChange}
    />
  );
}
