import { isEqual } from 'lodash';
import {
  useCallback,
  useDebugValue,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useSettingsContext } from './context';
import { SettingScope } from './types';

export interface UseSettingOptions<T> {
  name: string;
  scope: SettingScope;
  defaultValue: T;
  /**
   * Optional parseFn for transforming the saved value before returning it.
   * create via https://transform.tools/typescript-to-zod
   */
  parseFn?: (value: unknown) => T;
}

export interface UseSettingReturn<T = unknown> {
  value: T;
  set: (value: T) => void;
  delete: () => void;
}

export function useSetting<T = unknown>(
  options: UseSettingOptions<T>
): UseSettingReturn<T> {
  const { name, scope, defaultValue, parseFn } = options;

  const { getValue, setSetting, deleteSetting, subscribe } =
    useSettingsContext();

  const [value, setValue] = useState<T>(() => getValue()[scope][name] as T);

  useEffect(() => {
    function onStateChange() {
      const newValue = getValue()[scope][name] as T;
      setValue((oldValue) => {
        if (isEqual(oldValue, newValue)) {
          return oldValue;
        }

        return newValue;
      });
    }

    onStateChange();

    return subscribe(onStateChange);
  }, [name, scope, getValue, subscribe]);

  const boundSetSetting = useCallback(
    (value: T) => setSetting({ name, scope, value }),
    [setSetting, name, scope]
  );

  const boundDeleteSetting = useCallback(
    () => deleteSetting({ name, scope }),
    [deleteSetting, name, scope]
  );

  useDebugValue({
    name,
    scope,
    value,
    defaultValue,
  });

  return useMemo(() => {
    let resultValue = value === undefined ? defaultValue : value;

    if (value !== undefined && parseFn) {
      try {
        resultValue = parseFn(resultValue);
      } catch (err) {
        resultValue = defaultValue;
      }
    }

    return {
      value: resultValue,
      set: boundSetSetting,
      delete: boundDeleteSetting,
    };
  }, [value, defaultValue, boundSetSetting, parseFn, boundDeleteSetting]);
}
