import styles from './styles.module.scss';

export type IDialogActionsProps = {
  children: React.ReactNode;
};

export function DialogActions(props: IDialogActionsProps) {
  const { children } = props;

  return <div className={styles['dialog-actions']}>{children}</div>;
}
