import styles from './Dashboard.module.scss';

import { Skeleton, Table, TableBody, TableCell, TableRow } from '@mui/material';
import clsx from 'clsx';

import { Card } from '@work4all/components/lib/dataDisplay/card';
import { Heading4 } from '@work4all/components/lib/typography/heading4/Heading4';

export function DashboardTableCard(props: {
  label: string;
  values: Array<{ label: string; value: number | string | JSX.Element }>;
  isLoading: boolean;
}) {
  const cellStyles = {
    padding: '0.5rem 1rem',
    fontSize: '1rem',
    height: 'auto',
  };

  return (
    <Card noPadding={true} style={{ height: '100%' }}>
      <Heading4>
        <span style={{ padding: '0.5rem 1rem' }}>{props.label}</span>
      </Heading4>
      <Table>
        <TableBody>
          {props.values.map((row, rowIdx) => {
            return (
              <TableRow
                key={rowIdx}
                className={clsx({ [styles.alternateRow]: rowIdx % 2 })}
              >
                <TableCell style={cellStyles}>{row.label}</TableCell>
                <TableCell style={{ ...cellStyles, textAlign: 'right' }}>
                  {props.isLoading ? (
                    <Skeleton height={'1rem'} width={'100%'} />
                  ) : (
                    row.value
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Card>
  );
}
