import { Typography } from '@mui/material';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { Tour } from '@work4all/models/lib/Classes/Tour.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type ITourPickerProps<TMultiple extends boolean> = IPickerProps<
  Tour,
  TMultiple
>;

export const TourPicker = <TMultiple extends boolean>(
  props: ITourPickerProps<TMultiple>
) => {
  const { data, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...TOUR_FIELDS, ...data };
  }, [data]);

  const request = useMemo<DataRequest>(() => {
    return {
      data: allFields,
      entity: Entities.tour,
      completeDataResponse: true,
      operationName: 'GetTours',
    };
  }, [allFields]);

  const response = useDataProvider(request);

  // The data provider does not set the `total` property when
  // `completeDataResponse` is `true`.   So we have to set it manually.
  // Otherwise, the <ListEntityPicker /> will not be able to render the list.
  const patchedResponse = useMemo(() => {
    return { ...response, total: response.data.length };
  }, [response]);

  const renderItem = (tour: Tour) => {
    const label = tour.name;

    return (
      <Tooltip title={label}>
        <Typography variant="body2" noWrap>
          {label}
        </Typography>
      </Tooltip>
    );
  };

  return (
    <ListEntityPicker
      entity={Entities.user}
      data={allFields}
      filterBy={['note', 'number']}
      sortBy="note"
      {...rest}
      fixedDataSet={patchedResponse}
      renderItemContent={renderItem}
    />
  );
};

const TOUR_FIELDS: Tour = {
  id: null,
  name: null,
};
