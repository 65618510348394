import { FC, MouseEventHandler, useMemo } from 'react';

import { dateToString } from '@work4all/components/lib/dataDisplay/basic-table/utils/filters/filterConversion';

import { useProjectCaptions } from '../../../../../hooks';

import { InlineData } from './InlineData';

type IProjectPeriodCardProps = {
  projectStartDate: Date;
  projectEndDate: Date;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export const ProjectPeriodCard: FC<IProjectPeriodCardProps> = ({
  projectStartDate,
  projectEndDate,
  onClick,
}) => {
  const { projectStartEndDateCaption } = useProjectCaptions();
  const createProjectStartEndStr = useMemo(
    () => dateToString(projectStartDate, projectEndDate, false),
    [projectStartDate, projectEndDate]
  );

  return (
    <InlineData
      onClick={onClick}
      label={projectStartEndDateCaption}
      content={createProjectStartEndStr}
    />
  );
};
