import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputKrankheit<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: number;
  /** Alias: benutzerCode */
  benutzerCode?: number;
  /** Alias: datum */
  datum?: string;
  /** Alias: menge */
  menge?: number;
  /** Alias: notiz */
  notiz?: string;
  /** Alias: urlaubsArt */
  urlaubsArt?: number;
  /** Alias: datumAntrag */
  datumAntrag?: string;
  /** Alias: datumGenehmigung */
  datumGenehmigung?: string;
  /** Alias: bCodeGenehmigung */
  bCodeGenehmigung?: number;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];
  /** Alias: krankheitsArtCode */
  krankheitsArtCode?: number;

  __typename?: string;
}

const fields: FieldDefinitions<InputKrankheit> = {
  code: {
    alias: 'code',
  },
  benutzerCode: {
    alias: 'benutzerCode',
  },
  datum: {
    alias: 'datum',
  },
  menge: {
    alias: 'menge',
  },
  notiz: {
    alias: 'notiz',
  },
  urlaubsArt: {
    alias: 'urlaubsArt',
  },
  datumAntrag: {
    alias: 'datumAntrag',
  },
  datumGenehmigung: {
    alias: 'datumGenehmigung',
  },
  bCodeGenehmigung: {
    alias: 'bCodeGenehmigung',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customFields: {
    alias: 'customFields',
  },
  krankheitsArtCode: {
    alias: 'krankheitsArtCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputKrankheitEntityDefinition: EntitiyDefinition<InputKrankheit> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputKrankheit',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
