import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { ObjectType } from '../Enums/ObjectType.enum';

import { ChildItemCreatedObject } from './ChildItemCreatedObject.entity';
import { SalesOpportunityRating } from './SalesOpportunityRating.entity';
import { User } from './User.entity';

export class ChildItemCreated<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: creationDate */
  creationDate?: string;
  /** Alias: objectType */
  objectType?: ObjectType;
  /** Alias: objectId */
  objectId?: string;
  /** Alias: userCode */
  userId?: number;
  /** Alias: user */
  user?: User<T>;
  /** Alias: childObjectCode */
  childObjectCode?: number;
  /** Alias: childObjectId */
  childObjectId?: number;
  /** Alias: childObjectType */
  childObjectType?: ObjectType;
  /** Alias: data */
  data?: T extends EMode.query
    ? ChildItemCreatedObject<T>
    : SalesOpportunityRating<T>;

  __typename?: string;
}

const fields: FieldDefinitions<ChildItemCreated> = {
  id: {
    alias: 'id',
  },
  creationDate: {
    alias: 'creationDate',
  },
  objectType: {
    alias: 'objectType',
  },
  objectId: {
    alias: 'objectId',
  },
  userId: {
    alias: 'userCode',
  },
  user: {
    alias: 'user',
    entity: Entities.user,
  },
  childObjectCode: {
    alias: 'childObjectCode',
  },
  childObjectId: {
    alias: 'childObjectId',
  },
  childObjectType: {
    alias: 'childObjectType',
  },
  data: {
    alias: 'data',
    entity: [Entities.salesOpportunityRating],
  },
  __typename: {
    alias: '__typename',
  },
};

export const childItemCreatedEntityDefinition: EntitiyDefinition<ChildItemCreated> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'ChildItemCreated',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
