import { useMemo } from 'react';

import { settings, useSetting } from '../../settings';

import { TimeTrackerMenuTabs } from './TimeTrackerMenuTabs';

export function useTimeTrackerTab(options: {
  workTimeDisabled: boolean;
  projectTimeDisabled: boolean;
}) {
  const { workTimeDisabled, projectTimeDisabled } = options;

  const timeTrackingTab = useSetting(settings.timeTrackingTab());

  // Only shows tabs to which the user has access.
  const tab = useMemo(() => {
    const allowed: TimeTrackerMenuTabs[] = [
      workTimeDisabled ? null : TimeTrackerMenuTabs.workingTime,
      projectTimeDisabled ? null : TimeTrackerMenuTabs.projectTime,
    ].filter(Boolean);

    if (allowed.includes(timeTrackingTab.value)) {
      return timeTrackingTab.value;
    }

    return allowed[0] ?? null;
  }, [workTimeDisabled, projectTimeDisabled, timeTrackingTab.value]);

  return [tab, timeTrackingTab.set] as const;
}
