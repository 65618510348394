import styles from './BusinessPartnerPickerWithPrefix.module.scss';

import { Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { BusinessPartner } from '@work4all/models/lib/Classes/BusinessPartner.entity';

import { BusinessPartnerPickerField } from '../../entity-picker/BusinessPartnerPickerField';

interface Props {
  prefix: string;
  formKey: 'businessPartnerA' | 'businessPartnerB';
  disabled?: boolean;
}

export const BusinessPartnerPickerWithPrefix = ({
  prefix,
  formKey,
  disabled,
}: Props) => {
  const { watch, setValue } = useFormContext();
  const value = watch(formKey) as BusinessPartner;

  return (
    <div className={styles.wrapper}>
      <div className={styles.prefix}>
        <Typography variant="caption" fontWeight={700}>
          {prefix}
        </Typography>
      </div>
      <BusinessPartnerPickerField
        disabled={disabled}
        onChange={(value) => {
          setValue(
            formKey,
            { id: value?.data?.id, data: value?.data },
            { shouldDirty: true }
          );
        }}
        value={value?.data}
        labeledInputClassName={styles.picker}
      />
    </div>
  );
};
