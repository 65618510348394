import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class ProjectDistributor<T extends EMode = EMode.entity> {
  /** Alias: benutzer */
  userId?: number;
  /** Alias: code */
  id?: number;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: kunden */
  customers?: number;
  /** Alias: lieferanten */
  suppliers?: number;
  /** Alias: projektCode */
  projectId?: number;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: verteilerName */
  distributorName?: string;

  __typename?: string;
}

const fields: FieldDefinitions<ProjectDistributor> = {
  userId: {
    alias: 'benutzer',
  },
  id: {
    alias: 'code',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customers: {
    alias: 'kunden',
  },
  suppliers: {
    alias: 'lieferanten',
  },
  projectId: {
    alias: 'projektCode',
  },
  updateTime: {
    alias: 'updateTime',
  },
  distributorName: {
    alias: 'verteilerName',
  },
  __typename: {
    alias: '__typename',
  },
};

export const projectDistributorEntityDefinition: EntitiyDefinition<ProjectDistributor> =
  {
    local: {},
    remote: {
      queryName: 'getProjektVerteiler',
      fragmentName: 'ProjektVerteiler',
      withPaginationWrapper: true,
      params: [
        { name: 'querySize', type: 'Int' },
        { name: 'queryPage', type: 'Int' },
        { name: 'querySortOrder', type: 'SortOrderStyle' },
        { name: 'querySortBy', type: 'String' },
        { name: 'filter', type: 'String' },
      ],
    },
    fieldDefinitions: fields,
  };
