import { createContext, useContext } from 'react';

import { ParsedCustomFieldConfig } from '../types';

export type CustomFieldsConfigContextValue = {
  customFields: ParsedCustomFieldConfig[];
};

const Context = createContext<CustomFieldsConfigContextValue>({
  customFields: [],
});

export const CustomFieldsConfigContextProvider = Context.Provider;

export function useCustomFieldsConfigContext(): CustomFieldsConfigContextValue {
  const ctx = useContext(Context);

  return ctx;
}
