import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export interface UseDuplicatesDataProps {
  entity: Entities.customer | Entities.supplier;
  postalCode: string | null;
  street: string | null;
}

export const useDuplicatesData = ({
  entity,
  postalCode,
  street,
}: UseDuplicatesDataProps) => {
  const isPostalCode = postalCode && postalCode.trim().length > 0;
  const isStreet = street && street.trim().length > 0;

  const request: DataRequest = useMemo(() => {
    const filter = [];

    if (isPostalCode) {
      filter.push({ postalCode: { $eq: postalCode } });
    }

    if (isStreet) {
      filter.push({ street: { $eq: street } });
    }

    return {
      entity,
      data: {
        id: null,
        postalCode: null,
        street: null,
        firm1: null,
        city: null,
      },
      completeDataResponse: true,
      filter,
      skip: filter.length === 0,
    };
  }, [postalCode, street, entity, isPostalCode, isStreet]);

  const { data, loading } = useDataProvider<Customer | Supplier>(request);

  const filteredData = useMemo(() => {
    if ((!isPostalCode && !isStreet) || loading) {
      return [];
    }

    return (data as unknown as { data: (Customer | Supplier)[] }).data.map(
      (businessPartner) => businessPartner
    );
  }, [data, loading, isPostalCode, isStreet]);

  return {
    data: filteredData,
  };
};
