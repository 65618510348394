import styles from './NotificationTitle.module.scss';

import { Typography } from '@mui/material';

export interface NotificationTitleProps {
  unread?: boolean;
  children?: React.ReactNode;
}

export function NotificationTitle(props: NotificationTitleProps) {
  const { unread = false, children } = props;

  return (
    <div className={styles.root}>
      <Typography variant="body1" noWrap fontWeight={unread ? 700 : 400}>
        {children}
      </Typography>
    </div>
  );
}
