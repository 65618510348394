import styles from './CardHeader.module.scss';

import Typography from '@mui/material/Typography';

export interface CardHeaderProps {
  title: React.ReactNode;
  children?: React.ReactNode;
}

export function CardHeader(props: CardHeaderProps) {
  const { title, children } = props;

  return (
    <div className={styles.root}>
      <Typography className={styles.title} variant="h4" color="text.primary">
        {title}
      </Typography>

      <div className={styles.content}>{children}</div>
    </div>
  );
}
