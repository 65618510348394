import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { LetterPreviewContainer } from '@work4all/components/lib/components/entity-preview/letter-preview';
import { useTableStateBag } from '@work4all/components/lib/dataDisplay/basic-table';

import { useDataProvider, useUser } from '@work4all/data';

import { Letter } from '@work4all/models/lib/Classes/Letter.entity';
import { DataRequest, SortDirection } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Table } from '../../components/data-tables/table/Table';
import { OnOpenMask } from '../../containers/mask-overlays/mask-overlay';
import { useDocumentPdfEditRights } from '../../containers/mask-overlays/mask-overlay/views/pdf-editor/use-document-pdf-edit-rights';
import { usePdfEditorOverlayMask } from '../../containers/mask-overlays/mask-overlay/views/pdf-editor/use-pdf-editor-overlay-mask';
import { PersonalAndGeneralView } from '../filters/PersonalAndGeneralView';

import schema from './schemata/letter-table-schema.json';
import { TableLayoutContext, useTableLayoutState } from './table-layout';
import { TableNoRowsPlaceholder } from './TableNoRowsPlaceholder';
import { useDataTable } from './use-data-table';
import { useDeleteEntitiesToolbarConfig } from './use-delete-entities-toolbar-config';
import { useTableMaskHandlers } from './use-table-mask-handlers';
import { useUserColumnConfigs } from './use-user-column-configs';

interface Props {
  prefilter?: unknown[];
  onOpenMask: OnOpenMask;
}

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];
const manualGroupBy = true;

export const LettersTable = React.forwardRef<TableInstance, Props>(
  function LettersTable(props, ref) {
    const { prefilter, onOpenMask } = props;
    const { entity: entityType } = schema as never;

    const tableStateBag = useTableStateBag();

    const layoutState = useTableLayoutState();
    const [layout] = layoutState;

    const {
      pending,
      columnConfigs,
      cardConfig,
      prepareRowDisplayModifiers,
      data,
      fetchMore,
      total,
      selectedEntity,
      initialSortBy,
    } = useDataTable<Letter, never>({
      layout,
      schema: schema as never,
      tableStateBag,
      prefilter,
      defaultSort,
    });

    const maskHandlers = useTableMaskHandlers(entityType, onOpenMask);

    const deleteConfig = useDeleteEntitiesToolbarConfig({
      entityType,
    });

    const [userConfig, userConfigMethods] = useUserColumnConfigs({
      layout,
      entityType,
      columnConfigs,
    });

    const { t } = useTranslation();

    const user = useUser();

    const previewRequest = useMemo<DataRequest>(() => {
      const data: Letter<EMode.query> = {
        id: null,
        userId: null,
        fileInfos: {
          fileServiceProviderInfos: {
            id: null,
            fspUrl: null,
            fileName: null,
          },
        },
      };

      return {
        entity: Entities.letter,
        data,
        completeDataResponse: false,
        filter: [{ id: { $eq: selectedEntity?.id } }],
      };
    }, [selectedEntity?.id]);

    const selectedEntityResult = useDataProvider<Letter>(previewRequest);

    const { data: selectedEntityData, refetch } = selectedEntityResult;

    const selectedLetter = selectedEntityData?.[0];

    const pdfEditAllowed = useDocumentPdfEditRights({
      fileName: selectedLetter?.fileInfos?.fileServiceProviderInfos?.fileName,
      document: selectedLetter,
      userRights: user.benutzerRechte,
      userId: user.benutzerCode,
    });

    const { pdfEditOverlay, open: openPdfEditOverlay } =
      usePdfEditorOverlayMask({
        entityId: selectedEntity?.id,
        entityType: Entities.letter,
        docId: selectedLetter?.id,
        onClose: refetch,
      });

    const handleOnDoubleClick = useCallback(() => {
      const selectedEntity = selectedEntityResult?.[0];
      if (selectedEntity?.fileInfos?.fileServiceProviderInfos?.fspUrl) {
        window.open(
          selectedEntity?.fileInfos?.fileServiceProviderInfos?.fspUrl,
          '_blank'
        );
      } else {
        maskHandlers.edit.handler(selectedEntity?.id);
      }
    }, [maskHandlers.edit, selectedEntityResult]);

    if (!userConfig) return null;

    return (
      <>
        {pdfEditOverlay}

        <TableLayoutContext value={layoutState}>
          <Table
            pending={pending}
            noRowsRenderer={() => <TableNoRowsPlaceholder />}
            layout={layout}
            cardConfig={cardConfig}
            ref={ref}
            onRowDoubleClick={handleOnDoubleClick}
            areas={{
              left: {
                content: <PersonalAndGeneralView entity={Entities.letter} />,
                resizable: true,
                collapseConfig: {
                  title: t('COMMON.FILTER'),
                },
              },
              right: selectedEntity !== null && {
                content: (
                  <LetterPreviewContainer
                    letterId={selectedEntity.id}
                    onCloseClicked={() =>
                      tableStateBag.tableInstance.toggleAllRowsSelected(false)
                    }
                    onEditClicked={maskHandlers.edit.handler}
                    onShareClicked={maskHandlers.share}
                    onEditPdfClicked={
                      pdfEditAllowed
                        ? () => {
                            openPdfEditOverlay();
                          }
                        : undefined
                    }
                  />
                ),
              },
            }}
            columnConfigs={userConfig}
            manualGroupBy={manualGroupBy}
            initialSortBy={initialSortBy}
            loadMoreItems={fetchMore}
            actions={{
              add: maskHandlers.create,
              edit: maskHandlers.edit,
              convert: {
                exclude: Entities.letter,
                handler: maskHandlers.convert,
              },
              remove: deleteConfig,
              resetColumns: userConfigMethods.remove,
              createWidget: true,
            }}
            prepareRowDisplayModifiers={prepareRowDisplayModifiers}
            data={data}
            total={total}
          />
        </TableLayoutContext>
      </>
    );
  }
);
