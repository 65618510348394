import { History } from '../../history/History';
import { Panel } from '../../panel/Panel';

export const HistoryTabPanel = () => {
  return (
    <Panel>
      <History />
    </Panel>
  );
};
