import * as amplitude from '@amplitude/analytics-browser';

type EventProperties = {
  name?: string;
  entryPoint?: string;
  filterElement?: string;
};

export enum EventType {
  MainNavigationTap = 'MainNavigationTap',
  Search = 'Search',
  StartWorkTime = 'StartWorkTime',
  StopWorkTime = 'StopWorkTime',
  PauseWorkTime = 'PauseWorkTime',
  ResumeWorkTime = 'ResumeWorkTime',
  AddProjectTime = 'AddProjectTime',
  EditProjectTime = 'EditProjectTime',
  SaveProjectTime = 'SaveProjectTime',
  OpenList = 'OpenList',
  CloseList = 'CloseList',
  FilterList = 'FilterList',
  TabListSettings = 'TabListSettings',
  TabFilter = 'TabFilter',
  AddElement = 'AddElement',
  SaveElement = 'SaveElement',
  DeleteElement = 'DeleteElement',
  EditElement = 'EditElement',
  OpenFilePage = 'OpenFilePage',
  CloseFilePage = 'CloseFilePage',
  AddFile = 'AddFile',
  AddFileFromImprint = 'AddFileFromImprint',
  OpenStatistic = 'OpenStatistic',
  TabElementControlCenter = 'TabElementControlCenter ',
  AddVacation = 'AddVacation',
  OpenProjectTimeSettings = 'OpenProjectTimeSettings',
  OpenERPSettings = 'OpenERPSettings',
  OpenCalendarSettings = 'OpenCalendarSettings',
  SaveEMailTemplate = 'SaveEMailTemplate',
  AddEMailTemplate = 'AddEMailTemplate',
  EditEMailTemplate = 'EditEMailTemplate',
  AddEMailSignature = 'AddEMailSignature',
  SaveEMailSignature = 'SaveEMailSignature',
  EditEMailSignature = 'EditEMailSignature',
  AddTextBuildingBlock = 'AddTextBuildingBlock',
  SaveTextBuildingBlock = 'SaveTextBuildingBlock',
  EditTextBuildingBlock = 'EditTextBuildingBlock',
  OpenInboundInvoiceSettings = 'OpenInboundInvoiceSettings',
  OpenHomeWidgetSettings = 'OpenHomeWidgetSettings',
  OpenTableSettings = 'OpenTableSettings',
}

export type AmplitudeDefaultEventProps = {
  customerId?: number;
  userId?: number;
};

const AMPLITUDE_API_KEY = 'b839e702f29674258542d5eb4f9482c3';

let DEFAULT_AMPLITUDE_PROPS: AmplitudeDefaultEventProps = {};

export const initAmplitude = (defaultProps: AmplitudeDefaultEventProps) => {
  if (process.env.NX_AMPLITUDE_ENABLED) {
    DEFAULT_AMPLITUDE_PROPS = defaultProps;
    amplitude.init(AMPLITUDE_API_KEY);
  }
};

export const sendAmplitudeData = (
  eventType: EventType,
  eventProperties: EventProperties
) => {
  if (process.env.NX_AMPLITUDE_ENABLED) {
    amplitude.track(eventType, {
      ...DEFAULT_AMPLITUDE_PROPS,
      ...eventProperties,
    });
  }
};
