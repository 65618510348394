import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { DateienObjectType } from '../Enums/DateienObjectType.enum';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { FileEntity } from './FileEntity.entity';

export class ArticleImage<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: dateienType */
  dateienType?: DateienObjectType;
  /** Alias: objMemberCode */
  objMemberCode?: number;
  /** Alias: name */
  name?: string;
  /** Alias: info1 */
  info1?: string;
  /** Alias: info2 */
  info2?: string;
  /** Alias: stillgelegt */
  stillgelegt?: boolean;
  /** Alias: pfad */
  pfad?: string;
  /** Alias: localFilename */
  localFilename?: string;
  /** Alias: dMSId */
  dMSId?: string;
  /** Alias: data */
  data?: string;
  /** Alias: anhangAngebot */
  anhangAngebot?: boolean;
  /** Alias: anhangAuftrag */
  anhangAuftrag?: boolean;
  /** Alias: anhangBedarf */
  anhangBedarf?: boolean;
  /** Alias: anhangBestellung */
  anhangBestellung?: boolean;
  /** Alias: anhangKalkulation */
  anhangKalkulation?: boolean;
  /** Alias: anhangLieferschein */
  anhangLieferschein?: boolean;
  /** Alias: anhangRechnung */
  anhangRechnung?: boolean;
  /** Alias: showInShop */
  showInShop?: boolean;
  /** Alias: fileInfos */
  fileInfos?: FileEntity<T>;

  __typename?: string;
}

const fields: FieldDefinitions<ArticleImage> = {
  id: {
    alias: 'code',
  },
  dateienType: {
    alias: 'dateienType',
  },
  objMemberCode: {
    alias: 'objMemberCode',
  },
  name: {
    alias: 'name',
  },
  info1: {
    alias: 'info1',
  },
  info2: {
    alias: 'info2',
  },
  stillgelegt: {
    alias: 'stillgelegt',
  },
  pfad: {
    alias: 'pfad',
  },
  localFilename: {
    alias: 'localFilename',
  },
  dMSId: {
    alias: 'dMSId',
  },
  data: {
    alias: 'data',
  },
  anhangAngebot: {
    alias: 'anhangAngebot',
  },
  anhangAuftrag: {
    alias: 'anhangAuftrag',
  },
  anhangBedarf: {
    alias: 'anhangBedarf',
  },
  anhangBestellung: {
    alias: 'anhangBestellung',
  },
  anhangKalkulation: {
    alias: 'anhangKalkulation',
  },
  anhangLieferschein: {
    alias: 'anhangLieferschein',
  },
  anhangRechnung: {
    alias: 'anhangRechnung',
  },
  showInShop: {
    alias: 'showInShop',
  },
  fileInfos: {
    alias: 'fileInfos',
    entity: Entities.fileEntity,
  },
  __typename: {
    alias: '__typename',
  },
};

export const articleImageEntityDefinition: EntitiyDefinition<ArticleImage> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'Artikelbild',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
