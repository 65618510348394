import styles from './WhiteFlash.module.scss';

import clsx from 'clsx';
import React from 'react';

export const WhiteFlash: React.FC<{ isShowWhiteFlash: boolean }> = ({
  isShowWhiteFlash,
}) => {
  return (
    <div
      id="white-flash"
      className={clsx(styles.normal, {
        [styles.doTransition]: isShowWhiteFlash,
      })}
    ></div>
  );
};
