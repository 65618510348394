import { DateTime } from 'luxon';
import { useEffect, useRef } from 'react';

import { triggerOnChange } from '@work4all/utils/lib/triggerOnChange';

/**
 * set value to input and trigger onChange event.
 */
export const useOnChangeTrigger = (
  fieldValue: DateTime,
  fieldInstance: HTMLInputElement,
  date?: DateTime,
  time?: DateTime,
  customFormat?: string
) => {
  const mountedRef = useRef(false);
  const defaultRef = useRef(fieldValue);
  useEffect(() => {
    mountedRef.current = false;
    defaultRef.current = fieldValue;
  }, [fieldValue]);

  useEffect(() => {
    let value = '';
    let dateObject = defaultRef.current?.toObject();
    if (date) {
      dateObject = {
        ...dateObject,
        day: date.day,
        month: date.month,
        year: date.year,
      };

      if (time) {
        dateObject = {
          ...dateObject,
          hour: time.hour,
          minute: time.minute,
        };
      }

      const dateTime = DateTime.fromObject(dateObject);
      if (customFormat) {
        value = dateTime.toFormat(customFormat);
      } else {
        value = dateTime.toISO();
      }
    }
    if (mountedRef.current === true) {
      if (value !== fieldInstance.value) {
        triggerOnChange(fieldInstance, value);
      }
    } else {
      mountedRef.current = true;
    }
  }, [date, time, customFormat, fieldInstance]);
};
