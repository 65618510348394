import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { SalutationPicker } from '@work4all/components/lib/components/entity-picker/salutation-picker/SalutationPicker';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useEMailSuggestions } from '../../../../../../../../../../components/entity-picker/EMailSuggestionPickerField';
import { SuggestionPickerField } from '../../../../../../../../../../components/entity-picker/SuggestionPickerField';
import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { LabeledInput } from '../../../../../../../../locked-inputs';
import { DateTimeInputPicker } from '../../../../../../../../locked-inputs';
import { ControlWrapper } from '../../../../../../../components/control-wrapper';
import { ContactMaskFormValue } from '../../../../../types';

export const PersonalData: React.FC = () => {
  const { t } = useTranslation();
  const { control, register, watch, setValue } =
    useFormContextPlus<ContactMaskFormValue>();

  const contactId = watch('id');
  const firstname = watch('firstName');
  const lastname = watch('name');
  const businessPartnerId = watch('businessPartnerId');
  const businesspartnerType = watch('businessPartnerType');

  const [skipSuggestions, setSkipSuggestions] = useState(true);

  const emailSuggestions = useEMailSuggestions({
    firstname,
    lastname,
    businessPartnerId,
    businesspartnerType,
    skip: Boolean(contactId) || skipSuggestions,
  });

  const { hasDistinctEmailSuggestion, options } = emailSuggestions;

  useEffect(() => {
    if (hasDistinctEmailSuggestion) {
      setValue('eMail', options?.[0]);
    }
  }, [hasDistinctEmailSuggestion, emailSuggestions, options, setValue]);

  return (
    <Collapse defaultOpen={true} title={t('MASK.PERSONAL_DATA')}>
      <ControlWrapper>
        <ControllerPlus
          name="salutation"
          control={control}
          render={({ field: salutation }) => {
            return (
              <EntityPickerPopover
                picker={
                  <SalutationPicker
                    multiple={false}
                    value={salutation?.value}
                    onChange={(value) => {
                      salutation.onChange(value);
                    }}
                  />
                }
              >
                <LabeledInput
                  onClear={null}
                  value={`${
                    salutation?.value?.name ? salutation?.value?.name : ''
                  }${
                    salutation?.value?.standardletterSalutation
                      ? ' / ' + salutation?.value?.standardletterSalutation
                      : ''
                  }`}
                  label={t('INPUTS.SALUTATION')}
                />
              </EntityPickerPopover>
            );
          }}
        />
        <ControllerPlus
          name={'titleExtension'}
          control={control}
          render={({ field }) => {
            return (
              <SuggestionPickerField
                label={t('INPUTS.TITLE_EXTENSION')}
                {...field}
                entity={Entities.contact}
                field="titleExtension"
              />
            );
          }}
        />
      </ControlWrapper>
      <ControlWrapper>
        <LabeledInput
          label={t('INPUTS.NAME')}
          {...register('firstName')}
          onFocus={() => {
            setSkipSuggestions(true);
          }}
          onBlur={() => {
            setSkipSuggestions(false);
          }}
        />
        <LabeledInput
          label={t('INPUTS.LAST_NAME')}
          {...register('name')}
          onFocus={() => {
            setSkipSuggestions(true);
          }}
          onBlur={() => {
            setSkipSuggestions(false);
          }}
        />
      </ControlWrapper>
      <ControlWrapper>
        <LabeledInput
          label={t('INPUTS.NAME_EXTENSION')}
          {...register('nameExtension')}
        />
        <LabeledInput
          label={t('INPUTS.ADDRESS_EXTENSION')}
          {...register('addressExtension')}
        />
      </ControlWrapper>
      <ControlWrapper>
        <LabeledInput
          label={t('INPUTS.GREETING_LETTER_EMAIL')}
          {...register('letterSalutation')}
        />
        <DateTimeInputPicker
          withTime={false}
          required={false}
          dateLabel={t('INPUTS.BIRTH_DATE')}
          {...register('birthdayDate')}
        />
      </ControlWrapper>
    </Collapse>
  );
};
