import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export const MAX_DISPLAYED_NOTIFICATIONS = 20;

export const MASCULINE_ENTITIES = [
  Entities.ticket,
  Entities.offer,
  Entities.document,
  Entities.order,
  Entities.mailbox,
  Entities.deliveryNote,
  Entities.appointment,
  Entities.inboundDeliveryNote,
];
