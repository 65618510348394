import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { FC, MouseEventHandler, useContext, useMemo, useState } from 'react';

import { AppointmentPreviewContainer } from '@work4all/components/lib/components/entity-preview/appointment-preview';
import { CallMemoPreviewContainer } from '@work4all/components/lib/components/entity-preview/call-memo-preview';
import { ChecklistPreviewContainer } from '@work4all/components/lib/components/entity-preview/checklist-preview/ChecklistPreviewContainer';
import { DocumentPreviewContainer } from '@work4all/components/lib/components/entity-preview/document-preview';
import { EmailPreviewContainer } from '@work4all/components/lib/components/entity-preview/email-preview';
import { ErpPreviewContainer } from '@work4all/components/lib/components/entity-preview/erp-preview/ErpPreviewContainer';
import { LetterPreviewContainer } from '@work4all/components/lib/components/entity-preview/letter-preview';
import { NotePreviewContainer } from '@work4all/components/lib/components/entity-preview/note-preview';
import { SalesOpportunitiesContainer } from '@work4all/components/lib/components/entity-preview/sales-opportunities-preview/SalesOpportunitiesContainer';
import { TaskPreviewContainer } from '@work4all/components/lib/components/entity-preview/task-preview';
import { TicketPreviewContainer } from '@work4all/components/lib/components/entity-preview/ticket-preview/TicketPreviewContainer';
import { VisitationReportPreviewContainer } from '@work4all/components/lib/components/entity-preview/visitation-report-preview';
import { UserIconRegisterContext } from '@work4all/components/lib/components/user-icon/useUserIconRegister';
import { Caption } from '@work4all/components/lib/typography/caption/Caption';

import { EntitiesOfErpGroups, useDataProvider, useUser } from '@work4all/data';

import { Document } from '@work4all/models/lib/Classes/Document.entity';
import { File } from '@work4all/models/lib/Classes/File.entity';
import { InboundInvoice } from '@work4all/models/lib/Classes/InboundInvoice.entity';
import { Letter } from '@work4all/models/lib/Classes/Letter.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useDocumentPdfEditRights } from '../../../../mask-overlays/mask-overlay/views/pdf-editor/use-document-pdf-edit-rights';
import { useInboundInvoicePdfEditRights } from '../../../../mask-overlays/mask-overlay/views/pdf-editor/use-inbound-invoice-pdf-edit-rights';
import { usePdfEditorOverlayMask } from '../../../../mask-overlays/mask-overlay/views/pdf-editor/use-pdf-editor-overlay-mask';
import { ProjectFileInfoPanelContainer } from '../file-info-panel/project-file-info-panel-container/ProjectFileInfoPanelContainer';

export type IFilePreviewPanelProps = {
  entityId: number | string;
  entityType: Entities;
  onCloseClicked: MouseEventHandler<HTMLButtonElement>;
  onEditClicked: MouseEventHandler<HTMLButtonElement>;
  openMaskTab?: (tab: string) => void;
  openInFullscreen?: boolean;
};

export const FilePreviewPanel: FC<IFilePreviewPanelProps> = ({
  entityId,
  entityType,
  onCloseClicked,
  onEditClicked,
  openInFullscreen = false,
  openMaskTab,
}) => {
  const userIconContext = useContext(UserIconRegisterContext);
  const commonProps = {
    onCloseClicked: onCloseClicked,
    onEditClicked: onEditClicked,
  };

  const isErpType = EntitiesOfErpGroups.includes(entityType);

  const user = useUser();

  const [previewedFile, setPreviewedFile] = useState<File>(null);

  const documentPreviewRequest = useMemo<DataRequest>(() => {
    const data: Letter<EMode.query> | Document<EMode.query> = {
      id: null,
      userId: null,
      fileInfos: {
        fileServiceProviderInfos: {
          id: null,
          fspUrl: null,
          fileName: null,
        },
      },
    };

    return {
      entity: entityType,
      data,
      completeDataResponse: false,
      filter: [{ id: { $eq: entityId } }],
    };
  }, [entityId, entityType]);

  const selectedDocumentResult = useDataProvider<Letter>(
    documentPreviewRequest,
    ![Entities.document, Entities.letter].includes(entityType)
  );

  const { data: selectedDocumentData } = selectedDocumentResult;

  const selectedDocument = selectedDocumentData?.[0];

  const docPdfEditAllowed = useDocumentPdfEditRights({
    fileName: selectedDocument?.fileInfos?.fileServiceProviderInfos?.fileName,
    document: selectedDocument,
    userRights: user.benutzerRechte,
    userId: user.benutzerCode,
  });

  const inboundInvoicePreviewRequest = useMemo<DataRequest>(() => {
    const data: InboundInvoice<EMode.query> = {
      id: null,
      userId: null,
      datevDate: null,
      belegList: [
        {
          id: null,
          parentId: null,
          fileInfos: {
            fileServiceProviderInfos: {
              id: null,
              fspUrl: null,
            },
          },
        },
      ],
    };

    return {
      entity: Entities.inboundInvoice,
      data,
      completeDataResponse: false,
      filter: [{ id: { $eq: entityId } }],
    };
  }, [entityId]);

  const { data: selectedInboundInvoiceData } = useDataProvider<InboundInvoice>(
    inboundInvoicePreviewRequest,
    entityType !== Entities.inboundInvoice
  );

  const inboundInvoicePdfEditAllowed = useInboundInvoicePdfEditRights({
    fileName: previewedFile?.fileInfos?.fileEntityFilename,
    inboundInvoice: selectedInboundInvoiceData?.[0],
    userRights: user.benutzerRechte,
    userId: user.benutzerCode,
  });

  const docId = useMemo(() => {
    const selectedEntity = selectedInboundInvoiceData?.[0];

    const childDoc = selectedEntity?.belegList.find(
      (x) => x.parentId === previewedFile?.id
    );
    const docId = previewedFile?.parentId
      ? previewedFile?.id
      : childDoc
      ? childDoc.id
      : previewedFile?.id;

    return docId;
  }, [previewedFile?.id, previewedFile?.parentId, selectedInboundInvoiceData]);

  const { pdfEditOverlay, open: openPdfEditOverlay } = usePdfEditorOverlayMask({
    entityId,
    entityType,
    docId: entityType === Entities.inboundInvoice ? docId : entityId,
  });

  let comp = null;

  if (isErpType) {
    comp = (
      <ErpPreviewContainer
        {...commonProps}
        openInFullscreen={openInFullscreen}
        entityId={entityId as number}
        entity={entityType}
        onPreviewedFileChange={setPreviewedFile}
        onEditPdfClicked={
          inboundInvoicePdfEditAllowed ? () => openPdfEditOverlay() : undefined
        }
      />
    );
  }

  if (!comp)
    switch (entityType) {
      case Entities.eMail:
        comp = (
          <EmailPreviewContainer
            {...commonProps}
            emailId={entityId as number}
          />
        );
        break;
      case Entities.visitationReport:
        comp = (
          <VisitationReportPreviewContainer
            {...commonProps}
            visitationReportIds={[entityId] as number[]}
            userIconContext={userIconContext}
          />
        );
        break;

      case Entities.note:
        comp = (
          <NotePreviewContainer
            {...commonProps}
            noteIds={[entityId] as number[]}
          />
        );
        break;
      case Entities.appointment:
        comp = (
          <AppointmentPreviewContainer
            {...commonProps}
            appointmentIds={[entityId] as number[]}
          />
        );
        break;
      case Entities.project:
        comp = (
          <ProjectFileInfoPanelContainer
            {...commonProps}
            projectId={entityId as number}
          />
        );
        break;
      case Entities.callMemo:
        comp = (
          <CallMemoPreviewContainer
            {...commonProps}
            callMemoIds={[entityId] as number[]}
          />
        );
        break;
      case Entities.task:
        comp = (
          <TaskPreviewContainer
            {...commonProps}
            taskIds={[entityId] as number[]}
          />
        );
        break;
      case Entities.document:
        comp = (
          <DocumentPreviewContainer
            {...commonProps}
            documentId={entityId as number}
            onEditPdfClicked={
              docPdfEditAllowed ? () => openPdfEditOverlay() : undefined
            }
          />
        );
        break;
      case Entities.letter:
        comp = (
          <LetterPreviewContainer
            {...commonProps}
            letterId={entityId as number}
            onEditPdfClicked={
              docPdfEditAllowed ? () => openPdfEditOverlay() : undefined
            }
          />
        );
        break;
      case Entities.checkList:
        comp = (
          <ChecklistPreviewContainer
            {...commonProps}
            checklistIds={[entityId] as number[]}
          />
        );
        break;
      case Entities.ticket:
        comp = (
          <TicketPreviewContainer
            {...commonProps}
            ticketIds={[entityId as string]}
            openMaskTab={openMaskTab}
          />
        );
        break;
      case Entities.salesOpportunities:
        comp = (
          <SalesOpportunitiesContainer
            {...commonProps}
            ids={[entityId as number]}
          />
        );
        break;

      default:
        comp = (
          <div style={{ padding: '1rem' }}>
            <IconButton
              onClick={(e) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onCloseClicked(e as any);
              }}
              size="large"
            >
              <CloseIcon />
            </IconButton>
            <Caption>ToDo: not yet implemented</Caption>
          </div>
        );
        break;
    }

  return (
    <>
      {pdfEditOverlay}
      {comp}
    </>
  );
};
