import styles from './styles.module.scss';

import { Paper } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Body1 } from '@work4all/components/lib/typography/body1/Body1';
import { Body2 } from '@work4all/components/lib/typography/body2/Body2';

import { User } from '@work4all/models/lib/Classes/User.entity';

type LockBanderoleProps =
  | ({ lockReason?: string } & Pick<
      User,
      'id' | 'firstName' | 'lastName' | 'displayName'
    >)
  | null;

export const LockedBanderole: React.FC<LockBanderoleProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Paper className={styles.root}>
      <Body2 className={styles.body2}>{t('MASK.EDITING_NOT_POSSIBLE')}</Body2>
      <span className={styles.seperator} />
      {(props?.displayName || props?.lockReason) && (
        <Body1>
          {props?.lockReason
            ? props?.lockReason
            : t('MASK.MASK_OPENED_BY', {
                userName: `${props.displayName}`,
              })}
        </Body1>
      )}
    </Paper>
  );
};
