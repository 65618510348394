import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputEMailSignatur<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string | number;
  /** Alias: name */
  name?: string;
  /** Alias: body */
  body?: string;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputEMailSignatur> = {
  id: {
    alias: 'id',
  },
  name: {
    alias: 'name',
  },
  body: {
    alias: 'body',
  },
  customFields: {
    alias: 'customFields',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputEMailSignaturEntityDefinition: EntitiyDefinition<InputEMailSignatur> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputEMailSignatur',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
