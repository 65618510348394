import { IconButton } from '@mui/material';
import React, { useRef } from 'react';

import { ReactComponent as TextmarkIcon } from '@work4all/assets/icons/textmark.svg';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  TextmarkPicker,
  TextmarkPickerProps,
} from '@work4all/components/lib/components/entity-picker/textmark-picker/TextmarkPicker';

import { reactRefSetter } from '@work4all/utils';

export const TextmarkPickerButton = React.forwardRef<
  HTMLDivElement,
  TextmarkPickerProps
>(function TextmarkPickerButton(props: TextmarkPickerProps, ref) {
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={reactRefSetter(wrapperRef, ref)}>
      <EntityPickerPopover
        anchorEl={wrapperRef}
        picker={<TextmarkPicker {...props} />}
      >
        <IconButton size="large">
          <TextmarkIcon />
        </IconButton>
      </EntityPickerPopover>
    </div>
  );
});
