import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { TimeStampKind } from '../Enums/TimeStampKind.enum';

import { Appointment } from './Appointment.entity';
import { User } from './User.entity';
import { WorkTimeShortInfo } from './WorkTimeShortInfo.entity';

export class UserPresence<T extends EMode = EMode.entity> {
  /** Alias: abwesend */
  absent?: boolean;
  /** Alias: anwesend */
  isPresent?: boolean;
  /** Alias: anwesendSeit */
  presentSinceDate?: string;
  /** Alias: anwesenheitIstGestern */
  presenceIsYesterday?: boolean;
  /** Alias: art */
  kind?: TimeStampKind;
  /** Alias: ausserHausTerminCode */
  outOfOfficeAppointment?: number;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: feierabendSeit */
  closingTime?: string;
  /** Alias: keinCheckInOut */
  notCheckedInOrOut?: boolean;
  /** Alias: krank */
  sick?: boolean;
  /** Alias: pause */
  pause?: boolean;
  /** Alias: pauseSeit */
  pauseSince?: string;
  /** Alias: tagBeendet */
  dayWasFinished?: boolean;
  /** Alias: terminIstUrlaub */
  appointmentIsVacation?: boolean;
  /** Alias: terminNotiz */
  appointmentNote?: string;
  /** Alias: terminPrivat */
  appointmentPrivate?: boolean;
  /** Alias: terminTitel */
  appointmentTitle?: string;
  /** Alias: unklar */
  ambigious?: boolean;
  /** Alias: urlaub */
  vacation?: boolean;
  /** Alias: urlaubLookupNameAnzeigenAls */
  vacationLookupShowNameAs?: string;
  /** Alias: benutzer */
  user?: User<T>;
  /** Alias: terminCode */
  appointmentId?: number;
  /** Alias: appointmentOutOfOffice */
  appointmentOutOfOffice?: Appointment<T>;
  /** Alias: workTimeInfo */
  workTimeInfo?: WorkTimeShortInfo<T>;
  /** Alias: letztePauseEnde */
  lastPauseFinished?: string;

  __typename?: string;
}

const fields: FieldDefinitions<UserPresence> = {
  absent: {
    alias: 'abwesend',
  },
  isPresent: {
    alias: 'anwesend',
  },
  presentSinceDate: {
    alias: 'anwesendSeit',
  },
  presenceIsYesterday: {
    alias: 'anwesenheitIstGestern',
  },
  kind: {
    alias: 'art',
  },
  outOfOfficeAppointment: {
    alias: 'ausserHausTerminCode',
  },
  userId: {
    alias: 'benutzerCode',
  },
  closingTime: {
    alias: 'feierabendSeit',
  },
  notCheckedInOrOut: {
    alias: 'keinCheckInOut',
  },
  sick: {
    alias: 'krank',
  },
  pause: {
    alias: 'pause',
  },
  pauseSince: {
    alias: 'pauseSeit',
  },
  dayWasFinished: {
    alias: 'tagBeendet',
  },
  appointmentIsVacation: {
    alias: 'terminIstUrlaub',
  },
  appointmentNote: {
    alias: 'terminNotiz',
  },
  appointmentPrivate: {
    alias: 'terminPrivat',
  },
  appointmentTitle: {
    alias: 'terminTitel',
  },
  ambigious: {
    alias: 'unklar',
  },
  vacation: {
    alias: 'urlaub',
  },
  vacationLookupShowNameAs: {
    alias: 'urlaubLookupNameAnzeigenAls',
  },
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  appointmentId: {
    alias: 'terminCode',
  },
  appointmentOutOfOffice: {
    alias: 'appointmentOutOfOffice',
    entity: Entities.appointment,
  },
  workTimeInfo: {
    alias: 'workTimeInfo',
    entity: Entities.workTimeShortInfo,
  },
  lastPauseFinished: {
    alias: 'letztePauseEnde',
  },
  __typename: {
    alias: '__typename',
  },
};

export const userPresenceEntityDefinition: EntitiyDefinition<UserPresence> = {
  local: {},
  remote: {
    queryName: 'getUserPresence',
    fragmentName: 'UserPresence',
    withPaginationWrapper: false,
    params: [{ name: 'benutzerCodes', type: '[Int]!' }],
  },
  fieldDefinitions: fields,
};
