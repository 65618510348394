import Typography from '@mui/material/Typography';
import { useMemo } from 'react';

import { IResponse, useDataProvider } from '@work4all/data';

import { Mailbox } from '@work4all/models/lib/Classes/Mailbox.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type IMailboxPickerProps<TMultiple extends boolean> = IPickerProps<
  Mailbox,
  TMultiple
>;

export function MailboxPicker<TMultiple extends boolean>(
  props: IMailboxPickerProps<TMultiple>
) {
  const { data, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...MAILBOX_FIELDS, ...data };
  }, [data]);

  const requestData = useMemo<DataRequest>(() => {
    return {
      entity: Entities.mailbox,
      data: allFields,
      completeDataResponse: true,
    };
  }, [allFields]);

  const response = useDataProvider(requestData);

  const dataset = useMemo<IResponse<Mailbox>>(() => {
    return {
      ...response,
      total: response.data.length,
      // loading: response.loading,
      fetchMore: () => Promise.resolve(),
      refetch: () => Promise.resolve(null),
      // data: response.data,
      // pending: response.pending,
    };
  }, [response]);

  return (
    <ListEntityPicker
      entity={Entities.mailbox}
      data={allFields}
      filterBy={['mailboxPrimaryAddress']}
      sortBy="mailboxPrimaryAddress"
      useSearchHistory={false}
      fixedDataSet={dataset}
      favorites={false}
      {...rest}
      renderItemContent={(mailbox) => (
        <Tooltip title={mailbox.mailboxPrimaryAddress}>
          <Typography variant="body2" noWrap>
            {mailbox.mailboxPrimaryAddress}
          </Typography>
        </Tooltip>
      )}
    />
  );
}

const MAILBOX_FIELDS: Mailbox = {
  id: null,
  mailboxPrimaryAddress: null,
};
