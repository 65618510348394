import { useMemo } from 'react';

import { useAuthHeaders } from '../../auth/use-auth-headers';
import { HttpClient } from '../classes/HttpClient';

/**
 * Creates new instance of HttpClient
 * @returns HttpClient with User & Tenant headers
 */
export const useHttpClient = () => {
  const defaultHeader = useAuthHeaders();
  return useMemo(() => {
    return new HttpClient(defaultHeader);
  }, [defaultHeader]);
};
