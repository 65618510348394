import { Dialog, Divider, Slide, Theme, useMediaQuery } from '@mui/material';
import React from 'react';

import { DetailsView } from '@work4all/components/lib/dataDisplay/details-view/DetailsView';

import { AreaConfig } from '@work4all/models/lib/Tables/Tables';

import { settings, useSetting } from '../../../../../settings';

interface TableRightAreaProps {
  rightArea?: AreaConfig;
  className?: string;
}

export const TableRightArea: React.FC<TableRightAreaProps> = (
  props: TableRightAreaProps
) => {
  const { rightArea, className } = props;

  const previewSize = useSetting(settings.listPreviewSize());

  const isSmDown = useMediaQuery<Theme>((t) => t.breakpoints.down('sm'));

  return (
    <>
      {rightArea && !isSmDown ? (
        <>
          <Divider orientation="vertical" />
          <DetailsView
            size={previewSize.value}
            onResize={previewSize.set}
            className={className}
          >
            {rightArea.content}
          </DetailsView>
        </>
      ) : null}

      {rightArea && isSmDown ? (
        <Dialog
          fullScreen
          open={true}
          TransitionComponent={Slide}
          TransitionProps={{
            // @ts-expect-error MUI component seems to be typed
            // incorrectly. The props are passed down to the Slide
            // component and the transition is correct, so everything
            // is working as expected.
            direction: 'left',
          }}
        >
          {rightArea.content}
        </Dialog>
      ) : null}
    </>
  );
};
