import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { SetMfaCodeError } from '../Enums/SetMfaCodeError.enum';

export class ValidateSetMfaModeResponse<T extends EMode = EMode.entity> {
  /** Alias: ok */
  ok?: boolean;
  /** Alias: errorMessage */
  errorMessage?: string;
  /** Alias: errorCode */
  errorCode?: SetMfaCodeError;

  __typename?: string;
}

const fields: FieldDefinitions<ValidateSetMfaModeResponse> = {
  ok: {
    alias: 'ok',
  },
  errorMessage: {
    alias: 'errorMessage',
  },
  errorCode: {
    alias: 'errorCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const validateSetMfaModeResponseEntityDefinition: EntitiyDefinition<ValidateSetMfaModeResponse> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'ValidateSetMfaModeResponse',
      withPaginationWrapper: false,
      mutations: {
        upsert: {
          mutationName: 'validateUserMfaMode',
          args: [{ name: 'input', type: 'InputValidateSetMfaMode!' }],
        },
      },
    },
    fieldDefinitions: fields,
  };
