import { RxDatabase } from 'rxdb';

import { createTenantCollections, USER_META_ID } from '../db/db';
import { replicateEntitySchemas } from '../entities/utils/replicateEntitySchemas';
import { replicateLayoutDefinitions } from '../entities/utils/replicateLayoutDefinitions';
import { replicateSettings } from '../entities/utils/replicateSettings';
import {
  ReplicationEvent,
  ReplicationEventType,
  ReplicationId,
} from '../entities/utils/replication.types';
import { IUser } from '../hooks/useUser';

import { queueTask } from './task-queue';

/**
 * Ignore all new replication tasks while running
 * `stopReplicationForAllTenants`.
 */
let inProgressCleanUpTask: Promise<void> | null = null;

type Replication = { reSync: () => void; cancel: () => Promise<void> };

const replications = new Map<number, Replication>();

export function getAllReplicatingTenants(): Set<number> {
  return new Set(replications.keys());
}

export async function startReplicationForTenant({
  tenant,
  db,
  refreshToken,
  onReplicationEvent,
}: {
  tenant: number;
  db: RxDatabase;
  refreshToken: () => void;
  onReplicationEvent: (event: ReplicationEvent) => void;
}) {
  if (!db.isLeader()) {
    throw new Error('This tab is not the leader');
  }

  async function startReplicationForTenantTask(signal: AbortSignal) {
    console.debug(`Starting replication for tenant ${tenant}.`);

    // If a replication for this tenant is already running, just call `reSync()`
    // instead of starting a new replication. This will trigger a new replication
    // cycle which will emit all events we care about.
    if (replications.has(tenant)) {
      console.debug(`ReSyncing existing replication state.`);
      const replication = replications.get(tenant);
      replication.reSync();
      return;
    }

    console.debug('Starting a new replication.');

    const userDoc = await db.getLocal<{ user: IUser }>(USER_META_ID);

    if (signal.aborted) {
      throw signal.reason;
    }

    const user: IUser = userDoc.get('user');

    // If a tenant is not found, do not start a replication. This can happen if
    // you manually navigate to a URL with a tenant you don't have access to.
    if (!user.mandanten.find((mandant) => mandant.code === tenant)) {
      console.debug('Tenant not found. Aborting.');
      return;
    }

    const makeReportEvent =
      (replicationId: ReplicationId) =>
      (replicationEventType: ReplicationEventType) => {
        onReplicationEvent({ id: replicationId, type: replicationEventType });
      };

    await createTenantCollections(db, tenant);

    if (signal.aborted) {
      throw signal.reason;
    }

    const replicationParams = {
      user,
      tenant,
      db,
      refreshToken,
    };

    const settingsReplication = replicateSettings({
      ...replicationParams,
      reportEvent: makeReportEvent('settings'),
    });

    const layoutsReplication = replicateLayoutDefinitions({
      ...replicationParams,
      reportEvent: makeReportEvent('layouts'),
    });

    const schemasReplication = replicateEntitySchemas({
      ...replicationParams,
      reportEvent: makeReportEvent('schemas'),
    });

    const combinedReplication: Replication = {
      reSync() {
        settingsReplication.reSync();
        layoutsReplication.reSync();
        schemasReplication.reSync();
      },
      async cancel() {
        await Promise.all([
          settingsReplication.cancel(),
          layoutsReplication.cancel(),
          schemasReplication.cancel(),
        ]);
      },
    };

    replications.set(tenant, combinedReplication);
  }

  await queueTask(startReplicationForTenantTask);
}

export async function stopReplicationForTenant({ tenant }: { tenant: number }) {
  if (inProgressCleanUpTask) return;

  async function stopReplicationForTenantTask() {
    console.debug(`Stopping replications for tenant ${tenant}...`);

    const replication = replications.get(tenant);

    if (replication) {
      await replication.cancel();
      replications.delete(tenant);
      console.debug(`Stopped replications for tenant ${tenant}.`);
    } else {
      console.warn(
        `Tried to stop replication for tenant ${tenant} which is not running.`
      );
    }
  }

  await queueTask(stopReplicationForTenantTask);
}

export async function stopReplicationForAllTenants() {
  if (inProgressCleanUpTask) return inProgressCleanUpTask;

  async function stopReplicationForAllTenantsTask() {
    console.debug('Stopping all replications...');

    const promises: Promise<void>[] = [];

    for (const replication of replications.values()) {
      promises.push(replication.cancel());
    }

    await Promise.all(promises);

    replications.clear();

    console.debug('Stopped all replications.');
  }

  inProgressCleanUpTask = queueTask(stopReplicationForAllTenantsTask, {
    cancelPending: true,
  });

  try {
    await inProgressCleanUpTask;
  } finally {
    inProgressCleanUpTask = null;
  }
}
