import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { StatisticTimeRange } from '../Enums/StatisticTimeRange.enum';

import { StatisticsSalesVolumeByCustomerColumn } from './StatisticsSalesVolumeByCustomerColumn.entity';

export class StatisticSalesVoumeByCustomer<T extends EMode = EMode.entity> {
  /** Alias: start */
  start?: string;
  /** Alias: end */
  end?: string;
  /** Alias: timeSpanUnit */
  timeSpanUnit?: StatisticTimeRange;
  /** Alias: columnCount */
  columnCount?: number;
  /** Alias: columns */
  columns?: StatisticsSalesVolumeByCustomerColumn<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<StatisticSalesVoumeByCustomer> = {
  start: {
    alias: 'start',
  },
  end: {
    alias: 'end',
  },
  timeSpanUnit: {
    alias: 'timeSpanUnit',
  },
  columnCount: {
    alias: 'columnCount',
  },
  columns: {
    alias: 'columns',
    entity: Entities.statisticsSalesVolumeByCustomerColumn,
  },
  __typename: {
    alias: '__typename',
  },
};

export const statisticSalesVoumeByCustomerEntityDefinition: EntitiyDefinition<StatisticSalesVoumeByCustomer> =
  {
    local: {},
    remote: {
      queryName: 'statisticSalesAmountByCustomer',
      fragmentName: 'StatisticSalesVoumeByCustomer',
      withPaginationWrapper: false,
      params: [
        { name: 'rangeType', type: 'StatisticTimeRange!' },
        { name: 'rangeStart', type: 'Date!' },
        { name: 'rangeEnd', type: 'Date' },
        { name: 'onlyRaInvoices', type: 'Boolean' },
        { name: 'useDifferentBillingAddressIfUsed', type: 'Boolean' },
        { name: 'costCenterFilter', type: 'Int' },
        { name: 'customerCode', type: 'Int' },
      ],
    },
    fieldDefinitions: fields,
  };
