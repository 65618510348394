import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { InboundInvoice } from './InboundInvoice.entity';

export class ShadowRe<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: name */
  name?: string;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: sdObjMemberCode */
  sdObjMemberCode?: number;
  /** Alias: data */
  data?: InboundInvoice<T>;

  __typename?: string;
}

const fields: FieldDefinitions<ShadowRe> = {
  id: {
    alias: 'id',
  },
  name: {
    alias: 'name',
  },
  userId: {
    alias: 'benutzerCode',
  },
  sdObjMemberCode: {
    alias: 'sdObjMemberCode',
  },
  data: {
    alias: 'data',
    entity: Entities.inboundInvoice,
  },
  __typename: {
    alias: '__typename',
  },
};

export const shadowReEntityDefinition: EntitiyDefinition<ShadowRe> = {
  local: {},
  remote: {
    queryName: 'getShadowRe',
    fragmentName: 'ShadowRe',
    withPaginationWrapper: false,
    params: [
      { name: 'id', type: 'ID' },
      { name: 'sdObjMemberCode', type: 'Int' },
      { name: 'projectCode', type: 'Int' },
    ],
  },
  fieldDefinitions: fields,
};
