import { Breakpoint, breakpoints } from './variables';

export const getMaxBreakpoint = (...args: Breakpoint[]): Breakpoint => {
  let max = args[0];
  args.forEach((b) => {
    if (breakpoints[b] > breakpoints[max]) {
      max = b;
    }
  });

  return max;
};
