import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BooleanPicker } from '../../../../components/entity-picker/boolean-picker/BooleanPicker';
import { FilterCard } from '../filter-card/FilterCard';

export type IEmailKindFilterCardProps = {
  title: string;
  value: { id: string; name: string }[] | null;
  onChange: (value: { id: string; name: string }[]) => void;
  expanded: boolean;
  onExpandedChange: (val: boolean) => void;
};

export const BooleanFilterCard: React.FC<IEmailKindFilterCardProps> = (
  props
) => {
  const { title, value, onChange, expanded, onExpandedChange } = props;

  const { t } = useTranslation();

  const filterValueText: string = useMemo(() => {
    if (!value || value.length === 0) return null;
    if (value.length === 1) return t('BOOLEAN_FILTER.' + value[0].id);
    return value.length + ' ' + t('COMMON.CHOSEN');
  }, [value, t]);

  return (
    <FilterCard
      title={title}
      filterValue={filterValueText}
      filterValueSet={!!filterValueText}
      expanded={expanded}
      onExpandedChange={onExpandedChange}
      onValueDelete={() => {
        onChange([]);
      }}
    >
      <BooleanPicker
        multiple={true}
        value={value}
        onChange={(newValue) => {
          onChange(newValue);
        }}
      />
    </FilterCard>
  );
};
