import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FastForwardIcon from '@mui/icons-material/FastForward';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';

import { DISMISS_POPOVER } from '../../../../context-menu/ContextMenu';

import { useTableContextMenuContext } from './table-context-menu-context';
import { TableContextMenuConvertView } from './TableContextMenuConvertView';

export function TableContextMenuRootView() {
  const { t } = useTranslation();

  const { actions } = useTableContextMenuContext();
  const { go } = useHistoryStack();

  const { edit, remove, convert, openInNewTab } = actions;
  return (
    <List disablePadding>
      {openInNewTab && (
        <ListItem dense disableGutters disablePadding>
          <ListItemButton
            className={DISMISS_POPOVER}
            onClick={openInNewTab.onClick}
          >
            <ListItemIcon>
              <OpenInNewIcon />
            </ListItemIcon>
            <ListItemText>{t('MASK.OPEN_IN_NEW_TAB')}</ListItemText>
          </ListItemButton>
        </ListItem>
      )}

      {edit ? (
        <ListItem dense disableGutters disablePadding>
          <ListItemButton
            className={DISMISS_POPOVER}
            onClick={edit.onClick}
            disabled={edit.disabled}
          >
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText>{t('MASK.EDIT')}</ListItemText>
          </ListItemButton>
        </ListItem>
      ) : null}

      {convert ? (
        <ListItem dense disableGutters disablePadding>
          <ListItemButton
            onClick={() => {
              go({
                title: t('MASK.CONVERT'),
                view: <TableContextMenuConvertView />,
              });
            }}
          >
            <ListItemIcon>
              <FastForwardIcon />
            </ListItemIcon>
            <ListItemText>{t('MASK.CONVERT')}</ListItemText>
          </ListItemButton>
        </ListItem>
      ) : null}

      {remove ? (
        <ListItem dense disableGutters disablePadding>
          <ListItemButton
            className={DISMISS_POPOVER}
            disabled={remove.disabled}
            onClick={remove.onClick}
          >
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>{t('MASK.REMOVE')}</ListItemText>
          </ListItemButton>
        </ListItem>
      ) : null}
    </List>
  );
}
