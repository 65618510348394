import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';

import { useSetting } from '@work4all/data/lib/settings/use-setting';

import { settings } from '../../../../../../../../settings';
import { CalendarCardColorBy } from '../../../../../../../calendar/types';

export function ColorsSection() {
  const { t } = useTranslation();

  const calendarAppointmentCardColorBy = useSetting(
    settings.calendarAppointmentCardColorBy()
  );

  return (
    <Collapse title={t('SETTINGS.CALENDAR.APPOINTMENT_COLORS')} defaultOpen>
      <CheckboxRadioItem
        checked={
          calendarAppointmentCardColorBy.value ===
          CalendarCardColorBy.AppointmentStatus
        }
        onChange={() =>
          calendarAppointmentCardColorBy.set(
            CalendarCardColorBy.AppointmentStatus
          )
        }
        control="radio"
        label={t('SETTINGS.CALENDAR.COLORS_BY_APPOINTMENT_STATUS')}
      />
      <CheckboxRadioItem
        checked={
          calendarAppointmentCardColorBy.value === CalendarCardColorBy.User
        }
        onChange={() =>
          calendarAppointmentCardColorBy.set(CalendarCardColorBy.User)
        }
        control="radio"
        label={t('SETTINGS.CALENDAR.COLORS_BY_USER')}
      />
    </Collapse>
  );
}
