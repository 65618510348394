import styles from './FileListGalleryItemThumbnailReal.module.scss';

import { useSecureBlobUrl } from '@work4all/data';

import { Loading } from '../../Loading';

export interface FileListGalleryItemThumbnailRealProps {
  thumbnailUrl: string;
}

export function FileListGalleryItemThumbnailReal(
  props: FileListGalleryItemThumbnailRealProps
) {
  const { thumbnailUrl } = props;

  const url = useSecureBlobUrl(thumbnailUrl);

  if (url) {
    return <img className={styles.image} src={url} alt="" />;
  } else {
    return <Loading />;
  }
}
