import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { User } from './User.entity';

export class PostIt<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: datum */
  date?: string;
  /** Alias: objType */
  objType?: number;
  /** Alias: objCode */
  objId?: number;
  /** Alias: formName */
  formName?: string;
  /** Alias: body */
  body?: string;
  /** Alias: userCode */
  userId?: number;
  /** Alias: user */
  user?: User<T>;

  __typename?: string;
}

const fields: FieldDefinitions<PostIt> = {
  id: {
    alias: 'code',
  },
  date: {
    alias: 'datum',
  },
  objType: {
    alias: 'objType',
  },
  objId: {
    alias: 'objCode',
  },
  formName: {
    alias: 'formName',
  },
  body: {
    alias: 'body',
  },
  userId: {
    alias: 'userCode',
  },
  user: {
    alias: 'user',
    entity: Entities.user,
  },
  __typename: {
    alias: '__typename',
  },
};

export const postItEntityDefinition: EntitiyDefinition<PostIt> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'PostIt',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
