import React from 'react';

import { DropItem } from './DropItem';
import { FullScreenDropZone } from './FullScreenDropZone';
import { useImprintDropActions } from './useImprintDropActions';

export const ImprintPreview: React.FC = () => {
  const dropActions = useImprintDropActions();
  const components = dropActions.map((props) => (
    <DropItem key={props.text} {...props} />
  ));

  return <FullScreenDropZone>{components}</FullScreenDropZone>;
};

export const isImprintRouteAvailable = () => {
  //filter empty indexes, in case the URL ends with "/" which will cause it not to work
  const paths = window.location.pathname.split('/').filter((path) => !!path);
  const last = paths[paths.length - 1];

  const validRoutes = ['suppliers', 'customers', 'projects', 'home', 'more'];

  return paths.length === 2 && validRoutes.includes(last);
};
