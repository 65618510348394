import React, { useContext } from 'react';

export const EntityRightsContext = React.createContext({
  create: true,
  read: true,
  update: true,
  delete: true,
});

export const useEntityRightsContext = () => {
  const rights = useContext(EntityRightsContext);
  return rights;
};
