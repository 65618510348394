import { useEffect, useMemo, useState } from 'react';

import { useDataProvider } from '@work4all/data';

import { VisitationReport } from '@work4all/models/lib/Classes/VisitationReport.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export function useVisitationReportPreviewData(
  visitationReportIds: number[],
  requestedEntityData: object
): VisitationReport[] {
  const [dataResult, setDataResult] = useState<VisitationReport[]>();

  const requestData = useMemo<DataRequest>(() => {
    return {
      entity: Entities.visitationReport,
      data: requestedEntityData,
      filter: [{ id: { $in: visitationReportIds } }],
    };
  }, [visitationReportIds]);

  const { data } = useDataProvider<VisitationReport>(requestData);

  useEffect(() => {
    if (data && data[0]) {
      setDataResult(data);
    }
  }, [data]);

  return dataResult;
}
