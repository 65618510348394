import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class SalesOpportunityRatingStatus<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: name */
  name?: string;
  /** Alias: ratingValue */
  ratingValue?: number;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: comment */
  comment?: string;

  __typename?: string;
}

const fields: FieldDefinitions<SalesOpportunityRatingStatus> = {
  id: {
    alias: 'id',
  },
  name: {
    alias: 'name',
  },
  ratingValue: {
    alias: 'ratingValue',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  comment: {
    alias: 'comment',
  },
  __typename: {
    alias: '__typename',
  },
};

export const salesOpportunityRatingStatusEntityDefinition: EntitiyDefinition<SalesOpportunityRatingStatus> =
  {
    local: {},
    remote: {
      queryName: 'getSalesOpportunityRatingStatus',
      fragmentName: 'SalesOpportunityRatingStatus',
      withPaginationWrapper: false,
      mutations: {
        upsert: {
          mutationName: 'upsertSalesOpportunityRatingStatus',
          args: [
            { name: 'input', type: 'InputSalesOpportunityRatingStatus!' },
            { name: 'drySave', type: 'Boolean' },
          ],
        },
      },
      params: [{ name: 'filter', type: 'String' }],
    },
    fieldDefinitions: fields,
  };
