import { HomeWidgetType } from './HomeWidgetType.enum';

export const DEFAULT_HOME_WIDGETS = [
  {
    id: HomeWidgetType.calendarWidget,
    type: HomeWidgetType.calendarWidget,
  },
  {
    id: HomeWidgetType.overviewWidget,
    type: HomeWidgetType.overviewWidget,
  },
  {
    id: HomeWidgetType.relevantWidget,
    type: HomeWidgetType.relevantWidget,
  },
  {
    id: HomeWidgetType.timeTracker,
    type: HomeWidgetType.timeTracker,
  },
];
