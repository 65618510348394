import styles from './Upload.module.scss';

import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AddIcon } from '@work4all/assets/icons/outline-add-24.svg';

import { IconButtonWithTooltip } from '../IconButtonWithTooltip';
import { BaseActionProps } from '../types';

type UploadProps = BaseActionProps & { noTitle?: boolean };
export const Upload: React.FC<UploadProps> = (props) => {
  const { t } = useTranslation();
  const title = props.noTitle ? undefined : t('MASK.ADD_TO');
  return (
    <div className={clsx(styles.fileUploadInputWrap)}>
      <IconButtonWithTooltip tooltip={title} icon={<AddIcon />} {...props} />
      <input
        className={styles.fileUploadInput}
        type="file"
        name="upload"
        disabled={props.disabled}
        multiple={true}
        onChange={props.onChange}
      />
    </div>
  );
};
