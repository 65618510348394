import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  OrderPicker,
  OrderPickerProps,
} from '@work4all/components/lib/components/entity-picker/OrderPicker';
import { IPickerActionsProps } from '@work4all/components/lib/components/entity-picker/types';
import { ChipList } from '@work4all/components/lib/dataDisplay/chip/ChipList';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { reactRefSetter } from '@work4all/utils';

import {
  Chip,
  LabeledInput,
} from '../../containers/mask-overlays/locked-inputs';

import { EntityPickerActions } from './EntityPickerActions';

interface OrderPickerFieldProps<TMultiple extends boolean>
  extends Omit<OrderPickerProps<TMultiple>, 'multiple'>,
    IPickerActionsProps {
  label?: string;
  error?: string;
  disabled?: boolean;
  field?: JSX.Element;
  multiple?: boolean;
  onPopoverOpen?: () => void;
  onPopoverClose?: () => void;
}

const createOrderPickerField = <TMultiple extends boolean>() => {
  return React.forwardRef<HTMLDivElement, OrderPickerFieldProps<TMultiple>>(
    function OrderPickerField(props, ref) {
      const {
        field,
        error,
        disabled = false,
        label,
        multiple,
        onPopoverOpen,
        onPopoverClose,
        ...pickerProps
      } = props;
      const { value, onChange, clearable = true } = pickerProps;
      const { t } = useTranslation();

      const wrapperRef = useRef<HTMLDivElement>(null);
      const popoverRef = useRef<EntityPickerPopover>(null);

      const computedValue = (Array.isArray(value) ? value : [value]).filter(
        (x) => x
      );
      const labelValue = (
        <ChipList>
          {computedValue.map((x) => {
            return (
              <Chip
                key={x.id}
                maxWidth={18}
                label={x.note || x.number}
                handleDelete={() => {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange(computedValue.filter((y) => y.id !== x.id) as any);
                }}
              />
            );
          })}
        </ChipList>
      );

      const internalField =
        computedValue.length !== 0 ? (
          <LabeledInput
            value={labelValue}
            label={label ?? t('COMMON.ORDER_plural')}
            error={error}
            onClear={
              disabled || !clearable
                ? undefined
                : () => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onChange(multiple ? ([] as any) : null);
                  }
            }
          />
        ) : (
          field
        );

      if (disabled) {
        return internalField;
      }

      const selectedRowIds: Record<string, boolean> = {};
      computedValue.forEach((x) => {
        selectedRowIds[x.id.toString()] = true;
      });
      return (
        <div
          style={computedValue.length ? { width: '100%' } : {}}
          ref={reactRefSetter(wrapperRef, ref)}
        >
          <EntityPickerPopover
            width={560}
            ref={popoverRef}
            anchorEl={wrapperRef}
            picker={<OrderPicker multiple={!!multiple} {...pickerProps} />}
            onOpen={onPopoverOpen}
            onClose={onPopoverClose}
            footer={
              <EntityPickerActions
                disableAddButton
                hideAddButton
                entity={Entities.order}
                multiple={multiple}
                data={props.data}
                initialState={{
                  selectedRowIds: selectedRowIds,
                  filters: props.filters,
                  forceSelection: true,
                }}
                onChange={(value) => {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange(value as any);
                  popoverRef.current?.close();
                }}
              />
            }
          >
            {internalField}
          </EntityPickerPopover>
        </div>
      );
    }
  );
};

export const OrderPickerField = createOrderPickerField();
