import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { IndividualFieldControlType } from '../Enums/IndividualFieldControlType.enum';
import { IndividualFieldTableType } from '../Enums/IndividualFieldTableType.enum';

export class CustomFieldDefinition<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: fieldNumber */
  fieldNumber?: number;
  /** Alias: name */
  name?: string;
  /** Alias: tooltip */
  tooltip?: string;
  /** Alias: index */
  index?: number;
  /** Alias: fieldType */
  fieldType?: IndividualFieldControlType;
  /** Alias: tableType */
  tableType?: IndividualFieldTableType;
  /** Alias: pflichtFeld */
  required?: boolean;

  __typename?: string;
}

const fields: FieldDefinitions<CustomFieldDefinition> = {
  id: {
    alias: 'code',
  },
  fieldNumber: {
    alias: 'fieldNumber',
  },
  name: {
    alias: 'name',
  },
  tooltip: {
    alias: 'tooltip',
  },
  index: {
    alias: 'index',
  },
  fieldType: {
    alias: 'fieldType',
  },
  tableType: {
    alias: 'tableType',
  },
  required: {
    alias: 'pflichtFeld',
  },
  __typename: {
    alias: '__typename',
  },
};

export const customFieldDefinitionEntityDefinition: EntitiyDefinition<CustomFieldDefinition> =
  {
    local: {},
    remote: {
      queryName: 'getCustomFieldDefinition',
      fragmentName: 'CustomFieldDefinition',
      withPaginationWrapper: false,
      params: [{ name: 'tableType', type: 'IndividualFieldTableType' }],
    },
    fieldDefinitions: fields,
  };
