import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { GET_SETTINGS_QUERY } from '@work4all/data/lib/entities/utils/replicateSettings';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';
import { SETTING_NAME_PREFIX } from '@work4all/data/lib/settings/config';
import { useSettingsContext } from '@work4all/data/lib/settings/context';
import { parseSetting } from '@work4all/data/lib/settings/parser';

import { Setting } from '@work4all/models/lib/Classes/Setting.entity';

import { useReplicationState } from '../../replication-state/use-replication-state';

interface GetSettingsQuery {
  getSettings: Setting[];
}

export const useCalendarSettingsSync = () => {
  const tenant = useTenant();

  const { isReady: isSettingsStoreReady } = useReplicationState('settings');

  const filterPrefix = `${SETTING_NAME_PREFIX}t-${tenant.activeTenant}.calendar`;
  const { data, loading } = useQuery<GetSettingsQuery>(GET_SETTINGS_QUERY, {
    variables: { filterPrefix },
    skip: isSettingsStoreReady,
  });

  const { syncSettings } = useSettingsContext();

  const [isReady, setIsReady] = useState(isSettingsStoreReady);

  useEffect(() => {
    if (loading) return;
    if (data?.getSettings)
      syncSettings(
        data.getSettings.map((x) =>
          parseSetting(
            { ...x, _id: `${x.name}|${x.settingType}` },
            { tenant: tenant.activeTenant }
          )
        )
      );
    setIsReady(true);
  }, [tenant, syncSettings, data, setIsReady, loading]);

  return { isReady };
};
