import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { NotificationType } from '../Enums/NotificationType.enum';
import { ObjectType } from '../Enums/ObjectType.enum';

import { Mention } from './Mention.entity';
import { NotificationUnion } from './NotificationUnion.entity';
import { SaveSendMailJob } from './SaveSendMailJob.entity';
import { User } from './User.entity';

export class Notification<T extends EMode = EMode.entity> {
  /** Alias: notificationType */
  notificationType?: NotificationType;
  /** Alias: parentObjectType */
  parentObjectType?: ObjectType;
  /** Alias: parentObjectId */
  parentObjectId?: string;
  /** Alias: parentObjectCode */
  parentObjectCode?: number;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: ownerUserCode */
  ownerUserCode?: number;
  /** Alias: fromUserCode */
  fromUserCode?: number;
  /** Alias: isRead */
  isRead?: boolean;
  /** Alias: ownerUser */
  ownerUser?: User<T>;
  /** Alias: fromUser */
  fromUser?: User<T>;
  /** Alias: id */
  id?: string;
  /** Alias: objectType */
  objectType?: ObjectType;
  /** Alias: objectId */
  objectId?: string;
  /** Alias: objectCode */
  objectCode?: number;
  /** Alias: object */
  object?: T extends EMode.query
    ? NotificationUnion<T>
    : Mention<T> | SaveSendMailJob<T>;

  __typename?: string;
}

const fields: FieldDefinitions<Notification> = {
  notificationType: {
    alias: 'notificationType',
  },
  parentObjectType: {
    alias: 'parentObjectType',
  },
  parentObjectId: {
    alias: 'parentObjectId',
  },
  parentObjectCode: {
    alias: 'parentObjectCode',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  ownerUserCode: {
    alias: 'ownerUserCode',
  },
  fromUserCode: {
    alias: 'fromUserCode',
  },
  isRead: {
    alias: 'isRead',
  },
  ownerUser: {
    alias: 'ownerUser',
    entity: Entities.user,
  },
  fromUser: {
    alias: 'fromUser',
    entity: Entities.user,
  },
  id: {
    alias: 'id',
  },
  objectType: {
    alias: 'objectType',
  },
  objectId: {
    alias: 'objectId',
  },
  objectCode: {
    alias: 'objectCode',
  },
  object: {
    alias: 'object',
    entity: [Entities.mention, Entities.saveSendMailJob],
  },
  __typename: {
    alias: '__typename',
  },
};

export const notificationEntityDefinition: EntitiyDefinition<Notification> = {
  local: {},
  remote: {
    queryName: 'getNotifications',
    fragmentName: 'Notification',
    withPaginationWrapper: true,
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
