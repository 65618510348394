import LibCameraPhoto from 'jslib-html5-camera-photo';
import { useEffect, useState } from 'react';

import { FacingMode, Resolution } from '../types';

let libCameraPhoto: LibCameraPhoto = null;
let needToClean = false;

let cleanPromise: Promise<void> | null;

export function useLibCameraPhoto(
  videoRef: React.MutableRefObject<HTMLVideoElement>,
  idealFacingMode?: FacingMode,
  idealResolution?: Resolution,
  isMaxResolution?: boolean
) {
  const [mediaStream, setMediaStream] = useState<MediaStream>(null);
  const [cameraStartError, setCameraStartError] = useState<Error>(null);
  const [cameraStopError, setCameraStopError] = useState<Error>(null);

  useEffect(() => {
    if (videoRef && videoRef.current) {
      libCameraPhoto = new LibCameraPhoto(videoRef.current);
    }
  }, [videoRef]);

  useEffect(() => {
    async function enableStream() {
      await cleanPromise;
      needToClean = true;
      try {
        let _mediaStream = null;
        if (isMaxResolution) {
          _mediaStream = await libCameraPhoto.startCameraMaxResolution(
            idealFacingMode
          );
        } else {
          _mediaStream = await libCameraPhoto.startCamera(
            idealFacingMode,
            idealResolution
          );
        }
        setMediaStream(_mediaStream);
        setCameraStartError(null);
      } catch (cameraStartError) {
        setCameraStartError(cameraStartError);
      }
    }

    if (!mediaStream) {
      enableStream();
    } else {
      return function cleanup() {
        const clean = async () => {
          try {
            if (needToClean) {
              needToClean = false;
              await libCameraPhoto.stopCamera();
            }

            // protect setState from component umonted error
            // when the component is umonted videoRef.current == null
            // eslint-disable-next-line react-hooks/exhaustive-deps
            if (videoRef && videoRef.current) {
              setMediaStream(null);
              setCameraStopError(null);
            }
          } catch (cameraStopError) {
            setCameraStopError(cameraStopError);
          }
        };

        cleanPromise = clean();
      };
    }
  }, [
    videoRef,
    mediaStream,
    idealFacingMode,
    idealResolution,
    isMaxResolution,
  ]);

  function getDataUri(configDataUri) {
    return libCameraPhoto.getDataUri(configDataUri);
  }

  return { mediaStream, cameraStartError, cameraStopError, getDataUri };
}
