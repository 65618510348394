import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TreeNode } from '@work4all/components/lib/dataDisplay/tree/SelectableTree';

import { useModuleRights } from '@work4all/data/lib/hooks/use-module-rights';
import { useUser } from '@work4all/data/lib/hooks/useUser';

import { checkUserRight } from '@work4all/models/lib/additionalEnums/UserRights';

import { StatisticDefinitionItem } from '../data/StatisticDefinitions';

export const useGenerateStatisticTreeNode = (props: {
  definifion: StatisticDefinitionItem[];
}): TreeNode[] => {
  const { t } = useTranslation();
  const user = useUser();
  const { rights } = useModuleRights();
  const { definifion } = props;
  const parentIdx = '0';
  const translationFile = 'STATISTIC';

  const generateTreeNode = useCallback(
    (def: StatisticDefinitionItem[] = definifion, pIdx = parentIdx) => {
      return def.map((def, idx) => {
        //needed module not given
        if (
          def.needsModule &&
          !rights.find(
            (right) =>
              right.type === def.needsModule && right.hasAccess === true
          )
        ) {
          return undefined;
        }
        if (
          def.anyOfRights &&
          !def.anyOfRights.find((right) =>
            checkUserRight(right, user.benutzerRechte)
          )
        ) {
          //no rights given
          return undefined;
        }

        let children = def.children
          ? generateTreeNode(def.children, `${pIdx}-${idx}`).filter(
              (el) => el !== undefined
            )
          : undefined;

        if (children?.length === 0) {
          children = undefined;
          if (!def.statisticType) {
            //this is not a deeplink dont return a parent node at all
            return undefined;
          }
        }

        const result: TreeNode = {
          id: def.statisticType || def.title,
          label: (t && t(translationFile + '.' + def.title)) || def.title,
          children: children,
        };
        return result;
      });
    },
    [definifion, rights, t, user.benutzerRechte]
  );

  const treeNode = useMemo(() => {
    return generateTreeNode(definifion, parentIdx).filter(
      (el) => el !== undefined
    );
  }, [definifion, generateTreeNode]);
  return treeNode;
};
