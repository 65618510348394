import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { ModulName } from '../Enums/ModulName.enum';

export class LicenseModulInfo<T extends EMode = EMode.entity> {
  /** Alias: benutzerModulAssignIndex */
  userModulAssignIndex?: number;
  /** Alias: assignedToCurrentUserContext */
  assignedToCurrentUserContext?: boolean;
  /** Alias: isLegacyModule */
  isLegacyModule?: boolean;
  /** Alias: moduleIndex */
  moduleIndex?: number;
  /** Alias: moduleCount */
  moduleCount?: number;
  /** Alias: module */
  module?: ModulName;

  __typename?: string;
}

const fields: FieldDefinitions<LicenseModulInfo> = {
  userModulAssignIndex: {
    alias: 'benutzerModulAssignIndex',
  },
  assignedToCurrentUserContext: {
    alias: 'assignedToCurrentUserContext',
  },
  isLegacyModule: {
    alias: 'isLegacyModule',
  },
  moduleIndex: {
    alias: 'moduleIndex',
  },
  moduleCount: {
    alias: 'moduleCount',
  },
  module: {
    alias: 'module',
  },
  __typename: {
    alias: '__typename',
  },
};

export const licenseModulInfoEntityDefinition: EntitiyDefinition<LicenseModulInfo> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'LicenseModulInfo',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
