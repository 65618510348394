import React, {
  KeyboardEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  actions,
  Filters,
  Row,
  SortingRule,
  TableInstance,
  TableState,
  useBlockLayout,
  useColumnOrder,
  useExpanded,
  useFilters,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { VariableSizeList } from 'react-window';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { CardConfig } from '@work4all/models/lib/table-schema/card-config';

import { markSectionsByDate } from '@work4all/utils/lib/mark-sections-by-date';
import { reactRefSetter } from '@work4all/utils/lib/reactRefSetter';

import { EventType, sendAmplitudeData } from '../../utils/amplitude/amplitude';

import { ILoaderProps, Loader } from './components/loader/Loader';
import { EditableCell } from './components/row-render/components/editable-cell/EditableCell';
import { GROUP_ROW_MARKER } from './components/row-render/components/grouped/Grouped';
import { DraggableRowProvider } from './components/row-render/hooks/use-draggable-row';
import { Table } from './components/table/Table';
import { TableBody } from './components/table-body/TableBody';
import { TableFooter } from './components/table-footer/TableFooter';
import { TableFooterContext } from './components/table-footer/TableFooterContext';
import {
  IHeaderProps,
  TableHeader,
} from './components/table-header/TableHeader';
import { BodyScroll } from './hooks/body-scroll/BodyScroll';
import { ColumnVisibilityContext } from './hooks/useColumnVisibility';
import { useFilterTypes } from './hooks/useFilterTypes';
import { useGroupBy } from './hooks/useGroupBy';
import { useOnRowExpanded } from './hooks/useOnRowExpanded';
import { useResizeColumns } from './hooks/useResizeColumns';
import { ITableStateBag, useTableStateBag } from './hooks/useTableStateBag';
import {
  CellEditHandler,
  EditModeTableInstance,
  useEditMode,
} from './plugins/useEditMode';
import {
  PrepareTableRowModifiers,
  useRowDisplayModifiers,
} from './plugins/useRowDisplayModifiers';
import { useSticky } from './plugins/useSticky';
import {
  BasicTableColumn,
  ColumnInstance,
  DataType,
  ICssClasses,
  RowsLength,
  TableMode,
  TableRow,
} from './types';
import {
  makeRowsSelectable,
  SELECT_BOX_MARKER,
  SELECTION_COLUMN_ID,
} from './utils/makeRowsSelectable';

/**
 * Dragging works bad for top header row in case you have multiple header rows
 */

/**
 * There is a situation when user doesn't know total count, but has complete "data" object
 * where not yet fetched rows are replaced with skeletons. When rows are expanded/collapsed
 * total count will change. It is not efficient to calculate total count by hand.
 * "rows.length" will give you the same result, but it's more performant and easier to use.
 * @param {number | 'rowsLength'} allItemsCount
 */

export interface IBasicTableProps
  extends Pick<
    ILoaderProps,
    'bottomPadding' | 'fitContentHeight' | 'scrollRef'
  > {
  cardsView?: boolean;
  resizableColumns?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Array<Record<string, any> | null>;
  columns: BasicTableColumn[];
  cardConfig?: CardConfig | null;
  defaultHidden?: string[];
  initialSortBy?: SortingRule<object>[];
  reordableColumns?: IHeaderProps['reordableColumns'];
  selectableRows?: boolean;
  /**
   * @default true
   */
  selectableMultiple?: boolean;
  onVisibleColumnsChange?: (cols: ColumnInstance[]) => void;
  onAllColumnsChange?: (cols: ColumnInstance[]) => void;
  onStateChange?: (state: TableState) => void;
  allItemsCount?: number | RowsLength;
  loadMoreItems?: ILoaderProps['loadMoreItems'];
  isItemLoaded?: ILoaderProps['isItemLoaded'];
  loadGroups?: (state: TableState, instance: TableInstance) => void;
  onRowDoubleClick?: ILoaderProps['onRowDoubleClick'];
  onRowContextMenu?: ILoaderProps['onRowContextMenu'];
  onRowExpanded?: (row: TableRow) => void;
  onRowClick?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    row: TableRow
  ) => void;
  mode: TableMode;
  classes?: ICssClasses;
  rowHeightRem?: ILoaderProps['rowHeightRem'];
  noHeaderSeperator?: boolean;
  manualGroupBy?: boolean;
  isVirtual?: boolean;
  className?: string;
  onSelectedRowsChange?: (selectedRows: Row[]) => void;
  /**
   * Called when the input element, used to edit the value, is blurred, or when a user presses Enter key.
   * This function is not called when the value has not actually changed after editing, or if the new value is empty.
   * In these cases the old value will be kept instead.
   */
  onCellEdit?: CellEditHandler;
  prepareRowDisplayModifiers?: PrepareTableRowModifiers;
  displayFooter?: boolean;
  footerData?: unknown;
  entity?: Entities;
  forceSelection?: boolean;
  pending?: boolean;
  draggable?: boolean;

  /**
   * Render custom content when table has 0 rows and `pending` prop is false.
   * This content will replace normal table body.
   */
  noRowsRenderer?: () => React.ReactNode;
}

export function getRowId(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  row: any,
  relativeIndex: number,
  parent: Row<object>
): string {
  const id: string | undefined = row.id || row.primaryKey;
  const groupByVal: string | undefined = row.meta?.groupByVal;

  const ownId = String(id ?? groupByVal ?? `r${relativeIndex}`);
  const fullId = parent ? [parent.id, ownId].join('.') : ownId;

  return fullId;
}

export const BasicTable = React.memo(
  React.forwardRef<TableInstance, IBasicTableProps>(function BasicTable(
    props,
    ref
  ) {
    const {
      loadGroups,
      isVirtual = true,
      onSelectedRowsChange,
      selectableMultiple = true,
      cardConfig = null,
      displayFooter = false,
      pending,
      noRowsRenderer,
    } = props;

    const { t } = useTranslation();

    const defaultColumn = React.useMemo(
      () => ({
        // Default options for column configs when no overrides are provided
        minWidth: 50,
        EditableCell,
      }),
      []
    );

    const skipPageResetRef = useRef(false);
    const tableStateBag = useTableStateBag();
    const filterTypes = useFilterTypes();
    const { underPressSelect, setUnderPressSelect } = tableStateBag ?? {};
    useEffect(() => {
      // After the table has updated, always remove the flag
      if (skipPageResetRef) {
        skipPageResetRef.current = false;
      }
    });

    const manualOperations = props.mode === 'server';
    const cardsView = props.cardsView === true;

    const data = useMemo(() => {
      // prevent resetting of groupings, filters, sorting, etc on data update.
      skipPageResetRef.current = true;

      const sortBy = tableStateBag?.tableState?.sortBy ?? props.initialSortBy;
      const groupBy = tableStateBag?.tableState?.groupBy;

      const sortById = sortBy?.[0]?.id;
      const sortColumn = props.columns.find((column) => {
        const id = column.id ?? (column.accessor as string);
        return id === sortById;
      });
      const isSortedByDate = sortColumn?.dataType === DataType.Date;
      const isGrouped = groupBy != null && groupBy.length > 0;

      const items =
        props.data?.map((item) => {
          return item ?? { skeleton: true };
        }) ?? [];

      if (isSortedByDate && !isGrouped) {
        return markSectionsByDate({
          items: props.data,
          getDate(item) {
            return item[sortById];
          },
          mapFn(item, { isSectionStart, sectionLabel }) {
            return { ...item, meta: { isSectionStart, sectionLabel } };
          },
        });
      }

      return items;

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data, t]);

    const tableInstance = useTable(
      {
        columns: props.columns,
        data,
        defaultColumn,
        getRowId,
        filterTypes,
        manualFilters: manualOperations,
        manualSortBy: manualOperations,
        manualGroupBy: props.manualGroupBy,
        autoResetGroupBy: !skipPageResetRef?.current,
        autoResetResize: !skipPageResetRef?.current,
        autoResetPage: !skipPageResetRef?.current,
        autoResetExpanded: !skipPageResetRef?.current,
        autoResetSelectedRows: !skipPageResetRef?.current,
        autoResetSortBy: !skipPageResetRef?.current,
        autoResetFilters: !skipPageResetRef?.current,
        autoResetRowState: !skipPageResetRef?.current,
        onCellEdit: props.onCellEdit,
        disableSortRemove: true,
        disableMultiSort: true,
        initialState: {
          hiddenColumns: [...(props.defaultHidden ?? []), SELECTION_COLUMN_ID],
          sortBy: props.initialSortBy ?? [],
          selectedRowIds: tableStateBag?.initialState?.selectedRowIds || {},
          filters: tableStateBag?.initialState?.filters || [],
        },
      },
      useColumnOrder,
      useBlockLayout,
      useResizeColumns,
      useFilters,
      useGroupBy,
      useSortBy,
      useExpanded,
      useRowSelect,
      useSticky,
      (hooks) => {
        if (props.selectableRows && selectableMultiple) {
          makeRowsSelectable(hooks, props.classes?.selection);
        }
      },
      useEditMode,
      useRowDisplayModifiers(props.prepareRowDisplayModifiers)
      // eslint-disable-next-line @typescript-eslint/ban-types
    ) as TableInstance<object> & EditModeTableInstance;

    useEffect(() => {
      reactRefSetter(ref)(tableInstance);

      return () => {
        reactRefSetter(ref)(null);
      };
    }, [ref, tableInstance]);

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      allColumns: defAllColumns,
      visibleColumns,
      prepareRow,
      setColumnOrder,
      state,
      columns: tableInstanceCols,
      toggleAllRowsExpanded,
      selectedFlatRows,
    } = tableInstance;

    const [lastInteraction, setLastInteraction] = useState<{
      rowId: string;
      selected: boolean;
    } | null>(null);

    // Reset selected rows whenever the dataset changes.
    // We are using row indexes as row ids, so we need to reset selection
    // every time the order might change.
    // Otherwise there might be issues with selected rows changing unpredictably.
    useEffect(() => {
      tableInstance.dispatch({ type: actions.resetSelectedRows });
      setLastInteraction(null);
    }, [
      tableInstance,
      tableInstance.state.groupBy,
      tableInstance.state.filters,
      tableInstance.state.sortBy,
    ]);

    const [previousFilters, setPreviousFilters] = useState<Filters<object>>([]);

    useEffect(() => {
      const newFilters = tableInstance.state.filters.filter(
        (x) => !previousFilters.find((y) => y.id === x.id)
      );
      setPreviousFilters(tableInstance.state.filters);
      newFilters.forEach((filter) => {
        sendAmplitudeData(EventType.FilterList, {
          name: props.entity,
          filterElement: filter.id,
        });
      });
    }, [previousFilters, props.entity, tableInstance.state.filters]);

    const tableBodyRef = useRef<HTMLDivElement>(null);

    const onRowClick = useCallback(
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>, row: TableRow) => {
        // Check if the click event happened inside the table or if it's coming from a portal.
        if (
          !(
            e.target instanceof Node && tableBodyRef.current?.contains(e.target)
          )
        ) {
          // Event originated in a portal. Ignore.
          return;
        }

        const ctrlKey = e.ctrlKey || e.metaKey;

        if (props.selectableRows) {
          const element = e.target as Element;
          // If clicked on a group row (except the checkbox)
          if (element.closest(`[data-${GROUP_ROW_MARKER}=true]`)) {
            if (!element.closest(`[data-${SELECT_BOX_MARKER}=true]`)) return;
          }

          // When in single-selection mode, select only the clicked row.
          if (!selectableMultiple) {
            tableInstance.toggleAllRowsSelected(false);
            row.toggleRowSelected(true);
            setLastInteraction({ rowId: row.id, selected: true });
            return;
          }

          const shiftKey = e.shiftKey;
          const clickedCheckbox = element.closest(
            `[data-${SELECT_BOX_MARKER}=true]`
          );

          if (shiftKey) {
            if (ctrlKey && !lastInteraction) {
              return;
            }

            if (!ctrlKey) {
              tableInstance.toggleAllRowsSelected(false);
            }

            const start =
              lastInteraction !== null
                ? tableInstance.flatRows.findIndex(
                    (r) => r.id === lastInteraction.rowId
                  )
                : 0;
            const end = tableInstance.flatRows.findIndex(
              (r) => r.id === row.id
            );

            if (start === -1 || end === -1) return;

            const setSelected = !ctrlKey
              ? true
              : lastInteraction?.selected ?? false;

            for (let i = Math.min(start, end); i <= Math.max(start, end); i++) {
              tableInstance.toggleRowSelected(
                tableInstance.flatRows[i].id,
                setSelected
              );
            }
          } else {
            let setSelected = !row.isSelected;

            if (!ctrlKey && !clickedCheckbox && !underPressSelect) {
              setSelected = true;
              tableInstance.toggleAllRowsSelected(false);
            }

            row.toggleRowSelected(setSelected);
            setLastInteraction({ rowId: row.id, selected: setSelected });
          }
        }
      },
      [
        props.selectableRows,
        selectableMultiple,
        tableInstance,
        lastInteraction,
        underPressSelect,
      ]
    );

    const columnVisibilityContext = useContext(ColumnVisibilityContext);
    const toggleColumnVisibility =
      columnVisibilityContext?.toggleColumnVisibility;

    const tableStateBagRef = useRef<ITableStateBag>();
    tableStateBagRef.current = tableStateBag;

    const forceSelection =
      props.forceSelection === undefined
        ? !!tableStateBag?.initialState?.forceSelection
        : props.forceSelection;
    // This will fire right away after mount,
    // so technically it does not behave like "onChange".
    // If this is critical, we can add a check to skip the first change.
    // I don't think it matters at the moment.
    useEffect(() => {
      if (onSelectedRowsChange) {
        onSelectedRowsChange(selectedFlatRows);
      }

      if (selectedFlatRows.length === 0) {
        setUnderPressSelect?.(false);
      }

      const column = defAllColumns.find(
        (column) => column.id === SELECTION_COLUMN_ID
      );

      const groupBy = tableStateBagRef.current?.tableState?.groupBy;
      const isGrouped = groupBy != null && groupBy.length > 0;
      if (isGrouped) return;

      if (forceSelection) {
        if (!column?.isVisible)
          toggleColumnVisibility && toggleColumnVisibility(SELECTION_COLUMN_ID);
        return;
      } else if (
        (selectedFlatRows.length < 2 && column?.isVisible) ||
        (selectedFlatRows.length > 1 && !column?.isVisible)
      ) {
        toggleColumnVisibility && toggleColumnVisibility(SELECTION_COLUMN_ID);
      }
    }, [
      defAllColumns,
      onSelectedRowsChange,
      selectedFlatRows,
      setUnderPressSelect,
      toggleColumnVisibility,
      forceSelection,
    ]);

    const allColumns = defAllColumns;

    useOnRowExpanded(state.expanded, rows as TableRow[], props.onRowExpanded);

    const setColumnsById = tableStateBag?.setColumnsById;
    useEffect(() => {
      if (setColumnsById) {
        setColumnsById(
          tableInstanceCols.reduce((acc, col) => {
            acc[col.id] = col;
            return acc;
          }, {})
        );
      }
    }, [tableInstanceCols, setColumnsById]);

    useEffect(() => {
      if (tableStateBag) {
        tableStateBag.setVisibleColumns(visibleColumns);
      }
      if (props.onVisibleColumnsChange) {
        props.onVisibleColumnsChange(visibleColumns);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibleColumns]);

    useEffect(() => {
      if (props.onAllColumnsChange) {
        props.onAllColumnsChange(allColumns);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allColumns]);

    useEffect(() => {
      if (tableStateBag) {
        tableStateBag.setTableState(state);
      }
      if (props.onStateChange) {
        props.onStateChange(state);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    const apiRef = tableStateBag?.apiRef;
    useEffect(() => {
      if (!apiRef) {
        return;
      }

      apiRef.current = { toggleAllRowsExpanded };
    }, [apiRef, toggleAllRowsExpanded]);

    useEffect(() => {
      if (manualOperations && loadGroups) {
        loadGroups(state, tableInstance);
      }
    }, [state, manualOperations, tableInstance, loadGroups]);

    const headerElementRef = useRef<HTMLDivElement>(null);
    const footerElementRef = useRef<HTMLDivElement>(null);

    const [isTableScrolledToLeft, setIsTableScrolledToLeft] = useState(false);
    const [isTableScrolledToRight, setIsTableScrolledToRight] = useState(true);

    const handleTableBodyScroll = ({
      scrollLeft,
      scrollWidth,
      clientWidth,
    }: {
      scrollLeft: number;
      scrollWidth: number;
      clientWidth: number;
    }): void => {
      if (headerElementRef.current) {
        headerElementRef.current.scrollLeft = scrollLeft;
      }
      if (footerElementRef.current) {
        footerElementRef.current.scrollLeft = scrollLeft;
      }
      setIsTableScrolledToLeft(scrollLeft > 0);
      setIsTableScrolledToRight(clientWidth + scrollLeft < scrollWidth);
    };

    const listRef = useRef<VariableSizeList>();

    useEffect(() => {
      if (!headerElementRef?.current) return;
      const { scrollLeft, scrollWidth, clientWidth } = headerElementRef.current;
      handleTableBodyScroll({ scrollLeft, scrollWidth, clientWidth });
    }, []);

    const onKeyDownHandler = useCallback<KeyboardEventHandler<HTMLDivElement>>(
      (e) => {
        if (
          (e.code === 'ArrowUp' || e.code === 'ArrowDown') &&
          selectedFlatRows?.length <= 1
        ) {
          const currentlySelected = selectedFlatRows[0];
          let rowToActivateId = '0';
          let rowToActivateIdx = 0;
          if (currentlySelected) {
            const activeRowId = currentlySelected.id;
            //find next
            const activeRowIdx = rows.findIndex(
              (row) => row.id === activeRowId
            );

            if (activeRowIdx !== 0 || activeRowIdx < rows.length) {
              rowToActivateIdx = Math.min(
                rows.length - 1,
                Math.max(
                  0,
                  e.code === 'ArrowUp' ? activeRowIdx - 1 : activeRowIdx + 1
                )
              );

              tableInstance.toggleRowSelected(activeRowId);
              rowToActivateId = rows[rowToActivateIdx].id;
            }
          }
          listRef.current?.scrollToItem(rowToActivateIdx);
          tableInstance.toggleRowSelected(rowToActivateId);
        }
      },
      [selectedFlatRows, tableInstance, rows]
    );

    return (
      <DraggableRowProvider draggable={props.draggable}>
        <Table
          className={props.className}
          {...getTableProps()}
          data-sticky-scroll-left={isTableScrolledToLeft ? 'scroll' : undefined}
          data-sticky-scroll-right={
            isTableScrolledToRight ? 'scroll' : undefined
          }
          onKeyDown={onKeyDownHandler}
        >
          {cardsView ? (
            <div></div>
          ) : (
            <TableHeader
              elementRef={headerElementRef}
              flatColumns={visibleColumns}
              headerGroups={headerGroups}
              setColumnOrder={setColumnOrder}
              reordableColumns={props.reordableColumns}
              resizableColumns={props.resizableColumns}
              noSeperator={props.noHeaderSeperator}
              classes={props.classes}
              groupBy={state.groupBy}
            />
          )}

          <TableBody ref={tableBodyRef} {...getTableBodyProps()}>
            {pending === false && rows.length === 0 && noRowsRenderer ? (
              <BodyScroll onScroll={handleTableBodyScroll}>
                {noRowsRenderer()}
              </BodyScroll>
            ) : (
              <Loader
                cardsView={cardsView}
                ref={listRef}
                cardConfig={cardConfig}
                isVirtual={isVirtual}
                columnResizing={state.columnResizing}
                columnOrder={state.columnOrder}
                prepareRow={prepareRow}
                rows={rows as TableRow[]}
                loadMoreItems={props.loadMoreItems}
                allItemsCount={props.allItemsCount}
                onRowDoubleClick={props.onRowDoubleClick}
                onRowClick={props.onRowClick ?? onRowClick}
                onRowContextMenu={props.onRowContextMenu}
                mode={props.mode}
                classes={props.classes}
                rowHeightRem={props.rowHeightRem}
                visibleColumns={visibleColumns}
                manualGroupBy={props.manualGroupBy}
                minimumBatchSize={100}
                threshold={30}
                width={tableInstance.totalColumnsWidth}
                onScroll={handleTableBodyScroll}
                bottomPadding={props.bottomPadding}
                fitContentHeight={props.fitContentHeight}
                scrollRef={props.scrollRef}
              />
            )}
          </TableBody>

          {displayFooter && !cardsView && (
            <TableFooterContext data={props.footerData}>
              <TableFooter
                elementRef={footerElementRef}
                flatColumns={visibleColumns}
                headerGroups={headerGroups}
                classes={props.classes}
                groupBy={state.groupBy}
              />
            </TableFooterContext>
          )}
        </Table>
      </DraggableRowProvider>
    );
  })
);
