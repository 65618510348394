export enum IFileInfoPanelItems {
  Address = 'address',
  Phone = 'phone',
  Number = 'number',
  ContactOptions = 'contactOptions',
  SubscriptionContract = 'subscriptionContract',
  Opportunities = 'opportunities',
  FirstContact = 'firstContact',
  ContactList = 'contactList',
  Created = 'created',
  Categories = 'categories',
  Group = 'group',
  Payment = 'payment',
  Sales = 'sales',
  OpenDuePositions = 'openDuePositions',
  Note = 'note',
  Relations = 'relations',
  Website = 'website',
  ShowFunction = 'showFunctionInAddress',

  ProjectDirectory = 'projectDirectory',
  CustomerSupplier = 'customerSupplier',
  InternalProjectLeaders = 'internalProjectLeaders',
  CustomerProjectLeaders = 'customerProjectLeaders',
  Kind = 'kind',
  EventPeriod = 'eventPeriod',
  Status = 'status',
  ParentProject = 'parentProject',
  ProjectPeriod = 'projectPeriod',
  Budget = 'budget',
}
