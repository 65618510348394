import React from 'react';

import { TakeRestHeight, YSplit } from '../../../../layout/y-split';
import { MobileNavigation } from '../../../mobile-navigation/MobileNavigation';
import { MobileWrapper } from '../../../mobile-wrapper/MobileWrapper';

export const Mobile: React.FC = (props) => {
  return (
    <YSplit>
      <TakeRestHeight style={{ height: '100%' }}>
        <MobileWrapper>{props.children}</MobileWrapper>
      </TakeRestHeight>
      <MobileNavigation />
    </YSplit>
  );
};
