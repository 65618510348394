export enum ObjectType {
  LIEFERANT = 'LIEFERANT',
  KUNDE = 'KUNDE',
  PROJEKT = 'PROJEKT',
  KALKULATION = 'KALKULATION',
  AUFTRAG = 'AUFTRAG',
  ANGEBOT = 'ANGEBOT',
  RECHNUNG = 'RECHNUNG',
  LIEFERSCHEIN = 'LIEFERSCHEIN',
  BESTELLUNG = 'BESTELLUNG',
  BEDARFSANFORDERUNG = 'BEDARFSANFORDERUNG',
  EINGANGSLIEFERSCHEIN = 'EINGANGSLIEFERSCHEIN',
  PRODUKTIONSAUFTRAG = 'PRODUKTIONSAUFTRAG',
  PRODUKTIONS_SCHRITT = 'PRODUKTIONS_SCHRITT',
  TICKET = 'TICKET',
  AUFGABE = 'AUFGABE',
  TERMIN = 'TERMIN',
  BESUCHSBERICHT = 'BESUCHSBERICHT',
  DOKUMENT = 'DOKUMENT',
  E_MAIL = 'E_MAIL',
  TELEFONAT = 'TELEFONAT',
  NOTIZ = 'NOTIZ',
  BRIEF = 'BRIEF',
  LIEFERANTEN_BEWERTUNG = 'LIEFERANTEN_BEWERTUNG',
  RECHNUNGSEINGAENGE = 'RECHNUNGSEINGAENGE',
  KASSENBELEG = 'KASSENBELEG',
  PROFORMA_KOSTENBELEG = 'PROFORMA_KOSTENBELEG',
  REISEKOSTENBELEG = 'REISEKOSTENBELEG',
  WARTUNGSOBJEKT = 'WARTUNGSOBJEKT',
  VERKAUFSCHANCEN = 'VERKAUFSCHANCEN',
  GUTSCHRIFTEN = 'GUTSCHRIFTEN',
  CHECKLISTEN = 'CHECKLISTEN',
  REISEKOSTENABRECHNUNG = 'REISEKOSTENABRECHNUNG',
  OBJECT_LOCK = 'OBJECT_LOCK',
  POSITION = 'POSITION',
  WARENEINGANG = 'WARENEINGANG',
  ARTIKEL = 'ARTIKEL',
  KUNDENANSPRECHPARTNER = 'KUNDENANSPRECHPARTNER',
  LIEFERANTENANSPRECHPARTNER = 'LIEFERANTENANSPRECHPARTNER',
  CHAT_CHANNEL = 'CHAT_CHANNEL',
  INFOFENSTER_DEFINITION = 'INFOFENSTER_DEFINITION',
  CHAT_CHANNEL_MEMBER = 'CHAT_CHANNEL_MEMBER',
  CHAT_MESSAGE = 'CHAT_MESSAGE',
  API_ANMELDUNG = 'API_ANMELDUNG',
  BENUTZER_MAILADRESSEN = 'BENUTZER_MAILADRESSEN',
  BENUTZER = 'BENUTZER',
  GENERAL_SETTING_ITEM = 'GENERAL_SETTING_ITEM',
  LAYOUT_MAPPING = 'LAYOUT_MAPPING',
  LAYOUT = 'LAYOUT',
  LOGIN = 'LOGIN',
  LOOKUP = 'LOOKUP',
  MANDANT = 'MANDANT',
  MANDANTEN_BENUTZER = 'MANDANTEN_BENUTZER',
  DICTIONARY_NET_TRANSLATION = 'DICTIONARY_NET_TRANSLATION',
  WIDGET = 'WIDGET',
  WIDGET_FIELD = 'WIDGET_FIELD',
  ARCHIF_PDF = 'ARCHIF_PDF',
  ARTIKEL_BUCHUNG = 'ARTIKEL_BUCHUNG',
  ARTIKEL_GRUPPE = 'ARTIKEL_GRUPPE',
  ARTIKEL_TEXT = 'ARTIKEL_TEXT',
  CHARGE = 'CHARGE',
  EINHEIT = 'EINHEIT',
  EK_PREIS = 'EK_PREIS',
  EINZELPREIS = 'EINZELPREIS',
  KASSE_EIN_AUSZAHLUNG = 'KASSE_EIN_AUSZAHLUNG',
  PROVISION = 'PROVISION',
  TICKET_ANHANG = 'TICKET_ANHANG',
  TICKETSYSTEM_IMPORT_PROTOCOL = 'TICKETSYSTEM_IMPORT_PROTOCOL',
  BENUTZER_TAGESSOLL = 'BENUTZER_TAGESSOLL',
  DB_INSTANZ = 'DB_INSTANZ',
  KOMMT_GEHT_KONTO = 'KOMMT_GEHT_KONTO',
  KOMMT_GEHT_PROFIL = 'KOMMT_GEHT_PROFIL',
  KOMMT_GEHT_PROFIL_ITEM = 'KOMMT_GEHT_PROFIL_ITEM',
  KOMMT_GEHT_ZEITSTEMPEL = 'KOMMT_GEHT_ZEITSTEMPEL',
  KOMMT_GEHT_ZWISCHENSALDO = 'KOMMT_GEHT_ZWISCHENSALDO',
  KRANKHEIT = 'KRANKHEIT',
  PAUSEN_DETAILS = 'PAUSEN_DETAILS',
  PAUSEN_REGEL = 'PAUSEN_REGEL',
  URLAUB = 'URLAUB',
  VERKAUFSCHANCE = 'VERKAUFSCHANCE',
  BZ_OBJECT_FREIGABE = 'BZ_OBJECT_FREIGABE',
  SERVER_MANAGER_LOG_ITEM = 'SERVER_MANAGER_LOG_ITEM',
  PROJEKTE_PLANUNG_RUECKMELDUNG = 'PROJEKTE_PLANUNG_RUECKMELDUNG',
  PROJEKT_VERTEILER = 'PROJEKT_VERTEILER',
  PROJEKT_VERTEILER_EINTRAG = 'PROJEKT_VERTEILER_EINTRAG',
  ZEITERFASSUNG = 'ZEITERFASSUNG',
  BENUTZER_GRUPPE = 'BENUTZER_GRUPPE',
  BENUTZER_GRUPPE_ZUORDNUNG = 'BENUTZER_GRUPPE_ZUORDNUNG',
  ENTITY_SCHEMA = 'ENTITY_SCHEMA',
  ARTIKELBILD = 'ARTIKELBILD',
  DATEI = 'DATEI',
  EINSTELLUNG_BROWSER = 'EINSTELLUNG_BROWSER',
  INLINE_REPORT = 'INLINE_REPORT',
  PERFORMANCE_PROTOKOLL = 'PERFORMANCE_PROTOKOLL',
  KUNDEN_INDIVIDUELLER_PREIS = 'KUNDEN_INDIVIDUELLER_PREIS',
  LAGERORT = 'LAGERORT',
  MEHRWERTSTEUER_SATZ = 'MEHRWERTSTEUER_SATZ',
  RA = 'RA',
  RABATT_ZUORDNUNG = 'RABATT_ZUORDNUNG',
  RA_ERLOESKONTO_SPLIT = 'RA_ERLOESKONTO_SPLIT',
  RA_ZAHLUNG = 'RA_ZAHLUNG',
  RE_SACHKONTEN_SPLIT = 'RE_SACHKONTEN_SPLIT',
  RE_ZAHLUNG = 'RE_ZAHLUNG',
  SACHKONTO = 'SACHKONTO',
  SERIENNUMMER = 'SERIENNUMMER',
  STANDORT = 'STANDORT',
  STANDORT_ENTFERNUNG = 'STANDORT_ENTFERNUNG',
  STEUERSCHLUESSEL = 'STEUERSCHLUESSEL',
  WAEHRUNG = 'WAEHRUNG',
  WAEHRUNGS_KURS = 'WAEHRUNGS_KURS',
  EDITOR_BILD = 'EDITOR_BILD',
  PROJEKT_VORGANG = 'PROJEKT_VORGANG',
  TICKET_ART = 'TICKET_ART',
  TICKET_CHECKLISTE_MARK = 'TICKET_CHECKLISTE_MARK',
  TICKETLISTEN_FILTER = 'TICKETLISTEN_FILTER',
  TICKET_POSITION = 'TICKET_POSITION',
  TICKET_KATEGORIE_1 = 'TICKET_KATEGORIE_1',
  FEIERTAG = 'FEIERTAG',
  URLAUBSANSPRUCH = 'URLAUBSANSPRUCH',
  ANHANG_AUFGABE = 'ANHANG_AUFGABE',
  ADRESS_VERKNUEPFUNG = 'ADRESS_VERKNUEPFUNG',
  ANHANG_NOTIZ = 'ANHANG_NOTIZ',
  ANHANG_TELEFONAT = 'ANHANG_TELEFONAT',
  ANHANG_TERMIN = 'ANHANG_TERMIN',
  CHECKLISTE_POSITION = 'CHECKLISTE_POSITION',
  LIEFERANTEN_GRUPPE = 'LIEFERANTEN_GRUPPE',
  KUNDEN_GRUPPE = 'KUNDEN_GRUPPE',
  STAMMDATEN_PICTURE = 'STAMMDATEN_PICTURE',
  TERMIN_FARBE = 'TERMIN_FARBE',
  TERMIN_TEILNEHMER = 'TERMIN_TEILNEHMER',
  VERTEILER = 'VERTEILER',
  VERTEILER_MARK = 'VERTEILER_MARK',
  VERTEILER_KLASSE = 'VERTEILER_KLASSE',
  BANKVERBINDUNG = 'BANKVERBINDUNG',
  DHL_SENDUNG = 'DHL_SENDUNG',
  ERP_ANHANG = 'ERP_ANHANG',
  LIEFERUNG_ART = 'LIEFERUNG_ART',
  PREISSTAFFEL = 'PREISSTAFFEL',
  PREISSTAFFEL_EINSTAND = 'PREISSTAFFEL_EINSTAND',
  STUECKLISTEN_KOMPONENTE = 'STUECKLISTEN_KOMPONENTE',
  PREISSTAFFEL_ITEM = 'PREISSTAFFEL_ITEM',
  SHADOW_BZ_OBJECT_ENTITY = 'SHADOW_BZ_OBJECT_ENTITY',
  STAFFELPREIS = 'STAFFELPREIS',
  STAFFELPREIS_EK = 'STAFFELPREIS_EK',
  STAFFELPREIS_ZUSCHLAG = 'STAFFELPREIS_ZUSCHLAG',
  TEILRECHNUNG_LOGIK = 'TEILRECHNUNG_LOGIK',
  TOUR = 'TOUR',
  TEILRECHNUNG_LOGIK_DETAILS = 'TEILRECHNUNG_LOGIK_DETAILS',
  ZAHLUNGSART = 'ZAHLUNGSART',
  HISTORIE_2 = 'HISTORIE_2',
  HISTORIE_2_ITEM = 'HISTORIE_2_ITEM',
  KASSENABSCHLUSS = 'KASSENABSCHLUSS',
  KASSEN_BUCHUNGEN = 'KASSEN_BUCHUNGEN',
  KASSENABSCHLUSS_DETAILS = 'KASSENABSCHLUSS_DETAILS',
  E_MAIL_ANHANG = 'E_MAIL_ANHANG',
  E_MAIL_SIGNATUR = 'E_MAIL_SIGNATUR',
  E_MAIL_VORLAGE_3 = 'E_MAIL_VORLAGE_3',
  WARTUNGSVERTRAG_POSITION = 'WARTUNGSVERTRAG_POSITION',
  UMBUCHUNG_PROTOKOLL = 'UMBUCHUNG_PROTOKOLL',
  PROJEKT_GRUPPE = 'PROJEKT_GRUPPE',
  PROJEKT_KATEGORIE_ZUORDNUNG = 'PROJEKT_KATEGORIE_ZUORDNUNG',
  PROJEKT_KATEGORIE = 'PROJEKT_KATEGORIE',
  PROJEKT_OBERKATEGORIE = 'PROJEKT_OBERKATEGORIE',
  PROJEKT_UNTERVERZEICHNIS_ALLGEMEIN = 'PROJEKT_UNTERVERZEICHNIS_ALLGEMEIN',
  PROJEKT_VORGANG_LINK = 'PROJEKT_VORGANG_LINK',
  ARTIKEL_GRUPPEN_RECHT = 'ARTIKEL_GRUPPEN_RECHT',
  GR_ANSICHTSRECHT_ARTIKEL = 'GR_ANSICHTSRECHT_ARTIKEL',
  PROJEKT_UNTERVERZEICHNIS_TEMPLATE = 'PROJEKT_UNTERVERZEICHNIS_TEMPLATE',
  GR_ANSICHTSRECHT_LIEFERANTEN = 'GR_ANSICHTSRECHT_LIEFERANTEN',
  GR_ANSICHTSRECHT_PROJEKTE = 'GR_ANSICHTSRECHT_PROJEKTE',
  GR_ANSICHTSRECHT_KUNDEN = 'GR_ANSICHTSRECHT_KUNDEN',
  KUNDE_GRUPPEN_RECHT = 'KUNDE_GRUPPEN_RECHT',
  OBJ_GR_ITEM = 'OBJ_GR_ITEM',
  LIEFERANT_GRUPPEN_RECHT = 'LIEFERANT_GRUPPEN_RECHT',
  DMS_VOLLTEXT = 'DMS_VOLLTEXT',
  KOSTENSTELLE = 'KOSTENSTELLE',
  PROJEKT_GRUPPEN_RECHT = 'PROJEKT_GRUPPEN_RECHT',
  NUMMERNKREIS = 'NUMMERNKREIS',
  STAMMDATEN_SPRACHE = 'STAMMDATEN_SPRACHE',
  REPORT = 'REPORT',
  KUNDENMARK = 'KUNDENMARK',
  ARTIKEL_MARK = 'ARTIKEL_MARK',
  LIEFERANTEN_MARK = 'LIEFERANTEN_MARK',
  PROJEKTE_MARK = 'PROJEKTE_MARK',
  VERKAUFSCHANCEN_MARK = 'VERKAUFSCHANCEN_MARK',
  SUPPORT_MARK = 'SUPPORT_MARK',
  TEMP_DATEI = 'TEMP_DATEI',
  TEXTBAUSTEIN_UEBERSETZUNG = 'TEXTBAUSTEIN_UEBERSETZUNG',
  THEMA = 'THEMA',
  TEXTBAUSTEIN = 'TEXTBAUSTEIN',
  TICKETSYSTEM_PROTOKOLL = 'TICKETSYSTEM_PROTOKOLL',
  ABTEILUNG = 'ABTEILUNG',
  THEMEN_MARK = 'THEMEN_MARK',
  ANREDE = 'ANREDE',
  LAND = 'LAND',
  PREISGRUPPE = 'PREISGRUPPE',
  INDIVIDUAL_FIELD_DEFINITION = 'INDIVIDUAL_FIELD_DEFINITION',
  LOG_ENTRY = 'LOG_ENTRY',
  SHOP_ANSICHT_GRUPPE = 'SHOP_ANSICHT_GRUPPE',
  SHOP_DEFINITION_ROW = 'SHOP_DEFINITION_ROW',
  SHOP_ANSICHT_GRUPPE_ZUORDNUNG = 'SHOP_ANSICHT_GRUPPE_ZUORDNUNG',
  WIKI_BEITRAG = 'WIKI_BEITRAG',
  WIKI_BEITRAG_CONTENT = 'WIKI_BEITRAG_CONTENT',
  WIKI_BEITRAG_SCHLAGWORT = 'WIKI_BEITRAG_SCHLAGWORT',
  WIKI_BEREICH = 'WIKI_BEREICH',
  WIKI_GLIEDERUNG = 'WIKI_GLIEDERUNG',
  SEARCH_INDEX = 'SEARCH_INDEX',
  WIKI_SETTING = 'WIKI_SETTING',
  WIKI_FAVORIT = 'WIKI_FAVORIT',
  WIKI_IMAGE = 'WIKI_IMAGE',
  WIKI_LOG = 'WIKI_LOG',
  API_SESSION = 'API_SESSION',
  ARTIKEL_KATEGORIE = 'ARTIKEL_KATEGORIE',
  ARTIKEL_KATEGORIEN_MARK = 'ARTIKEL_KATEGORIEN_MARK',
  ARTIKEL_KATEGORIEN_MARK_REVERSED = 'ARTIKEL_KATEGORIEN_MARK_REVERSED',
  DOCUMENT_CLASSES = 'DOCUMENT_CLASSES',
  ANSPRECHPARTNER = 'ANSPRECHPARTNER',
  RECYCLE_BIN_ITEM = 'RECYCLE_BIN_ITEM',
  OBJ_GR_ZUORDNUNG = 'OBJ_GR_ZUORDNUNG',
  GESCHAEFTSPARTNER = 'GESCHAEFTSPARTNER',
  CONVERTED_FILE = 'CONVERTED_FILE',
  KURS = 'KURS',
  OBJEKT_DATEI_ZUGRIFF = 'OBJEKT_DATEI_ZUGRIFF',
  PLZ = 'PLZ',
  E_MAIL_PREVIEW_EMBEDED_ATTACHEMENT = 'E_MAIL_PREVIEW_EMBEDED_ATTACHEMENT',
  LETTER_TEMPLATE_GROUP = 'LETTER_TEMPLATE_GROUP',
  WORD_LETTER_TEMPLATE = 'WORD_LETTER_TEMPLATE',
  DOCUMENT_TEMPLATE_GROUP = 'DOCUMENT_TEMPLATE_GROUP',
  WORD_DOCUMENT_TEMPLATE = 'WORD_DOCUMENT_TEMPLATE',
  BACKGROUND_IMAGE = 'BACKGROUND_IMAGE',
  SHADOW_RE = 'SHADOW_RE',
  ORDER_INCOMMING_INVOICE_CONNECTION = 'ORDER_INCOMMING_INVOICE_CONNECTION',
  PRODUKTIONSAUFTRAG_GRUPPE = 'PRODUKTIONSAUFTRAG_GRUPPE',
  GESPRAECHSPUNKT = 'GESPRAECHSPUNKT',
  BESUCHSBERICHT_TEILNEHMER = 'BESUCHSBERICHT_TEILNEHMER',
  BENUTZER_ROLLE = 'BENUTZER_ROLLE',
  AUFTRAGSSTATUS = 'AUFTRAGSSTATUS',
  EVENT_ITEM = 'EVENT_ITEM',
  TICKET_ANHANG_FROM_TEMP = 'TICKET_ANHANG_FROM_TEMP',
  INLINE_IMAGE = 'INLINE_IMAGE',
  E_MAIL_VORLAGE = 'E_MAIL_VORLAGE',
  E_MAIL_VORLAGE_3_ANHANG = 'E_MAIL_VORLAGE_3_ANHANG',
  RESERVIERUNGEN = 'RESERVIERUNGEN',
  SAVE_SEND_MAIL_JOB = 'SAVE_SEND_MAIL_JOB',
  KUNDEN_GR_MARK = 'KUNDEN_GR_MARK',
  LIEFERANTEN_GR_MARK = 'LIEFERANTEN_GR_MARK',
  PROJEKTE_GR_MARK = 'PROJEKTE_GR_MARK',
  REFRESH_LOCKED_SD_OBJECTS_LOGS = 'REFRESH_LOCKED_SD_OBJECTS_LOGS',
  E_MAIL_TEMPLATE_GROUP = 'E_MAIL_TEMPLATE_GROUP',
  USER_COLLECTION = 'USER_COLLECTION',
  USER_COLLECTION_MEMBER = 'USER_COLLECTION_MEMBER',
  CHRONO_FILE_ITEM = 'CHRONO_FILE_ITEM',
  TEXTBAUSTEIN_GRUPPE = 'TEXTBAUSTEIN_GRUPPE',
  TICKET_FILTER = 'TICKET_FILTER',
  POST_IT = 'POST_IT',
  STANDARD_TEXT = 'STANDARD_TEXT',
  LIEFERANTEN_BEDARF_ZUORDNUNG = 'LIEFERANTEN_BEDARF_ZUORDNUNG',
  MENTION = 'MENTION',
  NOTIFICATION = 'NOTIFICATION',
  FILESYSTEM_FILE = 'FILESYSTEM_FILE',
  SAVED_LIST_FILTER = 'SAVED_LIST_FILTER',
  SALES_OPPORTUNITY_GROUP = 'SALES_OPPORTUNITY_GROUP',
  SALES_OPPORTUNITY_RATING = 'SALES_OPPORTUNITY_RATING',
  ZEITKARTE_ARCHIV = 'ZEITKARTE_ARCHIV',
  MAILBOX_CONFIGURATION = 'MAILBOX_CONFIGURATION',
  PROJECT_ACCESS_RIGHTS = 'PROJECT_ACCESS_RIGHTS',
  UNREAD_NOTIFICATION = 'UNREAD_NOTIFICATION',
  THUMBNAIL = 'THUMBNAIL',
  SALES_OPPORTUNITY_RATING_STATUS = 'SALES_OPPORTUNITY_RATING_STATUS',
  PROJECT_ACCESS_GROUP = 'PROJECT_ACCESS_GROUP',
  PROJECT_ACCESS_GROUP_USER = 'PROJECT_ACCESS_GROUP_USER',
  PROJECT_ACCESS_GROUP_PROJECT = 'PROJECT_ACCESS_GROUP_PROJECT',
  SL_MODE = 'SL_MODE',
  INBOUND_INVOICE_PAYMENT = 'INBOUND_INVOICE_PAYMENT',
  SALES_OPPORTUNITY_ATTACHEMENT = 'SALES_OPPORTUNITY_ATTACHEMENT',
  REPORTS_2 = 'REPORTS_2',
  REISEKOSTEN_BELEGARTEN = 'REISEKOSTEN_BELEGARTEN',
  INCOMING_MAIL_BLOCKED_SENDER = 'INCOMING_MAIL_BLOCKED_SENDER',
  TICKET_SPRINT = 'TICKET_SPRINT',
  AUTH_CODE = 'AUTH_CODE',
  REISEKOSTEN_BELEGARTEN_FAHRTKOSTEN = 'REISEKOSTEN_BELEGARTEN_FAHRTKOSTEN',
  REISEKOSTENABRECHNUNG_ZAHLUNGSART = 'REISEKOSTENABRECHNUNG_ZAHLUNGSART',
  UNKNOWN = 'UNKNOWN',
}
