import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { DeleteErrorMessage } from './DeleteErrorMessage.entity';

export class DeleteEntityResult<T extends EMode = EMode.entity> {
  /** Alias: deletedRecords */
  deletedRecords?: number;
  /** Alias: errorMessages */
  errorMessages?: DeleteErrorMessage<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<DeleteEntityResult> = {
  deletedRecords: {
    alias: 'deletedRecords',
  },
  errorMessages: {
    alias: 'errorMessages',
    entity: Entities.deleteErrorMessage,
  },
  __typename: {
    alias: '__typename',
  },
};

export const deleteEntityResultEntityDefinition: EntitiyDefinition<DeleteEntityResult> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'DeleteEntityResult',
      withPaginationWrapper: false,
      mutations: {
        upsert: {
          mutationName: 'deleteEntity',
          args: [
            { name: 'objectType', type: 'ObjectType!' },
            { name: 'keys', type: '[String]!' },
            { name: 'deleteMode', type: 'DeleteMode' },
          ],
        },
      },
    },
    fieldDefinitions: fields,
  };
