import { createContext, useContext } from 'react';

import {
  IAddFavoriteLinkOptions,
  IAddFavoriteLinksFolderOptions,
  ICombineLinksOptions,
  IMoveFavoriteLinkOptions,
  IMoveFavoriteLinksFolderOptions,
  IRenameFavoriteLinksFolderOptions,
} from './other';
import { IFavoriteLinkOrFolder } from './types';

export interface IFavoriteLinksContextValue {
  favoriteLinks: IFavoriteLinkOrFolder[];
  addFavoriteLink: (options: IAddFavoriteLinkOptions) => void;
  moveFavoriteLink: (options: IMoveFavoriteLinkOptions) => void;
  removeFavoriteLink: (linkId: string) => void;
  removeFavoriteLinkByHref: (href: string) => void;
  combineLinks: (options: ICombineLinksOptions) => void;
  removeFavoriteLinksFolder: (id: string) => void;
  moveFavoriteLinksFolder: (options: IMoveFavoriteLinksFolderOptions) => void;
  addFavoriteLinksFolder: (options?: IAddFavoriteLinksFolderOptions) => void;
  renameFavoriteLinksFolder: (
    options: IRenameFavoriteLinksFolderOptions
  ) => void;
}

export const FavoriteLinksContext =
  createContext<IFavoriteLinksContextValue | null>(null);

export function useFavoriteLinks(): IFavoriteLinksContextValue {
  const context = useContext(FavoriteLinksContext);

  if (!context) {
    throw new Error(
      '`useFavoriteLinks` cannot be used outside of <FavoriteLinksContext.Provider />'
    );
  }

  return context;
}
