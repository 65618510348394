import { useCallback, useMemo, useState } from 'react';

import { clamp } from '@work4all/utils/lib/math';

export type IUsePreviewConfig = {
  enabled: boolean;
  minScale: number;
  maxScale: number;
  scaleFactor: number;
};

export type IUsePreviewUnregister = {
  (): void;
};

export type IUsePreviewRegisterOptions = Omit<IUsePreviewConfig, 'enabled'>;

export type IUsePreviewRegister = {
  (options: IUsePreviewRegisterOptions): IUsePreviewUnregister;
};

export type IUsePreview = {
  available: boolean;
  props: {
    register: IUsePreviewRegister;
    scale: number | 'fit';
    onScaleChange: (scale: number) => void;
  };
  scale: number | 'fit';
  canZoomIn: boolean;
  canZoomOut: boolean;
  setScale: (scale: number) => void;
  zoomIn: () => void;
  zoomOut: () => void;
  zoomFit: () => void;
};

const DEFAULT_SCALE_FACTOR = 1.5;
const INITIAL_SCALE = 1;

const INITIAL_CONFIG = {
  enabled: false,
  minScale: Number.NEGATIVE_INFINITY,
  maxScale: Number.POSITIVE_INFINITY,
  scaleFactor: DEFAULT_SCALE_FACTOR,
};

export function usePreview(): IUsePreview {
  const [state, setState] = useState<number | 'fit'>(INITIAL_SCALE);

  const [config, setConfig] = useState<IUsePreviewConfig>(INITIAL_CONFIG);

  const register = useCallback((options: IUsePreviewRegisterOptions) => {
    setConfig({ ...options, enabled: true });
    setState(INITIAL_SCALE);

    return () => {
      setConfig(INITIAL_CONFIG);
      setState(INITIAL_SCALE);
    };
  }, []);

  const setScale = useCallback(
    (scale: number) => {
      const clamped = clamp(scale, config.minScale, config.maxScale);

      setState(clamped);
    },
    [config]
  );

  const updateScale = useCallback(
    (updater: (scale: number) => number) => {
      setState((currentScale) => {
        if (currentScale === 'fit') {
          throw new Error(
            "Tried to update the scale and expected it to be a number, but it was 'fit'. This is likely an error in one of the preview components. Whenever the scale prop is set to 'fit', it is expected, that the component will call 'onScaleChange' with the new numeric scale value."
          );
        }

        const newScale = updater(currentScale);
        const clamped = clamp(newScale, config.minScale, config.maxScale);
        return clamped;
      });
    },
    [config]
  );

  const zoomIn = useCallback(() => {
    updateScale((currentScale) => currentScale * config.scaleFactor);
  }, [config, updateScale]);

  const zoomOut = useCallback(() => {
    updateScale((currentScale) => currentScale / config.scaleFactor);
  }, [config, updateScale]);

  const zoomFit = useCallback(() => {
    setState('fit');
  }, []);

  const props = useMemo(
    () => ({ register, scale: state, onScaleChange: setScale }),
    [register, state, setScale]
  );

  return useMemo(
    () => ({
      available: config.enabled,
      props,
      scale: state,
      // @ts-expect-error TECH_DEBT
      canZoomIn: config.enabled && state < config.maxScale,
      // @ts-expect-error TECH_DEBT
      canZoomOut: config.enabled && state > config.minScale,
      setScale,
      zoomIn,
      zoomOut,
      zoomFit,
    }),
    [config, props, state, setScale, zoomIn, zoomOut, zoomFit]
  );
}
