import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { UserType } from '../Enums/UserType.enum';

import { UserLicenseInformationItem } from './UserLicenseInformationItem.entity';

export class UserLicenseInformation<T extends EMode = EMode.entity> {
  /** Alias: userType */
  userType?: UserType;
  /** Alias: defaultRoles */
  defaultRoles?: any[];
  /** Alias: modules */
  modules?: UserLicenseInformationItem<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<UserLicenseInformation> = {
  userType: {
    alias: 'userType',
  },
  defaultRoles: {
    alias: 'defaultRoles',
  },
  modules: {
    alias: 'modules',
    entity: Entities.userLicenseInformationItem,
  },
  __typename: {
    alias: '__typename',
  },
};

export const userLicenseInformationEntityDefinition: EntitiyDefinition<UserLicenseInformation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'UserLicenseInformation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
