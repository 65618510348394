import { useEffect, useState } from 'react';

import { DND_POPOVER_DROP_ENABLED_DELAY } from '@work4all/utils/lib/variables';

/**
 * Checks whether drop should be allowed on target or not.
 * Primarity designed to be used with popovers, so that,
 * when a popover opens while dragging an item,
 * it will not close right away if a user is hovering
 * a drag item over a valid drop target.
 *
 * Drop is considered disabled for `DND_POPOVER_DROP_ENABLED_DELAY`.
 * After that the drag item must be moved outside of drop target
 * and back inside for it to become active.
 *
 * @param isOver result of calling `monitor.isOver()`
 * @returns whether or not drop target should eb considered enabled.
 */
export function useDropEnabled(isOver: boolean): boolean {
  const [timedOut, setTimedOut] = useState(false);
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTimedOut(true);
    }, DND_POPOVER_DROP_ENABLED_DELAY);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (!enabled && timedOut && !isOver) {
      setEnabled(true);
    }
  }, [timedOut, enabled, isOver]);

  return enabled;
}
