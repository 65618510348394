/* eslint-disable jsx-a11y/anchor-is-valid */
import { ButtonBase } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useHistoryStack } from '../../navigation/history-stack';
import { Body1 } from '../../typography/body1/Body1';
import { Heading1 } from '../../typography/heading1/Heading1';

/**
 * in this example I provide viewIndex to getCurrentViewState
 * to make sure that correct local state is used
 * on transition effect.
 * TODO: bind correct viewIndex to getCurrentViewState
 */

const SubItem1 = () => {
  const {
    setObjectionListener: addObjectionListener,
    setCurrentViewState,
    getCurrentViewState,
    viewIndex,
    confirm,
  } = useHistoryStack();
  const { t } = useTranslation();
  return (
    <div style={{ padding: '1rem' }}>
      <Heading1>SubItem 2</Heading1>
      <Body1>Value: </Body1>
      <input
        type="text"
        value={getCurrentViewState<string>('text2', viewIndex) || ''}
        onChange={(e) => {
          setCurrentViewState<string>('text2', e.target.value);
          if (e.target.value) {
            addObjectionListener(() => {
              return confirm(t('ALERTS.UNSAVED_CHANGES'));
            });
          } else {
            addObjectionListener(null);
          }
        }}
      />
    </div>
  );
};

const SubItem = () => {
  const {
    setObjectionListener: addObjectionListener,
    go,
    setCurrentViewState,
    getCurrentViewState,
    viewIndex,
    confirm,
  } = useHistoryStack();
  const { t } = useTranslation();
  return (
    <div style={{ padding: '1rem' }}>
      <Heading1>SubItem 1</Heading1>
      <Body1>Value: </Body1>
      <input
        type="text"
        value={getCurrentViewState<string>('text1', viewIndex) || ''}
        onChange={(e) => {
          setCurrentViewState<string>('text1', e.target.value);
          if (e.target.value) {
            addObjectionListener(() => {
              return confirm(t('ALERTS.UNSAVED_CHANGES'));
            });
          } else {
            addObjectionListener(null);
          }
        }}
      />
      <br />
      <ButtonBase
        onClick={() => {
          go({ view: <SubItem1 />, title: 'SubItem2' });
        }}
      >
        <a href="#" onClick={(e) => e.preventDefault()}>
          SubItem1
        </a>
      </ButtonBase>
    </div>
  );
};

export const DummyOverlay: React.FC<{ type: string }> = (props) => {
  return (
    <div style={{ padding: '1rem' }}>
      <Heading1>{props.type}</Heading1>
      {/* <Heading3>Activeview Url: {overlayMatch?.params.activeviewurl}</Heading3>
      <Heading3>Entity Id: {overlayMatch?.params.entityid}</Heading3>
      <br />
      <Heading3>Current Page: {match?.params.page}</Heading3>
      <Heading3>Current Page Id: {match?.params.id}</Heading3>
      <br />
      <Body1>Value: </Body1>
      <input
        type="text"
        value={getCurrentViewState<string>('text', viewIndex) || ''}
        onChange={(e) => {
          setCurrentViewState<string>('text', e.target.value);
          if (e.target.value) {
            addObjectionListener(() => {
              return confirm(
                'unsafe changes. are you sure you want to close overlay?'
              );
            });
          } else {
            addObjectionListener(null);
          }
        }}
      />
      <br />
      <ButtonBase
        onClick={() => {
          go({ view: <SubItem />, title: 'SubItem1' });
        }}
      >
        <a href="#" onClick={(e) => e.preventDefault()}>
          SubItem
        </a>
      </ButtonBase> */}
    </div>
  );
};
