import { Hooks } from 'react-table';

import { TableRow, TableRowDisplayModifiers } from '../types';

export type PrepareTableRowModifiers<T = unknown> = (
  value: T
) => TableRowDisplayModifiers;

export function useRowDisplayModifiers<T = unknown>(
  prepareModifiers: PrepareTableRowModifiers<T> | undefined
) {
  function prepareRow(row: TableRow) {
    row.displayModifiers = prepareModifiers(row.original as unknown as T);
  }

  return (hooks: Hooks) => {
    if (!prepareModifiers) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hooks.prepareRow.push(prepareRow as any);
  };
}
