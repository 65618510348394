import styles from './styles.module.scss';

import SystemUpdateIcon from '@mui/icons-material/SystemUpdate';
import { Backdrop, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Modal } from '@work4all/components/lib/components/modal/Modal';

const titleId = 'api-update-available-mobile-modal-title';
const descriptionId = 'api-update-available-mobile-modal-description';

interface AppUpdateAvailableMobileAlertProps {
  open: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
}

export function AppUpdateAvailableMobileAlert({
  open,
  onConfirm,
  onDismiss,
}: AppUpdateAvailableMobileAlertProps) {
  const { t } = useTranslation();

  return (
    <Modal
      aria-labelledby={titleId}
      aria-describedby={descriptionId}
      open={open}
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500, className: styles.backdrop }}
      sx={{
        zIndex: 9999,
      }}
    >
      <div className={styles.root}>
        <div className={styles.content}>
          <SystemUpdateIcon className={styles.icon} />

          <div>
            <Typography
              id={titleId}
              className={styles.title}
              variant="h4"
              component="h2"
            >
              {t('APP_UPDATES.APP_UPDATE_AVAILABLE_MOBILE.TITLE')}
            </Typography>

            <Typography
              id={descriptionId}
              className={styles.description}
              variant="body2"
            >
              {t('APP_UPDATES.APP_UPDATE_AVAILABLE_MOBILE.DESCRIPTION')}
            </Typography>
          </div>
        </div>

        <div className={styles.actions}>
          <Button size="large" color="primary" fullWidth onClick={onDismiss}>
            {t('APP_UPDATES.APP_UPDATE_AVAILABLE_MOBILE.BUTTONS.DISMISS')}
          </Button>
          <Button size="large" color="primary" fullWidth onClick={onConfirm}>
            {t('APP_UPDATES.APP_UPDATE_AVAILABLE_MOBILE.BUTTONS.CONFIRM')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
