import styles from './MaskOverlay.module.scss';

import { noop } from 'lodash';
import React from 'react';

import { DummyOverlay } from '@work4all/components/lib/dataDisplay/dummy-overlay';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Form } from './components/form';
import { MaskOverlayHeader } from './components/MaskOverlayHeader/MaskOverlayHeader';
import { MaskContextProvider } from './hooks/mask-context';

interface Props {
  entity: Entities;
}
export const RestOverlay: React.FC<Props> = ({ entity }) => {
  return (
    <MaskContextProvider
      value={{
        entity,
        isCreateMode: true,
        isEditMode: false,
        mode: 'create',
        params: null,
        wip: true,
      }}
    >
      <Form className={styles.maskForm} onSubmit={noop}>
        <MaskOverlayHeader title={'Not yet implemented'} tabs={null} />
        <div className={styles.tabsWrapper}>
          <DummyOverlay type={'todo'} />
        </div>
      </Form>
    </MaskContextProvider>
  );
};
