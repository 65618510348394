import { useCallback, useMemo, useState } from 'react';

import { Dialog } from './Dialog';
import { useDuplicatesData, UseDuplicatesDataProps } from './useDuplicatesData';

export const useDuplicates = ({
  entity,
  postalCode,
  street,
}: UseDuplicatesDataProps) => {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState<{
    handleSave: () => void;
    handleCancel: () => void;
  }>();
  const { data } = useDuplicatesData({
    entity,
    postalCode,
    street,
  });

  const userResponse = async () => {
    return new Promise((resolve) => {
      const handleSave = () => {
        resolve('save');
      };

      const handleCancel = () => {
        resolve('cancel');
      };

      setState({
        handleSave,
        handleCancel,
      });
    });
  };

  const duplicatesHandler = useCallback(
    async (execute: () => void, skip?: boolean) => {
      if (skip || data.length === 0) {
        execute();
      } else {
        setOpen(true);
        const response = await userResponse();
        if (response === 'save') {
          setOpen(false);
          execute();
        } else if (response === 'cancel') {
          setOpen(false);
        }
      }
    },
    [data.length]
  );

  return useMemo(() => {
    return {
      duplicatesHandler,
      DuplicatesDialog: () => (
        <Dialog
          open={open}
          data={data}
          onSave={state?.handleSave}
          onCancel={state?.handleCancel}
        />
      ),
    };
  }, [data, duplicatesHandler, open, state?.handleCancel, state?.handleSave]);
};
