import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class CustomField<T extends EMode = EMode.entity> {
  /** Alias: key */
  key?: number;
  /** Alias: value */
  value?: string;

  __typename?: string;
}

const fields: FieldDefinitions<CustomField> = {
  key: {
    alias: 'key',
  },
  value: {
    alias: 'value',
  },
  __typename: {
    alias: '__typename',
  },
};

export const customFieldEntityDefinition: EntitiyDefinition<CustomField> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'CustomField',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
