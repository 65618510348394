import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Calculation } from './Calculation.entity';
import { Contract } from './Contract.entity';
import { DeliveryNote } from './DeliveryNote.entity';
import { Demand } from './Demand.entity';
import { InboundDeliveryNote } from './InboundDeliveryNote.entity';
import { Invoice } from './Invoice.entity';
import { Offer } from './Offer.entity';
import { Order } from './Order.entity';

export type ShadowBzObject<T extends EMode = EMode.entity> =
  T extends EMode.entity
    ?
        | Calculation<T>
        | Offer<T>
        | Contract<T>
        | Invoice<T>
        | DeliveryNote<T>
        | Demand<T>
        | Order<T>
        | InboundDeliveryNote<T>
    : {
        /** Alias: Kalkulation */
        calculation?: Calculation<T>;
        /** Alias: Angebot */
        offer?: Offer<T>;
        /** Alias: Auftrag */
        contract?: Contract<T>;
        /** Alias: Rechnung */
        invoice?: Invoice<T>;
        /** Alias: Lieferschein */
        deliveryNote?: DeliveryNote<T>;
        /** Alias: Bedarfsanforderung */
        demand?: Demand<T>;
        /** Alias: Bestellung */
        order?: Order<T>;
        /** Alias: Eingangslieferschein */
        inboundDeliveryNote?: InboundDeliveryNote<T>;
      };

const fields: FieldDefinitions<ShadowBzObject<EMode.query>> = {
  calculation: {
    alias: 'calculation',
    entity: Entities.calculation,
  },
  offer: {
    alias: 'offer',
    entity: Entities.offer,
  },
  contract: {
    alias: 'contract',
    entity: Entities.contract,
  },
  invoice: {
    alias: 'invoice',
    entity: Entities.invoice,
  },
  deliveryNote: {
    alias: 'deliveryNote',
    entity: Entities.deliveryNote,
  },
  demand: {
    alias: 'demand',
    entity: Entities.demand,
  },
  order: {
    alias: 'order',
    entity: Entities.order,
  },
  inboundDeliveryNote: {
    alias: 'inboundDeliveryNote',
    entity: Entities.inboundDeliveryNote,
  },
};

export const shadowBzObjectEntityDefinition: EntitiyDefinition<
  ShadowBzObject<EMode.query>
> = {
  kind: 'union',
  local: {},
  remote: {
    queryName: 'getShadowBzObject',
    fragmentName: 'ShadowBzObject',
    withPaginationWrapper: false,
    mutations: {
      upsert: {
        mutationName: 'getShadowBzObject',
        args: [
          { name: 'id', type: 'ID' },
          { name: 'sdObjMemberCode', type: 'Int' },
          { name: 'sdObjType', type: 'SdObjType' },
          { name: 'bzObjType', type: 'BzObjType' },
          { name: 'bzObjMemberCode', type: 'Int' },
          { name: 'projektCode', type: 'Int' },
        ],
      },
    },
    params: [
      { name: 'id', type: 'ID' },
      { name: 'sdObjMemberCode', type: 'Int' },
      { name: 'sdObjType', type: 'SdObjType' },
      { name: 'bzObjType', type: 'BzObjType' },
      { name: 'bzObjMemberCode', type: 'Int' },
      { name: 'projektCode', type: 'Int' },
    ],
  },
  fieldDefinitions: fields,
};
