import React, { useContext } from 'react';

export interface AppStateProps {
  overlayActive: boolean;
  forcedShareBaseUrl?: string;
}

export const useAppState = () => {
  return useContext(AppState);
};

export const AppState = React.createContext<AppStateProps>({
  overlayActive: false,
});
