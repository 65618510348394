import styles from './Card.module.scss';

import { Card as MUICard, CardProps } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

export interface ICardProps extends CardProps {
  noPadding?: boolean;
}

export const Card: React.FC<ICardProps> = (props: ICardProps) => {
  const { noPadding, ...muiCardProps } = props;
  return (
    <MUICard
      {...muiCardProps}
      className={clsx(styles.wrap, props.className, {
        [styles['no-padding']]: noPadding,
      })}
    >
      {props.children}
    </MUICard>
  );
};
