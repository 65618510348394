import { useControlled } from '@mui/material/utils';
import produce from 'immer';
import { get, isEqual, partition, uniq } from 'lodash';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';

import { useForceUpdate } from '@work4all/utils/lib/hooks/use-force-update';

import { INDIVIDUAL_TAB_ID } from '../mask-overlay/components/custom-fields/contants';

interface BoundUnregisterTabFormInputFunction {
  (): void;
}

interface UnregisterTabFormInputFunction {
  (tab: string | number, name: string): void;
}

interface RegisterTabFormInputFunction {
  (tab: string | number, name: string): BoundUnregisterTabFormInputFunction;
}

export interface MaskTabContextValue {
  value: string | number;
  onChange?: (value: string | number) => void;
  errors: Record<string, number>;
  register: RegisterTabFormInputFunction;
  unregister: UnregisterTabFormInputFunction;
}

export interface MaskTabContextProps {
  value?: string | number;
  defaultValue?: string | number;
  onChange?: (value: string | number) => void;
  children?: React.ReactNode;
}

type TabFields = Record<string, string[]>;

type StateUpdateFunction = (value: TabFields) => void;

const Context = createContext<MaskTabContextValue | null>(null);

export function MaskTabContext(props: MaskTabContextProps) {
  const { onChange, defaultValue: defaultTab, children } = props;

  const [value, setValue] = useControlled({
    controlled: props.value,
    default: defaultTab,
    name: 'MaskTabContext',
    state: 'value',
  });

  const handleChange = useCallback(
    (value: string | number) => {
      setValue(value);
      onChange?.(value);
    },
    [setValue, onChange]
  );

  const form = useFormContext();

  const [fields, setFields] = useState<TabFields>({});
  const forceUpdate = useForceUpdate();

  const updateQueueRef = useRef<StateUpdateFunction[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (updateQueueRef.current.length > 0) {
      const updates = updateQueueRef.current;
      updateQueueRef.current = [];

      const newFields = produce(fields, (draft) => {
        for (const update of updates) {
          update(draft);
        }
      });

      if (!isEqual(fields, newFields)) {
        setFields(newFields);
      }
    }
  });

  const scheduleUpdate = useCallback(
    (update: StateUpdateFunction) => {
      updateQueueRef.current.push(update);
      forceUpdate();
    },
    [forceUpdate]
  );

  const register = useCallback<RegisterTabFormInputFunction>(
    (tab: string | number, name: string) => {
      scheduleUpdate(addTabField(tab, name));

      const unregister = () => {
        scheduleUpdate(removeTabField(tab, name));
      };

      return unregister;
    },
    [scheduleUpdate]
  );

  const unregister = useCallback<UnregisterTabFormInputFunction>(
    (tab, name) => {
      scheduleUpdate(removeTabField(tab, name));
    },
    [scheduleUpdate]
  );

  // TODO refactor <MaskTabs> to use a standalone <Tabs> component which can be used without a form context
  const formState = form?.formState;

  // TODO There are sometimes performance issues with this because the context
  // value is changed every time the `form` is updated. We should find a better
  // way to do this, so that it only updates when the errors change.
  const context = useMemo<MaskTabContextValue>(() => {
    const formErrors = formState?.errors;

    // Keep track of all errors registered to the tabs. This way we can find
    // errors that are not displayed in the form UI and still show them in the
    // error badge for the default tab.
    //
    // Such errors are usually a configuration error (either in the form or in
    // the validation schema), but this way they are at least displayed
    // somewhere, instead of a user just not being able to submit the form with
    // no explanation in the UI.
    //
    // This requires the developers to configure the default tab with the
    // "defaultValue" prop.
    const errorsRegisteredToTabs = new Set<string>();

    const errors = Object.keys(fields).reduce<Record<string, number>>(
      (errors, tab) => {
        // uniq is for count field error once, even if we use same field more than one on the tab
        // it should show 1 error per field in each tab
        const tabErrors = uniq(fields[tab]).reduce<number>(
          (tabErrors, name) => {
            const error = get(formErrors, name);

            if (error) {
              errorsRegisteredToTabs.add(name);
              return tabErrors + 1;
            }

            return tabErrors;
          },
          0
        );

        errors[tab] = tabErrors;

        return errors;
      },
      {}
    );

    const errorsNotRegisteredToTabs = getAllFieldsWithErrors(formErrors).filter(
      (fieldName) => {
        return !errorsRegisteredToTabs.has(fieldName);
      }
    );

    // Try to extract all custom field errors that are not registered to any
    // tab and add them to the Individual tab.

    const [customFieldErrors, otherErrors] = partition(
      errorsNotRegisteredToTabs,
      (field) => field.startsWith('_customFields.')
    );

    const totalCustomFieldErrors = customFieldErrors.length;
    const totalOtherErrors = otherErrors.length;

    if (totalCustomFieldErrors > 0) {
      errors[INDIVIDUAL_TAB_ID] ??= 0;
      errors[INDIVIDUAL_TAB_ID] += totalCustomFieldErrors;
    }

    if (totalOtherErrors > 0) {
      errors[defaultTab] ??= 0;
      errors[defaultTab] += totalOtherErrors;
    }

    return {
      value,
      onChange: handleChange,
      errors,
      register,
      unregister,
    };
  }, [
    value,
    handleChange,
    fields,
    register,
    unregister,
    formState,
    defaultTab,
  ]);

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

/**
 * Returns the context value of the MaskTabContext. If this hook is called
 * outside of a MaskTabContext, it will throw.
 */
export function useMaskTabContext(): MaskTabContextValue {
  const context = useContext(Context);

  if (context === null) {
    throw new Error('useMaskTabContext must be used within a <MaskTabContext>');
  }

  return context;
}

function addTabField(tab: string | number, name: string) {
  return (draft: TabFields) => {
    draft[tab] = draft[tab] ?? [];
    draft[tab].push(name);
  };
}

function removeTabField(tab: string | number, name: string) {
  return (draft: TabFields) => {
    // TODO If there are duplicate inputs in the same tab, this will
    // unregister all of them when one is unregistered. This is not an
    // issue right now, as there are no masks at the moment with duplicate
    // inputs in the same tab. But it might be a problem in the future. In
    // that case, this should be changed to unregister only the one that
    // actually called the `unregister` function.
    draft[tab] = draft[tab]?.filter((n) => n !== name);
  };
}

function getAllFieldsWithErrors(errors: Record<string, object>): string[] {
  if (!errors) return [];

  // Custom field errors are nested inside the `_customFields` property. All
  // other errors are flat keys in the `errors` object.
  const { _customFields = {}, ...otherFields } = errors;

  return [
    ...Object.keys(_customFields).map((name) => `_customFields.${name}`),
    ...Object.keys(otherFields),
  ];
}
