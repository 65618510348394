import styles from './PositionPicker.module.scss';

import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import clsx from 'clsx';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Position } from '@work4all/models/lib/Classes/Position.entity';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { FilterTextInput } from '../components';
import { toSelectionValue } from '../components/list-entity-picker/utils';
import { useSelectionModel } from '../hooks/use-selection-model';
import { IPickerProps } from '../types';

export interface IPositionPickerProps
  extends Pick<
    IPickerProps<Position, false>,
    'value' | 'onChange' | 'maxItems' | 'clearable' | 'placeholder'
  > {
  positions: Position[];
}

const LIST_ITEM_HEIGHT = 50;

// This is a trimmed down version of ListEntityPicker with customizations for
// the positions UI. For now this is the only component of its kind with a very
// customized picker. If we add more such component later we'll need to improve
// code reuse between them.
export function PositionPicker(props: IPositionPickerProps) {
  const {
    value,
    onChange,
    maxItems = 5,
    clearable = true,
    placeholder,
    positions,
  } = props;

  const { t } = useTranslation();

  const initialSelected = useRef(toSelectionValue(value)).current;

  const selection = useSelectionModel<Position, false>({
    multiple: false,
    keyFn: (position) => position.id,
    initialSelected,
  });

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    selection.setSelected(toSelectionValue(value));
  }, [selection, value]);

  const handleSelect = (value: Position) => {
    selection.select(value);
    onChange(selection.getSelected());
  };

  const handleDeselect = (value: Position) => {
    selection.deselect(value);
    onChange(selection.getSelected());
  };

  const handleToggle = (value: Position) => {
    if (selection.isSelected(value)) {
      if (clearable) {
        handleDeselect(value);
      }
    } else {
      handleSelect(value);
    }
    setQuery('');
    inputRef.current?.focus();
  };

  const [query, setQuery] = useState('');
  const queryTrimmed = query.trim();

  const items = useMemo(() => {
    let items = [...positions];

    if (queryTrimmed !== '') {
      const q = queryTrimmed.toLocaleLowerCase();

      items = items.filter((el) => {
        return (
          el.longtext.toLocaleLowerCase().includes(q) || el.number.includes(q)
        );
      });
    }

    items.sort((a, b) => a.index - b.index);

    return items;
  }, [positions, queryTrimmed]);

  function renderNoResults() {
    return (
      <ListItem className={styles.row} disablePadding>
        <ListItemText
          primary={t('PICKER.NO_RESULTS_FOUND')}
          classes={{ primary: styles.nothingFound }}
        />
      </ListItem>
    );
  }

  function renderRow(position: Position) {
    return (
      <ItemRow
        key={position.id}
        position={position}
        selected={selection.isSelected(position)}
        onToggle={() => handleToggle(position)}
      />
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.search}>
        <FilterTextInput
          ref={inputRef}
          autoFocus
          value={query}
          onChange={setQuery}
          placeholder={placeholder ?? t('PICKER.SEARCH.DEFAULT')}
        />
      </div>

      <div
        style={{
          height: LIST_ITEM_HEIGHT * maxItems,
          overflow: 'auto',
          flex: 'auto',
          isolation: 'isolate',
        }}
      >
        <HeaderRow />
        {items.length === 0 && renderNoResults()}
        {items.map(renderRow)}
      </div>
    </div>
  );
}

const SELECTABLE_POSITION_TYPES: ErpPositionsKind[] = [
  ErpPositionsKind.STANDARD,
  ErpPositionsKind.STUECKLISTE,
];

function ItemRow(props: {
  position: Position;
  selected: boolean;
  onToggle: () => void;
}) {
  const { position, selected, onToggle } = props;

  const interactive = SELECTABLE_POSITION_TYPES.includes(position.positionKind);

  const content = (
    <>
      <span className={styles.mark} />
      <span className={styles.number}>{position.number}</span>
      <span className={styles.text}>{position.longtext}</span>
      <span className={styles.amount}>
        {interactive ? position.amount : null}
      </span>
      <span className={styles.unit}>{interactive ? position.unit : null}</span>
    </>
  );

  if (interactive) {
    return (
      <ListItem disablePadding>
        <Tooltip title={position.longtext}>
          <ListItemButton
            className={styles.row}
            dense
            onClick={() => onToggle()}
            selected={selected}
          >
            {content}
          </ListItemButton>
        </Tooltip>
      </ListItem>
    );
  }

  return (
    <ListItem className={clsx(styles.row, styles.muted)} dense>
      {content}
    </ListItem>
  );
}

function HeaderRow() {
  const { t } = useTranslation();

  return (
    <ListItem className={clsx(styles.row, styles.headerRow, styles.muted)}>
      <span className={styles.mark} />
      <span className={styles.number}>
        {t('POSITIONS_PICKER.COLUMNS.NUMBER')}
      </span>
      <span className={styles.text}>{t('POSITIONS_PICKER.COLUMNS.TEXT')}</span>
      <span className={styles.amount}>
        {t('POSITIONS_PICKER.COLUMNS.AMOUNT')}
      </span>
      <span className={styles.unit}>{t('POSITIONS_PICKER.COLUMNS.UNIT')}</span>
    </ListItem>
  );
}
