import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';

import { useUser } from '@work4all/data';
import { useSetting } from '@work4all/data/lib/settings/use-setting';

import { settings } from '../../../../../../../../settings';
import { CheckboxRadioItem } from '../../../../../../locked-inputs';

export function ExpiryDateSection() {
  const { t } = useTranslation();

  const inboundInvoiceExpireDateCalculationByDateOfReceipt = useSetting(
    settings.inboundInvoiceExpireDateCalculationByDateOfReceipt()
  );

  const user = useUser();

  return (
    <Collapse
      title={t('SETTINGS.INBOUND_INVOICE.CALCUTAION_OF_EXPIRY_DATE')}
      defaultOpen
    >
      <CheckboxRadioItem
        disabled={!user.isMaster}
        checked={inboundInvoiceExpireDateCalculationByDateOfReceipt.value}
        onChange={() =>
          inboundInvoiceExpireDateCalculationByDateOfReceipt.set(true)
        }
        control="radio"
        label={t('SETTINGS.INBOUND_INVOICE.BY_DATE_OF_RECEIPT')}
      />
      <CheckboxRadioItem
        disabled={!user.isMaster}
        checked={!inboundInvoiceExpireDateCalculationByDateOfReceipt.value}
        onChange={() =>
          inboundInvoiceExpireDateCalculationByDateOfReceipt.set(false)
        }
        control="radio"
        label={t('SETTINGS.INBOUND_INVOICE.BY_DATE_OF_INVOICE')}
      />
    </Collapse>
  );
}
