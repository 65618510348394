import { useTranslation } from 'react-i18next';

import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';

import { useSetting } from '@work4all/data/lib/settings/use-setting';

import { settings } from '../../../../../../../../settings';
import { Collapse } from '../../../../../components';

export function PreDocumentsSection() {
  const { t } = useTranslation();

  const inboundInvoiceCloseConnectedObjects = useSetting(
    settings.inboundInvoiceCloseConnectedObjects()
  );

  const inboundInvoiceShowOnlyOpenObjects = useSetting(
    settings.inboundInvoiceShowOnlyOpenObjects()
  );

  return (
    <Collapse title={t('SETTINGS.INBOUND_INVOICE.PRE_DOCUMENTS')} defaultOpen>
      <CheckboxRadioItem
        checked={inboundInvoiceCloseConnectedObjects.value}
        onChange={(e) =>
          inboundInvoiceCloseConnectedObjects.set(e.target.checked)
        }
        label={t('SETTINGS.INBOUND_INVOICE.CLOSE_PRE_DOCUMENTS')}
      />
      <CheckboxRadioItem
        checked={inboundInvoiceShowOnlyOpenObjects.value}
        onChange={(e) =>
          inboundInvoiceShowOnlyOpenObjects.set(e.target.checked)
        }
        label={t('SETTINGS.INBOUND_INVOICE.SHOW_ONLY_OPEN_PRE_DOCUMENTS')}
      />
    </Collapse>
  );
}
