import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputEMailAnhangAttachementAdd } from './InputEMailAnhangAttachementAdd.entity';

export class InputEMailAnhangAttachementsRelation<
  T extends EMode = EMode.entity
> {
  /** Alias: add */
  add?: InputEMailAnhangAttachementAdd<T>[];
  /** Alias: remove */
  remove?: number[];

  __typename?: string;
}

const fields: FieldDefinitions<InputEMailAnhangAttachementsRelation> = {
  add: {
    alias: 'add',
  },
  remove: {
    alias: 'remove',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputEMailAnhangAttachementsRelationEntityDefinition: EntitiyDefinition<InputEMailAnhangAttachementsRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputEMailAnhangAttachementsRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
