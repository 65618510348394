import { useLocation } from 'react-router-dom';

/**
 * Checks wheter or not the current page is the first in-app route or not.
 * If it is, then clicking "Back" button or using `history.back()` would
 * result in the app unloading, which is not desirable.
 *
 * @returns `true` if you will remain in the app after using `history.back()`.
 */
export function useCanGoBack() {
  // useLocation is required to reevaluate the route index after navigation
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _unUseButBoundLocation = useLocation();
  //state can be undefined if anchorlinks have been used e.g. on the morePage
  const canGoBack = window.history.state?.idx !== 0;
  return canGoBack;
}
