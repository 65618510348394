import { Theme, useMediaQuery } from '@mui/material';
import React from 'react';

import { Bubble } from '@work4all/components/lib/dataDisplay/bubble/Bubble';

interface IWithBubbleProps {
  count: number;
  children: React.ReactElement;
}

export const WithBubble: React.FC<IWithBubbleProps> = (props) => {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'));
  const show = props.count >= (isDesktop ? 6 : 3);
  if (show) {
    return <Bubble content={props.count} />;
  }

  return props.children;
};
