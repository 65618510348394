import styles from '../StatisticPage.module.scss';

import { LinearProgress } from '@mui/material';
import { DateTime } from 'luxon';
import { useObservableState } from 'observable-hooks';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounceTime, distinctUntilChanged } from 'rxjs';

import { StatisticCompanyReportTable } from '@work4all/components/lib/dataDisplay/basic-table/reporting/StatisticCompanyReportTable';

import { useDataProvider } from '@work4all/data';

import { ManagementDashboard } from '@work4all/models/lib/Classes/ManagementDashboard.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { GeneralSetting, useGeneralSetting } from '../util/use-general-setting';
import { useOldestInvoice } from '../util/use-oldest-invoice';

import { DateWheelPickerField } from './date-wheel-picker-field/DateWheelPickerField';

export function StatisticCompanyReportContainer() {
  const oldestInvoice = useOldestInvoice();
  const startBusinessYearSetting = useGeneralSetting({
    name: GeneralSetting.startBusinessYear,
  });

  const { t } = useTranslation();

  const currentDate = DateTime.now().startOf('year').toJSDate();
  const [currentYear, setCurrentYear] = useObservableState(
    (input$) => input$.pipe(distinctUntilChanged(), debounceTime(200)),
    currentDate
  );
  const [maxYear, setMaxYear] = useState(currentDate);

  useEffect(() => {
    const currentDate = DateTime.now();

    if (currentDate.month < startBusinessYearSetting?.data?.[0]?.value) {
      const date = currentDate
        .minus({ years: 1 })
        .set({ month: startBusinessYearSetting?.data?.[0]?.value })
        .startOf('month')
        .toJSDate();

      setCurrentYear(date);
      setMaxYear(date);
    }
  }, [setCurrentYear, setMaxYear, startBusinessYearSetting?.data]);

  const requestData: DataRequest = useMemo(() => {
    const currentYearToDateTime = DateTime.fromJSDate(currentYear);
    const startDate = currentYearToDateTime;
    const endDate = currentYearToDateTime.plus({ month: 11 }).endOf('month');

    const data: ManagementDashboard = {
      figure: null,
      year: null,
      month: null,
      value: null,
    };

    return {
      entity: Entities.managementDashboard,
      data: data,
      vars: {
        rangeStart: startDate.toFormat('yyyy-MM-dd'),
        rangeEnd: endDate.toFormat('yyyy-MM-dd'),
      },
      completeDataResponse: true,
    };
  }, [currentYear]);

  const data = useDataProvider(requestData);
  const isLoading = data.loading || data.pending;

  return (
    <>
      {isLoading && (
        <LinearProgress
          style={{ position: 'absolute', width: '100%', zIndex: 200 }}
        />
      )}
      <div className={styles.controlBar}>
        <div className={styles.picker}>
          <DateWheelPickerField
            min={
              oldestInvoice?.data?.[0]?.date
                ? new Date(oldestInvoice?.data?.[0]?.date)
                : new Date()
            }
            max={maxYear}
            unit="year"
            label={t('COMMON.BUSINESS_YEAR')}
            value={currentYear}
            onChange={(value) => {
              setCurrentYear(value);
            }}
          />
        </div>
      </div>
      <div className={styles.tableWrapper}>
        <StatisticCompanyReportTable data={data.data as ManagementDashboard} />
      </div>
    </>
  );
}
