import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputAttachementAdd } from './InputAttachementAdd.entity';
import { InputStammdatenAttachementModify } from './InputStammdatenAttachementModify.entity';

export class InputStammdatenAttachementsRelation<
  T extends EMode = EMode.entity
> {
  /** Alias: remove */
  remove?: number[];
  /** Alias: add */
  add?: InputAttachementAdd<T>[];
  /** Alias: modify */
  modify?: InputStammdatenAttachementModify<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputStammdatenAttachementsRelation> = {
  remove: {
    alias: 'remove',
  },
  add: {
    alias: 'add',
  },
  modify: {
    alias: 'modify',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputStammdatenAttachementsRelationEntityDefinition: EntitiyDefinition<InputStammdatenAttachementsRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputStammdatenAttachementsRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
