import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class VatRate<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: satz */
  sentence?: number;
  /** Alias: reihenfolge */
  sequence?: number;
  /** Alias: deaktiviert */
  disabled?: boolean;

  __typename?: string;
}

const fields: FieldDefinitions<VatRate> = {
  id: {
    alias: 'code',
  },
  sentence: {
    alias: 'satz',
  },
  sequence: {
    alias: 'reihenfolge',
  },
  disabled: {
    alias: 'deaktiviert',
  },
  __typename: {
    alias: '__typename',
  },
};

export const vatRateEntityDefinition: EntitiyDefinition<VatRate> = {
  local: {},
  remote: {
    queryName: 'getVatRates',
    fragmentName: 'MehrwertsteuerSatz',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
