import { createContext, useContext } from 'react';

import { ShadowReObjectApiMethods } from './use-shadow-re-object-api';

const ShadowReObjectApiContext = createContext<ShadowReObjectApiMethods>(null);

export const ShadowReObjectApiContextProvider =
  ShadowReObjectApiContext.Provider;

export function useShadowReObjectApiContext(): ShadowReObjectApiMethods {
  const ctx = useContext(ShadowReObjectApiContext);

  if (ctx === null) {
    throw new Error(
      'useShadowReObjectApiContext must be used within a <ShadowReObjectApiContextProvider>'
    );
  }

  return ctx;
}
