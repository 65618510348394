import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Article } from './Article.entity';

export class PurchasePrice<T extends EMode = EMode.entity> {
  /** Alias: artikelCode */
  articleId?: number;
  /** Alias: artikelNummer */
  articleNumber?: string;
  /** Alias: bestellmenge */
  orderAmount?: number;
  /** Alias: code */
  id?: number;
  /** Alias: ean */
  ean?: string;
  /** Alias: einheitCode */
  unitId?: number;
  /** Alias: einkaufpreis */
  purchasePrice?: number;
  /** Alias: faktor */
  factor?: number;
  /** Alias: gueltigAb */
  validFromDate?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: lieferantenCode */
  supplierId?: number;
  /** Alias: lieferantentext */
  supplierText?: string;
  /** Alias: lieferzeit */
  deliveryTime?: number;
  /** Alias: listenpreis */
  listPrice?: number;
  /** Alias: mengeProEinheit */
  amountPerUnit?: number;
  /** Alias: notiz */
  note?: string;
  /** Alias: preisstaffelCode */
  bulkPriceTierId?: number;
  /** Alias: rabatt */
  discount?: number;
  /** Alias: rabatt2 */
  discount2?: number;
  /** Alias: rabatt3 */
  discount3?: number;
  /** Alias: rabatt4 */
  discount4?: number;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: waehrungCode */
  currencyId?: number;
  /** Alias: artikel */
  article?: Article<T>;

  __typename?: string;
}

const fields: FieldDefinitions<PurchasePrice> = {
  articleId: {
    alias: 'artikelCode',
  },
  articleNumber: {
    alias: 'artikelNummer',
  },
  orderAmount: {
    alias: 'bestellmenge',
  },
  id: {
    alias: 'code',
  },
  ean: {
    alias: 'ean',
  },
  unitId: {
    alias: 'einheitCode',
  },
  purchasePrice: {
    alias: 'einkaufpreis',
  },
  factor: {
    alias: 'faktor',
  },
  validFromDate: {
    alias: 'gueltigAb',
  },
  insertTime: {
    alias: 'insertTime',
  },
  supplierId: {
    alias: 'lieferantenCode',
  },
  supplierText: {
    alias: 'lieferantentext',
  },
  deliveryTime: {
    alias: 'lieferzeit',
  },
  listPrice: {
    alias: 'listenpreis',
  },
  amountPerUnit: {
    alias: 'mengeProEinheit',
  },
  note: {
    alias: 'notiz',
  },
  bulkPriceTierId: {
    alias: 'preisstaffelCode',
  },
  discount: {
    alias: 'rabatt',
  },
  discount2: {
    alias: 'rabatt2',
  },
  discount3: {
    alias: 'rabatt3',
  },
  discount4: {
    alias: 'rabatt4',
  },
  updateTime: {
    alias: 'updateTime',
  },
  currencyId: {
    alias: 'waehrungCode',
  },
  article: {
    alias: 'artikel',
    entity: Entities.article,
  },
  __typename: {
    alias: '__typename',
  },
};

export const purchasePriceEntityDefinition: EntitiyDefinition<PurchasePrice> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'Einkaufspreis',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
