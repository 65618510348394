import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

const DraggableContext = createContext<DraggableRowContextProps>({
  draggable: false,
});

interface DraggableRowContextProps {
  draggable: boolean;
}

export const DraggableRowProvider = (
  props: PropsWithChildren<Partial<DraggableRowContextProps>>
) => {
  const { children, draggable } = props;
  const value = useMemo(() => {
    return { draggable };
  }, [draggable]);

  return (
    <DraggableContext.Provider value={value}>
      {children}
    </DraggableContext.Provider>
  );
};

export const useDraggableRow = () => useContext(DraggableContext);
