import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { BusinessPartnerUnion } from './BusinessPartnerUnion.entity';
import { Customer } from './Customer.entity';
import { Supplier } from './Supplier.entity';

export class BusinessPartner<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: data */
  data?: T extends EMode.query
    ? BusinessPartnerUnion<T>
    : Customer<T> | Supplier<T>;
  /** Alias: sdObjType */
  businessPartnerType?: SdObjType;

  __typename?: string;
}

const fields: FieldDefinitions<BusinessPartner> = {
  id: {
    alias: 'code',
  },
  data: {
    alias: 'data',
    entity: [Entities.customer, Entities.supplier],
  },
  businessPartnerType: {
    alias: 'sdObjType',
  },
  __typename: {
    alias: '__typename',
  },
};

export const businessPartnerEntityDefinition: EntitiyDefinition<BusinessPartner> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'Geschaeftspartner',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
