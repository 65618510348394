import styles from './EntityPickerActions.module.scss';

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  IVacationApproverPickerProps,
  VacationApproverPicker,
} from '@work4all/components/lib/components/entity-picker/vacation-approver-picker/VacationApproverPicker';
import { HookedUserIcon } from '@work4all/components/lib/components/user-icon/useUserIconRegister';

import { reactRefSetter } from '@work4all/utils';

import { LabeledInput } from '../../containers/mask-overlays/locked-inputs';

interface VacationApproverPickerFieldProps
  extends Omit<IVacationApproverPickerProps<false>, 'multiple'> {
  label?: string;
  error?: string;
  disabled?: boolean;
}

export const VacationApproverPickerField = React.forwardRef<
  HTMLDivElement,
  VacationApproverPickerFieldProps
>(function VacationApproverPickerField(props, ref) {
  const { error, disabled = false, label, ...pickerProps } = props;
  const { value, onChange, clearable = true } = pickerProps;

  const { t } = useTranslation();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const field = (
    <LabeledInput
      value={
        value?.id ? (
          <div className={styles.userItem}>
            <HookedUserIcon userId={value.id} size="m" />
            <span
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {value.displayName}
            </span>
          </div>
        ) : (
          ''
        )
      }
      label={label ?? t('VACATION.INPUTS.APPROVER')}
      error={error}
      onClear={
        disabled || !clearable
          ? undefined
          : () => {
              onChange(null);
            }
      }
      disabled={disabled}
    />
  );

  if (disabled) {
    return field;
  }

  return (
    <div ref={reactRefSetter(wrapperRef, ref)}>
      <EntityPickerPopover
        anchorEl={wrapperRef}
        picker={<VacationApproverPicker {...pickerProps} multiple={false} />}
      >
        {field}
      </EntityPickerPopover>
    </div>
  );
});
