import { Tooltip } from '@mui/material';
import { CellProps } from 'react-table';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';

import { MailboxContent } from '@work4all/models/lib/Classes/MailboxContent.entity';
import { MailAssignStatus } from '@work4all/models/lib/Enums/MailAssignStatus.enum';

import { useMailboxContentManagerContext } from '../context';

import { PickerTargetButton } from './components/PickerTargetButton';
import { SuggestionsFoundText } from './components/SuggestionsFoundText';
import { ContactOrBusinessPartnerPicker } from './ContactOrBusinessPartnerPicker';
import { contactAsString, ContactText } from './ContactText';

export function MailboxContentContactCell(
  props: CellProps<MailboxContent, MailAssignStatus>
) {
  const mailboxContent = props.row.original;
  const id = mailboxContent.id;

  const context = useMailboxContentManagerContext();

  const item = context.state.itemsById[id];

  const value = item?.contact ?? null;

  const isEditable =
    mailboxContent.assignStatus === MailAssignStatus.NOT_YET_ASSIGNED;

  const possibleSenders = mailboxContent.possibleSenders;

  return (
    <EntityPickerPopover
      picker={
        <ContactOrBusinessPartnerPicker
          value={value}
          onChange={(value) => {
            context.onContactChange(id, value);
          }}
          suggestions={possibleSenders}
        />
      }
    >
      <Tooltip title={contactAsString(value)}>
        <PickerTargetButton disabled={!isEditable}>
          {value || !isEditable ? (
            <ContactText contact={value} />
          ) : (
            <SuggestionsFoundText suggestions={possibleSenders} />
          )}
        </PickerTargetButton>
      </Tooltip>
    </EntityPickerPopover>
  );
}
