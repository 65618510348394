import { useMemo } from 'react';

import { settings, useSetting } from '../settings';

interface ProjectCaptions {
  internalProjectManagerCaption: string;
  externalProjectManagerCaption: string;
  projectStarDateCaption: string;
  projectEndDateCaption: string;
  projectStartEndDateCaption: string;
  eventStartDateCaption: string;
  eventEndDateCaption: string;
  eventStartEndDateCaption: string;
}

export const useProjectCaptions = (): ProjectCaptions => {
  const { value } = useSetting(settings.projectCaptions());

  const { projectStartEndDateCaption, eventStartEndDateCaption } =
    useMemo(() => {
      return {
        projectStartEndDateCaption: margeCaptions(
          value?.CaptionDatum1,
          value?.CaptionDatum4
        ),
        eventStartEndDateCaption: margeCaptions(
          value?.CaptionDatum2,
          value?.CaptionDatum3
        ),
      };
    }, [
      value?.CaptionDatum1,
      value?.CaptionDatum4,
      value?.CaptionDatum2,
      value?.CaptionDatum3,
    ]);

  return {
    internalProjectManagerCaption: value?.CaptionProjektleiterIntern,
    externalProjectManagerCaption: value?.CaptionProjektleiterExtern,
    projectStarDateCaption: value?.CaptionDatum1,
    projectEndDateCaption: value?.CaptionDatum4,
    projectStartEndDateCaption,
    eventStartDateCaption: value?.CaptionDatum2,
    eventEndDateCaption: value?.CaptionDatum3,
    eventStartEndDateCaption,
  };
};

const margeCaptions = (
  firstCaption: string | undefined,
  secondCaption: string | undefined
): string | undefined => {
  if (!firstCaption || !secondCaption) return null;
  const first = firstCaption.split('-');
  const second = secondCaption.split('-');

  if (first[0] === second[0]) return `${first[0]}zeitraum`;
  return `${firstCaption}-${secondCaption}`;
};
