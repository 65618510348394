import { gql, useMutation } from '@apollo/client';

const MARK_MAIL_READ = gql`
  mutation MarkMailRead(
    $mailboxId: String!
    $mailId: String!
    $mailFolderId: String!
  ) {
    setMailState(
      mailboxId: $mailboxId
      mailFolderId: $mailFolderId
      mailId: $mailId
      readState: true
    )
  }
`;

type MarkMailReadResponse = {
  setMailState: boolean;
};

type MarkMailReadVars = {
  mailboxId: string;
  mailFolderId: string;
  mailId: string;
};

export function useMarkMailReadMutation() {
  return useMutation<MarkMailReadResponse, MarkMailReadVars>(MARK_MAIL_READ);
}
