import React from 'react';
import {
  actions,
  defaultColumn,
  ensurePluginOrder,
  makePropGetter,
  useGetLatest,
  useMountedLayoutEffect,
} from 'react-table';

function getFirstDefined(...args) {
  for (let i = 0; i < args.length; i += 1) {
    if (typeof args[i] !== 'undefined') {
      return args[i];
    }
  }
}

let passiveSupported = null;
export function passiveEventSupported() {
  // memoize support to avoid adding multiple test events
  if (typeof passiveSupported === 'boolean') return passiveSupported;

  let supported = false;
  try {
    const options = {
      get passive() {
        supported = true;
        return false;
      },
    };

    window.addEventListener('test', null, options);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    window.removeEventListener('test' as any, null, options as any);
  } catch (err) {
    supported = false;
  }
  passiveSupported = supported;
  return passiveSupported;
}

// Default Column
defaultColumn.canResize = true;

// Actions
actions.columnStartResizing = 'columnStartResizing';
actions.columnResizing = 'columnResizing';
actions.columnDoneResizing = 'columnDoneResizing';
actions.resetResize = 'resetResize';

// TODO Could potentially remove this custom plugin with the built-in one.
//
// With the built-in plugin there is a conflict between "resize" and "reorder"
// drag'n'drop interaction. Which are not reproducible with this custom
// plugin. But the main logic itself is identical (I think) to the built-in
// plugin.
// The drag'n'drop issue seems to be not with the plugin itself,
// but with the header cell components. Multiple draggable elements are nested
// inside each other and it seems to be the root cause.
// There are also other problems with header cell components (specifically
// with sizing, that cause visual artifacts when resizing with block layouts).
// If these issues can be fixed inside the table header cell components,
// this custom plugin might not be necessary at all and we could switch to
// using the built-in one.
export const useResizeColumns = (hooks) => {
  hooks.getResizerProps = [defaultGetResizerProps];
  /*hooks.getHeaderProps.push({
    style: {
      position: 'relative',
    },
  })*/
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
  hooks.useInstanceBeforeDimensions.push(useInstanceBeforeDimensions);
};

const defaultGetResizerProps = (props, { instance, header }) => {
  const { dispatch } = instance;

  const onResizeStart = (e, header) => {
    let isTouchEvent = false;
    if (e.type === 'touchstart') {
      // lets not respond to multiple touches (e.g. 2 or 3 fingers)
      if (e.touches && e.touches.length > 1) {
        return;
      }
      isTouchEvent = true;
    }
    const headersToResize = getLeafHeaders(header);
    const headerIdWidths = headersToResize.map((d) => [d.id, d.totalWidth]);

    const clientX = isTouchEvent ? Math.round(e.touches[0].clientX) : e.clientX;

    const dispatchMove = (clientXPos) => {
      dispatch({ type: actions.columnResizing, clientX: clientXPos });
    };
    const dispatchEnd = () => dispatch({ type: actions.columnDoneResizing });

    const handlersAndEvents = {
      mouse: {
        moveEvent: 'mousemove',
        moveHandler: (e) => dispatchMove(e.clientX),
        upEvent: 'mouseup',
        upHandler: (e) => {
          document.removeEventListener(
            'mousemove',
            handlersAndEvents.mouse.moveHandler
          );
          document.removeEventListener(
            'mouseup',
            handlersAndEvents.mouse.upHandler
          );
          dispatchEnd();
        },
      },
      touch: {
        moveEvent: 'touchmove',
        moveHandler: (e) => {
          if (e.cancelable) {
            e.preventDefault();
            e.stopPropagation();
          }
          dispatchMove(e.touches[0].clientX);
          return false;
        },
        upEvent: 'touchend',
        upHandler: (e) => {
          document.removeEventListener(
            handlersAndEvents.touch.moveEvent,
            handlersAndEvents.touch.moveHandler
          );
          document.removeEventListener(
            handlersAndEvents.touch.upEvent,
            handlersAndEvents.touch.moveHandler
          );
          dispatchEnd();
        },
      },
    };

    const events = isTouchEvent
      ? handlersAndEvents.touch
      : handlersAndEvents.mouse;
    const passiveIfSupported = passiveEventSupported()
      ? { passive: false }
      : false;
    document.addEventListener(
      events.moveEvent,
      events.moveHandler,
      passiveIfSupported
    );
    document.addEventListener(
      events.upEvent,
      events.upHandler,
      passiveIfSupported
    );

    dispatch({
      type: actions.columnStartResizing,
      columnId: header.id,
      columnWidth: header.totalWidth,
      columnMinWidth: header.totalMinWidth || 1,
      headerIdWidths,
      clientX,
    });
  };

  return [
    props,
    {
      onMouseDown: (e) => {
        e.stopPropagation();
        e.preventDefault();
        e.persist() || onResizeStart(e, header);
      },
      onTouchStart: (e) => e.persist() || onResizeStart(e, header),
      style: {
        cursor: 'col-resize',
      },
      draggable: false,
      role: 'separator',
    },
  ];
};

useResizeColumns.pluginName = 'useResizeColumns';

function reducer(state, action) {
  if (action.type === actions.init) {
    return {
      columnResizing: {
        columnWidths: {},
      },
      ...state,
    };
  }

  if (action.type === actions.resetResize) {
    return {
      ...state,
      columnResizing: {
        columnWidths: {},
      },
    };
  }

  if (action.type === actions.columnStartResizing) {
    const { clientX, columnId, columnWidth, columnMinWidth, headerIdWidths } =
      action;

    return {
      ...state,
      columnResizing: {
        ...state.columnResizing,
        startX: clientX,
        headerIdWidths,
        columnWidth,
        columnMinWidth,
        isResizingColumn: columnId,
      },
    };
  }

  if (action.type === actions.columnResizing) {
    const { clientX } = action;
    const {
      startX,
      columnWidth,
      columnMinWidth,
      headerIdWidths = [],
    } = state.columnResizing;

    const deltaX = clientX - startX;
    const percentageDeltaX = deltaX / columnWidth;

    const newColumnWidths = {};
    headerIdWidths.forEach(([headerId, headerWidth]) => {
      newColumnWidths[headerId] = Math.max(
        headerWidth + headerWidth * percentageDeltaX,
        columnMinWidth || 1
      );
    });

    return {
      ...state,
      columnResizing: {
        ...state.columnResizing,
        columnWidths: {
          ...state.columnResizing.columnWidths,
          ...newColumnWidths,
        },
      },
    };
  }

  if (action.type === actions.columnDoneResizing) {
    return {
      ...state,
      columnResizing: {
        ...state.columnResizing,
        startX: null,
        isResizingColumn: null,
      },
    };
  }
}

const useInstanceBeforeDimensions = (instance) => {
  const {
    flatHeaders,
    disableResizing,
    getHooks,
    state: { columnResizing },
  } = instance;

  const getInstance = useGetLatest(instance);

  flatHeaders.forEach((header) => {
    const canResize = getFirstDefined(
      header.disableResizing === true ? false : undefined,
      disableResizing === true ? false : undefined,
      true
    );

    header.canResize = canResize;
    header.width =
      columnResizing.columnWidths[header.id] ||
      header.originalWidth ||
      header.width;
    header.isResizing = columnResizing.isResizingColumn === header.id;

    if (canResize) {
      header.getResizerProps = makePropGetter(getHooks().getResizerProps, {
        instance: getInstance(),
        header,
      });
    }
  });
};

function useInstance(instance) {
  const { plugins, dispatch, autoResetResize = true, columns } = instance;

  ensurePluginOrder(plugins, ['useAbsoluteLayout'], 'useResizeColumns');

  const getAutoResetResize = useGetLatest(autoResetResize);
  useMountedLayoutEffect(() => {
    if (getAutoResetResize()) {
      dispatch({ type: actions.resetResize });
    }
  }, [columns]);

  const resetResizing = React.useCallback(
    () => dispatch({ type: actions.resetResize }),
    [dispatch]
  );

  Object.assign(instance, {
    resetResizing,
  });
}

function getLeafHeaders(header) {
  const leafHeaders = [];
  const recurseHeader = (header) => {
    if (header.columns && header.columns.length) {
      header.columns.map(recurseHeader);
    }
    leafHeaders.push(header);
  };
  recurseHeader(header);
  return leafHeaders;
}
