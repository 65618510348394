export const EMPTY_UID = '00000000-0000-0000-0000-000000000000';

export function isUUID(uuid: string) {
  const s = '' + uuid;
  const result = s.match(
    '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$'
  );
  if (result === null) {
    return false;
  }
  return true;
}
