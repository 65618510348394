import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

import { Dialog, DialogContent } from '@work4all/components';

interface WorkTimeDisabledAlertProps {
  open: boolean;
  onClose: () => void;
}

// TODO There are many alerts like this in the app and it's rather cumbersome to
// maintain their state manually in the parent component when there aren't
// really any actions a user can perform on such alert rather than just
// dismissing it. For convenience sake we could add a hook to simplify the work
// with alert/prompt/common dialog component.
//
// It then could look similar to this:
//
// const dialogs = useDialogs();
//
// ...somewhere in an event handler
//
// await dialogs.alert({
//   title: "Title",
//   description: "Description"
// })
//
// The returned promise would resolve after the alert has been dismissed. A
// similar API could be used for input prompts, where the promise could resolve
// with some value.
export function WorkTimeDisabledAlert(props: WorkTimeDisabledAlertProps) {
  const { open, onClose } = props;

  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      closeButton={false}
      title={'Modul nicht verfügbar'}
    >
      <DialogContent>
        <p>
          Sie haben keine Lizenz für die Arbeitszeiterfassung. Kontaktieren Sie
          dazu Ihren Administrator.
        </p>

        <Button fullWidth size="large" color="primary" onClick={onClose}>
          {t('ALERTS.CLOSE')}
        </Button>
      </DialogContent>
    </Dialog>
  );
}
