import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { BzObjType } from '../Enums/BzObjType.enum';
import { EMode } from '../Enums/EMode.enum';

export class ReportOption<T extends EMode = EMode.entity> {
  /** Alias: optionId */
  optionId?: number;
  /** Alias: displayname */
  displayname?: string;
  /** Alias: fullname */
  fullname?: string;
  /** Alias: visibleForBzObjectType */
  visibleForBzObjectType?: BzObjType[];

  __typename?: string;
}

const fields: FieldDefinitions<ReportOption> = {
  optionId: {
    alias: 'optionId',
  },
  displayname: {
    alias: 'displayname',
  },
  fullname: {
    alias: 'fullname',
  },
  visibleForBzObjectType: {
    alias: 'visibleForBzObjectType',
  },
  __typename: {
    alias: '__typename',
  },
};

export const reportOptionEntityDefinition: EntitiyDefinition<ReportOption> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'ReportOption',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
