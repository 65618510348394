import { Theme, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DateTimeInputPicker } from '@work4all/components/lib/input/date-time-input-picker';

import { BankDetails } from '@work4all/models/lib/Classes/BankDetails.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';

import { CheckboxRadioItem } from '../../../../../../../../../../locked-inputs';
import { LabeledInput } from '../../../../../../../../../../locked-inputs';
import { ControlWrapper } from '../../../../../../../../../components/control-wrapper/ControlWrapper';
import { MaskPopover } from '../../../../../../../../../components/mask-popover/MaskPopover';
import { useMaskContext } from '../../../../../../../../../hooks/mask-context';

export const BankDetailsPopover: React.FC<{
  selectedElement?: BankDetails;
  onSubmit: (result: BankDetails) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  popoverState: any;
  newBankList?: BankDetails[];
}> = (props) => {
  const { t } = useTranslation();
  const { selectedElement, newBankList, onSubmit, popoverState } = props;

  const [resultElement, setResultElement] = useState<BankDetails>({});

  const { data } = useMaskContext<Customer | Supplier>();

  const hasBank = data.bankDetailsList.length;

  useEffect(() => {
    if (hasBank === 0 && !newBankList.length) {
      setResultElement({ ...selectedElement, isMainBank: true });
    } else {
      setResultElement(selectedElement);
    }
  }, [hasBank, newBankList.length, selectedElement, setResultElement]);

  const md = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const gridTemplateColumns = !md ? '2fr 1fr' : undefined;

  return (
    <MaskPopover
      title={t('MASK.BANK_DETAILS')}
      submit={() => {
        onSubmit(resultElement);
        popoverState.onClose();
      }}
      cancel={popoverState.onClose}
      anchorEl={popoverState.anchorEl}
      open={popoverState.open}
    >
      <ControlWrapper>
        <LabeledInput
          label={t('INPUTS.CREDIT_INSTITUTION')}
          value={resultElement?.name || ''}
          onChange={(e) =>
            setResultElement({ ...resultElement, name: e.target.value })
          }
        />
      </ControlWrapper>
      <ControlWrapper>
        <LabeledInput
          label={t('INPUTS.ACCOUNT_OWNER')}
          value={resultElement?.accountOwner || ''}
          onChange={(e) =>
            setResultElement({ ...resultElement, accountOwner: e.target.value })
          }
        />
      </ControlWrapper>
      <ControlWrapper gridTemplateColumns={gridTemplateColumns}>
        <LabeledInput
          label={t('INPUTS.IBAN')}
          value={resultElement?.iban || ''}
          onChange={(e) =>
            setResultElement({ ...resultElement, iban: e.target.value })
          }
        />
        <LabeledInput
          label={t('INPUTS.BIC_OPTIONAL')}
          value={resultElement?.bic || ''}
          onChange={(e) =>
            setResultElement({ ...resultElement, bic: e.target.value })
          }
        />
      </ControlWrapper>
      <ControlWrapper gridTemplateColumns={gridTemplateColumns}>
        <LabeledInput
          label={t('INPUTS.ACCOUNT_NUMBER')}
          value={resultElement?.accountNumber || ''}
          onChange={(e) =>
            setResultElement({
              ...resultElement,
              accountNumber: e.target.value,
            })
          }
        />
        <LabeledInput
          label={t('INPUTS.BLZ')}
          value={resultElement?.bankSortCode || ''}
          onChange={(e) =>
            setResultElement({
              ...resultElement,
              bankSortCode: parseInt(e.target.value),
            })
          }
        />
      </ControlWrapper>
      <ControlWrapper gridTemplateColumns={gridTemplateColumns}>
        <LabeledInput
          label={t('INPUTS.SEPA_MANDATE_REF_NUMBER')}
          value={resultElement?.mandateReferenceNumber || ''}
          onChange={(e) =>
            setResultElement({
              ...resultElement,
              mandateReferenceNumber: e.target.value,
            })
          }
        />
        <DateTimeInputPicker
          dateLabel={t('INPUTS.MANDATE_DATE')}
          defaultValue={new Date(resultElement?.mandateDate)}
          withTime={false}
          clearable={false}
          onChange={(e) => {
            setResultElement({
              ...resultElement,
              mandateDate: (
                e as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ).target.value,
            });
          }}
        />
      </ControlWrapper>
      <ControlWrapper>
        <CheckboxRadioItem
          label={t('INPUTS.STANDART_CUSTOMER_BANK_ACC')}
          checked={Boolean(resultElement?.isMainBank)}
          onChange={(e) =>
            setResultElement({
              ...resultElement,
              isMainBank: e.target.checked,
            })
          }
        />
      </ControlWrapper>
    </MaskPopover>
  );
};
