import { Typography } from '@mui/material';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { TicketCategory1 } from '@work4all/models/lib/Classes/TicketCategory1.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../tooltip/Tooltip';

import { ListEntityPicker } from './components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from './types';

export type ITicketCategory1PickerProps<TMultiple extends boolean> =
  IPickerProps<TicketCategory1, TMultiple>;

export function TicketCategory1Picker<TMultiple extends boolean>(
  props: ITicketCategory1PickerProps<TMultiple>
) {
  const { data, prefilter, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...TICKET_CATEGORY_1_FIELDS, ...data };
  }, [data]);

  const request = useMemo<DataRequest>(() => {
    return {
      data: allFields,
      entity: Entities.ticketCategory1,
      completeDataResponse: true,
      operationName: 'GetTicketCategory1List',
      filter: prefilter,
    };
  }, [allFields, prefilter]);

  const response = useDataProvider<TicketCategory1>(request);

  const dataset = useMemo(() => {
    return {
      loading: response.loading,
      data: response.data,
      total: response.data.length,
      fetchMore: () => Promise.resolve(),
      refetch: () => Promise.resolve(null),
    };
  }, [response]);

  const renderItem = (item: TicketCategory1) => {
    const label = item.name;

    return (
      <Tooltip title={label}>
        <Typography variant="body2" noWrap>
          {label}
        </Typography>
      </Tooltip>
    );
  };

  return (
    <ListEntityPicker
      entity={Entities.ticketCategory1}
      data={allFields}
      filterBy="name"
      sortBy="name"
      fixedDataSet={dataset}
      renderItemContent={renderItem}
      {...rest}
    />
  );
}

const TICKET_CATEGORY_1_FIELDS: TicketCategory1 = {
  id: null,
  name: null,
};
