import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { settings, useSetting } from '../../../../../settings';

export function useYellowNotePosition() {
  const positionSetting = useSetting(settings.yellowNotePosition());

  const debouncedUpdatePosition = useMemo(() => {
    return debounce(positionSetting.set, 500, {
      leading: false,
      trailing: true,
    });
  }, [positionSetting.set]);

  const [position, setPosition] = useState<{ x: number; y: number }>(() => {
    return positionSetting.value;
  });

  useEffect(() => {
    debouncedUpdatePosition(position);
  }, [position, debouncedUpdatePosition]);

  return [position, setPosition] as const;
}
