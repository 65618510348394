import { UserPresenceTimeInfo } from '@work4all/models/lib/Classes/UserPresenceTimeInfo.entity';
import { TimeStampKind } from '@work4all/models/lib/Enums/TimeStampKind.enum';

import { TimePreviewActions } from './types';

export const calculateActionDependsOnBlock = (
  block: UserPresenceTimeInfo
): TimePreviewActions[] => {
  if (!block) return ['Add'];

  const last = block.items[block.items.length - 1].kind;
  if (last === TimeStampKind.TAG_BEENDET) return ['Add'];
  if (last === TimeStampKind.PAUSENBEGINN) return ['Resume'];

  return ['Pause', 'End'];
};
