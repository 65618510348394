import { noop } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { BasicTableColumn, Table } from '@work4all/components';
import { Collapse } from '@work4all/components/lib/components/collapse';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';

import { ActionsBar } from '../../../../../../../../data-display/actions-bar/ActionsBar';
import { MaskPopover } from '../../../../../../../components/mask-popover/MaskPopover';
import { UpdateEntity } from '../../../../../../../components/update-entity/UpdateEntity';
import { useMaskContext } from '../../../../../../../hooks/mask-context';

import { SpecificPricePopover } from './components/specific-price-popover/SpecificPricePopover';

export const CustomizedPrices = React.forwardRef<TableInstance>(
  (_props, ref) => {
    const { t } = useTranslation();

    const { data } = useMaskContext<Customer>();

    const columns = useMemo<BasicTableColumn[]>(() => {
      return [
        {
          Header: t('MASK.ARTICLE'),
          accessor: 'article.name',
          minWidth: 100,
        },
        {
          Header: t('MASK.ORDER_NUMBER'),
          accessor: 'articleNumber',
          minWidth: 100,
        },
        {
          Header: t('MASK.COMMENT'),
          accessor: 'comment',
          minWidth: 100,
        },
        {
          Header: t('MASK.PRICE'),
          accessor: 'netPrice',
          minWidth: 100,
          alignment: 'right',
          Cell: (value) => {
            const price = value.row.original['netPrice'];
            return price.toLocaleString();
          },
        },
      ];
    }, [t]);

    const popoverState = usePopoverState();

    return (
      <Collapse
        grid={true}
        defaultOpen={true}
        headerBar={data?.id && <ActionsBar hideMoreButton />}
        title={t('MASK.CUSTOMIZED_PRICES')}
      >
        {!data?.id && <UpdateEntity />}
        {data?.id && (
          <>
            <MaskPopover
              title={t('MASK.CUSTOMER_SPECIFIC_PRICE')}
              submit={noop}
              cancel={popoverState.onClose}
              anchorEl={popoverState.anchorEl}
              open={popoverState.open}
            >
              <SpecificPricePopover />
            </MaskPopover>
            <Table
              ref={ref}
              cardsView={false}
              columns={columns}
              data={data?.customerIndividualPriceList}
              mode="client"
              allItemsCount={data?.customerIndividualPriceList?.length}
            />
          </>
        )}
      </Collapse>
    );
  }
);
