import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';

import { BasicTableColumn, Table } from '@work4all/components';
import { Collapse } from '@work4all/components/lib/components/collapse';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { BankDetails } from '@work4all/models/lib/Classes/BankDetails.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { ActionsBar } from '../../../../../../../../data-display/actions-bar/ActionsBar';
import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { UpdateEntity } from '../../../../../../../components/update-entity/UpdateEntity';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { BusinessPartners } from '../../../../../BusinessPartnerOverlayController';
import { BPMaskFormValue } from '../../../../../types';

import { BankDetailsPopover } from './components/bank-details-popover/BankDetailsPopover';

export const BankDetailsTable: React.FC = () => {
  const { t } = useTranslation();

  const { entity: type, data } = useMaskContext<BusinessPartners>();

  const { control } = useFormContextPlus<BPMaskFormValue>();
  const [bankDetailsList, setBankDetailsList] = useState<BankDetails[]>([]);

  const sortedBanklist = useMemo(() => {
    const bankList = data.bankDetailsList;
    return [...bankList].sort((a, b) => {
      if (a.isMainBank && !b.isMainBank) {
        return -1;
      } else if (!a.isMainBank && b.isMainBank) {
        return 1;
      } else {
        return 0;
      }
    });
  }, [data.bankDetailsList]);

  useEffect(() => {
    setBankDetailsList(sortedBanklist);
  }, [sortedBanklist]);

  const columns = useMemo<BasicTableColumn[]>(() => {
    return [
      {
        Header: t('MASK.CREDIT_INSTITUTION'),
        accessor: 'name',
        minWidth: 100,
      },
      {
        Header: t('MASK.ACCOUNT_OWNER'),
        accessor: 'accountOwner',
        minWidth: 100,
      },
      {
        Header: t('MASK.IBAN'),
        accessor: 'iban',
        minWidth: 100,
      },
      {
        Header: t('MASK.BIC'),
        accessor: 'bic',
        minWidth: 100,
      },
      {
        Header: t('MASK.MANDATE_REFERENCE_NUMBER'),
        accessor: 'mandateReferenceNumber',
        minWidth: 100,
      },
      {
        Header: t('MASK.MANDATE_DATE'),
        accessor: 'mandateDate',
        minWidth: 100,
        Cell: (value) => {
          const date = value.row.original['mandateDate'];
          return date ? new Date(date).toLocaleDateString() : '';
        },
      },
    ];
  }, [t]);

  const popoverState = usePopoverState();
  const [newMode, setNewMode] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<Row[]>([]);
  const [entityToEdit, setEntityToEdit] = useState<BankDetails | null>(null);

  const getModifiedList = useCallback(
    (editedElement: BankDetails) => {
      let modifiedList = [...bankDetailsList];

      if (newMode) {
        modifiedList.push(editedElement);
      } else {
        const idxToModify = modifiedList.findIndex(
          (x) => x.id.toString() === selectedRows[0].id
        );
        modifiedList[idxToModify] = editedElement;
      }

      if (editedElement.isMainBank) {
        modifiedList = modifiedList.map((item, idx) => {
          if (newMode) {
            return {
              ...item,
              isMainBank: idx === modifiedList.length - 1,
            };
          } else {
            return {
              ...item,
              isMainBank: idx === selectedRows[0].index,
            };
          }
        });
      }

      setBankDetailsList(modifiedList);
      return modifiedList;
    },
    [bankDetailsList, newMode, selectedRows]
  );

  const removeRows = useCallback(
    (idxList: number[]) => {
      const modifiedList = [...bankDetailsList].filter((item, idx) => {
        return !idxList.includes(idx);
      });
      setBankDetailsList(modifiedList);
      return modifiedList;
    },
    [bankDetailsList]
  );

  return (
    <ControllerPlus
      name={'bankDetailsList'}
      control={control}
      render={({ field }) => {
        return (
          <Collapse
            headerBar={
              data?.id && (
                <ActionsBar
                  add={() => {
                    setNewMode(true);
                    setEntityToEdit({
                      businessPartnerId: data.id,
                      businessPartnerType:
                        type === Entities.customer
                          ? SdObjType.KUNDE
                          : SdObjType.LIEFERANT,
                    });
                    popoverState.handleClick();
                  }}
                  edit={
                    selectedRows.length === 1 &&
                    (() => {
                      setNewMode(false);
                      setEntityToEdit(selectedRows?.[0]?.original);
                      popoverState.handleClick();
                    })
                  }
                  remove={
                    selectedRows.length > 0 &&
                    (() => {
                      field.onChange(
                        removeRows(selectedRows?.map((item) => item?.index))
                      );
                    })
                  }
                  hideMoreButton
                />
              )
            }
            grid={true}
            defaultOpen={true}
            title={t('MASK.BANK_DETAILS')}
          >
            {!data?.id && <UpdateEntity />}
            {data?.id && (
              <>
                <BankDetailsPopover
                  selectedElement={entityToEdit}
                  onSubmit={(editedElement) => {
                    field.onChange(getModifiedList(editedElement));
                  }}
                  popoverState={popoverState}
                  newBankList={bankDetailsList}
                />
                <Table
                  columns={columns}
                  data={bankDetailsList}
                  mode="client"
                  onSelectedRowsChange={setSelectedRows}
                  allItemsCount={data?.bankDetailsList?.length}
                  cardsView={false}
                  onRowDoubleClick={(id) => {
                    setNewMode(false);

                    let details = bankDetailsList.find((x) => `${x.id}` === id);

                    if (!details && id && id.includes('r')) {
                      const index = parseInt(id.replace('r', ''));
                      details = bankDetailsList[index];
                    }
                    setEntityToEdit(details);
                    popoverState.handleClick();
                  }}
                />
              </>
            )}
          </Collapse>
        );
      }}
    />
  );
};
