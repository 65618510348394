import styles from '../EntityPicker.module.scss';

import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { useMemo } from 'react';

import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type IProjectPickerProps<TMultiple extends boolean> = IPickerProps<
  Project,
  TMultiple
>;

export function ProjectPicker<TMultiple extends boolean>(
  props: IProjectPickerProps<TMultiple>
) {
  const { data, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...PROJECT_FIELDS, ...data };
  }, [data]);

  return (
    <ListEntityPicker
      entity={Entities.project}
      data={allFields}
      filterBy={['name', 'number']}
      sortBy="name"
      useSearchHistory
      {...rest}
      renderItemContent={(project) => (
        <Tooltip title={project.name}>
          <Typography
            variant="body2"
            noWrap
            className={clsx({
              [styles.strikeThrough]: project?.projectStatus?.closedStatus,
            })}
          >
            {project.number ? (
              <span className={styles.secondaryInfo}> {project.number} | </span>
            ) : null}
            {project.name}
          </Typography>
        </Tooltip>
      )}
    />
  );
}

const PROJECT_FIELDS: Project = {
  id: null,
  name: null,
  number: null,
  projectSubDirectories: [{ id: null, name: null }],
  customer: { id: null, name: null, number: null, website: null },
  supplier: { id: null, name: null, number: null, website: null },
  projectStatus: {
    id: null,
    closedStatus: null,
  },
  lockInhouseServices: null,
  lockExternalServices: null,
};
