import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

type Group = 'DMS' | 'CRM';
export type GroupEntities =
  | Entities.appointment
  | Entities.checkList
  | Entities.callMemo
  | Entities.note
  | Entities.salesOpportunities
  | Entities.task
  | Entities.ticket
  | Entities.eMail
  | Entities.document
  | Entities.letter
  | Entities.visitationReport;
const GROUP_BY_ENTITY: Record<GroupEntities, Group> = {
  [Entities.appointment]: 'CRM',
  [Entities.checkList]: 'CRM',
  [Entities.callMemo]: 'CRM',
  [Entities.note]: 'CRM',
  [Entities.salesOpportunities]: 'CRM',
  [Entities.task]: 'CRM',
  [Entities.ticket]: 'CRM',
  [Entities.eMail]: 'DMS',
  [Entities.document]: 'DMS',
  [Entities.letter]: 'DMS',
  [Entities.visitationReport]: 'DMS',
};

const getGroup = (entity: GroupEntities) => {
  const group = GROUP_BY_ENTITY[entity];
  if (!group) {
    console.warn(`Group entity for ${entity} isn't defined`);
  }

  return group;
};

export const areEntitiesInSameGroup = (
  entityA: GroupEntities,
  entityB: GroupEntities
) => {
  if (!entityA || !entityB) return false;

  return getGroup(entityA) === getGroup(entityB);
};
