import styles from './Positions.module.scss';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { isEqual } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ColumnInstance, TableInstance } from 'react-table';

import { ReactComponent as ColumnsIcon } from '@work4all/assets/icons/columns.svg';

import { ResizableArea } from '@work4all/components/lib/components/ResizableArea';
import {
  ColumnVisibilityContext,
  useColumnVisibilityManager,
} from '@work4all/components/lib/dataDisplay/basic-table/hooks/useColumnVisibility';
import { Card } from '@work4all/components/lib/dataDisplay/card';
import { ExpandButton } from '@work4all/components/lib/input/expand-button/ExpandButton';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';
import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { Position } from '@work4all/models/lib/Classes/Position.entity';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { useForceUpdate } from '@work4all/utils/lib/hooks/use-force-update';

import { ColumnsVisibility } from '../../../../../../../../../components/data-tables/table/components/toolbar/components/columns-visibility';
import { settings, useSetting } from '../../../../../../../../../settings';
import { ActionsBar } from '../../../../../../../data-display/actions-bar/ActionsBar';
import { useMaskContext } from '../../../../../../hooks/mask-context';
import { withAutoDisable } from '../../../../../../utils/with-auto-disable';

import { useBomDialog } from './hooks/use-bom-dialog';
import { usePositionState } from './hooks/use-position-state';
import { usePositionTableActions } from './hooks/use-position-table-actions';
import { usePositionUpdateTitle } from './hooks/use-position-update-title';
import { PositionPicker } from './position-picker/PositionPicker';
import { PositionsBillOfMaterials } from './positions-bill-of-materials/PositionsBillOfMaterials';
import { PositionsTable } from './positions-table/PositionsTable';

const tableOrder = [
  'number',
  'longtext',
  'amount',
  'unit',
  'singlePriceNet',
  'totalPriceNet',
  'discount',
  'insteadOfTotalPrice',
];

interface IPositionsProps {
  disabled?: boolean;
  showColumnSelector?: boolean;
  showArticleSelectionBig?: boolean;
  showArticleSelectionSmall?: boolean;
  showPositionMask?: boolean;
  showActions?: boolean;
  allowedColumns?: string[];
  onPaper?: boolean;
  bordersHorizontal?: boolean;
  hideMask?: boolean;
}

export const Positions: React.VFC<IPositionsProps> = (props) => {
  const {
    disabled = false,
    showArticleSelectionBig = true,
    showArticleSelectionSmall = true,
    showColumnSelector = true,
    showPositionMask = true,
    showActions = true,
    onPaper = true,
    bordersHorizontal,
    hideMask = false,
  } = props;

  const { t } = useTranslation();

  const { watch } = useFormContext();
  const { entity } = useMaskContext();
  const total = watch('value');
  const positionList = watch('positionList');

  const [selectedPositionIds, setSelectedPositionIds] = React.useState<
    number[]
  >([]);

  const {
    positions,
    onAddPosition,
    onRemovePosition,
    onMovePosition,
    onEditPosition,
    onCollapsePosition,
  } = usePositionState({
    positions: positionList as Position[],
  });

  const tableInstanceRef = useRef<TableInstance>();

  const desktopMenuButtonRef = useRef<HTMLButtonElement>(null);
  const columnsPopoverState = usePopoverState();
  const openColumnsPopover = columnsPopoverState.handleClick;
  const emptyColumns: ColumnInstance[] = [];

  const columnsVisibilityManager = useColumnVisibilityManager(
    tableInstanceRef.current,
    (columns) => {
      const ids = columns.map((x) => x.id);
      return tableOrder.filter((x) => ids.includes(x));
    }
  );

  const [settingsLoaded, setSettingsLoaded] = useState(false);
  const columnSettings = useSetting(settings.erpPositionsColumn());

  useEffect(() => {
    if (!settingsLoaded) return;
    if (tableInstanceRef.current?.visibleColumns) {
      const visible = tableInstanceRef.current?.visibleColumns;
      const all = tableInstanceRef.current?.allColumns;
      const visibleIds = visible.map((x) => x.id).sort();
      const allIds = all.map((x) => x.id).sort();
      const old = columnSettings.value;
      if (
        !isEqual(visibleIds, old) &&
        !props.allowedColumns &&
        !isEqual(visibleIds, allIds)
      ) {
        setTimeout(() => {
          columnSettings.set(visibleIds);
        }, 1000);
      }
    }
  }, [
    columnSettings,
    tableInstanceRef.current?.visibleColumns,
    props.allowedColumns,
    settingsLoaded,
  ]);

  const forceUpdate = useForceUpdate();

  const resetColumns = useCallback(() => {
    columnSettings.delete();
    const newValue = settings.erpPositionsColumn().defaultValue;
    tableInstanceRef.current.allColumns.forEach((x) => {
      tableInstanceRef.current.toggleHideColumn(x.id, !newValue.includes(x.id));
    });
    forceUpdate();
  }, [columnSettings, forceUpdate]);

  useEffect(() => {
    if (settingsLoaded) return;
    if (columnSettings.value && tableInstanceRef.current?.allColumns) {
      tableInstanceRef.current.allColumns.forEach((x) => {
        if (!columnSettings.value.includes(x.id)) {
          tableInstanceRef.current.toggleHideColumn(x.id);
        }
      });
      setSettingsLoaded(true);
    }
  }, [
    tableInstanceRef.current?.allColumns,
    columnSettings,
    settingsLoaded,
    setSettingsLoaded,
  ]);

  const onAddSpecial = useCallback(
    (art: ErpPositionsKind) => {
      onAddPosition({
        positionType: art,
      });
    },
    [onAddPosition]
  );

  const actions = usePositionTableActions({ onAddSpecial, disabled });
  const previewSize = useSetting(settings.erpArticleSize());
  const erpShowArticle = useSetting(settings.erpShowArticle());

  const { setTitleAsFollowAction } = usePositionUpdateTitle({
    positions,
    onEditPosition,
  });

  const isViewportDownMd = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  const { bomArticle, onAddBomArticle, onAddPositionWithArticle, onBomClose } =
    useBomDialog({
      onAddPosition,
      entity,
    });

  const onPickPosition = useCallback(
    (article?: Article | string) => {
      if (typeof article === 'string') {
        onAddPosition({
          positionType: ErpPositionsKind.TITEL,
        });
        setTitleAsFollowAction(article);
        return;
      }
      if (article)
        onAddPositionWithArticle({
          positionType: ErpPositionsKind.STANDARD,
          article,
        });
      else
        onAddPosition({
          positionType: ErpPositionsKind.TEXTZEILE,
        });
    },
    [onAddPosition, onAddPositionWithArticle, setTitleAsFollowAction]
  );

  const positionsTable = useMemo(() => {
    return (
      <PositionsTable
        tableInstanceRef={tableInstanceRef}
        disabled={disabled}
        total={total}
        positions={positions ?? []}
        onSelectedPositionIdsChange={setSelectedPositionIds}
        onMovePosition={onMovePosition}
        onPickPosition={onPickPosition}
        onRemovePosition={onRemovePosition}
        onAddPosition={onAddPositionWithArticle}
        onEditPosition={onEditPosition}
        onCollapsePosition={onCollapsePosition}
        allowedColumns={props.allowedColumns}
        showPositionMask={showPositionMask}
        bordersHorizontal={bordersHorizontal}
        showArticleSelectionSmall={
          showArticleSelectionSmall || !erpShowArticle.value
        }
        hideMask={hideMask}
      />
    );
  }, [
    disabled,
    total,
    positions,
    onMovePosition,
    onPickPosition,
    onRemovePosition,
    onAddPositionWithArticle,
    onEditPosition,
    onCollapsePosition,
    props.allowedColumns,
    showPositionMask,
    bordersHorizontal,
    showArticleSelectionSmall,
    erpShowArticle.value,
    hideMask,
  ]);

  return (
    <React.Fragment>
      <div className={styles['tableWrapper']}>
        {!isViewportDownMd && (
          <Box height="3rem" paddingLeft="1.75rem">
            <ActionsBar
              className={styles['actionBar']}
              isGrouped={false}
              customActions={{
                hideDivider: true,
                left: showActions ? actions : undefined,
                right: showColumnSelector
                  ? [
                      {
                        actionKey: 'columns',
                        IconComponent: ColumnsIcon,
                        title: t('MASK.COLUMNS'),
                        disabled: false,
                        onClick: (event) => {
                          return openColumnsPopover(
                            event,
                            desktopMenuButtonRef.current
                          );
                        },
                      },
                    ]
                  : [],
              }}
              remove={
                selectedPositionIds.length > 0
                  ? {
                      onClick: () => {
                        onRemovePosition(selectedPositionIds);
                      },
                    }
                  : null
              }
              hideMoreButton
            />
          </Box>
        )}

        <Box
          sx={{ height: '100%', display: 'flex', padding: '0 1rem', flex: 1 }}
        >
          {onPaper ? (
            <Card sx={{ height: '100%', display: 'flex', flex: 1 }} noPadding>
              {positionsTable}
            </Card>
          ) : (
            positionsTable
          )}
        </Box>

        <ColumnVisibilityContext.Provider value={columnsVisibilityManager}>
          <ColumnsVisibility
            popoverState={columnsPopoverState}
            tableInstanceRef={tableInstanceRef}
            visibleColumns={
              tableInstanceRef.current?.allColumns ?? emptyColumns
            }
            onResetColumns={resetColumns}
          />
        </ColumnVisibilityContext.Provider>
      </div>
      <PositionsBillOfMaterials
        article={bomArticle}
        onAddArticle={onAddBomArticle}
        onClose={onBomClose}
      />
      {!isViewportDownMd && showArticleSelectionBig && (
        <>
          <Box
            display="flex"
            paddingLeft="1rem"
            marginBottom={erpShowArticle.value ? '-1rem' : 0}
          >
            <ExpandButton
              title={t('COMMON.ARTICLE_LIST')}
              icon={erpShowArticle.value ? <ExpandMore /> : <ExpandLess />}
              onClick={() => {
                erpShowArticle.set(!erpShowArticle.value);
              }}
              orientation="horizontal"
            />
          </Box>
          {erpShowArticle.value && (
            <ResizableArea
              handles="top"
              className={styles.resizable}
              direction="vertical"
              size={previewSize.value}
              onResize={(size) => {
                previewSize.set(size);
              }}
              maxHeight={1000}
              minHeight={remToPx(5.95)}
            >
              <PositionPicker
                disabled={disabled}
                className={styles.picker}
                onAddStandard={(article) => {
                  onAddPositionWithArticle({
                    positionType: ErpPositionsKind.STANDARD,
                    article,
                  });
                  // setNewlyAddedPositions([positions.length]);
                }}
                onGroupDoubleClick={onPickPosition}
              />
            </ResizableArea>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export const PositionsWithAutoDisable = withAutoDisable(React.memo(Positions));
