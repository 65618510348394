import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Category } from './Category.entity';

export class CategoryClass<T extends EMode = EMode.entity> {
  /** Alias: artikelverteiler */
  isArticleDistributor?: boolean;
  /** Alias: code */
  id?: number;
  /** Alias: firmenverteiler */
  isFirmCategory?: boolean;
  /** Alias: hide */
  hide?: boolean;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: kundenverteiler */
  isCustomerCategory?: boolean;
  /** Alias: lieferantenverteiler */
  isSupplierCategory?: boolean;
  /** Alias: maximum */
  maximum?: number;
  /** Alias: name */
  name?: string;
  /** Alias: personenverteiler */
  isPersonCategory?: boolean;
  /** Alias: pflichtKunden */
  mandatoryCustomers?: boolean;
  /** Alias: pflichtLieferanten */
  mandatorySuppliers?: boolean;
  /** Alias: sortierung */
  sortOrder?: number;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: verteiler */
  categoryList?: Category<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<CategoryClass> = {
  isArticleDistributor: {
    alias: 'artikelverteiler',
  },
  id: {
    alias: 'code',
  },
  isFirmCategory: {
    alias: 'firmenverteiler',
  },
  hide: {
    alias: 'hide',
  },
  insertTime: {
    alias: 'insertTime',
  },
  isCustomerCategory: {
    alias: 'kundenverteiler',
  },
  isSupplierCategory: {
    alias: 'lieferantenverteiler',
  },
  maximum: {
    alias: 'maximum',
  },
  name: {
    alias: 'name',
  },
  isPersonCategory: {
    alias: 'personenverteiler',
  },
  mandatoryCustomers: {
    alias: 'pflichtKunden',
  },
  mandatorySuppliers: {
    alias: 'pflichtLieferanten',
  },
  sortOrder: {
    alias: 'sortierung',
  },
  updateTime: {
    alias: 'updateTime',
  },
  categoryList: {
    alias: 'verteiler',
    entity: Entities.category,
  },
  __typename: {
    alias: '__typename',
  },
};

export const categoryClassEntityDefinition: EntitiyDefinition<CategoryClass> = {
  local: {},
  remote: {
    queryName: 'getKategorieKlassen',
    fragmentName: 'VerteilerKlasse',
    withPaginationWrapper: true,
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
