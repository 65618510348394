import styles from './PreviewWrapper.module.scss';

import React from 'react';

export type IPreviewWrapperProps = { children: React.ReactNode };

export function PreviewWrapper(props: IPreviewWrapperProps) {
  const { children } = props;

  return <div className={styles['preview-wrapper']}>{children}</div>;
}
