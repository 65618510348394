import sanitizeHtml from 'sanitize-html';

import {
  searchText,
  SearchTextResult,
} from '../../../../utils/search-text-result/SearchTextResult';
import { useTableStateBag } from '../../hooks/useTableStateBag';

export type ITextCellProps = {
  value: string;
  column: {
    quickSearchable?: boolean;
    cellParams?: {
      removeHtmlTags?: boolean;
    };
  };
};

export const TextCell = (props: ITextCellProps) => {
  const {
    value,
    column: { quickSearchable, cellParams },
  } = props;

  const tableStateBag = useTableStateBag();

  const textValue = cellParams?.removeHtmlTags
    ? sanitizeHtml(value, {
        allowedTags: [],
      })
    : value ?? null;

  return (
    <SearchTextResult
      result={searchText(
        textValue,
        quickSearchable ? tableStateBag.searchFilterText : ''
      )}
    />
  );
};
