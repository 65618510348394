import { createContext, useContext } from 'react';

export interface IsFavoriteLinkFunction {
  (href: string): boolean;
}

const Context = createContext<IsFavoriteLinkFunction>(() => false);

export const IsFavoriteLinkProvider = Context.Provider;

export function useIsFavoriteLink() {
  return useContext(Context);
}
