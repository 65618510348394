import { ButtonBase, Divider, Popover, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';

interface EmailTemplatesPopoverProps {
  templates: EMailTemplate[];
  anchorEl: Element;
  onClose: () => void;
  onTemplateClick: (template: EMailTemplate) => void;
}

export const EmailTemplatesPopover = ({
  templates,
  anchorEl,
  onClose,
  onTemplateClick,
}: EmailTemplatesPopoverProps) => {
  const { t } = useTranslation();

  return (
    <Popover
      open={true}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      <Box
        padding={'.25rem'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'baseline',
        }}
      >
        <Typography
          variant="h4"
          color="text.primary"
          style={{
            marginBottom: '8px',
            marginTop: '16px',
            paddingLeft: '12px',
          }}
        >
          {t('COMMON.EMAIL_TEMPLATES')}
        </Typography>

        <Divider style={{ width: '100%' }} />

        {templates.map((template) => {
          return (
            <ButtonBase
              onClick={() => {
                onTemplateClick(template);
              }}
              key={template.id}
            >
              <Typography p={2}>{template.name}</Typography>
            </ButtonBase>
          );
        })}
      </Box>
    </Popover>
  );
};
