import React from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BaseDataTaxPicker,
  BaseDataTaxTranslationKeys,
  IBaseDataTaxPickerProps,
} from '@work4all/components/lib/components/entity-picker/base-data-tax-picker/BaseDataTaxPicker';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';

import { BaseDataTax } from '@work4all/models/lib/Enums/BaseDataTax.enum';

import { reactRefSetter } from '@work4all/utils';

import { LabeledInput } from '../../containers/mask-overlays/locked-inputs';

interface Props
  extends Omit<
    IBaseDataTaxPickerProps<false>,
    'multiple' | 'value' | 'onChange'
  > {
  error?: string;
  value: BaseDataTax;
  onChange: (value: BaseDataTax) => void;
}

export const BaseDataTaxPickerField = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { t } = useTranslation();
    const wrapperRef = useRef();
    const { error, ...pickerProps } = props;
    const { value: baseDataTax, onChange } = pickerProps;

    return (
      <div ref={reactRefSetter(wrapperRef, ref)}>
        <EntityPickerPopover
          anchorEl={wrapperRef}
          picker={
            <BaseDataTaxPicker
              multiple={false}
              value={{
                id: baseDataTax,
                name: t(BaseDataTaxTranslationKeys[baseDataTax]),
              }}
              onChange={(value) => {
                onChange(value.id as BaseDataTax);
              }}
            />
          }
        >
          <LabeledInput
            onClear={() => {
              onChange(null);
            }}
            label={t('INPUTS.BASE_DATA_TAX')}
            error={error}
            value={
              baseDataTax
                ? (t(BaseDataTaxTranslationKeys[baseDataTax]) as string)
                : ''
            }
          />
        </EntityPickerPopover>
      </div>
    );
  }
);
