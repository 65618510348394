import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  IVacationKindPickerProps,
  VacationKindPicker,
} from '@work4all/components/lib/components/entity-picker/vacation-kind-picker/VacationKindPicker';

import { reactRefSetter } from '@work4all/utils';

import { LabeledInput } from '../../containers/mask-overlays/locked-inputs';

interface VacationKindPickerFieldProps
  extends Omit<IVacationKindPickerProps<false>, 'multiple'> {
  error?: string;
  disabled?: boolean;
  isSickness?: boolean;
}

export const VacationKindPickerField = React.forwardRef<
  HTMLDivElement,
  VacationKindPickerFieldProps
>(function VacationKindPickerField(props, ref) {
  const { error, disabled = false, isSickness, ...pickerProps } = props;
  const { value, clearable = true, onChange } = pickerProps;

  const { t } = useTranslation();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const field = (
    <LabeledInput
      value={
        value
          ? value.name
          : isSickness
          ? t<string>('COMMON.SICKNESS')
          : t<string>('COMMON.VACATION_KIND_NULL_LABEL')
      }
      label={t('COMMON.VACATION_KIND')}
      error={error}
      onClear={
        disabled || !clearable
          ? undefined
          : () => {
              onChange(null);
            }
      }
    />
  );

  if (disabled) {
    return field;
  }

  return (
    <div ref={reactRefSetter(wrapperRef, ref)}>
      <EntityPickerPopover
        anchorEl={wrapperRef}
        picker={<VacationKindPicker {...pickerProps} multiple={false} />}
      >
        {field}
      </EntityPickerPopover>
    </div>
  );
});
