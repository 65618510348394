import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Customer } from './Customer.entity';
import { Supplier } from './Supplier.entity';

export type BusinessPartnerUnion<T extends EMode = EMode.entity> =
  T extends EMode.entity
    ? Customer<T> | Supplier<T>
    : {
        /** Alias: Kunde */
        customer?: Customer<T>;
        /** Alias: Lieferant */
        supplier?: Supplier<T>;
      };

const fields: FieldDefinitions<BusinessPartnerUnion<EMode.query>> = {
  customer: {
    alias: 'customer',
    entity: Entities.customer,
  },
  supplier: {
    alias: 'supplier',
    entity: Entities.supplier,
  },
};

export const businessPartnerUnionEntityDefinition: EntitiyDefinition<
  BusinessPartnerUnion<EMode.query>
> = {
  kind: 'union',
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'GeschaeftspartnerUnion',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
