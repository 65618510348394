import styles from './PrintComponent.module.scss';

import React, { useEffect, useRef, useState } from 'react';
import short from 'short-uuid';

import { usePrintBag } from '@work4all/data/lib/hooks/useHandlePrint';

import { PrintStyles } from './components/PrintStyles';

interface IPrintComponentProps {
  trigger?: JSX.Element;
}

export const PrintComponent: React.FC<IPrintComponentProps> = (props) => {
  const [printId] = useState(() => `print${short.generate()}`);
  const { printing, setPrinting, handlePrint } = usePrintBag();
  const printRef = useRef<HTMLDivElement>(null);
  const [root, setRoot] = useState(null);

  useEffect(() => {
    const r = document.createElement('div');
    r.id = printId;
    r.className = styles.print;
    document.body.append(r);
    setRoot(r);

    return () => {
      r.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!printing || !root) {
      return;
    }

    root.innerHTML = printRef.current.innerHTML;

    const afterPrint = () => {
      root.innerHTML = '';
      setPrinting(false);
    };

    // window.addEventListener('afterprint', afterPrint);
    window.onafterprint = afterPrint;
    window.print();

    return () => {
      window.onafterprint = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printing, root]);

  return (
    <>
      <PrintStyles printing={printing} printId={printId} />
      {props.trigger &&
        React.cloneElement(props.trigger, {
          ...props.trigger.props,
          onClick: handlePrint,
        })}
      <div ref={printRef} className={styles.print}>
        {props.children}
      </div>
    </>
  );
};
