import { DateTime, Settings } from 'luxon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useLocaleDateTime = () => {
  const {
    i18n: { language },
  } = useTranslation();
  Settings.defaultLocale = language;

  return useMemo(() => {
    return DateTime;
    //intentionally listen to langauge changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);
};
