import { RxJsonSchema } from 'rxdb';

import { DbEntities } from '@work4all/models';
import { Setting } from '@work4all/models/lib/Classes/Setting.entity';

export interface SettingEntity extends Omit<Setting, '_props' | '__typename'> {
  _id: string;
}

// Fix the schema to the current version of the SettingType enum to avoid errors
// if the enum changes. In the case that it does change, the schema must be
// updated manually and a migration should be added.
const SETTING_TYPES = ['PERSONAL', 'GLOBAL'];

const schema: RxJsonSchema<SettingEntity> = {
  title: DbEntities.Setting,
  version: 0,
  description: 'a document representing an app setting',
  primaryKey: {
    key: '_id',
    fields: ['name', 'settingType'],
    separator: '|',
  },
  type: 'object',
  properties: {
    _id: {
      type: 'string',
      maxLength: 128, // the primary key must have set maxLength
    },
    name: {
      type: 'string',
    },
    settingType: {
      type: 'string',
      enum: SETTING_TYPES,
    },
    value: {
      type: 'string',
    },
    insertTime: {
      type: 'string',
    },
    updateTime: {
      type: 'string',
    },
  },
};

export const settingEntity = {
  name: DbEntities.Setting,
  schema,
};
