import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';
import { CallMemoPreviewContainer } from '@work4all/components/lib/components/entity-preview/call-memo-preview';

import { CallMemo } from '@work4all/models/lib/Classes/CallMemo.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Table } from '../../components/data-tables/table/Table';
import { OnOpenMask } from '../../containers/mask-overlays/mask-overlay';
import { PersonalAndGeneralView } from '../filters/PersonalAndGeneralView';

import schema from './schemata/callMemo-table-schema.json';
import { TableLayoutContext, useTableLayoutState } from './table-layout';
import { TableNoRowsPlaceholder } from './TableNoRowsPlaceholder';
import { useDataTable } from './use-data-table';
import { useDeleteEntitiesToolbarConfig } from './use-delete-entities-toolbar-config';
import { useTableMaskHandlers } from './use-table-mask-handlers';
import { useUserColumnConfigs } from './use-user-column-configs';

interface Props {
  prefilter?: unknown[];
  onOpenMask: OnOpenMask;
}

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];
const manualGroupBy = true;

export const CallMemoTable = React.forwardRef<TableInstance, Props>(
  function CallMemoTable(props, ref) {
    const { prefilter, onOpenMask } = props;
    const { entity: entityType } = schema as never;

    const tableStateBag = useTableStateBag();

    const layoutState = useTableLayoutState();
    const [layout] = layoutState;

    const {
      columnConfigs,
      cardConfig,
      prepareRowDisplayModifiers,
      data,
      fetchMore,
      total,
      selectedEntities,
      initialSortBy,
      pending,
    } = useDataTable<CallMemo, never>({
      layout,
      schema: schema as never,
      tableStateBag,
      prefilter,
      defaultSort,
    });

    const maskHandlers = useTableMaskHandlers(entityType, onOpenMask);

    const deleteConfig = useDeleteEntitiesToolbarConfig({
      entityType,
    });

    const [userConfig, userConfigMethods] = useUserColumnConfigs({
      layout,
      entityType,
      columnConfigs,
    });

    const { t } = useTranslation();

    if (!userConfig) return null;

    return (
      <TableLayoutContext value={layoutState}>
        <Table
          pending={pending}
          noRowsRenderer={() => <TableNoRowsPlaceholder />}
          ref={ref}
          layout={layout}
          cardConfig={cardConfig}
          areas={{
            left: {
              content: <PersonalAndGeneralView entity={Entities.callMemo} />,
              resizable: true,
              collapseConfig: {
                title: t('COMMON.FILTER'),
              },
            },
            right: selectedEntities &&
              selectedEntities.length > 0 && {
                content: (
                  <CallMemoPreviewContainer
                    callMemoIds={selectedEntities.map((e) => e.id)}
                    onCloseClicked={() =>
                      tableStateBag.tableInstance.toggleAllRowsSelected(false)
                    }
                    onEditClicked={maskHandlers.edit.handler}
                    onShareClicked={maskHandlers.share}
                    convertProps={{
                      exclude: entityType,
                      onClick: maskHandlers.convert,
                    }}
                  />
                ),
              },
          }}
          columnConfigs={userConfig}
          manualGroupBy={manualGroupBy}
          initialSortBy={initialSortBy}
          loadMoreItems={fetchMore}
          actions={{
            add: maskHandlers.create,
            edit: maskHandlers.edit,
            convert: {
              exclude: entityType,
              handler: maskHandlers.convert,
            },
            remove: deleteConfig,
            resetColumns: userConfigMethods.remove,
            createWidget: true,
            clone: maskHandlers.clone,
          }}
          prepareRowDisplayModifiers={prepareRowDisplayModifiers}
          data={data}
          total={total}
        />
      </TableLayoutContext>
    );
  }
);
