import { z } from 'zod';

import { Size } from '@work4all/components/lib/components/ResizableArea';
import { TableLayout } from '@work4all/components/lib/components/table';
import { TimeUnitType } from '@work4all/components/lib/components/time-frame-switch/TimeFrameSwitch';
import { LayoutResponsiveDefinition } from '@work4all/components/lib/utils/react-grid-layout/react-grid-layout-models';

import { FileViewMode } from '@work4all/data/lib/hooks/useWidgetsBag';
import { UseSettingOptions } from '@work4all/data/lib/settings';

import { EMailSignature } from '@work4all/models/lib/Classes/EMailSignature.entity';
import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { TravelReceipts } from '@work4all/models/lib/Classes/TravelReceipts.entity';
import { WordDocumentTemplate } from '@work4all/models/lib/Classes/WordDocumentTemplate.entity';
import { WordLetterTemplate } from '@work4all/models/lib/Classes/WordLetterTemplate.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { InsertBomStyle } from '@work4all/models/lib/Enums/InsertBomStyle.enum';
import { FileType } from '@work4all/models/lib/File';

import { DeepRequired } from '@work4all/utils/lib/deep-required/DeepRequired';
import { Breakpoint } from '@work4all/utils/lib/variables';

import { TableLayoutDefaultValue } from '../components/data-tables/table-layout/table-layout-default-value';
import {
  AppointmentCalendarLastParams,
  AppointmentCalendarView,
  CalendarCardColorBy,
  CalendarUserAppearance,
} from '../containers/calendar/types';
import { IFileInfoPanelItems } from '../containers/files/detail/components/file-info-panel/types';
import { HomeWidget } from '../containers/home/components/home-widgets/HomeWidgetsContextProvider';
import { DEFAULT_HOME_WIDGET_LAYOUTS } from '../containers/home/components/home-widgets/layouts';
import { DEFAULT_HOME_WIDGETS } from '../containers/home/components/home-widgets/widgets';
import { IFavoriteLinkOrFolder } from '../containers/more/data/favorite-links/types';
import { ProjectTimeDisplayMode } from '../containers/time-tracker/ProjectTimeDisplayMode';
import { TimeTrackerMenuTabs } from '../containers/time-tracker/TimeTrackerMenuTabs';

import {
  SavedTableConfig,
  TimeTrackSettings,
  TimeTrackTemplate,
} from './types';

// Re-export all shared settings.
export * from '@work4all/data/lib/settings/settings';

// Configure keys for all app setting here.

/** Table configuration (columns order, size, visibility, etc.) */
export function tableConfig(options: {
  entityType: string;
  layout: TableLayout;
}): UseSettingOptions<SavedTableConfig> {
  const { entityType, layout } = options;

  return {
    name: `list.${entityType}.${layout}`,
    scope: 'tenant',
    defaultValue: null,
  };
}

/* eslint-disable @typescript-eslint/no-explicit-any */

const DEFAULT_LIST_PREVIEW_SIZE: Size = { width: 320 };

/** Preview size on list pages. */
export function listPreviewSize(): UseSettingOptions<Size> {
  return {
    name: 'list.previewSize',
    scope: 'user',
    defaultValue: DEFAULT_LIST_PREVIEW_SIZE,
  };
}

const ERP_ARTICLE_SIZE: Size<'vertical'> = { height: 420 };

/** Size of article component in erp positions. */
export function erpArticleSize(): UseSettingOptions<Size<'vertical'>> {
  return {
    name: 'list.erpArticleSize',
    scope: 'user',
    defaultValue: ERP_ARTICLE_SIZE,
  };
}

/** Size of article component in erp positions. */
export function erpShowArticle(): UseSettingOptions<boolean> {
  return {
    name: 'list.erpShowArticle',
    scope: 'user',
    defaultValue: false,
  };
}

const DEFAULT_FILE_PREVIEW_SIZE: Size = { width: 320 };

/** Left Area size of list */
export function leftAreaSize(): UseSettingOptions<Size> {
  return {
    name: 'list.leftAreaSize',
    scope: 'user',
    defaultValue: { width: 320 },
  };
}

export function positionMaskAreaSize(): UseSettingOptions<Size> {
  return {
    name: 'erp.positionMaskAreaSize',
    scope: 'user',
    defaultValue: { width: 600 },
  };
}

/** Preview size on list pages. */
export function filePreviewSize(options: {
  fileType: string;
}): UseSettingOptions<Size> {
  const { fileType } = options;

  return {
    name: `file.${fileType}.previewSize`,
    scope: 'user',
    defaultValue: DEFAULT_FILE_PREVIEW_SIZE,
  };
}

const DEFAULT_FAVORITE_LINKS: IFavoriteLinkOrFolder[] = [];

/** Favorite links. */
export function favoriteLinks(): UseSettingOptions<IFavoriteLinkOrFolder[]> {
  return {
    name: 'favoriteLinks',
    scope: 'user',
    defaultValue: DEFAULT_FAVORITE_LINKS,
  };
}

const DEFAULT_COLLAPSED_WIDGETS: Record<string, boolean> = {};

/** Collapsed widgets state. */
export function collapsedWidgets(options: {
  fileType: FileType;
}): UseSettingOptions<Record<string, boolean>> {
  const { fileType } = options;

  return {
    name: `file.${fileType}.collapsedWidgets`,
    scope: 'user',
    defaultValue: DEFAULT_COLLAPSED_WIDGETS,
  };
}

/** Collapsed widget group state. */
export function collapsedWidgetGroup(options: {
  fileType: FileType;
  id: string;
  defaultValue: boolean;
}): UseSettingOptions<boolean> {
  const { fileType, id, defaultValue } = options;

  return {
    name: `file.${fileType}.collapsedWidgetGroup.${id}`,
    scope: 'user',
    defaultValue,
  };
}

export function lastUsedDocTemplate(options: {
  docType: string;
  entity: Entities;
  defaultValue?: WordLetterTemplate | WordDocumentTemplate;
}): UseSettingOptions<WordLetterTemplate | WordDocumentTemplate> {
  const { defaultValue, docType, entity } = options;
  return {
    name: `${entity}.${docType}.lastUsedDocTemplateId`,
    scope: `tenant`,
    defaultValue,
  };
}

export function lastUsedEmailTemplate(): UseSettingOptions<EMailTemplate> {
  return {
    name: `lastUsedEmailTemplate`,
    scope: `tenant`,
    defaultValue: null,
  };
}

export function lastUsedEmailSignature(): UseSettingOptions<EMailSignature> {
  return {
    name: `lastUsedEmailSignature`,
    scope: `tenant`,
    defaultValue: null,
  };
}

/** Table layout. (Table or cards view.) */
export function tableLayout(options: {
  defaultValue: TableLayoutDefaultValue;
}): UseSettingOptions<TableLayoutDefaultValue> {
  const { defaultValue } = options;

  //as of jira story 1037 list view modes apply to all tables if changed
  return {
    name: `list.layout`,
    scope: 'user',
    defaultValue,
  };
}

/**
 * Search results for the global search component and file pages. The search
 * items are grouped by entity type.
 * */
export function defaultEmailTemplate(): UseSettingOptions<string> {
  return {
    name: `DefaultMailTemplateId`,
    scope: 'global',
    defaultValue: undefined,
  };
}

const DEFAULT_TIME_TRACK_HISTORY: TimeTrackTemplate[] = [];

export function timeTrackHistory(): UseSettingOptions<TimeTrackTemplate[]> {
  return {
    name: `timeTrackHistory`,
    scope: 'user',
    defaultValue: DEFAULT_TIME_TRACK_HISTORY,
  };
}

const DEFAULT_TIME_TRACK_SETTINGS: TimeTrackSettings = {
  last: {
    show: true,
    count: 8,
  },
  [Entities.task]: {
    show: true,
    count: 8,
  },
  [Entities.contract]: {
    show: true,
    count: 8,
  },
  [Entities.project]: {
    show: true,
    count: 8,
  },
  [Entities.appointment]: {
    show: true,
    count: 8,
  },
  [Entities.ticket]: {
    show: true,
    count: 8,
  },
};

export function timeTrackSettings(): UseSettingOptions<TimeTrackSettings> {
  return {
    name: `timeTrackSettings`,
    scope: 'user',
    defaultValue: DEFAULT_TIME_TRACK_SETTINGS,
  };
}

type IOverViewItemConf = {
  [key in Entities]?: boolean;
};

const DEFAULT_OVERVIEW_WIDGET_CONFIG: IOverViewItemConf = {};

export function overviewWidgetConfig(): UseSettingOptions<IOverViewItemConf> {
  return {
    name: `overview.widgetConfig`,
    scope: 'tenant',
    defaultValue: DEFAULT_OVERVIEW_WIDGET_CONFIG,
  };
}

export function overviewTimeUnitConfig(): UseSettingOptions<TimeUnitType> {
  return {
    name: `overview.timeUnitConfig`,
    scope: 'tenant',
    defaultValue: 'day',
  };
}

export enum PersonFilterType {
  all = 'all',
  department = 'department',
  me = 'mine',
}

export function overviewPersonFilterConfig(): UseSettingOptions<PersonFilterType> {
  return {
    name: `overview.personFilterConfig`,
    scope: 'tenant',
    defaultValue: PersonFilterType.all,
  };
}

const erpPositionsColumnSchema = z.array(z.string());
export function erpPositionsColumn(): UseSettingOptions<string[] | null> {
  return {
    name: `erp.positions.column`,
    scope: 'user',
    defaultValue: [
      'number',
      'longtext',
      'amount',
      'unit',
      'singlePriceNet',
      'discount',
      'internalText',
      'insteadOfTotalPrice',
      'totalPriceNet',
    ],
    parseFn: erpPositionsColumnSchema.parse,
  };
}

/** The status of showing suggestions on the Project Time */
const SHOW_TIME_TRACKING_SUGGESTIONS_DEFAULT_VALUE = false;
export function showTimeTrackingSuggestions(): UseSettingOptions<boolean> {
  return {
    name: 'showTimeTrackingSuggestions',
    scope: 'user',
    defaultValue: SHOW_TIME_TRACKING_SUGGESTIONS_DEFAULT_VALUE,
  };
}

export function calendarWorkDayStart(): UseSettingOptions<string | null> {
  return {
    name: `WorkingDaySetting_CalendarWorkDayStart`,
    scope: 'user',
    defaultValue: null,
  };
}

export function calendarWorkDayEnd(): UseSettingOptions<string | null> {
  return {
    name: `WorkingDaySetting_CalendarWorkDayEnd`,
    scope: 'user',
    defaultValue: null,
  };
}

export function projectTimeTrackingIntervalUnit(): UseSettingOptions<
  number | null
> {
  return {
    name: `TimeTrackingTimeUnits`,
    scope: 'global',
    defaultValue: null,
  };
}

export function calendarLastViewParams(): UseSettingOptions<AppointmentCalendarLastParams | null> {
  return {
    name: 'calendar.lastViewParams',
    scope: 'tenant',
    defaultValue: null,
  };
}

export function projectTimeTrackingMarkItemsAsDeductableAutomatically(): UseSettingOptions<
  boolean | null
> {
  return {
    name: `Kostenerfassung direkt abrechenbar`,
    scope: 'global',
    defaultValue: null,
  };
}

export function projectTimeTrackingDeductableWithoutActivity(): UseSettingOptions<
  boolean | null
> {
  return {
    name: `kostenerfassung ohne Tätigkeit abrechenbar`,
    scope: 'global',
    defaultValue: null,
  };
}

export function projectTimeTrackingDeductableByActivity(): UseSettingOptions<
  boolean | null
> {
  return {
    name: `kostenerfassung nach Tätigkeit abrechenbar`,
    scope: 'global',
    defaultValue: null,
  };
}

export function projectTimeTrackingShowCosts(): UseSettingOptions<
  boolean | null
> {
  return {
    name: `Kostenerfassung, Kosten anzeigen`,
    scope: 'global',
    defaultValue: null,
  };
}

export function projectTimeTrackingAllowEditForDeducableItems(): UseSettingOptions<
  boolean | null
> {
  return {
    name: `Zeiterfassung, Abrechenbare ändern`,
    scope: 'global',
    defaultValue: null,
  };
}

export function projectTimeTrackingCustomerMandatory(): UseSettingOptions<
  boolean | null
> {
  return {
    name: `Kostenerfassung nur mit Kunde`,
    scope: 'global',
    defaultValue: null,
  };
}

export function projectTimeTrackingProjectMandatory(): UseSettingOptions<
  boolean | null
> {
  return {
    name: `Kostenerfassung nur mit Projekt`,
    scope: 'global',
    defaultValue: true,
  };
}

export function projectTimeTrackingProjectProcessMandatory(): UseSettingOptions<
  boolean | null
> {
  return {
    name: `Kostenerfassung nur mit Vorgang`,
    scope: 'global',
    defaultValue: null,
  };
}

export function projectTimeTrackingContractMandatory(): UseSettingOptions<
  boolean | null
> {
  return {
    name: `Kostenerfassung nur mit Auftrag`,
    scope: 'global',
    defaultValue: null,
  };
}

export function projectTimeTrackingPositionMandatory(): UseSettingOptions<
  boolean | null
> {
  return {
    name: `Kostenerfassung nur mit Position`,
    scope: 'global',
    defaultValue: null,
  };
}

export function projectTimeTrackingActivityMandatory(): UseSettingOptions<
  boolean | null
> {
  return {
    name: `Kostenerfassung nur mit Tätigkeit`,
    scope: 'global',
    defaultValue: null,
  };
}

export function projectTimeTrackingTicketMandatory(): UseSettingOptions<
  boolean | null
> {
  return {
    name: `Kostenerfassung nur mit Ticket`,
    scope: 'global',
    defaultValue: null,
  };
}

export function projectTimeTrackingShowCustomer(): UseSettingOptions<
  boolean | null
> {
  return {
    name: `project time tracking show customer`,
    scope: 'global',
    defaultValue: null,
  };
}

export function projectTimeTrackingShowProject(): UseSettingOptions<
  boolean | null
> {
  return {
    name: `project time tracking show project`,
    scope: 'global',
    defaultValue: true,
  };
}

export function projectTimeTrackingShowProjectProcess(): UseSettingOptions<
  boolean | null
> {
  return {
    name: `project time tracking show project step`,
    scope: 'global',
    defaultValue: null,
  };
}

export function projectTimeTrackingShowContract(): UseSettingOptions<
  boolean | null
> {
  return {
    name: `project time tracking show order confirmation`,
    scope: 'global',
    defaultValue: null,
  };
}

export function projectTimeTrackingShowPosition(): UseSettingOptions<
  boolean | null
> {
  return {
    name: `project time tracking show order confirmation line`,
    scope: 'global',
    defaultValue: true,
  };
}

export function projectTimeTrackingShowActivity(): UseSettingOptions<
  boolean | null
> {
  return {
    name: `project time tracking show service item`,
    scope: 'global',
    defaultValue: null,
  };
}

export function projectTimeTrackingShowTicket(): UseSettingOptions<
  boolean | null
> {
  return {
    name: `project time tracking show ticket`,
    scope: 'global',
    defaultValue: null,
  };
}

export function projectTimeTrackingPriceOfCostCalculation(): UseSettingOptions<
  boolean | null
> {
  return {
    name: `Eigenleistungen aus Tätigkeit`,
    scope: 'global',
    defaultValue: null,
  };
}
export const DEFAULT_VIEWS = [];

export function calendarViews(): UseSettingOptions<AppointmentCalendarView[]> {
  return {
    name: 'calendar.views',
    scope: 'tenant',
    defaultValue: DEFAULT_VIEWS,
  };
}

const MS_TEAMS_DEFAULT_VALUE = false;
export function msTeams(): UseSettingOptions<boolean> {
  return {
    name: 'MS-Teams',
    scope: 'global',
    defaultValue: MS_TEAMS_DEFAULT_VALUE,
  };
}

type IFileSettings = Partial<Record<IFileInfoPanelItems, boolean>>;

const FILE_SETTINGS_DEFAULT_VALUE = {
  address: true,
  number: true,
  phone: true,
  contactOptions: true,
  group: true,
  firstContact: true,
  subscriptionContract: false,
  opportunities: false,
  payment: true,
  sales: false,
  openPositions: false,
  contactList: true,
  categories: true,
  relations: true,
  note: true,
  projectLeaders: true,
  budget: true,
  created: true,
  customerSupplier: true,
  kind: true,
  parentProject: true,
  projectPeriod: false,
  projectDirectory: true,
  showFunctionInAddress: true,
  status: true,
  timePeriods: true,
  website: true,
};

export function fileSettings(
  entity: Entities
): UseSettingOptions<IFileSettings> {
  return {
    name: `file.settings.${entity}`,
    scope: 'user',
    defaultValue: FILE_SETTINGS_DEFAULT_VALUE,
  };
}

export function fileHideLeftContacts(): UseSettingOptions<boolean> {
  return {
    name: `file.hideLeftContacts.`,
    scope: 'user',
    defaultValue: false,
  };
}

type FileViewModeBreakpoints = 'sm' | 'md' | 'lg' | 'xl';
type IFileViewMode = {
  [key in FileViewModeBreakpoints]: FileViewMode;
};

const FILES_DEFAULT_VIEW_MODE: IFileViewMode = {
  sm: 'tiles',
  md: 'tiles',
  lg: 'tiles',
  xl: 'tiles',
};

type FileWidgetMode = 'automatically' | 'individual';

const FILES_DEFAULT_WIDGET_MODES: FileWidgetMode = 'individual';

export function fileViewMode(): UseSettingOptions<IFileViewMode> {
  return {
    name: 'files.viewmode',
    scope: 'user',
    defaultValue: FILES_DEFAULT_VIEW_MODE,
  };
}

export function fileViewModeWidget(): UseSettingOptions<FileWidgetMode> {
  return {
    name: 'files.viewmode.widget',
    scope: 'user',
    defaultValue: FILES_DEFAULT_WIDGET_MODES,
  };
}

const CAN_CHANGE_APPROVAL_PERSON_DEFAULT_VALUE = false;
export function canChangeApprovalPerson(): UseSettingOptions<boolean> {
  return {
    name: 'wechseldesurlaubsgenehmigerserlauben',
    scope: 'global',
    defaultValue: CAN_CHANGE_APPROVAL_PERSON_DEFAULT_VALUE,
  };
}
export function calendarAppointmentCardShowBusinesspartner(): UseSettingOptions<boolean> {
  return {
    name: 'calendar.appointment.card.showBusinessPartner',
    scope: 'user',
    defaultValue: true,
  };
}

export function calendarAppointmentCardShowProject(): UseSettingOptions<boolean> {
  return {
    name: 'calendar.appointment.card.showProject',
    scope: 'user',
    defaultValue: true,
  };
}

export function calendarAppointmentShowTooltip(): UseSettingOptions<boolean> {
  return {
    name: 'calendar.appointment.tooltip.show',
    scope: 'user',
    defaultValue: true,
  };
}

export function calendarAppointmentTooltipShowBusinesspartner(): UseSettingOptions<boolean> {
  return {
    name: 'calendar.appointment.tooltip.showBusinesspartner',
    scope: 'user',
    defaultValue: true,
  };
}

export function calendarAppointmentTooltipShowProject(): UseSettingOptions<boolean> {
  return {
    name: 'calendar.appointment.tooltip.showProject',
    scope: 'user',
    defaultValue: true,
  };
}

export function calendarAppointmentTooltipShowNote(): UseSettingOptions<boolean> {
  return {
    name: 'calendar.appointment.tooltip.showNote',
    scope: 'user',
    defaultValue: true,
  };
}

export function calendarAppointmentCardColorBy(): UseSettingOptions<CalendarCardColorBy> {
  return {
    name: 'calendar.appointment.card.colorBy',
    scope: 'user',
    defaultValue: CalendarCardColorBy.AppointmentStatus,
  };
}

export function calendarUserAppearance(options: {
  breakpoint: Breakpoint;
}): UseSettingOptions<CalendarUserAppearance> {
  const { breakpoint } = options;

  return {
    name: `calendar.userDisplayStyle.${breakpoint}`,
    scope: 'user',
    defaultValue: CalendarUserAppearance.Avatar,
  };
}

//Set as main contact check input default value
export function mainContactFlag(): UseSettingOptions<boolean> {
  return {
    name: 'hauptansprechpartner nicht setzen',
    scope: 'global',
    defaultValue: false,
  };
}

export function defaultPaymentKindId(): UseSettingOptions<number> {
  return {
    name: 'Standardzahlungsart',
    scope: 'global',
    defaultValue: 0,
  };
}

export function defaultPaymentDeadlineDays(): UseSettingOptions<number> {
  return {
    name: 'Standard Zahlungsfrist /Tg.',
    scope: 'global',
    defaultValue: 0,
  };
}

export function defaultDunningToleranceDays(): UseSettingOptions<number> {
  return {
    name: 'Standard Mahntoleranz /tg.',
    scope: 'global',
    defaultValue: 0,
  };
}

export function defaultSkontoPercent(): UseSettingOptions<number> {
  return {
    name: 'Standard Skonto (%)',
    scope: 'global',
    defaultValue: 0,
  };
}

export function defaultSkontoDeadlineDays(): UseSettingOptions<number> {
  return {
    name: 'Standard Skontofrist /tg.',
    scope: 'global',
    defaultValue: 0,
  };
}

export function callModalAddCallMemo(): UseSettingOptions<boolean> {
  return {
    name: 'callModal.AddCallMemo',
    scope: 'user',
    defaultValue: false,
  };
}

export function erpAdditionalAddress3Name(): UseSettingOptions<string> {
  return {
    name: 'Nebenadresse 3',
    scope: 'global',
    defaultValue: '',
  };
}

export function erpShowAdditionalAddress3(): UseSettingOptions<boolean> {
  return {
    name: 'ERP.ShowAdditionalAddress3',
    scope: 'global',
    defaultValue: false,
  };
}

export function defaultCustomerGroupId(): UseSettingOptions<number> {
  return {
    name: 'StandardGroup_KundenGr',
    scope: 'global',
    defaultValue: 0,
  };
}

export function defaultSupplierGroupId(): UseSettingOptions<number> {
  return {
    name: 'StandardGroup_LieferantenGr',
    scope: 'global',
    defaultValue: 0,
  };
}

export function calendarDragAndDropAppointments(): UseSettingOptions<boolean> {
  return {
    name: 'calendar.dragAndDropAppointments',
    scope: 'tenant',
    defaultValue: true,
  };
}

export function defaultTicketSenderAddress(): UseSettingOptions<string> {
  return {
    name: 'Standardabsender für Ticketbenachrichtigungen',
    scope: 'global',
    defaultValue: '',
  };
}

/** consider invoice ledger for all statistics */
export function statisticConsiderInvoiceLedger(): UseSettingOptions<boolean> {
  return {
    name: 'Stistic.Dashboard.onlyRa',
    scope: 'user',
    defaultValue: true,
  };
}
export function projectTimeTrackingDisplayTemplates(): UseSettingOptions<boolean> {
  return {
    name: 'timeTracking.projectTimeTrackingDisplayTemplates',
    scope: 'user',
    defaultValue: false,
  };
}

export function inboundInvoiceExpireDateCalculationByDateOfReceipt(): UseSettingOptions<boolean> {
  return {
    name: 'RE Fälligkeitsberechnung nach Eingangsdatum',
    scope: 'global',
    defaultValue: false,
  };
}

export function timeTrackingTab(): UseSettingOptions<TimeTrackerMenuTabs> {
  return {
    name: 'timeTracking.timeTrackingTab',
    scope: 'user',
    defaultValue: TimeTrackerMenuTabs.workingTime,
  };
}

export function projectTimeDisplayMode(): UseSettingOptions<ProjectTimeDisplayMode> {
  return {
    name: 'timeTracking.projectTimeDisplayMode',
    scope: 'user',
    defaultValue: ProjectTimeDisplayMode.list,
  };
}

export function inboundInvoiceShowOnlyOpenObjects(): UseSettingOptions<boolean> {
  return {
    name: 'inboundInvoice.ShowOnlyOpenObjects',
    scope: 'user',
    defaultValue: true,
  };
}

export function inboundInvoiceCloseConnectedObjects(): UseSettingOptions<boolean> {
  return {
    name: 'inboundInvoice.CloseConnectedObjects',
    scope: 'user',
    defaultValue: true,
  };
}

export function yellowNotePosition(): UseSettingOptions<{
  x: number;
  y: number;
}> {
  return {
    name: 'file.yellowNote.position',
    scope: 'user',
    defaultValue: null,
  };
}

export const DEFAULT_FILE_WIDGET_LAYOUTS = {
  layouts: [],
};

export function fileWidgetLayouts(): UseSettingOptions<{
  layouts: LayoutResponsiveDefinition[];
}> {
  return {
    name: 'files.widgetLayouts',
    scope: 'user',
    defaultValue: DEFAULT_FILE_WIDGET_LAYOUTS,
  };
}

const DEFAULT_HOME_WIDGET_LAYOUT_DEF = {
  widgets: DEFAULT_HOME_WIDGETS,
  layouts: DEFAULT_HOME_WIDGET_LAYOUTS,
};

export function homeWidgetLayouts(): UseSettingOptions<{
  widgets: HomeWidget[];
  layouts: LayoutResponsiveDefinition;
}> {
  return {
    name: 'home.widgetLayouts',
    scope: 'user',
    defaultValue: DEFAULT_HOME_WIDGET_LAYOUT_DEF,
  };
}

export function msTeamsAvailable(): UseSettingOptions<string> {
  return {
    name: 'MsTeamsAvailable',
    scope: 'user',
    defaultValue: null,
  };
}

export function rtfTextsInERP(): UseSettingOptions<boolean> {
  return {
    name: 'RTF Kopf-/Fusstexte in ERP-Dokumenten',
    scope: 'global',
    defaultValue: true,
  };
}

export const DEFAULT_RELEVANT_WIDGET_VIEW: Entities[] = [
  Entities.task,
  Entities.appointment,
  Entities.ticket,
  Entities.timeTracking,
  Entities.vacation,
  Entities.salesOpportunities,
  Entities.travelReceipts,
];
export function relevantWidgetVisibleViews(): UseSettingOptions<Entities[]> {
  return {
    name: 'Relevant Widget Visible Entities',
    scope: 'user',
    defaultValue: DEFAULT_RELEVANT_WIDGET_VIEW,
  };
}

export function adminEnabledShowingBirthdays(): UseSettingOptions<boolean> {
  return {
    name: 'Admin Enabled Showing Birthdays',
    scope: 'global',
    defaultValue: true,
  };
}

export function userEnabledShowingBirthdays(): UseSettingOptions<boolean> {
  return {
    name: 'User Enabled Showing Birthdays',
    scope: 'user',
    defaultValue: true,
  };
}

export function pageZoomLevel(): UseSettingOptions<number> {
  return {
    name: 'Web.App.Page_Zoom',
    scope: 'user',
    defaultValue: 100,
  };
}

export const DEFAULT_TABLE_LAYOUT_BORDERS = {
  vertical: false,
  horizontal: true,
};

export function tablesLayoutBorders(): UseSettingOptions<{
  vertical: boolean;
  horizontal: boolean;
}> {
  return {
    name: 'Web.App.Tables.Layout.Borders',
    scope: 'user',
    defaultValue: DEFAULT_TABLE_LAYOUT_BORDERS,
  };
}

export function favoritesRegister(): UseSettingOptions<
  Record<Entities | string, Array<string | number>>
> {
  return {
    name: 'Web.App.Favorites.Register',
    scope: 'tenant',
    defaultValue: {},
  };
}

const hideClosedEntitiesZod = z.boolean();
export function hideClosedEntities(options: {
  entityType: string;
}): UseSettingOptions<DeepRequired<z.infer<typeof hideClosedEntitiesZod>>> {
  const { entityType } = options;

  return {
    name: `hideClosedEntities.${entityType}`,
    scope: 'user',
    defaultValue: true,
    parseFn: hideClosedEntitiesZod.parse,
  };
}

const tableCollapsedAreasZod = z.object({
  left: z.record(z.boolean()),
});
export type TableCollapseAreasMap = DeepRequired<
  z.infer<typeof tableCollapsedAreasZod>
>;

const TABLE_COLLAPSED_AREAS: TableCollapseAreasMap = {
  left: {},
};
export function tableCollapsedAreas(): UseSettingOptions<TableCollapseAreasMap> {
  return {
    name: `tableCollapsedAreas`,
    scope: 'user',
    defaultValue: TABLE_COLLAPSED_AREAS,
    parseFn: tableCollapsedAreasZod.parse as any,
  };
}

const vacationTimeRangeZod = z.union([z.literal('month'), z.literal('year')]);

export type VacationTimeRange = DeepRequired<
  z.infer<typeof vacationTimeRangeZod>
>;

export function vacationTimeRange(): UseSettingOptions<VacationTimeRange> {
  return {
    name: 'vacationTimeRange',
    scope: 'user',
    defaultValue: 'month',
    parseFn: vacationTimeRangeZod.parse,
  };
}

const vacationVisibleKindsZod = z.array(z.union([z.string(), z.number()]));
export type VacationVisibleKindsList = DeepRequired<
  z.infer<typeof vacationVisibleKindsZod>
>;

const DEFAULT_VACATION_VISIBLE_KINDS: VacationVisibleKindsList = [
  'vacation',
  'sickness',
  33184105, // Teilzeitausgleich
  50448622, // Überstundenausgleich
  152409305, // Kurzarbeit
  666716357, // Sonderurlaub
  1169570473, // Berufsschule
];
export function vacationVisibleKinds(): UseSettingOptions<VacationVisibleKindsList> {
  return {
    name: 'vacationVisibleKinds',
    scope: 'user',
    defaultValue: DEFAULT_VACATION_VISIBLE_KINDS,
    parseFn: vacationVisibleKindsZod.parse,
  };
}

const createTimeTrackingForOthersZod = z.boolean();
export function createTimeTrackingForOthers(): UseSettingOptions<
  DeepRequired<z.infer<typeof createTimeTrackingForOthersZod>>
> {
  return {
    name: 'createTimeTrackingForOthers',
    scope: 'user',
    defaultValue: false,
    parseFn: createTimeTrackingForOthersZod.parse,
  };
}

const projectCaptionsZod = z.object({
  CaptionProjektleiterExtern: z.string(),
  CaptionProjektleiterIntern: z.string(),
  CaptionDatum1: z.string(),
  CaptionDatum2: z.string(),
  CaptionDatum3: z.string(),
  CaptionDatum4: z.string(),
});

export type ProjectCaptions = DeepRequired<z.infer<typeof projectCaptionsZod>>;

export function projectCaptions(): UseSettingOptions<ProjectCaptions> {
  return {
    name: 'Projekt-Captions',
    scope: 'global',
    defaultValue: null,
    parseFn: projectCaptionsZod.parse as any,
  };
}

const selectedMailboxFolderZod = z
  .object({
    mailbox: z.string(),
    folder: z.string(),
  })
  .nullable();

export type MailboxFolder = DeepRequired<
  z.infer<typeof selectedMailboxFolderZod>
>;
export function selectedMailboxFolder(): UseSettingOptions<MailboxFolder> {
  return {
    name: 'selectedMailboxFolder',
    scope: 'user',
    defaultValue: null,
    parseFn: selectedMailboxFolderZod.parse as any,
  };
}

const bomVariantsZod = z.array(
  z.object({
    articleId: z.number(),
    variant: z.nativeEnum(InsertBomStyle),
    noShow: z.boolean(),
  })
);

export type BomVariantSettingList = DeepRequired<
  z.infer<typeof bomVariantsZod>
>;

const BOM_VARIANT_DEFAULT: BomVariantSettingList = [];

export function bomVariants(): UseSettingOptions<BomVariantSettingList> {
  return {
    name: `bomVariants`,
    scope: 'user',
    defaultValue: BOM_VARIANT_DEFAULT,
    parseFn: bomVariantsZod.parse as any,
  };
}

const showProjectAccessGroupRightQuestionZod = z.boolean();
export function showProjectAccessGroupRightQuestion(): UseSettingOptions<
  z.infer<typeof showProjectAccessGroupRightQuestionZod>
> {
  return {
    name: 'showProjectAccessGroupRightQuestion',
    scope: 'user',
    defaultValue: true,
    parseFn: showProjectAccessGroupRightQuestionZod.parse,
  };
}

const pageThemeZod = z.union([
  z.literal('light'),
  z.literal('dark'),
  z.literal('auto'),
]);

export type PageTheme = DeepRequired<z.infer<typeof pageThemeZod>>;

export function pageTheme(): UseSettingOptions<PageTheme> {
  return {
    name: 'pageTheme',
    scope: 'user',
    defaultValue: 'auto',
    parseFn: pageThemeZod.parse,
  };
}

export function isInDemoMode(): UseSettingOptions<boolean> {
  return {
    name: 'isInDemoMode',
    scope: 'user',
    defaultValue: false,
  };
}

const mailboxFolderVisibilitySchema = z.record(
  z.string(),
  z.record(z.string(), z.boolean())
);

export type MailboxFolderVisibility = z.infer<
  typeof mailboxFolderVisibilitySchema
>;

const MAILBOX_FOLDER_VISIBILITY_DEFAULT_VALUE: MailboxFolderVisibility = {};

export function mailboxFolderVisibility(): UseSettingOptions<MailboxFolderVisibility> {
  return {
    name: 'mailboxFolderVisibility',
    scope: 'tenant',
    defaultValue: MAILBOX_FOLDER_VISIBILITY_DEFAULT_VALUE,
  };
}

const CONST_TRAVEL_RECEIPTS: TravelReceipts = {};
export function travelReceiptDefaults(): UseSettingOptions<TravelReceipts> {
  return {
    name: 'travelReceiptDefaults',
    scope: 'tenant',
    defaultValue: CONST_TRAVEL_RECEIPTS,
  };
}
