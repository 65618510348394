import styles from './General.module.scss';

import { memo } from 'react';

import { Panel } from '../../../components';

import { Costs } from './Costs';
import { GeneralInfo } from './GeneralInfo';
import { Others } from './Others';
import { Receipt } from './Receipt/Receipt';

export const General = memo(function General() {
  return (
    <Panel className={styles.panel}>
      <div className={styles.left}>
        <GeneralInfo />
        <Others />
        <Costs />
      </div>
      <Receipt />
    </Panel>
  );
});
