import { XYCoord } from 'react-dnd';

export type IHoverPosition = Record<'over' | 'before' | 'after', boolean>;

export function getHoverPosition(
  clientOffset: XYCoord,
  rect: DOMRect
): IHoverPosition {
  const hoverClientY = clientOffset.y - rect.top;

  const before = hoverClientY < rect.height * 0.5;
  const over =
    hoverClientY >= rect.height * 0.25 && hoverClientY < rect.height * 0.75;
  const after = !before;

  return { over, before, after };
}
