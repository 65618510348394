import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  EMailTemplateGroupPicker,
  IEMailTemplateGroupProps,
} from '@work4all/components/lib/components/entity-picker/email-template-group-picker/EmailTemplateGroupPicker';

import { reactRefSetter } from '@work4all/utils';

import { LabeledInput } from '../../containers/mask-overlays/locked-inputs';

interface EMailTemplateGroupPickerFieldProps
  extends Omit<IEMailTemplateGroupProps<false>, 'multiple'> {
  error?: string;
  excludeTerminatedEMailTemplateGroups?: boolean;
}

export const EMailTemplateGroupPickerField = React.forwardRef<
  HTMLDivElement,
  EMailTemplateGroupPickerFieldProps
>(function EMailTemplateGroupPickerField(props, ref) {
  const { error, ...pickerProps } = props;
  const { value, onChange } = pickerProps;

  const { t } = useTranslation();

  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={reactRefSetter(wrapperRef, ref)}>
      <EntityPickerPopover
        anchorEl={wrapperRef}
        picker={<EMailTemplateGroupPicker {...pickerProps} multiple={false} />}
      >
        <LabeledInput
          value={value ? value?.name : ''}
          label={t('COMMON.GROUP')}
          error={error}
          onClear={() => {
            onChange(null);
          }}
        />
      </EntityPickerPopover>
    </div>
  );
});
