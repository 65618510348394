import styles from './TakePictureButton.module.scss';

import { IconButton } from '@mui/material';
import React from 'react';

import { ReactComponent as CameraIcon } from '@work4all/assets/icons/camera-alt.svg';

export const TakePictureButton: React.FC<{
  onClick: React.HTMLAttributes<HTMLButtonElement>['onClick'];
}> = (props) => {
  return (
    <IconButton
      className={styles.button}
      color="primary"
      onClick={props.onClick}
      size="large"
    >
      <CameraIcon />
    </IconButton>
  );
};
