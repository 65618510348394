import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import React, { useState } from 'react';

import { useResizeObserver } from '../../../../hooks';
import { ILabeledInput } from '../../LabeledInput';
import { IDropdownProps } from '../../types';

export const withDropdown = <Input extends ILabeledInput>(
  Input: React.FC<Input>,
  Dropdown: React.FC<Input & IDropdownProps>
) => {
  return React.forwardRef<HTMLInputElement, Input & IDropdownProps>(
    (props, ref) => {
      const {
        showDropdown,
        onHideDropdown,
        onShowDropdown,
        anchorEl,
        anchorCenterEl,
        ...rest
      } = props;

      const [anchorWidth, setAnchorWidth] = useState<number>();

      /*
      Measure the width of the anchor element (input) and limit the width of the
      dropdown to this width.
      */
      useResizeObserver(anchorEl, ({ contentRect }) => {
        setAnchorWidth(contentRect.width);
      });

      return (
        <>
          <Input ref={ref} {...(rest as Input)} onClick={onShowDropdown} />

          <Popper
            open={showDropdown}
            anchorEl={anchorEl}
            role={undefined}
            placement="bottom"
            /*
            The filters modal has a z-index of 1300. This needs to be higher
            than that because the dropdown is rendered in a portal and attached
            to the body container instead of being nested inside the modal in
            the DOM.
            */
            style={{
              zIndex: 1500,
              minWidth: 'min-content',
              maxWidth: anchorWidth,
            }}
          >
            <ClickAwayListener onClickAway={onHideDropdown}>
              {/*
               This wrapper element is required because the child element of
               ClickAwayListener must accept a ref to register the event
               handler and the provided Dropdown component might not do that.
               */}
              <div>
                <Dropdown {...props} />
              </div>
            </ClickAwayListener>
          </Popper>
        </>
      );
    }
  );
};
