import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { BusinessPartner } from './BusinessPartner.entity';
import { CallMemoAttachment } from './CallMemoAttachment.entity';
import { Contact } from './Contact.entity';
import { CustomField } from './CustomField.entity';
import { Project } from './Project.entity';
import { ProjectProcess } from './ProjectProcess.entity';
import { TopicSimple } from './TopicSimple.entity';
import { User } from './User.entity';

export class CallMemo<T extends EMode = EMode.entity> {
  /** Alias: anhaenge */
  attachmentList?: CallMemoAttachment<T>[];
  /** Alias: ansprechpartner */
  contact?: Contact<T>;
  /** Alias: ansprechpartnerCode */
  contactId?: number;
  /** Alias: artikelCode */
  articleId?: number;
  /** Alias: beginTime */
  startTime?: string;
  /** Alias: benutzer */
  user?: User<T>;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: code */
  id?: number;
  /** Alias: creationDate */
  creationDate?: string;
  /** Alias: datum */
  date?: string;
  /** Alias: endTime */
  endTime?: string;
  /** Alias: erinnernDate */
  reminderDate?: string;
  /** Alias: erstellerBenutzerCode */
  creatorUserId?: number;
  /** Alias: geschaeftspartner */
  businessPartner?: BusinessPartner<T>;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: kontaktberichtCode */
  contactReportId?: number;
  /** Alias: kostenerfassungCode */
  costAccountingId?: number;
  /** Alias: modificationDate */
  modificationDate?: string;
  /** Alias: notiz */
  note?: string;
  /** Alias: objGrCode */
  objGrId?: number;
  /** Alias: parentCode */
  parentId?: number;
  /** Alias: projekt */
  project?: Project<T>;
  /** Alias: projektCode */
  projectId?: number;
  /** Alias: projektePlanungCode */
  projectProcessId?: number;
  /** Alias: sDObjMemberCode */
  businessPartnerId?: number;
  /** Alias: sDObjType */
  businessPartnerType?: SdObjType;
  /** Alias: seriennummer */
  serialNumber?: string;
  /** Alias: status */
  status?: number;
  /** Alias: titel */
  title?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: projektVorgang */
  projectProcess?: ProjectProcess<T>;
  /** Alias: themenZuordnungen */
  topicMarkList?: TopicSimple<T>[];
  /** Alias: userRightsCanUpdate */
  userRightsCanUpdate?: boolean;
  /** Alias: userRightsCanDelete */
  userRightsCanDelete?: boolean;
  /** Alias: customFields */
  customFieldList?: CustomField<T>[];
  /** Alias: createdByUserCode */
  createdByUserCode?: number;

  __typename?: string;
}

const fields: FieldDefinitions<CallMemo> = {
  attachmentList: {
    alias: 'anhaenge',
    entity: Entities.callMemoAttachment,
  },
  contact: {
    alias: 'ansprechpartner',
    entity: Entities.contact,
  },
  contactId: {
    alias: 'ansprechpartnerCode',
  },
  articleId: {
    alias: 'artikelCode',
  },
  startTime: {
    alias: 'beginTime',
  },
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  userId: {
    alias: 'benutzerCode',
  },
  id: {
    alias: 'code',
  },
  creationDate: {
    alias: 'creationDate',
  },
  date: {
    alias: 'datum',
  },
  endTime: {
    alias: 'endTime',
  },
  reminderDate: {
    alias: 'erinnernDate',
  },
  creatorUserId: {
    alias: 'erstellerBenutzerCode',
  },
  businessPartner: {
    alias: 'geschaeftspartner',
    entity: Entities.businessPartner,
  },
  insertTime: {
    alias: 'insertTime',
  },
  contactReportId: {
    alias: 'kontaktberichtCode',
  },
  costAccountingId: {
    alias: 'kostenerfassungCode',
  },
  modificationDate: {
    alias: 'modificationDate',
  },
  note: {
    alias: 'notiz',
  },
  objGrId: {
    alias: 'objGrCode',
  },
  parentId: {
    alias: 'parentCode',
  },
  project: {
    alias: 'projekt',
    entity: Entities.project,
  },
  projectId: {
    alias: 'projektCode',
  },
  projectProcessId: {
    alias: 'projektePlanungCode',
  },
  businessPartnerId: {
    alias: 'sDObjMemberCode',
  },
  businessPartnerType: {
    alias: 'sDObjType',
  },
  serialNumber: {
    alias: 'seriennummer',
  },
  status: {
    alias: 'status',
  },
  title: {
    alias: 'titel',
  },
  updateTime: {
    alias: 'updateTime',
  },
  projectProcess: {
    alias: 'projektVorgang',
    entity: Entities.projectProcess,
  },
  topicMarkList: {
    alias: 'themenZuordnungen',
    entity: Entities.topicSimple,
  },
  userRightsCanUpdate: {
    alias: 'userRightsCanUpdate',
  },
  userRightsCanDelete: {
    alias: 'userRightsCanDelete',
  },
  customFieldList: {
    alias: 'customFields',
    entity: Entities.customField,
  },
  createdByUserCode: {
    alias: 'createdByUserCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const callMemoEntityDefinition: EntitiyDefinition<CallMemo> = {
  local: {},
  remote: {
    queryName: 'getTelefonate',
    fragmentName: 'Telefonat',
    withPaginationWrapper: true,
    mutations: {
      upsert: {
        mutationName: 'upsertTelefonat',
        args: [
          { name: 'input', type: 'InputTelefonat!' },
          { name: 'relations', type: 'InputTelefonatRelation' },
          { name: 'drySave', type: 'Boolean' },
        ],
      },
    },
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
