import styles from './ErpInitialView.module.scss';

import { Button, Typography } from '@mui/material';
import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog } from '@work4all/components';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { CreditorPickerField } from '../../../../../../../components/entity-picker/CreditorPickerField';
import { PartnerPickerByEntity } from '../../../../../../../components/entity-picker/PartnerPickerByEntity';

type State = {
  open: boolean;
  businessPartner: Customer | Supplier | null;
};

type Action =
  | { type: 'CLOSE' }
  | { type: 'CONFIRM'; payload: Customer | Supplier };

const initialState: State = {
  open: true,
  businessPartner: null,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'CLOSE':
      return {
        ...state,
        open: false,
      };
    case 'CONFIRM':
      return {
        ...state,
        open: false,
        businessPartner: action.payload,
      };
    default:
      throw new Error(`Unhandled action type: ${action}`);
  }
};

export const useErpInitialView = (
  open: boolean,
  onCanel?: (data: unknown) => void
) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    open,
  });

  const onClose = () => {
    dispatch({ type: 'CLOSE' });
  };

  const onConfirm = (selected: (Supplier | Customer)[]) => {
    dispatch({ type: 'CONFIRM', payload: selected[0] ?? null });
    onClose();
  };

  const closeController = !state.open && open && !state.businessPartner;
  useEffect(() => {
    if (closeController && onCanel) onCanel(null);
  }, [closeController, onCanel]);

  return { onClose, onConfirm, ...state };
};

export type ErpInitialViewProps = ReturnType<typeof useErpInitialView>;

export const ErpInitialView = (
  props: ErpInitialViewProps & { entity?: Entities; creditor?: boolean }
) => {
  const { onClose, open, onConfirm, businessPartner, entity, creditor } = props;
  const { t } = useTranslation();

  let picker = null;
  if (creditor)
    picker = (
      <CreditorPickerField
        label=""
        placeholder={t('COMMON.PICK_FROM', { from: t('COMMON.CREDITOR') })}
        onChange={(bp) => onConfirm([bp])}
        value={businessPartner as Supplier}
        required={false}
      />
    );
  else
    picker = (
      <PartnerPickerByEntity
        businessPartner={businessPartner}
        entity={entity}
        onChange={onConfirm}
      />
    );

  return (
    <Dialog
      open={open}
      title={t('COMMON.SELECTION_REQUIRED')}
      onClose={onClose}
    >
      <div className={styles.content}>
        <Typography variant="body1">
          {t('ALERTS.PRESELECT_INFO', {
            what: creditor
              ? t('COMMON.CREDITOR_S')
              : t(
                  `COMMON.${
                    entity ? `${entity?.toUpperCase()}S` : 'BUSINESSPARTNER'
                  }`
                ),
          })}
        </Typography>
        {picker}
        <Button onClick={onClose}>{t('ALERTS.BTN_ABORT')}</Button>
      </div>
    </Dialog>
  );
};
