import styles from './ContactRessourcePicker.module.scss';

import Typography from '@mui/material/Typography';
import { capitalize } from 'lodash';
import { useMemo, useState } from 'react';

import { ListEntityPicker } from '@work4all/components/lib/components/entity-picker/components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '@work4all/components/lib/components/entity-picker/types';

import { useDataProvider } from '@work4all/data';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { ContactUnionWrapper } from '@work4all/models/lib/Classes/ContactUnionWrapper.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { ContactKind } from '@work4all/models/lib/Enums/ContactKind.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export function createContactUnionWrapper(
  value: Contact | User | Customer | Supplier,
  contactKind: ContactKind
): ContactUnionWrapper {
  const result: ContactUnionWrapper = {
    id: `${capitalize(contactKind)}_${value?.id}`,
    contactKind,
    data: value,
  };

  return result;
}

const getRessourceName = (resource: ContactUnionWrapper) => {
  const value = resource.data as User & Contact & Customer & Supplier;
  let title = value?.displayName || value?.name || value?.loginName;
  if (
    [
      ContactKind.BENUTZER,
      ContactKind.KUNDENANSPRECHPARTNER,
      ContactKind.LIEFERANTENANSPRECHPARTNER,
      ContactKind.MITARBEITER,
    ].includes(resource.contactKind)
  ) {
    title =
      (value.lastName || value.name || '') + ' ' + (value.firstName || '');
  }
  return title;
};

export const abbreviate = (value: string, maxLength: number) => {
  return value?.length > maxLength
    ? value.substring(0, maxLength) + '...'
    : value;
};

export const renderChipContent = (resource: ContactUnionWrapper): string => {
  const value = resource.data as User & Contact & Customer & Supplier;

  const title = abbreviate(getRessourceName(resource), 25);
  const subtitle = abbreviate(value?.businessPartner?.data?.name, 20);

  return `${subtitle ? `${subtitle} | ` : ''}${title} `;
};

export const renderItemContent = (
  resource: ContactUnionWrapper
): React.ReactNode => {
  const value = resource.data as User & Contact & Customer & Supplier;

  const title = abbreviate(getRessourceName(resource), 25);
  const subtitle = abbreviate(value?.businessPartner?.data?.name, 20);

  return (
    <Typography component="div">
      <span>{title}</span>
      {subtitle ? (
        <span className={styles.secondaryItemLabel}>{` | ${subtitle}`}</span>
      ) : null}
    </Typography>
  );
};

export type IContactRessourcePickerProps<TMultiple extends boolean> =
  IPickerProps<ContactUnionWrapper, TMultiple> & {
    value: Array<ContactUnionWrapper>;
    contactKindList?: ContactKind[];
    renderChipContent?: (value: ContactUnionWrapper) => React.ReactNode;
  };

export function ContactRessourcePicker<TMultiple extends boolean>(
  props: IContactRessourcePickerProps<TMultiple>
) {
  const { data, ...rest } = props;
  let searchTimeout = null;

  const allFields = useMemo(() => {
    return { ...CONTACT_FIELDS, ...data };
  }, [data]);

  const [searchText, setSearchText] = useState<string>('');

  const requestData: DataRequest = useMemo(() => {
    const result: DataRequest = {
      entity: Entities.contactUnionWrapper,
      data: CONTACT_FIELDS,
      completeDataResponse: true,
      vars: {
        searchValue: '%' + searchText.split(' ').join('%') + '%',
        maxRows: 700,
        contactTypes: props.contactKindList,
      },
    };

    return result;
  }, [props.contactKindList, searchText]);

  const response = useDataProvider<ContactUnionWrapper>(requestData);

  const dataset = useMemo(() => {
    const value = Array.isArray(props.value)
      ? props.value
      : props.value
      ? [props.value]
      : [];
    return {
      total: response.data.length,
      loading: false,
      fetchMore: () => Promise.resolve(),
      refetch: () => Promise.resolve(null),
      data:
        searchText.trim().length === 0
          ? [...response.data, ...value]
          : response.data,
    };
  }, [searchText, response, props.value]);

  return (
    <ListEntityPicker
      layout="advanced"
      suppressFilter={true}
      multiple={props.multiple}
      entity={Entities.contactUnionWrapper}
      data={allFields}
      fixedDataSet={dataset}
      onChange={props.onChange}
      onSearchValueChange={(newSearchText) => {
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => {
          setSearchText(newSearchText);
        }, 200);
      }}
      filterBy={['displayName', 'number']}
      sortBy="displayName"
      {...rest}
      renderItemContent={renderItemContent}
      inputType="email"
    />
  );
}

const CONTACT_FIELDS: ContactUnionWrapper<EMode.query> = {
  id: null,
  contactKind: null,
  data: {
    customer: {
      id: null,
      eMail: null,
      name: null,
    },
    supplier: {
      id: null,
      eMail: null,
      name: null,
    },
    user: {
      id: null,
      displayName: null,
      lastName: null,
      firstName: null,
      eMail: null,
      userKind: null,
      loginName: null,
    },
    contact: {
      id: null,
      displayName: null,
      firstName: null,
      name: null,
      eMail: null,
      businessPartnerType: null,
      businessPartnerId: null,
      businessPartner: {
        id: null,
        data: {
          customer: {
            id: null,
            name: null,
          },
          supplier: {
            id: null,
            name: null,
          },
        },
      },
    },
  },
};
