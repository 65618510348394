import { useEffect, useMemo } from 'react';

import { useTableStateBag } from '../../dataDisplay/basic-table';
import { IColumnVisibilityContext } from '../../dataDisplay/basic-table/hooks/useColumnVisibility';

import { useLockObject } from './useLockObject';

export const useLockObjectColumn = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inData: any[],
  columnsVisibilityManager: IColumnVisibilityContext,
  skip = false
) => {
  const { objectLocks } = useLockObject(skip);

  const enrichedData = useMemo(() => {
    let isEnriched = false;
    let data = [];
    if (!skip) {
      data = inData.map((item) => {
        const lock = objectLocks?.find((o) => o.entityId === item.id);
        if (lock) {
          isEnriched = true;
          return { ...item, objectLock: lock.userId };
        }
        return item;
      });
    } else {
      data = inData;
    }
    return { data, isEnriched };
  }, [inData, objectLocks, skip]);

  const tableStateBag = useTableStateBag();
  const column = tableStateBag.columnsById['objectLock'];
  useEffect(() => {
    if (!column) {
      return;
    }
    const hideEmpty = !enrichedData.isEnriched && column.isVisible;
    const showAny = enrichedData.isEnriched && !column.isVisible;

    if (hideEmpty || showAny) {
      columnsVisibilityManager.toggleColumnVisibility('objectLock');
    }
  }, [enrichedData.isEnriched, columnsVisibilityManager, column]);

  return enrichedData;
};
