import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { WordDocumentTemplate } from './WordDocumentTemplate.entity';

export class DocumentTemplateGroup<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: name */
  name?: string;
  /** Alias: groupIndex */
  groupIndex?: number;
  /** Alias: templates */
  templates?: WordDocumentTemplate<T>[];
  /** Alias: documentClass */
  documentClass?: string;

  __typename?: string;
}

const fields: FieldDefinitions<DocumentTemplateGroup> = {
  id: {
    alias: 'code',
  },
  name: {
    alias: 'name',
  },
  groupIndex: {
    alias: 'groupIndex',
  },
  templates: {
    alias: 'templates',
    entity: Entities.wordDocumentTemplate,
  },
  documentClass: {
    alias: 'documentClass',
  },
  __typename: {
    alias: '__typename',
  },
};

export const documentTemplateGroupEntityDefinition: EntitiyDefinition<DocumentTemplateGroup> =
  {
    local: {},
    remote: {
      queryName: 'getDocumentTemplateGroups',
      fragmentName: 'DocumentTemplateGroup',
      withPaginationWrapper: false,
      params: [{ name: 'filter', type: 'String' }],
    },
    fieldDefinitions: fields,
  };
