/**
 * This utility function can be used to create exhaustive switch statements.
 * Put it in the `default` block of a switch statement if you want to get
 * a compilation erorr in case you forget to handle some case.
 *
 * If this function is called at runtime, it will always throw an error
 * with the given message.
 */
export function assertNever(_: never, errorMessage: string): never {
  const error = new Error(errorMessage);
  Error?.captureStackTrace(error, assertNever);
  throw error;
}
