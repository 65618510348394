import { useMemo } from 'react';

import { FixedDataPicker } from '../fixed-data-picker/FixedDataPicker';
import { IPickerProps } from '../types';

export type CustomFieldComboPickerPickerProps<TMultiple extends boolean> =
  IPickerProps<{ id: string; name: string }, TMultiple>;

export function CustomFieldComboPicker<TMultiple extends boolean>(
  props: CustomFieldComboPickerPickerProps<TMultiple> & {
    fieldOptions: string[];
  }
) {
  const { fieldOptions, ...rest } = props;

  const data = useMemo(() => {
    return fieldOptions.map((fieldOption) => {
      return {
        id: fieldOption,
        name: fieldOption,
      };
    });
  }, [fieldOptions]);

  return <FixedDataPicker dataSet={data} {...rest} />;
}
