import styles from './EntityPickerTabBar.module.scss';

import React, { useMemo } from 'react';

import { EntityPickerTabBarContext } from './entity-picker-tab-bar-context';

export type IEntityPickerTabBarProps<T extends string | number> = {
  value: T;
  onChange: (value: T) => void;
  children: React.ReactNode;
};

export function EntityPickerTabBar<T extends string | number>(
  props: IEntityPickerTabBarProps<T>
) {
  const { value, onChange, children } = props;

  const context = useMemo<EntityPickerTabBarContext>(
    () => ({ value, onChange }),
    [value, onChange]
  );

  return (
    <div className={styles['root']}>
      <div className={styles['inner']}>
        <EntityPickerTabBarContext.Provider value={context}>
          {children}
        </EntityPickerTabBarContext.Provider>
      </div>
    </div>
  );
}
