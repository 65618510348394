import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input';

import { reactRefSetter } from '@work4all/utils';

import {
  MailboxEncryptionPicker,
  MailboxEncryptionPickerProps,
} from './MailboxEncryptionPicker';

interface Props extends MailboxEncryptionPickerProps {
  error?: string;
  label?: string;
  required?: boolean;
}

export const MailboxEncryptionPickerField = React.forwardRef<
  HTMLDivElement,
  Props
>(function MailboxEncryptionPickerField(props, forwardedRef) {
  const { error, label, required, ...pickerProps } = props;
  const { value } = pickerProps;

  const { t } = useTranslation();

  const ref = useRef();

  const displayValue: string = value
    ? t(`LINK_MAILBOX.MAILBOX_ENCRYPTION.${value}`)
    : '';

  return (
    <div ref={reactRefSetter(ref, forwardedRef)}>
      <EntityPickerPopover
        anchorEl={ref}
        picker={<MailboxEncryptionPicker {...pickerProps} />}
      >
        <LabeledInput
          label={label || t('LINK_MAILBOX.INPUTS.ENCRYPTION')}
          error={error}
          required={required}
          value={displayValue}
        />
      </EntityPickerPopover>
    </div>
  );
});
