import { get } from 'lodash';

export const createTraverseRows = <
  TRow extends Record<string, unknown>,
  TKey1 extends keyof TRow,
  TKey2 extends keyof TRow
>(
  pathKey: TKey1,
  subRowsPathKey: TKey2
) => {
  return (path: string[], rows: TRow[]) => {
    if (path.length === 0) {
      return rows;
    }

    for (const id of path) {
      const row = rows.find((row) => get(row, pathKey) === id);

      if (row === undefined) break;

      rows = get(row, subRowsPathKey) as TRow[];
    }

    return rows;
  };
};
