import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

export class InputTerminTeilnehmerAdd<T extends EMode = EMode.entity> {
  /** Alias: benutzerCode */
  benutzerCode?: number;
  /** Alias: sdObjType */
  sdObjType?: SdObjType;
  /** Alias: sdObjMemberCode */
  sdObjMemberCode?: number;
  /** Alias: ansprechpartnerCode */
  ansprechpartnerCode?: number;

  __typename?: string;
}

const fields: FieldDefinitions<InputTerminTeilnehmerAdd> = {
  benutzerCode: {
    alias: 'benutzerCode',
  },
  sdObjType: {
    alias: 'sdObjType',
  },
  sdObjMemberCode: {
    alias: 'sdObjMemberCode',
  },
  ansprechpartnerCode: {
    alias: 'ansprechpartnerCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputTerminTeilnehmerAddEntityDefinition: EntitiyDefinition<InputTerminTeilnehmerAdd> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputTerminTeilnehmerAdd',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
