import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { ArticleKind } from '@work4all/models/lib/Enums/ArticleKind.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';
import { InsertBomStyle } from '@work4all/models/lib/Enums/InsertBomStyle.enum';

import { settings, useSetting } from '../../../../../../../../../../settings';
import { BomVariantSettingList } from '../../../../../../../../../../settings/settings';
import {
  convertEntityToBzObjType,
  ShadowObjectAddPositionArgs,
} from '../../../../../hooks/use-bz-shadow-object-api';
import {
  BomVariant,
  SLMODE_TO_BOM,
} from '../positions-bill-of-materials/BomVariantPicker';

export interface PositionsBillOfMaterialsValues {
  variant: BomVariant;
  noShow: boolean;
}

const BOM_ARTICLE_TYPES: ArticleKind[] = [
  ArticleKind.STUECKLISTE,
  ArticleKind.STILLGELEGTE_STUECKLISTE,
  ArticleKind.STAFFELPREISSTUECKLISTE,
];

interface UseBomDialogOptions {
  onAddPosition: (context: ShadowObjectAddPositionArgs) => void;
  entity: Entities;
}

export const useBomDialog = (options: UseBomDialogOptions) => {
  const { onAddPosition, entity } = options;
  const { set, value } = useSetting(settings.bomVariants());
  const [bomArticle, setBomArticle] = useState<Article | null>(null);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const ref = useRef<BomVariantSettingList>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  const onAddPositionWithArticle = useCallback(
    (context: ShadowObjectAddPositionArgs) => {
      if (!context.article) {
        onAddPosition(context);
        return;
      }
      const bomValue = ref.current;
      const hasArticle = Boolean(context.article);
      const isBomArticle =
        hasArticle && BOM_ARTICLE_TYPES.includes(context.article.articleKind);
      const shouldShowModal =
        isBomArticle &&
        !bomValue.find((x) => x.articleId === context.article.id && x.noShow);

      const bZObject = convertEntityToBzObjType(entity);
      const forcedSetting =
        isBomArticle &&
        (context.article.slModes || []).find((x) => x.bzObjType === bZObject);
      const forcedBomStyle = SLMODE_TO_BOM[forcedSetting?.slMode];

      if (shouldShowModal && !forcedBomStyle) {
        setBomArticle(context.article);
        return;
      }
      const settings = bomValue.find((x) => x.articleId === context.article.id);
      const bomStyle = forcedBomStyle ?? settings?.variant;
      if (isBomArticle && bomStyle) {
        if (
          bomStyle === InsertBomStyle.BOM_STYLE_5 ||
          bomStyle === InsertBomStyle.BOM_STYLE_6
        ) {
          enqueueSnackbar(t('MASK.BILL_OF_MATERIALS_ERROR_5_6'), {
            variant: 'error',
            autoHideDuration: 10000,
          });
          return;
        }

        onAddPosition({
          positionType: ErpPositionsKind.STUECKLISTE,
          article: context.article,
          bomStyle,
        });
      } else {
        onAddPosition(context);
      }
    },
    [onAddPosition, entity, enqueueSnackbar, t]
  );

  const onAddBomArticle = useCallback(
    (settingUpdate: PositionsBillOfMaterialsValues) => {
      const { noShow, variant } = settingUpdate;
      const allWithoutSelected = value.filter(
        (x) => x.articleId !== bomArticle.id
      );
      set([
        ...allWithoutSelected,
        {
          articleId: bomArticle.id,
          noShow,
          variant: variant.id,
        },
      ]);

      setBomArticle(null);
      onAddPosition({
        positionType: ErpPositionsKind.STUECKLISTE,
        article: bomArticle,
        bomStyle: variant.id,
      });
    },
    [onAddPosition, bomArticle, value, set]
  );

  const onBomClose = useCallback(() => {
    setBomArticle(null);
  }, []);

  return {
    bomArticle,
    onAddBomArticle,
    onAddPositionWithArticle,
    onBomClose,
  };
};
