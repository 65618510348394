import { useContext } from 'react';

import { DialogsContext } from './context';

export function useDialogs() {
  const context = useContext(DialogsContext);

  if (context === null) {
    throw new Error('useDialogs must be used within a <DialogsProvider>');
  }

  return context;
}
