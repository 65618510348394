import styles from './VacationDaysInfo.module.scss';

import Typography from '@mui/material/Typography';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

export interface VacationDaysInfoProps {
  label: string;
  days: number;
  info?: string | null;
}

export function VacationDaysInfo(props: VacationDaysInfoProps) {
  const { label, days, info } = props;

  const { t } = useTranslation();

  return (
    <Typography className={styles.root} component="div">
      <div className={styles.label}>{label}</div>
      <div className={styles.days}>
        {t(days === 1 ? 'ABSENCE.DAY' : 'ABSENCE.DAYS', {
          days: days.toLocaleString(i18next.language),
        })}
      </div>
      {!!info && <div className={styles.info}>{info}</div>}
    </Typography>
  );
}
