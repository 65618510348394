import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { BusinessPartner } from './BusinessPartner.entity';
import { Contact } from './Contact.entity';
import { CustomField } from './CustomField.entity';
import { NoteAttachment } from './NoteAttachment.entity';
import { Project } from './Project.entity';
import { ProjectProcess } from './ProjectProcess.entity';
import { TopicSimple } from './TopicSimple.entity';
import { User } from './User.entity';

export class Note<T extends EMode = EMode.entity> {
  /** Alias: anhaenge */
  attachmentList?: NoteAttachment<T>[];
  /** Alias: ansprechpartner */
  contact?: Contact<T>;
  /** Alias: ansprpCode */
  contactId?: number;
  /** Alias: artikelCode */
  articleId?: number;
  /** Alias: bCode */
  creatorUserId?: number;
  /** Alias: bErstellt */
  creationDate?: string;
  /** Alias: benutzer */
  user?: User<T>;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: code */
  id?: number;
  /** Alias: datum */
  date?: string;
  /** Alias: erinnernDate */
  reminderDate?: string;
  /** Alias: geschaeftspartner */
  businessPartner?: BusinessPartner<T>;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: kNBerichtCode */
  customerNumberVisitationId?: number;
  /** Alias: modificationDate */
  modificationDate?: string;
  /** Alias: notizText */
  note?: string;
  /** Alias: objGrCode */
  objGrId?: number;
  /** Alias: parentCode */
  parentId?: number;
  /** Alias: projekt */
  project?: Project<T>;
  /** Alias: projektCode */
  projectId?: number;
  /** Alias: projektePlanungCode */
  projectProcessId?: number;
  /** Alias: sDObjMemberCode */
  businessPartnerId?: number;
  /** Alias: sDObjType */
  businessPartnerType?: SdObjType;
  /** Alias: sN */
  serialNumber?: string;
  /** Alias: status */
  status?: number;
  /** Alias: titel */
  title?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: projektVorgang */
  projectProcess?: ProjectProcess<T>;
  /** Alias: themenZuordnungen */
  topicMarkList?: TopicSimple<T>[];
  /** Alias: customFields */
  customFieldList?: CustomField<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<Note> = {
  attachmentList: {
    alias: 'anhaenge',
    entity: Entities.noteAttachment,
  },
  contact: {
    alias: 'ansprechpartner',
    entity: Entities.contact,
  },
  contactId: {
    alias: 'ansprpCode',
  },
  articleId: {
    alias: 'artikelCode',
  },
  creatorUserId: {
    alias: 'bCode',
  },
  creationDate: {
    alias: 'bErstellt',
  },
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  userId: {
    alias: 'benutzerCode',
  },
  id: {
    alias: 'code',
  },
  date: {
    alias: 'datum',
  },
  reminderDate: {
    alias: 'erinnernDate',
  },
  businessPartner: {
    alias: 'geschaeftspartner',
    entity: Entities.businessPartner,
  },
  insertTime: {
    alias: 'insertTime',
  },
  customerNumberVisitationId: {
    alias: 'kNBerichtCode',
  },
  modificationDate: {
    alias: 'modificationDate',
  },
  note: {
    alias: 'notizText',
  },
  objGrId: {
    alias: 'objGrCode',
  },
  parentId: {
    alias: 'parentCode',
  },
  project: {
    alias: 'projekt',
    entity: Entities.project,
  },
  projectId: {
    alias: 'projektCode',
  },
  projectProcessId: {
    alias: 'projektePlanungCode',
  },
  businessPartnerId: {
    alias: 'sDObjMemberCode',
  },
  businessPartnerType: {
    alias: 'sDObjType',
  },
  serialNumber: {
    alias: 'sN',
  },
  status: {
    alias: 'status',
  },
  title: {
    alias: 'titel',
  },
  updateTime: {
    alias: 'updateTime',
  },
  projectProcess: {
    alias: 'projektVorgang',
    entity: Entities.projectProcess,
  },
  topicMarkList: {
    alias: 'themenZuordnungen',
    entity: Entities.topicSimple,
  },
  customFieldList: {
    alias: 'customFields',
    entity: Entities.customField,
  },
  __typename: {
    alias: '__typename',
  },
};

export const noteEntityDefinition: EntitiyDefinition<Note> = {
  local: {},
  remote: {
    queryName: 'getNotizen',
    fragmentName: 'Notiz',
    withPaginationWrapper: true,
    mutations: {
      upsert: {
        mutationName: 'upsertNotiz',
        args: [
          { name: 'input', type: 'InputNotiz!' },
          { name: 'relations', type: 'InputNotizRelation' },
          { name: 'drySave', type: 'Boolean' },
        ],
      },
    },
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
