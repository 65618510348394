import Box from '@mui/material/Box';
import { createContext, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FileEntity } from '@work4all/models/lib/Classes/FileEntity.entity';

import { PreviewTitle } from '../components/entity-preview/components';
import { StackProvider } from '../navigation/history-stack';
import { Content } from '../navigation/navigation-overlay/components/content/Content';

import { PreviewComponent } from './Preview';
import { usePreview } from './use-preview';

interface FilePreviewState extends FilePreviewProps {
  openFullscreen: () => void;
  closeFullscreen: () => void;
  fullscreen: boolean;
}

const FilePreviewContext = createContext<FilePreviewState>(null);

export const FilePreviewProvider = ({
  children,
  file,
}: {
  children: React.ReactNode;
} & FilePreviewProps) => {
  const { t } = useTranslation();
  const [fullscreen, setFullscreen] = useState(false);
  const openFullscreen = useCallback(() => {
    setFullscreen(true);
  }, []);

  const closeFullscreen = useCallback(() => {
    setFullscreen(false);
  }, []);

  return (
    <FilePreviewContext.Provider
      value={{ openFullscreen, closeFullscreen, fullscreen, file }}
    >
      {children}
      <StackProvider
        onClose={closeFullscreen}
        initialView={{
          title: file?.fileName || t('COMMON.PREVIEW'),
          view: (
            <Box height={'100%'} display="flex" flexDirection="column">
              <PreviewTitle onCloseClicked={closeFullscreen}>
                {file?.fileName}
              </PreviewTitle>
              <Box flex="1">{<FilePreview file={file} />}</Box>
            </Box>
          ),
        }}
      >
        <Content
          open={fullscreen}
          close={closeFullscreen}
          onExited={closeFullscreen}
        />
      </StackProvider>
    </FilePreviewContext.Provider>
  );
};

export const useFilePreview = () => {
  const context = useContext(FilePreviewContext);
  if (context === null) {
    throw new Error('useFilePreview must be used within <FilePreviewProvider>');
  }

  return context;
};
interface FilePreviewProps {
  file: { id?: string | number; fileName?: string; fileInfos?: FileEntity };
  initialScale?: number;
  initalTranslate?: string;
}
export const FilePreview = (props: FilePreviewProps) => {
  const { file, initialScale, initalTranslate } = props;
  const { scale, setScale: onScaleChange } = usePreview();

  const url = file.fileInfos?.previewUrl;
  const previewProps = {
    scale,
    onScaleChange,
    url,
    mimeType: file.fileInfos?.previewMimeType,
    initialScale,
    initalTranslate,
  };
  return <PreviewComponent {...previewProps} />;
};
