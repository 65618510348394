import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ShareIcon } from '@work4all/assets/icons/share.svg';

import { Base } from '../base/Base';
import { BaseActionProps } from '../types';

export const Share: React.FC<BaseActionProps> = (props) => {
  const { t } = useTranslation();

  return <Base title={t('INPUTS.SHARE')} icon={<ShareIcon />} {...props} />;
};
