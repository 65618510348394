import styles from './styles.module.scss';

import { FC, MouseEventHandler } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { OpenFileIconButton } from '@work4all/components/lib/components/entity-preview/components';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';

import { InlineData } from './InlineData';

type ICustomerSupplierCardProps = {
  customer: Customer;
  supplier: Supplier;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export const CustomerSupplierCard: FC<ICustomerSupplierCardProps> = ({
  customer,
  supplier,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className={customer ? styles['grid-div'] : null}>
        <div className={customer ? styles['grid-entity-column'] : null}>
          {customer && (
            <InlineData
              onClick={onClick}
              label={t('COMMON.CUSTOMER')}
              content={customer.name}
            />
          )}
        </div>
        <div className={customer ? styles['grid-file-column'] : styles['hide']}>
          <OpenFileIconButton data={customer} />
        </div>
      </div>
      <div className={supplier ? styles['grid-div'] : null}>
        <div className={supplier ? styles['grid-entity-column'] : null}>
          {supplier && (
            <InlineData
              onClick={onClick}
              label={t('COMMON.SUPPLIER')}
              content={supplier.name}
            />
          )}
        </div>
        <div className={supplier ? styles['grid-file-column'] : styles['hide']}>
          <OpenFileIconButton data={supplier} />
        </div>
      </div>
    </React.Fragment>
  );
};
