import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputCrmAnhangAttachementsRelation } from './InputCrmAnhangAttachementsRelation.entity';
import { InputTopicMarkRelation } from './InputTopicMarkRelation.entity';

export class InputNotizRelation<T extends EMode = EMode.entity> {
  /** Alias: attachements */
  attachements?: InputCrmAnhangAttachementsRelation<T>;
  /** Alias: topic */
  topic?: InputTopicMarkRelation<T>;

  __typename?: string;
}

const fields: FieldDefinitions<InputNotizRelation> = {
  attachements: {
    alias: 'attachements',
  },
  topic: {
    alias: 'topic',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputNotizRelationEntityDefinition: EntitiyDefinition<InputNotizRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputNotizRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
