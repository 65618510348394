import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class InputBenutzer<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: number;
  /** Alias: projectAccessRightsLimited */
  projectAccessRightsLimited?: boolean;

  __typename?: string;
}

const fields: FieldDefinitions<InputBenutzer> = {
  code: {
    alias: 'code',
  },
  projectAccessRightsLimited: {
    alias: 'projectAccessRightsLimited',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputBenutzerEntityDefinition: EntitiyDefinition<InputBenutzer> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'InputBenutzer',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
