import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { WordLetterTemplate } from './WordLetterTemplate.entity';

export class LetterTemplateGroup<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: name */
  name?: string;
  /** Alias: groupIndex */
  groupIndex?: number;
  /** Alias: templates */
  templates?: WordLetterTemplate<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<LetterTemplateGroup> = {
  id: {
    alias: 'code',
  },
  name: {
    alias: 'name',
  },
  groupIndex: {
    alias: 'groupIndex',
  },
  templates: {
    alias: 'templates',
    entity: Entities.wordLetterTemplate,
  },
  __typename: {
    alias: '__typename',
  },
};

export const letterTemplateGroupEntityDefinition: EntitiyDefinition<LetterTemplateGroup> =
  {
    local: {},
    remote: {
      queryName: 'getLetterTemplateGroups',
      fragmentName: 'LetterTemplateGroup',
      withPaginationWrapper: false,
      params: [{ name: 'filter', type: 'String' }],
    },
    fieldDefinitions: fields,
  };
