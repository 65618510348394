import { useState } from 'react';

import { EntityByObjectType } from '@work4all/models';
import { Mention } from '@work4all/models/lib/Classes/Mention.entity';
import { Notification } from '@work4all/models/lib/Classes/Notification.entity';
import { SaveSendMailJob } from '@work4all/models/lib/Classes/SaveSendMailJob.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { NotificationType } from '@work4all/models/lib/Enums/NotificationType.enum';

import { assertNever } from '@work4all/utils';

import { useSetNotificationReadMutation } from '../../hooks/use-set-notification-read-mutation';

interface NotificationMaskState {
  open: boolean;
  entity: Entities;
  id: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: Record<string, any>;
}

export function NotificationActions({
  onMutationCompleted,
  children,
}: {
  onMutationCompleted: () => void;
  children: (props: {
    mask: NotificationMaskState;
    onCloseMask: () => void;
    onNotificationClick: (notificationList: Notification[]) => void;
    onMarkAllAsReadClick: () => void;
  }) => JSX.Element;
}) {
  const [mask, setMask] = useState<NotificationMaskState>({
    open: false,
    entity: null,
    id: null,
    params: {},
  });

  function handleCloseMask() {
    setMask((mask) => {
      return { ...mask, open: false };
    });
  }

  const [setNotificationRead] = useSetNotificationReadMutation({
    onCompleted: onMutationCompleted,
  });

  function markMultipleAsRead(idList: string[]): void {
    setNotificationRead({
      variables: { notificationIds: idList.map((id) => ({ entityId: id })) },
    });
  }

  function markAllAsRead(): void {
    setNotificationRead({ variables: { all: true } });
  }

  function handleNotificationClick(notificationList: Notification[]) {
    const markAsReadIds = notificationList
      .filter((item) => !item.isRead)
      .map((item) => item.id);

    if (markAsReadIds.length > 0) {
      markMultipleAsRead(markAsReadIds);
    }

    const notification = notificationList[0];

    switch (notification.notificationType) {
      case NotificationType.MENTION: {
        const mention = notification.object as Mention;

        const entity = EntityByObjectType[mention.parentObjectType];
        const id = mention.parentObjectCode || mention.parentObjectId;

        setMask({
          open: true,
          entity,
          id,
          params: { tab: 'history' },
        });

        break;
      }

      case NotificationType.FAILED_SAVE_SEND_MAIL_JOB: {
        const job = notification.object as SaveSendMailJob;

        const entity = Entities.eMail;
        const id = job.createdMail.id;

        setMask({
          open: true,
          entity,
          id,
        });

        break;
      }

      default:
        assertNever(
          notification.notificationType,
          `Unknown notification type "${notification.notificationType}"`
        );
    }
  }

  function handleReadAllClick() {
    markAllAsRead();
  }

  return children({
    onNotificationClick: handleNotificationClick,
    onMarkAllAsReadClick: handleReadAllClick,
    mask: mask,
    onCloseMask: handleCloseMask,
  });
}
