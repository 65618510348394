import { useEffect } from 'react';

export const useSyncScroll = (
  type: 'horizontal' | 'vertical',
  ...refs: React.MutableRefObject<HTMLDivElement>[]
) => {
  useEffect(() => {
    if (refs.every((x) => x.current)) {
      const handleScroll = (e) => {
        refs.forEach((ref) => {
          if (ref.current === e.target) return;
          if (type === 'horizontal')
            ref.current.scrollLeft = e.target.scrollLeft;
          else ref.current.scrollTop = e.target.scrollTop;
        });
      };

      const elements = refs.map((x) => x.current);

      elements.forEach((el) => {
        el.addEventListener('scroll', handleScroll);
      });

      return () => {
        elements.forEach((el) => {
          el.removeEventListener('scroll', handleScroll);
        });
      };
    }
  }, [refs, type]);
};
