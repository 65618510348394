import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useEventCallback } from '@mui/material/utils';
import { createContext, useContext, useMemo, useState } from 'react';

import {
  IStackItem,
  useHistoryStack,
} from '@work4all/components/lib/navigation/history-stack';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { useHeaderInject } from '../../../containers/file-entities-lists/components/contentWrapper/HeaderComponentProvider';
import { LinkMailboxDialog } from '../LinkMailboxDialog';

import { SelectedMailboxFolder } from './mailbox-content-manager/types';
import {
  MailboxContentTableQuickFilters,
  MailboxContentTableQuickFiltersProps,
} from './MailboxContentTableQuickFilters';

export type MobileFolderSidebarProps = MailboxContentTableQuickFiltersProps;

export function MobileFolderSidebar(props: MobileFolderSidebarProps) {
  const [popoverState, setPopoverState] = useState<boolean>(false);

  const [isLinkMailboxDialogOpen, setLinkMailboxDialogOpen] = useState(false);

  const component = useMemo(
    () => (
      <Box sx={{ display: 'flex', alignItems: 'center', px: '1rem' }}>
        <IconButton onClick={() => setPopoverState(true)}>
          <MenuIcon />
        </IconButton>
      </Box>
    ),
    []
  );

  useHeaderInject(component);

  const handleSelectedFolderChange = useEventCallback(
    (folder: SelectedMailboxFolder) => {
      setPopoverState(false);
      props.onSelectedFolderChange(folder);
    }
  );

  const context = useMemo(
    () => ({
      mailboxes: props.mailboxes,
      selectedFolder: props.selectedFolder,
      onDeleteMailbox: props.onDeleteMailbox,
      onSelectedFolderChange: handleSelectedFolderChange,
    }),
    [
      props.mailboxes,
      props.selectedFolder,
      props.onDeleteMailbox,
      handleSelectedFolderChange,
    ]
  );

  return (
    <>
      <LinkMailboxDialog
        open={isLinkMailboxDialogOpen}
        onClose={() => {
          setLinkMailboxDialogOpen(false);
        }}
      />

      <Context.Provider value={context}>
        <NavigationOverlay
          open={popoverState}
          initialView={VIEW}
          withBreadcrumbs={true}
          close={() => {
            setPopoverState(false);
          }}
        />
      </Context.Provider>
    </>
  );
}

const VIEW: IStackItem = { view: <Content /> };

const Context = createContext<MailboxContentTableQuickFiltersProps>(null);

function Content() {
  const props = useContext(Context);

  const stack = useHistoryStack();

  return (
    <Stack direction="column">
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <IconButton onClick={() => stack.close()}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h4" sx={{ my: '1rem' }}>
          Postfächer
        </Typography>
      </Box>

      <Divider />

      <Box sx={{ flex: 1, overflow: 'auto' }}>
        <MailboxContentTableQuickFilters {...props} />
      </Box>
    </Stack>
  );
}
