import { MINIMUM_API_VERSION } from '../../../config';

import { ApiVersion, parseApiVersion } from './parse-api-version';

export interface CheckApiVersionResult {
  satisfies: boolean;
  isLower: boolean;
  isGreater: boolean;
  isExact: boolean;
}

export function checkApiVersion(version: string): CheckApiVersionResult {
  const diff = compareVersions(
    parseApiVersion(version),
    parseApiVersion(MINIMUM_API_VERSION)
  );

  const isLower = diff < 0;
  const isGreater = diff > 0;
  const isExact = diff === 0;

  return {
    satisfies: isExact || isGreater,
    isLower,
    isGreater,
    isExact,
  };
}

function compareVersions(a: ApiVersion, b: ApiVersion): -1 | 0 | 1 {
  // Compare the version numbers segment by segment.
  for (let i = 0; i < a.length; i++) {
    const diff = a[i] - b[i];

    if (diff < 0) {
      return -1;
    } else if (diff > 0) {
      return 1;
    }
  }

  return 0;
}
