import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { BusinessPartner } from './BusinessPartner.entity';
import { Contact } from './Contact.entity';
import { LookUp } from './LookUp.entity';

export class AddressConnection<T extends EMode = EMode.entity> {
  /** Alias: ansprechpartnerCodeA */
  contactIdA?: number;
  /** Alias: ansprechpartnerCodeB */
  contactIdB?: number;
  /** Alias: code */
  id?: number;
  /** Alias: creationDate */
  creationDate?: string;
  /** Alias: erstellerCode */
  creatorId?: number;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: sdObjMemberCodeA */
  sdObjMemberIdA?: number;
  /** Alias: sdObjMemberCodeB */
  sdObjMemberIdB?: number;
  /** Alias: sdObjTypeA */
  sdObjTypeA?: SdObjType;
  /** Alias: sdObjTypeB */
  sdObjTypeB?: SdObjType;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: verknuepfung */
  connection?: LookUp<T>;
  /** Alias: verknuepfungCode */
  connectionId?: number;
  /** Alias: geschaeftspartnerA */
  businessPartnerA?: BusinessPartner<T>;
  /** Alias: geschaeftspartnerB */
  businessPartnerB?: BusinessPartner<T>;
  /** Alias: ansprechpartnerA */
  contactA?: Contact<T>;
  /** Alias: ansprechpartnerB */
  contactB?: Contact<T>;

  __typename?: string;
}

const fields: FieldDefinitions<AddressConnection> = {
  contactIdA: {
    alias: 'ansprechpartnerCodeA',
  },
  contactIdB: {
    alias: 'ansprechpartnerCodeB',
  },
  id: {
    alias: 'code',
  },
  creationDate: {
    alias: 'creationDate',
  },
  creatorId: {
    alias: 'erstellerCode',
  },
  insertTime: {
    alias: 'insertTime',
  },
  sdObjMemberIdA: {
    alias: 'sdObjMemberCodeA',
  },
  sdObjMemberIdB: {
    alias: 'sdObjMemberCodeB',
  },
  sdObjTypeA: {
    alias: 'sdObjTypeA',
  },
  sdObjTypeB: {
    alias: 'sdObjTypeB',
  },
  updateTime: {
    alias: 'updateTime',
  },
  connection: {
    alias: 'verknuepfung',
    entity: Entities.lookUp,
  },
  connectionId: {
    alias: 'verknuepfungCode',
  },
  businessPartnerA: {
    alias: 'geschaeftspartnerA',
    entity: Entities.businessPartner,
  },
  businessPartnerB: {
    alias: 'geschaeftspartnerB',
    entity: Entities.businessPartner,
  },
  contactA: {
    alias: 'ansprechpartnerA',
    entity: Entities.contact,
  },
  contactB: {
    alias: 'ansprechpartnerB',
    entity: Entities.contact,
  },
  __typename: {
    alias: '__typename',
  },
};

export const addressConnectionEntityDefinition: EntitiyDefinition<AddressConnection> =
  {
    local: {},
    remote: {
      queryName: 'getAdressVerknuepfung',
      fragmentName: 'AdressVerknuepfung',
      withPaginationWrapper: true,
      mutations: {
        upsert: {
          mutationName: 'upsertAdressVerknuepfung',
          args: [
            { name: 'input', type: 'InputAdressVerknuepfung!' },
            { name: 'drySave', type: 'Boolean' },
            { name: 'reverseConnectionId', type: 'Int' },
          ],
        },
      },
      params: [
        { name: 'querySize', type: 'Int' },
        { name: 'queryPage', type: 'Int' },
        { name: 'querySortOrder', type: 'SortOrderStyle' },
        { name: 'querySortBy', type: 'String' },
        { name: 'filter', type: 'String' },
      ],
    },
    fieldDefinitions: fields,
  };
