import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { CostCenter } from './CostCenter.entity';
import { LedgerAccount } from './LedgerAccount.entity';

export class RaLedgerAccountSplit<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: betrag */
  betrag?: number;
  /** Alias: anteilProzent */
  anteilProzent?: number;
  /** Alias: kostenstelleCode */
  kostenstelleCode?: number;
  /** Alias: kostenstelle */
  kostenstelle?: number;
  /** Alias: mwstSatz */
  mwstSatz?: number;
  /** Alias: notiz */
  note?: string;
  /** Alias: sachkonto */
  sachkonto?: string;
  /** Alias: steuerschluessel */
  steuerschluessel?: number;
  /** Alias: kostenstelle2 */
  kostenstelle2?: number;
  /** Alias: raCode */
  raCode?: number;
  /** Alias: netto */
  netto?: number;
  /** Alias: mwstBetrag */
  mwstBetrag?: number;
  /** Alias: kostenstelleItem */
  kostenstelleItem?: CostCenter<T>;
  /** Alias: konto */
  konto?: LedgerAccount<T>;

  __typename?: string;
}

const fields: FieldDefinitions<RaLedgerAccountSplit> = {
  id: {
    alias: 'code',
  },
  betrag: {
    alias: 'betrag',
  },
  anteilProzent: {
    alias: 'anteilProzent',
  },
  kostenstelleCode: {
    alias: 'kostenstelleCode',
  },
  kostenstelle: {
    alias: 'kostenstelle',
  },
  mwstSatz: {
    alias: 'mwstSatz',
  },
  note: {
    alias: 'notiz',
  },
  sachkonto: {
    alias: 'sachkonto',
  },
  steuerschluessel: {
    alias: 'steuerschluessel',
  },
  kostenstelle2: {
    alias: 'kostenstelle2',
  },
  raCode: {
    alias: 'raCode',
  },
  netto: {
    alias: 'netto',
  },
  mwstBetrag: {
    alias: 'mwstBetrag',
  },
  kostenstelleItem: {
    alias: 'kostenstelleItem',
    entity: Entities.costCenter,
  },
  konto: {
    alias: 'konto',
    entity: Entities.ledgerAccount,
  },
  __typename: {
    alias: '__typename',
  },
};

export const raLedgerAccountSplitEntityDefinition: EntitiyDefinition<RaLedgerAccountSplit> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'RaErloeskontoSplit',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
