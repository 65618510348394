import { MenuItem } from '@mui/material';
import { useEventCallback } from '@mui/material/utils';
import { cloneDeep, pick } from 'lodash';
import { DateTime } from 'luxon';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AttachmentsDropZone } from '@work4all/components/lib/components/attachments/AttachmentsDropZone';
import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';

import { useDataProvider, useUser } from '@work4all/data';
import {
  TempFileManagerContext,
  useTempFileManager,
} from '@work4all/data/lib/hooks/data-provider/useTempFileManager';
import { useEntityJsonSchema } from '@work4all/data/lib/json-schema/EntityJsonSchemasContext';

import { BankDetails } from '@work4all/models/lib/Classes/BankDetails.entity';
import { Currency } from '@work4all/models/lib/Classes/Currency.entity';
import { InboundInvoice } from '@work4all/models/lib/Classes/InboundInvoice.entity';
import { InputErpAnhangAttachementsRelation } from '@work4all/models/lib/Classes/InputErpAnhangAttachementsRelation.entity';
import { PaymentKind } from '@work4all/models/lib/Classes/PaymentKind.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useJSONSchemaResolver } from '@work4all/utils/lib/form-utils/jsonSchemaResolver';
import {
  canAddInboundInvoice,
  canDeleteInboundInvoice,
  canEditInboundInvoice,
} from '@work4all/utils/lib/permissions';

import useAttachementsRelation from '../../../../../hooks/useAttachementsRelation';
import {
  MaskTab,
  MaskTabContext,
  MaskTabPanel,
  MaskTabs,
} from '../../../mask-tabs';
import { Form, MaskOverlayHeader } from '../../components';
import { INDIVIDUAL_TAB_ID } from '../../components/custom-fields/contants';
import { MaskContent } from '../../components/MaskContent/MaskContent';
import { MaskOverlayFullscreenToggleButton } from '../../components/MaskOverlayFullscreenToggleButton';
import { MaskOverlayMenuWrapper } from '../../components/MaskOverlayMenuWrapper';
import { MaskOverlaySubmitButton } from '../../components/MaskOverlaySubmitButton';
import { HistoryTabPanel } from '../../components/tab-panels/history/HistoryTabPanel';
import { MaskContextProvider, useMaskConfig } from '../../hooks/mask-context';
import { useConfirmBeforeCloseMask } from '../../hooks/use-confrm-before-close-mask';
import { EntityRightsContext } from '../../hooks/use-entity-rights';
import { useMaskLock } from '../../hooks/use-mask-lock';
import { useExtendedForm } from '../../hooks/useExtendedFormContext';
import { MaskControllerProps } from '../../types';
import { parseTemplate } from '../../utils/use-assignable-template-entity';
import { useFormUpdate } from '../../utils/use-form-update';
import {
  ErpInitialView,
  useErpInitialView,
} from '../erp/components/initial-view/ErpInitialView';
import { InboundInvoiceSettings } from '../settings/inbound-invoice-settings/InboundInvoiceSettings';

import { GeneralTabPanel, MoreTabPanel } from './components/tab-panels';
import { CurrencyExchangeInfoContextProvider } from './CurrencyExchangeInfoContextProvider';
import { useSkontoStartDate, useSkontoUpdate } from './hooks/use-skonto-update';
import {
  InboundInvoiceFormValue,
  InboundInvoiceLocalData,
  InboundInvoiceRemoteData,
} from './types';
import { useShadowReObjectApi } from './use-re-shadow-object-api/use-shadow-re-object-api';
import { ShadowReObjectApiContextProvider } from './use-re-shadow-object-api/use-shadow-re-object-api-context';
import { SHADOW_RE_OBJECT_RESPONSE_FIELDS } from './use-re-shadow-object-api/use-shadow-re-object-graphql';

export function InboundInvoiceOverlayController(props: MaskControllerProps) {
  const { t } = useTranslation();
  const { onAfterSave } = props;
  const mask = useMaskConfig(props);

  const lock = useMaskLock(mask);

  const shouldUseRegularQuery = !lock.isLoading && lock.isLocked;
  const shouldUseShadowObjectApi = !lock.isLoading && !lock.isLocked;

  const schema = useEntityJsonSchema(mask.entity);

  const customRules = useCallback(
    (input: InboundInvoiceRemoteData) => {
      if (!input.businessPartnerId || !input.supplier) {
        return {
          supplier: {
            message: t('ERROR.FIELD_REQUIRED'),
            type: 'customValidation',
          },
        };
      }
      return true;
    },
    [t]
  );

  const resolver = useJSONSchemaResolver(schema, customRules);
  const user = useUser();
  const template = parseTemplate(mask);

  const initialProps = useErpInitialView(!template && !mask.id, onAfterSave);

  const query = useDataProvider(
    useMemo(() => {
      const data = SHADOW_RE_OBJECT_RESPONSE_FIELDS.data;

      return {
        skip: !shouldUseRegularQuery,
        filter: [{ id: { $eq: mask.id } }],
        entity: mask.entity,
        data,
      };
    }, [mask.entity, mask.id, shouldUseRegularQuery])
  );

  const [shadowObject, shadowObjectApi] = useShadowReObjectApi({
    entity: mask.entity,
    id: mask.id,
    parentEntity: template?.businessPartnerType || Entities.supplier,
    parentId: template?.businessPartnerId || initialProps.businessPartner?.id,
    skip:
      !shouldUseShadowObjectApi ||
      (mask.isCreateMode && !template && !initialProps.businessPartner),
  });

  const entityData =
    (shouldUseRegularQuery
      ? query.data[0]
      : shouldUseShadowObjectApi
      ? shadowObject?.data
      : null) ?? null;

  const localState = useRef<InboundInvoiceLocalData>({
    localOrders: [],
    localInboundDeliveryNotes: [],
  });

  const extendedState = useMemo(() => {
    if (shadowObject?.type === 'create') {
      localState.current.localOrders = shadowObject.data.order;
      localState.current.localInboundDeliveryNotes =
        shadowObject.data.inboundDeliveryNote;
    }
    return {
      ...entityData,
      ...localState.current,
    };
  }, [shadowObject, entityData]);

  const data = extendedState;

  const form = useForm<InboundInvoiceFormValue>({
    resolver,
    mode: 'onChange',
    defaultValues: data,
    context: {
      schema,
    },
  });

  const extendedForm = useExtendedForm<InboundInvoiceFormValue>(
    data,
    form,
    schema
  );
  const startDate = useSkontoStartDate();
  useSkontoUpdate(form);

  const { getValues } = form;
  useFormUpdate(
    {
      approvalByUser: (user: User) => {
        return {
          approvedByUserId: user?.id || 0,
        };
      },
      releaseUser: (user: User) => {
        return {
          releaseUserId: user?.id || 0,
        };
      },
      blockedByUser: (user: User) => {
        return {
          lockUserCode: user?.id || 0,
        };
      },
      currency: (currency: Currency) => {
        return {
          currencyId: currency?.id || 0,
        };
      },
      bankDetails: (bankDetails: BankDetails) => {
        return {
          bankDetailsId: bankDetails?.id || 0,
        };
      },
      supplier: (supplier: Supplier) => {
        return {
          bankDetails: undefined,
          businessPartnerId: supplier?.id || 0,
        };
      },
      paymentKind: (paymentKind: PaymentKind) => {
        return {
          paymentId: paymentKind ? paymentKind.id : 0,
        };
      },
      skontoDurationDays: (skontoDurationDays: number) => {
        const receivedDate = getValues(startDate);
        if (skontoDurationDays < 0) return { skontoDurationDays: 0 };
        if (!receivedDate || Number.isNaN(skontoDurationDays)) return {};
        const date = DateTime.fromISO(receivedDate);
        const skontoDate = date.plus({ days: skontoDurationDays }).toISO();
        return { skontoDate };
      },
      skontoDate: (skontoDate: string) => {
        const receivedDate = getValues(startDate);
        if (!receivedDate) return {};

        const date = DateTime.fromISO(receivedDate);
        const kontoDate = DateTime.fromISO(skontoDate);

        const skontoDurationDays = Number(
          kontoDate.diff(date, 'days').toFormat('d')
        );
        if (skontoDurationDays < 0)
          return { skontoDurationDays: 0, skontoDate: receivedDate };
        return { skontoDurationDays };
      },
      skonto2DurationDays: (skonto2DurationDays: number) => {
        const receivedDate = getValues(startDate);
        if (skonto2DurationDays < 0) return { skonto2DurationDays: 0 };
        if (!receivedDate || Number.isNaN(skonto2DurationDays)) return {};
        const date = DateTime.fromISO(receivedDate);
        const skonto2Date = date.plus({ days: skonto2DurationDays }).toISO();
        return { skonto2Date };
      },
      skonto2Date: (skonto2Date: string) => {
        const receivedDate = getValues(startDate);
        if (!receivedDate) return {};

        const date = DateTime.fromISO(receivedDate);
        const kontoDate = DateTime.fromISO(skonto2Date);

        const skonto2DurationDays = Number(
          kontoDate.diff(date, 'days').toFormat('d')
        );
        if (skonto2DurationDays < 0)
          return { skonto2DurationDays: 0, skonto2Date: receivedDate };
        return { skonto2DurationDays };
      },
      dueDate: (dueDateIso: string) => {
        const date = getValues('receivedDate');
        const invoiceDate = DateTime.fromISO(date);
        const dueDate = DateTime.fromISO(dueDateIso);
        const days = Number(dueDate.diff(invoiceDate, 'days').toFormat('d'));

        if (days <= 0) {
          return { dueDate: date };
        }
      },
      localOrders: (orders) => {
        localState.current.localOrders = orders;
        return {};
      },
      localInboundDeliveryNotes: (inboundDeliveryNotes) => {
        localState.current.localInboundDeliveryNotes = inboundDeliveryNotes;
        return {};
      },
    },
    form,
    [data, startDate]
  );

  const { formState, handleSubmit } = extendedForm;
  const { isSubmitting } = formState;

  const isDirty = shadowObjectApi.isDirty;

  const formValues = form.watch();
  const lastFormValues = useRef<InboundInvoiceFormValue>(null);

  const handleFormChange = useEventCallback(() => {
    const pickFields = Object.keys(formState.dirtyFields);

    if (
      JSON.stringify(formValues) !== JSON.stringify(lastFormValues.current) &&
      formValues &&
      Object.values(formValues).filter((x) => x).length &&
      lastFormValues.current &&
      Object.values(lastFormValues.current).filter((x) => x).length &&
      formValues.supplier &&
      pickFields.length > 0
    ) {
      const { __typename, ...input } = formValues;
      // TODO: problem is that DatePicker when clear return '' value instead of null
      if (!input.plannedDeliveryDate) input.plannedDeliveryDate = null;
      if (!input.deliveryDate) input.deliveryDate = null;
      if (!input.skonto2Date) input.skonto2Date = null;
      if (!input.skontoDate) input.skontoDate = null;
      if (input.paymentTermDays && typeof input.paymentTermDays === 'string')
        input.paymentTermDays = parseInt(input.paymentTermDays);

      const toModify = pick(input, pickFields);
      shadowObjectApi.modify(toModify, {
        assignedDeliveryNotes: input.localInboundDeliveryNotes.map((x) => x.id),
        assignedOrders: input.localOrders.map((x) => x.id),
      });
    }

    lastFormValues.current = cloneDeep(formValues);
  });

  useEffect(handleFormChange, [handleFormChange, formValues]);

  const entityRights = useMemo(
    () => ({
      create: canAddInboundInvoice(user),
      read: false,
      update: canEditInboundInvoice(user, data as InboundInvoice<EMode.entity>),
      delete: canDeleteInboundInvoice(
        user,
        data as InboundInvoice<EMode.entity>
      ),
    }),
    [data, user]
  );

  const receipt = useMemo(() => {
    return data?.belegList?.map((x) => {
      return {
        ...x,
      };
    });
  }, [data?.belegList]);

  const tempFileManager = useTempFileManager(receipt);

  const attachments =
    useAttachementsRelation<InputErpAnhangAttachementsRelation>(
      tempFileManager,
      Entities.erpAttachment,
      'id'
    );

  const attachementsDirty = attachments?.attachements
    ? Object.entries(attachments?.attachements)
        .filter((x) => x[1])
        .some((x) => x[1].length)
    : false;

  const onSubmit = useCallback(async () => {
    const submitShadowObject = async () => {
      await shadowObjectApi.persist(undefined, attachments?.attachements);
      onAfterSave?.(null);
    };

    await submitShadowObject();
  }, [attachments, shadowObjectApi, onAfterSave]);

  const computedIsDirty = attachementsDirty || isDirty || formState.isDirty;
  useConfirmBeforeCloseMask(computedIsDirty);

  const { go } = useHistoryStack();

  const openSettings = useCallback(() => {
    go({
      title: t('SETTINGS.SETTINGS'),
      subTitle: '',
      view: <InboundInvoiceSettings amplitudeEntryPoint="InboundInvoiceMask" />,
    });
  }, [go, t]);

  return (
    <MaskContextProvider value={mask}>
      <EntityRightsContext.Provider value={entityRights}>
        <TempFileManagerContext.Provider value={tempFileManager}>
          <CurrencyExchangeInfoContextProvider>
            <ShadowReObjectApiContextProvider value={shadowObjectApi}>
              <FormProvider {...form}>
                <MaskTabContext
                  defaultValue={mask.params?.tab || props.openTab || 'general'}
                >
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <MaskOverlayHeader
                      title={t(`COMMON.${mask.entity.toUpperCase()}`)}
                      subTitle={data?.supplier?.name}
                      actions={
                        <>
                          <MaskOverlaySubmitButton
                            loading={isSubmitting}
                            disabled={!computedIsDirty}
                          />
                          <MaskOverlayFullscreenToggleButton />
                          <MaskOverlayMenuWrapper>
                            <MenuItem onClick={openSettings}>
                              {t('MASK.SETTINGS')}
                            </MenuItem>
                          </MaskOverlayMenuWrapper>
                        </>
                      }
                      tabs={<Tabs isCreateMode={mask.isCreateMode} />}
                    />
                    <Content isCreateMode={mask.isCreateMode} />
                    <ErpInitialView
                      {...initialProps}
                      creditor
                      entity={Entities.supplier}
                    />
                  </Form>
                </MaskTabContext>
              </FormProvider>
            </ShadowReObjectApiContextProvider>
          </CurrencyExchangeInfoContextProvider>
        </TempFileManagerContext.Provider>
      </EntityRightsContext.Provider>
    </MaskContextProvider>
  );
}

const Tabs = memo(function AppointmentTabs({
  isCreateMode,
}: {
  isCreateMode: boolean;
}) {
  const { t } = useTranslation();

  return (
    <MaskTabs>
      <MaskTab value={'general'} label={t('MASK.GENERAL')} />
      <MaskTab value={'more'} label={t('MASK.MORE')} />
      <MaskTab
        disabled
        value={INDIVIDUAL_TAB_ID}
        label={t('MASK.INDIVIDUAL')}
      />
      <MaskTab
        value="history"
        disabled={isCreateMode}
        label={t('MASK.HISTORY')}
      />
    </MaskTabs>
  );
});

const Content = memo(function AppointmentTabPanels({
  isCreateMode,
}: {
  isCreateMode: boolean;
}) {
  return (
    <AttachmentsDropZone>
      <MaskContent>
        <MaskTabPanel value={'general'}>
          <GeneralTabPanel />
        </MaskTabPanel>

        <MaskTabPanel value={'more'}>
          <MoreTabPanel />
        </MaskTabPanel>

        {!isCreateMode && (
          <MaskTabPanel value="history">
            <HistoryTabPanel />
          </MaskTabPanel>
        )}
      </MaskContent>
    </AttachmentsDropZone>
  );
});
