import React from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  IInvoiceFormPickerProps,
  InvoiceFormPicker,
  InvoiceFormTranslationKeys,
} from '@work4all/components/lib/components/entity-picker/invoice-form-picker/InvoiceFormPicker';

import { InvoiceForm } from '@work4all/models/lib/Enums/InvoiceForm.enum';

import { reactRefSetter } from '@work4all/utils';

import { LabeledInput } from '../../containers/mask-overlays/locked-inputs';

interface Props
  extends Omit<
    IInvoiceFormPickerProps<false>,
    'multiple' | 'value' | 'onChange'
  > {
  error?: string;
  value: InvoiceForm;
  onChange: (value: InvoiceForm) => void;
}

export const InvoiceFormPickerField = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { t } = useTranslation();
    const wrapperRef = useRef();
    const { error, ...pickerProps } = props;
    const { value: invoiceForm, onChange } = pickerProps;

    return (
      <div ref={reactRefSetter(wrapperRef, ref)}>
        <EntityPickerPopover
          anchorEl={wrapperRef}
          picker={
            <InvoiceFormPicker
              multiple={false}
              value={{ id: invoiceForm, name: '' }}
              onChange={(value) => {
                onChange(value?.id as InvoiceForm);
              }}
            />
          }
        >
          <LabeledInput
            onClear={() => {
              onChange(null);
            }}
            label={t('INPUTS.INVOICE_FORM')}
            error={error}
            value={
              invoiceForm
                ? (t(InvoiceFormTranslationKeys[invoiceForm]) as string)
                : ''
            }
          />
        </EntityPickerPopover>
      </div>
    );
  }
);
