import { gql, useQuery } from '@apollo/client';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import { useEntityChanges } from '@work4all/data/lib/hooks/use-entity-changed';

import { ObjectLock } from '@work4all/models/lib/Classes/ObjectLock.entity';
import { ChangeType } from '@work4all/models/lib/Enums/ChangeType.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ObjectTypeByEntity } from '@work4all/models/lib/GraphQLEntities/Entities';

import { isDev } from '@work4all/utils';

const GET_OBJECT_LOCKS = gql`
  query getObjectLocks($objectTypes: [ObjectType]!) {
    getObjectLocks(objectTypes: $objectTypes) {
      objPrimaryKey: objPrimaryKey
      user: benutzer {
        id: code
        displayName: anzeigename
        __typename
      }
      __typename
    }
  }
`;

interface ObjectLockResult {
  getObjectLocks: ObjectLock[];
}

interface LockObjectProviderItem {
  entityId: string;
  userId: number;
  userDisplayName: string;
}

interface ILockObjectContext {
  objectLocks: LockObjectProviderItem[];
  loading: boolean;
}

const LockObjectContext = createContext<ILockObjectContext>(undefined);

interface ILockObjectProviderProps {
  entity: Entities;
}

/**
 * Subscription could be done with no specific entity.
 * This implementation is optimize to entity cause there is no other use case right now.
 */
export const LockObjectProvider: React.FC<
  PropsWithChildren<ILockObjectProviderProps>
> = (props: PropsWithChildren<ILockObjectProviderProps>) => {
  const { children, entity } = props;
  const objectType = ObjectTypeByEntity[entity];

  if (!objectType && isDev()) {
    console.error(
      `Entity '${entity}' doesn't exist in ObjectTypeByEntity object - 'getObjectLocks' skipped`
    );
  }

  const {
    data: queryData,
    loading,
    refetch,
  } = useQuery<ObjectLockResult>(GET_OBJECT_LOCKS, {
    variables: { objectTypes: objectType },
    skip: !objectType,
  });

  useEntityChanges({
    disabled: !objectType,
    entity: Entities.objectLock,
    changeType: [ChangeType.ITEM_INSERTED, ChangeType.ITEM_DELETED],
    objectLockEntity: entity,
    onEntityChanged() {
      refetch();
    },
  });

  const objectLocks = useMemo(() => {
    const apiLocks = queryData?.getObjectLocks ?? [];

    const mapped = apiLocks.map<LockObjectProviderItem>((lock) => ({
      entityId: lock.objPrimaryKey,
      userId: lock.user?.id,
      userDisplayName: lock.user?.displayName,
    }));

    return mapped;
  }, [queryData]);

  return (
    <LockObjectContext.Provider value={{ objectLocks, loading }}>
      {children}
    </LockObjectContext.Provider>
  );
};

export const useLockObject = (skippable = false) => {
  const context = useContext(LockObjectContext);

  if (!context && !skippable) {
    throw new Error('You need to use LockObjectProvider.');
  }

  const isLocked = useCallback(
    (entityId: string) => {
      const lock = context?.objectLocks?.find((x) => x.entityId === entityId);
      const locked = Boolean(lock);
      return {
        lockedBy: locked
          ? {
              id: lock?.userId,
              displayName: lock?.userDisplayName,
            }
          : null,
        locked,
      };
    },
    [context]
  );

  return { ...context, isLocked };
};
