import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { ObjectType } from '../Enums/ObjectType.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { Appointment } from './Appointment.entity';
import { Calculation } from './Calculation.entity';
import { CallMemo } from './CallMemo.entity';
import { CheckList } from './CheckList.entity';
import { ChronoFileItemUnion } from './ChronoFileItemUnion.entity';
import { Contract } from './Contract.entity';
import { DeliveryNote } from './DeliveryNote.entity';
import { Demand } from './Demand.entity';
import { Document } from './Document.entity';
import { EMail } from './EMail.entity';
import { InboundDeliveryNote } from './InboundDeliveryNote.entity';
import { InboundInvoice } from './InboundInvoice.entity';
import { Invoice } from './Invoice.entity';
import { Letter } from './Letter.entity';
import { Note } from './Note.entity';
import { Offer } from './Offer.entity';
import { Order } from './Order.entity';
import { Project } from './Project.entity';
import { Task } from './Task.entity';
import { Ticket } from './Ticket.entity';
import { TravelExpenses } from './TravelExpenses.entity';
import { VisitationReport } from './VisitationReport.entity';

export class ChronoFileItem<T extends EMode = EMode.entity> {
  /** Alias: primaryKey */
  primaryKey?: any;
  /** Alias: objectType */
  objectType?: ObjectType;
  /** Alias: sdObjMemberCode */
  sdObjMemberCode?: number;
  /** Alias: sdObjType */
  sdObjType?: SdObjType;
  /** Alias: projectCode */
  projectCode?: number;
  /** Alias: objGrCode */
  objGrCode?: number;
  /** Alias: dateTime */
  dateTime?: string;
  /** Alias: fileItem */
  fileItem?: T extends EMode.query
    ? ChronoFileItemUnion<T>
    :
        | Task<T>
        | CheckList<T>
        | CallMemo<T>
        | Note<T>
        | Project<T>
        | Appointment<T>
        | Ticket<T>
        | VisitationReport<T>
        | Letter<T>
        | Document<T>
        | EMail<T>
        | Offer<T>
        | Contract<T>
        | Calculation<T>
        | DeliveryNote<T>
        | Invoice<T>
        | Demand<T>
        | TravelExpenses<T>
        | Order<T>
        | InboundDeliveryNote<T>
        | InboundInvoice<T>;
  /** Alias: contactCode */
  contactCode?: number;
  /** Alias: project */
  project?: Project<T>;

  __typename?: string;
}

const fields: FieldDefinitions<ChronoFileItem> = {
  primaryKey: {
    alias: 'primaryKey',
  },
  objectType: {
    alias: 'objectType',
  },
  sdObjMemberCode: {
    alias: 'sdObjMemberCode',
  },
  sdObjType: {
    alias: 'sdObjType',
  },
  projectCode: {
    alias: 'projectCode',
  },
  objGrCode: {
    alias: 'objGrCode',
  },
  dateTime: {
    alias: 'dateTime',
  },
  fileItem: {
    alias: 'fileItem',
    entity: [
      Entities.task,
      Entities.checkList,
      Entities.callMemo,
      Entities.note,
      Entities.project,
      Entities.appointment,
      Entities.ticket,
      Entities.visitationReport,
      Entities.letter,
      Entities.document,
      Entities.eMail,
      Entities.offer,
      Entities.contract,
      Entities.calculation,
      Entities.deliveryNote,
      Entities.invoice,
      Entities.demand,
      Entities.travelExpenses,
      Entities.order,
      Entities.inboundDeliveryNote,
      Entities.inboundInvoice,
    ],
  },
  contactCode: {
    alias: 'contactCode',
  },
  project: {
    alias: 'project',
    entity: Entities.project,
  },
  __typename: {
    alias: '__typename',
  },
};

export const chronoFileItemEntityDefinition: EntitiyDefinition<ChronoFileItem> =
  {
    local: {},
    remote: {
      queryName: 'getFilesChronologically',
      fragmentName: 'ChronoFileItem',
      withPaginationWrapper: true,
      params: [
        { name: 'querySize', type: 'Int' },
        { name: 'queryPage', type: 'Int' },
        { name: 'querySortOrder', type: 'SortOrderStyle' },
        { name: 'querySortBy', type: 'String' },
        { name: 'filter', type: 'String' },
      ],
    },
    fieldDefinitions: fields,
  };
