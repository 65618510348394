import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Calculation } from './Calculation.entity';
import { Contract } from './Contract.entity';
import { DeliveryNote } from './DeliveryNote.entity';
import { Demand } from './Demand.entity';
import { InboundDeliveryNote } from './InboundDeliveryNote.entity';
import { Invoice } from './Invoice.entity';
import { Offer } from './Offer.entity';
import { Order } from './Order.entity';

export type BzObject<T extends EMode = EMode.entity> = T extends EMode.entity
  ?
      | Calculation<T>
      | Offer<T>
      | Contract<T>
      | Invoice<T>
      | DeliveryNote<T>
      | Demand<T>
      | Order<T>
      | InboundDeliveryNote<T>
  : {
      /** Alias: Kalkulation */
      calculation?: Calculation<T>;
      /** Alias: Angebot */
      offer?: Offer<T>;
      /** Alias: Auftrag */
      contract?: Contract<T>;
      /** Alias: Rechnung */
      invoice?: Invoice<T>;
      /** Alias: Lieferschein */
      deliveryNote?: DeliveryNote<T>;
      /** Alias: Bedarfsanforderung */
      demand?: Demand<T>;
      /** Alias: Bestellung */
      order?: Order<T>;
      /** Alias: Eingangslieferschein */
      inboundDeliveryNote?: InboundDeliveryNote<T>;
    };

const fields: FieldDefinitions<BzObject<EMode.query>> = {
  calculation: {
    alias: 'calculation',
    entity: Entities.calculation,
  },
  offer: {
    alias: 'offer',
    entity: Entities.offer,
  },
  contract: {
    alias: 'contract',
    entity: Entities.contract,
  },
  invoice: {
    alias: 'invoice',
    entity: Entities.invoice,
  },
  deliveryNote: {
    alias: 'deliveryNote',
    entity: Entities.deliveryNote,
  },
  demand: {
    alias: 'demand',
    entity: Entities.demand,
  },
  order: {
    alias: 'order',
    entity: Entities.order,
  },
  inboundDeliveryNote: {
    alias: 'inboundDeliveryNote',
    entity: Entities.inboundDeliveryNote,
  },
};

export const bzObjectEntityDefinition: EntitiyDefinition<
  BzObject<EMode.query>
> = {
  kind: 'union',
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'BzObject',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
