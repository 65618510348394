import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { BzObjType } from '../Enums/BzObjType.enum';
import { EMode } from '../Enums/EMode.enum';

export class DhlShipment<T extends EMode = EMode.entity> {
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: bzObjMemberCode */
  bzObjMemberId?: number;
  /** Alias: bzObjType */
  bzObjType?: BzObjType;
  /** Alias: code */
  id?: number;
  /** Alias: datum */
  date?: string;
  /** Alias: dhlLabelUrl */
  dhlLabelUrl?: string;
  /** Alias: dhlSendenummer */
  dhlShipmentNumber?: string;
  /** Alias: erpAnhangCode */
  erpAttachmentId?: number;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: lieferdatum */
  deliveryDate?: string;
  /** Alias: notiz */
  note?: string;
  /** Alias: updateTime */
  updateTime?: string;

  __typename?: string;
}

const fields: FieldDefinitions<DhlShipment> = {
  userId: {
    alias: 'benutzerCode',
  },
  bzObjMemberId: {
    alias: 'bzObjMemberCode',
  },
  bzObjType: {
    alias: 'bzObjType',
  },
  id: {
    alias: 'code',
  },
  date: {
    alias: 'datum',
  },
  dhlLabelUrl: {
    alias: 'dhlLabelUrl',
  },
  dhlShipmentNumber: {
    alias: 'dhlSendenummer',
  },
  erpAttachmentId: {
    alias: 'erpAnhangCode',
  },
  insertTime: {
    alias: 'insertTime',
  },
  deliveryDate: {
    alias: 'lieferdatum',
  },
  note: {
    alias: 'notiz',
  },
  updateTime: {
    alias: 'updateTime',
  },
  __typename: {
    alias: '__typename',
  },
};

export const dhlShipmentEntityDefinition: EntitiyDefinition<DhlShipment> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'DhlSendung',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
