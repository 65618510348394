import styles from './EventPopover.module.scss';

import { MbscCalendarEvent } from '@mobiscroll/react';
import { Person, Videocam } from '@mui/icons-material';
import { Avatar, Popover, Typography } from '@mui/material';
import clsx from 'clsx';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PreviewTextContent } from '@work4all/components/lib/components/entity-preview/components';
import { Chip } from '@work4all/components/lib/dataDisplay/chip/Chip';
import { useResizeObserver } from '@work4all/components/lib/hooks';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { CalendarUserIcon } from './CalendarUserIcon';

export interface HoveredEvent {
  event: MbscCalendarEvent;
  startEndTime: string;
  appointment: Appointment;
  target: Element;
}

interface EventPopoverProps {
  hoveredEvent: HoveredEvent;
  showBusinesspartner: boolean;
  showProject: boolean;
  showNote: boolean;
}

const SPACE_BETWEEN_USERS_AND_RESOURCES = 12;
const USER_AVATAR_SIZE = 28;
const USER_AVATAR_MARGIN = 4;
const MAXIMUM_NUMBER_OF_RESOURCES_TO_SHOW = 2;
const POPOVER_SIZE = 416;

const EventPopover = ({
  hoveredEvent,
  showBusinesspartner,
  showProject,
  showNote,
}: EventPopoverProps) => {
  const { t } = useTranslation();

  const { users, resources } = useMemo(() => {
    const users = [];
    const resources = [];

    hoveredEvent?.appointment?.appointmentAttendeeList.forEach((attendee) => {
      if (attendee.user) {
        users.push(attendee.user);
      }

      if (attendee.ressource) {
        resources.push(attendee.ressource);
      }
    });

    return { users, resources };
  }, [hoveredEvent?.appointment?.appointmentAttendeeList]);

  const windowWidth = window.innerWidth;
  const hoveredEventEndAt =
    hoveredEvent?.target?.getBoundingClientRect()?.right;

  const note = hoveredEvent?.appointment?.note
    ? hoveredEvent?.appointment?.note.split(/\r?\n/)
    : null;

  const [maximumNumberOfUsersToShow, setMaximumNumberOfUsersToShow] =
    useState<number>();

  const anchorEl = hoveredEvent?.target;
  const usersAndResourcesRef = useRef(null);
  const resourcesRef = useRef(null);

  const handleResize = () => {
    const usersAndResourcesWidth = usersAndResourcesRef.current?.clientWidth;
    const resourcesWidth = resourcesRef.current?.clientWidth;

    const remainingSpace =
      usersAndResourcesWidth -
      resourcesWidth -
      SPACE_BETWEEN_USERS_AND_RESOURCES;

    const userAvatarSpace = USER_AVATAR_SIZE + USER_AVATAR_MARGIN;

    setMaximumNumberOfUsersToShow(
      Math.floor(remainingSpace / userAvatarSpace) - 1
    );
  };

  useResizeObserver(anchorEl as HTMLElement, handleResize);

  return (
    <Popover
      open={!!hoveredEvent}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal:
          windowWidth - hoveredEventEndAt > POPOVER_SIZE
            ? 'right'
            : -POPOVER_SIZE,
      }}
      classes={{
        root: styles.rootPopover,
        paper: styles.paperPopover,
      }}
    >
      <div className={styles.titleTimeAndIconContainer}>
        <div>
          <Typography variant="h4" className={styles.text}>
            {hoveredEvent?.event?.title}
          </Typography>
          <Typography variant="body2" color="text.tertiary">
            {hoveredEvent?.startEndTime}
          </Typography>
        </div>

        <div>
          {hoveredEvent?.appointment?.privat && <Person />}
          {hoveredEvent?.appointment?.meetingUrl && (
            <Videocam color="primary" />
          )}
        </div>
      </div>

      {(showBusinesspartner || showProject) &&
        (hoveredEvent?.appointment?.businessPartner ||
          hoveredEvent?.appointment?.project) && (
          <div className={styles.businessPartnerAndProjectContainer}>
            {showBusinesspartner &&
              hoveredEvent?.appointment?.businessPartner && (
                <div>
                  <Typography variant="body2" className={styles.text}>
                    <span className={styles.businessPartnerAndProjectPrefix}>
                      {hoveredEvent.appointment.businessPartner
                        .businessPartnerType === SdObjType.KUNDE
                        ? t('COMMON.CUSTOMER')
                        : t('COMMON.SUPPLIER')}
                    </span>

                    {hoveredEvent.appointment.businessPartner.data.name}
                  </Typography>
                </div>
              )}

            {showProject && hoveredEvent.appointment.project && (
              <div>
                <Typography variant="body2" className={styles.text}>
                  <span className={styles.businessPartnerAndProjectPrefix}>
                    {t('COMMON.PROJECT')}
                  </span>

                  {hoveredEvent.appointment?.project?.name}
                </Typography>
              </div>
            )}
          </div>
        )}

      {(users.length > 0 || resources.length > 0) && (
        <div
          className={styles.usersAndResourcesContainer}
          ref={usersAndResourcesRef}
        >
          <div className={styles.usersContainer}>
            {maximumNumberOfUsersToShow && (
              <>
                {users.slice(0, maximumNumberOfUsersToShow).map((user, i) => {
                  return (
                    <CalendarUserIcon
                      key={i}
                      type="user"
                      userId={user.id}
                      size="l"
                    />
                  );
                })}

                {users.length > maximumNumberOfUsersToShow && (
                  <Avatar className={styles.numberOfOtherUsersContainer}>
                    <Typography variant="h4">
                      +{users.length - maximumNumberOfUsersToShow}
                    </Typography>
                  </Avatar>
                )}
              </>
            )}
          </div>

          <div className={styles.resourcesContainer} ref={resourcesRef}>
            {resources
              .slice(0, MAXIMUM_NUMBER_OF_RESOURCES_TO_SHOW)
              .map((resource) => {
                return (
                  <Chip
                    className={styles.resourceChip}
                    label={resource.loginName}
                  />
                );
              })}

            {resources.length > MAXIMUM_NUMBER_OF_RESOURCES_TO_SHOW && (
              <Chip
                label={`+${
                  resources.length - MAXIMUM_NUMBER_OF_RESOURCES_TO_SHOW
                }`}
              />
            )}
          </div>
        </div>
      )}

      {showNote && note && (
        <div className={styles.note}>
          {note.slice(0, 3).map((line, i) => {
            return (
              <PreviewTextContent
                className={styles.previewTextContent}
                childrenClassName={clsx(styles.noteLine, {
                  [styles.moreLines]: note.length === 1,
                })}
                key={i}
              >
                {line}
              </PreviewTextContent>
            );
          })}
        </div>
      )}
    </Popover>
  );
};

export default EventPopover;
