import styles from './EntityPickerActions.module.scss';

import { Theme, useMediaQuery } from '@mui/material';
import { Box } from '@mui/material';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { WebsiteFavicon } from '@work4all/components';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  CustomerPicker,
  ICustomerPickerProps,
} from '@work4all/components/lib/components/entity-picker/customer-picker/CustomerPicker';
import {} from '@work4all/components/lib/components/entity-picker/supplier-picker/SupplierPicker';
import { useEntityTemplate } from '@work4all/components/lib/components/entity-template-provider/EntityTemplateProvider';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { reactRefSetter } from '@work4all/utils';

import { LabeledInput } from '../../containers/mask-overlays/locked-inputs';

interface CustomerPickerFieldProps
  extends Omit<ICustomerPickerProps<false>, 'multiple'> {
  error?: string;
  label?: string;
  name?: string;
}

export const CustomerPickerField = React.forwardRef<
  HTMLDivElement,
  CustomerPickerFieldProps
>((props, ref) => {
  const { error, favorites = true, ...pickerProps } = props;
  const { value, onChange } = pickerProps;

  const { t } = useTranslation();

  const wrapperRef = useRef();
  const popoverRef = useRef<EntityPickerPopover>(null);
  const entityTemplate = useEntityTemplate();

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );

  return (
    <div ref={reactRefSetter(wrapperRef, ref)}>
      <EntityPickerPopover
        fullscreen={isMobile}
        ref={popoverRef}
        anchorEl={wrapperRef}
        picker={
          <CustomerPicker
            favorites={favorites}
            {...pickerProps}
            multiple={false}
          />
        }
        footer={entityTemplate.renderPickerFooter({
          popoverRef,
          entity: Entities.customer,
          ...props,
        })}
      >
        <LabeledInput
          onClear={() => {
            onChange(null);
          }}
          name={props.name}
          label={
            <>
              {!value && (props.label || t('COMMON.CUSTOMER'))}
              {value && (
                <>
                  <span className={styles.primaryInfo}>
                    {props.label || t('COMMON.CUSTOMER')} &nbsp;
                  </span>
                  <span className={styles.secondaryInfo}>
                    {value?.number || ''}
                  </span>
                </>
              )}
            </>
          }
          error={error}
          value={
            value ? (
              <Box
                paddingTop="1.5rem"
                display="flex"
                gap="0.5rem"
                alignItems="center"
              >
                {<WebsiteFavicon website={value?.website} />}{' '}
                <Box textOverflow="ellipsis" height="1rem" overflow="hidden">
                  {value?.name}
                </Box>
              </Box>
            ) : (
              ''
            )
          }
        />
      </EntityPickerPopover>
    </div>
  );
});
