import styles from './Seperator.module.scss';

import clsx from 'clsx';
import React from 'react';

interface ISeperatorProps {
  className?: string;
}

export const Seperator: React.FC<ISeperatorProps> = (props) => {
  const { className, ...rest } = props;

  return (
    <div className={styles.wrapper} {...rest}>
      <div className={clsx(styles.seperator, props.className)} />
    </div>
  );
};
