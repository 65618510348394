import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMailPriority } from '../Enums/EMailPriority.enum';
import { EMode } from '../Enums/EMode.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputEMail<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: number;
  /** Alias: art */
  art?: any;
  /** Alias: sdObjMemberCode */
  sdObjMemberCode?: number;
  /** Alias: sdObjType */
  sdObjType?: SdObjType;
  /** Alias: ansprechpartnerCode */
  ansprechpartnerCode?: number;
  /** Alias: projektCode */
  projektCode?: number;
  /** Alias: benutzerCode */
  benutzerCode?: number;
  /** Alias: erstellerBenutzerCode */
  erstellerBenutzerCode?: number;
  /** Alias: datum */
  datum?: string;
  /** Alias: body */
  body?: string;
  /** Alias: bodyHtml */
  bodyHtml?: string;
  /** Alias: betreff */
  betreff?: string;
  /** Alias: from */
  from?: string;
  /** Alias: to */
  to?: string;
  /** Alias: cc */
  cc?: string;
  /** Alias: bcc */
  bcc?: string;
  /** Alias: prioritaet */
  prioritaet?: EMailPriority;
  /** Alias: ticketId */
  ticketId?: string;
  /** Alias: lastModificationDate */
  lastModificationDate?: string;
  /** Alias: objGrCode */
  objGrCode?: number;
  /** Alias: lookupCode */
  lookupCode?: number;
  /** Alias: personalisiert */
  personalisiert?: number;
  /** Alias: mimeContent */
  mimeContent?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];
  /** Alias: toMailContacts */
  toMailContacts?: string;
  /** Alias: ccMailContacts */
  ccMailContacts?: string;
  /** Alias: bccMailContacts */
  bccMailContacts?: string;
  /** Alias: saveSendMailJobID */
  saveSendMailJobID?: string;

  __typename?: string;
}

const fields: FieldDefinitions<InputEMail> = {
  code: {
    alias: 'code',
  },
  art: {
    alias: 'art',
  },
  sdObjMemberCode: {
    alias: 'sdObjMemberCode',
  },
  sdObjType: {
    alias: 'sdObjType',
  },
  ansprechpartnerCode: {
    alias: 'ansprechpartnerCode',
  },
  projektCode: {
    alias: 'projektCode',
  },
  benutzerCode: {
    alias: 'benutzerCode',
  },
  erstellerBenutzerCode: {
    alias: 'erstellerBenutzerCode',
  },
  datum: {
    alias: 'datum',
  },
  body: {
    alias: 'body',
  },
  bodyHtml: {
    alias: 'bodyHtml',
  },
  betreff: {
    alias: 'betreff',
  },
  from: {
    alias: 'from',
  },
  to: {
    alias: 'to',
  },
  cc: {
    alias: 'cc',
  },
  bcc: {
    alias: 'bcc',
  },
  prioritaet: {
    alias: 'prioritaet',
  },
  ticketId: {
    alias: 'ticketId',
  },
  lastModificationDate: {
    alias: 'lastModificationDate',
  },
  objGrCode: {
    alias: 'objGrCode',
  },
  lookupCode: {
    alias: 'lookupCode',
  },
  personalisiert: {
    alias: 'personalisiert',
  },
  mimeContent: {
    alias: 'mimeContent',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customFields: {
    alias: 'customFields',
  },
  toMailContacts: {
    alias: 'toMailContacts',
  },
  ccMailContacts: {
    alias: 'ccMailContacts',
  },
  bccMailContacts: {
    alias: 'bccMailContacts',
  },
  saveSendMailJobID: {
    alias: 'saveSendMailJobID',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputEMailEntityDefinition: EntitiyDefinition<InputEMail> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'InputEMail',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
