import { Layout } from 'react-grid-layout';

import { HomeWidgetType } from './HomeWidgetType.enum';

const lg: Layout[] = [
  {
    i: HomeWidgetType.relevantWidget,
    h: 5,
    w: 5,
    x: 1,
    y: 0,
    minW: 2,
    minH: 3,
  },
  {
    i: HomeWidgetType.overviewWidget,
    h: 8,
    w: 5,
    x: 1,
    y: 4,
    minW: 2,
    minH: 3,
  },
  {
    i: HomeWidgetType.calendarWidget,
    h: 13,
    w: 5,
    x: 6,
    y: 0,
    minW: 2,
    minH: 3,
  },
];

const md: Layout[] = [
  {
    i: HomeWidgetType.relevantWidget,
    h: 5,
    w: 6,
    x: 0,
    y: 0,
    minW: 2,
    minH: 3,
  },
  {
    i: HomeWidgetType.overviewWidget,
    h: 8,
    w: 6,
    x: 0,
    y: 4,
    minW: 2,
    minH: 3,
  },
  {
    i: HomeWidgetType.calendarWidget,
    h: 13,
    w: 6,
    x: 6,
    y: 0,
    minW: 2,
    minH: 3,
  },
];

const sm: Layout[] = [
  {
    i: HomeWidgetType.relevantWidget,
    h: 5,
    w: 6,
    x: 0,
    y: 0,
    minW: 2,
    minH: 3,
  },
  {
    i: HomeWidgetType.overviewWidget,
    h: 8,
    w: 6,
    x: 0,
    y: 4,
    minW: 2,
    minH: 3,
  },
  {
    i: HomeWidgetType.calendarWidget,
    h: 13,
    w: 6,
    x: 6,
    y: 0,
    minW: 2,
    minH: 3,
  },
];

const xs: Layout[] = [
  {
    i: HomeWidgetType.relevantWidget,
    h: 5,
    w: 12,
    x: 0,
    y: 0,
    minW: 2,
    minH: 3,
  },
  {
    i: HomeWidgetType.overviewWidget,
    h: 8,
    w: 12,
    x: 2,
    y: 4,
    minW: 2,
    minH: 3,
  },
  {
    i: HomeWidgetType.calendarWidget,
    h: 13,
    w: 12,
    x: 6,
    y: 0,
    minW: 2,
    minH: 3,
  },
];

const xxs: Layout[] = [
  {
    i: HomeWidgetType.relevantWidget,
    h: 5,
    w: 4,
    x: 2,
    y: 0,
    minW: 2,
    minH: 3,
  },
  {
    i: HomeWidgetType.overviewWidget,
    h: 8,
    w: 4,
    x: 2,
    y: 4,
    minW: 2,
    minH: 3,
  },
  {
    i: HomeWidgetType.calendarWidget,
    h: 13,
    w: 4,
    x: 6,
    y: 0,
    minW: 2,
    minH: 3,
  },
];

export const DEFAULT_HOME_WIDGET_LAYOUTS = { lg, md, sm, xs, xxs };
