import styles from './TooltipFieldsSection.module.scss';

import clsx from 'clsx';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { BasicTableColumn, Table } from '@work4all/components';
import { Collapse } from '@work4all/components/lib/components/collapse';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';

import {
  useSetting,
  UseSettingReturn,
} from '@work4all/data/lib/settings/use-setting';

import { settings } from '../../../../../../../../settings';

export function TooltipFieldsSection() {
  const { t } = useTranslation();

  const calendarAppointmentTooltipShowBusinesspartner = useSetting(
    settings.calendarAppointmentTooltipShowBusinesspartner()
  );
  const calendarAppointmentTooltipShowProject = useSetting(
    settings.calendarAppointmentTooltipShowProject()
  );
  const calendarAppointmentTooltipShowNote = useSetting(
    settings.calendarAppointmentTooltipShowNote()
  );
  const calendarAppointmentShowTooltip = useSetting(
    settings.calendarAppointmentShowTooltip()
  );

  const apiRef = useRef<TableInstance>(null);

  const fieldsData = useMemo<
    {
      name: string;
      display: UseSettingReturn<boolean> | null;
      disabled: boolean;
    }[]
  >(() => {
    return [
      {
        name: t('COMMON.TITLE'),
        display: null,
        disabled: true,
      },
      {
        name: t('COMMON.DURATION'),
        display: null,
        disabled: true,
      },
      {
        name: t('COMMON.CUSTOMER'),
        display: calendarAppointmentTooltipShowBusinesspartner,
        disabled: false,
      },
      {
        name: t('COMMON.PROJECT'),
        display: calendarAppointmentTooltipShowProject,
        disabled: false,
      },
      {
        name: t('COMMON.NOTE'),
        display: calendarAppointmentTooltipShowNote,
        disabled: false,
      },
    ];
  }, [
    calendarAppointmentTooltipShowBusinesspartner,
    calendarAppointmentTooltipShowNote,
    calendarAppointmentTooltipShowProject,
    t,
  ]);

  const fieldsColumns = useMemo<BasicTableColumn[]>(() => {
    const result: BasicTableColumn[] = [
      {
        Header: t('Name'),
        accessor: 'name',
        width: 250,
        Cell: ({ value, row }: { value: string; row }) => {
          return (
            <span
              className={clsx({ [styles.disabled]: row.original.disabled })}
            >
              {value}
              {row.original.disabled &&
                ' (' + t('SETTINGS.CALENDAR.ALWAYS_VISIBLE') + ')'}
            </span>
          );
        },
      },
      {
        Header: t('COMMON.DISPLAY'),
        accessor: 'display',
        alignment: 'center',
        width: 80,
        Cell: ({ value, row }: { value: UseSettingReturn<boolean>; row }) => {
          return (
            <div className={styles.checkBoxWrap}>
              <CheckboxRadioItem
                disabled={row.original.disabled}
                className={styles.checkBox}
                checked={value?.value || row.original.disabled}
                onChange={(e) => {
                  row.original.display.set(e.target.checked);
                }}
                label=" "
              />
            </div>
          );
        },
      },
    ];

    return result;
  }, [t]);

  const collapseTitle = useMemo(() => {
    return (
      <div className={styles.titleWrap}>
        <div className={styles.title}>
          {t('SETTINGS.CALENDAR.FIELDS_IN_TOOLTIP')}
        </div>
        <div className={styles.titleCTA}>
          <CheckboxRadioItem
            checked={calendarAppointmentShowTooltip?.value}
            onChange={(e) => {
              calendarAppointmentShowTooltip.set(e.target.checked);
            }}
            label={t('SETTINGS.CALENDAR.SHOW_TOOLTIP')}
          />
        </div>
      </div>
    );
  }, [calendarAppointmentShowTooltip, t]);

  return (
    <Collapse title={collapseTitle} defaultOpen>
      <div className={styles.tableWrap}>
        <Table
          mode="client"
          ref={apiRef}
          data={fieldsData}
          columns={fieldsColumns}
          allItemsCount={fieldsData.length}
          selectableRows={false}
        />
      </div>
    </Collapse>
  );
}
