import styles from './EntityPickerActions.module.scss';

import { Stack, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BankDetailsPicker,
  BankDetailsPickerProps,
} from '@work4all/components/lib/components/entity-picker/BankDetailsPicker';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';

import { reactRefSetter } from '@work4all/utils';

import { LabeledInput } from '../../containers/mask-overlays/locked-inputs';

interface BankDetailsPickerFieldProps extends BankDetailsPickerProps {
  label?: string;
  error?: string;
  disabled?: boolean;
  field?: JSX.Element;
}

export const BankDetailsPickerField = React.forwardRef<
  HTMLDivElement,
  BankDetailsPickerFieldProps
>(function BankDetailsPickerField(props, ref) {
  const { field, error, disabled = false, label, ...pickerProps } = props;
  const { value, onChange, clearable = true } = pickerProps;

  const { t } = useTranslation();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<EntityPickerPopover>(null);

  const internalField = field || (
    <LabeledInput
      value={
        value ? (
          <Stack
            direction="row"
            gap="0.5rem"
            style={{ width: '100%', marginTop: '1rem' }}
          >
            {value.name ? (
              <Typography variant="body2" noWrap>
                {value.name}
              </Typography>
            ) : null}

            {value.name && value.iban ? (
              <Typography
                variant="body2"
                noWrap
                className={styles.secondaryInfo}
              >
                {'|'}
              </Typography>
            ) : null}
            {value.iban ? (
              <Typography
                variant="body2"
                noWrap
                className={styles.secondaryInfo}
                flex={1}
              >
                {value.iban}
              </Typography>
            ) : null}
          </Stack>
        ) : (
          ''
        )
      }
      label={label ?? t('INPUTS.BANK_DETAILS')}
      error={error}
      onClear={
        disabled || !clearable
          ? undefined
          : () => {
              onChange(null);
            }
      }
    />
  );

  if (disabled) {
    return internalField;
  }

  return (
    <div ref={reactRefSetter(wrapperRef, ref)}>
      <EntityPickerPopover
        width={560}
        ref={popoverRef}
        anchorEl={wrapperRef}
        picker={<BankDetailsPicker {...pickerProps} />}
      >
        {internalField}
      </EntityPickerPopover>
    </div>
  );
});
