import styles from './ContentWrapper.module.scss';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Theme, Typography, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import {
  TakeRestHeight,
  YSplit,
} from '@work4all/components/lib/layout/y-split';
import {
  EventType,
  sendAmplitudeData,
} from '@work4all/components/lib/utils/amplitude/amplitude';

import { useNavigate } from '@work4all/data';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { TestDataAttributes } from '@work4all/utils';

import {
  HeaderComponentProvider,
  useHeaderComponent,
} from './HeaderComponentProvider';

interface ContentWrapperProps extends TestDataAttributes {
  title: string;
  backAction: 'close' | 'back';
  closeUrl: string;
  children?: React.ReactNode;
  entity: Entities;
  amplitudeEntryPoint: string;
}

export const ContentWrapper = (props: ContentWrapperProps) => {
  return (
    <HeaderComponentProvider>
      <ContentWrapperInternal {...props} />
    </HeaderComponentProvider>
  );
};
export const ContentWrapperInternal = (props: ContentWrapperProps) => {
  const navigate = useNavigate();

  const injectedComponent = useHeaderComponent();

  const isViewportDownMd = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  return (
    <YSplit
      className={styles.organisation}
      data-test-id={props['data-test-id']}
    >
      <div
        className={clsx(styles.headerWrapper, {
          [styles.downMd]: isViewportDownMd,
        })}
      >
        <div
          className={clsx(styles.header, {
            [styles.downMd]: isViewportDownMd,
          })}
        >
          <div className={clsx(styles.headerInner)}>
            {props.backAction && (
              <IconButton
                className={styles.goBackButton}
                size="large"
                onClick={() => {
                  sendAmplitudeData(EventType.CloseList, {
                    name: props.entity,
                    entryPoint: props.amplitudeEntryPoint,
                  });
                  if (props.backAction === 'close') {
                    navigate(props.closeUrl);
                  } else {
                    navigate(-1);
                  }
                }}
              >
                {props.backAction === 'close' ? (
                  <CloseIcon />
                ) : (
                  <ArrowBackIcon />
                )}
              </IconButton>
            )}

            <Typography variant={isViewportDownMd ? 'h4' : 'h3'} noWrap>
              {props.title}
            </Typography>
          </div>
          {injectedComponent ? (
            <div className={clsx(styles.headerInner)}>{injectedComponent}</div>
          ) : null}
        </div>
      </div>
      <TakeRestHeight>{props.children}</TakeRestHeight>
    </YSplit>
  );
};
