import { Checkbox, FormControlLabel, Stack } from '@mui/material';

import { useAppointmentStatesHelper } from '../hooks/use-appointment-states-helper';
import { CalendarCardColorBy } from '../types';

interface AppointmentStatesSettingsProps {
  selectedAppointmentStates: number[];
  onSelectedAppointmentStatesChange: (states: number[]) => void;
  calendarCardColorBy: CalendarCardColorBy;
}

export function AppointmentStatesSettings(
  props: AppointmentStatesSettingsProps
) {
  const {
    selectedAppointmentStates,
    onSelectedAppointmentStatesChange,
    calendarCardColorBy,
  } = props;

  const { appointmentStates, isSelected, toggle } = useAppointmentStatesHelper({
    selectedAppointmentStates,
    onSelectedAppointmentStatesChange,
  });

  return (
    <Stack direction="column" alignItems="stretch" p="1rem">
      {appointmentStates.map((appointmentState) => {
        const color = appointmentState.hexColorForeground;

        return (
          <FormControlLabel
            key={appointmentState.id}
            label={appointmentState.name}
            control={
              <Checkbox
                checked={isSelected(appointmentState)}
                onChange={(_event, checked) => {
                  toggle(appointmentState, checked);
                }}
                sx={
                  calendarCardColorBy === CalendarCardColorBy.AppointmentStatus
                    ? {
                        color,
                        '&.Mui-checked': {
                          color,
                        },
                      }
                    : null
                }
              />
            }
          />
        );
      })}
    </Stack>
  );
}
