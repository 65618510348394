import { gql, useMutation } from '@apollo/client';

import { TempFile } from '@work4all/models/lib/Classes/TempFile.entity';

const CONVERT_MAIL_TO_TEMP_FILE = gql`
  mutation ConvertMailToTempfile(
    $mailboxId: String!
    $mailFolderId: String!
    $mailId: String!
  ) {
    convertMailToTempfile(
      mailboxId: $mailboxId
      mailFolderId: $mailFolderId
      mailId: $mailId
    ) {
      id
      fileName: dateiname
    }
  }
`;

type ConvertMailToTempFileResponse = {
  convertMailToTempfile: TempFile;
};

type ConvertMailToTempFileVars = {
  mailboxId: string;
  mailFolderId: string;
  mailId: string;
};

export function useConvertMailToTempFileMutation() {
  return useMutation<ConvertMailToTempFileResponse, ConvertMailToTempFileVars>(
    CONVERT_MAIL_TO_TEMP_FILE,
    { context: { singleBatch: true } }
  );
}
