import styles from './SalesOpportunitiesPreview.module.scss';

import { Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import type React from 'react';
import { MouseEventHandler, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useDataMutation,
  useInaccessibleFields,
  useUser,
} from '@work4all/data';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { InputTicketAttachementsRelation } from '@work4all/models/lib/Classes/InputTicketAttachementsRelation.entity';
import { SalesOpportunities } from '@work4all/models/lib/Classes/SalesOpportunities.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { formatCurrency, formatNumberAsCurrency } from '@work4all/utils';
import {
  DateFormatPreset,
  formatDateString,
} from '@work4all/utils/lib/date-utils/formatDateString';

import { DatePicker } from '../../../input/date-picker';
import { useMobiscrollLanguage } from '../../../utils/use-mobiscroll-language/use-mobiscroll-language';
import { IConvertPopoverProps } from '../../convert-popover/ConvertPopover';
import { EntityPickerPopover } from '../../entity-picker/components';
import { ContactPicker } from '../../entity-picker/contact-picker/ContactPicker';
import { CustomerPicker } from '../../entity-picker/customer-picker/CustomerPicker';
import { ProjectPicker } from '../../entity-picker/project-picker/ProjectPicker';
import {
  FormValue as SalesOpportunitiesEvaluationFormValue,
  SalesOpportunitiesEvaluationPicker,
} from '../../entity-picker/sales-opportunities-evaluation-picker/SalesOpportunitiesEvaluationPicker';
import { SalesOpportunitiesKindLookUp } from '../../entity-picker/sales-opportunities-kind-picker/SalesOpportunitiesKindPicker';
import {
  InfoCard,
  InfoCards,
  OpenFileIconButton,
  PreviewEditMessage,
  PreviewTitle,
  PreviewWrapper,
} from '../components';
import { EntityHistory } from '../EntityHistory';
import { useEntityPreview } from '../hooks/use-entity-preview';

import { SalesOpportunitiesFields } from './SalesOpportunitiesContainer';

export type SalesOpportunitiesHandlers = {
  onCloseClicked?: MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onShareClicked?: () => void;
  /**this preview has subactions */
  onActionElClicked?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  convertProps?: Pick<IConvertPopoverProps, 'exclude' | 'onClick'>;
};

export type SalesOpportunitiesPreviewProps = {
  entries: SalesOpportunities[];
} & SalesOpportunitiesHandlers;

enum previewFields {
  DATE_NEXT_REVIEW = 'dateNextReview',
  DECISION_DATE = 'decisionDate',
  CUSTOMER = 'customer',
  CONTACT = 'contact',
  STATUS = 'status',
  EDITOR = 'editor',
  DESCRIPTION = 'description',
  EVALUATION = 'evaluation',
  PROJECT = 'project',
}

export function SalesOpportunitiesPreview(
  props: SalesOpportunitiesPreviewProps
) {
  const {
    entries,
    onCloseClicked,
    onEditClicked,
    onShareClicked,
    convertProps,
  } = props;
  const { t } = useTranslation();

  const user = useUser();
  const language = useMobiscrollLanguage();

  const { isInaccessible } = useInaccessibleFields();

  const descriptionIsAccessible = !isInaccessible(
    Entities.salesOpportunities,
    'description'
  );
  const decisionDateIsAccessible = !isInaccessible(
    Entities.salesOpportunities,
    'decisionDate'
  );
  const nextReviewIsAccessible = !isInaccessible(
    Entities.salesOpportunities,
    'dateNextReview'
  );
  const businessPartnerIsAccessible = !isInaccessible(
    Entities.salesOpportunities,
    'businessPartner'
  );
  const contactIsAccessible = !isInaccessible(
    Entities.salesOpportunities,
    'contact'
  );
  const evaluationIsAccessible = !isInaccessible(
    Entities.salesOpportunities,
    'probabilityPercent'
  );
  const projectIsAccessible = !isInaccessible(
    Entities.salesOpportunities,
    'project'
  );
  const valueIsAccessible = !isInaccessible(
    Entities.salesOpportunities,
    'value'
  );
  const expectedCostsIsAccessible = !isInaccessible(
    Entities.salesOpportunities,
    'expectedCosts'
  );

  const kindIsAccessible = !isInaccessible(Entities.salesOpportunities, 'kind');
  const groupIsAccessible = !isInaccessible(
    Entities.salesOpportunities,
    'group'
  );

  const dateRef = useRef<EntityPickerPopover>(null);
  const decisionDateRef = useRef<EntityPickerPopover>(null);
  const customerRef = useRef<EntityPickerPopover>(null);
  const contactRef = useRef<EntityPickerPopover>(null);
  const statusRef = useRef<EntityPickerPopover>(null);
  const editorRef = useRef<EntityPickerPopover>(null);
  const titleRef = useRef<PreviewTitle>(null);
  const evaluationRef = useRef<EntityPickerPopover>(null);
  const projectRef = useRef<EntityPickerPopover>(null);

  const openPicker = useCallback((picker: previewFields) => {
    switch (picker) {
      case previewFields.DATE_NEXT_REVIEW: {
        dateRef.current.open();
        break;
      }

      case previewFields.DECISION_DATE: {
        decisionDateRef.current.open();
        break;
      }

      case previewFields.EVALUATION: {
        evaluationRef.current.open();
        break;
      }

      case previewFields.PROJECT: {
        projectRef.current.open();
        break;
      }

      case previewFields.CUSTOMER: {
        customerRef.current.open();
        break;
      }

      case previewFields.CONTACT: {
        contactRef.current.open();
        break;
      }

      case previewFields.STATUS: {
        statusRef.current.open();
        break;
      }

      case previewFields.EDITOR: {
        editorRef.current.open();
        break;
      }

      case previewFields.DESCRIPTION: {
        titleRef.current.open();
        break;
      }

      default:
        return;
    }
  }, []);

  const [mutate] = useDataMutation<
    SalesOpportunities,
    EMode.upsert,
    {
      comment: string;
      attachements: InputTicketAttachementsRelation;
    }
  >({
    entity: Entities.salesOpportunities,
    mutationType: EMode.upsert,
    responseData: SalesOpportunitiesFields as unknown as SalesOpportunities,
  });

  const {
    loading,
    isMultiselect,
    commonFields,
    activePicker,
    optimisticTitle,
    handleLock,
    onPopoverClose,
    setOptimisticTitle,
    onEdit,
    canEditAllSelected,
  } = useEntityPreview({
    user,
    subEntityType: Entities.salesOpportunities,
    entries: entries,
    openPicker,
    mutate,
  });

  const projectIsCommon = commonFields.includes('project');
  const nextReviewIsCommon = commonFields.includes('dateNextReview');
  const decisionDateIsCommon = commonFields.includes('decisionDate');
  const probabilityIsCommon = commonFields.includes('probabilityPercent');
  const expectedCostIsCommon = commonFields.includes('expectedCosts');
  const valueIsCommon = commonFields.includes('value');
  const customerIsCommon = commonFields.includes('businessPartner');
  const contactIsCommon = commonFields.includes('contact');
  const kindIsCommon = commonFields.includes('kind');
  const groupIsCommon = commonFields.includes('groupId');

  const salesOpportunity = entries[0];

  const [newComment, setNewComment] = useState('');

  return (
    <PreviewWrapper>
      <PreviewTitle
        disabled={true}
        showEdit={!isMultiselect && canEditAllSelected}
        ref={titleRef}
        label={salesOpportunity?.description}
        onClick={() =>
          !isMultiselect &&
          descriptionIsAccessible &&
          handleLock(previewFields.DESCRIPTION)
        }
        onClose={(e) => {
          if (e.target.value !== salesOpportunity?.name) {
            setOptimisticTitle(e.target.value);
            void onEdit({ name: e.target.value });
          }
          onPopoverClose();
        }}
        onCloseClicked={onCloseClicked}
        onEditClicked={onEditClicked}
        onShareClicked={onShareClicked}
        actions={
          loading && activePicker === previewFields.DESCRIPTION ? (
            <CircularProgress size="1rem" color="secondary" />
          ) : null
        }
        convertProps={convertProps}
      >
        {isMultiselect
          ? `${t('COMMON.SELECTION')}: ${entries.length} ${t(
              'COMMON.ELEMENTS'
            )}`
          : descriptionIsAccessible
          ? optimisticTitle ?? salesOpportunity?.name
          : ''}
      </PreviewTitle>

      {isMultiselect && (
        <PreviewEditMessage entityId={salesOpportunity.id.toString()} />
      )}

      <Stack direction={'column'}>
        <InfoCards>
          {businessPartnerIsAccessible && (
            <EntityPickerPopover
              ref={customerRef}
              disabled={true}
              onTargetClick={() => handleLock(previewFields.CUSTOMER)}
              onClose={onPopoverClose}
              picker={
                <CustomerPicker
                  onChange={(value) =>
                    onEdit({
                      businessPartner: value
                        ? {
                            data: value,
                            businessPartnerType: SdObjType.KUNDE,
                            id: value?.id,
                          }
                        : null,
                      businessPartnerId: value ? value?.id : 0,
                      businessPartnerType: SdObjType.KUNDE,
                    })
                  }
                  value={salesOpportunity.businessPartner?.data as Customer}
                  multiple={false}
                />
              }
            >
              <InfoCard
                truncate
                label={t('COMMON.BUSINESS_PARTNER', {
                  context:
                    salesOpportunity?.businessPartner?.businessPartnerType,
                })}
                decorator={
                  loading && activePicker === previewFields.CUSTOMER ? (
                    <CircularProgress size="1rem" color="secondary" />
                  ) : null
                }
                decoratorPos="right"
                beforeContentElement={
                  <OpenFileIconButton
                    data={salesOpportunity?.businessPartner?.data}
                  />
                }
              >
                {customerIsCommon
                  ? salesOpportunity?.businessPartner?.data?.name
                  : `(${t('COMMON.MULTIPLE')})`}
              </InfoCard>
            </EntityPickerPopover>
          )}

          {contactIsAccessible && (
            <EntityPickerPopover
              ref={contactRef}
              disabled={true}
              onTargetClick={() =>
                customerIsCommon &&
                salesOpportunity?.businessPartner?.data &&
                handleLock(previewFields.CONTACT)
              }
              onClose={onPopoverClose}
              picker={
                <ContactPicker
                  prefilter={[
                    {
                      businessPartnerType: { $eq: SdObjType.KUNDE },
                    },
                    {
                      businessPartnerId: {
                        $eq: salesOpportunity?.businessPartner?.data.id,
                      },
                    },
                  ]}
                  value={salesOpportunity.contact}
                  multiple={false}
                  onChange={(value) =>
                    onEdit({
                      contact: value,
                      contactId: value ? value.id : 0,
                    })
                  }
                />
              }
            >
              <InfoCard
                disabled={
                  !customerIsCommon || !salesOpportunity?.businessPartner?.data
                }
                tooltip={
                  !customerIsCommon || !salesOpportunity?.businessPartner?.data
                    ? 'Wählen Sie zunächst ein einheitliches Projekt'
                    : undefined
                }
                label={t('COMMON.CONTACT')}
                decorator={
                  loading && activePicker === previewFields.CONTACT ? (
                    <CircularProgress size="1rem" color="secondary" />
                  ) : null
                }
                decoratorPos="right"
              >
                {contactIsCommon
                  ? salesOpportunity?.contact?.displayName || '-'
                  : `(${t('COMMON.MULTIPLE')})`}
              </InfoCard>
            </EntityPickerPopover>
          )}

          {projectIsAccessible && (
            <EntityPickerPopover
              ref={projectRef}
              disabled={true}
              onTargetClick={() => handleLock(previewFields.PROJECT)}
              onClose={onPopoverClose}
              picker={
                <ProjectPicker
                  onChange={(value) => {
                    onEdit({
                      project: value,
                      projectId: value ? value.id : 0,
                    });
                  }}
                  value={salesOpportunity.project}
                  multiple={false}
                />
              }
            >
              <InfoCard
                truncate
                label={t('COMMON.PROJECT')}
                decorator={
                  loading && activePicker === previewFields.PROJECT ? (
                    <CircularProgress size="1rem" color="secondary" />
                  ) : null
                }
                decoratorPos="right"
                beforeContentElement={
                  <OpenFileIconButton data={salesOpportunity?.project} />
                }
              >
                {projectIsCommon
                  ? salesOpportunity?.project?.name
                  : `(${t('COMMON.MULTIPLE')})`}
              </InfoCard>
            </EntityPickerPopover>
          )}

          {evaluationIsAccessible && (
            <EntityPickerPopover
              ref={evaluationRef}
              onTargetClick={() => handleLock(previewFields.EVALUATION)}
              onClose={onPopoverClose}
              disabled={true}
              picker={
                <SalesOpportunitiesEvaluationPicker
                  salesOpportunity={salesOpportunity}
                  value={salesOpportunity.probabilityPercent}
                  multiple={false}
                  onChange={(
                    newValue: SalesOpportunitiesEvaluationFormValue
                  ) => {
                    if (!newValue) {
                      onPopoverClose();
                    } else {
                      onEdit(
                        {
                          probabilityPercent: newValue?.probabilityPercent,
                          ratingStatusId: newValue?.ratingStatusId,
                          status: newValue?.status,
                        },
                        newValue?.comment && {
                          relations: {
                            ratings: {
                              set: [
                                {
                                  comment: newValue?.comment,
                                },
                              ],
                            },
                          },
                        }
                      );
                    }
                  }}
                />
              }
            >
              <InfoCard label={t('COMMON.EVALUATION')}>
                {probabilityIsCommon
                  ? `${salesOpportunity?.probabilityPercent ?? 100} %`
                  : `(${t('COMMON.MULTIPLE')})`}
              </InfoCard>
            </EntityPickerPopover>
          )}

          {nextReviewIsAccessible && (
            <EntityPickerPopover
              ref={dateRef}
              disabled
              onTargetClick={() => handleLock(previewFields.DATE_NEXT_REVIEW)}
              onClose={onPopoverClose}
              autoclose={true}
              picker={
                <DatePicker
                  locale={language}
                  controls={['calendar']}
                  defaultValue={formatDateString(
                    salesOpportunity?.dateNextReview,
                    DateFormatPreset.DATE_SIMPLE_2YEAR
                  )}
                  onChange={(val) => {
                    onEdit({
                      dateNextReview: new Date(val.value).toISOString(),
                    });
                  }}
                />
              }
            >
              <InfoCard
                label={t('COMMON.NEXT_REVIEW')}
                decorator={
                  loading && activePicker === previewFields.DATE_NEXT_REVIEW ? (
                    <CircularProgress size="1rem" color="secondary" />
                  ) : null
                }
                decoratorPos="right"
              >
                {nextReviewIsCommon
                  ? formatDateString(
                      salesOpportunity?.dateNextReview,
                      DateFormatPreset.DATE_SIMPLE_2YEAR
                    )
                  : `(${t('COMMON.MULTIPLE')})`}
              </InfoCard>
            </EntityPickerPopover>
          )}

          {decisionDateIsAccessible && (
            <EntityPickerPopover
              ref={decisionDateRef}
              disabled
              onTargetClick={() => handleLock(previewFields.DECISION_DATE)}
              onClose={onPopoverClose}
              autoclose={true}
              picker={
                <DatePicker
                  locale={language}
                  controls={['calendar']}
                  defaultValue={formatDateString(
                    salesOpportunity?.decisionDate,
                    DateFormatPreset.DATE_SIMPLE_2YEAR
                  )}
                  onChange={(val) => {
                    onEdit({
                      decisionDate: new Date(val.value).toISOString(),
                    });
                  }}
                />
              }
            >
              <InfoCard
                label={t('COMMON.DECISION_DATE')}
                decorator={
                  loading && activePicker === previewFields.DECISION_DATE ? (
                    <CircularProgress size="1rem" color="secondary" />
                  ) : null
                }
                decoratorPos="right"
              >
                {decisionDateIsCommon
                  ? formatDateString(
                      salesOpportunity?.decisionDate,
                      DateFormatPreset.DATE_SIMPLE_2YEAR
                    )
                  : `(${t('COMMON.MULTIPLE')})`}
              </InfoCard>
            </EntityPickerPopover>
          )}

          {expectedCostsIsAccessible && (
            <InfoCard label={t('COMMON.EXPECTED_SALES')} disabled>
              {/* <LabeledInputWithUnit
                unit={'EUR'}
                value={salesOpportunity?.expectedCosts}
                onChange={(val) => {
                  onEdit({
                    expectedCosts: parseFloat(val.target.value),
                  });
                }}
              /> */}
              {expectedCostIsCommon
                ? `${formatNumberAsCurrency(salesOpportunity?.value).replace(
                    /,.*$/,
                    ',-'
                  )} ${formatCurrency(salesOpportunity.currency, t)}`
                : `(${t('COMMON.MULTIPLE')})`}
            </InfoCard>
          )}

          {valueIsAccessible && (
            <InfoCard label={t('COMMON.RATED')} disabled>
              {valueIsCommon
                ? `${formatNumberAsCurrency(
                    salesOpportunity?.value *
                      ((salesOpportunity?.probabilityPercent ?? 100) / 100)
                  ).replace(/,.*$/, ',-')} ${formatCurrency(
                    salesOpportunity.currency,
                    t
                  )}`
                : `(${t('COMMON.MULTIPLE')})`}
            </InfoCard>
          )}

          {kindIsAccessible && (
            <InfoCard label={t('COMMON.KIND')} disabled>
              {kindIsCommon
                ? `${t(
                    SalesOpportunitiesKindLookUp[salesOpportunity?.kind ?? 0]
                  )}`
                : `(${t('COMMON.MULTIPLE')})`}
            </InfoCard>
          )}

          {groupIsAccessible && (
            <InfoCard label={t('COMMON.GROUP')} disabled>
              {groupIsCommon
                ? salesOpportunity?.group?.name ?? '-'
                : `(${t('COMMON.MULTIPLE')})`}
            </InfoCard>
          )}
        </InfoCards>
        {!isMultiselect ? (
          <>
            <Divider orientation="horizontal" />
            <EntityHistory
              fitContainer={false}
              entity={Entities.salesOpportunities}
              fullWidth
              id={salesOpportunity.id}
              className={styles.hist}
              breadcrumbTitle={`${salesOpportunity.description}`}
              newComment={newComment}
              onNewCommentChange={setNewComment}
            />
          </>
        ) : null}
      </Stack>
    </PreviewWrapper>
  );
}
