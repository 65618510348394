import styles from './FileListGalleryItem.module.scss';

import { EditableAttachmentFileName } from './EditableAttachmentFileName';
import { FileListGalleryItemThumbnail } from './FileListGalleryItemThumbnail';
import { FileAndThumbnail } from './types';

export interface FileListGalleryItemProps {
  file: FileAndThumbnail;
  onClick: () => void;
}

export function FileListGalleryItem(props: FileListGalleryItemProps) {
  const { file, onClick } = props;

  const fileId = file.id ?? file.file.fileServiceProviderInfos?.id ?? null;
  const fileName = file.file.fileEntityFilename;

  return (
    <div className={styles.root}>
      <div className={styles.thumbnail} tabIndex={0} onClick={onClick}>
        <FileListGalleryItemThumbnail file={file} />
      </div>

      <EditableAttachmentFileName fileId={fileId} originalFileName={fileName} />
    </div>
  );
}
