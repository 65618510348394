import styles from './Content.module.scss';

import { Modal, Paper, Slide, Theme, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { IgnoreKeys } from 'react-hotkeys';

import { OverlayFullscreenContext } from '@work4all/data/lib/hooks/data-provider/useOverlayFullscreenDataProvider';

import {
  Breadcrumbs,
  IBreadcrumbsProps,
} from '../../../breadcrumbs/Breadcrumbs';
import { useHistoryStack } from '../../../history-stack';
import { StackIndexProvider } from '../../../history-stack/components/stack-index-provider/StackIndexProvider';
import { Transisition } from '../transition/Transition';

interface IClasses {
  wrapper?: string;
  wrapperSmall?: string;
  paper?: string;
}

export interface IContentProps extends Pick<IBreadcrumbsProps, 'heading'> {
  withBreadcrumbs?: boolean;
  close: () => void;
  open: boolean;
  classes?: IClasses;
  onExited?: () => void;
  /**
   * In case you have several decorators and you want to compose them use "composeReactComponents" util (@work4all/utils/lib/compose)
   * It's more performant this way because we don't need to compose components at rendering time.
   */
  decorator?: <Props>(props: Props) => React.ReactElement;
}

const transitionClasses = { root: styles.transition };

export const Content: React.FC<IContentProps> = React.memo(function Content(
  props
) {
  const { current, stack, currentStackIndex, close, goBack } =
    useHistoryStack();
  const { fullscreen } = useContext(OverlayFullscreenContext);
  const View = current ? current.view : null;
  const Decorator = props?.decorator;
  const viewIndex = currentStackIndex;
  const isSmallSize = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );

  return (
    <IgnoreKeys>
      <Modal open={props.open} onClose={close}>
        <Slide direction="left" in={props.open} onExited={props.onExited}>
          <div
            className={clsx(
              styles.wrapper,
              {
                [styles.wrapperFullscreen]: fullscreen,
                [styles.wrapperSmall]: isSmallSize,
                [props.classes?.wrapperSmall]: props.classes?.wrapperSmall,
              },
              props.classes?.wrapper
            )}
          >
            <Transisition classes={transitionClasses}>
              <Paper className={clsx(styles.paper, props.classes?.paper)}>
                {props.withBreadcrumbs && (
                  <Breadcrumbs
                    close={close}
                    stack={stack}
                    current={current}
                    currentIndex={viewIndex}
                    goBack={goBack}
                    heading={props.heading}
                    classes={{ root: styles['breadcrumbs-root'] }}
                  />
                )}
                {/**
                 * Transition component will copy these childer which allows to bind viewIndex.
                 * Views can use this index to get retrieve correct data from stack item
                 * (including transition effect)
                 */}
                <div className={styles.contentWrap}>
                  <StackIndexProvider index={viewIndex}>
                    {Decorator ? <Decorator>{View}</Decorator> : View}
                  </StackIndexProvider>
                </div>
              </Paper>
            </Transisition>
          </div>
        </Slide>
      </Modal>
    </IgnoreKeys>
  );
});
