import Typography from '@mui/material/Typography';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export const taskPrioTranslationKeys: Record<number, string> = {
  10: 'COMMON.LOW',
  0: 'COMMON.LOW', //Gelesen
  11: 'COMMON.NORMAL',
  1: 'COMMON.NORMAL', //Gelesen
  12: 'COMMON.HIGH',
  2: 'COMMON.HIGH', //Gelesen
};

export type ITaskPrioPickerProps<TMultiple extends boolean> = IPickerProps<
  { id: number; name: string },
  TMultiple
>;

export function TaskPrioPicker<TMultiple extends boolean>(
  props: ITaskPrioPickerProps<TMultiple>
) {
  const { ...rest } = props;

  const { t } = useTranslation();

  const dataSet = useMemo(() => {
    const taskStatusValues: { id: number; name: string }[] = [
      {
        id: 10,
        name: t(taskPrioTranslationKeys[10]),
      },
      {
        id: 11,
        name: t(taskPrioTranslationKeys[11]),
      },
      {
        id: 12,
        name: t(taskPrioTranslationKeys[12]),
      },
    ];

    return {
      total: taskStatusValues.length,
      loading: false,
      fetchMore: () => Promise.resolve(),
      refetch: () => Promise.resolve(null),
      data: taskStatusValues,
    };
  }, [t]);

  return (
    <ListEntityPicker
      entity={Entities.task}
      multiple={false}
      maxItems={Math.max(dataSet.total, 5)}
      data={TASK_STATUS_FIELDS}
      filterBy="name"
      sortBy="name"
      clearable={false}
      {...rest}
      fixedDataSet={dataSet}
      renderItemContent={(status) => (
        <Typography variant="body2" noWrap>
          {status.name}
        </Typography>
      )}
    />
  );
}

const TASK_STATUS_FIELDS = {
  id: null,
  name: null,
};
