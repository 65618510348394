import styles from './EntityPickerActions.module.scss';

import { Theme, useMediaQuery } from '@mui/material';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  IProjectPickerProps,
  ProjectPicker,
} from '@work4all/components/lib/components/entity-picker/project-picker/ProjectPicker';
import { useEntityTemplate } from '@work4all/components/lib/components/entity-template-provider/EntityTemplateProvider';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { reactRefSetter } from '@work4all/utils';

import { LabeledInput } from '../../containers/mask-overlays/locked-inputs';

interface ProjectPickerFieldProps
  extends Omit<IProjectPickerProps<false>, 'multiple'> {
  error?: string;
  excludeClosedStatus?: boolean;
  excludeLockInhouseServicesFilter?: boolean;
  excludeLockExternalServicesFilter?: boolean;
  name?: string;
  label?: string;
}

const closedStatusFilter = {
  $and: [
    {
      $or: [
        {
          projectStatusId: { $eq: 0 },
        },
        {
          projectStatusId: { $eq: null },
        },
        {
          'projectStatus.closedStatus': { $eq: 0 },
        },
        {
          'projectStatus.closedStatus': { $eq: null },
        },
      ],
    },
  ],
};

const lockInhouseServicesFilter = {
  $and: [
    {
      $or: [
        {
          lockInhouseServices: { $eq: 0 },
        },
        {
          lockInhouseServices: { $eq: null },
        },
      ],
    },
  ],
};

const lockExternalServicesFilter = {
  lockExternalServices: { $eq: 0 },
};

export const ProjectPickerField = React.forwardRef<
  HTMLDivElement,
  ProjectPickerFieldProps
>(function ProjectPickerField(props, ref) {
  const {
    error,
    favorites = true,
    excludeClosedStatus,
    excludeLockExternalServicesFilter,
    excludeLockInhouseServicesFilter,
    label,
    prefilter: originalPrefilter,
    ...pickerProps
  } = props;
  const { value, onChange } = pickerProps;

  const { t } = useTranslation();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<EntityPickerPopover>(null);
  const entityTemplate = useEntityTemplate();

  const prefilter =
    !excludeClosedStatus &&
    !excludeLockExternalServicesFilter &&
    !excludeLockInhouseServicesFilter
      ? undefined
      : [
          ...(excludeClosedStatus ? [closedStatusFilter] : []),
          ...(excludeLockExternalServicesFilter
            ? [lockExternalServicesFilter]
            : []),
          ...(excludeLockInhouseServicesFilter
            ? [lockInhouseServicesFilter]
            : []),
          ...(originalPrefilter ? originalPrefilter : []),
        ];

  const resultingLabel = label || t('COMMON.PROJECT');

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );

  return (
    <div ref={reactRefSetter(wrapperRef, ref)}>
      <EntityPickerPopover
        fullscreen={isMobile}
        ref={popoverRef}
        anchorEl={wrapperRef}
        footer={entityTemplate.renderPickerFooter({
          popoverRef,
          entity: Entities.project,
          ...props,
        })}
        picker={
          <ProjectPicker
            multiple={false}
            prefilter={prefilter}
            favorites={favorites}
            {...pickerProps}
          />
        }
      >
        <LabeledInput
          value={value ? value?.name : ''}
          name={props.name}
          label={
            <>
              {!value && resultingLabel}
              {value && (
                <>
                  <span className={styles.primaryInfo}>
                    {resultingLabel} &nbsp;
                  </span>
                  <span className={styles.secondaryInfo}>{value?.number}</span>
                </>
              )}
            </>
          }
          error={error}
          onClear={() => {
            onChange(null);
          }}
        />
      </EntityPickerPopover>
    </div>
  );
});
