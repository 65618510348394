import styles from './NavigationOverlayHeader.module.scss';

import { Paper } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect } from 'react';

import {
  Breadcrumbs,
  IBreadrumbsClasses,
} from '@work4all/components/lib/navigation/breadcrumbs/Breadcrumbs';
import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack/hooks/useHistoryStack';

interface INavigationOverlayHeader {
  classes?: {
    breadcrumbs?: IBreadrumbsClasses;
    breadcrumbsWrapper?: string;
  };
  breadcrumbsChildren?: React.ReactChild;
  tabs?: React.ReactChild;
  title?: string;
  subTitle?: string;
  breadcrumbTitle?: string;
  horizontalPadding?: 'xxs' | 'xs' | 's';
  hideBreadcrumbs?: boolean;
  forceStackItems?: boolean;
}

export const NavigationOverlayHeader: React.FC<INavigationOverlayHeader> = (
  props
) => {
  const {
    title,
    subTitle,
    breadcrumbTitle,
    classes,
    breadcrumbsChildren,
    tabs,
    children,
    hideBreadcrumbs,
    forceStackItems = false,
  } = props;

  const {
    viewStackItem: current,
    currentStackIndex,
    viewIndex,
    stack,
    updateCurrent,
    close,
    goBack,
  } = useHistoryStack();

  let verticalPadding = 's';
  if (viewIndex > 0) {
    /** breadcrumbs will be shown. with breadcrumbs padding should be "xs" */
    verticalPadding = 'xs';
  }

  useEffect(() => {
    if (
      currentStackIndex !== viewIndex ||
      (!title && !subTitle && !breadcrumbTitle)
    ) {
      return;
    }

    updateCurrent((baseState) => {
      const newState = { ...baseState };
      if (title) {
        newState.title = title;
      }
      if (subTitle) {
        newState.subTitle = subTitle;
      }
      if (breadcrumbTitle) {
        newState.breadcrumbTitle = breadcrumbTitle;
      }

      return newState;
    });
  }, [
    currentStackIndex,
    subTitle,
    title,
    updateCurrent,
    viewIndex,
    breadcrumbTitle,
  ]);

  return (
    <Paper className={styles.root}>
      <div
        className={clsx(styles.breadcrumbsWrapper, classes?.breadcrumbsWrapper)}
      >
        <Breadcrumbs
          classes={{
            ...classes?.breadcrumbs,
            root: clsx(styles.breadcrumbs, classes?.breadcrumbs?.root, {
              [styles[`verticalPadding--${verticalPadding}`]]: verticalPadding,
            }),
            heading: clsx(
              styles.breadcrumbsHeading,
              classes?.breadcrumbs?.heading
            ),
          }}
          withMinHeight={false}
          current={current}
          currentIndex={viewIndex}
          goBack={goBack}
          stack={stack}
          close={close}
          hideBreadcrumbs={hideBreadcrumbs}
          forceStackItems={forceStackItems}
        />
        {breadcrumbsChildren}
      </div>
      {tabs ? <div className={styles.tabsWrapper}>{tabs}</div> : null}
      {children}
    </Paper>
  );
};
