import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

export const typeNameToSdObjType = (typeName: string, fallback = false) => {
  if (typeName === 'Kunde') return SdObjType.KUNDE;
  if (typeName === 'Lieferant') return SdObjType.LIEFERANT;
  if (typeName === 'Projekt') return SdObjType.PROJEKT;
  if (fallback) return SdObjType.KUNDE;

  return null;
};
