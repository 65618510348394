import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { Invoice } from '@work4all/models/lib/Classes/Invoice.entity';
import { DataRequest, SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export const useOldestInvoice = () => {
  const invoiceYearDataRequest: DataRequest = useMemo(() => {
    const data: Invoice = {
      id: null,
      date: null,
    };
    return {
      entity: Entities.invoice,
      data: data,
      sort: [{ field: 'date', direction: SortDirection.ASCENDING }],
      vars: {
        querySize: 1,
        queryPage: 0,
      },
    };
  }, []);

  return useDataProvider<Invoice>(invoiceYearDataRequest);
};
