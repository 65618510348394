import { useTranslation } from 'react-i18next';

import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';

import { FixedDataPicker } from '../fixed-data-picker/FixedDataPicker';
import { IPickerProps } from '../types';

export type IEMailTemplateKindPickerProps<TMultiple extends boolean> =
  IPickerProps<{ id: EMailTemplateKind; name: string }, TMultiple>;

export function EMailTemplateKindPicker<TMultiple extends boolean>(
  props: IEMailTemplateKindPickerProps<TMultiple>
) {
  const { ...rest } = props;

  const { t } = useTranslation();

  const dataSet = [
    {
      id: EMailTemplateKind.KEINE,
      name: t('E_MAIL_TEMPLATE_KIND.' + EMailTemplateKind.KEINE),
    },
    {
      id: EMailTemplateKind.TICKET,
      name: t('E_MAIL_TEMPLATE_KIND.' + EMailTemplateKind.TICKET),
    },
  ];

  return <FixedDataPicker dataSet={dataSet} {...rest} />;
}
