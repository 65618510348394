import { TopicSimple } from '@work4all/models/lib/Classes/TopicSimple.entity';

export const titleWithTopicMark = (
  currentTitle: string,
  topicMarkList: TopicSimple[],
  usedTopicMark: string | undefined,
  skip: boolean
) => {
  let title: string;
  let topicMark: string | undefined = usedTopicMark;
  const isTopicMarkList = topicMarkList && topicMarkList.length > 0;

  if (skip === false) {
    if (isTopicMarkList === false && usedTopicMark) {
      // If the title contains the used topic mark, remove it.
      // The topic mark might or might not have a "| " part in front of it.
      const topicMarkString1 = `| ${usedTopicMark}`;
      const topicMarkString2 = `${usedTopicMark}}`;

      if (currentTitle.includes(topicMarkString1)) {
        title = currentTitle.replace(topicMarkString1, '');
      } else if (currentTitle.includes(topicMarkString2)) {
        title = currentTitle.replace(topicMarkString2, '');
      }
    } else if (isTopicMarkList) {
      topicMark = formatTopicMarkName(topicMarkList[0].name);

      if (usedTopicMark && currentTitle.includes(usedTopicMark)) {
        title = currentTitle.replace(usedTopicMark, topicMark);
      } else {
        title = currentTitle ? `${currentTitle} | ${topicMark}` : topicMark;
      }
    }
  }

  return { title, topicMark };
};

/**
 * Formats a topic mark name by removing the leading number and whitespace.
 */
export function formatTopicMarkName(name: string) {
  if (!name) return '';

  const prefix = /^\d+\s/;
  // If the name starts with a string of numbers followed by a whitespace,
  // remove them and return the remaining string. Otherwise, return the full
  // string.
  return name.match(prefix) ? name.replace(/^\d+\s/, '') : name;
}
