import { User } from '@work4all/models/lib/Classes/User.entity';

import { createUseUser } from './createUseUser';

export type IUser = {
  refreshToken?: string;
  benutzerCode?: number;
  benutzerRechte?: string | null;
  kundennummer?: number;
  baseUrl: string;
  shortName?: string;
  token: string;
  salutation?: string;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  phone?: string;
  email?: string;
  departmentName?: string;
  isMaster?: boolean;
  roles: Array<{ id: number; name: string }>;
  vacationApprover?: User;
  vacationApproverId?: number;
  vactationEntitlement?: User['vactationEntitlement'];
  senderMailaddresses?: string[];
  mandanten: { code: number; name: string; staat: string }[];
  kommtGehtMinimaleStartZeit?: number;
  supplierCode?: number;
};

const { UserProvider, useUser } = createUseUser<IUser>();

export { UserProvider, useUser };
