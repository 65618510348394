import styles from './Camera.module.scss';

import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';

import { DisplayError } from './components/display-error/DisplayError';
import { TakePictureButton } from './components/take-picture-button/TakePictureButton';
import { WhiteFlash } from './components/white-flash/WhiteFlash';
import { printCameraInfo } from './helpers/helpers';
import { useLibCameraPhoto } from './hooks/useLibCameraPhoto';
import { ICameraProps } from './types';

let showVideoTimeoutId: NodeJS.Timeout = null;

export const Camera: React.FC<ICameraProps> = (props) => {
  const [dataUri, setDataUri] = useState('');
  const [isShowVideo, setIsShowVideo] = useState(true);
  const [cameraStartDisplayError, setCameraStartDisplayError] = useState('');

  const videoRef = useRef<HTMLVideoElement>(null);

  const { mediaStream, cameraStartError, cameraStopError, getDataUri } =
    useLibCameraPhoto(
      videoRef,
      props.idealFacingMode,
      props.idealResolution,
      props.isMaxResolution
    );

  useEffect(() => {
    if (mediaStream) {
      if (typeof props.onCameraStart === 'function') {
        props.onCameraStart();
      }
    } else {
      if (typeof props.onCameraStop === 'function') {
        props.onCameraStop();
      }
    }
  }, [mediaStream, props]);

  useEffect(() => {
    if (cameraStartError) {
      setCameraStartDisplayError(
        `${cameraStartError.name} ${cameraStartError.message}`
      );
      if (typeof props.onCameraError === 'function') {
        props.onCameraError(cameraStartError);
      }
    }
  }, [cameraStartError, props]);

  useEffect(() => {
    if (cameraStopError) {
      printCameraInfo(cameraStopError.message);
    }
  }, [cameraStopError]);

  function clearShowVideoTimeout() {
    if (showVideoTimeoutId) {
      clearTimeout(showVideoTimeoutId);
    }
  }

  function handleTakePhoto() {
    const configDataUri = {
      sizeFactor: props.sizeFactor,
      imageType: props.imageType,
      imageCompression: props.imageCompression,
      isImageMirror: props.isImageMirror,
    };

    const dataUri = getDataUri(configDataUri);
    setDataUri(dataUri);

    setIsShowVideo(false);
    clearShowVideoTimeout();
    showVideoTimeoutId = setTimeout(() => {
      if (typeof props.onTakePhoto === 'function') {
        props.onTakePhoto(dataUri);
      }
      setIsShowVideo(true);
      if (typeof props.onTakePhotoAnimationDone === 'function') {
        props.onTakePhotoAnimationDone(dataUri);
      }
    }, 900);
  }

  return (
    <div className={clsx(styles.root)}>
      <DisplayError
        isDisplayError={props.isDisplayStartCameraError}
        errorMsg={cameraStartDisplayError}
      />
      <WhiteFlash isShowWhiteFlash={!isShowVideo} />
      <img
        className={clsx(styles.img, { [styles.invis]: isShowVideo })}
        alt="camera"
        src={dataUri}
      />
      <video
        className={clsx(styles.video, {
          [styles.invis]: !isShowVideo,
          [styles.imageMirror]: props.isImageMirror,
        })}
        ref={videoRef}
        autoPlay={true}
        muted={true}
        playsInline
      />
      {isShowVideo && <TakePictureButton onClick={handleTakePhoto} />}
    </div>
  );
};
