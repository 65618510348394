import React from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import {
  MultiStepControls,
  Step,
} from '@work4all/components/lib/input/multi-step-controls';

import { BusinessPartnerContactCombined } from '@work4all/models/lib/Classes/BusinessPartnerContactCombined.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { BusinessPartnerPickerField } from '../../../../../../../../../../components/entity-picker/BusinessPartnerPickerField';
import { ContactPickerField } from '../../../../../../../../../../components/entity-picker/ContactPickerField';
import { DeliveryKindPickerField } from '../../../../../../../../../../components/entity-picker/DeliveryKindPickerField';
import { TourPickerField } from '../../../../../../../../../../components/entity-picker/TourPickerField';
import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { ControlWrapper } from '../../../../../../../components/control-wrapper';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { BPMaskFormValue } from '../../../../../types';

export const Delivery: React.FC = () => {
  const { t } = useTranslation();
  const { control, watch } = useFormContextPlus<BPMaskFormValue>();

  const mask = useMaskContext();

  const currentAdditionalAddress2: BusinessPartnerContactCombined =
    watch('additionalAddress2');

  return (
    <Collapse defaultOpen={true} title={t('MASK.DELIVERY')}>
      <ControlWrapper>
        <ControllerPlus
          name="additionalAddress2"
          control={control}
          render={({ field: adAddField, fieldState: addFieldState }) => {
            return (
              <ControllerPlus
                name="additionalAddress2.contact"
                control={control}
                render={({
                  field: contactField,
                  fieldState: contactFieldState,
                }) => {
                  return (
                    <MultiStepControls>
                      <Step active={true} index={0}>
                        <BusinessPartnerPickerField
                          {...adAddField}
                          error={addFieldState?.error?.message}
                          label={t('INPUTS.DELIVERY_ADDRESS')}
                          value={
                            (
                              adAddField?.value as BusinessPartnerContactCombined
                            )?.businessPartner?.data
                          }
                          onChange={(value) => {
                            let newValue =
                              adAddField.value as BusinessPartnerContactCombined;

                            if (newValue === undefined || newValue === null) {
                              newValue = {};
                            }

                            newValue.businessPartner = {
                              businessPartnerType:
                                value?.entity === Entities.customer
                                  ? SdObjType.KUNDE
                                  : SdObjType.LIEFERANT,
                              data: value?.data,
                              id: value?.data?.id,
                            };
                            newValue.contact = value?.data?.mainContact || null;

                            if (!value) {
                              newValue = null;
                            }
                            adAddField.onChange(newValue);
                          }}
                        />
                      </Step>
                      <Step
                        active={
                          adAddField.value !== undefined &&
                          adAddField.value !== null
                        }
                        index={1}
                      >
                        <ContactPickerField
                          {...contactField}
                          businessPartnerId={
                            currentAdditionalAddress2?.businessPartner?.id
                          }
                          businessPartnerType={
                            currentAdditionalAddress2?.businessPartner
                              ?.businessPartnerType
                          }
                          error={contactFieldState?.error?.message}
                          value={contactField.value}
                        />
                      </Step>
                    </MultiStepControls>
                  );
                }}
              />
            );
          }}
        />

        <ControllerPlus
          name="deliveryKind"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <DeliveryKindPickerField
                {...field}
                error={fieldState?.error?.message}
              />
            );
          }}
        />
        {mask.entity === Entities.customer && (
          <ControllerPlus
            name="tour"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TourPickerField
                  {...field}
                  error={fieldState?.error?.message}
                />
              );
            }}
          />
        )}
        {mask.entity === Entities.supplier && <div>&nbsp;</div>}
      </ControlWrapper>
    </Collapse>
  );
};
