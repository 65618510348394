import styles from './UserMenu.module.scss';

import { Badge } from '@mui/material';
import React, { useState } from 'react';

import { HookedUserIcon } from '@work4all/components/lib/components/user-icon/useUserIconRegister';
import { IStackItem } from '@work4all/components/lib/navigation/history-stack';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { useUser } from '@work4all/data';

import {
  MAX_DISPLAYED_NOTIFICATIONS,
  useUnreadNotifications,
} from '../../features/notifications';

import { Profile } from './views/Profile/Profile';

const classes = { wrapper: styles.menu };

export const UserMenu: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = (props) => {
  const user = useUser();

  const [popoverState, setPopoverState] = useState<{
    open: boolean;
    initialView: IStackItem;
  }>({ open: false, initialView: null });

  const latestNotifications = useUnreadNotifications();

  const openPopover = () => {
    setPopoverState({
      open: true,
      initialView: {
        title: '',
        view: <Profile />,
        breadcrumbTitle: user.shortName,
      },
    });
  };

  const closePopover = () => {
    setPopoverState((state) => {
      return { ...state, open: false };
    });
  };

  const togglePopover = () => {
    if (popoverState.open) {
      closePopover();
    } else {
      openPopover();
    }
  };

  return (
    <div {...props}>
      <NavigationOverlay
        classes={classes}
        open={popoverState.open}
        initialView={popoverState.initialView}
        withBreadcrumbs={true}
        close={() => {
          closePopover();
        }}
      />
      <div onClick={togglePopover} className={styles.pointer}>
        <Badge
          badgeContent={latestNotifications.total}
          max={MAX_DISPLAYED_NOTIFICATIONS}
          color="error"
          overlap="circular"
        >
          <HookedUserIcon userId={user?.benutzerCode} size="xl" />
        </Badge>
      </div>
    </div>
  );
};
