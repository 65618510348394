import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class Sprint<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: completed */
  completed?: number;
  /** Alias: endDate */
  endDate?: string;
  /** Alias: startDate */
  startDate?: string;
  /** Alias: name */
  name?: string;

  __typename?: string;
}

const fields: FieldDefinitions<Sprint> = {
  id: {
    alias: 'id',
  },
  completed: {
    alias: 'completed',
  },
  endDate: {
    alias: 'endDate',
  },
  startDate: {
    alias: 'startDate',
  },
  name: {
    alias: 'name',
  },
  __typename: {
    alias: '__typename',
  },
};

export const sprintEntityDefinition: EntitiyDefinition<Sprint> = {
  local: {},
  remote: {
    queryName: 'getTicketSprints',
    fragmentName: 'Sprint',
    withPaginationWrapper: true,
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
