import styles from '../../Login.module.scss';

import ClearIcon from '@mui/icons-material/Clear';
import {
  ButtonBase,
  FilledTextFieldProps,
  TextField as MuiTextField,
} from '@mui/material';
import { FieldHelperProps, useField } from 'formik';
import React from 'react';

interface ITextFieldProps {
  onClear?: (helpers: FieldHelperProps<string>) => void;
  name: string;
}

export const TextField: React.FC<
  Omit<FilledTextFieldProps, 'variant' | 'name'> & ITextFieldProps
> = (props) => {
  const [inputProps, _, helpers] = useField(props.name);
  const { onClear, ...restProps } = props;

  // https://github.com/mui-org/material-ui/issues/15697
  // problem appears after you "abornedEnd" class
  const textFieldProps: FilledTextFieldProps = {
    className: styles['form-input'],
    variant: 'filled',
    InputProps: {
      classes: {
        root: styles['form-input__input-root'],
        input: styles['form-input__input'],
        adornedEnd: styles['clear-btn-wrapper'],
      },
      endAdornment: onClear && (
        <ButtonBase
          onClick={() => onClear(helpers)}
          classes={{ root: styles['clear-btn'] }}
        >
          <ClearIcon className={styles['clear-btn__icon']} />
        </ButtonBase>
      ),
    },
    InputLabelProps: {
      classes: {
        root: styles['form-input__label'],
        asterisk: styles['form-input__asterisk'],
      },
    },
    ...restProps,
    ...inputProps,
  };

  return <MuiTextField {...textFieldProps} />;
};
