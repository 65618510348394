import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class ArticleText<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: artikelCode */
  articleId?: number;
  /** Alias: einheitCode */
  einheitCode?: number;
  /** Alias: sprachCode */
  sprachCode?: number;
  /** Alias: kurztext */
  kurztext?: string;
  /** Alias: langtext */
  longtext?: string;

  __typename?: string;
}

const fields: FieldDefinitions<ArticleText> = {
  id: {
    alias: 'code',
  },
  articleId: {
    alias: 'artikelCode',
  },
  einheitCode: {
    alias: 'einheitCode',
  },
  sprachCode: {
    alias: 'sprachCode',
  },
  kurztext: {
    alias: 'kurztext',
  },
  longtext: {
    alias: 'langtext',
  },
  __typename: {
    alias: '__typename',
  },
};

export const articleTextEntityDefinition: EntitiyDefinition<ArticleText> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'ArtikelText',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
