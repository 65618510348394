import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class Department<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: kundenCode */
  customerId?: number;
  /** Alias: lieferantenCode */
  supplierId?: number;
  /** Alias: name */
  name?: string;
  /** Alias: updateTime */
  updateTime?: string;

  __typename?: string;
}

const fields: FieldDefinitions<Department> = {
  id: {
    alias: 'code',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customerId: {
    alias: 'kundenCode',
  },
  supplierId: {
    alias: 'lieferantenCode',
  },
  name: {
    alias: 'name',
  },
  updateTime: {
    alias: 'updateTime',
  },
  __typename: {
    alias: '__typename',
  },
};

export const departmentEntityDefinition: EntitiyDefinition<Department> = {
  local: {},
  remote: {
    queryName: 'getAbteilungen',
    fragmentName: 'Abteilung',
    withPaginationWrapper: false,
    params: [
      { name: 'kundenCode', type: '[Int]' },
      { name: 'lieferantenCode', type: '[Int]' },
    ],
  },
  fieldDefinitions: fields,
};
