import styles from './ReportPreviewMask.module.scss';

import { gql, useQuery } from '@apollo/client';
import { Download, Fullscreen } from '@mui/icons-material';
import PrintIcon from '@mui/icons-material/Print';
import {
  IconButton,
  LinearProgress,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import printJS from 'print-js';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButtonWithTooltip } from '@work4all/components/lib/input/actions/IconButtonWithTooltip';
import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';
import {
  FilePreview,
  FilePreviewProvider,
  useFilePreview,
} from '@work4all/components/lib/preview/FilePreviewProvider';

import { useAuthHeaders } from '@work4all/data/lib/auth/use-auth-headers';
import {
  downloadAuthed,
  getObjectUrlAuthed,
  parseNameAndExtension,
} from '@work4all/data/lib/utils';

import { CreateCrystalReportRequest } from '@work4all/models/lib/Classes/CreateCrystalReportRequest.entity';
import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { Report } from '@work4all/models/lib/Classes/Report.entity';
import { TempFile } from '@work4all/models/lib/Classes/TempFile.entity';
import { BzObjType } from '@work4all/models/lib/Enums/BzObjType.enum';
import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ReportPickerField } from '../../../../../../../../components/entity-picker/ReportPickerField';
import { NavigationOverlayHeader } from '../../../../../../../../components/navigation-overlay-header/NavigationOverlayHeader';
import {
  ApiErrors,
  ValidationErrors,
} from '../../../../../../../apollo/ValidationErrors';
import {
  EmailTemplateButtonProvider,
  EmailTemplateIconButton,
} from '../../../../../../components/email-template-button/EmailTemplateButton';
import { EmailOverlayController } from '../../../../email/EmailOverlayController';

interface ReportPreviewMaskProps extends BaseReportPreviewMaskProps {
  id: number | string;
}

interface CrystalReportResponse {
  createCrystalReport: CreateCrystalReport;
}

interface CreateCrystalReport {
  displayName: string;
  tempFile: TempFile;
  tempFileID: string;
  typename: string;
}

const CRYSTAL_REPORT_QUERY = gql`
  query CreateCrystalReport($input: CreateCrystalReportRequest) {
    createCrystalReport(input: $input) {
      displayName
      tempFile {
        dateiname
        fileInfos {
          downloadMimeType
          downloadUrl
          fileEntityFilename
          previewUrl
          previewMimeType
          fileSize
          fileServiceProviderInfos {
            customUrl
            exists
            fspUrl
            thumbnail
            size
            mimeType
            key
            id
            filename
          }
          fileRequestType
        }
        id
        datum
      }
      tempFileId
    }
  }
`;

export const ReportPreviewMask = (props: ReportPreviewMaskProps) => {
  const { id } = props;
  const [report, setReport] = useState<Report | null>(props.report);

  const { loading, data, error } = useQuery<
    CrystalReportResponse,
    { input: CreateCrystalReportRequest }
  >(CRYSTAL_REPORT_QUERY, {
    skip: !report,
    variables: {
      input: {
        bzObjType: report?.bzObjType,
        reportCode: report?.id,
        objectCode: parseInt(id.toString()),
      },
    },
  });
  const parsedErrors = error
    ? ValidationErrors.parseErrors(error?.graphQLErrors)
    : undefined;

  return (
    <FilePreviewProvider file={data?.createCrystalReport.tempFile}>
      <ReportPreviewMaskInternal
        {...props}
        errors={parsedErrors}
        report={report}
        loading={loading}
        setReport={setReport}
      />
    </FilePreviewProvider>
  );
};

interface ReportPreviewMaskInternalProps extends BaseReportPreviewMaskProps {
  setReport: (report: Report) => void;
  errors?: ApiErrors;
  loading: boolean;
}

interface BaseReportPreviewMaskProps {
  contactId?: number;
  businessPartnerId?: number;
  businessPartnerType?: Entities.customer | Entities.supplier;
  report: Report | null;
  bzObjecType?: BzObjType;
}

const ReportPreviewMaskInternal = (props: ReportPreviewMaskInternalProps) => {
  const {
    bzObjecType,
    report,
    setReport,
    contactId,
    businessPartnerId,
    businessPartnerType,
    errors,
    loading,
  } = props;
  const { t } = useTranslation();
  const { file, openFullscreen } = useFilePreview();
  const httpHeaders = useAuthHeaders();

  const isSmDown = useMediaQuery<Theme>((t) => t.breakpoints.down('sm'));

  const { go, goBack, setObjectionListener } = useHistoryStack();

  const fileName =
    report && file
      ? `${report.note}.${
          parseNameAndExtension(file.fileInfos.fileEntityFilename).extension
        }`
      : '';
  const openEmailMask = useCallback(
    async (template: EMailTemplate) => {
      const entityTemplate = businessPartnerId
        ? {
            entity: contactId ? Entities.contact : businessPartnerType,
            id: contactId
              ? `${contactId}:${businessPartnerType}:${businessPartnerId}`
              : businessPartnerId,
          }
        : undefined;

      const onAfterSave = () => {
        setObjectionListener(null);
        goBack();
      };
      go({
        view: (
          <EmailOverlayController
            entity={Entities.eMail}
            id={null}
            template={entityTemplate}
            onAfterSave={onAfterSave}
            params={{
              basedon: 'EmailTemplate',
              emailTemplate: JSON.stringify(template),
              tempFileAttachements: JSON.stringify([
                {
                  ...file,
                  fileName,
                },
              ]),
            }}
          />
        ),
      });
    },
    [
      contactId,
      businessPartnerId,
      businessPartnerType,
      go,
      file,
      fileName,
      goBack,
      setObjectionListener,
    ]
  );

  const download = (e) => {
    e.preventDefault();
    downloadAuthed(
      file.fileInfos.downloadUrl,
      `${report.note}.${
        parseNameAndExtension(file.fileInfos.fileEntityFilename).extension
      }`,
      httpHeaders
    );
  };

  const print = async (e) => {
    e.preventDefault();
    const object = await getObjectUrlAuthed(
      file.fileInfos.downloadUrl,
      httpHeaders
    );
    printJS(object);
  };

  const renderBreadcrumbsChildren = () => (
    <div className={styles['header-wrapper']}>
      <IconButton color="primary" onClick={print}>
        <PrintIcon />
      </IconButton>
      <IconButton color="primary" onClick={download}>
        <Download />
      </IconButton>

      <EmailTemplateIconButton />

      {isDesktop && (
        <IconButtonWithTooltip
          tooltip={t('COMMON.FULLSCREEN')}
          icon={<Fullscreen />}
          onClick={openFullscreen}
        />
      )}
    </div>
  );
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));

  const errorComponent = errors?.length
    ? errors.map((x) => (
        <Typography key={x.code} component="span" variant="body2" color="error">
          {t(`ERROR.${x.code}`)}
        </Typography>
      ))
    : null;

  return (
    <EmailTemplateButtonProvider
      kind={EMailTemplateKind.KEINE}
      openEmailMask={openEmailMask}
    >
      <div className={styles['wrapper']}>
        <NavigationOverlayHeader
          title={t('COMMON.PREVIEW')}
          breadcrumbsChildren={file ? renderBreadcrumbsChildren() : undefined}
          forceStackItems
        />
        <div className={styles['content-wrapper']}>
          <div className={styles['left-panel']}>
            <ReportPickerField
              bzObjecType={bzObjecType}
              value={report}
              error={errors?.length ? ' ' : undefined}
              onChange={(report) => {
                setReport(report);
              }}
            />
            {isDesktop && errorComponent}
          </div>
          <div className={styles['right-content']}>
            {loading && <LinearProgress />}
            {!isDesktop && errors?.length && (
              <div className={styles['mobile-error']}>{errorComponent}</div>
            )}
            {!loading && file && (
              <FilePreview
                file={file}
                initialScale={isSmDown ? undefined : 0.6}
                initalTranslate={isSmDown ? undefined : 'translate(30%, 50px)'}
              />
            )}
          </div>
        </div>
      </div>
    </EmailTemplateButtonProvider>
  );
};
