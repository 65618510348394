import { createContext } from 'react';

// TODO This context could (and should?) probably be reworked to be a part of
// the MaskContext instead, seeing as many masks likely need to pass some shared
// state to other UI components.
export const DocumentMaskContext = createContext<{
  type: 'file' | 'link' | 'template';
  addFiles(files: File[]): void;
  removeFile(id: string): void;
  setFiles(files: File[]): void;
}>(null);
