import { HttpClientProps, HttpClientResponse } from '../interfaces';
import { Method } from '../types';
import { prepareBody, prepareData, prepareHeaders, prepareUrl } from '../utils';

export class HttpClient {
  defaultHeaders: HeadersInit;

  constructor(defaultHeaders?: HeadersInit) {
    this.defaultHeaders = defaultHeaders ?? {};
  }

  public async get<TResponse = undefined, TParams = undefined>(
    data: HttpClientProps<never, TParams>
  ) {
    return await this.makeRequest<TResponse, never, TParams>('GET', data);
  }

  public async post<
    TResponse = undefined,
    TBody = undefined,
    TParams = undefined
  >(data: HttpClientProps<TBody, TParams>) {
    return await this.makeRequest<TResponse, TBody, TParams>('POST', data);
  }

  public async put<
    TResponse = undefined,
    TBody = undefined,
    TParams = undefined
  >(data: HttpClientProps<TBody, TParams>) {
    return await this.makeRequest<TResponse, TBody, TParams>('PUT', data);
  }

  private async makeRequest<
    TResponse = undefined,
    TBody = undefined,
    TParams = undefined
  >(
    method: Method,
    data: HttpClientProps<TBody, TParams>
  ): Promise<HttpClientResponse<TResponse>> {
    const {
      url,
      params,
      headers,
      responseType = 'JSON',
      body,
      contentType,
    } = data;

    const preparedURL = prepareUrl<TParams>({ url, params });
    const preparedBody = prepareBody<TBody>({ body });
    const preparedHeaders = prepareHeaders({
      defaultHeaders: { ...this.defaultHeaders, ...headers },
      contentType,
    });

    const response = await fetch(preparedURL, {
      method: method,
      body: preparedBody,
      headers: preparedHeaders,
    });

    const editedResponse = {
      status: response.status,
      data: await prepareData<TResponse>({ response, responseType }),
    };

    return response.ok ? editedResponse : Promise.reject(editedResponse);
  }
}

export const httpClient = new HttpClient();
