import styles from './MobileActionBar.module.scss';

import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import { Fab } from '@mui/material';
import { useState } from 'react';

import { User } from '@work4all/models/lib/Classes/User.entity';

import { AppointmentCalendarView, CalendarCardColorBy } from '../types';

import { CalendarUserIcon } from './CalendarUserIcon';
import { CalendarUsersDialog } from './CalendarUsersDialog';

export interface MobileActionBarProps {
  users: User[];
  onUsersChange: (users: User[]) => void;
  onUsersClear: () => void;
  focusedUserIds: number[];
  onFocusedUserIdsChange: (ids: number[]) => void;
  appointmentStates: number[];
  onAppointmentStatesChange: (states: number[]) => void;
  onCreateClick: () => void;
  views: AppointmentCalendarView[];
  activeView: AppointmentCalendarView | undefined;
  onSelectView: (view: AppointmentCalendarView) => void;
  onCreateView: () => void;
  onDeleteView: (view: AppointmentCalendarView) => void;
  calendarCardColorBy: CalendarCardColorBy;
  hideAddButton?: boolean;
  hideUserSelection?: boolean;
}

export function MobileActionBar(props: MobileActionBarProps) {
  const {
    users,
    onUsersChange,
    onUsersClear,
    focusedUserIds,
    onFocusedUserIdsChange,
    onCreateClick,
    views,
    activeView,
    onSelectView,
    onCreateView,
    onDeleteView,
    calendarCardColorBy,
    hideAddButton,
    hideUserSelection,
  } = props;

  const [usersDialogOpen, setUsersDialogOpen] = useState(false);

  return (
    <>
      <div className={styles.mobileToolbar}>
        {!hideUserSelection && (
          <div className={styles.inner}>
            <Fab size="medium" onClick={() => setUsersDialogOpen(true)}>
              {users.length === 0 ? (
                <PersonIcon />
              ) : (
                <CalendarUserIcon
                  userId={users[0].id}
                  size="xl"
                  type="user"
                  withBorder
                />
              )}
            </Fab>
            {users.length > 1 && (
              <Fab
                style={{ marginLeft: '-1rem' }}
                size="medium"
                color="secondary"
                onClick={() => setUsersDialogOpen(true)}
              >
                +{users.length - 1}
              </Fab>
            )}
          </div>
        )}

        {!hideAddButton && (
          <div className={styles.inner}>
            <Fab size="medium" color="primary" onClick={onCreateClick}>
              <AddIcon />
            </Fab>
          </div>
        )}
      </div>

      <CalendarUsersDialog
        open={usersDialogOpen}
        onClose={() => setUsersDialogOpen(false)}
        users={users}
        onUsersChange={onUsersChange}
        onUsersClear={onUsersClear}
        focusedUserIds={focusedUserIds}
        onFocusedUserIdsChange={onFocusedUserIdsChange}
        views={views}
        activeView={activeView}
        onSelectView={onSelectView}
        onCreateView={onCreateView}
        onDeleteView={onDeleteView}
        calendarCardColorBy={calendarCardColorBy}
      />
    </>
  );
}
