import styles from './TableNoRowsPlaceholder.module.scss';

import { useTranslation } from 'react-i18next';

import { useTableStateBag } from '@work4all/components';

export function TableNoRowsPlaceholder() {
  const { t } = useTranslation();

  const { tableState, tableInstance, searchFilterText } = useTableStateBag();

  const hasRegularFilters = tableState?.filters.length > 0;
  const hasSearchFilter = !!searchFilterText;

  const renderMessage = () => {
    const messageKey = hasRegularFilters
      ? hasSearchFilter
        ? 'LISTS_PAGES.NO_RESULTS.FILTERS_AND_SEARCH'
        : 'LISTS_PAGES.NO_RESULTS.FILTERS'
      : hasSearchFilter
      ? 'LISTS_PAGES.NO_RESULTS.SEARCH'
      : 'LISTS_PAGES.NO_RESULTS.DEFAULT';

    return <span className={styles.message}>{t(messageKey)}</span>;
  };

  const maybeRenderButton = () => {
    if (!hasRegularFilters) return null;

    return (
      <button
        className={styles.button}
        type="button"
        onClick={() => {
          tableInstance?.columns.forEach((c) => c.setFilter(null));
        }}
      >
        {t('LISTS_PAGES.NO_RESULTS.BUTTON')}
      </button>
    );
  };

  return (
    <div className={styles.root}>
      {renderMessage()} {maybeRenderButton()}
    </div>
  );
}
