import styles from './MobileNavigation.module.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@work4all/data/lib/hooks/useNavigation';

import { Button } from './components/button/Button';

export interface MobileNavigationItemProps {
  navigation: {
    title: string;
    pathname: string | string[];
  };
  icon?: React.ReactElement;
}

interface Props extends MobileNavigationItemProps {
  activeClassName: string;
  className?: string;
}

function guardString(input: string | string[]): input is string {
  return typeof input === 'string';
}

export const MobileNavigationItemBase: React.FC<Props> = (props: Props) => {
  const { navigation, icon, activeClassName, className } = props;
  const { t } = useTranslation();
  const { activePathname } = useNavigation();

  const pathnames = guardString(navigation.pathname)
    ? [navigation.pathname]
    : navigation.pathname;

  const active = navigation.pathname === activePathname;

  return (
    <Button
      key={pathnames[0]}
      className={active ? `${activeClassName} ${className}` : className}
      href={`${pathnames[0]}`}
      onClick={(e) => {
        e.currentTarget?.scrollIntoView();
      }}
    >
      {icon && <div>{icon}</div>}
      <span className={styles.title}>{t(`MOBILE.${navigation.title}`)}</span>
    </Button>
  );
};

export const MobileNavigationItem: React.FC<MobileNavigationItemProps> = (
  props: MobileNavigationItemProps
) => {
  return (
    <MobileNavigationItemBase
      {...props}
      activeClassName={styles['active']}
      className={styles['navigation__item']}
    />
  );
};
