import styles from '../appointmentview/AppointmentView.module.scss';

import { Notifications } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { Card } from '@work4all/components/lib/dataDisplay/card';
import { BaseActionButton } from '@work4all/components/lib/input/base-action-button/BaseActionButton';

import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import {
  CheckboxRadioItem,
  DateTimeInputPicker,
} from '../../../../../../../../locked-inputs';
import { TabPanelSectionProps } from '../../../../../../../types';
import { AppointmentMaskFormValue } from '../../../../../types';

const collapseClasses = {
  root: styles.collapseContainer,
  wrapperInner: styles.collapseWrapperInner,
};

export const Period: React.FC<TabPanelSectionProps> = () => {
  const { t } = useTranslation();

  const { control, register, setValue } =
    useFormContextPlus<AppointmentMaskFormValue>();
  const isWholeDay = useWatch({ control, name: 'isWholeDay' });
  const remind = useWatch({ control, name: 'remind' });

  return (
    <Collapse
      classes={collapseClasses}
      title={t('INPUTS.PERIOD')}
      className={styles.collapse}
      defaultOpen={true}
    >
      <Card>
        <Grid container spacing="1rem">
          <Grid item xs={12} md={6} lg={3}>
            <DateTimeInputPicker
              dateLabel={t('COMMON.STARTDATE')}
              withTime={!isWholeDay}
              clearable={false}
              {...register('startDate')}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DateTimeInputPicker
              dateLabel={t('COMMON.ENDDATE')}
              withTime={!isWholeDay}
              clearable={false}
              {...register('endDate')}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={2}>
            <CheckboxRadioItem
              label={t('COMMON.WHOLEDAYEVENT')}
              defaultChecked={isWholeDay}
              {...register('isWholeDay')}
            />
          </Grid>
          <Grid item xs={remind ? 12 : 6} md={6} lg={4}>
            {remind ? (
              <DateTimeInputPicker
                dateLabel={t('COMMON.REMINDER')}
                timeLabel={t('COMMON.TIME')}
                withTime={true}
                clearable={true}
                {...register('remindDate')}
                onChange={(e) => {
                  if (!e.currentTarget.value) {
                    setValue('remind', false, { shouldDirty: true });
                  }
                  register('remindDate').onChange(e);
                }}
              />
            ) : (
              <BaseActionButton
                onClick={() => setValue('remind', true, { shouldDirty: true })}
                icon={<Notifications />}
              >
                {t('COMMON.ADD_REMINDER')}
              </BaseActionButton>
            )}
          </Grid>
        </Grid>
      </Card>
    </Collapse>
  );
};
