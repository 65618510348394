import styles from './CustomDragLayer.module.scss';

import React, { useMemo } from 'react';
import { useDragLayer, XYCoord } from 'react-dnd';

import { DndTypes } from '@work4all/utils/lib/variables';

import {
  FavoriteLinkDragObject,
  FavoriteLinksFolderDragObject,
  LinkDragObject,
  LinksFolderDragObject,
} from '../../../dnd/drag-objects';

import { DragLayerProvider } from './DragLayerProvider';
import {
  ImprintPreview,
  isImprintRouteAvailable,
} from './previews/imprint/ImprintPreview';
import { LinkPreview } from './previews/link/LinkPreview';
import { WidgetPreview } from './previews/widget/WidgetPreview';

function renderPreview(
  type: string | symbol,
  item: unknown
): React.ReactNode | null {
  switch (type) {
    case DndTypes.LINK:
    case DndTypes.LINKS_FOLDER:
    case DndTypes.FAVORITE_LINK:
    case DndTypes.FAVORITE_LINKS_FOLDER: {
      const linkItem = item as
        | LinkDragObject
        | LinksFolderDragObject
        | FavoriteLinkDragObject
        | FavoriteLinksFolderDragObject;

      return <LinkPreview>{linkItem.text}</LinkPreview>;
    }
    case DndTypes.HTML:
      if (isImprintRouteAvailable()) return <ImprintPreview />;
      return null;

    case DndTypes.FILE_CARD_WIDGET:
      return <WidgetPreview item={item} />;
    default:
      return null;
  }
}

function getItemWrapperStyle({
  offset,
}: {
  offset: XYCoord | null;
}): React.CSSProperties {
  if (!offset) {
    return {};
  }

  return { left: offset.x, top: offset.y };
}

export function CustomDragLayer() {
  const { type, item, isDragging, offset } = useDragLayer((monitor) => ({
    type: monitor.getItemType(),
    item: monitor.getItem(),
    isDragging: monitor.isDragging(),
    offset: monitor.getClientOffset(),
  }));

  const preview = useMemo(() => renderPreview(type, item), [type, item]);

  const renderContent = () => {
    if (!isDragging || !preview || !offset) {
      return undefined;
    }

    return (
      <div className={styles.layer}>
        <div
          className={styles.itemWrapper}
          style={getItemWrapperStyle({ offset })}
        >
          {preview}
        </div>
      </div>
    );
  };
  return <DragLayerProvider>{renderContent()}</DragLayerProvider>;
}
