import { gql, useQuery } from '@apollo/client';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IResponse } from '@work4all/data';

import { Currency } from '@work4all/models/lib/Classes/Currency.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { formatCurrency } from '@work4all/utils';

import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

const GetCurrencies = gql`
  query GetCurrencies {
    getCurrencies: getWaehrungen {
      id: code
      name: name
    }
  }
`;

interface GetCurrenciesResponse {
  getCurrencies: Currency[];
}

export type ICurrencyPickerProps<TMultiple extends boolean> = IPickerProps<
  Currency,
  TMultiple
>;

export function CurrencyPicker<TMultiple extends boolean>(
  props: ICurrencyPickerProps<TMultiple>
) {
  const { data: dataProp, ...rest } = props;

  const { t } = useTranslation();

  const { data, loading } = useQuery<GetCurrenciesResponse>(GetCurrencies);

  const currencies = data?.getCurrencies ?? [];

  const fixedDataSet: IResponse<Currency> = {
    data: currencies,
    loading,
    pending: loading,
    total: currencies.length,
    fetchMore: () => Promise.resolve(),
    refetch: () => Promise.resolve(null),
  };

  const renderItem = (currency: Currency) => {
    return (
      <Typography variant="body2" noWrap>
        {formatCurrency(currency, t)}
      </Typography>
    );
  };

  return (
    <ListEntityPicker
      entity={Entities.currency}
      data={CURRENCY_FIELDS}
      filterBy={'name'}
      sortBy="name"
      renderItemContent={renderItem}
      fixedDataSet={fixedDataSet}
      {...rest}
    />
  );
}
const CURRENCY_FIELDS: Currency = { id: null, name: null };
