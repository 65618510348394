import styles from './PositionsTable.module.scss';

import InsertPageBreakIcon from '@mui/icons-material/InsertPageBreak';
import { Box, LinearProgress } from '@mui/material';
import { Tooltip } from '@mui/material';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Column, TableInstance } from 'react-table';

import {
  ColumnAdditionalData,
  CurrencyCell,
  DateCell,
  HoursCell,
  NumberCell,
} from '@work4all/components';
import { getTextHeight } from '@work4all/components/lib/dataDisplay/basic-table/utils/get-text-height/get-text-height';

import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { Position } from '@work4all/models/lib/Classes/Position.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { formatCurrency, formatNumberAsCurrency } from '@work4all/utils';

import { ArticlePickerField } from '../../../../../../../../../../components/entity-picker/ArticlePickerField';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { CostCenterCell } from '../../../../../../components/table-cells/CostCenterCell';
import { LedgerAccountCell } from '../../../../../../components/table-cells/LedgerAccountCell';
import { ErpData } from '../../../../../ErpData';
import {
  ERP_DATE_CELLS,
  ERP_NUMBER_CELLS,
  ERP_TEXT_CELLS,
  ERP_TIME_CELLS,
} from '../../../../../hooks/use-bz-shadow-object-api';
import { useShadowBzObjectApiContext } from '../../../../../hooks/use-bz-shadow-object-api/use-shadow-bz-object-api-context';
import { ARTICLE_POSITIONS_DATA } from '../constants';
import { EditTable, EditTableProps } from '../edit-table/EditTable';
import { OnEditPosition } from '../edit-table/types';
import { PositionMask } from '../position-mask/PositionMask';

export const ForbiddenPriceCell = () => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('ALERTS.NOT_AUTHORIZED_TO_SEE_PRICES')}>
      <Box textAlign="center">-</Box>
    </Tooltip>
  );
};

const TABLE_ROW_HEIGHT = 3;
const NumberCellWithFraction = (cellProps) => (
  <NumberCell {...cellProps} maximumFractionDigits={2} />
);

// TODO: check if need to have different sizes for edit Row and not edit row
const itemSize = (pos: Position) => {
  if (pos.positionKind === ErpPositionsKind.SEITENUMBRUCH) {
    return remToPx(TABLE_ROW_HEIGHT);
  }
  return getTextHeight({ text: pos?.longtext }) + remToPx(0.5);
};

export type IPositionsTableProps = {
  disabled?: boolean;
  showPositionMask?: boolean;
  positions: Position[];
  hideMask?: boolean;
  onSelectedPositionIdsChange: (selectedPositionIds: number[]) => void;
  onMovePosition: (id: number, index: number, finish?: boolean) => void;
  onPickPosition?: (article?: Article) => void;
  onAddPosition?: (props: {
    positionType: ErpPositionsKind;
    article?: Article;
  }) => void;
  onRemovePosition: (positionId: number[]) => void;
  onEditPosition: (result: OnEditPosition<Position>) => void;
  onCollapsePosition: (result: Position) => void;
  tableInstanceRef: React.RefObject<TableInstance>;
  total: number;
  allowedColumns?: string[];
  bordersHorizontal?: boolean;
  showArticleSelectionSmall?: boolean;
} & Pick<EditTableProps<Position>, 'refs'>;

const editableRows = {
  TITEL: ['longtext'],
  TITELSUMME: ['longtext'],
  ZWISCHENSUMME: ['longtext'],
  TEXTZEILE: ['longtext'],
};

type ColumnData = Column<Position> & ColumnAdditionalData;

export const PositionsTable = (props: IPositionsTableProps) => {
  const {
    disabled = false,
    positions,
    total,
    onEditPosition,
    allowedColumns,
    onAddPosition,
    onCollapsePosition,
    showPositionMask = true,
    bordersHorizontal = true,
    showArticleSelectionSmall = true,
    hideMask,
  } = props;

  const { t } = useTranslation();
  const { pending, loading } = useShadowBzObjectApiContext();

  const mask = useMaskContext();
  const hidePrices = mask.entity === Entities.deliveryNote;

  const longtextFooter = useCallback(() => {
    return (
      <ArticlePickerField
        value={undefined}
        data={ARTICLE_POSITIONS_DATA}
        showFunctions
        label={t('ERP.ADD_POSITION')}
        onChange={(value) => {
          if (!value) {
            return;
          }
          if ((value as ErpPositionsKind) in ErpPositionsKind) {
            onAddPosition({ positionType: value as ErpPositionsKind });
          } else {
            onAddPosition({
              positionType: ErpPositionsKind.STANDARD,
              article: value as Article,
            });
          }
        }}
      />
    );
  }, [onAddPosition, t]);

  const columns = useMemo(() => {
    const baseColumns: ColumnData[] = [
      {
        Header: t('COMMON.ERP.NUMBER'),
        accessor: 'number',
        width: remToPx(5.7),
        sticky: 'left',
      },
      {
        Header: t('COMMON.ERP.DESCRIPTION'),
        accessor: 'longtext',
        width: remToPx(27),
        Footer: showArticleSelectionSmall ? longtextFooter : <div></div>,
      },
      {
        Header: t('COMMON.ERP.AMOUNT'),
        accessor: 'amount',
        width: remToPx(5),
        Cell: NumberCellWithFraction,
      },
      {
        Header: t('COMMON.ERP.UNIT'),
        accessor: 'unit',
        width: remToPx(4.5),
      },
    ];

    const pricingColumns: ColumnData[] = [
      {
        Header: t('COMMON.ERP.PRICE'),
        accessor: 'singlePriceNet',
        Cell: hidePrices ? ForbiddenPriceCell : CurrencyCell,
        width: remToPx(7),
      },
      {
        Header: t('COMMON.ERP.TOTAL_PRICE'),
        accessor: 'totalPriceNet',
        Cell: hidePrices ? ForbiddenPriceCell : CurrencyCell,
        width: remToPx(7),
        Footer: () => {
          return (
            <span>
              {hidePrices
                ? ForbiddenPriceCell
                : CurrencyCell({
                    value: total,
                  })}
            </span>
          );
        },
      },
      {
        Header: t('COMMON.ERP.ORDER_AMOUNT'),
        accessor: 'orderAmount',
        width: remToPx(7),
        Cell: NumberCellWithFraction,
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
      },
      {
        Header: t('COMMON.ERP.DISCOUNT'),
        accessor: 'discount',
        width: remToPx(7),
        Cell: hidePrices ? ForbiddenPriceCell : NumberCellWithFraction,
      },
      {
        Header: t('COMMON.ERP.INSTEAD_OF_PRICE'),
        accessor: 'insteadOfTotalPrice',
        width: remToPx(14),
      },
      {
        Header: t('COMMON.ERP.PURCHASE_PRICE'),
        accessor: 'purchasePrice',
        width: remToPx(7),
        Cell: hidePrices ? ForbiddenPriceCell : CurrencyCell,
      },
      {
        Header: t('COMMON.ERP.VAT'),
        accessor: 'vat',
        width: remToPx(7),
        Cell: NumberCellWithFraction,
        filterSubgroupPath: ['COMMON.ERP.CALCULATION'],
      },
      {
        Header: t('COMMON.ERP.PURCHASE_PRICE_SURCHARGE'),
        accessor: 'purchasePriceSurcharge',
        width: remToPx(7),
        Cell: NumberCellWithFraction,
        filterSubgroupPath: ['COMMON.ERP.CALCULATION'],
      },
      {
        Header: t('COMMON.ERP.SINGLE_PRICE_GROSS'),
        accessor: 'singlePriceGross',
        width: remToPx(7),
        Cell: NumberCellWithFraction,
        filterSubgroupPath: ['COMMON.ERP.CALCULATION'],
      },
      {
        Header: t('COMMON.ERP.TOTAL_PRICE_GROSS'),
        accessor: 'totalPriceGross',
        width: remToPx(7),
        Cell: NumberCellWithFraction,
        filterSubgroupPath: ['COMMON.ERP.CALCULATION'],
      },
      {
        Header: t('COMMON.ERP.MINUTE_PRICE'),
        accessor: 'minutePrice',
        width: remToPx(7),
        Cell: NumberCellWithFraction,
        filterSubgroupPath: ['COMMON.ERP.CALCULATION'],
      },
    ];

    const additionalColumns: ColumnData[] = [
      {
        Header: t('COMMON.ERP.SHORT_TEXT'),
        accessor: 'shortText',
        width: remToPx(25),
      },
      {
        Header: t('COMMON.ERP.INTERNAL_TEXT'),
        accessor: 'internalText',
        width: remToPx(25),
      },
      {
        Header: t('COMMON.ERP.LEDGER_ACCOUNT'),
        accessor: 'ledgerAccountNumber',
        width: remToPx(7),
        Cell: (item) => item.value || '',
        filterSubgroupPath: ['COMMON.ERP.BOOKING'],
      },
      {
        Header: t('COMMON.ERP.COST_CENTER'),
        accessor: 'costCenter1Number',
        width: remToPx(7),
        Cell: (item) => {
          return item.value || '';
        },
        filterSubgroupPath: ['COMMON.ERP.BOOKING'],
      },
      {
        Header: t('COMMON.ERP.MEASUREMENT'),
        accessor: 'measurement',
        width: remToPx(7),
        filterSubgroupPath: ['COMMON.DIMENSIONS'],
      },
      {
        Header: t('COMMON.ERP.WIDTH'),
        accessor: 'width',
        width: remToPx(7),
        Cell: NumberCellWithFraction,
        filterSubgroupPath: ['COMMON.DIMENSIONS'],
      },
      {
        Header: t('COMMON.ERP.WEIGHT_TOTAL'),
        accessor: 'weight',
        width: remToPx(7),
        Cell: NumberCellWithFraction,
        filterSubgroupPath: ['COMMON.DIMENSIONS'],
      },
      {
        Header: t('COMMON.ERP.VOLUME'),
        accessor: 'volume',
        width: remToPx(7),
        Cell: NumberCellWithFraction,
        filterSubgroupPath: ['COMMON.DIMENSIONS'],
      },
      {
        Header: t('COMMON.ERP.VOLUME_TOTAL'),
        accessor: 'totalVolume',
        width: remToPx(7),
        Cell: NumberCellWithFraction,
        filterSubgroupPath: ['COMMON.DIMENSIONS'],
      },
      {
        Header: t('COMMON.ERP.WEIGHT_SINGLE'),
        accessor: 'singleWeight',
        width: remToPx(7),
        Cell: NumberCellWithFraction,
        filterSubgroupPath: ['COMMON.DIMENSIONS'],
      },
      {
        Header: t('COMMON.ERP.LENGTH'),
        accessor: 'length',
        width: remToPx(7),
        Cell: NumberCellWithFraction,
        filterSubgroupPath: ['COMMON.DIMENSIONS'],
      },
      {
        Header: t('COMMON.ERP.AMOUNT_CARTON'),
        accessor: 'amountCarton',
        width: remToPx(7),
        Cell: NumberCellWithFraction,
        filterSubgroupPath: ['COMMON.DIMENSIONS'],
      },
      {
        Header: t('COMMON.ERP.AMOUNT_PALETTES'),
        accessor: 'amoundPalettes',
        width: remToPx(7),
        Cell: NumberCellWithFraction,
        filterSubgroupPath: ['COMMON.DIMENSIONS'],
      },
      {
        Header: t('COMMON.ERP.AMOUNT_VE'),
        accessor: 'amountVe',
        width: remToPx(7),
        Cell: NumberCellWithFraction,
        filterSubgroupPath: ['COMMON.DIMENSIONS'],
      },
      {
        Header: t('COMMON.ERP.FACTOR'),
        accessor: 'factor',
        width: remToPx(7),
        Cell: NumberCellWithFraction,
        filterSubgroupPath: ['COMMON.ERP.CALCULATION'],
      },
      {
        Header: t('COMMON.ERP.START_TIME'),
        accessor: 'startTime',
        width: remToPx(7),
        Cell: HoursCell,
        filterSubgroupPath: ['COMMON.ERP.CALCULATION'],
      },
      {
        Header: t('COMMON.ERP.END_TIME'),
        accessor: 'endTime',
        width: remToPx(7),
        Cell: HoursCell,
        filterSubgroupPath: ['COMMON.ERP.CALCULATION'],
      },
      {
        Header: t('COMMON.ERP.ARTICLE_NUMBER'),
        accessor: 'articleNumber',
        width: remToPx(7),
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
      },
      {
        Header: t('COMMON.ERP.DISPO_START'),
        accessor: 'dispositionStart',
        width: remToPx(7),
        Cell: DateCell,
      },
      {
        Header: t('COMMON.ERP.DISPO_END'),
        accessor: 'dispositionEnd',
        width: remToPx(7),
        Cell: DateCell,
      },
      {
        Header: t('COMMON.ERP.OWN_ARTICLE_NUMBER'),
        accessor: 'ownArticleNumber',
        width: remToPx(7),
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
      },
      {
        Header: t('COMMON.ERP.SCHEDULED_DELIVERY_DATE'),
        accessor: 'deliveryDate',
        width: remToPx(7),
        Cell: DateCell,
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
      },
      {
        Header: t('COMMON.ERP.ACTUAL_DELIVERY_DATE'),
        accessor: 'actualDeliveryDate',
        width: remToPx(7),
        Cell: DateCell,
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
      },
      {
        Header: t('COMMON.ERP.MANUFACTURER_NUMBER'),
        accessor: 'manufacturerNumber',
        width: remToPx(7),
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
      },
      {
        Header: t('COMMON.ERP.PROCEDURE'),
        accessor: 'process',
        width: remToPx(7),
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
      },
      {
        Header: t('COMMON.SUPPLIER'),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        accessor: 'supplier.name' as any,
        width: remToPx(10),
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
      },
      {
        Header: t('COMMON.RESPONSIBLE'),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        accessor: 'responsibleUser.displayName' as any,
        width: remToPx(10),
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
      },
    ];

    return [
      ...baseColumns,
      ...(!hidePrices ? pricingColumns : []),
      ...additionalColumns,
    ].filter((x) => {
      return allowedColumns
        ? allowedColumns.includes(x.accessor as string)
        : true;
    });
  }, [
    t,
    hidePrices,
    longtextFooter,
    total,
    allowedColumns,
    showArticleSelectionSmall,
  ]);

  const { watch } = useFormContextPlus<ErpData>();
  const currency = watch('currency');

  const renderMobilePosition = useCallback(
    (position: Position, setEditedPosition: (position: Position) => void) => {
      const key = position.id;

      switch (position.positionKind) {
        case ErpPositionsKind.SEITENUMBRUCH:
          return (
            <tr key={key}>
              <td colSpan={2} className={styles['tr-border']}>
                <div
                  className={styles['page-break']}
                  onClick={() => {
                    setEditedPosition(position);
                  }}
                  style={{ width: 'fit-content' }}
                >
                  <InsertPageBreakIcon />
                  <div>{t('COMMON.PAGE_BREAK')}</div>
                </div>
              </td>
            </tr>
          );
        case ErpPositionsKind.TITEL:
        case ErpPositionsKind.TITELSUMME:
        case ErpPositionsKind.ZWISCHENSUMME:
          return (
            <tr
              key={key}
              onClick={() => {
                setEditedPosition(position);
              }}
              className={styles.title}
            >
              <td>{position.number}</td>
              <td style={{ width: '100%' }}>
                {position.positionKind !== ErpPositionsKind.TITEL &&
                  !hidePrices && (
                    <span style={{ float: 'right' }}>
                      {formatNumberAsCurrency(position.totalPriceNet)}{' '}
                      {formatCurrency(currency, t)}
                    </span>
                  )}
                <div style={{ whiteSpace: 'pre-line' }}>
                  {position.longtext}
                </div>
              </td>
            </tr>
          );

        default:
          return (
            <tr
              key={key}
              onClick={() => {
                setEditedPosition(position);
              }}
            >
              <td>{position.number}</td>
              <td style={{ width: '100%' }}>
                <div style={{ whiteSpace: 'pre-line' }}>
                  {position.longtext}
                </div>

                <div className={styles.subline}>
                  <div className={styles.left}>
                    <span>
                      {position.amount} {position.unit || 'x'}
                    </span>
                    {!hidePrices && (
                      <span
                        className={clsx({
                          [styles.strikethrough]: position.insteadOfTotalPrice,
                        })}
                      >
                        {formatNumberAsCurrency(position.singlePriceNet)}{' '}
                        {formatCurrency(currency, t)}
                      </span>
                    )}
                    {!hidePrices && position.discount > 0 && (
                      <span>{position.discount} %</span>
                    )}
                  </div>
                  {!hidePrices && (
                    <div className={styles.right}>
                      {position.insteadOfTotalPrice ? (
                        position.insteadOfTotalPrice
                      ) : (
                        <>
                          {formatNumberAsCurrency(position.totalPriceNet)}{' '}
                          {formatCurrency(currency, t)}
                        </>
                      )}
                    </div>
                  )}
                </div>
              </td>
            </tr>
          );
      }
    },
    [currency, hidePrices, t]
  );

  const cellDefinition = useMemo(() => {
    const defs: EditTableProps<Position>['cellDefinition'] = {};

    const skeletonCells = ['totalPriceGross', 'totalPriceNet'];

    skeletonCells.forEach((x) => {
      defs[x] = {
        type: 'number',
        editable: false,
        skeleton: true,
      };
    });

    ERP_DATE_CELLS.forEach((x) => {
      defs[x] = {
        type: 'date',
      };
    });
    ERP_TIME_CELLS.forEach((x) => {
      defs[x] = {
        type: 'time',
      };
    });
    ERP_TEXT_CELLS.forEach((x) => {
      defs[x] = {
        type: 'text',
      };
    });
    ERP_NUMBER_CELLS.forEach((x) => {
      defs[x] = {
        type: 'number',
      };
    });
    defs.ledgerAccountNumber = {
      type: 'picker',
      render: (cell: Cell<Position>) => (
        <LedgerAccountCell
          label={(value) => value?.number || ''}
          value={cell?.row?.original?.ledgerAccount}
          className={styles.customCell}
          onChange={(value) => {
            onEditPosition({
              position: {
                id: cell.row.original.id,
                [cell.column.id]: value?.number || 0,
              },
            });
          }}
          hover
          disabled={false}
        />
      ),
    };

    defs.costCenter1Number = {
      type: 'picker',
      render: (cell: Cell<Position>) => (
        <CostCenterCell
          label={(value) => value?.number || ''}
          value={cell?.row?.original?.costCenter1}
          className={styles.customCell}
          onChange={(value) => {
            onEditPosition({
              position: {
                id: cell.row.original.id,
                [cell.column.id]: value?.number || 0,
              },
            });
          }}
          hover
          disabled={false}
        />
      ),
    };
    return defs;
  }, [onEditPosition]);

  const classes = useMemo(
    () => ({
      mobile: styles.mobilePositionsTable,
    }),
    []
  );

  const loadingRows = useMemo(
    () =>
      (pending.type === 'modifyPosition' &&
        ['singlePriceNet', 'amount', 'discount'].some((x) =>
          pending.properties.includes(x)
        )) ||
      pending.type === 'movePosition'
        ? [pending.positionId]
        : [],
    [pending]
  );

  const decorators = useMemo(() => {
    const getRelation = (position: Position) => {
      const idx = positions.findIndex((p) => p.id === position.id);
      const next = positions[idx + 1];

      if (position.posId) return 'child';
      if (
        next?.posId ||
        position.positionKind === ErpPositionsKind.STUECKLISTE ||
        ('collapsed' in position && position.collapsed)
      )
        return 'parent';
      return 'normal';
    };

    const isEditableCell = (cell: string) => {
      return cell !== 'singlePriceNet';
    };

    return { getRelation, isEditableCell };
  }, [positions]);

  return !loading ? (
    // <Box display="flex" flex="1" flexDirection="column" width="100%">

    <Box display="flex" flex="1" flexDirection="column" overflow="hidden">
      {pending.pending && <LinearProgress sx={{ width: '100%' }} />}
      <EditTable<Position>
        hideMask={hideMask}
        columns={columns}
        onEditPosition={onEditPosition}
        onCollapsePosition={onCollapsePosition}
        editableRows={editableRows}
        cellDefinition={cellDefinition}
        disabled={disabled}
        items={positions}
        renderMobileItem={renderMobilePosition}
        tableInstanceRef={props.tableInstanceRef}
        onPick={props.onPickPosition}
        onMove={props.onMovePosition}
        onRemove={props.onRemovePosition}
        onSelectedItemIdsChange={props.onSelectedPositionIdsChange}
        mask={showPositionMask ? PositionMask : undefined}
        classes={classes}
        refs={props.refs}
        loadingRows={loadingRows}
        itemSize={itemSize}
        decorators={decorators}
        bordersHorizontal={bordersHorizontal}
        showSelectionColumn={false}
      />
    </Box>
  ) : null;
};
