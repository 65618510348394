import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { EntityChangedInfo } from './EntityChangedInfo.entity';

export class Work4allSubscription<T extends EMode = EMode.entity> {
  /** Alias: entityChangend */
  entityChangend?: EntityChangedInfo<T>;

  __typename?: string;
}

const fields: FieldDefinitions<Work4allSubscription> = {
  entityChangend: {
    alias: 'entityChangend',
    entity: Entities.entityChangedInfo,
  },
  __typename: {
    alias: '__typename',
  },
};

export const work4allSubscriptionEntityDefinition: EntitiyDefinition<Work4allSubscription> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'Work4allSubscription',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
