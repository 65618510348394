import { gql, useQuery } from '@apollo/client';
import { useMemo, useState } from 'react';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Body1 } from '../../../typography/body1/Body1';
import { ListEntityPicker } from '../components/list-entity-picker';

export interface PostalCodePickerProps {
  value: PostalCodeWrap | null;
  onChange: (value: PostalCodeWrap) => void;
}

export interface PostalCodeWrap {
  id: string;
  postalCode: string;
}

export function PostalCodePicker(props: PostalCodePickerProps) {
  const { value, onChange } = props;

  const [searchTerm, setSearchTerm] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let searchTimeout: any = null;

  const response = usePostalCodes({
    postalCodeFilter: searchTerm.length > 0 ? `${searchTerm}%` : undefined,
    queryPage: 0,
    querySize: 100,
  });

  const dataset = useMemo(() => {
    return {
      total: response?.data?.getPostalCodes?.data.length || 0,
      loading: false,
      fetchMore: () => Promise.resolve(),
      refetch: () => Promise.resolve(null),
      data:
        response?.data?.getPostalCodes?.data.map((x) => ({
          id: x,
          postalCode: x,
        })) || [],
    };
  }, [response]);

  return (
    <ListEntityPicker
      entity={Entities.customer}
      data={{ id: null, postalCode: null }}
      filterBy={['postalCode']}
      sortBy="postalCode"
      fixedDataSet={dataset}
      onSearchValueChange={(searchValue) => {
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => setSearchTerm(searchValue), 300);
      }}
      renderItemContent={(item) => {
        return <Body1>{item.postalCode}</Body1>;
      }}
      multiple={false}
      value={value}
      onChange={(result) => {
        onChange(result);
      }}
    />
  );
}

const GET_POSTAL_CODES = gql`
  query GetPostalCodes(
    $postalCodeFilter: String
    $querySize: Int
    $queryPage: Int
  ) {
    getPostalCodes(
      postalCodeFilter: $postalCodeFilter
      querySize: $querySize
      queryPage: $queryPage
    ) {
      total
      data
    }
  }
`;

function usePostalCodes(variables: {
  postalCodeFilter?: string;
  querySize?: number;
  queryPage?: number;
}) {
  return useQuery(GET_POSTAL_CODES, { variables });
}
