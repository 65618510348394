import { useEffect } from 'react';
import { Observable } from 'rxjs';

import type { ReplicationEvent } from '@work4all/data/lib/entities/utils/replication.types';

import { ReplicationStateContextProvider } from './replication-state-context';
import { useReplicationStateReducer } from './use-replication-state-reducer';

export interface ReplicationStateProviderProps {
  events: Observable<ReplicationEvent>;
  children?: React.ReactNode;
}

export function ReplicationStateProvider(props: ReplicationStateProviderProps) {
  const { events, children } = props;

  const [state, dispatch] = useReplicationStateReducer();

  useEffect(() => {
    const subscription = events.subscribe({
      next(event) {
        dispatch({ type: 'event', event });
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [events, dispatch]);

  return (
    <ReplicationStateContextProvider value={state}>
      {children}
    </ReplicationStateContextProvider>
  );
}
