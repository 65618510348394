import { useMemo } from 'react';

import { useTenant } from '../hooks/routing/TenantProvider';
import { useUser } from '../hooks/useUser';
import { httpClient } from '../http-client';

export const blobToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export const getBlobAuthed = async (
  url: string,
  tokenOrHeaders: string | Record<string, string>,
  tenant?: number
) => {
  return await httpClient.get<BlobPart>({
    url: url,
    responseType: 'blob',
    headers:
      typeof tokenOrHeaders === 'string'
        ? {
            Authorization: `bearer ${tokenOrHeaders}`,
            mandantenCode: `${tenant}`,
          }
        : tokenOrHeaders,
  });
};

export const getObjectUrlAuthed = async (
  url: string,
  tokenOrHeaders: string | Record<string, string>,
  tenant?: number
) => {
  const response = await getBlobAuthed(url, tokenOrHeaders, tenant);
  return window.URL.createObjectURL(new Blob([response.data]));
};

export const downloadAuthed = async (
  url: string,
  fileName: string,
  tokenOrHeaders: string | Record<string, string>,
  tenant?: number
) => {
  const objectUrl = await getObjectUrlAuthed(url, tokenOrHeaders, tenant);
  const link = document.createElement('a');
  link.href = objectUrl;
  link.setAttribute('download', fileName);
  link.setAttribute('id', 'temp-dl-inject');
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const useDeafultPreviewHeaders = () => {
  const { activeTenant: tenant } = useTenant();
  const user = useUser();
  const bearerToken = user.token;
  return useMemo(
    () => ({
      Authorization: `bearer ${bearerToken}`,
      mandantenCode: tenant.toString(),
    }),
    [bearerToken, tenant]
  );
};
