import React from 'react';
import { useTranslation } from 'react-i18next';

import { OkAlert } from '../alert/OkAlert';
import {
  AlertState,
  IInstructionsAlertProps,
  InstructionsAlert,
} from '../instructions-alert/InstructionsAlert';

export type ClipboardAlertState = AlertState | 'EMPTY';

interface IProps extends Omit<IInstructionsAlertProps, 'state'> {
  state?: ClipboardAlertState;
}

export const ClipboardAlert: React.FC<IProps> = (props) => {
  const { state } = props;
  const { t } = useTranslation();

  if (state === 'EMPTY')
    return (
      <OkAlert
        {...props}
        header={t('ALERTS.PERMISSION_READ_FAILED')}
        body={t('ALERTS.CLIPBOARD_EMPTY')}
      />
    );

  return <InstructionsAlert {...(props as IInstructionsAlertProps)} />;
};
