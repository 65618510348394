import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputEMailVorlagenAnhangAdd } from './InputEMailVorlagenAnhangAdd.entity';

export class InputEMailVorlagenAnhangRelation<T extends EMode = EMode.entity> {
  /** Alias: add */
  add?: InputEMailVorlagenAnhangAdd<T>[];
  /** Alias: remove */
  remove?: (string | number)[];

  __typename?: string;
}

const fields: FieldDefinitions<InputEMailVorlagenAnhangRelation> = {
  add: {
    alias: 'add',
  },
  remove: {
    alias: 'remove',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputEMailVorlagenAnhangRelationEntityDefinition: EntitiyDefinition<InputEMailVorlagenAnhangRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputEMailVorlagenAnhangRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
