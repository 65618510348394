import styles from './statistics-styles.module.scss';

import { Theme, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { useMemo, useRef } from 'react';
import { TableInstance } from 'react-table';

import { ManagementDashboard } from '@work4all/models/lib/Classes/ManagementDashboard.entity';

import { BasicTable } from '../BasicTable';
import { BasicTableColumn } from '../types';
import { CurrencyCell } from '../utils';

import { getStatisticFormatedDate } from './getStatisticFormatedDate';

interface StatisticCompanyReportTableProps {
  data: ManagementDashboard;
}

interface MonthDefinition {
  value: number;
  month: number;
  year: number;
}

interface StatisticItemRemodeled {
  indicator: string;
  months: MonthDefinition[];
}

export const StatisticCompanyReportTable: React.FC<
  StatisticCompanyReportTableProps
> = (props) => {
  const { data } = props;

  const isViewportDownMd = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  const newData: StatisticItemRemodeled[] = useMemo(() => {
    const result: StatisticItemRemodeled[] = [];

    if (data === undefined) {
      return [];
    }

    const GetMonthValues = (months: MonthDefinition[], indicator: string) => {
      const result: MonthDefinition[] = [];
      let value: number;

      months.forEach((month) => {
        value = 0;
        for (const k in data) {
          if (
            data[k].year === month.year &&
            data[k].month === month.month &&
            data[k].figure === indicator
          ) {
            value += data[k].value;
          }
        }
        const newMonth: MonthDefinition = {
          year: month.year,
          month: month.month,
          value: value,
        };
        result.push(newMonth);
      });

      return result;
    };

    let indicatorList: string[] = [];
    for (const k in data) {
      indicatorList.push(data[k].figure);
    }
    indicatorList = [...new Set(indicatorList)];

    const timePeriod: MonthDefinition[] = [];
    for (const k in data) {
      const newMonth: MonthDefinition = {
        year: data[k].year,
        month: data[k].month,
        value: 0,
      };
      if (
        timePeriod.filter(
          (e) => e.year === newMonth.year && e.month === newMonth.month
        ).length === 0
      ) {
        timePeriod.push(newMonth);
      }
    }

    indicatorList.forEach((i) => {
      const newStatisticItemRemodeled: StatisticItemRemodeled = {
        indicator: i,
        months: GetMonthValues(timePeriod, i),
      };
      result.push(newStatisticItemRemodeled);
    });

    return result;
  }, [data]);

  const columns = useMemo<BasicTableColumn[]>(() => {
    const defaultProperties: BasicTableColumn = {
      accessor: '',
      width: 100,
      Cell: ({ value }: { value: number }) => {
        if (value === 0) {
          return '';
        }
        return (
          <CurrencyCell
            className={clsx({ [styles.red]: value < 0 })}
            value={value}
            maximumFractionDigits={0}
            minimumFractionDigits={0}
            hideUnit={true}
          />
        );
      },
      alignment: 'right',
    };

    let result: BasicTableColumn[] = [
      {
        Header: 'MANAGMENT',
        accessor: 'indicator',
        sticky: 'left',
        width: isViewportDownMd ? 150 : 300,
      },
    ];

    if (newData[0] !== undefined) {
      result = result.concat(
        newData?.[0].months.map((month, monthIndex) => {
          return {
            ...defaultProperties,
            Header: getStatisticFormatedDate(
              new Date(month.year, month.month - 1),
              'MMM yyyy'
            ),
            accessor: `months[${monthIndex}].value`,
          };
        })
      );
    }

    return result;
  }, [newData, isViewportDownMd]);

  const apiRef = useRef<TableInstance>(null);

  return (
    <BasicTable
      displayFooter={false}
      mode="client"
      ref={apiRef}
      reordableColumns={true}
      resizableColumns={false}
      selectableRows={true}
      data={newData}
      columns={columns}
      allItemsCount={newData.length || 0}
      cardsView={false}
      selectableMultiple={false}
      className={styles.table}
    />
  );
};
