import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class StatisticCompanyReportFigures<T extends EMode = EMode.entity> {
  /** Alias: figure */
  figure?: string;
  /** Alias: value */
  value?: number;

  __typename?: string;
}

const fields: FieldDefinitions<StatisticCompanyReportFigures> = {
  figure: {
    alias: 'figure',
  },
  value: {
    alias: 'value',
  },
  __typename: {
    alias: '__typename',
  },
};

export const statisticCompanyReportFiguresEntityDefinition: EntitiyDefinition<StatisticCompanyReportFigures> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'StatisticCompanyReportFigures',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
