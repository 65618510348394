import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { Theme, useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useContext } from 'react';

import { OverlayFullscreenContext } from '@work4all/data/lib/hooks/data-provider/useOverlayFullscreenDataProvider';

export const MaskOverlayFullscreenToggleButton = () => {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'));

  const { fullscreen, setFullscreen } = useContext(OverlayFullscreenContext);

  if (!isDesktop) {
    return null;
  }

  const icon = fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />;

  return (
    <IconButton size="large" onClick={() => setFullscreen(!fullscreen)}>
      {icon}
    </IconButton>
  );
};
