import React from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  IPriceGroupPickerProps,
  PriceGroupPicker,
} from '@work4all/components/lib/components/entity-picker/price-group-picker/PriceGroupPicker';

import { reactRefSetter } from '@work4all/utils';

import { LabeledInput } from '../../containers/mask-overlays/locked-inputs';

interface Props extends Omit<IPriceGroupPickerProps<false>, 'multiple'> {
  error?: string;
}

export const PriceGroupPickerField = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { t } = useTranslation();
    const priceGroupWrapperRef = useRef();
    const { error, ...pickerProps } = props;
    const { value, onChange } = pickerProps;

    return (
      <div ref={reactRefSetter(priceGroupWrapperRef, ref)}>
        <EntityPickerPopover
          anchorEl={priceGroupWrapperRef}
          picker={<PriceGroupPicker {...pickerProps} multiple={false} />}
        >
          <LabeledInput
            onClear={() => {
              onChange(null);
            }}
            label={t('COMMON.PRICE_GROUP')}
            error={error}
            value={value?.name || ''}
          />
        </EntityPickerPopover>
      </div>
    );
  }
);
