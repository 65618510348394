import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class PaymentKind<T extends EMode = EMode.entity> {
  /** Alias: ausziffernAutomatisch */
  disentangleAutomatically?: number;
  /** Alias: ausziffernAutomatischKonto */
  disentangleAutomaticallyAccount?: number;
  /** Alias: code */
  id?: number;
  /** Alias: fibuReferenznummer */
  fibuReferencenumber?: string;
  /** Alias: langtext */
  longtext?: string;
  /** Alias: notiz */
  note?: string;
  /** Alias: sachkonto */
  ledgerAccountId?: number;
  /** Alias: skonto2Prozent */
  skonto2Percent?: number;
  /** Alias: skonto2Tage */
  skonto2DurationDays?: number;
  /** Alias: skontoProzent */
  skonto1Percent?: number;
  /** Alias: skontoTage */
  skonto1DurationDays?: number;
  /** Alias: zahlungsziel */
  paymentTarget?: number;

  __typename?: string;
}

const fields: FieldDefinitions<PaymentKind> = {
  disentangleAutomatically: {
    alias: 'ausziffernAutomatisch',
  },
  disentangleAutomaticallyAccount: {
    alias: 'ausziffernAutomatischKonto',
  },
  id: {
    alias: 'code',
  },
  fibuReferencenumber: {
    alias: 'fibuReferenznummer',
  },
  longtext: {
    alias: 'langtext',
  },
  note: {
    alias: 'notiz',
  },
  ledgerAccountId: {
    alias: 'sachkonto',
  },
  skonto2Percent: {
    alias: 'skonto2Prozent',
  },
  skonto2DurationDays: {
    alias: 'skonto2Tage',
  },
  skonto1Percent: {
    alias: 'skontoProzent',
  },
  skonto1DurationDays: {
    alias: 'skontoTage',
  },
  paymentTarget: {
    alias: 'zahlungsziel',
  },
  __typename: {
    alias: '__typename',
  },
};

export const paymentKindEntityDefinition: EntitiyDefinition<PaymentKind> = {
  local: {},
  remote: {
    queryName: 'getZahlungsarten',
    fragmentName: 'Zahlungsart',
    withPaginationWrapper: false,
    params: [{ name: 'codes', type: '[Int]' }],
  },
  fieldDefinitions: fields,
};
