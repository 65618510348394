import styles from './Link.module.scss';

import { Link as MuiLink, LinkProps } from '@mui/material';

import { Body1 } from '../body1/Body1';

interface Props extends LinkProps {
  sliceTo?: number;
}

export const Link = (props: Props) => {
  const { sliceTo, ...otherProps } = props;

  const displayText = sliceTo
    ? `${otherProps.href.slice(0, sliceTo)}[...]`
    : otherProps.href;

  return (
    <MuiLink {...otherProps} className={styles['link']}>
      <Body1 className={styles['link__body']}>{displayText}</Body1>
    </MuiLink>
  );
};
