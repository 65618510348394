import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class InputSetting<T extends EMode = EMode.entity> {
  /** Alias: name */
  name?: string;
  /** Alias: personal */
  personal?: boolean;
  /** Alias: value */
  value?: string;

  __typename?: string;
}

const fields: FieldDefinitions<InputSetting> = {
  name: {
    alias: 'name',
  },
  personal: {
    alias: 'personal',
  },
  value: {
    alias: 'value',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputSettingEntityDefinition: EntitiyDefinition<InputSetting> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'InputSetting',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
