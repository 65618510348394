import { createMentionElement } from './create-mention-element';

export type ExtractMentionsResolveFn = (options: { id: string }) =>
  | {
      text?: string | null;
    }
  | null
  | undefined;

/**
 * Replaces all inline text mentions in the html string html elements.
 *
 * For example, text `"@(First Last)[user:1]"` will be replaced with element
 * `<span data-mention="user" data-mention-user="1">First Last</span>` (by
 * default).
 *
 * The `resolve` function can be provided to customize the mention element.
 */
export function extractMentions(
  html: string,
  resolve: ExtractMentionsResolveFn
): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  function processNode(node: Node): void {
    if (node.nodeType === document.TEXT_NODE) {
      processTextNode(node);
    } else {
      processElementNode(node);
    }
  }

  function processTextNode(node: Node): void {
    // Parse mention string "@(First Last)[user:1]"
    const regex = /@\(([^)]+)\)\[user:([^\]]+)\]/;

    const result = regex.exec(node.nodeValue);

    if (!result) return;

    const [match, inlinedText, id] = result;

    const text = resolve?.({ id })?.text ?? inlinedText;

    const element = createMentionElement({ id, text: `@${text}` });

    const startOffset = result.index;
    const endOffset = startOffset + match.length;

    const range = new Range();
    range.setStart(node, startOffset);
    range.setEnd(node, endOffset);

    range.deleteContents();
    range.insertNode(element);
  }

  function processElementNode(node: Node): void {
    // Processing text nodes can cause them to be split into multiple text nodes
    // and insert new element nodes into the parent element. Using a forEach
    // here would miss these newly inserted nodes, so we iterate using manually
    // nextSibling.
    let childNode = node.firstChild;
    while (childNode !== null) {
      processNode(childNode);
      childNode = childNode.nextSibling;
    }
  }

  processNode(doc.body);

  return doc.body.innerHTML;
}
