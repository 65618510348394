import styles from './FileContactsCard.module.scss';

import Typography from '@mui/material/Typography';
import clsx from 'clsx';

import { InlineData } from './InlineData';
import { InlineDataProps } from './InlineData';

export interface InlineDataListItem extends InlineDataProps {
  id?: number | string;
  className?: string;
  acitonsComponent?: JSX.Element;
}

export interface InlineDataListProps<T extends InlineDataListItem> {
  items: T[];
  onClick?: (item: T) => void;
  onLabelClick?: (item: T) => void;
}

export function InlineDataList<T extends InlineDataListItem>(
  props: InlineDataListProps<T>
) {
  const { onClick, items, onLabelClick } = props;
  return (
    <div className={clsx('custom-scrollbar', styles.list)}>
      {items.map((item) => {
        const { id, className, acitonsComponent, ...inlineDataProps } = item;
        const onLocalClick = inlineDataProps.onClick
          ? undefined
          : onClick
          ? () => onClick(item)
          : undefined;

        return (
          <div
            key={id}
            className={clsx(className, {
              [styles.row]: true,
              [styles.actionRow]: !!onLocalClick,
            })}
          >
            <Typography
              style={{ width: '100%' }}
              className={clsx({
                [styles.button]: !!onLocalClick,
              })}
              onClick={onLocalClick}
            >
              <InlineData
                onLabelClick={
                  onLabelClick ? () => onLabelClick(item) : undefined
                }
                {...inlineDataProps}
              />
            </Typography>
            {acitonsComponent ? (
              <div className={styles.actions}>{acitonsComponent}</div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}
