import styles from './FolderPlaceholderItem.module.scss';

import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import { DndTypes } from '@work4all/utils/lib/variables';

import { useFavoriteLinks } from '../../../../containers/more/data/favorite-links';
import {
  FavoriteLinkDragObject,
  LinkDragObject,
} from '../../../../dnd/drag-objects';

export interface IFolderPlaceholderItem {
  folderId: string;
}

export function FolderPlaceholderItem(props: IFolderPlaceholderItem) {
  const { folderId } = props;

  const { addFavoriteLink, moveFavoriteLink } = useFavoriteLinks();

  const { t } = useTranslation();

  const [{ isOver }, drop] = useDrop({
    accept: [DndTypes.LINK, DndTypes.FAVORITE_LINK],
    drop: (item, monitor) => {
      switch (monitor.getItemType()) {
        case DndTypes.LINK: {
          const link = item as LinkDragObject;

          addFavoriteLink({
            link: { href: link.href, name: link.text },
            position: { folder: folderId },
          });

          break;
        }

        case DndTypes.FAVORITE_LINK: {
          const favoriteLink = item as FavoriteLinkDragObject;
          moveFavoriteLink({
            target: {
              link: favoriteLink.id,
              folder: favoriteLink.folderId,
            },
            position: { folder: folderId },
          });

          break;
        }
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div ref={drop} className={styles.placeholder}>
      {isOver ? (
        <div className={styles.indicator} />
      ) : (
        <div className={styles.text}>{t('FAV_LINKS.EMPTY_FOLDER')}</div>
      )}
    </div>
  );
}
