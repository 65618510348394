import { IGenerateParams } from '@work4all/models/lib/DataProvider';
import { IListQueryVars } from '@work4all/models/lib/Lists';

import { generateUseGraphQLQuery } from '@work4all/utils/lib/graphql-query-generation';

export const generateLookupQuery = <Data>(params: IGenerateParams) => {
  const {
    useQuery: useDefaultQuery,
    useLazyQuery: useDefaultLazyQuery,
    query,
    queryString,
  } = generateUseGraphQLQuery(params);

  const useQuery = (vars: IListQueryVars) => {
    return useDefaultQuery<Data, IListQueryVars>({
      variables: vars,
    });
  };

  const useLazyQuery = (vars: IListQueryVars) => {
    return useDefaultLazyQuery<Data, IListQueryVars>({
      variables: vars,
    });
  };

  return { useQuery, useLazyQuery, query, queryString };
};
