import { Button, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';

import { useSetting } from '@work4all/data/lib/settings/use-setting';

import { uppercaseFirstLetter } from '@work4all/utils/lib/uppercaseFirstLetter';

import { settings } from '../../../../../../../../settings';

export function BomSection() {
  const { t } = useTranslation();

  const bomVariant = useSetting(settings.bomVariants());
  const reset = () => {
    bomVariant.set([]);
  };

  return (
    <Collapse title={t('MASK.BILL_OF_MATERIALS_SETTINGS')} defaultOpen>
      <Tooltip title={t('MASK.BILL_OF_MATERIALS_SETTINGS_WARNING')}>
        <Button
          sx={{ marginTop: '2px' }}
          variant="contained"
          disabled={!bomVariant.value.length}
          onClick={reset}
        >
          {uppercaseFirstLetter(t('COMMON.RESTORE'))}
        </Button>
      </Tooltip>
    </Collapse>
  );
}
