import { DateTime } from 'luxon';
import { useCallback } from 'react';
import {
  actions,
  Cell,
  Hooks,
  makePropGetter,
  Row,
  TableInstance,
} from 'react-table';

// eslint-disable-next-line @typescript-eslint/ban-types
export type CellEditResult<T extends object = {}> = {
  cell: Cell<T>;
  value: string | number | boolean;
  valueChanged: boolean;
  interaction: 'submit' | 'blur' | 'update';
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type CellEditHandler<T extends object = {}> = (
  result: CellEditResult<T>
) => void;

export interface EditModeConfig {
  /** Id of the row, that is being edited. */
  row: string;
  /** List of ids of the columns, that are being edited. */
  columns: string[];
  /**
   * If this value is not undefined, an input element in cell in a column
   * with the matching ID will be focused on mount.
   */
  autoFocus?: string;
}

export interface GetEditableCellProps {
  (): {
    onEdit: (
      value: string | number | DateTime,
      interaction: 'submit' | 'blur' | 'cancel' | 'update'
    ) => void;
    autoFocus: boolean;
  };
}

export interface EditModeTableInstance {
  /** Allows to set the row and columns, that need to be editable. */
  setEditModeConfig: (config: EditModeConfig) => void;
  /**
   * Allows to update the edit state of the table to, for example,
   * remove one of the columns. Receives current edit state and must return the new state.
   */
  updateEditModeConfig: (
    updater: (config: EditModeConfig) => EditModeConfig
  ) => void;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export interface EditModeCell<D extends object = {}> extends Cell<D> {
  /**
   * Is this cell currently being edited.
   *
   * Usually you would call cell.render("EditableCell")
   * to render a component to edit the data.
   * You can customize the component, that will render, by
   * configuring it in `useTable`. (Use "EditableCell") as key.
   */
  isEditMode: boolean;
  getEditableCellProps: GetEditableCellProps;
}

export function useEditMode<D extends object>(hooks: Hooks<D>) {
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
  hooks.prepareRow.push(prepareRow);
  hooks.getEditableCellProps = [getEditableCellProps];
}

const SET_EDIT_MODE_CONFIG = 'setEditModeConfig';
const UPDATE_EDIT_MODE_CONFIG = 'updateEditModeConfig';

actions.setEditModeConfig = SET_EDIT_MODE_CONFIG;
actions.updateEditModeConfig = UPDATE_EDIT_MODE_CONFIG;

function reducer(state, action) {
  if (action.type === actions.init) {
    return {
      ...state,
      editModeConfig: null,
    };
  }

  if (action.type === actions.setEditModeConfig) {
    const { row, columns, autoFocus } = action;

    return { ...state, editModeConfig: { row, columns, autoFocus } };
  }

  if (action.type === actions.updateEditModeConfig) {
    const { updater } = action;

    return { ...state, editModeConfig: updater(state.editModeConfig) };
  }
}

// Add methods to the object returned by `useTable` to set/update the edit config.
function useInstance<D extends object>(instance: TableInstance<D>) {
  const { dispatch } = instance;

  const setEditModeConfig = useCallback(
    (config: EditModeConfig) => {
      dispatch({ type: SET_EDIT_MODE_CONFIG, ...config });
    },
    [dispatch]
  );

  const updateEditModeConfig = useCallback(
    (updater: (config: EditModeConfig) => EditModeConfig | null) => {
      dispatch({ type: SET_EDIT_MODE_CONFIG, updater });
    },
    [dispatch]
  );

  Object.assign(instance, {
    setEditModeConfig,
    updateEditModeConfig,
  });
}

// eslint-disable-next-line @typescript-eslint/ban-types
function prepareRow<D extends object = {}>(row: Row<D>, { instance }) {
  const config: EditModeConfig = instance.state.editModeConfig;

  if (!config) return;

  row.allCells.forEach((cell: EditModeCell<D>) => {
    if (config.row === cell.row.id && config.columns.includes(cell.column.id)) {
      cell.isEditMode = true;

      cell.getEditableCellProps = makePropGetter(
        instance.getHooks().getEditableCellProps,
        { instance, row, cell }
      );
    } else {
      cell.isEditMode = false;
    }
  });
}

function getEditableCellProps(props, { instance, cell }) {
  const { dispatch, onCellEdit, state } = instance;

  // Removes the column with a matching ID from the list of editable columns.
  // If there are no columns left after removing this one, sets the config to `null`.
  //
  // We could update this in the future to call a function, passed in `useTable`
  // to notify the parent component, that the editing has finished. And maybe
  // also emit full list of changes, if it's easier to save everything at the end,
  // instead of emitting changes for each cell separately.
  function removeColumnFromConfig(removedColumnId: string) {
    dispatch({
      type: actions.updateEditModeConfig,
      updater: (config: EditModeConfig) => {
        const newConfig: EditModeConfig = {
          ...config,
          columns: config.columns.filter(
            (columnId) => columnId !== removedColumnId
          ),
        };

        return newConfig.columns.length > 0 ? newConfig : null;
      },
    });
  }

  return [
    props,
    {
      onEdit: (
        value: string,
        interaction: 'submit' | 'blur' | 'cancel' | 'update'
      ) => {
        if (interaction !== 'cancel') {
          const result: CellEditResult = {
            cell,
            value,
            valueChanged: value !== cell.value,
            interaction,
          };

          onCellEdit?.(result);
        }

        if (interaction !== 'update') removeColumnFromConfig(cell.column.id);
      },
      autoFocus: state.editModeConfig.autoFocus === cell.column.id,
    },
  ];
}
