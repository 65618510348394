import styles from './TableBody.module.scss';

import { TableBody as MuiTableBody } from '@mui/material';
import { forwardRef } from 'react';

export const TableBody = forwardRef<
  HTMLDivElement,
  { children?: React.ReactNode }
>(function TableBody(props, ref) {
  const { children, ...rest } = props;

  return (
    <MuiTableBody ref={ref} component="div" className={styles.body} {...rest}>
      {children}
    </MuiTableBody>
  );
});
