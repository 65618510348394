import { cloneDeep, isEqual, noop } from 'lodash';
import { DateTime } from 'luxon';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { UserPresenceTimeInfo } from '@work4all/models/lib/Classes/UserPresenceTimeInfo.entity';

export interface TimeCardDetails {
  date: string;
  items: UserPresenceTimeInfo[];
  disabled: boolean;
  disabledReason?: string;
  disabledPause: boolean;
  userId: number;
  isCurrentUser: boolean;
  refetch: () => void;
}

const TimeCardDetailsContext = createContext<TimeCardDetails>({
  date: DateTime.now().toISO(),
  items: [],
  disabled: true,
  disabledPause: true,
  userId: 0,
  isCurrentUser: true,
  refetch: noop,
});

export const TimeCardDetailsProvider: React.FC<
  PropsWithChildren<TimeCardDetails>
> = (props: PropsWithChildren<TimeCardDetails>) => {
  const { children, ...others } = props;
  const [items, setItems] = useState<UserPresenceTimeInfo[]>(
    cloneDeep(others.items)
  );

  useEffect(() => {
    if (!isEqual(others.items, items)) {
      setItems(cloneDeep(others.items));
    }
  }, [others.items, items]);

  return useMemo(() => {
    return (
      <TimeCardDetailsContext.Provider value={{ ...others, items }}>
        {children}
      </TimeCardDetailsContext.Provider>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, others.date]);
};

export const useTimeCardDetails = () => {
  const context = useContext(TimeCardDetailsContext);

  return {
    ...context,
    isLast: (id: number) => {
      const map = context.items.flatMap((x) => x.items);
      const last = map[map.length - 1];
      return last?.id === id;
    },
  };
};
