import styles from './CalendarPageHeader.module.scss';

import { MoreVert } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Box, IconButton, Theme, Tooltip, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SettingsIcon } from '@work4all/assets/icons/settings3.svg';

import { BackButton } from '@work4all/components/lib/components/BackButton';
import { IStackItem } from '@work4all/components/lib/navigation/history-stack';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { usePageTitle } from '../../../hooks';
import {
  MaskContextProvider,
  useMaskConfig,
  useMaskContextValue,
} from '../../mask-overlays/mask-overlay/hooks/mask-context';
import { CalendarSettings } from '../../mask-overlays/mask-overlay/views/settings/calendar-settings/CalendarSettings';
import { CalendarProps } from '../types';

import { CalendarMobileMenu } from './CalendarMobileMenu';

export interface CalendarPageHeaderProps
  extends Omit<CalendarProps, 'onOpenMask'> {
  onCreateClick: () => void;
}

export function CalendarPageHeader(props: CalendarPageHeaderProps) {
  const {
    onCreateClick,
    range,
    onRangeChange,
    groupMode,
    onAppointmentStatesChange,
    appointmentStates,
    onGroupModeChange,
  } = props;

  const { t } = useTranslation();

  const isMdUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));

  const title = t(`CALENDAR.TITLE`);

  usePageTitle(title);

  const [menuOpen, setMenuOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const openMenu = useCallback(() => {
    setMenuOpen(true);
  }, []);

  const openSettings = useCallback(() => {
    setSettingsOpen(true);
  }, []);

  const onMenuClose = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const onSettingsClose = useCallback(() => {
    setSettingsOpen(false);
  }, []);

  const mask = useMaskConfig({ entity: Entities.appointment });
  const maskContext = useMaskContextValue(mask);

  const calendarMobileMenuView = useMemo<IStackItem>(() => {
    return {
      title: t('COMMON.CALENDAR'),
      view: (
        <CalendarMobileMenu
          onAppointmentStatesChange={onAppointmentStatesChange}
          appointmentStates={appointmentStates}
          groupMode={groupMode}
          onRangeChange={onRangeChange}
          range={range}
          onGroupModeChange={onGroupModeChange}
        />
      ),
    };
  }, [
    appointmentStates,
    groupMode,
    onAppointmentStatesChange,
    onGroupModeChange,
    onRangeChange,
    range,
    t,
  ]);

  return (
    <MaskContextProvider value={maskContext}>
      <NavigationOverlay
        open={settingsOpen}
        initialView={{
          view: <CalendarSettings amplitudeEntryPoint="Calendar" />,
        }}
        close={onSettingsClose}
      />

      <NavigationOverlay
        classes={{
          wrapper: styles.menuWrapper,
          paper: styles.menuWrapper,
        }}
        open={menuOpen}
        initialView={calendarMobileMenuView}
        close={onMenuClose}
      />

      <div className={styles.header}>
        <BackButton href="/more" />

        <Typography variant={isMdUp ? 'h3' : 'h4'} color="text.secondary">
          {title}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            gap: '0.5rem',
            alignItems: 'center',
            ml: 'auto',
          }}
        >
          {isMdUp && (
            <Tooltip
              title={t('CALENDER.CREATE.APPOINTMENT')}
              placement="top-end"
            >
              <IconButton color="primary" onClick={onCreateClick}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          )}
          {!isMdUp ? (
            <IconButton color="primary" onClick={openMenu}>
              <MoreVert />
            </IconButton>
          ) : (
            <IconButton color="primary" onClick={openSettings}>
              <SettingsIcon />
            </IconButton>
          )}
        </Box>
      </div>
    </MaskContextProvider>
  );
}
