import { CellProps } from 'react-table';

import { ChronoFileItem } from '@work4all/models/lib/Classes/ChronoFileItem.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';

export const UserCell = (props: CellProps<ChronoFileItem>) => {
  const fileItem = props.cell.row.original.fileItem;

  const user = getUser(fileItem);
  if (user) {
    return <div>{`${user.displayName}`}</div>;
  }

  return null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getUser(fileItem: any): User {
  return (
    fileItem?.user || fileItem?.editor1 || fileItem?.internalProjectManager
  );
}
