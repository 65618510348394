import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { BzObjType } from '../Enums/BzObjType.enum';
import { EMode } from '../Enums/EMode.enum';
import { ErpPositionSlMode } from '../Enums/ErpPositionSlMode.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputPosition<T extends EMode = EMode.entity> {
  /** Alias: xmlImport */
  xmlImport?: any;
  /** Alias: vorgang */
  vorgang?: string;
  /** Alias: einzelpreisBrutto */
  einzelpreisBrutto?: number;
  /** Alias: gesamtpreisBrutto */
  gesamtpreisBrutto?: number;
  /** Alias: objekteParentCode */
  objekteParentCode?: number;
  /** Alias: autoEkProzent */
  autoEkProzent?: number;
  /** Alias: mengePaletten */
  mengePaletten?: number;
  /** Alias: palettenFaktorMultiplikator */
  palettenFaktorMultiplikator?: number;
  /** Alias: mengeVe */
  mengeVe?: number;
  /** Alias: veFaktorMultiplikator */
  veFaktorMultiplikator?: number;
  /** Alias: mengeKarton */
  mengeKarton?: number;
  /** Alias: kartonFaktorMultiplikator */
  kartonFaktorMultiplikator?: number;
  /** Alias: formatierungsArt */
  formatierungsArt?: string;
  /** Alias: uhrzeitBeginn */
  uhrzeitBeginn?: string;
  /** Alias: uhrzeitEnde */
  uhrzeitEnde?: string;
  /** Alias: volumen */
  volumen?: number;
  /** Alias: anzahlungsrechnungCode */
  anzahlungsrechnungCode?: number;
  /** Alias: listenpreis */
  listenpreis?: number;
  /** Alias: slMode */
  slMode?: number;
  /** Alias: slAnzahl */
  slAnzahl?: number;
  /** Alias: produktionschargenCode */
  produktionschargenCode?: number;
  /** Alias: langtext */
  langtext?: string;
  /** Alias: stattGPreis */
  stattGPreis?: string;
  /** Alias: bzObjType */
  bzObjType?: BzObjType;
  /** Alias: bzObjMemberCode */
  bzObjMemberCode?: number;
  /** Alias: index */
  index?: number;
  /** Alias: nummer */
  nummer?: string;
  /** Alias: posCode */
  posCode?: number;
  /** Alias: aufmass */
  aufmass?: string;
  /** Alias: artikelNummer */
  artikelNummer?: string;
  /** Alias: anzahl */
  anzahl?: number;
  /** Alias: fertigmeldung */
  fertigmeldung?: boolean;
  /** Alias: fertigmeldungDatum */
  fertigmeldungDatum?: string;
  /** Alias: fertigmeldungDurchBCode */
  fertigmeldungDurchBCode?: number;
  /** Alias: fertigmeldungNotiz */
  fertigmeldungNotiz?: string;
  /** Alias: einheit */
  einheit?: string;
  /** Alias: einzelpreis */
  einzelpreis?: number;
  /** Alias: gesamtpreis */
  gesamtpreis?: number;
  /** Alias: mwst */
  mwst?: number;
  /** Alias: rabatt */
  rabatt?: number;
  /** Alias: ekPreis */
  ekPreis?: number;
  /** Alias: aufwand */
  aufwand?: number;
  /** Alias: minutenpreis */
  minutenpreis?: number;
  /** Alias: ekZuschlag */
  ekZuschlag?: number;
  /** Alias: positionsArt */
  positionsArt?: any;
  /** Alias: artikelCode */
  artikelCode?: number;
  /** Alias: internerText */
  internerText?: string;
  /** Alias: faktor */
  faktor?: number;
  /** Alias: sachkontoNummer */
  sachkontoNummer?: number;
  /** Alias: kostenstelleNummer */
  kostenstelleNummer?: number;
  /** Alias: kostenstelle2Nummer */
  kostenstelle2Nummer?: number;
  /** Alias: rueckstand */
  rueckstand?: number;
  /** Alias: geliefert */
  geliefert?: number;
  /** Alias: liefertermin */
  liefertermin?: string;
  /** Alias: tatsaechlicherLiefertermin */
  tatsaechlicherLiefertermin?: string;
  /** Alias: provisionProzent */
  provisionProzent?: number;
  /** Alias: provisionWerte */
  provisionWerte?: number;
  /** Alias: provisionaerCode */
  provisionaerCode?: number;
  /** Alias: bestellmenge */
  bestellmenge?: number;
  /** Alias: kurztext */
  kurztext?: string;
  /** Alias: lagerortCode */
  lagerortCode?: number;
  /** Alias: lagerort */
  lagerort?: string;
  /** Alias: rabatt2 */
  rabatt2?: number;
  /** Alias: rabatt3 */
  rabatt3?: number;
  /** Alias: rabatt4 */
  rabatt4?: number;
  /** Alias: reserviert */
  reserviert?: number;
  /** Alias: kostenkonto */
  kostenkonto?: number;
  /** Alias: projektCode */
  projektCode?: number;
  /** Alias: originalCode */
  originalCode?: number;
  /** Alias: einzelgewicht */
  einzelgewicht?: number;
  /** Alias: gewicht */
  gewicht?: number;
  /** Alias: herstellernummer */
  herstellernummer?: string;
  /** Alias: breite */
  breite?: number;
  /** Alias: laenge */
  laenge?: number;
  /** Alias: benutzerCode */
  benutzerCode?: number;
  /** Alias: creationDate */
  creationDate?: string;
  /** Alias: editBenutzerCode */
  editBenutzerCode?: number;
  /** Alias: editDate */
  editDate?: string;
  /** Alias: sLArtCode */
  sLArtCode?: number;
  /** Alias: sLType */
  sLType?: ErpPositionSlMode;
  /** Alias: lieferantenCode */
  lieferantenCode?: number;
  /** Alias: eigeneArtikelnummer */
  eigeneArtikelnummer?: string;
  /** Alias: kalkulationslogikFaktor */
  kalkulationslogikFaktor?: number;
  /** Alias: kalkulationslogik */
  kalkulationslogik?: any;
  /** Alias: dispositionBegin */
  dispositionBegin?: string;
  /** Alias: dispositionEnde */
  dispositionEnde?: string;
  /** Alias: frei1 */
  frei1?: number;
  /** Alias: status */
  status?: any;
  /** Alias: lagerMobilFertig */
  lagerMobilFertig?: boolean;
  /** Alias: frei4 */
  frei4?: string;
  /** Alias: zeiterfassungCode */
  zeiterfassungCode?: number;
  /** Alias: bearbeiterBenutzerCode */
  bearbeiterBenutzerCode?: number;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];
  /** Alias: code */
  code?: number;
  /** Alias: buchungsmenge */
  buchungsmenge?: number;
  /** Alias: demandOfTime */
  demandOfTime?: number;
  /** Alias: accesories */
  accesories?: boolean;
  /** Alias: totalVolume */
  totalVolume?: number;
  /** Alias: extraChargePerCent */
  extraChargePerCent?: number;

  __typename?: string;
}

const fields: FieldDefinitions<InputPosition> = {
  xmlImport: {
    alias: 'xmlImport',
  },
  vorgang: {
    alias: 'vorgang',
  },
  einzelpreisBrutto: {
    alias: 'einzelpreisBrutto',
  },
  gesamtpreisBrutto: {
    alias: 'gesamtpreisBrutto',
  },
  objekteParentCode: {
    alias: 'objekteParentCode',
  },
  autoEkProzent: {
    alias: 'autoEkProzent',
  },
  mengePaletten: {
    alias: 'mengePaletten',
  },
  palettenFaktorMultiplikator: {
    alias: 'palettenFaktorMultiplikator',
  },
  mengeVe: {
    alias: 'mengeVe',
  },
  veFaktorMultiplikator: {
    alias: 'veFaktorMultiplikator',
  },
  mengeKarton: {
    alias: 'mengeKarton',
  },
  kartonFaktorMultiplikator: {
    alias: 'kartonFaktorMultiplikator',
  },
  formatierungsArt: {
    alias: 'formatierungsArt',
  },
  uhrzeitBeginn: {
    alias: 'uhrzeitBeginn',
  },
  uhrzeitEnde: {
    alias: 'uhrzeitEnde',
  },
  volumen: {
    alias: 'volumen',
  },
  anzahlungsrechnungCode: {
    alias: 'anzahlungsrechnungCode',
  },
  listenpreis: {
    alias: 'listenpreis',
  },
  slMode: {
    alias: 'slMode',
  },
  slAnzahl: {
    alias: 'slAnzahl',
  },
  produktionschargenCode: {
    alias: 'produktionschargenCode',
  },
  langtext: {
    alias: 'langtext',
  },
  stattGPreis: {
    alias: 'stattGPreis',
  },
  bzObjType: {
    alias: 'bzObjType',
  },
  bzObjMemberCode: {
    alias: 'bzObjMemberCode',
  },
  index: {
    alias: 'index',
  },
  nummer: {
    alias: 'nummer',
  },
  posCode: {
    alias: 'posCode',
  },
  aufmass: {
    alias: 'aufmass',
  },
  artikelNummer: {
    alias: 'artikelNummer',
  },
  anzahl: {
    alias: 'anzahl',
  },
  fertigmeldung: {
    alias: 'fertigmeldung',
  },
  fertigmeldungDatum: {
    alias: 'fertigmeldungDatum',
  },
  fertigmeldungDurchBCode: {
    alias: 'fertigmeldungDurchBCode',
  },
  fertigmeldungNotiz: {
    alias: 'fertigmeldungNotiz',
  },
  einheit: {
    alias: 'einheit',
  },
  einzelpreis: {
    alias: 'einzelpreis',
  },
  gesamtpreis: {
    alias: 'gesamtpreis',
  },
  mwst: {
    alias: 'mwst',
  },
  rabatt: {
    alias: 'rabatt',
  },
  ekPreis: {
    alias: 'ekPreis',
  },
  aufwand: {
    alias: 'aufwand',
  },
  minutenpreis: {
    alias: 'minutenpreis',
  },
  ekZuschlag: {
    alias: 'ekZuschlag',
  },
  positionsArt: {
    alias: 'positionsArt',
  },
  artikelCode: {
    alias: 'artikelCode',
  },
  internerText: {
    alias: 'internerText',
  },
  faktor: {
    alias: 'faktor',
  },
  sachkontoNummer: {
    alias: 'sachkontoNummer',
  },
  kostenstelleNummer: {
    alias: 'kostenstelleNummer',
  },
  kostenstelle2Nummer: {
    alias: 'kostenstelle2Nummer',
  },
  rueckstand: {
    alias: 'rueckstand',
  },
  geliefert: {
    alias: 'geliefert',
  },
  liefertermin: {
    alias: 'liefertermin',
  },
  tatsaechlicherLiefertermin: {
    alias: 'tatsaechlicherLiefertermin',
  },
  provisionProzent: {
    alias: 'provisionProzent',
  },
  provisionWerte: {
    alias: 'provisionWerte',
  },
  provisionaerCode: {
    alias: 'provisionaerCode',
  },
  bestellmenge: {
    alias: 'bestellmenge',
  },
  kurztext: {
    alias: 'kurztext',
  },
  lagerortCode: {
    alias: 'lagerortCode',
  },
  lagerort: {
    alias: 'lagerort',
  },
  rabatt2: {
    alias: 'rabatt2',
  },
  rabatt3: {
    alias: 'rabatt3',
  },
  rabatt4: {
    alias: 'rabatt4',
  },
  reserviert: {
    alias: 'reserviert',
  },
  kostenkonto: {
    alias: 'kostenkonto',
  },
  projektCode: {
    alias: 'projektCode',
  },
  originalCode: {
    alias: 'originalCode',
  },
  einzelgewicht: {
    alias: 'einzelgewicht',
  },
  gewicht: {
    alias: 'gewicht',
  },
  herstellernummer: {
    alias: 'herstellernummer',
  },
  breite: {
    alias: 'breite',
  },
  laenge: {
    alias: 'laenge',
  },
  benutzerCode: {
    alias: 'benutzerCode',
  },
  creationDate: {
    alias: 'creationDate',
  },
  editBenutzerCode: {
    alias: 'editBenutzerCode',
  },
  editDate: {
    alias: 'editDate',
  },
  sLArtCode: {
    alias: 'sLArtCode',
  },
  sLType: {
    alias: 'sLType',
  },
  lieferantenCode: {
    alias: 'lieferantenCode',
  },
  eigeneArtikelnummer: {
    alias: 'eigeneArtikelnummer',
  },
  kalkulationslogikFaktor: {
    alias: 'kalkulationslogikFaktor',
  },
  kalkulationslogik: {
    alias: 'kalkulationslogik',
  },
  dispositionBegin: {
    alias: 'dispositionBegin',
  },
  dispositionEnde: {
    alias: 'dispositionEnde',
  },
  frei1: {
    alias: 'frei1',
  },
  status: {
    alias: 'status',
  },
  lagerMobilFertig: {
    alias: 'lagerMobilFertig',
  },
  frei4: {
    alias: 'frei4',
  },
  zeiterfassungCode: {
    alias: 'zeiterfassungCode',
  },
  bearbeiterBenutzerCode: {
    alias: 'bearbeiterBenutzerCode',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customFields: {
    alias: 'customFields',
  },
  code: {
    alias: 'code',
  },
  buchungsmenge: {
    alias: 'buchungsmenge',
  },
  demandOfTime: {
    alias: 'demandOfTime',
  },
  accesories: {
    alias: 'accesories',
  },
  totalVolume: {
    alias: 'totalVolume',
  },
  extraChargePerCent: {
    alias: 'extraChargePerCent',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputPositionEntityDefinition: EntitiyDefinition<InputPosition> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'InputPosition',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
