import styles from './TimelineSection.module.scss';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { LabeledTimeInputWithDropdown } from '@work4all/components/lib/input/labeled-time-input';
import { Body1 } from '@work4all/components/lib/typography/body1/Body1';

import { useSetting } from '@work4all/data/lib/settings/use-setting';

import {
  DEFAULT_CALENDAR_WORK_DAY_END,
  DEFAULT_CALENDAR_WORK_DAY_START,
} from '../../../../../../../../containers/calendar/constants';
import { settings } from '../../../../../../../../settings';

export function TimelineSection() {
  const { t } = useTranslation();

  const calendarWorkDayStart = useSetting(settings.calendarWorkDayStart());

  const calendarWorkDayEnd = useSetting(settings.calendarWorkDayEnd());

  return (
    <Collapse title={t('SETTINGS.CALENDAR.TIMELINE')} defaultOpen>
      <div className={styles.xSplit}>
        <Body1>{t('SETTINGS.CALENDAR.WORKTIME_DURATION')}</Body1>

        <LabeledTimeInputWithDropdown
          style={{ width: '100px' }}
          value={
            DateTime.fromISO(calendarWorkDayStart.value).toFormat('HH:mm') ??
            DEFAULT_CALENDAR_WORK_DAY_START
          }
          label={t('COMMON.STARTDATE')}
          placeholder="hh:mm"
          onChange={(e) => {
            const newVal = DateTime.fromFormat(e.target.value, 'HH:mm');
            calendarWorkDayStart.set(newVal.toISO());
          }}
          anchorEl={null}
        />

        <LabeledTimeInputWithDropdown
          style={{ width: '100px' }}
          value={
            DateTime.fromISO(calendarWorkDayEnd.value).toFormat('HH:mm') ??
            DEFAULT_CALENDAR_WORK_DAY_END
          }
          label={t('COMMON.END')}
          placeholder="hh:mm"
          onChange={(e) => {
            const newVal = DateTime.fromFormat(e.target.value, 'HH:mm');
            calendarWorkDayEnd.set(newVal.toISO());
          }}
          anchorEl={null}
        />
      </div>
    </Collapse>
  );
}
