import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';

import { reactRefSetter } from '@work4all/utils';

import { LabeledInput } from '../../../../../../../../locked-inputs';

import { BomVariantPicker, IBomVariantPickerProps } from './BomVariantPicker';

interface BomVariantPickerFieldProps
  extends Omit<IBomVariantPickerProps<false>, 'multiple'> {
  error?: string;
  name?: string;
}
export const BomVariantPickerField = React.forwardRef<
  HTMLDivElement,
  BomVariantPickerFieldProps
>(function BomVariantPickerField(props, ref) {
  const { error, ...pickerProps } = props;
  const { value } = pickerProps;
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<EntityPickerPopover>(null);
  return (
    <div ref={reactRefSetter(wrapperRef, ref)}>
      <EntityPickerPopover
        ref={popoverRef}
        anchorEl={wrapperRef}
        picker={<BomVariantPicker {...pickerProps} multiple={false} />}
      >
        <LabeledInput
          value={value ? value?.name : ''}
          name={props.name}
          label={t('FIELDS.BILL_OF_MATERIALS')}
          error={error}
        />
      </EntityPickerPopover>
    </div>
  );
});
