import styles from './styles.module.scss';

import { Alert, Box, Stack } from '@mui/material';
import React, { MouseEventHandler } from 'react';

import { WebsiteFavicon } from '@work4all/components';
import {
  PreviewTitle,
  PreviewWrapper,
} from '@work4all/components/lib/components/entity-preview/components';
import { useRenderMobileConfig } from '@work4all/components/lib/navigation/mobile-navigation/useRenderMobileConfig';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useFilePreview } from '../file-settings/useFileSettingsGroups';

import { IFileInfoPanelItems } from './types';

export interface FilePreviewPanelBaseProps {
  onEdit?: () => void;
  onSettings?: () => void;
  onCloseClicked?: MouseEventHandler<HTMLButtonElement>;
  displayItems?: IFileInfoPanelItems[];
}

export interface FilePreviewPanelProps extends FilePreviewPanelBaseProps {
  title: string;
  website?: string;
  warning?: string;
  renderItem: (item: IFileInfoPanelItems) => React.ReactNode;
  entity: Entities;
  onOpenFilePageClicked?: () => void;
}

export function FilePreviewPanel(props: FilePreviewPanelProps) {
  const {
    title,
    onEdit,
    onSettings,
    onCloseClicked,
    displayItems,
    renderItem,
    entity,
    warning,
    website,
    onOpenFilePageClicked,
  } = props;

  const groups = useFilePreview(entity);
  const isFilePage = useRenderMobileConfig();

  if (!displayItems.length) return null;

  return (
    <PreviewWrapper>
      <PreviewTitle
        readonly
        removeTopPadding={isFilePage}
        onEditClicked={onEdit}
        onSettingsClicked={onSettings}
        onCloseClicked={onCloseClicked}
        onOpenFilePageClicked={onOpenFilePageClicked}
      >
        <Box display="flex" gap="0.5rem" alignItems="center">
          {website && <WebsiteFavicon website={website} />} {title}
        </Box>
      </PreviewTitle>

      <div className={styles.group}>
        {warning && (
          <Alert
            variant="filled"
            severity="error"
            icon={false}
            sx={{ borderRadius: 0 }}
          >
            {warning}
          </Alert>
        )}

        {groups.map((group, idx) => {
          const visibleInGroup = group.filter((x) =>
            displayItems.includes(x.id)
          );
          if (!visibleInGroup.length) return null;
          return (
            <React.Fragment key={idx}>
              <Stack gap="0.5rem">
                {visibleInGroup.map((item) => renderItem(item.id))}
              </Stack>
            </React.Fragment>
          );
        })}
      </div>
    </PreviewWrapper>
  );
}
