import { useEffect, useRef, useState } from 'react';
import { Path, PathValue, UseFormReturn } from 'react-hook-form';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UpdateAny = (value: any, prevValue?: any) => { [name: string]: any };

type Updater<T, F extends Path<T>> = (
  value: PathValue<T, F>,
  prevValue: PathValue<T, F>
) => Partial<T>;

type FormUpdateConfig<T> = { [K in Path<T>]?: Updater<T, K> };
/**
 * Use this hook to run some update logic whenever some of the form values
 * change. Like if you need to keep some form values in sync and update them
 * whenever the related field is changed.
 *
 * @param config Object that maps field name to updater functions.
 * The object should be of shape `{ 'form-field-name': updaterFunciton }`.
 *
 * If you return an object from the updater function, it will be used to update
 * form values.
 * If you wan't to run you update logic manually, you can return `null` to
 * disable the default behavior.
 * @param form The object returned from the `useForm` hook.
 * @param deps Here you should provide a list of dependencies that cause the
 * form value to change. This list will be used in `useEffect` to ignore the
 * next form value change. This way your updater functions don't run as a result
 * of resetting form value after, for example, submitting a form.
 */
export function useFormUpdate<T>(
  config: FormUpdateConfig<T>,
  form: UseFormReturn<T>,
  deps: React.DependencyList = [] //ToDo remove after all updates to FromPlus are done
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  type TValues = any;

  const { watch, getValues, setValue } = form;
  const [initialConfig] = useState(config);
  const skipNextChangeRef = useRef(false);

  useEffect(() => {
    if (deps.length > 0) {
      skipNextChangeRef.current = true;
    }
  }, deps);

  const savedValuesRef = useRef<TValues>();

  useEffect(() => {
    const newSavedValues: TValues = {};

    Object.keys(initialConfig).forEach((name: Path<T>) => {
      const value = getValues(name);
      newSavedValues[name] = value;
    });

    savedValuesRef.current = newSavedValues;

    // TODO Probably need to update saved values after deps change
  }, []);

  useEffect(() => {
    const subscription = watch(() => {
      const savedValues = savedValuesRef.current;
      const newValues: TValues = {};

      // Get all new form values.
      Object.keys(initialConfig).forEach((name: Path<T>) => {
        const value = getValues(name);
        newValues[name] = value;
      });

      // Update saved form values.
      savedValuesRef.current = newValues;

      // If the dependency list changed, ignore this form value change.
      if (skipNextChangeRef.current) {
        skipNextChangeRef.current = false;
        return;
      }

      const changesToBeMade: TValues = {};

      // Call the updater function for fields from config that chaned.
      // Collect all changes to apply to the form state.
      Object.entries(initialConfig).forEach(
        ([name, updater]: [string, UpdateAny]) => {
          const savedValue = savedValues[name];
          const newValue = newValues[name];

          const hasChanged = savedValue !== newValue;

          if (!hasChanged) {
            return;
          }

          const change = updater(newValue, savedValue);

          if (change != null) {
            Object.assign(changesToBeMade, change);
          }
        }
      );
      // Apply all changes.
      Object.entries(changesToBeMade).forEach(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ([name, value]: [Path<T>, any]) => {
          setValue(name, value, { shouldDirty: true, shouldValidate: true });
        }
      );

      return () => {
        subscription.unsubscribe();
      };
    });
  }, [watch, initialConfig, getValues, setValue]);
}
