import styles from './CalendarTimeRangeSwitch.module.scss';

import {
  FormControl,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CalendarView1 } from '@work4all/assets/icons/calendar-view-1.svg';
import { ReactComponent as CalendarView5 } from '@work4all/assets/icons/calendar-view-5.svg';
import { ReactComponent as CalendarView7 } from '@work4all/assets/icons/calendar-view-7.svg';
import { ReactComponent as CalendarViewA } from '@work4all/assets/icons/calendar-view-a.svg';
import { ReactComponent as CalendarViewM } from '@work4all/assets/icons/calendar-view-m.svg';

import {
  EntityPickerTab,
  EntityPickerTabBar,
} from '@work4all/components/lib/components/entity-picker/components';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { AppointmentsGroupMode, AppointmentsTimeRange } from '../types';

interface CalendarTimeRangeSwitchProps {
  value: AppointmentsTimeRange;
  onChange: (value: AppointmentsTimeRange) => void;
  groupMode: AppointmentsGroupMode;
  layout?: 'select' | 'button' | 'tabs' | 'list';
}

export function CalendarTimeRangeSwitch(props: CalendarTimeRangeSwitchProps) {
  const { value, onChange, groupMode, layout } = props;
  const { t } = useTranslation();

  const getIcon = (range: AppointmentsTimeRange) => {
    switch (range) {
      case 'agenda':
        return <CalendarViewA />;
      case 'day':
        return <CalendarView1 />;
      case 'month':
        return <CalendarViewM />;
      case 'week-5':
        return <CalendarView5 />;
      case 'week-7':
        return <CalendarView7 />;
    }
  };

  const availableRanges: AppointmentsTimeRange[] = [
    'agenda',
    'day',
    'week-5',
    'week-7',
    'month',
  ];
  if (groupMode === 'vertical')
    availableRanges.splice(availableRanges.indexOf('day'), 1);

  const components = availableRanges.map((x) => (
    <EntityPickerTab key={x} value={x}>
      {t(`COMMON.${x.toUpperCase()}`)}
    </EntityPickerTab>
  ));

  const { handleClick, ...menuState } = usePopoverState('bottom');

  // Re-use tab components from entity pickers for simplicity. The correct UI
  // component here would be something like a toggle button group, but we don't
  // have a styled version at the moment. The tab bar from pickers looks and
  // behaves identically, so just re-use until the new component is implemented
  // (if it is required).
  return layout === 'select' ? (
    <FormControl variant="filled">
      <InputLabel id="time-range-select-label">
        {t('CALENDAR.TIMERANGE.TITLE')}
      </InputLabel>
      <Select
        labelId="time-range-select-label"
        value={value}
        onChange={(event) => {
          onChange(event.target.value as AppointmentsTimeRange);
        }}
        style={{
          width: '10rem',
        }}
        classes={{
          select: styles.select,
        }}
      >
        {availableRanges.map((mode) => {
          return (
            <MenuItem key={mode} value={mode}>
              <ListItemIcon>{getIcon(mode)}</ListItemIcon>
              <ListItemText primary={t(`COMMON.${mode.toUpperCase()}`)} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  ) : layout === 'tabs' ? (
    <EntityPickerTabBar value={value} onChange={onChange}>
      {components}
    </EntityPickerTabBar>
  ) : layout === 'list' ? (
    <div>
      {availableRanges.map((mode) => {
        return (
          <MenuItem
            key={mode}
            value={mode}
            onClick={() => {
              onChange(mode);
              menuState.onClose();
            }}
            selected={mode === value}
          >
            <ListItemIcon>{getIcon(mode)}</ListItemIcon>
            <ListItemText primary={t(`COMMON.${mode.toUpperCase()}`)} />
          </MenuItem>
        );
      })}
    </div>
  ) : (
    <>
      <IconButton color="primary" onClick={handleClick}>
        {getIcon(value)}
      </IconButton>
      <Menu {...menuState}>
        {availableRanges.map((mode) => {
          return (
            <MenuItem
              key={mode}
              value={mode}
              onClick={() => {
                onChange(mode);
                menuState.onClose();
              }}
            >
              <ListItemIcon>{getIcon(mode)}</ListItemIcon>
              <ListItemText primary={t(`COMMON.${mode.toUpperCase()}`)} />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
