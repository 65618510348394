import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import produce from 'immer';
import { Epic, ofType } from 'redux-observable';
import { CollectionsOfDatabase, RxDatabase } from 'rxdb';
import {
  combineLatest,
  defer,
  EMPTY,
  from,
  interval,
  merge,
  Observable,
  of,
  Subject,
  timer,
} from 'rxjs';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  exhaustMap,
  filter,
  ignoreElements,
  map,
  mergeMap,
  reduce,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { User } from '@work4all/models/lib/Classes/User.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { genQuery } from '@work4all/utils/lib/genQuery';

import { noop } from '../actions/common-actions';
import {
  changeTenant,
  RefreshTokenAction,
  RefreshUserAction,
  updateUserData,
  UserActions,
} from '../actions/user-actions';
import { cleanupDatabase, createTenantCollections, USER_META_ID } from '../db';
import { ReplicationEvent } from '../entities/utils/replication.types';
import { buildQuery } from '../hooks/data-provider/utils/buildQuery';
import { IUser } from '../hooks/useUser';

import {
  channel,
  isLogoutMessage,
  isReplicationEventMessage,
  isReportTenantMessage,
  isTenantMessaage,
  messages$,
} from './messaging';
import {
  getAllReplicatingTenants,
  startReplicationForTenant,
  stopReplicationForAllTenants,
  stopReplicationForTenant,
} from './replication';

const userData: User = {
  id: null,
  shortName: null,
  letterSalutation: null,
  firstName: null,
  lastName: null,
  displayName: null,
  phoneNumber: null,
  eMail: null,
  departmentName: null,
  isMaster: null,
  accountRole: {
    id: null,
  },
  vacationApprover: {
    id: null,
    firstName: null,
    lastName: null,
    shortName: null,
    displayName: null,
  },
  vacationApproverId: null,
  vactationEntitlement: [
    {
      id: null,
      jahr: null,
      anspruch: null,
      userId: null,
      bisJetzt: null,
      vorjahr: null,
    },
  ],
  kommtGehtMinimaleStartZeit: null,
};

const { query: GET_USER_META } = buildQuery(
  {
    operationName: 'GetCurrentUser',
    entity: Entities.user,
    data: userData,
  },
  100
);

interface GetUserMetaResponse {
  getBenutzer: {
    data: User[];
  };
}

export interface UserEpicsDependencies {
  db: RxDatabase<CollectionsOfDatabase>;
  tokenRefresh: Subject<void>;
  apollo: ApolloClient<NormalizedCacheObject>;
  onReplicationEvent: (event: ReplicationEvent) => void;
}

export const replicationControlEpic: Epic = (
  action$,
  state$,
  dependencies: UserEpicsDependencies
) => {
  const { db, tokenRefresh, onReplicationEvent } = dependencies;

  const refreshToken = () => {
    tokenRefresh.next();
  };

  const isTabLeader$ = from(db.waitForLeadership()).pipe(
    map(() => true),
    startWith(false)
  );

  const isUserLoggedIn$ = db.getLocal$<{ user: IUser }>(USER_META_ID).pipe(
    switchMap((doc$) => {
      if (!doc$) return of(null);
      return doc$.$.pipe(map((doc) => doc.data.user ?? null));
    }),
    map((user) => user != null),
    distinctUntilChanged()
  );

  let currentTenant: number | null = null;

  const activeTenant$ = action$.pipe(
    ofType(UserActions.CHANGE_TENANT),
    map((action) => action.data.tenantId),
    distinctUntilChanged(),
    tap((tenantId) => {
      currentTenant = tenantId;
      console.debug(`Tenant changed in the active tab (tenant=${tenantId}).`);
    })
  );

  return combineLatest([activeTenant$, isTabLeader$, isUserLoggedIn$]).pipe(
    switchMap(([activeTenant, isTabLeader, isUserLoggedIn]) => {
      if (activeTenant == null || !isUserLoggedIn || !isTabLeader) {
        return EMPTY;
      }

      startReplicationForTenant({
        tenant: activeTenant,
        db,
        refreshToken,
        onReplicationEvent: (event) => {
          if (activeTenant === currentTenant) {
            onReplicationEvent(event);
          }

          channel.postMessage({
            type: 'replication_event',
            tenant: activeTenant,
            replicationId: event.id,
            event: event.type,
          });
        },
      }).catch(ignoreAbortError);

      const cleanUpUnusedTenants$ = interval(30_000).pipe(
        concatMap(() => {
          channel.postMessage({ type: 'report_tenant', ping: true });

          return messages$.pipe(
            takeUntil(timer(1000)),
            filter(isTenantMessaage),
            map((message) => message.tenant),
            startWith(activeTenant),
            reduce((acc, tenant) => {
              acc.add(tenant);
              return acc;
            }, new Set<number>()),
            map((activeTenants) => {
              const replicatingTenants = getAllReplicatingTenants();

              console.debug(
                `Found active tenants: ${[...activeTenants.values()]}`
              );
              for (const tenant of replicatingTenants) {
                if (!activeTenants.has(tenant)) {
                  console.debug(`Should remove tenant ${tenant}.`);
                  stopReplicationForTenant({ tenant }).catch(ignoreAbortError);
                }
              }
            })
          );
        })
      );

      const startReplicationForOtherTabs$ = messages$.pipe(
        takeUntil(action$.pipe(ofType(UserActions.LOGOUT))),
        filter(isTenantMessaage),
        filter((message) => !message.ping),
        tap((message) => {
          const tenant = message.tenant;
          console.debug(`Tenant change message received (tenant=${tenant}).`);

          startReplicationForTenant({
            tenant,
            db,
            refreshToken,
            onReplicationEvent: (event) => {
              if (tenant === currentTenant) {
                onReplicationEvent(event);
              }

              channel.postMessage({
                type: 'replication_event',
                tenant,
                replicationId: event.id,
                event: event.type,
              });
            },
          });
        })
      );

      channel.postMessage({ type: 'report_tenant' });

      return merge(cleanUpUnusedTenants$, startReplicationForOtherTabs$);
    }),
    ignoreElements()
  );
};

export const changeTenantEpic: Epic = (
  action$: Observable<ReturnType<typeof changeTenant>>,
  state,
  dependencies: UserEpicsDependencies
) => {
  const { db, onReplicationEvent } = dependencies;

  return action$.pipe(
    ofType(UserActions.CHANGE_TENANT),
    map((action) => action.data.tenantId),
    distinctUntilChanged(),
    concatMap(async (tenantId: number) => {
      if (tenantId == null) return null;
      await createTenantCollections(db, tenantId);
      channel.postMessage({ type: 'tenant', tenant: tenantId });
      return tenantId;
    }),
    switchMap((tenantId) => {
      if (tenantId == null) {
        return EMPTY;
      }

      const replyToPings$ = messages$.pipe(
        filter(isReportTenantMessage),
        tap((message) => {
          channel.postMessage({
            type: 'tenant',
            tenant: tenantId,
            ping: message.ping,
          });
        })
      );

      const reportReplicatonEvents$ = messages$.pipe(
        filter(isReplicationEventMessage),
        tap((message) => {
          if (message.tenant === tenantId) {
            onReplicationEvent({
              id: message.replicationId,
              type: message.event,
            });
          }
        })
      );

      return merge(replyToPings$, reportReplicatonEvents$);
    }),
    ignoreElements()
  );
};

export const updateUserEpic = (
  action$: Observable<ReturnType<typeof updateUserData>>,
  state$,
  dependencies: UserEpicsDependencies
) => {
  return action$.pipe(
    ofType(UserActions.UPDATE),
    concatMap(async (action) => {
      const db = dependencies.db;
      const userDoc = await db.getLocal<{ user: IUser }>(USER_META_ID);
      return userDoc.incrementalModify((data) => {
        return produce(data, (draft) => {
          draft.user = { ...draft.user, ...action.data };
        });
      });
    }),
    ignoreElements()
  );
};

export const refreshUserEpic = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actions$: Observable<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state$: Observable<any>,
  dependencies: UserEpicsDependencies
) => {
  return actions$.pipe(
    ofType(UserActions.REFRESH),
    switchMap((action: RefreshUserAction) => {
      const { db, apollo } = dependencies;
      return from(
        apollo.query<GetUserMetaResponse>({
          query: GET_USER_META,
          variables: { filter: genQuery({ code: action.data.id }) },
        })
      ).pipe(
        concatMap(async (result) => {
          const user = result.data?.getBenutzer?.data[0];
          if (!user) {
            throw new Error('No user returned in the response');
          }

          const userDoc = await db.getLocal<{ user: IUser }>(USER_META_ID);

          // TODO Some of the properties do not exist in the response
          // ("salutation" , "phone") or have been renamed.
          await userDoc.incrementalModify((data) => {
            if (!data.user) {
              return data;
            }

            data.user.shortName = user.shortName;
            data.user.salutation = user.letterSalutation;
            data.user.firstName = user.firstName;
            data.user.lastName = user.lastName;
            data.user.phone = user.phoneNumber;
            data.user.email = user.eMail;
            data.user.departmentName = user.departmentName;
            data.user.isMaster = user.isMaster;
            data.user.displayName = user.displayName;
            data.user.vacationApproverId = user.vacationApproverId;
            data.user.vacationApprover = user.vacationApprover;
            data.user.vactationEntitlement = user.vactationEntitlement;
            return data;
          });
        }),
        catchError((error: unknown) => {
          const reason = error instanceof Error ? error.message : null;

          const errorMessage = [
            'Failed to update user meta data.',
            reason && ` Reason: ${reason}`,
          ]
            .filter(Boolean)
            .join('');

          console.error(errorMessage);

          return EMPTY;
        })
      );
    }),
    ignoreElements()
  );
};

export const logoutUserEpic: Epic = (
  actions$: Observable<unknown>,
  state$: Observable<unknown>,
  dependencies: UserEpicsDependencies
) => {
  const { db } = dependencies;

  const eventsFromThisTab$ = actions$.pipe(
    ofType(UserActions.LOGOUT),
    tap(() => {
      if (!db.isLeader()) {
        channel.postMessage({ type: 'logout' });
      }
    })
  );

  const eventsFromOtherTabs$ = messages$.pipe(filter(isLogoutMessage));

  return merge(eventsFromThisTab$, eventsFromOtherTabs$).pipe(
    filter(() => db.isLeader()),
    exhaustMap(async () => {
      try {
        console.debug('Stopping all replications.');
        await stopReplicationForAllTenants();
        console.debug('Stopped all replications.');
        await cleanupDatabase(db);
        console.debug('Cleaned up the database.');
      } catch (error) {
        console.error(error);
      }
    }),
    ignoreElements()
  );
};

export const tokenRefreshEpic = (
  actions$: Observable<RefreshTokenAction>,
  state,
  dependencies
) => {
  return actions$.pipe(
    ofType(UserActions.REFRESH_TOKEN),
    mergeMap((action: RefreshTokenAction) =>
      defer(async () => {
        dependencies.tokenRefresh.next({});

        return noop();
      })
    )
  );
};

export const allUserEffects = [
  refreshUserEpic,
  changeTenantEpic,
  logoutUserEpic,
  updateUserEpic,
  tokenRefreshEpic,
  replicationControlEpic,
];

function ignoreAbortError(reason: unknown): void {
  if (reason instanceof Error && reason.name === 'AbortError') {
    console.debug('Aborted:', reason.message);
  } else {
    throw reason;
  }
}
