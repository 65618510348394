import styles from './PRTabPanel.module.scss';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';

import { CityPickerField } from '../../../../../../../../components/entity-picker/CityPickerField';
import { CountryPickerField } from '../../../../../../../../components/entity-picker/CountryPickerField';
import { PostalCodePickerField } from '../../../../../../../../components/entity-picker/PostalCodePickerField';
import { ControllerPlus } from '../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../form-plus/use-form-context-plus';
import { LabeledInput } from '../../../../../../locked-inputs';
import { ControlWrapper } from '../../../../../components/control-wrapper';
import { Panel } from '../../../../../components/panel/Panel';
import { ContactMaskFormValue } from '../../../types';

export const PRTabPanel: React.FC = (props) => {
  const { register, control, getValues } =
    useFormContextPlus<ContactMaskFormValue>();

  const { t } = useTranslation();

  const [currentPostalCode, setCurrentPostalCode] = useState<
    string | undefined
  >(getValues().postalCode);

  return (
    <Panel>
      <Collapse defaultOpen={true} title={t('MASK.PRIVATE_ADDRESS')}>
        <ControlWrapper className={styles.mobileVert}>
          <LabeledInput
            label={t('INPUTS.STREET_HOUSE_NUMBER')}
            {...register('street')}
          />
          <ControlWrapper className={styles.mobileVert}>
            <ControllerPlus
              name="postalCode"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <PostalCodePickerField
                    {...field}
                    value={
                      field.value ? { id: null, postalCode: field.value } : null
                    }
                    onChange={(value) => {
                      setCurrentPostalCode(value?.postalCode || undefined);
                      field.onChange(value?.postalCode || null);
                    }}
                    error={fieldState.error?.message}
                  />
                );
              }}
            />

            <ControllerPlus
              name="city"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <CityPickerField
                    {...field}
                    postalCodeFilter={currentPostalCode}
                    value={field.value}
                    onChange={(value) => field.onChange(value || null)}
                    error={fieldState.error?.message}
                  />
                );
              }}
            />

            <ControllerPlus
              name="country"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <CountryPickerField
                    {...field}
                    error={fieldState.error?.message}
                    value={field.value ? { shortName: field.value } : null}
                    onChange={(value) => {
                      field.onChange(value?.shortName || null);
                    }}
                  />
                );
              }}
            />
          </ControlWrapper>
        </ControlWrapper>
      </Collapse>
      <Collapse defaultOpen={true} title={t('MASK.PRIVATE_COMMUNICATION')}>
        <ControlWrapper className={styles.mobileVert}>
          <LabeledInput
            label={t('INPUTS.EMAIL')}
            {...register('eMailPrivate')}
          />
          <LabeledInput
            label={t('INPUTS.PHONE_AND_EXTENSION_NUMBERS')}
            {...register('phoneNumberPrivate')}
          />
          <LabeledInput
            label={t('INPUTS.MOBILE')}
            {...register('mobileNumberPrivate')}
          />
          <LabeledInput label={t('INPUTS.FAX')} {...register('faxPrivate')} />
        </ControlWrapper>
      </Collapse>
    </Panel>
  );
};
