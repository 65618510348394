import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EmailTemplatePreview } from './EmailTemplatePreview';

export type IEmailPreviewContainerProps = {
  emailTemplateId: string;
  onCloseClicked?: React.MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: React.MouseEventHandler<HTMLButtonElement>;
};

export const EMAIL_TEMPLATE_PREVIEW_DATA: EMailTemplate<EMode.query> = {
  id: null,
  name: null,
  body: null,
  signature: {
    id: null,
    body: null,
  },
};

export function EmailTemplatePreviewContainer(
  props: IEmailPreviewContainerProps
) {
  const { emailTemplateId, onCloseClicked, onEditClicked } = props;

  const requestData = useMemo<DataRequest>(() => {
    return {
      completeDataResponse: true,
      entity: Entities.eMailTemplate,
      data: EMAIL_TEMPLATE_PREVIEW_DATA,
      filter: [
        {
          id: { $eq: emailTemplateId },
        },
      ],
    };
  }, [emailTemplateId]);

  const { data } = useDataProvider<EMailTemplate>(requestData);

  const found = useMemo(() => {
    return data.find((emailTemplate) => emailTemplate.id === emailTemplateId);
  }, [data, emailTemplateId]);

  if (!found) {
    return null;
  }

  return (
    <EmailTemplatePreview
      onCloseClicked={onCloseClicked}
      onEditClicked={onEditClicked}
      emailTemplate={found}
    />
  );
}
