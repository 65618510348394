import styles from './Header.module.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AddPictureIcon } from '@work4all/assets/icons/add_picture.svg';
import { ReactComponent as DeleteIcon } from '@work4all/assets/icons/delete.svg';

import { PictureFromFileOrCam } from '@work4all/components/lib/input/upload-image/components/camera-take-picture/PicFromFileOrCam';
import { TakePictureButton } from '@work4all/components/lib/input/upload-image/components/take-picture-button/TakePictureButton';
import { IUploadImageProps } from '@work4all/components/lib/input/upload-image/types';

import { NavigationOverlayHeader } from '../../../../../components/navigation-overlay-header/NavigationOverlayHeader';
import { BaseActionButton } from '../../../locked-inputs';

export const Header: React.FC<
  Pick<IUploadImageProps, 'onPictureTaken' | 'onImageChoosen'>
> = (props) => {
  const { onImageChoosen, onPictureTaken } = props;
  const { t } = useTranslation();

  return (
    <NavigationOverlayHeader
      breadcrumbsChildren={
        <PictureFromFileOrCam
          onImageChoosen={onImageChoosen}
          onPictureTaken={onPictureTaken}
        >
          {({ takePicture, chooseFile: uploadFile }) => {
            return (
              <div className={styles.actions}>
                <TakePictureButton onClick={takePicture} />
                <BaseActionButton
                  title={t('INPUTS.CHANGE_PICTURE')}
                  icon={<AddPictureIcon />}
                  onClick={uploadFile}
                />
                <BaseActionButton
                  color="error"
                  title={t('MASK.REMOVE')}
                  icon={<DeleteIcon />}
                />
              </div>
            );
          }}
        </PictureFromFileOrCam>
      }
    />
  );
};
