import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class InputCustomField<T extends EMode = EMode.entity> {
  /** Alias: key */
  key?: number;
  /** Alias: value */
  value?: string;

  __typename?: string;
}

const fields: FieldDefinitions<InputCustomField> = {
  key: {
    alias: 'key',
  },
  value: {
    alias: 'value',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputCustomFieldEntityDefinition: EntitiyDefinition<InputCustomField> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputCustomField',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
