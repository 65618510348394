import { useEffect, useState } from 'react';

export const useBottomOverlayHeight = () => {
  const [bottomOverlayHeight, setBottomOverlayHeight] = useState(
    window.innerHeight - window.visualViewport.height
  );
  useEffect(() => {
    function handleResize() {
      setBottomOverlayHeight(window.innerHeight - window.visualViewport.height);
    }
    window.visualViewport.addEventListener('resize', handleResize);

    return () => {
      window.visualViewport.removeEventListener('resize', handleResize);
    };
  }, []);

  return bottomOverlayHeight;
};
