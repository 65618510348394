import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { Contact } from './Contact.entity';
import { User } from './User.entity';

export class VisitationReportAttendee<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: kNBerichtCode */
  customerNumberVisitationId?: number;
  /** Alias: teilnehmerType */
  attendeeType?: number;
  /** Alias: name */
  name?: string;
  /** Alias: mitarbeiterCode */
  collegueId?: number;
  /** Alias: sDObjType */
  sDObjType?: SdObjType;
  /** Alias: sDObjMemberCode */
  businessPartnerId?: number;
  /** Alias: kontaktcode */
  contactId?: number;
  /** Alias: kontaktType */
  contactType?: number;
  /** Alias: ansprechpartner */
  contact?: Contact<T>;
  /** Alias: mitarbeiter */
  collegue?: User<T>;

  __typename?: string;
}

const fields: FieldDefinitions<VisitationReportAttendee> = {
  id: {
    alias: 'code',
  },
  customerNumberVisitationId: {
    alias: 'kNBerichtCode',
  },
  attendeeType: {
    alias: 'teilnehmerType',
  },
  name: {
    alias: 'name',
  },
  collegueId: {
    alias: 'mitarbeiterCode',
  },
  sDObjType: {
    alias: 'sDObjType',
  },
  businessPartnerId: {
    alias: 'sDObjMemberCode',
  },
  contactId: {
    alias: 'kontaktcode',
  },
  contactType: {
    alias: 'kontaktType',
  },
  contact: {
    alias: 'ansprechpartner',
    entity: Entities.contact,
  },
  collegue: {
    alias: 'mitarbeiter',
    entity: Entities.user,
  },
  __typename: {
    alias: '__typename',
  },
};

export const visitationReportAttendeeEntityDefinition: EntitiyDefinition<VisitationReportAttendee> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'BesuchsberichtTeilnehmer',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
