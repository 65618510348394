import produce from 'immer';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import { DbEntities } from '@work4all/models';
import { SettingType } from '@work4all/models/lib/Enums/SettingType.enum';

import { useTenantRxCollection } from '../data-retriever/hooks/useTenantRxCollection';
import { SettingEntity } from '../entities/settingEntity';
import { useTenant } from '../hooks/routing/TenantProvider';

import { SETTING_NAME_PREFIX, UNPREFIXED_USER_SETTINGS } from './config';
import { SettingsContext } from './context';
import { parseSetting } from './parser';
import {
  useDeleteSettingMutation,
  useSetSettingMutation,
} from './settings-graphql';
import { Settings, SettingScope } from './types';

/**
 * Read all the settings from the db, aggregate them and prepare the context
 * value.
 */
export function useSettings(): SettingsContext | null {
  const { activeTenant: tenant } = useTenant();

  const collection = useTenantRxCollection<SettingEntity>(DbEntities.Setting);

  // Keep track of the initial loading state so we can avoid FOC on app load if
  // the page UI depends on settings. The delay here is just a DB read delay, so
  // it should be minimal.
  const [isLoading, setLoading] = useState(true);

  // Keep the current settings value in a ref to avoid unnecessary re-renders.
  const valueRef = useRef<Settings | null>(null);

  // Keep track of all subscribers in `useSetting` hooks.
  const subscribersRef = useRef<(() => void)[]>([]);

  // Subscribe to the settings value changes to receive updates.
  const subscribe = useCallback((callback: () => void) => {
    subscribersRef.current.push(callback);

    return function unsubscribe() {
      subscribersRef.current = subscribersRef.current.filter(
        (cb) => cb !== callback
      );
    };
  }, []);

  // Notify all current subscribers when the settings value changes.
  const notifySubscribers = useCallback(() => {
    // React 17 can't batch these state updates, which can cause
    // desynchronization when multiple relates settings are used in the same
    // component because not all of the settings are updated on the same render.
    // For this reason we wrap all updates to subscribers in
    // `unstable_batchedUpdates` here.
    //
    // With React 18 this shouldn't be necessary, since React will batch all
    // state changes by default. This can be removed when we upgrade.
    //
    // TODO Should also probably rework state management for the `useSetting`
    // hook to use React's `useSyncExternalStore` instead of a custom
    // subscription.
    unstable_batchedUpdates(() => {
      for (const subscriber of subscribersRef.current) {
        subscriber();
      }
    });
  }, []);

  // Get the current settings value (or `null` if loading.)
  const getValue = useCallback(() => {
    return valueRef.current;
  }, []);

  // Read all settings from the DB and aggregate them for easy access.
  useEffect(() => {
    if (!collection) {
      return;
    }

    const query = collection.find({});

    const subscription = query.$.subscribe({
      next(docs) {
        const settings: Record<SettingScope, Record<string, unknown>> = {
          global: {},
          user: {},
          tenant: {},
        };
        for (const doc of docs) {
          const setting = parseSetting(doc.toJSON(), { tenant });

          if (setting) {
            const { name, scope, value } = setting;
            settings[scope][name] = value;
          }
        }

        valueRef.current = settings;

        setLoading(false);

        notifySubscribers();
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [collection, tenant, notifySubscribers]);

  const [setSettingMutation] = useSetSettingMutation();

  // Set the setting value, store the new value in the DB and send a update
  // mutation.
  const setSetting = useCallback<SettingsContext['setSetting']>(
    ({ name, scope, value }) => {
      valueRef.current = produce(valueRef.current, (settings) => {
        settings[scope][name] = value;
      });

      notifySubscribers();

      const fullSettingName = getFullSettingName({ name, scope, tenant });
      const stringifiedValue = JSON.stringify(value);

      collection.upsert({
        name: fullSettingName,
        settingType:
          scope === 'global' ? SettingType.GLOBAL : SettingType.PERSONAL,
        value: stringifiedValue,
      });

      setSettingMutation({
        variables: {
          setting: {
            name: fullSettingName,
            personal: scope !== 'global',
            value: stringifiedValue,
          },
        },
      });
    },
    [collection, tenant, notifySubscribers, setSettingMutation]
  );

  const syncSettings = useCallback<SettingsContext['syncSettings']>(
    (arrayToSync) => {
      valueRef.current = produce(valueRef.current, (settings) => {
        arrayToSync.forEach(({ scope, name, value }) => {
          settings[scope][name] = value;
        });
      });

      notifySubscribers();

      arrayToSync.forEach(({ scope, name, value }) => {
        try {
          const fullSettingName = getFullSettingName({ name, scope, tenant });
          const stringifiedValue = JSON.stringify(value);

          collection.upsert({
            name: fullSettingName,
            settingType:
              scope === 'global' ? SettingType.GLOBAL : SettingType.PERSONAL,
            value: stringifiedValue,
          });
        } catch (err) {
          console.error(err);
        }
      });
    },
    [notifySubscribers, collection, tenant]
  );

  const [deleteSettingMutation] = useDeleteSettingMutation();

  // Delete the setting value, delete the corresponding row in the DB and send a
  // delete mutation.
  const deleteSetting = useCallback<SettingsContext['deleteSetting']>(
    ({ name, scope }) => {
      if (scope !== 'user' && scope !== 'tenant') {
        throw new Error(
          `Cannot edit setting "${name}" with scope "${scope}".` +
            ' Only setting with scope "user" or "tenant" can be edited.'
        );
      }

      valueRef.current = produce(valueRef.current, (settings) => {
        delete settings[scope][name];
      });

      notifySubscribers();

      const fullSettingName = getFullSettingName({ name, scope, tenant });

      collection
        .find({
          selector: {
            name: fullSettingName,
            settingType: SettingType.PERSONAL,
          },
        })
        .remove();

      deleteSettingMutation({
        variables: {
          setting: {
            name: fullSettingName,
            personal: true,
          },
        },
      });
    },
    [collection, tenant, notifySubscribers, deleteSettingMutation]
  );

  // Memoize the context value to avoid re-renders.
  return useMemo(
    () => ({
      isLoading,
      getValue,
      subscribe,
      setSetting,
      deleteSetting,
      syncSettings,
    }),
    [isLoading, getValue, subscribe, setSetting, deleteSetting, syncSettings]
  );
}

/**
 * Derive the full setting name that is used in the DB from setting scope and
 * local name.
 */
function getFullSettingName({
  name,
  scope,
  tenant,
}: {
  name: string;
  scope: SettingScope;
  tenant: number;
}): string {
  if (UNPREFIXED_USER_SETTINGS.includes(name)) {
    if (scope !== 'user') {
      throw new Error(
        'Unprefixed personal settings must be used with scope "user"'
      );
    }

    return name;
  }

  const fullName = [
    scope === 'tenant' || scope === 'user' ? SETTING_NAME_PREFIX : null,
    scope === 'tenant' ? `t-${tenant}.` : null,
    name,
  ]
    .filter(Boolean)
    .join('');

  return fullName;
}
