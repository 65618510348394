import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class ArticleGroup<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: index */
  index?: number;
  /** Alias: level */
  level?: number;
  /** Alias: name */
  name?: string;
  /** Alias: artikelNummerLogik */
  artikelNummerLogik?: string;
  /** Alias: obergruppe */
  obergruppe?: string;
  /** Alias: lang2 */
  lang2?: string;
  /** Alias: lang3 */
  lang3?: string;
  /** Alias: lang4 */
  lang4?: string;
  /** Alias: lang5 */
  lang5?: string;
  /** Alias: lang6 */
  lang6?: string;
  /** Alias: lang7 */
  lang7?: string;

  __typename?: string;
}

const fields: FieldDefinitions<ArticleGroup> = {
  id: {
    alias: 'code',
  },
  index: {
    alias: 'index',
  },
  level: {
    alias: 'level',
  },
  name: {
    alias: 'name',
  },
  artikelNummerLogik: {
    alias: 'artikelNummerLogik',
  },
  obergruppe: {
    alias: 'obergruppe',
  },
  lang2: {
    alias: 'lang2',
  },
  lang3: {
    alias: 'lang3',
  },
  lang4: {
    alias: 'lang4',
  },
  lang5: {
    alias: 'lang5',
  },
  lang6: {
    alias: 'lang6',
  },
  lang7: {
    alias: 'lang7',
  },
  __typename: {
    alias: '__typename',
  },
};

export const articleGroupEntityDefinition: EntitiyDefinition<ArticleGroup> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'ArtikelGruppe',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
