import { gql, useApolloClient } from '@apollo/client';
import { set } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import {
  FieldDefinitions,
  SortDirection,
} from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { EntityByLayoutType } from '@work4all/models/lib/GraphQLEntities';
import { WidgetsDefinitions } from '@work4all/models/lib/Layout';

import { useRefetchOnEntityChanges } from '../../hooks';
import { fieldDefinitions } from '../../hooks/data-provider/definitons/fieldDefinitions';
import { buildQuery } from '../../hooks/data-provider/utils/buildQuery';
import {
  DOCUMENT_FILTER_EXCLUDE_CHILD_DOCUMENTS,
  PARENT_DOCUMENT_ID_PROPERTY,
} from '../../prefilters/document';
import { entityHasProperty } from '../helpers/entityHelper';

export const useLayoutsData = (
  fileId: number,
  contactId: number | null,
  definitions: WidgetsDefinitions | undefined,
  querySize: number,
  querySizeByWidgetId?: Record<string, number>
) => {
  const [loadingRequest, setLoadingRequest] = useState<Record<number, boolean>>(
    {}
  );

  const requestData = useMemo(() => {
    const allQueryParts = [];
    const requestIdToWidgetConfig = {};
    const entities = [];
    const requestByGroup: Record<string, string[]> = {};
    definitions?.definition.forEach((group) => {
      requestByGroup[group.name] = requestByGroup[group.name] || [];
      for (const definition of group.widgets) {
        for (const entity of definition.config.entities) {
          const key = EntityByLayoutType[entity.entityTypeName];
          entities.push(key);
          const fields = {};

          for (const column of entity.ui.columns) {
            set(fields, column.accessor, null);
          }

          if (entity.ui.rowModifiers) {
            for (const modifier of entity.ui.rowModifiers.filter(
              (rm) => rm.type === 'StyleModifier'
            )) {
              for (const rule of modifier.rules) {
                for (const property of Object.keys(rule.condition)) {
                  set(fields, property, null);
                }
              }
            }
          }

          // Add `id` field to all entities so the response can be normalized.
          addIdFields(fields, key);
          const filter: Record<
            string,
            | Record<string, string>
            | Record<string, Record<string, string | number>>[]
          >[] = [...entity.filters];
          if (definitions.layoutType === 'PROJEKT') {
            const _filter: Record<string, Record<string, string>>[] = [
              { projectId: { $eq: fileId.toString() } },
            ];
            /**
             * according to Sascha "Incoming invoices have multiple projects, so also the field projectCode is not correct (deprecated)."
             * so for now we'll keep filtering inboundInvoice by projectId
             * https://work4all.slack.com/archives/C03LXTT1RL4/p1683279484778709
             */
            if (key !== 'inboundInvoice') {
              _filter.push({
                'project.parentProject.id': { $eq: fileId.toString() },
              });
            }

            filter.push({ $or: _filter });
          } else {
            if (key === Entities.project) {
              filter.push({
                [definitions.layoutType === 'KUNDE'
                  ? 'customerId'
                  : 'supplierId']: { $eq: fileId.toString() },
              });
              if (contactId) {
                filter.push({
                  [definitions.layoutType === 'KUNDE'
                    ? 'customerContactId'
                    : 'supplierContactId']: { $eq: contactId.toString() },
                });
              }
            } else {
              filter.push({ businessPartnerId: { $eq: fileId.toString() } });
              if (
                entityHasProperty(
                  EntityByLayoutType[entity.entityTypeName],
                  'businessPartnerType'
                )
              ) {
                filter.push({
                  businessPartnerType: { $eq: definitions.layoutType },
                });
              }
              if (
                entityHasProperty(
                  EntityByLayoutType[entity.entityTypeName],
                  'contactId'
                ) &&
                contactId
              ) {
                filter.push({ contactId: { $eq: contactId.toString() } });
              }
            }
          }

          if (key === Entities.document) {
            const hasParentDocumentFilter = filter.some((filterPart) => {
              return PARENT_DOCUMENT_ID_PROPERTY in filterPart;
            });

            if (!hasParentDocumentFilter) {
              filter.push(DOCUMENT_FILTER_EXCLUDE_CHILD_DOCUMENTS);
            }
          }

          const entityQuery = buildQuery(
            {
              entity: key,
              sort: [
                {
                  field: entity.sortField,
                  direction: SortDirection.DESCENDING,
                },
              ],
              filter,
              data: fields,
            },
            querySizeByWidgetId?.[definition.id] || querySize
          );
          //push the filter for the active layout
          const varsWithDefaults = {
            queryPage: 0,
            rewriteInlineAttachements: false,
            categoryCodes: null,
            ...entityQuery.variables,
          };
          let query = entityQuery.gen.queryInner;
          Object.keys(varsWithDefaults).forEach((name) => {
            let value = varsWithDefaults[name];
            if (typeof value === 'string' && name !== 'querySortOrder') {
              value = `"${value.replace(/"/g, '\\"')}"`;
            }
            query = query.replace(`$${name}`, value);
          });
          const mappedName = `req${allQueryParts.length + 1}`;
          requestIdToWidgetConfig[mappedName] = definition;
          allQueryParts.push(`${mappedName}:${query.trim()}\n`);
          requestByGroup[group.name].push(`${mappedName}:${query.trim()}\n`);
        }
      }
    });

    return {
      allQuery: allQueryParts.join('\n'),
      reqMap: requestIdToWidgetConfig,
      entities: entities,
      requestByGroup,
    };
  }, [definitions, fileId, contactId, querySize, querySizeByWidgetId]);

  const client = useApolloClient();
  useEffect(() => {
    const dataSubscriptions = [];
    if (requestData.requestByGroup) {
      Object.keys(requestData.requestByGroup).forEach(
        (groupRequestsKey, idx) => {
          const groupRequests = requestData.requestByGroup[groupRequestsKey];
          setLoadingRequest((current) => ({ ...current, [idx]: true }));
          const response = client.watchQuery({
            query: gql`
          query getInlinedLayoutData${idx} {
            ${groupRequests.join('\n')}
          }
          `,
            context: {
              singleBatch: true,
            },
          });

          const sub = response.subscribe((response) => {
            //update all the data that belongs to your set of widgets
            setWidgetsData((current) => {
              return { ...current, ...response.data };
            });
            setLoadingRequest((current) => ({ ...current, [idx]: false }));
          });
          dataSubscriptions.push(sub);
        }
      );

      return () => {
        dataSubscriptions.forEach((sub) => {
          sub.unsubscribe();
        });
      };
    }
  }, [
    client,
    requestData.allQuery,
    requestData.reqMap,
    requestData.requestByGroup,
  ]);

  useRefetchOnEntityChanges({
    entity: requestData.entities,
    refetch: () =>
      client.refetchQueries({
        include: Object.keys(requestData.requestByGroup).map(
          (_el, idx) => `getInlinedLayoutData-${idx}`
        ),
      }),
  });

  const [widgetsData, setWidgetsData] = useState<
    Record<
      string,
      {
        widgetTitle: string;
        widgetId: string;
        total: number;
        //eslint-disable-next-line
        data: Record<string, any>[];
      }
    >[]
  >([]);

  return useMemo(() => {
    const mappedData: Record<
      string,
      {
        widgetTitle: string;
        widgetId: string;
        totalCount: number;
        //eslint-disable-next-line
        data: Record<string, any>[];
      }
    > = Object.keys(widgetsData).reduce((acc, reqId) => {
      const data = widgetsData[reqId];
      const widgetDefinition = requestData.reqMap[reqId];

      if (widgetDefinition) {
        //the widget definitions may be gone when logging our but data is requested
        acc[widgetDefinition.id] = {
          widgetId: widgetDefinition.id,
          widgetTitle: widgetDefinition.title,
          totalCount: data.total,
          data: data.data,
        };
      }
      return acc;
    }, {});

    return {
      data: mappedData,
      loading:
        Object.keys(loadingRequest).find(
          (key) => loadingRequest[key] === true
        ) !== undefined,
    };
  }, [loadingRequest, requestData.reqMap, widgetsData]);
};

/**
 * Adds `id` field to the fields list if the it is not present and the entity
 * has a definition for it. Recursively repeats the process for nested entities.
 *
 * This is required for the Apollo cache normalization to work properly.
 */
//eslint-disable-next-line
function addIdFields(fields: any, entity: Entities): void {
  //eslint-disable-next-line
  const definition = fieldDefinitions[entity] as FieldDefinitions<any>;

  // If the entity does not have `id` field, there is nothing to do. There is no
  // reason to check nested entities, because it will be impossible to normalize
  // the cache anyway.
  if (!definition.id) {
    return;
  }

  fields.id = null;

  // If the entity has nested entities, add `id` field to the fields list.
  const nestedFields = Object.entries(fields).filter(
    ([_, value]) => value !== null
  );

  for (const [key, value] of nestedFields) {
    const def = definition[key.toLowerCase()];

    if (def && def.entity) {
      addIdFields(
        value,
        Array.isArray(def.entity) ? def.entity[0] : def.entity
      );
    }
  }
}
