import styles from './CreateDocumentEntry.module.scss';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import CloudIcon from '@mui/icons-material/Cloud';
import UploadFile from '@mui/icons-material/UploadFile';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { ReactComponent as WordDocIcon } from '@work4all/assets/icons/word_doc.svg';

import { FileButton } from '@work4all/components/lib/input/FileButton';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input';

import { assertNever } from '@work4all/utils/lib/assert';

import { considerExternalSourcesRegExp } from './DocumentsOverlayController';

type CreateDocumentStep = 'root' | 'link';

export type DocumentInit =
  | { type: 'file'; content: File[] }
  | { type: 'link'; content: string }
  | { type: 'template' };

export const CreateDocumentEntry = ({
  multiple,
  onInitReady,
}: {
  multiple: boolean;
  onInitReady: (init: DocumentInit) => void;
}) => {
  const [step, setStep] = useState<CreateDocumentStep>('root');

  switch (step) {
    case 'root':
      return (
        <CreateDocumentRoot
          multiple={multiple}
          onFilesSelect={(files) => {
            onInitReady({ type: 'file', content: files });
          }}
          onCreateFromLinkClick={() => {
            setStep('link');
          }}
          onCreateFromTemplate={() => {
            onInitReady({ type: 'template' });
          }}
        />
      );

    case 'link':
      return (
        <CreateDocumentFromLink
          onConfirm={(link) => {
            onInitReady({ type: 'link', content: link });
          }}
          onCancel={() => {
            setStep('root');
          }}
        />
      );

    default:
      assertNever(step, `Unknown step ${step}`);
  }
};

interface CreateDocumentRootProps {
  multiple: boolean;
  onFilesSelect: (files: File[]) => void;
  onCreateFromLinkClick: () => void;
  onCreateFromTemplate: () => void;
}

function CreateDocumentRoot(props: CreateDocumentRootProps) {
  const {
    multiple,
    onFilesSelect,
    onCreateFromLinkClick,
    onCreateFromTemplate,
  } = props;

  const { t } = useTranslation();

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    multiple,
    onDrop(acceptedFiles) {
      if (acceptedFiles.length > 0) {
        onFilesSelect(acceptedFiles);
      }
    },
  });

  const handleCreateFromLinkClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onCreateFromLinkClick();
  };
  const handleCreateFromTemplate = (event: React.MouseEvent) => {
    event.stopPropagation();
    onCreateFromTemplate();
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      position="relative"
      {...getRootProps()}
    >
      <Grid
        item
        xs={3}
        className={clsx({
          [styles.dropzoneActive]: isDragAccept,
        })}
      >
        <Stack
          spacing={2}
          className={clsx(styles.actionBox, {
            [styles.canDrop]: isDragAccept,
          })}
        >
          <Typography color="secondary">
            {t('FILE.createFrom.title')}
          </Typography>

          <Stack alignItems="flex-start">
            <FileButton {...getInputProps()}>
              {({ onClick }) => {
                return (
                  <Button
                    size="large"
                    startIcon={<UploadFile />}
                    onClick={onClick}
                  >
                    {multiple
                      ? t('FILE.createFrom.upload_many')
                      : t('FILE.createFrom.upload')}
                  </Button>
                );
              }}
            </FileButton>

            <Button
              size="large"
              startIcon={<CloudIcon />}
              onClick={handleCreateFromLinkClick}
            >
              {t('FILE.createFrom.link')}
            </Button>

            <Button
              size="large"
              startIcon={<WordDocIcon />}
              onClick={handleCreateFromTemplate}
            >
              {t('FILE.createFrom.template')}
            </Button>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}

function CreateDocumentFromLink({
  onConfirm,
  onCancel,
}: {
  onConfirm: (link: string) => void;
  onCancel: () => void;
}) {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');

  const link = inputValue.trim();

  const isValid = link.length > 0;

  const handleConfirmClick = () => {
    //todo should we enhance links or not?
    let linkToRes = link;
    if (!considerExternalSourcesRegExp.exec(link)) {
      linkToRes = `https://${link}`;
    }
    onConfirm(linkToRes);
  };

  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      <Stack spacing={2} flex={1} padding={4} maxWidth={600}>
        <Typography color="secondary">
          {t('FILE.createFromLink.title')}
        </Typography>

        <LabeledInput
          label={t('FILE.createFromLink.inputLabel')}
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
        />

        <Stack direction="row" justifyContent="space-between">
          <Button size="large" startIcon={<ArrowBackIcon />} onClick={onCancel}>
            {t('FILE.createFromLink.return')}
          </Button>

          <Button
            disabled={!isValid}
            size="large"
            startIcon={<CheckIcon />}
            onClick={handleConfirmClick}
          >
            {t('FILE.createFromLink.continue')}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
