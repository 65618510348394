import { useMemo, useState } from 'react';

import { IStackItem } from '@work4all/components/lib/navigation/history-stack';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PdfEditorOverlayController } from './PdfEditorOverlayController';

interface Props {
  entityType: Entities;
  docId?: string | number;
  entityId: string | number;
  onClose?: () => void;
}

export const usePdfEditorOverlayMask = (props: Props) => {
  const { onClose, entityId, entityType, docId } = props;

  const [pdfEditPopoverState, setPdfEditPopoverState] =
    useState<boolean>(false);

  const editPdfView = useMemo<IStackItem>(() => {
    return {
      view: (
        <PdfEditorOverlayController
          docId={entityType === Entities.inboundInvoice ? docId : entityId}
          entityId={entityId}
          entityType={entityType}
          onClose={() => {
            setPdfEditPopoverState(false);
            onClose?.();
          }}
        />
      ),
    };
  }, [docId, entityId, entityType, onClose]);

  const pdfEditOverlay = useMemo(() => {
    return (
      <NavigationOverlay
        open={pdfEditPopoverState}
        initialView={editPdfView}
        withBreadcrumbs={true}
        close={() => {
          setPdfEditPopoverState(false);
        }}
      />
    );
  }, [editPdfView, pdfEditPopoverState]);

  const open = () => {
    setPdfEditPopoverState(true);
  };
  const close = () => {
    setPdfEditPopoverState(false);
  };

  return { pdfEditOverlay, open, close };
};
