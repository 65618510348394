import styles from './styles.module.scss';

import { ListItem } from '@mui/material';
import clsx from 'clsx';
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { DndTypes } from '@work4all/utils/lib/variables';

import { IFavoriteLink } from '../../../../containers/more/data/favorite-links';
import { FavoriteLinkDragObject } from '../../../../dnd/drag-objects';
import { getHoverPosition } from '../../../../dnd/get-hover-position';
import { useEmptyDragPreview } from '../../../../dnd/use-empty-drag-preview';

function canDropLink({
  folderId,
  link,
  itemType,
  item,
}: {
  folderId?: string;
  link: IFavoriteLink;
  itemType: unknown;
  item: unknown;
}) {
  return (
    folderId == null &&
    (itemType === DndTypes.LINK ||
      (itemType === DndTypes.FAVORITE_LINK &&
        (item as FavoriteLinkDragObject).id !== link.id))
  );
}

export interface IPopoverListLinkItemProps {
  index: number;
  link: IFavoriteLink;
  active?: boolean;
  folderId?: string;
  onClick: (link: IFavoriteLink) => void;
  onContextMenu?: React.MouseEventHandler;
  onMove: (index: number) => void;
  onDrop: (type: unknown, item: unknown) => void;
  onDropOnLink: (link: IFavoriteLink, type: unknown, item: unknown) => void;
}

export function PopoverListLinkItem({
  index,
  link,
  active = false,
  folderId,
  onClick,
  onContextMenu,
  onMove,
  onDrop,
  onDropOnLink,
}: IPopoverListLinkItemProps) {
  const ref = useRef<HTMLDivElement>(null);

  const [, drag, preview] = useDrag<FavoriteLinkDragObject, unknown, unknown>({
    type: DndTypes.FAVORITE_LINK,
    item: {
      id: link.id,
      folderId,
      text: link.name,
    },
  });

  useEmptyDragPreview(preview);

  const [isOver, drop] = useDrop({
    accept: [
      DndTypes.LINK,
      DndTypes.FAVORITE_LINK,
      DndTypes.FAVORITE_LINKS_FOLDER,
    ],
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }

      const position = getHoverPosition(
        monitor.getClientOffset(),
        ref.current.getBoundingClientRect()
      );

      const itemType = monitor.getItemType();

      if (position.over && canDropLink({ folderId, link, itemType, item })) {
        onMove(-1);
      } else if (position.before) {
        onMove(index);
      } else if (position.after) {
        onMove(index + 1);
      }
    },
    drop: (item, monitor) => {
      if (!ref.current) {
        return;
      }

      const position = getHoverPosition(
        monitor.getClientOffset(),
        ref.current.getBoundingClientRect()
      );

      const itemType = monitor.getItemType();

      if (position.over && canDropLink({ folderId, link, itemType, item })) {
        onDropOnLink(link, itemType, item);
      } else {
        onDrop(itemType, item);
      }
    },
    collect: (monitor) => {
      if (!monitor.isOver()) {
        return false;
      }

      const position = getHoverPosition(
        monitor.getClientOffset(),
        ref.current.getBoundingClientRect()
      );

      const itemType = monitor.getItemType();
      const item = monitor.getItem();

      return position.over && canDropLink({ folderId, link, itemType, item });
    },
  });

  drag(drop(ref));

  return (
    <ListItem
      ref={ref}
      button
      className={clsx(
        styles.popoverItem,
        isOver && styles.popoverLinkItemOver,
        active && styles.active
      )}
      onClick={() => onClick(link)}
      onContextMenu={onContextMenu}
    >
      {link.name}
    </ListItem>
  );
}
