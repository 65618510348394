import { Duration } from 'luxon';

export type IRequiredTimeCellProps = {
  timePassed: Duration;
  value: number;
  column: {
    cellParams: {
      convertTo?: 'hours' | 'days';
    };
  };
};

export const RequiredTimeCell = (props: IRequiredTimeCellProps) => {
  const {
    value,
    column: { cellParams },
  } = props;
  if (cellParams && cellParams.convertTo === 'hours') {
    return value * 8;
  }
  return value;
};
