export type ITimeCellProps = {
  value: string;
  column: {
    cellParams: {
      convertTo?: 'days' | 'hours';
    };
  };
};

export const TimeCell = (props: ITimeCellProps) => {
  const {
    value,
    column: { cellParams },
  } = props;
  if (cellParams && cellParams.convertTo === 'days') {
    return parseInt(value, 10) / 8;
  }
  return value;
};
