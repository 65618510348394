import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';

import { ControllerPlus } from '../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../form-plus/use-form-context-plus';
import { ControlWrapper } from '../../../../../../components/control-wrapper';
import { useMaskContext } from '../../../../../../hooks/mask-context';
import { DocumentMaskFormValue } from '../../../../types';

import { ProjectFolderPickerField } from './ProjectFolderPicker';

export function Target() {
  const { t } = useTranslation();

  const { control, watch } = useFormContextPlus<DocumentMaskFormValue>();

  const mask = useMaskContext();

  const project = watch('project');

  const subDirs = useMemo(() => {
    return (
      project?.projectSubDirectories
        ?.slice()
        .sort((a, b) => (a.name > b.name ? 1 : -1)) ?? []
    );
  }, [project]);

  const shouldShowTarget = mask.isCreateMode && subDirs.length > 0;

  if (!shouldShowTarget) return null;

  return (
    <Collapse title={t('COMMON.TARGET')} defaultOpen>
      <ControlWrapper>
        <ControllerPlus
          name="assignedToFolder"
          control={control}
          render={({ field }) => {
            return (
              <ProjectFolderPickerField
                dataSet={subDirs}
                {...field}
                value={
                  field.value
                    ? { id: field.value.id, name: field.value.name }
                    : null
                }
              />
            );
          }}
        />
      </ControlWrapper>
    </Collapse>
  );
}
