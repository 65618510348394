import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';

import { IFilterPickerConfig } from '../../../../components/entity-picker/components/list-entity-picker';
import { SupplierPicker } from '../../../../components/entity-picker/supplier-picker/SupplierPicker';
import { FilterCard } from '../filter-card/FilterCard';

export type ISupplierFilterCardProps = {
  title: string;
  value: Supplier[] | null;
  onChange: (value: Supplier[]) => void;
  expanded: boolean;
  onExpandedChange: (val: boolean) => void;
  filterConfig?: IFilterPickerConfig;
};

export function SupplierFilterCard(props: ISupplierFilterCardProps) {
  const { title, value, onChange, expanded, onExpandedChange, filterConfig } =
    props;

  const { t } = useTranslation();

  const filterValueText: string = useMemo(() => {
    if (!value || value.length === 0) return null;
    return value.length + ' ' + t('COMMON.CHOSEN');
  }, [value, t]);

  return (
    <FilterCard
      title={title}
      filterValue={filterValueText}
      filterValueSet={!!filterValueText}
      expanded={expanded}
      onExpandedChange={onExpandedChange}
      onValueDelete={() => {
        onChange([]);
      }}
    >
      <SupplierPicker
        layout="advanced"
        multiple={true}
        value={value}
        onChange={(newValue) => {
          onChange(newValue);
        }}
        filterConfig={filterConfig}
      />
    </FilterCard>
  );
}
