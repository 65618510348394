import styles from './TableQuickFilters.module.scss';

export interface TableQuickFiltersProps {
  children?: React.ReactNode;
}

export function TableQuickFilters(props: TableQuickFiltersProps) {
  const { children } = props;

  return <div className={styles.tableQuickFilters}>{children}</div>;
}
