import { noop } from 'lodash';
import { createContext, useContext } from 'react';

export interface ApolloExtrasContextValue {
  /**
   * Indicates whether or not the default automatic cache invalidation after
   * mutations invoked from `useDataMutation` and `useDeleteEntity` hooks should
   * be disabled.
   *
   * This was an older approach used to simplify state management and refetch
   * all queries upon changes, but this is starting ot cause problems. Instead
   * for new pages you can use `useEntityEvents` hook to subscribe to changes
   * and manually refetch affected queries from your component.
   *
   * This flag is global and if set to `true` none of the mutations will cause
   * cache invalidation. It can still be manually controlled with
   * `useDataMutation` hook's `resetStore` option.
   */
  isAutoInvalidationDisabled: boolean;

  /**
   * Disable automatic Apollo cache invalidation.
   *
   * @returns A function to that can be called to re-enable auto-invalidation
   * (if this is not currently disabled from somewhere else).   */
  disableAutoInvalidation: () => () => void;
}

const Context = createContext<ApolloExtrasContextValue>({
  isAutoInvalidationDisabled: false,
  disableAutoInvalidation: () => noop,
});

export const ApolloExtrasContextProvider = Context.Provider;

export function useApolloExtrasContext(): ApolloExtrasContextValue {
  const context = useContext(Context);
  return context;
}
