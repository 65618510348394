import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { CheckInOut } from '../Enums/CheckInOut.enum';
import { EMode } from '../Enums/EMode.enum';
import { TimeStampKind } from '../Enums/TimeStampKind.enum';

export class UserPresenceTimeInfoItem<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: art */
  kind?: TimeStampKind;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: checkInOut */
  checkInOut?: CheckInOut;
  /** Alias: zeitstempel */
  timestamp?: string;
  /** Alias: notiz */
  note?: string;

  __typename?: string;
}

const fields: FieldDefinitions<UserPresenceTimeInfoItem> = {
  id: {
    alias: 'code',
  },
  kind: {
    alias: 'art',
  },
  userId: {
    alias: 'benutzerCode',
  },
  checkInOut: {
    alias: 'checkInOut',
  },
  timestamp: {
    alias: 'zeitstempel',
  },
  note: {
    alias: 'notiz',
  },
  __typename: {
    alias: '__typename',
  },
};

export const userPresenceTimeInfoItemEntityDefinition: EntitiyDefinition<UserPresenceTimeInfoItem> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'KommtGehtZeitstempel',
      withPaginationWrapper: false,
      mutations: {
        upsert: {
          mutationName: 'upsertKommtGehtZeitstempel',
          args: [
            { name: 'input', type: 'InputKommtGehtZeitstempel!' },
            { name: 'drySave', type: 'Boolean' },
          ],
        },
      },
    },
    fieldDefinitions: fields,
  };
