import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { ChecklistPositionKind } from '../Enums/ChecklistPositionKind.enum';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { CustomField } from './CustomField.entity';
import { ProjectProcess } from './ProjectProcess.entity';
import { User } from './User.entity';

export class CheckListPosition<T extends EMode = EMode.entity> {
  /** Alias: art */
  kind?: ChecklistPositionKind;
  /** Alias: benutzerCodeErledigt */
  doneByUserId?: number;
  /** Alias: benutzerCodeGeplant */
  plannedByUserId?: number;
  /** Alias: bisWann */
  until?: string;
  /** Alias: checked */
  isChecked?: boolean;
  /** Alias: checklisteCode */
  checkListId?: number;
  /** Alias: code */
  id?: number;
  /** Alias: datumErledigt */
  doneDate?: string;
  /** Alias: farbe */
  color?: string;
  /** Alias: kategorie1 */
  category1?: string;
  /** Alias: kategorie2 */
  category2?: string;
  /** Alias: lieferantenCode */
  supplierId?: number;
  /** Alias: name */
  name?: string;
  /** Alias: notiz */
  note?: string;
  /** Alias: nummer */
  number?: number;
  /** Alias: personGeplant */
  plannedBy?: string;
  /** Alias: positionsNummer */
  positionNumber?: string;
  /** Alias: prioritaet */
  hasPriority?: boolean;
  /** Alias: projektePlanungCode */
  projectProcessId?: number;
  /** Alias: vorlageProjektDatumBezug */
  templateProjectDateRelation?: string;
  /** Alias: benutzerErledigt */
  userDone?: User<T>;
  /** Alias: benutzerGeplant */
  userPlanned?: User<T>;
  /** Alias: projektVorgang */
  projectProcess?: ProjectProcess<T>;
  /** Alias: customFields */
  customFieldList?: CustomField<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<CheckListPosition> = {
  kind: {
    alias: 'art',
  },
  doneByUserId: {
    alias: 'benutzerCodeErledigt',
  },
  plannedByUserId: {
    alias: 'benutzerCodeGeplant',
  },
  until: {
    alias: 'bisWann',
  },
  isChecked: {
    alias: 'checked',
  },
  checkListId: {
    alias: 'checklisteCode',
  },
  id: {
    alias: 'code',
  },
  doneDate: {
    alias: 'datumErledigt',
  },
  color: {
    alias: 'farbe',
  },
  category1: {
    alias: 'kategorie1',
  },
  category2: {
    alias: 'kategorie2',
  },
  supplierId: {
    alias: 'lieferantenCode',
  },
  name: {
    alias: 'name',
  },
  note: {
    alias: 'notiz',
  },
  number: {
    alias: 'nummer',
  },
  plannedBy: {
    alias: 'personGeplant',
  },
  positionNumber: {
    alias: 'positionsNummer',
  },
  hasPriority: {
    alias: 'prioritaet',
  },
  projectProcessId: {
    alias: 'projektePlanungCode',
  },
  templateProjectDateRelation: {
    alias: 'vorlageProjektDatumBezug',
  },
  userDone: {
    alias: 'benutzerErledigt',
    entity: Entities.user,
  },
  userPlanned: {
    alias: 'benutzerGeplant',
    entity: Entities.user,
  },
  projectProcess: {
    alias: 'projektVorgang',
    entity: Entities.projectProcess,
  },
  customFieldList: {
    alias: 'customFields',
    entity: Entities.customField,
  },
  __typename: {
    alias: '__typename',
  },
};

export const checkListPositionEntityDefinition: EntitiyDefinition<CheckListPosition> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'ChecklistePosition',
      withPaginationWrapper: false,
      mutations: {
        upsert: {
          mutationName: 'upsertChecklistePosition',
          args: [
            { name: 'input', type: 'InputChecklistePosition!' },
            { name: 'drySave', type: 'Boolean' },
          ],
        },
      },
    },
    fieldDefinitions: fields,
  };
