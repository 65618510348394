import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { DatabaseType } from '../Enums/DatabaseType.enum';
import { EMode } from '../Enums/EMode.enum';
import { ObjectType } from '../Enums/ObjectType.enum';

export class EntitySchema<T extends EMode = EMode.entity> {
  /** Alias: entityName */
  entityName?: string;
  /** Alias: jsonSchema */
  jsonSchema?: string;
  /** Alias: objectType */
  objectType?: ObjectType;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: mandantenCode */
  tenantId?: number;
  /** Alias: databaseType */
  databaseType?: DatabaseType;

  __typename?: string;
}

const fields: FieldDefinitions<EntitySchema> = {
  entityName: {
    alias: 'entityName',
  },
  jsonSchema: {
    alias: 'jsonSchema',
  },
  objectType: {
    alias: 'objectType',
  },
  updateTime: {
    alias: 'updateTime',
  },
  tenantId: {
    alias: 'mandantenCode',
  },
  databaseType: {
    alias: 'databaseType',
  },
  __typename: {
    alias: '__typename',
  },
};

export const entitySchemaEntityDefinition: EntitiyDefinition<EntitySchema> = {
  local: {},
  remote: {
    queryName: 'getSchema',
    fragmentName: 'EntitySchema',
    withPaginationWrapper: false,
    params: [
      { name: 'type', type: 'ObjectType' },
      { name: 'entityName', type: 'String' },
      { name: 'since', type: 'DateTime' },
    ],
  },
  fieldDefinitions: fields,
};
