import { useDataMutation } from '@work4all/data';

import { InputTicketAttachementsRelation } from '@work4all/models/lib/Classes/InputTicketAttachementsRelation.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

const TICKET_MUTATION_RESPONSE_DATA: Ticket = {
  id: null,
  attachmentList: [
    {
      id: null,
      fileName: null,
      displayName: null,
      note: null,
      fileInfos: {
        fileSize: null,
        fileEntityFilename: null,
        previewUrl: null,
        downloadUrl: null,
        previewMimeType: null,
        downloadMimeType: null,
      },
      lastModificationDate: null,
      user: {
        id: null,
        displayName: null,
      },
    },
  ],
};

export function useAttachMailToTicketMutation() {
  return useDataMutation<
    Ticket,
    EMode.upsert,
    { attachements: InputTicketAttachementsRelation }
  >({
    entity: Entities.ticket,
    mutationType: EMode.upsert,
    responseData: TICKET_MUTATION_RESPONSE_DATA,
    resetStore: false,
  });
}
