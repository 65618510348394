import React from 'react';

import { IGroupingsProps } from '../columns-visibility';
import { VisibleColumnsContext } from '../columns-visibility/visible-columns-context';
import { Popover } from '../popover/Popover';

export const GroupingsVisibilty: React.FC<IGroupingsProps> = React.memo(
  function GroupingsVisibilty(props) {
    const { visibleColumns, tableInstanceRef, popoverState, onResetColumns } =
      props;

    return (
      <VisibleColumnsContext.Provider value={visibleColumns}>
        <Popover
          popoverState={popoverState}
          tableInstanceRef={tableInstanceRef}
          type="columnsGrouping"
          onResetColumns={onResetColumns}
        />
      </VisibleColumnsContext.Provider>
    );
  }
);
