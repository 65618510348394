import { useLocation } from 'react-router-dom';

const removableRoutes = ['more', 'customers', 'suppliers', 'projects'];

export const useRenderMobileConfig = () => {
  const location = useLocation();

  //   this condition is used to remove the navigation bar from the list view.
  //   It's a temporary solution, until we figure out a better way or we refactor the pages.
  //   Tried to make the conditions as precise as possible, so the navBar would be removed from the lists when reached from the control center and the files
  if (
    location.pathname.includes('/entity/') &&
    removableRoutes.map((route) => location.pathname.includes(`/${route}/`))
  ) {
    return false;
  } else return true;
};
