import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';
import { LookupType } from '@work4all/models/lib/Enums/LookupType.enum';

import { IFilterPickerConfig } from '../../../../components/entity-picker/components/list-entity-picker';
import { LookUpPicker } from '../../../../components/entity-picker/look-up-picker/LookUpPicker';
import { FilterCard } from '../filter-card/FilterCard';

export type ILookUpFilterCardProps = {
  title: string;
  value: LookUp[] | null;
  onChange: (value: LookUp[]) => void;
  expanded: boolean;
  onExpandedChange: (val: boolean) => void;
  filterConfig?: IFilterPickerConfig;
  lookupType: LookupType;
};

export function LookUpFilterCard(props: ILookUpFilterCardProps) {
  const {
    title,
    value,
    onChange,
    expanded,
    onExpandedChange,
    filterConfig,
    lookupType,
  } = props;

  const { t } = useTranslation();

  const filterValueText: string = useMemo(() => {
    if (!value || value.length === 0) return null;

    return value.length + ' ' + t('COMMON.CHOSEN');
  }, [value, t]);

  return (
    <FilterCard
      title={title}
      filterValue={filterValueText}
      filterValueSet={!!filterValueText}
      expanded={expanded}
      onExpandedChange={onExpandedChange}
      onValueDelete={() => {
        onChange([]);
      }}
    >
      <LookUpPicker
        layout="advanced"
        multiple={true}
        value={value}
        lookupType={lookupType}
        onChange={(newValue) => {
          onChange(newValue);
        }}
        filterConfig={filterConfig}
      />
    </FilterCard>
  );
}
