import styles from './CollapsibleCard.module.scss';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  CardHeader,
  IconButton,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

import { Link } from '@work4all/data';

export interface CollapsibleCardProps extends CollapsibleCardHeaderProps {
  height?: 'auto' | 'fixed' | 'fill';
  sx?: SxProps<Theme>;
  children?: React.ReactChild;
  hideCollapseButton?: boolean;
}

export function CollapsibleCard(props: CollapsibleCardProps) {
  const {
    collapsed,
    height = 'auto',
    sx,
    children,
    hideCollapseButton,
    ...others
  } = props;

  const flex: Record<CollapsibleCardProps['height'], string> = {
    auto: '1 1 auto',
    fill: '1 1 0',
    fixed: '0 0 auto',
  };

  return (
    <Box
      className={styles.card}
      flex={collapsed ? '0 0 auto' : flex[height]}
      sx={sx}
    >
      <CollapsibleCardHeader
        collapsed={collapsed}
        hideCollapseButton={hideCollapseButton}
        {...others}
      />

      {collapsed ? null : children}
    </Box>
  );
}

interface CollapsibleCardHeaderProps {
  title: React.ReactNode;
  href?: string;
  collapsed: boolean;
  onCollapsedChange: (collapsed: boolean) => void;
  headerContent?: React.ReactChild;
  hideCollapseButton?: boolean;
}

function CollapsibleCardHeader(props: CollapsibleCardHeaderProps) {
  const {
    title,
    href,
    collapsed,
    onCollapsedChange,
    headerContent,
    hideCollapseButton,
  } = props;

  const renderTitle = () => {
    if (href) {
      return (
        <div className={'drag-handle'} style={{ pointerEvents: 'auto' }}>
          <Typography
            variant="h4"
            className={styles.title}
            component={Link}
            to={href}
          >
            {title}
          </Typography>
        </div>
      );
    }

    return (
      <div className={'drag-handle'} style={{ pointerEvents: 'auto' }}>
        <Typography variant="h4" className={styles.title} component="span">
          {title}
        </Typography>
      </div>
    );
  };

  return (
    <CardHeader
      className={styles.header}
      classes={{ action: styles.action }}
      title={renderTitle()}
      action={
        <div className={styles.actionsWrapper}>
          {headerContent}

          {!hideCollapseButton && (
            <IconButton
              color="default"
              onClick={() => onCollapsedChange(!collapsed)}
              size="medium"
            >
              {props.collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
          )}
        </div>
      }
    />
  );
}
