import { useUser } from '@work4all/data';

import { Letter } from '@work4all/models/lib/Classes/Letter.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { canEditLetter } from '@work4all/utils/lib/permissions';

import { MIME_TYPES } from '../../../preview/Preview';
import { IDocumentPreviewContainerProps } from '../document-preview';
import { DocumentPreview } from '../document-preview/DocumentPreview';
import { useEntityPreviewData } from '../hooks/use-entity-preview-data';

export interface ILetterPreviewContainerProps
  extends Omit<IDocumentPreviewContainerProps, 'documentId'> {
  letterId: number;
}

const requestedLetterData: Letter<EMode.query> = {
  id: null,
  fileInfos: {
    previewUrl: null,
    previewMimeType: null,
    fileServiceProviderInfos: {
      id: null,
      fileName: null,
      mimeType: null,
      fspUrl: null,
    },
    downloadUrl: null,

    // As the FileInfo type doesn't have an ID field, we need to also request
    // properties that are likely to be fetched by other components (the table)
    // so the Apollo cache is not invalidated.
    fileEntityFilename: null,
  },
  filePath: null,
  note: null,
};

export function LetterPreviewContainer(props: ILetterPreviewContainerProps) {
  const {
    letterId,
    onCloseClicked,
    onEditClicked,
    onShareClicked,
    onEditPdfClicked,
  } = props;
  const user = useUser();

  const letters = useEntityPreviewData<Letter>(
    [letterId],
    Entities.letter,
    requestedLetterData
  );

  if (!letters) return null;

  return (
    <DocumentPreview
      title={
        letters[0].note ||
        letters[0].fileInfos?.fileServiceProviderInfos?.fileName ||
        ''
      }
      url={`${letters[0].fileInfos.previewUrl}`}
      mimeType={letters[0].fileInfos?.previewMimeType as MIME_TYPES}
      noPreviewUrl={
        letters[0].fileInfos?.fileServiceProviderInfos?.fspUrl ||
        letters[0].fileInfos?.downloadUrl
      }
      onCloseClicked={onCloseClicked}
      onEditClicked={onEditClicked}
      filePath={letters[0]?.filePath}
      fspUrl={letters[0]?.fileInfos?.fileServiceProviderInfos?.fspUrl}
      downloadUrl={letters[0].fileInfos?.downloadUrl}
      iconProps={{
        showPreviewExternallyIcon: canEditLetter(user, letters[0]),
      }}
      onShareClicked={onShareClicked}
      onPdfEditClicked={onEditPdfClicked}
    />
  );
}
