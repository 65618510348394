import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';

import { EditTableEntry, IdType, OnEditPosition } from '../types';

export interface EditableActions<T extends EditTableEntry<TId>, TId, TContext> {
  onAddPosition: (context?: TContext) => void;
  onRemovePosition: (positionId: TId[]) => void;
  onMovePosition: (positionId: TId, index: number) => void;
  onEditPosition: (result: OnEditPosition<T>) => void;
}

export interface UseEditableStateProps<
  T extends EditTableEntry<TId>,
  TId,
  TContext
> extends EditableActions<T, TId, TContext> {
  positions: T[];
  mapAddContext?: (context: TContext) => T;
  mutateState?: (inputs: T[]) => EditTableResultEntry<T>[];
}

export interface UseEditableStateResult<
  T extends EditTableEntry<TId>,
  TId,
  TContext
> extends UseEditableStateProps<T, TId, TContext> {
  onCollapsePosition: (position: T) => void;
}

interface AddAction<T extends EditTableEntry<TId>, TId> {
  type: 'add';
  newEntry: T;
}

interface RemoveAction<TId> {
  type: 'remove';
  positionId: TId[];
}

interface MergeAction<T extends EditTableEntry<TId>, TId> {
  type: 'merge';
  positions: T[];
}

interface EditAction<T extends EditTableEntry<TId>, TId> {
  type: 'edit';
  result: OnEditPosition<T>;
}

interface MoveAction<TId> {
  type: 'move';
  positionId: TId;
  index: number;
}

type PositionStateAction<T extends EditTableEntry<TId>, TId> =
  | AddAction<T, TId>
  | RemoveAction<TId>
  | MergeAction<T, TId>
  | EditAction<T, TId>
  | MoveAction<TId>;

const createDataFetchReducer =
  <T extends EditTableEntry<TId>, TId>(
    mutateState: UseEditableStateProps<T, unknown, unknown>['mutateState']
  ) =>
  (
    state: EditTableResultEntry<T>[],
    action: PositionStateAction<T, TId>
  ): EditTableResultEntry<T>[] => {
    let newState: EditTableResultEntry<T>[] = [];
    switch (action.type) {
      case 'add':
        newState = [...state, action.newEntry];
        break;
      case 'move': {
        const { index: from, positionId } = action;
        const to = state.findIndex((x) => x.id === positionId);
        const p = [...state];
        [p[from], p[to]] = [p[to], p[from]];
        newState = p;
        break;
      }
      case 'merge':
        // not mutate a state
        return action.positions ? cloneDeep(action.positions) : [];
      case 'remove':
        newState = state.filter((x) => !action.positionId.includes(x.id));
        break;
      case 'edit': {
        const { position } = action.result;

        newState = state.map((pos) => {
          if (pos.id !== position.id) return pos;
          const val = cloneDeep(pos);

          Object.entries(position).forEach((x) => {
            const [field, value] = x;
            val[field] = value;
          });

          return val;
        });
        break;
      }
      default:
        newState = state;
    }
    return mutateState ? mutateState(newState) : newState;
  };

export type EditTableResultEntry<T> = T & {
  relation?: 'parent' | 'child' | 'none';
  collapsed?: boolean;
};

export function useEditableState<
  T extends EditTableEntry<TId>,
  TId = IdType,
  TContext = Record<string, unknown>
>(
  props: UseEditableStateProps<T, TId, TContext>
): UseEditableStateResult<T, TId, TContext> {
  const {
    positions,
    onAddPosition: onAddPositionOnServer,
    onRemovePosition: onRemovePositionOnServer,
    onEditPosition: onEditPositionOnServer,
    onMovePosition: onMovePositionOnServer,
    mapAddContext,
    mutateState,
  } = props;

  const reducer = createDataFetchReducer<T, TId>(mutateState);
  const [state, dispatch] = useReducer(reducer, []);

  const onRemovePosition = useCallback(
    (positionId: TId[]) => {
      dispatch({ type: 'remove', positionId });
      onRemovePositionOnServer(positionId);
    },
    [onRemovePositionOnServer]
  );

  const onEditPosition = useCallback(
    (result: OnEditPosition<T>) => {
      const entry = state.find((x) => x.id === result.position.id);
      const finalChange = Object.entries(result.position).filter((x) => {
        const [field, value] = x;
        return entry[field] !== value;
      });

      const position: T = finalChange.reduce((prev, [field, value]) => {
        prev[field] = value;
        return prev;
      }, {} as T);
      position.id = result.position.id;

      if (!finalChange.length) {
        return;
      }
      dispatch({ type: 'edit', result: { position } });
      onEditPositionOnServer({ position });
    },
    [onEditPositionOnServer, state]
  );

  const onAddPosition = useCallback(
    function (result: TContext) {
      if (mapAddContext)
        dispatch({ type: 'add', newEntry: mapAddContext(result) });
      onAddPositionOnServer(result);
    },
    [onAddPositionOnServer, mapAddContext]
  );

  const onMovePosition = useCallback(
    function (positionId: TId, index: number, finish: boolean = true) {
      dispatch({ type: 'move', positionId, index });
      if (finish) onMovePositionOnServer(positionId, index);
    },
    [onMovePositionOnServer]
  );

  useEffect(() => {
    dispatch({ type: 'merge', positions });
  }, [positions]);

  const [collapsedHeads, setCollapsedHeads] = useState<T[]>([]);
  const onCollapsePosition = useCallback((position: T) => {
    setCollapsedHeads((prev) => {
      const exist = prev.find((x) => x.id === position.id);
      if (exist) return prev.filter((x) => x.id !== position.id);
      return [...prev, position];
    });
  }, []);

  const resultState = useMemo(() => {
    const headsIds = collapsedHeads.map((x) => x.id);
    return state
      .filter((x) => !headsIds.includes(x.posId))
      .map((x) => {
        if (headsIds.includes(x.id)) return { ...x, collapsed: true };
        return x;
      });
  }, [state, collapsedHeads]);

  return {
    positions: resultState,
    onAddPosition,
    onRemovePosition,
    onMovePosition,
    onEditPosition,
    onCollapsePosition,
  };
}
