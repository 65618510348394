import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { StatisticsSalesVolumeByCostCenterRow } from './StatisticsSalesVolumeByCostCenterRow.entity';

export class StatisticsSalesVolumeByCostCenterColumn<
  T extends EMode = EMode.entity
> {
  /** Alias: rangeStart */
  rangeStart?: string;
  /** Alias: rangeEnd */
  rangeEnd?: string;
  /** Alias: name */
  name?: string;
  /** Alias: rowCount */
  rowCount?: number;
  /** Alias: rows */
  rows?: StatisticsSalesVolumeByCostCenterRow<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<StatisticsSalesVolumeByCostCenterColumn> = {
  rangeStart: {
    alias: 'rangeStart',
  },
  rangeEnd: {
    alias: 'rangeEnd',
  },
  name: {
    alias: 'name',
  },
  rowCount: {
    alias: 'rowCount',
  },
  rows: {
    alias: 'rows',
    entity: Entities.statisticsSalesVolumeByCostCenterRow,
  },
  __typename: {
    alias: '__typename',
  },
};

export const statisticsSalesVolumeByCostCenterColumnEntityDefinition: EntitiyDefinition<StatisticsSalesVolumeByCostCenterColumn> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'StatisticsSalesVolumeByCostCenterColumn',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
