import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { UserRights } from '../Enums/UserRights.enum';

export class UserRightDefinition<T extends EMode = EMode.entity> {
  /** Alias: key */
  key?: number;
  /** Alias: value */
  value?: UserRights;

  __typename?: string;
}

const fields: FieldDefinitions<UserRightDefinition> = {
  key: {
    alias: 'key',
  },
  value: {
    alias: 'value',
  },
  __typename: {
    alias: '__typename',
  },
};

export const userRightDefinitionEntityDefinition: EntitiyDefinition<UserRightDefinition> =
  {
    local: {},
    remote: {
      queryName: 'getUserrightDefinitions',
      fragmentName: 'UserrightDefinition',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
