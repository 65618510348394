import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

export type IProgressCellProps = {
  value: number;
};

export const ProgressCell = (props: IProgressCellProps) => {
  const { value } = props;
  return (
    <div
      style={{
        display: 'flex',
        width: '999px',
        maxWidth: '100%',
        height: '100%',
        alignItems: 'center',
      }}
    >
      <Progress value={value} />
    </div>
  );
};

interface LinearProgressProps {
  value: number;
}

type InputProps = {
  customColor: string;
};

const ColoredLinearProgress = styled(LinearProgress)<InputProps>(
  ({ customColor }) => ({
    height: 4,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#EEE',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: customColor,
    },
  })
);

export const Progress = (props: LinearProgressProps) => {
  const value = Math.min(100, Math.max(0, props.value));
  const lookUp = [
    {
      min: 0,
      max: 50,
      color: '#E14E2E',
    },
    {
      min: 50,
      max: 75,
      color: '#D9A931',
    },
    {
      min: 75,
      max: 100.1,
      color: '#67B443',
    },
  ];
  const customColor = lookUp.find(
    (x) => value >= x.min && value < x.max
  )?.color;
  return (
    <ColoredLinearProgress
      style={{ flex: 1 }}
      variant="determinate"
      value={value}
      customColor={customColor}
    />
  );
};
