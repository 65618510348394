function isText(current: ChildNode) {
  return current && current.nodeName.includes('text');
}

function hasNoText(current: HTMLElement) {
  return (
    !current.textContent &&
    !current.innerHTML?.includes('<br') &&
    current.nodeName !== 'HR'
  );
}

export const htmlUtils = {
  isText,
  hasNoText,
};
