import { Box } from '@mui/material';

import { Card } from '@work4all/components/lib/dataDisplay/card';

import { Panel } from '../../../../../components/panel/Panel';

import { Document } from './components/document/Document';

export const GeneralTabPanel = () => {
  return (
    <Panel style={{ height: '100%' }}>
      <Box padding="3rem 0 1rem 1rem" display="flex" flex="1">
        <Card
          noPadding
          style={{
            display: 'flex',
            flex: 1,
            maxWidth: '65rem',
            margin: 'auto',
            minHeight: '90rem',
          }}
        >
          <Document />
        </Card>
      </Box>
    </Panel>
  );
};
