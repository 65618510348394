import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { ProjectCategory } from './ProjectCategory.entity';

export class ProjectCategoryClass<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: name */
  name?: string;
  /** Alias: categories */
  categoryList?: ProjectCategory<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<ProjectCategoryClass> = {
  id: {
    alias: 'code',
  },
  name: {
    alias: 'name',
  },
  categoryList: {
    alias: 'categories',
    entity: Entities.projectCategory,
  },
  __typename: {
    alias: '__typename',
  },
};

export const projectCategoryClassEntityDefinition: EntitiyDefinition<ProjectCategoryClass> =
  {
    local: {},
    remote: {
      queryName: 'getProjectTopLevelCategories',
      fragmentName: 'ProjektOberkategorie',
      withPaginationWrapper: true,
      params: [
        { name: 'querySize', type: 'Int' },
        { name: 'queryPage', type: 'Int' },
        { name: 'querySortOrder', type: 'SortOrderStyle' },
        { name: 'querySortBy', type: 'String' },
        { name: 'filter', type: 'String' },
      ],
    },
    fieldDefinitions: fields,
  };
