import styles from './TableRow.module.scss';

import clsx from 'clsx';
import produce from 'immer';
import React, { useEffect } from 'react';
import { ReactNode, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { TableCommonProps } from 'react-table';

import { ReactComponent as DragIndicatorIcon } from '@work4all/assets/icons/drag-vertical.svg';

import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { IdType } from '../../types';
import { TableCell } from '../TableCell/TableCell';

import {
  DRAG_HANDLER_COLUMN_WIDTH,
  TABLE_ROW_DND_ITEM_TYPE,
} from './constants';

type ITableRowDndItem = {
  id: IdType;
  index: number;
};

export type ITableRowProps = TableCommonProps & {
  disabled?: boolean;
  id: IdType;
  index: number;
  moveRow: (originalIndex: number, currentIndex: number) => void;
  moveRowDone?: (id: IdType, index: number) => void;
  children?: ReactNode;
  kind?: ErpPositionsKind;
  onClickOutside?: (id: IdType) => void;
  isDraggable?: boolean;
  hasBorder?: boolean;
};

export const TableRow = (props: ITableRowProps) => {
  const {
    disabled = false,
    id,
    index,
    moveRow,
    moveRowDone,
    className,
    style,
    children,
    kind,
    onClickOutside,
    isDraggable = true,
    hasBorder = true,
    ...rest
  } = props;

  const dropRef = useRef<HTMLDivElement>(null);
  const dragRef = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop<ITableRowDndItem, unknown, never>({
    accept: TABLE_ROW_DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      setTimeout(() => {
        // Time to actually perform the action
        moveRow(dragIndex, hoverIndex);
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex;
      }, 10);
    },
  });

  const [{ isDragging }, drag, preview] = useDrag<
    ITableRowDndItem,
    unknown,
    { isDragging: boolean }
  >({
    type: TABLE_ROW_DND_ITEM_TYPE,
    item: { id, index },
    options: {
      dropEffect: 'move',
    },
    collect(monitor) {
      return {
        isDragging: monitor.isDragging(),
      };
    },
    end(item) {
      moveRowDone?.(item.id, item.index);
    },
  });

  preview(drop(dropRef));
  drag(dragRef);

  const modifiedStyle = produce(style, (draft) => {
    draft.width =
      (typeof draft.width === 'number' ? draft.width : parseInt(draft.width)) +
      DRAG_HANDLER_COLUMN_WIDTH;
    draft.opacity = isDragging ? 0 : 1;
  });

  const border: React.CSSProperties = {
    borderTopStyle: 'solid',
    borderColor: '#D3D4D8',
    borderWidth:
      kind === ErpPositionsKind.SEITENUMBRUCH ? 2 : hasBorder ? 1 : 0,
    marginTop: kind === ErpPositionsKind.SEITENUMBRUCH ? 1 : 0,
    width: '100%',
  };

  const elId = `position_row_${id}`;

  useEffect(() => {
    const onClick = (evt) => {
      const flyoutEl = document.getElementById(`${elId}`);
      let targetEl = evt.target; // clicked element

      do {
        if (targetEl === flyoutEl || targetEl.id === elId) {
          return;
        }
        targetEl = targetEl.parentNode;
      } while (targetEl);
      onClickOutside?.(id);
    };

    document.addEventListener('click', onClick);
    return () => {
      document.removeEventListener('click', onClick);
    };
  }, [id, elId, onClickOutside]);

  return (
    <div
      id={elId}
      key={elId}
      ref={dropRef}
      className={clsx(styles['table-row'], className)}
      style={{ ...modifiedStyle, ...border }}
      {...rest}
    >
      {isDraggable ? (
        disabled || !moveRowDone ? null : (
          <TableCell
            ref={dragRef}
            role="cell"
            className={clsx(styles['drag-handler'])}
            kind={kind}
          >
            <DragIndicatorIcon />
          </TableCell>
        )
      ) : (
        <TableCell className={styles['drag-empty']} />
      )}
      {children}
    </div>
  );
};
