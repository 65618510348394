import styles from './NavigationPopover.module.scss';

import { Popover, PopoverOrigin, PopoverProps } from '@mui/material';
import React from 'react';

import {
  IStackProviderProps,
  StackProvider,
  useHistoryStack,
} from '../../navigation/history-stack';

import { Content } from './components/content/Content';

const defaultAnchorOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const defaultTransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

interface INavigationPopoverProps
  extends IStackProviderProps,
    Partial<
      Pick<
        PopoverProps,
        | 'anchorOrigin'
        | 'transformOrigin'
        | 'open'
        | 'anchorEl'
        | 'disableScrollLock'
      >
    > {}

const PopoverWrapper: React.FC<INavigationPopoverProps> = (props) => {
  const { current } = useHistoryStack();

  return (
    <Popover
      anchorOrigin={props.anchorOrigin || defaultAnchorOrigin}
      transformOrigin={props.transformOrigin || defaultTransformOrigin}
      open={props.open}
      anchorEl={props.anchorEl}
      disableScrollLock={props.disableScrollLock ?? true}
      onClose={props.onClose}
      classes={{ paper: styles.root }}
      id={current ? current.title : 'no-view'}
    >
      <Content close={props.onClose} />
    </Popover>
  );
};

export const NavigationPopover: React.FC<INavigationPopoverProps> = (props) => {
  return (
    <StackProvider onClose={props.onClose} initialView={props.initialView}>
      <PopoverWrapper {...props} />
    </StackProvider>
  );
};
