import { Typography } from '@mui/material';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { BaseDataLanguage } from '@work4all/models/lib/Classes/BaseDataLanguage.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type IBaseDataLanguagePickerProps<TMultiple extends boolean> =
  IPickerProps<BaseDataLanguage, TMultiple>;

export function BaseDataLanguagePicker<TMultiple extends boolean>(
  props: IBaseDataLanguagePickerProps<TMultiple>
) {
  const { data, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...BASE_DATA_LANGUAGE_FIELDS, ...data };
  }, [data]);

  const request = useMemo<DataRequest>(() => {
    return {
      data: allFields,
      entity: Entities.baseDataLanguage,
      completeDataResponse: true,
      operationName: 'GetBaseDataLanguages',
    };
  }, [allFields]);

  const response = useDataProvider(request);

  // The data provider does not set the `total` property when
  // `completeDataResponse` is `true`.   So we have to set it manually.
  // Otherwise, the <ListEntityPicker /> will not be able to render the list.
  const patchedResponse = useMemo(() => {
    return { ...response, total: response.data.length };
  }, [response]);

  const renderItem = (baseDataLanguage: BaseDataLanguage) => {
    const label = baseDataLanguage.name;

    return (
      <Tooltip title={label}>
        <Typography variant="body2" noWrap>
          {label}
        </Typography>
      </Tooltip>
    );
  };

  return (
    <ListEntityPicker
      entity={Entities.user}
      data={allFields}
      filterBy={'name'}
      sortBy="name"
      {...rest}
      fixedDataSet={patchedResponse}
      renderItemContent={renderItem}
    />
  );
}

const BASE_DATA_LANGUAGE_FIELDS: BaseDataLanguage = {
  id: null,
  name: null,
};
