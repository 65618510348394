import styles from './Search.module.scss';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import clsx from 'clsx';
import React, { ChangeEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LabeledInput } from '../../labeled-input';

export const Search: React.FC<{
  label?: string;
  value?: string;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  fieldStyle?: 'underline' | 'fill';
}> = (props) => {
  const { t } = useTranslation();

  const [value, setValue] = useState('');
  const { onChange, fieldStyle = 'fill' } = props;
  const computedValue = props.value === undefined ? value : props.value;
  return (
    <LabeledInput
      label={props.label ?? t('INPUTS.QUICKSEARCH')}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e);
      }}
      value={computedValue}
      style={{ width: '100%', minWidth: '10rem' }}
      iconRight={
        computedValue.length === 0 ? (
          <SearchIcon className={styles.icon} />
        ) : (
          <CloseIcon
            className={clsx(styles.icon, styles.pointer)}
            onClick={() => {
              setValue('');
              onChange(null);
            }}
          />
        )
      }
      fieldStyle={fieldStyle}
    />
  );
};
