import { Tooltip, Typography } from '@mui/material';
import { useRef } from 'react';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { LedgerAccountPicker } from '@work4all/components/lib/components/entity-picker/ledger-account-picker/LedgerAccountPicker';

import { LedgerAccount } from '@work4all/models/lib/Classes/LedgerAccount.entity';

import { PickerTargetButton } from './PickerTargetButton';

/**
 * There's no enum for that so
 * 1 mean Anlage- und Kapitalkonten
 * 3 Abgrenzungskonten
 * 4 Betriebliche Aufwendungen
 * 8 Skonto-Eingangskonto
 */
const ARRAY_OF_ID_NAME = [1, 3, 4, 8];

export function LedgerAccountCell(props: {
  disabled?: boolean;
  value: LedgerAccount;
  onChange: (value: LedgerAccount | null) => void;
  label?: (value: LedgerAccount | null) => React.ReactNode;
  className?: string;
  hover?: boolean;
}) {
  const {
    disabled = false,
    value,
    onChange,
    label,
    className,
    hover = false,
  } = props;
  const popoverRef = useRef<EntityPickerPopover>(null);

  const resultingLabel = label ? (
    label(value)
  ) : value ? (
    <>
      <Typography component="span" variant="body2" color="text.tertiary">
        {value.number}
        {value.number && value.name ? ' | ' : ''}
      </Typography>
      <Typography component="span" variant="body2">
        {value.name}
      </Typography>
    </>
  ) : null;

  return (
    <EntityPickerPopover
      ref={popoverRef}
      picker={
        <LedgerAccountPicker
          value={value}
          onChange={onChange}
          multiple={false}
          prefilter={[{ kind: { $in: ARRAY_OF_ID_NAME } }]}
        />
      }
    >
      <Tooltip title={value?.name ?? ''}>
        <PickerTargetButton
          disabled={disabled}
          hover={hover}
          className={className}
        >
          {resultingLabel}
        </PickerTargetButton>
      </Tooltip>
    </EntityPickerPopover>
  );
}
