import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  CircularProgress,
  Dialog,
  Stack,
  Typography,
} from '@mui/material';
import { dialogClasses } from '@mui/material/Dialog';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TicketPicker } from '@work4all/components/lib/components/entity-picker/ticket-picker/TicketPicker';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { isCustomer, isSupplier } from './cells/contact-or-business-partner';

export interface AssignToTicketDialogProps {
  open: boolean;
  loading: boolean;
  error: boolean;
  businessPartner: Customer | Supplier | null;
  onClose: () => void;
  onAssign: (id: string) => void;
  onCreateTicket: () => void;
}

export function AssignToTicketDialog(props: AssignToTicketDialogProps) {
  const {
    open,
    loading,
    error,
    businessPartner,
    onClose,
    onAssign,
    onCreateTicket,
  } = props;

  function renderContent() {
    if (error) {
      return <DialogContentError onClose={onClose} />;
    }

    if (loading) {
      return <DialogContentLoading />;
    }

    return (
      <DialogContent
        businessPartner={businessPartner}
        onAssign={onAssign}
        onCreateTicket={onCreateTicket}
      />
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{
        [`.${dialogClasses.paper}`]: {
          height: 500,
        },
      }}
    >
      {renderContent()}
    </Dialog>
  );
}

function DialogContentError(props: Pick<AssignToTicketDialogProps, 'onClose'>) {
  const { onClose } = props;

  const { t } = useTranslation();

  return (
    <Stack direction="column" sx={{ flex: '1' }}>
      <Stack direction="column" sx={{ flex: '1', justifyContent: 'center' }}>
        <Typography variant="h3" align="center">
          {t('ASSIGN_INCOMING_EMAILS.CREATE_TICKET_ERROR')}
        </Typography>
      </Stack>

      <Button
        size="large"
        fullWidth
        color="primary"
        onClick={onClose}
        sx={{ flex: 'none' }}
      >
        {t('ALERTS.CLOSE')}
      </Button>
    </Stack>
  );
}

function DialogContentLoading() {
  const { t } = useTranslation();

  return (
    <Stack direction="column" sx={{ flex: '1' }}>
      <Stack
        direction="column"
        sx={{
          flex: '1',
          gap: '1rem',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />

        <Typography variant="h3" align="center">
          {t('ASSIGN_INCOMING_EMAILS.CREATE_TICKET_LOADING')}
        </Typography>
      </Stack>
    </Stack>
  );
}

function DialogContent(
  props: Pick<
    AssignToTicketDialogProps,
    'businessPartner' | 'onAssign' | 'onCreateTicket'
  >
) {
  const { businessPartner, onAssign, onCreateTicket } = props;

  const { t } = useTranslation();

  const prefilter = useMemo(() => {
    if (isCustomer(businessPartner)) {
      return [
        { businessPartnerId: { $eq: businessPartner.id } },
        { businessPartnerType: { $eq: SdObjType.KUNDE } },
      ];
    } else if (isSupplier(businessPartner)) {
      return [
        { businessPartnerId: { $eq: businessPartner.id } },
        { businessPartnerType: { $eq: SdObjType.LIEFERANT } },
      ];
    } else {
      return null;
    }
  }, [businessPartner]);

  return (
    <Stack direction="column" sx={{ flex: '1', '& > *': { flex: '1' } }}>
      <TicketPicker
        multiple={false}
        value={null}
        onChange={(ticket) => {
          if (ticket) {
            onAssign(ticket.id);
          }
        }}
        prefilter={prefilter}
        layout="advanced"
        strikeThroughIfClosed
        sortBy={'creationDate' satisfies keyof Ticket}
        sortByDirection={SortDirection.DESCENDING}
      />

      <Button
        size="large"
        fullWidth
        startIcon={<AddIcon />}
        color="primary"
        onClick={onCreateTicket}
        sx={{ flex: 'none' }}
      >
        {t('ASSIGN_INCOMING_EMAILS.CREATE_TICKET')}
      </Button>
    </Stack>
  );
}
