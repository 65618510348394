import React from 'react';
import { TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';

import { Ra } from '@work4all/models/lib/Classes/Ra.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Table } from '../../../components/data-tables/table/Table';

import { EntityTable } from './../EntityTable';
import schema from './../schemata/outgoing-invoices-table-schema.json';
import { TableLayoutContext, useTableLayoutState } from './../table-layout';
import { TableNoRowsPlaceholder } from './../TableNoRowsPlaceholder';
import { useDataTable } from './../use-data-table';
import { useDeleteEntitiesToolbarConfig } from './../use-delete-entities-toolbar-config';
import { useErpTableMaskHandlers } from './../use-erp-table-mask-handlers';
import { useUserColumnConfigs } from './../use-user-column-configs';

type Props = EntityTable;

const defaultSort = [{ field: 'datum', direction: SortDirection.DESCENDING }];
const manualGroupBy = true;

export const OutgoingInvoicesTable = React.forwardRef<TableInstance, Props>(
  function OutgoingInvoicesTable(props, ref) {
    const { prefilter, onOpenMask } = props;
    const { entity: entityType } = schema as never;

    const tableStateBag = useTableStateBag();

    const layoutState = useTableLayoutState();
    const [layout] = layoutState;

    const {
      columnConfigs,
      cardConfig,
      data,
      fetchMore,
      total,
      initialSortBy,
      pending,
    } = useDataTable<Ra, never>({
      layout,
      schema: schema as never,
      tableStateBag,
      prefilter,
      defaultSort,
    });

    const maskHandlers = useErpTableMaskHandlers(
      entityType,
      onOpenMask,
      'supplier.id',
      Entities.supplier
    );

    const deleteConfig = useDeleteEntitiesToolbarConfig({
      entityType,
    });

    const [userConfig, userConfigMethods] = useUserColumnConfigs({
      layout,
      entityType,
      columnConfigs,
    });

    if (!userConfig) return null;

    return (
      <TableLayoutContext value={layoutState}>
        <Table
          pending={pending}
          noRowsRenderer={() => <TableNoRowsPlaceholder />}
          ref={ref}
          layout={layout}
          cardConfig={cardConfig}
          columnConfigs={userConfig}
          manualGroupBy={manualGroupBy}
          initialSortBy={initialSortBy}
          loadMoreItems={fetchMore}
          data={data}
          total={total}
          actions={{
            add: maskHandlers.create,
            edit: maskHandlers.edit,
            remove: deleteConfig,
            resetColumns: userConfigMethods.remove,
            createWidget: true,
          }}
        />
      </TableLayoutContext>
    );
  }
);
