import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Mention } from './Mention.entity';
import { SaveSendMailJob } from './SaveSendMailJob.entity';

export type NotificationUnion<T extends EMode = EMode.entity> =
  T extends EMode.entity
    ? Mention<T> | SaveSendMailJob<T>
    : {
        /** Alias: Mention */
        mention?: Mention<T>;
        /** Alias: SaveSendMailJob */
        saveSendMailJob?: SaveSendMailJob<T>;
      };

const fields: FieldDefinitions<NotificationUnion<EMode.query>> = {
  mention: {
    alias: 'mention',
    entity: Entities.mention,
  },
  saveSendMailJob: {
    alias: 'saveSendMailJob',
    entity: Entities.saveSendMailJob,
  },
};

export const notificationUnionEntityDefinition: EntitiyDefinition<
  NotificationUnion<EMode.query>
> = {
  kind: 'union',
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'NotificationUnion',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
