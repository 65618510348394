import styles from './PositionMask.module.scss';

import { ArrowBack, Close, Delete } from '@mui/icons-material';
import { Grid, IconButton, Theme, useMediaQuery } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LabeledInput } from '@work4all/components/lib/input/labeled-input';
import { EndAdornment } from '@work4all/components/lib/input/labeled-input/components/end-adornment/EndAdornment';

import { Currency } from '@work4all/models/lib/Classes/Currency.entity';
import { Position } from '@work4all/models/lib/Classes/Position.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { formatCurrency } from '@work4all/utils';

import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { Collapse } from '../../../../../../../components';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { ErpData } from '../../../../../ErpData';
import { EditTableMask } from '../edit-table/types';

export type IPositionMaskProps = {
  currency: Currency;
} & EditTableMask<Position>;

export function PositionMask(props: IPositionMaskProps) {
  const {
    item: position,
    onCloseClicked,
    onEdit: onEditPosition,
    onRemove: onRemovePosition,
  } = props;

  const { watch } = useFormContextPlus<ErpData>();
  const currency = watch('currency');

  const [resultedPosition, setResultedPosition] = useState(position);

  const mask = useMaskContext();
  const hidePrices = mask.entity === Entities.deliveryNote;

  useEffect(() => {
    setResultedPosition(position);
  }, [position]);

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  const { t } = useTranslation();

  const onChange = useCallback(
    (props: { id: string; value: number | string }) => {
      const { id, value } = props;
      setResultedPosition({ ...position, [id]: value });
    },
    [position]
  );

  const onBlur = useCallback(
    (props: { id: string; value: number | string }) => {
      const { id, value } = props;

      onEditPosition({
        position: {
          id: position.id,
          [id]: value,
        },
      });
    },
    [onEditPosition, position]
  );

  if (!resultedPosition) {
    return null;
  }

  return (
    <div className={styles.outerWrap}>
      <div className={styles.header}>
        <IconButton onClick={onCloseClicked}>
          {isMobile ? <ArrowBack /> : <Close />}
        </IconButton>
        <div className={styles.titleContainer}>
          {resultedPosition?.number} | {resultedPosition?.longtext}
        </div>
        <IconButton
          color="primary"
          onClick={(e) => {
            onRemovePosition([position?.id]);
            onCloseClicked(e);
          }}
        >
          <Delete />
        </IconButton>
      </div>
      <div className={styles.scrollContainer}>
        <Collapse
          defaultOpen={true}
          title={t('COMMON.DESCRIPTION')}
          className={styles.topSection}
        >
          <LabeledInput
            value={resultedPosition?.longtext}
            multiline={true}
            style={{ minHeight: '12rem' }}
            onBlur={(e) => {
              onBlur({ id: 'longtext', value: e.target.value });
            }}
            onChange={(e) => {
              onChange({ id: 'longtext', value: e.target.value });
            }}
            label={t('FIELDS.longtext')}
          />
        </Collapse>
        {[ErpPositionsKind.STANDARD, ErpPositionsKind.TEXTZEILE].includes(
          position?.positionKind
        ) && (
          <>
            <Collapse defaultOpen={true} title={t('COMMON.ERP.CALCULATION')}>
              <Grid container spacing="1rem">
                <Grid item xs={6}>
                  <LabeledInput
                    value={resultedPosition?.amount}
                    type="number"
                    onBlur={(e) => {
                      onBlur({
                        id: 'amount',
                        value: parseFloat(e.target.value),
                      });
                    }}
                    onChange={(e) => {
                      onChange({
                        id: 'amount',
                        value: parseFloat(e.target.value),
                      });
                    }}
                    label={t('FIELDS.amount')}
                    selectOnFocus
                  />
                </Grid>
                <Grid item xs={6}>
                  <LabeledInput
                    value={resultedPosition?.unit}
                    type="text"
                    onBlur={(e) => {
                      onBlur({ id: 'unit', value: e.target.value });
                    }}
                    onChange={(e) => {
                      onChange({ id: 'unit', value: e.target.value });
                    }}
                    label={t('FIELDS.unit')}
                    selectOnFocus
                  />
                </Grid>
              </Grid>
            </Collapse>
            {!hidePrices && (
              <Collapse defaultOpen={true} title={t('COMMON.ERP.PRICING')}>
                <Grid container spacing="1rem">
                  <Grid item xs={6}>
                    <LabeledInput
                      value={resultedPosition?.singlePriceNet}
                      type="number"
                      onBlur={(e) => {
                        onBlur({
                          id: 'singlePriceNet',
                          value: parseFloat(e.target.value),
                        });
                      }}
                      onChange={(e) => {
                        onChange({
                          id: 'singlePriceNet',
                          value: parseFloat(e.target.value),
                        });
                      }}
                      label={t('FIELDS.singlePriceNet')}
                      endAdornment={
                        <EndAdornment>
                          {formatCurrency(currency, t)}
                        </EndAdornment>
                      }
                      selectOnFocus
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <LabeledInput
                      value={resultedPosition?.discount}
                      type="number"
                      onBlur={(e) => {
                        onBlur({
                          id: 'discount',
                          value: parseFloat(e.target.value),
                        });
                      }}
                      onChange={(e) => {
                        onChange({
                          id: 'discount',
                          value: parseFloat(e.target.value),
                        });
                      }}
                      label={t('FIELDS.discount')}
                      endAdornment={<EndAdornment>%</EndAdornment>}
                      selectOnFocus
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <LabeledInput
                      value={resultedPosition?.totalPriceNet}
                      type="number"
                      label={t('FIELDS.totalPriceNet')}
                      endAdornment={
                        <EndAdornment>
                          {formatCurrency(currency, t)}
                        </EndAdornment>
                      }
                      selectOnFocus
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <LabeledInput
                      value={resultedPosition?.insteadOfTotalPrice}
                      type="text"
                      onBlur={(e) => {
                        onBlur({
                          id: 'insteadOfTotalPrice',
                          value: e.target.value,
                        });
                      }}
                      onChange={(e) => {
                        onChange({
                          id: 'insteadOfTotalPrice',
                          value: e.target.value,
                        });
                      }}
                      label={t('FIELDS.insteadOfTotalPrice')}
                      selectOnFocus
                    />
                  </Grid>
                </Grid>
              </Collapse>
            )}
            <Collapse defaultOpen={true} title={t('COMMON.ERP.BOOKING')}>
              <Grid container spacing="1rem">
                <Grid item sm={6} xs={6}>
                  <LabeledInput
                    value={resultedPosition?.vat}
                    type="number"
                    onBlur={(e) => {
                      onBlur({ id: 'vat', value: parseFloat(e.target.value) });
                    }}
                    onChange={(e) => {
                      onChange({
                        id: 'vat',
                        value: parseFloat(e.target.value),
                      });
                    }}
                    label={t('FIELDS.vat')}
                    endAdornment={<EndAdornment>%</EndAdornment>}
                    selectOnFocus
                  />
                </Grid>
              </Grid>
            </Collapse>
          </>
        )}
      </div>
    </div>
  );
}
