import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class TravelCostInvoiceKindTransportCost<
  T extends EMode = EMode.entity
> {
  /** Alias: code */
  id?: number;
  /** Alias: enumBelegartFahrtkosten */
  enumReceiptKindTravelCosts?: number;
  /** Alias: name */
  name?: string;
  /** Alias: ledgerAccount */
  ledgerAccount?: number;
  /** Alias: ledgerAccount1 */
  ledgerAccount1?: number;
  /** Alias: ledgerAccount2 */
  ledgerAccount2?: number;
  /** Alias: ledgerAccount3 */
  ledgerAccount3?: number;
  /** Alias: payrollAccount */
  payrollAccount?: number;
  /** Alias: payrollAccount1 */
  payrollAccount1?: number;
  /** Alias: payrollAccount2 */
  payrollAccount2?: number;
  /** Alias: vat1 */
  vat1?: number;
  /** Alias: vat2 */
  vat2?: number;
  /** Alias: vat3 */
  vat3?: number;
  /** Alias: taxKey1 */
  taxKey1?: number;
  /** Alias: taxKey2 */
  taxKey2?: number;
  /** Alias: taxKey3 */
  taxKey3?: number;

  __typename?: string;
}

const fields: FieldDefinitions<TravelCostInvoiceKindTransportCost> = {
  id: {
    alias: 'code',
  },
  enumReceiptKindTravelCosts: {
    alias: 'enumBelegartFahrtkosten',
  },
  name: {
    alias: 'name',
  },
  ledgerAccount: {
    alias: 'ledgerAccount',
  },
  ledgerAccount1: {
    alias: 'ledgerAccount1',
  },
  ledgerAccount2: {
    alias: 'ledgerAccount2',
  },
  ledgerAccount3: {
    alias: 'ledgerAccount3',
  },
  payrollAccount: {
    alias: 'payrollAccount',
  },
  payrollAccount1: {
    alias: 'payrollAccount1',
  },
  payrollAccount2: {
    alias: 'payrollAccount2',
  },
  vat1: {
    alias: 'vat1',
  },
  vat2: {
    alias: 'vat2',
  },
  vat3: {
    alias: 'vat3',
  },
  taxKey1: {
    alias: 'taxKey1',
  },
  taxKey2: {
    alias: 'taxKey2',
  },
  taxKey3: {
    alias: 'taxKey3',
  },
  __typename: {
    alias: '__typename',
  },
};

export const travelCostInvoiceKindTransportCostEntityDefinition: EntitiyDefinition<TravelCostInvoiceKindTransportCost> =
  {
    local: {},
    remote: {
      queryName: 'getTravelAndReceiptReceiptDrivingTypes',
      fragmentName: 'ReisekostenBelegartenFahrtkosten',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
