import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { BusinessPartner } from './BusinessPartner.entity';
import { Contact } from './Contact.entity';
import { FileEntity } from './FileEntity.entity';
import { Project } from './Project.entity';
import { Thumbnail } from './Thumbnail.entity';
import { TopicSimple } from './TopicSimple.entity';
import { User } from './User.entity';

export class Document<T extends EMode = EMode.entity> {
  /** Alias: ansprechpartner */
  contact?: Contact<T>;
  /** Alias: ansprechpartnerCode */
  contactId?: number;
  /** Alias: art */
  kind?: number;
  /** Alias: benutzer */
  user?: User<T>;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: binaryData */
  binaryData?: string;
  /** Alias: blobFileType */
  blobFileType?: string;
  /** Alias: code */
  id?: number;
  /** Alias: dateiPfad */
  filePath?: string;
  /** Alias: datum */
  date?: string;
  /** Alias: geschaeftspartner */
  businessPartner?: BusinessPartner<T>;
  /** Alias: infofensterName */
  infoWindowName?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: lastModificationDate */
  lastModificationDate?: string;
  /** Alias: notiz */
  note?: string;
  /** Alias: objGr */
  objGr?: number;
  /** Alias: objGrCode */
  objGrId?: number;
  /** Alias: projekt */
  project?: Project<T>;
  /** Alias: projektCode */
  projectId?: number;
  /** Alias: sdObjMemberCode */
  businessPartnerId?: number;
  /** Alias: sdObjType */
  businessPartnerType?: SdObjType;
  /** Alias: themaCode */
  topicId?: number;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: themenZuordnungen */
  topicMarkList?: TopicSimple<T>[];
  /** Alias: fileInfos */
  fileInfos?: FileEntity<T>;
  /** Alias: createdByUserCode */
  createdByUserCode?: number;
  /** Alias: documentParentCode */
  documentParentCode?: number;
  /** Alias: parentDocument */
  parentDocument?: Document<T>;
  /** Alias: childDocuments */
  childDocuments?: Document<T>[];
  /** Alias: thumbnail */
  thumbnail?: Thumbnail<T>;
  /** Alias: name */
  name?: string;
  /** Alias: notizHtml */
  noteHtml?: string;

  __typename?: string;
}

const fields: FieldDefinitions<Document> = {
  contact: {
    alias: 'ansprechpartner',
    entity: Entities.contact,
  },
  contactId: {
    alias: 'ansprechpartnerCode',
  },
  kind: {
    alias: 'art',
  },
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  userId: {
    alias: 'benutzerCode',
  },
  binaryData: {
    alias: 'binaryData',
  },
  blobFileType: {
    alias: 'blobFileType',
  },
  id: {
    alias: 'code',
  },
  filePath: {
    alias: 'dateiPfad',
  },
  date: {
    alias: 'datum',
  },
  businessPartner: {
    alias: 'geschaeftspartner',
    entity: Entities.businessPartner,
  },
  infoWindowName: {
    alias: 'infofensterName',
  },
  insertTime: {
    alias: 'insertTime',
  },
  lastModificationDate: {
    alias: 'lastModificationDate',
  },
  note: {
    alias: 'notiz',
  },
  objGr: {
    alias: 'objGr',
  },
  objGrId: {
    alias: 'objGrCode',
  },
  project: {
    alias: 'projekt',
    entity: Entities.project,
  },
  projectId: {
    alias: 'projektCode',
  },
  businessPartnerId: {
    alias: 'sdObjMemberCode',
  },
  businessPartnerType: {
    alias: 'sdObjType',
  },
  topicId: {
    alias: 'themaCode',
  },
  updateTime: {
    alias: 'updateTime',
  },
  topicMarkList: {
    alias: 'themenZuordnungen',
    entity: Entities.topicSimple,
  },
  fileInfos: {
    alias: 'fileInfos',
    entity: Entities.fileEntity,
  },
  createdByUserCode: {
    alias: 'createdByUserCode',
  },
  documentParentCode: {
    alias: 'documentParentCode',
  },
  parentDocument: {
    alias: 'parentDocument',
    entity: Entities.document,
  },
  childDocuments: {
    alias: 'childDocuments',
    entity: Entities.document,
  },
  thumbnail: {
    alias: 'thumbnail',
    entity: Entities.thumbnail,
  },
  name: {
    alias: 'name',
  },
  noteHtml: {
    alias: 'notizHtml',
  },
  __typename: {
    alias: '__typename',
  },
};

export const documentEntityDefinition: EntitiyDefinition<Document> = {
  local: {},
  remote: {
    queryName: 'getDokument',
    fragmentName: 'Dokument',
    withPaginationWrapper: true,
    mutations: {
      upsert: {
        mutationName: 'upsertDokument',
        args: [
          { name: 'input', type: 'InputDokument!' },
          { name: 'relations', type: 'InputDokumentRelation' },
          { name: 'drySave', type: 'Boolean' },
        ],
      },
    },
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
