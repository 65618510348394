import { createContext, useCallback, useEffect, useState } from 'react';
import type { ColumnInstance, TableInstance } from 'react-table';

import { useForceUpdate } from '@work4all/utils/lib/hooks/use-force-update';

export const ColumnVisibilityContext =
  createContext<IColumnVisibilityContext | null>(null);

export interface IColumnVisibilityContext {
  toggledColumnId: string | null;
  toggleColumnVisibility: (columnId: string) => void;
}

export function useColumnVisibilityManager(
  tableInstance: TableInstance,
  // eslint-disable-next-line @typescript-eslint/ban-types
  orderColumns?: (nextColumns: ColumnInstance<{}>[]) => string[]
): IColumnVisibilityContext {
  const [toggledColumnId, setToggledColumnId] = useState<string | null>(null);

  const forceUpdate = useForceUpdate();
  const toggleColumnVisibility = useCallback(
    (columnId: string): void => {
      if (!tableInstance) return;

      const column = tableInstance.allColumns.find(
        (column) => column.id === columnId
      );

      if (!column) {
        return;
      }

      tableInstance.toggleHideColumn(columnId);

      // If column was visible simply hide it and return.
      if (column.isVisible) {
        forceUpdate();
        return;
      }

      // Otherwise save its id to trigger the animation and append it after all
      // of the sticky columns.
      setToggledColumnId(columnId);

      const nextColumns = [...tableInstance.visibleColumns];
      nextColumns.push(column);
      if (orderColumns) {
        tableInstance?.setColumnOrder(orderColumns(nextColumns));
        return;
      }
      tableInstance?.setColumnOrder(nextColumns.map((column) => column.id));
    },
    [forceUpdate, tableInstance, orderColumns]
  );

  useEffect(() => {
    if (toggledColumnId === null) return;

    const timeout = window.setTimeout(() => {
      setToggledColumnId(null);
    }, 300);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [toggledColumnId]);

  return { toggledColumnId, toggleColumnVisibility };
}
