import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { Calculation } from '@work4all/models/lib/Classes/Calculation.entity';
import { CallMemo } from '@work4all/models/lib/Classes/CallMemo.entity';
import { CheckList } from '@work4all/models/lib/Classes/CheckList.entity';
import { CommentEvent } from '@work4all/models/lib/Classes/CommentEvent.entity';
import { Contract } from '@work4all/models/lib/Classes/Contract.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { DeliveryNote } from '@work4all/models/lib/Classes/DeliveryNote.entity';
import { Demand } from '@work4all/models/lib/Classes/Demand.entity';
import { Document } from '@work4all/models/lib/Classes/Document.entity';
import { EMail } from '@work4all/models/lib/Classes/EMail.entity';
import { InboundDeliveryNote } from '@work4all/models/lib/Classes/InboundDeliveryNote.entity';
import { InboundInvoice } from '@work4all/models/lib/Classes/InboundInvoice.entity';
import { Invoice } from '@work4all/models/lib/Classes/Invoice.entity';
import { Letter } from '@work4all/models/lib/Classes/Letter.entity';
import { MentionUnion } from '@work4all/models/lib/Classes/MentionUnion.entity';
import { Note } from '@work4all/models/lib/Classes/Note.entity';
import { Offer } from '@work4all/models/lib/Classes/Offer.entity';
import { Order } from '@work4all/models/lib/Classes/Order.entity';
import { ProductionContract } from '@work4all/models/lib/Classes/ProductionContract.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { SalesOpportunities } from '@work4all/models/lib/Classes/SalesOpportunities.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Task } from '@work4all/models/lib/Classes/Task.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { VisitationReport } from '@work4all/models/lib/Classes/VisitationReport.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { assertNever } from '@work4all/utils';

export function resolveNotificationData(object: MentionUnion): {
  entity: Entities | null;
  title: string | null;
} {
  if (object === null) {
    return null;
  }

  if (isTicket(object)) {
    return {
      entity: Entities.ticket,
      title: `${object.number} | ${object.title}`,
    };
  } else if (isNote(object)) {
    return {
      entity: Entities.note,
      title: `${object.title}`,
    };
  } else if (isTask(object)) {
    return {
      entity: Entities.task,
      title: `${object.title}`,
    };
  } else if (isAppointment(object)) {
    return {
      entity: Entities.appointment,
      title: `${object.title}`,
    };
  } else if (isCheckList(object)) {
    return {
      entity: Entities.checkList,
      title: `${object.title}`,
    };
  } else if (isSalesOpportunities(object)) {
    return {
      entity: Entities.salesOpportunities,
      title: `${object.name}`,
    };
  } else if (isCallMemo(object)) {
    return {
      entity: Entities.callMemo,
      title: `${object.title}`,
    };
  } else if (isVisitationReport(object)) {
    return {
      entity: Entities.visitationReport,
      title: `${object.note}`,
    };
  } else if (isLetter(object)) {
    return {
      entity: Entities.letter,
      title: `${object.note}`,
    };
  } else if (isDocument(object)) {
    return {
      entity: Entities.document,
      title: `${object.note}`,
    };
  } else if (isEMail(object)) {
    return {
      entity: Entities.eMail,
      title: `${object.subject}`,
    };
  } else if (isDeliveryNote(object)) {
    return {
      entity: Entities.deliveryNote,
      title: `${object.note}`,
    };
  } else if (isInboundDeliveryNote(object)) {
    return {
      entity: Entities.inboundDeliveryNote,
      title: `${object.note}`,
    };
  } else if (isCalculation(object)) {
    return {
      entity: Entities.calculation,
      title: `${object.note}`,
    };
  } else if (isInvoice(object)) {
    return {
      entity: Entities.invoice,
      title: `${object.note}`,
    };
  } else if (isContract(object)) {
    return {
      entity: Entities.contract,
      title: `${object.note}`,
    };
  } else if (isOffer(object)) {
    return {
      entity: Entities.offer,
      title: `${object.note}`,
    };
  } else if (isOrder(object)) {
    return {
      entity: Entities.order,
      title: `${object.note}`,
    };
  } else if (isDemand(object)) {
    return {
      entity: Entities.demand,
      title: `${object.note}`,
    };
  } else if (isProductionContract(object)) {
    return {
      entity: Entities.productionContract,
      title: `${object.name}`,
    };
  } else if (isInboundInvoice(object)) {
    return {
      entity: Entities.inboundInvoice,
      title: `${object.note}`,
    };
  } else if (isCustomer(object)) {
    return {
      entity: Entities.customer,
      title: `${object.name}`,
    };
  } else if (isSupplier(object)) {
    return {
      entity: Entities.supplier,
      title: `${object.name}`,
    };
  } else if (isProject(object)) {
    return {
      entity: Entities.project,
      title: `${object.name}`,
    };
  } else if (isArticle(object)) {
    return {
      entity: Entities.article,
      title: `${object.name}`,
    };
  } else if (isCommentEvent(object)) {
    throw new Error('Unsupported object type on mention: CommentEvent');
  } else {
    // Throw an error in DEV mode and display fallback UI in PROD.
    if (__DEV__) {
      assertNever(
        object,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        `Unknown object type on mention: ${(object as any).__typename}`
      );
    }

    return null;
  }
}

function isTicket(object: MentionUnion): object is Ticket {
  return object.__typename === 'Ticket';
}

function isNote(object: MentionUnion): object is Note {
  return object.__typename === 'Notiz';
}

function isTask(object: MentionUnion): object is Task {
  return object.__typename === 'Aufgabe';
}

function isAppointment(object: MentionUnion): object is Appointment {
  return object.__typename === 'Termin';
}

function isCheckList(object: MentionUnion): object is CheckList {
  return object.__typename === 'Checkliste';
}

function isSalesOpportunities(
  object: MentionUnion
): object is SalesOpportunities {
  return object.__typename === 'Verkaufschance';
}

function isCallMemo(object: MentionUnion): object is CallMemo {
  return object.__typename === 'Telefonat';
}

function isCommentEvent(object: MentionUnion): object is CommentEvent {
  return object.__typename === 'CommentEvent';
}

function isVisitationReport(object: MentionUnion): object is VisitationReport {
  return object.__typename === 'Besuchsbericht';
}

function isLetter(object: MentionUnion): object is Letter {
  return object.__typename === 'Brief';
}

function isDocument(object: MentionUnion): object is Document {
  return object.__typename === 'Dokument';
}

function isEMail(object: MentionUnion): object is EMail {
  return object.__typename === 'EMail';
}

function isDeliveryNote(object: MentionUnion): object is DeliveryNote {
  return object.__typename === 'Lieferschein';
}

function isInboundDeliveryNote(
  object: MentionUnion
): object is InboundDeliveryNote {
  return object.__typename === 'Eingangslieferschein';
}

function isCalculation(object: MentionUnion): object is Calculation {
  return object.__typename === 'Kalkulation';
}

function isInvoice(object: MentionUnion): object is Invoice {
  return object.__typename === 'Rechnung';
}

function isContract(object: MentionUnion): object is Contract {
  return object.__typename === 'Auftrag';
}

function isOffer(object: MentionUnion): object is Offer {
  return object.__typename === 'Angebot';
}

function isOrder(object: MentionUnion): object is Order {
  return object.__typename === 'Bestellung';
}

function isDemand(object: MentionUnion): object is Demand {
  return object.__typename === 'Bedarfsanforderung';
}

function isProductionContract(
  object: MentionUnion
): object is ProductionContract {
  return object.__typename === 'Produktionsauftrag';
}

function isInboundInvoice(object: MentionUnion): object is InboundInvoice {
  return object.__typename === 'Eingangsrechnung';
}

function isCustomer(object: MentionUnion): object is Customer {
  return object.__typename === 'Kunde';
}

function isSupplier(object: MentionUnion): object is Supplier {
  return object.__typename === 'Lieferant';
}

function isProject(object: MentionUnion): object is Project {
  return object.__typename === 'Projekt';
}

function isArticle(object: MentionUnion): object is Article {
  return object.__typename === 'Artikel';
}
