import ModeIcon from '@mui/icons-material/Mode';
import { get } from 'lodash';
import { useContext, useMemo } from 'react';

import { ReactComponent as InboundIcon } from '@work4all/assets/icons/email-actions/inbound.svg';
import { ReactComponent as OutboundIcon } from '@work4all/assets/icons/email-actions/outbound.svg';

import {
  CardWidget,
  ICardWidgetColumnDef,
  ICardWidgetProps,
  ICardWidgetRenderCellParams,
  IGetRowModifiers,
} from '@work4all/components/lib/dataDisplay/card-widget';
import { WidgetDragDropProvider } from '@work4all/components/lib/dataDisplay/card-widget/hooks/use-widget-drag-drop-context';

import {
  AppParts,
  useCanView,
  useInaccessibleFields,
  useNavigate,
} from '@work4all/data';
import { usePermissions } from '@work4all/data/lib/hooks/use-permissions';

import {
  EntityByLayoutType,
  ILayoutDefinition,
  RowModifierCondition,
  RowModifierConditionRule,
} from '@work4all/models';
import { EMail } from '@work4all/models/lib/Classes/EMail.entity';
import { Invoice } from '@work4all/models/lib/Classes/Invoice.entity';
import { EMailKind } from '@work4all/models/lib/Enums/EMailKind.enum';

import { invariant } from '@work4all/utils';

import { useEntityLinks } from '../hooks/useEntityLinks';
import { SelectedItemContext } from '../selected-item-context';

import { useCardDnd } from './hooks/use-card-dnd';

type ICardWidgetContainerGeneratedProps<T> = Pick<
  ICardWidgetProps<T>,
  | 'isSelected'
  | 'getItemHref'
  | 'listHref'
  | 'newHref'
  | 'onItemClick'
  | 'onItemDoubleClick'
  | 'columns'
  | 'getRowModifiers'
  | 'getCellModifiers'
  | 'entity'
>;

export function CardWidgetContainer<T extends { id?: number | string }>(
  props: Omit<
    ICardWidgetProps<T>,
    keyof ICardWidgetContainerGeneratedProps<T>
  > & {
    definition: ILayoutDefinition;
  }
) {
  const { definition, ...propsWithoutDefinition } = props;

  const { item, setItem } = useContext(SelectedItemContext);

  const navigate = useNavigate();

  const { columns, getRowModifiers, entityType } = useWidgetConfig(definition);
  const isEditableEntity = useCanView(AppParts.EDIT_MASK_OF_ENTITY, entityType);

  const { listHref, newHref, getItemHref } = useEntityLinks(
    definition,
    entityType
  );
  const { untypedPermissions } = usePermissions();
  const canAdd = untypedPermissions(entityType).canAdd();
  const dndValue = useCardDnd(entityType);

  return (
    <WidgetDragDropProvider value={dndValue}>
      <CardWidget<T>
        entity={entityType}
        getItemHref={getItemHref}
        listHref={listHref}
        newHref={newHref}
        columns={columns}
        isSelected={({ id }) => id === item?.id && entityType === item?.type}
        onItemClick={({ id }) => {
          setItem({ widgetId: definition.id, id, type: entityType });
        }}
        onItemDoubleClick={(item) => {
          if (!isEditableEntity) {
            if (item) {
              const { id } = item;
              setItem({ widgetId: definition.id, id, type: entityType });
            }
          } else {
            navigate(getItemHref(item));
          }
        }}
        {...propsWithoutDefinition}
        getRowModifiers={getRowModifiers}
        maxRows={props.maxRows}
        canAdd={canAdd}
      />
    </WidgetDragDropProvider>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cellRenderers: Record<string, ICardWidgetColumnDef<any>['renderCell']> = {
  'email-kind': renderEMailKindIcon,
  'invoice-credit': renderInvoiceCreditValue,
};

export function useWidgetConfig<T>(definition: ILayoutDefinition) {
  // The widget definition config is typed as an array, but at the moment only
  // widgets with a single entity are supported.
  const entity = definition.config.entities[0];

  invariant(entity, 'Invalid widget config');
  const { isInaccessible } = useInaccessibleFields();

  const entityType = EntityByLayoutType[entity.entityTypeName];
  const columnsDef = entity.ui.columns;

  const columns = useMemo(() => {
    if (!columnsDef) return [];

    const columns: ICardWidgetColumnDef<T>[] = columnsDef
      .filter((def) => {
        return !isInaccessible(entityType, def.accessor);
      })
      .map((def) => {
        const {
          accessor: field,
          primary = false,
          type = 'string',
          align = type === 'number' ? 'right' : 'left',
          modifiers,
        } = def;

        const column: ICardWidgetColumnDef<T> = {
          field,
          primary,
          type,
          align,
          modifiers:
            modifiers?.reduce((acc, name) => {
              acc[name] = true;
              return acc;
            }, {}) ?? null,
          renderCell: cellRenderers[type],
        };

        return column;
      });

    return columns;
  }, [columnsDef, entityType, isInaccessible]);

  const getRowModifiers = useMemo<IGetRowModifiers<T>>(() => {
    const rowModifiers = entity.ui.rowModifiers;

    if (!rowModifiers || rowModifiers.length === 0) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getRowModifiers: IGetRowModifiers<any> = ({ row }) => {
      const modifiers = {};

      rowModifiers
        .filter((modifier) => modifier.type === 'StyleModifier')
        .forEach((modifier) => {
          for (const rule of modifier.rules) {
            if (testStyleModifier(rule.condition, row)) {
              for (const name of rule.styles) {
                modifiers[name] = true;
              }
            }
          }
        });

      return modifiers;
    };

    return getRowModifiers;
  }, [entity.ui.rowModifiers]);

  return {
    entityType,
    columns,
    getRowModifiers,
  };
}

function renderInvoiceCreditValue(
  params: ICardWidgetRenderCellParams<Invoice>
) {
  return params.value < 0 ? 'G' : '';
}

function renderEMailKindIcon(params: ICardWidgetRenderCellParams<EMail>) {
  const kind: EMailKind = params.value;

  switch (kind) {
    case EMailKind.AUSGEHEND:
      return <OutboundIcon title="Outbound" />;
    case EMailKind.EINGEHEND_HTML:
    case EMailKind.EINGEHEND:
      return <InboundIcon title="Inbound" />;
    case EMailKind.ENTWURF:
    case EMailKind.ENTWURF_HTML:
      return <ModeIcon titleAccess="Draft" />;
    default:
      return '?';
  }
}

function testStyleModifier(
  condition: RowModifierCondition,
  row: unknown
): boolean {
  const keys = Object.keys(condition);

  return keys.every((key) => {
    return testStyleModifierOperator(key, condition[key], row);
  });
}

function testStyleModifierOperator(
  path: string,
  rule: RowModifierConditionRule,
  row: unknown
): boolean {
  const value = get(row, path);

  //ToDo what is a good way to express eg: if a porject.closesStatus does not eq 0, but if no project is assinged at all it doesnt cound neither
  //currently in these cases we validate undefined!=0, which is technically not correct because the closedStatus simply does not exist
  if (value === undefined) return false;

  if ('$eq' in rule) {
    return value === rule.$eq;
  } else if ('$ne' in rule) {
    return value !== rule.$ne;
  } else if ('$in' in rule) {
    return rule.$in.includes(value);
  } else if ('$nin' in rule) {
    return !rule.$nin.includes(value);
  } else {
    // The condition has been validated at this point, so this should never
    // happen.
    throw new Error(`Unknown operator`);
  }
}
