import { WithDeleted } from 'rxdb';

import { DbEntities } from '@work4all/models';
import { ILayout } from '@work4all/models/lib/Layout';

import { generateGraphQLQuery } from '@work4all/utils/lib/graphql-query-generation/generateGraphQLQuery';

import { createReplicateTenantCollection } from './create-replicate-tenant-collection';

const { queryString } = generateGraphQLQuery({
  operationName: 'ReplicateLayouts',
  rootField: {
    name: 'getLayouts',
    params: [
      { name: 'since', type: 'DateTime' },
      { name: 'lastId', type: 'Guid' },
    ],
  },
  fields: [
    { name: 'id' },
    { name: 'layoutType' },
    { name: 'name' },
    { name: 'defaultLayout' },
    { name: 'definition' },
    { name: 'insertTime' },
    { name: 'updateTime' },
    { name: 'mandantenCode', alias: 'tenant' },
  ],
});

interface LayoutPullQueryCheckpoint {
  id: string;
  updatedAt: string;
}

export const replicateLayoutDefinitions = createReplicateTenantCollection(
  DbEntities.Layout
)<LayoutPullQueryCheckpoint, ILayout>(() => {
  return {
    queryBuilder(checkpoint) {
      return {
        query: queryString,
        variables: {
          since: checkpoint?.updatedAt,
          lastId: checkpoint?.id,
        },
      };
    },
    responseModifier(docs: ILayout[], origin, requestCheckpoint) {
      function mapDocument(doc: ILayout): WithDeleted<ILayout> {
        return { ...doc, _deleted: false };
      }

      function createCheckpoint(doc: ILayout): LayoutPullQueryCheckpoint {
        return {
          id: doc.id,
          updatedAt: doc.updateTime,
        };
      }

      return {
        documents: docs.map(mapDocument),
        checkpoint:
          docs.length === 0
            ? requestCheckpoint
            : createCheckpoint(docs[docs.length - 1]),
      };
    },
  };
});
