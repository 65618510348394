import React from 'react';

interface Props {
  depth?: number;
}

const IDENT = 40;

export const RowIndent: React.FC<Props> = (props) => {
  if (typeof props.depth !== 'number') {
    return null;
  }

  const depth = props.depth;

  return (
    <div
      style={{
        width: `${depth * IDENT}px`,
      }}
    />
  );
};
