import styles from './GraphQLExplorerPage.module.scss';

import { ApolloExplorer } from '@apollo/explorer';
import { IntrospectionQuery } from 'graphql';
import { isEqual } from 'lodash';
import { DateTime } from 'luxon';
import { memo, useEffect, useState } from 'react';

import { useUser } from '@work4all/data';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';

const SCHEMA_POLL_INTERVAL = 10_000; // Poll the GraphQL schema for changes every 10 seconds.

export const GraphQLExplorerPage = memo(function GraphQLExplorerPage() {
  const user = useUser();
  const { activeTenant } = useTenant();

  const [wrapperElement, setWrapperElement] = useState<HTMLDivElement | null>();
  const [schema, setSchema] = useState<IntrospectionQuery | null>(null);

  const [explorer, setExplorer] = useState<ApolloExplorer | null>(null);

  // Fetch the GraphQL schema by introspecting the API at a set interval.
  useEffect(() => {
    if (!user) return;

    let timeoutId: number | null = null;

    const controller = new AbortController();

    async function fetchSchema() {
      try {
        const response = await fetch(`${user.baseUrl}/graphql`, {
          method: 'POST',
          body: JSON.stringify(INTROSPECTION_QUERY),
          headers: {
            'x-work4all-mandant': `${activeTenant}`,
            'content-type': 'application/json',
            authorization: `Bearer ${user.token}`,
            'x-work4all-apiurl': user.baseUrl,
          },
          signal: controller.signal,
        });

        const schema = (await response.json()).data;

        // Updating the schema causes the whole Explorer to re-render, so only
        // do it if the new schema is different.
        setSchema((currentSchema) => {
          return isEqual(currentSchema, schema) ? currentSchema : schema;
        });

        timeoutId = window.setTimeout(() => {
          fetchSchema();
        }, SCHEMA_POLL_INTERVAL);
      } catch (error) {
        if (!isAbortError(error)) {
          throw error;
        }
      }
    }

    fetchSchema();

    return () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }

      controller.abort();
    };
  }, [activeTenant, user]);

  // Instantiate the ApolloExplorer with custom options and request handler.
  useEffect(() => {
    if (!user || !wrapperElement) return;

    const explorer = new ApolloExplorer({
      target: wrapperElement,
      endpointUrl: `${user.baseUrl}/graphql`,
      // The `schema` is required in the `EmbeddableExplorerOptions` interface,
      // but we can't add it to this effect's dependencies because it will
      // create a new Explorer instance every time the schema changes. (The
      // GraphQL API is introspected polled every 10 seconds.)
      //
      // Instead we instantiate the Explorer without a schema and set it later
      // in another effect.
      //
      // Passing `undefined` here is simply a way to silence the TypeScript
      // error.
      schema: undefined,
      initialState: {
        displayOptions: {
          docsPanelState: 'open',
          // Disable the Headers tab in the Explorer. We override the headers in
          // the `handleRequest` function, so changing them in the UI will not
          // have any effect and will just cause confusion.
          //
          // Note: By hiding it we also make it impossible to add new headers,
          // but it shouldn't be necessary.
          showHeadersAndEnvVars: false,
        },
        // Fetch the current user as an example query.
        //
        // This will be the query that you see when you open the Explorer for
        // the first time or after you close the last tab.
        document: INITIAL_QUERY,
        variables: {
          filter: JSON.stringify([{ code: { $eq: user.benutzerCode } }]),
        },
      },
      // The Explorer will persist its state in LocalStorage.
      persistExplorerState: true,
      handleRequest: (endpointUrl, options) => {
        return fetch(endpointUrl, {
          ...options,
          headers: {
            ...options.headers,
            authorization: `Bearer ${user.token}`,
            'x-work4all-mandant': `${activeTenant}`,
            'x-work4all-timezone': DateTime.now().toFormat('ZZ'),
            'x-work4all-apiurl': user.baseUrl,
          },
        });
      },
    });

    setExplorer(explorer);

    return () => {
      explorer.dispose();
      setExplorer(null);
    };
  }, [user, wrapperElement]);

  // Update the Explorer whenever schema changes.
  useEffect(() => {
    if (explorer) {
      // BUG Sometimes the Explorer does not update after the schema is fetched.
      // Cause unknown. For now, the solution is to simply reload the page if it
      // happens.
      setTimeout(() => {
        explorer.updateSchemaInEmbed({ schema });
      }, 100);
    }
  }, [schema, explorer]);

  return <div className={styles.explorer} ref={setWrapperElement} />;
});

const INTROSPECTION_QUERY = {
  query:
    '\n    query IntrospectionQuery {\n      __schema {\n        \n        queryType { name }\n        mutationType { name }\n        subscriptionType { name }\n        types {\n          ...FullType\n        }\n        directives {\n          name\n          description\n          \n          locations\n          args {\n            ...InputValue\n          }\n        }\n      }\n    }\n\n    fragment FullType on __Type {\n      kind\n      name\n      description\n      \n      fields(includeDeprecated: true) {\n        name\n        description\n        args {\n          ...InputValue\n        }\n        type {\n          ...TypeRef\n        }\n        isDeprecated\n        deprecationReason\n      }\n      inputFields {\n        ...InputValue\n      }\n      interfaces {\n        ...TypeRef\n      }\n      enumValues(includeDeprecated: true) {\n        name\n        description\n        isDeprecated\n        deprecationReason\n      }\n      possibleTypes {\n        ...TypeRef\n      }\n    }\n\n    fragment InputValue on __InputValue {\n      name\n      description\n      type { ...TypeRef }\n      defaultValue\n      \n      \n    }\n\n    fragment TypeRef on __Type {\n      kind\n      name\n      ofType {\n        kind\n        name\n        ofType {\n          kind\n          name\n          ofType {\n            kind\n            name\n            ofType {\n              kind\n              name\n              ofType {\n                kind\n                name\n                ofType {\n                  kind\n                  name\n                  ofType {\n                    kind\n                    name\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  ',
  operationName: 'IntrospectionQuery',
};

const INITIAL_QUERY = `
query GetCurrentUser($filter: String) {
  getBenutzer(filter: $filter) {
    data {
      code
      anzeigename
    }
  }
}
`;

function isAbortError(error: unknown): error is Error {
  return (
    error != null &&
    typeof error === 'object' &&
    error instanceof Error &&
    error.name === 'AbortError'
  );
}
