import { useEffect, useState } from 'react';

export type IUsePageTitleOptions = {
  /**
   * If there are multiple independent component trees each trying to set their
   * own page titles, you should use different priority values to avoid conflicts.
   *
   * By default, when all `usePageTitle` use the same priority, the resulting
   * page title will depend on the order in which the hooks are called. Which
   * might be inconsistent. You can use priority to override this behavior and
   * specify which title should always take priority.
   *
   * @default 0
   */
  priority?: number;
};

/**
 * Set `document.title` dynamically from any component. If there are multiple
 * components mounted with this hook at the same time the resulting title will
 * depend on the order in which the components were mounted.
 *
 * If you have multiple independent component trees that need to update the
 * page title, you can use `priority` in the options param to avoid race
 * conditions.
 */
export function usePageTitle(
  title: string,
  options: IUsePageTitleOptions = {}
) {
  const { priority = 0 } = options;

  // Use immutable id to keep trach of the order off calls for the hook
  const id = useId();

  // Groups all hook calls by priority
  useEffect(() => {
    allConfigs.set(id, { title });

    const ids = groupedIds.get(priority) ?? [];
    ids.push(id);
    groupedIds.set(priority, ids);

    scheduleProcessChange();

    return () => {
      allConfigs.delete(id);
      ids.splice(ids.indexOf(id), 1);

      scheduleProcessChange();
    };
  }, [id, title, priority]);
}

type IPageTitleConfig = {
  title: string;
};

const allConfigs = new Map<number, IPageTitleConfig>();
const groupedIds = new Map<number, number[]>();

const defaultPageTitle = document.title;

let nextId = 1;

function useId(): number {
  const [id] = useState(() => nextId++);
  return id;
}

let timeoutId: number | null = null;

// Schedule the update function to be called after this render
// and after all the hooks are updated.
function scheduleProcessChange(): void {
  if (timeoutId === null) {
    timeoutId = window.setTimeout(processChange, 0);
  }
}

function processChange(): void {
  timeoutId = null;

  const newPageTitle = resolvePageTitle() ?? defaultPageTitle;

  if (newPageTitle !== document.title) {
    document.title = newPageTitle;
  }
}

function resolvePageTitle(): string | null {
  // Find the group with the highest priority
  const { ids } = [...groupedIds.entries()]
    .filter(([_priority, ids]) => ids.length > 0)
    .reduce<{ priority: number; ids: number[] }>(
      (highest, current) => {
        const [priority, ids] = current;

        if (priority > highest.priority || highest.priority === null) {
          return { priority, ids };
        }

        return highest;
      },
      { priority: null, ids: [] }
    );

  // If it's not empty, use the last-most value to update the title
  if (ids.length > 0) {
    const id = ids[ids.length - 1];
    const config = allConfigs.get(id);
    return config.title;
  }

  // Otherwise fallback to the default title
  return null;
}
