import { Divider, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { MouseEventHandler, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useDataMutation,
  useInaccessibleFields,
  useUser,
} from '@work4all/data';

import { CallMemo } from '@work4all/models/lib/Classes/CallMemo.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Note } from '@work4all/models/lib/Classes/Note.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import {
  DateFormatPreset,
  formatDateString,
} from '@work4all/utils/lib/date-utils/formatDateString';

import { useContactActions } from '../../../hooks/use-contact-actions';
import { DatePicker } from '../../../input/date-picker';
import { useMobiscrollLanguage } from '../../../utils/use-mobiscroll-language/use-mobiscroll-language';
import { IConvertPopoverProps } from '../../convert-popover/ConvertPopover';
import { EntityPickerPopover } from '../../entity-picker/components';
import { ContactPicker } from '../../entity-picker/contact-picker/ContactPicker';
import { CustomerPicker } from '../../entity-picker/customer-picker/CustomerPicker';
import { ProjectPicker } from '../../entity-picker/project-picker/ProjectPicker';
import { UserPicker } from '../../entity-picker/user-picker/UserPicker';
import { useEntityTemplate } from '../../entity-template-provider/EntityTemplateProvider';
import { callMemoPreviewFields } from '../call-memo-preview';
import {
  InfoCard,
  InfoCards,
  OpenFileIconButton,
  PreviewEditMessage,
  PreviewTextContent,
  PreviewTitle,
  PreviewWrapper,
} from '../components';
import { useEntityPreview } from '../hooks/use-entity-preview';

import { notePreviewFields } from './NotePreviewContainer';

type EntityUntion = CallMemo | Note;

export type INotePreviewProps = {
  isCallMemo?: boolean;
  notes: EntityUntion[];
  onCloseClicked?: MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onShareClicked?: () => void;
  convertProps?: Pick<IConvertPopoverProps, 'exclude' | 'onClick'>;
};

enum previewFields {
  DATE = 'date',
  CUSTOMER = 'customer',
  CONTACT = 'contact',
  EMPLOYEES = 'employees',
  PREVIEW_TITLE = 'previewTitle',
  PROJECT = 'project',
}

export function NotePreview(props: INotePreviewProps) {
  const {
    notes,
    onCloseClicked,
    onEditClicked,
    isCallMemo,
    onShareClicked,
    convertProps,
  } = props;
  const { t } = useTranslation();
  const language = useMobiscrollLanguage();

  const user = useUser();
  const { isInaccessible } = useInaccessibleFields();
  const entityType = isCallMemo ? Entities.callMemo : Entities.note;

  const titleIsAccessible = !isInaccessible(entityType, 'title');
  const noteUserIsAccessible = !isInaccessible(entityType, 'user');
  const dateIsAccessible = !isInaccessible(entityType, 'date');
  const businessPartnerIsAccessible = !isInaccessible(
    entityType,
    'businessPartner'
  );
  const contactIsAccessible = !isInaccessible(entityType, 'contact');
  const projectIsAccessible = !isInaccessible(entityType, 'project');

  const dateRef = useRef<EntityPickerPopover>(null);
  const customerRef = useRef<EntityPickerPopover>(null);
  const contactRef = useRef<EntityPickerPopover>(null);
  const projectRef = useRef<EntityPickerPopover>(null);
  const employeesRef = useRef<EntityPickerPopover>(null);
  const titleRef = useRef<PreviewTitle>(null);

  const openPicker = useCallback((picker: previewFields) => {
    switch (picker) {
      case previewFields.DATE: {
        dateRef.current.open();
        break;
      }

      case previewFields.CUSTOMER: {
        customerRef.current.open();
        break;
      }

      case previewFields.CONTACT: {
        contactRef.current.open();
        break;
      }

      case previewFields.PROJECT: {
        projectRef.current.open();
        break;
      }

      case previewFields.EMPLOYEES: {
        employeesRef.current.open();
        break;
      }

      case previewFields.PREVIEW_TITLE: {
        titleRef.current.open();
        break;
      }

      default:
        return;
    }
  }, []);

  const [mutate] = useDataMutation<CallMemo | Note, EMode.upsert>({
    entity: isCallMemo ? Entities.callMemo : Entities.note,
    mutationType: EMode.upsert,
    responseData: isCallMemo
      ? (callMemoPreviewFields as never as CallMemo)
      : (notePreviewFields as never as Note),
  });

  const {
    loading,
    isMultiselect,
    commonFields,
    activePicker,
    optimisticTitle,
    handleLock,
    onPopoverClose,
    setOptimisticTitle,
    onEdit,
    canEditAllSelected,
  } = useEntityPreview({
    user,
    subEntityType: entityType,
    entries: notes,
    openPicker,
    mutate,
  });

  const dateIsCommon = commonFields.includes('date');
  const customerIsCommon = commonFields.includes('businessPartner');
  const contactIsCommon = commonFields.includes('contact');
  const projectIsCommon = commonFields.includes('project');
  const employeesIsCommon = commonFields.includes('user');

  const note = notes[0];

  const dateFormatter = (value: string): Date => {
    const newDate = new Date(value);
    const prevDate = new Date(note?.date);
    newDate.setHours(prevDate.getHours());
    newDate.setMinutes(prevDate.getMinutes());
    return newDate;
  };

  const entityTemplate = useEntityTemplate();
  const { actions: contactActions } = useContactActions(notes?.[0]?.contact);

  return (
    <PreviewWrapper>
      <PreviewTitle
        disabled={true}
        showEdit={!isMultiselect && canEditAllSelected}
        ref={titleRef}
        label={note?.title}
        onClick={() =>
          !isMultiselect &&
          titleIsAccessible &&
          handleLock(previewFields.PREVIEW_TITLE)
        }
        onClose={(e) => {
          if (e.target.value !== note?.title) {
            setOptimisticTitle(e.target.value);
            onEdit({ title: e.target.value });
          }
          onPopoverClose();
        }}
        onCloseClicked={onCloseClicked}
        onEditClicked={onEditClicked}
        actions={
          loading && activePicker === previewFields.PREVIEW_TITLE ? (
            <CircularProgress size="1rem" color="secondary" />
          ) : null
        }
        onShareClicked={onShareClicked}
        convertProps={convertProps}
      >
        {isMultiselect
          ? `${t('COMMON.SELECTION')}: ${notes.length} ${t('COMMON.ELEMENTS')}`
          : titleIsAccessible
          ? optimisticTitle ?? note?.title
          : ''}
      </PreviewTitle>
      {isMultiselect && <PreviewEditMessage entityId={String(note.id)} />}

      <InfoCards>
        {noteUserIsAccessible && (
          <EntityPickerPopover
            ref={employeesRef}
            disabled={true}
            onTargetClick={() => handleLock(previewFields.EMPLOYEES)}
            onClose={onPopoverClose}
            picker={
              <UserPicker
                multiple={false}
                value={employeesIsCommon ? note.user : null}
                onChange={(value) => {
                  onEdit({
                    userId: value.id,
                  });
                }}
              />
            }
          >
            <InfoCard
              truncate
              disabled={false}
              label={t('COMMON.EMPLOYEE')}
              decorator={
                loading && activePicker === previewFields.EMPLOYEES ? (
                  <CircularProgress size="1rem" color="secondary" />
                ) : null
              }
              decoratorPos="right"
            >
              {employeesIsCommon
                ? note?.user?.displayName || '-'
                : `(${t('COMMON.MULTIPLE')})`}
            </InfoCard>
          </EntityPickerPopover>
        )}

        {dateIsAccessible && (
          <EntityPickerPopover
            ref={dateRef}
            disabled={true}
            onTargetClick={() => handleLock(previewFields.DATE)}
            onClose={onPopoverClose}
            autoclose={true}
            picker={
              <DatePicker
                locale={language}
                controls={['calendar']}
                defaultValue={formatDateString(
                  note?.date,
                  DateFormatPreset.DATE_SIMPLE_2YEAR
                )}
                onChange={(val) => {
                  const newDate = dateFormatter(val?.value);
                  onEdit({ date: newDate.toISOString() });
                }}
              />
            }
          >
            <InfoCard
              label={t('COMMON.DATE.TIME')}
              decorator={
                loading && activePicker === previewFields.DATE ? (
                  <CircularProgress size="1rem" color="secondary" />
                ) : null
              }
              decoratorPos="right"
            >
              {dateIsCommon
                ? t('COMMON.DATE.TIME.VALUE', {
                    date: formatDateString(
                      note?.date,
                      DateFormatPreset.DATE_SIMPLE_2YEAR
                    ),
                    time: formatDateString(
                      note?.date,
                      DateFormatPreset.TIME_SIMPLE
                    ),
                  })
                : `(${t('COMMON.MULTIPLE')})`}
            </InfoCard>
          </EntityPickerPopover>
        )}

        {businessPartnerIsAccessible && (
          <EntityPickerPopover
            ref={customerRef}
            disabled={true}
            onTargetClick={() => handleLock(previewFields.CUSTOMER)}
            onClose={onPopoverClose}
            footer={entityTemplate.renderPickerFooter({
              entity: Entities.customer,
              popoverRef: customerRef,
              data: note.businessPartner?.data as Customer,
              onChange: (value) => {
                onEdit({
                  businessPartner:
                    value && !Array.isArray(value)
                      ? {
                          data: value,
                          businessPartnerType: SdObjType.KUNDE,
                          id: value?.id,
                        }
                      : null,
                  businessPartnerId:
                    value && !Array.isArray(value) ? value?.id : 0,
                  businessPartnerType: SdObjType.KUNDE,
                });
              },
            })}
            picker={
              <CustomerPicker
                onChange={(value) => {
                  onEdit({
                    businessPartner: value
                      ? {
                          data: value,
                          businessPartnerType: SdObjType.KUNDE,
                          id: value?.id,
                        }
                      : null,
                    businessPartnerId: value ? value?.id : 0,
                    businessPartnerType: SdObjType.KUNDE,
                  });
                }}
                value={note.businessPartner?.data as Customer}
                multiple={false}
              />
            }
          >
            <InfoCard
              truncate
              label={t('COMMON.BUSINESS_PARTNER', {
                context: note?.businessPartner?.businessPartnerType,
              })}
              decorator={
                loading && activePicker === previewFields.CUSTOMER ? (
                  <CircularProgress size="1rem" color="secondary" />
                ) : null
              }
              decoratorPos="right"
              beforeContentElement={
                <OpenFileIconButton data={note?.businessPartner?.data} />
              }
            >
              {customerIsCommon
                ? note?.businessPartner?.data?.name
                : `(${t('COMMON.MULTIPLE')})`}
            </InfoCard>
          </EntityPickerPopover>
        )}

        {contactIsAccessible && (
          <Stack direction="row">
            <div style={{ width: '100%' }}>
              <EntityPickerPopover
                ref={contactRef}
                disabled={true}
                onTargetClick={() =>
                  customerIsCommon &&
                  note?.businessPartner?.data &&
                  handleLock(previewFields.CONTACT)
                }
                onClose={onPopoverClose}
                picker={
                  <ContactPicker
                    prefilter={[
                      {
                        businessPartnerType: { $eq: SdObjType.KUNDE },
                      },
                      {
                        businessPartnerId: {
                          $eq: note?.businessPartner?.data.id,
                        },
                      },
                    ]}
                    value={note.contact}
                    multiple={false}
                    onChange={(value) => {
                      onEdit({
                        contact: value,
                        contactId: value ? value.id : 0,
                      });
                    }}
                  />
                }
              >
                <InfoCard
                  disabled={!customerIsCommon || !note?.businessPartner?.data}
                  tooltip={
                    !customerIsCommon || !note?.businessPartner?.data
                      ? 'Wählen Sie zunächst ein einheitliches Projekt'
                      : undefined
                  }
                  label={t('COMMON.CONTACT')}
                  decorator={
                    loading && activePicker === previewFields.CONTACT ? (
                      <CircularProgress size="1rem" color="secondary" />
                    ) : null
                  }
                  decoratorPos="right"
                >
                  {contactIsCommon
                    ? note?.contact?.displayName || '-'
                    : `(${t('COMMON.MULTIPLE')})`}
                </InfoCard>
              </EntityPickerPopover>
            </div>
            {contactActions}
          </Stack>
        )}

        {projectIsAccessible && (
          <EntityPickerPopover
            ref={projectRef}
            disabled={true}
            onTargetClick={() => handleLock(previewFields.PROJECT)}
            onClose={onPopoverClose}
            footer={entityTemplate.renderPickerFooter({
              entity: Entities.project,
              popoverRef: projectRef,
              data: note.project,
              onChange: (value) => {
                onEdit({
                  project: value,
                  projectId: value && !Array.isArray(value) ? value.id : 0,
                });
              },
            })}
            picker={
              <ProjectPicker
                onChange={(value) => {
                  onEdit({
                    project: value,
                    projectId: value ? value.id : 0,
                  });
                }}
                value={note.project}
                multiple={false}
              />
            }
          >
            <InfoCard
              truncate
              label={t('COMMON.PROJECT')}
              decorator={
                loading && activePicker === previewFields.PROJECT ? (
                  <CircularProgress size="1rem" color="secondary" />
                ) : null
              }
              decoratorPos="right"
              beforeContentElement={<OpenFileIconButton data={note?.project} />}
            >
              {projectIsCommon
                ? note?.project?.name
                : `(${t('COMMON.MULTIPLE')})`}
            </InfoCard>
          </EntityPickerPopover>
        )}
      </InfoCards>

      <Divider orientation="horizontal" />

      <PreviewTextContent>{note?.note}</PreviewTextContent>
    </PreviewWrapper>
  );
}
