import { useEffect, useRef } from 'react';

export const useAutoFocus = (autoFocus: boolean, selectAll = true) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    let toId: number;
    if (inputRef && autoFocus === true) {
      toId = window.setTimeout(() => {
        inputRef.current?.focus();
        if (selectAll) inputRef.current?.select();
      }, 250);
    }
    return () => {
      if (toId !== undefined) clearTimeout(toId);
    };
  }, [autoFocus, selectAll]);

  return inputRef;
};
