import React from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  DeliveryKindPicker,
  IDeliveryKindPickerProps,
} from '@work4all/components/lib/components/entity-picker/delivery-kind-picker/DeliveryKindPicker';

import { reactRefSetter } from '@work4all/utils';

import { LabeledInput } from '../../containers/mask-overlays/locked-inputs';

interface Props extends Omit<IDeliveryKindPickerProps<false>, 'multiple'> {
  error?: string;
}

export const DeliveryKindPickerField = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { t } = useTranslation();
    const deliveryKindWrapperRef = useRef();
    const { error, ...pickerProps } = props;
    const { value, onChange } = pickerProps;

    return (
      <div ref={reactRefSetter(deliveryKindWrapperRef, ref)}>
        <EntityPickerPopover
          anchorEl={deliveryKindWrapperRef}
          picker={<DeliveryKindPicker {...pickerProps} multiple={false} />}
        >
          <LabeledInput
            onClear={() => {
              onChange(null);
            }}
            label={t('INPUTS.DELIVERY_KIND')}
            error={error}
            value={value?.text || ''}
          />
        </EntityPickerPopover>
      </div>
    );
  }
);
