import styles from './NewComment.module.scss';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Stack, Theme, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { useHistoryStack } from '../../../../navigation/history-stack/hooks/useHistoryStack';

import { CommentEditor } from './CommentEditor';

export interface NewCommentProps {
  onSubmit: (comment: string) => void | Promise<void>;
  breadcrumbTitle?: string;
  canAddOrInsertImage: boolean;
  newComment?: string;
  onNewCommentChange?: (value: string) => void;
}

export function NewComment(props: NewCommentProps) {
  const {
    onSubmit,
    breadcrumbTitle,
    canAddOrInsertImage,
    newComment,
    onNewCommentChange,
  } = props;

  const { t } = useTranslation();

  const { close } = useHistoryStack();

  const isUpSm = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <IconButton className={styles.back} onClick={close}>
          <ArrowBackIcon />
        </IconButton>
        <Stack direction={'column'} width="100%" overflow="hidden">
          <Typography variant={isUpSm ? 'h3' : 'h4'} color="text.secondary">
            {t('COMMON.COMMENT')}
          </Typography>
          {breadcrumbTitle && (
            <Box onClick={close} width="100%" flex={1} display="flex">
              <Typography
                variant="caption"
                color="primary.main"
                width="100%"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                flex={1}
                overflow="hidden"
              >
                {breadcrumbTitle}
              </Typography>
            </Box>
          )}
        </Stack>

        <div className={styles.actions}>
          <Button
            disabled={!newComment}
            onClick={() => {
              onSubmit(newComment);
            }}
            size="large"
            color="primary"
            variant="contained"
            sx={{ ml: 'auto' }}
          >
            {t('INPUTS.SAVE')}
          </Button>
        </div>
      </div>

      <div className={styles.editorContainer}>
        <CommentEditor
          value={newComment}
          setValue={onNewCommentChange}
          canAddOrInsertImage={canAddOrInsertImage}
          hideToolbar={!isUpSm}
          editorConf={{
            height: remToPx(8),
            heightMin: remToPx(8),
            heightMax: remToPx(16),
          }}
        />
      </div>
    </div>
  );
}
