import styles from './SeparatorRow.module.scss';

import Typography from '@mui/material/Typography';

import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

export const SEPARATOR_ROW_HEIGHT = remToPx(2);

export interface SeparatorRowProps {
  label: string;
  rowStyle: React.CSSProperties;
}

export function SeparatorRow(props: SeparatorRowProps) {
  const { label, rowStyle } = props;

  return (
    <div className={styles.row} style={rowStyle}>
      <Typography variant="caption" className={styles.label}>
        {label}
      </Typography>
    </div>
  );
}
