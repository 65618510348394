import { Duration } from 'luxon';

export const decimalToDuration = (amount: number) => {
  const hours = Math.floor(amount);
  const minutes = Math.round((amount % 1) * 60);
  return Duration.fromObject({
    hours: hours,
    minutes: minutes,
  });
};
