import { Theme, useMediaQuery } from '@mui/material';
import { useEffect, useMemo } from 'react';

import { AppParts, useCanView } from '@work4all/data';

import { ERPTypes } from '@work4all/models/lib/Classes/ERPTypes.entity';
import { File } from '@work4all/models/lib/Classes/File.entity';
import { FileEntity } from '@work4all/models/lib/Classes/FileEntity.entity';
import { InboundInvoice } from '@work4all/models/lib/Classes/InboundInvoice.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { CustomToolbar } from '../../../dataDisplay/actions-bar/ToolbarTypes';
import { MIME_TYPES } from '../../../preview/Preview';
import {
  DocumentPreview,
  IDocumentPreviewContainerProps,
} from '../document-preview';
import { useEntityPreviewData } from '../hooks/use-entity-preview-data';

import { usePreviewNavigation } from './usePreviewNavigation';

export interface IErpPreviewContainerProps
  extends Omit<IDocumentPreviewContainerProps, 'documentId'> {
  entityId: number;
  entity: Entities;
  openInFullscreen?: boolean;
  onPreviewedFileChange?: (doc: File) => void;
  customActions?: CustomToolbar;
  subtitleComp?: React.ReactNode;
}

export function ErpPreviewContainer(props: IErpPreviewContainerProps) {
  const {
    entityId,
    entity,
    onCloseClicked,
    onEditClicked,
    onEditPdfClicked,
    openInFullscreen = false,
    onPreviewedFileChange,
    subtitleComp,
  } = props;

  const isEditableEntity = useCanView(AppParts.EDIT_MASK_OF_ENTITY, entity);

  const requestEntityData = useMemo(() => {
    if (entity === Entities.inboundInvoice) {
      const inboundInvoiceData: InboundInvoice<EMode.query> = {
        id: null,
        note: null,
        belegList: [
          {
            id: null,
            parentId: null,
            fileInfos: {
              fileEntityFilename: null,
              downloadUrl: null,
              previewUrl: null,
              previewMimeType: null,
              downloadMimeType: null,
              fileServiceProviderInfos: {
                id: null,
                fileName: null,
                mimeType: null,
                fspUrl: null,
              },
            },
          },
        ],
      };
      return inboundInvoiceData;
    } else {
      const requestErpData: ERPTypes<EMode.query> = {
        id: null,
        note: null,
        archivePdf: [
          {
            id: null,
            downloadUrl: null,
            mimeType: null,
            xML: null,
            fileInfos: {
              fileEntityFilename: null,
              downloadUrl: null,
              previewUrl: null,
              previewMimeType: null,
              downloadMimeType: null,
              fileServiceProviderInfos: {
                id: null,
                fileName: null,
                mimeType: null,
                fspUrl: null,
              },
            },
          },
        ],
      };
      return requestErpData;
    }
  }, [entity]);

  const entitiesData = useEntityPreviewData<ERPTypes | InboundInvoice>(
    [entityId],
    entity,
    requestEntityData
  );

  const fileList = useMemo<
    { fileInfos?: FileEntity; mimeType?: string; id?: number }[]
  >(() => {
    const archivePdf = (entitiesData?.[0] as ERPTypes)?.archivePdf;
    let belegList = (entitiesData?.[0] as InboundInvoice)?.belegList;
    if (belegList && belegList.length > 1) {
      belegList = [...belegList];
      belegList.sort((a, b) => {
        return !a.parentId && b.parentId
          ? -1
          : a.parentId && !b.parentId
          ? 1
          : 0;
      });
    }
    return entity !== Entities.inboundInvoice ? archivePdf : belegList;
  }, [entitiesData, entity]);

  const { file, component } = usePreviewNavigation(fileList || []);

  useEffect(() => {
    if (onPreviewedFileChange) {
      onPreviewedFileChange(file);
    }
  }, [file, onPreviewedFileChange]);

  const fileInfos = file?.fileInfos;
  const mimeType = file?.mimeType;

  const isSmUp = useMediaQuery<Theme>((t) => t.breakpoints.up('sm'));

  return (
    <DocumentPreview
      openInFullscreen={openInFullscreen}
      navigation={component}
      title={
        entitiesData?.[0]?.note || fileInfos?.fileServiceProviderInfos?.fileName
      }
      url={fileInfos?.previewUrl}
      mimeType={(fileInfos?.previewMimeType || mimeType) as MIME_TYPES}
      noPreviewUrl={
        entity !== Entities.inboundInvoice
          ? fileInfos?.fileServiceProviderInfos?.fspUrl ||
            fileInfos?.downloadUrl
          : fileInfos?.downloadUrl
      }
      downloadUrl={fileInfos?.downloadUrl}
      onCloseClicked={onCloseClicked}
      onEditClicked={isEditableEntity && isSmUp ? onEditClicked : undefined}
      fspUrl={fileInfos?.fileServiceProviderInfos?.fspUrl}
      erpPreview={true}
      filePath={fileInfos?.fileEntityFilename}
      showFullscreenButton={isSmUp}
      onPdfEditClicked={onEditPdfClicked}
      customActions={props.customActions}
      subtitleComp={subtitleComp}
    />
  );
}
