import { clamp } from 'lodash';

import { useElementSize } from './use-element-size';
import { useYellowNotePosition } from './use-yellow-note-position';

export function useAdjustedYellowNotePosition({
  wrapperEl,
  draggableEl,
}: {
  wrapperEl: HTMLElement | null;
  draggableEl: HTMLElement | null;
}) {
  const [position, setPosition] = useYellowNotePosition();

  const wrapperSize = useElementSize(wrapperEl);
  const draggableSize = useElementSize(draggableEl);

  const x = position
    ? wrapperSize && draggableSize
      ? clamp(position.x, 0, wrapperSize.width - draggableSize.width)
      : position.x
    : null;

  const y = position
    ? wrapperSize && draggableSize
      ? clamp(position.y, 0, wrapperSize.height - draggableSize.height)
      : position.y
    : null;

  const adjustedPosition = { x, y };

  return [adjustedPosition, setPosition] as const;
}
