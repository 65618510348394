import styles from './Actions.module.scss';

import { Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BaseActionButton } from '@work4all/components/lib/input/base-action-button/BaseActionButton';
import { ActionType } from '@work4all/components/lib/input/search-bar-with-fullscreen/types';

interface IActionsProps {
  actions: ActionType[];
  /**
   * Will change all text and button colors to white. Should be set based on the
   * background color.
   */
  inverseColor?: boolean;
}

export const Actions: React.FC<IActionsProps> = (props) => {
  const { actions, inverseColor = false } = props;
  const { t } = useTranslation();
  const lg = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'));

  const renderButton = (el: ActionType, i: number) => {
    const actionProps = { href: undefined, onClick: undefined };
    if (el.handler) {
      actionProps.onClick = el.handler;
    } else if (el.route) {
      actionProps.href = el.route;
    }

    return (
      <BaseActionButton
        key={i}
        title={t(el.title)}
        icon={<el.icon />}
        color={inverseColor ? 'textInverse' : 'primary'}
        {...actionProps}
      />
    );
  };

  let items;
  if (lg) {
    items = <div className={styles.row}>{actions.map(renderButton)}</div>;
  } else {
    items = (
      <Grid container columns={{ xs: 1 }} spacing={1}>
        {actions.map((el, i) => {
          return (
            <Grid key={i} xs={6} item>
              {renderButton(el, i)}
            </Grid>
          );
        })}
      </Grid>
    );
  }

  const marginLeft = lg ? '1rem' : '0';
  return (
    <>
      <Typography
        variant="h4"
        color={inverseColor ? 'text.inverse' : 'text.tertiary'}
        sx={{ margin: `3rem 0 0.5rem ${marginLeft}` }}
      >
        {t('SEARCH.NEXT_ACTIONS')}
      </Typography>
      {items}
    </>
  );
};
