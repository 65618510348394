import styles from './Label.module.scss';

import { Typography } from '@mui/material';
import React from 'react';

import { replaceClassName } from '@work4all/utils/lib/replaceClassName';

import { ITypographyProps } from '..';

export const Label: React.FC<ITypographyProps> = (props) => {
  return (
    <Typography
      className={replaceClassName(styles.label, props.className)}
      component="label"
    >
      {props.children}
    </Typography>
  );
};
