import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputSalesOpportunityAttachementRelation } from './InputSalesOpportunityAttachementRelation.entity';
import { InputSalesOpportunityRatingRelation } from './InputSalesOpportunityRatingRelation.entity';

export class InputVerkaufschanceRelation<T extends EMode = EMode.entity> {
  /** Alias: ratings */
  ratings?: InputSalesOpportunityRatingRelation<T>;
  /** Alias: attachements */
  attachements?: InputSalesOpportunityAttachementRelation<T>;

  __typename?: string;
}

const fields: FieldDefinitions<InputVerkaufschanceRelation> = {
  ratings: {
    alias: 'ratings',
  },
  attachements: {
    alias: 'attachements',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputVerkaufschanceRelationEntityDefinition: EntitiyDefinition<InputVerkaufschanceRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputVerkaufschanceRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
