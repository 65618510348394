import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';

import { BusinessPartnerPicker } from '../../../../components/entity-picker/business-partner-picker/BusinessPartnerPicker';
import { IFilterPickerConfig } from '../../../../components/entity-picker/components/list-entity-picker';
import { FilterCard } from '../filter-card/FilterCard';

export type IBusinessPartnerFilterCardProps = {
  title: string;
  value: (Customer | Supplier)[] | null;
  onChange: (value: (Customer | Supplier)[]) => void;
  expanded: boolean;
  onExpandedChange: (val: boolean) => void;
  filterConfig?: IFilterPickerConfig;
};

export function BusinessPartnerFilterCard(
  props: IBusinessPartnerFilterCardProps
) {
  const { title, value, onChange, expanded, onExpandedChange, filterConfig } =
    props;

  const { t } = useTranslation();

  const filterValueText: string = useMemo(() => {
    if (!value || value.length === 0) return null;
    return value.length + ' ' + t('COMMON.CHOSEN');
  }, [value, t]);

  return (
    <FilterCard
      title={title}
      filterValue={filterValueText}
      filterValueSet={!!filterValueText}
      expanded={expanded}
      onExpandedChange={onExpandedChange}
      onValueDelete={() => {
        onChange([]);
      }}
    >
      <BusinessPartnerPicker
        layout="advanced"
        multiple={true}
        value={value}
        onChange={(newValue) => {
          onChange(newValue);
        }}
        filterConfig={filterConfig}
      />
    </FilterCard>
  );
}
