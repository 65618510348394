import { DateTime } from 'luxon';

import { TimeTracker, TimeTrackerState } from './use-time-tracker';

function checkAllowChangeDate(
  state: TimeTrackerState,
  stoppedSince: DateTime,
  presentSince: DateTime
): boolean {
  if (state === 'initial' && stoppedSince == null) return false;
  else if (state === 'running' && presentSince.day !== DateTime.now().day)
    return true;
}

function getMinimumDate(
  state: TimeTrackerState,
  stoppedSince: DateTime,
  presentSince: DateTime,
  lastPauseFinished: DateTime
): DateTime {
  if (state === 'running' && lastPauseFinished > presentSince)
    return lastPauseFinished;
  if (state === 'running') return presentSince;
  else if (state === 'stopped' && stoppedSince !== null) return stoppedSince;
}

export const TimeTrackerInputSettings = (
  tracker: TimeTracker,
  type: 'start' | 'stop'
) => {
  const { state, stoppedSince, presentSince, lastPauseFinished } =
    tracker.result;

  return {
    allowChangeDate: checkAllowChangeDate(state, stoppedSince, presentSince),
    minimumDateTime: getMinimumDate(
      state,
      stoppedSince,
      presentSince,
      lastPauseFinished
    ),
    minimumDateAlert: `TIME_TRACKER.DIALOG.EARLIEST.${type}`,
    maximumDateTime: DateTime.now(),
  };
};
