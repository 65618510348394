import Typography from '@mui/material/Typography';
import { useMemo } from 'react';

import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type IProjectProcessPickerProps<TMultiple extends boolean> =
  IPickerProps<ProjectProcess, TMultiple> & { projectId: number };

export function ProjectProcessPicker<TMultiple extends boolean>(
  props: IProjectProcessPickerProps<TMultiple>
) {
  const { projectId, data, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...PROJECTPROCESS_FIELDS, ...data };
  }, [data]);

  if (!projectId) return null;

  let prefilter: unknown[] = [{ projectId: { $eq: projectId } }];
  if (props?.prefilter) {
    prefilter = prefilter.concat(props.prefilter);
  }

  return (
    <ListEntityPicker
      entity={Entities.projectProcess}
      multiple={false}
      data={allFields}
      filterBy={['process', 'number']}
      sortBy="process"
      {...rest}
      prefilter={prefilter}
      renderItemContent={(projectProcess) => (
        <Tooltip title={projectProcess.process}>
          <Typography variant="body2" noWrap>
            {projectProcess.process}
          </Typography>
        </Tooltip>
      )}
    />
  );
}

const PROJECTPROCESS_FIELDS: ProjectProcess = {
  id: null,
  process: null,
};
