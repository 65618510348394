import styles from './TaskPriorityCell.module.scss';

import { ReactComponent as TaskPriorityMarkIcon } from '@work4all/assets/icons/task-actions/table/priority-mark.svg';

import { TicketPriority } from '@work4all/components/lib/components/entity-picker/PriorityPicker';

import { Task } from '@work4all/models/lib/Classes/Task.entity';

export type ITicketPriorityCellProps = {
  value: TicketPriority;
  row: { original: Task };
};

export const TicketPriorityCell = (props: ITicketPriorityCellProps) => {
  const { value } = props;
  return value ? (
    <div className={styles.taskPriorityCellRoot}>
      {value === TicketPriority.HIGH && <TaskPriorityMarkIcon />}
    </div>
  ) : null;
};
