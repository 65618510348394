import styles from './Button.module.scss';

import { ButtonBase } from '@mui/material';
import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';

import { Link } from '@work4all/data';

interface IButtonProps {
  href: string;
  active?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  className?: string;
}

export const Button: React.FC<PropsWithChildren<IButtonProps>> = (props) => {
  return (
    <ButtonBase
      component={Link}
      to={props.href}
      onClick={props.onClick}
      className={clsx(styles['button'], props.className, {
        [styles['button--active']]: props.active,
      })}
    >
      {props.children}
    </ButtonBase>
  );
};
