import styles from './CreditorPicker.module.scss';

import { Stack, Typography } from '@mui/material';
import { useMemo } from 'react';

import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type CreditorPickerProps<TMultiple extends boolean> = IPickerProps<
  Supplier,
  TMultiple
>;

export const renderCreditorPickerItem = (item: Supplier) =>
  item ? (
    <Tooltip title={item.name}>
      <Stack direction="row" gap="0.5rem">
        <Typography variant="body2" noWrap className={styles.number}>
          {item.number}
        </Typography>
        {item.number && item.name ? (
          <Typography variant="body2" noWrap className={styles.number}>
            {'|'}
          </Typography>
        ) : null}
        <Typography variant="body2" noWrap>
          {item.name}
        </Typography>
      </Stack>
    </Tooltip>
  ) : undefined;

export function CreditorPicker<TMultiple extends boolean>(
  props: CreditorPickerProps<TMultiple>
) {
  const { data, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...FIELDS, ...data };
  }, [data]);

  return (
    <ListEntityPicker
      entity={Entities.supplier}
      data={allFields}
      filterBy={['name', 'number']}
      sortBy="name"
      {...rest}
      renderItemContent={renderCreditorPickerItem}
    />
  );
}

const FIELDS: Supplier = {
  id: null,
  name: null,
  number: null,
};
