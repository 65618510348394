import markdownStyles from './Markdown.module.scss';
import styles from './ReleaseNotesDialog.module.scss';

import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogActions,
  DialogContent,
} from '@work4all/components/lib/dialog';

import { useReleaseNotesDialogContext } from '../../../../contexts/ReleaseNotesDialogContext';

export interface ReleaseNotesDialogProps {
  open: boolean;
  onClose: () => void;
}

export function ReleaseNotesDialog(props: ReleaseNotesDialogProps) {
  const { open, onClose } = props;

  const { t } = useTranslation();

  const { releaseNotesFile, version } = useReleaseNotesDialogContext();

  if (!releaseNotesFile) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={
        <div className={styles['dialog-title']}>
          {t('RELEASE_NOTES.DIALOG.title')}
          <Typography component="span" color="text.disabled">
            {t('RELEASE_NOTES.DIALOG.subtitle', { version })}
          </Typography>
        </div>
      }
      classes={{
        dialog: {
          paper: styles['dialog'],
        },
      }}
    >
      <ReleaseNotesDialogContent onClose={onClose} html={releaseNotesFile} />
    </Dialog>
  );
}

function ReleaseNotesDialogContent({
  onClose,
  html,
}: {
  onClose: () => void;
  html: string;
}) {
  const { t } = useTranslation();

  return (
    <>
      <DialogContent>
        <div className={markdownStyles['markdown-content']}>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </DialogContent>

      <DialogActions>
        <Button size="large" color="primary" onClick={onClose}>
          {t('ALERTS.CLOSE')}
        </Button>
      </DialogActions>
    </>
  );
}
