import { useCallback, useEffect, useRef, useState } from 'react';

import { useDataProvider } from '@work4all/data';

import { DataRequest } from '@work4all/models/lib/DataProvider';

/**
 * Fetches the initial form value using `useDataProvider` but ignores subsequent
 * updates. This is useful so that the form does not reset by itself if the
 * query updates. (This currently happens after any entity mutation.)
 *
 * To update the form after a submit you can call `onMutateSuccess` in
 * mutation success handler. The next query update after calling
 * `onMutateSuccess` will result in `value` updating.
 * @param request
 * @param skip
 * @returns
 */
export function useInitialFormValue<T = unknown>(
  request: DataRequest,
  skip: boolean
) {
  const response = useDataProvider<T>(request, skip);

  const value = response.data[0] ?? null;
  const loading = response.loading;
  const { refetch } = response;

  const [savedValue, setSavedValue] = useState(value);

  const ignoreChangesRef = useRef(false);

  const refetchCB = useCallback(() => {
    ignoreChangesRef.current = false;
    return refetch();
  }, [refetch]);

  useEffect(() => {
    ignoreChangesRef.current = false;
  }, [request, skip]);

  useEffect(() => {
    if (ignoreChangesRef.current) {
      return;
    }
    setSavedValue(value);
  }, [value]);

  useEffect(() => {
    if (loading === false) {
      ignoreChangesRef.current = true;
    }
  }, [loading]);

  const onMutateSuccess = () => {
    ignoreChangesRef.current = false;
  };

  return { value: savedValue, onMutateSuccess, refetch: refetchCB, loading };
}
