import styles from '../PositionPicker.module.scss';

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ISearchProps = {
  onChange: (value: string) => void;
  disabled: boolean;
};

export const ArticleSearch = (props: ISearchProps) => {
  const { t } = useTranslation();
  const { onChange, disabled } = props;

  const inputRef = useRef(null);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  const handleClear = () => {
    setValue('');
    onChange('');
    inputRef.current?.focus();
    inputRef.current.value = '';
  };

  const [value, setValue] = useState<string>('');

  return (
    <div className={styles.search}>
      <SearchIcon className={styles.searchIcon} />
      <input
        ref={inputRef}
        autoFocus
        type="text"
        autoComplete="off"
        placeholder={t('SEARCH.ARTICLE_PICKER')}
        className={styles.searchInput}
        value={value}
        onChange={handleChange}
        disabled={disabled}
      />

      {value && (
        <IconButton className={styles.searchClear} onClick={handleClear}>
          <ClearIcon />
        </IconButton>
      )}
    </div>
  );
};
