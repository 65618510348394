import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { InboundInvoice } from './InboundInvoice.entity';

export class ModifyShadowREResult<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: name */
  name?: string;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: sdObjMemberCode */
  sdObjMemberCode?: number;
  /** Alias: data */
  data?: InboundInvoice<T>;
  /** Alias: incommingInvoiceCode */
  incommingInvoiceCode?: number;
  /** Alias: generatedLineCodes */
  generatedLineCodes?: number[];

  __typename?: string;
}

const fields: FieldDefinitions<ModifyShadowREResult> = {
  id: {
    alias: 'id',
  },
  name: {
    alias: 'name',
  },
  userId: {
    alias: 'benutzerCode',
  },
  sdObjMemberCode: {
    alias: 'sdObjMemberCode',
  },
  data: {
    alias: 'data',
    entity: Entities.inboundInvoice,
  },
  incommingInvoiceCode: {
    alias: 'incommingInvoiceCode',
  },
  generatedLineCodes: {
    alias: 'generatedLineCodes',
  },
  __typename: {
    alias: '__typename',
  },
};

export const modifyShadowREResultEntityDefinition: EntitiyDefinition<ModifyShadowREResult> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'ModifyShadowREResult',
      withPaginationWrapper: false,
      mutations: {
        upsert: {
          mutationName: 'createShadowRe',
          args: [
            { name: 'sdObjMemberCode', type: 'Int' },
            { name: 'reCode', type: 'Int' },
            { name: 'projectCode', type: 'Int' },
            { name: 'name', type: 'String' },
          ],
        },
      },
    },
    fieldDefinitions: fields,
  };
