import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { EntityAccessRightsState } from './EntityAccessRightsState.entity';

export class EntityAccessRights<T extends EMode = EMode.entity> {
  /** Alias: key */
  key?: string;
  /** Alias: values */
  values?: EntityAccessRightsState<T>[];
  /** Alias: userOwnagePropertyName */
  userOwnagePropertyName?: string;

  __typename?: string;
}

const fields: FieldDefinitions<EntityAccessRights> = {
  key: {
    alias: 'key',
  },
  values: {
    alias: 'values',
    entity: Entities.entityAccessRightsState,
  },
  userOwnagePropertyName: {
    alias: 'userOwnagePropertyName',
  },
  __typename: {
    alias: '__typename',
  },
};

export const entityAccessRightsEntityDefinition: EntitiyDefinition<EntityAccessRights> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'EntityAccessRights',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
