import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class ProductionContractGroup<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: index */
  index?: number;
  /** Alias: level */
  level?: number;
  /** Alias: name */
  name?: string;

  __typename?: string;
}

const fields: FieldDefinitions<ProductionContractGroup> = {
  id: {
    alias: 'code',
  },
  index: {
    alias: 'index',
  },
  level: {
    alias: 'level',
  },
  name: {
    alias: 'name',
  },
  __typename: {
    alias: '__typename',
  },
};

export const productionContractGroupEntityDefinition: EntitiyDefinition<ProductionContractGroup> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'ProduktionsauftragGruppe',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
