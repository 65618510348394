import styles from './ProjectHourEvent.module.scss';

import { CloseRounded } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { IconButton } from '@mui/material';
import clsx from 'clsx';
import { Duration } from 'luxon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export interface ProjectHourEventPrpos {
  fixed: boolean;
  suggested: boolean | null;
  duration: Duration;
  firstText?: string;
  customer?: string;
  project?: string;
  contract?: string;
  ticket?: string;
  onHide?: () => void;
}

interface ProjectHourEventSection {
  key: ProjectHourEventSectionType;
  value: string;
}
type ProjectHourEventSectionType =
  | 'firstText'
  | 'customer'
  | 'project'
  | 'contract'
  | 'ticket';

const ProjectHourEvent = ({
  fixed,
  suggested,
  duration,
  firstText = '',
  customer = '',
  project = '',
  contract = '',
  ticket = '',
  onHide,
}: ProjectHourEventPrpos) => {
  const content = useMemo(() => {
    let values: ProjectHourEventSection[] = [
      { key: 'firstText', value: firstText.trim() },
      { key: 'customer', value: customer.trim() },
      { key: 'project', value: project.trim() },
      { key: 'contract', value: contract.trim() },
      { key: 'ticket', value: ticket.trim() },
    ];
    values = values.filter((el) => el.value);

    if (values.length > 0) {
      return values.map((el, idx) => {
        if (idx === 0) {
          return (
            <FirstRow
              el={el}
              duration={duration}
              onHide={onHide}
              suggested={suggested}
            />
          );
        }
        return <SecondaryRow el={el} />;
      });
    }
    return [
      <div className={styles.firstLine}>
        <div className={styles.row}></div>
        <DurationAndHideIcon
          duration={duration}
          onHide={onHide}
          suggested={suggested}
        />
      </div>,
    ];
  }, [
    firstText,
    customer,
    project,
    contract,
    ticket,
    duration,
    onHide,
    suggested,
  ]);

  return (
    <ContentComponent suggested={suggested} fixed={fixed}>
      {fixed ? content[0] : content}
    </ContentComponent>
  );
};

interface RowProps {
  el: ProjectHourEventSection;
  variant?: 'body1' | 'caption';
  color?: string;
  fontSize?: string;
}

const Row = ({ children }) => {
  return <div className={styles.row}>{children}</div>;
};

const FirstRow = (props: { el; duration; onHide; suggested }) => {
  const { el } = props;

  let component;
  switch (el.key) {
    case 'firstText':
      component = <ValueRow {...props} variant="body1" color="text.primary" />;
      break;
    default:
      component = (
        <>
          <KeyRow {...props} variant="body1" color="text.tertiary" />
          <ValueRow {...props} variant="body1" color="text.primary" />
        </>
      );
      break;
  }

  return (
    <div className={styles.firstLine}>
      <Row>{component}</Row>
      <DurationAndHideIcon {...props} />
    </div>
  );
};

const SecondaryRow = (props: { el }) => {
  const { el } = props;

  let component;
  switch (el.key) {
    default:
      component = (
        <>
          <KeyRow {...props} variant="caption" color="var(--text03)" />
          <ValueRow {...props} variant="caption" color="var(--text01)" />
        </>
      );
      break;
  }

  return <Row>{component}</Row>;
};

const KeyRow = (props: RowProps) => {
  const { el, variant, color } = props;
  const { t } = useTranslation();

  return (
    <Typography variant={variant} color={color}>
      {t('COMMON.' + el?.key.toUpperCase())}:&nbsp;
    </Typography>
  );
};

const ValueRow = (props: RowProps) => {
  const { el, variant, color, fontSize } = props;

  return (
    <Typography variant={variant} color={color} fontSize={fontSize}>
      {el?.value}
    </Typography>
  );
};

const DurationAndHideIcon = ({ duration, onHide, suggested }) => {
  return (
    <div className={styles.durationAndHideIcon}>
      <Typography className={styles.timeBox} color={'text.tertiary'}>
        {duration.toFormat('hh:mm')} h
      </Typography>
      {suggested && (
        <IconButton
          size="small"
          onClick={(e) => {
            //to prevent opning the mask
            e.stopPropagation();
            onHide();
          }}
        >
          <CloseRounded
            sx={(theme) => ({ color: theme.palette.text.secondary })}
          />
        </IconButton>
      )}
    </div>
  );
};

const ContentComponent = ({ suggested, fixed, children }) => {
  return (
    <div className={styles.container}>
      {suggested && (
        <div className={clsx(styles.addContainer, fixed && styles.fixed)}>
          +
        </div>
      )}
      <div
        className={clsx(
          styles.contentContainer,
          fixed && styles.fixed,
          suggested && styles.suggested
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default ProjectHourEvent;
