import React, { useCallback, useMemo, useReducer } from 'react';

import { randomId } from '@work4all/utils';

import { AlertDialog } from '../dialogs/AlertDialog/AlertDialog';
import { ConfirmDialog } from '../dialogs/ConfirmDialog/ConfirmDialog';

import { DialogsContext, DialogsContextValue } from './context';
import { dialogsReducer } from './reducer';
import {
  AlertDialogState,
  ConfirmDialogState,
  CustomDialogState,
  DialogState,
} from './types';

export interface DialogsProviderProps {
  children?: React.ReactNode;
}

export function DialogsProvider(props: DialogsProviderProps) {
  const { children } = props;

  const [state, dispatch] = useReducer(dialogsReducer, { dialogs: [] });

  const close = useCallback<DialogsContextValue['close']>(
    (id) => {
      dispatch({ type: 'close_dialog', id: id });

      // After some time remove the dialog. To preserve the closing animation
      // the dialog must stay in state and be rendered while it is closing.
      setTimeout(() => {
        dispatch({ type: 'remove_dialog', id: id });
      }, 1000);
    },
    [dispatch]
  );

  const confirm = useCallback<DialogsContextValue['confirm']>(
    (props) => {
      return new Promise((resolve) => {
        const id = randomId();

        const handleConfirm = () => {
          props.onConfirm?.();
          resolve(true);
          close(id);
        };

        const handleCancel = () => {
          props.onCancel?.();
          resolve(false);
          close(id);
        };

        const dialog: ConfirmDialogState = {
          type: 'confirm',
          id: id,
          open: true,
          props: {
            ...props,
            onConfirm: handleConfirm,
            onCancel: handleCancel,
          },
        };

        dispatch({ type: 'add_dialog', dialog: dialog });
      });
    },
    [dispatch, close]
  );

  const alert = useCallback<DialogsContextValue['alert']>(
    (props) => {
      return new Promise((resolve) => {
        const id = randomId();

        const handleClose = () => {
          props.onClose?.();
          resolve();
          close(id);
        };

        const dialog: AlertDialogState = {
          type: 'alert',
          id: id,
          open: true,
          props: {
            ...props,
            onClose: handleClose,
          },
        };

        dispatch({ type: 'add_dialog', dialog: dialog });
      });
    },
    [dispatch, close]
  );

  const open = useCallback<DialogsContextValue['open']>(
    (component, props) => {
      const id = randomId();

      const dialog: CustomDialogState<React.ComponentProps<typeof component>> =
        {
          type: 'custom',
          id: id,
          open: true,
          component: component,
          props: props,
        };

      dispatch({ type: 'add_dialog', dialog: dialog });

      return { id: id };
    },
    [dispatch]
  );

  const context = useMemo<DialogsContextValue>(() => {
    return {
      confirm: confirm,
      alert: alert,
      open: open,
      close: close,
    };
  }, [confirm, alert, open, close]);

  function renderDialogs() {
    return state.dialogs.map((dialog) => {
      return renderDialog(dialog);
    });
  }

  function renderDialog(dialog: DialogState) {
    const { type, id, open, props } = dialog;

    switch (type) {
      case 'confirm': {
        return <ConfirmDialog key={id} open={open} {...props} />;
      }

      case 'alert': {
        return <AlertDialog key={id} open={open} {...props} />;
      }

      case 'custom': {
        const Component = dialog.component;
        return <Component key={id} open={open} {...props} />;
      }

      default:
        return null;
    }
  }

  return (
    <DialogsContext.Provider value={context}>
      {renderDialogs()}
      {children}
    </DialogsContext.Provider>
  );
}
