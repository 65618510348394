import { noop } from 'lodash';
import { createContext } from 'react';

export type AttachmentRenameContextValue = {
  /**
   * Renaming can be disabled if attachments are read-only. In this case the
   * name should not be editable and the actual filename should be used.
   */
  disabled: boolean;

  /**
   * Returns the name of a given attachment. Returns `null` if the name hasn't
   * been changed yet, or if renaming is disabled.
   */
  getName(id: string | number): string | null;

  /**
   * Sets the name of a given attachment. Has no effect if renaming is disabled.
   */
  setName(id: string | number, name: string): void;
};

export const AttachmentRenameContext =
  createContext<AttachmentRenameContextValue>({
    disabled: true,
    getName: () => null,
    setName: noop,
  });
