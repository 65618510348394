import { FavoritesProvider } from '@work4all/data/lib/hooks/favorites/FavoritesProvider';

import { settings, useSetting } from '../settings';

export const HookedFavoritesProvider = (props) => {
  const favoritesRegister = useSetting(settings.favoritesRegister());

  return (
    <FavoritesProvider favoritesRegister={favoritesRegister}>
      {props.children}
    </FavoritesProvider>
  );
};
