import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import {
  BasicTableColumn,
  Table,
  TableStateBagProvider,
} from '@work4all/components';
import { useRowSelection } from '@work4all/components/lib/dataDisplay/basic-table/hooks/useRowSelection';
import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';

import { useUser } from '@work4all/data/lib/hooks/useUser';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PathsOf } from '@work4all/utils/lib/paths-of/paths-of';
import {
  canDeleteCustomerContact,
  canDeleteSupplierContact,
  canEditCustomerContact,
  canEditSupplierContact,
} from '@work4all/utils/lib/permissions';

import { useUserColumnConfigs } from '../../../../../../../../components/data-tables/use-user-column-configs';
import { ActionsBar } from '../../../../../../data-display/actions-bar/ActionsBar';
import { useFormContextPlus } from '../../../../../../form-plus/use-form-context-plus';
import { Panel } from '../../../../../components/panel/Panel';
import { UpdateEntity } from '../../../../../components/update-entity/UpdateEntity';
import { useMaskContext } from '../../../../../hooks/mask-context';
import { useStandardDeleteEntityHandler } from '../../../../../hooks/use-standard-delete-entity-handler';
import { ContactsOverlayController } from '../../../../contacts/ContactsOverlayController';
import { BusinessPartners } from '../../../BusinessPartnerOverlayController';
import { BPMaskFormValue } from '../../../types';

export function ContactsTabPanel() {
  const [tableInstance, setTableInstance] = useState<TableInstance>(null);

  return (
    <TableStateBagProvider tableInstance={tableInstance}>
      <ContactsTabPanelInner tableRef={setTableInstance} />
    </TableStateBagProvider>
  );
}

export const ContactsTabPanelInner = (props: {
  tableRef: React.Dispatch<TableInstance>;
}) => {
  const { tableRef } = props;

  const { t } = useTranslation();
  const { go, goBack, setObjectionListener } = useHistoryStack();
  const mask = useMaskContext();
  const { onSelectedRowsChange, selectedRows } = useRowSelection();
  const { watch } = useFormContextPlus<BPMaskFormValue>();
  const user = useUser();

  const contactRights = useMemo(() => {
    switch (mask.entity) {
      case Entities.customer:
        return {
          delete: canDeleteCustomerContact(user, mask.data),
          edit: canEditCustomerContact(user, mask.data),
          create: canEditCustomerContact(user, mask.data),
        };
      case Entities.supplier:
        return {
          delete: canDeleteSupplierContact(user, mask.data),
          edit: canEditSupplierContact(user, mask.data),
          create: canEditSupplierContact(user, mask.data),
        };
      default:
        return {
          delete: false,
          edit: false,
          create: false,
        };
    }
  }, [mask.data, mask.entity, user]);

  const { data: entityData } = useMaskContext<BusinessPartners>();

  const businessPartnerPhone = watch('phoneNumber');

  const columns = useMemo(() => {
    const formatPhone = () => {
      if (businessPartnerPhone?.split('-').length > 1) {
        return businessPartnerPhone?.slice(0, -1).replace(',', '-');
      } else return businessPartnerPhone + '-';
    };

    const formattedPhone = formatPhone();

    const columns: (BasicTableColumn & { accessor: PathsOf<Contact> })[] = [
      {
        id: 'title',
        Header: t('COMMON.TITLE'),
        accessor: 'salutation.name',
        width: 80,
        minWidth: 80,
        maxWidth: 150,
      },
      {
        id: 'firstName',
        Header: t('COMMON.NAME'),
        accessor: 'firstName',
        width: 100,
        minWidth: 100,
      },
      {
        id: 'name',
        Header: t('INPUTS.LAST_NAME'),
        accessor: 'name',
        width: 100,
        minWidth: 100,
      },
      {
        id: 'role',
        Header: t('INPUTS.FUNCTION'),
        accessor: 'role',
        width: 100,
        minWidth: 100,
      },
      {
        id: 'department.name',
        Header: t('INPUTS.DEPARTMENT'),
        accessor: 'department.name',
        width: 100,
        minWidth: 100,
      },
      {
        id: 'eMail',
        Header: t('INPUTS.EMAIL'),
        accessor: 'eMail',
        width: 100,
        minWidth: 100,
      },
      {
        id: 'mobileNumber',
        Header: t('FILE_CONTACTS.PHONE_TYPE.CONTACT_PHONE_MOBILE'),
        accessor: 'mobileNumber',
        width: 100,
        minWidth: 100,
      },
      {
        id: 'phoneExtension',
        Header: t('INPUTS.PHONE_AND_EXTENSION_NUMBERS'),
        accessor: 'phoneNumber',
        width: 100,
        minWidth: 100,
        Cell: (col) => {
          return col.value && col.value.length < 5
            ? formattedPhone + col.value
            : col.value;
        },
      },
    ];

    return columns;
  }, [t, businessPartnerPhone]);

  const [userColumns] = useUserColumnConfigs({
    // @ts-expect-error needed until changing columns configuration
    columnConfigs: columns,
    entityType: Entities.contact,
    layout: 'table',
  });

  const openContactMask = useCallback(
    (props: { id?: string; addNew?: boolean } = {}) => {
      const { addNew, id } = props;

      const onAfterSave = () => {
        setObjectionListener(null);
        goBack();
      };

      go({
        view: (
          <ContactsOverlayController
            entity={Entities.contact}
            id={addNew ? null : id}
            template={{ entity: mask.entity, id: mask.id }}
            onAfterSave={onAfterSave}
          />
        ),
      });
    },
    // ToDo: RK check if this is correct, just disbaled to please linting
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [entityData?.contactList, go, goBack, mask.entity, mask.id, selectedRows]
  );

  const contactList = entityData?.contactList || [];

  const deleteContact = useStandardDeleteEntityHandler({
    entity: Entities.contact,
    id: selectedRows[0],
    template: { entity: mask.entity, id: mask.id },
    amplitudeEntryPoint: mask.entity + 'Mask',
  });

  const rowModifiers = useCallback((value) => {
    return {
      isFaded: value.layedOff === true,
    };
  }, []);

  if (userColumns === null) return null;

  return (
    <Panel>
      {!entityData?.id && <UpdateEntity />}
      {entityData?.id && (
        <>
          <ActionsBar
            add={{
              disabled: !contactRights.create,
              onClick: () => openContactMask({ addNew: true }),
            }}
            edit={
              selectedRows.length === 1 && {
                disabled: !contactRights.edit,
                onClick: () =>
                  openContactMask({ id: selectedRows[0].toString() }),
              }
            }
            remove={
              selectedRows.length === 1 && {
                disabled: !contactRights.delete,
                onClick: deleteContact,
              }
            }
            hideMoreButton={true}
            isGrouped={false}
          />
          <Table
            ref={tableRef}
            cardsView={false}
            onSelectedRowsChange={onSelectedRowsChange}
            allItemsCount={contactList?.length}
            resizableColumns
            reordableColumns
            // @ts-expect-error needed until changing columns configuration
            columns={userColumns}
            mode="client"
            data={contactList}
            onRowDoubleClick={(id) => openContactMask({ id })}
            fitContentHeight
            prepareRowDisplayModifiers={rowModifiers}
          />
        </>
      )}
    </Panel>
  );
};
