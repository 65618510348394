import { DateTime } from 'luxon';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useTableStateBag } from '@work4all/components';
import { CountIndicator } from '@work4all/components/lib/dataDisplay/count-indicator/CountIndicator';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { CheckListField } from '@work4all/components/lib/input/check-list-field/CheckListField';

import { useDataProvider } from '@work4all/data';
import { useEntityEvents } from '@work4all/data/lib/entity-events/use-entity-events';

import { W4ADateFormat } from '@work4all/models/lib/additionalEnums/DateFormat.enum';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { TicketFilter } from '@work4all/models/lib/Classes/TicketFilter.entity';
import { TicketKind } from '@work4all/models/lib/Classes/TicketKind.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { TicketStatus } from '@work4all/models/lib/Enums/TicketStatus.enum';

const endDate = DateTime.now().endOf('day');
const statusList = [TicketStatus.OFFEN];
const statusFilter = statusList.map((x) => ({ id: x }));
const ActiveTicketIndicator = (props: { id: number }) => {
  const { id } = props;
  const requestData = useMemo(
    () => ({
      entity: Entities.ticket,
      data: {},
      filter: [
        {
          followUpDate: {
            $lt: endDate.toFormat(W4ADateFormat.DEFAULT),
          },
        },
        {
          ticketKindId: {
            $eq: id,
          },
        },
        {
          $and: [
            {
              status1: {
                $in: statusList,
              },
            },
          ],
        },
      ],
    }),
    [id]
  );
  const result = useDataProvider<Ticket>(requestData);

  useEntityEvents((event) => {
    if (event.entity === Entities.ticket) {
      result.refetch();
    }
  });

  return <CountIndicator variant="secondary" value={result.total} />;
};

const data = {
  id: null,
  name: null,
};

export function KindFilters({
  selectedFilter,
  setSelectedFilter,
}: {
  selectedFilter: TicketFilter | TicketKind;
  setSelectedFilter: Dispatch<SetStateAction<TicketFilter | TicketKind>>;
}) {
  const { t } = useTranslation();

  const requestData = useMemo(
    () => ({
      entity: Entities.ticketKind,
      data,
      operationName: 'GetTicketKindList',
      completeDataResponse: true,
    }),
    []
  );

  const categories = useDataProvider<TicketKind>(requestData);

  const tableStateBag = useTableStateBag();

  const ticketKindColumn = tableStateBag.columnsById['ticketKind.id'];
  const statusColumn = tableStateBag.columnsById['status1'];
  const dateColumn = tableStateBag.columnsById['followUpDate'];
  const columns = tableStateBag.columnsById;
  const setSearchFilterText = tableStateBag.setSearchFilterText;

  const clearCurrentFilters = useCallback(() => {
    Object.entries(columns).forEach((element) => {
      element[1].setFilter(null);
    });
    setSearchFilterText('');
  }, [columns, setSearchFilterText]);

  const handleGroupsChange = useCallback(
    (groups: TicketKind[]) => {
      clearCurrentFilters();
      if (groups.length === 0) {
        ticketKindColumn.setFilter(null);
        statusColumn.setFilter(null);
        setSelectedFilter(undefined);
      } else {
        dateColumn.setFilter({
          value: {
            endDate: endDate.toJSDate(),
          },
          filterType: dateColumn.filterType,
        });
        statusColumn.setFilter({
          value: statusFilter,
          filterType: statusColumn.filterType,
        });

        ticketKindColumn.setFilter({
          value: groups,
          filterType: ticketKindColumn.filterType,
        });

        setSelectedFilter(groups[0]);
      }
    },
    [
      clearCurrentFilters,
      ticketKindColumn,
      statusColumn,
      dateColumn,
      setSelectedFilter,
    ]
  );

  return (
    <CheckListField
      labelElement={
        <Divider
          title={t('TICKET.KIND')}
          horizontalPadding={true}
          style={{ paddingBottom: '1rem' }}
        />
      }
      onChange={handleGroupsChange}
      all={categories.data}
      value={[selectedFilter]}
      renderIndicator={(id) =>
        typeof id === 'number' ? <ActiveTicketIndicator id={id} /> : undefined
      }
      hideCheckboxs
    />
  );
}
