import styles from './TableQuickFiltersSectionHeader.module.scss';

import { CheckListLabel } from '../../filters/PersonalAndGeneralView/components/CheckListLabel';

export interface TableQuickFiltersSectionHeaderProps {
  children: string;
}

export function TableQuickFiltersSectionHeader(
  props: TableQuickFiltersSectionHeaderProps
) {
  const { children } = props;

  return (
    <div className={styles.tableQuickFiltersSectionHeader}>
      <CheckListLabel value={children} />
    </div>
  );
}
