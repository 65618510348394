import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { MailboxType } from './types';

export interface MailboxTypeOptionProps {
  value: MailboxType;
}

export function MailboxTypeOption(props: MailboxTypeOptionProps) {
  const { value } = props;

  const { t } = useTranslation();

  switch (value) {
    case 'microsoft':
      return (
        <StyledOption>
          <MicrosoftIcon />
          <Typography component="span" variant="body2" noWrap>
            {t('LINK_MAILBOX.MICROSOFT_ACCOUNT')}
          </Typography>
        </StyledOption>
      );

    case 'manual':
      return (
        <StyledOption>
          <SMTPIcon />
          <Typography component="span" variant="body2" noWrap>
            {t('LINK_MAILBOX.INPUTS.SMTP')}
          </Typography>
        </StyledOption>
      );

    default:
      return null;
  }
}

const StyledOption = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  paddingTop: '1rem',
}));

function MicrosoftIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M3 3H21V21H3V3Z" fill="white" />
      <path d="M3 3H11V11H3V3Z" fill="#F24E1F" />
      <path d="M13 3H21V11H13V3Z" fill="#7FBA00" />
      <path d="M13 13H21V21H13V13Z" fill="#FFB900" />
      <path d="M3 13H11V21H3V13Z" fill="#00A4EF" />
    </svg>
  );
}

function SMTPIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M8 8C8 5.79 9.79 4 12 4C14.21 4 16 5.79 16 8C16 10.21 14.21 12 12 12C9.79 12 8 10.21 8 8Z"
        fill="black"
      />
      <path
        d="M4 18C4 15.34 9.33 14 12 14C14.67 14 20 15.34 20 18V20H4V18Z"
        fill="black"
      />
    </svg>
  );
}
