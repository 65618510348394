import { Tooltip as TooltipBase, TooltipProps } from '@mui/material';
import React, { useState } from 'react';

export const Tooltip: React.FC<
  TooltipProps & {
    //show this tooltip even if the target element ist disabled
    activateForDisabled?:
      | boolean
      | {
          //show this tooltip only if clicked on the target (managed mode, e.g. for touch devices)
          activeateOnClick?: boolean;
        };
    hideOnClickIfUnhoverd?: boolean;
  }
> = (props) => {
  const {
    title = '',
    children,
    activateForDisabled: paramActiveForDisabled,
    placement,
    hideOnClickIfUnhoverd,
  } = props;

  const activateForDisabled = !!paramActiveForDisabled;
  const activeateOnClick =
    typeof paramActiveForDisabled === 'object' &&
    paramActiveForDisabled.activeateOnClick;

  const [showTooltip, setShowTooltip] = useState(false);

  /* why add a wrapper span? https://mui.com/material-ui/react-tooltip/#disabled-elements */
  const child = activateForDisabled ? (
    <span
      onClick={
        activeateOnClick ? () => setShowTooltip((active) => !active) : undefined
      }
    >
      {children}
    </span>
  ) : (
    children
  );

  const hideOnClickIfUnhoverdHandler = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setTimeout(() => {
      //this checks if the mouse still on the clicked element
      //if not we hide the tooltip e.g. a popover appers
      if (!(e.target as Element).matches(':hover')) {
        setShowTooltip(false);
      }
    }, 100);
  };

  if (activeateOnClick || hideOnClickIfUnhoverd) {
    const hideOnClickIfUnhoverdConfig = hideOnClickIfUnhoverd
      ? {
          onClick: hideOnClickIfUnhoverdHandler,
          onMouseEnter: () => setShowTooltip(true),
        }
      : undefined;

    //managed
    return (
      <TooltipBase
        key={'managed'}
        title={title}
        arrow
        open={showTooltip}
        onClose={() => setShowTooltip(false)}
        placement={placement}
        {...hideOnClickIfUnhoverdConfig}
      >
        {child}
      </TooltipBase>
    );
  }
  //auto
  return (
    <TooltipBase key={'auto'} title={title} placement={placement} arrow>
      {child}
    </TooltipBase>
  );
};
