import { useEffect } from 'react';
import { createContext, useContext, useState } from 'react';

export interface HeaderComponentProviderValue {
  component: JSX.Element;
  setComponent: (e: JSX.Element) => void;
}

const Context = createContext<HeaderComponentProviderValue>({
  component: null,
  setComponent: () => {
    console.warn('There is no context provider, so this hook has no action.');
  },
});

export const HeaderComponentProvider = (props: { children: JSX.Element }) => {
  const [component, setComponent] = useState<JSX.Element>(null);
  return (
    <Context.Provider value={{ component, setComponent }}>
      {props.children}
    </Context.Provider>
  );
};

export const useHeaderComponent = () => {
  const { component } = useContext(Context);
  return component;
};

export const useHeaderInject = (component: JSX.Element) => {
  const { setComponent } = useContext(Context);

  useEffect(() => {
    setComponent(component);
    return () => {
      setComponent(null);
    };
  }, [setComponent, component]);
};
