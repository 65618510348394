import { useMemo } from 'react';

import { DocumentClass } from '@work4all/models/lib/Classes/DocumentClass.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useDataProvider } from '../data-provider';

import {
  DocumentClassesContextProvider,
  DocumentClassesContextValue,
} from './document-classes-context';

const request: DataRequest = {
  operationName: 'GetDocumentClasses',
  entity: Entities.documentClass,
  completeDataResponse: true,
  data: {
    id: null,
    convertedItem: null,
    name: null,
    businessPartnerType: null,
  } as DocumentClass,
};

export function DocumentClassesProvider(props: { children?: React.ReactNode }) {
  const { children } = props;

  const response = useDataProvider<DocumentClass>(request);

  const { data: documentClasses } = response;

  const context = useMemo<DocumentClassesContextValue>(
    () => ({ documentClasses }),
    [documentClasses]
  );

  return (
    <DocumentClassesContextProvider value={context}>
      {children}
    </DocumentClassesContextProvider>
  );
}
