import styles from './NoPreview.module.scss';

import { Typography } from '@mui/material';

export type INoPreviewProps = {
  text: string;
  url?: string;
};

export function NoPreview({ text }: INoPreviewProps) {
  return (
    <div className={styles['no-preview']}>
      <Typography className="text" variant="body1">
        {text}
      </Typography>
    </div>
  );
}
