import { ColumnInstance } from '@work4all/components/lib/dataDisplay/basic-table/types';

export const createHandleColumnFilterChange = <T>(column: ColumnInstance) => {
  const handleColumnFilterChange = (groups: T[]) => {
    if (!column) {
      return;
    }

    if (groups.length === 0) {
      column.setFilter(null);
    } else {
      column.setFilter({
        value: groups,
        filterType: column.filterType,
      });
    }
  };
  return handleColumnFilterChange;
};
