import { Grid } from '@mui/material';
import React from 'react';
import { Control, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';

import { Calculation } from '@work4all/models/lib/Classes/Calculation.entity';
import { Contract } from '@work4all/models/lib/Classes/Contract.entity';
import { DeliveryNote } from '@work4all/models/lib/Classes/DeliveryNote.entity';
import { Demand } from '@work4all/models/lib/Classes/Demand.entity';
import { InboundDeliveryNote } from '@work4all/models/lib/Classes/InboundDeliveryNote.entity';
import { Invoice } from '@work4all/models/lib/Classes/Invoice.entity';
import { Offer } from '@work4all/models/lib/Classes/Offer.entity';
import { Order } from '@work4all/models/lib/Classes/Order.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PathsOf } from '@work4all/utils/lib/paths-of/paths-of';

import { ErpStatusPickerField } from '../../../../../../../../../../components/entity-picker/ErpStatusPickerField';
import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import {
  DateTimeInputPicker,
  LabeledInput,
} from '../../../../../../../../locked-inputs';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { ErpData } from '../../../../../ErpData';
import { FormCheck } from '../../../../FormCheck';

export const Description: React.VFC = () => {
  const { t } = useTranslation();

  const mask = useMaskContext();
  const { register, control } = useFormContextPlus<ErpData>();
  const dateProperty =
    Entities.contract === mask.entity ? 'contractDate' : 'date';
  const numberProperty =
    Entities.contract === mask.entity ? 'contractNumber' : 'number';

  return (
    <Collapse defaultOpen={true} title={t('MASK.DESCRIPTION')}>
      <Grid container spacing="1rem">
        <Grid item xs={6}>
          <LabeledInput
            label={t('COMMON.NOTE')}
            {...register('note')}
            required={true}
            multiline={true}
            style={{ minHeight: '7rem' }}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing="1rem">
            <Grid item xs={6}>
              <ControllerPlus
                control={control as Control<PathsOf<Contract>>}
                name={numberProperty}
                render={({ field }) => {
                  const { value, onChange } = field;

                  const handleChange = (
                    e: React.ChangeEvent<HTMLInputElement>
                  ) => {
                    const newValue = e.target.value;

                    const newValue2 = newValue.replace(/[^0-9]/g, '');

                    const valueAsNumber =
                      newValue2 === '' ? null : Number(newValue2);

                    onChange(valueAsNumber);
                  };
                  return (
                    <LabeledInput
                      label={t('INPUTS.NUMBER')}
                      value={String(value ?? '')}
                      onChange={handleChange}
                      type="text"
                      error={field.error}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ControllerPlus
                control={control}
                name="frozen"
                render={({ field: frozenField }) => {
                  if (mask.entity === Entities.invoice) {
                    return (
                      <FormCheck<ErpData, Invoice>
                        control={control}
                        entity={mask.entity}
                        allowedEntities={[Entities.invoice]}
                        render={({ control }) => (
                          <ControllerPlus
                            control={control}
                            name="ra"
                            render={({ field: raField }) => {
                              return (
                                <ControllerPlus
                                  control={control}
                                  name="paid"
                                  render={({ field: paidField }) => {
                                    const value = paidField.value
                                      ? t('COMMON.PAYED')
                                      : raField.value
                                      ? t('COMMON.IN_THE_INVOICE_LEDGER')
                                      : t('FILTERS.OPEN');
                                    return (
                                      <div>
                                        <LabeledInput
                                          label={t('COMMON.STATUS')}
                                          disabled
                                          value={value}
                                        />
                                      </div>
                                    );
                                  }}
                                />
                              );
                            }}
                          />
                        )}
                      />
                    );
                  }
                  return (
                    <FormCheck<ErpData, ErpData & { isClosed: boolean }>
                      control={control}
                      entity={mask.entity}
                      allowedEntities={[
                        Entities.offer,
                        Entities.deliveryNote,
                        Entities.contract,
                        Entities.order,
                        Entities.demand,
                        Entities.calculation,
                        Entities.inboundDeliveryNote,
                      ]}
                      render={({ control }) => (
                        <ControllerPlus
                          control={control}
                          name="isClosed"
                          render={({ field: closedField }) => {
                            return (
                              <ErpStatusPickerField
                                frozen={frozenField?.value}
                                isClosed={closedField?.value}
                                onChange={(frozen, isClosed) => {
                                  frozenField.onChange(frozen);
                                  closedField.onChange(isClosed);
                                }}
                              />
                            );
                          }}
                        />
                      )}
                    />
                  );
                }}
              />
            </Grid>
            <FormCheck<
              ErpData,
              Offer | DeliveryNote | Contract | Order | Demand | Calculation
            >
              register={register}
              entity={mask.entity}
              allowedEntities={[
                Entities.offer,
                Entities.deliveryNote,
                Entities.contract,
                Entities.order,
                Entities.demand,
                Entities.calculation,
              ]}
              render={({ register }) => (
                <React.Fragment>
                  <Grid item xs={4}>
                    <DateTimeInputPicker
                      required={false}
                      dateLabel={t('INPUTS.DATE')}
                      withTime={false}
                      {...(register as UseFormRegister<PathsOf<Contract>>)(
                        dateProperty
                      )}
                      clearable={false}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DateTimeInputPicker
                      required={false}
                      dateLabel={t('INPUTS.PLANNED_DATE_OF_DELIVERY')}
                      withTime={false}
                      {...register('contractStartDate')}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DateTimeInputPicker
                      required={false}
                      dateLabel={t('INPUTS.FACT_DATE_OF_DELIVERY')}
                      withTime={false}
                      {...register('outgoingDeliveryDate')}
                    />
                  </Grid>
                </React.Fragment>
              )}
            />
          </Grid>
          <FormCheck<ErpData, InboundDeliveryNote>
            register={register}
            entity={mask.entity}
            allowedEntities={[Entities.inboundDeliveryNote]}
            render={({ register }) => (
              <React.Fragment>
                <Grid item xs={6}>
                  <LabeledInput
                    label={t('INPUTS.DELIVERY_RECEIPT_NUMBER')}
                    {...register('deliveryNoteNumber')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DateTimeInputPicker
                    required={false}
                    dateLabel={t('INPUTS.DATE')}
                    withTime={false}
                    {...register('date')}
                    clearable={false}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DateTimeInputPicker
                    required={false}
                    dateLabel={t('INPUTS.DELIVERY_DATE')}
                    withTime={false}
                    {...register('deliveryDate')}
                  />
                </Grid>
              </React.Fragment>
            )}
          />
        </Grid>
      </Grid>
    </Collapse>
  );
};
