import { ModuleAccessRight } from '@work4all/models/lib/Classes/ModuleAccessRight.entity';
import { ModuleAccessRightType } from '@work4all/models/lib/Enums/ModuleAccessRightType.enum';

export function isTimeTrackingUser(rights: ModuleAccessRight[]): boolean {
  //users that are considered light users and have only access to one of the two timetracking light modules

  const isFullUser = !!rights.find(
    (r) =>
      r.type === ModuleAccessRightType.FEATURE_WORK_4_ALL_WEB_FULL &&
      r.hasAccess === true
  );
  const hasOneOfTimeRights = !!rights.find(
    (r) =>
      [
        ModuleAccessRightType.FEATURE_PROJECT_TIME_TRACKING_LIGHT,
        ModuleAccessRightType.FEATURE_WORK_TIME_TRACKING_LIGHT,
      ].includes(r.type) && r.hasAccess === true
  );

  const hasOtherLightRight = !!rights.find(
    (r) =>
      [
        ModuleAccessRightType.FEATURE_CRM_LIGHT,
        ModuleAccessRightType.FEATURE_ERP_LIGHT,
        ModuleAccessRightType.FEATURE_PROJECT_LIGHT,
        ModuleAccessRightType.FEATURE_TICKETS_LIGHT,
        ModuleAccessRightType.FEATURE_RECEIPTS_TRAVEL_LIGHT,
      ].includes(r.type) && r.hasAccess === true
  );

  return !isFullUser && hasOneOfTimeRights && !hasOtherLightRight;
}
