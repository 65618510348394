import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { DelimitationForm } from '../Enums/DelimitationForm.enum';
import { EMode } from '../Enums/EMode.enum';
import { GeneralLedgerAccountType } from '../Enums/GeneralLedgerAccountType.enum';

export class LedgerAccount<T extends EMode = EMode.entity> {
  /** Alias: abgrenzungsform */
  delimitationForm?: DelimitationForm;
  /** Alias: abgrenzungskonto */
  delimitationAccount?: number;
  /** Alias: anzahlungsKonto */
  depositAccount?: number;
  /** Alias: art */
  kind?: number;
  /** Alias: automatikKonto */
  isAutomationAccount?: boolean;
  /** Alias: bankkonto */
  bankAccount?: string;
  /** Alias: code */
  id?: number;
  /** Alias: deaktiviert */
  disabled?: boolean;
  /** Alias: ersatzKontoMwstUmstellung */
  substituteAccountVatConversion?: number;
  /** Alias: gemeinkosten */
  isOverheadCost?: boolean;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: kostengruppenCode */
  costGroupId?: number;
  /** Alias: kostenstelleCode */
  costCenterId?: number;
  /** Alias: kostenstelleNummer */
  costCenterNumber?: string;
  /** Alias: kuerzel */
  short?: string;
  /** Alias: name */
  name?: string;
  /** Alias: nichtUmsatzschmaelernd */
  notDeminishingTurnover?: boolean;
  /** Alias: nummer */
  number?: number;
  /** Alias: steuerschluessel */
  taxKey?: number;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: ledgerAccountType */
  ledgerAccountType?: GeneralLedgerAccountType;

  __typename?: string;
}

const fields: FieldDefinitions<LedgerAccount> = {
  delimitationForm: {
    alias: 'abgrenzungsform',
  },
  delimitationAccount: {
    alias: 'abgrenzungskonto',
  },
  depositAccount: {
    alias: 'anzahlungsKonto',
  },
  kind: {
    alias: 'art',
  },
  isAutomationAccount: {
    alias: 'automatikKonto',
  },
  bankAccount: {
    alias: 'bankkonto',
  },
  id: {
    alias: 'code',
  },
  disabled: {
    alias: 'deaktiviert',
  },
  substituteAccountVatConversion: {
    alias: 'ersatzKontoMwstUmstellung',
  },
  isOverheadCost: {
    alias: 'gemeinkosten',
  },
  insertTime: {
    alias: 'insertTime',
  },
  costGroupId: {
    alias: 'kostengruppenCode',
  },
  costCenterId: {
    alias: 'kostenstelleCode',
  },
  costCenterNumber: {
    alias: 'kostenstelleNummer',
  },
  short: {
    alias: 'kuerzel',
  },
  name: {
    alias: 'name',
  },
  notDeminishingTurnover: {
    alias: 'nichtUmsatzschmaelernd',
  },
  number: {
    alias: 'nummer',
  },
  taxKey: {
    alias: 'steuerschluessel',
  },
  updateTime: {
    alias: 'updateTime',
  },
  ledgerAccountType: {
    alias: 'ledgerAccountType',
  },
  __typename: {
    alias: '__typename',
  },
};

export const ledgerAccountEntityDefinition: EntitiyDefinition<LedgerAccount> = {
  local: {},
  remote: {
    queryName: 'getSachkonten',
    fragmentName: 'Sachkonto',
    withPaginationWrapper: true,
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'filter', type: 'String' },
      { name: 'code', type: 'Int' },
      { name: 'nummer', type: 'Int' },
      { name: 'name', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
