import { MouseEventHandler } from 'react';

import { Note } from '@work4all/models/lib/Classes/Note.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { LockProvider } from '../../../hooks';
import { IConvertPopoverProps } from '../../convert-popover/ConvertPopover';
import { useEntityPreviewData } from '../hooks/use-entity-preview-data';

import { NotePreview } from './NotePreview';

export type INotePreviewContainerProps = {
  noteIds: number[];
  onCloseClicked?: MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onShareClicked?: () => void;
  convertProps?: Pick<IConvertPopoverProps, 'exclude' | 'onClick'>;
};

export const notePreviewFields: Note<EMode.query> = {
  id: null,
  title: null,
  date: null,
  user: {
    id: null,
    displayName: null,
  },
  businessPartner: {
    id: null,
    businessPartnerType: null,
    data: {
      customer: {
        id: null,
        name: null,
        website: null,
      },
      supplier: {
        id: null,
        name: null,
        website: null,
      },
    },
  },
  contact: {
    id: null,
    displayName: null,
    businessPartnerId: null,
    businessPartnerType: null,
    eMail: null,
  },
  project: {
    id: null,
    name: null,
  },
  note: null,
};

export function NotePreviewContainer(props: INotePreviewContainerProps) {
  const {
    noteIds,
    onCloseClicked,
    onEditClicked,
    onShareClicked,
    convertProps,
  } = props;

  const notes = useEntityPreviewData<Note>(
    noteIds,
    Entities.note,
    notePreviewFields
  );

  if (!notes) {
    return null;
  }

  return (
    <LockProvider>
      <NotePreview
        isCallMemo={false}
        onCloseClicked={onCloseClicked}
        onEditClicked={onEditClicked}
        notes={notes}
        onShareClicked={onShareClicked}
        convertProps={convertProps}
      />
    </LockProvider>
  );
}
