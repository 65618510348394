import React from 'react';

import { ILockInfo } from './types';

export const LockContext = React.createContext<ILockInfo | null>({
  lock: () => null,
  unlock: () => null,
  loading: false,
  locked: false,
  user: null,
});
