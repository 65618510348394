import { Theme, useMediaQuery } from '@mui/material';

import { TableLayout } from '@work4all/components';

import { settings, useSetting } from '../../../settings';

import { TableLayoutContextValue } from './table-layout-context';

export function useTableLayoutState(): TableLayoutContextValue {
  const isViewportDownSm = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const isViewportDownMd = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );
  const isViewportDownLg = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('xl')
  );

  const breakpointName = isViewportDownSm
    ? 'sm'
    : isViewportDownMd
    ? 'md'
    : isViewportDownLg
    ? 'lg'
    : 'xl';

  const layoutState = useSetting(
    settings.tableLayout({
      defaultValue: {
        sm: 'cards',
        md: 'cards',
        lg: 'cards',
        xl: 'table',
      },
    })
  );

  const value = layoutState.value[breakpointName];
  const set = (value: TableLayout) => {
    layoutState.set({
      ...layoutState.value,
      [breakpointName]: value,
    });
  };

  return [value, set];
}
