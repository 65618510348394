import { useDataMutation } from '@work4all/data';

import { InputProjectAccessGroup } from '@work4all/models/lib/Classes/InputProjectAccessGroup.entity';
import { ProjectAccessGroup } from '@work4all/models/lib/Classes/ProjectAccessGroup.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export const useProjectAccessGroupMutate = (
  onCompleted?: (data: ProjectAccessGroup<EMode.entity>) => void,
  onError?: () => void
) => {
  const [mutate] = useDataMutation<
    ProjectAccessGroup,
    EMode.upsert,
    InputProjectAccessGroup
  >({
    entity: Entities.projectAccessGroup,
    mutationType: EMode.upsert,
    responseData: {
      id: null,
      name: null,
      users: [
        {
          id: null,
          displayName: null,
        },
      ],
    },
    onCompleted,
    onError,
  });
  return mutate;
};
