import useSWR from 'swr';

import { useTenant } from './routing/TenantProvider';
import { useUser } from './useUser';

export function useSecureBlob(url: string | null) {
  const user = useUser();
  const { activeTenant } = useTenant();

  const { data: blob } = useSWR(url, async (url) => {
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${user.token}`,
        'x-work4all-mandant': `${activeTenant}`,
        'x-work4all-apiurl': user.baseUrl,
      },
    });

    if (!response.ok) {
      throw new Error('Response not ok');
    }

    const blob = await response.blob();

    return blob;
  });

  return blob;
}
