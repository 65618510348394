import styles from './styles.module.scss';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SaveIcon from '@mui/icons-material/Save';
import { Divider, IconButton, Tooltip } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';

import { UsersList } from '../UsersList';
import { ViewsList } from '../ViewsList';

import { CalendarUsersDialogContext } from './calendar-users-dialog-context';
import { View2 } from './View2';

export function View1() {
  const { t } = useTranslation();

  const { go, close } = useHistoryStack();

  const {
    users,
    onUsersChange,
    onUsersClear,
    focusedUserIds,
    onFocusedUserIdsChange,
    views,
    activeView,
    onSelectView,
    onCreateView,
    onDeleteView,
    calendarCardColorBy,
  } = useContext(CalendarUsersDialogContext);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <IconButton size="large" onClick={() => close()}>
          <ArrowBackIcon />
        </IconButton>

        <div className={styles.buttons}>
          <Tooltip title={t('USER_PICKER.ADD_USER')}>
            <IconButton
              color="primary"
              size="large"
              onClick={() => go({ view: <View2 /> })}
            >
              <PersonAddIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title={t('USER_PICKER.CLEAR_SELECTION')}>
            <IconButton color="primary" size="large" onClick={onUsersClear}>
              <CloseIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title={t('USER_PICKER.SAVE_COLLECTION')}>
            <IconButton color="primary" size="large" onClick={onCreateView}>
              <SaveIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <Divider />

      <div className={styles.content}>
        <UsersList
          users={users}
          onUsersChange={onUsersChange}
          focusedUserIds={focusedUserIds}
          onFocusedUserIdsChange={onFocusedUserIdsChange}
          calendarCardColorBy={calendarCardColorBy}
        />

        <ViewsList
          views={views}
          activeView={activeView}
          onSelectView={onSelectView}
          onDeleteView={onDeleteView}
        />
      </div>
    </div>
  );
}
