import { omit } from 'lodash';

import { ParsedCustomFieldConfig } from '@work4all/data/lib/custom-fields';

import { CustomField } from '@work4all/models/lib/Classes/CustomField.entity';
import { IndividualFieldControlType } from '@work4all/models/lib/Enums/IndividualFieldControlType.enum';

import { encodeCustomFieldName } from './custom-field-name';
import { EncodedCustomFieldName } from './types';
import { WithCustomFields } from './with-custom-fields';

type NormalizedData<T> = WithCustomFields<Omit<T, 'customFieldList'>>;

/**
 * Remove `customFieldList` property from the data and add a new property
 * `customFieldList` with keys and values mapped as an object instead of an
 * array for easier use with forms.
 */
export function normalizeCustomFields<
  T extends { customFieldList?: CustomField[] }
>(data: T, customFields: ParsedCustomFieldConfig[]): NormalizedData<T> {
  const clone: NormalizedData<T> = {
    ...omit(data, 'customFieldList'),
    _customFields: {},
  };

  const original = data.customFieldList;

  if (customFields.length === 0) {
    return clone;
  }

  const fieldValueById = new Map(
    original?.map((field) => [field.key, JSON.parse(field.value)]) ?? []
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fields = customFields.reduce<Record<EncodedCustomFieldName, any>>(
    (acc, field) => {
      // Ignore Caption and NewLine fields. We don't need to add them to the
      // form. They are just used to create groups and subgroups in the UI.
      if (
        field.fieldType === IndividualFieldControlType.CAPTION ||
        field.fieldType === IndividualFieldControlType.ZEILENUMBRUCH
      ) {
        return acc;
      }

      const id = field.id;
      const value = fieldValueById.has(id)
        ? fieldValueById.get(id)
        : getDefaultValueForType(field.fieldType);

      // Add a prefix to the field key to avoid it being treated as an array.
      const name = encodeCustomFieldName(id);
      acc[name] = value;

      return acc;
    },
    {}
  );

  clone._customFields = fields;

  return clone;
}

function getDefaultValueForType(fieldType: IndividualFieldControlType) {
  switch (fieldType) {
    case IndividualFieldControlType.TEXT:
      return '';
    case IndividualFieldControlType.NUMERIC:
      return 0;
    case IndividualFieldControlType.DATE_TYPE:
      return null;
    case IndividualFieldControlType.CHECK:
      return false;
    case IndividualFieldControlType.RADIO:
      return null;
    case IndividualFieldControlType.COMBO:
      return '';
    case IndividualFieldControlType.CAPTION:
    case IndividualFieldControlType.LIST:
    case IndividualFieldControlType.LEERSPALTE:
    case IndividualFieldControlType.ZEILENUMBRUCH:
    case IndividualFieldControlType.COUNTER:
    case IndividualFieldControlType.USER_EXIT_BUTTON:
      throw new Error(`Unsupported custom field type "${fieldType}"`);
  }
}
