import { Theme, useMediaQuery } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';
import { TaskPreviewContainer } from '@work4all/components/lib/components/entity-preview/task-preview';

import { Task } from '@work4all/models/lib/Classes/Task.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Table } from '../../components/data-tables/table/Table';
import { OnOpenMask } from '../../containers/mask-overlays/mask-overlay/types';
import { PersonalAndGeneralView } from '../filters/PersonalAndGeneralView';

import { PreviewMobileWrapper } from './PreviewMobileWrapper';
import schema from './schemata/task-table-schema.json';
import { TableLayoutContext, useTableLayoutState } from './table-layout';
import { TableNoRowsPlaceholder } from './TableNoRowsPlaceholder';
import { TaskPriorityCell } from './TaskPriorityCell';
import { TaskStatusCell } from './TaskStatusCell';
import { useDataTable } from './use-data-table';
import { useDeleteEntitiesToolbarConfig } from './use-delete-entities-toolbar-config';
import { useTableMaskHandlers } from './use-table-mask-handlers';
import { useUserColumnConfigs } from './use-user-column-configs';

interface Props {
  prefilter?: unknown[];
  onOpenMask: OnOpenMask;
}

export type ITaskPriorityCellConfig = {
  type: 'TaskPriority';
};

export type ITaskStatusCellConfig = {
  type: 'TaskStatus';
  params: {
    ownerIdField: string;
  };
};

type ITaskTableCustomColumns = {
  TaskStatus?: ITaskStatusCellConfig;
  TaskPriority?: ITaskPriorityCellConfig;
};

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];
const manualGroupBy = true;

export const TasksTable = React.forwardRef<TableInstance, Props>(
  function TasksTable(props, ref) {
    const { prefilter, onOpenMask } = props;
    const { entity: entityType } = schema as never;

    const tableStateBag = useTableStateBag();

    const layoutState = useTableLayoutState();
    const [layout] = layoutState;

    const {
      columnConfigs,
      cardConfig,
      prepareRowDisplayModifiers,
      data,
      fetchMore,
      total,
      selectedEntities,
      initialSortBy,
      pending,
    } = useDataTable<Task, ITaskTableCustomColumns>({
      layout,
      schema: schema as never,
      cells: {
        TaskStatus: TaskStatusCell,
        TaskPriority: TaskPriorityCell,
      },
      cellProcessors: {
        TaskStatus(config) {
          return [config.accessor, 'user.id'];
        },
      },
      tableStateBag,
      prefilter,
      defaultSort,
    });

    const maskHandlers = useTableMaskHandlers(entityType, onOpenMask);

    const deleteConfig = useDeleteEntitiesToolbarConfig({
      entityType,
    });

    const [userConfig, userConfigMethods] = useUserColumnConfigs({
      layout,
      entityType,
      columnConfigs,
    });

    const isDesktop = useMediaQuery<Theme>((theme) =>
      theme.breakpoints.up('sm')
    );

    const { t } = useTranslation();

    if (!userConfig) return null;

    return (
      <TableLayoutContext value={layoutState}>
        <Table
          pending={pending}
          noRowsRenderer={() => <TableNoRowsPlaceholder />}
          ref={ref}
          layout={layout}
          areas={{
            left: {
              content: <PersonalAndGeneralView entity={Entities.task} />,
              resizable: true,
              collapseConfig: {
                title: t('COMMON.FILTER'),
              },
            },
            right: selectedEntities &&
              selectedEntities.length > 0 && {
                content: (
                  <PreviewMobileWrapper
                    active={!isDesktop}
                    actions={{
                      edit: maskHandlers.edit,
                      convert: {
                        exclude: entityType,
                        handler: maskHandlers.convert,
                      },
                      remove: deleteConfig,
                    }}
                  >
                    <TaskPreviewContainer
                      onCloseClicked={() =>
                        tableStateBag.tableInstance.toggleAllRowsSelected(false)
                      }
                      onEditClicked={maskHandlers.edit.handler}
                      taskIds={selectedEntities.map((e) => e.id)}
                      onShareClicked={maskHandlers.share}
                      convertProps={{
                        exclude: entityType,
                        onClick: maskHandlers.convert,
                      }}
                    />
                  </PreviewMobileWrapper>
                ),
              },
          }}
          actions={{
            add: maskHandlers.create,
            edit: maskHandlers.edit,
            convert: {
              exclude: entityType,
              handler: maskHandlers.convert,
            },
            remove: deleteConfig,
            resetColumns: userConfigMethods.remove,
            createWidget: true,
            clone: maskHandlers.clone,
          }}
          columnConfigs={userConfig}
          cardConfig={cardConfig}
          manualGroupBy={manualGroupBy}
          initialSortBy={initialSortBy}
          loadMoreItems={fetchMore}
          prepareRowDisplayModifiers={prepareRowDisplayModifiers}
          data={data}
          total={total}
        />
      </TableLayoutContext>
    );
  }
);
