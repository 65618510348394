import { UseFormReturn } from 'react-hook-form';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PathsOf } from '@work4all/utils/lib/paths-of/paths-of';

export type Control<T> = UseFormReturn<T, object>['control'];
export type Register<T> = UseFormReturn<T, object>['register'];

export interface FormCheckProps<T, TAllowed extends object> {
  render: (params: {
    control: Control<PathsOf<TAllowed, 2>>;
    register: Register<PathsOf<TAllowed, 2>>;
  }) => JSX.Element;
  control?: Control<T>;
  register?: Register<T>;
  entity: Entities;
  allowedEntities: Entities[];
}

export function FormCheck<T, TAllowed extends object>({
  render,
  control,
  register,
  entity,
  allowedEntities,
}: FormCheckProps<T, TAllowed>) {
  if (allowedEntities.includes(entity))
    return render({
      control: control as unknown as Control<PathsOf<TAllowed, 2>>,
      register: register as unknown as Register<PathsOf<TAllowed, 2>>,
    });
  return null;
}
