import styles from './TaskStatusPickerField.module.scss';

import { CheckCircleRounded, CircleTwoTone } from '@mui/icons-material';
import clsx from 'clsx';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  ITaskStatusPickerProps,
  TaskStatusPicker,
} from '@work4all/components/lib/components/entity-picker/task-status-picker/TaskStatusPicker';

import { TaskStatus } from '@work4all/models/lib/Enums/TaskStatus.enum';

import { reactRefSetter } from '@work4all/utils';

import { LabeledInput } from '../../containers/mask-overlays/locked-inputs';

interface Props extends Omit<ITaskStatusPickerProps<false>, 'onChange'> {
  error?: string;
  label?: string;
  onChange: (value: string) => void;
}

export const TaskStatusPickerField = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { error, label, ...pickerProps } = props;
    const { value, onChange } = pickerProps;

    const { t } = useTranslation();

    const wrapperRef = useRef();

    const valueIsDone = TaskStatus.ERLEDIGT === value?.id;

    const Icon = valueIsDone ? CheckCircleRounded : CircleTwoTone;

    return (
      <div ref={reactRefSetter(wrapperRef, ref)}>
        <EntityPickerPopover
          anchorEl={wrapperRef}
          picker={
            <TaskStatusPicker
              {...pickerProps}
              onChange={(value) => onChange(value.id)}
            />
          }
        >
          <LabeledInput
            startAdornment={
              value && (
                <Icon
                  className={clsx(styles['check-circle'], {
                    [styles['check-circle-success']]:
                      value.id === TaskStatus.ERLEDIGT,
                    [styles['check-circle-edit']]:
                      value.id === TaskStatus.IN_BEARBEITUNG ||
                      value.id === TaskStatus.WARTET ||
                      value.id === TaskStatus.ZURUECK_GESTELLT,
                  })}
                />
              )
            }
            label={label || t('INPUTS.STATUS')}
            error={error}
            value={value ? t<string>(`TASK_STATUS.${value?.id || value}`) : ''}
          />
        </EntityPickerPopover>
      </div>
    );
  }
);
