import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class CompanyKpis<T extends EMode = EMode.entity> {
  /** Alias: salesAmountCurrentYear */
  salesAmountCurrentYear?: number;
  /** Alias: salesAmountLastYear */
  salesAmountLastYear?: number;
  /** Alias: salesAmountVariationYearPercent */
  salesAmountVariationYearPercent?: number;
  /** Alias: salesAmountCurrentMonth */
  salesAmountCurrentMonth?: number;
  /** Alias: salesAmountCurrentMonthLastYear */
  salesAmountCurrentMonthLastYear?: number;
  /** Alias: salesAmountVariationMonthPercent */
  salesAmountVariationMonthPercent?: number;
  /** Alias: incommingOrdersAmountCurrentYear */
  incommingOrdersAmountCurrentYear?: number;
  /** Alias: incommingOrdersAmountLastYear */
  incommingOrdersAmountLastYear?: number;
  /** Alias: incommingOrdersAmountVariationYearPercent */
  incommingOrdersAmountVariationYearPercent?: number;
  /** Alias: incommingOrdersAmountCurrentMonth */
  incommingOrdersAmountCurrentMonth?: number;
  /** Alias: incommingOrdersAmountCurrentMonthLastYear */
  incommingOrdersAmountCurrentMonthLastYear?: number;
  /** Alias: incommingOrdersAmountVariationMonthPercent */
  incommingOrdersAmountVariationMonthPercent?: number;
  /** Alias: sickDaysCurrentYear */
  sickDaysCurrentYear?: number;
  /** Alias: sickDaysLastYear */
  sickDaysLastYear?: number;
  /** Alias: sickDaysLastYearUntilDate */
  sickDaysLastYearUntilDate?: number;
  /** Alias: holidaysCurrentYear */
  holidaysCurrentYear?: number;
  /** Alias: holidaysLastYear */
  holidaysLastYear?: number;
  /** Alias: holidaysLastYearUntilDate */
  holidaysLastYearUntilDate?: number;
  /** Alias: timeTrackingProjectHoursCurrentYear */
  timeTrackingProjectHoursCurrentYear?: number;
  /** Alias: timeTrackingProjectLastYearUntilDate */
  timeTrackingProjectLastYearUntilDate?: number;
  /** Alias: countProspectiveCustomerCurrentYear */
  countProspectiveCustomerCurrentYear?: number;
  /** Alias: countProspectiveCustomerLastYear */
  countProspectiveCustomerLastYear?: number;
  /** Alias: countOpenOffers */
  countOpenOffers?: number;
  /** Alias: countOpenOpportunities */
  countOpenOpportunities?: number;
  /** Alias: amountOpenOpportunities */
  amountOpenOpportunities?: number;
  /** Alias: amountOpenOpportunitiesRated */
  amountOpenOpportunitiesRated?: number;
  /** Alias: amountVacantPositionsCustomer */
  amountVacantPositionsCustomer?: number;
  /** Alias: amountVacantPositionsCustomerDue */
  amountVacantPositionsCustomerDue?: number;
  /** Alias: amountVacantPositionsSupplier */
  amountVacantPositionsSupplier?: number;
  /** Alias: amountVacantPositionsSupplierDue */
  amountVacantPositionsSupplierDue?: number;
  /** Alias: salesAmountNewCustomerCurrentYear */
  salesAmountNewCustomerCurrentYear?: number;
  /** Alias: countNewCustomerUpTo1kCurrentYear */
  countNewCustomerUpTo1kCurrentYear?: number;
  /** Alias: countNewCustomerUpTo10kCurrentYear */
  countNewCustomerUpTo10kCurrentYear?: number;
  /** Alias: countNewCustomerUpTo30kCurrentYear */
  countNewCustomerUpTo30kCurrentYear?: number;
  /** Alias: countNewCustomerFrom30kCurrentYear */
  countNewCustomerFrom30kCurrentYear?: number;
  /** Alias: salesAmountNewCustomerLastYear */
  salesAmountNewCustomerLastYear?: number;
  /** Alias: countNewCustomerFrom30kLastYear */
  countNewCustomerFrom30kLastYear?: number;
  /** Alias: countNewCustomerUpTo1kLastYear */
  countNewCustomerUpTo1kLastYear?: number;
  /** Alias: countNewCustomerUpTo10kLastYear */
  countNewCustomerUpTo10kLastYear?: number;
  /** Alias: countNewCustomerUpTo30kLastYear */
  countNewCustomerUpTo30kLastYear?: number;

  __typename?: string;
}

const fields: FieldDefinitions<CompanyKpis> = {
  salesAmountCurrentYear: {
    alias: 'salesAmountCurrentYear',
  },
  salesAmountLastYear: {
    alias: 'salesAmountLastYear',
  },
  salesAmountVariationYearPercent: {
    alias: 'salesAmountVariationYearPercent',
  },
  salesAmountCurrentMonth: {
    alias: 'salesAmountCurrentMonth',
  },
  salesAmountCurrentMonthLastYear: {
    alias: 'salesAmountCurrentMonthLastYear',
  },
  salesAmountVariationMonthPercent: {
    alias: 'salesAmountVariationMonthPercent',
  },
  incommingOrdersAmountCurrentYear: {
    alias: 'incommingOrdersAmountCurrentYear',
  },
  incommingOrdersAmountLastYear: {
    alias: 'incommingOrdersAmountLastYear',
  },
  incommingOrdersAmountVariationYearPercent: {
    alias: 'incommingOrdersAmountVariationYearPercent',
  },
  incommingOrdersAmountCurrentMonth: {
    alias: 'incommingOrdersAmountCurrentMonth',
  },
  incommingOrdersAmountCurrentMonthLastYear: {
    alias: 'incommingOrdersAmountCurrentMonthLastYear',
  },
  incommingOrdersAmountVariationMonthPercent: {
    alias: 'incommingOrdersAmountVariationMonthPercent',
  },
  sickDaysCurrentYear: {
    alias: 'sickDaysCurrentYear',
  },
  sickDaysLastYear: {
    alias: 'sickDaysLastYear',
  },
  sickDaysLastYearUntilDate: {
    alias: 'sickDaysLastYearUntilDate',
  },
  holidaysCurrentYear: {
    alias: 'holidaysCurrentYear',
  },
  holidaysLastYear: {
    alias: 'holidaysLastYear',
  },
  holidaysLastYearUntilDate: {
    alias: 'holidaysLastYearUntilDate',
  },
  timeTrackingProjectHoursCurrentYear: {
    alias: 'timeTrackingProjectHoursCurrentYear',
  },
  timeTrackingProjectLastYearUntilDate: {
    alias: 'timeTrackingProjectLastYearUntilDate',
  },
  countProspectiveCustomerCurrentYear: {
    alias: 'countProspectiveCustomerCurrentYear',
  },
  countProspectiveCustomerLastYear: {
    alias: 'countProspectiveCustomerLastYear',
  },
  countOpenOffers: {
    alias: 'countOpenOffers',
  },
  countOpenOpportunities: {
    alias: 'countOpenOpportunities',
  },
  amountOpenOpportunities: {
    alias: 'amountOpenOpportunities',
  },
  amountOpenOpportunitiesRated: {
    alias: 'amountOpenOpportunitiesRated',
  },
  amountVacantPositionsCustomer: {
    alias: 'amountVacantPositionsCustomer',
  },
  amountVacantPositionsCustomerDue: {
    alias: 'amountVacantPositionsCustomerDue',
  },
  amountVacantPositionsSupplier: {
    alias: 'amountVacantPositionsSupplier',
  },
  amountVacantPositionsSupplierDue: {
    alias: 'amountVacantPositionsSupplierDue',
  },
  salesAmountNewCustomerCurrentYear: {
    alias: 'salesAmountNewCustomerCurrentYear',
  },
  countNewCustomerUpTo1kCurrentYear: {
    alias: 'countNewCustomerUpTo1kCurrentYear',
  },
  countNewCustomerUpTo10kCurrentYear: {
    alias: 'countNewCustomerUpTo10kCurrentYear',
  },
  countNewCustomerUpTo30kCurrentYear: {
    alias: 'countNewCustomerUpTo30kCurrentYear',
  },
  countNewCustomerFrom30kCurrentYear: {
    alias: 'countNewCustomerFrom30kCurrentYear',
  },
  salesAmountNewCustomerLastYear: {
    alias: 'salesAmountNewCustomerLastYear',
  },
  countNewCustomerFrom30kLastYear: {
    alias: 'countNewCustomerFrom30kLastYear',
  },
  countNewCustomerUpTo1kLastYear: {
    alias: 'countNewCustomerUpTo1kLastYear',
  },
  countNewCustomerUpTo10kLastYear: {
    alias: 'countNewCustomerUpTo10kLastYear',
  },
  countNewCustomerUpTo30kLastYear: {
    alias: 'countNewCustomerUpTo30kLastYear',
  },
  __typename: {
    alias: '__typename',
  },
};

export const companyKpisEntityDefinition: EntitiyDefinition<CompanyKpis> = {
  local: {},
  remote: {
    queryName: 'getCompanyKpis',
    fragmentName: 'CompanyKpis',
    withPaginationWrapper: false,
    params: [
      { name: 'date', type: 'DateTimeEx' },
      { name: 'calcEoMSalesAmountYear', type: 'Boolean' },
      { name: 'calcEoMSalesAmountMonth', type: 'Boolean' },
      { name: 'calcEoMOrderAmountYear', type: 'Boolean' },
      { name: 'calcEoMOrderAmountMonth', type: 'Boolean' },
      { name: 'onlyRa', type: 'Boolean' },
    ],
  },
  fieldDefinitions: fields,
};
