import { Notification } from '@work4all/models/lib/Classes/Notification.entity';
import { NotificationType } from '@work4all/models/lib/Enums/NotificationType.enum';

import { assertNever } from '@work4all/utils';

import { NotificationListItemEmail } from './NotificationListItemEmail';
import { NotificationListItemMention } from './NotificationListItemMention';

export interface NotificationListItemProps {
  className?: string;
  style?: React.CSSProperties;
  notification: Notification;
  count?: number;
  onClick: () => void;
}

export function NotificationListItem(props: NotificationListItemProps) {
  const { className, style, notification, onClick, count = 1 } = props;

  switch (notification.notificationType) {
    case NotificationType.MENTION:
      return (
        <NotificationListItemMention
          notification={notification}
          onClick={onClick}
          className={className}
          style={style}
          count={count}
        />
      );

    case NotificationType.FAILED_SAVE_SEND_MAIL_JOB:
      return (
        <NotificationListItemEmail
          notification={notification}
          onClick={onClick}
          className={className}
          style={style}
        />
      );

    default:
      assertNever(
        notification.notificationType,
        `Unknown notification type "${notification.notificationType}"`
      );
  }
}
