import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { BaseDataTax } from '../Enums/BaseDataTax.enum';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { InvoiceForm } from '../Enums/InvoiceForm.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { BankDetails } from './BankDetails.entity';
import { BaseDataAttachment } from './BaseDataAttachment.entity';
import { BaseDataLanguage } from './BaseDataLanguage.entity';
import { BusinessPartnerContactCombined } from './BusinessPartnerContactCombined.entity';
import { CategoryAssignment } from './CategoryAssignment.entity';
import { Contact } from './Contact.entity';
import { Currency } from './Currency.entity';
import { CustomField } from './CustomField.entity';
import { DefaultErpText } from './DefaultErpText.entity';
import { Department } from './Department.entity';
import { KpisSupplier } from './KpisSupplier.entity';
import { LedgerAccount } from './LedgerAccount.entity';
import { PaginationListAddressConnection } from './PaginationListAddressConnection.entity';
import { PaginationListAppointment } from './PaginationListAppointment.entity';
import { PaginationListCallMemo } from './PaginationListCallMemo.entity';
import { PaginationListDemand } from './PaginationListDemand.entity';
import { PaginationListDocument } from './PaginationListDocument.entity';
import { PaginationListEMail } from './PaginationListEMail.entity';
import { PaginationListInboundDeliveryNote } from './PaginationListInboundDeliveryNote.entity';
import { PaginationListLetter } from './PaginationListLetter.entity';
import { PaginationListNote } from './PaginationListNote.entity';
import { PaginationListOrder } from './PaginationListOrder.entity';
import { PaginationListProject } from './PaginationListProject.entity';
import { PaginationListTask } from './PaginationListTask.entity';
import { PaginationListTravelExpenses } from './PaginationListTravelExpenses.entity';
import { PaginationListVisitationReport } from './PaginationListVisitationReport.entity';
import { PaymentKind } from './PaymentKind.entity';
import { PostIt } from './PostIt.entity';
import { PriceGroup } from './PriceGroup.entity';
import { PurchasePrice } from './PurchasePrice.entity';
import { Salutation } from './Salutation.entity';
import { SupplierGroup } from './SupplierGroup.entity';

export class Supplier<T extends EMode = EMode.entity> {
  /** Alias: abteilungen */
  departmentList?: Department<T>[];
  /** Alias: adresseWirdGepflegtBeiKundeCode */
  addressMaintainedAtCustomerId?: number;
  /** Alias: adresserweiterung */
  addressExtension?: string;
  /** Alias: anrede */
  salutation?: Salutation<T>;
  /** Alias: anredeCode */
  salutationId?: number;
  /** Alias: ansprechpartner */
  contactList?: Contact<T>[];
  /** Alias: PaginationListaufgaben */
  taskList?: PaginationListTask<T>;
  /** Alias: bIC */
  bic?: string;
  /** Alias: bankleitzahl */
  bankCode?: string;
  /** Alias: bankverbindung */
  bankDetails?: string;
  /** Alias: PaginationListbedarfsanforderungen */
  demandList?: PaginationListDemand<T>;
  /** Alias: PaginationListbestellungen */
  orderList?: PaginationListOrder<T>;
  /** Alias: PaginationListbesuchsberichte */
  visitationReportList?: PaginationListVisitationReport<T>;
  /** Alias: briefAnrede */
  letterSalutation?: string;
  /** Alias: PaginationListbriefe */
  letterList?: PaginationListLetter<T>;
  /** Alias: bruttorechnung */
  isGrossInvoice?: boolean;
  /** Alias: code */
  id?: number;
  /** Alias: customFields */
  customFieldList?: CustomField<T>[];
  /** Alias: dB */
  dB?: number;
  /** Alias: dTANichtZusammenfassen */
  dontSumUpDTAN?: boolean;
  /** Alias: PaginationListdokumente */
  documentList?: PaginationListDocument<T>;
  /** Alias: doublettenCheck_NichtMehrAnzeigen */
  dontShowDoubletteCheck?: number;
  /** Alias: eMail */
  eMail?: string;
  /** Alias: eMail2 */
  eMail2?: string;
  /** Alias: PaginationListeMails */
  eMailList?: PaginationListEMail<T>;
  /** Alias: eRPFreigabepflichtDeaktivieren */
  deactivateERPReleaseRequirement?: boolean;
  /** Alias: PaginationListeingangslieferscheine */
  inboundDeliveryNoteList?: PaginationListInboundDeliveryNote<T>;
  /** Alias: entfernung */
  distance?: number;
  /** Alias: erloschen */
  extinct?: number;
  /** Alias: erstkontakt */
  firstContactDate?: string;
  /** Alias: firma1 */
  firm1?: string;
  /** Alias: firma2 */
  firm2?: string;
  /** Alias: firma3 */
  firm3?: string;
  /** Alias: funktion */
  role?: string;
  /** Alias: geburtsdatum */
  birthdayDate?: string;
  /** Alias: geburtstagJahr */
  birthdayYear?: number;
  /** Alias: geburtstagMonat */
  birthdayMonth?: number;
  /** Alias: geburtstagTag */
  birthdayDay?: number;
  /** Alias: gesperrt */
  locked?: number;
  /** Alias: grCode */
  groupId?: number;
  /** Alias: gruppe */
  group?: SupplierGroup<T>;
  /** Alias: hasObjektschutz */
  hasObjectProtection?: boolean;
  /** Alias: hauptansprechpartner */
  mainContact?: Contact<T>;
  /** Alias: hauptansprechpartnerCode */
  mainContactId?: number;
  /** Alias: hausbankCode */
  houseBankId?: number;
  /** Alias: iBAN */
  iban?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: interNet */
  website?: string;
  /** Alias: intrastatNichtberuecksichtigen */
  dontConsiderIntrastat?: number;
  /** Alias: kKontaktCode */
  kContactId?: number;
  /** Alias: kategorieZuordnungen */
  categoryAssignmentList?: CategoryAssignment<T>[];
  /** Alias: keineStaffelrabatte */
  noScaleDiscounts?: boolean;
  /** Alias: kontoinhaber */
  accountOwner?: string;
  /** Alias: kontonummer */
  accountNumber?: string;
  /** Alias: konzernkennzeichen */
  firmSign?: string;
  /** Alias: kreditlimit */
  creditLimit?: number;
  /** Alias: kuerzel */
  short?: string;
  /** Alias: kundennr */
  externalNumber?: string;
  /** Alias: lastModificationDate */
  lastModificationDate?: string;
  /** Alias: letzteAenderung */
  lastEdit?: string;
  /** Alias: lieferungsArtCode */
  deliveryKindId?: number;
  /** Alias: mahntoleranz */
  dunningTolerance?: number;
  /** Alias: mailSperre */
  eMailLock?: number;
  /** Alias: mark */
  mark?: string;
  /** Alias: mobilfunk */
  mobileNumber?: string;
  /** Alias: name */
  name?: string;
  /** Alias: namenserweiterung */
  nameExtension?: string;
  /** Alias: nebenAdrCode1 */
  additionalAddress1Id?: number;
  /** Alias: nebenAdrCode2 */
  additionalAddress2Id?: number;
  /** Alias: nebenAdrCode3 */
  additionalAddress3Id?: number;
  /** Alias: nebenAdrType1 */
  additionalAddress1Type?: SdObjType;
  /** Alias: nebenAdrType2 */
  additionalAddress2Type?: SdObjType;
  /** Alias: nebenAdrType3 */
  additionalAddress3Type?: SdObjType;
  /** Alias: nebenadrAPCode1 */
  additionalAddressContact1Id?: number;
  /** Alias: nebenadrAPCode2 */
  additionalAddressContact2Id?: number;
  /** Alias: nebenadrAPCode3 */
  additionalAddressContact3Id?: number;
  /** Alias: notiz */
  note?: string;
  /** Alias: notizRTF */
  noteRTF?: string;
  /** Alias: PaginationListnotizen */
  noteList?: PaginationListNote<T>;
  /** Alias: nummer */
  number?: number;
  /** Alias: ort */
  city?: string;
  /** Alias: ortPostfach */
  postOfficeBoxLocation?: string;
  /** Alias: pLZPostfach */
  postOfficeBoxPostalCode?: string;
  /** Alias: plz */
  postalCode?: string;
  /** Alias: postfach */
  postOfficeBox?: string;
  /** Alias: preisgruppe */
  priceGroupId?: number;
  /** Alias: preisgruppeItem */
  priceGroup?: PriceGroup<T>;
  /** Alias: privatkunde */
  isPrivateCustomer?: boolean;
  /** Alias: PaginationListprojekte */
  projectList?: PaginationListProject<T>;
  /** Alias: provision */
  commission?: number;
  /** Alias: rabattvorschlag */
  discountProposal?: number;
  /** Alias: rechnungsadresseEMail */
  invoiceEMailAddress?: string;
  /** Alias: rechnungsform */
  invoiceForm?: InvoiceForm;
  /** Alias: PaginationListreisekostenabrechnungen */
  travelExpensesList?: PaginationListTravelExpenses<T>;
  /** Alias: senderName */
  senderName?: string;
  /** Alias: serienbriefSperre */
  serialLetterLock?: number;
  /** Alias: skonto */
  skonto?: number;
  /** Alias: skonto2 */
  skonto2?: number;
  /** Alias: skonto2Frist */
  skonto2DurationDays?: number;
  /** Alias: skontofrist */
  skontoDurationDays?: number;
  /** Alias: sprachCode */
  languageId?: number;
  /** Alias: staat */
  country?: string;
  /** Alias: standardkonto */
  standardAccount?: number;
  /** Alias: steuer */
  tax?: BaseDataTax;
  /** Alias: steuerschluesselCode */
  taxKeyId?: number;
  /** Alias: strasse */
  street?: string;
  /** Alias: telefax */
  faxNumber?: string;
  /** Alias: telefon */
  phoneNumber?: string;
  /** Alias: telefon2 */
  phoneNumber2?: string;
  /** Alias: PaginationListtelefonate */
  phoneCallList?: PaginationListCallMemo<T>;
  /** Alias: PaginationListtermine */
  appointmentList?: PaginationListAppointment<T>;
  /** Alias: titelerweiterung */
  titleExtension?: string;
  /** Alias: uSTIDNR */
  vatId?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: PaginationListverknuepfungen */
  addressConnectionList?: PaginationListAddressConnection<T>;
  /** Alias: vertreter2Code */
  representative2Id?: number;
  /** Alias: vertreterCode */
  representativeId?: number;
  /** Alias: vertreterSDObjMemberCode */
  representativeSdObjMemberId?: number;
  /** Alias: vertreterSDObjType */
  representativeSdObjType?: number;
  /** Alias: vorwahl */
  phoneNumberPrefix?: string;
  /** Alias: waehrung */
  currency?: Currency<T>;
  /** Alias: waehrungCode */
  currencyId?: number;
  /** Alias: zahlungsCode */
  paymentId?: number;
  /** Alias: zahlungsfrist */
  paymentDeadline?: number;
  /** Alias: bankverbindungen */
  bankDetailsList?: BankDetails<T>[];
  /** Alias: einkaufspreise */
  purchasePriceList?: PurchasePrice<T>[];
  /** Alias: personErstkontakt */
  firstContactPerson?: string;
  /** Alias: zahlungsart */
  paymentKind?: PaymentKind<T>;
  /** Alias: attachements */
  attachements?: BaseDataAttachment<T>[];
  /** Alias: defaultAccount */
  defaultAccount?: LedgerAccount<T>;
  /** Alias: nebenadresse1 */
  additionalAddress1?: BusinessPartnerContactCombined<T>;
  /** Alias: nebenadresse2 */
  additionalAddress2?: BusinessPartnerContactCombined<T>;
  /** Alias: nebenadresse3 */
  additionalAddress3?: BusinessPartnerContactCombined<T>;
  /** Alias: sprache */
  language?: BaseDataLanguage<T>;
  /** Alias: letzterkontakt */
  lastContactTs?: string;
  /** Alias: personLetzterkontakt */
  personLastContact?: string;
  /** Alias: firmenAnrede */
  firmSalutation?: string;
  /** Alias: postIt */
  postIt?: PostIt<T>;
  /** Alias: createdByUserCode */
  createdByUserCode?: number;
  /** Alias: defaultErpTexts */
  defaultErpTexts?: DefaultErpText<T>[];
  /** Alias: kpis */
  kpis?: KpisSupplier<T>;

  __typename?: string;
}

const fields: FieldDefinitions<Supplier> = {
  departmentList: {
    alias: 'abteilungen',
    entity: Entities.department,
  },
  addressMaintainedAtCustomerId: {
    alias: 'adresseWirdGepflegtBeiKundeCode',
  },
  addressExtension: {
    alias: 'adresserweiterung',
  },
  salutation: {
    alias: 'anrede',
    entity: Entities.salutation,
  },
  salutationId: {
    alias: 'anredeCode',
  },
  contactList: {
    alias: 'ansprechpartner',
    entity: Entities.contact,
  },
  taskList: {
    alias: 'aufgaben',
    entity: Entities.task,
  },
  bic: {
    alias: 'bIC',
  },
  bankCode: {
    alias: 'bankleitzahl',
  },
  bankDetails: {
    alias: 'bankverbindung',
  },
  demandList: {
    alias: 'bedarfsanforderungen',
    entity: Entities.demand,
  },
  orderList: {
    alias: 'bestellungen',
    entity: Entities.order,
  },
  visitationReportList: {
    alias: 'besuchsberichte',
    entity: Entities.visitationReport,
  },
  letterSalutation: {
    alias: 'briefAnrede',
  },
  letterList: {
    alias: 'briefe',
    entity: Entities.letter,
  },
  isGrossInvoice: {
    alias: 'bruttorechnung',
  },
  id: {
    alias: 'code',
  },
  customFieldList: {
    alias: 'customFields',
    entity: Entities.customField,
  },
  dB: {
    alias: 'dB',
  },
  dontSumUpDTAN: {
    alias: 'dTANichtZusammenfassen',
  },
  documentList: {
    alias: 'dokumente',
    entity: Entities.document,
  },
  dontShowDoubletteCheck: {
    alias: 'doublettenCheck_NichtMehrAnzeigen',
  },
  eMail: {
    alias: 'eMail',
  },
  eMail2: {
    alias: 'eMail2',
  },
  eMailList: {
    alias: 'eMails',
    entity: Entities.eMail,
  },
  deactivateERPReleaseRequirement: {
    alias: 'eRPFreigabepflichtDeaktivieren',
  },
  inboundDeliveryNoteList: {
    alias: 'eingangslieferscheine',
    entity: Entities.inboundDeliveryNote,
  },
  distance: {
    alias: 'entfernung',
  },
  extinct: {
    alias: 'erloschen',
  },
  firstContactDate: {
    alias: 'erstkontakt',
  },
  firm1: {
    alias: 'firma1',
  },
  firm2: {
    alias: 'firma2',
  },
  firm3: {
    alias: 'firma3',
  },
  role: {
    alias: 'funktion',
  },
  birthdayDate: {
    alias: 'geburtsdatum',
  },
  birthdayYear: {
    alias: 'geburtstagJahr',
  },
  birthdayMonth: {
    alias: 'geburtstagMonat',
  },
  birthdayDay: {
    alias: 'geburtstagTag',
  },
  locked: {
    alias: 'gesperrt',
  },
  groupId: {
    alias: 'grCode',
  },
  group: {
    alias: 'gruppe',
    entity: Entities.supplierGroup,
  },
  hasObjectProtection: {
    alias: 'hasObjektschutz',
  },
  mainContact: {
    alias: 'hauptansprechpartner',
    entity: Entities.contact,
  },
  mainContactId: {
    alias: 'hauptansprechpartnerCode',
  },
  houseBankId: {
    alias: 'hausbankCode',
  },
  iban: {
    alias: 'iBAN',
  },
  insertTime: {
    alias: 'insertTime',
  },
  website: {
    alias: 'interNet',
  },
  dontConsiderIntrastat: {
    alias: 'intrastatNichtberuecksichtigen',
  },
  kContactId: {
    alias: 'kKontaktCode',
  },
  categoryAssignmentList: {
    alias: 'kategorieZuordnungen',
    entity: Entities.categoryAssignment,
  },
  noScaleDiscounts: {
    alias: 'keineStaffelrabatte',
  },
  accountOwner: {
    alias: 'kontoinhaber',
  },
  accountNumber: {
    alias: 'kontonummer',
  },
  firmSign: {
    alias: 'konzernkennzeichen',
  },
  creditLimit: {
    alias: 'kreditlimit',
  },
  short: {
    alias: 'kuerzel',
  },
  externalNumber: {
    alias: 'kundennr',
  },
  lastModificationDate: {
    alias: 'lastModificationDate',
  },
  lastEdit: {
    alias: 'letzteAenderung',
  },
  deliveryKindId: {
    alias: 'lieferungsArtCode',
  },
  dunningTolerance: {
    alias: 'mahntoleranz',
  },
  eMailLock: {
    alias: 'mailSperre',
  },
  mark: {
    alias: 'mark',
  },
  mobileNumber: {
    alias: 'mobilfunk',
  },
  name: {
    alias: 'name',
  },
  nameExtension: {
    alias: 'namenserweiterung',
  },
  additionalAddress1Id: {
    alias: 'nebenAdrCode1',
  },
  additionalAddress2Id: {
    alias: 'nebenAdrCode2',
  },
  additionalAddress3Id: {
    alias: 'nebenAdrCode3',
  },
  additionalAddress1Type: {
    alias: 'nebenAdrType1',
  },
  additionalAddress2Type: {
    alias: 'nebenAdrType2',
  },
  additionalAddress3Type: {
    alias: 'nebenAdrType3',
  },
  additionalAddressContact1Id: {
    alias: 'nebenadrAPCode1',
  },
  additionalAddressContact2Id: {
    alias: 'nebenadrAPCode2',
  },
  additionalAddressContact3Id: {
    alias: 'nebenadrAPCode3',
  },
  note: {
    alias: 'notiz',
  },
  noteRTF: {
    alias: 'notizRTF',
  },
  noteList: {
    alias: 'notizen',
    entity: Entities.note,
  },
  number: {
    alias: 'nummer',
  },
  city: {
    alias: 'ort',
  },
  postOfficeBoxLocation: {
    alias: 'ortPostfach',
  },
  postOfficeBoxPostalCode: {
    alias: 'pLZPostfach',
  },
  postalCode: {
    alias: 'plz',
  },
  postOfficeBox: {
    alias: 'postfach',
  },
  priceGroupId: {
    alias: 'preisgruppe',
  },
  priceGroup: {
    alias: 'preisgruppeItem',
    entity: Entities.priceGroup,
  },
  isPrivateCustomer: {
    alias: 'privatkunde',
  },
  projectList: {
    alias: 'projekte',
    entity: Entities.project,
  },
  commission: {
    alias: 'provision',
  },
  discountProposal: {
    alias: 'rabattvorschlag',
  },
  invoiceEMailAddress: {
    alias: 'rechnungsadresseEMail',
  },
  invoiceForm: {
    alias: 'rechnungsform',
  },
  travelExpensesList: {
    alias: 'reisekostenabrechnungen',
    entity: Entities.travelExpenses,
  },
  senderName: {
    alias: 'senderName',
  },
  serialLetterLock: {
    alias: 'serienbriefSperre',
  },
  skonto: {
    alias: 'skonto',
  },
  skonto2: {
    alias: 'skonto2',
  },
  skonto2DurationDays: {
    alias: 'skonto2Frist',
  },
  skontoDurationDays: {
    alias: 'skontofrist',
  },
  languageId: {
    alias: 'sprachCode',
  },
  country: {
    alias: 'staat',
  },
  standardAccount: {
    alias: 'standardkonto',
  },
  tax: {
    alias: 'steuer',
  },
  taxKeyId: {
    alias: 'steuerschluesselCode',
  },
  street: {
    alias: 'strasse',
  },
  faxNumber: {
    alias: 'telefax',
  },
  phoneNumber: {
    alias: 'telefon',
  },
  phoneNumber2: {
    alias: 'telefon2',
  },
  phoneCallList: {
    alias: 'telefonate',
    entity: Entities.callMemo,
  },
  appointmentList: {
    alias: 'termine',
    entity: Entities.appointment,
  },
  titleExtension: {
    alias: 'titelerweiterung',
  },
  vatId: {
    alias: 'uSTIDNR',
  },
  updateTime: {
    alias: 'updateTime',
  },
  addressConnectionList: {
    alias: 'verknuepfungen',
    entity: Entities.addressConnection,
  },
  representative2Id: {
    alias: 'vertreter2Code',
  },
  representativeId: {
    alias: 'vertreterCode',
  },
  representativeSdObjMemberId: {
    alias: 'vertreterSDObjMemberCode',
  },
  representativeSdObjType: {
    alias: 'vertreterSDObjType',
  },
  phoneNumberPrefix: {
    alias: 'vorwahl',
  },
  currency: {
    alias: 'waehrung',
    entity: Entities.currency,
  },
  currencyId: {
    alias: 'waehrungCode',
  },
  paymentId: {
    alias: 'zahlungsCode',
  },
  paymentDeadline: {
    alias: 'zahlungsfrist',
  },
  bankDetailsList: {
    alias: 'bankverbindungen',
    entity: Entities.bankDetails,
  },
  purchasePriceList: {
    alias: 'einkaufspreise',
    entity: Entities.purchasePrice,
  },
  firstContactPerson: {
    alias: 'personErstkontakt',
  },
  paymentKind: {
    alias: 'zahlungsart',
    entity: Entities.paymentKind,
  },
  attachements: {
    alias: 'attachements',
    entity: Entities.baseDataAttachment,
  },
  defaultAccount: {
    alias: 'defaultAccount',
    entity: Entities.ledgerAccount,
  },
  additionalAddress1: {
    alias: 'nebenadresse1',
    entity: Entities.businessPartnerContactCombined,
  },
  additionalAddress2: {
    alias: 'nebenadresse2',
    entity: Entities.businessPartnerContactCombined,
  },
  additionalAddress3: {
    alias: 'nebenadresse3',
    entity: Entities.businessPartnerContactCombined,
  },
  language: {
    alias: 'sprache',
    entity: Entities.baseDataLanguage,
  },
  lastContactTs: {
    alias: 'letzterkontakt',
  },
  personLastContact: {
    alias: 'personLetzterkontakt',
  },
  firmSalutation: {
    alias: 'firmenAnrede',
  },
  postIt: {
    alias: 'postIt',
    entity: Entities.postIt,
  },
  createdByUserCode: {
    alias: 'createdByUserCode',
  },
  defaultErpTexts: {
    alias: 'defaultErpTexts',
    entity: Entities.defaultErpText,
  },
  kpis: {
    alias: 'kpis',
    entity: Entities.kpisSupplier,
  },
  __typename: {
    alias: '__typename',
  },
};

export const supplierEntityDefinition: EntitiyDefinition<Supplier> = {
  local: {},
  remote: {
    queryName: 'getLieferanten',
    fragmentName: 'Lieferant',
    withPaginationWrapper: true,
    mutations: {
      upsert: {
        mutationName: 'upsertLieferant',
        args: [
          { name: 'input', type: 'InputLieferant!' },
          { name: 'relations', type: 'InputLieferantRelation' },
          { name: 'drySave', type: 'Boolean' },
        ],
      },
    },
    params: [
      { name: 'categoryCodes', type: '[Int]' },
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
