import { useCustomBackgroundsContext } from '@work4all/data';

import { FixedBackground } from './FixedBackground';

/**
 * Displays a custom background as a fixed image. If no custom background is
 * selected, renders nothing.
 */
export function CustomBackground() {
  const { activeBackground } = useCustomBackgroundsContext();

  if (!activeBackground) {
    return null;
  }

  return <FixedBackground src={activeBackground.url} />;
}
