import { generate } from 'short-uuid';

/**
 * Generates a random alphanumeric id.
 *
 * @returns A random id.
 */
export function randomId(): string {
  return generate();
}
