import { Button } from '@mui/material';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { RelationTypePicker } from '@work4all/components/lib/components/entity-picker/relation-type-picker/RelationTypePicker';

import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';

interface RelationTypePickerButtonProps {
  value: LookUp;
  onChange: (value: LookUp) => void;
  onClick?: () => void;
  label?: string;
}

export const RelationTypePickerButton = (
  props: RelationTypePickerButtonProps
) => {
  const { value, onChange, ...pickerProps } = props;

  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<EntityPickerPopover>(null);

  return (
    <div ref={wrapperRef}>
      <EntityPickerPopover
        ref={popoverRef}
        anchorEl={wrapperRef}
        picker={
          <RelationTypePicker
            {...pickerProps}
            multiple={false}
            value={value}
            onChange={(value) => {
              onChange(value);
              popoverRef.current?.close();
            }}
          />
        }
      >
        <Button variant="text">{value?.name ?? t('MASK.RELATION_TYPE')}</Button>
      </EntityPickerPopover>
    </div>
  );
};
