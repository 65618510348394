import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class Currency<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: name */
  name?: string;

  __typename?: string;
}

const fields: FieldDefinitions<Currency> = {
  id: {
    alias: 'code',
  },
  name: {
    alias: 'name',
  },
  __typename: {
    alias: '__typename',
  },
};

export const currencyEntityDefinition: EntitiyDefinition<Currency> = {
  local: {},
  remote: {
    queryName: 'getWaehrungen',
    fragmentName: 'Waehrung',
    withPaginationWrapper: false,
    params: [{ name: 'codes', type: 'Int' }],
  },
  fieldDefinitions: fields,
};
