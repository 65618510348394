import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { YELLOW_NOTE_FIELDS_QUERY } from '../yellow-note/yellow-note-fields';

export function useCustomer(id?: string) {
  const requestData = useMemo<DataRequest>(() => {
    const filter = [{ id: { $eq: id } }];

    return {
      entity: Entities.customer,
      data: CUSTOMER_FIELDS,
      filter,
    };
  }, [id]);

  const { data, pending, refetch } = useDataProvider<Customer>(
    requestData,
    !id
  );
  const customer = data?.[0];
  return { customer, pending, refetch };
}

const CUSTOMER_FIELDS: Customer<EMode.query> = {
  id: null,
  name: null,
  note: null,
  phoneNumber: null,
  phoneNumber2: null,
  number: null,
  isPrivateCustomer: null,
  eMail: null,
  eMail2: null,
  mainContactId: null,
  street: null,
  city: null,
  postalCode: null,
  website: null,
  group: { id: null, name: null },
  paymentKind: { id: null, longtext: null, note: null },
  paymentDeadline: null,
  skonto: null,
  skonto2: null,
  skontoDurationDays: null,
  skonto2DurationDays: null,
  postOfficeBox: null,
  postOfficeBoxLocation: null,
  postOfficeBoxPostalCode: null,
  firstContactDate: null,
  firstContactPerson: null,
  postIt: YELLOW_NOTE_FIELDS_QUERY,
  categoryAssignmentList: [
    {
      id: null,
      categoryId: null,
      categoryKindName: null,
      categoryName: null,
    },
  ],
  kpis: {
    dueOpenValue: null,
    salesValues: [
      {
        fromDate: null,
        toDate: null,
        value: null,
      },
    ],
    vacantPositions: null,
  },
  contactList: [
    {
      id: null,
      displayName: null,
      phoneNumber: null,
      phoneNumber2: null,
      phoneNumber3: null,
      phoneNumberPrivate: null,
      mobileNumber: null,
      mobileNumberPrivate: null,
      eMail: null,
      eMailPrivate: null,
      showFunctionInAddress: null,
      showDepartmentInAddress: null,
      layedOff: null,
      role: null,
      departmentId: null,
      department: {
        id: null,
        customerId: null,
        supplierId: null,
        name: null,
        insertTime: null,
        updateTime: null,
      },
      salutation: {
        id: null,
        isMale: null,
        isFemale: null,
      },
      firstName: null,
      name: null,
      note: null,
      businessPartnerType: null,
      businessPartnerId: null,
      businessPartner: {
        id: null,
        data: {
          customer: { id: null, name: null, website: null },
        },
      },
      categoryAssignmentList: [
        {
          id: null,
          categoryId: null,
          categoryKindName: null,
          categoryName: null,
        },
      ],
      attachements: [
        {
          id: null,
          index: null,
          objMemberCode: null,
          type: null,
          fileInfos: {
            fileEntityFilename: null,
            downloadUrl: null,
            previewUrl: null,
            fileRequestType: null,
            downloadMimeType: null,
            previewMimeType: null,
            fileSize: null,
          },
        },
      ],
    },
  ],
  extinct: null,
};
