import styles from './FilterSubGroupList.module.scss';

import ChevronRight from '@mui/icons-material/ChevronRight';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import i18next from 'i18next';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ColumnInstance,
  FilterType,
  useTableStateBag,
} from '@work4all/components';
import {
  IStackItem,
  useHistoryStack,
} from '@work4all/components/lib/navigation/history-stack';

import { FilterOverlayContext } from '../../FiltersOverlay';

export const getLeavesAndTwigs = (
  columns: ColumnInstance[],
  currentPath: string[]
): { leaves: ColumnInstance[]; twigs: string[] } => {
  const result = { leaves: [], twigs: [] };

  const currentPathString = currentPath.join('.');

  columns.forEach((col) => {
    if (!col.canFilter || col.filterValue?.readOnly) {
      return;
    }

    const subGroupPathString = col.filterSubgroupPath
      ? col.filterSubgroupPath.join('.')
      : '';

    if (subGroupPathString === currentPathString) {
      result.leaves.push(col);
    } else if (
      subGroupPathString.startsWith(currentPathString) &&
      col.filterSubgroupPath.length - currentPath.length === 1 &&
      result.twigs.indexOf(col.filterSubgroupPath[currentPath.length]) === -1
    ) {
      result.twigs.push(col.filterSubgroupPath[currentPath.length]);
    }
  });

  return result;
};

const goToSubgroup = (
  subgroupPath: string[],
  go: (item: IStackItem) => void
) => {
  go({
    title: i18next.t(subgroupPath[subgroupPath.length - 1]),
    view: <FilterSubGroupList subgroupPath={subgroupPath} />,
  });
};

interface IFilterSubGroupListProps {
  subgroupPath: string[];
}

export const FilterSubGroupList: React.FC<IFilterSubGroupListProps> = (
  props
) => {
  const context = useContext(FilterOverlayContext);
  const { subgroupPath } = props;
  const { go, goBack } = useHistoryStack();

  const { t } = useTranslation();

  const columns = useTableStateBag().tableInstance.columns;

  const leavesAndTwigs = useMemo(() => {
    return getLeavesAndTwigs(columns, subgroupPath);
  }, [columns, subgroupPath]);

  return (
    <List>
      {leavesAndTwigs.leaves.map((col) => {
        return !col.filterValue ||
          // Explicitly show the ClosedStatus filter if it is set to `false`. For
          // ClosedStatus filter type the value `false` acts basically the same as
          // "unset" for other filters.
          (col.filterType === FilterType.ClosedStatus &&
            col.filterValue.value === false) ? (
          <ListItem
            key={col.Header as string}
            className={styles.item}
            button
            divider={false}
            onClick={() => {
              context.addAdditionalFilter(col.Header as string);
              goBack(0);
            }}
          >
            <ListItemText
              className={styles.itemText}
              classes={{ primary: styles.primary }}
            >
              <div className={styles.itemText}>
                <div className={styles.filterNavLabel}>
                  {col.Header as string}
                </div>
              </div>
            </ListItemText>
          </ListItem>
        ) : null;
      })}
      {leavesAndTwigs.twigs.map((subgroup) => {
        return (
          <ListItem
            key={subgroup}
            className={styles.item}
            button
            divider={false}
            onClick={() => {
              goToSubgroup([...subgroupPath, subgroup], go);
            }}
          >
            <ListItemText
              className={styles.itemText}
              classes={{ primary: styles.primary }}
            >
              {t(subgroup)}
            </ListItemText>
            <ListItemIcon>
              <ChevronRight></ChevronRight>
            </ListItemIcon>
          </ListItem>
        );
      })}
    </List>
  );
};
