import styles from './TemplatesCategory.module.scss';

import { Button, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDataProvider, useUser } from '@work4all/data';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { Contract } from '@work4all/models/lib/Classes/Contract.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Order } from '@work4all/models/lib/Classes/Order.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { Task } from '@work4all/models/lib/Classes/Task.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useTrackHistory } from '../../../hooks/use-time-track-history';
import { ProjectTimeMaskInit } from '../ProjectTimeMask';
import { categoryType } from '../TimeTemplatesWidget';

import { normalizeData, requestedData } from './TemplatesDataRequests';
import TemplateCard from './TenplateCard';

interface TemplatesCategoryProps {
  onTemplateSelect: (init: ProjectTimeMaskInit, createdFrom: Entities) => void;
  title: string;
  type: categoryType;
  mobileMode?: boolean;
}

const TemplatesCategory: React.FC<TemplatesCategoryProps> = ({
  title,
  type,
  onTemplateSelect,
  mobileMode = false,
}) => {
  const { lastTimeTemplates, saveTimeTemplate, categoriesSettings } =
    useTrackHistory();
  const user = useUser();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const isRecentCategory = type === 'last';
  const recentItemsLength = lastTimeTemplates.length;
  const showCategoryBySettings = categoriesSettings[type].show;
  const countBySettings = categoriesSettings[type].count;

  const requestData = useMemo(
    () => ({
      entity: type === 'last' ? Entities.task : type,
      data: requestedData(user)[type === 'last' ? Entities.task : type].data,
      filter:
        requestedData(user)[type === 'last' ? Entities.task : type].filter,
      sort: requestedData(user)[type === 'last' ? Entities.task : type].sort,
    }),
    [type, user]
  );

  const { data: responseData } = useDataProvider<
    Task | Order | Project | Appointment | Ticket
  >(requestData, false);

  const data = normalizeData(
    responseData as Task[] | Contract[] | Project[] | Appointment[] | Ticket[],
    type
  );

  const createdFrom = type === 'last' ? Entities.task : type;

  return showCategoryBySettings &&
    countBySettings > 0 &&
    ((!isRecentCategory && data.length > 0) ||
      (isRecentCategory && recentItemsLength > 0)) ? (
    <div>
      <Divider classes={{ root: styles.divider }} textAlign="left">
        {title}
      </Divider>
      <div
        className={clsx(styles.templatesGrid, { [styles.mobile]: mobileMode })}
      >
        {type === 'last'
          ? lastTimeTemplates
              .slice(0, open ? lastTimeTemplates.length : countBySettings)
              .map((item, i) => (
                <TemplateCard
                  type={type}
                  key={`${item.title}-${item.subtitle1}-${i}`}
                  title={item.title}
                  subtitle1={item.subtitle1}
                  subtitle2={item.subtitle2}
                  subtitle3={item.subtitle3}
                  subtitle4={item.subtitle4}
                  onClick={() => {
                    onTemplateSelect(
                      {
                        project: item.toApply.project,
                        projectProcess: item.toApply.projectProcess,
                        customer: item.toApply.businessPartner as Customer,
                        contract: item.toApply.contract,
                        ticket: item.toApply.ticket,
                      },
                      createdFrom
                    );
                  }}
                />
              ))
          : data
              .slice(0, open ? data.length : countBySettings)
              .map((item, i) => (
                <TemplateCard
                  type={type}
                  key={`${item.title}-${item.subtitle1}-${i}`}
                  title={item.title}
                  subtitle1={item.subtitle1}
                  subtitle2={item.subtitle2}
                  subtitle3={item.subtitle3}
                  subtitle4={item.subtitle4}
                  businessPartnerName={item?.businessPartnerName}
                  projectName={item?.projectName}
                  onClick={() => {
                    saveTimeTemplate(item);
                    onTemplateSelect(
                      {
                        project: item.toApply.project,
                        projectProcess: item.toApply.projectProcess,
                        customer: item.toApply.businessPartner,
                        contract: item.toApply.contract,
                        ticket: item.toApply.ticket,
                      },
                      createdFrom
                    );
                  }}
                />
              ))}
      </div>
      {(!isRecentCategory && data.length > countBySettings) ||
      (isRecentCategory && lastTimeTemplates.length > countBySettings) ? (
        <Button
          className={styles.moreButton}
          variant="text"
          onClick={() => setOpen(!open)}
        >
          {open
            ? 'weniger anzeigen'
            : `+${
                isRecentCategory
                  ? lastTimeTemplates.length - countBySettings
                  : data.length - countBySettings
              } weitere`}
        </Button>
      ) : null}
    </div>
  ) : (
    <div>
      <Divider classes={{ root: styles.divider }} textAlign="left">
        {title}
      </Divider>
      <Typography variant="caption" color={'InactiveCaptionText'}>
        {t('COMMON.NO_DATA')}
      </Typography>
    </div>
  );
};

export default TemplatesCategory;
