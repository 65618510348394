import styles from './WidgetGroup.module.scss';

import { ButtonBase, Theme, useMediaQuery } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import clsx from 'clsx';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ChevronDownIcon } from '@work4all/assets/icons/chevron-down.svg';

import { FileViewMode, useCustomBackgroundsContext } from '@work4all/data';

import { ILayoutDefinition } from '@work4all/models/lib/Layout';

import { settings, useSetting } from '../../../../../../settings';
import { FileContext } from '../../FileContext';
import { WidgetGroupContent } from '../WidgetGroupContent';

export type IWidgetGroupProps = {
  id: string;
  name: string;
  defaultCollapsed: boolean;
  widgets: ILayoutDefinition[];
  viewMode: FileViewMode;
};

export function WidgetGroup({
  id,
  name,
  defaultCollapsed,
  widgets,
  viewMode,
}: IWidgetGroupProps) {
  const isViewportDesktop = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up('md')
  );

  const fileContext = useContext(FileContext);
  const { t } = useTranslation();
  const collapsedWidgetGroup = useSetting(
    settings.collapsedWidgetGroup({
      fileType: fileContext.type,
      id,
      defaultValue: defaultCollapsed,
    })
  );

  const customBackground = useCustomBackgroundsContext();

  return (
    <div
      className={
        styles[!isViewportDesktop ? 'widget-group-md' : 'widget-group']
      }
    >
      {
        <ButtonBase
          className={clsx(styles['group-header-button'], {
            [styles['group-header-button-md']]: !isViewportDesktop,
            [styles['textInverse']]: customBackground.isActive,
          })}
          onClick={() => {
            collapsedWidgetGroup.set(!collapsedWidgetGroup.value);
          }}
        >
          <ChevronDownIcon
            className={clsx(
              styles['icon'],
              !collapsedWidgetGroup.value && styles['group-open']
            )}
          />
          <span>{t(name)}</span>
        </ButtonBase>
      }

      <Collapse in={!collapsedWidgetGroup.value}>
        <WidgetGroupContent widgets={widgets} viewMode={viewMode} />
      </Collapse>
    </div>
  );
}
