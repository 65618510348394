import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { NavigationOverlayHeader } from '../../../../../../components/navigation-overlay-header/NavigationOverlayHeader';
import { MaskContent } from '../../../../../mask-overlays/mask-overlay/components/MaskContent/MaskContent';
import {
  MaskContextProvider,
  useMaskContextValue,
} from '../../../../../mask-overlays/mask-overlay/hooks/mask-context';
import {
  MaskTab,
  MaskTabContext,
  MaskTabPanel,
  MaskTabs,
} from '../../../../../mask-overlays/mask-tabs';

import { General } from './tabs/General';
import { Personal } from './tabs/Personal';

export function SettingsMenu() {
  const { t } = useTranslation();
  const maskContext = useMaskContextValue({
    wip: false,
    entity: undefined,
    mode: 'edit',
    isCreateMode: false,
    isEditMode: true,
    params: undefined,
  });
  return (
    <MaskContextProvider value={maskContext}>
      <MaskTabContext defaultValue="personal">
        <NavigationOverlayHeader
          title={t('SETTINGS.SETTINGS')}
          subTitle={t('RELEVANT_WIDGET.TITLE')}
          breadcrumbsChildren={<div></div>}
          tabs={<SettingsTabs />}
        />
        <Content />
      </MaskTabContext>
    </MaskContextProvider>
  );
}

const SettingsTabs = memo(function Tabs() {
  const { t } = useTranslation();

  return (
    <MaskTabs>
      <MaskTab value="personal" label={t('COMMON.PERSONAL')}></MaskTab>
      <MaskTab value="general" label={t('COMMON.GENERAL')} />
    </MaskTabs>
  );
});

const Content = memo(function TabPanels() {
  return (
    <div>
      <MaskContent>
        <MaskTabPanel value="general">
          <General />
        </MaskTabPanel>
        <MaskTabPanel value="personal">
          <Personal />
        </MaskTabPanel>
      </MaskContent>
    </div>
  );
});
