import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { ContactKind } from '../Enums/ContactKind.enum';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Contact } from './Contact.entity';
import { ContactUnion } from './ContactUnion.entity';
import { Customer } from './Customer.entity';
import { Supplier } from './Supplier.entity';
import { User } from './User.entity';

export class ContactUnionWrapper<T extends EMode = EMode.entity> {
  /** Alias: data */
  data?: T extends EMode.query
    ? ContactUnion<T>
    : User<T> | Customer<T> | Supplier<T> | Contact<T>;
  /** Alias: id */
  id?: string;
  /** Alias: kontaktArt */
  contactKind?: ContactKind;

  __typename?: string;
}

const fields: FieldDefinitions<ContactUnionWrapper> = {
  data: {
    alias: 'data',
    entity: [
      Entities.user,
      Entities.customer,
      Entities.supplier,
      Entities.contact,
    ],
  },
  id: {
    alias: 'id',
  },
  contactKind: {
    alias: 'kontaktArt',
  },
  __typename: {
    alias: '__typename',
  },
};

export const contactUnionWrapperEntityDefinition: EntitiyDefinition<ContactUnionWrapper> =
  {
    local: {},
    remote: {
      queryName: 'searchContacts',
      fragmentName: 'ContactUnionWrapper',
      withPaginationWrapper: false,
      params: [
        { name: 'searchValue', type: 'String!' },
        { name: 'contactTypes', type: '[KontaktArt]' },
        { name: 'maxRows', type: 'Int' },
      ],
    },
    fieldDefinitions: fields,
  };
