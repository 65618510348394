import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SalesOpportunityStatus } from '@work4all/models/lib/Enums/SalesOpportunityStatus.enum';

import { SalesOpportunitiesStatusPicker } from '../../../../components/entity-picker/sales-opportunities-status-picker/SalesOpportunitiesStatusPicker';
import { FilterCard } from '../filter-card/FilterCard';

interface SalesOpportunityStatusOption {
  id: SalesOpportunityStatus;
  name: string;
}

export type ISalesOpportunitiesStatusFilterCardProps = {
  title: string;
  value: SalesOpportunityStatusOption[] | null;
  onChange: (value: { id: string; name: string }[]) => void;
  expanded: boolean;
  onExpandedChange: (val: boolean) => void;
};

export const SalesOpportunitiesStatusFilterCard: React.FC<
  ISalesOpportunitiesStatusFilterCardProps
> = (props) => {
  const { title, value, onChange, expanded, onExpandedChange } = props;

  const { t } = useTranslation();

  const filterValueText: string = useMemo(() => {
    if (!value || value.length === 0) return null;
    if (value.length === 1)
      return t('COMMON.SALESOPPORTUNITIES.' + value[0].id);
    return value.length + ' ' + t('COMMON.CHOSEN');
  }, [value, t]);

  return (
    <FilterCard
      title={title}
      filterValue={filterValueText}
      filterValueSet={!!filterValueText}
      expanded={expanded}
      onExpandedChange={onExpandedChange}
      onValueDelete={() => {
        onChange([]);
      }}
    >
      <SalesOpportunitiesStatusPicker
        multiple={true}
        value={value}
        onChange={(newValue) => {
          onChange(newValue);
        }}
      />
    </FilterCard>
  );
};
