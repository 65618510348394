import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { StatisticsSalesVolumeByCustomerRow } from './StatisticsSalesVolumeByCustomerRow.entity';

export class StatisticsSalesVolumeByCustomerColumn<
  T extends EMode = EMode.entity
> {
  /** Alias: rangeStart */
  rangeStart?: string;
  /** Alias: rangeEnd */
  rangeEnd?: string;
  /** Alias: name */
  name?: string;
  /** Alias: rowCount */
  rowCount?: number;
  /** Alias: rows */
  rows?: StatisticsSalesVolumeByCustomerRow<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<StatisticsSalesVolumeByCustomerColumn> = {
  rangeStart: {
    alias: 'rangeStart',
  },
  rangeEnd: {
    alias: 'rangeEnd',
  },
  name: {
    alias: 'name',
  },
  rowCount: {
    alias: 'rowCount',
  },
  rows: {
    alias: 'rows',
    entity: Entities.statisticsSalesVolumeByCustomerRow,
  },
  __typename: {
    alias: '__typename',
  },
};

export const statisticsSalesVolumeByCustomerColumnEntityDefinition: EntitiyDefinition<StatisticsSalesVolumeByCustomerColumn> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'StatisticsSalesVolumeByCustomerColumn',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
