import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputCrmAnhangAttachementsRelation } from './InputCrmAnhangAttachementsRelation.entity';
import { InputTerminTeilnehmerRelation } from './InputTerminTeilnehmerRelation.entity';
import { InputTopicMarkRelation } from './InputTopicMarkRelation.entity';

export class InputTerminRelation<T extends EMode = EMode.entity> {
  /** Alias: teilnehmer */
  teilnehmer?: InputTerminTeilnehmerRelation<T>;
  /** Alias: topic */
  topic?: InputTopicMarkRelation<T>;
  /** Alias: attachements */
  attachements?: InputCrmAnhangAttachementsRelation<T>;

  __typename?: string;
}

const fields: FieldDefinitions<InputTerminRelation> = {
  teilnehmer: {
    alias: 'teilnehmer',
  },
  topic: {
    alias: 'topic',
  },
  attachements: {
    alias: 'attachements',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputTerminRelationEntityDefinition: EntitiyDefinition<InputTerminRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputTerminRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
