import { CustomField } from '@work4all/models/lib/Classes/CustomField.entity';

import { decodeCustomFieldName } from './custom-field-name';
import { EncodedCustomFieldName } from './types';

type InputWithMappedCustomFields<T> = Omit<T, '_customFields'> & {
  customFieldList?: CustomField[];
};

export function prepareInputWithCustomFields<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends { _customFields?: Record<string, any> }
>(input: T): InputWithMappedCustomFields<T> {
  const { _customFields: customFields, ...clone } = input;

  const result: InputWithMappedCustomFields<T> = clone;

  if (customFields) {
    const customFieldList = serializeCustomFieldValues(customFields);
    result.customFieldList = customFieldList;
  }

  return result;
}

function serializeCustomFieldValues(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldValues: Record<EncodedCustomFieldName, any>
): CustomField[] {
  const customFieldList: CustomField[] = Object.entries(fieldValues)
    .map(([key, value]) => {
      if (value == null) return null;

      return {
        // Remove the "f" prefix from the field key.
        key: decodeCustomFieldName(key as EncodedCustomFieldName),
        value: JSON.stringify(value),
      };
    })
    .filter(Boolean);

  return customFieldList;
}
