import { ParsedCustomFieldConfig } from '@work4all/data/lib/custom-fields';

import { IndividualFieldControlType } from '@work4all/models/lib/Enums/IndividualFieldControlType.enum';

export interface GroupedCustomFields {
  groups: CustomFieldGroup[];
}

export interface CustomFieldGroup {
  id: string | number;
  name: string;
  isDefault?: true;
  subgroups: CustomFieldSubgroup[];
}

export interface CustomFieldSubgroup {
  id: string | number;
  fields: ParsedCustomFieldConfig[];
}

export function groupCustomFields(
  fields: ParsedCustomFieldConfig[]
): GroupedCustomFields {
  let currentGroup: CustomFieldGroup = null;
  let currentSubgroup: CustomFieldSubgroup = null;

  const groups: CustomFieldGroup[] = [];

  // If the first field is not a caption, create a default group.
  if (fields.length > 0 && !isCaptionField(fields[0])) {
    currentGroup = createDefaultGroup();
    currentSubgroup = createDefaultSubgroup();

    currentGroup.subgroups.push(currentSubgroup);

    groups.push(currentGroup);
  }

  for (const field of fields) {
    if (isCaptionField(field)) {
      currentGroup = createGroup(field.id, field.name);
      currentSubgroup = createDefaultSubgroup();

      currentGroup.subgroups.push(currentSubgroup);

      groups.push(currentGroup);
    } else if (isNewLineField(field)) {
      currentSubgroup = createSubgroup(field.id);

      currentGroup.subgroups.push(currentSubgroup);
    } else {
      currentSubgroup.fields.push(field);
    }
  }

  return { groups };
}

function isCaptionField(field: ParsedCustomFieldConfig): boolean {
  return field.fieldType === IndividualFieldControlType.CAPTION;
}

function isNewLineField(field: ParsedCustomFieldConfig): boolean {
  return field.fieldType === IndividualFieldControlType.ZEILENUMBRUCH;
}

function createGroup(
  id: string | number,
  name: string,
  isDefault?: true
): CustomFieldGroup {
  return { id, name, isDefault, subgroups: [] };
}

function createDefaultGroup(): CustomFieldGroup {
  return createGroup('default', '', true);
}

function createSubgroup(id: string | number): CustomFieldSubgroup {
  return { id, fields: [] };
}

function createDefaultSubgroup(): CustomFieldSubgroup {
  return createSubgroup('default');
}
