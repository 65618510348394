import styles from './SlideTransition.module.scss';
import scssVariables from '@work4all/assets/theme/variablesDark.scss';

import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const transitionTimeout = parseInt(scssVariables.slideTransitionTimeout);

interface IClasses {
  root?: string;
}

type Direction = 'push' | 'pop';

export interface ISlideTransitionProps {
  currentKey: string;
  direction: Direction;
  classes?: IClasses;
}

export const SlideTransition: React.FC<ISlideTransitionProps> = (props) => {
  const { children } = props;

  /**
   * PROBLEM:
   * CSSTransition applies *-(enter|exit) and *-(enter|exit)-active
   * class names at the same time.
   * Because of this transition effects are broken in production mode
   * "push-enter" sets "transform: translateX(100%)"
   * And "push-enter-active" sets "transform: translateX(0)" and also defines transition.
   * In this case browser doesn't recognize that element position was translated.
   * Transition will work only if you set this classes IN SEPERATE FRAMES.
   *
   * After transition is finished make sure to call setActive with null to remove
   * any *-(enter|exit)-active class names.
   */
  const [active, setActive] = useState<Direction | null>(null);
  useEffect(() => {
    const rId = requestAnimationFrame(() => {
      setActive(props.direction);
    });

    return () => {
      cancelAnimationFrame(rId);
    };
  }, [props.direction, props.currentKey]);

  useEffect(() => {
    if (active) {
      const timerId = setTimeout(() => {
        setActive(null);
      }, transitionTimeout);

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [active]);

  // Be careful with class names.
  // CSSTransition have issues when className updates dynamically
  // (both in children components and CSSTransition component)
  // Unfortunately issue is still open.
  // https://github.com/reactjs/react-transition-group/issues/318
  return (
    <TransitionGroup
      className="transtion-group"
      component="div"
      data-direction={props.direction}
      data-active-direction={active}
    >
      <CSSTransition
        key={props.currentKey}
        timeout={transitionTimeout}
        classNames={props.direction}
        mountOnEnter={false}
        unmountOnExit={true}
      >
        <div className={clsx(styles.page, props.classes?.root)}>{children}</div>
      </CSSTransition>
    </TransitionGroup>
  );
};
