import styles from './withLocker.module.scss';

import { ReactComponent as LockIcon } from '@work4all/assets/icons/lock_outline.svg';

export interface WithLockerProps {
  userId: number;
  locked?: boolean;
}

export function withLocker<T extends WithLockerProps>(
  WrappedComponent: React.ComponentType<T>
) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithLocker = (props: T) => {
    if (!props?.userId) return <></>;
    if (!props?.locked) return <WrappedComponent {...(props as T)} />;

    return (
      <div className={styles['locker__container']}>
        <WrappedComponent {...(props as T)} />
        <div className={styles['locker__icon']}>
          <LockIcon />
        </div>
      </div>
    );
  };

  ComponentWithLocker.displayName = `withLocker(${displayName})`;
  return ComponentWithLocker;
}
