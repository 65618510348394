import { Sickness } from '@work4all/models/lib/Classes/Sickness.entity';
import { Vacation } from '@work4all/models/lib/Classes/Vacation.entity';
import { VacationDayKind } from '@work4all/models/lib/Enums/VacationDayKind.enum';

import { isVacationApproved } from '../../../containers/vacations/utils';
import { APPROVED_COLOR, PENDING_COLOR, SICKNESS_COLOR } from '../constants';

export const absenceBackground = (absence: Vacation | Sickness) => {
  const isSickness = absence.__typename === 'Krankheit';

  const color = isVacationApproved(absence)
    ? isSickness
      ? SICKNESS_COLOR
      : (absence as Vacation)?.simpleVacationKind?.color ?? APPROVED_COLOR
    : PENDING_COLOR;

  if (isSickness) return color;

  switch ((absence as Vacation)?.vacationDayPosition) {
    case VacationDayKind.HALBTAGS_VORMITTAG:
      return `linear-gradient(135deg, ${color} 0%, ${color} 50%, transparent 50%)`;
    case VacationDayKind.HALBTAGS_NACHMITTAG:
      return `linear-gradient(-45deg, ${color} 0%, ${color} 50%, transparent 50%)`;
    default:
      return color;
  }
};
