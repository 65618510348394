import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { CategoryPicker, CategoryPickerProps } from './CategoryPicker';

type ProjectCategoryPickerProps = Omit<
  CategoryPickerProps,
  'filter' | 'entity'
>;

export function ProjectCategoryPicker(props: ProjectCategoryPickerProps) {
  return <CategoryPicker {...props} entity={Entities.projectCategoryClass} />;
}
