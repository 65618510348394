import styles from './FilesPagination.module.scss';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useCallback, useMemo } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';

import { Caption } from '@work4all/components/lib/typography/caption/Caption';

export interface IFilesPaginationProps {
  /**
   * If `true`, this will disable the back and forward buttons and the keyboard shortcuts.
   *
   * @default false
   */
  disabled?: boolean;
  /**
   * Current page.
   */
  page: number;
  /**
   * Total number of pages.
   */
  count: number;
  onChange: (page: number) => void;
}

export const FilesPagination: React.VFC<IFilesPaginationProps> = (props) => {
  const { disabled = false, page, count, onChange } = props;

  const theme = useTheme();
  const belowMd = useMediaQuery(theme.breakpoints.down('md'));

  const handleBackClick = useCallback(() => {
    if (disabled) return;

    if (page <= 1) {
      onChange(count);
    } else {
      onChange(page - 1);
    }
  }, [disabled, page, count, onChange]);

  const handleForwardClick = useCallback(() => {
    if (disabled) return;

    if (page >= count) {
      onChange(1);
    } else {
      onChange(page + 1);
    }
  }, [disabled, page, count, onChange]);

  const hotkeysKeyMap = useMemo(
    () => ({
      PREV_FILE: 'ArrowLeft',
      NEXT_FILE: 'ArrowRight',
    }),
    []
  );

  const hotkeysHandlers = useMemo(
    () => ({
      PREV_FILE: handleBackClick,
      NEXT_FILE: handleForwardClick,
    }),
    [handleBackClick, handleForwardClick]
  );

  return (
    <div className={styles['files-pagination']}>
      <IconButton
        disabled={disabled}
        color="primary"
        size="large"
        onClick={handleBackClick}
      >
        <ArrowBackIcon />
      </IconButton>

      {!belowMd && (
        <Caption color="text03">
          {page} / {count}
        </Caption>
      )}

      <IconButton
        disabled={disabled}
        color="primary"
        size="large"
        onClick={handleForwardClick}
      >
        <ArrowForwardIcon />
      </IconButton>

      <GlobalHotKeys
        allowChanges
        keyMap={hotkeysKeyMap}
        handlers={hotkeysHandlers}
      />
    </div>
  );
};
