import { ExtendedMailboxFolder } from './types';

export const ROOT_PARENT_ID = '';

export type MailboxFolderTreeItem = {
  folder: ExtendedMailboxFolder;
  children: MailboxFolderTreeItem[];
};

export function makeFolderTree(
  folders: ExtendedMailboxFolder[]
): MailboxFolderTreeItem[] {
  const folderMap = new Map<string, ExtendedMailboxFolder[]>();

  for (const folder of folders) {
    const { parentId } = folder;
    if (!folderMap.has(parentId)) {
      folderMap.set(parentId, []);
    }
    folderMap.get(parentId).push(folder);
  }

  const topLevelFolders = folderMap.get(ROOT_PARENT_ID);

  if (!topLevelFolders) {
    return [];
  }

  function createItem(folder: ExtendedMailboxFolder): MailboxFolderTreeItem {
    const children = folderMap.get(folder.id);
    return {
      folder,
      children: children ? children.map(createItem) : null,
    };
  }

  return topLevelFolders.map(createItem);
}
