import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { ObjectType } from '../Enums/ObjectType.enum';

import { Appointment } from './Appointment.entity';
import { Article } from './Article.entity';
import { Calculation } from './Calculation.entity';
import { CallMemo } from './CallMemo.entity';
import { CheckList } from './CheckList.entity';
import { CommentEvent } from './CommentEvent.entity';
import { Contract } from './Contract.entity';
import { Customer } from './Customer.entity';
import { DeliveryNote } from './DeliveryNote.entity';
import { Demand } from './Demand.entity';
import { Document } from './Document.entity';
import { EMail } from './EMail.entity';
import { InboundDeliveryNote } from './InboundDeliveryNote.entity';
import { InboundInvoice } from './InboundInvoice.entity';
import { Invoice } from './Invoice.entity';
import { Letter } from './Letter.entity';
import { MentionUnion } from './MentionUnion.entity';
import { Note } from './Note.entity';
import { Offer } from './Offer.entity';
import { Order } from './Order.entity';
import { ProductionContract } from './ProductionContract.entity';
import { Project } from './Project.entity';
import { SalesOpportunities } from './SalesOpportunities.entity';
import { Supplier } from './Supplier.entity';
import { Task } from './Task.entity';
import { Ticket } from './Ticket.entity';
import { VisitationReport } from './VisitationReport.entity';

export class Mention<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: fromUserCode */
  fromUserCode?: number;
  /** Alias: toUserCode */
  toUserCode?: number;
  /** Alias: objectType */
  objectType?: ObjectType;
  /** Alias: objectCode */
  objectCode?: number;
  /** Alias: objectId */
  objectId?: string;
  /** Alias: parentObjectCode */
  parentObjectCode?: number;
  /** Alias: parentObjectId */
  parentObjectId?: string;
  /** Alias: parentObjectType */
  parentObjectType?: ObjectType;
  /** Alias: object */
  object?: T extends EMode.query
    ? MentionUnion<T>
    :
        | CommentEvent<T>
        | Ticket<T>
        | Task<T>
        | Appointment<T>
        | CheckList<T>
        | Note<T>
        | SalesOpportunities<T>
        | CallMemo<T>
        | VisitationReport<T>
        | Document<T>
        | EMail<T>
        | Letter<T>
        | Offer<T>
        | InboundDeliveryNote<T>
        | ProductionContract<T>
        | Contract<T>
        | InboundInvoice<T>
        | Invoice<T>
        | Demand<T>
        | Calculation<T>
        | Order<T>
        | DeliveryNote<T>
        | Customer<T>
        | Supplier<T>
        | Project<T>
        | Article<T>;
  /** Alias: parentObject */
  parentObject?: T extends EMode.query
    ? MentionUnion<T>
    :
        | CommentEvent<T>
        | Ticket<T>
        | Task<T>
        | Appointment<T>
        | CheckList<T>
        | Note<T>
        | SalesOpportunities<T>
        | CallMemo<T>
        | VisitationReport<T>
        | Document<T>
        | EMail<T>
        | Letter<T>
        | Offer<T>
        | InboundDeliveryNote<T>
        | ProductionContract<T>
        | Contract<T>
        | InboundInvoice<T>
        | Invoice<T>
        | Demand<T>
        | Calculation<T>
        | Order<T>
        | DeliveryNote<T>
        | Customer<T>
        | Supplier<T>
        | Project<T>
        | Article<T>;

  __typename?: string;
}

const fields: FieldDefinitions<Mention> = {
  id: {
    alias: 'id',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  fromUserCode: {
    alias: 'fromUserCode',
  },
  toUserCode: {
    alias: 'toUserCode',
  },
  objectType: {
    alias: 'objectType',
  },
  objectCode: {
    alias: 'objectCode',
  },
  objectId: {
    alias: 'objectId',
  },
  parentObjectCode: {
    alias: 'parentObjectCode',
  },
  parentObjectId: {
    alias: 'parentObjectId',
  },
  parentObjectType: {
    alias: 'parentObjectType',
  },
  object: {
    alias: 'object',
    entity: [
      Entities.commentEvent,
      Entities.ticket,
      Entities.task,
      Entities.appointment,
      Entities.checkList,
      Entities.note,
      Entities.salesOpportunities,
      Entities.callMemo,
      Entities.visitationReport,
      Entities.document,
      Entities.eMail,
      Entities.letter,
      Entities.offer,
      Entities.inboundDeliveryNote,
      Entities.productionContract,
      Entities.contract,
      Entities.inboundInvoice,
      Entities.invoice,
      Entities.demand,
      Entities.calculation,
      Entities.order,
      Entities.deliveryNote,
      Entities.customer,
      Entities.supplier,
      Entities.project,
      Entities.article,
    ],
  },
  parentObject: {
    alias: 'parentObject',
    entity: [
      Entities.commentEvent,
      Entities.ticket,
      Entities.task,
      Entities.appointment,
      Entities.checkList,
      Entities.note,
      Entities.salesOpportunities,
      Entities.callMemo,
      Entities.visitationReport,
      Entities.document,
      Entities.eMail,
      Entities.letter,
      Entities.offer,
      Entities.inboundDeliveryNote,
      Entities.productionContract,
      Entities.contract,
      Entities.inboundInvoice,
      Entities.invoice,
      Entities.demand,
      Entities.calculation,
      Entities.order,
      Entities.deliveryNote,
      Entities.customer,
      Entities.supplier,
      Entities.project,
      Entities.article,
    ],
  },
  __typename: {
    alias: '__typename',
  },
};

export const mentionEntityDefinition: EntitiyDefinition<Mention> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'Mention',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
