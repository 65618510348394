import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  IPositionPickerProps,
  PositionPicker,
} from '@work4all/components/lib/components/entity-picker/position-picker/PositionPicker';

import { reactRefSetter } from '@work4all/utils';

import { LabeledInput } from '../../containers/mask-overlays/locked-inputs';

interface PositionPickerFieldProps extends IPositionPickerProps {
  error?: string;
  name?: string;
}

export const PositionPickerField = React.forwardRef<
  HTMLDivElement,
  PositionPickerFieldProps
>(function PositionPickerField(props, ref) {
  const { error, ...pickerProps } = props;
  const { value, onChange } = pickerProps;

  const { t } = useTranslation();

  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={reactRefSetter(wrapperRef, ref)}>
      <EntityPickerPopover
        anchorEl={wrapperRef}
        picker={<PositionPicker {...pickerProps} />}
      >
        <LabeledInput
          name={props.name}
          value={value ? `${value.number} ${value.longtext}` : ''}
          label={t('COMMON.POSITION')}
          error={error}
          onClear={() => {
            onChange(null);
          }}
        />
      </EntityPickerPopover>
    </div>
  );
});
