import { useCallback, useEffect, useState } from 'react';
import { Row } from 'react-table';

import { TimeCard } from '@work4all/models/lib/Classes/TimeCard.entity';
import { WorkingTimeTimeCardDayInfo } from '@work4all/models/lib/Classes/WorkingTimeTimeCardDayInfo.entity';

type RowType = { date: string };
export const useTimePreview = (
  timeCard: TimeCard,
  refetch: () => Promise<void>
) => {
  const [row, setRow] = useState<Row<RowType>>(undefined);
  const [detailItem, setDetailsItem] =
    useState<WorkingTimeTimeCardDayInfo>(undefined);

  const showDetails = useCallback(
    (current: Row<RowType>[]) => {
      if (current && current.length === 1 && current[0]?.id !== row?.id) {
        setRow(current[0]);
      }
    },
    [setRow, row]
  );

  const hideDetails = useCallback(() => {
    setDetailsItem(undefined);
    refetch();
  }, [setDetailsItem, refetch]);

  useEffect(() => {
    if (row?.values)
      setDetailsItem(
        timeCard?.timeCardDays?.find((x) => x.date === row?.values?.date)
      );
  }, [timeCard, row]);

  return { showDetails, detailItem, hideDetails };
};
