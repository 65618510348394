import Typography from '@mui/material/Typography';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data/lib/hooks/data-provider';

import { TravelExpenseReportPaymentMethod } from '@work4all/models/lib/Classes/TravelExpenseReportPaymentMethod.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type ITravelExpenseReportPaymentMethodPickerProps<
  TMultiple extends boolean
> = IPickerProps<TravelExpenseReportPaymentMethod, TMultiple>;

export function TravelExpenseReportPaymentMethodPicker<
  TMultiple extends boolean
>(props: ITravelExpenseReportPaymentMethodPickerProps<TMultiple>) {
  const { data, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...FIELDS, ...data };
  }, [data]);

  const requestData = useMemo<DataRequest>(() => {
    return {
      entity: Entities.travelExpenseReportPaymentMethod,
      data: allFields,
      completeDataResponse: true,
    };
  }, [allFields]);

  const response = useDataProvider(requestData);

  const dataset = useMemo(() => {
    return {
      total: response.data.length,
      loading: false,
      fetchMore: () => Promise.resolve(),
      refetch: () => Promise.resolve(null),
      data: response.data,
    };
  }, [response.data]);

  return (
    <ListEntityPicker
      entity={Entities.travelExpenseReportPaymentMethod}
      data={allFields}
      fixedDataSet={dataset}
      filterBy="name"
      sortBy="name"
      {...rest}
      renderItemContent={(projectKind) => (
        <Tooltip title={projectKind.name}>
          <Typography variant="body2" noWrap>
            {projectKind.name}
          </Typography>
        </Tooltip>
      )}
    />
  );
}

const FIELDS: TravelExpenseReportPaymentMethod = {
  id: null,
  name: null,
};
