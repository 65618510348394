import { MouseEventHandler } from 'react';

import { useSyncEmailStatus } from '@work4all/data/lib/hooks/use-sync-email-status';

import { EMail } from '@work4all/models/lib/Classes/EMail.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useEntityPreviewData } from '../hooks/use-entity-preview-data';

import { EmailPreview } from './EmailPreview';

export type IEmailPreviewContainerProps = {
  emailId: number;
  onCloseClicked?: MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onShareClicked?: () => void;
};
export const requestedEmailData: EMail<EMode.query> = {
  id: null,
  subject: null,
  user: {
    id: null,
    displayName: null,
  },
  date: null,
  to: null,
  kind: null,
  from: null,
  cc: null,
  body: null,
  bodyHtml: null,
  projectId: null,
  businessPartnerId: null,
  businessPartnerType: null,
  contactId: null,
  attachmentList: [
    {
      id: null,
      fileName: null,
      displayFilename: null,
      fileInfos: {
        fileSize: null,
        downloadUrl: null,
        fileEntityFilename: null,
        previewUrl: null,
        previewMimeType: null,
        downloadMimeType: null,
        fileRequestType: null,
        fileServiceProviderInfos: {
          customUrl: null,
          exists: null,
          fileName: null,
          fspUrl: null,
          id: null,
          key: null,
          mimeType: null,
          size: null,
          thumbnail: null,
        },
      },
    },
  ],
  saveSendMailJob: {
    id: null,
    errorMessages: null,
  },
};

const additionalVars = { rewriteInlineAttachements: true };

export function EmailPreviewContainer(props: IEmailPreviewContainerProps) {
  const { emailId, onCloseClicked, onEditClicked, onShareClicked } = props;

  const emails = useEntityPreviewData<EMail>(
    [emailId],
    Entities.eMail,
    requestedEmailData,
    additionalVars
  );

  const email = emails?.[0];

  useSyncEmailStatus({
    disabled: email == null,
    shouldSync({ id }) {
      return email?.id === id;
    },
  });

  if (!email) {
    return null;
  }

  return (
    <EmailPreview
      onCloseClicked={onCloseClicked}
      onEditClicked={onEditClicked}
      email={email}
      onShareClicked={onShareClicked}
    />
  );
}
