import React, { useCallback, useMemo } from 'react';

import { useDataProvider } from '@work4all/data/lib/hooks/data-provider/useDataProvider';

import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';
import { TicketChecklisteMark } from '@work4all/models/lib/Classes/TicketChecklisteMark.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { CheckListField, ICheckItem } from './CheckListField';

interface ICheckListFieldProps {
  label?: string;
  value?: (TicketChecklisteMark | LookUp)[];
  disabled?: boolean;
  onChange: (list: LookUp[]) => void;
}

function isLookup(item: TicketChecklisteMark | LookUp): item is LookUp {
  return item.__typename === 'LookUp';
}

export const mapToCheckItem = (item: TicketChecklisteMark | LookUp) => {
  if (isLookup(item)) return { id: item.id };
  return { id: item.lookupId };
};

export const LookupCheckListField: React.FC<ICheckListFieldProps> = (
  props: ICheckListFieldProps
) => {
  const { onChange, value, disabled } = props;

  const requestData: DataRequest = useMemo(() => {
    return {
      entity: Entities.lookUp,
      data: {
        id: null,
        name: null,
        lookUpId: null,
      },
      completeDataResponse: true,
      vars: {
        type: 'TICKET_CHECKLISTENPUNKT',
      },
    };
  }, []);
  const response = useDataProvider<LookUp>(requestData);

  const all = response.data.map((x) => ({ id: x.id, name: x.name })) || [];
  const checkListValue = value?.map(mapToCheckItem) || [];

  const onChangeValue = useCallback(
    (value: ICheckItem[]) => {
      const items = response.data.filter((x) =>
        value.some((y) => y.id === x.id)
      );
      onChange(items);
    },
    [onChange, response.data]
  );
  return (
    <CheckListField
      all={all}
      value={checkListValue}
      disabled={disabled}
      onChange={onChangeValue}
    />
  );
};
