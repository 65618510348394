import styles from './EmailKindCell.module.scss';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EmailInboundIcon } from '@work4all/assets/icons/email-actions/inbound.svg';
import { ReactComponent as EmailOutboundIcon } from '@work4all/assets/icons/email-actions/outbound.svg';

import { EMailKind } from '@work4all/models/lib/Enums/EMailKind.enum';

export type IEmailKindCellProps = {
  value: EMailKind;
};

export const EmailKindCell = (props: IEmailKindCellProps) => {
  const { value } = props;
  const { t } = useTranslation();

  const emailKindValue = useMemo(() => {
    switch (value) {
      case EMailKind.EINGEHEND_HTML:
      case EMailKind.EINGEHEND:
        return {
          kindTranslationText: 'FIELDS.values.kind.INBOUND_TEXT',
          kindIcon: <EmailInboundIcon />,
        };
      case EMailKind.AUSGEHEND:
        return {
          kindTranslationText: 'FIELDS.values.kind.OUTBOUND_TEXT',
        };
      case EMailKind.ENTWURF:
      case EMailKind.ENTWURF_HTML:
        return {
          kindTranslationText: 'FIELDS.values.kind.DRAFT_TEXT',
          kindIcon: <EmailOutboundIcon />,
        };
      default:
        return null;
    }
  }, [value]);

  return (
    <div className={styles.emailKindCellRoot}>
      <span className={styles.icon}>{emailKindValue?.kindIcon}</span>
      <span className={styles.label}>
        {t(emailKindValue?.kindTranslationText)}
      </span>
    </div>
  );
};
