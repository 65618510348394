import { useContext } from 'react';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { LockContext } from './LockContext';

export const useLock = (args?: {
  subEntityType?: Entities;
  subEntityIds?: string[] | number[];
  forcedObjectType?: string;
}) => {
  const ctx = useContext(LockContext);

  return {
    ...ctx,
    lock: () => ctx.lock(args),
    unlock: () => ctx.unlock(args),
  };
};
