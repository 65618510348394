import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class InputErpAnhangAttachementModify<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: number;
  /** Alias: name */
  name?: string;

  __typename?: string;
}

const fields: FieldDefinitions<InputErpAnhangAttachementModify> = {
  code: {
    alias: 'code',
  },
  name: {
    alias: 'name',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputErpAnhangAttachementModifyEntityDefinition: EntitiyDefinition<InputErpAnhangAttachementModify> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputErpAnhangAttachementModify',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
