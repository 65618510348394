import styles from './TurnOffUserStyles.module.scss';

import clsx from 'clsx';
import React from 'react';

import { ITurnOffUserStylesProps } from '../../types';

export const TurnOffUserStyles = React.forwardRef<
  HTMLDivElement,
  ITurnOffUserStylesProps
>(function TurnOffUserStyles(props, ref) {
  const className = clsx(props.children.props.className as string | undefined, {
    [styles.noLeftRadiuses]: props.noLeftRadiuses,
    [styles.noRightRadiuses]: props.noRightRadiuses,
    [styles.noTopRadiuses]: props.noTopRadiuses,
    [styles.noBottomRadiuses]: props.noBottomRadiuses,
  });

  return (
    <div ref={ref} className={clsx(styles.wrapper, props.className)}>
      {React.cloneElement(props.children, { className })}
    </div>
  );
});
