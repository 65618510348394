import { gql } from '@apollo/client';
import cryptoRandomString from 'crypto-random-string';

import {
  IFieldParam,
  IGenerateParams,
} from '@work4all/models/lib/DataProvider';

import { genFieldName } from './helpers/genFieldName';
import { genFields } from './helpers/genFields';
import { genOperationParams } from './helpers/genOperationParams';

export const generateGraphQLQuery = (params: IGenerateParams) => {
  const operationName =
    params.operationName ||
    `Query${cryptoRandomString({ length: 32, type: 'alphanumeric' })}`;

  const { str, allParams: fieldsParams } = genFields(params.fields);
  let allParams: IFieldParam[] = fieldsParams;
  if (params.rootField.params) {
    allParams = allParams.concat(params.rootField.params);
  }

  const queryInner = `${genFieldName(params.rootField)} {
    ${str}
  }`;
  const queryString = `
    query ${operationName}${genOperationParams(allParams)} {
      ${queryInner}
    }
  `;

  return { query: gql(queryString), queryString, queryInner };
};
