import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Holiday } from './Holiday.entity';
import { Sickness } from './Sickness.entity';
import { TimeTracking } from './TimeTracking.entity';
import { User } from './User.entity';
import { Vacation } from './Vacation.entity';

export class TimeTrackingOverviewItem<T extends EMode = EMode.entity> {
  /** Alias: date */
  date?: string;
  /** Alias: targetTime */
  targetTime?: number;
  /** Alias: trackedTime */
  trackedTime?: number;
  /** Alias: timeTrackingItems */
  timeTrackingItems?: TimeTracking<T>[];
  /** Alias: holidayItems */
  holidayItems?: Vacation<T>[];
  /** Alias: absenceItems */
  absenceItems?: Sickness<T>[];
  /** Alias: publicHolidayItems */
  publicHolidayItems?: Holiday<T>[];
  /** Alias: user */
  user?: User<T>;

  __typename?: string;
}

const fields: FieldDefinitions<TimeTrackingOverviewItem> = {
  date: {
    alias: 'date',
  },
  targetTime: {
    alias: 'targetTime',
  },
  trackedTime: {
    alias: 'trackedTime',
  },
  timeTrackingItems: {
    alias: 'timeTrackingItems',
    entity: Entities.timeTracking,
  },
  holidayItems: {
    alias: 'holidayItems',
    entity: Entities.vacation,
  },
  absenceItems: {
    alias: 'absenceItems',
    entity: Entities.sickness,
  },
  publicHolidayItems: {
    alias: 'publicHolidayItems',
    entity: Entities.holiday,
  },
  user: {
    alias: 'user',
    entity: Entities.user,
  },
  __typename: {
    alias: '__typename',
  },
};

export const timeTrackingOverviewItemEntityDefinition: EntitiyDefinition<TimeTrackingOverviewItem> =
  {
    local: {},
    remote: {
      queryName: 'getTimeTrackingOverview',
      fragmentName: 'TimeTrackingOverviewItem',
      withPaginationWrapper: false,
      params: [
        { name: 'from', type: 'Date!' },
        { name: 'to', type: 'Date!' },
        { name: 'userCode', type: 'Int!' },
      ],
    },
    fieldDefinitions: fields,
  };
