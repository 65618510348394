import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowDown } from '@work4all/assets/icons/arrow_down.svg';

import { Base } from '../base/Base';
import { BaseActionProps } from '../types';

export const UnfoldAll: React.FC<BaseActionProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Base icon={<ArrowDown />} title={t('INPUTS.UNFOLD_ALL')} {...props} />
  );
};
