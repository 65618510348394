import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { Theme, useMediaQuery } from '@mui/material';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  ContactPicker,
  IContactPickerProps,
} from '@work4all/components/lib/components/entity-picker/contact-picker/ContactPicker';
import { useEntityTemplate } from '@work4all/components/lib/components/entity-template-provider/EntityTemplateProvider';
import { useCallModalContext } from '@work4all/components/lib/hooks/call-modal/useCallModalContext';
import { useContactActions } from '@work4all/components/lib/hooks/use-contact-actions';

import { useUser } from '@work4all/data';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { reactRefSetter } from '@work4all/utils';
import {
  canAddCustomerContact,
  canAddSupplierContact,
} from '@work4all/utils/lib/permissions';

import { LabeledInput } from '../../containers/mask-overlays/locked-inputs';

interface ContactPickerFieldProps
  extends Omit<IContactPickerProps<false>, 'multiple'> {
  error?: string;
  showContactActions?: boolean;
  businessPartnerType: SdObjType;
  businessPartnerId: number;
}

export const ContactPickerField = React.forwardRef<
  HTMLDivElement,
  ContactPickerFieldProps
>(function ContactPickerField(props, ref) {
  const {
    error,
    showContactActions = true,
    businessPartnerType,
    businessPartnerId,
    ...pickerProps
  } = props;
  const { value, onChange } = pickerProps;

  const { t } = useTranslation();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const entityTemplate = useEntityTemplate();

  const { completeTelephoneNumbers } = useContactActions(value);

  const { setCallModal } = useCallModalContext();

  const user = useUser();

  const canAddContact =
    (canAddCustomerContact(user) && businessPartnerType === SdObjType.KUNDE) ||
    (canAddSupplierContact(user) &&
      businessPartnerType === SdObjType.LIEFERANT);

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );

  const actions = useMemo(() => {
    const result = [];
    if (value?.eMail)
      result.push({
        href: `mailto:${value?.eMail}`,
        icon: <EmailIcon color="primary" />,
      });
    if (
      Object.keys(completeTelephoneNumbers).find(
        (key) =>
          !key.startsWith('__') &&
          completeTelephoneNumbers[key] !== null &&
          completeTelephoneNumbers[key].length > 0
      )
    )
      result.push({
        onClick: () => {
          setCallModal({
            data: value,
            telephoneNumbers: completeTelephoneNumbers,
          });
        },
        icon: <PhoneIcon color="primary" />,
      });

    return result;
  }, [completeTelephoneNumbers, setCallModal, value]);

  const popoverRef = useRef<EntityPickerPopover>(null);

  // TODO: ContactPicker has no generic footer
  return (
    <div ref={reactRefSetter(wrapperRef, ref)}>
      <EntityPickerPopover
        fullscreen={isMobile}
        ref={popoverRef}
        anchorEl={wrapperRef}
        picker={
          <ContactPicker
            prefilter={[
              {
                businessPartnerId: {
                  $eq: businessPartnerId,
                },
              },
              {
                businessPartnerType: {
                  $eq: businessPartnerType,
                },
              },
            ]}
            {...pickerProps}
            multiple={false}
          />
        }
        footer={
          canAddContact
            ? entityTemplate.renderPickerFooter({
                popoverRef,
                entity: Entities.contact,
                template: {
                  id: businessPartnerId,
                  entity:
                    businessPartnerType === SdObjType.KUNDE
                      ? Entities.customer
                      : Entities.supplier,
                },
                data: {
                  id: null,
                  displayName: null,
                  firstName: null,
                  name: null,
                },
                hideListButton: true,
                ...props,
              })
            : undefined
        }
      >
        <LabeledInput
          value={value?.displayName ?? ''}
          label={t(`COMMON.CONTACT`)}
          error={error}
          onClear={() => {
            onChange(null);
          }}
          actions={showContactActions && value ? actions : undefined}
        />
      </EntityPickerPopover>
    </div>
  );
});
