import { createContext, Provider, useContext } from 'react';

export interface ApiVersionContextValue {
  version: string;
}

const Context = createContext<ApiVersionContextValue | null>(null);

export const ApiVersionContextProvider =
  Context.Provider as Provider<ApiVersionContextValue>;

export function useApiVersionContext(): ApiVersionContextValue {
  const context = useContext(Context);

  if (context === null) {
    throw new Error(
      `useApiVersionContext must be used within a <ApiVersionContextProvider>`
    );
  }

  return context;
}
