import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { AppearanceBackgroundImage } from './AppearanceBackgroundImage.entity';
import { UserApperanceConfiguration } from './UserApperanceConfiguration.entity';

export class Appearance<T extends EMode = EMode.entity> {
  /** Alias: backgroundImagesGeneral */
  backgroundImagesGeneral?: AppearanceBackgroundImage<T>[];
  /** Alias: backgroundImagesTenant */
  backgroundImagesTenant?: AppearanceBackgroundImage<T>[];
  /** Alias: backgroundImagesUser */
  backgroundImagesUser?: AppearanceBackgroundImage<T>[];
  /** Alias: configuration */
  configuration?: UserApperanceConfiguration<T>;

  __typename?: string;
}

const fields: FieldDefinitions<Appearance> = {
  backgroundImagesGeneral: {
    alias: 'backgroundImagesGeneral',
    entity: Entities.appearanceBackgroundImage,
  },
  backgroundImagesTenant: {
    alias: 'backgroundImagesTenant',
    entity: Entities.appearanceBackgroundImage,
  },
  backgroundImagesUser: {
    alias: 'backgroundImagesUser',
    entity: Entities.appearanceBackgroundImage,
  },
  configuration: {
    alias: 'configuration',
    entity: Entities.userApperanceConfiguration,
  },
  __typename: {
    alias: '__typename',
  },
};

export const appearanceEntityDefinition: EntitiyDefinition<Appearance> = {
  local: {},
  remote: {
    queryName: 'getAppearance',
    fragmentName: 'Appearance',
    withPaginationWrapper: false,
    mutations: {
      upsert: {
        mutationName: 'modifyAppearance',
        args: [{ name: 'input', type: 'AppearanceSettingsInput!' }],
      },
    },
    params: [
      { name: 'syncDate', type: 'DateTime' },
      { name: 'filterPrefix', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
