import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class Tour<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: name */
  name?: string;
  /** Alias: nummer */
  number?: number;
  /** Alias: wochentag */
  weekDay?: number;

  __typename?: string;
}

const fields: FieldDefinitions<Tour> = {
  id: {
    alias: 'code',
  },
  name: {
    alias: 'name',
  },
  number: {
    alias: 'nummer',
  },
  weekDay: {
    alias: 'wochentag',
  },
  __typename: {
    alias: '__typename',
  },
};

export const tourEntityDefinition: EntitiyDefinition<Tour> = {
  local: {},
  remote: {
    queryName: 'getTouren',
    fragmentName: 'Tour',
    withPaginationWrapper: false,
    params: [{ name: 'codes', type: '[Int]' }],
  },
  fieldDefinitions: fields,
};
