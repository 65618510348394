export enum TimeStampKind {
  MANUELL = 'MANUELL',
  BARCODE = 'BARCODE',
  WORK_4_ALL = 'WORK_4_ALL',
  REISEKOSTENABRECHNUNG = 'REISEKOSTENABRECHNUNG',
  URLAUB = 'URLAUB',
  KRANKHEIT = 'KRANKHEIT',
  AUTOMATISCHER_EINTRAG = 'AUTOMATISCHER_EINTRAG',
  STUNDENABBAU = 'STUNDENABBAU',
  PAUSENBEGINN = 'PAUSENBEGINN',
  TAG_BEENDET = 'TAG_BEENDET',
  KORREKTUR = 'KORREKTUR',
  PAUSENENDE = 'PAUSENENDE',
  DIENSTLICH_BEGINN = 'DIENSTLICH_BEGINN',
  DIENSTLICH_ENDE = 'DIENSTLICH_ENDE',
  WEGEZEIT_BEGINN = 'WEGEZEIT_BEGINN',
  WEGEZEIT_ENDE = 'WEGEZEIT_ENDE',
  KORREKTUR_MONAT = 'KORREKTUR_MONAT',
  UEBERSTUNDENKORREKTUR = 'UEBERSTUNDENKORREKTUR',
}
