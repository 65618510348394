import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { LedgerAccount } from './LedgerAccount.entity';

export class TravelExpenseReportPaymentMethod<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: enumZahlungsart */
  enumPaymentKind?: number;
  /** Alias: name */
  name?: string;
  /** Alias: ledgerAccountCode */
  ledgerAccountId?: number;
  /** Alias: payrollAccountCode */
  payrollAccountId?: number;
  /** Alias: ledgerAccount */
  ledgerAccount?: LedgerAccount<T>;
  /** Alias: payrollAccount */
  payrollAccount?: LedgerAccount<T>;

  __typename?: string;
}

const fields: FieldDefinitions<TravelExpenseReportPaymentMethod> = {
  id: {
    alias: 'code',
  },
  enumPaymentKind: {
    alias: 'enumZahlungsart',
  },
  name: {
    alias: 'name',
  },
  ledgerAccountId: {
    alias: 'ledgerAccountCode',
  },
  payrollAccountId: {
    alias: 'payrollAccountCode',
  },
  ledgerAccount: {
    alias: 'ledgerAccount',
    entity: Entities.ledgerAccount,
  },
  payrollAccount: {
    alias: 'payrollAccount',
    entity: Entities.ledgerAccount,
  },
  __typename: {
    alias: '__typename',
  },
};

export const travelExpenseReportPaymentMethodEntityDefinition: EntitiyDefinition<TravelExpenseReportPaymentMethod> =
  {
    local: {},
    remote: {
      queryName: 'getTravelAndReceiptReceiptPaymentType',
      fragmentName: 'ReisekostenabrechnungZahlungsart',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
