import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class InputTicketChecklisteMarkRelation<T extends EMode = EMode.entity> {
  /** Alias: check */
  check?: number[];
  /** Alias: uncheck */
  uncheck?: number[];

  __typename?: string;
}

const fields: FieldDefinitions<InputTicketChecklisteMarkRelation> = {
  check: {
    alias: 'check',
  },
  uncheck: {
    alias: 'uncheck',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputTicketChecklisteMarkRelationEntityDefinition: EntitiyDefinition<InputTicketChecklisteMarkRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputTicketChecklisteMarkRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
