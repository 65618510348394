import styles from './TimeCardPreview.module.scss';

import clsx from 'clsx';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PauseIcon } from '@work4all/assets/icons/statistics/pause_circle_filled.svg';
import { ReactComponent as PlayIcon } from '@work4all/assets/icons/statistics/play_circle_filled.svg';
import { ReactComponent as StopIcon } from '@work4all/assets/icons/statistics/stop-circle.svg';

import { TimeInputPicker } from '@work4all/components/lib/input/time-input-picker';

import { UserPresenceTimeInfo } from '@work4all/models/lib/Classes/UserPresenceTimeInfo.entity';
import { TimeStampKind } from '@work4all/models/lib/Enums/TimeStampKind.enum';

import { useTimeCardDetails } from './hooks/useTimeCardDetails';
import { useTimeEntryMutation } from './hooks/useTimeEntryMutation';
import { TimeCardPreviewTimeStamp } from './TimeCardPreviewTimestamp';

interface Props {
  block: UserPresenceTimeInfo;
  separator: boolean;
}

export const TimeCardPreviewBlock: React.FC<Props> = (props: Props) => {
  const { block, separator } = props;
  const { t } = useTranslation();
  const { disabledPause, disabled, isLast } = useTimeCardDetails();

  const { update, remove, onBlur } = useTimeEntryMutation();

  if (!block.items?.length) return <></>;
  const array = block.items.map((stamp, id) => {
    if (stamp.kind === TimeStampKind.MANUELL)
      return (
        <TimeCardPreviewTimeStamp
          {...stamp}
          key={`stamp_${stamp.timestamp}`}
          icon={<PlayIcon />}
          disabled={disabled}
          label={t('INPUTS.START')}
          onClear={
            isLast(stamp.id)
              ? () => {
                  remove(stamp.id);
                }
              : undefined
          }
        />
      );
    if (stamp.kind === TimeStampKind.TAG_BEENDET)
      return (
        <TimeCardPreviewTimeStamp
          {...stamp}
          key={`stamp_${stamp.timestamp}`}
          icon={<StopIcon />}
          disabled={disabled}
          label={t('INPUTS.END')}
          onClear={
            isLast(stamp.id)
              ? () => {
                  remove(stamp.id);
                }
              : undefined
          }
        />
      );

    if (stamp.kind === TimeStampKind.PAUSENBEGINN) {
      const endTimeStamp = block.items[id + 1];
      return (
        <div
          key={`stamp_${stamp.timestamp}`}
          className={styles['double-time-entry']}
        >
          <div className={styles['time-preview__icon']}>
            <PauseIcon />
          </div>

          <div
            className={clsx(
              styles['time-preview__item'],
              styles['time-preview__row']
            )}
          >
            <TimeInputPicker
              onChange={(e) => {
                update(stamp.id, e.currentTarget?.value);
              }}
              onDestroyPopover={onBlur}
              disabled={disabledPause}
              className={styles['double-time-entry--left']}
              rootClassName={styles['double-time-entry__item']}
              label={t('INPUTS.PAUSE')}
              defaultValue={DateTime.fromISO(stamp.timestamp)}
              onClear={
                isLast(stamp.id)
                  ? () => {
                      remove(stamp.id);
                    }
                  : undefined
              }
            />
            {endTimeStamp && (
              <TimeInputPicker
                onChange={(e) => {
                  update(endTimeStamp.id, e.currentTarget?.value);
                }}
                onDestroyPopover={onBlur}
                disabled={disabledPause}
                label={t('INPUTS.END')}
                className={styles['double-time-entry--right']}
                rootClassName={styles['double-time-entry__item']}
                defaultValue={DateTime.fromISO(endTimeStamp.timestamp)}
                onClear={
                  isLast(endTimeStamp.id)
                    ? () => {
                        remove(endTimeStamp.id);
                      }
                    : undefined
                }
              />
            )}
          </div>
        </div>
      );
    }
  });

  return (
    <>
      <div className={styles['time-preview__block']}>{array}</div>
      {separator ? (
        <div
          key={`${block.start}_separator`}
          className={styles['time-preview__separator']}
        />
      ) : undefined}
    </>
  );
};
