import styles from './MaskActionButton.module.scss';

import { IconButton, Theme, useMediaQuery } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import Fab from '@mui/material/Fab';

import { Tooltip } from '../tooltip/Tooltip';

export enum LABEL_VISIBILITY {
  SHOW = 'SHOW',
  HIDE = 'HIDE',
}

export interface MaskActionButtonProps
  extends Pick<
    ButtonProps,
    'type' | 'color' | 'onClick' | 'disabled' | 'variant'
  > {
  label: string;
  icon: React.ReactElement;
  labelVisibility?: LABEL_VISIBILITY;
  disabledReason?: string;
}

export function MaskActionButton(props: MaskActionButtonProps) {
  const {
    label,
    icon,
    variant,
    labelVisibility = LABEL_VISIBILITY.SHOW,
    disabledReason,
    ...other
  } = props;

  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'));

  if (isDesktop) {
    const buttonProps: ButtonProps = {
      size: 'large',
      variant,
      ...other,
    };

    let ButtonComponent = (
      <Button {...buttonProps} startIcon={icon}>
        {label}
      </Button>
    );
    if (labelVisibility === LABEL_VISIBILITY.HIDE) {
      ButtonComponent = (
        <IconButton
          {...buttonProps}
          data-test="1"
          className={styles.iconWrapper}
        >
          {icon}
        </IconButton>
      );
    }
    const tooltipText = [
      labelVisibility === LABEL_VISIBILITY.HIDE ? label : undefined,
      disabledReason,
    ]
      .filter((el) => !!el)
      .join(': ');

    return (
      <Tooltip title={tooltipText} activateForDisabled={true}>
        {ButtonComponent}
      </Tooltip>
    );
  }
  return (
    <Tooltip
      title={disabledReason}
      activateForDisabled={{ activeateOnClick: true }}
    >
      <Fab size="medium" aria-label={label} {...other}>
        {icon}
      </Fab>
    </Tooltip>
  );
}
