import styles from './HtmlInputField.module.scss';

import { useState } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';

import {
  FROALA_TEXTMARKS_STYLES_URL,
  getPageThemeStyles,
} from '@work4all/components/lib/components/entity-preview/utils';
import { TextEditor } from '@work4all/components/lib/input/format-text/TextEditor';

import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { TextmarkPickerButton } from '../../../../../../../components/entity-picker/TextmarkPickerButton';
import { LabeledInput } from '../../../../../locked-inputs';

export interface HtmlInputFieldProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
}

export const HtmlInputField = (props: HtmlInputFieldProps) => {
  const { label, value, onChange } = props;

  const [editor, setEditor] = useState<FroalaEditor>();

  return (
    <LabeledInput
      onClear={null}
      value={
        <div className={styles.inputWrapper}>
          <TextEditor
            value={value}
            onInitialized={(editor: FroalaEditor) => {
              setEditor(editor);
            }}
            hideToolbar
            onChange={onChange}
            config={{
              height: remToPx(1),
              heightMax: remToPx(6),
              heightMin: remToPx(1),
              placeholderText: '',
              imageUpload: false,
              iframeStyleFiles: [FROALA_TEXTMARKS_STYLES_URL],
              iframeStyle:
                'html, body{ height: unset !important;padding:3px 0 !important; } .fr-view { font-family: Roboto, Helvetica, Arial, sans-serif; font-size: 14px !important; } ' +
                getPageThemeStyles(),
            }}
          />
        </div>
      }
      label={label}
      iconRight={
        <TextmarkPickerButton
          onChange={({ newEditorValue }) => {
            onChange(newEditorValue);
          }}
          editor={editor}
        />
      }
    />
  );
};
