import styles from './PositionPicker.module.scss';

import CheckIcon from '@mui/icons-material/Check';
import clsx from 'clsx';
import { useObservableState } from 'observable-hooks';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { TableStateBagProvider } from '@work4all/components';
import { ResizableArea } from '@work4all/components/lib/components/ResizableArea/ResizableArea';
import {
  ColumnVisibilityContext,
  useColumnVisibilityManager,
} from '@work4all/components/lib/dataDisplay/basic-table/hooks/useColumnVisibility';
import { LockObjectProvider } from '@work4all/components/lib/hooks/object-lock-subscription/useLockObject';
import { IconButtonWithTooltip } from '@work4all/components/lib/input/actions/IconButtonWithTooltip';

import { PrintBagProvider } from '@work4all/data/lib/hooks/useHandlePrint';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { settings, useSetting } from '../../../../../../../../../../settings';
import { PositionGroupsTree } from '../position-group-tree/PositionGroupsTree';

import { ArticleSearch } from './components/ArticleSearch';
import { PositionArticlesTable } from './components/PositionArticlesTable';
import { PositionMore } from './components/PositionMore';
import { PositionsFilter } from './components/PositionsFilter';

export type IPositionPickerProps = {
  className?: string;
  onAddStandard: (articleId: Article) => void;
  onGroupDoubleClick: (title: string) => void;
  disabled: boolean;
};

export const PositionPicker = (props: IPositionPickerProps) => {
  const { className, onAddStandard, onGroupDoubleClick, disabled } = props;

  const { t } = useTranslation();

  const [selectedGroupIds, setSelectedGroupIds] = useState<number[]>(null);

  const [searchString, setSearchString] = useObservableState(
    (input$) => input$.pipe(distinctUntilChanged(), debounceTime(200)),
    ''
  );

  const [selectedArticles, setSelectedArticles] = useState<Article[]>([]);

  const formattedSearchString = searchString.trim();

  const [tableInstance, setTableInstance] = useState<TableInstance | null>(
    null
  );

  const tableInstanceRef = useRef<TableInstance>();
  useEffect(() => {
    tableInstanceRef.current = tableInstance;
  }, [tableInstance]);
  const previewSize = useSetting(settings.leftAreaSize());

  const columnsVisibilityManager = useColumnVisibilityManager(
    tableInstanceRef.current
  );

  const resetColumnsRef = useRef<() => void>();

  return (
    <PrintBagProvider>
      <LockObjectProvider entity={Entities.article}>
        <TableStateBagProvider tableInstance={tableInstance}>
          <ColumnVisibilityContext.Provider value={columnsVisibilityManager}>
            <div
              className={clsx(styles.grid, className, {
                [styles.pickerDisabled]: disabled,
              })}
            >
              <div className={styles.toolbar}>
                <ArticleSearch onChange={setSearchString} disabled={disabled} />

                <div className={styles.actions}>
                  {selectedArticles.length ? (
                    <IconButtonWithTooltip
                      tooltip={t('COMMON.APPLY_SELECTION')}
                      icon={<CheckIcon />}
                      onClick={() => {
                        // TODO: add bulk save
                        onAddStandard(selectedArticles[0]);
                      }}
                    />
                  ) : null}
                  <PositionsFilter
                    entity={Entities.article}
                    disabled={disabled}
                    tableInstanceRef={tableInstanceRef}
                  />
                  <PositionMore
                    resetColumns={resetColumnsRef.current}
                    disabled={disabled}
                    tableInstanceRef={tableInstanceRef}
                  />
                </div>
              </div>

              <div className={styles.tableWrapper}>
                <ResizableArea
                  handles="right"
                  className={styles.groupsContainer}
                  size={previewSize.value}
                  onResize={(size) => {
                    previewSize.set(size);
                  }}
                  maxWidth={800}
                  minWidth={100}
                >
                  <div className={styles.groups}>
                    <PositionGroupsTree
                      disabled={disabled}
                      onSelect={(ids) => {
                        if (ids.length > 0) {
                          setSelectedGroupIds(ids);
                        } else {
                          setSelectedGroupIds(null);
                        }
                      }}
                      onNodeDoubleClick={(e) =>
                        onGroupDoubleClick(e.target.innerText)
                      }
                    />
                  </div>
                </ResizableArea>

                <div className={styles.tableContainer}>
                  <PositionArticlesTable
                    ref={setTableInstance}
                    searchString={formattedSearchString}
                    groups={selectedGroupIds}
                    onSelectedChange={setSelectedArticles}
                    onRowDoubleClick={(article) => onAddStandard(article)}
                    disabled={disabled}
                    setResetColumns={(resetColumns) =>
                      (resetColumnsRef.current = resetColumns)
                    }
                  />
                </div>
              </div>
            </div>
          </ColumnVisibilityContext.Provider>
        </TableStateBagProvider>
      </LockObjectProvider>
    </PrintBagProvider>
  );
};
